import React, {Component} from 'react';
import { loadConfig } from '../../helpers/configHelper';
import App from './app';

class AppWrapper extends Component {

  state = {
    configLoaded: false
  };

  componentDidMount() {
    loadConfig().then(() => {
      this.setState({configLoaded: true})
    });
  }

  render() {
    return (<div> { this.state.configLoaded && <App/> } </div>);
  }
}

export default AppWrapper;