import _ from 'lodash';
import moment from 'moment';

import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import { handleError } from "../../../../app/actions/appErrorActions";

import errorMessages from '../../../../common/errorMessages';

import * as appWebsocketActions from '../../../../app/actions/appWebsocketActions';
import * as appContextActions from '../../../../app/actions/appContextActions';
import ComponentTypes from '../../../../../components/componentTypes';

import liveViewActionTypes from './liveViewActionTypes';
import * as liveViewQueries from './liveViewQueries';
import * as liveViewService from './services/liveViewService';
import { getDefaultTimeFrame, dataDisplayModes } from './liveViewSelectors';

import {exportCsvFactory} from '../../../../common/exportCsv/exportCsvActions';

const loadLiveViewConfiguration = (stateDef, fleetName) => {
  return async (dispatch, getState) => {
    // CLAIM: All users should have a Fleet Dashboard configuration 
    const fleetDashboardConfig = { ...getState().app.user.dashboards[ComponentTypes.FLEET_DASHBOARD] };
    const configurationToLoad = _.find(fleetDashboardConfig.views.views, ['id', stateDef.key]);
    if (!_.isNil(configurationToLoad)) {
      
      await dispatch(liveViewReset(stateDef));
      await dispatch(onSetTimeFrame(stateDef, configurationToLoad.config.selectedTimeFrame));
      await dispatch(onSetTruckFilter(stateDef, configurationToLoad.config.selectedTruckFilter));
      await dispatch(onSetSelectedSensors(stateDef, configurationToLoad.config.selectedSensors));
      await dispatch(onSetSensorForDefinition(stateDef, configurationToLoad.config.selectedSensor, fleetName));

    }
  }
}

const queryTrucksStarting = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_STARTING, 'stateDef');
const queryTrucksSuccess = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_SUCCESS, 'stateDef', 'queryResults');
const queryTrucksError = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_TRUCKS_ERROR, 'stateDef');

const queryTrucks = (stateDef, fleetId, datavanPid, unitType) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      const componentState = getState()[stateDef.key];

      if (!_.isNil(componentState)) {
        // Close off any existing subscriptions
        if (!_.isEmpty(componentState.selectedSensors)) {
          await dispatch(closeSubscription(stateDef));
        }

        // This method is called when component is first mounted, which may be before state slice exists
        const selectedTimeFrame = _.isNil(componentState) ? getDefaultTimeFrame() : componentState.selectedTimeFrame;
        const startTimeUnix = moment().subtract(selectedTimeFrame.value, 'minutes').startOf('minute').unix();
        const endTimeUnix = moment().startOf('minute').unix();

        await dispatch(queryTrucksStarting(stateDef));

        // Create the input object
        const input = {
          datavanPid: datavanPid,
          fleetId: fleetId,
          startTime: startTimeUnix,
          endTime: endTimeUnix,
          unitType: unitType
        };

        queryResults = await liveViewQueries.fetchTrucksForLiveView(input);

        await dispatch(queryTrucksSuccess(stateDef, queryResults));

        // Create new subscriptions
        if (!_.isEmpty(componentState.selectedSensors)) {
          await dispatch(createSubscription(stateDef));
        }

        // We can check if any trucks are returned and if a sensor is selected, query the sensor data
        if (!_.isNil(componentState.selectedSensor) && !_.isEmpty(queryResults.trucksForLiveView)) {
          await dispatch(onQuerySensorDataForTrucks(stateDef));
        }
      }
    } catch (e) {
      await dispatch(queryTrucksError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LIVE_VIEW_QUERY_TRUCKS, e.message));
    }
  }
}

const onSetTimeFrame = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_TIME_FRAME, 'stateDef', 'timeFrame');

const setTimeFrame = (stateDef, timeFrame, fleetId, datavanPid, unitType) => {
  return async (dispatch, getState) => {

    const componentState = getState()[stateDef.key];

    // Close off any existing subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(closeSubscription(stateDef));
    }

    await dispatch(onSetTimeFrame(stateDef, timeFrame));

    await dispatch(saveToUserConfiguration(stateDef));

    await dispatch(queryTrucks(stateDef, fleetId, datavanPid, unitType));

    await dispatch(onQuerySensorDataForTrucks(stateDef));

    // Create new subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(createSubscription(stateDef));
    }
  }
}

const onSetTruckFilter = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_TRUCK_FILTER, 'stateDef', 'truckFilter');

const setTruckFilter = (stateDef, truckFilter) => {
  return async (dispatch, getState) => {

    const componentState = getState()[stateDef.key];

    // Close off any existing subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(closeSubscription(stateDef));
    }
    
    await dispatch(onSetTruckFilter(stateDef, truckFilter));

    await dispatch(saveToUserConfiguration(stateDef));

    await dispatch(onQuerySensorDataForTrucks(stateDef));

    // Create new subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(createSubscription(stateDef));
    }
  }
}

const onQuerySensorDataForTrucks = (stateDef) => {
  return async (dispatch, getState) => {

    const componentState = getState()[stateDef.key];

    // If we have trucks selected (it's possible that no trucks match a selected label filter) and a sensor selected, query the sensor data
    // CLAIM: Time frame will always have a value
    if ((!_.isEmpty(componentState.selectedTrucks)) && (!_.isNil(componentState.selectedSensor))) {
      let startTimeUnix = moment().subtract(componentState.selectedTimeFrame.value, 'minutes').startOf('minute').unix();
      let endTimeUnix = moment().startOf('minute').unix();

      await dispatch(querySensorForTrucks(stateDef, componentState.selectedTrucks, startTimeUnix, endTimeUnix, componentState.selectedSensor));
    }

  }
}

const openSensorSelector = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_SENSOR_SELECTOR_OPEN, 'stateDef', 'shouldOpen');
const onSetSelectedSensors = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_SELECTED_SENSORS, 'stateDef', 'selectedSensors');

const setSelectedSensors = (stateDef, selectedSensors, fleetName) => {
  return async (dispatch, getState) => {

    const componentState = getState()[stateDef.key];

    const differences = _.differenceWith(selectedSensors, componentState.selectedSensors, (a, b) => {
      return a.sensorSetId === b.sensorSetId && a.uom === b.uom;
    });

    // Only make updates if there are differences
    if (!_.isEmpty(differences) || componentState.selectedSensors.length !== selectedSensors.length) {

      // Close off any existing subscriptions
      if (!_.isEmpty(componentState.selectedSensors)) {
        await dispatch(closeSubscription(stateDef));
      }

      await dispatch(onSetSelectedSensors(stateDef, selectedSensors));

      await dispatch(saveToUserConfiguration(stateDef));

      // Create new subscriptions
      if (!_.isEmpty(componentState.selectedSensors)) {
        await dispatch(createSubscription(stateDef));
      }

      // If the current selected sensor has changed, then we need to set the sensor accordingly in the definition
      if (!_.isNil(componentState.selectedSensor)) {
        const selectedSensor = _.find(differences, ['sensorSetId', componentState.selectedSensor.sensorSetId]);
        if (!_.isNil(selectedSensor)) {
          await dispatch(setSensorForDefinition(stateDef, selectedSensor, fleetName));
        }
      }
    }
  }
}

const onSetSensorForDefinition = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_DEFINITION_SET_SENSOR, 'stateDef', 'sensor', 'fleetName');

const setSensorForDefinition = (stateDef, sensor, fleetName) => {
  return async (dispatch, getState) => {
    await dispatch(onSetSensorForDefinition(stateDef, sensor, fleetName));

    await dispatch(saveToUserConfiguration(stateDef));

    await dispatch(onQuerySensorDataForTrucks(stateDef));
  }
}

const querySensorForTrucksStarting = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_STARTING, 'stateDef');
const querySensorForTrucksSuccess = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_SUCCESS, 'stateDef', 'queryResults', 'startTime', 'endTime');
const querySensorForTrucksError = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS_ERROR, 'stateDef');

const querySensorForTrucks = (stateDef, trucks, startTime, endTime, selectedSensor) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {

      await dispatch(querySensorForTrucksStarting(stateDef));      

      // Create the input object
      const input = [];

      _.forEach(trucks, (truck) => {
        if (truck.truckPid !== 0) {
          input.push({
            truckPid: truck.truckPid,
            sensorSetId: selectedSensor.sensorSetId,
            unitOfMeasure: selectedSensor.uom,
            startTime: startTime,
            endTime: endTime
          })
        }
      });

      queryResults = await liveViewQueries.fetchSensorDataForTrucks(input);

      await dispatch(querySensorForTrucksSuccess(stateDef, queryResults, startTime, endTime));
    } catch (e) {
      await dispatch(querySensorForTrucksError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS, e.message));
    }
  }
}

const toggleContextVisibility = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY, 'stateDef', 'truckPid');
const toggleContextVisibilityAllOthers = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_ALL_OTHERS, 'stateDef', 'truckPid', 'isVisible');
const toggleContextVisibilityOtherAfter = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONTEXT_TOGGLE_VISIBILITY_OTHER_AFTER, 'stateDef', 'truckPid', 'isVisible');

const onSetConfigureChart = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_CHART, 'stateDef', 'configureChart');

const setConfigureChart = (stateDef, configureChart, fleetName) => {
  return async (dispatch, getState) => {
    await dispatch(onSetConfigureChart(stateDef, configureChart));
    await dispatch(appContextActions.setContext(ComponentTypes.FLEET_LIVE_VIEW, {fleetName: fleetName}));
    await dispatch(appContextActions.openContextDrawer(configureChart, getState()[stateDef.key]?.configPanelWidth));
  }
}

const setContextColor = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_CONTEXT_COLOR, 'stateDef', 'truckPid', 'color');
const selectConfigTab = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_CHART_SELECT_TAB, 'stateDef', 'tabIndex');

const addDataRuleForSensor = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_ADD_DATA_RULE, 'stateDef', 'sensorSetId');
const removeDataRuleForSensor = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_REMOVE_DATA_RULE, 'stateDef', 'sensorSetId', 'ruleIndex');
const dataRuleSetColorPickerState = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_DATA_RULE_SET_COLOR_PICKER_STATE, 'stateDef', 'sensorSetId', 'index', 'origColor');
const dataRuleSetProperty = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_DATA_RULE_SET_PROPERTY, 'stateDef', 'sensorSetId', 'index', 'property', 'value');
const moveSensor = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_MOVE_SENSOR, 'stateDef', 'removedIndex', 'addedIndex');
const setSensorDisplayName = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_SENSOR_DISPLAY_NAME, 'stateDef', 'sensorSetId', 'displayName');
const onSetSensorUOM = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_SET_SENSOR_UOM, 'stateDef', 'sensorSetId', 'uom');

const setSensorUOM = (stateDef, sensorSetId, uom, fleetName) => {
  return async (dispatch, getState) => {

    const componentState = getState()[stateDef.key];

    // Close off any existing subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(closeSubscription(stateDef));
    }

    await dispatch(onSetSensorUOM(stateDef, sensorSetId, uom));

    // If the current selected sensor has changed, then we need to set the sensor accordingly in the definition and get new historical data
    if (!_.isNil(componentState.selectedSensor) && (componentState.selectedSensor.sensorSetId === sensorSetId)) {
      await dispatch(setSensorForDefinition(stateDef, componentState.selectedSensor, fleetName));
    }

    // Create new subscriptions
    if (!_.isEmpty(componentState.selectedSensors)) {
      await dispatch(createSubscription(stateDef));
    }

  }
}

const setFullScreenOption = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_FULLSCREEN_OPTION, 'stateDef', 'option');
const highlightTruck = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_HIGHLIGHT_TRUCK, 'stateDef', 'truckPid');

const xAxisVisibleRangeChanged = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_XAXIS_VISIBLE_RANGE_CHANGED, 'stateDef', 'xAxisId', 'xMin', 'xMax');
const yAxisVisibleRangeChanged = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_YAXIS_VISIBLE_RANGE_CHANGED, 'stateDef', 'yAxisId', 'yMin', 'yMax');

const downloadData = (stateDef, fleetName) => {
  return async (dispatch, getState) => {
    try {
      const componentState = getState()[stateDef.key];

      // We don't store a secondary xValues set so we need to decide which yValues set to use based on 
      // the data display mode selected by the user
      const yValuesToDownload = componentState.dataDisplayModeToggle === dataDisplayModes()[0] ? componentState.primaryYValues : componentState.relativeYValues;

      const json = liveViewService.transformDataToJson(componentState.primaryXValues, yValuesToDownload, componentState.ranges, componentState.definition[componentState.definition.xAxes[0]].contexts);

      const dateTimeFormat = 'YYYY-MM-DD@HH-mm-ss';
      const startTime = moment.unix(componentState.ranges.x[componentState.definition.xAxes[0]].min).format(dateTimeFormat);
      const endTime = moment.unix(componentState.ranges.x[componentState.definition.xAxes[0]].max).format(dateTimeFormat);
      const sensorName = componentState.selectedSensor.alias + '_' + componentState.selectedSensor.uom;
      const columnNames = [];
      columnNames.push({ name: 'Sensor_' + sensorName, property: 'timeStamp' });
      _.forEach(componentState.definition[componentState.definition.xAxes[0]].contexts, (truck, i) => {
        if (truck.visible === true) {
          columnNames.push( { name: truck.name, property: truck.name });
        }
      })
      const exportCsv = exportCsvFactory('live_view_fleet_' + fleetName + '_' + sensorName + '_' + startTime + '_to_' + endTime, columnNames, errorMessages.ERROR_DOWNLOADING_VISIBLE_CHART_DATA);
      await dispatch(exportCsv(stateDef, json));
    } catch (e) {
      return dispatch(handleError(errorMessages.ERROR_LIVE_VIEW_DOWNLOAD_DATA, e.message));
    }
  }
}

const createSubscription = (stateDef) => {
  return async (dispatch, getState) => {
    try {
      const componentState = getState()[stateDef.key];
      const sensorSetIds = _.map(componentState.selectedSensors, (sensor) => sensor.sensorSetId);
      const uoms = _.map(componentState.selectedSensors, (sensor) => sensor.uom);

      const data = [];
      _.forEach(componentState.selectedTrucks, (truck) => {
        if (truck.truckPid !== 0) {
          data.push({
            truckPid: truck.truckPid,
            sensorSetIds: sensorSetIds,
            uoms: uoms
          })
        }
      });

      const payload = {
        clientId: getState().app.live.clientId,
        type: 'sub:latestvalue',
        stateDef: stateDef,
        payload: data
      }

      await dispatch(appWebsocketActions.sendMessage(JSON.stringify(payload)));

      // Save a "unsub" message we can use to close the subscription at the app level 
      // so we can clean up subscriptions when the websocket connection is closed
      const subscriptionData = _.map(_.filter(componentState.selectedTrucks, (truck) => { return truck.truckPid !== 0; }), (truck) => truck.truckPid);
      if (!_.isEmpty(subscriptionData)) {
        const payload = liveViewService.getCloseSubscriptionMessage(getState().app.live.clientId, stateDef, subscriptionData);
        await dispatch(appWebsocketActions.saveSubscription(JSON.stringify(payload)));
      }
    } catch (e) {
      return dispatch(handleError(errorMessages.ERROR_WEBSOCKET_SENDING_MESSAGE, e.message));
    }
  }
}

const closeSubscription = (stateDef) => {
  return async (dispatch, getState) => {
    try {
      const componentState = getState()[stateDef.key];
      const data = _.map(_.filter(componentState.selectedTrucks, (truck) => { return truck.truckPid !== 0; }), (truck) => truck.truckPid);
      
      if (!_.isEmpty(data)) {
        const payload = liveViewService.getCloseSubscriptionMessage(getState().app.live.clientId, stateDef, data);
        await dispatch(appWebsocketActions.sendMessage(JSON.stringify(payload)));
      }
    } catch (e) {
      return dispatch(handleError(errorMessages.ERROR_WEBSOCKET_SENDING_MESSAGE, e.message));
    }
  }
}

const toggleLiveFeed = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_PAUSE_SUBSCRIPTION, 'stateDef', 'isPaused');
const setDataDisplayModeOption = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_SET_DATA_DISPLAY_MODE_OPTION, 'stateDef', 'option');
const liveViewReset = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_RESET, 'stateDef');


const saveToUserConfigurationStarting = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_STARTING, 'stateDef');
const saveToUserConfigurationSuccess = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_SUCCESS, 'stateDef', 'queryResults');
const saveToUserConfigurationError = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_USER_CONFIGURATION_SAVE_ERROR, 'stateDef');

/**
 * This will be dispatched by other actions and not the user directly
 */
const saveToUserConfiguration = (stateDef) => {
  return async (dispatch, getState) => {
    try {

      await dispatch(saveToUserConfigurationStarting(stateDef));

      const userId = getState().app.user.userId;
       // CLAIM: All users should have a Fleet Dashboard configuration 
      const fleetDashboardConfig = { ...getState().app.user.dashboards[ComponentTypes.FLEET_DASHBOARD] };
      const componentState = getState()[stateDef.key];

      let configurationToSave = _.find(fleetDashboardConfig.views.views, ['id', stateDef.key]);
      if (!_.isNil(configurationToSave)) {
        configurationToSave.config.selectedTimeFrame = componentState.selectedTimeFrame;
        configurationToSave.config.selectedSensor = componentState.selectedSensor;
        configurationToSave.config.selectedSensors = componentState.selectedSensors;
        configurationToSave.config.selectedTruckFilter = componentState.selectedTruckFilter;
      } else {
        configurationToSave = {
          selectedTimeFrame: componentState.selectedTimeFrame,
          selectedSensor: componentState.selectedSensor,
          selectedSensors: componentState.selectedSensors,
          selectedTruckFilter: componentState.selectedTruckFilter,
        }
        const liveView = {
          id: stateDef.key,
          type: ComponentTypes.SYSTEM_VIEW,
          allowCustomization: false,
          config: configurationToSave
        }
        fleetDashboardConfig.views.views.push(liveView);
      }

      // Setup the input object for the mutation
      let input = 
      {
        userId: userId,
        name: ComponentTypes.FLEET_DASHBOARD,
        config: JSON.stringify(fleetDashboardConfig.views)
      }

      let queryResults = await liveViewQueries.fetchSaveUserDashboard(input);

      await dispatch(saveToUserConfigurationSuccess(stateDef, queryResults));

    } catch (e) {
      await dispatch(saveToUserConfigurationError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LIVE_VIEW_SAVING_CONFIGURATION, e.message))
    }
  }
}

const onRollover = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_ON_ROLLOVER, 'stateDef', 'xValue', 'xAxis', 'yValue', 'contextId');
const onCloseGaps = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_CONFIGURE_CLOSE_GAPS, 'stateDef', 'closeGaps');
const onToggleLegend = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_TOGGLE_LEGEND, 'stateDef');

const setFleetNameForDefinition = defaultActionFactory(liveViewActionTypes.LIVE_VIEW_DEFINITION_SET_FLEET_NAME, 'stateDef', 'fleetName');

export {
  queryTrucks,
  setTimeFrame,
  setTruckFilter,
  openSensorSelector,
  setSelectedSensors,
  querySensorForTrucks,
  setSensorForDefinition,
  toggleContextVisibility,
  setConfigureChart,
  setContextColor,
  selectConfigTab,
  addDataRuleForSensor,
  removeDataRuleForSensor,
  dataRuleSetColorPickerState,
  moveSensor,
  setSensorDisplayName,
  setSensorUOM,
  dataRuleSetProperty,
  setFullScreenOption,
  highlightTruck,
  xAxisVisibleRangeChanged,
  yAxisVisibleRangeChanged,
  downloadData,
  createSubscription,
  toggleContextVisibilityAllOthers,
  toggleContextVisibilityOtherAfter,
  toggleLiveFeed,
  setDataDisplayModeOption,
  liveViewReset,
  closeSubscription,
  loadLiveViewConfiguration,
  onQuerySensorDataForTrucks,
  onRollover,
  onCloseGaps,
  onToggleLegend,
  setFleetNameForDefinition
}