const fetchAlarmCountData = (isFleet) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (isFleet) {
        resolve({
          fleetAlarmCount: simData.fleetAlarm
        })
      } else {
        resolve({
          unitAlarmCount: simData.pumpAlarm
        })
      }
    }, 1000)
  });
};

const simData = {
  fleetAlarm: {
    criticalAlarmCount: 50,
    warningAlarmCount: 25,
    infoAlarmCount: 12,
    name: 'Fleet 1'
  },

  pumpAlarm: {
    criticalAlarmCount: 75,
    warningAlarmCount: 32,
    infoAlarmCount: 16,
    name: 'Pump 1',
    activeAlarms: [
      {
        "truckId": 1348,
        "alarmKey": "621272:19",
        "raisedTimestamp": 1687150023,
        "acknowledgedTimestamp": null,
        "component": "EB07 Status Warning",
        "details": "USB Drive Is Missing or Malfunctioning",
        "displayName": "EB07 Status Warning",
        "event": "Raised",
        "severityValue": 100,
        "subType": "Custom",
        "type": "STW"
      },
      {
        "truckId": 1348,
        "alarmKey": "621272:52",
        "raisedTimestamp": 1687208285,
        "acknowledgedTimestamp": null,
        "component": "PWM_FAN_CONTROL",
        "details": "Engine fan constraint triggered",
        "displayName": "PWM_FAN_CONTROL Generic",
        "event": "Raised",
        "severityValue": 200,
        "subType": "Generic",
        "type": "STW"
      },
      {
        "truckId": 1348,
        "alarmKey": "621272:53",
        "raisedTimestamp": 1687208285,
        "acknowledgedTimestamp": null,
        "component": "Engine Air Cooler Temp High - Alarm",
        "details": "Engine Air Cooler Temp high warning. Fan set to 100%",
        "displayName": "Engine Air Cooler Temp High - Alarm",
        "event": "Raised",
        "severityValue": 400,
        "subType": "Custom",
        "type": "STW"
      }
    ]
  }
};

export {
  fetchAlarmCountData, simData
}
