import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class AlarmInfo extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M0.681,10.594l9.913,-9.913c0.777,-0.777 2.035,-0.777 2.812,0l9.913,9.913c0.777,0.777 0.777,2.035 0,2.812l-9.913,9.913c-0.777,0.777 -2.035,0.777 -2.812,0l-9.913,-9.913c-0.777,-0.777 -0.777,-2.035 0,-2.812Zm12.737,7.75l0,-9.611l-2.74,0l0,9.611l2.74,0Zm0,-11.574l0,-1.891l-2.74,0l0,1.891l2.74,0Z" style={{fillRule:'evenodd'}}/>
      </SvgIcon>
    )
  }
}

export default AlarmInfo;
