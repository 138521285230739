import _ from 'lodash';
import moment from 'moment';
import alarmsActionTypes from '../alarmsActionTypes';
import * as alarmsService from '../services/alarmsService';
import * as filterService from '../../../common/filtering/filterService';
import { alarmsState } from '../alarmsSelectors';

const initialState = alarmsState();

const displayReducer = (state, action) => {

  switch (action.type) {

    case alarmsActionTypes.ALARMS_SHOW_ACTIVE:
      return onShowActiveAlarms(state, action);

    case alarmsActionTypes.ALARMS_SHOW_HISTORY:
      return onShowAlarmHistory(state, action);

    case alarmsActionTypes.ALARMS_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);

    case alarmsActionTypes.ALARMS_SET_CUSTOM_START_TIME:
      return onSetStartTime(state, action);

    case alarmsActionTypes.ALARMS_SET_CUSTOM_DURATION:
      return onSetDuration(state, action);

    case alarmsActionTypes.ALARMS_HANDLE_NAVIGATION_CONTEXT:
      return onHandleNavigationContext(state, action);

    case alarmsActionTypes.ALARMS_SHOW_MESSAGE_ALARMS:
      return onShowMessageAlarms(state, action);

    case alarmsActionTypes.ALARMS_SET_CUSTOM_START_TIME_DISPLAY:
      return onSetStartTimeDisplay(state, action);

    default: return state;

  }
};

const onShowActiveAlarms = (state, action) => {

  // Resolve the sort context to ensure column sorting stays consistent across the two views.
  let sortContext = alarmsService.resolveSortContext(state.sortContext, true);

  return {
    ...state,
    showingActiveAlarms: true,
    sortContext: sortContext,
  }
};

const onShowAlarmHistory = (state, action) => {

  // Resolve the sort context to ensure column sorting stays consistent across the two views.
  let sortContext = alarmsService.resolveSortContext(state.sortContext, false);

  return {
    ...state,
    showingActiveAlarms: false,
    sortContext: sortContext,
  }
};

const onSetTimeFrame = (state, action) => {

  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    selectedCustomStartTime: initialState.selectedCustomStartTime,
    selectedCustomDuration: initialState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: initialState.selectedCustomStartTimeDisplay,
  };

  // If the time frame is set to custom, set the initial start time to the now.

  if (newState.selectedTimeFrame.label === 'Custom') {

    let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');

    newState = {
      ...newState,
      selectedCustomStartTime: defaultTime,
      selectedCustomStartTimeDisplay: defaultTime
    }
  }

  return newState;
};

const onSetStartTime = (state, action) => {

  return {
    ...state,
    selectedCustomStartTime: action.startTime,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onSetDuration = (state, action) => {

  return {
    ...state,
    selectedCustomDuration: action.duration
  }
};

const onHandleNavigationContext = (state, action) => {

  let newState = {...state};

  if (!_.isNil(action.navigationContext.fleetName)) {
    newState.appliedFilters = [];
    newState.appliedFilters.push(filterService.createPropertyFilterValue('Fleet', 'fleetName', action.navigationContext.fleetName));
  }

  if (!_.isNil(action.navigationContext.unitName)) {
    newState.appliedFilters = [];
    newState.appliedFilters.push(filterService.createPropertyFilterValue('Unit ID', 'unitName', action.navigationContext.unitName));
  }

  return newState;
};

const onShowMessageAlarms = (state, action) => {

  // NOTE: We track the exclusion of message alarms in the state instead of the inclusion so that the processing
  // of the alarms can default to processing all alarms. The caller must explicitly opt into excluding message
  // alarms.
  let excludeMessageAlarms = action.excludeMessageAlarms;

  let alarmDetails = alarmsService.processAlarms(state.alarms, state.appliedFilters, excludeMessageAlarms);

  return {
    ...state,
    excludeMessageAlarms: excludeMessageAlarms,
    filteredAlarms: alarmDetails.filteredAlarms,
    filters: alarmDetails.filters,
    currentPage: initialState.currentPage,
  }

};

const onSetStartTimeDisplay = (state, action) => {

  return {
    ...state,
    selectedCustomStartTimeDisplay: action.startTime
  }
}

export default displayReducer