import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, DialogContentText, Button } from '@mui/material';

import ComponentTypes from "../../../componentTypes";
import {providerState} from "../../../../state/displays/settings/selectors/providerSelectors";
import * as providerActions from "../../../../state/displays/settings/actions/providerActions";

class DeleteProviderDialog extends Component {

  render() {
    return (
      <Dialog open={this.props.showDialog} disableEscapeKeyDown>
        <DialogTitle>Delete Provider</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete the Provider with the following name?</DialogContentText>
          <Typography>{_.isNil(this.props.context) ? '' : this.props.context.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onShowDeleteProviderDialog(false, this.props.context)}} color='inherit'>Cancel</Button>
          <Button onClick={() => 
          { 
            this.props.onDeleteProvider(this.props.context.id);
            this.props.onShowDeleteProviderDialog(false, this.props.context);
          }} 
          color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {

  let settingsState = providerState(state.settings);

  return {
    showDialog: settingsState.provider.showDeleteProviderDialog,
    context: settingsState.provider.providerDialogContext,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowDeleteProviderDialog: (show, context) => { dispatch(providerActions.showDeleteProviderDialog(props.stateDef, show, context)); },
    onDeleteProvider: (id) => { dispatch(providerActions.deleteProviderSettings(props.stateDef, id)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DeleteProviderDialog));
