import _ from 'lodash';

const userMgmtState = state => {

  return {

    // Displayed user list and paging details
    users: _.isNil(state) ? [] : state.users,
    usersPerPage: _.isNil(state) ? 25 : state.usersPerPage,
    currentPage: _.isNil(state) ? 0 : state.currentPage,

    // Flag to indicate one of the user management remote calls is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    // Flags to indicate that the various forms should be displayed.
    openAddUserUI: _.isNil(state) ? false : state.openAddUserUI,
    openEditUserUI: _.isNil(state) ? false : state.openEditUserUI,
    openDeleteUserUI: _.isNil(state) ? false : state.openDeleteUserUI,
    // openContextMenuUI: _.isNil(state) ? false : state.openContextMenuUI,

    // Sort details for the user table
    sortDirection: _.isNil(state) ? 'asc' : state.sortDirection,
    sortContext: _.isNil(state) ? 'name' : state.sortContext,

    // State to hold the values for the user that is being manipulated by the UI
    // for usage in add/edit dialogs.
    workingUser: _.isNil(state) ? {
      id: _.isNil(state) ? '' : state.workingUser.id,
      name: _.isNil(state) ? '' : state.workingUser.name,
      email: _.isNil(state) ? '' : state.workingUser.email,
      confirmEmail: _.isNil(state) ? '' : state.workingUser.confirmEmail,
      isEnabled: _.isNil(state) ? true : state.workingUser.isEnabled,
      isAdmin: _.isNil(state) ? false : state.workingUser.isAdmin,
    } : state.workingUser,

    // Validation states for the working user.
    workingUserValidation: _.isNil(state) ? {
      hasErrors: _.isNil(state) ? false : state.workingUserValidation.hasErrors,
      hasErrorName: _.isNil(state) ? false : state.workingUserValidation.hasErrorName,
      hasErrorEmail: _.isNil(state) ? false : state.workingUserValidation.hasErrorEmail,
      hasErrorConfirmEmail: _.isNil(state) ? false : state.workingUserValidation.hasErrorConfirmEmail,
    } : state.workingUserValidation,

  };

};

export {
  userMgmtState
};