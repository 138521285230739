import * as wellsDisplaySimulation from './wellsDisplaySimulation';
import {performDisplayFetch} from "../../common/fetch";
import useSimulationData from "../../../helpers/simulationHelper";

const fetchWellsDisplayData = () => {
    if (useSimulationData('wellsDisplay')) {
        return wellsDisplaySimulation.fetchWellsDisplayData();
    }
    return performDisplayFetch(
        {
            'query': 'query wells { wells { id name apiNumber leaseName leaseNumber padName latitude longitude } }'
        }
    )
}

const fetchSaveWell = async (well) => {
    if(useSimulationData('wellsDisplay')) return; //avoid update of real data
    return performDisplayFetch(
        {
            'query': 'mutation saveWell($input:WellInput!) { saveWell(context: $input) { id name apiNumber leaseName leaseNumber padName latitude longitude } }',
            'variables': {
                'input': {
                    id: well.id,
                    name: well.name,
                    apiNumber: well.apiNumber,
                    leaseName: well.leaseName,
                    leaseNumber: well.leaseNumber,
                    padName: well.padName,
                    latitude: well.latitude,
                    longitude: well.longitude,
                }
            }
        });

};

const fetchDeleteWell = (wellId) => {
    if(useSimulationData('wellsDisplay')) {
      return wellsDisplaySimulation.fetchDeleteWell();
    } 
    return performDisplayFetch(
        {
            'query': 'mutation deleteWell($id: Long!) {  deleteWell(id:$id) { success message }}',
            'variables': {
                id: wellId
            }
        }
    );
};

export {
    fetchWellsDisplayData,
    fetchSaveWell,
    fetchDeleteWell
};