import React, { Component } from 'react';
import _ from 'lodash';

import { Typography, Box } from '@mui/material';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import unitChartOptions from './unitChartOptions';

import getCardStyles from '../../cardStyles';
import getLayoutStyles from '../../../controls/layout/layoutStyles';
import getTypographyStyles from '../../../common/styles/typographyStyles';

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,
  cardContainerReplace: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardHeaderLabel: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  chartAndLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: '10px'
  },
  resolution: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  resolutionLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  chartContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1,
    alignItems: 'center',
  },
  chart: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  chartAndResolution: {
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'row nowrap',
    flexGrow: 1,
  },
  latestValues: {
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    justifyContent: 'center',
  },
  latestValue: {
    display: 'flex',
    width: '203px',
    flexFlow: 'column nowrap',
    justifyContent: 'center'
  },
  latestValuesBuffer: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  latestValuesCenteredContent: {
    display: 'flex',
    width: '609px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  latestValuesLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  seriesLabel: {
    textAlign: 'center',
  },
  noDataHighChartsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    // This keeps the 6 Months portion the same height as when we have data and are showing a chart
    height: '180px',
  },
};

class UnitChart extends Component {

  render() {

    const { cardConfig } = this.props;

    const series1 = _.isNil(cardConfig) || _.isNil(cardConfig.series1) ? {} : cardConfig.series1;
    const series2 = _.isNil(cardConfig) || _.isNil(cardConfig.series2) ? {} : cardConfig.series2;
    const series3 = _.isNil(cardConfig) || _.isNil(cardConfig.series3) ? {} : cardConfig.series3;
    const series4 = _.isNil(cardConfig) || _.isNil(cardConfig.series4) ? {} : cardConfig.series4;

    const showSeries1Latest = !_.isEmpty(series1.data);
    const showSeries2Latest = !_.isEmpty(series2.data);
    const showSeries3Latest = !_.isEmpty(series3.data);
    const showSeries4Latest = !_.isEmpty(series4.data);

    const seriesData = _.filter([ series1, series2, series3, series4 ], series => { return !_.isEmpty(series.data) });
    const chartOptions = unitChartOptions(cardConfig.startTime, cardConfig.endTime, seriesData);
    
    const showChart = !_.isEmpty(series1.data) || !_.isEmpty(series2.data) || !_.isEmpty(series3.data) || !_.isEmpty(series4.data);
    const showResolution = !_.isNil(cardConfig) && !_.isNil(cardConfig.resolutionLabel);

    return (
        <Box sx={styles.cardContent}>
          <Box sx={styles.chartAndLabel}>
            {
              showChart === false &&
              <Box sx={styles.noDataHighChartsContainer}>
                <Typography sx={styles.noDataLabel} variant={'caption'}>No Data</Typography>
              </Box>
            }
            {
              showChart === true &&
              <Box sx={styles.chartContainer}>
                <Box sx={styles.chartAndResolution}>
                  {
                    showResolution === true &&
                    <Box sx={styles.resolution}>
                      <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>{cardConfig.resolutionLabel}</Typography>
                    </Box>
                  }
                  <Box sx={styles.chart}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
                  </Box>
                </Box>
              </Box>
            }
          </Box>
          <Box sx={styles.latestValues}>
            <Box sx={styles.latestValuesBuffer}>
              <Typography sx={styles.latestValuesLabel} variant={'subtitle1'}>Latest</Typography>
            </Box>
            <Box sx={styles.latestValuesCenteredContent}>
              {
                showSeries1Latest === true &&
                <Box sx={styles.latestValue}>
                  <Typography variant={'h4'} sx={{...styles.seriesLabel, color: cardConfig.series1.color}}>{series1.latest}</Typography>
                  <Typography variant={'subtitle1'} sx={{...styles.seriesLabel, color: cardConfig.series1.color}}>{series1.latestLabel}</Typography>
                </Box>
              }
              {
                // DOC - only the engine chart has a series4, so we put it in
                // the 2nd position to align with its related sensor and
                // maintain existing colors, but when we add a 4th sensor to
                // other charts then we need to revisit this ordering.
                showSeries4Latest === true &&
                <Box sx={styles.latestValue}>
                  <Typography variant={'h4'} sx={{...styles.seriesLabel, color: cardConfig.series4.color}}>{series4.latest}</Typography>
                  <Typography variant={'subtitle1'} sx={{...styles.seriesLabel, color: cardConfig.series4.color}}>{series4.latestLabel}</Typography>
                </Box>
              }
              {
                showSeries2Latest === true &&
                <Box sx={styles.latestValue}>
                  <Typography variant={'h4'} sx={{...styles.seriesLabel, color: cardConfig.series2.color}}>{series2.latest}</Typography>
                  <Typography variant={'subtitle1'} sx={{...styles.seriesLabel, color: cardConfig.series2.color}}>{series2.latestLabel}</Typography>
                </Box>
              }
              {
                showSeries3Latest === true &&
                <Box sx={styles.latestValue}>
                  <Typography variant={'h4'} sx={{...styles.seriesLabel, color: cardConfig.series3.color}}>{series3.latest}</Typography>
                  <Typography variant={'subtitle1'} sx={{...styles.seriesLabel, color: cardConfig.series3.color}}>{series3.latestLabel}</Typography>
                </Box>
              }
            </Box>
            <Box sx={styles.latestValuesBuffer}/>
          </Box>
        </Box>
    )
  }
}

export default UnitChart;

