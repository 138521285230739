import _ from "lodash";

let testWarmStart = { avgFuelCost: 99 } ;

const fetchWarmStartSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ownerWarmStartSetting: 
        {
          avgFuelCost: testWarmStart.avgFuelCost,
        }
      })
    }, 1000)  
  });
};

const fetchTruckWarmStartSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        truckWarmStartSetting: [
          {
            "truckId": 1489,
            "truckName": "CATCAT435STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4828,
            "truckName": "Cummins",
            "truckEngineModel": "Cummins QSK50",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344245,
            "truckName": "Metric1",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344185,
            "truckName": "QSKCEC5",
            "truckEngineModel": "MTU 12V4000S81",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 5087,
            "truckName": "3512C TH55",
            "truckEngineModel": "CAT 3512C",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 5277,
            "truckName": "CATCAT4530STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1487,
            "truckName": "CATCAT433STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1081,
            "truckName": "CATCAT432STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 11118,
            "truckName": "682001",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1076,
            "truckName": "CATCATDBG431STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 2189,
            "truckName": "MTU Tier 2 Allison S9820",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 10828,
            "truckName": "ThirdParty",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1390,
            "truckName": "CATCAT4529STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1079,
            "truckName": "CATCAT431STW",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344223,
            "truckName": "M2",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4830,
            "truckName": "MTUTier1",
            "truckEngineModel": "MTU 12V4000S81",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344179,
            "truckName": "453STW",
            "truckEngineModel": "Cummins QSK50",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 11740,
            "truckName": "DD35 ",
            "truckEngineModel": "Turbine",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4829,
            "truckName": "MTU Tier 2",
            "truckEngineModel": "MTU 12V4000S81",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344500,
            "truckName": "Valor",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344189,
            "truckName": "MTUTDAC",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 11136,
            "truckName": "DD352",
            "truckEngineModel": "Turbine",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 2111,
            "truckName": "CATCAT452STW_dgb",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4831,
            "truckName": "CATCAT4524STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 12487,
            "truckName": "CATCAT4550STW",
            "truckEngineModel": "Electric E50",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 2618,
            "truckName": "MTU12V4000S81-AllisonS9820M",
            "truckEngineModel": "",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344525,
            "truckName": "MPC2",
            "truckEngineModel": "CAT 3512E",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4832,
            "truckName": "CATCAT4526STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4835,
            "truckName": "CATCAT4527STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344215,
            "truckName": "MTUS98",
            "truckEngineModel": "MTU Tier 2",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4836,
            "truckName": "CATCAT4523STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344512,
            "truckName": "Valor2",
            "truckEngineModel": "MTU Tier 4f",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1344501,
            "truckName": "MTU1S9820MWS",
            "truckEngineModel": "MTU 12V4000S81",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 12488,
            "truckName": "E50",
            "truckEngineModel": "Electric E50",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 1282,
            "truckName": "CSVTruck2",
            "truckEngineModel": null,
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4833,
            "truckName": "CATCAT4522STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          },
          {
            "truckId": 4834,
            "truckName": "CATCAT4525STW",
            "truckEngineModel": "?",
            "avgIdelFuelConsumption": 25.0
          }
        ]
      })
    }, 1000)
  });
};

const fetchSaveWarmStartSettings = (averageFuelConsumption, averageFuelCost) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      testWarmStart.avgFuelCost = _.isNil(averageFuelCost) ? testWarmStart.avgFuelCost : averageFuelCost;

      resolve({
        result: true
      })
    }, 1000)
  });
};

export {
  fetchWarmStartSettings,
  fetchTruckWarmStartSettings,
  fetchSaveWarmStartSettings
}