import _ from 'lodash';

const tableState = state => {

  return {

    // Table paging details
    unitsPerPage: _.isNil(state) ? 25 : state.unitsPerPage,
    currentPage: _.isNil(state) ? 0 : state.currentPage,

    // Table sort details
    sortDirection: _.isNil(state) ? 'asc' : state.sortDirection,
    sortContext: _.isNil(state) ? 'name' : state.sortContext,

    // Secondary sort definitions.
    // Note that the user cannot select these, they are defined in the display state.
    //
    // Example: [{ context: 'severity', direction: 'asc' }]
    secondarySortDefinitions: _.isNil(state) ? [] : state.secondarySortDefinitions,
  };

};

export {
  tableState
};