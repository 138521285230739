import _ from 'lodash';
import fleetClientStatusActionTypes from './fleetClientStatusActionTypes';
import * as clientService from './services/clientService';
import { fleetClientStatusState } from './fleetClientStatusSelectors';

const initialState = fleetClientStatusState();

const fleetClientStatusReducer = (state=initialState, action) => {

  switch (action.type) {

    case fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_STARTING:
      return onQueryStarting(state, action);

    case fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_SUCCESS:
      return onQuerySuccess(state, action);

    case fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_ERROR:
      return onQueryError(state, action);

    case fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQuerySuccess = (state, action) => {

  let client = clientService.processClients(action.queryResults.fleetId, action.queryResults.clientStatus);

  return {
    ...state,
    queryRunning: false,
    status: _.isNil(client) ? initialState.status : client.status,
    isUploading: _.isNil(client) ? initialState.isUploading : client.isUploading,
    dataUploadStatus: _.isNil(client) ? initialState.dataUploadStatus : client.dataUploadStatus,
    datasourceStatus: _.isNil(client) ? initialState.datasourceStatus : client.datasourceStatus,
    description: _.isNil(client) ? initialState.description : client.description,
    sensorUploadDescription: _.isNil(client) ? initialState.sensorUploadDescription : client.sensorUploadDescription,
    sensorDataDescription: _.isNil(client) ? initialState.sensorDataDescription : client.sensorDataDescription,
    dataSourceAggregateStatusDescription: _.isNil(client) ? initialState.dataSourceAggregateStatusDescription : client.dataSourceAggregateStatusDescription,
  };
};

const onQueryError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    status: initialState.status,
    isUploading: initialState.isUploading,
    dataUploadStatus: initialState.dataUploadStatus,
    datasourceStatus : initialState.datasourceStatus,
    description: initialState.description,
    sensorUploadDescription: initialState.sensorUploadDescription,
    sensorDataDescription: initialState.sensorDataDescription,
    dataSourceAggregateStatusDescription: initialState.dataSourceAggregateStatusDescription,
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    status: initialState.status,
    isUploading: initialState.isUploading,
    dataUploadStatus: initialState.dataUploadStatus,
    datasourceStatus : initialState.datasourceStatus,
    description: initialState.description,
    sensorUploadDescription: initialState.sensorUploadDescription,
    sensorDataDescription: initialState.sensorDataDescription,
    dataSourceAggregateStatusDescription: initialState.dataSourceAggregateStatusDescription,
  };
};

export default fleetClientStatusReducer