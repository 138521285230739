import { performDisplayFetch } from "../../common/fetch";
import moment from "moment";

/**
 * Method to execute the remote mutation to trigger an export of sample data for a given truck, start/end time.
 */
const fetchExportByTruck = (truckId, startTime, endTime, timezone, sensors) => {

  // Convert startTime & endTime to UTC and output the string representation
  const startTimeString = moment.utc(startTime).format();
  const endTimeString = moment.utc(endTime).format();

  return performDisplayFetch({
    'query': 'mutation exportByTruckInfo($exportInput:ExportInput) { exportByTruck(exportInput:$exportInput) { exportId, complete, exportZipUrl }}',
    'variables': {
      'exportInput': {
        'truckId': truckId,
        'startTime': startTimeString,
        'endTime': endTimeString,
        'timezone': timezone,
        'sensors': sensors
      }
    }
  })
};

/**
 * Method to perform the remote query for the status of a given
 * export job.
 */
const fetchExportByTruckStatus = (exportId) => {
  return performDisplayFetch({
    'query': '{exportByTruckStatus(exportId: "{exportId}"){ exportId, complete, exportZipUrl }}'
      .replace('{exportId}', exportId)
  })
};

/**
 * Method to perform the remote query for a list of trucks
 */
const fetchTrucks = () => {
  return performDisplayFetch({
    'query': '{exportDetails{trucks{id truckName fleetId fleetName division region truckPid type }}}'
  })
};

const fetchTruckDetails = (truckId, time) => {

  return performDisplayFetch({
    'query': 'query truckExportDetails($truckExportDetailsInput: TruckExportDetailsInput!) { truckExportDetails(truckExportDetailsInput: $truckExportDetailsInput) { startTime endTime availabilityBlocks {startTime endTime}}}',
    'variables': {
      truckExportDetailsInput: {
        truckId: truckId,
        time: time,
      }
    }
  })

};

export {
  fetchTrucks,
  fetchExportByTruck,
  fetchExportByTruckStatus,
  fetchTruckDetails
}