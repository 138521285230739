export default {

  // Display actions
  USER_MGMT_QUERY_USERS_STARTING: 'USER_MGMT_QUERY_USERS_STARTING',
  USER_MGMT_QUERY_USERS_SUCCESS: 'USER_MGMT_QUERY_USERS_SUCCESS',
  USER_MGMT_QUERY_USERS_ERROR: 'USER_MGMT_QUERY_USERS_ERROR',
  USER_MGMT_SET_CURRENT_PAGE: 'USER_MGMT_SET_CURRENT_PAGE',
  USER_MGMT_SET_USERS_PER_PAGE: 'USER_MGMT_SET_USERS_PER_PAGE',
  USER_MGMT_OPEN_ADD_DIALOG: 'USER_MGMT_OPEN_ADD_DIALOG',

  // User add/edit dialog actions
  USER_MGMT_UPDATE_USER_STARTING: 'USER_MGMT_UPDATE_USER_STARTING',
  USER_MGMT_UPDATE_USER_SUCCESS: 'USER_MGMT_UPDATE_USER_SUCCESS',
  USER_MGMT_UPDATE_USER_ERROR: 'USER_MGMT_UPDATE_USER_ERROR',
  USER_MGMT_ADD_USER_STARTING: 'USER_MGMT_ADD_USER_STARTING',
  USER_MGMT_ADD_USER_SUCCESS: 'USER_MGMT_ADD_USER_SUCCESS',
  USER_MGMT_ADD_USER_ERROR: 'USER_MGMT_ADD_USER_ERROR',
  USER_MGMT_CLOSE_ADD_DIALOG: 'USER_MGMT_CLOSE_ADD_DIALOG',
  USER_MGMT_CLOSE_EDIT_DIALOG: 'USER_MGMT_CLOSE_EDIT_DIALOG',
  USER_MGMT_SET_WORKING_NAME: 'USER_MGMT_SET_WORKING_NAME',
  USER_MGMT_SET_WORKING_EMAIL: 'USER_MGMT_SET_WORKING_EMAIL',
  USER_MGMT_SET_WORKING_IS_ENABLED: 'USER_MGMT_SET_WORKING_IS_ENABLED',
  USER_MGMT_SET_WORKING_IS_ADMIN: 'USER_MGMT_SET_WORKING_IS_ADMIN',
  USER_MGMT_SET_WORKING_CONFIRM_EMAIL: 'USER_MGMT_SET_WORKING_CONFIRM_EMAIL',
  USER_MGMT_VALIDATE_WORKING: 'USER_MGMT_VALIDATE_WORKING',
  USER_MGMT_OPEN_EDIT_DIALOG: 'USER_MGMT_OPEN_EDIT_DIALOG',

  // Delete dialog actions
  USER_MGMT_OPEN_DELETE_CONFIRMATION: 'USER_MGMT_OPEN_DELETE_CONFIRMATION',
  USER_MGMT_DELETE_USER_STARTING: 'USER_MGMT_DELETE_USER_STARTING',
  USER_MGMT_DELETE_USER_SUCCESS: 'USER_MGMT_DELETE_USER_SUCCESS',
  USER_MGMT_DELETE_USER_ERROR: 'USER_MGMT_DELETE_USER_ERROR',
  USER_MGMT_CLOSE_DELETE_CONFIRMATION: 'USER_MGMT_CLOSE_DELETE_CONFIRMATION',

  // Set active state actions
  USER_MGMT_SET_ACTIVE_STARTING: 'USER_MGMT_SET_ACTIVE_STARTING',
  USER_MGMT_SET_ACTIVE_SUCCESS: 'USER_MGMT_SET_ACTIVE_SUCCESS',
  USER_MGMT_SET_ACTIVE_ERROR: 'USER_MGMT_SET_ACTIVE_ERROR',

};