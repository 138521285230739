import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import filterReducer from './reducers/filterReducer';
import tableReducer from './reducers/tableReducer';
import exportCsvReducer from '../../common/exportCsv/exportCsvReducer';

import { componentHoursState } from './componentHoursSelectors';

const initialState = componentHoursState();

const componentHoursReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    filterReducer,
    tableReducer,
    exportCsvReducer
  );

  return reducer(state, action);
};

export default componentHoursReducer
