import _ from 'lodash';
import { subscriptionsDisplayState } from './subscriptionsDisplaySelectors';
import subscriptionsDisplayActionTypes from './subscriptionsDisplayActionTypes';

const initialState = subscriptionsDisplayState();

const displayReducer = (state=initialState, action) => {

  switch (action.type) {

    case subscriptionsDisplayActionTypes.QUERY_DATA_STARTING: 
      return { ...state, queryRunning: true };
    case subscriptionsDisplayActionTypes.QUERY_DATA_ERROR: 
      return { ...state, queryRunning: false };
    case subscriptionsDisplayActionTypes.QUERY_DATA_SUCCESS: 
      return onQueryDataSuccess(state, action);

    case subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_STARTING:
      return { ...state, queryRunning: true };
    case subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_ERROR:
      return { ...state, queryRunning: false };
    case subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_SUCCESS:
      return onQueryDataForOwnerSuccess(state, action);

    case subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_STARTING: 
      return { ...state, queryRunning: true };
    case subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_ERROR: 
      return { ...state, queryRunning: false };
    case subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_SUCCESS: 
      return onSaveSubscriptionSuccess(state, action);
      
    case subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_STARTING: 
      return { ...state, queryRunning: true };
    case subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_ERROR: 
      return { ...state, queryRunning: false };
    case subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_SUCCESS: 
      return onDeleteSubscriptionSuccess(state, action);

    case subscriptionsDisplayActionTypes.SET_SUBSCRIPTION_ACTIVE:
      return onSetSubscriptionActive(state, action);

    case subscriptionsDisplayActionTypes.SHOW_DELETE_SUBSCRIPTION_DIALOG:
      return onShowDeleteSubscriptionDialog(state, action);

    case subscriptionsDisplayActionTypes.SHOW_ASSIGN_SUBSCRIPTION_DIALOG:
      return onShowAssignSubscriptionDialog(state, action);

    case subscriptionsDisplayActionTypes.SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);


    default:
      return state;
  }

};

const onQueryDataSuccess = (state, action) => {

  const subscriptions = action.queryResults.subscriptionsByCreator;

  return {
    ...state,
    queryRunning: false,
    subscriptions: subscriptions,
  }

}

const onQueryDataForOwnerSuccess = (state, action) => {

  const subscriptions = action.queryResults.subscriptionsForOwner;

  return {
    ...state,
    queryRunning: false,
    subscriptions: subscriptions,
  }

}

const onSaveSubscriptionSuccess = (state, action) => {

  const updatedSubscriptions = _.cloneDeep(state.subscriptions);

  // Update the saved subscription
  let foundIndex = _.findIndex(updatedSubscriptions, ['id', action.queryResults.saveSubscription.id]);
  if (!_.isNil(updatedSubscriptions[foundIndex])) {
    updatedSubscriptions[foundIndex] = action.queryResults.saveSubscription;
  } else {
    updatedSubscriptions.push( action.queryResults.saveSubscription);
  }

  return {
    ...state,
    queryRunning: false,
    subscriptions: updatedSubscriptions
  }

}

const onDeleteSubscriptionSuccess = (state, action) => {

  const updatedSubscriptions = _.cloneDeep(state.subscriptions);

  // Update the saved subscription
  _.remove(updatedSubscriptions, (x) => { return x.id === action.queryResults.subscriptionId; });

  return {
    ...state,
    queryRunning: false,

    subscriptions: updatedSubscriptions,
    subscription: null,
  }

}

const onSetSubscriptionActive = (state, action) => {

  const updatedSubscriptions = _.cloneDeep(state.subscriptions);

  const foundItem = _.find(updatedSubscriptions, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.active = action.active;
  }

  return {
    ...state,
    subscriptions: updatedSubscriptions
  }

}

const onShowDeleteSubscriptionDialog = (state, action) => {

  return {
    ...state,
    showDeleteSubscriptionDialog: action.show,
    subscription: action.subscription,
  }

}

const onShowAssignSubscriptionDialog = (state, action) => {

  return {
    ...state,
    showAssignSubscriptionDialog: action.show,
    subscription: action.subscription,
  }

}

const onDataGridSetRowSize = (state, action) => {
  
  return {
    ...state,
    rowsPerPage: action.rowSize,
  }

}

export default displayReducer;