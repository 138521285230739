import _ from 'lodash';
import providerDialogActionTypes from './providerDialogActionTypes';
import { providerDialogState } from './providerDialogSelectors';
import { resolveContextEntityForProviderDialogState } from './services/entityResolver';
import { evaluateCanSave, updateIdsAfterRemoval } from './services/providerDialogServices';
import providerTypes from '../../../../../components/common/providerTypes';

const initialState = providerDialogState();

const providerDialogReducer = (state=initialState, action) => {

  switch (action.type) {

    case providerDialogActionTypes.LOAD_CONTEXT_DATA_STARTING:
      return { ...state, queryRunning: true }
    case providerDialogActionTypes.LOAD_CONTEXT_DATA_ERROR:
      return { ...state, queryRunning: false }
    case providerDialogActionTypes.LOAD_CONTEXT_DATA_SUCCESS:
      return onLoadContextDataSuccess(state, action);
    case providerDialogActionTypes.SET_PROVIDER_NAME:
      return onSetProviderName(state, action);
    case providerDialogActionTypes.SELECT_PROVIDER_TYPE:
      return onSelectProviderType(state, action);
    case providerDialogActionTypes.SET_ACTIVE_STEP:
      return onProviderDialogSetActiveStep(state, action);
    case providerDialogActionTypes.ADD_TEAMS_CHANNEL:
      return onAddTeamsChannel(state, action);
    case providerDialogActionTypes.REMOVE_TEAMS_CHANNEL:
      return onRemoveTeamsChannel(state, action);
    case providerDialogActionTypes.SET_TEAMS_CHANNEL_NAME:
      return onSetTeamsChannelName(state, action);
    case providerDialogActionTypes.SET_TEAMS_CHANNEL_WEBHOOK:
      return onSetTeamsChannelWebhook(state, action);
    case providerDialogActionTypes.SET_TWILIO_ACCT_SID:
      return onSetTwilioAcctSid(state, action);
    case providerDialogActionTypes.SET_TWILIO_AUTH_TOKEN:
      return onSetTwilioAuthToken(state, action);
    case providerDialogActionTypes.SET_TWILIO_FROM_NUMBER:
      return onSetTwilioFromNumber(state, action);
    case providerDialogActionTypes.CLEAR_FORM:
      return onClearForm(state, action);
    default:
      return state;
  }

};


const onLoadContextDataSuccess = (state, action) => {

  const resolvedContext = resolveContextEntityForProviderDialogState(action.context);

  const newState = {
    ...state,
    ...resolvedContext,

    queryRunning: false
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

};


const onSetProviderName = (state, action) => {

  const newState = {
    ...state,
    providerName: action.value,
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}


const onSelectProviderType = (state, action) => {

  const newState = {
    ...state,
    providerType: action.value
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onProviderDialogSetActiveStep = (state, action) => {
  return {
    ...state,
    activeStep: action.step
  }
};


const onAddTeamsChannel = (state, action) => {

  const newTeamsChannelsCollection = _.cloneDeep(state.configs.teamsChannels);
  const id = state.configs.teamsChannels.length;
  newTeamsChannelsCollection.push(
    { 
      id: id,
      name: '',
      webhook: ''
    });


  const newState = {
    ...state,
    configs: {...state.configs,teamsChannels: newTeamsChannelsCollection}
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onRemoveTeamsChannel = (state, action) => {

  const currentTeamsChannels = _.cloneDeep(state.configs.teamsChannels);
  
  // Remove the item
  _.remove(currentTeamsChannels, (x) => { return x.id === action.id; });

  // Update the ids so they match their indexes
  updateIdsAfterRemoval(currentTeamsChannels);

  const newState = {
    ...state,
    configs : {...state.configs,teamsChannels: currentTeamsChannels}
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

};

const onSetTeamsChannelName = (state, action) => {

  const currentTeamsChannels = _.cloneDeep(state.configs.teamsChannels);
  const foundItem = _.find(currentTeamsChannels, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.name = action.value;
  }

  const newState = {
    ...state,
    configs: {...state.configs,teamsChannels: currentTeamsChannels}
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onSetTeamsChannelWebhook = (state, action) => {

  const currentTeamsChannels = _.cloneDeep(state.configs.teamsChannels);
  const foundItem = _.find(currentTeamsChannels, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.webhook = action.value;
  }

  const newState = {
    ...state,
    configs: {...state.configs, teamsChannels: currentTeamsChannels},
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onSetTwilioAcctSid = (state, action) => {

  const newState = {
    ...state,
    configs: {...state.configs, twilioAcctSid: action.value},
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onSetTwilioAuthToken = (state, action) => {

  const newState = {
    ...state,
    configs: {...state.configs, twilioAuthToken: action.value},
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onSetTwilioFromNumber = (state, action) => {

  const newState = {
    ...state,
    configs: {...state.configs, twilioFromNumber: action.value},
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onClearForm = (state, action) => {

  const newState = {
    ...state,
    providerName: '',
    //default as teams
    providerType: {label:"Microsoft Teams", value:providerTypes.TEAMS},
    configs: {
      teamsChannels:[],
      twilioAcctSid: '',
      twilioAuthToken: '',
      twilioFromNumber: '',
    },
    activeStep: 0,
  }

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

}


export default providerDialogReducer;