import _ from 'lodash';

const MAX_OFFSET_INPUT = 50000;
const MIN_OFFSET_INPUT = -50000;
const MAX_NUMERIC_INPUT = 50000;
const MIN_NUMERIC_INPUT = 0;

const resolveValidationState = (state) => {
  return {
    canSave: resolveCanSave(state, true),
    canAdd: resolveCanAdd(state, true),
    canDelete: resolveCanDelete(state),
    canCancel: resolveCanCancel(state),
    canEditSnapshotStart: resolveCanEditSnapshotStart(state),
    hasErrorUnitOffset: !isNumericInputValid(state.selectedUnitOffset, MAX_OFFSET_INPUT, MIN_OFFSET_INPUT),
    hasErrorSnapshotStart: !isNumericInputValid(state.selectedSnapshotStart, MAX_NUMERIC_INPUT, MIN_NUMERIC_INPUT),
    hasErrorCalculatorInput: !isNumericInputValid(state.calculatorInput, MAX_NUMERIC_INPUT, MIN_NUMERIC_INPUT),
  }
};

const resolveCanSave = (state, checkForValidValues) => {

  // Make sure we are in edit mode

  if (!state.editMode) {
    return false;
  }

  // Check to see if the state and current component are the same

  if (areStateAndComponentEqual(state)) {
    return false;
  }

  // Finally check to see if the form values are valid

  if (checkForValidValues === true) {

    if (!isNumericInputValid(state.selectedUnitOffset, MAX_OFFSET_INPUT, MIN_OFFSET_INPUT)) {
      return false;
    }

  }

  return true;
};

const resolveCanAdd = (state, checkForValidValues) => {

  // Make sure we are not in edit mode

  if (state.editMode) {
    return false;
  }

  // Make sure there are values in the form fields.

  if (_.isNil(state.selectedEffectiveDate)) {
    return false;
  }

  // Finally check to see if the form values are valid

  if (checkForValidValues === true) {

    if (!isNumericInputValid(state.selectedUnitOffset, MAX_OFFSET_INPUT, MIN_OFFSET_INPUT)) {
      return false;
    }

    if (!isNumericInputValid(state.selectedSnapshotStart, MAX_NUMERIC_INPUT, MIN_NUMERIC_INPUT)) {
      return false;
    }

  }

  return true;

};

const resolveCanDelete = (state) => {
  // As long as we have a component Id we can delete it.
  return !_.isNil(state.selectedComponentId);
};

const resolveCanCancel = (state) => {

  // This check is essentially the same as the ones for saving and adding, but we don't care
  // about making sure the input values are valid.

  if (state.editMode) {
    return resolveCanSave(state, false);
  }
  return resolveCanAdd(state, false);
};

const resolveCanEditSnapshotStart = (state) => {

  // If the effective date for the selected component is earlier than the earliest data date for the selected
  // component type then we need to enter the snapshot start value

  let result = false;
  // if (!_.isNil(state.selectedEffectiveDate) && !_.isNil(state.selectedComponentType) && !_.isNil(state.selectedComponentType.earliestDataMoment)) {
  if (!_.isNil(state.selectedEffectiveDate) && !_.isNil(state.selectedComponentType)) {
    // We need to allow editing of the snapshot start value if there is no earliest data date
    result = _.isNil(state.selectedComponentType.earliestDataMoment) || state.selectedEffectiveDate.isBefore(state.selectedComponentType.earliestDataMoment);
  }

  return result;
};

const isNumericInputValid = (input, max, min) => {
  return !_.isNil(input) &&
    _.isInteger(input) &&
    input <= max &&
    input >= min;
};

const areStateAndComponentEqual = (state) => {

  // Make sure we have a valid component selected

  if (_.isNil(state.selectedComponent)) {
    return false;
  }

  // Now just check to see if any of the state values are different from the current component

  if (_.isNil(state.selectedComponent.effectiveDateMoment) || !state.selectedComponent.effectiveDateMoment.isSame(state.selectedEffectiveDate)) {
    return false;
  }

  if (!_.isEqual(state.selectedComponent.model, state.selectedModel)) {
    return false;
  }

  if (!_.isEqual(state.selectedComponent.serialNumber, state.selectedSerialNumber)) {
    return false;
  }

  if (!_.isEqual(state.selectedComponent.referenceNumber, state.selectedRefNumber)) {
    return false;
  }

  if (!_.isEqual(state.selectedComponent.unitOffset, state.selectedUnitOffset)) {
    return false;
  }

  return true;
};

export {
  resolveValidationState,
  resolveCanSave,
  resolveCanAdd,
  resolveCanDelete,
  resolveCanCancel,
  resolveCanEditSnapshotStart,
  isNumericInputValid,
  areStateAndComponentEqual,
}