import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import PropTypes from "prop-types";

import { Typography, Box, Skeleton, ButtonBase, Tooltip, Divider, Button } from '@mui/material';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {EAxisAlignment} from 'scichart/types/AxisAlignment';

import { AutoCompleteMDT } from '../../../controls/mdtMuiControls';
import ComponentTypes from '../../../componentTypes';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes} from "../../mdtCard/mdtCard";
import getCardStyles from '../../cardStyles';
import getTypographyStyles from '../../../common/styles/typographyStyles';

import { CardChart, X_AXIS_IDS } from '../../../controls/charts/cardChart/cardChart';
import CardChartAxisIndicator from '../../../controls/charts/cardChart/cardChartAxisIndicator';
import { MdtYAxisPosition } from '../../../../state/common/dataExplorationChart/mdtYAxisPosition';
import { jobStageChartState } from '../../../../state/cards/job/stageChart/jobStageChartSelectors';
import * as jobStageChartActions from '../../../../state/cards/job/stageChart/jobStageChartActions';

import takeScreenShot from '../../../../helpers/screenshotHelper'
import * as appErrorActions from '../../../../state/app/actions/appErrorActions';

const cardStyles = getCardStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  noDataContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70%'
  },
  noDataLabel: {
    ...typographyStyles.noDataLabel
  },
  legend: {
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'row wrap',
    flexGrow: 1,
    justifyContent: 'center',
    height: '5.5%'
  },

  seriesLabel: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    fontSize: '0.6rem',
    fontWeight: '450',
  },
  seriesValue: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    fontSize: '0.7rem',
    fontWeight: '450',
  },
  axisIndicator: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  availableStagesAutoComplete: {
    width: '400px',
    paddingLeft: '10px',
    borderBottomWidth: '0px',
  },
  headerDivider: {
    height: '80%',
    marginRight: '12px'
  },
  dottedLine: {
    overflow: 'hidden',
    width: '50px',
    display: 'flex',
    height: '10px',
    alignItems: 'center'
  },
  legendValueContainer: {
    display: 'flex', 
    flexFlow: 'row nowrap', 
    width: '100px', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  legendItem: {
    display: 'flex', 
    flexFlow: 'column nowrap', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexGrow: 1,
    marginLeft: 1,
    marginRight: 1
  }
}

class JobStageChart extends MDTCard {

  getName(){
    return this.props.context.title;
  }

  // This isn't really used for this card at the moment since it exists on its own view and is outside of the customizable dashboard domain
  isContextReady(){
    return true;
  }

  constructor(props) {
    super(props);
  }

  queryPrimaryDefinitionData() {
    this.props.onQueryData(this.props.definition?.primary.defaultContext, this.props.definition?.primary.timeRange.startTime, this.props.definition?.primary.timeRange.endTime, 
      this.props.definition?.primary.sensors, this.props.cardQueryRunningCallback);
  }

  querySecondaryDefinitionData() {
    this.props.onQueryData(this.props.definition?.secondary.defaultContext, this.props.definition?.secondary.timeRange.startTime, this.props.definition?.secondary.timeRange.endTime, 
      this.props.definition?.secondary.sensors, this.props.cardQueryRunningCallback);
  }

  componentDidMount() {

    // Initialize the chart definition with stage, time range and sensors
    if (!_.isNil(this.props.context.stage)) {
      this.props.onSetPrimaryDefinitionStage(this.props.context.stage);
    }
    if (!_.isNil(this.props.context.stage.stageStart) && !_.isNil(this.props.context.stage.stageEnd)) {
      this.props.onSetPrimaryDefinitionTimeRange(this.props.context.stage.stageStart, this.props.context.stage.stageEnd);
    }

    // Introduce a tiny delay before calling the query
    // When switching views, it's possible the onSetPrimaryDefinitionStage or onSetPrimaryDefinitionTimeRange does not complete its lifecycle before the call to 
    // the query happens so the card will mount and the query call will execute before we setup stage or sensors
    setTimeout(() => { this.queryPrimaryDefinitionData()}, 25);
    
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
  }

  removeCard() {
    if (!_.isNil(this.props.removeCardCallback)) {
      this.props.removeCardCallback(this.props.stateKey);
    }
  }

  setupSecondaryDefinition(stage) {
    if (!_.isNil(stage)) {
      this.props.onSetSecondaryDefinitionStage(stage);
    }
    if (!_.isNil(stage.stageStart) && !_.isNil(stage.stageEnd)) {
      this.props.onSetSecondaryDefinitionTimeRange(stage.stageStart, stage.stageEnd);
    }

    // Introduce a tiny delay before calling the query
    // When switching views, it's possible the onSetSecondaryDefinitionStage or onSetSecondaryDefinitionTimeRange does not complete its lifecycle before the call to 
    // the query happens so the card will mount and the query call will execute before we setup stage or sensors
    setTimeout(() => { this.querySecondaryDefinitionData()}, 25);
  }

  filteredStagesForCompare() {
    if (!_.isNil(this.props.definition.primary.defaultContext)) {
      return _.filter(this.props.availableStages, (stage) => {
        return stage.id !== this.props.definition.primary.defaultContext.stage.id;
      });    
    } else {
      return this.props.availableStages;
    }
  }

  getRenderedButtonsInHeader() {
    return (
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, position: 'relative', top: '-2px', alignItems: 'center'}}>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: 2}}>
            <Divider sx={styles.headerDivider} orientation='vertical'/>
            <Typography variant='subtitle1'>Compare To: </Typography>
            <AutoCompleteMDT 
              sx={styles.availableStagesAutoComplete}
              options={this.filteredStagesForCompare()}
              value={_.isNil(this.props.definition.secondary.defaultContext) ? null : this.props.definition.secondary.defaultContext.stage}
              onChange={(event, value, reason) => {
                if (!_.isNil(value)) {
                  this.setupSecondaryDefinition(value);
                }
              }}
              noOptionsText={'No Stages found...'}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                );
              }}
            />
            <Box sx={{marginLeft: 1}}>
              <Button variant='text' size='small' onClick={() => { this.props.onClearCompare() }} disabled={_.isNil(this.props.definition.secondary.defaultContext)}>Clear Compare</Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
          <Tooltip disableInteractive title={"Take Screenshot (w/ 2 sec delay)"}>
            <Box>
              <ButtonBase focusRipple
                sx={styles.cardActionButton}
                onClick={() => setTimeout(() => { 
                  takeScreenShot(this.props.stateDef.key);
                  this.props.signalScreenshotTaken();
                }, 2000)}>
                <ScreenshotIcon/>  
              </ButtonBase>
            </Box>
          </Tooltip>
          <Tooltip disableInteractive title={"Remove Card"}>
            <Box>
              <ButtonBase focusRipple
                sx={styles.cardActionButton}
                onClick={() => { this.removeCard() }}>
                <ClearIcon/>
              </ButtonBase>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    )
  }

  renderLineStyles = (line, color) => {
    const lineLength = line[0];
    const gapLength = line[1];
    return ({
      dottedBg: {
        backgroundColor: 'transparent',
        height: '2px',
        width: gapLength + 'px',
        minWidth: gapLength + 'px',
      },
      dottedFill: {
        backgroundColor: !_.isNil(color) ? color : '#FFF',
        height: '2px',
        width: lineLength + 'px',
        minWidth: lineLength + 'px',
      }
    });
  } 

  getRenderedContent() {
    return (
      <Box id={"screenshot-area@" + this.props.stateDef.key} sx={{...styles.cardContent, backgroundColor: styles.cardContainer.backgroundColor}}>
        {
          !_.isNil(this.props.definition.secondary.defaultContext) &&
          <Box sx={styles.legend}>
            {
              this.props.definition.secondary.sensors.map((sensor, index) => {
                return (
                  <Tooltip key={index} sx={styles.legendItem} disableInteractive title={(sensor.isVisible === false ? 'Show' : 'Hide' )}>
                    <ButtonBase focusRipple 
                      onClick={() => { this.props.onToggleSensorVisibility(X_AXIS_IDS[1], sensor.sensorSetId)}}>
                      {
                        sensor.isVisible === false &&
                        <VisibilityOffIcon sx={{position: 'absolute', backdropFilter: 'blur(0.65px)', height: '100%', width: '100%'}}/>
                      } 
                      <Box>
                        <Typography variant='caption' sx={styles.seriesLabel}>{sensor.displayName} ({sensor.uom})</Typography>
                        <Box sx={styles.legendValueContainer}>
                          <Box sx={styles.dottedLine}>
                              {_.times(_.ceil(100 / (sensor.lineStyle.value[0] + sensor.lineStyle.value[1])), (lineIndex) => (
                                  <Box sx={{ display: 'flex' }} key={lineIndex}>
                                      <Box sx={this.renderLineStyles(sensor.lineStyle.value, sensor.color).dottedFill}></Box>
                                      <Box sx={this.renderLineStyles(sensor.lineStyle.value, sensor.color).dottedBg}></Box>
                                  </Box>
                              ))
                              }
                          </Box>
                          <Typography variant='caption' sx={styles.seriesValue}>{(_.isNil(sensor.value) || Number.isNaN(sensor.value) ? '-' : Number(sensor.value).toFixed(2))}</Typography>
                        </Box>
                      </Box>
                    </ButtonBase>
                  </Tooltip>
                )
              })
            }
          </Box>
        }
        {
          (
            this.props.queryRunning === true ?
            <Skeleton variant='rounded' sx={{ height: (!_.isNil(this.props.definition.secondary.defaultContext) ? '85%' : '91%' ), display: 'flex', flexFlow: 'row nowrap', margin: 1, backgroundColor: 'rgba(35,35,35,0.4)' }}/>
            : 
            <Box sx={{ height: (!_.isNil(this.props.definition.secondary.defaultContext) ? '88%' : '94%' ) , display: 'flex', flexFlow: 'row nowrap' }}>
              <Box sx={{ ...styles.axisIndicator, marginLeft: 1 }}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.LeftOuter} definition={this.props.definition} reversed={true}/>
              </Box>
              <Box sx={{...styles.axisIndicator, marginRight: '5px'}}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.LeftInner} definition={this.props.definition} reversed={true}/>
              </Box>
              <CardChart
                chartId={this.props.stateKey}
                chartContainerStyle={{ height: 'calc(100%)', display: 'flex', flexFlow: 'row nowrap', flexGrow: 1 }}
                primaryDefinition={this.props.definition.primary}
                secondaryDefinition={this.props.definition.secondary}
                
                primaryXValues={this.props.primaryXValues}
                primaryYValues={this.props.primaryYValues}

                secondaryXValues={this.props.secondaryXValues}
                secondaryYValues={this.props.secondaryYValues}

                onRollOver={this.props.onRollOver}
                showTooltips={false}

                primaryZeroRanges={true}
                secondaryZeroRanges={true}

                primaryXAxisPlacement={EAxisAlignment.Bottom}
                secondaryXAxisPlacement={EAxisAlignment.Top}
              />
              <Box sx={{...styles.axisIndicator, marginLeft: '5px'}}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.RightInner} definition={this.props.definition} reversed={true}/>
              </Box>
              <Box sx={{ ...styles.axisIndicator, marginRight: 1 }}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.RightOuter} definition={this.props.definition} reversed={true}/>
              </Box>
            </Box>
          )
        }
        <Box sx={styles.legend}>
          {
            this.props.definition.primary.sensors.map((sensor, index) => {
              return (
                <Tooltip key={index} sx={styles.legendItem} disableInteractive title={(sensor.isVisible === false ? 'Show' : 'Hide' )}>
                  <ButtonBase focusRipple 
                    onClick={() => { this.props.onToggleSensorVisibility(X_AXIS_IDS[0], sensor.sensorSetId)}}>
                    {
                      sensor.isVisible === false &&
                      <VisibilityOffIcon sx={{position: 'absolute', backdropFilter: 'blur(0.65px)', height: '100%', width: '100%'}}/>
                    } 
                    <Box>
                      <Typography variant='caption' sx={styles.seriesLabel}>{sensor.displayName} ({sensor.uom})</Typography>
                      <Box sx={styles.legendValueContainer}>
                        <Box sx={styles.dottedLine}>
                            {_.times(_.ceil(100 / (sensor.lineStyle.value[0] + sensor.lineStyle.value[1])), (lineIndex) => (
                                <Box sx={{ display: 'flex' }} key={lineIndex}>
                                    <Box sx={this.renderLineStyles(sensor.lineStyle.value, sensor.color).dottedFill}></Box>
                                    <Box sx={this.renderLineStyles(sensor.lineStyle.value, sensor.color).dottedBg}></Box>
                                </Box>
                            ))
                            }
                        </Box>
                        <Typography variant='caption' sx={styles.seriesValue}>{(_.isNil(sensor.value) || Number.isNaN(sensor.value) ? '-' : Number(sensor.value).toFixed(2))}</Typography>
                      </Box>
                    </Box>
                  </ButtonBase>
                </Tooltip>

              )
            })
          }
        </Box>
      </Box>
    )
  }
}

JobStageChart.propTypes = {
  ...mdtCardPropTypes,
  removeCardCallback: PropTypes.func,
  availableStages: PropTypes.arrayOf(PropTypes.object),
  stateKey: PropTypes.string.isRequired
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_STAGE_CHART : props.stateKey,
      type: ComponentTypes.JOB_STAGE_CHART,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobStageChartState(state[stateDef.key]);
  return {
    definition: componentState.definition,
    queryRunning: componentState.queryRunning,

    primaryXValues: componentState.primaryXValues,
    primaryYValues: componentState.primaryYValues,
    secondaryXValues: componentState.secondaryXValues,
    secondaryYValues: componentState.secondaryYValues
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    onQueryData: (stage, startTime, endTime, sensors, cardQueryRunningCallback) => { dispatch(jobStageChartActions.queryData(props.stateDef, stage, startTime, endTime, sensors, cardQueryRunningCallback))},

    onSetPrimaryDefinitionStage: (stage) => { dispatch(jobStageChartActions.setPrimaryDefinitionStage(props.stateDef, stage)); },
    onSetPrimaryDefinitionTimeRange: (startTime, endTime) => { dispatch(jobStageChartActions.setPrimaryDefinitionTimeRange(props.stateDef, startTime, endTime)); },

    onSetSecondaryDefinitionStage: (stage) => { dispatch(jobStageChartActions.setSecondaryDefinitionStage(props.stateDef, stage)); },
    onSetSecondaryDefinitionTimeRange: (startTime, endTime) => { dispatch(jobStageChartActions.setSecondaryDefinitionTimeRange(props.stateDef, startTime, endTime)); },

    onClearCompare: () => { dispatch(jobStageChartActions.clearCompare(props.stateDef)); },

    // If we don't throttle, there will be too many calls when the mouse moves over the chart and thus too many render calls
    // Throttling will ensure that the calls are made at most every 250ms and works well with both x axes
    // Debounce works well with one x axis but with two, it can miss callbacks for one axis entirely so the values don't update on the legend when rolled over
    // see https://css-tricks.com/debouncing-throttling-explained-examples/
    onRollOver: _.throttle((xValue, xAxis, yValue, contextId) => { dispatch(jobStageChartActions.onRollover(props.stateDef, xValue, xAxis, null, null)); }, 250),

    signalScreenshotTaken: () => { dispatch(appErrorActions.displayError('Screenshot of chart taken and saved to clipboard')); },
    onToggleSensorVisibility: (xAxis, sensorSetId) => { dispatch(jobStageChartActions.toggleSensorVisibility(props.stateDef, xAxis, sensorSetId)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(JobStageChart));
