import React, {Component} from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
  labelsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '96px',
    height: '32px'
  },

  trippedStyle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#EA3A40',
    fontSize: '16px'
  },

  idleStyle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#E1AB09',
    fontSize: '16px'
  },

  stoppedStyle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px'
  },

  valueStyle: {
    fontSize: '24px',
    fontWeight: 500
  }
};

class Rate extends Component {

  render() {
    return (
      <div>
      {
        (this.props.tripped === true) &&
        <Box sx={styles.labelsContainer}>
          <Typography variant={'caption'} sx={styles.trippedStyle}>Tripped</Typography>
        </Box>
      }
      {
        (this.props.idle === true) &&
        <Box sx={styles.labelsContainer}>
          <Typography variant={'caption'} sx={styles.idleStyle}>Idle</Typography>
        </Box>
      }
      {
        (this.props.stopped === true) &&
        <Box sx={styles.labelsContainer}>
          <Typography variant={'caption'} sx={styles.stoppedStyle}>Stopped</Typography>
        </Box>
      }
      {
        (!this.props.tripped || this.props.tripped === false) && (!this.props.idle || this.props.idle === false) && (!this.props.stopped || this.props.stopped === false) && (this.props.value) &&
        <Box sx={styles.labelsContainer}>
          <Typography variant={'caption'}>{this.props.label}</Typography>
          <Typography variant={'subtitle1'} sx={styles.valueStyle}>{this.props.value}</Typography>
        </Box>
      }
      </div>
    );
  }
}

Rate.propTypes ={
  tripped: PropTypes.bool,
  idle: PropTypes.bool.isRequired,
  stopped: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Rate;