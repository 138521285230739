import _ from 'lodash';

import appActionTypes from "../appActionTypes";
import { appState } from "../appSelectors";

const initialState = appState();

const websocketReducer = (state = initialState, action) => {
  switch(action.type) {
    case appActionTypes.APP_SET_WEBSOCKET_INTERVAL_ID:
      return onSetWebsocketIntervalId(state, action);
    case appActionTypes.APP_SAVE_WEBSOCKET_CLIENTID:
      return onSaveWebSocketClientId(state, action);
    case appActionTypes.APP_UPDATE_WEBSOCKET_CONNECTION_STATE:
      return onUpdateWebsocketConnectionState(state, action);
    case appActionTypes.APP_WEBSOCKET_SAVE_SUBSCRIPTION:
      return onSaveSubscription(state, action);
    default: 
      return state;
  }
}

const onSaveWebSocketClientId = (state, action) => {

  return {
    ...state,
    live: {
      ...state.live,
      clientId: action.clientId,
      connected: true
    }
  };

}

const onSetWebsocketIntervalId = (state, action) => {
  return {
    ...state,
    live: {
      ...state.live,
      intervalId: !_.isNil(action.intervalId) ? action.intervalId : state.live.intervalId
    }
  };
}

const onUpdateWebsocketConnectionState = (state, action) => {

  const newState = {
    ...state,
    live: {
      ...state.live,
      connected: action.status
    }
  };

  if (action.status === false) {
    newState.live.subscriptions = [];
  }

  return newState;
}

const onSaveSubscription = (state, action) => {
  const newSubscriptions = _.cloneDeep(state.live.subscriptions);
  // The subscription should be a stringified JSON object
  if (!_.isEmpty(action.subscription)) {
    newSubscriptions.push(action.subscription);
  }
  return {
    ...state,
    live: {
      ...state.live,
      subscriptions: newSubscriptions
    }
  };
}

export default websocketReducer;