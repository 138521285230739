import { handleError } from '../../app/actions/appErrorActions';
import defaultActionFactory from './defaultActionFactory';

const queryActionFactory = (startingType, successType, errorType, errorMessage, query) => {

  const startingAction = defaultActionFactory(startingType, 'stateDef');
  const successAction = defaultActionFactory(successType, 'stateDef', 'queryResults');
  const errorAction = defaultActionFactory(errorType, 'stateDef');

  return function (stateDef, ...queryParams) {
    return async (dispatch, getState) => {
      let queryResults = null;
      try {
        await dispatch(startingAction(stateDef));
        queryResults = await query(...queryParams);
      } catch(e) {
        await dispatch(errorAction(stateDef));
        return dispatch(handleError(errorMessage, e.message));
      }
      return dispatch(successAction(stateDef, queryResults));
    }
  }
};

export default queryActionFactory