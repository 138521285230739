import _ from 'lodash';
import React, {Component} from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

import { includesSensor } from "../../../../../state/common/sensorSelector/services/sensorSelectorService";

import getSensorSelectorStyles from './sensorSelectorStyles';

const sensorSelectorStyles = getSensorSelectorStyles();
const styles = {
  ...sensorSelectorStyles,
};

class AvailableSensors extends Component {

  render() {
    return (
      <div>
          {
            _.isEmpty(this.props.sensors) &&
            <Box sx={styles.sensorList}>
              <Typography variant={'subtitle1'}
                          sx={styles.message}>
                No sensors available for the selected time range
              </Typography>
            </Box>
          }
          {
            !_.isEmpty(this.props.sensors) &&
            <List sx={styles.sensorList}>
              {
                _.isNil(this.props.sensors) ? [] : this.props.sensors.map((sensor, index) => {

                  let sensorAlreadySelected = includesSensor(this.props.selectedSensors, sensor);

                  return (
                    <ListItem
                      selected={this.props.selectedIndexesForAdd.findIndex(item => item === index) >= 0}
                      sx={styles.listItem}
                      disabled={ sensorAlreadySelected }
                      key={`${sensor.alias}:${sensor.uom}`}
                      onClick={() => { 
                          if (sensorAlreadySelected === false) {
                            this.props.selectSensorToAdd(index);
                          }
                        }
                      }>
                      <ListItemText primary={`${sensor.alias} (${sensor.uom})`} primaryTypographyProps={{fontSize: '14px'}}/>
                    </ListItem>
                  )
                })
              }
            </List>
          }
      </div>
    )
  }
}

AvailableSensors.props = {
  sensors: PropTypes.array,
  selectedSensors: PropTypes.array,
  selectedIndexesForAdd: PropTypes.array,
  selectSensorToAdd: PropTypes.func,
}

export default AvailableSensors;
