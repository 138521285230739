import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import UnitAlarmBadge from '../unitAlarmBadge';

import fleetMapStyles from '../../fleetMapStyles';
import Bar from '../../../../controls/charts/bar/bar';
import { mdtPalette } from "../../../../common/styles/mdtPalette";

const styles = {
  ...fleetMapStyles,
  unitContentItem: {
    ...fleetMapStyles.unitContentItem,
    paddingTop: '5px',
  },
};

class Hydration extends Component {
  render() {

    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.unitMapContainer}>
        <Box sx={styles.unitVisualContent}>
          <Box sx={styles.unitVisualHeaderContent}>
            <Typography variant={'subtitle1'}>{data.name}</Typography>
          </Box>
          {
            !(_.isEmpty(this.props.data.components)) &&
            <Box sx={styles.unitContent}>
              {
                this.props.data.components.map((component, index) => {
                  return (
                      <Box key={index} sx={styles.unitContentItem}>
                        <Bar value={component.currentLife} total={component.expectedLife}
                             label={component.displayName} valueColor={mdtPalette().status.level2} totalColor={mdtPalette().status.level1} enableThresholds={true}/>
                      </Box>
                  )
                })
              }
            </Box>
          }
          <Box sx={styles.badgeContainer}>
            <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
          </Box>
        </Box>
      </Box>
    );
  }
}

Hydration.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hydration;