import * as jobHistorySimulation from './jobHistorySimulation';
import {performDisplayFetch} from "../../common/fetch";
import useSimulationData from '../../../helpers/simulationHelper';

const fetchCustomers = () => {

  if (useSimulationData('jobHistory')) {
    return jobHistorySimulation.fetchCustomers();
  }

  return performDisplayFetch(
      {
          'query': 'query customers { customers { id ownerId name location note active contactName email phone } }'
      }
  );
};

const fetchJobsForTimeFrame = (timeFrame) => {

  if (useSimulationData('jobHistory')) {
    return jobHistorySimulation.fetchJobsForTimeFrame(timeFrame.label);
  }

  return performDisplayFetch(
    {
        'query': 'query JobsForTimeFrame($time:Long!) { jobsForTimeFrame(time: $time) { id jobName jobType datavan customerId customerName contactName startDate endDate wells { jobId wellId apiNumber wellName pad leaseName leaseNumber plannedStages } } }',
        'variables': {
          'time': timeFrame.value*1000
        }
    }
  );
};

const fetchActiveJobs = () => {

    if (useSimulationData('jobHistory')) {
      return jobHistorySimulation.fetchJobsForTimeFrame('Active');
    }

    return performDisplayFetch(
      {
          'query': 'query ActiveJobs { activeJobs { id jobName jobType datavan customerId customerName contactName startDate endDate wells { jobId wellId apiNumber wellName pad leaseName leaseNumber plannedStages } } }'
      }
    );
};

const fetchPlannedJobs = () => {

  if (useSimulationData('jobHistory')) {
    return jobHistorySimulation.fetchJobsForTimeFrame('Planned');
  }

  return performDisplayFetch(
    {
        'query': 'query PlannedJobs { plannedJobs { id jobName jobType datavan customerId customerName contactName startDate endDate wells { jobId wellId apiNumber wellName pad leaseName leaseNumber plannedStages } } }'
    }
  );
};

const fetchJobMetadata = () => {

  if(useSimulationData('jobHistory')) {
    return jobHistorySimulation.fetchJobMetadata();
  }

  return performDisplayFetch(
    {
        'query': 'query JobMetadataForOwner { jobMetadataForOwner { datavans { id truckPid truckName fleetId fleetName division region type } jobTypes { jobTypeId jobType } wells { id apiNumber name leaseName leaseNumber padName latitude longitude } } }'
    }
  );
};

const fetchSaveJob = (job) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveJob($input:JobHistoryInput!) { saveJob(input: $input) { id jobName jobType datavan customerId customerName contactName startDate endDate wells { jobId wellId apiNumber wellName pad leaseName leaseNumber plannedStages } } }',
      'variables': {
        'input': job
      }
    });
};

const fetchSaveJobWells = (jobWells) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveWellsForJob($inputs:[JobWellInput]!) { saveWellsForJob(inputs: $inputs) { success } }',
      'variables': {
        'inputs': jobWells
      }
    });
};

const fetchDeleteJob = (jobId) => {

  return performDisplayFetch(
    {
      'query': 'mutation DeleteJob($id:Long!) { deleteJob(id: $id) { success } }',
      'variables': {
        'id': jobId
      }
    });
};

export {
  fetchCustomers,
  fetchJobsForTimeFrame,
  fetchSaveJob,
  fetchSaveJobWells,
  fetchActiveJobs,
  fetchPlannedJobs,
  fetchJobMetadata,
  fetchDeleteJob,
};