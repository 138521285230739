import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CriticalAlarmBadge from '../../../controls/badges/criticalAlarmBadge';
import WarningAlarmBadge from '../../../controls/badges/warningAlarmBadge';

class UnitAlarmBadge extends Component {

  render() {
    const { alarmCounts } = this.props;

    let criticalCount = !_.isNil(alarmCounts) && !_.isNil(alarmCounts.critical) ? alarmCounts.critical : 0;
    let warningCount = !_.isNil(alarmCounts) && !_.isNil(alarmCounts.warning) ? alarmCounts.warning : 0;

    let showCritical = criticalCount > 0;
    let showWarning = !showCritical && warningCount > 0;

    return (
      <div>
        {
          showCritical === true &&
          <CriticalAlarmBadge count={criticalCount}/>
        }
        {
          showWarning === true &&
          <WarningAlarmBadge count={warningCount}/>
        }
      </div>
    );
  }
}

UnitAlarmBadge.propTypes = {
  alarmCounts: PropTypes.object,
};

export default UnitAlarmBadge