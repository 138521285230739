import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import unitActivityActionTypes from './unitActivityActionTypes';

import fetchUnitActivityCardData from './unitActivityQueries';
import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(unitActivityActionTypes.UNIT_ACTIVITY_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_STARTING,
  unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_SUCCESS,
  unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_ACTIVITY_CARD_DATA,
  fetchUnitActivityCardData
);

export {
  queryData,
  clearData
}