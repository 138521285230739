import { handleError } from "../../../app/actions/appErrorActions";
import errorMessages from "../../../common/errorMessages";
import defaultActionFactory from "../../../common/factories/defaultActionFactory";
import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { fetchAddConsumer, fetchUpdateConsumer } from "../routeAssetMgmtQueries";
import { queryConsumers, queryRouteAssets } from './routeAssetMgmtDisplayActions';
const closeAddDialog = (stateDef) => {
  return async (dispatch) => {
    await dispatch(resetWorkingConsumer(stateDef));
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_ADD_CONSUMER_DIALOG, 'stateDef')(stateDef));
  }
};

const closeEditDialog = (stateDef) => {
  return async (dispatch) => {
    await dispatch(resetWorkingConsumer(stateDef));
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_EDIT_CONSUMER_DIALOG, 'stateDef')(stateDef));
  }
};
const openAddDialog = (stateDef) => {
  return async (dispatch) => {
    await dispatch(resetWorkingConsumer(stateDef));
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_ADD_CONSUMER_DIALOG, 'stateDef')(stateDef));
    await dispatch(validateWorkingConsumer(stateDef));
  }
};

const openEditDialog = (stateDef, consumer) => {
  return async (dispatch) => {
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_EDIT_CONSUMER_DIALOG, 'stateRef')(stateDef));
  }
}
const onSetWorkingConsumerName = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_CONSUMER_NAME, 'stateDef', 'consumerName'); 
const setWorkingConsumerName = (stateDef, consumerName) => { 
  return async (dispatch) => {
    await dispatch(onSetWorkingConsumerName(stateDef, consumerName));
    await dispatch(validateWorkingConsumer(stateDef));
  } 
};

const onSetWorkingDestinationProject = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PROJECT, 'stateDef', 'destinationProject');
const setWorkingDestinationProject = (stateDef, destinationProject) => {
  return async (dispatch) => {
    await dispatch(onSetWorkingDestinationProject(stateDef, destinationProject));
    await dispatch(validateWorkingConsumer(stateDef));
  }
};

const onSetWorkingDestinationPath = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_DESTINATION_PATH, 'stateDef', 'destinationPath'); 
const setWorkingDestinationPath = (stateDef, destinationPath) => {
  return async (dispatch) => {
    await dispatch(onSetWorkingDestinationPath(stateDef, destinationPath));
    await dispatch(validateWorkingConsumer(stateDef));
  }
};

const validateWorkingConsumer = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_VALIDATE_WORKING_CONSUMER, 'stateDef');

const addConsumerStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_STARTING, 'stateDef'); 
const addConsumerSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_SUCCESS, 'stateDef', 'results');
const addConsumerError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_CONSUMER_ERROR, 'stateDef');

const updateConsumerStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_UPDATE_CONSUMER_STARTING, 'stateDef');
const updateConsumerSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_UPDATE_CONSUMER_SUCCESS, 'stateDef', 'results');
const updateConsumerError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_UPDATE_CONSUMER_ERROR, 'stateDef');

const saveConsumer = (stateDef, workingConsumer) => {
  return async (dispatch) => {

    let results = null;
    const input = {...workingConsumer };
    input.consumerName = workingConsumer.consumerName.value;

    if (_.isNil(workingConsumer.id)) {
        try {
          await dispatch(addConsumerStarting(stateDef));
          results = await fetchAddConsumer(input);
          await dispatch(addConsumerSuccess(stateDef, results));
        } catch (e) {
          await dispatch(addConsumerError(stateDef));
          return dispatch(handleError(errorMessages.ERROR_ADDING_CONSUMER, e.message));
        }
    } else {
        try {
          await dispatch(updateConsumerStarting(stateDef));
          const results = await fetchUpdateConsumer(input);
          await dispatch(updateConsumerSuccess(stateDef, results));
        } catch (e) {
          await dispatch(updateConsumerError(stateDef));
          return dispatch(handleError(errorMessages.ERROR_UPDATING_CONSUMER, e.message));
        }
        await dispatch(queryRouteAssets(stateDef));
    }
    await dispatch(resetWorkingConsumer(stateDef));
    return dispatch(queryConsumers(stateDef));
  }
};


const resetWorkingConsumer = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_RESET_WORKING_CONSUMER, 'stateDef');

export {
    closeAddDialog,
    closeEditDialog,
    openAddDialog,
    openEditDialog,
    setWorkingConsumerName,
    setWorkingDestinationPath,
    setWorkingDestinationProject,
    validateWorkingConsumer,
    addConsumerStarting,
    addConsumerSuccess,
    addConsumerError,
    saveConsumer,
    resetWorkingConsumer,
}