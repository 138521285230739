import componentsActionTypes from '../componentsActionTypes';
import { editComponentState, addComponentState } from '../componentsSelectors';

import * as validationService from '../services/validataionService';

const commandReducer = (state, action) => {

  switch (action.type) {

    case componentsActionTypes.COMPONENTS_REVERT_FORM:
      return onRevertForm(state, action);

    case componentsActionTypes.COMPONENTS_SAVE_COMPONENT_STARTING:
      return onSaveComponentStarting(state, action);

    case componentsActionTypes.COMPONENTS_SAVE_COMPONENT_SUCCESS:
      return onSaveComponentSuccess(state, action);

    case componentsActionTypes.COMPONENTS_SAVE_COMPONENT_ERROR:
      return onSaveComponentError(state, action);

    case componentsActionTypes.COMPONENTS_DELETE_COMPONENT_STARTING:
      return onDeleteComponentStarting(state, action);

    case componentsActionTypes.COMPONENTS_DELETE_COMPONENT_SUCCESS:
      return onDeleteComponentSuccess(state, action);

    case componentsActionTypes.COMPONENTS_DELETE_COMPONENT_ERROR:
      return onDeleteComponentError(state, action);

    default: return state;

  }
};

const onRevertForm = (state, action) => {

  let formState = state.editMode ? editComponentState(state.selectedComponent) : addComponentState();

  let newState = {
    ...state,
    ...formState,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSaveComponentStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  }
};

const onSaveComponentSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    editMode: true,
  }
};

const onSaveComponentError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  }
};

const onDeleteComponentStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
    openDeleteConfirmation: false,
  }
};

const onDeleteComponentSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  }
};

const onDeleteComponentError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  }
};

export default commandReducer