import _ from 'lodash';

const iconPickerState = state => {
    return {
        open: _.isNil(state) ? false : state.open,
        iconTargetElement: _.isNil(state) ? null : state.iconTargetElement,
        filterString: _.isNil(state) ? '' : state.filterString
    };
};

export {
    iconPickerState
};