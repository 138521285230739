import React, {Component} from 'react';
import { Typography, Tooltip, Box  } from '@mui/material';
import PropTypes from 'prop-types';
import { mdtPalette } from '../../../common/styles/mdtPalette';

const styles = {
  chartAndLabelContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '110px',
  },

  chartLabel: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: mdtPalette().typography.black,
    fontSize: '12px'
  },

  vibrationChartContainerOver: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '15px',
    backgroundColor: mdtPalette().status.level5,
  },

  vibrationChartContainerWarn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '15px',
    backgroundColor: mdtPalette().status.level4,
  },

  vibrationChartContainerUnder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '15px',
    backgroundColor: mdtPalette().status.level2,
  },

  vibrationChartContainerNoValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '15px',
    backgroundColor: mdtPalette().status.level1,
  },
};

class Vibration extends Component {

  render() {
    
    let numberValue = Number.parseFloat(this.props.value);

    return (
        <Tooltip disableFocusListener followCursor={true} title={
          <React.Fragment>
            <Typography variant={'caption'} color="inherit" sx={styles.tooltipTitle}>{this.props.component}</Typography>
            <Box sx={styles.tooltipValuesContainer}>
              <Typography variant={'caption'} color="inherit">Lower Limit: 4</Typography>
              <Box sx={styles.tooltipValuesSpacer}/>
              <Typography variant={'caption'} color="inherit">Upper Limit: 6</Typography>
            </Box>
          </React.Fragment>
        } >

          <Box sx={styles.chartAndLabelContainer}>
            <Typography variant={'caption'} sx={styles.label}>{this.props.component}</Typography>
            {
              (numberValue > 6) &&
              <Box sx={styles.vibrationChartContainerOver}>
                <Typography variant={'caption'} sx={styles.chartLabel}>{this.props.value}</Typography>
              </Box>
            }
            {
              (numberValue >= 4) && (numberValue <= 6) &&
              <Box sx={styles.vibrationChartContainerWarn}>
                <Typography variant={'caption'} sx={styles.chartLabel}>{this.props.value}</Typography>
              </Box>
            }
            {
              (numberValue < 4) &&
              <Box sx={styles.vibrationChartContainerUnder}>
                <Typography variant={'caption'} sx={styles.chartLabel}>{this.props.value}</Typography>
              </Box>
            }
            {
              (Number.isNaN(numberValue)) &&
              <Box sx={styles.vibrationChartContainerNoValue}>
                <Typography variant={'caption'} sx={styles.chartLabel}>{this.props.value}</Typography>
              </Box>
            }
          </Box>

        </Tooltip>
    );
  }
}

Vibration.propTypes = {
  component: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Vibration;