import { performDisplayFetch } from '../../common/fetch';

const fetchLatestAvailableDownloads = () => {
    return performDisplayFetch({
        'query': 'query allLatestDownloadableAssets { allLatestDownloadableAssets { type version fileName downloadUrl}}'
    });
};

export {
 fetchLatestAvailableDownloads
}