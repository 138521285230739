import React from 'react';
import _ from 'lodash';

import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter, useGridApiRef } from '@mui/x-data-grid-pro';
import AddchartIcon from '@mui/icons-material/Addchart';

import MDTDataGrid from '../../../common/table/MDTDataGrid';
import getDataGridStyles from "../../../common/styles/dataGridStyle";
import getDataGridDialogStyles from '../styles/dataGridDialogStyles';

const dataGridStyle = getDataGridStyles();
const dataGridDialogStyle = getDataGridDialogStyles();

const styles = {
  ...dataGridStyle,
  ...dataGridDialogStyle,
};

// This is a functional react component.
// We have been using class components for 99% of the time now.
// Functional components work like class components except for a few differences, major one being able to
// use React Hooks.
// https://reactjs.org/docs/components-and-props.html
export default function StageDataGrid({stateDef, stages, selectedStage, selectStageCallback, createChartForStageCallback, charts}) {

  const apiRef = useGridApiRef();

  const customGridToolbarStages = () => {
    return (
      <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
        <GridToolbarQuickFilter sx={{width: '255px'}} />
      </GridToolbarContainer>
    )
  }

  const customNoRowsOverlay = () => {
    return (
      <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
        <Typography variant='subtitle1'>{_.isEmpty(stages) ? 'No Data' : 'Please load Job Data to view Stages.'}</Typography>
      </Box>
    )
  }

  /**
   * Create a PRC Chart for a Stage
   * @param {*} row The Stage
   * @returns 
   */
  const renderStageActions = (row) => {

    const alreadyPlotted = _.find(charts, (chart) => { return chart.configuration.stage.id === row.id });

    return (
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center'}}>
        <Tooltip title="Add Stage to Treatment Plot">
          <Box>
            <IconButton
              onClick={(e) => { 
                e.stopPropagation(); 
                selectStageCallback(_.find(stages, ['id', row.id]));
                createChartForStageCallback(row);
              }}
              size="large"
              disabled={!_.isNil(alreadyPlotted)}>
              <AddchartIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    )
  }

  const columns = [
    {
      field: 'requestedTime',
      headerName: 'Requested Time',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Typography variant='body2'>{params.row.createdAt}</Typography>
        )
      },
      // Using a padStart to make sure the sort works properly for stages 1 to 999
      // "Created On" is the "timestamp" of the Stage and can possibly be null 
      // This lets us do sorting by the order the Stages were created
      // and also lets the user use the quick filter against the timestamp
      valueGetter: (params) => {
        if (_.isNil(params.row)) return '';
        return (params.row.id + '').padStart(3, '0')  + '_' + params.row.createdAt;
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      headerAlign: 'center',
      align: 'left',
      flex: 1.5,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
    },
    {
      field: 'processes',
      headerName: 'Process',
      headerAlign: 'center',
      type: 'string',
      align: 'center',
      flex: 1,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: (params) => 
      {
        return (
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', width: '250px'}}>
            <Typography variant='body2' sx={{ fontWeight: 500 }}>{'Process'}</Typography>
            <Typography variant='body2' sx={{ fontWeight: 500 }}>{'Well'}</Typography>
            <Typography variant='body2' sx={{ fontWeight: 500 }}>{'Stage'}</Typography>
          </Box>
        );
      },
      // Parse out the delimited string so we can display it nicely
      renderCell: (params) => 
      {
        const processes = _.split(params.value, ',');

        return (
          <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
            {
            _.map(processes, (process) => {
              const processData = _.split(process, '/');

              return (
                <Box key={processData[0]} sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', width: '250px'}}>
                  <Typography variant='body2' sx={{paddingLeft: '5px'}}>{processData[0]}</Typography>
                  <Typography variant='body2'sx={{paddingRight: '10px'}}>{processData[1]}</Typography>
                  <Typography variant='body2'sx={{paddingRight: '17px'}}>{processData[2]}</Typography>
                </Box>
              )
            })
            }
          </Box>
        );
      },
      // Need to take the Processes collection and turn into a delimited string
      // This lets us apply the quick filter against the data in here
      valueGetter: (params) => {
        if (_.isNil(params.row)) return '';
        return params.row.processes.map((process) => process.side + '/' + process.wellApiNumber + '/' + process.stage).join(",");
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
    },
    {
      // Some actions to perform on the Stage
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      width: 200,  
      editable: false,
      hideable: false,
      pinnable: false,
      sortable: false,
      resizable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderCell: (params) => {
        const { row } = params;
        return renderStageActions(row);
      },
    },
  ];

  // Since this is a functional react component, we can use hooks now
  // useEffect can be made to work like componentDidUpdate or componentDidMount, depending on how many
  // properties are in the second argument supplied to useEffect
  // If there are no properties, meaning [], 
  //    then what happens in useEffect is like componentDidMount - only happens once
  // If there are 1 or more properties, meaning [prop1,...,propN], 
  //    then what happens is useEffect will run everytime any of the properties have changed
  React.useEffect(() => {
    // The use of setTimeout here is a workaround to this bug 
    // https://github.com/mui/mui-x/issues/6411
    setTimeout(() => {
      if (!_.isNil(selectedStage) && !_.isNil(apiRef.current)) {
        const rowIndex = _.findIndex(stages, ['id', selectedStage.id]);
        apiRef.current.scrollToIndexes({rowIndex: rowIndex, colIndex: 0});
      }
    }, 0);
  }, [apiRef, selectedStage]);

  return (
    <Box sx={styles.mdtDataGridContainer}>
      <MDTDataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'requestedTime', sort: 'desc' }],
          },
        }}

        apiRef={apiRef}
        getRowId={row => row.id}
        pagination={false}
        autoHeight={false}
        hideFooter
        columns={columns}
        rows={stages}
        
        components={{
          Toolbar: customGridToolbarStages,
          NoRowsOverlay: customNoRowsOverlay,
        }}

        stateDef={stateDef}

        disableMultipleSelection={true}
        onSelectionModelChange={(newSelectionModel) => {
          if (!_.isEmpty(newSelectionModel) && newSelectionModel[0] > -1) {
            selectStageCallback(_.find(stages, ['id', newSelectionModel[0]]));
          }
        }}
        selectionModel={_.isNil(selectedStage) ? -1 : selectedStage.id}
        />
    </Box>
  );
}