import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import { fleetMgmtState } from '../fleetMgmtSelectors';
import { datasourcesHaveChanges, validateFleetChanges } from "../services/fleetMgmtService";
import splitButtonActionTypes from '../../../common/splitButton/splitButtonActionTypes';

const initialState = fleetMgmtState();

const splitButtonReducer = (state = initialState, action) => {

  switch (action.type) {

    case splitButtonActionTypes.SPLIT_BUTTON_CLICK:
      return onClick(state, action);
    case splitButtonActionTypes.SPLIT_BUTTON_MENU_ITEM_CLICK:
      return onMenuItemClick(state, action);
    case splitButtonActionTypes.SPLIT_BUTTON_TOGGLE:
      return onToggle(state, action);
    case splitButtonActionTypes.SPLIT_BUTTON_CLOSE:
      return onClose(state, action);
    default:
      return state;
  }
}

const onClick = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  const datasourceToAdd = state.datasourceTemplates[state.splitButtonSelectedIndex];
  datasourceToAdd.datasourceGuid = uuidv4();
  datasourceToAdd.hasChanges = true;

  // Check if there is a datasource with the same name
  const duplicateName = datasources.find((datasource) => {
    return datasource.name === datasourceToAdd.name;
  });

  datasourceToAdd.hasDuplicateNameError = !_.isNil(duplicateName);

  datasources.push(datasourceToAdd);

  return {
    ...state,
    datasources: datasources,
    datasourcesChanged: datasourcesHaveChanges(datasources, state.originalDatasources),
    errorsOnFleetSave: validateFleetChanges(state.fleetToSave, datasources),
  };
};

const onMenuItemClick = (state, action) => {
  return {
    ...state,
    splitButtonSelectedIndex: action.selectedIndex,
    splitButtonOpen: false
  };
};

const onToggle = (state, action) => {
  return {
    ...state,
    splitButtonOpen: !state.splitButtonOpen
  }
};

const onClose = (state, action) => {
  return {
    ...state,
    splitButtonOpen: false
  }
}

export default splitButtonReducer;