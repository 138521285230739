import _ from 'lodash';
import unitChartSeriesTypes from './unitChartSeriesTypes';

const fetchUnitEngineCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let oilPressureData = generateChartData(600, 0, 12000, startTime, endTime);
      let engineTempData = generateChartData(600, 0, 120, startTime, endTime);
      let engineRpmData = generateChartData(600, 0, 12000, startTime, endTime);
      let engineFuelPressureData = generateChartData(600, 0, 200, startTime, endTime);
      
      resolve( {
        unitEngineChart: {
          series: [
            { id: unitChartSeriesTypes.OIL_PRESSURE, data: oilPressureData, latest: _.last(oilPressureData).value },
            { id: unitChartSeriesTypes.ENGINE_FUEL_PRESSURE, data: engineFuelPressureData, latest: _.last(engineFuelPressureData).value },
            { id: unitChartSeriesTypes.ENGINE_TEMP, data: engineTempData, latest: _.last(engineTempData).value },
            { id: unitChartSeriesTypes.ENGINE_RPM, data: engineRpmData, latest: _.last(engineRpmData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitTransmissionCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let transPressureData = generateChartData(600, 0, 12000, startTime, endTime);
      let transTempData = generateChartData(600, 0, 120, startTime, endTime);
      let gearData = generateChartData(600, -2, 7, startTime, endTime);

      resolve( {
        unitTransmissionChart: {
          series: [
            { id: unitChartSeriesTypes.TRANS_PRESSURE, data: transPressureData, latest: _.last(transPressureData).value },
            { id: unitChartSeriesTypes.TRANS_TEMP, data: transTempData, latest: _.last(transTempData).value },
            { id: unitChartSeriesTypes.GEAR, data: gearData, latest: _.last(gearData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitPowerEndCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let lubePressureData = generateChartData(600, 0, 12000, startTime, endTime);
      let lubeTempData = generateChartData(600, 0, 120, startTime, endTime);
      let flowRateData = generateChartData(600, 0, 10, startTime, endTime);

      resolve( {
        unitPowerEndChart: {
          series: [
            { id: unitChartSeriesTypes.LUBE_PRESSURE, data: lubePressureData, latest: _.last(lubePressureData).value },
            { id: unitChartSeriesTypes.LUBE_TEMP, data: lubeTempData, latest: _.last(lubeTempData).value },
            { id: unitChartSeriesTypes.FLOW_RATE, data: flowRateData, latest: _.last(flowRateData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitLockupCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let lockupData = generateChartData(600, 0, 1, startTime, endTime);
      let transTempData = generateChartData(600, 0, 120, startTime, endTime);
      let engineRpmData = generateChartData(600, 0, 12000, startTime, endTime);

      resolve( {
        unitLockupChart: {
          series: [
            { id: unitChartSeriesTypes.LOCKUP, data: lockupData, latest: _.last(lockupData).value },
            { id: unitChartSeriesTypes.TRANS_TEMP, data: transTempData, latest: _.last(transTempData).value },
            { id: unitChartSeriesTypes.ENGINE_RPM, data: engineRpmData, latest: _.last(engineRpmData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitCoilDetailCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let depthData = generateChartData(600, 0, 100000, startTime, endTime);
      let weightData = generateChartData(600, 100, 1000, startTime, endTime);
      let weightTareData = generateChartData(600, 0, 1000, startTime, endTime);

      resolve( {
        unitCoilDetailChart: {
          series: [
            { id: unitChartSeriesTypes.COIL_DEPTH, data: depthData, latest: _.last(depthData).value },
            { id: unitChartSeriesTypes.COIL_WEIGHT, data: weightData, latest: _.last(weightData).value },
            { id: unitChartSeriesTypes.COIL_WEIGHT_TARE, data: weightTareData, latest: _.last(weightTareData).value },
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitCoilCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let depthData = generateChartData(600, 0, 100000, startTime, endTime);
      let wellheadPressureData = generateChartData(600, 1, 12000, startTime, endTime);
      let circulatingPressureData = generateChartData(600, 1, 12000, startTime, endTime);

      resolve( {
        unitCoilChart: {
          series: [
            { id: unitChartSeriesTypes.COIL_DEPTH, data: depthData, latest: _.last(depthData).value },
            { id: unitChartSeriesTypes.COIL_WELLHEAD_PRESSURE, data: wellheadPressureData, latest: _.last(wellheadPressureData).value },
            { id: unitChartSeriesTypes.COIL_CIRCULATING_PRESSURE, data: circulatingPressureData, latest: _.last(circulatingPressureData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitPressureHistoryCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let dischargeData = generateChartData(600, 0, 12000, startTime, endTime);
      let suctionData = generateChartData(600, 0, 12000, startTime, endTime);
      let flowRateData = generateChartData(600, 0, 10, startTime, endTime);

      resolve( {
        unitPressureHistoryChart: {
          series: [
            { id: unitChartSeriesTypes.DISCHARGE, data: dischargeData, latest: _.last(dischargeData).value },
            { id: unitChartSeriesTypes.SUCTION, data: suctionData, latest: _.last(suctionData).value },
            { id: unitChartSeriesTypes.FLOW_RATE, data: flowRateData, latest: _.last(flowRateData).value }
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitOutputHistoryCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let flowRateData = generateChartData(600, 0, 10, startTime, endTime);
      let hhpData = generateChartData(600, 0, 12000, startTime, endTime);

      resolve( {
        unitOutputHistoryChart: {
          series: [
            { id: unitChartSeriesTypes.FLOW_RATE, data: flowRateData, latest: _.last(flowRateData).value },
            { id: unitChartSeriesTypes.HYDRAULIC_HORSE_POWER, data: hhpData, latest: _.last(hhpData).value },
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitPressureHistoryRoadsideCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let dischargeData = generateChartData(600, 0, 12000, startTime, endTime);
      let suctionData = generateChartData(600, 0, 12000, startTime, endTime);
      let flowRateData = generateChartData(600, 0, 10, startTime, endTime);

      resolve( {
        unitPressureHistoryRoadsideChart: {
          series: [
            { id: unitChartSeriesTypes.FLOW_RATE, data: flowRateData, latest: _.last(flowRateData).value },
            { id: unitChartSeriesTypes.DISCHARGE, data: dischargeData, latest: _.last(dischargeData).value },
            { id: unitChartSeriesTypes.SUCTION, data: suctionData, latest: _.last(suctionData).value },
          ]
        }
      });
    }, 0)
  });
};

const fetchUnitPressureHistoryCurbsideCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let dischargeData = generateChartData(600, 0, 12000, startTime, endTime);
      let suctionData = generateChartData(600, 0, 12000, startTime, endTime);
      let flowRateData = generateChartData(600, 0, 10, startTime, endTime);

      resolve( {
        unitPressureHistoryCurbsideChart: {
          series: [
            { id: unitChartSeriesTypes.FLOW_RATE, data: flowRateData, latest: _.last(flowRateData).value },
            { id: unitChartSeriesTypes.DISCHARGE, data: dischargeData, latest: _.last(dischargeData).value },
            { id: unitChartSeriesTypes.SUCTION, data: suctionData, latest: _.last(suctionData).value },
          ]
        }
      });
    }, 0)
  });
};


const fetchUnitVibrationHistoryCardData = (truckId, startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let powerEndFrontData = generateChartData(600, 0, 10, startTime, endTime);
      let fluidEndFrontData = generateChartData(600, 0, 12000, startTime, endTime);
      let powerEndRearData = generateChartData(600, 0, 12000, startTime, endTime);
      let fluidEndRearData = generateChartData(600, 0, 12000, startTime, endTime);

      resolve( {
        unitVibrationHistory: {
          series: [
            { id: unitChartSeriesTypes.POWER_END_FRONT, data: powerEndFrontData, latest: _.last(powerEndFrontData).value },
            { id: unitChartSeriesTypes.FLUID_END_FRONT, data: fluidEndFrontData, latest: _.last(fluidEndFrontData).value },
            { id: unitChartSeriesTypes.POWER_END_REAR,  data: powerEndRearData,  latest: _.last(powerEndRearData).value },
            { id: unitChartSeriesTypes.FLUID_END_REAR,  data: fluidEndRearData,  latest: _.last(fluidEndRearData).value },
          ]
        }
      });
    }, 0)
  });
};

const generateChartData = (count, min, max, startTime, endTime) => {

  let steps = (endTime - startTime) / count;
  let timestamps = _.range(startTime, endTime, steps);

  // generate a random walk within the min and max for a smoother chart
  let data = [];
  let range = min + max;
  let value = range / 2.0;
  let step = range / 100.0;
  for (let i=0; i<count; i++) {
    value += _.random(-step, step, true);
    data.push({
      timestamp: timestamps[i],
      value: value
    });
  }

  return data;
};


export {
  fetchUnitEngineCardData,
  fetchUnitTransmissionCardData,
  fetchUnitPowerEndCardData,
  fetchUnitLockupCardData,
  fetchUnitCoilDetailCardData,
  fetchUnitCoilCardData,
  fetchUnitPressureHistoryCardData,
  fetchUnitOutputHistoryCardData,
  fetchUnitPressureHistoryRoadsideCardData,
  fetchUnitPressureHistoryCurbsideCardData,
  fetchUnitVibrationHistoryCardData,
}
