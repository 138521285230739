import React, {Component} from 'react';
import {connect} from "react-redux";
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

const styles = {
  page: {
    width: '100%',
    maxWidth: (theme) => theme.breakpoints.values.md,
    paddingTop: 16,
    paddingLeft: 2,
    paddingRight: 2
  }
};

class Home extends Component {
  render() {
    return (
      <Grid container justifyContent={'center'}>
        <Box sx={styles.page}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant={'h5'} align={'center'} noWrap >
                Welcome to the MDT Cloud Portal
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);