const combinators = [
  {
    name: "AND",
    label: "AND"
  },
  {
    name: "OR",
    label: "OR"
  }
];

const stringOperators = [
  {
    name: "=",
    label: "=="
  },
  {
      name: "<>",
      label: "!="
  },
  {
      name: "in",
      label: "In"
  },
  {
      name: "not_in",
      label: "Not In"
  },
  {
      name: "contains",
      label: "Contains"
  }
];

// Operators that work for both numbers and datetime values
const numericOperators = [
  {
    name: "<",
    label: "<"
  },
  {
      name: ">",
      label: ">"
  },
  {
      name: "<=",
      label: "<="
  },
  {
      name: ">=",
      label: ">="
  },
  {
      name: "=",
      label: "=="
  },
  {
      name: "<>",
      label: "!="
  },
  {
      name: "in",
      label: "In"
  },
  {
      name: "not_in",
      label: "Not In"
  }
];

const fields = [
  {
    label: "Message ID",
    name: "message_id",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Datavan PID",
    name: "datavan_pid",
    type: "Number",
    availableOperators: numericOperators
  },
  {
    label: "Datavan Name",
    name: "datavan_name",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Truck PID",
    name: "truck_pid",
    type: "Number",
    availableOperators: numericOperators
  },
  {
    label: "Unit Number",
    name: "unit_number",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Action Type",
    name: "action_type",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Detail",
    name: "detail",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "New State",
    name: "new_state",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Old State",
    name: "old_state",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Source",
    name: "source",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Sub Type",
    name: "sub_type",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "UI Component",
    name: "ui_component",
    type: "String",
    availableOperators: stringOperators
  },
  {
    label: "Timestamp",
    name: "timestamp",
    type: "String",
    availableOperators: numericOperators
  }
];

const fetchAuditLogsMetadata = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        metadata: {
          combinators: combinators,
          fields: fields,
        },
      });
    }, 1000);
  });
};

export { fetchAuditLogsMetadata, combinators, stringOperators, numericOperators };
