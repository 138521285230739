import { mdtPalette } from '../../common/styles/mdtPalette';

const chartOptions = (pumping, idle, stopped, offline) => {

  let chartData = [];

  if (pumping > 0) {
    chartData.push({ y: pumping, color:  mdtPalette().status.level2 });
  }

  if (idle > 0) {
    chartData.push({ y: idle, color: mdtPalette().status.level4 });
  }

  if (stopped > 0) {
    chartData.push({ y: stopped, color: mdtPalette().status.level5 });
  }

  if (offline > 0) {
    chartData.push({ y: offline, color: mdtPalette().status.level1 });
  }

  return {

    title: { text: '' },

    credits: { enabled: false },

    chart: {
      type: 'pie',
      width: 100,
      height: 100,
      spacing: [0,0,0,0],
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      animation: false,
    },

    lang: {
      noData: null
    },

    pane: {
      size: '100%',
      center: ['50%', '50%'],
      startAngle: -135,
      endAngle: 135,
      background: {
        backgroundColor: mdtPalette().materialUI.palette.grey['600'],
        borderColor: mdtPalette().materialUI.palette.grey['600'],
        shape: 'arc'
      }
    },

    tooltip: { enabled: false },

    plotOptions: {
      pie: {
        borderColor: null,
        allowPointSelect: false,
        dataLabels: { enabled: false, },
        animation: false,
      }
    },

    series: [{
      states: { 
        hover: { 
          enabled: false 
        }, 
        inactive: { 
          enabled: false  
        } 
      },
      data: chartData,
    }]
  }

};

export default chartOptions;