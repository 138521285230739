import _ from 'lodash';

const processDetails = (queryResults) => {

  let state = _.cloneDeep(queryResults.componentFleetMap.componentViewData);

  return {
    ...state,
    pumps: _.map(state.pumps, processPumps)
  }
};

const processComponent = (component) => {

  if (!component) {
    return null;
  }

  let { currentLife, expectedLife } = component;

  let displayCurrentLife = _.isNil(currentLife) ? null : Number(currentLife.toFixed(0));
  let displayExpectedLife = _.isNil(expectedLife) ? 0 : Number(expectedLife.toFixed(0));

  return {
    ...component,
    currentLife: displayCurrentLife,
    expectedLife: displayExpectedLife
  }

};

const processPumps = (pump) => {

  if (!pump) {
    return null;
  }

  return {
    ...pump,
    components: _.map(pump.components, processComponent)
  }

};

export {
  processDetails
}