/**
 * This is a common library of MDT wrappers around MUI controls.
 */
import _ from "lodash";
import { Autocomplete, TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * MUI (v5) now has an Autocomplete control.
 *
 * The way we want it setup, it requires some certain imports, making
 * it hard to use consistently throughout.
 * 
 * Let's use our previous AutoComplete control as a wrapper
 * and simplify the usage of the new Autocomplete control from MUI.
 * 
 * We will leave it generally unstyled so consumers can 
 * style it to their needs
 *
 */
const AutoCompleteMDT = (props) => 
  <Autocomplete 
    disableClearable
    autoComplete={true}
    autoHighlight={true}
    renderInput={(params) => <TextField {...params} variant='standard' InputProps={{ ...params.InputProps, disableUnderline: true }} />} 
    popupIcon={<KeyboardArrowDownIcon/>}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    {...props} 
  />;
// This lets us reference *this* custom Autocomplete control as the MUI Autocomplete
// https://mui.com/material-ui/guides/composition/#wrapping-components
// However we currently are choosing not to, so there is less confusion
AutoCompleteMDT.muiName = 'AutoCompleteMDT';

/**
 * This is a wrapper around the MobileDateTimePicker from MUI.
 * 
 * We are doing this to commonize the date time picker controls that are used throughout
 * and to simplify development going forward.
 * 
 * We are using the Mobile version of the DateTimePicker specifically because it gives us
 * the UX we want.
 */
const DateTimePickerMDT = (props) => 
  <MobileDateTimePicker  
    sx={{    
      ...props.sx,  
      // This paddingTop applies to the dialog that comes up, not the control itself
      // We do actually want this space here otherwise it gets a bit cramped on the dialog
      paddingTop: '40px',
    }}
    inputFormat={'MMM DD yyyy hh:mm a'}
    showToolbar={true}
    showTodayButton={true}
    toolbarTitle={''}
    disableFuture={true}
    disableCloseOnSelect={true}
    renderInput={(params) => <TextField {...params} variant='standard' />}
    {...props} 
  />;
// This lets us reference *this* custom MobileDateTimePicker control as the MUI MobileDateTimePicker
// https://mui.com/material-ui/guides/composition/#wrapping-components
// However we currently are choosing not to, so there is less confusion
DateTimePickerMDT.muiName = 'DateTimePickerMDT';

export { 
  AutoCompleteMDT, 
  DateTimePickerMDT
};
