import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';

import ownerManagementActionTypes from './ownerManagementActionTypes';

const selectSetting = defaultActionFactory(ownerManagementActionTypes.SELECT_SETTING, 'stateDef', 'setting');

const showCreateOwnerDialog = defaultActionFactory(ownerManagementActionTypes.SHOW_CREATE_OWNER_DIALOG, 'stateDef', 'show');

const setCreateOwnerName = defaultActionFactory(ownerManagementActionTypes.SET_CREATE_OWNER_NAME, 'stateDef', 'name');

export {
  selectSetting,
  showCreateOwnerDialog,
  setCreateOwnerName
};