import _ from 'lodash';
import * as statusMappingService from './statusMappingService';

const cloudClientDescriptions = {
  ONLINE : 'This fleet is communicating with the cloud service',
  OFFLINE: 'This fleet is not able to communicate with the cloud service',
  INACTIVE: 'This fleet has been deactivated',
};

const sensorUploadDescriptions = {
  UPLOADING : 'This fleet is currently uploading sensor data',
  NOT_UPLOADING: 'This fleet is not currently uploading sensor data',
};

const sensorDataDescriptions = {
  CURRENT : 'This fleet has uploaded all sensor data and/or is keeping up with the sensor updates',
  BEHIND: 'This fleet has not uploaded all sensor data and is currently catching up',
  UNKNOWN: 'The cloud cannot currently determine the state of the sensor data for this fleet',
};

const dataSourceDescriptions = {
  ONLINE : 'The Cloud Client is communicating with this data source',
  OFFLINE: 'The Cloud Client is not able to communicate with this data source',
};

const dataSourceAggregateStatusDescriptions = {
  ONLINE : 'The Cloud Client is communicating with all the data sources',
  OFFLINE: 'The Cloud Client is not able to communicate with one or more data sources',
};

const generateCloudClientDescription = (client) => {

  if (_.isNil(client)) {
    return null;
  }

  let description = {};

  description.title = 'Cloud Client ({status})'.replace('{status}', statusMappingService.getCloudClientStatusName(client.status));
  switch (client.status) {
    case statusMappingService.cloudClientStatuses.ONLINE:
      description.caption = cloudClientDescriptions.ONLINE;
      break;
    case statusMappingService.cloudClientStatuses.OFFLINE:
      description.caption = cloudClientDescriptions.OFFLINE;
      break;
    case statusMappingService.cloudClientStatuses.INACTIVE:
      description.caption = cloudClientDescriptions.INACTIVE;
      break;
    default:
      break;
  }

  return description;
};

const generateSensorUploadDescription = (client) => {

  if (_.isNil(client)) {
    return null;
  }

  let description = {};

  description.title = 'Sensor Upload ({status})'.replace('{status}', statusMappingService.getSensorUploadStatusName(client.isUploading));
  if (client.isUploading) {
    description.caption = sensorUploadDescriptions.UPLOADING;
  } else {
    description.caption = sensorUploadDescriptions.NOT_UPLOADING;
  }

  return description;
};

const generateSensorDataDescription = (client) => {

  if (_.isNil(client)) {
    return null;
  }

  let description = {};

  description.title = 'Sensor Data ({status})'.replace('{status}', statusMappingService.getSensorDataStatusName(client.dataUploadStatus));
  switch (client.dataUploadStatus) {
    case statusMappingService.sensorDataStatuses.CURRENT:
      description.caption = sensorDataDescriptions.CURRENT;
      break;
    case statusMappingService.sensorDataStatuses.BEHIND:
      description.caption = sensorDataDescriptions.BEHIND;
      break;
    case statusMappingService.sensorDataStatuses.UNKNOWN:
      description.caption = sensorDataDescriptions.UNKNOWN;
      break;
    default:
      break;
  }

  return description;
};

const generateDataSourceDescription = (dataSource) => {

  if (_.isNil(dataSource)) {
    return null;
  }

  let description = {};

  description.title = '{name} ({status})'
    .replace('{name}', dataSource.name)
    .replace('{status}', statusMappingService.getDataSourceStatusName(dataSource.status));
  switch (dataSource.status) {
    case statusMappingService.dataSourceStatuses.ONLINE:
      description.caption = dataSourceDescriptions.ONLINE;
      break;
    case statusMappingService.dataSourceStatuses.OFFLINE:
      description.caption = dataSourceDescriptions.OFFLINE;
      break;
    default:
      break;
  }

  return description;

};

const generateDataSourceAggregateStatusDescription = (client) => {

  if (_.isNil(client)) {
    return null;
  }

  let description = {};

  description.title = 'Data Sources ({status})'.replace('{status}', statusMappingService.getDataSourceAggregateStatusName(client.datasourceStatus));
  switch (client.datasourceStatus) {
    case statusMappingService.dataSourceAggregateStatuses.ONLINE:
      description.caption = dataSourceAggregateStatusDescriptions.ONLINE;
      break;
    case statusMappingService.dataSourceAggregateStatuses.OFFLINE:
      description.caption = dataSourceAggregateStatusDescriptions.OFFLINE;
      break;
    default:
      break;
  }

  return description;
};

export {
  cloudClientDescriptions,
  sensorUploadDescriptions,
  sensorDataDescriptions,
  dataSourceDescriptions,
  dataSourceAggregateStatusDescriptions,
  generateCloudClientDescription,
  generateSensorUploadDescription,
  generateSensorDataDescription,
  generateDataSourceDescription,
  generateDataSourceAggregateStatusDescription
}