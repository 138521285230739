import React, { Fragment } from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import ComponentTypes from "../../componentTypes";
import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel, mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";

import { Box, Card, Grid, TextField, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Container, Draggable } from "@edorivai/react-smooth-dnd";

import { getCardFromLayoutConfigViews } from "../../common/layout/layoutHelper";
import { appState as applicationState } from '../../../state/app/appSelectors';
import { componentLifeState } from "../../../state/cards/componentLife/componentLifeSelectors";
import * as componentLifeActions from '../../../state/cards/componentLife/componentLifeActions';

export const extractConfiguration = (componentTypes) => {
  let config = _.cloneDeep(componentTypes);
  return config.map(type => type.name).sort().map(type => ({ type: type, isVisible: true }));
};

const styles = {
    dragIcon: {
        "&:hover": {
            color: 'grey.400',
            transform: 'scale(1.1)'
        }
    }
};

class ComponentLifeConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        defaultTitle: 'COMPONENT LIFE',
        availableSizes:[{w:2, h:2}, {w:3, h:2}, {w:3, h:3}]
    };

    hasConfigChanged(dashboard, view, cardKey) {
      let hasChanges = super.hasConfigChanged(dashboard, view, cardKey);

      const currentDashboard = this.props.dashboards[dashboard];
      const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

      if (_.isNil(originalCard)) return false;

      // if original card has no config, then we must have saved the card into backend without configuring it
      // in that case, we compare against the default config that the app supplies
      const originalConfig = {
        components: originalCard.configuration?.components ?? extractConfiguration(this.props.componentTypes),
        isEvenlySpaced: originalCard.configuration?.isEvenlySpaced ?? true,
      };
      const currentConfig = {
        components: this.props.configuration?.components ?? {},
        isEvenlySpaced: this.props.configuration?.isEvenlySpaced ?? true,
      }
      const hasConfigChanges = !_.isEqual(currentConfig, originalConfig);

      return hasChanges || hasConfigChanges;
    };

    componentDidMount() {
      super.componentDidMount();
      this.props.onQueryComponentTypes('FracPumper');
    };

    componentDidUpdate(prevProps) {
      super.componentDidUpdate(prevProps);

      if (prevProps.stateDef.key !== this.props.stateDef.key)  {
        this.props.onQueryComponentTypes('FracPumper');
      }
    };

    getRenderedContent() {
      return (
        <>
        <Box>
          <Grid container sx={{alignItems: 'center'}}>
            <Grid item>
              <Checkbox
                  checked={this.props.configuration.isEvenlySpaced ?? true}
                  onChange={() => this.props.onToggleJustifyContent()}
              />
            </Grid>
            <Grid item>
              <Typography>Component Types Take Up Entire Card Space</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)", overFlow: 'hidden' }}>
          <Container lockAxis='y' dragHandleSelector=".drag-handle" onDrop={this.props.onReorderComponents}>
            { _.map(this.props.configuration?.components ?? [], (component, index) => { return (
              <Fragment key={index}>
              <Draggable>
                <Card sx={{marginBottom: 1}}>
                  <Grid container alignItems={'center'} justifyContent={'center'} sx={{minHeight:'60px'}}>
                    <Grid item sx={{ width: '310px', height: '100%', paddingLeft: 1}}>
                      <TextField fullWidth placeholder='Name' size='small' variant='standard'
                                 inputProps={{maxLength: 40}}
                                 value={component.type}
                      />
                    </Grid>
                    <Grid item sx={{ width: '85px', height: '100%'}}>
                      <Checkbox
                          checked={component.isVisible}
                          onChange={() => this.props.onToggleComponentVisibility(index)}
                      />
                      <IconButton className="drag-handle" sx={styles.dragIcon} disableRipple>
                        <Tooltip title='Move component' disableInteractive={true}>
                          <DragHandleIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Draggable>
              </Fragment>
            )})}
          </Container>
        </Box>
        </>
      )
    }
}

ComponentLifeConfigPanel.proptypes = mdtCardConfigPanelPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? (props.card?.card?.i ?? ComponentTypes.COMPONENT_LIFE) : props.stateKey,
            type: ComponentTypes.COMPONENT_LIFE,
        }
    }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentLifeState(state[stateDef.key]);
  let appState = applicationState(state);
  let card = getCardFromLayoutConfigViews(appState.user.dashboards[props.card.dashboard]?.views, props.card.view, props.card.stateKey);

  return {
    ...mdtCardConfigMapStateToProps(state, props),
    components: componentState.components,
    componentTypes: componentState.componentTypes,
    configuration: card.configuration, // this.props.card.configuration is not updated, hence doing this way
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardConfigMapDispatchToProps(dispatch, props),
    onReorderComponents: ({ removedIndex, addedIndex }) => {
      dispatch(componentLifeActions.onReorderComponents(props.stateDef, props.card, removedIndex, addedIndex));
    },
    onToggleComponentVisibility: (componentIndex) => {
      dispatch(componentLifeActions.onToggleComponentVisibility(props.stateDef, props.card, componentIndex));
    },
    onToggleJustifyContent: () => {
      dispatch(componentLifeActions.onToggleJustifyContent(props.card));
    },
    onQueryComponentTypes: (unitType) => {
      dispatch(componentLifeActions.onQueryComponentTypes(props.stateDef, unitType, props.card.dashboard, props.card.view, props.card.card.i));
    },
    onDiscardCardConfiguration: (dashboard, view, cardKey) => {
      dispatch(componentLifeActions.onDiscardCardConfiguration(props.stateDef, dashboard, view, cardKey));
    },
  }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(ComponentLifeConfigPanel));