import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class ActiveAlarms extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M6,6.9L3.87,4.78L5.28,3.37L7.4,5.5L6,6.9M13,1V4H11V1H13M20.13,4.78L18,6.9L16.6,5.5L18.72,3.37L20.13,4.78M4.5,10.5V12.5H1.5V10.5H4.5M19.5,10.5H22.5V12.5H19.5V10.5M6,20H18A2,2 0 0,1 20,22H4A2,2 0 0,1 6,20M12,5A6,6 0 0,1 18,11V19H6V11A6,6 0 0,1 12,5Z" />
      </SvgIcon>
    )
  }
}

export default ActiveAlarms;
