import _ from 'lodash';

const evaluateCanSave = (state) => {

  let newState = _.cloneDeep(state);

  // If any of the required fields are empty, return false
  newState.errorDeviceName = _.isEmpty(state.deviceName);

  newState.errorDeviceOwner = _.isNil(state.deviceOwner);

  if (newState.errorDeviceName === true ||
      newState.errorDeviceOwner === true
    ) {
    newState.canSave = false;
  } else {
    newState.canSave = true;
  }

  return newState;
};


export {
  evaluateCanSave
}