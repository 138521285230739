import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ExportCsv extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.triggerExportCsv === false && this.props.triggerExportCsv === true) {
      this.exportAnchor.click();
    }
  }

  render() {
    return (
      <a style={{visibility:'hidden'}} ref={element => this.exportAnchor = element} href={this.props.exportCsvUrl} download>exportAnchor</a>
    );
  }
}

ExportCsv.propTypes = {
  triggerExportCsv: PropTypes.bool,
  exportCsvUrl: PropTypes.string,
};

export default ExportCsv;