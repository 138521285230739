import systemSoftwareActionTypes from '../systemSoftwareActionTypes';

const exportReducer = (state, action) => {

  switch (action.type) {
    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_STARTING:
      return onQueryExportInfoStarting(state, action);

    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_SUCCESS:
      return onQueryExportInfoSuccess(state, action);

    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_ERROR:
      return onQueryExportInfoError(state, action);

    default: return state;
  }
};


const onQueryExportInfoStarting = (state, action) => {
  return {
    ...state,
    dialogOpen: true,
    triggerExportByTruck: false
  };
};

const onQueryExportInfoError = (state, action) => {
  return {
    ...state,
    dialogOpen: false,
    triggerExportByTruck: false
  };
};

const onQueryExportInfoSuccess = (state, action) => {
  return {
    ...state,
    dialogOpen: false,
    exportJobState: action.results.truckConfigExportStatus,
    triggerExportByTruck: true
  };
};

export default exportReducer