import reduceReducers from 'reduce-reducers';

import { dfpDashboardState } from './dfpDashboardSelectors';
import dfpReducer from './reducers/dfpReducer';
import filterReducer from './reducers/filterReducer';

const initialState = dfpDashboardState();

const dfpDashboardReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    dfpReducer,
    filterReducer
  );

  return reducer(state, action);

};

export default dfpDashboardReducer
