import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";
import {LicenseInfo} from "@mui/x-license-pro";

const setLicenses = ()=>{
    //Set SchiChart license
    SciChartSurface.setRuntimeLicenseKey(
        process.env.REACT_APP_SCICHART_RUNTIME_LICENSE
    );

    //Set MUI pro license
    LicenseInfo.setLicenseKey(
        process.env.REACT_APP_MUI_LICENSE
    );
}

export {
    setLicenses
}