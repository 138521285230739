import _ from 'lodash';
import { performDisplayFetch } from '../../common/fetch';

const fetchClientStatus = (fleetId) => {
  return performDisplayFetch({
    'query': '{ clientStatus { fleetId fleetIdentifier fleetName region status isUploading dataUploadStatus datasourceStatus } }'
  }).then((response) => {
    if (!_.isNil(response)) {
      // Attach the fleetId to the response so it can be used to process the clients
      response.fleetId = fleetId;
    }
    return response;
  });
};

export {
  fetchClientStatus,
}