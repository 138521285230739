import _ from 'lodash';
import { tableState } from '../../../../common/table/tableSelectors';

const dgbFleetMapState = state => {

  let tableDetails = tableState(state);

  return {

    ...tableDetails,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    hasActiveData: _.isNil(state) ? null : state.hasActiveData,
    trucks: _.isNil(state) ? [] : state.trucks,
  }

};

export {
  dgbFleetMapState
};