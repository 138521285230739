import _ from "lodash";
import React, { Component } from 'react';
import {withProps,compose} from "recompose";
import {connect} from "react-redux";

import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, DialogContentText, Button } from '@mui/material';

import ComponentTypes from "../../componentTypes";
import { jobHistoryState } from '../../../state/displays/jobHistory/jobHistorySelectors';
import * as jobHistoryActions from '../../../state/displays/jobHistory/jobHistoryActions';

class DeleteJobDialog extends Component {

  render() {
    return (
      <Dialog open={this.props.showDialog} disableEscapeKeyDown>
        <DialogTitle>Delete Job</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete the job with the following name?</DialogContentText>
          <Typography>{_.isNil(this.props.selectedJob) ? '' : this.props.selectedJob.jobName}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onShowDeleteJobDialog(false, this.props.selectedJob)}} color='inherit'>Cancel</Button>
          <Button onClick={() => 
          { 
            this.props.onDeleteJob(this.props.selectedJob.id); 
            this.props.onShowDeleteJobDialog(false, this.props.selectedJob); 
          }} 
          color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_HISTORY : props.stateKey,
      type: ComponentTypes.JOB_HISTORY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobHistoryState(state[stateDef.key]);
  return {
    showDialog: componentState.showDeleteJobDialog,
    selectedJob: componentState.selectedJob,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowDeleteJobDialog: (show, job) => dispatch(jobHistoryActions.showDeleteJobDialog(props.stateDef, show, job)),
    onDeleteJob: (jobId) => { dispatch(jobHistoryActions.deleteJob(props.stateDef, jobId)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DeleteJobDialog));
