import _ from "lodash";
import moment from "moment";

const prettifyValue = (value) => {
    let noValue = '-';
    return !_.isNil(value) ? moment.utc(value*1000).fromNow() : noValue;
};

const resolveSelectedContext = (contexts, contextId) => {

    let selectedContext = null;

    // Validate that we have a context with the requests id
    if (!_.isNil(contextId) && !_.isEmpty(contexts)) {
        let match = _.find(contexts, { id: contextId });
        selectedContext = _.isNil(match) ? null : match;
    }

    // If we did not resolve a selected context, but we do have contexts, set it to the first element.
    if (_.isNil(selectedContext) && !_.isEmpty(contexts)) {
        selectedContext = contexts[0];
    }

    return selectedContext;
};

const processCoilUnits = (coilUnits) => {

    // Map the coil units into context objects for the dashboard UI
    let contexts = mapCoilUnitsToContexts(coilUnits);

    return {
        contexts: contexts,
    };
};

const mapCoilUnitsToContexts = (coilUnits) => {

    let contexts = [];

    if (!_.isNil(coilUnits) && !_.isEmpty(coilUnits)) {
        contexts = _.orderBy(coilUnits, 'name').map(coil => {
            return {
                ...coil,
                // We set the value and label to the same value to work around the text
                // search in the autocomplete control. The text search looks in both the
                // value and the label properties.
                value: coil.name,
                label: coil.name,
                fleet: {id: coil.fleetId, name: coil.fleetName},
            }
        });
    }

    return contexts;
};

export {
    prettifyValue,
    resolveSelectedContext,
    processCoilUnits
}
