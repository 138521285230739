import _ from 'lodash';

const fetchDatGridCardData = (sensors) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let sensorsData = [];
      _.map(sensors, (sensor) => {
        if (sensor.sensorSetId % 3 === 0) {
          sensorsData.push(
            {
              sensorSetId: sensor.sensorSetId,
              latest: _.random(500, 1000)
            }
          )
        }
      })

      resolve({
        dataGrid: {
          series: sensorsData
        }
      })
    }, 1000)
  });

};

export { fetchDatGridCardData }