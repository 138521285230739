import _ from 'lodash';

const processFleets = (fleets) => {

  let processedFleets = [];

  if (!_.isEmpty(fleets)) {
    processedFleets = _.orderBy(fleets, 'name').map(fleet => {
      return {
        ...fleet,
        value: fleet.name,
        label: fleet.name,
      }
    });
  }

  return processedFleets;
};

export {
  processFleets
}