import componentsActionTypes from '../componentsActionTypes';
import * as componentsService from '../services/componentsService';
import * as validationService from '../services/validataionService';

const queryReducer = (state, action) => {

  switch (action.type) {

    case componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_STARTING:
      return onQueryDisplayDataStarting(state, action);

    case componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_SUCCESS:
      return onQueryDisplayDataSuccess(state, action);

    case componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_ERROR:
      return onQueryDisplayDataError(state, action);

    case componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_STARTING:
      return onQueryComponentDetailsStarting(state, action);

    case componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_SUCCESS:
      return onQueryComponentDetailsSuccess(state, action);

    case componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_ERROR:
      return onQueryComponentDetailsError(state, action);

    case componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_STARTING:
      return onCalculatePumpHoursStarting(state, action);

    case componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_SUCCESS:
      return onCalculatePumpHoursSuccess(state, action);

    case componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_ERROR:
      return onCalculatePumpHoursError(state, action);

    default: return state;

  }
};

const  onQueryDisplayDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const  onQueryDisplayDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onQueryDisplayDataSuccess = (state, action) => {

  let unitDetails = componentsService.processUnits(action.queryResults.componentsDisplay.units, state.appliedFilters);

  return {
    ...state,
    queryRunning: false,
    units: action.queryResults.componentsDisplay.units,
    filteredUnits: unitDetails.filteredUnits,
    filters: unitDetails.filters,
  };
};

const  onQueryComponentDetailsStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const  onQueryComponentDetailsError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};


const onQueryComponentDetailsSuccess = (state, action) => {

  let details = componentsService.processComponentDetails(action.queryResults.componentsDisplayComponentDetails);

  return {
    ...state,
    queryRunning: false,
    componentTypes: details.componentTypes,
    components: details.components,
  };

};

const  onCalculatePumpHoursStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
    openCalculator: false,
  };
};

const  onCalculatePumpHoursError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onCalculatePumpHoursSuccess = (state, action) => {

  let newState = {
    ...state,
    queryRunning: false,
    selectedSnapshotStart: action.queryResults.estimatedPumpHours.estimatedPumpHours,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

export default queryReducer