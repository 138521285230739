import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import { tableState } from '../../common/table/tableSelectors';
import { exportCsvState } from'../../common/exportCsv/exportCSVSelectors';

const alarmsState = state => {

  let filterDetails = filterState(state);
  let tableDetails = tableState(state);
  let exportCsvDetails = exportCsvState(state);

  return {

    ...filterDetails,
    ...tableDetails,
    ...exportCsvDetails,

    // Flag to indicate a remote call is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    // Alarms returned from the service
    alarms: _.isNil(state) ? [] : state.alarms,

    // Alarms displayed in the UI
    filteredAlarms: _.isNil(state) ? [] : state.filteredAlarms,

    // Default to viewing active alarms
    showingActiveAlarms: _.isNil(state) ? true : state.showingActiveAlarms,

    // Flag to indicate if message alarms should be visible in the UI
    excludeMessageAlarms: _.isNil(state) ? true : state.excludeMessageAlarms,

    // Default sort context
    sortContext:  _.isNil(state) ? 'severity' : state.sortContext,
    secondarySortDefinitions: _.isNil(state) ? [
      { context: 'severity', direction: 'asc' }
    ] : state.secondarySortDefinitions,

    timeFrames: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: 'Last 6 hours' },
      { value: 720, label: 'Last 12 hours' },
      { value: 1440, label: 'Last day' },
      { value: null, label: 'Custom' },
    ] : state.timeFrames,

    customDurations: _.isNil(state) ? [
      { value: 60, label: '1 hour' },
      { value: 360, label: '6 hours' },
      { value: 720, label: '12 hours' },
      { value: 1440, label: '1 day' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: 60, label: 'Last hour' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 60, label: '1 hour' } : state.selectedCustomDuration,

    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay
  };

};

export {
  alarmsState
};