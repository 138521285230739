import React, { Component } from 'react';
import _ from "lodash";
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Dialog, DialogContent, DialogActions,DialogTitle,Typography, DialogContentText, Button } from '@mui/material';

import * as userMgmtDeleteDialogActions from '../../../state/displays/userMgmt/actions/userMgmtDeleteDialogActions';
import { userMgmtState } from '../../../state/displays/userMgmt/userMgmtSelectors';
import ComponentTypes from "../../componentTypes";

class UserMgmtDeleteConfirmationDialog extends Component {

  render() {
    return (
      <Dialog open={this.props.openDeleteUserUI} disableEscapeKeyDown>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete user account with the following email?</DialogContentText>
          <Typography>{this.props.workingUser.email}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.closeDeleteConfirmation()}} color='inherit'>Cancel</Button>
          <Button onClick={() => { this.props.deleteUser(this.props.workingUser.id)}} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.USER_MANAGEMENT : props.stateKey,
      type: ComponentTypes.USER_MANAGEMENT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = userMgmtState(state[stateDef.key]);
  return {
    openDeleteUserUI: componentState.openDeleteUserUI,
    workingUser: componentState.workingUser,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeDeleteConfirmation: () => dispatch(userMgmtDeleteDialogActions.closeDeleteConfirmation(props.stateDef)),
    deleteUser: (userId) => dispatch(userMgmtDeleteDialogActions.deleteUser(props.stateDef, userId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UserMgmtDeleteConfirmationDialog))
