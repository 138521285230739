import _ from 'lodash';

const fracOverviewCardState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    openMenu: _.isNil(state) ? false : state.openMenu,
    menuTargetElement: _.isNil(state) ? null : state.menuTargetElement,
    fleetFracOverview: _.isNil(state) ? {} : state.fleetFracOverview
  };

};

export {
  fracOverviewCardState
};