import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Box, Button, FormControlLabel, Switch, Typography, Divider } from '@mui/material';

import ComponentTypes from "../../componentTypes";
import { ownerManagementState } from '../../../state/displays/ownerManagement/ownerManagementSelectors';
import * as ownerManagementActions from '../../../state/displays/ownerManagement/ownerManagementActions';

class CreateOwnerDialog extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Dialog open={this.props.showCreateOwnerDialog} disableEscapeKeyDown PaperProps={{ sx: { minWidth: '500px' }}}>
        <DialogTitle>Create a New Owner</DialogTitle>
        <DialogContent>
          <Box sx={{width: '450px', display: 'flex', flexFlow: 'column nowrap'}}>
            <TextField
              variant='standard'
              type='text'
              helperText='Owner Name'
              value={this.props.createOwnerName}
              onChange={(event) => { this.props.onSetCreateOwnerName(event.target.value); }}
              error={this.props.hasSensorGroupNameError}
              onFocus={(e) => e.currentTarget.select()}
              InputProps={{
                inputProps: {
                  maxLength: 255,
                  autoFocus: true
                }
              }}
              />
            <FormControlLabel 
              sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
              label={<Typography variant='body2'>Active</Typography>}
              labelPlacement="start" 
              control={<Switch color='primary'/>} 
              onChange={(event) => {}}
            />
            <Divider />
            <Box sx={{display: 'flex', flexFlow: 'column nowrap', marginTop: 1}}>
              <Typography variant='subtitle1'>This Owner has:</Typography>
              <Box sx={{display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start'}}>
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>Frac Pumps</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary'/>} 
                  onChange={(event) => {}}
                />
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>Blenders</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary'/>} 
                  onChange={(event) => {}}
                />
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>Chemvans</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary'/>} 
                  onChange={(event) => {}}
                />
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>Hydration</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary' />} 
                  onChange={(event) => {}}
                />
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>DFPs</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary' />} 
                  onChange={(event) => {}}
                />
                <FormControlLabel 
                  sx={{justifyContent: 'space-between', marginLeft: 2, paddingRight: 24, width: '100%'}}
                  label={<Typography variant='body2'>Oil/Lube Change Tracking</Typography>}
                  labelPlacement="start" 
                  control={<Switch color='primary' />} 
                  onChange={(event) => {}}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
              sx={{
                backgroundColor:'grey.200',
                color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                '&:hover': {
                  backgroundColor:'grey.400',
                  color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                }
              }}
              disableRipple 
              variant='contained' 
              onClick={() => { this.props.onShowCreateOwnerDialog(false)}} 
              >
              CANCEL
            </Button>
          <Button onClick={() => { this.props.onShowCreateOwnerDialog(false)}}  variant='contained' >Save</Button>
        </DialogActions>
      </Dialog>
    )
  }

};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.OWNER_MANAGEMENT : props.stateKey,
      type: ComponentTypes.OWNER_MANAGEMENT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = ownerManagementState(state[stateDef.key]);
  return {
    showCreateOwnerDialog: componentState.showCreateOwnerDialog, 
    createOwnerName: componentState.createOwnerName,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowCreateOwnerDialog: (show) => { dispatch(ownerManagementActions.showCreateOwnerDialog(props.stateDef, show)); },
    onSetCreateOwnerName: (name) => { dispatch(ownerManagementActions.setCreateOwnerName(props.stateDef, name)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CreateOwnerDialog));