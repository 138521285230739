import reduceReducers from 'reduce-reducers';

import { userMgmtState } from './userMgmtSelectors';

import userMgmtDeleteDialogReducer from './reducers/userMgmtDeleteDialogReducer';
import userMgmtDisplayReducer from './reducers/userMgmtDisplayReducer';
import userMgmtUserDialogReducer from './reducers/userMgmtUserDialogReducer';

const initialState = userMgmtState();

const userMgmtStateReducer = (state=initialState, action) => {
  const reducer = reduceReducers(
    userMgmtDeleteDialogReducer,
    userMgmtDisplayReducer,
    userMgmtUserDialogReducer
  );
  return reducer(state, action);
};

export default userMgmtStateReducer;

