import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';

import appActionTypes from '../appActionTypes';
import { push } from 'connected-react-router';

const openDrawer = defaultActionFactory(appActionTypes.APP_OPEN_DRAWER);
const closeDrawer = defaultActionFactory(appActionTypes.APP_CLOSE_DRAWER);

const setNavigationContext = defaultActionFactory(appActionTypes.APP_SET_NAVIGATION_CONTEXT, 'contextKey', 'contextData');

const navigate = (url, context) => {
  return async (dispatch, getState) => {
    // Force null for an undefined context as it makes debugging easier in the redux
    // debug window. Undefined properties do not show up in the debug window.
    let contextData = _.isNil(context) ? null : context;
    await dispatch(setNavigationContext(url, contextData));
    return dispatch(push(url));
  }
};

const resetUrl = (url) => {
  return async (dispatch, getState) => {
    return dispatch(push(url));
  }
}

export {
  openDrawer,
  closeDrawer,
  navigate,
  setNavigationContext,
  resetUrl
};