import moment from "moment";

const fetchCustomersDisplayData = () => {

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                customers:
                    [
                        {
                            id: 1,
                            name: 'Conoco',
                            contactName: 'name1',
                            email: 'test1@fake.com',
                            phone: '+140312345678',
                            location: 'location1',
                            note: 'note1'
                        },
                        {
                            id: 2,
                            name: 'Devon Energy',
                            contactName: 'name2',
                            email: 'test2@fake.com',
                            phone: '+140312345678',
                            location: 'location2',
                            note: 'note2'
                        },
                        {
                            id: 3,
                            name: 'Chevon',
                            contactName: 'name3',
                            email: 'test3@fake.com',
                            phone: '+140312345678',
                            location: 'location3',
                            note: 'note3'
                        }
                    ]
            })
        }, 10)
    });
};

const fetchJobDataByCustomerId = (customerId) => {
    const jobs = [
        {
            id: 1,
            jobs: [{
                id: 1,
                name: "J-1234",
                createdOn: moment().subtract(1, 'days').unix()
            }, {
                id: 2,
                name: "J-2345",
                createdOn: moment().subtract(1, 'months').unix()
            }, {
                id: 3,
                name: "J-3456",
                createdOn: moment().subtract(1, 'months').unix()
            }]
        },
        {
            id: 6,
            jobs: [{
                id: 111,
                name: "J-4567",
                createdOn: moment().subtract(1, 'days').unix()
            }]
        },
        {
            id: 2,
            jobs: [{
                id: 4,
                name: "J-1234AB",
                createdOn: moment().subtract(1, 'days').unix()
            }, {
                id: 5,
                name: "J-2345CD",
                createdOn: moment().subtract(1, 'months').unix()
            }]
        },
        {
            id: 3,
            jobs: [{
                id: 6,
                name: "J-1234E"
            }, {
                id: 7,
                name: "J-2345F"
            }, {
                id: 8,
                name: "J-3456G"
            }, {
                id: 9,
                name: "J-3456H"
            }, {
                id: 10,
                name: "J-3456I"
            }, {
                id: 11,
                name: "J-3456J"
            }, {
                id: 12,
                name: "J-3456K"
            }, {
                id: 13,
                name: "J-3456M"
            }, {
                id: 14,
                name: "J-3456N"
            }, {
                id: 15,
                name: "J-34560"
            }, {
                id: 16,
                name: "J-3456P"
            }, {
                id: 17,
                name: "J-3456Q"
            }, {
                id: 18,
                name: "J-3456R"
            }, {
                id: 19,
                name: "J-3456S"
            }, {
                id: 20,
                name: "J-3456T"
            }, {
                id: 21,
                name: "J-3456U"
            }, {
                id: 22,
                name: "J-3456V"
            }, {
                id: 23,
                name: "J-3456W"
            }, {
                id: 24,
                name: "J-3456X"
            }, {
                id: 25,
                name: "J-3456Y"
            }]
        }
    ];
    //Randomly pick on jobs if there's no matching found
    customerId = jobs.find(j => j.id === customerId) ? customerId : jobs[Math.floor((Math.random() * jobs.length))].id;
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                jobsByCustomerId: jobs.find(j => j.id === customerId).jobs
            })
        }, 10)
    });
}
export {
    fetchCustomersDisplayData,
    fetchJobDataByCustomerId
}
