import _ from 'lodash';

import { filterState } from '../../common/filtering/filterSelectors';
import { tableState } from '../../common/table/tableSelectors';

const clientStatusState = state => {

  let filterDetails = filterState(state);
  let tableDetails = tableState(state);

  return {

    ...filterDetails,
    ...tableDetails,

    // Override the default sort context
    sortContext: _.isNil(state) ? 'fleetName' : state.sortContext,

    // Raw clients returned from the service
    clients: _.isNil(state) ? [] : state.clients,

    // Displayed client list
    filteredClients: _.isNil(state) ? [] : state.filteredClients,

    // Flag to indicate client details dialog is visible
    openDetailsUI: _.isNil(state) ? false : state.openDetailsUI,

    // Client that is used as the context for the details UI
    selectedClient: _.isNil(state) ? null : state.selectedClient,

    // The client details for the selected client
    selectedClientDetails : _.isNil(state) ? {} : state.selectedClientDetails,

    // Flag to indicate the client query is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    // Flag to indicate the client details query is executing
    detailsQueryRunning: _.isNil(state) ? false : state.detailsQueryRunning,

    // Flag to indicate the client details query failed
    detailsQueryFailed: _.isNil(state) ? false : state.detailsQueryFailed,

    // An identifier associated with the most current client details query. If this id does not
    // match the value in the state when a query returns the response is ignored.
    queryTransactionId: _.isNil(state) ? null : state.queryTransactionId,
  };

};

export {
  clientStatusState
};