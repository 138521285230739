import _ from 'lodash';
import unitHoursActionTypes from './unitHoursActionTypes';
import { unitHoursState, warmStartDefaultLabel } from "./unitHoursSelectors";
import abbreviateLabel from './services/warmStartMetricsServices';

const initialState = unitHoursState();

const warmStartLabel = warmStartDefaultLabel();

const unitHoursReducer = (state=initialState, action) => {

  switch (action.type) {

    case unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_SUCCESS:
      return onQuerySuccess(state, action);

    case unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case unitHoursActionTypes.UNIT_HOURS_CLEAR_DATA:
      return onClearData(state, action);

    case unitHoursActionTypes.UNIT_HOURS_METRIC_INDEX_CHANGED: 
      return onMetricsIndexChanged(state, action);

    default: return state;

  }

};

const onClearData = (state, action) => {

  const noValue = '-';

  return { 
    ...state, 
    ecmHours: noValue, 
    pumpHours: noValue, 
    tcmHours: noValue, 
    warmStartState: noValue,  
    warmStartMetrics: [],
    warmStartMetricsLabel: warmStartLabel,
    warmStartMetricsIndex: 0,
  };

}

const onQuerySuccess = (state, action) => {
  const noValue = '-';

  let { ecmHours, pumpHours, tcmHours, warmStart: { warmStartState, data } } = action.queryResults.unitHours;

  ecmHours = _.isNil(ecmHours) ? noValue : ecmHours;
  pumpHours = _.isNil(pumpHours) ? noValue : pumpHours;
  tcmHours = _.isNil(tcmHours) ? noValue : tcmHours;
  warmStartState = _.isNil(warmStartState) ? noValue : warmStartState;
  let warmStartMetrics = _.isEmpty(data) ? data : data.map(metric => ({
    ...metric, 'label': warmStartLabel + ' - ' + abbreviateLabel(metric.type)
  }));
  let defaultWarmStartMetricsIndex = _.isNil(state.warmStartMetricsIndex) ? 0 : state.warmStartMetricsIndex;
  let abbreviatedLabel = abbreviateLabel(data[defaultWarmStartMetricsIndex].type);
  let warmStartMetricsLabel = _.isEmpty(data) ?  state.warmStartMetricsLabel : warmStartLabel + ' - ' + abbreviatedLabel;

  return {
    ...state,
    queryRunning: false,
    ecmHours: ecmHours,
    pumpHours: pumpHours,
    tcmHours: tcmHours,
    warmStartState: warmStartState,
    warmStartMetrics: warmStartMetrics,
    warmStartMetricsLabel: warmStartMetricsLabel,
  };
};

const onMetricsIndexChanged = (state, action) => {

  let newIndex = _.isNil(action.index) ? 0 : action.index;
  let abbreviatedLabel = abbreviateLabel(state.warmStartMetrics[newIndex].type);

  let newState = {
    ...state,
    warmStartMetricsLabel: _.isEmpty(state.warmStartMetrics) ?  state.warmStartMetricsLabel : warmStartLabel + ' - ' + abbreviatedLabel,
  }

  if (newIndex != state.warmStartMetricsIndex) {
    return {
      ...newState,
      warmStartMetricsIndex: newIndex
    }
  }
  
  return newState;
};

export default unitHoursReducer