import _ from 'lodash';
import moment from 'moment';
import * as filterService from '../../../common/filtering/filterService';
import { exportState } from '../exportSelectors';

const initialState = exportState();

const MAXIMUM_SENSOR_MINUTES = 93600; // 65 sensors * 1440 minutes (24hrs)

const resolveCanExport = (state) => {

  // Validate the state data

  if (_.isNil(state.selectedTruck) || _.isEmpty(state.selectedTruck)) {
    return false;
  } else if (_.isNil(state.selectedDateTime)) {
    return false;
  } else if (_.isNil(state.selectedDuration) || _.isEmpty(state.selectedDuration)) {
    return false;
  } else if (_.isNil(state.selectedTimezone) || _.isEmpty(state.selectedTimezone)) {
    return false;
  } else if (_.isNil(state.dataRanges) || _.isEmpty(state.dataRanges)) {
    return false;
  } else if (state.exceededMaximumDuration) {
    return false;
  } else if (_.isEmpty(state.selectedSensors)) {
    return false; 
  } else if (state.truckDetailsQueryRunning === true) {
    return false;
  }

  // Check to see if there is data in the export window (selectedDateTime + duration)

  return isDataInExportWindow(state.selectedDateTime, state.dataRanges, state.selectedDuration);
};

const processTrucks = (trucks, filters, selectedTruck) => {

  // Map the trucks into context objects for the dashboard UI
  let contexts = mapTrucksToContexts(trucks);
  // Filter the truck contexts by the current filters (in order)
  let filteredTrucks = filterService.filterItems(contexts, filters);
  // Generate filter data from the filtered truck list
  let updatedFilters = filterService.generateFilters(filteredTrucks, filterDefinitions());

  let updatedSelectedTruck = _.isEmpty(filteredTrucks) ? null : filteredTrucks[0];

  // Check if the currently selected truck is still in the filtered trucks list
  if (!_.isNil(selectedTruck) && _.find(filteredTrucks, (truck) => truck.id === selectedTruck.id)) {
    updatedSelectedTruck = selectedTruck;
  }

  return {
    filteredTrucks: filteredTrucks,
    filters: updatedFilters,
    selectedTruck: updatedSelectedTruck
  };

};

const mapTrucksToContexts = (trucks) => {

  let contexts = [];

  if (!_.isNil(trucks) && !_.isEmpty(trucks)) {
    contexts = _.orderBy(trucks, 'truckName').map(truck => {
      return {
        ...truck,
        // We set the value and label to the same value to work around the text
        // search in the autocomplete control. The text search looks in both the
        // value and the label properties.
        value: truck.truckName,
        label: truck.truckName,
      }
    });
  }

  return contexts;
};

const isDataInExportWindow = (dateTime, dataRanges, duration) => {

  if (_.isNil(dateTime) || _.isNil(dataRanges) || _.isNil(duration)) {
    return false;
  }

  let leftTime = dateTime;
  let rightTime = moment(leftTime).add(duration.value, 'minutes');

  for (let i=0, len=dataRanges.length; i<len; i++) {

    let range = dataRanges[i];

    let rangeStart = moment.unix(range.startTime);
    let rangeEnd = moment.unix(range.endTime);

    if ((rangeStart.isSameOrAfter(leftTime, 'minutes') && rangeStart.isSameOrBefore(rightTime, 'minutes')) ||
        (rangeEnd.isSameOrAfter(leftTime, 'minutes') && rangeEnd.isSameOrBefore(rightTime, 'minutes')) ||
        (leftTime.isSameOrAfter(rangeStart, 'minutes') && rightTime.isSameOrBefore(rangeEnd, 'minutes'))) {

      return true;

    }

  }

  return false;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Type', 'type'));
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createFilterDefinition('Division', 'division'));
  definitions.push(filterService.createFilterDefinition('Unit ID', 'truckName'));
  return definitions;
};

const calculateSensorMinutes = (sensorCount, durationMinutes) => {

  if (_.isNil(sensorCount) || _.isNil(durationMinutes)) {
    return 0;
  }

  return sensorCount * durationMinutes;
};

const hasExceededMaximumDuration = (state) => {

  if (_.isEmpty(state.selectedSensors)) {
    return false;
  }

  if (_.isNil(state.selectedDuration) || _.isNil(state.selectedDuration.value)) {
    return false;
  }

  return calculateSensorMinutes(state.selectedSensors.length, state.selectedDuration.value) > MAXIMUM_SENSOR_MINUTES;
};

const getMaximumDurationLabel = (state) => {

  let durations = initialState.durations;

  if (!hasExceededMaximumDuration(state)) {
    return (durations[durations.length-1].value / 60).toString();
  }

  // Try and find a duration that works for the current truck selection

  // Use all sensors if none are selected via the sensor selector
  const sensors = (_.isNil(state.selectedSensors) || _.isEmpty(state.selectedSensors)) ? state.sensors : state.selectedSensors;

  for (let len=durations.length, i=len-1; i>=0; i--) {
    let duration = durations[i];
    if (calculateSensorMinutes(sensors.length, duration.value) <= MAXIMUM_SENSOR_MINUTES) {
      return duration.label;
    }
  }

  return null;
};

/**
 * Given a collection of Selected Sensors from the Sensor Selector, create a collection that can 
 * be supplied to the Export Input object so the Export only contains those sensors
 * @param {*} selectedSensors Selected Sensors from Sensor Selector in the 'MDT' namespace
 */
const createSensorsCollectionForExportInput = (selectedSensors) => {

  return _.map(selectedSensors, (selectedSensor) => {
    return {
      sensorSetId: selectedSensor.sensorSetId,
      alias: selectedSensor.alias,
      uom: selectedSensor.uom
    }
  });
}

export {
  resolveCanExport,
  processTrucks,
  isDataInExportWindow,
  calculateSensorMinutes,
  hasExceededMaximumDuration,
  getMaximumDurationLabel,
  createSensorsCollectionForExportInput
}