import React, {Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';

import getDataGridStyles from "../styles/dataGridStyle";

const dataGridStyles = getDataGridStyles();

class MDTDataGrid extends Component {

  render() {
    return (
        <DataGridPro
            sx={dataGridStyles.root}
            rowsPerPageOptions={this.props.rowsPerPageOptions}
            pageSize={this.props.rowsPerPage}
            onPageSizeChange={(newPageSize) => this.props.setRowsPerPage(newPageSize)}
            pagination
            autoHeight
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,    //Default set to enable quick search bar
                    printOptions: {
                        hideToolbar: true,    //Default set to hide toolbar and footer for printing
                        hideFooter: true
                    },
                    csvOptions : {
                        fileName: _.isNil(this.props.exportFileName)? document.title: this.props.exportFileName
                    }
                },
            }}
            disableDensitySelector
            components={{
                Toolbar: GridToolbar, //Default set to enable the toolbar
            }}
            {...this.props}       //Allow props override
        />
    )
  }
}

MDTDataGrid.propTypes = {
    exportFileName: PropTypes.string,
    stateDef: PropTypes.object.isRequired,
    rowsPerPage: PropTypes.number,
    setRowsPerPage: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
}

export default MDTDataGrid;