import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import { ButtonBase } from '@mui/material'
import AlarmsIcon from '@mui/icons-material/Alarm';
import ComponentTypes from "../../componentTypes";
import AlarmCountCard from '../common/alarmCountCard/alarmCountCard';
import * as alarmActions from '../../../state/displays/alarms/alarmsActions';
import * as alarmCountActions from '../../../state/cards/alarmCount/alarmCountActions';
import alarmCountState from '../../../state/cards/alarmCount/alarmCountSelectors';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";
import { appState as applicationState } from '../../../state/app/appSelectors';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import {getCardFromLayoutConfigViews} from "../../common/layout/layoutHelper";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
};

class UnitAlarmCount extends MDTCard {

  constructor(props) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  getName() {
    return 'ACTIVE ALARMS';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  onNavigate() {
    if (this.isContextReady()) {
      return this.props.navigateToDashboard(this.props.name);
    }
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck.id);
    }
  }

  getRenderedButtonsInHeader() {
    return (
      this.isContextReady() &&
      !this.isInEditMode() &&
      <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.onNavigate()}><AlarmsIcon/></ButtonBase>
    )
  }

  getRenderedContent() {
    return (
      <AlarmCountCard alarmCounts={this.props.alarmCounts} cardConfig={this.props.card} stateDef={this.props.stateDef} />
    )
  }
}

UnitAlarmCount.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_ALARM_COUNT : props.stateKey,
      type: ComponentTypes.UNIT_ALARM_COUNT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let componentState = alarmCountState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    ...mdtCardMapStateToProps(state, props),
    alarmCounts: componentState.alarmCounts,
    name: componentState.name,
    card: getCardFromLayoutConfigViews(appState.user.dashboards[props.dashboard]?.views, props.view, props.cardKey),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(alarmCountActions.queryData(props.stateDef, context, false)) },
    clearData: () => { dispatch(alarmCountActions.clearData(props.stateDef)) },
    navigateToDashboard: (name) => dispatch(alarmActions.navigateByTruck(name)),
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UnitAlarmCount));