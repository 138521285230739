import _ from 'lodash';
import deviceActionTypes from '../actionTypes/deviceActionTypes';

const deviceReducer = (state, action) => {

  switch (action.type) {

    case deviceActionTypes.SETTINGS_DEVICE_QUERY_STARTING:
      return onDeviceQueryStarting(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_QUERY_ERROR:
      return onDeviceQueryError(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_QUERY_SUCCESS:
      return onDeviceQuerySuccess(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_STARTING:
      return onDevicePairStarting(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_SUCCESS:
      return onDevicePairSuccess(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_ERROR:
      return onDevicePairError(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_SAVE_STARTING:
      return onDeviceSaveStarting(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_SAVE_ERROR:
      return onDeviceSaveError(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_SAVE_SUCCESS:
      return onDeviceSaveSuccess(state, action);

    case deviceActionTypes.SETTINGS_SHOW_DEVICE_DIALOG:
      return onShowDeviceDialog(state, action);

    case deviceActionTypes.SETTINGS_DEVICE_SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);

    case deviceActionTypes.SETTINGS_TRUCK_QUERY_STARTING:
      return onTrucksQueryStarting(state, action);

    case deviceActionTypes.SETTINGS_TRUCK_QUERY_ERROR:
      return onTrucksQueryError(state, action);

    case deviceActionTypes.SETTINGS_TRUCK_QUERY_SUCCESS:
      return onTrucksQuerySuccess(state, action);

    default: return state;
  }
};

const onDeviceQueryStarting = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      loadingDeviceSetting: true,
      queryRunning: true,
    }
  };
};

const onDeviceQueryError = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      loadingDeviceSetting: false,
      queryRunning: false,
    }
  };
};

const onDeviceQuerySuccess = (state, action) => {

  return {
    ...state,
    device: {
      ...state.device,
      loadingDeviceSetting: false,
      queryRunning: false,
      deviceSettings: action.queryResults.allDevices
    }
  };
};

const onTrucksQueryStarting = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      loadingTrucksForOwner: true,
      queryRunning: true,
    }
  };
};

const onTrucksQueryError = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      loadingTrucksForOwner: false,
      queryRunning: false,
    }
  };
};

const onTrucksQuerySuccess = (state, action) => {

  let truckInfos = action.queryResults.trucksForDevice;
  let trucks = [];
  if (!_.isEmpty(truckInfos)) {
    trucks = _.orderBy(truckInfos, 'name').map(truck => ({
      value: truck.truckPid,
      label: truck.truckName
    }));
  }
  //provide an option to unassign the truck
  trucks.unshift({ value: null, label: 'No Truck Assigned' });
  return {
    ...state,
    device: {
      ...state.device,
      loadingTrucksForOwner: false,
      queryRunning: false,
      trucks: trucks
    }
  };
};

const onDeviceSaveStarting = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      queryRunning: true,
    }
  };
};

const onDeviceSaveError = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      queryRunning: false,
    }
  };
};

const onDeviceSaveSuccess = (state, action) => {
  const updatedDeviceSettings = _.cloneDeep(state.device.deviceSettings);

  // Update the saved device
  let foundIndex = _.findIndex(updatedDeviceSettings, ['id', action.queryResults.saveDevice.id]);
  if (!_.isNil(updatedDeviceSettings[foundIndex])) {
    updatedDeviceSettings[foundIndex] = action.queryResults.saveDevice;
  } else {
    updatedDeviceSettings.push( action.queryResults.saveDevice);
  }

  return {
    ...state,
    device: {
      ...state.device,
      deviceSettings: updatedDeviceSettings,
      showDeviceDialog: false, // Close the dialog if save triggered from dialog
      queryRunning: false,
    },
  }
};

const onDevicePairStarting = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      queryRunning: true,
    }
  };
};

const onDevicePairError = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      queryRunning: false,
    }
  };
};

const onDevicePairSuccess = (state, action) => {
  const esn = action.esn;
  const newTruckPid = action.newTruckPid;
  const updatedDeviceSettings = _.cloneDeep(state.device.deviceSettings);

  // Update the saved device
  let foundIndex = _.findIndex(updatedDeviceSettings, ['esn', esn]);
  updatedDeviceSettings[foundIndex].truckPid = newTruckPid;

  return {
    ...state,
    device: {
      ...state.device,
      deviceSettings: updatedDeviceSettings,
      queryRunning: false,
    }
  }
};

const onShowDeviceDialog = (state, action) => {
  return {
    ...state,
    device: {
      ...state.device,
      showDeviceDialog: action.show
    }
  }
}

const onDataGridSetRowSize = (state, action) => {
  
  return {
    ...state,
    device: {
      ...state.device,
      rowsPerPage: action.rowSize,
    }
  }

}


export default deviceReducer