import _ from 'lodash';

import ComponentTypes from '../components/componentTypes';

import UnitChartEngine from '../components/cards/unitChart/unitChartEngine';
import UnitChartTransmission from '../components/cards/unitChart/unitChartTransmission';
import UnitChartPowerEnd from '../components/cards/unitChart/unitChartPowerEnd';
import UnitChartLockup from '../components/cards/unitChart/unitChartLockup';
import UnitHours from '../components/cards/unitHours/unitHours';
import UnitActivity from '../components/cards/unitActivity/unitActivity';
import UnitFleet from '../components/cards/unitFleet/unitFleet';
import ComponentLife from '../components/cards/componentLife/componentLife';
import UnitAlarmCount from '../components/cards/unitAlarmCount/unitAlarmCount';
import UnitPressureHistory from '../components/cards/unitChart/unitChartPressureHistory';
import UnitChartVibrationHistory from '../components/cards/unitChart/unitChartVibrationHistory'
import UnitOutputHistory from '../components/cards/unitChart/unitChartOutputHistory';
import UnitUserChart from '../components/cards/unitUserChart/unitUserChart';
import DataGrid from '../components/cards/dataGrid/dataGrid'
import UnitAuditLog from "../components/cards/unitAuditLog/unitAuditLog";

import FleetActivity from '../components/cards/fleetActivity/fleetActivity';
import FleetPumpStatus from '../components/cards/fleetPumpStatus/fleetPumpStatus';
import FleetPRCHistory from '../components/cards/fleetPRCHistory/fleetPRCHistory';
import FleetClientStatus from '../components/cards/fleetClientStatus/fleetClientStatus';
import FleetAlarmCount from '../components/cards/fleetAlarmCount/fleetAlarmCount';
import FleetComponents from '../components/cards/fleetComponents/fleetComponents';
import FleetOperation from '../components/cards/fleetOperation/fleetOperation';
import FleetVibration from '../components/cards/fleetVibration/fleetVibration';
import FleetAuditLog from '../components/cards/fleetAuditLog/fleetAuditLog';

/**
 * Given a layout of cards, return an element based on the Component Type of each card.
 * This is dashboard agnostic so it handles all types of cards and the dashboard and view the card belongs to will be passed to the card.
 * @param {*} dashboard Which Dashboard the View belongs to
 * @param {*} viewId Which View is holding this card
 * @param {*} cardsLayout Layout of cards
 * @param {*} selectedContext Contextual info for cards to reference
 * @param {*} editMode View Edit Mode
 * @param {*} selectedCardType selected card for adding
 * @param {*} activeCardConfig context of card that is in config editing
 * @param {*} cardQueryRunningCallback optional callback to signal to the parent of the cards that a card is running a query
 */
const getCardElementsFromLayout = (dashboard, viewId, cardsLayout, selectedContext, editMode, selectedCardType, activeCardConfig, cardQueryRunningCallback, cardsMetaConfig, navigateToViewCallback) => {

  let cardElements = _.map(cardsLayout, (card) => {

    // Get meta configuration of the card
    const cardMetaConfig = getCardMetaConfig(cardsMetaConfig, card.type);
    
    const shouldCardHighlight = _.isNil(activeCardConfig?.card)? selectedCardType === card.type : activeCardConfig.card.i === card.i;
    //The card can't be removed when the card is in editing the configuration
    const removable = !(!_.isNil(activeCardConfig?.card) &&
                          activeCardConfig.dashboard === dashboard &&
                          activeCardConfig.view === viewId &&
                          activeCardConfig.card.i === card.i);
    const editable = _.isNil(activeCardConfig?.card);
    switch (card.type) {
      // UNIT
      case ComponentTypes.UNIT_HOURS:
        return { key: card.i, element: (<UnitHours stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_ACTIVITY:
        return { key: card.i, element: (<UnitActivity stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_PRESSURE_HISTORY:
        return { key: card.i, element: (<UnitPressureHistory stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_FLEET:
        return { key: card.i, element: (<UnitFleet stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.COMPONENT_LIFE:
        return { key: card.i, element: (<ComponentLife stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_ALARM_COUNT:
        return { key: card.i, element: (<UnitAlarmCount stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_VIBRATION_HISTORY:
        return { key: card.i, element: (<UnitChartVibrationHistory stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_OUTPUT_HISTORY:
        return { key: card.i, element: (<UnitOutputHistory stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_ENGINE:
        return { key: card.i, element: (<UnitChartEngine stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_TRANSMISSION:
        return { key: card.i, element: (<UnitChartTransmission stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_POWER_END:
        return { key: card.i, element: (<UnitChartPowerEnd stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_CHART_LOCKUP:
        return { key: card.i, element: (<UnitChartLockup stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_USER_CHART:
        return { key: card.i, element: (<UnitUserChart stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} cardQueryRunningCallback={cardQueryRunningCallback} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.DATA_GRID:
        return { key: card.i, element: (<DataGrid stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.UNIT_AUDIT_LOG:
        return { key: card.i, element: (<UnitAuditLog stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};

      // FLEET
      case ComponentTypes.FLEET_PRC_HISTORY:
        return { key: card.i, element: (<FleetPRCHistory stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_ACTIVITY:
        return { key: card.i, element: (<FleetActivity stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_PUMP_STATUS:
        return { key: card.i, element: (<FleetPumpStatus selectedContext={selectedContext.truck} />)}; // NOTE: This card isn't really used 
      case ComponentTypes.FLEET_CLIENT_STATUS:
        return { key: card.i, element: (<FleetClientStatus stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_ALARM_COUNT:
        return { key: card.i, element: (<FleetAlarmCount stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} isFleetQuery={true} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_COMPONENTS:
        return { key: card.i, element: (<FleetComponents stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} navigateToView={navigateToViewCallback} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_OPERATION:
        return { key: card.i, element: (<FleetOperation stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} navigateToView={navigateToViewCallback} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_VIBRATION:
        return { key: card.i, element: (<FleetVibration stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext.truck} editMode={editMode} navigateToView={navigateToViewCallback} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      case ComponentTypes.FLEET_AUDIT_LOG:
        return { key: card.i, element: (<FleetAuditLog stateKey={card.i} cardKey={card.i} dashboard={dashboard} view={viewId} context={selectedContext} editMode={editMode} navigateToView={navigateToViewCallback} highlight={shouldCardHighlight} removable={removable} editable={editable} metaConfiguration={cardMetaConfig}/>)};
      // Default
      default:
        return { key: card.i, element: (<div/>)};
    }
  });

  return cardElements;

}

/**
 * Find meta configuration of a specific card type
 */
const getCardMetaConfig = (cardsMetaConfig, cardType) =>{
  return _.find(cardsMetaConfig, ['type', cardType])?.metaConfiguration;
}

/**
 * Add the Custom Resize Handle to a card, if the card supports Free Sizing (aka click and drag to resize a card).
 * We do this because the React Layout Grid supports configuring cards (size, positions, resizable, etc) in 1 of 2 ways:
 * 1. Card Configuration exists in the overall Layout Configuration <<< this is what we use
 * 2. Each Card Configuration contains its Configuration
 * 
 * Since React Layout Grid only supports 1 of 2 ways to configure the cards, we cannot mix both ways.
 * 
 * Because we are using Layout Configuration to configure our cards, we will update the Layout Configuration to add the 
 * Custom Resize Handle to cards that support it.
 * @param {*} editMode Only add the handle if we are in edit mode
 * @param {*} cards Cards to process
 */
const addResizeHandle = (editMode, cards) => {
  if (editMode) {
    _.forEach(cards, (card) => {
      if (card.configuration?.canFreeSize) {
        _.set(card, ['isResizable'], true);
        _.set(card, ['resizeHandles'], ['se']);
      }
    })
  } else {
    _.forEach(cards, (card) => {
      if (card.configuration?.canFreeSize) {
        _.unset(card, ['isResizable']);
        _.unset(card, ['resizeHandles']);
      }
    })
  }
}

export {
  getCardElementsFromLayout,
  addResizeHandle
}