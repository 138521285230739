import reduceReducers from 'reduce-reducers';

import { coilDashboardState } from './coilDashboardSelectors';
import coilReducer from './reducers/coilReducer';

const initialState = coilDashboardState();

const coilDashboardReducer = (state=initialState, action) => {

    const reducer = reduceReducers(
        coilReducer,
    );

    return reducer(state, action);

};

export default coilDashboardReducer