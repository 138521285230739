import React, {Component} from 'react';
import { Box } from '@mui/material';
import fleetMapStyles from '../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class BlankSand extends Component {
  render() {
    return (
      <Box sx={styles.sandMapContainer}/>
    );
  }
}

export default BlankSand;