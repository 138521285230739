import wellsDisplayActionTypes from "./wellsDisplayActionTypes";
import {
    validateWellChanges,
    parseWellInUseMessage
} from "./services/wellsDisplayServices";
import {wellsDisplayState} from "../../../state/displays/wellsDisplay/wellsDisplaySelectors";
import _ from "lodash";

const initialState = wellsDisplayState();

const wellsDisplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case wellsDisplayActionTypes.QUERY_WELL_DATA_STARTING:
            return {...state, queryRunning: true};
        case wellsDisplayActionTypes.QUERY_WELL_DATA_SUCCESS:
            return onQueryWellSuccess(state, action);
        case wellsDisplayActionTypes.QUERY_WELL_DATA_ERROR:
            return {...state, queryRunning: false};
        case wellsDisplayActionTypes.SAVE_WELL_DIALOG_OPEN:
            return onSaveWellDialogOpen(state, action);
        case wellsDisplayActionTypes.CHANGE_WELL:
            return onChangeWell(state, action);
        case wellsDisplayActionTypes.VALIDATE_CHANGE_WELL:
            return onValidateChangeWell(state, action);
        case wellsDisplayActionTypes.OPEN_DELETE_DIALOG:
            return {...state, wellToDelete: action.wellToDelete, openDeleteConfirmation: true};
        case wellsDisplayActionTypes.CLOSE_DELETE_DIALOG:
            return {...state, openDeleteConfirmation: false, showWellInUseMessage: false, wellInUseMessage: {}};
        case wellsDisplayActionTypes.SAVE_WELL_DIALOG_CLOSE:
            return onSaveWellDialogClose(state, action);
        case wellsDisplayActionTypes.SAVE_WELL_DATA_STARTING:
            return {...state, queryRunning: true};
        case wellsDisplayActionTypes.SAVE_WELL_DATA_SUCCESS:
            return onSaveWellSuccess(state,action);
        case wellsDisplayActionTypes.SAVE_WELL_DATA_ERROR:
            return {...state, queryRunning: false};
        case wellsDisplayActionTypes.DELETE_WELL_DATA_STARTING:
            return {...state, queryRunning: true};
        case wellsDisplayActionTypes.DELETE_WELL_DATA_SUCCESS:
            return {...state, openDeleteConfirmation: false, queryRunning: false, wellToDelete: {}};
        case wellsDisplayActionTypes.DELETE_WELL_DATA_ERROR:
            return {...state, openDeleteConfirmation: false, queryRunning: false};
        case wellsDisplayActionTypes.SET_ROWS_PER_PAGE:
            return { ...state, rowsPerPage: action.rowSize }
        case wellsDisplayActionTypes.DELETE_WELL_ERROR_WELL_IN_USE:
            return onDeleteWellErrorWellInUse(state, action);
        default:
            return state;
    }
};

const onSaveWellSuccess = (state, action) => {
    const updatedWells = _.cloneDeep(state.wells);
    // Update the saved Well
    let foundIndex = _.findIndex(updatedWells, ['id', action.queryResults.saveWell.id]);
    if (!_.isNil(updatedWells[foundIndex])) {
        updatedWells[foundIndex] = action.queryResults.saveWell;
    } else {
        updatedWells.push( action.queryResults.saveWell);
    }
    return {
        ...state,
        openSaveWell: false,
        queryRunning: false,
        wells: updatedWells
    };
}

const onValidateChangeWell = (state, action) => {
    let well = {...state.wellForSave, [action.property]: action.value};
    const errorsOnWell = validateWellChanges(well);
    return {
        ...state,
        errorsOnWellSave: errorsOnWell,
        wellForSave: onChangeWell(state, action).wellForSave
    };
}

const onSaveWellDialogOpen = (state, action) => {
    return {
        ...state,
        wellForSave: action.wellForSave,
        errorsOnWellSave: validateWellChanges(action.wellForSave),
        openSaveWell: true,
        originalWellForSave: action.wellForSave
    };
}

const onSaveWellDialogClose = (state, action) => {
    return {
        ...state,
        openSaveWell: false,
        errorsOnWell: {},
    };
}

const onChangeWell = (state, action) => {
    const wellForSave = {...state.wellForSave, [action.property]: action.value};
    return {
        ...state,
        wellForSave
    };
}

const onQueryWellSuccess = (state, action) => {

    return {
        ...state,
        queryRunning: false,
        wells: action.wellsQueryResults.wells,
    }
};

const onDeleteWellErrorWellInUse = (state, action) => {
  
  // Parse the message
  let parsedMessage = parseWellInUseMessage(action.message);

  return {
    ...state,
    queryRunning: false,
    showWellInUseMessage: true,
    wellInUseMessage: parsedMessage
  }
  
}

export default wellsDisplayReducer;