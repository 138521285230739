import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import { tableState } from '../../common/table/tableSelectors';
import { exportCsvState } from'../../common/exportCsv/exportCSVSelectors';

const componentHoursState = state => {

  let filterDetails = filterState(state);
  let tableDetails = tableState(state);
  let exportCsvDetails = exportCsvState(state);

  return {

    ...filterDetails,
    ...tableDetails,
    ...exportCsvDetails,

    // Flag to indicate a remote call is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    // Units returned from the service
    units: _.isNil(state) ? [] : state.units,

    // Units displayed in the UI
    filteredUnits: _.isNil(state) ? [] : state.filteredUnits,

    // Components to display in the UI. This is generated from the Units
    // that are displayed in the UI such that it is a union of all the
    // components on the Units that are in the filtered Unit list.
    filteredComponents: _.isNil(state) ? [] : state.filteredComponents,

    // By default we show the hours remaining for a component, this flags
    // allows us to switch to the consumed hours
    showConsumedHours: _.isNil(state) ? false : state.showConsumedHours,
  };

};

export {
  componentHoursState
};