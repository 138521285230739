import customersDisplayActionTypes from "./customersDisplayActionTypes";
import _ from "lodash";
import {
    validateCustomerChanges
} from "./services/customersDisplayServices";
import {customersDisplayState} from "../../../state/displays/customersDisplay/customersDisplaySelectors";

const initialState = customersDisplayState();

const customersDisplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case customersDisplayActionTypes.QUERY_CUSTOMER_DATA_STARTING:
            return {...state, queryRunning: true};
        case customersDisplayActionTypes.QUERY_CUSTOMER_DATA_SUCCESS:
            return onQueryCustomerSuccess(state, action);
        case customersDisplayActionTypes.QUERY_CUSTOMER_DATA_ERROR:
            return {...state, queryRunning: false};
        case customersDisplayActionTypes.SAVE_CUSTOMER_DIALOG_OPEN:
            return {
                ...state,
                customerForSave: action.customerForSave,
                errorsOnCustomerSave: validateCustomerChanges(action.customerForSave),
                openSaveCustomer: true,
                originalCustomerForSave: action.customerForSave
            };
        case customersDisplayActionTypes.CHANGE_CUSTOMER:
            return onChangeCustomer(state, action);
        case customersDisplayActionTypes.VALIDATE_CHANGE_CUSTOMER:
            let customer = {...state.customerForSave, [action.property]: action.value}
            const errorsOnCustomer = validateCustomerChanges(customer)
            return {
                ...state,
                errorsOnCustomerSave: errorsOnCustomer,
                customerForSave: onChangeCustomer(state, action).customerForSave
            }
        case customersDisplayActionTypes.OPEN_DELETE_DIALOG:
            return {...state, customerToDelete: action.customerToDelete,
                openDeleteConfirmation: true};
        case customersDisplayActionTypes.CLOSE_DELETE_DIALOG:
            return {...state, openDeleteConfirmation: false};
        case customersDisplayActionTypes.SAVE_CUSTOMER_DIALOG_CLOSE:
            return {
                ...state,
                openSaveCustomer: false,
                errorsOnCustomer: {},
            };
        case customersDisplayActionTypes.SAVE_CUSTOMER_DATA_STARTING:
            return {...state, queryRunning: true};
        case customersDisplayActionTypes.SAVE_CUSTOMER_DATA_SUCCESS:
            return {...state, openSaveCustomer: false, queryRunning: false};
        case customersDisplayActionTypes.SAVE_CUSTOMER_DATA_ERROR:
            return {...state, queryRunning: false};
        case customersDisplayActionTypes.DELETE_CUSTOMER_DATA_STARTING:
            return {...state, queryRunning: true};
        case customersDisplayActionTypes.DELETE_CUSTOMER_DATA_SUCCESS:
            return {...state, openDeleteConfirmation: false, queryRunning: false, customerToDelete: {}};
        case customersDisplayActionTypes.DELETE_CUSTOMER_DATA_ERROR:
            return {...state, openDeleteConfirmation: false, queryRunning: false};
        case customersDisplayActionTypes.SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.rowSize,
            }
        default:
            return state;
    }
};


const onChangeCustomer = (state, action) => {
    const customerForSave = {...state.customerForSave, [action.property]: action.value}
    return {
        ...state,
        customerForSave
    }
}

const onQueryCustomerSuccess = (state, action) => {

    return {
        ...state,
        queryRunning: false,
        customers: action.customersQueryResults.customers,
    }
};

export default customersDisplayReducer;