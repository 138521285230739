import _ from 'lodash';

const fleetClientStatusState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    status: _.isNil(state) ? null : state.status,
    isUploading: _.isNil(state) ? null : state.isUploading,
    dataUploadStatus: _.isNil(state) ? null : state.dataUploadStatus,
    datasourceStatus: _.isNil(state) ? null : state.datasourceStatus,
    description: _.isNil(state) ? null : state.description,
    sensorUploadDescription: _.isNil(state) ? null : state.sensorUploadDescription,
    sensorDataDescription: _.isNil(state) ? null : state.sensorDataDescription,
    dataSourceAggregateStatusDescription: _.isNil(state) ? null : state.dataSourceAggregateStatusDescription,
  };

};

export {
  fleetClientStatusState
};