import _ from 'lodash';

const componentLifeState = state => {

  return {
    queryRunning : _.isNil(state) ? false : state.queryRunning,
    components: _.isNil(state) ? [] : state.components,
    componentTypes: _.isNil(state) ? [] : state.componentTypes,
  }
};

export {
  componentLifeState
};