import _ from 'lodash';
import {performCardFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as unitHoursDfpSimulation from './unitHoursDfpSimulation';
import unitChartDfpTypes from './unitHoursDfpTypes';

const fetchUnitHoursCurbsideCardData = (truckId) => {

  if (useSimulationData('unitHoursCurbsideCard')) {
    return unitHoursDfpSimulation.fetchUnitHoursDfpCardData(unitChartDfpTypes.CURBSIDE)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartDfpTypes.CURBSIDE; }
        return response;
      });
  }

  return performCardFetch({
    'query': '{ unitHoursCurbside(truckId:{truckId}) { ecmHours pumpHours } }'.replace('{truckId}', truckId)
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartDfpTypes.CURBSIDE; }
    return response;
  });

};

const fetchUnitHoursRoadsideCardData = (truckId) => {

  if (useSimulationData('unitHoursRoadsideCard')) {
    return unitHoursDfpSimulation.fetchUnitHoursDfpCardData(unitChartDfpTypes.ROADSIDE)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartDfpTypes.ROADSIDE; }
        return response;
      });
  }

  return performCardFetch({
    'query': '{ unitHoursRoadside(truckId:{truckId}) { ecmHours pumpHours } }'.replace('{truckId}', truckId)
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartDfpTypes.ROADSIDE; }
    return response;
  });

};

export {
  fetchUnitHoursCurbsideCardData,
  fetchUnitHoursRoadsideCardData
}