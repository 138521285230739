import _ from "lodash";
import * as assetTypeService from '../../../common/services/assetTypeService';
import moment from 'moment';

const processItems = (items) => {
  // Update any displayed values
  prettifyValues(items);
  return items;
};

const prettifyValues = (items) => {
  if (!_.isNil(items) && !_.isEmpty(items)) {
    items.forEach((item, index) => {
      let noValue = '-';
      item.internalId = index;   //Adding unique index as the internalId
      item.displayValueType = !_.isNil(item.type) ? assetTypeService.getAssetNameByType(item.type) : 'N/A';
      item.displayValueFirmwareVersion = !_.isNil(item.firmwareVersion) ? item.firmwareVersion : noValue;
      item.displayValueSoftware = !_.isNil(item.software) ? item.software : noValue;
      item.displayValueSoftwareVersion = !_.isNil(item.softwareVersion) ? item.softwareVersion : noValue;
      item.displayValueConfigName = !_.isNil(item.configName) ? item.configName : noValue;
      item.displayValueConfigVersion = !_.isNil(item.configVersion) ? item.configVersion : noValue;
      item.displayValueKernelVersion = !_.isNil(item.kernelVersion) ? item.kernelVersion : noValue;
      item.displayValueSerialNumber = !_.isNil(item.serialNumber) ? item.serialNumber : noValue;

      // timeStamp represents lastModified date in DB (createdOn date is applied when lastModified is null)
      item.displayValueTimeStamp = !_.isNil(item.timeStamp) ? moment.unix(item.timeStamp).format('YYYY-MM-DD HH:mm:ss') : noValue;
      item.displayValueHardwareVersion = !_.isNil(item.hardwareVersion) ? item.hardwareVersion : noValue;
    })
  }
};

export {
  processItems,
  prettifyValues
}