import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Grid, Button } from '@mui/material';
import history from "../../../helpers/historyHelper";

const styles = {
  page: {
    width: '100%',
    maxWidth: (theme) => theme.breakpoints.values.md,
    paddingTop: 16,
    paddingLeft: 2,
    paddingRight: 2
  }
};

class NotFoundPage extends Component {
  render() {
    return (
      <Grid container justifyContent={'center'}>
        <Box sx={styles.page}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h5'} align={'center'} noWrap >
                Oops! Something went wrong.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align={'center'} noWrap >
                We were unable to find what you were looking for.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div align={'center'}>
                <Button variant={'contained'} color={'primary'} onClick={() => { history.replace('/home'); } }>Home</Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

export default NotFoundPage;