import _ from 'lodash';

const fleetActivityState = state => {

  const noValue = '-';

  return {

    queryRunning : _.isNil(state) ? false : state.queryRunning,

    rawValuePumping: _.isNil(state) ? null : state.rawValuePumping,
    rawValueIdle: _.isNil(state) ? null : state.rawValueIdle,
    rawValueOffline: _.isNil(state) ? null : state.rawValueOffline,

    displayDays: _.isNil(state) ? null : state.displayDays,

    displayValuePumping: _.isNil(state) ? noValue : state.displayValuePumping,
    displayValueIdle: _.isNil(state) ? noValue : state.displayValueIdle,
    displayValueOffline: _.isNil(state) ? noValue : state.displayValueOffline,
  };

};

export {
  fleetActivityState
};