import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {LinearScale} from "@mui/icons-material";
import {Grid} from '@mui/material';

/**
 * Draws a graphical indicator for each series of the given y-Axis Id.
 */
class AxisIndicator extends Component {

  render() {
    const {definition} = this.props;

    // divide up the configurations by yAxisId
    let allConfigs = [];
    if (!_.isNil(definition.primary) && !_.isNil(definition.primary.config) && !_.isEmpty(definition.primary.config)) {
      allConfigs.push(definition.primary.config);
    }
    if (!_.isNil(definition.secondary) && !_.isNil(definition.secondary.config) && !_.isEmpty(definition.secondary.config)) {
      allConfigs.push(definition.secondary.config);
    }
    allConfigs = _.flatten(allConfigs);
    allConfigs = _.filter(allConfigs, ['isVisible', true]);
    let configs = _.filter(allConfigs, ['yAxisId', this.props.yAxisId]);
    return (
          <Grid container direction={this.props.isRotated ? 'row' : 'column'}
                        justifyContent={'flex-start'}
                        alignItems={'stretch'}>
            {
              configs.map((item, index) => {
                return (
                <Grid item key={'yAxisId=' + index}>
                  <div>
                    <LinearScale key={'lo' + index} sx={{color: item.color}} fontSize={'small'}/>
                    {/*<Typography key={'lo' + index} style={{color: item.color, fontSize: 'large'}}>&mdash;</Typography>*/}
                  </div>
                </Grid>
                );
              })
            }
          </Grid>
    );
  }
}

AxisIndicator.propTypes = {
  definition: PropTypes.object.isRequired,
  yAxisId: PropTypes.number.isRequired,
  isRotated: PropTypes.bool,
};

export default AxisIndicator;
