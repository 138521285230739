import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import BlankWellHead from '../blankWellHead';

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class WellHead extends Component {
  render() {

    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.wellHeadMapContainer}>
        <Typography variant={'h6'} sx={styles.fleetMapText}>{data.flowRate}</Typography>
        <Typography variant={'caption'} sx={styles.fleetMapText}>Flow Rate (bpm)</Typography>
        <Typography variant={'h6'} sx={styles.fleetMapText}>{data.pressure}</Typography>
        <Typography variant={'caption'} sx={styles.fleetMapText}>Pressure (psi)</Typography>
        <BlankWellHead/>
        <Typography variant={'h6'} sx={styles.fleetMapText}>{data.density1}</Typography>
        <Typography variant={'caption'} sx={styles.fleetMapText}>Density 1 (ppa)</Typography>
        <Typography variant={'h6'} sx={styles.fleetMapText}>{data.density2}</Typography>
        <Typography variant={'caption'} sx={styles.fleetMapText}>Density 2 (ppa)</Typography>
      </Box>
    );
  }
}

WellHead.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WellHead;