import {EXyDirection} from "scichart/types/XyDirection";
import {EDragMode} from "scichart/types/DragMode";
import {ELegendOrientation, ELegendPlacement} from "scichart/Charting/Visuals/Legend/SciChartLegendBase";
import {EExecuteOn} from "scichart/types/ExecuteOn";
import {easing} from "scichart/Core/Animations/EasingFunctions";
import {MdtYAxisPosition} from "../../../../state/common/dataExplorationChart/mdtYAxisPosition";
import { mdtPalette } from "../../../common/styles/mdtPalette";
import _ from "lodash";

/**
 * Round to four significant digits: 1234, 123.4, 12.34, 1.234, or 0.1234
 * @param value Sensor value
 * @return {number} Rounded sensor value
 */
const roundToFourSignificantDigits = (value) => {
  if (value >= 1000) {
    return _.round(value, 0);
  }
  else if (value >= 100) {
    return _.round(value, 1);
  }
  else if (value >= 10) {
    return _.round(value, 2);
  }
  else if (value >= 1) {
    return _.round(value, 3);
  }
  else {
    return _.round(value, 4);
  }
}

// A function to get the text for the rolloverModifier tooltip
const metadataTooltipDataTemplate = (seriesInfo) => {
  const valuesWithLabels = [];

  // add metadata if available
  if (seriesInfo.pointMetadata) {
    valuesWithLabels.push("Note: " + seriesInfo.pointMetadata.note);
  }

  // management wants values with 4 significant digits
  const value = roundToFourSignificantDigits(seriesInfo.yValue);

  const seriesNameParts = seriesInfo.seriesName.replaceAll('(', ' ').replaceAll(')', ' ').trim().split(' ');
  const uom = seriesNameParts.pop();
  const sensorName = _.join(_.slice(seriesNameParts, 1, seriesNameParts.length-1), ' ');

  // add UOM to lessen confusion, it is at the end of the series name
  const delimiter = ' &nbsp; @ &nbsp; ';
  valuesWithLabels.push(`${sensorName} ${delimiter}${value} ${uom}`);

  return valuesWithLabels;
};

const tooltipLegend = (seriesInfos, svgAnnotation) => {

  // management wants only the time of day -- use 12h for clarity and match the x-axis
  const timestamp = (_.isNil(seriesInfos[0]) || _.isNil(seriesInfos[0].xValue)) ? null : new Date(seriesInfos[0].xValue * 1000).toLocaleTimeString("en-US", {hour12: true});
  const display = _.isNil(timestamp) ? 'none' : 'block';
  const isVerticalChart = (_.isNil(seriesInfos[0]) || _.isNil(seriesInfos[0].renderableSeries)) ? false : seriesInfos[0].renderableSeries.currentRenderPassData.isVerticalChart;
  const y = (_.isNil(seriesInfos[0]) || _.isNil(seriesInfos[0].renderableSeries)) ? 0 : seriesInfos[0].renderableSeries.parentSurfaceProperty.chartModifierSurfaceViewRect.height;
  const x = (_.isNil(seriesInfos[0]) || _.isNil(seriesInfos[0].renderableSeries)) ? 0 : seriesInfos[0].renderableSeries.parentSurfaceProperty.chartModifierSurfaceViewRect.width;
  const containerStyle = `
    display:${display};
    filter:drop-shadow(3px 5px 2px rgb(48 48 48 / 0.6));`;

  return `<svg style="${containerStyle}" width="${x}" height="${y}">
            <text x="${x/(isVerticalChart === true ? 2.35 : 2.15)}" y="${y-10}" font-size="18" font-weight="bold" font-family="Roboto" fill="white">${timestamp}</text>
          </svg>`;
}

const modifiers = (isRotated) => 
{
  return [
    {
      name: "MouseWheelZoomModifier",
      isEnabled: true,
      options: {
        // restrict zooming to X-direction only; users can use rubber band to drill in
        xyDirection: EXyDirection.XDirection,
      }
    },
    {
      name: "ZoomPanModifier",
      isEnabled: true,
      options: {
        xyDirection: EXyDirection.XyDirection,
      }
    },
    {
      name: "LegendModifier",
      isEnabled: false,
      options: {
        showCheckboxes: true,
        showSeriesMarkers: true,
        placement: ELegendPlacement.TopLeft,
        orientation: ELegendOrientation.Vertical,
      }
    },
    {
      //this seems to cause issues with most recent version of sciChart
      name: "CursorModifier",
      isEnabled: false,
      options: {
        crosshairStroke: mdtPalette().typography.white,
        crosshairStrokeThickness: 0.25,
        tooltipContainerBackground: "#5b5b5b", // default MaterialUI tooltip color
        tooltipTextStroke: "white",
        showTooltip: true,
        // TODO - use a SVG template for better formatting
        showAxisLabels: false,
      }
    },
    {
      name: "RolloverModifier",
      isEnabled: true,
      options: {
        rolloverLineStroke: mdtPalette().typography.white,
        rolloverLineStrokeThickness: 0.25,
        showRolloverLine: true,
        showTooltip: true,
        tooltipDataTemplate: isRotated ? null : metadataTooltipDataTemplate, // TODO - use a SVG template for better formatting
        tooltipLegendTemplate: tooltipLegend,
        allowTooltipOverlapping: false,
        snapToDataPoint: false,
        placementDivId: isRotated ? 'tooltip-div' : null
      },
      
    },
    {
      name: "YAxisDragModifier",
      isEnabled: true,
      options: {
        dragMode: EDragMode.Scaling,
      }
    },
    {
      name: "XAxisDragModifier",
      isEnabled: true,
      options: {
        dragMode: EDragMode.Panning,
      }
    },
    {
      name: "ZoomExtentsModifier",
      isEnabled: true,
      options: {
        isAnimated: true,
        animationDuration: 400,
        easingFunction: easing.outExpo
      }
    },
    {
      name: "RubberBandXyZoomModifier",
      isEnabled: true,
      options: {
        executeOn: EExecuteOn.MouseRightButton,
        easingFunction: easing.inOutSine
      }
    },
  ];
}
const Y_AXIS_IDS = Object.freeze(["LEFT_INNER","LEFT_OUTER", "RIGHT_INNER", "RIGHT_OUTER"]);

/**
 * MDT defines Y-Axis position is a non-zero integer ordered from inner axis to
 * outer, with left axes being negative and right axes being positive. For
 * example four axes can be represented as
 *     -2 -1 [CHART] 1 2
 * But SciChart, we currently encode the axis differently as
 *     1 0 [CHART] 2 3
 * So we translate the position here.
 *
 * @param mdtYAxisId MDT Y-axis position identifier
 * @return {string} SciChart Y-axis identifier
 */
const translateMdtYAxisId = (mdtYAxisId) => {
  switch (mdtYAxisId) {
    case MdtYAxisPosition.LeftOuter:
      return "LEFT_OUTER";
    case MdtYAxisPosition.LeftInner:
      return "LEFT_INNER";
    case MdtYAxisPosition.RightInner:
      return "RIGHT_INNER";
    case MdtYAxisPosition.RightOuter:
      return "RIGHT_OUTER";
    default:
      console.error(`Invalid numerical Y-axis position '${mdtYAxisId}', defaulting to LEFT_INNER`);
      return "LEFT_INNER";
  }
}

/**
 * Convenience function to get an actual Y-Axis object given a MDT Y-Axis
 * position.
 *
 * @param surface surface object
 * @param mdtYAxisId MDT Y-axis position identifier
 * @return {*} Y-Axis object
 */
const getYAxisFromMdtId = (surface, mdtYAxisId) => {
  const yAxisId = translateMdtYAxisId(mdtYAxisId);
  return surface.getYAxisById(yAxisId);
}

const X_AXIS_IDS = Object.freeze(["primary", "secondary"]);
const X_AXIS_LABEL_STYLE = {
  color: "#ff99ff",
  fontFamily: 'Roboto',
  fontWeight:'normal'
};

export {
  modifiers,
  translateMdtYAxisId,
  getYAxisFromMdtId,
  Y_AXIS_IDS,
  X_AXIS_IDS,
  X_AXIS_LABEL_STYLE
}
