import {performCardFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as fleetPRCHistorySimulation from './fleetPRCHistorySimulation';

const fetchFleetPRCHistoryCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('fleetPRCHistoryCard')) {
    return fleetPRCHistorySimulation.fetchFleetPRCHistoryCardData(truckId, startTime, endTime, 400);
  }

  return performCardFetch({
    'query': 'query FleetPRCHistoryChart($input:ChartSeriesInput!) { fleetPRCHistoryChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  })
};

export default fetchFleetPRCHistoryCardData