import { handleError } from "../../../app/actions/appErrorActions";
import errorMessages from "../../../common/errorMessages";
import defaultActionFactory from "../../../common/factories/defaultActionFactory";
import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { fetchAddRouteAsset, fetchUpdateRouteAsset } from "../routeAssetMgmtQueries";
import { queryRouteAssets } from "./routeAssetMgmtDisplayActions";

const closeAddDialog = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_ADD_ROUTE_ASSET_DIALOG, 'stateDef')(stateDef));
    await dispatch(resetWorkingRouteAsset(stateDef));
  }
};

const closeEditDialog = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_EDIT_DIALOG, 'stateDef')(stateDef));
    await dispatch(resetWorkingRouteAsset(stateDef));
  }
};

const openAddRouteAssetDialog = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(resetWorkingRouteAsset(stateDef));
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_ADD_ROUTE_ASSET_DIALOG, 'stateDef')(stateDef));
  }
};

const openEditRouteAssetDialog = (stateDef, routeAsset) => {
  return async(dispatch, getState) => {
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET, 'stateDef', 'routeAsset')(stateDef, routeAsset));
    await dispatch(defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_EDIT_DIALOG, 'stateDef')(stateDef));
  }
};

const setWorkingRouteAssetConsumerName = (stateDef, consumerName) => ({
    type: routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET_CONSUMER_NAME,
    stateDef,
    consumerName
});

const setWorkingRouteAssetTruckName = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_TRUCK_NAME, 'stateDef', 'truckName');

const updateRouteAssetStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_STARTING, 'stateDef');
const updateRouteAssetError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_UPDATE_ROUTE_ASSET_ERROR, 'stateDef');

const addRouteAssetStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_STARTING, 'stateDef');
const saveRouteAssetSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SAVE_ROUTE_ASSET_SUCCESS, 'stateDef', 'results');
const addRouteAssetError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_ERROR, 'stateDef');

const saveRouteAsset = (stateDef, workingRouteAsset) => {
    return async (dispatch, getState) => {
      try {
        _.isNil(workingRouteAsset.id) ? await dispatch(addRouteAssetStarting(stateDef)) : await dispatch(updateRouteAssetStarting(stateDef));

        const workingRouteAssetToSave = {
          id: workingRouteAsset.id,
          truckName: workingRouteAsset.truckName?.value,
          consumerId: null
        };

        const consumer = _.find(getState()[stateDef.key].consumers, (consumer) => { return consumer.consumerName === workingRouteAsset.consumerName.value });
        workingRouteAssetToSave.consumerId = consumer?.id;

        const results = _.isNil(workingRouteAsset.id) ? await fetchAddRouteAsset(workingRouteAssetToSave) : await fetchUpdateRouteAsset(workingRouteAssetToSave);
        await dispatch(saveRouteAssetSuccess(stateDef, results));
      } catch (e) {
        _.isNil(workingRouteAsset.id) ? await dispatch(addRouteAssetError(stateDef)) : await dispatch(updateRouteAssetError(stateDef));
        return dispatch(handleError(_.isNil(workingRouteAsset.id) ? errorMessages.ERROR_ADDING_ROUTE_ASSET : errorMessages.ERROR_UPDATING_ROUTE_ASSET, e.message));
      }
      await dispatch(resetWorkingRouteAsset(stateDef));
      return dispatch(queryRouteAssets(stateDef));
    };
};

const resetWorkingRouteAsset = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_RESET_WORKING_ROUTE_ASSET, 'stateDef');

export {
    closeAddDialog,
    closeEditDialog,
    openAddRouteAssetDialog,
    openEditRouteAssetDialog,
    setWorkingRouteAssetConsumerName,
    setWorkingRouteAssetTruckName,
    saveRouteAsset,
    resetWorkingRouteAsset,
};
