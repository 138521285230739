import _ from 'lodash';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import history from './historyHelper';
import rootReducer from "../state/common/rootReducer";
import { websocketMiddleware } from './websocketHelper';

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV === 'production') {
  // Ensure we are not using the Redux developer tools in production.
  composeEnhancers = compose;
}

let store = null;
// Only setup the websocket (and related middleware) if we are not running in a CI environment
if (!_.isNil(process.env.CI) && process.env.CI.trim() === 'true') {
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))));
} else {
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, routerMiddleware(history), websocketMiddleware())));
}

export default store;