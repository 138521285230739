import defaultActionFactory from "../factories/defaultActionFactory";
import iconPickerActionTypes from "./iconPickerActionTypes";

const onOpenPicker = defaultActionFactory(iconPickerActionTypes.ICON_PICKER_OPEN, 'stateDef','iconTargetElement');
const onClosePicker = defaultActionFactory(iconPickerActionTypes.ICON_PICKER_CLOSE, 'stateDef');
const onUpdateFilter = defaultActionFactory(iconPickerActionTypes.ICON_PICKER_UPDATE_FILTER, 'stateDef', 'filterString');

export {
    onOpenPicker,
    onClosePicker,
    onUpdateFilter
};