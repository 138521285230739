import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import ComponentTypes from "../../componentTypes";
import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel, mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";

class UnitHoursConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        defaultTitle: 'UNIT HOURS',
        availableSizes:[{w:2, h:2}, {w:3, h:1}, {w:3, h:2}, {w:3, h:4}]
    };
}

UnitHoursConfigPanel.proptypes = mdtCardConfigPanelPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_HOURS : props.stateKey,
            type: ComponentTypes.UNIT_HOURS,
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        ...mdtCardConfigMapStateToProps(state, props),
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardConfigMapDispatchToProps(dispatch, props)
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UnitHoursConfigPanel));