import _ from 'lodash';

/**
 * The AutoComplete component data needs to be in the form of
 * { value: ..., label: ... } for each item 
 * @param {*} trucks List of trucks to process
 * @returns {Array} Processed trucks
 */
const processTrucks = (trucks) => {

  const processedTrucks = _.map(_.sortBy(trucks.truckNamesByOwner, 'name'), (truck) => {
    return { value: truck.name, label: truck.name }
  });
  return processedTrucks
}

/**
 * The AutoComplete component data needs to be in the form of
 * { value: ..., label: ... } for each item 
 * @param {*} consumers List of consumers to process
 * @returns {Array} Processed consumers
 */
const processConsumers = (consumers) => {
  const processedConsumers = _.map(consumers.consumersForOwner, (consumer) => {
    return {
      ...consumer,
      value: consumer.consumerName, 
      label: consumer.consumerName 
    }
  });
  return processedConsumers;
}

/**
 * Process a Route Asset object so the truckName and consumerName are in the correct format
 * to be used in an AutoComplete control
 * @param {*} routeAsset The Route Asset to process
 * @returns The Route Asset with the truckName and consumerName in an Autocomplete compatible format
 */
const processRouteAsset = (routeAsset) => {
  return {
    ...routeAsset,
    truckName: { value: routeAsset.truckName, label: routeAsset.truckName },
    consumerName: { value: routeAsset.consumerName, label: routeAsset.consumerName }
  }
}

export {
  processConsumers,
  processTrucks,
  processRouteAsset
}