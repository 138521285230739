import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import * as navActions from '../../app/actions/appNavActions';
import componentsActionTypes from './componentsActionTypes';
import { handleError } from '../../app/actions/appErrorActions';

import * as componentsQueries from './componentsQueries';
import errorMessages from '../../common/errorMessages';

const setEditMode = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_EDIT_MODE, 'stateDef', 'editMode');
const setNavigationContext = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_NAVIGATION_CONTEXT, 'stateDef', 'unitId', 'componentType');

const setSelectedUnit = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_UNIT, 'stateDef', 'unit');
const setSelectedComponentType = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_COMPONENT_TYPE, 'stateDef', 'componentType');
const setSelectedEffectiveDate = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_EFFECTIVE_DATE, 'stateDef', 'dateTime');
const setSelectedModel = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_MODEL, 'stateDef', 'model');
const setSelectedSerialNumber = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_SERIAL_NUMBER, 'stateDef', 'serialNumber');
const setSelectedRefNumber = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_REF_NUMBER, 'stateDef', 'refNumber');
const setSelectedUnitOffset = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_UNIT_OFFSET, 'stateDef', 'offset');
const setSelectedSnapshotStart = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_SNAPSHOT_START, 'stateDef', 'snapshot');
const setSelectedEcmReset = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_ECM_RESET, 'stateDef', 'reset');

const revertForm = defaultActionFactory(componentsActionTypes.COMPONENTS_REVERT_FORM, 'stateDef');

const componentQueryDisplayDataStarting = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_STARTING, 'stateDef');
const componentQueryDisplayDataSuccess = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_SUCCESS, 'stateDef', 'queryResults');
const componentQueryDisplayDataError = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_DISPLAY_DATA_ERROR, 'stateDef');

const queryDisplayData = queryActionCustomFactory(
  componentQueryDisplayDataStarting,
  componentQueryDisplayDataSuccess,
  componentQueryDisplayDataError,
  errorMessages.ERROR_RETRIEVING_COMPONENT_DISPLAY_DATA,
  componentsQueries.fetchComponentsDisplay
);

const componentQueryComponentDetailsStarting = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_STARTING, 'stateDef');
const componentQueryComponentDetailsSuccess = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_SUCCESS, 'stateDef', 'queryResults');
const componentQueryComponentDetailsError = defaultActionFactory(componentsActionTypes.COMPONENTS_QUERY_COMPONENT_DETAILS_ERROR, 'stateDef');

const queryComponentDetails = queryActionCustomFactory(
  componentQueryComponentDetailsStarting,
  componentQueryComponentDetailsSuccess,
  componentQueryComponentDetailsError,
  errorMessages.ERROR_RETRIEVING_COMPONENT_DETAILS,
  componentsQueries.fetchComponentsDisplayComponentDetails
);

const loadUnits = (stateDef, switchingOwners) => {
  return async (dispatch, getState) => {

    // Check if there is a navigation context for this display in the app state
    let navigationState = getState().app.navigation.context;
    let navigationContext = _.isNil(navigationState['/components']) ? null : navigationState['/components'];
    if (!_.isNil(navigationContext)) {
      // Clear the app state if we find a navigation context
      await dispatch(navActions.setNavigationContext('/components', null));
      // Set the individual contexts in the display state accordingly.
      await dispatch(setNavigationContext(stateDef, navigationContext.unitId, navigationContext.componentType));
    }

    // Execute the query
    await dispatch(queryDisplayData(stateDef));

    // Trigger a set selected unit action, this will resolve the selection appropriately
    return dispatch(setSelectedUnit(stateDef, switchingOwners === true ? null : getState()[stateDef.key].selectedUnit));
  }
};

const loadComponentDetails = (stateDef, unitId) => {
  return async (dispatch, getState) => {

    // Execute the query
    await dispatch(queryComponentDetails(stateDef, unitId));

    // Try and find an equivalent component type selection if there was a previous selection
    let componentType = getState()[stateDef.key].selectedComponentType;
    if (!_.isNil(componentType)) {
      componentType = _.find(getState()[stateDef.key].componentTypes, { 'name': componentType.name });
    }

    // Trigger a set selected component type action, this will resolve the selection appropriately
    return dispatch(setSelectedComponentType(stateDef, componentType));
  }
};

const saveComponentStarting = defaultActionFactory(componentsActionTypes.COMPONENTS_SAVE_COMPONENT_STARTING, 'stateDef');
const saveComponentSuccess = defaultActionFactory(componentsActionTypes.COMPONENTS_SAVE_COMPONENT_SUCCESS, 'stateDef', 'queryResults');
const saveComponentError = defaultActionFactory(componentsActionTypes.COMPONENTS_SAVE_COMPONENT_ERROR, 'stateDef');

const saveComponent = (stateDef, componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(saveComponentStarting(stateDef));
      queryResults = await componentsQueries.fetchSaveComponent(componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart);
    }catch(e) {
      await dispatch(saveComponentError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_COMPONENT, e.message));
    }
    await dispatch(saveComponentSuccess(stateDef, queryResults));
    return dispatch(loadComponentDetails(stateDef, getState()[stateDef.key].selectedUnit.id));
  }
};

const openDeleteConfirmation = defaultActionFactory(componentsActionTypes.COMPONENTS_OPEN_DELETE_CONFIRMATION, 'stateDef');
const closeDeleteConfirmation = defaultActionFactory(componentsActionTypes.COMPONENTS_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteComponentStarting = defaultActionFactory(componentsActionTypes.COMPONENTS_DELETE_COMPONENT_STARTING, 'stateDef');
const deleteComponentSuccess = defaultActionFactory(componentsActionTypes.COMPONENTS_DELETE_COMPONENT_SUCCESS, 'stateDef', 'queryResults');
const deleteComponentError = defaultActionFactory(componentsActionTypes.COMPONENTS_DELETE_COMPONENT_ERROR, 'stateDef');

const deleteComponent = (stateDef, componentId) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(deleteComponentStarting(stateDef));
      queryResults = await componentsQueries.fetchDeleteComponent(componentId);
    }catch(e) {
      await dispatch(deleteComponentError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_DELETING_COMPONENT, e.message));
    }
    await dispatch(deleteComponentSuccess(stateDef, queryResults));
    return dispatch(loadComponentDetails(stateDef, getState()[stateDef.key].selectedUnit.id));
  }
};

const openCalculator = defaultActionFactory(componentsActionTypes.COMPONENTS_OPEN_CALCULATOR, 'stateDef');
const closeCalculator = defaultActionFactory(componentsActionTypes.COMPONENTS_CLOSE_CALCULATOR, 'stateDef');
const setCalculatorInput = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_CALCULATOR_INPUT, 'stateDef', 'input');
const calculatePumpHoursStarting = defaultActionFactory(componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_STARTING, 'stateDef');
const calculatePumpHoursSuccess = defaultActionFactory(componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_SUCCESS, 'stateDef', 'queryResults');
const calculatePumpHoursError = defaultActionFactory(componentsActionTypes.COMPONENTS_CALCULATE_PUMP_HOURS_ERROR, 'stateDef');

const calculatePumpHours = queryActionCustomFactory(
  calculatePumpHoursStarting,
  calculatePumpHoursSuccess,
  calculatePumpHoursError,
  errorMessages.ERROR_CALCULATING_ESTIMATED_PUMP_HOURS,
  componentsQueries.fetchEstimatePumpHours
);

const navigate = (unitId, componentType) => {
  return async (dispatch, getState) => {
    const url = '/components';
    const context = { unitId: unitId, componentType: componentType };
    return dispatch(navActions.navigate(url, context));
  }
};

const setSelectedEffectiveDateDisplay = defaultActionFactory(componentsActionTypes.COMPONENTS_SET_SELECTED_EFFECTIVE_DATE_DISPLAY, 'stateDef', 'dateTime');

export {
  setEditMode,
  setNavigationContext,
  setSelectedUnit,
  setSelectedComponentType,
  setSelectedEffectiveDate,
  setSelectedModel,
  setSelectedSerialNumber,
  setSelectedRefNumber,
  setSelectedUnitOffset,
  setSelectedSnapshotStart,
  setSelectedEcmReset,
  revertForm,
  loadUnits,
  loadComponentDetails,
  saveComponent,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  deleteComponent,
  navigate,
  openCalculator,
  closeCalculator,
  setCalculatorInput,
  calculatePumpHours,
  setSelectedEffectiveDateDisplay
};