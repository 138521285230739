import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import fleetVibrationActionTypes from './fleetVibrationActionTypes';
import fetchFleetVibrationCardData from './fleetVibrationQueries';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetVibrationActionTypes.FLEET_VIBRATION_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_STARTING,
  fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_SUCCESS,
  fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEET_VIBRATION_CARD_DATA,
  fetchFleetVibrationCardData
);

export {
  queryData,
  clearData,
}