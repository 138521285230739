import _ from 'lodash';
import fleetOperationActionTypes from './fleetOperationActionTypes';
import { fleetOperationState } from './fleetOperationSelectors';
import * as fleetOperationService from './services/fleetOperationService';

const initialState = fleetOperationState();

const fleetOperationReducer = (state=initialState, action) => {

  switch (action.type) {

    case fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    case fleetOperationActionTypes.FLEET_OPERATION_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQueryDataSuccess = (state, action) => {

  let { fleetPumpStatusInfo, suctionData, hhp, sandTotal, slurryTotal } = action.queryResults.fleetOperationStatus;

  const noValue = '-';

  return {
    ...state,
    queryRunning: false,
    pumps: fleetOperationService.processPumpStatusInfo(fleetPumpStatusInfo),
    suction: suctionData,
    hhp: hhp,
    totalSand: _.isNil(sandTotal) ? noValue : parseFloat(sandTotal.toFixed(0)).toLocaleString('en'),
    totalSlurry: _.isNil(slurryTotal) ? noValue : parseFloat(slurryTotal.toFixed(0)).toLocaleString('en'),
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    pumps: initialState.pumps,
    suction: initialState.suction,
    hhp: initialState.hhp,
    totalSand: initialState.totalSand,
    totalSlurry: initialState.totalSlurry,
  };
};

export default fleetOperationReducer