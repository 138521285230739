import _ from 'lodash';

import { X_AXIS_IDS } from '../../../../components/controls/charts/cardChart/cardChart';

import jobStageChartActionTypes from './jobStageChartActionTypes';
import { jobStageChartState } from './jobStageChartSelectors';
import * as jobStageChartServices from './services/jobStageChartService';

const initialState = jobStageChartState();

const jobStageChartReducer = (state = initialState, action) => {
  switch (action.type) {

    case jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_STARTING:
      return { ...state, queryRunning: true };
    case jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_ERROR:
      return { ...state, queryRunning: false };
    case jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_SUCCESS:
      return onQuerySuccess(state, action);

    case jobStageChartActionTypes.JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_STAGE:
      return onSetPrimaryDefinitionStage(state, action);
    case jobStageChartActionTypes.JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_TIME_RANGE:
      return onSetPrimaryDefinitionTimeRange(state, action);

    case jobStageChartActionTypes.JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_STAGE:
      return onSetSecondaryDefinitionStage(state, action);
    case jobStageChartActionTypes.JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_TIME_RANGE:
      return onSetSecondaryDefinitionTimeRange(state, action);

    case jobStageChartActionTypes.JOB_STAGE_CHART_CLEAR_COMPARE:
      return onClearCompare(state, action);

    case jobStageChartActionTypes.JOB_STAGE_CHART_ON_ROLLOVER:
      return onRollover(state, action);

    case jobStageChartActionTypes.JOB_STAGE_CHART_SENSOR_VISIBILITY_TOGGLE:
      return onToggleSensorVisibility(state, action);

    default:
      return state;
  }
}

const onQuerySuccess = (state, action) => {

  const newState = _.cloneDeep(state);
  newState.queryRunning = false;

  // Find out if the stage is primary or secondary
  const stageIsPrimaryOrSecondary = jobStageChartServices.checkStageAgainstDefinition(action.stage, state.definition);

  // All the series have the same number of buckets so just use one to get the xValues
  const xValues = (_.isEmpty(action.queryResults.processChart)) ? [] : [...action.queryResults.processChart[0].timestamps];
  const yValues = jobStageChartServices.normalizeSensorData(xValues, action.queryResults.processChart);

  if (stageIsPrimaryOrSecondary === 1) {
    newState.primaryXValues = xValues;
    newState.primaryYValues = yValues;
  } 
  if (stageIsPrimaryOrSecondary === 2) {
    newState.secondaryXValues = xValues;
    newState.secondaryYValues = yValues;
  }

  if (!_.isEmpty(newState.primaryXValues) && !_.isEmpty(newState.secondaryXValues)) {
    jobStageChartServices.equalizeTimespansForPrimaryAndSecondary(newState.primaryXValues, newState.primaryYValues, newState.secondaryXValues, newState.secondaryYValues);
  }

  return newState;
}

const onSetPrimaryDefinitionStage = (state, action) => {

  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.defaultContext = 
  {
    stage: _.omit(action.stage, ['stageDefinition', 'processedStageDefinition']),
    name: action.stage.id
  };
  newDefinition.primary.contexts = Array(newDefinition.primary.sensors.length).fill(newDefinition.primary.defaultContext);
  newDefinition.primary.axisTitle = action.stage.job + ' - Stage ' + action.stage.processes[0].stage + ' - ' + action.stage.processes[0].side + ' - ' + action.stage.processes[0].wellApiNumber;

  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetPrimaryDefinitionTimeRange = (state, action) => {

  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.timeRange.startTime = action.startTime;
  newDefinition.primary.timeRange.endTime = action.endTime;
  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetSecondaryDefinitionStage = (state, action) => {

  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.secondary.defaultContext = 
  {
    stage: _.omit(action.stage, ['stageDefinition', 'processedStageDefinition']),
    name: action.stage.id
  };
  newDefinition.secondary.contexts = Array(newDefinition.secondary.sensors.length).fill(newDefinition.secondary.defaultContext);
  newDefinition.secondary.axisTitle = action.stage.job + ' - Stage ' + action.stage.processes[0].stage + ' - ' + action.stage.processes[0].side + ' - ' + action.stage.processes[0].wellApiNumber;
  newDefinition.secondary.isVisible = true;  

  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetSecondaryDefinitionTimeRange = (state, action) => {

  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.secondary.timeRange.startTime = action.startTime;
  newDefinition.secondary.timeRange.endTime = action.endTime;
  return {
    ...state,
    definition: newDefinition,
  }
}

const onClearCompare = (state, action) => {
  
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.secondary.defaultContext = null;
  newDefinition.secondary.contexts = [];
  newDefinition.secondary.timeRange.startTime = null;
  newDefinition.secondary.timeRange.endTime = null;
  newDefinition.secondary.axisTitle = '';
  newDefinition.secondary.isVisible = false;
  // Reset the visibility of sensors on the secondary definition
  _.map(newDefinition.secondary.sensors, (sensor) => {
    sensor.isVisible = true;
  });

  return {
    ...state,
    definition: newDefinition,
    secondaryXValues: [],
    secondaryYValues: [],
  }
}

const onRollover = (state, action) => {

  const newDefinition = _.cloneDeep(state.definition);
  switch(action.xAxis) {
    case X_AXIS_IDS[0]: {
      const xValueIndex = _.indexOf(state.primaryXValues, action.xValue);

      _.map(newDefinition.primary.sensors, (sensor) => {
        const sensorDataSet = state.primaryYValues[sensor.sensorSetId];
        if (!_.isNil(sensorDataSet) && (sensor.isVisible === true)) {
          sensor.value = sensorDataSet[xValueIndex];
        }
      });
      break;
    }
    case X_AXIS_IDS[1]: {
      const xValueIndex = _.indexOf(state.secondaryXValues, action.xValue);

      _.map(newDefinition.secondary.sensors, (sensor) => {
        const sensorDataSet = state.secondaryYValues[sensor.sensorSetId];
        if (!_.isNil(sensorDataSet) && (sensor.isVisible === true)) {
          sensor.value = sensorDataSet[xValueIndex];
        }
      });
      break;
    }
    default: {
      _.map(newDefinition.primary.sensors, (sensor) => {
        sensor.value = null;
      });
      _.map(newDefinition.secondary.sensors, (sensor) => {
        sensor.value = null;
      });
      break;
    }
  }

  return {
    ...state,
    definition: newDefinition,
  }
}

const onToggleSensorVisibility = (state, action) => {

  const newDefinition = _.cloneDeep(state.definition);
  switch(action.xAxis) {
    case X_AXIS_IDS[0]: {
      const sensor = _.find(newDefinition.primary.sensors, {sensorSetId: action.sensorSetId});
      sensor.isVisible = !sensor.isVisible;
      break;
    }
    case X_AXIS_IDS[1]: {
      const sensor = _.find(newDefinition.secondary.sensors, {sensorSetId: action.sensorSetId});
      sensor.isVisible = !sensor.isVisible;
      break;
    }
  }

  return {
    ...state,
    definition: newDefinition,
  }
}

export default jobStageChartReducer;