import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';

const dataExplorationDisplayState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    // The full list of trucks returned from the service.
    trucks: _.isNil(state) ? [] : state.trucks,

    // The list of trucks fit for the filter conditions
    contexts: _.isNil(state) ? [] : state.contexts,

    // The selected truck (the context for the cards)
    selectedContext: _.isNil(state) ? null : state.selectedContext,

    dataAgeActual: _.isNil(state) ? '' : state.dataAgeActual,
    dataAgeDisplay: _.isNil(state) ? '-' : state.dataAgeDisplay,

    timeFrames: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: 'Last 6 hours' },
      { value: 720, label: 'Last 12 hours' },
      { value: 1440, label: 'Last 24 hours' },
      { value: null, label: 'Custom' },
    ] : state.timeFrames,

    customDurations: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: '6 hours' },
      { value: 720, label: '12 hours' },
      { value: 1440, label: '24 hours' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedCustomDuration,
    
    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
  };

};

export {
  dataExplorationDisplayState
};