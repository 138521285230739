import _ from "lodash";
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import { handleError } from '../../app/actions/appErrorActions';
import errorMessages from '../../common/errorMessages';
import moment from 'moment';
import {exportCsvFactory} from '../../common/exportCsv/exportCsvActions';

import { transformDataToJson, concatSensorNameAndUom } from './services/dataExplorationChartService';
import dataExplorationTypes from "./dataExplorationChartActionTypes";
import { fetchDataExploration } from "./dataExplorationChartQueries";
import { chunkTimeRange } from './services/dataExplorationChartQueryManager';

import * as sensorSelectorActions from '../sensorSelector/sensorSelectorActions';

const setTemplate = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_TEMPLATE, 'stateDef', 'template', 'initialLoad');
const setConfigYAxis = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_CONFIG_YAXIS, 'stateDef', 'xAxisId', 'refIndex', 'yAxisId');
const setConfigColor = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_CONFIG_COLOR, 'stateDef', 'xAxisId', 'refIndex', 'color');
const setConfigVisible = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_CONFIG_VISIBLE, 'stateDef', 'xAxisId', 'refIndex', 'isVisible');
const calculateStats = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_CALCULATE_STATS, 'stateDef', 'xAxisId', 'min', 'max');
const adjustDataRange = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_ADJUST_DATA_RANGE, 'stateDef', 'xAxisId', 'min', 'max');
const queryByChunks = (stateDef, truck, start, end, sensors) => {
  return async (dispatch, getState) => {
    const timeChunks = chunkTimeRange(start, end, 2);
    // Send queries for all chunks
    // Don't throw away the promises - this will dispatch querySensorData in parallel for all chunks and handle the promises for each
    Promise.all(_.map(timeChunks, async (tc, i) => {
      dispatch(querySensorData(stateDef, truck, tc.startTime, tc.endTime, sensors));
    }));
  }
};
const userPanZoomUpdate = (stateDef, xAxisId, min, max) => {
  return async (dispatch, getState) => {
    if (xAxisId === 'primary') {
      // Check min and max to make sure they don't past now
      if (moment().unix() < max) {
        min = min - (max - moment().unix());
        max = moment().unix();
      }

      // Check if there are any new chunks of time that require us to get data for
      try {
        let earliestTimeAlreadyQueried = _.head(getState()[stateDef.key].data[xAxisId].xValues);
        let latestTimeAlreadyQueried = _.last(getState()[stateDef.key].data[xAxisId].xValues);
        earliestTimeAlreadyQueried = _.isNil(earliestTimeAlreadyQueried) ? max : earliestTimeAlreadyQueried;
        latestTimeAlreadyQueried = _.isNil(latestTimeAlreadyQueried) ? max : latestTimeAlreadyQueried;

        let defaultTruck = getState()[stateDef.key].definition[xAxisId].defaultTruck;
        let sensors = getState()[stateDef.key].definition[xAxisId].sensors;
        // If the new min time is earlier than the earliest time we have, dispatch actions to chunk and query for that
        // new time span
        if (min < earliestTimeAlreadyQueried) {
          // Dispatch an action to adjust the x and y values accordingly
          await dispatch(adjustDataRange(stateDef, xAxisId, min, earliestTimeAlreadyQueried));
          // Dispatch an action to chunk and query data for the new time ranges
          await dispatch(queryByChunks(stateDef, defaultTruck, min, earliestTimeAlreadyQueried, sensors));
        }

        // Similarily, if the new max time is later than the latest time we have, dispatch actions to chunk and query for that
        // new time span
        if (max > latestTimeAlreadyQueried) {
          // Dispatch an action to adjust the x and y values accordingly
          await dispatch(adjustDataRange(stateDef, xAxisId, latestTimeAlreadyQueried, max));
          // Dispatch an action to chunk and query data for the new time ranges
          await dispatch(queryByChunks(stateDef, defaultTruck, latestTimeAlreadyQueried, max, sensors));
        }
      } catch (e) {
        return dispatch(handleError(errorMessages.ERROR_RETRIEVING_DATA_EXPLORATION_CHART_DATA, e.message));
      }
    }

    return dispatch(calculateStats(stateDef, xAxisId, min, max));
  }
};

const openSensorSelector = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_OPEN_SENSOR_SELECTOR, 'stateDef');

const requestOpenSensorSelector = (stateDef) => {
  return async (dispatch, getState) => {
    // stateDef key is in format of 'dataExploration_<parent stateDef key>'
    let stateDefParent = stateDef.key.split('-')[1];
    let unitType = getState()[stateDefParent].selectedContext.unitType;
    
    // If unitType is not there, then select the Available Sensors tab and show that to the user
    // first, instead of always showing the Groups tab
    await dispatch(sensorSelectorActions.setSelectedFormIndex(stateDef, _.isNil(unitType) ? 1 : 0));
    
    await dispatch(openSensorSelector(stateDef));
  }
};

const requestCloseSensorSelector = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_CLOSE_SENSOR_SELECTOR, 'stateDef');
const setSelectedSensors = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_SELECTED_SENSORS, 'stateDef', 'xAxisId', 'sensors');
const setColorPickerState = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SET_COLOR_PICKER_STATE, 'stateDef', 'xAxisId', 'refIndex', 'origColor');

const querySensorData = queryActionFactory(
  dataExplorationTypes.DATA_EXPLORATION_CHART_QUERY_STARTING,
  dataExplorationTypes.DATA_EXPLORATION_CHART_QUERY_SUCCESS,
  dataExplorationTypes.DATA_EXPLORATION_CHART_QUERY_ERROR,
  errorMessages.ERROR_RETRIEVING_DATA_EXPLORATION_CHART_DATA,
  fetchDataExploration
);

const resetQuerySensorData = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_QUERY_RESET, 'stateDef');
const onClickChartSettingsPopup = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_CLICK_SETTINGS_POPUP, 'stateDef','chartSettingButtonAnchor');
const onToggleCloseNaNGap = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_TOGGLE_CLOSENANGAP, 'stateDef');

const downloadData = (stateDef) => {
  return async(dispatch, getState) => {
    try {
      let componentState = getState()[stateDef.key];
      const json = transformDataToJson(componentState.data, componentState.definition.primary.sensors, componentState.definition.primary.config,
        componentState.stats.primary);
      
      const dateTimeFormat = 'YYYY-MM-DD@HH-mm-ss';
      const startTime = moment.unix(componentState.stats.primary.xMin).format(dateTimeFormat);
      const endTime = moment.unix(componentState.stats.primary.xMax).format(dateTimeFormat);
      const truckName = componentState.definition.primary.defaultTruck.name;
      const columnNames = [];
      columnNames.push({ name: '', property: 'valueColumn' });
      _.forEach(componentState.definition.primary.sensors, (sensor, i) => {
        if (componentState.definition.primary.config[i].isVisible === true) {
          let sensorNameUom = concatSensorNameAndUom(sensor.alias, sensor.uom);
          if (!_.isEmpty(sensorNameUom)) { 
            columnNames.push( { name: sensorNameUom, property: sensorNameUom });
          }
        }
      }); 
      const exportCsv = exportCsvFactory('data-exploration_' + truckName + '_' + startTime + '_to_' + endTime, columnNames, errorMessages.ERROR_DOWNLOADING_VISIBLE_CHART_DATA);
      await dispatch(exportCsv(stateDef, json));
    } catch(e) {
      return dispatch(handleError(errorMessages.ERROR_VISIBLE_CHART_DATA_DOWNLOAD_PROCESSING, e.message));
    }
  }
};

const rotateAxes = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_ROTATE, 'stateDef', 'rotate');

const setQueryRunningState = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_PROGRESS_STATE, 'stateDef', 'state');
const onClickScreenShotSettingsPopup = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_SCREENSHOT_SETTINGS_POPUP, 'stateDef','screenShotButtonAnchor');
const onToggleDelayedScreenShot = defaultActionFactory(dataExplorationTypes.DATA_EXPLORATION_CHART_TOGGLE_DELAYED_SCREENSHOT, 'stateDef');

export {
  setTemplate,
  setConfigYAxis,
  setConfigColor,
  setConfigVisible,
  calculateStats,
  querySensorData,
  requestOpenSensorSelector,
  requestCloseSensorSelector,
  setSelectedSensors,
  setColorPickerState,
  resetQuerySensorData,
  downloadData,
  userPanZoomUpdate,
  adjustDataRange,
  queryByChunks,
  rotateAxes,
  onClickChartSettingsPopup,
  onToggleCloseNaNGap,
  setQueryRunningState,
  onClickScreenShotSettingsPopup,
  onToggleDelayedScreenShot,
}
