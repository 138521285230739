export default {

  // This action takes in a list of state keys and removes them.
  ROOT_REMOVE_STATE: 'ROOT_REMOVE_STATE',

  // This is a special action that will clear out the display/card state information. Basically
  // it wipes out the redux state with the exception of the root app state. It is used when
  // user with the super user permission switching owners in the UI.
  ROOT_CLEAR_OWNER_STATE: 'ROOT_CLEAR_OWNER_STATE',
};
