import auditLogActionTypes from "./auditLogActionTypes";
import auditLogState from "./auditLogSelectors";
import createAuditLogs from "./services/auditLogService";
import _ from 'lodash';

const initialState = auditLogState();

const auditLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case auditLogActionTypes.AUDIT_LOG_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);
    case auditLogActionTypes.AUDIT_LOG_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);
    case auditLogActionTypes.AUDIT_LOG_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);
    case auditLogActionTypes.AUDIT_LOG_CLEAR_DATA:
      return onClearData(state, action);
    default:
      return state;
  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQueryDataSuccess = (state, action) => {
    let enrichedAuditLogs = [];

    if (!_.isEmpty(action.queryResults.unitAuditLog)) {
        enrichedAuditLogs = createAuditLogs(action.queryResults.unitAuditLog);
    } else if (!_.isEmpty(action.queryResults.fleetAuditLog)) {
        enrichedAuditLogs = createAuditLogs(action.queryResults.fleetAuditLog);
    }


  return {
    ...state,
    queryRunning: false,
    auditLogs: enrichedAuditLogs
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    auditLogs: initialState.auditLogs
  };
};

export default auditLogReducer