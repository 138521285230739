import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import {withProps} from 'recompose';

import {Button, Box, Typography} from '@mui/material';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import * as deviceActions from '../../../../state/displays/settings/actions/devicesActions';
import { deviceState } from '../../../../state/displays/settings/selectors/deviceSelectors';
import ComponentTypes from '../../../componentTypes';

import { trackPage } from '../../../../helpers/googleAnalyticsHelper';
import MDTDataGrid from "../../../common/table/MDTDataGrid";
import {gridClasses} from '@mui/x-data-grid-pro';
import DeviceDialog from '../dialogs/deviceDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import detailsPageStyles from "../../../common/styles/detailsPageStyles";
import {AutoCompleteMDT} from "../../../controls/mdtMuiControls";
import Progress from "../../../controls/progress";
import OWNERS from "../../../common/owners";

const styles = {
  formContainer: {
    marginTop: '20px',
    width: '800px',
  },
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    paddingTop: '10px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start',
  },
  deviceActionsButton: {
    marginLeft: 2,
  },
  textRow: {
    padding: '45px',
  },
  mdtDataGridContainer: {
    display:'flex',
    flexFlow:'column noWrap',
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible',
      },
    },
    [`& .${gridClasses.row}`]: {
      '.MuiDataGrid-cell': {
        paddingTop:'5px',
        paddingBottom:'20px',
      },
    },
    '& .deviceTableCell': {
      display:'flex',
      flexFlow:'column noWrap',
      fontWeight: '400',
    },
  },
  innerTable: {
    width:'100%',
  },
  innerTableCell: {
    color: 'text.primary',
    paddingBottom: '15px',
    paddingLeft: '15px',
    width:"33%",
  },
  innerTableCellText: {
    wordWrap: 'break-word',
    fontSize: '0.875rem',
  },
};



class DeviceSettings extends Component {

  componentDidMount() {
    trackPage(ComponentTypes.DEVICE_SETTINGS, this.props.user);
    this.props.queryTrucksForOwner();
    this.props.queryDeviceSettings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryTrucksForOwner();
      this.props.queryDeviceSettings();
    }
  }

  render() {
      const getOwnerId = () => {
          return _.isNil(this.props.selectedOwner) ? this.props.user.ownerId : this.props.selectedOwner.value;
      }

    const rowsToDisplay = () => {
      const ownerId = getOwnerId();
      let filteredRows = [];
      if(ownerId === OWNERS.MDT) {
        // for MDT user, only show devices not belonging to NexTier
        filteredRows = this.props.deviceSettings.filter(device => device.ownerId !== OWNERS.NEXTIER);
      } else {
        // for other users, show devices belonging to its owner
        filteredRows = this.props.deviceSettings.filter(device => device.ownerId === ownerId);
      }
      return filteredRows;
    }

    let columns = [
      {
        field: 'esn',
        headerName: 'Device ESN',
        headerAlign: 'center',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
        cellClassName: 'deviceTableCell',
      },
    ];
    // NexTier data is read only, also MDT admin (with setting permission) should not be able to change truck
    if ([OWNERS.MDT, OWNERS.NEXTIER].includes(getOwnerId())){
      columns.push(
          {
            field: 'truckPid',
            headerName: 'Truck',
            headerAlign: 'center',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value)=> {
              return this.props.trucks.find(truck => truck.value === value.value)?.label
            },
            cellClassName: 'deviceTableCell'
          }
      );
    } else {
      // for users belongs to other owners, they should be able to change truck for device under their owner
      columns.push(
          {
            field: 'truckPid',
            headerName: 'Truck',
            headerAlign: 'center',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false,
            cellClassName: 'deviceTableCell',
            renderCell: (params) => (
                <Box sx={{width:'80%'}}>
                  <AutoCompleteMDT
                      getOptionLabel={(option) => option.label}
                      options={this.props.trucks}
                      value={this.props.trucks.find(truck => truck.value === params.value)}
                      onChange={(event, value, reason) => {
                        this.props.savePairSettings(params.row, value);
                      }}
                      noOptionsText={'No truck found...'}
                  />
                </Box>
            ),
          }
      );
    }

    // user with device setting permission should be able to view owner column and change owner (NexTier excluded) when selectedOwner is MDT
    // note: this has to be the selectedOwner but not the user owner (to make sure user have contextAdmin access)
    if (this.props.user.hasDeviceSettingPermission && this.props.selectedOwner.value === OWNERS.MDT) {
      columns.unshift(
          {
            field: 'ownerId',
            headerName: 'Owner',
            headerAlign: 'center',
            flex: 1,
            editable: true,
            hideable: false,
            pinnable: false,
            cellClassName: 'deviceTableCell',
            valueGetter: (value)=> {
                return this.props.owners.find(owner => owner.value === value.value)?.label
            },
            renderCell: (params) => (
                <Box sx={{width:'100%', paddingLeft:'5px'}}>
                  <AutoCompleteMDT
                      options={this.props.owners}
                      value={this.props.owners.find(owner => owner.label === params.value)} //valueGetter changed the value for the column to be label
                      onChange={(event, value, reason) => {
                        this.props.saveDeviceSettings(params.row, value);
                      }}
                      noOptionsText={'No owners found...'}
                  />
                </Box>
            ),
          },
      );
    }

    return (
      <Box sx={styles.formContainer}>
        {
          // only user authorized with permission can create devices for owners (other than NexTier) when selectedOwner is MDT
          (this.props.user.hasDeviceSettingPermission && this.props.selectedOwner.value === OWNERS.MDT) &&
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderActions}>
              <Button sx={styles.deviceActionsButton} onClick={() => {
                this.props.onShowDeviceDialog(true, null);
              }} color={'inherit'}>
                <AddCircleIcon sx={{marginRight: '5px'}}/>
                CREATE DEVICE
              </Button>
            </Box>
          </Box>
        }
        <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
              getRowId={row => row.id}
              rows={rowsToDisplay()}
              columns={columns}
              exportFileName={'owner_devices_export'}
              disableSelectionOnClick
              disableColumnSelector
              stateDef={this.props.stateDef}
              rowsPerPage={this.props.rowsPerPage}
              setRowsPerPage={this.props.setRowsPerPage}
              rowsPerPageOptions={this.props.rowsPerPageOptions}
            />
        </Box>
        <DeviceDialog owners={this.props.owners}/>
        <Progress open={this.props.queryRunning}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = deviceState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    deviceCanSave: componentState.device.deviceCanSave,
    deviceSettings: componentState.device.deviceSettings,
    showDeviceDialog: componentState.device.showDeviceDialog,
    rowsPerPage: componentState.device.rowsPerPage,
    user: appState.user,
    rowsPerPageOptions: componentState.device.rowsPerPageOptions,
    owners: appState.owners.filter(owner => owner.value !== OWNERS.NEXTIER), // NexTier is read only, so don't show it in the dropdown list
    trucks: componentState.device.trucks,
    isUserAdmin: appState.user.isUserAdmin,
    queryRunning: componentState.device.queryRunning,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryDeviceSettings: () => { dispatch(deviceActions.queryDeviceSettings(props.stateDef)) },
    queryTrucksForOwner: () => { dispatch(deviceActions.queryTrucksForOwner(props.stateDef)) },
    saveDeviceSettings: (deviceContext, owner) => { dispatch(deviceActions.saveDeviceSettings(props.stateDef, deviceContext, owner)); },
    savePairSettings: (deviceContext, truck) => { dispatch(deviceActions.savePairSettings(props.stateDef, deviceContext, truck)); },
    onShowDeviceDialog: (show, context) => { dispatch(deviceActions.showDeviceDialog(props.stateDef, show)); },
    setRowsPerPage: (rowSize) => { dispatch(deviceActions.dataGridSetRowSize(props.stateDef, rowSize)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DeviceSettings));