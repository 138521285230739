import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import tableReducer from './reducers/tableReducer';

import { dgbFleetMapState } from './dgbFleetMapSelectors';

const initialState = dgbFleetMapState();

const dgbFleetMapReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
      queryReducer,
      tableReducer
  );

  return reducer(state, action);
};

export default dgbFleetMapReducer
