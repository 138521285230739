import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import ComponentTypes from "../../componentTypes";
import * as auditLogActions from '../../../state/cards/auditLog/auditLogActions';
import auditLogCardState from '../../../state/cards/auditLog/auditLogSelectors';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";
import { appState as applicationState } from '../../../state/app/appSelectors';

import {getCardFromLayoutConfigViews} from "../../common/layout/layoutHelper";
import AuditLogCard from "../common/auditLogCard/auditLogCard";

import { ButtonBase } from '@mui/material'
import ListIcon from '@mui/icons-material/List';

const styles = {
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
};

class UnitAuditLog extends MDTCard {

  constructor(props) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  getName() {
    return 'AUDIT LOG';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  onNavigate() {
    if (this.isContextReady()) {
      // the startTime and endTime already in unix timestamp when it's set in the context
      return this.props.navigateToDashboard(this.props.context.truck?.name, this.props.context.startTime, this.props.context.endTime);
    }
  }

  getRenderedButtonsInHeader() {
    return (
      this.isContextReady() &&
      !this.isInEditMode() &&
      <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.onNavigate()}><ListIcon/></ButtonBase>
    )
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
        this.props.queryData(this.props.context.truck.truckPid, this.props.context.startTime, this.props.context.endTime, this.props.card?.configuration?.rowSize);
    }
  }

  getRenderedContent() {
    return (
      <AuditLogCard auditLogs={this.props.auditLogs} cardConfig={this.props.card} stateDef={this.props.stateDef} />
    )
  }
}

UnitAuditLog.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_AUDIT_LOG : props.stateKey,
      type: ComponentTypes.UNIT_AUDIT_LOG,
    }
  }
};

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let componentState = auditLogCardState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    ...mdtCardMapStateToProps(state, props),
    auditLogs: componentState.auditLogs,
    name: componentState.name,
    card: getCardFromLayoutConfigViews(appState.user.dashboards[props.dashboard]?.views, props.view, props.cardKey)
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (truckPid, startTime, endTime, rowSize) => { dispatch(auditLogActions.queryData(props.stateDef, truckPid, props.dashboard, startTime, endTime, rowSize )) },
    clearData: () => { dispatch(auditLogActions.clearData(props.stateDef)) },
    navigateToDashboard: (truckName, startTime, endTime) => { dispatch(auditLogActions.navigateByTruckAndTimeFrame(truckName, startTime, endTime, props.dashboard)) }
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UnitAuditLog));