
const alarmSeverities = {
  CRITICAL: 100,
  WARNING: 200,
  INFORMATION: 300,
  MESSAGE: 400,
};

const alarmSeverityNames = {
  CRITICAL: 'Critical',
  WARNING: 'Warning',
  INFORMATION: 'Info',
  MESSAGE: 'Message',
};

const getSeverityName = (severity) => {
  switch (severity) {
    case alarmSeverities.CRITICAL:
      return alarmSeverityNames.CRITICAL;
    case alarmSeverities.WARNING:
      return alarmSeverityNames.WARNING;
    case alarmSeverities.INFORMATION:
      return alarmSeverityNames.INFORMATION;
    case alarmSeverities.MESSAGE:
      return alarmSeverityNames.MESSAGE;
    default:
      return severity;
  }
};

export {
  alarmSeverities,
  alarmSeverityNames,
  getSeverityName
};