export default {
  ENGINE: 'engine',
  TRANSMISSION: 'transmission',
  POWER_END: 'powerEnd',
  LOCKUP: 'lockup',
  COIL_DETAIL: 'coilDetail',
  COIL: 'coil',
  PRESSURE_HISTORY: 'pressureHistory',
  OUTPUT_HISTORY: 'outputHistory',
  ROADSIDE_PRESSURE_HISTORY: 'roadsidePressureHistory',
  CURBSIDE_PRESSURE_HISTORY: 'curbsidePressureHistory',
  VIBRATION_HISTORY: 'vibrationHistory'
};


