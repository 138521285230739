import _ from "lodash";
import { userMgmtState } from '../../userMgmtSelectors';

const initialState = userMgmtState();

const defaultWorkingUser = initialState.workingUser;

const defaultWorkingUserValidation = initialState.workingUserValidation;

const createWorkingUser = (existing) => {

  if (!_.isNil(existing)) {
    return {
      ...defaultWorkingUser,
      id: existing.id,
      name: existing.name,
      email: existing.email,
      isEnabled: existing.isEnabled,
      isAdmin: existing.isAdmin,
    }
  }

  return {...defaultWorkingUser};
};

const validateWorkingUser = (state) => {

  state.workingUserValidation = {...defaultWorkingUserValidation};

  // We do not want users to see errors for form inputs they have no yet
  // entered, for these we just disable the buttons so they cannot proceed
  // ahead. If there is a value and it is not valid, then we set the individual
  // error flags.

  if (_.isNil(state.workingUser.name) || _.isEmpty(state.workingUser.name)) {
    state.workingUserValidation.hasErrors = true;
  } else if (state.workingUser.name.length > 60) {
    state.workingUserValidation.hasErrors = true;
    state.workingUserValidation.hasErrorName = true;
  }

  if (_.isNil(state.workingUser.email) || _.isEmpty(state.workingUser.email)) {
    state.workingUserValidation.hasErrors = true;
  } else if (!validateEmail(state.workingUser.email)) {
    state.workingUserValidation.hasErrors = true;
    state.workingUserValidation.hasErrorEmail = true;
  }

  if (state.openAddUserUI) {

    if (_.isNil(state.workingUser.confirmEmail) || _.isEmpty(state.workingUser.confirmEmail)) {
      state.workingUserValidation.hasErrors = true;
    } else if (state.workingUser.confirmEmail !== state.workingUser.email) {
      state.workingUserValidation.hasErrors = true;
      state.workingUserValidation.hasErrorConfirmEmail = true;
    }

  }

};

const validateEmail = (email) => {
  let regex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export {
  defaultWorkingUser,
  defaultWorkingUserValidation,
  createWorkingUser,
  validateWorkingUser,
}