import {performCardFetch} from '../../../common/fetch'
import _ from "lodash";
import useSimulationData from '../../../../helpers/simulationHelper';

import * as jobStageChartSimulation from './jobStageChartSimulation'

const fetchJobStageChart = (stage, startTime, endTime, sensors) => {

  if (useSimulationData('jobStageChart')) {
    return jobStageChartSimulation.fetchJobStageChart(stage, startTime, endTime, sensors);
  }

  const inputs = [] 
  _.forEach(sensors, s => {
    inputs.push({
      'processId': stage.processes[0].id,
      'sensorSetId': s.sensorSetId,
      'unitOfMeasure': s.uom,
      'startTime': startTime,
      'endTime': endTime,
    });
  });

  return performCardFetch({
    "operationName": "processChart",
    'query': 'query processChart($input:[ProcessSeriesInput]!) { processChart(input:$input) { processId sensorSetId unitOfMeasure timestamps values } }',
    'variables': {
      'input': inputs
    }
  });
};

export {
  fetchJobStageChart
};