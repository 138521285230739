import _ from 'lodash';
import { ownerManagementState } from './ownerManagementSelectors';
import ownerManagementActionTypes from './ownerManagementActionTypes';

const initialState = ownerManagementState();

const ownerManagementReducer = (state=initialState, action) => {

  switch (action.type) {
    
    case ownerManagementActionTypes.SELECT_SETTING:
      return onSelectSetting(state, action);
    
    case ownerManagementActionTypes.SHOW_CREATE_OWNER_DIALOG:
      return onShowCreateOwnerDialog(state, action);

    case ownerManagementActionTypes.SET_CREATE_OWNER_NAME:
      return onSetCreateOwnerName(state, action);
  };

};

const onSelectSetting = (state, action) => {

  return {
    ...state,
    selectedSetting: action.setting
  }

}

const onShowCreateOwnerDialog = (state, action) => {

  return {
    ...state,
    showCreateOwnerDialog: action.show
  }
  
}

const onSetCreateOwnerName = (state, action) => {

  return {
    ...state, 
    createOwnerName: action.name
  }
}

export default ownerManagementReducer;