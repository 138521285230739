import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';
import { Typography, ButtonBase, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ComponentTypes from '../../componentTypes';

import * as unitFleetActions from '../../../state/cards/unitFleet/unitFleetActions';
import { unitFleetState } from '../../../state/cards/unitFleet/unitFleetSelectors';
import * as fleetMapActions from '../../../state/displays/fleetMap/display/fleetMapActions';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';

let cardStyles = getCardStyles();
let layoutStyles = getLayoutStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardValue: {
    color: 'rgba(255, 255, 255, 0.7)'
  },
  fleetNameValue: {
    color: 'rgba(255, 255, 255, 0.7)',
    width: '300px', // have to be px width to support text-overflow
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace:'nowrap',
    textOverflow: 'ellipsis'
  },
  detailsContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: 1
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
};

class UnitFleet extends MDTCard {

  getName() {
    return 'FLEET';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck.id);
    }
  }

  getRenderedButtonsInHeader() {
    return (
        !_.isNil(this.props.fleetId) &&
        !this.isInEditMode() &&
        <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.props.navigateToDashboard(this.props.fleetId)}><DashboardIcon/></ButtonBase>
    )
  }

  getRenderedContent() {

    // function to adjust text display size based on text length
     const customFontSize = (textLength) => {
       if (textLength > 27)  return "h6";
       else if (textLength > 15)  return "h5";
       else return "h4";
    };

    return (
        <Box sx={styles.cardContent}>
          <Typography variant={customFontSize(this.props.name.length)} sx={styles.fleetNameValue}>{this.props.name}</Typography>
          <Box sx={styles.detailsContent}>
            <div>
              <Typography variant={'caption'}>Type</Typography>
              <Typography variant={'subtitle1'} sx={styles.cardValue}>{this.props.type}</Typography>
            </div>
            <div>
              <Typography variant={'caption'}>Previous</Typography>
              <Typography variant={'subtitle1'} sx={styles.cardValue}>{this.props.previous}</Typography>
            </div>
          </Box>
        </Box>
    )
  }

}

UnitFleet.propTypes = mdtCardPropTypes

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_FLEET : props.stateKey,
      type: ComponentTypes.UNIT_FLEET,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitFleetState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    name: componentState.name,
    type: componentState.type,
    previous: componentState.previous,
    fleetId: componentState.fleetId,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(unitFleetActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(unitFleetActions.clearData(props.stateDef)) },
    navigateToDashboard: (fleetId) => dispatch(fleetMapActions.navigate(fleetId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitFleet))

