import _ from 'lodash';

const warmStartDefaultLabel = () => {
  return 'Warm Start';
}

const unitHoursState = state => {

  return {
    // The card metrics
    ecmHours: _.isNil(state) ? '-' : state.ecmHours,
    pumpHours: _.isNil(state) ? '-' : state.pumpHours,
    tcmHours: _.isNil(state) ? '-' : state.tcmHours,
    warmStartState: _.isNil(state) ? '-' : state.warmStartState,
    warmStartMetrics: _.isNil(state) ? [] : state.warmStartMetrics,
    warmStartMetricsLabel: _.isNil(state) ? warmStartDefaultLabel() : state.warmStartMetricsLabel,
    warmStartMetricsIndex: _.isNil(state) ? 0 : state.warmStartMetricsIndex
  };

};

export {
  unitHoursState,
  warmStartDefaultLabel
};