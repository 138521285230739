import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null
  };

  let transPressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.TRANS_PRESSURE]);
  transformedSeries.series1 = unitChartService.transformSeries(transPressureSeries, 1);

  let transTempSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.TRANS_TEMP]);
  transformedSeries.series2 = unitChartService.transformSeries(transTempSeries, 1);

  let gearSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.GEAR]);
  transformedSeries.series3 = unitChartService.transformSeries(gearSeries);

  // Apply the logic to convert gears values below 1 to their appropriate letter representation
  if (!_.isNil(transformedSeries.series3)) {
    if (transformedSeries.series3.latest === '-2') {
      transformedSeries.series3.latest = 'W';
    } else if (transformedSeries.series3.latest === '-1') {
      transformedSeries.series3.latest = 'B';
    } else if (transformedSeries.series3.latest === '0') {
      transformedSeries.series3.latest = 'N';
    }
  }

  return transformedSeries;
};


export {
  transformSeries,
}