import reduceReducers from 'reduce-reducers';

import itemsReducer from './reducers/itemsReducer';
import exportReducer from "./reducers/exportReducer";

import { systemSoftwareState } from './systemSoftwareSelectors';

const initialState = systemSoftwareState();

const systemSoftwareReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    itemsReducer,
    exportReducer
  );

  return reducer(state, action);
};

export default systemSoftwareReducer
