import _ from 'lodash';
import { styled } from '@mui/styles';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { mdtPalette } from "../../../common/styles/mdtPalette";
import { Typography, Button, Box } from "@mui/material";

// This is an example of the styled api from MUI v5.

const ButtonFontSize = '10px';

const AutoButton = styled(Button)(({ theme }) => ({
  alignSelf: 'stretch',
  size: "small",
  boxShadow: "none",
  textTransform: "none",
  padding: "0px 0px",
  border: "0px solid",
  lineHeight: '15px',
  backgroundColor: mdtPalette().status.level2,
  fullWidth: true,
  disableElevation: true,
  disableFocusRipple: true,
  disableRipple: true,
  "&:hover": {
    backgroundColor: mdtPalette().status.level2,
    boxShadow: "none"
  },
}));

const ManualButton = styled(Button)(({ theme }) => ({
  alignSelf: 'stretch',
  size: "small",
  boxShadow: "none",
  textTransform: "none",
  padding: "0px 0px",
  border: "0px solid",
  lineHeight: '15px',
  backgroundColor: mdtPalette().status.level4,
  fullWidth: true,
  disableElevation: true,
  disableFocusRipple: true,
  disableRipple: true,
  "&:hover": {
    backgroundColor: mdtPalette().status.level4,
    boxShadow: "none"
  },
}));

const NoDataButton =  styled(Button)(({ theme }) => ({
  alignSelf: 'stretch',
  size: "small",
  boxShadow: "none",
  textTransform: "none",
  padding: "0px 0px",
  border: "0px solid",
  lineHeight: '15px',
  backgroundColor: mdtPalette().typography.noData,
  fullWidth: true,
  disableElevation: true,
  disableFocusRipple: true,
  disableRipple: true,
  "&:hover": {
    backgroundColor: mdtPalette().typography.noData,
    boxShadow: "none"
  },
}));

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'none',
    width: '100%',
    padding: '0px 10px',
    alignItems: 'center'
  },

  title: {
    variant: 'caption',
    color: mdtPalette().typography.color,
    fontSize: '12px',
  },

  autoLabel: {
    color: mdtPalette().typography.black,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: ButtonFontSize,
    whiteSpace: 'nowrap',
  },

  manualLabel: {
    color: mdtPalette().typography.black,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: ButtonFontSize,
    whiteSpace: 'nowrap',
  },

  noDataLabel: {
    color: mdtPalette().typography.black,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: ButtonFontSize,
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },
};

// A highly specific control for indicating auto vs manual control, used for
// Tub Mode on blender and hydration trucks.
class AutoManualIndicator extends Component {

    render() {
      return (
        <Box sx={styles.container}>
          <Typography sx={styles.title}>{this.props.title}</Typography>
          {
            _.isNil(this.props.value) &&
            <NoDataButton sx={styles.noDataLabel}>NO DATA</NoDataButton>
          }
          {
            !_.isNil(this.props.value) && this.props.value === true &&
            <AutoButton sx={styles.autoLabel}>AUTO</AutoButton>
          }
          {
            !_.isNil(this.props.value) && this.props.value === false &&
            <ManualButton sx={styles.manualLabel}>MANUAL</ManualButton>
          }
        </Box>
      );
    }
}

AutoManualIndicator.propTypes = {
    title: PropTypes.string,
    value: PropTypes.bool,
};

export default AutoManualIndicator;
