import _ from 'lodash';

const subscriptionsDisplayState = state => {

  return {

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    subscriptions: _.isNil(state) ? [] : state.subscriptions,

    showDeleteSubscriptionDialog: _.isNil(state) ? false : state.showDeleteSubscriptionDialog,
    showAssignSubscriptionDialog: _.isNil(state) ? false : state.showAssignSubscriptionDialog,
    subscription: _.isNil(state) ? {} : state.subscription,

    rowsPerPage: _.isNil(state) ? 10 : state.rowsPerPage,
    rowsPerPageOptions: [5,10,25,50,100]
  };
  
};

export {
  subscriptionsDisplayState
};