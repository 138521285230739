import _ from 'lodash';
import moment from 'moment';
import { alarmSeverities } from '../../common/services/alarmSeverityService';

const fetchActiveAlarmDetails = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        activeAlarmDetails : activeAlarms
      })
    }, 1000)
  });
};

const fetchAlarmHistoryDetails = (startTime, endTime) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        historicalAlarmDetails : historicalAlarms
      })
    }, 1000)
  });
};

const alarmDefinitions = [
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 100',
    details: 'Some alarm description (sargasso caracara)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 101',
    details: 'Some alarm description (caeoma edgeways)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'J1939',
    category: 'Category 102',
    details: 'Some alarm description (plangent calathus)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'J1939',
    category: 'Category 103',
    details: 'Some alarm description (gamut lectern)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 104',
    details: 'Some alarm description (fringing duma)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 105',
    details: 'Some alarm description (burgle gambado)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'Unknown',
    category: 'Category 106',
    details: 'Some alarm description (attached revile)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 107',
    details: 'Some alarm description (boyish mouse)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'STW',
    category: 'Category 108',
    details: 'Some alarm description (amylene hanging)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'HMI',
    category: 'Category 109',
    details: 'Some alarm description (git chopine)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'J1939',
    category: 'Category 110',
    details: 'Some alarm description (lacus faucibus)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'HMI',
    category: 'Category 111',
    details: 'Some alarm description bibendum dolor)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 200 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (speak nomism) - Extra details (skittles fruits betoken chastise)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 201 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (telex sixpence) - Extra details (lated pyaemia dubbing expiate)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'J1939',
    category: 'Category 202 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (engraft melamed) - Extra details (unstep saddles ortolan temporal)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'J1939',
    category: 'Category 203 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (acidify relent) - Extra details (hydrated tinder piceous rip)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'J1939',
    category: 'Category 204 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (quince aperture) - Extra details (mandolin wiliness shock playroom)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 205 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (impotent kingbird) - Extra details (cassaba animism jar grill)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'STW',
    category: 'Category 206 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (monk must) - Extra details (toed canasta pommel embryo)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 207 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (natation peril) - Extra details (evildoer coaction steak nth)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 208 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (plumbism sapajou) - Extra details (entirety delicacy imbrue tracking)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 209 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (pecten hangman) - Extra details (saber durative etiolate uranic)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'HMI',
    category: 'Category 210 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (plumbism sapajou) - Extra details (Pellentesque quis ante tincidunt)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'STW',
    category: 'Category 211 - (^+*.!-],[@$M=)',
    details: 'Some alarm description (pecten hangman) - Extra details (placerat tortor, sed luctus ligula mi a lectus)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 300 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (speak nomism) - Extended details (skittles fruits betoken chastise telex sixpence lated pyaemia)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 301 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (dubbing expiate) - Extended details (engraft melamed unstep saddles ortolan temporal acidify relent)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 302 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (hydrated tinder) - Extended details (piceous rip quince aperture mandolin wiliness shock playroom)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 303 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (impotent kingbird) - Extended details (cassaba animism jar grill monk must toed canasta)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 304 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (pommel embryo) - Extended details (natation peril evildoer coaction steak nth plumbism sapajou)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 305 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (entirety delicacy) - Extended details (imbrue tracking pecten hangman saber durative etiolate uranic)',
  },
  {
    severity: alarmSeverities.WARNING, type: 'J1939',
    category: 'Category 306 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (phonic resume) - Extended details (quaggy millibar bridge miscible syllabus weanling pizzeria overheat)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'J1939',
    category: 'Category 307 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (forego sideway) - Extended details (caddie shoer uncloak tmesis doited beeswax leucoma shod)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 308 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (improper phonics) - Extended details (pricket ethics sunless glaring spasm fellow pitiable figurant)',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'Unknown',
    category: 'Category 309 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (bedeck check) - Extended details (intersex sober plier context benzoyl tether burying thereof)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'Unknown',
    category: 'Category 310 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (nulla sit) - Extended details (Phasellus nibh odio, consectetur ut urna laoreet, interdum ultrices felis.)',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'J1939',
    category: 'Category 311 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (varius justo) - Extended details (porttitor efficitur mi a, iaculis blandit nisi. Nullam justo mauris, cursus)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 400 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (hendrerit non) - Extended details (Phasellus egestas nisi risus, ut commodo velit sodales efficitur. Ut quis velit lorem. In eu erat nec tellus rutrum eleifend et a risus. Etiam commodo odio a tellus pellentesque, a condimentum neque rhoncus. Etiam gravida leo neque, vitae dignissim dui vulputate non. Nunc ut ante semper, faucibus ligula vel, ornare enim. Quisque varius, leo eu ullamcorper sodales, orci nisi lacinia est, quis fermentum felis nisi id lectus. Praesent facilisis lacus et massa pellentesque, et lobortis lorem molestie. Fusce tempus enim leo, ut laoreet sapien hendrerit vel. Pellentesque sollicitudin sem et dolor mattis, sit amet facilisis neque luctus. Duis dui libero, lacinia nec varius non, commodo ut ligula. Vestibulum non purus sit amet sapien blandit hendrerit in eget metus. Vivamus facilisis dui sapien, vitae vestibulum velit consequat sit amet. Vivamus nisl lorem, elementum a dui at, condimentum fringilla leo.)',
  },
  {
    severity: alarmSeverities.CRITICAL, type: 'HMI',
    category: 'Category 401 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (quis dui mattis) - Extended details (Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempor tincidunt justo, in pulvinar ipsum tempus vel. Fusce congue ipsum ut turpis ultricies, non commodo nisl efficitur. Donec mattis lorem vitae ipsum laoreet varius. Morbi eros neque, efficitur et suscipit non, rutrum vel tellus. Ut consectetur sagittis lorem, sit amet rutrum nunc auctor sed. Ut mollis augue nibh, imperdiet pharetra enim fermentum id. Nam tincidunt nisi quis dignissim vestibulum. Sed et nibh ut ipsum aliquet dapibus. Fusce ut justo lacus. Nam dictum leo augue, id porta orci iaculis quis. Pellentesque non lectus id libero auctor congue. Morbi fermentum porttitor diam, vitae tempus nibh tincidunt ac. Donec tempus, est a tempor blandit, eros felis tincidunt arcu, at accumsan turpis tortor at massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris varius vitae mi eu pellentesque. Quisque vitae ligula eu odio sagittis tristique sit amet vitae lorem. )',
  },
  {
    severity: alarmSeverities.WARNING, type: 'HMI',
    category: 'Category 402 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (Sed ac venenatis) - Extended details (Duis blandit dolor sollicitudin leo pretium, at blandit velit dictum. Nulla elit neque, tempus eget elementum et, bibendum non quam. Ut porttitor hendrerit turpis tincidunt sollicitudin. Donec finibus metus eget urna cursus, quis rhoncus enim semper. Nunc eu aliquam nisi. Sed vitae libero sagittis, placerat metus accumsan, tincidunt dolor. Aenean nec pretium sapien. Nunc interdum egestas ligula, at lobortis arcu rutrum a. Phasellus gravida ante ipsum, sit amet auctor augue maximus eu. Etiam venenatis elit quis finibus consequat. )',
  },
  {
    severity: alarmSeverities.WARNING, type: 'STW',
    category: 'Category 403 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (impotent kingbird) - Extended details (Phasellus imperdiet turpis tellus, et mollis ligula rutrum sit amet. Sed id ultrices dolor, et luctus augue. Nam lorem lacus, pharetra ullamcorper lorem vestibulum, consequat commodo diam. Donec quis hendrerit odio, vitae mollis lectus. Nulla sit amet nibh nec libero accumsan imperdiet id non nisl. Vestibulum blandit accumsan enim ut placerat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et rutrum dolor. Praesent eget enim pharetra, rhoncus justo nec, viverra nisi. Nullam eget leo erat. Nullam suscipit est nec libero sodales aliquet. )',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'STW',
    category: 'Category 404 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (porttitor vel nibh) - Extended details (Donec hendrerit ut ex a placerat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel egestas orci, in semper ex. Integer vel ipsum risus. Ut massa lacus, malesuada ut ipsum eu, egestas egestas tortor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam ipsum odio, lobortis imperdiet arcu non, mollis eleifend leo. Suspendisse dignissim quis enim at fermentum. Cras nec fringilla neque. Proin viverra magna vel lectus dapibus auctor. Donec consequat risus pharetra, porta elit non, efficitur tellus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla ultrices leo quis fermentum finibus. Sed aliquet lorem sed erat pretium hendrerit. Sed id varius tellus. )',
  },
  {
    severity: alarmSeverities.INFORMATION, type: 'STW',
    category: 'Category 405 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (Nullam mollis) - Extended details (Cras ut placerat mi, sed pellentesque urna. Quisque non elit sit amet ipsum posuere tempor quis ut diam. Suspendisse iaculis urna id justo sollicitudin, quis mollis ipsum rhoncus. Vestibulum posuere, magna id lobortis egestas, est lectus feugiat leo, id tincidunt nibh enim quis purus. Vestibulum vestibulum nulla aliquet lectus pulvinar, id luctus mauris auctor. Cras venenatis iaculis odio a lobortis. Duis sit amet pulvinar ipsum. Pellentesque id mi mattis, venenatis ligula sit amet, maximus nisl. Fusce fermentum tortor at felis euismod, vitae gravida arcu egestas. Etiam nisi urna, laoreet at aliquet sit amet, convallis vitae eros. Fusce pellentesque volutpat sapien. In vulputate, massa id venenatis elementum, erat eros tristique felis, eget semper ipsum ligula nec justo. Sed ornare mauris ut sollicitudin semper. Curabitur dui nibh, fermentum in condimentum at, vestibulum et augue. Ut tincidunt et turpis ac malesuada. Curabitur auctor orci nisi, sit amet rhoncus leo sodales vitae. )',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'J1939',
    category: 'Category 406 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (Nam gravida) - Extended details (Donec lacus lacus, posuere tincidunt porttitor quis, bibendum sit amet leo. Suspendisse potenti. Aenean maximus mauris euismod quam imperdiet sagittis id eget mauris. Sed accumsan, erat ac placerat imperdiet, velit purus ullamcorper nibh, sed tincidunt leo ligula in tellus. Suspendisse fringilla, ligula et luctus viverra, nisl urna ultrices libero, sed imperdiet metus elit eu ex. Fusce venenatis, massa non dapibus laoreet, justo justo eleifend urna, vel tincidunt tellus quam sit amet mi. Vestibulum dictum nisi ac metus hendrerit egestas. Praesent ut sapien ut arcu sollicitudin maximus vitae ut mi. Ut orci turpis, fermentum quis tempus et, aliquet nec risus. )',
  },
  {
    severity: alarmSeverities.MESSAGE, type: 'J1939',
    category: 'Category 407 - With additional information (^+*.!-],[@$M=)',
    details: 'Some alarm description (dictum hendrerit) - Extended details (Cras ut placerat mi, sed pellentesque urna. Quisque non elit sit amet ipsum posuere tempor quis ut diam. Suspendisse iaculis urna id justo sollicitudin, quis mollis ipsum rhoncus. Vestibulum posuere, magna id lobortis egestas, est lectus feugiat leo, id tincidunt nibh enim quis purus. Vestibulum vestibulum nulla aliquet lectus pulvinar, id luctus mauris auctor. Cras venenatis iaculis odio a lobortis. Duis sit amet pulvinar ipsum. Pellentesque id mi mattis, venenatis ligula sit amet, maximus nisl. Fusce fermentum tortor at felis euismod, vitae gravida arcu egestas. Etiam nisi urna, laoreet at aliquet sit amet, convallis vitae eros. Fusce pellentesque volutpat sapien. In vulputate, massa id venenatis elementum, erat eros tristique felis, eget semper ipsum ligula nec justo. Sed ornare mauris ut sollicitudin semper. Curabitur dui nibh, fermentum in condimentum at, vestibulum et augue. Ut tincidunt et turpis ac malesuada. Curabitur auctor orci nisi, sit amet rhoncus leo sodales vitae.)',
  },
];

const createActiveAlarms = () => {

  let alarms = [];

  for(let i=0; i<50; i++) {

    let fleet = getFleet();
    let unit = getUnit();

    let alarm = getAlarm(true);

    while (!_.isNil(_.find(alarms, {'truck': unit.id, 'category': alarm.category}))) {
      alarm = getAlarm(true);
    }

    let timestamp = getActiveDate();
    // Note: We use subtract instead of add since getDate can return the current time
    let raisedTimestamp = alarm.state === 'Acknowledged' ? moment.unix(timestamp).subtract(45, 'minutes').unix() : timestamp;
    let acknowledgedTimestamp = alarm.state === 'Acknowledged' ? timestamp : undefined;

    alarms.push({
      unitName: unit.id,
      unitType: unit.type,
      fleetName: fleet.fleet,
      severity: alarm.severity,
      state: alarm.state,
      type: alarm.type,
      details: alarm.details,
      category: alarm.category,
      raisedTimestamp: raisedTimestamp,
      acknowledgedTimestamp: acknowledgedTimestamp,
    })
  }

  return alarms;

};

const createAlarmHistory = () => {

  let alarms = [];

  for(let i=0; i<50; i++) {

    let fleet = getFleet();
    let unit = getUnit();
    let alarm = getAlarm();

    let timestamp = getHistoricalDate();

    if (alarm.state === 'Acknowledged') {

      // If the alarm event was 'Ack' then create an associated 'Raised' event.

      alarms.push({
        unitName: unit.id,
        unitType: unit.type,
        fleetName: fleet.fleet,
        severity: alarm.severity,
        state: 'Raised',
        type: alarm.type,
        details: alarm.details,
        category: alarm.category,
        timestamp: moment.unix(timestamp).subtract(45,'minutes').unix()
      });

    } else if (alarm.state === 'Cleared') {

      // If the alarm event was 'Cleared' then create an associated 'Raised' event AND randomly create
      // and associated 'Ack' event.

      alarms.push({
        unitName: unit.id,
        unitType: unit.type,
        fleetName: fleet.fleet,
        severity: alarm.severity,
        state: 'Raised',
        type: alarm.type,
        details: alarm.details,
        category: alarm.category,
        timestamp: moment.unix(timestamp).subtract(60,'minutes').unix(),
      });

      // Randomly decide if it was acknowledged
      if (_.random(0,1) === 1) {

        alarms.push({
          unitName: unit.id,
          unitType: unit.type,
          fleetName: fleet.fleet,
          severity: alarm.severity,
          state: 'Acknowledged',
          type: alarm.type,
          details: alarm.details,
          category: alarm.category,
          timestamp: moment.unix(timestamp).subtract(15, 'minutes').unix(),
        });

      }
    }

    alarms.push({
      unitName: unit.id,
      unitType: unit.type,
      fleetName: fleet.fleet,
      severity: alarm.severity,
      state: alarm.state,
      type: alarm.type,
      details: alarm.details,
      category: alarm.category,
      timestamp: timestamp,
    })

  }

  return alarms;
};

const getFleet = () => {
  const fleets = [
    { fleet: 'WTX-3', region: 'West'},
    { fleet: 'WTX-4', region: 'West'},
    { fleet: 'WTX-5', region: 'West'},
    { fleet: 'WTX-6', region: 'West'},
    { fleet: 'MC-1', region: 'Mid-Con'},
    { fleet: 'MC-2', region: 'Mid-Con'},
    { fleet: 'MC-3', region: 'Mid-Con'},
    { fleet: 'MC-4', region: 'Mid-Con'},
    { fleet: 'STX-1', region: 'South'},
    { fleet: 'STX-2', region: 'South'},
    { fleet: 'STX-3', region: 'South'},
    { fleet: 'STX-4', region: 'South'},
    { fleet: 'ETX-1', region: 'East'},
    { fleet: 'ETX-2', region: 'East'},
    { fleet: 'ETX-3', region: 'East'},
    { fleet: 'ETX-4', region: 'East'},
  ];
  return _.sample(fleets);
};

const getUnit = () => {

  const types = ['FracPumper', 'Datavan_Main', 'Blender', 'Chemvan', 'Hydration', 'DFP', 'Cement'];

  let type = _.sample(types);
  let id = ''+(_.random(100000, 999999));
  return { id: id, type: type };
};

const getAlarm = (activeOnly) => {

  let definition = _.sample(alarmDefinitions);

  const activeStates = ['Raised', 'Acknowledged'];
  const historyStates = ['Raised', 'Acknowledged', 'Cleared'];

  let state = activeOnly === true ? _.sample(activeStates) : _.sample(historyStates);

  return {
    severity: definition.severity,
    state: state,
    type: definition.type,
    category: definition.category,
    details: definition.details,
  }
};

const getHistoricalDate = () => {
  // Return a random timestamp from the last 7 days
  return _.random(moment().subtract(7, 'days').unix(), moment().unix());
};

const getActiveDate = () => {
  // Return a random timestamp from the last 24 hours
  return _.random(moment().subtract(1, 'days').unix(), moment().unix());
};


const activeAlarms = createActiveAlarms();
const historicalAlarms = createAlarmHistory();

export {
  fetchActiveAlarmDetails,
  fetchAlarmHistoryDetails
}
