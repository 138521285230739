import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import {exportCsvFactory} from '../../common/exportCsv/exportCsvActions';

import componentHoursActionTypes from './componentHoursActionTypes';
import * as componentHoursQueries from './componentHoursQueries';
import * as componentHoursService from './services/componentHoursService';

import errorMessages from '../../common/errorMessages';

const setShowConsumedHours = defaultActionFactory(componentHoursActionTypes.COMPONENT_HOURS_SET_SHOW_CONSUMED_HOURS, 'stateDef', 'showConsumedHours');

const queryComponentHoursStarting = defaultActionFactory(componentHoursActionTypes.COMPONENT_HOURS_QUERY_STARTING, 'stateDef');
const queryComponentHoursSuccess = defaultActionFactory(componentHoursActionTypes.COMPONENT_HOURS_QUERY_SUCCESS, 'stateDef', 'queryResults');
const queryComponentHoursError = defaultActionFactory(componentHoursActionTypes.COMPONENT_HOURS_QUERY_ERROR, 'stateDef');

const queryData = queryActionCustomFactory(
  queryComponentHoursStarting,
  queryComponentHoursSuccess,
  queryComponentHoursError,
  errorMessages.ERROR_RETRIEVING_COMPONENT_HOURS,
  componentHoursQueries.fetchComponentHoursDisplayDetails
);

const exportCsv = exportCsvFactory('component-hours');

const exportHoursToCsv = (stateDef, units, components, sortContext, sortDirection) => {
  return async (dispatch, getState) => {
    let columns = componentHoursService.resolveCsvColumns(components);
    return dispatch(exportCsv(stateDef, units, columns, sortContext, sortDirection));
  }
};

export {
  queryData,
  exportCsv,
  exportHoursToCsv,
  setShowConsumedHours
}