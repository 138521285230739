import _ from 'lodash';
import deviceDialogActionTypes from './deviceDialogActionTypes';
import { deviceDialogState } from './deviceDialogSelectors';
import { evaluateCanSave } from './services/deviceDialogServices';

const initialState = deviceDialogState();

const deviceDialogReducer = (state=initialState, action) => {

  switch (action.type) {

    case deviceDialogActionTypes.LOAD_CONTEXT_DATA_STARTING:
      return { ...state, queryRunning: true }
    case deviceDialogActionTypes.LOAD_CONTEXT_DATA_ERROR:
      return { ...state, queryRunning: false }
    case deviceDialogActionTypes.LOAD_CONTEXT_DATA_SUCCESS:
      return onLoadContextDataSuccess(state, action);
    case deviceDialogActionTypes.SET_NEW_DEVICE_NAME:
      return onSetDeviceName(state, action);
    case deviceDialogActionTypes.SET_NEW_DEVICE_OWNER:
      return onSelectDeviceOwner(state, action);
    case deviceDialogActionTypes.CLEAR_FORM:
      return onClearForm(state, action);
    default:
      return state;
  }

};


const onLoadContextDataSuccess = (state, action) => {

  const newState = {
    ...state,
    queryRunning: false
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave
  }

};


const onSetDeviceName = (state, action) => {

  const newState = {
    ...state,
    deviceName: action.value,
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}


const onSelectDeviceOwner = (state, action) => {

  const newState = {
    ...state,
    deviceOwner: action.value
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onClearForm = (state, action) => {

  const newState = {
    ...state,
    deviceName: '',
    deviceOwner: {label:"MDT", value:1} // set to default
  }

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

}


export default deviceDialogReducer;