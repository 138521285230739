import _ from 'lodash';
import moment from 'moment';

const fetchUnitHoursCardData = () => {

  return new Promise((resolve) => {
    setTimeout(() => {

      let warmStartStateRandom = _.random(1,3);
      let warmStartStateString = 'Disabled';
      switch (warmStartStateRandom) { 
        case 3:
          warmStartStateString = 'Enabled';
          break;
        case 2:
          warmStartStateString = 'Suspended';
          break;
      }

      // Max possible values
      const ttmMax = 12 * (30 * 24); // 12 months * 30 days in a month * 24 hours in a day
      const ytdMax = (moment().month() + 1) * (moment().date() * 24); // current month * current day of the month * 24 hours in a day
      const thirtyDayMax = (30 * 24); // 30 days * 24 hours in a day

      // Generate Hours based on a random between 0 and max
      // In terms of value 'bigness', TTM > YTD > 30D 
      const ttmHours = _.random(0, ttmMax);
      const ytdHours = _.random(0, ytdMax > ttmHours ? ttmHours : ytdMax); 
      const thirtyDayHours = _.random(0, thirtyDayMax > ytdHours ? ytdHours : thirtyDayMax);

      // Let the average fuel consumption = 25gal/hr
      const avgFuelConsumption = 25;
      const ttmFuelSaved = ttmHours * avgFuelConsumption;
      const ytdFuelSaved = ytdHours * avgFuelConsumption;
      const thirtyDayFuelSaved = thirtyDayHours * avgFuelConsumption;

      // Set the Emissions Reduced based on the 0.01018 tons CO2/gallon of diesel
      const co2PerGalDiesel = 0.01018;
      const ttmEmissionsReduced = Math.floor(ttmFuelSaved * co2PerGalDiesel);
      const ytdEmissionsReduced = Math.floor(ytdFuelSaved * co2PerGalDiesel);
      const thirtyDayEmissionsReduced = Math.floor(thirtyDayFuelSaved * co2PerGalDiesel);

      resolve({
        unitHours: {
          ecmHours: _.random(1000, 10000),
          pumpHours: _.random(1000, 10000),
          tcmHours: _.random(1000, 10000),
          warmStart: {
            warmStartState: warmStartStateString,
            data: 
            [
              { type: 'TrailingTwelveMonths', warmStartHrsReduced: ttmHours, warmStartFuelSavings: ttmFuelSaved, warmStartEmissionReduced: ttmEmissionsReduced },
              { type: 'YearToDate', warmStartHrsReduced: ytdHours, warmStartFuelSavings: ytdFuelSaved, warmStartEmissionReduced: ytdEmissionsReduced },
              { type: 'LastThirtyDays', warmStartHrsReduced: thirtyDayHours, warmStartFuelSavings: thirtyDayFuelSaved, warmStartEmissionReduced: thirtyDayEmissionsReduced },
            ]
            
          }
        }
      })
    }, 1000)
  });

};

export { fetchUnitHoursCardData }