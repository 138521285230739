import _ from 'lodash';

import WebSocket from 'isomorphic-ws';
import appActionTypes from '../state/app/appActionTypes';
import * as appWebSocketActions from '../state/app/actions/appWebsocketActions';

let ws = null;
const wsURL = 'wss://www.mdtcloud.com/livedata/v1/';
let retryCount = 0;

const createWebSocketClient = (store) => {
  // If we aren't running in test, then setup the websocket client
  if (_.isNil(process.env.CI) || process.env.CI.trim() !== 'true') {
    try {
      const accessToken = 'accesstoken_{token}'; 
      const ownerId = 'ownerId_{value}';
      const clientId = 'clientId_{value}'; 
      const email = 'email_{value}';
      const protocols = [
        accessToken.replace('{token}', localStorage.getItem('live_access_token')), 
        ownerId.replace('{value}', store.getState().app.selectedOwner?.value || store.getState().app.user.ownerId), 
        clientId.replace('{value}', store.getState().app.live.clientId),
        email.replace('{value}', store.getState().app.user.email.replace('@', '.'))
      ];
      if (!_.isNil(ws)) {
        ws = null;
      }
      ws = new WebSocket(wsURL, protocols);

      const errorHandler = (event) => {
        console.log('Error on websocket connection: ' + event.message);
      }
  
      const openHandler = (event) => {
        console.log('Connected to server: ' + ws.readyState);
        store.dispatch(appWebSocketActions.updateWebSocketConnectionStatus(true));
      }
  
      const closeHandler = (event) => {
        console.log('Connection closed: ' + ws.readyState + ' (code: ' + event.code + ')');
        store.dispatch(appWebSocketActions.closeWebSocket());
        ws.onopen = null;
        ws.onclose = null;
        ws.onmessage = null;
        ws.onerror = null;
        if (event.code !== 1000) {
          setTimeout((accessToken, ownerId, clientId, email) => {
            if (retryCount < 5) {
              const protocols = [
                accessToken.replace('{token}', localStorage.getItem('live_access_token')), 
                ownerId.replace('{value}', store.getState().app.selectedOwner?.value || store.getState().app.user.ownerId), 
                clientId.replace('{value}', store.getState().app.live.clientId),
                email.replace('{value}', store.getState().app.user.email.replace('@', '.'))
              ];        
              ws = new WebSocket(wsURL, protocols);
              ws.onopen = openHandler;
              ws.onclose = closeHandler;
              ws.onmessage = messageHandler;
              ws.onerror = errorHandler;
              retryCount++;
            } else {
              retryCount = 0;
            }
          // Exponential backoff retry-type strategy
          // 5 seconds, 5 + 3 seconds, 5 + 6 seconds, 5 + 9 seconds, 5 + 12 seconds
          }, (5000 + ((retryCount-1) * 3000)), accessToken, ownerId, clientId, email); 
        }
      }
  
      const messageHandler = (event) => {
        store.dispatch(appWebSocketActions.dispatchProcessWebSocketData(event));
      };
  
      ws.onopen = openHandler;
      ws.onclose = closeHandler;
      ws.onmessage = messageHandler;
      ws.onerror = errorHandler;
    } catch (error) {
      console.log('Error creating websocket client: ', error);
    }
  }
  return null;
}

const websocketMiddleware = () => {
  return (store) => {
    return (next) => (action) => {
      if ((action.type == appActionTypes.APP_WEBSOCKET_CONNECT) && (_.isNil(ws) || ws.readyState === 3)) {
        createWebSocketClient(store);
      }
      if ((action.type == appActionTypes.APP_SEND_WEBSOCKET_MESSAGE) && (!_.isNil(ws)) && (ws.readyState === 1)) {
        ws.send(action.payload);
        return next(action);
      }
      if ((action.type == appActionTypes.APP_CLOSE_WEBSOCKET) && (!_.isNil(ws))) {

        const subscriptionsToClose = store.getState().app.live.subscriptions;
        if (!_.isEmpty(subscriptionsToClose)) {
          subscriptionsToClose.forEach((subscription) => {
            // The data in subscriptions should already be stringified JSON
            ws.send(subscription);
          });
        }

        const intervalId = store.getState().app.live.intervalId;
        if (!_.isNil(intervalId)) {
          clearInterval(intervalId);
        }

        // This will signal to close and set the ready state = 2 (closing)
        // The 1000 is the close code a client should send to the server
        ws.close(1000);
        store.dispatch(appWebSocketActions.updateWebSocketConnectionStatus(false));
        return next(action);
      }
      return next(action);
    }
  }
}

const isGuid = (value) => {

  // Regex to check valid GUID (Globally Unique Identifier).
  const regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
 
  // If the GUID (Globally Unique Identifier)
  // is empty return false
  if (_.isNil(value))
  {
     return false;
  }
  // Return true if the GUID (Globally Unique Identifier) matched the Regex
  if(regex.test(value) === true)
  {
    return true;
  }
  return false;

}

export { createWebSocketClient, websocketMiddleware, isGuid };