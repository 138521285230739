import { assetTypes } from "../../../../common/services/assetTypeService";

const fetchTrucksForLiveView = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        trucksForLiveView: 
        [
          {
            truckPid: 11861768,
            truckName: '620305',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 1,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 11861773,
            truckName: '620335',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 11,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 11861887,
            truckName: '620371',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 2,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 11861744,
            truckName: '620374',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 12,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 11862002,
            truckName: '620394',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 3,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 11861719,
            truckName: '620444',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 13,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 11861804,
            truckName: '620449',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 4,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 6339833,
            truckName: '620891',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 14,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 5274519,
            truckName: '621164',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 5,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 5269048,
            truckName: '621249',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 15,
            labels: ['highSide', 'dirty']
          }
          
          ,
          {
            truckPid: 11861825,
            truckName: '620683',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 6,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 11861753,
            truckName: '620268',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 16,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 11861718,
            truckName: '620433',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 7,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 5269260,
            truckName: '620743',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 17,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 5269256,
            truckName: '620747',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 8,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 5269227,
            truckName: '620772',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 18,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 6339812,
            truckName: '620870',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 9,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 6339838,
            truckName: '620896',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 19,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 6339840,
            truckName: '620898',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 10,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 21161891,
            truckName: '621315',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 20,
            labels: ['highSide', 'dirty']
          }
        
          ,
          {
            truckPid: 30463969,
            truckName: '621349',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 21,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 43264046,
            truckName: '621353',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 31,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 43601727,
            truckName: '621355',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 22,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 43601741,
            truckName: '621369',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 32,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 51371882,
            truckName: '621373',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 23,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 51371899,
            truckName: '621374',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 33,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 51371935,
            truckName: '621388',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 24,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 27258172,
            truckName: '621444',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 34,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 11861873,
            truckName: '620496',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 25,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 11861796,
            truckName: '620508',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 35,
            labels: ['highSide', 'dirty']
          }

          ,
          {
            truckPid: 27258336,
            truckName: '621408',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 26,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 27258137,
            truckName: '621409',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 36,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 27258141,
            truckName: '621415',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 27,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 27258138,
            truckName: '621435',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 37,
            labels: ['highSide', 'clean']
          },
          {
            truckPid: 27258329,
            truckName: '621451',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 28,
            labels: ['lowSide', 'clean']
          },
          {
            truckPid: 57009688,
            truckName: '621711',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 38,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 57009698,
            truckName: '621712',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 29,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 57009697,
            truckName: '621713',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 39,
            labels: ['highSide', 'dirty']
          },
          {
            truckPid: 57009690,
            truckName: '621714',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 30,
            labels: ['lowSide', 'dirty']
          },
          {
            truckPid: 57009625,
            truckName: '621725',
            unitType: assetTypes.FRAC_PUMP,
            slotNumber: 40,
            labels: ['highSide', 'dirty']
          }

        ]
      })

    }, 1000);

  });
}

const fetchSensorDataForTrucks = (input) => {
  // Input has list of trucks and 1 sensor and a start and end time
  // Goal is to generate n number of values, where n = (end - start)

  let data = [];

  _.forEach(input, (truck) => {

    let sensorValues = _.range(input[0].startTime, input[0].endTime, 1);

    let seedValue = _.random(5,100);

    let timestamps = [];
    let values = [];

    for (let i = 0; i < sensorValues.length; i++) {
      const shouldSpike = _.random(0,1000) % 250 === 0;
      let spikeValue = 0;
      if (shouldSpike === true) {
        spikeValue = _.random(5, 50)
      }

      timestamps.push(input[0].startTime + i);
      values.push(_.round(seedValue + _.random(_.random((-0.25 - spikeValue),0, true), _.random(0, (0.25 + spikeValue), true), true), 2));
    }

    data.push
    ({
      truckPid: truck.truckPid, 
      timestamps: timestamps,
      values: values,
      sensorSetId: truck.sensorSetId,
      unitOfMeasure: truck.unitOfMeasure
    });
  });

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        chartSeries: data
      })
    }, 3000);
  });

}

export {
  fetchTrucksForLiveView,
  fetchSensorDataForTrucks
}