import React from 'react';
import { alarmSeverities } from '../../state/common/services/alarmSeverityService';
import CriticalAlarmIcon from '../controls/icons/alarmCritical';
import WarningAlarmIcon from '../controls/icons/alarmWarning';
import InfoAlarmIcon from '../controls/icons/alarmInfo';
import MessageAlarmIcon from '@mui/icons-material/Message';
import { mdtPalette } from './styles/mdtPalette';

const getAlarmStatusIconStyles = () => ({
  alarmStatusCritical : {
    color: mdtPalette().status.level5,
    fontSize: '36px',
  },
  alarmStatusWarning: {
    color: mdtPalette().status.level4,
    fontSize: '36px',
  },
  alarmStatusInformation: {
    color: mdtPalette().status.level3,
    fontSize: '36px',
  },
  alarmStatusMessage: {
    color: mdtPalette().status.level1,
    fontSize: '36px',
  }
});

const getAlarmStatusIcon = (status, styles) => {
  switch (status) {
    case alarmSeverities.CRITICAL:
      return <CriticalAlarmIcon sx={styles.alarmStatusCritical} />;
    case alarmSeverities.WARNING:
      return <WarningAlarmIcon sx={styles.alarmStatusWarning} />;
    case alarmSeverities.INFORMATION:
      return <InfoAlarmIcon sx={styles.alarmStatusInformation}/>;
    case alarmSeverities.MESSAGE:
      return <MessageAlarmIcon sx={styles.alarmStatusMessage}/>;
    default:
      return <div/>
  }
};

export {
  getAlarmStatusIcon,
  getAlarmStatusIconStyles,
}