import {performCardFetch} from '../../common/fetch'

/**
 * Method to perform the remote query for the unit hours card data
 */
const fetchUnitActivityCardData = (truckId) => {
  return performCardFetch({
    'query': '{ unitActivity(truckId:{truckId}) { truckId pumping idle stopped days } }'.replace('{truckId}', truckId)
  })
};

export default fetchUnitActivityCardData