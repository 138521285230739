import { performCardFetch } from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as dataGridSimulation from './dataGridSimulation';

/**
 * Method to perform the remote query for the data grid data
 */
const fetchDataGridCardData = (truck, sensors) => {

  if (useSimulationData('dataGrid')) {
    return dataGridSimulation.fetchDatGridCardData(sensors);
  }

  const sensorSetIds = _.map(sensors, s => {
    return {
      'sensorSetId': s.sensorSetId,
      'unitOfMeasure': s.uom,
    };
  });

  return performCardFetch({
    'operationName': 'getDataGrid',
    'query': 'query DataGrid($input:DataGridInput!){ dataGrid(input: $input) {series { sensorSetId unitOfMeasure latest } } }',
    'variables': {
      'input': {
        'truckPid': truck.truckPid,
        'sensorSetIds': sensorSetIds
      }
    }
  });
};

export default fetchDataGridCardData