import _ from "lodash";
import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField, Box } from '@mui/material';

import Progress from "../../../controls/progress";
import * as sensorSelectorActions from '../../../../state/common/sensorSelector/sensorSelectorActions';
import { sensorSelectorState } from "../../../../state/common/sensorSelector/sensorSelectorSelectors";

const styles = {
  dialogContentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  sensorGroupNameInput: {
    width: '260px',
    height: '64px', 
  },
  sensorGroupDescriptionInput: {
    width: '260px'
  }
};

class CreateEditSensorGroupDialog extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Reset default values for sensor group when the component reinitialized with another sensor group
    if(!_.isNil(prevProps.groupToEdit) && prevProps.groupToEdit.id !== prevProps.groupToSave.id){
      this.props.setInitStateSensorGroup(prevProps.groupToEdit);
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.openCreateEditSensorGroup}
        maxWidth={'sm'}
        disableEscapeKeyDown>
        <DialogTitle>{this.props.isEditMode === true ? 'Edit': 'Create New'} Sensor Group</DialogTitle>
        <DialogContent>
          <Box sx={styles.dialogContentContainer}>
            <TextField
              sx={styles.sensorGroupNameInput}
              variant='standard'
              type='text'
              helperText={this.props.sensorGroupNameHelperText}
              value={this.props.groupToSave.name}
              onChange={event => this.props.setSensorGroupName(event.target.value)}
              error={this.props.hasSensorGroupNameError}
              onFocus={(e) => e.currentTarget.select()}
              InputProps={{
                inputProps: {
                  maxLength: 40,
                  autoFocus: true
                }
              }}
            />
            <TextField
              sx={styles.sensorGroupDescriptionInput}
              variant='standard'
              type='text'
              helperText='Description'
              value={this.props.groupToSave.description}
              onChange={event => this.props.setSensorGroupDescription(event.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              InputProps={{
                inputProps: {
                  maxLength: 200,
                }
              }}
            />
          </Box>
          <Progress open={this.props.queryRunning}/>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => { this.props.closeCreateSensorGroup()}} color='inherit'>Cancel</Button>
          <Button onClick={() => this.props.saveSensorGroup(this.props.groupToSave, this.props.unitType, this.props.axisDefinition, this.props.namespace) }disabled={!this.props.userCanSaveGroup} color='primary'>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateEditSensorGroupDialog.propTypes = {
  unitType: PropTypes.string,
  axisDefinition: PropTypes.object,
  namespace: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = sensorSelectorState(state[stateDef.key]);

  return {
    openCreateEditSensorGroup: componentState.shouldOpenCreateEditSensorGroup,
    groupToSave: componentState.groupToSave,
    hasSensorGroupNameError: componentState.hasSensorGroupNameError,
    sensorGroupNameHelperText: componentState.sensorGroupNameHelperText,
    userCanSaveGroup: componentState.userCanSaveGroup,
    queryRunning: componentState.queryRunning,
    isEditMode: componentState.isEditMode,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setInitStateSensorGroup: () => dispatch(sensorSelectorActions.setInitStateSensorGroup(props.stateDef, props.groupToEdit)),
    closeCreateSensorGroup: () => dispatch(sensorSelectorActions.closeCreateSensorGroup(props.stateDef)),
    setSensorGroupName: (name) => dispatch(sensorSelectorActions.setSensorGroupName(props.stateDef, name)),
    setSensorGroupDescription: (description) => dispatch(sensorSelectorActions.setSensorGroupDescription(props.stateDef, description)),
    saveSensorGroup: (groupToSave, unitType, axisDefinition, namespace) => {
      dispatch(sensorSelectorActions.saveSharedSensorGroup(props.stateDef, groupToSave, unitType, axisDefinition, namespace))
    }
  }
};

export default (connect(mapStateToProps, mapDispatchToProps)(CreateEditSensorGroupDialog));