import React, {Component} from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
  labelsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '96px',
    height: '57px'
  },

  subLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },

  valueStyle: {
    fontSize: '24px',
    fontWeight: 500
  }
};

class RpmGear extends Component {

  render() {
    return (
        <Box sx={styles.labelsContainer}>
          <Box sx={styles.subLabelContainer}>
            <Typography variant={'subtitle1'} sx={styles.valueStyle}>{this.props.rpmValue}</Typography>
            <Typography variant={'caption'}>RPM</Typography>
          </Box>
          <Box sx={styles.subLabelContainer}>
            <Typography variant={'subtitle1'} sx={styles.valueStyle}>{this.props.gearValue}</Typography>
            <Typography variant={'caption'}>Gear</Typography>
          </Box>
        </Box>
    );
  }
}

RpmGear.propTypes = {
  rpmValue: PropTypes.number.isRequired,
  gearValue: PropTypes.string.isRequired,
};

export default RpmGear;