import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class AlarmWarningBadge extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M9.225,3.329l-8.631,13.894c-1.355,2.177 0.211,4.99 2.776,4.99l17.263,0c2.564,0 4.125,-2.813 2.776,-4.99l-8.631,-13.894c-1.277,-2.056 -4.271,-2.056 -5.553,0Z"/>
      </SvgIcon>
    )
  }
}

export default AlarmWarningBadge;
