import {NumericTickProvider} from "scichart/Charting/Numerics/TickProviders/NumericTickProvider";

/**
 * Specialized TickProvider that rounds major ticks to at most 4 decimal
 * places. This works around the default NumericTickProvider ugliness where
 * it displays some major ticks with very large number of decimal places.
 */
export class SensorValueTickProvider extends NumericTickProvider {

  /**
   * @summary Performs the Numeric tick calculation
   * @param visibleRange The current {@link AxisCore.visibleRange} which is the minimum / maximum range visible on the Axis.
   * @param delta The delta we are calculating for (could be major or minor delta)
   * @param majorDelta The current {@link AxisCore.majorDelta} which is the difference between major
   * gridlines requested by the {@link AxisCore | Axis}
   */
  calculateTicks(visibleRange, delta, majorDelta) {
    // let NumericTickProvider calculate the ticks
    let ticks = super.calculateTicks(visibleRange, delta, majorDelta);

    // if working with major ticks then round them nicely
    if (delta === majorDelta) {
      _.forEach(ticks, (tick, index, ticks) => {
        ticks[index] = this.round(tick, 4); // 4 decimals is sufficient for sensor values
      })
    }

    return ticks;
  }

  /**
   * Rounds the given value to the specified number of decimal places.
   * Supports negative decimalPlaces for powers of 10, ie.
   *   round(123.456, 2) => 123.46
   *   round(123.456, 1) => 123.5
   *   round(123.456, 0) => 123
   *   round(123.456, -1) => 120
   *   round(123.456, -2) => 100
   *
   * from https://expertcodeblog.wordpress.com/2018/02/12/typescript-javascript-round-number-by-decimal-pecision/
   */
  round(value, decimalPlaces) {
    if (decimalPlaces < 0) {
      let factor = Math.pow(10, decimalPlaces);
      return Math.round(value * factor) / factor;
    } else {
      return +(Math.round(Number(value + "e+" + decimalPlaces)) + "e-" + decimalPlaces);
    }
  }
}
