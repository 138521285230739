export default {
  DATA_GRID_QUERY_DATA_STARTING: 'DATA_GRID_QUERY_DATA_STARTING',
  DATA_GRID_QUERY_DATA_SUCCESS: 'DATA_GRID_QUERY_DATA_SUCCESS',
  DATA_GRID_QUERY_DATA_ERROR: 'DATA_GRID_QUERY_DATA_ERROR',
  DATA_GRID_QUERY_SENSORS_SUCCESS: 'DATA_GRID_QUERY_SENSORS_SUCCESS',
  DATA_GRID_CLEAR_DATA: 'DATA_GRID_CLEAR_DATA',

  DATA_GRID_DEFINITION_SET_DEFAULT_TRUCK: 'DATA_GRID_DEFINITION_SET_DEFAULT_TRUCK',
  DATA_GRID_DEFINITION_SET_START_TIME: 'DATA_GRID_DEFINITION_SET_START_TIME',
  DATA_GRID_DEFINITION_SET_SENSORS: 'DATA_GRID_DEFINITION_SET_SENSORS',

  DATA_GRID_CONFIG_OPEN_SENSOR_SELECTOR: 'DATA_GRID_CONFIG_OPEN_SENSOR_SELECTOR',
  DATA_GRID_CONFIG_CLOSE_SENSOR_SELECTOR: 'DATA_GRID_CONFIG_CLOSE_SENSOR_SELECTOR',
  DATA_GRID_CONFIG_SET_SELECTED_SENSORS: 'DATA_GRID_CONFIG_SET_SELECTED_SENSORS',

  DATA_GRID_CONFIG_DISCARD_DEFINITION: 'DATA_GRID_CONFIG_DISCARD_DEFINITION',

  DATA_GRID_CONFIG_SET_SENSOR_VISIBILITY: 'DATA_GRID_CONFIG_SET_SENSOR_VISIBILITY',
  DATA_GRID_CONFIG_UPDATE_DISPLAY_NAME: 'DATA_GRID_CONFIG_UPDATE_DISPLAY_NAME',
  DATA_GRID_CONFIG_UPDATE_UOM: 'DATA_GRID_CONFIG_UPDATE_UOM',
  DATA_GRID_CONFIG_UPDATE_CONDITIONAL_FORMATTING: 'DATA_GRID_CONFIG_UPDATE_CONDITIONAL_FORMATTING',
  DATA_GRID_CONFIG_ADD_CONDITIONAL_FORMATTING: 'DATA_GRID_CONFIG_ADD_CONDITIONAL_FORMATTING',
  DATA_GRID_CONFIG_REMOVE_CONDITIONAL_FORMATTING: 'DATA_GRID_CONFIG_REMOVE_CONDITIONAL_FORMATTING',
  DATA_GRID_CONFIG_REMOVE_SENSOR: 'DATA_GRID_CONFIG_REMOVE_SENSOR',
  DATA_GRID_CONFIG_SET_COLOR_PICKER_STATE: 'DATA_GRID_CONFIG_SET_COLOR_PICKER_STATE',

  DATA_GRID_CONFIG_UPDATE_ORDER: 'DATA_GRID_CONFIG_UPDATE_ORDER',

  DATA_GRID_SETUP_EDIT_MODE: 'DATA_GRID_SETUP_EDIT_MODE'
};