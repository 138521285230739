import _ from 'lodash';

import providerTypes from '../../../../../components/common/providerTypes';

/**
 * Given the current state of the Subscription Dialog, determine if the user has provided
 * enough information to save.
 * @param {*} state 
 * @returns True if required information has been provided; false otherwise
 */
const evaluateCanSave = (state) => {

  let newState = _.cloneDeep(state);

  // If any of the required fields are empty, return false
  if (_.isEmpty(state.subscriptionName)) {
    newState.errorSubscriptionName = true;
  } else {
    newState.errorSubscriptionName = false;
  }
      
  if (_.isNil(state.event)) {
    newState.errorEventType = true;
  } else {
    newState.errorEventType = false;
  }
      
  if (_.isNil(state.eventType)) {
    newState.errorEventType = true;
  } else {
    newState.errorEventType = false;
  }
  
  // Check the filters are all properly filled out
  if (
      (_.isEmpty(state.filters)) ||
      (!_.isEmpty(state.filters) &&
      (!_.isEmpty(_.filter(state.filters, (x) => {
        return _.isNil(x.attribute)
            || _.isNil(x.operator)
            //for array or dropdown object, it should not be empty; for string text field it should not be empty after trim
            || typeof x.value === 'object'? _.isEmpty(x.value): (_.isNil(x.value) || _.isEmpty(x.value.trim()));
      }))) )
    ) {
    newState.errorFilters = true;
  } else {
    newState.errorFilters = false;
  }

  // Check the recipients are all properly filled out
  if (
      (_.isEmpty(state.recipients)) ||
      (!_.isEmpty(state.recipients) &&
      (!_.isEmpty(_.filter(state.recipients, (x) => { return _.isNil(x.provider) || _.isEmpty(x.destination); }))) )
    ) {
    newState.errorRecipients = true;
  } else {
    newState.errorRecipients = false;
  }

  if (newState.errorSubscriptionName === true ||
      newState.errorEventType === true ||
      newState.errorFilters === true ||
      newState.errorRecipients === true
    ) {
    newState.canSave = false;
  } else {
    newState.canSave = true;
  }

  return newState;
};

/**
 * Update the ids of the elements in given the collection
 * so the ids match their respective index in the collection. 
 * This helps when we want to add a new item to this collection later on.
 * @param {*} collection 
 */
const updateIds = (collection) => {

  // Update the ids so they match their indexes
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].id !== i) {
      collection[i].id = i;
    }
  }
};

/**
 * Given a collection of Events create a new collection of id/value/label objects of the Events only.
 * @param {*} events Collection of events returned from Portal App Service `getEvents` method
 */
const getEvents = (events) => {
  events.sort((a,b) => a.entity.localeCompare(b.entity));

  return _.uniqBy
  (
      _.map(events, (x) => {
        //First split the camel cased word into words separated by a space
        let parsedEvent = x.entity.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        return { 
          value: x.entity, 
          label: parsedEvent
        };
      }),
      'value'
  );

}

/**
 * Given a collection of Events, create a new collection of id/value/label objects of the Event Types only.
 * @param {*} events Collection of events returned from Portal App Service `getEvents` method
 */
const getEventTypes = (events) => {

  events.sort((a,b) => a.type.localeCompare(b.type));

  return _.map(events, (event) => {

    // First split the camel cased word into words separated by a space
    // "myEventType" -> "my Event Type"
    let parsedEventType = event.type.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

    return { 
      id: event.id, 
      event: event.entity, 
      value: event.type, 
      label: parsedEventType, 
      filterAttributes: getFilterAttributesForEvent(event) 
    };
  });

}

/**
 * Given an Event, return a collection of Filter Attributes that contains Portal App friendly property names.
 * @param {*} eventEventType An Event object that contains Event Type as well Filter Attributes
 */
const getFilterAttributesForEvent = (event) => {

  return _.map(event.filterAttributes, (attribute) => {
    return { 
      label: attribute.label, 
      value: attribute.name, 
      type: attribute.type, 
      availableOperators: attribute.availableOperators, 
      availableValues: attribute.availableValues 
    };
  })
}

/**
 * Given a collection of Provider Details (with id, name, type, config), create and return a collection of
 * Available Providers (with id, value, label, type, availableDestinations).
 * @param {*} providerBasics Collection of Provider Details (with id, name, type, config)
 */
const convertProviderBasicsToAvailableProviders = (providerBasics) => {

  const availableProviders = [];

  _.forEach(providerBasics, (provider) => {

    availableProviders.push(
      {
        id: provider.id,
        value: provider.type,
        label: provider.name,
        type: provider.type,
        availableDestinations: createAvailableDestinationsForProvider(provider)
      }
    );

  });

  return availableProviders;
}

/**
 * Given a Config for a Provider, return the proper Available Destinations collection.
 * @param {*} provider A Provider with a Config
 */
const createAvailableDestinationsForProvider = (provider) => {

  switch(provider.type)
  {
    case providerTypes.TEAMS:
      return createAvailableDestinationsFromTeamsConfig(provider.config);
    case providerTypes.TWILIO:
    default:
      return [];
  }
}

/**
 * Creates a list of Channels using the given Teams Config JSON object.
 * @param {*} teamsConfig Teams provider Config as a JSON object
 */
const createAvailableDestinationsFromTeamsConfig = (teamsConfig) => {

  let config = JSON.parse(teamsConfig);
  let availableDestinations = [];
  let index = 0;

  _.forEach(config.channels, (channel) => {

    // First split the camel cased word into words separated by a space
    // "myChannelName" -> "my Channel Name"
    let parsedChannelName = channel.name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

    availableDestinations.push(
      {
        id: index,
        value: channel.name,
        label: parsedChannelName
      }
    );
    index++;
  });

  return availableDestinations;

}

export {
  evaluateCanSave,
  updateIds,
  getEvents,
  getEventTypes,
  convertProviderBasicsToAvailableProviders
}