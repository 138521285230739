/** MDT defines Y-Axis position is a non-zero integer ordered from inner axis
 *  to outer, with left axes being negative and right axes being positive.
 * For example four axes can be represented as
 *     -2 -1 [CHART] 1 2
 */
class MdtYAxisPosition {
  static LeftOuter = -2;
  static LeftInner = -1;
  static RightInner = 1;
  static RightOuter = 2;
}

export {
  MdtYAxisPosition,
}
