import _ from "lodash";
import moment from "moment";

import * as filterService from '../../../common/filtering/filterService';

const prettifyValue = (value) => {
  let noValue = '-';
  return !_.isNil(value) ? moment.utc(value*1000).fromNow() : noValue;
};

const resolveSelectedContext = (contexts, contextId) => {

  let selectedContext = null;

  // Validate that we have a context with the requests id
  if (!_.isNil(contextId) && !_.isEmpty(contexts)) {
    let match = _.find(contexts, { id: contextId });
    selectedContext = _.isNil(match) ? null : match;
  }

  // If we did not resolve a selected context, but we do have contexts, set it to the first element.
  if (_.isNil(selectedContext) && !_.isEmpty(contexts)) {
    selectedContext = contexts[0];
  }

  return selectedContext;
};

const processDfps = (dfps, filters) => {

  // Map the dfps into context objects for the dashboard UI
  let contexts = mapDfpsToContexts(dfps);
  // Filter the dfp contexts by the current filters (in order)
  let filteredContexts = filterService.filterItems(contexts, filters);
  // Generate filter data from the filtered equipment list
  let updatedFilters = filterService.generateFilters(filteredContexts, filterDefinitions());

  return {
    contexts: filteredContexts,
    filters: updatedFilters
  };
};

const mapDfpsToContexts = (dfps) => {

  let contexts = [];

  if (!_.isNil(dfps) && !_.isEmpty(dfps)) {
    contexts = _.orderBy(dfps, 'name').map(dfp => {
      return {
        ...dfp,
        // We set the value and label to the same value to work around the text
        // search in the autocomplete control. The text search looks in both the
        // value and the label properties.
        value: dfp.name,
        label: dfp.name,
      }
    });
  }

  return contexts;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  return definitions;
};

export {
  prettifyValue,
  resolveSelectedContext,
  processDfps
}
