
const fetchDGBViewDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let simData = getSimData_Active_WithDGB(fleetId);
      // let simData = getSimData_Active_NoDGB(fleetId);
      //let simData = getSimData_InActive(fleetId);

      resolve({
        dgbFleetMap: {
          ...simData
        }
      })
    }, 1000)
  });
};

const getSimData_Active_WithDGB = (fleetId) => {

  let trucks = [
    { id:'1', name:'612001', gasSupplyPressure:4734.0, gasPostFilterPressure:18936.1, gasSubstitution:44.0, gasSubstitutionSetPoint:12.0, engineLoad:78.0, engineTorque: 50.0, engineRpm: 302 },
    { id:'2', name:'612002', gasSupplyPressure:0.0, gasPostFilterPressure:2353.9, gasSubstitution:34.0, gasSubstitutionSetPoint:33.0, engineLoad:59.0, engineTorque: 10.1, engineRpm: 532 },
    { id:'3', name:'612003', gasSupplyPressure:3467.0, gasPostFilterPressure:12344.1, gasSubstitution:70.0, gasSubstitutionSetPoint:29.0, engineLoad:48.0, engineTorque: 9.9, engineRpm: 382 },
    { id:'4', name:'612004', gasSupplyPressure:442.0, gasPostFilterPressure:936.6, gasSubstitution:81.0, gasSubstitutionSetPoint:5.0, engineLoad:77.0, engineTorque: 73.0, engineRpm: 832 },
    { id:'5', name:'612005', gasSupplyPressure:8563.0, gasPostFilterPressure:597.1, gasSubstitution:18.0, gasSubstitutionSetPoint:92.0, engineLoad:33.0, engineTorque: 99.9, engineRpm: 350 },
    { id:'6', name:'612006', gasSupplyPressure:null, gasPostFilterPressure:null, gasSubstitution:42.0, gasSubstitutionSetPoint:16.0, engineLoad:60.0, engineTorque: 100.0, engineRpm: 763 },
    { id:'7', name:'612007', gasSupplyPressure:9312.0, gasPostFilterPressure:6421.9, gasSubstitution:69.0, gasSubstitutionSetPoint:26.0, engineLoad:93.0, engineTorque: 61.0, engineRpm: 294 },
    { id:'8', name:'612008', gasSupplyPressure:0.0, gasPostFilterPressure:12543.3, gasSubstitution:82.0, gasSubstitutionSetPoint:27.0, engineLoad:76.0, engineTorque: 5.0, engineRpm: 734 },
    { id:'9', name:'612009', gasSupplyPressure:71.0, gasPostFilterPressure:null, gasSubstitution:53.0, gasSubstitutionSetPoint:72.0, engineLoad:86.0, engineTorque: 59.0, engineRpm: 922 },
    { id:'10', name:'612010', gasSupplyPressure:621.0, gasPostFilterPressure:21189.6, gasSubstitution:31.0, gasSubstitutionSetPoint:22.0, engineLoad:40.0, engineTorque: 33.0, engineRpm: 712 },
  ];

  return {
    id: fleetId,
    name: 'simFleet',
    hasActiveData: true,
    trucks: trucks
  }
};

// const getSimData_Active_NoDGB = (fleetId) => {
//
//   return {
//     id: fleetId,
//     name: 'simFleet',
//     hasActiveData: true,
//     trucks: []
//   }
// };

// const getSimData_InActive = (fleetId) => {
//
//   return {
//     id: fleetId,
//     name: 'simFleet',
//     hasActiveData: false,
//     trucks: []
//   }
// };

export {
  fetchDGBViewDetails
}