import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";

import { IconButton, TextField, Typography, Tooltip, Box } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import DashboardView from './dashboard';

import getDisplayStyles from '../../common/styles/displayStyles';
import ComponentTypes from '../../componentTypes';
import * as coilDashboardActions from '../../../state/displays/coilDashboard/coilDashboardActions';
import { coilDashboardState } from '../../../state/displays/coilDashboard/coilDashboardSelectors';
import { appState as applicationState} from '../../../state/app/appSelectors';
import { AutoCompleteMDT } from '../../controls/mdtMuiControls';

let displayStyles = getDisplayStyles();

const styles = {
  ...displayStyles,
};

class CoilDashboard extends Component {

  componentDidMount() {
    // Load the dashboard data. NOTE: Loading the display data implicitly
    // handles the setting of the selected context which will trigger the
    // loading of the data age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
    // Start the timer for refreshing the relative time label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshRelativeTime();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    // Load the data age if the selected context changes
    if (prevProps.selectedContext !== this.props.selectedContext) {
      this.loadDataAge();
    }
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(()=>{
      this.props.loadDisplay();
    }, 180000); // 3 minutes
  }

  periodicRefreshRelativeTime() {
    // Periodically refresh the label for the data age
    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
  }

  loadDataAge() {
    if (!_.isNil(this.props.selectedContext) && !_.isNil(this.props.selectedContext.id)) {
      // Update the Data Age
      this.props.queryDataAge(this.props.selectedContext.id);
    } else {
      // When the selected owner has no trucks, we want to clear the existing data age
      this.props.clearDataAge();
    }
  }

  render() {
      
    return (
      <Box sx={styles.page}>
        <Box sx={styles.pageContent}>
          <Box sx={styles.pageHeader}>
            <Box sx={styles.pageHeaderTitle}>
              <Typography variant={'h6'}>Coil Dashboard</Typography>
            </Box>
            <Box sx={styles.pageHeaderActions}>
              <Box sx={styles.pageHeaderButton}>
                <Tooltip title="Refresh">
                  <IconButton onClick={() => this.manualRefreshDisplay()} size="large">
                    <RefreshIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={styles.dataAgeContainer}>
              <Typography sx={styles.childComponent}>Data as of:</Typography>
              <Typography sx={styles.childComponent}>
                {this.props.dataAgeDisplay}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.selectionContainer}>
            <Typography variant={'subtitle1'}>COIL TUBING UNIT:</Typography>
            <AutoCompleteMDT
              sx={styles.contextSelection}
              options={this.props.contexts}
              value={this.props.selectedContext}
              onChange={(event, value, reason) => {
                this.props.selectContext(value);
              }}
              noOptionsText={"No CTUs found..."}
              />
          </Box>
          <Box sx={styles.viewContainer}>
            <DashboardView coilTubingUnit={this.props.selectedContext} user={this.props.user}/>
          </Box>
        </Box> 
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COIL_DASHBOARD : props.stateKey,
      type: ComponentTypes.COIL_DASHBOARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = coilDashboardState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    contexts: componentState.contexts,
    selectedContext: componentState.selectedContext,
    dataAgeDisplay: componentState.dataAgeDisplay,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: () => { dispatch(coilDashboardActions.loadDisplay(props.stateDef))},
    queryDataAge: (truckId) => { dispatch(coilDashboardActions.queryDataAge(props.stateDef, truckId))},
    clearDataAge: () => { dispatch(coilDashboardActions.clearDataAge(props.stateDef))},
    refreshRelativeTime: () => { dispatch(coilDashboardActions.refreshRelativeTime(props.stateDef))},
    selectContext: (context) => {dispatch(coilDashboardActions.selectContext(props.stateDef, context))},
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CoilDashboard))
