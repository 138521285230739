import _ from 'lodash';
import expectedLifeActionTypes from '../actionTypes/expectedLifeActionTypes';
import * as expectedLifeService from '../services/expectedLifeService';
import {hasOwnerAdminToken} from "../../../../helpers/authHelper";

const expectedLifeReducer = (state, action) => {

  switch (action.type) {

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_STARTING:
      return onExpectedLifeQueryStarting(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_ERROR:
      return onExpectedLifeQueryError(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_SUCCESS:
      return onExpectedLifeQuerySuccess(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_STARTING:
      return onExpectedLifeSaveStarting(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_ERROR:
      return onExpectedLifeSaveError(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_SUCCESS:
      return onExpectedLifeSaveSuccess(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SET_SELECTED_UNIT_TYPE:
      return onExpectedLifeSetSelectedUnitType(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SET_EXPECTED_LIFE:
      return onExpectedLifeSetExpectedLife(state, action);

    case expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_REVERT_FORM:
      return onExpectedLifeRevertForm(state, action);

    default: return state;

  }
};

const onExpectedLifeQueryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onExpectedLifeQueryError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onExpectedLifeQuerySuccess = (state, action) => {

  let settings = expectedLifeService.processExpectedLifeSettings(action.queryResults.expectedLifeSettings);

  return {
    ...state,
    expectedLife: {
      ...state.expectedLife,
      expectedLifeUnitTypes: settings.unitTypes,
      expectedLifeComponentTypes: settings.componentTypes,
    },
    queryRunning: false,
  };
};

const onExpectedLifeSaveStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true, 
  };
};

const onExpectedLifeSaveError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onExpectedLifeSaveSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onExpectedLifeSetSelectedUnitType = (state, action) => {

  let selectedUnitType = expectedLifeService.resolveSelectedUnitType(state.expectedLife.expectedLifeUnitTypes, action.unitType);
  let selectedComponentTypes = expectedLifeService.getSelectedComponentTypes(state.expectedLife.expectedLifeComponentTypes, _.isNil(selectedUnitType) ? null : selectedUnitType.unitType);

  let newState = {
    ...state.expectedLife,
    expectedLifeSelectedUnitType: selectedUnitType,
    expectedLifeSelectedComponentTypes: selectedComponentTypes,
  };

  return {
    ...state,
    expectedLife: {
      ...newState,
      expectedLifeCanSave: expectedLifeService.canSave(newState)
    }
  };
};

const onExpectedLifeSetExpectedLife = (state, action) => {

  // The material UI Text Field control (input type number) sends string values, and an empty string value for any
  // invalid numbers. We need to convert it to a number ourselves and account for the empty string.

  let expectedLife = !_.isEmpty(action.expectedLife) ? Number(action.expectedLife) : action.expectedLife;

  let updatedComponentTypes = expectedLifeService.updateSelectedComponentTypes(state.expectedLife.expectedLifeSelectedComponentTypes, action.componentTypeId, expectedLife);

  let newState = {
    ...state.expectedLife,
    expectedLifeSelectedComponentTypes: updatedComponentTypes,
  };

  return {
    ...state,
    expectedLife: {
      ...newState,
      expectedLifeCanSave: expectedLifeService.canSave(newState)  
    }
  };
};

const onExpectedLifeRevertForm = (state, action) => {

  let selectedComponentTypes = expectedLifeService.getSelectedComponentTypes(state.expectedLife.expectedLifeComponentTypes, state.expectedLife.expectedLifeSelectedUnitType.unitType);

  let newState = {
    ...state.expectedLife,
    expectedLifeSelectedComponentTypes: selectedComponentTypes,
  };

  return {
    ...state,
    expectedLife: {
      ...newState,
      expectedLifeCanSave: expectedLifeService.canSave(newState)
    }
  };
};

export default expectedLifeReducer