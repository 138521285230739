const JOB_APPROVAL_ACCEPT = 'JobApprovalAccept';
const JOB_APPROVAL_REJECT = 'JobApprovalReject';
const JOB_APPROVAL_REQUEST = 'JobApprovalRequest';
const JOB_START = 'JobStart';
const JOB_END = 'JobEnd';
const STAGE_APPROVAL_REQUEST = 'StageApprovalRequest';
const STAGE_APPROVAL_ACCEPT = 'StageApprovalAccept';
const STAGE_APPROVAL_REJECT = 'StageApprovalReject';
const STAGE_APPROVAL_CANCEL = 'StageApprovalCancel';
const STAGE_START = 'StageStart';
const STAGE_CONTROLLER_LOAD = 'StageControllerLoad'; 
const STAGE_ABORT = 'StageAbort';
const STAGE_COMPLETE = 'StageComplete';

export {
  JOB_APPROVAL_ACCEPT,
  JOB_APPROVAL_REJECT,
  JOB_APPROVAL_REQUEST,
  JOB_START,
  JOB_END,
  STAGE_APPROVAL_REQUEST,
  STAGE_APPROVAL_ACCEPT,
  STAGE_APPROVAL_REJECT,
  STAGE_APPROVAL_CANCEL,
  STAGE_START,
  STAGE_CONTROLLER_LOAD,
  STAGE_ABORT,
  STAGE_COMPLETE
}