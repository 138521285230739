import _ from 'lodash';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, TextField, Box } from "@mui/material";
import { Component } from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import componentTypes from "../../componentTypes";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import * as routeAssetMgmtRouteAssetDialogActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtRouteAssetDialogActions";
import { AutoCompleteMDT } from "../../controls/mdtMuiControls";

class RouteAssetMgmtRouteAssetDialog extends Component {
    isAddButtonDisabled = () => {
        const { truckName, consumerName } = this.props.workingRouteAsset;
        return (_.isNil(truckName) || _.isNil(consumerName));
    };

    isUpdateButtonDisabled = () => {
        const { truckName, consumerName } = this.props.workingRouteAsset;
        return (_.isNil(truckName) || _.isNil(consumerName));
    };

    handleTruckChange = (event, value) => {
        this.props.setWorkingRouteAssetTruckName(value.label);
    };

    handleConsumerChange = (event, value) => {
        this.props.setWorkingRouteAssetConsumerName(value.label);
    };

    render() {
        return (
            <Dialog
                aria-modal
                open={this.props.openAddRouteAssetUI || this.props.openEditRouteAssetUI}
                disableEscapeKeyDown
            >
                {this.props.openAddRouteAssetUI && <DialogTitle>Route An Asset</DialogTitle>}
                {this.props.openEditRouteAssetUI && <DialogTitle>Edit Asset Routing</DialogTitle>}
                <DialogContent>
                    <Box sx={{paddingTop: 1, paddingBottom: 1, width: '450px'}}>
                      <AutoCompleteMDT
                          options={this.props.trucks}
                          value={this.props.workingRouteAsset.truckName}
                          onChange={this.handleTruckChange}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => <TextField {...params} variant="standard" helperText={'Truck'} />}
                          noOptionsText={'No trucks found...'}
                          disabled={this.props.openEditRouteAssetUI}
                      />
                   </Box>
                   <Box sx={{paddingTop: 1, paddingBottom: 1}}>
                      <AutoCompleteMDT
                          options={this.props.consumers}
                          value={this.props.workingRouteAsset.consumerName}
                          onChange={this.handleConsumerChange}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => <TextField {...params} variant="standard" helperText={'Consumer'} />}
                          noOptionsText={'No consumers found...'}
                      />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color='inherit' 
                        onClick={() => { 
                            if (this.props.openAddRouteAssetUI === true) {
                                this.props.closeAddDialog();
                            } else if (this.props.openEditRouteAssetUI === true) {
                                this.props.closeEditDialog();
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                          this.props.saveRouteAsset(this.props.workingRouteAsset);
                        }} 
                        color="primary"
                        disabled={this.props.openAddRouteAssetUI ? this.isAddButtonDisabled() : this.isUpdateButtonDisabled()}
                    >
                        {this.props.openAddRouteAssetUI ? 'Add' : 'Update'}
                    </Button> 
                </DialogActions>
            </Dialog>
        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props; 
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    return {
        routeAssets: componentState.routeAssets,
        consumers: componentState.consumers,
        trucks: componentState.trucks, 
        openAddRouteAssetUI: componentState.openAddRouteAssetUI,
        openEditRouteAssetUI: componentState.openEditRouteAssetUI,
        workingRouteAsset: componentState.workingRouteAsset,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeAddDialog: () => dispatch(routeAssetMgmtRouteAssetDialogActions.closeAddDialog(props.stateDef)),
        closeEditDialog: () => dispatch(routeAssetMgmtRouteAssetDialogActions.closeEditDialog(props.stateDef)),
        openAddRouteAssetDialog: () => dispatch(routeAssetMgmtRouteAssetDialogActions.openAddRouteAssetDialog(props.stateDef)),
        openEditRouteAssetDialog: (routeAsset) => dispatch(routeAssetMgmtRouteAssetDialogActions.openEditRouteAssetDialog(props.stateDef, routeAsset)),
        setWorkingRouteAssetConsumerName: (consumerName) => dispatch(routeAssetMgmtRouteAssetDialogActions.setWorkingRouteAssetConsumerName(props.stateDef, consumerName)),
        setWorkingRouteAssetTruckName: (truckName) => dispatch(routeAssetMgmtRouteAssetDialogActions.setWorkingRouteAssetTruckName(props.stateDef, truckName)),
        saveRouteAsset: (workingRouteAsset) => dispatch(routeAssetMgmtRouteAssetDialogActions.saveRouteAsset(props.stateDef, workingRouteAsset)),
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(RouteAssetMgmtRouteAssetDialog));