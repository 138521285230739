import _ from "lodash";
import componentsActionTypes from '../componentsActionTypes';
import * as componentsService from '../services/componentsService';
import * as validationService from '../services/validataionService';
import { componentsState, editComponentState, addComponentState } from '../componentsSelectors';

const initialState = componentsState();

const formReducer = (state, action) => {

  switch (action.type) {

    case componentsActionTypes.COMPONENTS_SET_SELECTED_UNIT:
      return onSetSelectedUnit(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_COMPONENT_TYPE:
      return onSetSelectedComponentType(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_EFFECTIVE_DATE:
      return onSetSelectedEffectiveDate(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_MODEL:
      return onSetSelectedModel(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_SERIAL_NUMBER:
      return onSetSelectedSerialNumber(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_REF_NUMBER:
      return onSetSelectedRefNumber(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_UNIT_OFFSET:
      return onSetSelectedUnitOffset(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_SNAPSHOT_START:
      return onSetSelectedSnapshotStart(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_ECM_RESET:
      return onSetSelectedEcmReset(state, action);

    case componentsActionTypes.COMPONENTS_SET_CALCULATOR_INPUT:
      return onSetCalculatorInput(state, action);

    case componentsActionTypes.COMPONENTS_SET_SELECTED_EFFECTIVE_DATE_DISPLAY:
      return onSetSelectedEffectiveDateDisplay(state, action);

    default: return state;
  }
};

const onSetSelectedUnit = (state, action) => {

  if (_.isNil(state.navigationContextUnitId)) {

    // If there is no navigation context that we need to consider just use the action value

    return { ...state, selectedUnit: _.isNil(action.unit) && !_.isEmpty(state.filteredUnits) ? state.filteredUnits[0] : action.unit };
  }

  // Otherwise try and resolve the unit id from the navigation context into a unit entity

  let selectedUnit = _.find(state.filteredUnits, { 'id': state.navigationContextUnitId });

  if (!_.isNil(selectedUnit)) {

    // If we have a match then use it

    return { ...state, selectedUnit: selectedUnit, navigationContextUnitId: initialState.navigationContextUnitId };
  }

  if (_.isEmpty(state.appliedFilters)) {

    // If we didn't find a match and there are no configured filters, then set the selection to the first context

    return {...state, selectedUnit: state.filteredUnits[0], navigationContextUnitId: initialState.navigationContextUnitId };
  }

  // If we get here, then the unit id is not in the current list of filtered units. Process
  // the units without any filters and again.

  let unitDetails = componentsService.processUnits(state.units, []);

  selectedUnit = componentsService.resolveSelectedUnit(unitDetails.filteredUnits, state.navigationContextUnitId);

  return {
    ...state,
    filteredUnits: unitDetails.filteredUnits,
    filters: unitDetails.filters,
    selectedUnit: selectedUnit,
    appliedFilters: initialState.appliedFilters,
    navigationContextUnitId: initialState.navigationContextUnitId
  }

};

const onSetSelectedComponentType = (state, action) => {

  let selectedComponentType = _.isNil(action.componentType) && !_.isEmpty(state.componentTypes) ? state.componentTypes[0] : action.componentType;

  if (!_.isNil(state.navigationContextComponentType)) {

    // A navigation context exists so use it instead (unless it cannot be found)

    let match = _.find(state.componentTypes, { 'name': state.navigationContextComponentType });
    selectedComponentType = _.isNil(match) ? selectedComponentType : match;
  }

  let selectedComponent =  componentsService.resolveSelectedComponent(state.components, selectedComponentType);

  // If there is no component then switch edit mode off so the user is automatically provided
  // the ability to add a component
  let editMode = _.isNil(selectedComponent) ? false: state.editMode;

  let formState = editMode ? editComponentState(selectedComponent) : addComponentState();

  let newState = {
    ...state,
    ...formState,
    editMode: editMode,
    selectedComponent: selectedComponent,
    selectedComponentType: selectedComponentType,
    navigationContextComponentType: initialState.navigationContextComponentType,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };

};

const onSetSelectedEffectiveDate = (state, action) => {
  let newState = {
    ...state,
    selectedEffectiveDate: action.dateTime,
    selectedEffectiveDateDisplay: action.dateTime,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedModel = (state, action) => {
  let newState = {
    ...state,
    selectedModel: action.model,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedSerialNumber = (state, action) => {
  let newState = {
    ...state,
    selectedSerialNumber: action.serialNumber,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedRefNumber = (state, action) => {
  let newState = {
    ...state,
    selectedRefNumber: action.refNumber,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedUnitOffset = (state, action) => {

  // The material UI Text Field control (input type number) sends string values, and an empty string value for any
  // invalid numbers. We need to convert it to a number ourselves and account for the empty string.

  let newState = {
    ...state,
    selectedUnitOffset: !_.isEmpty(action.offset) ? Number(action.offset) : action.offset,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedSnapshotStart = (state, action) => {
  let newState = {
    ...state,
    selectedSnapshotStart: !_.isEmpty(action.snapshot) ? Number(action.snapshot) : action.snapshot,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedEcmReset = (state, action) => {
  let newState = {
    ...state,
    selectedECMReset: action.reset,
    selectedSnapshotStart: action.reset === true ? 0 : state.selectedSnapshotStart,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetCalculatorInput = (state, action) => {

  let newState = {
    ...state,
    calculatorInput: !_.isEmpty(action.input) ? Number(action.input) : action.input,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetSelectedEffectiveDateDisplay = (state, action) => {

  return {
    ...state,
    selectedEffectiveDateDisplay: action.dateTime
  }
}

export default formReducer