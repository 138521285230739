import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import coilDashboardActionTypes from "../coilDashboard/coilDashboardActionTypes";

import errorMessages from '../../common/errorMessages';
import * as navActions from "../../app/actions/appNavActions";
import {fetchCoilDashboardDataAge, fetchCoilDashboardDisplayData} from "../coilDashboard/coilDashboardQueries";

const selectContext = defaultActionFactory(coilDashboardActionTypes.COIL_DASHBOARD_SELECT_CONTEXT, 'stateDef', 'context');
const selectNavigationContext = defaultActionFactory(coilDashboardActionTypes.COIL_DASHBOARD_SELECT_NAVIGATION_CONTEXT, 'stateDef', 'context');
const refreshRelativeTime = defaultActionFactory(coilDashboardActionTypes.COIL_DASHBOARD_DATA_AGE_REFRESH_RELATIVE, 'stateDef');

const queryData = queryActionFactory(
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_STARTING,
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_SUCCESS,
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_COIL_TUBING_UNIT_LIST,
    fetchCoilDashboardDisplayData
);

const queryDataAge = queryActionFactory(
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_STARTING,
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_SUCCESS,
    coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_ERROR,
    errorMessages.ERROR_RETRIEVING_DATA_AGE,
    fetchCoilDashboardDataAge
);

const clearDataAge = defaultActionFactory(coilDashboardActionTypes.COIL_DASHBOARD_DATA_AGE_CLEAR_DATA, 'stateDef');

const loadDisplay = (stateDef) => {
    return async (dispatch, getState) => {

        // Check if there is a navigation context for this display in the app state
        let navigationContext = getState().app.navigation.context;
        let dashboardContext = _.isNil(navigationContext['/coil-dashboard']) ? null : navigationContext['/coil-dashboard'];
        if (!_.isNil(dashboardContext)) {
            // Clear out the context from the app state now that we have it
            await dispatch(navActions.setNavigationContext('/coil-dashboard', null));
        }

        // Execute our display query
        await dispatch(queryData(stateDef));

        // Set the selected context
        if (_.isNil(dashboardContext)) {
            await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
        } else {
            await dispatch(selectNavigationContext(stateDef, dashboardContext));
        }

    }
};

const navigate = (unitId) => {
    return async (dispatch, getState) => {
        const url = '/coil-dashboard';
        const context = _.isNil(unitId) ? null : { unitId: unitId };
        return dispatch(navActions.navigate(url, context));
    }
};

export {
    queryData,
    queryDataAge,
    clearDataAge,
    selectContext,
    loadDisplay,
    refreshRelativeTime,
    navigate
}
