const fleetTypes = {
    REMOTESKID: 'Remote Skid',
    ETECH: 'eTech',
    CNGFACILITY: 'CNGFacility',
    FRAC: 'Frac Fleet',
    DFP: 'DFP',
    WIRELINE: 'Wireline',
    COIL: 'Coil',
    PUMPONLY: 'Pumper Only',
    BUILDER: 'Builder',
    PRS: 'PRS',
    INVENTORY: 'Inventory',
    PORTABLECOMP: 'PortableComp',
    PSGATEWAY: 'PSGateway'
};

const fleetTypeNames = {
    REMOTESKID: 'Remote Skid',
    ETECH: 'eTech',
    CNGFACILITY: 'CNGFacility',
    FRAC: 'Frac Fleet',
    DFP: 'DFP',
    WIRELINE: 'Wireline',
    COIL: 'Coil',
    PUMPONLY: 'Pumper Only',
    BUILDER: 'Builder',
    PRS: 'PRS',
    INVENTORY: 'Inventory',
    PORTABLECOMP: 'Portable Compressor',
    PSGATEWAY: 'PS Gateway'
};

const getFleetTypeNameByType = (type) => {
    switch (type) {
        case fleetTypes.REMOTESKID:
            return fleetTypeNames.REMOTESKID;
        case fleetTypes.ETECH:
            return fleetTypeNames.ETECH;
        case fleetTypes.CNGFACILITY:
            return fleetTypeNames.CNGFACILITY;
        case fleetTypes.DFP:
            return fleetTypeNames.DFP;
        case fleetTypes.WIRELINE:
            return fleetTypeNames.WIRELINE;
        case fleetTypes.COIL:
            return fleetTypeNames.COIL;
        case fleetTypes.FRAC:
            return fleetTypeNames.FRAC;
        case fleetTypes.BUILDER:
            return fleetTypeNames.BUILDER;
        case fleetTypes.PRS:
            return fleetTypeNames.PRS;
        case fleetTypes.INVENTORY:
            return fleetTypeNames.INVENTORY;
        case fleetTypes.PUMPONLY:
            return fleetTypeNames.PUMPONLY;
        case fleetTypes.PORTABLECOMP:
            return fleetTypeNames.PORTABLECOMP;
        case fleetTypes.PSGATEWAY:
            return fleetTypeNames.PSGATEWAY;
        default:
            return type;
    }
};

export {
    fleetTypes,
    fleetTypeNames,
    getFleetTypeNameByType
};