import _ from 'lodash';
import moment from 'moment';
import getTypographyStyles from '../../../common/styles/typographyStyles';
import { mdtPalette } from "../../../common/styles/mdtPalette";

const unitChartOptions = (startTime, endTime, seriesData) => {

  let typographyStyles = getTypographyStyles();
  const borderColor = mdtPalette().categories.category1;

  let startTimeMoment = new moment.unix(startTime).utc();
  let endTimeMoment = new moment.unix(endTime).utc();

  let durationHours = endTimeMoment.diff(startTimeMoment, 'hours');

  // The highcharts configuration requires time based axis to be unix milliseconds instead
  // of unix seconds. As well the tick interval must be in milliseconds.
  let maxDate = endTimeMoment.valueOf();
  let minDate = startTimeMoment.valueOf();
  let hourTicks = 60 * 60 * 1000; // every hour
  let minuteTicks = 10 * 60 * 1000; // every 10 minutes

  let tickInterval = hourTicks;
  if (durationHours < 6) {
    // IMPORTANT - We are assuming here that there is only one duration less than the 6 hour duration (last hour),
    // and if we add more then we need to adjust this tick count.
    tickInterval = minuteTicks ;
  } else if (durationHours > 12) {
    tickInterval = hourTicks * 2;
  }

  const series1 = _.isNil(seriesData[0]) ? {} : {...seriesData[0], lineWidth: 0.5};
  const series2 = _.isNil(seriesData[1]) ? {} : seriesData[1];
  const series3 = _.isNil(seriesData[2]) ? {} : {...seriesData[2], lineWidth: 0.5};
  const hasSeries4 = !_.isNil(seriesData[3]);
  const series4 = !hasSeries4 ? {} : seriesData[3];

  let yAxisDefinitions = [];
  // DOC - The yAxes are ordered from beside the chart to outward so we need
  // to place series4 first so that we get our order: 1, 4, 2, 3. This also
  // affects the series yAxis index below.
  if (hasSeries4) {
    yAxisDefinitions.push({color: series4.color, drawBorder: true, placeOnTheRight: false, max: series4.max, min: series4.min, tickInterval: series4.tickInterval});
  }
  yAxisDefinitions.push({color: series1.color, drawBorder: !hasSeries4, placeOnTheRight: false, max: series1.max, min: series1.min, tickInterval: series1.tickInterval});
  yAxisDefinitions.push({color: series2.color, drawBorder: true, placeOnTheRight: true, max: series2.max, min: series2.min, tickInterval: series2.tickInterval});
  yAxisDefinitions.push({color: series3.color, drawBorder: false, placeOnTheRight: true, max: series3.max, min: series3.min, tickInterval: series3.tickInterval});

  let yAxis = _.map(yAxisDefinitions, (definition) => {
    return {
      labels: { format: '{value}', enabled: true, style: { color: definition.color, fontSize: '9px' }, },
      lineWidth: definition.drawBorder ? 0.5 : 0.0,
      lineColor: borderColor,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: { enabled: false },
      opposite: definition.placeOnTheRight,
      max: definition.max,
      min: definition.min,
      tickInterval: definition.tickInterval,
      allowDecimals: false
    }
  });

  let series = _.map(seriesData, (series, index) => {
    return {
      type: 'line',
      data: series.data,
      states: { hover: { enabled: false } },
      color: series.color,
      name: series.latestLabel,
      // DOC - the seriesData is ordered normally: 1, 2, 3, 4 but we reordered
      // the indexes as 4, 1, 2, 3 so we need to ensure the yAxis correspond -
      // this happens to be easy programmatically:
      yAxis: hasSeries4 ? (index + 1) % 4  : index,
      marker: { enabled: false, radius: 3, states: { hover: { enabled: false } } }
    }
  }) ;

  return {
    title: {
      text: ""
    },
    boost: {
      enabled: true,
    },
    time:{
      useUTC: false,
    },
    chart: {
      spacing: [0,1,0,0],
      height: 180,
      width: 625,
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      plotBackgroundColor: mdtPalette().materialUI.palette.background.paper,
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'normal'
      },
      reflow: false,
      animation: false,
      marginBottom: 20,
      marginTop: 8,
      alignTicks: false,
    },
    lang: {
      noData: 'No Data'
    },
    noData: {
      style: typographyStyles.noDataLabel
    },
    tooltip: {
      enabled: true,
      shared: true,
      split: false,
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      borderColor: borderColor,
      style: {
        color: mdtPalette().typography.color,
        fontSize: '10px',
      },
      useHTML: true,
      headerFormat: '<table><tr><td colspan="3" align="center">{point.key}</td></tr>',
      pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
        '<td style="text-align: right"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      stickOnContact: true,
      animation: false
    },
    exporting: {
      enabled:false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      align: 'center',
      verticalAlign: 'center',
      layout: 'vertical',
      itemStyle: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        cursor: 'default'
      },
      itemHoverStyle: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        cursor: 'default'
      },
      x: 278,
      y: 20,
      floating: true
    },
    plotOptions: {
      series: {
        animation: false,
        connectNulls: false
      },
      line: {
        animation: false,
        lineWidth: 0.5,
        enableMouseTracking: false, // enable this for tooltips
        turboThreshold: 500,
        stickyTracking: true,
        shadow: false,
        dataLabels: {
          style: {
            textShadow: false
          }
        },
        events: {
          legendItemClick: function() {
            return false;
          }
        }
      }
    },
    yAxis: yAxis,
    xAxis: {
      crosshair: {
        color: mdtPalette().typography.noData,
      },
      type: 'datetime',
      lineWidth: 0.5,
      lineColor: borderColor,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      tickWidth: 0.5,
      tickPosition: 'inside',
      tickInterval: tickInterval,
      tickColor: borderColor,
      labels: {
        enabled: true,
        format: '{value:%H:%M}',
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          color: '#BDBDBD' // Material UI Grey 400, because mdtPallete().typography.color is still too bright
        }
      },
      min: minDate,
      max: maxDate
    },
    series: series
  }
};

export default unitChartOptions;
