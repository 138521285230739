import * as subscriptionsDisplaySimulation from './subscriptionsDisplaySimulation';
import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchSubscriptionsDisplayDataForOwner = () => {

  if (useSimulationData('subscriptionsDisplay')) {
    return subscriptionsDisplaySimulation.fetchSubscriptionsDisplayData('fakeUser1');
  }

  return performDisplayFetch(
      {
        'query': 'query { subscriptionsForOwner { id name eventId eventEntity eventType createdBy active interval recipients { providerId destination } conditions { name operator value } conditionOperator } }'
      });
}

const fetchSubscriptionsDisplayDataForUser = (user) => {

  if (useSimulationData('subscriptionsDisplay')) {
    return subscriptionsDisplaySimulation.fetchSubscriptionsDisplayData(user);
  }

  return performDisplayFetch(
  {
    'query': 'query SubscriptionsByCreator($creator:String!) { subscriptionsByCreator(creator: $creator) { id name eventId eventEntity eventType createdBy active interval recipients { providerId destination } conditions { name operator value } conditionOperator } }',
    'variables': {
      'creator': user
    }
  });
}

const fetchSaveSubscription = (subscription) => {

  return performDisplayFetch(
  {
    'query': 'mutation SaveSubscription($input:SubscriptionInput!) { saveSubscription(input: $input) { id name eventId eventEntity eventType createdBy active interval recipients { providerId destination } conditions { name operator value } conditionOperator } }',
    'variables': {
      'input': subscription
    }
  });
}

const fetchDeleteSubscription = (subscriptionId) => {

  return performDisplayFetch(
  {
    'query': 'mutation DeleteSubscription($subscriptionId: Long!) {  deleteSubscription(subscriptionId: $subscriptionId) { success } }',
    'variables': {
      'subscriptionId': subscriptionId
    }
  });

}

export {
  fetchSubscriptionsDisplayDataForUser,
  fetchSubscriptionsDisplayDataForOwner,
  fetchSaveSubscription,
  fetchDeleteSubscription,
};