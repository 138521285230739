import _ from 'lodash';

const unitHoursDfpState = state => {
  const noValue = '-';
  return {
    ecmHours: _.isNil(state) ? noValue : state.ecmHours,
    pumpHours: _.isNil(state) ? noValue : state.pumpHours,
  };

};

export {
  unitHoursDfpState
};