import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';
import providerActionTypes from "../actionTypes/providerActionTypes";
import * as providerQueries from '../queries/providerQueries';
import { handleError } from '../../../app/actions/appErrorActions';
import _ from "lodash";
import {constructConfigString} from "../services/providerService";
import {fetchDeleteProviderSettings} from "../queries/providerQueries";
import ComponentTypes from "../../../../components/componentTypes";

const providerQueryDataStarting = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_QUERY_STARTING, 'stateDef');
const providerQueryDataSuccess = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_QUERY_SUCCESS, 'stateDef', 'queryResults');
const providerQueryDataError = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_QUERY_ERROR, 'stateDef');

const queryProviderSettings = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(providerQueryDataStarting(stateDef));
      queryResults = await providerQueries.fetchProviderSettings();
    }catch(e) {
      await dispatch(providerQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_PROVIDER_SETTINGS, e.message));
    }
    await dispatch(providerQueryDataSuccess(stateDef, queryResults));
  }
};

const saveProviderSettingsStarting = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_SAVE_STARTING, 'stateDef');
const saveProviderSettingsSuccess = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_SAVE_SUCCESS, 'stateDef', 'queryResults');
const saveProviderSettingsError = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_SAVE_ERROR, 'stateDef');

const saveProviderSettings = (stateDef, context, name, type, configs) => {
  return async (dispatch, getState) => {

    let queryResults = null;
    let stateDefinition = stateDef;

    try {
      // We maybe calling this from PROVIDER_DIALOG
      // so let's make sure we use the Settings stateKey
      if (stateDefinition.key !== ComponentTypes.SETTINGS) {
        stateDefinition = {
          key: ComponentTypes.SETTINGS,
          type: ComponentTypes.SETTINGS,
        }
      }

      await dispatch(saveProviderSettingsStarting(stateDefinition));

      let providerToSave =
          {
            id: _.isNil(context) ? null : context.id,
            name: name,
            type: type ,
            config: constructConfigString(type, configs),
          }
      queryResults = await providerQueries.fetchSaveProviderSettings(providerToSave);
    }catch(e) {
      await dispatch(saveProviderSettingsError(stateDefinition));
      return dispatch(handleError(errorMessages.ERROR_SAVING_PROVIDER_SETTINGS, e.message));
    }
    await dispatch(saveProviderSettingsSuccess(stateDefinition, queryResults));

    // reuse the handle error for now to show a message on the snackbar
    return dispatch(handleError(`Saved Provider '${name}'.`, null));
  }
};


const deleteProviderSettingsStarting = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_DELETE_STARTING, 'stateDef');
const deleteProviderSettingsSuccess = defaultActionFactory( providerActionTypes.SETTINGS_PROVIDER_DELETE_SUCCESS, 'stateDef', 'queryResults');
const deleteProviderSettingsError = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_DELETE_ERROR, 'stateDef');

const deleteProviderSettings = (stateDef, providerId) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(deleteProviderSettingsStarting(stateDef));

      queryResults = await fetchDeleteProviderSettings(providerId);

    } catch(e) {
      await dispatch(deleteProviderSettingsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_DELETE_PROVIDER_SETTINGS, e.message));
    }

    await dispatch(deleteProviderSettingsSuccess(stateDef, { ...queryResults, providerId: providerId }));
    return dispatch(queryProviderSettings(stateDef));
  }

};

const showProviderDialogSetting = defaultActionFactory(providerActionTypes.SETTINGS_SHOW_PROVIDER_DIALOG, 'stateDef','show', 'context');

const showProviderDialog = (stateDef, show, context) => {
  return async (dispatch, getState) => {
    let stateDefinition = stateDef;

    // We maybe calling this from PROVIDER_DIALOG component
    // so let's make sure we use the SETTINGS stateKey
    if (stateDefinition.key !== ComponentTypes.SETTINGS) {
      stateDefinition = {
        key: ComponentTypes.SETTINGS,
        type: ComponentTypes.SETTINGS,
      }
    }
    await dispatch(showProviderDialogSetting(stateDefinition, show, context));
  }
};

const showDeleteProviderDialog = defaultActionFactory(providerActionTypes.SETTINGS_SHOW_DELETE_PROVIDER_DIALOG, 'stateDef','show', 'context');

const dataGridSetRowSize = defaultActionFactory(providerActionTypes.SETTINGS_PROVIDER_SET_ROW_SIZE, 'stateDef', 'rowSize');

export {
  queryProviderSettings,
  saveProviderSettings,
  deleteProviderSettings,
  showProviderDialog,
  showDeleteProviderDialog,
  dataGridSetRowSize,
};