import _ from 'lodash';
import unitActivityDfpActionTypes from './unitActivityDfpActionTypes';
import { unitActivityDfpState } from './unitActivityDfpSelectors';
import unitActivityDfpTypes from './unitActivityDfpTypes';

const initialState = unitActivityDfpState();

const unitActivityDfpReducer = (state=initialState, action) => {

  switch (action.type) {

    case unitActivityDfpActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);
    case unitActivityDfpActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);
    case unitActivityDfpActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);
      case unitActivityDfpActionTypes.UNIT_ACTIVITY_DFP_CLEAR_DATA:
      return onClearData(state, action);
    default: return state;
  }
};

const onQueryDataStarting = (state, action) => {
  return { ...state, queryRunning: true };
};

const onQueryDataError = (state, action) => {
  return { ...state, queryRunning: false };
};

const onQueryDataSuccess = (state, action) => {
  if (action.queryResults.chartType === unitActivityDfpTypes.CURBSIDE) {
    return processResponse(state, action.queryResults.unitActivityCurbside)
  } else {
    return processResponse(state, action.queryResults.unitActivityRoadside)
  }
};

const processResponse = (state, response) => {
  const noValue = '-';

  let { pumping, idle, stopped, days } = response;

  let displayValuePumping = _.isNil(pumping) ? noValue : parseFloat(pumping.toFixed(0)).toLocaleString('en') + '%';
  let displayValueIdle = _.isNil(idle) ? noValue : parseFloat(idle.toFixed(0)).toLocaleString('en')  + '%';
  let displayValueStopped = _.isNil(stopped) ? noValue : parseFloat(stopped.toFixed(0)).toLocaleString('en')  + '%';

  let displayDays = null;
  if (!_.isNil(days)) {
    if (days > 1) {
      displayDays = '{days} days'.replace('{days}', days);
    } else if (days === 1) {
      displayDays = '{days} day'.replace('{days}', days);
    }
  }

  return {
    ...state,
    queryRunning: false,
    rawValuePumping: pumping,
    rawValueIdle: idle,
    rawValueStopped: stopped,
    displayDays: displayDays,
    displayValuePumping: displayValuePumping,
    displayValueIdle: displayValueIdle,
    displayValueStopped: displayValueStopped,
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    rawValuePumping: initialState.rawValuePumping,
    rawValueIdle: initialState.rawValueIdle,
    rawValueStopped: initialState.rawValueStopped,
    displayDays: initialState.displayDays,
    displayValuePumping: initialState.displayValuePumping,
    displayValueIdle: initialState.displayValueIdle,
    displayValueStopped: initialState.displayValueStopped,
  };
};

export default unitActivityDfpReducer