import {performCardFetch} from '../../common/fetch'

/**
 * Method to perform the remote query for the unit hours card data
 */
const fetchUnitFleetCardData = (truckId) => {
  return performCardFetch({
    'query': '{ unitFleet(truckId:{truckId}) { currentFleetName currentFleetId currentFleetType previousFleetName } }'.replace('{truckId}', truckId)
  })
};

export default fetchUnitFleetCardData