import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import chartOptions from '../common/activityCard/activityCardChartOptions';
import ActivityCard from '../common/activityCard/activityCard';
import ComponentTypes from '../../componentTypes';
import * as unitActivityDfpActions from '../../../state/cards/unitActivityDfp/unitActivityDfpActions';
import { unitActivityDfpState } from '../../../state/cards/unitActivityDfp/unitActivityDfpSelectors';
import unitActivityDfpTypes from '../../../state/cards/unitActivityDfp/unitActivityDfpTypes';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes,mdtCardMapStateToProps} from "../mdtCard/mdtCard";

class UnitActivityCurbside extends MDTCard {

  getName() {
    return 'CURBSIDE ACTIVITY';
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  getRenderedContent() {
    return (
      <ActivityCard
        title={'CURBSIDE ACTIVITY'}
        chartOptions={this.props.chartOptions}
        displayValueDays={this.props.displayValueDays}
        displayValuePumping={this.props.displayValuePumping}
        displayValueIdle={this.props.displayValueIdle}
        displayValueStopped={this.props.displayValueStopped}
      />
    )
  }
}

UnitActivityCurbside.propTypes = mdtCardPropTypes

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_ACTIVITY_CURBSIDE : props.stateKey,
      type: ComponentTypes.UNIT_ACTIVITY_CURBSIDE,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitActivityDfpState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    displayValuePumping : componentState.displayValuePumping,
    displayValueIdle: componentState.displayValueIdle,
    displayValueStopped: componentState.displayValueStopped,
    displayValueDays: componentState.displayDays,
    chartOptions: chartOptions(componentState.rawValuePumping, componentState.rawValueIdle, componentState.rawValueStopped),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(unitActivityDfpActions.queryData(props.stateDef, unitActivityDfpTypes.CURBSIDE, context)) },
    clearData: () => { dispatch(unitActivityDfpActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitActivityCurbside));
