import React, {Component} from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import {connect} from 'react-redux';

import { Typography, DialogContent, Box, Divider, Button, CircularProgress, IconButton } from '@mui/material';
import DataTableControl from '../../controls/table/dataTableControl';
import RefreshIcon from '@mui/icons-material/Refresh';
import DashboardIcon from '@mui/icons-material/Dashboard';

import ComponentTypes from '../../componentTypes';
import * as clientStatusActions from '../../../state/displays/clientStatus/clientStatusActions';
import * as statusIconHelpers from '../../common/statusIconHelper';
import { clientStatusState } from '../../../state/displays/clientStatus/clientStatusSelectors';
import * as assetTypesService from '../../../state/common/services/assetTypeService';

const statusIconStyles = statusIconHelpers.getStatusIconStyles();

const styles = {
  ...statusIconStyles,
  dialogContextContainer: {
    display: 'flex',
    flexGrow: 1
  },
  dialogContentLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '900px',
  },
  dialogContentLoaded: {
    display: 'flex',
    flexFlow: 'column',
    width: '900px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      marginLeft: 1
    }
  },
  contentBreak: {
    marginTop: 2
  },
  cloudDetails: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  section: {
    flexGrow: 1,
    '& > div': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      paddingTop: 2
    }
  },
  truckTableContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 2
  },
  textSpacingNormal: {
    paddingLeft: 1
  },
  textSpacingLarge: {
    paddingLeft: 3
  },
  buttonSpacingMedium: {
    marginLeft: 2,
  },
  errorContentContainer: {
    display:'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  errorContentTitle: {
    paddingBottom: 2
  },
  errorContentCaption: {
    paddingBottom: 4
  }
};

class ClientStatusDetails extends Component {

  constructor(props) {
    super(props);
    this.renderTableAction = this.renderTableAction.bind(this);
  }

  componentDidMount() {
    this.props.queryDetails(this.props.selectedClient);
  }

  generateTruckDetailsTableColumns() {
    let columns = [];
    columns.push({type: 'data', width: 160, label: 'Unit ID', displayProperty: 'name', sortProperty: 'name'});
    columns.push({type: 'data', width: 160, label: 'Type', displayProperty: 'displayValueType', sortProperty: 'type'});
    columns.push({type: 'data', width: 200, label: 'Last Sensor Upload', displayProperty: 'displayValueLastSensorUpload', sortProperty: 'lastSensorUpload' });
    columns.push({type: 'data', width: 200, label: 'Last Sensor Data Age', displayProperty: 'displayValueLastSensorDataAge', sortProperty: 'lastSensorDataAge' });
    columns.push({type: 'action', width: 60, renderAction: this.renderTableAction});
    return columns;
  }

  renderLoadingContent() {
    return (
      <DialogContent sx={styles.dialogContentLoading}>
        <CircularProgress size={80}/>
      </DialogContent>
    )
  }

  renderErrorContent() {
    return (
      <DialogContent sx={styles.dialogContentLoading}>
        <Box sx={styles.errorContentContainer}>
          <Typography sx={styles.errorContentTitle} variant={'h5'}>Oops! Something went wrong.</Typography>
          <Typography sx={styles.errorContentCaption} variant={'subtitle1'} align="center" noWrap >We were unable to load the details for this Cloud Client. Please try again.</Typography>
          <Button variant={'contained'} color="primary" onClick={() => { this.props.queryDetails(this.props.selectedClient); } }>Try Again</Button>
        </Box>
      </DialogContent>
    )
  }

  renderTableAction(tableContext, item) {
    if (!_.isNil(item) && item.type === assetTypesService.assetTypes.FRAC_PUMP) {
      return (
        <IconButton
          onClick={(e) => { this.props.navigateToPumpDashboard(item.id) }}
          size="large"><DashboardIcon fontSize={'small'}/>
        </IconButton>
      );
    }
    if (!_.isNil(item) && item.type === assetTypesService.assetTypes.COIL_UNIT) {
      return (
        <IconButton
          onClick={(e) => { this.props.navigateToCoilDashboard(item.id) }}
          size="large"><DashboardIcon fontSize={'small'}/>
        </IconButton>
      );
    }
  }

  renderDetailsContent() {
    let columns = this.generateTruckDetailsTableColumns();

    return (
      <DialogContent sx={styles.dialogContentLoaded}>
        <div>
          <Box sx={styles.dialogTitle}>
            <Typography variant='h5'>{this.props.selectedClientDetails.fleetName}</Typography>
            <IconButton
              onClick={() => { this.props.queryDetails(this.props.selectedClient); }}
              size="large"><RefreshIcon/>
            </IconButton>
            <IconButton
              onClick={() => { this.props.navigateToFleetDashboard(this.props.selectedClient.fleetId) }}
              size="large"><DashboardIcon/>
            </IconButton>
          </Box>
          <Divider/>
        </div>
        <div>
          <Box sx={styles.cloudDetails}>
            <Box sx={styles.section}>
              <div>
                <Typography variant={'h6'}>{this.props.selectedClientDetails.description ? this.props.selectedClientDetails.description.title : ''}</Typography>
                <Box sx={styles.textSpacingLarge}>
                  { statusIconHelpers.getClientStatusIcon(this.props.selectedClientDetails.status, styles) }
                </Box>
              </div>
              <div>
                <Typography variant='caption'>{this.props.selectedClientDetails.description ? this.props.selectedClientDetails.description.caption : ''}</Typography>
              </div>
              <div>
                <Typography variant={'caption'}>Last Cloud Service Contact:</Typography>
                <Typography sx={styles.textSpacingNormal} variant={'body2'}>{this.props.selectedClientDetails.displayValueLastContactEdge}</Typography>
              </div>
              <div>
                <Typography variant={'caption'}>Fleet Identifier:</Typography>
                <Typography sx={styles.textSpacingNormal} variant={'body2'}>{this.props.selectedClientDetails.fleetIdentifier}</Typography>
              </div>
              <div>
                <Typography variant={'caption'}>Version:</Typography>
                <Typography sx={styles.textSpacingNormal} variant={'body2'}>{this.props.selectedClientDetails.producerVersion}</Typography>
                <Typography sx={styles.textSpacingLarge} variant={'caption'}>Computer:</Typography>
                <Typography sx={styles.textSpacingNormal} variant={'body2'}>{this.props.selectedClientDetails.computer}</Typography>
              </div>
            </Box>
            <Box sx={styles.section}>
              <div>
                <Typography variant={'h6'}>{this.props.selectedClientDetails.sensorUploadDescription ? this.props.selectedClientDetails.sensorUploadDescription.title : ''}</Typography>
                <Box sx={styles.textSpacingLarge}>
                  { statusIconHelpers.getUploadStatusIcon(this.props.selectedClientDetails.isUploading, styles) }
                </Box>
              </div>
              <div>
                <Typography variant='caption'>{this.props.selectedClientDetails.sensorUploadDescription ? this.props.selectedClientDetails.sensorUploadDescription.caption : ''}</Typography>
              </div>
              <div>
                <Typography variant={'caption'}>Last Sensor Upload:</Typography>
                <Typography sx={styles.textSpacingNormal} variant={'body2'}>{this.props.selectedClientDetails.displayValueLastContactSensor}</Typography>
              </div>
              <div>
                <Typography variant={'h6'}>{this.props.selectedClientDetails.sensorDataDescription ? this.props.selectedClientDetails.sensorDataDescription.title : ''}</Typography>
                <Box sx={styles.textSpacingLarge}>
                  { statusIconHelpers.getDataStatusIcon(this.props.selectedClientDetails.dataUploadStatus, styles) }
                </Box>
              </div>
              <div>
                <Typography variant='caption'>{this.props.selectedClientDetails.sensorDataDescription ? this.props.selectedClientDetails.sensorDataDescription.caption : ''}</Typography>
              </div>
            </Box>
          </Box>
        </div>
        {
          !_.isEmpty(this.props.selectedClientDetails.datasources) &&
          this.props.selectedClientDetails.datasources.map((dataSource, index) => {
            return (
              <div key={index}>
                <Divider sx={styles.contentBreak}/>
                <Box sx={styles.section}>
                  <div>
                    <Typography variant={'h6'}>Data Source - {dataSource.description ? dataSource.description.title : ''}</Typography>
                    <Box sx={styles.textSpacingLarge}>
                      { statusIconHelpers.getProductStatusIcon(dataSource.status, styles) }
                    </Box>
                  </div>
                  <div>
                    <Typography variant='caption'>{dataSource.description ? dataSource.description.caption : ''}</Typography>
                    {
                      !dataSource.showDetails &&
                      <Button sx={styles.buttonSpacingMedium} variant={'outlined'} size="small" onClick={() => { this.props.showDataSourceDetails(dataSource.id)}} color='inherit'>More</Button>
                    }
                    {
                      dataSource.showDetails &&
                      <Button sx={styles.buttonSpacingMedium} variant={'outlined'} size="small" onClick={() => { this.props.hideDataSourceDetails(dataSource.id)}} color='inherit'>Less</Button>
                    }
                  </div>
                </Box>
                {
                  dataSource.showDetails &&
                  <Box sx={styles.section}>
                    <div>
                      <Typography variant={'caption'}>Last Contact:</Typography>
                      <Typography sx={styles.textSpacingNormal} variant={'body2'}>{dataSource.displayValueLastContact}</Typography>
                    </div>
                    <div>
                      <Typography variant={'caption'}>Last Sensor Upload:</Typography>
                      <Typography sx={styles.textSpacingNormal} variant={'body2'}>{dataSource.displayValueLastSensorUpload}</Typography>
                      <Typography sx={styles.textSpacingLarge}  variant={'caption'}>Last Sensor Data Age:</Typography>
                      <Typography sx={styles.textSpacingNormal} variant={'body2'}>{dataSource.displayValueLastSensorDataAge}</Typography>
                    </div>
                    <div>
                      <Typography variant={'caption'}>Database:</Typography>
                      <Typography sx={styles.textSpacingNormal} variant={'body2'}>{dataSource.database}</Typography>
                      <Typography sx={styles.textSpacingLarge} variant={'caption'}>Samples Remaining:</Typography>
                      <Typography sx={styles.textSpacingNormal} variant={'body2'}>{dataSource.displayValueSamplesRemaining}</Typography>
                    </div>
                    <div>
                      <Typography variant={'subtitle1'}>Truck Details:</Typography>
                    </div>
                    <Box sx={styles.truckTableContainer}>
                      <DataTableControl
                        tableContext={dataSource.id}
                        columns={columns}
                        items={dataSource.truckDetails}
                        sortContext={dataSource.trucksSortContext}
                        sortDirection={dataSource.trucksSortDirection}
                        itemsPerPage={dataSource.trucksPerPage}
                        currentPage={dataSource.trucksCurrentPage}
                        sortItems={this.props.sortDataSourceTrucks}
                        setItemsPerPage={this.props.setDataSourceTruckItemsPerPage}
                        setCurrentPage={this.props.setDataSourceTruckCurrentPage}
                      />
                    </Box>
                  </Box>
                }
              </div>
            )
          })
        }
      </DialogContent>
    );
  }

  render() {
    if (this.props.detailsQueryRunning) {
      return this.renderLoadingContent();
    } else if (this.props.detailsQueryFailed) {
      return this.renderErrorContent();
    } else {
      return this.renderDetailsContent();
    }
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.CLIENT_STATUS : props.stateKey,
      type: ComponentTypes.CLIENT_STATUS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = clientStatusState(state[stateDef.key]);
  return {
    openDetailsUI : componentState.openDetailsUI,
    selectedClient: componentState.selectedClient,
    selectedClientDetails: componentState.selectedClientDetails,
    detailsQueryRunning: componentState.detailsQueryRunning,
    detailsQueryFailed: componentState.detailsQueryFailed,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryDetails: (fleetIdentifier) => { dispatch(clientStatusActions.queryDetails(props.stateDef, fleetIdentifier)) },
    closeDetailsDialog: () => { dispatch(clientStatusActions.closeDetailsDialog(props.stateDef)) },
    showDataSourceDetails: (dataSourceId) => { dispatch(clientStatusActions.showDataSourceDetails(props.stateDef, dataSourceId)) },
    hideDataSourceDetails: (dataSourceId) => { dispatch(clientStatusActions.hideDataSourceDetails(props.stateDef, dataSourceId)) },
    sortDataSourceTrucks: (dataSourceId, sortContext) => { dispatch(clientStatusActions.sortDataSourceTrucks(props.stateDef, dataSourceId, sortContext)) },
    setDataSourceTruckItemsPerPage: (dataSourceId, itemsPerPage) => { dispatch(clientStatusActions.setDataSourceTruckItemsPerPage(props.stateDef, dataSourceId, itemsPerPage)) },
    setDataSourceTruckCurrentPage: (dataSourceId, currentPage) => { dispatch(clientStatusActions.setDataSourceTruckCurrentPage(props.stateDef, dataSourceId, currentPage)) },
    navigateToFleetDashboard: (fleetId) => dispatch(clientStatusActions.navigateToFleetDashboardFromDialog(props.stateDef, fleetId)),
    navigateToPumpDashboard: (unitId) => dispatch(clientStatusActions.navigateToPumpDashboardFromDialog(props.stateDef, unitId)),
    navigateToCoilDashboard: (unitId) => dispatch(clientStatusActions.navigateToCoilDashboardFromDialog(props.stateDef, unitId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ClientStatusDetails))
