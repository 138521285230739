import React from 'react';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import {connect} from 'react-redux';
import _ from 'lodash';
import { Typography, Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import ComponentTypes from '../../componentTypes';
import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { mdtPalette } from '../../common/styles/mdtPalette';
import {fleetOperationState} from '../../../state/cards/fleetOperation/fleetOperationSelectors';
import chartOptions from './chartOptions';
import * as fleetOperationActions from '../../../state/cards/fleetOperation/fleetOperationActions';
import ButtonBase from '@mui/material/ButtonBase';
import GearsIcon from '../../controls/icons/gears';
import fleetMapViews from '../../../state/displays/fleetMap/display/fleetMapViews';
import VerticalBarChart from '../../controls/charts/verticalBarChart/verticalBarChart';

import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,

  // Overall card style
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    alignItems: 'center',
  },

  // Chart and associated label
  chartAndChartLabelContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    width: '95%',
    height: '110px',
    minWidth: '313px',
    marginTop: '10px',
  },
  totalsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    width: '95%',
    height: '100px',
    minWidth: '313px',
  },
  chartAndBorderContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  highChartContainer: {
    display:'flex',
    alignItems:'center',
    width: '100px' // For consistent sizing when there is no data
  },
  pieChartAndTotalsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    width: '274px', // For consistent sizing inside cards
    minHeight: '100px', // For consistent sizing inside cards
    alignItems: 'center',
  },
  chartLabelContainer: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
    paddingRight: '10px'
  },
  chartLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },

  // Legend for Pumps chart
  legendContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    width: '164px',
  },
  legend: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  legendValue: {
    width: '20px',
    paddingLeft: '7px',
    alignItems: 'center',
  },
  legendLabel: {
    paddingLeft: '10px',
  },

  pumpCountContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1
  },

  // Totals style
  labelsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '57px',
  },

  subLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },

  valueAndUomContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '110px',
  },
  valueLabel: {
    fontSize: '20px',
    fontWeight: 500,
    marginRight: '5px'
  },
};

class FleetOperation extends MDTCard {

  getName() {
    return 'Fleet Operation';
  }

  getTitle() {
    return 'OPERATION'
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.id);
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  getRenderedButtonsInHeader() {
    return (
        !this.isInEditMode() &&
        <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.props.navigateToView(fleetMapViews.OPERATION)}>
         <GearsIcon/>
        </ButtonBase>
    )
  }

  getRenderedContent() {
    const { chartOptions } = this.props;

    return (
     <Box sx={styles.cardContent}>
            <Box sx={styles.chartAndChartLabelContainer}>
              <Box sx={styles.chartLabelContainer}>
                <Typography sx={styles.chartLabel} variant={'subtitle1'}>Pumps</Typography>
              </Box>
              <Box sx={styles.pieChartAndTotalsContainer}>
                <Box sx={styles.pumpCountContainer}>
                  <Typography variant={'h3'}>{this.props.pumps.count}</Typography>
                  <Typography variant={'subtitle1'}>Pumps</Typography>
                </Box>
                <Box sx={styles.highChartContainer}>
                  {
                    <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
                  }
                </Box>
                <Box sx={styles.legendContainer}>
                  <Box sx={styles.legend}>
                    <svg width='18' height='18'><rect width='18' height='18' style={{fill:mdtPalette().status.level2}} /></svg>
                    <Box sx={styles.legendValue}>
                      <Typography variant={'subtitle1'}>{this.props.pumps.pumping.display}</Typography>
                    </Box>
                    <Box sx={styles.legendLabel}>
                      <Typography variant={'subtitle1'}>Pumping</Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.legend}>
                    <svg width='18' height='18'><rect width='18' height='18' style={{fill:mdtPalette().status.level4}} /></svg>
                    <Box sx={styles.legendValue}>
                      <Typography variant={'subtitle1'}>{this.props.pumps.idle.display}</Typography>
                    </Box>
                    <Box sx={styles.legendLabel}>
                      <Typography variant={'subtitle1'}>Idle</Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.legend}>
                    <svg width='18' height='18'><rect width='18' height='18' style={{fill:mdtPalette().status.level5}} /></svg>
                    <Box sx={styles.legendValue}>
                      <Typography variant={'subtitle1'}>{this.props.pumps.stopped.display}</Typography>
                    </Box>
                    <Box sx={styles.legendLabel}>
                      <Typography variant={'subtitle1'}>Stopped</Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.legend}>
                    <svg width='18' height='18'><rect width='18' height='18' style={{fill:mdtPalette().status.level1}} /></svg>
                    <Box sx={styles.legendValue}>
                      <Typography variant={'subtitle1'}>{this.props.pumps.offline.display}</Typography>
                    </Box>
                    <Box sx={styles.legendLabel}>
                      <Typography variant={'subtitle1'}>Offline</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <VerticalBarChart data={this.props.suction} label='Suction'
                              minLabel={0} maxLabel={200} total={200}/>

            <VerticalBarChart data={this.props.hhp} label='HHP'
                              minLabel={0} maxLabel={2000} total={2000}/>

            <Box sx={styles.totalsContainer}>
              <Box sx={styles.chartLabelContainer}>
                <Typography sx={styles.chartLabel} variant={'subtitle1'}>Totals</Typography>
              </Box>
              <Box sx={styles.chartAndBorderContainer}>
                <Box sx={styles.pieChartAndTotalsContainer}>
                  <Box sx={styles.labelsContainer}>
                    <Box sx={styles.subLabelContainer}>
                      <Typography variant={'subtitle1'}>Sand</Typography>
                      <Box sx={styles.valueAndUomContainer} >
                        <Typography variant={'subtitle1'} sx={styles.valueLabel}>{this.props.totalSand}</Typography>
                        <Typography variant={'caption'}>lbs</Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.subLabelContainer}>
                      <Typography variant={'subtitle1'}>Slurry</Typography>
                      <Box sx={styles.valueAndUomContainer} >
                        <Typography variant={'subtitle1'} sx={styles.valueLabel}>{this.props.totalSlurry}</Typography>
                        <Typography variant={'caption'}>bbl</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
  }
}

FleetOperation.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_OPERATION : props.stateKey,
      type: ComponentTypes.FLEET_OPERATION,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetOperationState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    pumps: componentState.pumps,
    suction: componentState.suction,
    hhp: componentState.hhp,
    totalSand: componentState.totalSand,
    totalSlurry: componentState.totalSlurry,
    chartOptions: chartOptions(
        componentState.pumps.pumping.raw, componentState.pumps.idle.raw,
        componentState.pumps.stopped.raw, componentState.pumps.offline.raw,
    ),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(fleetOperationActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetOperationActions.clearData(props.stateDef)) },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetOperation));