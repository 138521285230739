import _ from 'lodash';
import exportActionTypes from '../exportActionTypes';
import * as exportService from '../services/exportService';

const formReducer = (state, action) => {

  switch (action.type) {

    case exportActionTypes.EXPORT_SELECT_TRUCK:
      return onSelectTruck(state, action);

    case exportActionTypes.EXPORT_SELECT_DATE_TIME:
      return onSelectStartTime(state, action);

    case exportActionTypes.EXPORT_SELECT_DURATION:
      return onSelectDuration(state, action);

    case exportActionTypes.EXPORT_SELECT_TIMEZONE:
      return onSelectTimezone(state, action);

    case exportActionTypes.EXPORT_INIT_TIMEZONES:
      return onSetTimezoneDetails(state, action);

    case exportActionTypes.EXPORT_SELECT_DATE_TIME_DISPLAY:
      return onSelectStartTimeDisplay(state, action);

    case exportActionTypes.EXPORT_SHOW_SENSOR_SELECTOR: 
      return onShowSensorSelector(state, action);

    case exportActionTypes.EXPORT_SET_SELECTED_SENSORS:
      return onSetSelectedSensors(state, action);

    case exportActionTypes.EXPORT_SELECT_NEXT_TRUCK:
      return onSelectNextTruck(state, action);

    case exportActionTypes.EXPORT_SELECT_PREV_TRUCK:
      return onSelectPrevTruck(state, action);

    default: return state;

  }
};

const onSelectTruck = (state, action) => {
  let newState = {
    ...state,
    selectedTruck: action.truckId,
  };
  newState.canExport = exportService.resolveCanExport(newState);
  return newState;
};

const onSelectStartTime = (state, action) => {
  let newState = {
    ...state,
    selectedDateTime: action.startTime,
    selectedDateTimeDisplay: action.startTime,
  };
  newState.canExport = exportService.resolveCanExport(newState);
  return newState;
};

const onSelectDuration = (state, action) => {
  let newState = {
    ...state,
    selectedDuration: action.duration,
  };
  newState.exceededMaximumDuration = exportService.hasExceededMaximumDuration(newState);
  newState.canExport = exportService.resolveCanExport(newState);
  newState.maximumDurationLabel = exportService.getMaximumDurationLabel(newState);
  return newState;
};

const onSelectTimezone = (state, action) => {
  let newState = {
    ...state,
    selectedTimezone: action.timezone,
  };
  newState.canExport = exportService.resolveCanExport(newState);
  return newState;
};

const onSetTimezoneDetails = (state, action)  => {

  if (_.isNil(action)) {
    return state;
  }

  let groupedTimezones = [];
  
  groupedTimezones.push(
    {
      value: 'UTC',
      label: 'UTC/Coordinated Universal Time',
      group: 'UTC',
      order: 1
    }
  );

  if (!_.isNil(action.caTimezones)) {
    for (let i=0; i<action.caTimezones.length; i++) {
      groupedTimezones.push(
        {
          value: action.caTimezones[i],
          label: action.caTimezones[i],
          group: 'Canada',
          order: 2
        }
      )
    }
  }

  if (!_.isNil(action.usTimezones)) {
    for (let i=0; i<action.usTimezones.length; i++) {
      groupedTimezones.push(
        {
          value: action.usTimezones[i],
          label: action.usTimezones[i],
          group: 'United States',
          order: 3
        }
      )
    }
  }

  _.sortBy(groupedTimezones, ["order"]);

  return {
    ...state,
    timezones: {
      caTimezones: action.caTimezones,
      usTimezones: action.usTimezones,
      localTimezone: {value: action.localTimezone, label: action.localTimezone},
      locale: action.locale,
      groupedTimezones: groupedTimezones
    }
  }
};

const onSelectStartTimeDisplay = (state, action) => {

  return {
    ...state,
    selectedDateTimeDisplay: action.startTime
  }
}

const onShowSensorSelector = (state, action) => {

  return {
    ...state,
    showSensorSelector: action.show
  }

}

const onSetSelectedSensors = (state, action) => {

  let newState = {
    ...state,
    selectedSensors: action.selectedSensors,
  }
  newState.exceededMaximumDuration = exportService.hasExceededMaximumDuration(newState);
  newState.canExport = exportService.resolveCanExport(newState);
  newState.maximumDurationLabel = exportService.getMaximumDurationLabel(newState);

  return newState;

}

const onSelectNextTruck = (state, action) => {

  let selectedTruck = state.selectedTruck;
  let selectedIndex = state.filteredTrucks.findIndex(t=>t.id===selectedTruck.id);
  
  if(selectedIndex < state.filteredTrucks.length-1){
    ++selectedIndex;
    selectedTruck = state.filteredTrucks[selectedIndex];
  }

  return onSelectTruck(state, { truckId: selectedTruck } );

}

const onSelectPrevTruck = (state, action) => {

  let selectedTruck = state.selectedTruck;
  let selectedIndex = state.filteredTrucks.findIndex(t=>t.id===selectedTruck.id);
  
  if(selectedIndex > 0){
    --selectedIndex;
    selectedTruck = state.filteredTrucks[selectedIndex];
  }

  return onSelectTruck(state, { truckId: selectedTruck } );

}

export default formReducer