import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';

import fleetComponentsActionTypes from './fleetComponentsActionTypes';
import * as fleetComponentsQueries from './fleetComponentsQueries';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetComponentsActionTypes.FLEET_COMPONENTS_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_STARTING,
  fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_SUCCESS,
  fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEET_COMPONENT_CARD_DATA,
  fleetComponentsQueries.fetchFleetComponents
);

export {
  queryData,
  clearData,
}