import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Box, Button } from '@mui/material';

import ComponentTypes from "../../componentTypes";
import { appState } from '../../../state/app/appSelectors';

import { jobOverviewState } from "../../../state/displays/jobOverview/jobOverviewSelectors";
import * as jobOverviewActions from '../../../state/displays/jobOverview/jobOverviewActions';
import * as jobStatusConstants from '../../common/jobStatusConstants';

class ApprovalDialog extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const noValue = '-';
    let contextName = noValue;
    let eventType = this.props.dialogType;
    let event = noValue;
    switch (eventType) {
      case jobStatusConstants.JOB_APPROVAL_ACCEPT:
        event = 'Approve';
        contextName = this.props.selectedJobInfo.name;
        eventType = 'JOB';
        break;
      case jobStatusConstants.JOB_APPROVAL_REJECT:
        event = 'Reject';
        contextName = this.props.selectedJobInfo.name;
        eventType = 'JOB';
        break;
      case jobStatusConstants.STAGE_APPROVAL_ACCEPT:
        event = 'Approve';
        contextName = this.props.selectedStage.description;
        eventType = 'STAGE';
        break;
      case jobStatusConstants.STAGE_APPROVAL_REJECT:
        event = 'Reject';
        contextName = this.props.selectedStage.description;
        eventType = 'STAGE';
        break;
      default:
        eventType = 'UNKNOWN';
        break;
    }

    const title = event + ' ' + contextName;

    return (
      <Dialog open={this.props.showDialog} disableEscapeKeyDown PaperProps={{ sx: { minWidth: '700px' }}}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{width: '650px'}}>
            <TextField 
              sx={{marginTop: 2}}
              focused
              autoFocus
              multiline
              minRows={1}
              maxRows={10}
              variant='outlined' 
              size="small" 
              fullWidth 
              inputProps={{maxLength:500}} 
              value={this.props.notes}
              onChange={event => this.props.onDialogSetNotes(event.target.value)}
              label='Notes'
              helperText={(500 - this.props.notes.length) + '/500 characters remaining' }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onShowDialog(false)}} color='inherit'>Cancel</Button>
          <Button onClick={() => 
          { 
            switch (this.props.dialogType) {
              case jobStatusConstants.JOB_APPROVAL_ACCEPT:
              case jobStatusConstants.JOB_APPROVAL_REJECT:
                this.props.onSaveJobStatus(this.props.selectedJobInfo.id, this.props.dialogType, this.props.user.email, this.props.notes);
                break;
              case jobStatusConstants.STAGE_APPROVAL_ACCEPT:
              case jobStatusConstants.STAGE_APPROVAL_REJECT:
                this.props.onSaveStageStatus(this.props.selectedStage.id, this.props.dialogType, this.props.user.email, this.props.notes, this.props.selectedJobInfo.name, this.props.selectedStage.stageGuid);
                break;
              default:
                break;
            }
            this.props.onShowDialog(false); 
          }} 
          color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_OVERVIEW : props.stateKey,
      type: ComponentTypes.JOB_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobOverviewState(state[stateDef.key]);
  let applicationState = appState(state);

  return {
    showDialog: componentState.showDialog,
    notes: componentState.notes,
    dialogType: componentState.dialogType,
    
    user: applicationState.user,

    selectedJobInfo: componentState.selectedJobInfo,
    selectedStage: componentState.selectedStage,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowDialog: (show) => { dispatch(jobOverviewActions.showDialog(props.stateDef, show)); },

    onDialogSetNotes: _.debounce((value) => { dispatch(jobOverviewActions.dialogSetNotes(props.stateDef, value)); }),

    onSaveJobStatus: (id, status, user, notes) => { dispatch(jobOverviewActions.saveJobStatus(props.stateDef, id, status, user, notes)); },
    onSaveStageStatus: (id, status, user, notes, jobName, stageGuid) => { dispatch(jobOverviewActions.saveStageStatus(props.stateDef, id, status, user, notes, jobName, stageGuid)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ApprovalDialog));