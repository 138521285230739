import _ from 'lodash';
import clientActionTypes from '../clientStatusActionTypes';
import * as clientService from '../services/clientService';
import { clientStatusState } from '../clientStatusSelectors';

const initialState = clientStatusState();

const clientReducer = (state, action) => {

  switch (action.type) {

    case clientActionTypes.CLIENT_STATUS_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case clientActionTypes.CLIENT_STATUS_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case clientActionTypes.CLIENT_STATUS_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    case clientActionTypes.CLIENT_STATUS_QUERY_DETAILS_STARTING:
      return onQueryDetailsStarting(state, action);

    case clientActionTypes.CLIENT_STATUS_QUERY_DETAILS_SUCCESS:
      return onQueryDetailsSuccess(state, action);

    case clientActionTypes.CLIENT_STATUS_QUERY_DETAILS_ERROR:
      return onQueryDetailsError(state, action);

    case clientActionTypes.CLIENT_STATUS_REFRESH_RELATIVE_TIME:
      return onRefreshRelativeTime(state, action);

    case clientActionTypes.CLIENT_STATUS_CLOSE_DETAILS_DIALOG:
      return onCloseDetailsDialog(state, action);

    case clientActionTypes.CLIENT_STATUS_SHOW_DATASOURCE_DETAILS:
      return onShowDataSourceDetails(state, action);

    case clientActionTypes.CLIENT_STATUS_HIDE_DATASOURCE_DETAILS:
      return onHideDataSourceDetails(state, action);

    case clientActionTypes.CLIENT_STATUS_SORT_DATASOURCE_TRUCKS:
      return onSortDataSourceTrucks(state, action);

    case clientActionTypes.CLIENT_STATUS_SET_DATASOURCE_TRUCKS_ITEMS_PER_PAGE:
      return onSetDataSourceTrucksItemsPerPage(state, action);

    case clientActionTypes.CLIENT_STATUS_SET_DATASOURCE_TRUCKS_CURRENT_PAGE:
      return onSetDataSourceTrucksCurrentPage(state, action);

    default: return state;

  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
    clients: [],
    filteredClients: [],
    currentPage: initialState.currentPage,
    selectedClient: initialState.selectedClient,
    selectedClientDetails: initialState.selectedClientDetails,
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    clients: [],
    filteredClients: [],
    currentPage: initialState.currentPage,
    selectedClient: initialState.selectedClient,
    selectedClientDetails: initialState.selectedClientDetails,
  };
};

const onQueryDataSuccess = (state, action) => {

  let clientDetails = clientService.processClients(action.queryResults.clientStatus, state.appliedFilters);

  return {
    ...state,
    queryRunning: false,
    clients: action.queryResults.clientStatus,
    filteredClients: clientDetails.filteredClients,
    filters: clientDetails.filters,
    currentPage: initialState.currentPage
  };
};

const onQueryDetailsStarting = (state, action) => {

  return {
    ...state,
    queryTransactionId: action.transactionId,
    detailsQueryRunning: true,
    detailsQueryFailed: false,
    selectedClientDetails: initialState.selectedClientDetails,
  };
};

const onQueryDetailsError = (state, action) => {

  if (action.transactionId !== state.queryTransactionId) {
    return state;
  }

  return {
    ...state,
    detailsQueryRunning: false,
    detailsQueryFailed: true,
    selectedClientDetails: initialState.selectedClientDetails,
  };
};

const onQueryDetailsSuccess = (state, action) => {

  if (action.transactionId !== state.queryTransactionId) {
    return state;
  }

  let clientDetails = clientService.processDetails(action.queryResults.clientDetail);

  return {
    ...state,
    detailsQueryRunning: false,
    detailsQueryFailed: false,
    selectedClientDetails: clientDetails,
  };
};

const onRefreshRelativeTime = (state, action) => {

  let updatedClients = [...state.filteredClients];
  clientService.prettifyClients(updatedClients);
  let updatedDetails = {...state.selectedClientDetails};
  if (!_.isNil(updatedDetails) && !_.isEmpty(updatedDetails)) {
    clientService.prettifyDetails(updatedDetails);
  }

  return {
    ...state,
    filteredClients: updatedClients,
    selectedClientDetails: updatedDetails
  };
};

const onCloseDetailsDialog = (state, action) => {

  return {
    ...state,
    openDetailsUI: false,
  };
};

const onShowDataSourceDetails = (state, action) => {

  let updatedClientDetails = {...state.selectedClientDetails};

  let dataSource = clientService.getDataSourceFromClientDetails(updatedClientDetails, action.dataSourceId);

  if (_.isNil(dataSource)) {
    return state;
  }

  dataSource.showDetails = true;

  return {
    ...state,
    selectedClientDetails: updatedClientDetails
  };

};

const onHideDataSourceDetails = (state, action) => {

  let updatedClientDetails = {...state.selectedClientDetails};

  let dataSource = clientService.getDataSourceFromClientDetails(updatedClientDetails, action.dataSourceId);

  if (_.isNil(dataSource)) {
    return state;
  }

  dataSource.showDetails = false;

  return {
    ...state,
    selectedClientDetails: updatedClientDetails
  };

};

const onSortDataSourceTrucks = (state, action) => {

  let updatedClientDetails = {...state.selectedClientDetails};

  let dataSource = clientService.getDataSourceFromClientDetails(updatedClientDetails, action.dataSourceId);

  if (_.isNil(dataSource)) {
    return state;
  }

  if (dataSource.trucksSortContext !== action.sortContext) {
    dataSource.trucksSortDirection = 'asc';
  } else {
    dataSource.trucksSortDirection = dataSource.trucksSortDirection === 'asc' ? 'desc' : 'asc';
  }
  dataSource.trucksSortContext = action.sortContext;

  return {
    ...state,
    selectedClientDetails: updatedClientDetails
  };
};

const onSetDataSourceTrucksItemsPerPage = (state, action) => {

  let updatedClientDetails = {...state.selectedClientDetails};

  let dataSource = clientService.getDataSourceFromClientDetails(updatedClientDetails, action.dataSourceId);

  if (_.isNil(dataSource)) {
    return state;
  }

  dataSource.trucksPerPage = action.itemsPerPage;
  dataSource.trucksCurrentPage = 0;

  return {
    ...state,
    selectedClientDetails: updatedClientDetails
  };
};

const onSetDataSourceTrucksCurrentPage = (state, action) => {

  let updatedClientDetails = {...state.selectedClientDetails};

  let dataSource = clientService.getDataSourceFromClientDetails(updatedClientDetails, action.dataSourceId);

  if (_.isNil(dataSource)) {
    return state;
  }

  dataSource.trucksCurrentPage = action.currentPage;

  return {
    ...state,
    selectedClientDetails: updatedClientDetails
  };
};

export default clientReducer