import _ from 'lodash';
import React, {Component} from 'react';
import { compose } from 'recompose';
import {connect} from 'react-redux';
import {withProps} from 'recompose';
import PropTypes from 'prop-types';

import Progress from '../../../../controls/progress';
import DataTable from '../../../../common/table/dataTable';

import ComponentTypes from '../../../../componentTypes';
import { dgbFleetMapState } from '../../../../../state/displays/fleetMap/maps/dgb/dgbFleetMapSelectors';
import * as dgbFleetMapActions from '../../../../../state/displays/fleetMap/maps/dgb/dgbFleetMapActions';
import messages from '../../../../common/messages';
import { Typography, Box } from '@mui/material';

import getDetailsPageStyles from '../../../../common/styles/detailsPageStyles';
import fleetMapStyles from '../../fleetMapStyles';
import { trackPage } from '../../../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  ...fleetMapStyles,
  tableContainer: {
    minWidth: 'values.md'
  },
  message: {
    textAlign: 'center',
    color: '#9c9c9c',
    marginTop: '145px',
    maxWidth: '900px',
  },
};

class DgbView extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.FLEET_DASHBOARD + '/' + ComponentTypes.DGB_VIEW, this.props.user);

    if (!_.isNil(this.props.fleet)) {
      this.props.queryDgbFleetMapDetails(this.props.fleet.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fleet !== this.props.fleet && !_.isNil(this.props.fleet)) {
      this.props.queryDgbFleetMapDetails(this.props.fleet.id);
    }
  }

  render() {

    let columns = [
      {type: 'data', width: 150, label: 'Unit ID', displayProperty: 'name', sortProperty: 'name'},
      {type: 'data', width: 150, label: 'Substitution (%)', displayProperty: 'gasSubstitutionDisplay', sortProperty: 'gasSubstitution'},
      {type: 'data', width: 210, label: 'Substitution Set Point (%)', displayProperty: 'gasSubstitutionSetPointDisplay', sortProperty: 'gasSubstitutionSetPoint'},
      {type: 'data', width: 185, label: 'Supply Pressure (psi)', displayProperty: 'gasSupplyPressureDisplay', sortProperty: 'gasSupplyPressure'},
      {type: 'data', width: 235, label: 'Post Regulator Pressure (psi)', displayProperty: 'gasPostFilterPressureDisplay', sortProperty: 'gasPostFilterPressure'},
      {type: 'data', width: 150, label: 'Engine Load (%)', displayProperty: 'engineLoadDisplay', sortProperty: 'engineLoad'},
      {type: 'data', width: 165, label: 'Engine Torque (%)', displayProperty: 'engineTorqueDisplay', sortProperty: 'engineTorque'},
      {type: 'data', width: 165, label: 'Engine RPM (rpm)', displayProperty: 'engineRpmDisplay', sortProperty: 'engineRpm'},
    ];

    let trucks = _.isNil(this.props.trucks) ? [] : this.props.trucks;

    let noActiveData = !_.isNil(this.props.hasActiveData) && this.props.hasActiveData === false;
    let noDgbData = !_.isNil(this.props.hasActiveData) && this.props.hasActiveData === true && trucks.length <= 0;

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.tableContainer}>
          {
            trucks.length > 0 &&
            <DataTable stateDef={this.props.stateDef} columns={columns} items={trucks} />
          }
          {
            noActiveData &&
            <Typography variant={'h4'} sx={styles.message} gutterBottom={true}>{messages.NO_DATA_FLEET_MAP}</Typography>
          }
          {
            noDgbData &&
            <Typography variant={'h4'} sx={styles.message} gutterBottom={true}>{messages.NO_DGB_DATA_FLEET_MAP}</Typography>
          }
        </Box>
        <Progress open={this.props.queryRunning}/>
      </Box>
    )

  }
}

DgbView.propTypes = {
  fleet: PropTypes.object,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MAP_DGB_VIEW : props.stateKey,
      type: ComponentTypes.FLEET_MAP_DGB_VIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dgbFleetMapState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning,
    hasActiveData: componentState.hasActiveData,
    trucks: componentState.trucks,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryDgbFleetMapDetails: (fleetId) => { dispatch(dgbFleetMapActions.queryDgbFleetMapDetails(props.stateDef, fleetId)) }
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DgbView))