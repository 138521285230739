import {iconPickerState} from "./iconPickerSelector";
import iconPickerActionTypes from "./iconPickerActionTypes";

const initialState = iconPickerState();

const iconPickerReducer = (state=initialState, action) => {
    switch(action.type) {
        case iconPickerActionTypes.ICON_PICKER_OPEN:
            return {
                ...state,
                open: true,
                iconTargetElement: action.iconTargetElement
            };
        case iconPickerActionTypes.ICON_PICKER_CLOSE:
            return {
                ...state,
                open: false,
                iconTargetElement: null
            };
        case iconPickerActionTypes.ICON_PICKER_UPDATE_FILTER:
            return {
                ...state,
                filterString: action.filterString
            };
        default:
            return state;
    };
};

export default iconPickerReducer;