import fleetComponentsActionTypes from '../fleetComponentsActionTypes';

const queryReducer = (state, action) => {

  switch (action.type) {

    case fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetComponentsActionTypes.FLEET_COMPONENTS_CARD_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    default: return state;

  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onQueryDataSuccess = (state, action) => {

  return {
    ...state,
    queryRunning: false,
    components: action.queryResults.fleetComponents,
  };
};

const  onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer