import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import { handleError } from "../../../../app/actions/appErrorActions";

import pumpOnlyFleetMapActionTypes from './pumpOnlyFleetMapActionTypes';
import * as pumpOnlyFleetMapQueries from './pumpOnlyFleetMapQueries';
import fleetMapViews from '../../display/fleetMapViews';

import errorMessages from '../../../../common/errorMessages';

const queryPumpOnlyFleetMapDetailsStarting = defaultActionFactory(pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_STARTING, 'stateDef');
const queryPumpOnlyFleetMapDetailsSuccess = defaultActionFactory(pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_SUCCESS, 'stateDef', 'queryResults', 'view');
const queryPumpOnlyFleetMapDetailsError = defaultActionFactory(pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_ERROR, 'stateDef');

const queryPumpOnlyFleetMapDetails = (stateDef, fleetId, view) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(queryPumpOnlyFleetMapDetailsStarting(stateDef));
      switch (view) {
        case fleetMapViews.OPERATION:
          queryResults = await pumpOnlyFleetMapQueries.fetchPumpOnlyFleetMapOperationDetails(fleetId);
          break;
        case fleetMapViews.COMPONENT:
          queryResults = await pumpOnlyFleetMapQueries.fetchPumpOnlyFleetMapComponentDetails(fleetId);
          break;
        case fleetMapViews.HEALTH:
          queryResults = await pumpOnlyFleetMapQueries.fetchPumpOnlyFleetMapHealthDetails(fleetId);
          break;
        default:
          throw Error('Invalid Fleet Map view provided');
      }
    } catch(e) {
      await dispatch(queryPumpOnlyFleetMapDetailsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_FLEET_MAP_DETAILS, e.message));
    }
    return dispatch(queryPumpOnlyFleetMapDetailsSuccess(stateDef, queryResults, view));
  }
};

export {
  queryPumpOnlyFleetMapDetails
}