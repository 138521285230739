import reduceReducers from 'reduce-reducers';

import clientReducer from './reducers/clientReducer';
import filterReducer from './reducers/filterReducer';
import tableReducer from './reducers/tableReducer';

import { clientStatusState } from './clientStatusSelectors';

const initialState = clientStatusState();

const clientStatusReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    clientReducer,
    filterReducer,
    tableReducer
  );

  return reducer(state, action);
};

export default clientStatusReducer