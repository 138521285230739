export default {
  JOB_STAGE_CHART_QUERY_STARTING: 'JOB_STAGE_CHART_QUERY_STARTING',
  JOB_STAGE_CHART_QUERY_SUCCESS: 'JOB_STAGE_CHART_QUERY_SUCCESS',
  JOB_STAGE_CHART_QUERY_ERROR: 'JOB_STAGE_CHART_QUERY_ERROR',

  JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_STAGE: 'JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_STAGE',
  JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_TIME_RANGE: 'JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_TIME_RANGE',
  JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_SENSORS: 'JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_SENSORS',

  JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_STAGE: 'JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_STAGE',
  JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_TIME_RANGE: 'JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_TIME_RANGE',
  JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_SENSORS: 'JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_SENSORS',

  JOB_STAGE_CHART_CLEAR_COMPARE: 'JOB_STAGE_CHART_CLEAR_COMPARE',

  JOB_STAGE_CHART_ON_ROLLOVER: 'JOB_STAGE_CHART_ON_ROLLOVER',

  JOB_STAGE_CHART_SENSOR_VISIBILITY_TOGGLE: 'JOB_STAGE_CHART_SENSOR_VISIBILITY_TOGGLE',
};