import _ from 'lodash';
import unitActivityDfpTypes from './unitActivityDfpTypes';

const fetchUnitActivityDfpCardData = (chartType) => {

  return new Promise((resolve) => {
    setTimeout(() => {

      let pumping = _.random(1, 95);
      let idle = _.random(1, 100 - pumping);
      let stopped = 100 - pumping - idle;
      let days = _.random(1, 50);

      const data = {
        pumping: pumping,
        idle: idle,
        stopped: stopped,
        days: days,
      };

      if (chartType === unitActivityDfpTypes.CURBSIDE) {
        resolve({ unitActivityCurbside: data });
      } else {
        resolve({ unitActivityRoadside: data });
      }
    }, 1000)
  });

};

export { fetchUnitActivityDfpCardData }