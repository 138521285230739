import _ from 'lodash';

import errorMessages from '../../common/errorMessages';

import { sensorSelectorState } from "./sensorSelectorSelectors";
import sensorSelectorActionTypes from "./sensorSelectorActionTypes";
import {sortSensorGroups, sortSensors, validateSensorGroupName} from "./services/sensorSelectorService";

const initialState = sensorSelectorState();

const sensorSelectorReducer = (state=initialState, action) => {
  switch(action.type) {
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_STARTING:
      return { ...state, queryRunning: true };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_ERROR:
      return { ...state, queryRunning: false };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_SUCCESS:
      return onQuerySensorsSuccess(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_STARTING:
      return { ...state, queryRunning: true };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_ERROR:
      return { ...state, queryRunning: false };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_SUCCESS:
      return onQuerySensorGroupsSuccess(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_ADD:
      return onToggleSelectSensorForAdd(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_REMOVE:
      return onToggleSelectSensorForRemove(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_GROUP_INDEX_FOR_ADD:
      return onToggleSelectGroupForAdd(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SET_SELECTED_SENSORS:
      return onSetSelectedSensors(state, action.sensors);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_TO_SELECTED_SENSORS:
      return onAddToSelectedSensors(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_GROUP_TO_SELECTED_SENSORS:
      return onAddGroupToSelectedSensors(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_REMOVE_FROM_SELECTED_SENSORS:
      return onRemoveFromSelectedSensors(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SET_TRUCKS:
      return { ...state, trucks: action.trucks };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_CLEAR_STATE:
      return onClearState(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SET_SELECTED_FORM_INDEX:
      return onSetSelectedFormIndex(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_OPEN_GROUPS_CONTEXT_MENU:
      return { ...state, openGroupsContextMenuUI: true, groupsContextMenuTargetElement: action.groupsContextMenuTargetElement, groupsContextMenuGroup: action.groupsContextMenuGroup };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_MENU:
      return { ...state, openGroupsContextMenuUI: false, groupsContextMenuTargetElement: null };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_EDIT_DIALOG:
      return {...state, shouldOpenCreateEditSensorGroup: true, isEditMode: true}
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_DELETE_CONFIRMATION:
      return { ...state, openDeleteGroupUI: true};
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_DELETE_CONFIRMATION:
      return { ...state, openDeleteGroupUI: false };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_STARTING:
      return { ...state, queryRunning: true };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_SUCCESS:
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_ERROR:
      return { ...state, queryRunning: false, openDeleteGroupUI: false };
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_OPEN_TARGET_UOM_MENU:
      return onShowTargetUomMenu(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_CLOSE_TARGET_UOM_MENU:
      return onCloseTargetUomMenu(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_UPDATE_UOM:
      return onUpdateSelectedSensorTargetUom(state, action);

    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_OPEN_CREATE_SENSOR_GROUP:
      return updateSaveSensorGroupState(state, true);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CLOSE_CREATE_SENSOR_GROUP:
      return updateSaveSensorGroupState(state, false);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_INIT_STATE_SENSOR_GROUP:
      return onSetInitSensorGroupState(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_NAME:
      return updateSensorGroupName(state, action.name);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_DESCRIPTION:
      return updateSensorGroupDescription(state, action.description);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_STARTING:
      return onSaveSensorGroupStarting(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_SUCCESS:
      return onSaveSensorGroupSuccess(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_ERROR:
      return onSaveSensorGroupError(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_REMOVE_ALL_SELECTED_SENSORS:
      return onRemoveAllSelectedSensors(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_ALL_AVAILABLE_SENSORS:
      return onAddAllAvailableSensors(state, action);
    case sensorSelectorActionTypes.SENSOR_SELECTOR_SEARCH_QUERY:
      return onUpdateSensorSearch(state, action);
    default:
      return state;
  };
};

const onToggleSelectSensorForAdd = (state, action) => {
  const hasIndex = state.selectedIndexesForAdd.findIndex(i=>i===action.index) >=0;
  return {
    ...state,
    selectedIndexesForAdd: hasIndex ?
      state.selectedIndexesForAdd.filter(index=>index!==action.index)
      : [...state.selectedIndexesForAdd, action.index]
  };
}

const onToggleSelectSensorForRemove = (state, action) => {
  const hasIndex = state.selectedIndexesForRemove.findIndex(i=>i===action.index) >=0;
  return {
    ...state,
    selectedIndexesForRemove: hasIndex ?
      state.selectedIndexesForRemove.filter(index=>index!==action.index)
      : [...state.selectedIndexesForRemove, action.index]
  };
}

const onToggleSelectGroupForAdd = (state, action) => {
  const hasIndex = state.selectedGroupIndexesForAdd.findIndex(i=>i===action.index) >=0;
  return {
    ...state,
    selectedGroupIndexesForAdd: hasIndex ?
      state.selectedGroupIndexesForAdd.filter(index=>index!==action.index)
      : [...state.selectedGroupIndexesForAdd, action.index]
  };
}

const onAddToSelectedSensors = (state, action) => {
  const sensorsToMerge = state.sensors.filter((sensor, index)=>_.includes(action.indexes, index));
  const sensorsToAdd = _.differenceBy(sensorsToMerge, state.selectedSensors, "alias");
  const newSelectedSensors = addToSelectedSensors(state.selectedSensors, sensorsToAdd);
  return {
    ...state,
    selectedIndexesForAdd: [],
    selectedSensors: newSelectedSensors,
  };
};

const onAddGroupToSelectedSensors = (state, action) => {
  const groupsToAdd = state.groups.filter((group, index) => _.includes(action.indexes, index));
  const sensorsToAdd = _.flatten(_.map(groupsToAdd, (group) => group.sensors));
  const newSelectedSensors = addToSelectedSensors(state.selectedSensors, sensorsToAdd);
  return {
    ...state,
    selectedGroupIndexesForAdd: [],
    selectedSensors: newSelectedSensors,
  };
};

/**
 * Add sensors to an existing list of sensors. Only the first of any duplicates is kept.
 * @param existingSensorList sensor collection to update
 * @param sensorsToAdd sensors to add
 * @return {*} updated sensor collection
 */
const addToSelectedSensors = (existingSensorList, sensorsToAdd) => {
  const updatedSensorList = [...existingSensorList];
  const aliases = _.map(updatedSensorList, (s) => s.alias);
  sensorsToAdd.forEach((sensor) => {
    if (!aliases.includes(sensor.alias)) {
      aliases.push(sensor.alias);
      updatedSensorList.push(sensor);
    }
  });
  return updatedSensorList;
}

const onSetSelectedSensors = (state, sensors) => {
  return {
    ...state,
    selectedSensors: _.cloneDeep(sensors),
  };
};

const onRemoveFromSelectedSensors = (state, action) => {
  const newSelectedSensors = state.selectedSensors.filter((sensor, index) => !_.includes(action.indexes, index));
  return {
    ...state,
    selectedIndexesForRemove: [],
    selectedSensors: newSelectedSensors,
  };
};

const onQuerySensorsSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    sensors: sortSensors(action.queryResults.sensorForAssets),
    allSensors: sortSensors(action.queryResults.sensorForAssets),
  };
};

const onQuerySensorGroupsSuccess = (state, action) => {
  const sensorGroups = !_.isNil(action.queryResults) ? _.cloneDeep(action.queryResults.sharedSensorGroupsForUnitType) : []; // create a clone because we modify the data
  return {
    ...state,
    queryRunning: false,
    groups: sortSensorGroups(sensorGroups),
    allGroups: sortSensorGroups(sensorGroups),
  };
};

const onSetSelectedFormIndex = (state, action) => {
  return {
    ...state,
    formIndex: action.index,
  };
};

const onClearState = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    sensors: [],
    groups: [],
    trucks: [],
    startTime: null,
    endTime: null,

    selectedSensors: [],

    selectedIndexesForAdd: [],
    selectedIndexesForRemove: [],
    selectedGroupIndexesForAdd: [],
    lastQueryKey: '',

    formIndex: _.isNil(state.formIndex) ? 0 : state.formIndex,
  };

}

const onShowTargetUomMenu = (state, action) => {
  return {
    ...state,
    showTargetUomMenu: true,
    selectedSensorMenuTargetElement: action.uomMenuTargetElement,
    selectedSensorTargetUoms: action.sensor.targetUoms,
    selectedSensorForNewTargetUom: action.sensor,
  }
}

const onCloseTargetUomMenu = (state, action) => {
  return {
    ...state,
    showTargetUomMenu: false,
  }
}

const onUpdateSelectedSensorTargetUom = (state, action) => {
  let updatedSensors = _.cloneDeep(state.selectedSensors);
  const foundSensor = _.find(updatedSensors, ['alias', action.sensor.alias]);
  if (foundSensor) {
    foundSensor.uom = action.uom;
  }
  return {
    ...state,
    selectedSensors: updatedSensors
  }
}

const updateSensorGroupName = (state, name) => {

  let existingNameOfSensorGroup = _.find(state.groups, function (group){
    return _.trim(group.name) === _.trim(name) && state.groupToSave.id !== group.id;
  });
  
  let isInvalidName = validateSensorGroupName(name);

  let groupToSave = _.cloneDeep(state.groupToSave);
  groupToSave.name = name;

  let newState = {
    ...state,
    groupToSave:groupToSave,
    hasSensorGroupNameError: isInvalidName || !_.isNil(existingNameOfSensorGroup),
    sensorGroupNameHelperText: _.isNil(existingNameOfSensorGroup)? 'Name' : errorMessages.ERROR_DUPLICATE_SENSOR_GROUP_NAME_WARNING,
    userCanSaveGroup: !isInvalidName
  };

  return newState;
};

const updateSensorGroupDescription = (state, description) => {
  let groupToSave = _.cloneDeep(state.groupToSave);
  groupToSave.description = description;
  return {
    ...state,
    groupToSave: groupToSave,
  }
};

const onSaveSensorGroupStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  }
};

const onSaveSensorGroupSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    shouldOpenCreateEditSensorGroup: false,
    userCanSaveGroup: false,
    hasSensorGroupNameError: false,
    sensorGroupNameHelperText: 'Name',
    groupToSave: { name: '', description: '' }
  }
};

const onSaveSensorGroupError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    shouldOpenCreateEditSensorGroup: false,
    userCanSaveGroup: false
  }
};

const onSetInitSensorGroupState = (state, action) => {
  return {
    ...state,
    groupToSave: action.groupToSave,
    userCanSaveGroup: true
  }
};

const updateSaveSensorGroupState = (state, enabled) => {

  const nameToValidate = _.isNil(state.groupToSave) ? '' : state.groupToSave.name;

  return {
    ...state,
    shouldOpenCreateEditSensorGroup: enabled,
    hasSensorGroupNameError: validateSensorGroupName(nameToValidate),
    sensorGroupNameHelperText: 'Name',
    userCanSaveGroup: false,
    groupToSave: { name: '', description: '' },
    isEditMode: false,
  }
};

const onRemoveAllSelectedSensors = (state, action) => {
  return {
    ...state,
    selectedSensors: [],
    selectedIndexesForRemove: [],
  }
}

const onAddAllAvailableSensors = (state, action) => {
  // Get a collection of the indexes for all sensors
  const indexesForAllAvailableSensors = _.map(_.keys(_.pickBy(state.sensors)), Number);

  // Add the collection to the action
  const actionWithIndexes = {
    ...action,
    indexes: indexesForAllAvailableSensors
  }

  // Use our existing method to add sensors 
  const postAddingSensorsState = onAddToSelectedSensors(state, actionWithIndexes);

  // Return the updated state
  return {
    ...postAddingSensorsState,
  }
}

const onUpdateSensorSearch = (state, action) => {
  // Filter out the sensors that meet the query,
  // Only include a group if it has 1+ matching sensors in it
  const normalizedSensorSearch = _.replace(action.sensorSearchQuery, /_/g, ' ').toLocaleLowerCase();

  let filteredSensorGroups = []
    _.forEach(state.allGroups, function (group) {
      const matchingSensors = group.sensors.filter((sensor) => _.replace(sensor.alias, /_/g, ' ').toLocaleLowerCase().includes(normalizedSensorSearch))
      if (matchingSensors.length) filteredSensorGroups.push({
        ...group,
        sensors: matchingSensors
      }) 
    })

  const filteredAvailableSensors = _.isNil(state?.allSensors) ? [] : state.allSensors.filter((sensor) => _.replace(sensor.alias, /_/g, ' ').toLocaleLowerCase().includes(normalizedSensorSearch))
    
  return {
    ...state,
    groups: sortSensorGroups(filteredSensorGroups),
    sensors: sortSensors(filteredAvailableSensors),
  }
}

export default sensorSelectorReducer;
