import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import displayReducer from './reducers/displayReducer';

import { fleetMapState } from './fleetMapSelectors';

const initialState = fleetMapState();

const fleetMapReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    displayReducer,
  );

  return reducer(state, action);
};

export default fleetMapReducer
