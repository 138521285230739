import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';

import AlarmInfoBadge from '../icons/alarmInfoBadge';
import { mdtPalette } from '../../common/styles/mdtPalette';

const styles = {
  smallInfoBadgeIcon: {
    color: mdtPalette().status.level3,
    fontSize: '40px'
  },
  smallInfoBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-37px',
    textAlign: 'center'
  },
  smallInfoBadge: {
    height: '40px',
  },

  mediumInfoBadgeIcon: {
    color: mdtPalette().status.level3,
    fontSize: '48px'
  },
  mediumInfoBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-46px',
    marginRight: '1px',
    textAlign: 'center'
  },
  mediumInfoBadge: {
    height: '48px',
  },

  largeInfoBadgeIcon: {
    color: mdtPalette().status.level3,
    fontSize: '64px'
  },
  largeInfoBadge: {
    height: '64px',
  },
  largeInfoBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-53px',
    textAlign: 'center'
  },
};

class InfoAlarmBadge extends Component {

  render() {
    let count = !_.isNil(this.props.count) ? this.props.count : 0;

    return (
      <Box sx={this.props.isLarge === true ? {...styles.largeInfoBadge} : this.props.isMedium === true ? {...styles.mediumInfoBadge} : {...styles.smallInfoBadge}}>
        <AlarmInfoBadge sx={this.props.isLarge === true ? {...styles.largeInfoBadgeIcon} : this.props.isMedium === true ? {...styles.mediumInfoBadgeIcon} : {...styles.smallInfoBadgeIcon}}/>
        {
          this.props.isLarge === true &&
          <Typography variant={'h5'} sx={styles.largeInfoBadgeText}>{count}</Typography>
        }
        {
          this.props.isMedium === true &&
          <Typography variant={'h6'} sx={styles.mediumInfoBadgeText}>{count}</Typography>
        }
        {
          this.props.isLarge !== true &&  this.props.isMedium !== true &&
          <Typography variant={'subtitle2'} sx={styles.smallInfoBadgeText}>{count}</Typography>
        }
      </Box>
    );
  }
}

InfoAlarmBadge.propTypes = {
  count: PropTypes.number,
  isLarge : PropTypes.bool,
  isMedium: PropTypes.bool,
};

export default InfoAlarmBadge;