
const fetchDeviceSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        allDevices: [
          {
            //device not assigned yet
            "id": 1,
            "ownerId": 3,
            "truckPid": null,
            "esn": "esn890",
            "active": true,
            "lastKnownCoord": null,
            "lastPolledTime": 1711056387
          },
          {
            //device assigned to an owner and truck
            "id": 2,
            "ownerId": 2,
            "truckPid": 123,
            "esn": "esn891",
            "active": true,
            "lastKnownCoord": {
              "latitude": 1,
              "longitude": 2
            },
            "lastPolledTime": 1711056317
          },
          {
            //device assigned to an owner and truck
            "id": 3,
            "ownerId": 1,
            "truckPid": 125,
            "esn": "esn892",
            "active": true,
            "lastKnownCoord": {
              "latitude": 1,
              "longitude": 2
            },
            "lastPolledTime": 1711056317
          }
        ]
      })
    }, 1000)
  });
};

const fetchSaveDeviceSettings = (deviceInput) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        saveDevice: {
          //device assigned to an owner and truck
          "id": 1,
          "ownerId": 12,
          "truckPid": 123,
          "esn": "esn890",
          "active": true,
          "lastKnownCoord": {
            "latitude": 1,
            "longitude": 2
          },
          "lastPolledTime": 1711056317
      }})
    }, 1000)
  });
};

const fetchTrucks = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        trucksForDevice: [
          {
            "id": 1,
            "truckName": "621303",
            "truckPid": 123,
            "fleetId": 12,
            "type": "datavan",
            "active": true
          },
          {
            "id": 2,
            "truckName": "truckName2",
            "truckPid": 124,
            "fleetId": 12,
            "type": "pump",
            "active": true
          },
          {
            "id": 3,
            "truckName": "623333",
            "truckPid": 125,
            "fleetId": 12,
            "type": "pump",
            "active": true
          }
        ]
      })
    }, 1000)
  });
};

export {
  fetchDeviceSettings,
  fetchSaveDeviceSettings,
  fetchTrucks
}