import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import fleetPRCHistoryActionTypes from './fleetPRCHistoryActionTypes';

import fetchfleetPRCHistoryCardData from './fleetPRCHistoryQueries';
import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_STARTING,
  fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_SUCCESS,
  fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEET_PRC_HISTORY_CARD_DATA,
  fetchfleetPRCHistoryCardData
);

export {
  queryData,
  clearData
}