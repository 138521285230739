import _ from 'lodash';

const expectedLifeState = state => {

  return {
    expectedLife: 
    {
      expectedLifeUnitTypes: _.isNil(state) ? [] : state.expectedLife.expectedLifeUnitTypes,
      expectedLifeSelectedUnitType: _.isNil(state) ? null : state.expectedLife.expectedLifeSelectedUnitType,
      expectedLifeComponentTypes: _.isNil(state) ? [] : state.expectedLife.expectedLifeComponentTypes,
      expectedLifeSelectedComponentTypes: _.isNil(state) ? [] : state.expectedLife.expectedLifeSelectedComponentTypes,
      expectedLifeCanSave: _.isNil(state) ? false : state.expectedLife.expectedLifeCanSave,
    }
  };

};

export {
  expectedLifeState
};