import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null,
    series4: null
  };

  const oilPressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.OIL_PRESSURE]);
  transformedSeries.series1 = unitChartService.transformSeries(oilPressureSeries, 1);

  const engineTempSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.ENGINE_TEMP]);
  transformedSeries.series2 = unitChartService.transformSeries(engineTempSeries, 1);

  const engineRpmSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.ENGINE_RPM]);
  transformedSeries.series3 = unitChartService.transformSeries(engineRpmSeries);

  const enginePressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.ENGINE_FUEL_PRESSURE]);
  transformedSeries.series4 = unitChartService.transformSeries(enginePressureSeries, 1);

  return transformedSeries;
};


export {
  transformSeries,
}
