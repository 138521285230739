import _ from "lodash";
import * as assetTypeService from '../../../common/services/assetTypeService';

const processExpectedLifeSettings = (settings) => {

  let unitTypes = parseUnitTypes(_.isNil(settings) ? null : settings.componentTypes);
  let componentsTypes = prettifyComponentTypes(_.isNil(settings) ? null : settings.componentTypes);

  return {
    unitTypes: unitTypes,
    componentTypes: componentsTypes,
  }

};

const parseUnitTypes = (componentTypes) => {

  let unitTypes = [];

  if (!_.isEmpty(componentTypes)) {
    unitTypes = _.uniqBy(componentTypes, 'unitType');
    unitTypes = _.orderBy(unitTypes, 'unitType').map(componentType => {
     return {
       unitType: componentType.unitType,
       value: assetTypeService.getAssetNameByType(componentType.unitType),
       label: assetTypeService.getAssetNameByType(componentType.unitType),
     }
    });
  }

  return unitTypes;

};

const prettifyComponentTypes = (componentTypes) => {

  let results = [];

  if (!_.isEmpty(componentTypes)) {
    results = _.map(componentTypes, componentType => {
      return {
        ...componentType,
        value: componentType.displayName,
        label: componentType.name,
      }
    });
    results = _.orderBy(results, 'label');
  }

  return results;
};

const resolveSelectedUnitType = (unitTypes, unitType) => {

  let selectedUnitType = null;

  // Check if the  unit type exists
  if (!_.isNil(unitType) && !_.isEmpty(unitTypes)) {
    let match = _.find(unitTypes, { 'unitType': unitType.unitType });
    selectedUnitType = _.isNil(match) ? null : match;
  }

  // If we have not found one yet, set it to the first unit type
  if (_.isNil(selectedUnitType) && !_.isEmpty(unitTypes)) {
    selectedUnitType = unitTypes[0];
  }

  return selectedUnitType;
};

const getSelectedComponentTypes = (componentTypes, unitType) => {

  let selectedComponentTypes = [];

  if (!_.isNil(unitType) && !_.isEmpty(componentTypes)) {
    selectedComponentTypes = _.filter(componentTypes, ['unitType', unitType]);
    selectedComponentTypes = _.orderBy(selectedComponentTypes, 'value').map(componentType => {
      return {
        ...componentType,
        hasError: false,
      }
    });
  }

  return selectedComponentTypes;
};

const updateSelectedComponentTypes = (componentTypes, componentTypeId, expectedLife) => {

  let updatedComponentTypes = _.cloneDeep(componentTypes);

  let componentType = _.find(updatedComponentTypes, {'id': componentTypeId});
  if (!_.isNil(componentType)) {
    componentType.expectedLife = expectedLife;
    componentType.hasError = !isExpectedLifeValid(expectedLife);
  }

  return updatedComponentTypes;
};

const isExpectedLifeValid = (expectedLife) => {
  return (!_.isNil(expectedLife) && _.isInteger(expectedLife) &&  expectedLife <= 100000 && expectedLife >= 0)
};

const canSave = (state) => {

  // Check if we have any errors

  if (!validateSelectedComponentTypes(state.expectedLifeSelectedComponentTypes)) {
    return false;
  }

  // Check if there are any changes

  return hasChanges(state.expectedLifeSelectedComponentTypes, state.expectedLifeComponentTypes);
};

const validateSelectedComponentTypes = (componentTypes) => {

  // The component types are valid if none of them has an error
  return !_.isEmpty(componentTypes) && _.isNil(_.find(componentTypes, {'hasError': true}));
};

const hasChanges = (componentTypes, originalComponentTypes) => {

  // Check if the current expected life values differ from the original

  if (!_.isEmpty(componentTypes) && !_.isEmpty(originalComponentTypes)) {
    for (let i=0; i<componentTypes.length; i++) {
      let current = componentTypes[i];
      let original = _.find(originalComponentTypes, {'id': current.id});
      if (current.expectedLife !== original.expectedLife) {
        return true;
      }
    }
  }

  return false;
};

export {
  processExpectedLifeSettings,
  parseUnitTypes,
  prettifyComponentTypes,
  resolveSelectedUnitType,
  getSelectedComponentTypes,
  updateSelectedComponentTypes,
  isExpectedLifeValid,
  canSave,
  validateSelectedComponentTypes,
  hasChanges,
}