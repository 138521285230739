import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InputAdornment, Typography, TextField, Button, Box, FormControlLabel, Switch, IconButton, Tooltip } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Calculator from '../../controls/icons/calculator';
import CalculatorDialog from './componentCalculatorDialog';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';
import { componentsState } from '../../../state/displays/componentsDisplay/componentsSelectors';
import ComponentTypes from '../../componentTypes';

const actionCommonStyle = {
  backgroundColor:'grey.200',
  color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
  '&:hover': {
    backgroundColor:'grey.400',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
  }
}

const styles = {
  formContainer: {
    display: 'flex',
    width: '800px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '620px',
    padding: 3
  },
  formRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  formActionRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: 3,
    justifyContent: 'space-between'
  },
  formActionGroup: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  textControl: {
    width: '260px',
    marginTop: 2
  },
  dateTimeControl: {
    paddingTop: '40px',
  },
  rightSideAction: {
    marginLeft: 3
  },
  leftSideAction: {
    ...actionCommonStyle,
    marginRight: 3,
  },
  engineHoursContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '260px',
  },
  engineHoursTextInput: {
    width: '120px',
  },
  ecmResetInput: {
    marginRight: '0px'
  }
};

class ComponentForm extends Component {

  render() {

    const onSaveComponent = () => {
      this.props.saveComponent(
        this.props.editMode ? this.props.selectedComponentId : null,
        this.props.selectedUnit.id,
        this.props.selectedComponentType.name,
        this.props.selectedModel,
        this.props.selectedSerialNumber,
        this.props.selectedRefNumber,
        this.props.selectedEffectiveDate.unix(),
        this.props.selectedUnitOffset,
        this.props.canEditSnapshotStart === true ? this.props.selectedSnapshotStart : null
      );
    };

    let disableForm = this.props.editMode && _.isNil(this.props.selectedComponent);

    // Always disable the form if we have no unit or component type, regardless of edit/add mode.
    disableForm = _.isNil(this.props.selectedUnit) || _.isNil(this.props.selectedComponentType) ? true : disableForm;

    // We set the minimum effective date to be the effective date of the current component. For error conditions
    // set it to 2015-01-01T00:00:00Z (yes this is arbitrary, but we shouldn't have any components before that)
    let minimumEffectiveDate = _.isNil(this.props.selectedComponent) || _.isNil(this.props.selectedComponent.effectiveDateMoment) ? moment.unix(1420070400) : this.props.selectedComponent.effectiveDateMoment;

    let isEngineComponent = !_.isNil(this.props.selectedComponentType) && this.props.selectedComponentType.kind === 'Engine';
    let isTransmissionComponent = !_.isNil(this.props.selectedComponentType) && this.props.selectedComponentType.kind === 'Transmission';

    // Adjust the label of the save button accordingly
    let saveLabel = 'Save';
    saveLabel = this.props.editMode === false && !_.isNil(this.props.selectedComponent) ? 'Replace' : saveLabel;
    saveLabel = this.props.editMode === false && _.isNil(this.props.selectedComponent) ? 'Add' : saveLabel;

    let missingSnapshotLabel = _.isNil(this.props.selectedComponent) ? '' : 'Not Available';

    // The calculator to estimate pump hours will be visible/hidden by proxy based on the state telling us
    // if we can edit the snap shot values. There is an extra condition however where we want to hide the
    // calculator even if we can edit the snap shot value. If there is no earliest data date associated with
    // the selected component type then we will not be able to calculate the estimated pump hours so there is
    // no point showing the calculator.
    let showCalculator = this.props.canEditSnapshotStart === true && !_.isNil(this.props.selectedComponentType) && !_.isNil(this.props.selectedComponentType.earliestDataMoment);

    return (
      <Box sx={styles.formContainer}>
        <Box sx={styles.form}>
          <Box sx={styles.formRow}>
            {/* Effective Date */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* We are implementing MobileDateTimePicker here directly instead of using
                  DateTimePickerMDT because we need to customize the renderInput. */}
              <MobileDateTimePicker
                sx={styles.dateTimeControl}
                inputFormat={"MMM DD yyyy hh:mm a"}
                showToolbar={true}
                showTodayButton={true}
                toolbarTitle={''}
                disableFuture={true}
                disableCloseOnSelect={true}
                onChange={(value) => {
                  if (!_.isNil(value)) {
                    this.props.setSelectedEffectiveDateDisplay(value);
                  }     
                }}
                value={this.props.selectedEffectiveDateDisplay}
                onAccept={(value) => {
                  if (!_.isNil(value)) {
                    this.props.setSelectedEffectiveDate(value);
                  }
                }}
                disabled={disableForm || this.props.editMode === true}
                minDate={minimumEffectiveDate}
                renderInput={(params) => <TextField {...params} sx={styles.textControl} variant='standard' helperText='Install Date' />}
              />
            </LocalizationProvider>
            {/* Model */}
            <TextField 
              sx={styles.textControl}
              type='text'
              helperText='Model'
              disabled={disableForm}
              value={this.props.selectedModel}
              onChange={event => this.props.setSelectedModel(event.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              inputProps={{
                maxLength:20
              }}
              variant='standard'
            />
          </Box>
          <Box sx={styles.formRow}>
            {/* Reference Number */}
            <TextField 
              sx={styles.textControl}
              type='text'
              helperText='Ref #'
              disabled={disableForm}
              value={this.props.selectedRefNumber}
              onChange={event => this.props.setSelectedRefNumber(event.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              inputProps={{
                maxLength:20
              }}
              variant='standard'
            />
            {/* Serial Number */}
            <TextField
              sx={styles.textControl}
              type='text'
              helperText='Serial #'
              disabled={disableForm}
              value={this.props.selectedSerialNumber}
              onChange={event => this.props.setSelectedSerialNumber(event.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              inputProps={{
                maxLength:20
              }}
              variant='standard'
            />
          </Box>
          {/* When editing a component display the snapshot value (readonly) and the offset */}
          {
            this.props.editMode === true &&
            <Box sx={styles.formRow}>
              <TextField
                sx={styles.textControl}
                type='text'
                helperText={'Hours at install date'}
                disabled={true}
                value={_.isNil(this.props.selectedSnapshotStart) ? missingSnapshotLabel : this.props.selectedSnapshotStart}
                variant='standard'
              />
              <TextField
                sx={styles.textControl}
                type='number'
                helperText='Unit Offset'
                disabled={disableForm}
                value={this.props.selectedUnitOffset}
                onChange={event => this.props.setSelectedUnitOffset(event.target.value)}
                error={!disableForm && this.props.hasErrorUnitOffset}
                onFocus={(e) => e.currentTarget.select()}
                InputProps={{
                  inputProps: {
                    max: 50000,
                    min: -50000
                  }
                }}
                variant='standard'
              />
            </Box>
          }
          {/* When adding/replacing an engine component, display the engine hours value and ECM reset switch */}
          {
            this.props.editMode === false &&
            this.props.canEditSnapshotStart &&
            isEngineComponent === true &&
            <Box sx={styles.formRow}>
              <TextField
                sx={styles.textControl}
                type='number'
                helperText='Estimated Hours at install date'
                disabled={this.props.selectedECMReset === true}
                value={this.props.selectedSnapshotStart}
                onChange={event => this.props.setSelectedSnapshotStart(event.target.value)}
                error={!disableForm && this.props.hasErrorSnapshotStart}
                onFocus={(e) => e.currentTarget.select()}
                InputProps={{
                  inputProps: {
                    max: 50000,
                    min: 0
                  }
                }}
                variant='standard'
              />
              <FormControlLabel
                sx={styles.textControl}
                label={<Typography variant='body2'>Was the ECM Reset?</Typography>}
                control={
                  <Switch color='primary' checked={this.props.selectedECMReset} />
                }
                onChange={(event) => this.props.setSelectedEcmReset(event.target.checked)}
              />
            </Box>
          }
          {/* When adding/replacing a component that is NOT an engine or transmission, the pump hours value with calculator */}
          {
            this.props.editMode === false &&
            this.props.canEditSnapshotStart &&
            isEngineComponent === false &&
            isTransmissionComponent === false &&
            <Box sx={styles.formRow}>
              <TextField
                sx={styles.textControl}
                type='number'
                helperText='Estimated Hours at install date'
                value={this.props.selectedSnapshotStart}
                onChange={event => this.props.setSelectedSnapshotStart(event.target.value)}
                error={!disableForm && this.props.hasErrorSnapshotStart}
                onFocus={(e) => e.currentTarget.select()}
                InputProps={{
                  endAdornment: (
                    showCalculator &&
                    <InputAdornment position='end'>
                      <Tooltip title='Calculate Estimated Hours' placement='right'>
                        <IconButton onClick={() => this.props.openCalculator()} size="large">
                          <Calculator/>
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  inputProps: {
                    max: 50000,
                    min: 0
                  }
                }}
                variant='standard'
              />
            </Box>
          }
          {/* Render the action buttons */}
          <Box sx={styles.formActionRow}>
            <Box sx={styles.formActionGroup}>
              {
                this.props.editMode === true &&
                <Button sx={styles.leftSideAction}
                  variant='contained'
                  disabled={!this.props.canDelete}
                  onClick={() => this.props.openDeleteConfirmation()}>
                  Delete
                </Button>
              }
            </Box>
            <Box sx={styles.formActionGroup}>
              <Button
                sx={{...styles.rightSideAction, ...actionCommonStyle}}
                variant='contained'
                disabled={disableForm || !this.props.canCancel}
                onClick={() => this.props.revertForm()}>
                Discard Changes
              </Button>
              <Button
                sx={styles.rightSideAction}
                variant='contained'
                color='primary'
                disabled={disableForm || (this.props.editMode === true ? !this.props.canSave : !this.props.canAdd)}
                onClick={() => onSaveComponent()}>
                {saveLabel}
              </Button>
            </Box>
          </Box>
        </Box>
        <CalculatorDialog />
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENTS : props.stateKey,
      type: ComponentTypes.COMPONENTS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentsState(state[stateDef.key]);
  return {
    selectedUnit: componentState.selectedUnit,
    selectedComponentType: componentState.selectedComponentType,
    editMode: componentState.editMode,
    canSave: componentState.canSave,
    canAdd: componentState.canAdd,
    canDelete: componentState.canDelete,
    canCancel: componentState.canCancel,
    canEditSnapshotStart: componentState.canEditSnapshotStart,
    hasErrorUnitOffset: componentState.hasErrorUnitOffset,
    hasErrorSnapshotStart: componentState.hasErrorSnapshotStart,
    selectedComponent: componentState.selectedComponent,
    selectedComponentId: componentState.selectedComponentId,
    selectedEffectiveDate: componentState.selectedEffectiveDate,
    selectedModel: componentState.selectedModel,
    selectedSerialNumber: componentState.selectedSerialNumber,
    selectedRefNumber: componentState.selectedRefNumber,
    selectedUnitOffset: componentState.selectedUnitOffset,
    selectedSnapshotStart: componentState.selectedSnapshotStart,
    selectedECMReset: componentState.selectedECMReset,
    selectedEffectiveDateDisplay: componentState.selectedEffectiveDateDisplay
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setSelectedEffectiveDate: (dateTime) => { dispatch(componentsActions.setSelectedEffectiveDate(props.stateDef, dateTime)) },
    setSelectedModel: (model) => { dispatch(componentsActions.setSelectedModel(props.stateDef, model)) },
    setSelectedSerialNumber: (serialNumber) => { dispatch(componentsActions.setSelectedSerialNumber(props.stateDef, serialNumber)) },
    setSelectedRefNumber: (refNumber) => { dispatch(componentsActions.setSelectedRefNumber(props.stateDef, refNumber)) },
    setSelectedUnitOffset: (offset) => { dispatch(componentsActions.setSelectedUnitOffset(props.stateDef, offset)) },
    setSelectedSnapshotStart: (snapshot) => { dispatch(componentsActions.setSelectedSnapshotStart(props.stateDef, snapshot)) },
    setSelectedEcmReset: (reset) => { dispatch(componentsActions.setSelectedEcmReset(props.stateDef, reset)) },
    revertForm: () => { dispatch(componentsActions.revertForm(props.stateDef)) },
    saveComponent: (componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart) => {
      dispatch(componentsActions.saveComponent(props.stateDef, componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart))
    },
    openDeleteConfirmation: () => { dispatch(componentsActions.openDeleteConfirmation(props.stateDef)) },
    openCalculator: () => { dispatch(componentsActions.openCalculator(props.stateDef)) },
    setSelectedEffectiveDateDisplay: (dateTime) => { dispatch(componentsActions.setSelectedEffectiveDateDisplay(props.stateDef, dateTime)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ComponentForm))