/** @jsxImportSource @emotion/react */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { detect } from 'detect-browser';
import { Typography, Box, IconButton, Menu, Toolbar, Grid, Snackbar, AppBar, Drawer, Hidden, Button, FormControlLabel, Checkbox, TextField, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';

import Highcharts from 'highcharts'
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsMore from 'highcharts/highcharts-more';

import { isAuthenticated, logout } from '../../helpers/authHelper';
import { renderRouter } from '../../helpers/routeHelper';
import { renderNavMenu } from '../../helpers/navHelper';
import * as errorActions from '../../state/app/actions/appErrorActions';
import * as navActions from '../../state/app/actions/appNavActions';
import * as userActions from '../../state/app/actions/appUserActions';
import { appState } from '../../state/app/appSelectors';
import WhatsNewDialog from './whatsNewDialog';
import { AutoCompleteMDT } from '../controls/mdtMuiControls';
import Progress from '../controls/progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell }  from '@fortawesome/free-regular-svg-icons'
import _ from "lodash";
import * as contextActions from '../../state/app/actions/appContextActions';

import { renderContextContent } from '../../helpers/contextHelper';
import * as webSocketActions  from '../../state/app/actions/appWebsocketActions';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width='xs' />;

// These Highchart components are here because we can only have one instance of them per application.
// Adding them to individual displays or cards will cause conflicts.
HighchartsBoost(Highcharts);
HighchartsNoData(Highcharts);
HighchartsMore(Highcharts);

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1
  },
  userMenu: {
    width: '300px',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    outline: 'none !important'
  },
  userMenuActions: {
    height: '100%'
  },
  snackbar: {
    marginBottom: '20px'
  },
  snackbarClose: {
    width: 4,
    height: 4,
  },
  menuButton: {
    marginLeft: '-12px',
    marginRight: '20px',
  },
  drawer: {
    width: '270px',
    marginTop: {
      xs: '0px',
      sm: '64px'
    },
  },
  userIcon: {
    fontSize: '40px'
  },
  appBarAutoComplete: {
    width: '200px',
    paddingLeft: '10px',
    backgroundColor: 'primary.main',
    borderBottomWidth: '0px',
  },
  drawerAutoComplete: {
    width: '100%',
    paddingLeft: '16px',
  },
  drawerOwnerMenu: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  drawerTitleBar: {
    height: '64px',
    paddingLeft: '16px',
    paddingRight: '8px',
  },
  appBarToolBar: {
    height: '64px',
  },
  drawerGroup: {
    paddingLeft: '16px',
    paddingRight: '8px',
    paddingTop: '8px',
  },
  drawerGroupItem: {
    fontWeight: 300,
    fontSize: '0.80rem'
  },
  menuItem: {
    height: '48px',
    minWidth: '56px',
    "& .pin-icon": {
      display: "none"
    },
    '&:hover .pin-icon': {
      display: 'flex',
    },

  },
  menuItemNotFoundTitle: {
    fontWeight: 300,
    fontSize: '12px'

  },
  menuItemNotFoundBody: {
    fontWeight: 300,
    padding: '0 15px 10px 15px',
    fontSize: '10px'

  },
  drawerMenuList: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  drawerShowContentItem: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  whatsNewButtonContainer: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  whatsNewButton: {
    marginRight: 3,
  },
  whatsNewIcon: {
    fontSize: '24px'
  },
};

const contextDrawerStyles = (props) => {

  let contextDrawerMinimized = props.contextDrawerMinimized;

  return {
    contextDrawer: {
      marginTop: {
        xs: '0px',
        sm: '64px'
      },
      width: (theme) => (contextDrawerMinimized ? `calc(${theme.spacing(7)} + 1px)` : '240px'),
    },
    contextDrawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      padding: (theme) => theme.spacing(1,1),
    }
  }
}

const initializeWebsocketPing = (sendMessageCallback, clientId, saveWebsocketIntervalIdCallback) => {

  // Connection is kept alive by sending a ping message every 30 seconds, otherwise
  // it will expire in 60 seconds
  const interval = setInterval((sendMessageCallback, clientId) => {
    const ping = {
      clientId: clientId,
      type: 'ping',
      data: []
    }
    sendMessageCallback(JSON.stringify(ping));
  }, 30000, sendMessageCallback, clientId);

  // This lets us know the interval id so we can clear it when the browser is closed
  saveWebsocketIntervalIdCallback(interval);
}



class Layout extends React.Component {

  componentDidMount() {
    window.addEventListener('resize', this.onResize.bind(this));
    initializeWebsocketPing(this.props.sendWebSocketMessage, this.props.clientId, this.props.saveIntervalId);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
    clearInterval(this.props.intervalId);
  }

  onResize() {

    // If either the user menu or drawer is open while resizing the
    // browser window. Close them.

    if (this.props.drawerOpen) {
      this.props.onCloseDrawer();
    }

    if (this.props.userMenuOpen) {
      this.props.onCloseUserMenu();
    }
  }

  isSupportedBrowser() {
    const browser = detect();
    switch (browser && browser.name) {
      case 'ie':
        return false;
      default:
        return true;
    }
  }

  render() {
    const { userInfoLoaded } = this.props;

    const supportedBrowser = this.isSupportedBrowser();
    const userAuthenticated = isAuthenticated();

    if (!userAuthenticated && userInfoLoaded) {

      // IMPORTANT - Always try to avoid side-effects in the render method. It
      // should be a pure function. This is an exception for the scenario where
      // we are not authenticated, but the user information is still loaded in
      // the application state.

      // When the authentication details expire, or the user logs out, they will
      // be removed from the local storage, however the user information will
      // remain in the application state. We will detect this here and trigger
      // an action to clear the state.

      // And close the websocket connection if it is open
      this.props.closeWebSocket();

      this.props.clearUserInformation();
    }

    const hideError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.props.onHideError()
    };

    return (
        <Box sx={styles.root}>
          <Grid container>
            <Grid item xs={12}>
              <AppBar enableColorOnDark>
                <Toolbar sx={styles.appBarToolBar}>
                  <div data-test='navButton'>
                    <IconButton sx={styles.menuButton}
                                color='inherit'
                                aria-label='Menu'
                                onClick={(event) => {
                                  this.props.onOpenDrawer();
                                }}
                                size='large'
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                  <Typography variant='h6' color='inherit' sx={{ width: '200px' }}>
                    MDT Cloud Portal
                  </Typography>
                  <Hidden only={'xs'}>
                    {this.props.ownersVisible && userInfoLoaded && <DomainIcon />}
                    {this.props.ownersVisible && userInfoLoaded && (
                        <div>
                          <AutoCompleteMDT
                              sx={styles.appBarAutoComplete}
                              getOptionLabel={(owner) => owner.label}
                              options={this.props.owners}
                              value={this.props.selectedOwner}
                              onChange={(event, value, reason) => {
                                this.props.setOwner(value);
                              }}
                              noOptionsText={'No owners found...'}
                          />
                        </div>
                    )}
                  </Hidden>
                  <Typography sx={styles.flex}/>
                  <Tooltip title={ this.props.connectedLive ? 'Live Data Connection Established' : 'No Live Data Connection. Click to Connect.' } sx={{marginRight: 3}}>
                    {
                      this.props.connectedLive ? 
                        <SignalCellular4BarIcon /> : 
                        <IconButton
                          onClick={(event) => { this.props.connectWebSocket(); }}>
                          <SignalCellularOffIcon />
                        </IconButton>
                    }
                  </Tooltip>
                  <IconButton
                      sx={styles.whatsNewButton}
                      onClick={(event) => {
                        this.props.onShowWhatsNew(true);
                      }}
                      size='large'
                  >
                    <FontAwesomeIcon icon={faBell} shake={false} />
                  </IconButton>
                  <Hidden only={['xs', 'sm']}>
                    {this.props.hasViewPreReleaseContentPermission === true && (
                        <FormControlLabel
                            control={
                              <Checkbox
                                  color={'default'}
                                  checked={this.props.showPreReleaseContent}
                                  onChange={(event, checked) => {
                                    this.props.onShowPreReleaseContent(checked);
                                  }}
                              />
                            }
                            label={
                              <Typography variant={'body2'}>
                                Show Pre-Release Content
                              </Typography>
                            }
                        />
                    )}
                  </Hidden>
                  <Hidden only={'xs'}>
                    {userAuthenticated && (
                        <IconButton
                            aria-owns={
                              this.props.userMenuTargetElement ? 'layout-menu' : null
                            }
                            aria-haspopup='true'
                            onClick={(event) => {
                              this.props.onOpenUserMenu(event.currentTarget);
                            }}
                            size='large'
                        >
                          <AccountCircleIcon sx={styles.userIcon} />
                        </IconButton>
                    )}
                  </Hidden>
                </Toolbar>
                <Menu
                    id='layout-menu'
                    anchorEl={this.props.userMenuTargetElement}
                    open={this.props.userMenuOpen}
                    onClose={() => this.props.onCloseUserMenu()}
                >
                  <Box sx={styles.userMenu}
                       tabIndex={0}
                       role='button'
                  >
                    <Grid container direction={'column'}>
                      <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
                          <Typography variant={'subtitle1'}>
                            {this.props.userName}
                          </Typography>
                          <Typography variant={'body2'}>{this.props.userEmail}</Typography>
                        </Box>
                        <Tooltip title={'Close'} followCursor={true}>
                          <IconButton sx={{height: '30px'}} size='small'
                                      onClick={() => this.props.onCloseUserMenu()}>
                            <CloseIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {this.props.hasViewDevelopmentContentPermission === true && (
                          <FormControlLabel
                              control={
                                <Checkbox
                                    color={'default'}
                                    checked={this.props.showDevelopmentContent}
                                    onChange={(event, checked) => {
                                      this.props.onShowDevelopmentContent(checked);
                                      this.props.onCloseUserMenu();
                                    }}
                                />
                              }
                              label={
                                <Typography variant={'body2'}>
                                  Show Development Content
                                </Typography>
                              }
                          />
                      )}
                      <Grid sx={styles.userMenuActions}
                            container
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                            direction={'column'}
                      >
                        <AutoCompleteMDT
                            sx={{marginTop: 2, marginBottom: 2, width: '100%'}}
                            getOptionLabel={(page) => page.label}
                            options={this.props.availableDisplays}
                            value={this.props.userProfileSettings.landingPage}
                            onChange={(event, value, reason) => {
                              this.props.onSetLandingPage(value);
                              this.props.onCloseUserMenu();
                            }}
                            noOptionsText={'No pages found...'}
                            renderInput={(params) => <TextField {...params} label="Landing Page" />}
                        />
                        <Button
                            sx={{marginBottom: 1}}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                              this.props.onCloseUserMenu();
                              logout();
                            }}
                        >
                          Sign Out
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Menu>
              </AppBar>
            </Grid>
            <Grid item xs={12}>
              {renderRouter(
                  supportedBrowser,
                  userAuthenticated,
                  userInfoLoaded,
                  this.props.userRoutes,
                  this.props.defaultRoute,
              )}
            </Grid>
          </Grid>
          <Drawer
              PaperProps={{
                sx: styles.drawer
              }}
              open={this.props.drawerOpen}
              onClose={() => this.props.onCloseDrawer()}
          >
            <div tabIndex={0} role='button'>
              {renderNavMenu(this.props, styles)}
            </div>
          </Drawer>
          <Drawer
              PaperProps={{
                sx: 
                { 
                  '&.MuiDrawer-paper': {
                    marginTop: {
                      xs: '0px',
                      sm: '64px'
                    },
                    overflow: 'hidden',
                    width: (theme) => (this.props.contextDrawerMinimized ? `calc(${theme.spacing(7)} + 1px)` : this.props.contextDrawerWidth),
                    // Give the Context Drawer some properties of glass, so the user can see cards that move behind it
                    background: 'rgba(48,48,48,0.7)',
                    '&:before': {
                      backdropFilter: 'blur(5px)',
                      zIndex: -1,
                      position: 'absolute',
                      content: '""',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }
                  }
                }
              }}
              variant='persistent'
              open={this.props.contextDrawerOpen}
              anchor='right'
          >
            <Box sx={contextDrawerStyles(this.props).contextDrawerHeader}>
              <IconButton disableRipple onClick={() => { this.props.contextDrawerMinimized ? (this.props.onOpenContextDrawer(true, this.props.contextDrawerWidth)) : this.props.onMinimizeContextDrawer();}}>
                { this.props.contextDrawerMinimized ? <ChevronLeftIcon/> : <ChevronRightIcon /> }
              </IconButton>
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, height: 'calc(100vh - 64px)'}}>
              { this.props.contextDrawerMinimized ?
                // Make the minimized area clickable so the user can expand it easily         
                <Box sx={{ height: '100%', cursor: 'pointer'}} onClick={() => { this.props.onOpenContextDrawer(true, this.props.contextDrawerWidth) }} /> :
                renderContextContent(this.props.contextType, this.props.contextData)
              }
            </Box>
          </Drawer>
          <Snackbar sx={styles.snackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.props.openErrorUI}
                    autoHideDuration={6000}
                    onClose={hideError}
                    message={this.props.displayedError}
                    action={[
                      <IconButton sx={styles.snackbarClose}
                                  key='close'
                                  color='inherit'
                                  onClick={() => this.props.onHideError()}
                                  size='large'
                      >
                        <CloseIcon />
                      </IconButton>,
                    ]}
                    TransitionProps={{
                      onEntered: () => this.props.onErrorUIOpened(),
                      onExited: () => this.props.onErrorUIClosed(),
                    }}
          />
          <WhatsNewDialog />
          <Progress open={this.props.queryRunning}/>
        </Box>
    );
  }
}

Layout.propTypes = {
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  let componentState = appState(state);
  return {
    ownersVisible: componentState.user.ownersVisible,
    owners: componentState.owners,
    selectedOwner: componentState.selectedOwner,
    drawerOpen: componentState.navigation.drawerOpen,
    userMenuOpen: componentState.navigation.userMenuOpen,
    userMenuTargetElement: componentState.navigation.userMenuTargetElement,
    openErrorUI: componentState.errorHandling.openErrorUI,
    displayedError: componentState.errorHandling.displayedError,
    userInfoLoaded: componentState.user.loaded,
    defaultRoute: componentState.user.defaultRoute,
    userRoutes: componentState.user.routes,
    userName: componentState.user.name,
    userEmail: componentState.user.email,
    hasViewDevelopmentContentPermission: componentState.user.hasViewDevelopmentContentPermission,
    hasViewPreReleaseContentPermission: componentState.user.hasViewPreReleaseContentPermission,
    showDevelopmentContent: componentState.user.showDevelopmentContent,
    showPreReleaseContent: componentState.user.showPreReleaseContent,
    showWhatsNew: componentState.showWhatsNew,
    availableDisplays: componentState.user.availableDisplays,
    queryRunning: componentState.queryRunning,
    userProfileSettings: componentState.user.profileSettings,
    contextDrawerOpen: componentState.context.contextDrawerOpen,
    contextType: componentState.context.contextType,
    contextDrawerMinimized: componentState.context.contextDrawerMinimized,
    contextDrawerWidth: componentState.context.contextDrawerWidth,
    contextData: componentState.context.contextData,
    contextStateDef: componentState.context.contextStateDef,
    connectedLive: componentState.live.connected,
    clientId: componentState.live.clientId,
    intervalId: componentState.live.intervalId,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenDrawer: () => dispatch(navActions.openDrawer()),
    onCloseDrawer: () => dispatch(navActions.closeDrawer()),
    setOwner: (owner) => dispatch(userActions.setOwnerContext(owner)),
    onOpenUserMenu: (userMenuTargetElement) => dispatch(userActions.openUserMenu(userMenuTargetElement)),
    onCloseUserMenu: () => dispatch(userActions.closeUserMenu()),
    onHideError: () => dispatch(errorActions.hideError()),
    onErrorUIOpened: () => dispatch(errorActions.errorUIOpened()),
    onErrorUIClosed: () => dispatch(errorActions.errorUIClosed()),
    clearUserInformation: () => dispatch(userActions.clearUserInformation()),
    navigate: (url) => dispatch(navActions.navigate(url)),
    onShowDevelopmentContent: (show) => dispatch(userActions.showDevelopmentContent(show)),
    onShowPreReleaseContent: (show) => dispatch(userActions.showPreReleaseContent(show)),
    onSetLandingPage: (page) => dispatch(userActions.setLandingPage(page)),
    onShowWhatsNew: (show) => dispatch(userActions.onShowWhatsNew(show)),
    onUpdateUserRoutes: (groupName, expand) => dispatch(userActions.updateUserRoutes(groupName, expand)),
    onUpdateUserConfigForMenu: (config) => dispatch(userActions.updateUserConfigForMenu(config)),
    onOpenContextDrawer: (open, width) => dispatch(contextActions.openContextDrawer(open, width)),
    onMinimizeContextDrawer: () => dispatch(contextActions.minimizeContextDrawer()),
    closeWebSocket: () => dispatch(webSocketActions.closeWebSocket()),
    connectWebSocket: () => dispatch(webSocketActions.connectWebSocket()),
    saveIntervalId: (id) => dispatch(webSocketActions.saveWebsocketIntervalId(id)),
    sendWebSocketMessage: (payload) => dispatch(webSocketActions.sendMessage(payload)),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(Layout)));

