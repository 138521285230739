import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../../../common/factories/queryActionCustomFactory';

import dgbFleetMapActionTypes from './dgbFleetMapActionTypes';
import * as dgbFleetMapQueries from './dgbFleetMapQueries';

import errorMessages from '../../../../common/errorMessages';

const queryDgbFleetMapDetailsStarting = defaultActionFactory(dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_STARTING, 'stateDef');
const queryDgbFleetMapDetailsSuccess = defaultActionFactory(dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_SUCCESS, 'stateDef', 'queryResults');
const queryDgbFleetMapDetailsError = defaultActionFactory(dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_ERROR, 'stateDef');

const queryDgbFleetMapDetails = queryActionCustomFactory(
  queryDgbFleetMapDetailsStarting,
  queryDgbFleetMapDetailsSuccess,
  queryDgbFleetMapDetailsError,
  errorMessages.ERROR_RETRIEVING_DGB_DETAILS,
  dgbFleetMapQueries.fetchDGBViewDetails
);

export {
  queryDgbFleetMapDetails
}