const getLayoutStyles = () => ({
  layoutCellJustifyTop: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  layoutCellJustifyBottom: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  layoutCellJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  layoutCellJustifyLeft: {
    display: 'flex',
    justifyContent: 'left',
  },
  layoutCellAlignTop: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  layoutCellAlignBottom: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  layoutCellAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default getLayoutStyles;