export default {
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR',
  DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT: 'DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT',

  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR',
  DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA: 'DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA',
  DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE: 'DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE',

  DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME: 'DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME',
  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME',
  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION',

  DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START: 'DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START',
  DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END: 'DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END',
  DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START: 'DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START',
  DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END: 'DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END',

  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY',
};
