import _ from 'lodash';
import {hasOwnerAdminToken, hasSetContextToken, hasFleetManagementPermission} from '../../../helpers/authHelper';
import ComponentTypes from '../../../components/componentTypes';

import fleetMapViews from '../../../state/displays/fleetMap/display/fleetMapViews';
import fleetOverviewViews from '../../displays/fleetOverview/fleetOverviewViews';
import dfpDashboardViews from '../../displays/dfpDashboard/dfpDashboardViews';
import OWNERS from "../../../components/common/owners";

const GROUP_NAME_FAVORITES = 'favorites';
const GROUP_NAME_DASHBOARDS = 'dashboards';
const GROUP_NAME_DISPLAYS = 'displays';
const GROUP_NAME_MANAGEMENT = 'management';

/**
 * Returns the list of routes available in the Portal App for a given owner. The logic to
 * generate the list can make use of the provided owner Id as well as the permissions existing
 * for the current user.
 *
 * The only rule to follow is that the returned routes should not have
 * duplicate URLs or the display to display navigation will not work properly.
 *
 * The type attribute is used by the Portal App to map to the correct React component. These
 * values must be the same as the defined Component Types in the Portal App project or the
 * component mapping will not be correct.
 * 
 * If the user has a default route, use that for the default.
 * 
 * NOTE: For users that can select owner contexts, you could see some odd behaviour if you are attempting
 * to navigate to a owner-specific route while having a different selected owner.
 * 
 *  For example: 
 *    Job Overview is a NexTier/MDT only route. You have WebbTex selected as your current owner.
 *    If you pasted in a url that goes to the Job Overview, you'll see current owner change to NexTier
 *    and you will have the Job Overview page load successfully.
 * 
 *    This is expected behaviour.
 * 
 *    This is because during the login process, the owners and selected owner is loaded *before* these routes and router are. 
 *    We don't persist the selected owner across sessions and navigating using a direct url is the same as doing a page reload so
 *    the selected owner will *always* change to the default one (NexTier) in this scenario.
 */
const getUserRoutes = (ownerId, showDevelopmentContent, showPreReleaseContent, defaultRoute, pinnedPages, routeGroups) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      //TODO: these will be moved to be cloud supplied, along with coil
      const fleetDashboardConfiguration = getFleetDashboardConfiguration(ownerId, showDevelopmentContent, showPreReleaseContent);
      const fleetOverviewConfiguration = getFleetOverviewConfiguration(ownerId, showDevelopmentContent, showPreReleaseContent);
      const dfpDashboardConfiguration = getDfpDashboardConfiguration(ownerId, showDevelopmentContent, showPreReleaseContent);


      // Resolve the list of routes for this user based on the owner context and user permissions
      let routes = { groups: [], routes: [], pinPages: [] };

      // At the point of this change, user's should have a default route from their profile
      // However, should something happen, let's not leave the user in a bad state if their default route is missing a value
      // If their default route has a value but the wrong one, then at least we (UI) handle that properly
      // A missing default route value just ends up breaking the router
      let defaultRouteValue = (_.isNil(defaultRoute) || _.isNil(defaultRoute.value)) ? '/fleet-overview' : defaultRoute.value;

      let isUserAdmin = hasOwnerAdminToken();

      routes.pinPages = pinnedPages;

      if(!_.isEmpty(routeGroups)){
        routes.groups = routeGroups; //keep previous expand status
      }else{
        routes.groups.push({ value: GROUP_NAME_FAVORITES, label: 'FAVORITES', order: 0, expand:true });
        routes.groups.push({ value: GROUP_NAME_DASHBOARDS, label: 'DASHBOARDS', order: 1, expand:false });
        routes.groups.push({ value: GROUP_NAME_DISPLAYS, label: 'DISPLAYS', order: 2, expand:false });
        routes.groups.push({ value: GROUP_NAME_MANAGEMENT, label: 'MANAGEMENT', order: 3, expand:false });
      }

      // TODO: the "configuration" property on routes should be retired - routes are routes and the configuration is not a route concern, but a display concern
      // Once we have all dashboards loading their configs from the cloud, those configs will exist under the User in AppState
      // TODO: we need to also load Owner Default Dashboard Configs and store them under Owner in AppState
      routes.routes.push({ value: '/fleet-overview', label: 'Fleet Overview', type: ComponentTypes.FLEET_OVERVIEW, group: GROUP_NAME_DASHBOARDS, order: 0, configuration: fleetOverviewConfiguration });
      routes.routes.push({ value: '/fleet-dashboard', label: 'Fleet Dashboard', type: ComponentTypes.FLEET_DASHBOARD, group: GROUP_NAME_DASHBOARDS, order: 1, configuration: fleetDashboardConfiguration });
      routes.routes.push({ value: '/pump-dashboard', label: 'Pump Dashboard', type: ComponentTypes.PUMP_DASHBOARD, group: GROUP_NAME_DASHBOARDS, order: 2 });

      if (ownerId !== OWNERS.NEXTIER && ownerId !== OWNERS.PUMPED_EQUIPMENT && ownerId !== OWNERS.BEDROCK_ENERGY) {
        routes.routes.push({ value: '/dfp-dashboard', label: 'DFP Dashboard', type: ComponentTypes.DFP_DASHBOARD, group: GROUP_NAME_DASHBOARDS, order: 3, configuration: dfpDashboardConfiguration });
      }

      if (ownerId === OWNERS.MDT || ownerId === OWNERS.DEMO || ownerId === OWNERS.GLADIATOR || ownerId === OWNERS.BEDROCK_ENERGY || ownerId === OWNERS.GOLIATH_PUMPING) {
        routes.routes.push({ value: '/coil-dashboard', label: 'Coil Dashboard', type: ComponentTypes.COIL_DASHBOARD, group: GROUP_NAME_DASHBOARDS, order: 4});
      }

      if (ownerId === OWNERS.MDT || ownerId === OWNERS.NEXTIER) {
        routes.routes.push({ value: '/job-overview', label: 'Job Overview', type: ComponentTypes.JOB_OVERVIEW, group: GROUP_NAME_DASHBOARDS, order: 5 });
      }

      // disabling data exploration display in favor of data exploration pump dashboard view
      routes.routes.push({ value: '/data-exploration', label: "Data Exploration", type: ComponentTypes.DATA_EXPLORATION_DISPLAY, group: GROUP_NAME_DISPLAYS, order:2});

      routes.routes.push({ value: '/equipment-hours', label: 'Equipment Hours', type: ComponentTypes.EQUIPMENT_LIST, group: GROUP_NAME_DISPLAYS, order: 0 });
      routes.routes.push({ value: '/component-hours', label: 'Component Hours', type: ComponentTypes.COMPONENT_HOURS, group: GROUP_NAME_DISPLAYS, order: 1 });
      routes.routes.push({ value: '/export', label: 'Export', group: GROUP_NAME_DISPLAYS, type: ComponentTypes.EXPORT, order: 2 });
      routes.routes.push({ value: '/system-software', label: 'System Software', type: ComponentTypes.SYSTEM_SOFTWARE, group: GROUP_NAME_DISPLAYS, order: 3 });
      routes.routes.push({ value: '/alarms', label: 'Alarms', type: ComponentTypes.ALARMS_DISPLAY, group: GROUP_NAME_DISPLAYS, order: 4});
      routes.routes.push({ value: '/notifications', label: 'Notifications', type: ComponentTypes.SUBSCRIPTIONS_DISPLAY, group: GROUP_NAME_DISPLAYS, order: 5});
      if (ownerId === OWNERS.MDT || (ownerId === OWNERS.NEXTIER && isUserAdmin === true)) {
        routes.routes.push({ value: '/downloads', label: 'Downloads', type: ComponentTypes.DOWNLOADS, group: GROUP_NAME_DISPLAYS, order: 9 });
      }
      if ((ownerId === OWNERS.MDT || ownerId === OWNERS.BLACKSTAR  || ownerId === OWNERS.BEDROCK_ENERGY || ownerId === OWNERS.PUMPED_EQUIPMENT)) {
        routes.routes.push({ value: '/customers', label: 'Customers', type: ComponentTypes.CUSTOMERS_DISPLAY, group: GROUP_NAME_DISPLAYS, order: 6 });
        routes.routes.push({ value: '/wells', label: 'Wells', type: ComponentTypes.WELLS_DISPLAY, group: GROUP_NAME_DISPLAYS, order: 7 });
        routes.routes.push({ value: '/jobs', label: 'Jobs', type: ComponentTypes.JOB_HISTORY, group: GROUP_NAME_DISPLAYS, order: 8 });
      }
      routes.routes.push({ value: '/audit-logs', label: 'Audit Logs', type: ComponentTypes.AUDIT_LOGS, group: GROUP_NAME_DISPLAYS, order: 10});
      
      if (isUserAdmin === true) {
        routes.routes.push({ value: '/users', label: 'User Management', type: ComponentTypes.USER_MANAGEMENT, group: GROUP_NAME_MANAGEMENT, order: 0 });
      }
      if (hasSetContextToken() && hasFleetManagementPermission() === true) {
        routes.routes.push({ value: '/fleetManagement', label: 'Fleet Management', type: ComponentTypes.FLEET_MANAGEMENT, group: GROUP_NAME_MANAGEMENT, order: 1 });
      }

      if (showDevelopmentContent === true) {
        routes.routes.push({ value: '/ownerManagement', label: 'Owner Management', type: ComponentTypes.OWNER_MANAGEMENT, group: GROUP_NAME_MANAGEMENT, order: 2 });
      }

      routes.routes.push({ value: '/asset-tracking', label: 'Asset Tracking', type: ComponentTypes.ASSET_TRACKING, group: GROUP_NAME_DASHBOARDS, order: 6});

      if (showDevelopmentContent === true) {
        routes.routes.push({ value: '/assetRouting', label: 'Asset Routing', type: ComponentTypes.ROUTE_ASSET_MANAGEMENT, group: GROUP_NAME_MANAGEMENT, order: 3 });
      }
      routes.routes.push({ value: '/communication-status', label: 'Communication Status', type: ComponentTypes.CLIENT_STATUS, group: GROUP_NAME_MANAGEMENT, order: 4 });
      routes.routes.push({ value: '/components', label: 'Components', type: ComponentTypes.COMPONENTS, group: GROUP_NAME_MANAGEMENT, order: 5 });
      routes.routes.push({ value: '/settings', label: 'Settings', type: ComponentTypes.SETTINGS, group: GROUP_NAME_MANAGEMENT, order: 6 });

      // Order Displays section in Alphabetical order
      _.forEach(_.sortBy(_.filter(routes.routes, ((route) => { return route.group === GROUP_NAME_DISPLAYS })), ['label']), (route, index) => {
        route.order = index;
      });

      // setup pinned pages list
      // This step should happen after all available routes added for the user
      // So to make sure not showing a pinned page which previously available for user but not available anymore
      let pinnedPageOrder = 0;
      routes.pinPages.forEach(pinnedPage => {
        const indexForRoute = routes.routes.findIndex(i => i.label === pinnedPage );
        if (indexForRoute > -1){ //only show when it's available for user
          let pinnedRoute = _.cloneDeep(routes.routes[indexForRoute]);
          pinnedRoute.group = GROUP_NAME_FAVORITES;
          pinnedRoute.order = pinnedPageOrder;
          routes.routes.push(pinnedRoute);
          pinnedPageOrder++;
        }
      });

      resolve({
        defaultRoute: defaultRouteValue,
        routes: routes
      })
    }, 0)
  })
};

const getFleetDashboardConfiguration = (ownerId, showDevelopmentContent, showPreReleaseContent) => {

  let dashboardCards = [
    ComponentTypes.FLEET_PRC_HISTORY,
    ComponentTypes.FLEET_ACTIVITY,
    ComponentTypes.FLEET_CLIENT_STATUS,
    ComponentTypes.FLEET_ALARM_COUNT,
    ComponentTypes.FLEET_OPERATION,
    ComponentTypes.FLEET_COMPONENTS,
  ];

  if (ownerId === OWNERS.NEXTIER) {
    dashboardCards.push(ComponentTypes.FLEET_VIBRATION);
  }

  let dashboardLayout = [
    {i: 'fleetHistory', x:0, y:0, w:4, h:2 },
    {i: 'fleetActivity', x:4, y:0, w:2, h:1 },
    {i: 'fleetClientStatus', x:6, y:0, w:2, h:1 },
    {i: 'fleetAlarm', x:8, y:0, w:2, h:1 },
    {i: 'fleetOperation', x:4, y:1, w:2, h:3 },
    {i: 'fleetComponents', x:6, y:1, w:2, h:3 },
    {i: 'fleetVibration', x:8, y:1, w:2, h:3 },
    {i: 'fleetHistory1', x:0, y:0, w:4, h:2 },
  ];

  let views = [
    { id: fleetMapViews.DASHBOARD, configuration: { cards: dashboardCards, layout: dashboardLayout } },
    { id: fleetMapViews.OPERATION },
    { id: fleetMapViews.COMPONENT },
    { id: fleetMapViews.HEALTH },
  ];

  if (ownerId === OWNERS.NEXTIER || ownerId === OWNERS.MDT) {
    views.push({ id: fleetMapViews.DGB });
  }

  return {
    views: views,
  };

};

const getDfpDashboardConfiguration = (ownerId, showDevelopmentContent, showPreReleaseContent) => {

  let dashboardCards = [
    { type: ComponentTypes.UNIT_FLEET },
    { type: ComponentTypes.COMPONENT_LIFE_CURBSIDE },
    { type: ComponentTypes.COMPONENT_LIFE_ROADSIDE },
    { type: ComponentTypes.UNIT_ALARM_COUNT },
    { type: ComponentTypes.UNIT_CHART_CURBSIDE_PRESSURE_HISTORY },
    { type: ComponentTypes.UNIT_CHART_ROADSIDE_PRESSURE_HISTORY },
    { type: ComponentTypes.UNIT_HOURS_CURBSIDE },
    { type: ComponentTypes.UNIT_HOURS_ROADSIDE },
    { type: ComponentTypes.UNIT_ACTIVITY_CURBSIDE },
    { type: ComponentTypes.UNIT_ACTIVITY_ROADSIDE },
  ];

  let dashboardLayout = [
    // first row
    {i: 'unitFleet',                   x: 0, y: 0, w: 2, h: 1 },
    {i: 'unitActivityRoadside',        x: 2, y: 0, w: 2, h: 1 },
    {i: 'unitActivityCurbside',        x: 4, y: 0, w: 2, h: 1 },
    {i: 'pumpAlarm',                   x: 6, y: 0, w: 2, h: 1 },
    // second row
    {i: 'unitPressureHistoryRoadside', x: 0, y: 1, w: 4, h: 2 },
    {i: 'unitPressureHistoryCurbside', x: 4, y: 1, w: 4, h: 2 },
    // third row
    {i: 'componentLifeRoadside',       x: 0, y: 3, w: 3, h: 2 },
    {i: 'unitHoursRoadside',           x: 3, y: 3, w: 2, h: 1 },
    {i: 'unitHoursCurbside',           x: 3, y: 4, w: 2, h: 1 },
    {i: 'componentLifeCurbside',       x: 5, y: 3, w: 3, h: 2 },
  ];

  return {
    views: [
      { id: dfpDashboardViews.DASHBOARD, configuration: { cards: dashboardCards, layout: dashboardLayout } },
      { id: dfpDashboardViews.OPERATION },
    ],
  };

};

const getFleetOverviewConfiguration = (ownerId, showDevelopmentContent, showPreReleaseContent) => {

  return {
    views: [
      { id: fleetOverviewViews.DASHBOARD, configuration: { cardType: ComponentTypes.FRAC_OVERVIEW_CARD } },
      { id: fleetOverviewViews.MAP },
    ],
  };
};

export {
  getUserRoutes,
  GROUP_NAME_FAVORITES
}
