import alarmCountActionTypes from "./alarmCountActionTypes";
import alarmCountState from "./alarmCountSelectors";
import createAlarmCounts from "./services/alarmCountService";

const initialState = alarmCountState();

const alarmCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);
    case alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);
    case alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);
    case alarmCountActionTypes.ALARM_COUNT_CLEAR_DATA:
      return onClearData(state, action);
    default:
      return state;
  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQueryDataSuccess = (state, action) => {
  const name = action.queryResults.name;
  const alarmCounts = createAlarmCounts(action.queryResults);

  return {
    ...state,
    queryRunning: false,
    alarmCounts: alarmCounts,
    name: name
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    alarmCounts: initialState.alarmCounts,
    name: initialState.name,
  };
};

export default alarmCountReducer