
import _ from 'lodash';

import subscriptionsDisplayActionTypes from './subscriptionsDisplayActionTypes';
import {
  fetchSubscriptionsDisplayDataForUser,
  fetchSaveSubscription,
  fetchDeleteSubscription,
  fetchSubscriptionsDisplayDataForOwner
} from './subscriptionsDisplayQueries';
import { mapRecipientsToRecipientInput, mapFiltersToConditionsInput } from './services/subscriptionsDisplayServices';

import subscriptionEntity from '../../../components/common/subscriptionEntity';
import queryActionFactory from "../../common/factories/queryActionFactory";
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from '../../app/actions/appErrorActions';
import ComponentTypes from '../../../components/componentTypes';

const queryDataForUser = queryActionFactory(
  subscriptionsDisplayActionTypes.QUERY_DATA_STARTING,
  subscriptionsDisplayActionTypes.QUERY_DATA_SUCCESS,
  subscriptionsDisplayActionTypes.QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_SUBSCRIPTIONS_DISPLAY_DATA,
  fetchSubscriptionsDisplayDataForUser
);

const queryDataForOwner = queryActionFactory(
    subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_STARTING,
    subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_SUCCESS,
    subscriptionsDisplayActionTypes.QUERY_DATA_FOR_OWNER_ERROR,
    errorMessages.ERROR_RETRIEVING_SUBSCRIPTIONS_DISPLAY_DATA,
    fetchSubscriptionsDisplayDataForOwner
);

const setSubscriptionActive = (stateDef, subscription) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(saveSubscriptionStarting(stateDef));

      let subscriptionToSave = 
      {
        id: subscription.id,
        name: subscription.name,
        eventId: subscription.eventId,
        createdBy: subscription.createdBy,
        active: subscription.active,
        recipients: subscription.recipients,
        conditions: subscription.conditions,
      }

      queryResults = await fetchSaveSubscription(subscriptionToSave);

      await dispatch(saveSubscriptionSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(saveSubscriptionError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_SUBSCRIPTION, e.message));
    }
  }
}

const saveSubscriptionStarting = defaultActionFactory(subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_STARTING, 'stateDef');
const saveSubscriptionSuccess = defaultActionFactory( subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_SUCCESS, 'stateDef', 'queryResults');
const saveSubscriptionError = defaultActionFactory(subscriptionsDisplayActionTypes.SAVE_SUBSCRIPTION_ERROR, 'stateDef');

const saveSubscription = (stateDef, context, entity, user, name, eventId, active, filters, recipients) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    let stateDefinition = stateDef;
    try {

      // We maybe calling this from a non-SubscriptionDisplay component
      // so let's make sure we use the SubscriptionDisplay stateKey
      if (stateDefinition.key !== ComponentTypes.SUBSCRIPTIONS_DISPLAY) {
        stateDefinition = {
          key: ComponentTypes.SUBSCRIPTIONS_DISPLAY,
          type: ComponentTypes.SUBSCRIPTIONS_DISPLAY,
        }
      }

      await dispatch(saveSubscriptionStarting(stateDefinition));

      let subscription = 
      {
        name: name,
        eventId: eventId,
        // When edit existing subscription it should keep createdBy value as it is
        createdBy: _.isNil(context?.createdBy) ? user: context.createdBy,
        active: _.isNil(active) ? true : active,
        recipients: mapRecipientsToRecipientInput(recipients),
        conditions: mapFiltersToConditionsInput(filters),
      }

      // If we are updating an existing subscription, set the id
      if (entity === subscriptionEntity.SUBSCRIPTIONS && !_.isNil(context) &&  !_.isNil(context.id)) {
        subscription.id = context.id;
      }

      queryResults = await fetchSaveSubscription(subscription);

      await dispatch(saveSubscriptionSuccess(stateDefinition, queryResults));

      // The Portal App's current paradigm for creating/saving things is that you are creating 
      // things on the same display that you are on
      // However, with Subscriptions, that might not be the case - the user could be at 
      // the Alarms display and create a Subscription off an alarm in the list
      // So we are going to reuse the handle error for now to show a message on the snackbar
      return dispatch(handleError(`Saved Subscription '${name}'.`, null));
    } catch(e) {
      await dispatch(saveSubscriptionError(stateDefinition));

      return dispatch(handleError(errorMessages.ERROR_SAVING_SUBSCRIPTION, e.message));
    }
  }
}

/**
 * function to assign a subscription to a user
 */
const assignSubscription = (stateDef, subscription, user) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(saveSubscriptionStarting(stateDef));

      // for assigning a subscription, we need to set the createdBy to the user
      const subscriptionToSave = {
        id: subscription.id,
        name: subscription.name,
        eventId: subscription.eventId,
        createdBy: user,
        active: subscription.active,
        recipients: subscription.recipients,
        conditions: subscription.conditions
      }

      queryResults = await fetchSaveSubscription(subscriptionToSave);

      await dispatch(saveSubscriptionSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(saveSubscriptionError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_SUBSCRIPTION, e.message));
    }
  }
}

const deleteSubscriptionStarting = defaultActionFactory(subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_STARTING, 'stateDef');
const deleteSubscriptionSuccess = defaultActionFactory( subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_SUCCESS, 'stateDef', 'queryResults');
const deleteSubscriptionError = defaultActionFactory(subscriptionsDisplayActionTypes.DELETE_SUBSCRIPTION_ERROR, 'stateDef');

const deleteSubscription = (stateDef, subscriptionId) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(deleteSubscriptionStarting(stateDef));

      queryResults = await fetchDeleteSubscription(subscriptionId);

      await dispatch(deleteSubscriptionSuccess(stateDef, { ...queryResults, subscriptionId: subscriptionId }));

      // The Portal App's current paradigm for creating/saving things is that you are creating 
      // things on the same display that you are on
      // However, with Subscriptions, that might not be the case - the user could be at 
      // the Alarms display and create a Subscription off an alarm in the list
      // So we are going to reuse the handle error for now to show a message on the snackbar
      return dispatch(handleError(`Deleted Subscription.`, null));
    } catch(e) {
      await dispatch(deleteSubscriptionError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_SUBSCRIPTION, e.message));
    }
  }

}

const showDeleteSubscriptionDialog = defaultActionFactory(subscriptionsDisplayActionTypes.SHOW_DELETE_SUBSCRIPTION_DIALOG, 'stateDef', 'show', 'subscription');
const showAssignSubscriptionDialog = defaultActionFactory(subscriptionsDisplayActionTypes.SHOW_ASSIGN_SUBSCRIPTION_DIALOG, 'stateDef', 'show', 'subscription');

const dataGridSetRowSize = defaultActionFactory(subscriptionsDisplayActionTypes.SET_ROW_SIZE, 'stateDef', 'rowSize');

export {
  queryDataForUser,
  queryDataForOwner,
  saveSubscription,
  assignSubscription,
  deleteSubscription,
  setSubscriptionActive,
  showDeleteSubscriptionDialog,
  showAssignSubscriptionDialog,
  dataGridSetRowSize
}