import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";

import { Typography, Box, List, ListItem, ListItemText, ListItemSecondaryAction, Icon, Tab, Tabs, IconButton, Button, Grid, TextField } from '@mui/material';
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RGL, { WidthProvider } from "react-grid-layout";

import ComponentTypes from "../../../componentTypes";
import { sensorSelectorState } from "../../../../state/common/sensorSelector/sensorSelectorSelectors";
import {
  querySensors,
  toggleSelectIndexForAdd,
  toggleSelectIndexForRemove,
  addToSelectedSensors,
  removeFromSelectedSensors,
  clearState, setSensorSelectorSensors, querySensorGroups,
  setSelectedFormIndex, addGroupToSelectedSensors, toggleSelectGroupIndexForAdd,
  openContextMenu, openTargetUomMenu, removeAllSelectedSensors, addAllAvailableSensors,
  onUpdateSensorSearch
} from "../../../../state/common/sensorSelector/sensorSelectorActions";
import Progress from "../../../controls/progress";
import getCardStyles from "../../../cards/cardStyles";
import getLayoutStyles from "../../../controls/layout/layoutStyles";
import getTypographyStyles from "../../styles/typographyStyles";

import AvailableSensors from './SensorSelectors/availableSensors'
import getSensorSelectorStyles from './SensorSelectors/sensorSelectorStyles';
import SensorGroups from "./SensorSelectors/sensorGroups";
import SelectedSensorTargetUomMenu from "./SensorSelectors/selectedSensorTargetUomsMenu";

import { appState as applicationState } from '../../../../state/app/appSelectors';

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();
const sensorSelectorStyles = getSensorSelectorStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,
  ...sensorSelectorStyles,
  cardContainer: {
    backgroundColor: 'background.paper',
    borderRadius: '4px',
    height: '840px',
  },
  cardHeader: {
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardHeaderLabel: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  searchBar: {
    p: 1,
    width: '100%',
  },
  cardContent: {
    height: '90%',
  },
  button: {
    marginTop: 2,
    marginBottom: 2,
    width: '12rem',
  },
  reactGridLayout: {
    height: '100%'
  },
  selectedSensorActions: {
    visibility: 'hidden',
    textAlign: 'center'
  },
  container: {
    position: 'relative',
    '&:hover div': {
      visibility: 'visible'
    }
  },
  rightIcon: {
    marginBottom: '2px'
  },
  leftIcon: {
    marginBottom: '2px'
  },
  selectedSensorText: {
    '&span': {
      fontSize: '14px'
    }
  }
};

const LayoutGridLayout = WidthProvider(RGL);

class SensorSelector extends Component {

  componentDidMount() {
    this.props.clearState();

    // set the initially selected sensors
    this.props.setSensorSelectorSensors(this.props.initalSelectedSensors);

    // load the list of available sensors for the given trucks and timerange
    if (!_.isNil(this.props.trucks) && !_.isEmpty(this.props.trucks)
      && !_.isNil(this.props.startTime) && !_.isNil(this.props.endTime)) {

      this.props.loadSensors(this.props.trucks, this.props.startTime, this.props.endTime, this.props.namespace);
    }

    // always load sensor groups when SensorSelector displayed
    this.props.loadSensorGroups(this.props.namespace, this.props.unitType);
  }

  componentWillUnmount() {
    // update the parent with the list of selected sensors (may be empty)
    this.props.parentCallback(this.props.selectedSensors);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // nothing to do when things change
  }

  render() {
    const isGroupTab = this.props.formIndex === 0;
    const isSensorTab = this.props.formIndex === 1;
    const canAddToSelectedSensors = (isGroupTab && this.props.selectedGroupIndexesForAdd.length !== 0)
      || (isSensorTab && this.props.selectedIndexesForAdd.length !== 0);
    const canRemoveSelectedSensors = this.props.selectedIndexesForRemove.length !== 0;
    const canRemoveAllSelectedSensors = this.props.selectedSensors.length !== 0;
    const canAddAllAvailableSensors = isSensorTab && this.props.sensors.length !== 0;

    // Add an ability for the host of the Sensor Selector to allow/disallow group editing (edit/delete actions on a group)
    const canEditGroups = (_.isNil(this.props.enableGroupEdit) ? true : this.props.enableGroupEdit) && this.props.canEditGroups;
    return (
      <Box sx={styles.cardContainer}>
        {/* Card Header */}
        <Box sx={styles.cardHeader}>
          <Typography sx={styles.cardHeaderLabel} variant={'subtitle1'}>SENSOR SELECTOR</Typography>
        </Box>

        {/* Card Search */}
        <TextField
          sx={styles.searchBar}
          onChange={(e) => {
            this.props.onUpdateSensorSearch(e.target.value.toLowerCase())
          }}
          variant="standard"
          placeholder='Filter...'
          helperText='Start typing to filter both the Sensor Groups and Available Sensors lists'
        />

        {/* Card Content */}
        <div>
          <Progress open={this.props.queryRunning} />
          <LayoutGridLayout
            cols={8} rowHeight={130}
            isResizable={false}
            isDraggable={false}
            isBounded={true}
            resizeHandles={[]}
          >
            <div key={'availSensors'} data-grid={{ x: 0, y: 0, w: 3, h: 5 }}>
              <Tabs
                value={this.props.formIndex}
                centered={true}
                onChange={(event, value) => this.props.setSelectedFormIndex(value)}
                indicatorColor='primary'
                textColor='primary'
                variant={'fullWidth'}
              >
                {
                  <Tab icon={<AppsIcon />} label='Sensor Groups' />
                }
                <Tab icon={<ListIcon />} label='Available' />
              </Tabs>
              {isSensorTab && <AvailableSensors sensors={this.props.sensors}
                selectedIndexesForAdd={this.props.selectedIndexesForAdd}
                selectSensorToAdd={this.props.selectSensorToAdd}
                selectedSensors={this.props.selectedSensors}
              />
              }
              {isGroupTab && <SensorGroups canEditGroups={canEditGroups}
                groups={this.props.sensorGroups}
                selectedIndexesForAdd={this.props.selectedGroupIndexesForAdd}
                selectGroupToAdd={this.props.selectGroupToAdd}
                selectedSensors={this.props.selectedSensors}
                openContextMenu={(targetElement, group) => this.props.openContextMenu(targetElement, group)}
                stateDef={this.props.stateDef}
                unitType={this.props.unitType}
                axisDefinition={this.props.axisDefinition}
                namespace={this.props.namespace}
              />
              }
            </div>

            <div key={'selectedSensors'} data-grid={{ x: 5, y: 0, w: 3, h: 5 }}>
              <Tabs
                value={'chart'}
                centered={true}
                indicatorColor='primary'
                textColor='primary'
                variant={'fullWidth'}
              >
                <Tab value={'chart'} icon={<ShowChartIcon />} label={'Selected'} disabled={true} />
              </Tabs>
              <div>
                <List sx={styles.sensorList}>
                  {
                    _.isNil(this.props.selectedSensors) ? [] : this.props.selectedSensors.map((sensor, index) => {
                      return (
                        <ListItem
                          selected={this.props.selectedIndexesForRemove.findIndex(item => item === index) >= 0}
                          key={`${sensor.alias}:${sensor.uom}`}
                          onClick={() => this.props.selectSensorToRemove(index)}
                          sx={{ overflowWrap: 'break-word' }}>
                          <ListItemText primary={`${sensor.alias} (${sensor.uom})`}
                            primaryTypographyProps={{ fontSize: '14px' }}
                          />
                          {
                            /* Target UoM Menu */
                            /* We will get target uoms lists with 0,1, or more items.
                              Target Uom Lists will always include the source uom they were generated for.
                              Does not make sense to show the menu when:
                                1. There are no targets at all (empty)
                                2. The only target uom is the same as the source uom (1 item)
                            */
                            ((sensor?.targetUoms?.length ?? 0) > 1) &&
                            <ListItemSecondaryAction sx={styles.selectedSensorActions}>
                              <div>
                                <IconButton
                                  edge="end"
                                  onClick={(event) => {
                                    this.props.openTargetUomMenu(event.currentTarget, sensor)
                                  }}
                                  size="large">
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </ListItemSecondaryAction>
                          }
                        </ListItem>
                      );
                    })
                  }
                </List>
              </div>
              <SelectedSensorTargetUomMenu stateDef={this.props.stateDef} />
            </div>

            <Box key={'transferButtons'} data-grid={{ x: 3, y: 0, w: 2, h: 3 }}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

              <Button variant={'contained'} color={'primary'} sx={styles.button}
                disabled={!canAddAllAvailableSensors}
                onClick={() => this.props.addAllAvailableSensors()}>
                Add All
                <KeyboardDoubleArrowRightIcon sx={styles.rightIcon} />
              </Button>
              <Button variant={'contained'} color={'primary'} sx={styles.button}
                disabled={!canAddToSelectedSensors}
                onClick={() => {
                  if (isGroupTab) {
                    this.props.addSelectedGroups(this.props.selectedGroupIndexesForAdd);
                  } else if (isSensorTab) {
                    this.props.addSelectedSensors(this.props.selectedIndexesForAdd);
                  }
                }}>
                Add
                <KeyboardArrowRightIcon sx={styles.rightIcon} />
              </Button>
              <Button variant={'contained'} color={'primary'} sx={styles.button}
                disabled={!canRemoveSelectedSensors}
                onClick={() => this.props.removeSelectedSensors(this.props.selectedIndexesForRemove)}>
                <KeyboardArrowLeftIcon sx={styles.leftIcon} />
                Remove
                <Icon sx={styles.rightIcon} />
              </Button>

              <Button variant={'contained'} color={'primary'} sx={styles.button}
                disabled={!canRemoveAllSelectedSensors}
                onClick={() => this.props.removeAllSelectedSensors()}>
                <KeyboardDoubleArrowLeftIcon sx={styles.leftIcon} />
                Remove All
                <Icon sx={styles.rightIcon} />
              </Button>
            </Box>

            <Box key={'closeButtons'} data-grid={{ x: 3, y: 11, w: 2, h: 1 }}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                {this.props.config && this.props.selectedSensors.length > 4 && 
                <Typography>Please select a max of 4 sensors.</Typography>}
              <Button
                sx={styles.button}
                variant={'contained'}
                color={'primary'}
                onClick={this.props.onClose}
                disabled={this.props.config && this.props.selectedSensors.length > 4}>
                Close
              </Button>
            </Box>
          </LayoutGridLayout>
        </div>
      </Box>
    );
  }
}

SensorSelector.propTypes = {
  parentCallback: PropTypes.func.isRequired,
  initalSelectedSensors: PropTypes.array,
  // TODO: This is an array of trucks but Sensor Selector is only
  // really meant to be used for a single truck (100% now) or an array of trucks of the same type (0% now)
  // We don't support trucks of different unit types 
  trucks: PropTypes.array.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  // Add an ability for the host of the Sensor Selector to allow/disallow group editing (edit/delete actions on a group)
  // Default to true if not provided
  enableGroupEdit: PropTypes.bool,
  // Namespace for the sensors we want
  // Valid values: MDT, DXP
  namespace: PropTypes.string.isRequired,
  // TODO: If multiple trucks of different types are supported, then this will need to be 
  // update to support multiple types
  unitType: PropTypes.string,
  // Required for Saving Sensor Group Axis Selections
  axisDefinition: PropTypes.object
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: props.stateKey,
      type: ComponentTypes.SENSOR_SELECTOR,
    }
  };
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let appState = applicationState(state);
  let componentState = sensorSelectorState(state[stateDef.key]);

  return {
    sensors: componentState.sensors,
    sensorGroups: componentState.groups,

    selectedSensors: componentState.selectedSensors,
    queryRunning: componentState.queryRunning,
    selectedIndexesForAdd: componentState.selectedIndexesForAdd,
    selectedGroupIndexesForAdd: componentState.selectedGroupIndexesForAdd,
    selectedIndexesForRemove: componentState.selectedIndexesForRemove,

    formIndex: componentState.formIndex,

    canEditGroups: appState.user.isUserAdmin,
  };
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    loadSensors: (trucks, startTime, endTime, namespace) => {
      dispatch(querySensors(props.stateDef, trucks, startTime, endTime, namespace));
    },
    loadSensorGroups: (namespace, unitType) => {
      dispatch(querySensorGroups(props.stateDef, namespace, unitType));
    },
    clearState: () => dispatch(clearState(props.stateDef)),
    setSensorSelectorSensors: (sensors) => {
      dispatch(setSensorSelectorSensors(props.stateDef, sensors));
    },
    addSelectedSensors: (indexes) => {
      dispatch(addToSelectedSensors(props.stateDef, indexes));
    },
    addSelectedGroups: (indexes) => {
      dispatch(addGroupToSelectedSensors(props.stateDef, indexes));
    },
    removeSelectedSensors: (indexes) => {
      dispatch(removeFromSelectedSensors(props.stateDef, indexes));
    },
    selectSensorToAdd: (index) => {
      dispatch(toggleSelectIndexForAdd(props.stateDef, index));
    },
    selectSensorToRemove: (index) => {
      dispatch(toggleSelectIndexForRemove(props.stateDef, index));
    },
    selectGroupToAdd: (index) => {
      dispatch(toggleSelectGroupIndexForAdd(props.stateDef, index));
    },
    setTrucks: (trucks) => dispatch(props.stateDef, trucks),
    setSelectedFormIndex: (index) => {
      dispatch(setSelectedFormIndex(props.stateDef, index))
    },
    openContextMenu: (contextMenuTargetElement, group) => {
      dispatch(openContextMenu(props.stateDef, contextMenuTargetElement, group))
    },
    openTargetUomMenu: (uomMenuTargetElement, sensor) => {
      dispatch(openTargetUomMenu(props.stateDef, uomMenuTargetElement, sensor))
    },
    removeAllSelectedSensors: () => {
      dispatch(removeAllSelectedSensors(props.stateDef))
    },
    addAllAvailableSensors: () => {
      dispatch(addAllAvailableSensors(props.stateDef))
    },
    onUpdateSensorSearch: (sensorSearchQuery) => {
      dispatch(onUpdateSensorSearch(props.stateDef, sensorSearchQuery))
    },
  };
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(SensorSelector));
