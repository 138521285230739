import _ from "lodash";
import moment from "moment";

import { v4 as uuidv4 } from 'uuid';

const createAuditLogs = (auditLogData) => {
  const auditLogs = _.cloneDeep(auditLogData);
  generateId(auditLogs);
  transformAuditLogAgeAndTimeStamp(auditLogs);
  return auditLogs;
};

/**
 * Generate id for each auditLog record to support display on MDTDataGrid
 * We only need to make sure it's unique id for each row to display,
 * since we don't need to use the id to do further reference etc.
 */
const generateId = (auditLogs) => {
  _.map(auditLogs, auditLog => {
    auditLog.id = uuidv4();
    return auditLog;
  });
}

const transformAuditLogAgeAndTimeStamp = (auditLogs) => {
  _.map(auditLogs, auditLog => {
    // we don't parse the age yet to support the sorting on MDTDataGrid
    auditLog.age = auditLog.timestamp;
    // after age get the unix value, we can format the timestamp to expected format
    auditLog.timestamp = moment.unix(auditLog.timestamp).format('YYYY-MM-DD HH:mm:ss');
    return auditLog;
  });
}

export default createAuditLogs