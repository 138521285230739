import _ from 'lodash';
import unitActivityActionTypes from './unitActivityActionTypes';
import { unitActivityState } from "./unitActivitySelectors";

const initialState = unitActivityState();

const unitActivityReducer = (state=initialState, action) => {

  switch (action.type) {

    case unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case unitActivityActionTypes.UNIT_ACTIVITY_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case unitActivityActionTypes.UNIT_ACTIVITY_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {

  const noValue = '-';

  let { pumping, idle, stopped, days } = action.queryResults.unitActivity;

  let displayValuePumping = _.isNil(pumping) ? noValue : parseFloat(pumping.toFixed(0)).toLocaleString('en') + '%';
  let displayValueIdle = _.isNil(idle) ? noValue : parseFloat(idle.toFixed(0)).toLocaleString('en')  + '%';
  let displayValueStopped = _.isNil(stopped) ? noValue : parseFloat(stopped.toFixed(0)).toLocaleString('en')  + '%';

  let displayDays = null;
  if (!_.isNil(days)) {
    if (days > 1) {
      displayDays = '{days} days'.replace('{days}', days);
    } else if (days === 1) {
      displayDays = '{days} day'.replace('{days}', days);
    }
  }

  return {
    ...state,
    queryRunning: false,
    rawValuePumping: pumping,
    rawValueIdle: idle,
    rawValueStopped: stopped,
    displayDays: displayDays,
    displayValuePumping: displayValuePumping,
    displayValueIdle: displayValueIdle,
    displayValueStopped: displayValueStopped,
  };
};

const onClearData = (state, action) => {

  return {
    ...state,
    queryRunning: false,
    rawValuePumping: initialState.rawValuePumping,
    rawValueIdle: initialState.rawValueIdle,
    rawValueStopped: initialState.rawValueStopped,
    displayDays: initialState.displayDays,
    displayValuePumping: initialState.displayValuePumping,
    displayValueIdle: initialState.displayValueIdle,
    displayValueStopped: initialState.displayValueStopped,
  };

};

export default unitActivityReducer