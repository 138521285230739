import _ from 'lodash';
import moment from 'moment';
import { filterState } from '../../common/filtering/filterSelectors';

const componentsState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    editMode: _.isNil(state) ? true : state.editMode,

    units: _.isNil(state) ? [] : state.units,
    filteredUnits: _.isNil(state) ? [] : state.filteredUnits,
    componentTypes: _.isNil(state) ? [] : state.componentTypes,
    components: _.isNil(state) ? [] : state.components,

    selectedUnit: _.isNil(state) ? null : state.selectedUnit,
    selectedComponentType: _.isNil(state) ? null : state.selectedComponentType,

    selectedComponent: _.isNil(state) ? null : state.selectedComponent,
    selectedComponentId: _.isNil(state) ? null : state.selectedComponentId,
    selectedEffectiveDate: _.isNil(state) ? null : state.selectedEffectiveDate,
    selectedModel: _.isNil(state) ? '' : state.selectedModel,
    selectedSerialNumber: _.isNil(state) ? '' : state.selectedSerialNumber,
    selectedRefNumber: _.isNil(state) ? '' : state.selectedRefNumber,
    selectedUnitOffset: _.isNil(state) ? '' : state.selectedUnitOffset,
    selectedSnapshotStart: _.isNil(state) ? null : state.selectedSnapshotStart,
    selectedECMReset: _.isNil(state) ? false : state.selectedECMReset,

    canSave: _.isNil(state) ? false : state.canSave,
    canAdd: _.isNil(state) ? false : state.canAdd,
    canDelete: _.isNil(state) ? false : state.canDelete,
    canCancel: _.isNil(state) ? false : state.canCancel,
    canEditSnapshotStart: _.isNil(state) ? false : state.canEditSnapshotStart,
    hasErrorUnitOffset: _.isNil(state) ? false : state.hasErrorUnitOffset,
    hasErrorSnapshotStart: _.isNil(state) ? false : state.hasErrorSnapshotStart,

    openDeleteConfirmation: _.isNil(state) ? false : state.openDeleteConfirmation,
    openCalculator: _.isNil(state) ? false : state.openCalculator,

    calculatorInput: _.isNil(state) ? null : state.calculatorInput,
    hasErrorCalculatorInput: _.isNil(state) ? false : state.hasErrorCalculatorInput,

    // These are set when the display is loaded and there is a navigation context for the display
    // in the app state (ie. one display linked to another display).
    navigationContextUnitId: _.isNil(state) ? null : state.navigationContextUnitId,
    navigationContextComponentType: _.isNil(state) ? null : state.navigationContextComponentType,

    selectedEffectiveDateDisplay: _.isNil(state) ? null : state.selectedEffectiveDateDisplay,
  };

};

const editComponentState = component => {

  let initialState = componentsState();

  return {
    selectedComponentId: _.isNil(component) ? initialState.selectedComponentId : component.id,
    selectedEffectiveDate: _.isNil(component) ? initialState.selectedEffectiveDate : component.effectiveDateMoment,
    selectedModel: _.isNil(component) ? initialState.selectedModel : component.model,
    selectedSerialNumber: _.isNil(component) ? initialState.selectedSerialNumber : component.serialNumber,
    selectedRefNumber: _.isNil(component) ? initialState.selectedRefNumber : component.referenceNumber,
    selectedUnitOffset: _.isNil(component) ? initialState.selectedUnitOffset : component.unitOffset,
    selectedSnapshotStart: _.isNil(component) ? initialState.selectedSnapshotStart : component.snapshotStart,
    selectedECMReset: initialState.selectedECMReset,

    selectedEffectiveDateDisplay: _.isNil(component) ? initialState.selectedEffectiveDate : component.effectiveDateMoment,
  };
};

const addComponentState = () => {

  let initialState = componentsState();

  let defaultTime = moment();

  return {
    selectedComponentId: initialState.selectedComponentId,
    selectedEffectiveDate: defaultTime,
    selectedModel: initialState.selectedModel,
    selectedSerialNumber: initialState.selectedSerialNumber,
    selectedRefNumber: initialState.selectedRefNumber,
    selectedUnitOffset: 0,
    selectedSnapshotStart: 0,
    selectedECMReset: initialState.selectedECMReset,

    selectedEffectiveDateDisplay: defaultTime,
  };
};


export {
  componentsState,
  editComponentState,
  addComponentState,
};