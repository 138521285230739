import _ from 'lodash';
import unitHoursDfpActionTypes from './unitHoursDfpActionTypes';
import { unitHoursDfpState } from './unitHoursDfpSelectors';
import unitChartDfpTypes from './unitHoursDfpTypes';

const noValue = '-';

const initialState = unitHoursDfpState();

const unitHoursDfpReducer = (state=initialState, action) => {
  switch (action.type) {
    case unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);
    case unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_SUCCESS:
      return onQuerySuccess(state, action);
    case unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);
    case unitHoursDfpActionTypes.UNIT_HOURS_DFP_CLEAR_DATA:
      return onClearData(state, action);
    default: return state;
  }

};

const onQueryDataStarting = (state, action) => {
  return { ...state, queryRunning: true };
};

const onQueryDataError = (state, action) => {
  return { ...state, queryRunning: false };
};

const onClearData = (state, action) => {
  return { ...state, ecmHours: noValue, pumpHours: noValue };
};

const onQuerySuccess = (state, action) => {
  if (action.queryResults.chartType === unitChartDfpTypes.CURBSIDE) {
    return processResponse(state, action.queryResults.unitHoursCurbside)
  } else {
    return processResponse(state, action.queryResults.unitHoursRoadside)
  }
};

const processResponse = (state, response) => {

  let { ecmHours, pumpHours } = response;

  ecmHours = _.isNil(ecmHours) ? noValue : ecmHours;
  pumpHours = _.isNil(pumpHours) ? noValue : pumpHours;

  return {
    ...state,
    queryRunning: false,
    ecmHours: ecmHours,
    pumpHours: pumpHours,
  };
};

export default unitHoursDfpReducer