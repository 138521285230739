import _ from 'lodash';

const fracJobOverviewCardState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    openMenu: _.isNil(state) ? false : state.openMenu,
    menuTargetElement: _.isNil(state) ? null : state.menuTargetElement,
    fleetFracJobOverview: _.isNil(state) ? {} : state.fleetFracJobOverview
  };

};

export {
  fracJobOverviewCardState
};