import { v4 as uuidv4 } from 'uuid';
import userConfigActionTypes from "../appActionTypes";
import { appState } from "../appSelectors";
import {
  getCardFromLayoutConfigViews,
  getViewFromLayoutConfigViews
} from "../../../components/common/layout/layoutHelper";
import ComponentTypes from '../../../components/componentTypes';
import _ from "lodash";

const initialState = appState();

const userConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_STARTING:
      return { ...state, queryRunning: true };
    case userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_ERROR:
      return { ...state, queryRunning: false };
    case userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_SUCCESS:
      return onQueryUserConfigForPageSuccess(state, action);
    case userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_STARTING:
      return { ...state, queryRunning: true };
    case userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_ERROR:
      return { ...state, queryRunning: false };
    case userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_SUCCESS:
      return onSaveUserConfigForPageSuccess(state, action);
    case userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_STARTING:
      return { ...state, queryRunning: true };
    case userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_ERROR:
      return { ...state, queryRunning: false };
    case userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_SUCCESS:
      return onQueryUserConfigDashboardLayoutSuccess(state, action);
    case userConfigActionTypes.USER_CONFIG_UPDATE_DASHBOARD_LAYOUT:
      return onUpdateDashboardLayout(state, action);
    case userConfigActionTypes.USER_CONFIG_ADD_CARD_TO_DASHBOARD_LAYOUT:
      return onAddCardToDashboardLayout(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CARD:
      return onRemoveCardFromDashboardLayout(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT:
      return onEditDashboardLayout(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_EDIT_LAYOUT_SELECT_CARD:
      return onSelectCard(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_TITLE:
      return onChangeCardTitle(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG:
      return onChangeConfig(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_SET_DEFAULT_TITLE:
      return onSetDefaultCardTitle(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_SIZE:
      return onChangeCardSize(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_CONFIG_PANEL_CHANGES_DISCARD:
      return onConfigPanelChangesDiscard(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_ADD_CUSTOM_VIEW:
      return onAddCustomView(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CUSTOM_VIEW:
      return onRemoveCustomView(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_TOGGLE_FAVORITE_VIEW:
      return onToggleFavoriteView(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_CHANGE_CUSTOM_VIEW_NAME:
      return onChangeCustomViewName(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_MANAGE_CUSTOM_VIEW:
      return onManageDashboardCustomViews(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_DISCARD_CUSTOM_VIEW_CHANGES:
      return onDiscardManageCustomViews(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REORDER_CUSTOM_VIEW:
      return onReorderCustomViews(state, action);
    case userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_SELECT_CUSTOM_VIEW_ICON:
      return onSelectCustomViewIcon(state, action);
    case userConfigActionTypes.USER_CONFIG_RESTORE_DEFAULT_VIEW:
      return onRestoreDefaultView(state, action);

    default:
      return state;
  };
};

const onQueryUserConfigForPageSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onSaveUserConfigForPageSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onQueryUserConfigDashboardLayoutSuccess = (state, action) => {

  const newUserDashboardConfigurations = {};

  _.forEach(action.queryResults.userDashboardConfiguration, (config) => {
    let newConfig = config.config;
    newUserDashboardConfigurations[config.name] =
    {
      hasChanges: false,
      views: { ...JSON.parse(newConfig) }
    }
  })

  return {
    ...state,
    queryRunning: false,
    user: {
      ...state.user,
      dashboards: newUserDashboardConfigurations
    }
  };
};

const isLayoutConfigChanged = (originalViewCards, targetViewCards) => {
  // Two cases to check if the layout config has not been changed:
  // 1. when all original view cards are in the target view cards and they are the same
  // 2. the number of cards in the target view cards is the same as cards in original view
  const normalizedOriginalViewCards =  originalViewCards ?? [];
  const layoutComparison = normalizedOriginalViewCards.map(originalViewCard => {
    return _.isNil(targetViewCards.find( targetViewCard =>
                    _.isEqual(_.omit(targetViewCard, ['isResizable', 'resizeHandles']), _.omit(originalViewCard, ['isResizable', 'resizeHandles']))));
  });
  return layoutComparison.includes(true) || targetViewCards.length !== normalizedOriginalViewCards.length;
};

const onUpdateDashboardLayout = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);

  if (newDashboards[action.dashboard].editMode === true) {

    const newOriginalCards = _.cloneDeep(action.view.cards);
    const newDashboardsView = getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view.id);
    const originalCongifurations = _.map(newDashboardsView.cards, (card) => (_.isNil(card.configuration) ? null : {i: card.i, configuration: card.configuration}));
    _.forEach(action.newLayout, (card) => {
      const cardInOriginal = _.find(newOriginalCards, ['i', card.i]);
      const originalConfig = _.find(originalCongifurations, ['i', card.i]);
      cardInOriginal.x = card.x;
      cardInOriginal.y = card.y;
      cardInOriginal.w = card.w;
      cardInOriginal.h = card.h;
      if (!_.isNil(originalConfig)) {
        cardInOriginal.configuration = originalConfig.configuration;
      }
    });
    newDashboardsView.cards = newOriginalCards;
    //Check if or not there are changes been made to the layout
    newDashboards[action.dashboard].hasChanges = isLayoutConfigChanged(
      getViewFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view.id).cards,
      newDashboardsView.cards);
  }
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
}

const onEditDashboardLayout = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  if (!_.isNil(newDashboards[action.dashboard])) {
    newDashboards[action.dashboard].editMode = action.isEdit;
    if (action.isEdit === true) {
      newDashboards[action.dashboard].originalConfigViews = _.cloneDeep(newDashboards[action.dashboard].views);
    } else {
      newDashboards[action.dashboard].originalConfigViews = null;
      newDashboards[action.dashboard].selectedCard = null;
      newDashboards[action.dashboard].hasChanges = false;
    }
  }
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onSelectCard = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  if (!_.isNil(newDashboards[action.dashboard])) {
    newDashboards[action.dashboard].selectedCard = action.selectedCard;
  }

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onAddCardToDashboardLayout = (state, action) => {

  // if on custom view, then action.view is an object with field id
  // otherwise it should be MDT view, and action.view is a string
  const view = action.view.id ?? action.view;
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const newViewCards = _.cloneDeep(getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, view).cards) ?? [];

  // Create a new card and add it to the layout
  let newCard =
  {
    i: uuidv4(),
    type: action.selectedCard.type,
    x: newViewCards.length % 9,
    y: Infinity,
    w: action.selectedCard.w,
    h: action.selectedCard.h,
    minW: action.selectedCard.minW,
    minH: action.selectedCard.minH,
    configuration: action.selectedCard.configuration || {}
  }

  newViewCards.push(newCard);
  getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, view).cards = newViewCards;

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onRemoveCardFromDashboardLayout = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  _.remove(
    newDashboards[action.dashboard].views.views.find(view => view.id === action.view).cards,
    (card) => card.i === action.cardKey
  );

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }

};

const onChangeCardTitle = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const card = getCardFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view, action.cardKey);
  const originalCard = getCardFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view, action.cardKey)
  card.configuration = card.configuration || {};

  if (_.isEmpty(action.title) && !_.isNil(originalCard) && originalCard.configuration.title === action.defaultTitle) {
    //If user clear the title and if the originalCard is set to the default card title, we will consider it as the tile has no change but keep the title in the title textfield to still be empty string until user close the config panel
    card.configuration.title = action.defaultTitle;
    newDashboards[action.dashboard].hasChanges = isLayoutConfigChanged(getViewFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view).cards,
      getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view).cards);
    //Set the title back to empty string once done dirty check
    card.configuration.title = action.title;
  } else {
    card.configuration.title = action.title;
    newDashboards[action.dashboard].hasChanges = isLayoutConfigChanged(getViewFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view).cards,
      getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view).cards);
  }

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onSetDefaultCardTitle = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  // If the dashboard we are on does not even have any customization support, let's just return
  if (_.isNil(newDashboards[action.dashboard])) {
    return {
      ...state
    }
  }
  const card = getCardFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view, action.cardKey);
  const originalCard = getCardFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view, action.cardKey)
  card.configuration = card.configuration || {};
  card.configuration.title = action.defaultTitle;

  if (!_.isNil(originalCard)) {
    originalCard.configuration = originalCard.configuration || {};
    if (_.isEmpty(originalCard.configuration.title)) {
      originalCard.configuration.title = action.defaultTitle;
    }
  }

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onChangeConfig = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const card = getCardFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view, action.cardKey);
  card.configuration = {
    ...card.configuration,
    ...action.configuration
  };
  newDashboards[action.dashboard].hasChanges = _.isNil(newDashboards[action.dashboard].originalConfigViews) ? false : isLayoutConfigChanged(getViewFromLayoutConfigViews(newDashboards[action.dashboard].originalConfigViews, action.view).cards,
    getViewFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view).cards);
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
}

const onConfigPanelChangesDiscard = (state, action) => {
  const dashboardsDeepCopy = _.cloneDeep(state.user.dashboards);
  const card = getCardFromLayoutConfigViews(dashboardsDeepCopy[action.dashboard].views, action.view, action.cardKey);
  const originalConfiguration = getCardFromLayoutConfigViews(dashboardsDeepCopy[action.dashboard].originalConfigViews, action.view, action.cardKey);
  if (originalConfiguration) {
    card.configuration = { ...originalConfiguration.configuration } || {};
    card.w = originalConfiguration.w;
    card.h = originalConfiguration.h;
  }
  dashboardsDeepCopy[action.dashboard].hasChanges = isLayoutConfigChanged(getViewFromLayoutConfigViews(dashboardsDeepCopy[action.dashboard].originalConfigViews, action.view).cards,
    getViewFromLayoutConfigViews(dashboardsDeepCopy[action.dashboard].views, action.view).cards);
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: dashboardsDeepCopy
    }
  };
};

const onChangeCardSize = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const card = getCardFromLayoutConfigViews(newDashboards[action.dashboard].views, action.view, action.cardKey);

  card.w = action.size.w;
  card.h = action.size.h;

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
};

const onAddCustomView = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const views = newDashboards[action.dashboard].views.views;
  let newCustomView =
      {
        id: uuidv4(),
        type: ComponentTypes.USER_VIEW,
        name:'',
        allowCustomization: true,
        isFavourite:false,
        icon: 'image_search',
        cards: []
      };
  views.push(newCustomView);

  newDashboards[action.dashboard].isValid = validateCustomViews(views);
  newDashboards[action.dashboard].hasChanges = true;
  newDashboards[action.dashboard].views.views = views;

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onRemoveCustomView = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);

  _.remove(newDashboards[action.dashboard].views.views, {
    id: action.viewId
  });
  newDashboards[action.dashboard].isValid = validateCustomViews(newDashboards[action.dashboard].views.views);
  newDashboards[action.dashboard].hasChanges = !_.isEqual(newDashboards[action.dashboard].views.views, newDashboards[action.dashboard].originalConfigViews.views);

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onToggleFavoriteView = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const newView = _.find(newDashboards[action.dashboard].views.views, ['id', action.viewId]);

  newView.isFavorite = !newView.isFavorite;
  newDashboards[action.dashboard].isValid = validateCustomViews(newDashboards[action.dashboard].views.views);
  newDashboards[action.dashboard].hasChanges = !_.isEqual(newDashboards[action.dashboard].views.views, newDashboards[action.dashboard].originalConfigViews.views);;

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onManageDashboardCustomViews = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);

  newDashboards[action.dashboard].isManageCustomViews = action.isManage;
  if(action.isManage === true) {
    newDashboards[action.dashboard].originalConfigViews = _.cloneDeep(newDashboards[action.dashboard].views);
    newDashboards[action.dashboard].isValid = true;
  } else {
    newDashboards[action.dashboard].originalConfigViews = null;
    newDashboards[action.dashboard].hasChanges = false;
    newDashboards[action.dashboard].isValid = null;
  }
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onChangeCustomViewName = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const currentView = newDashboards[action.dashboard].views.views.find( (view) => view.id === action.viewId);
  currentView.name = action.name;
  newDashboards[action.dashboard].isValid = validateCustomViews(newDashboards[action.dashboard].views.views);
  newDashboards[action.dashboard].hasChanges = !_.isEqual(newDashboards[action.dashboard].views.views, newDashboards[action.dashboard].originalConfigViews.views);

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onSelectCustomViewIcon = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const currentView = newDashboards[action.dashboard].views.views.find( (view) => view.id === action.viewId);
  currentView.icon = action.icon;
  newDashboards[action.dashboard].hasChanges = !_.isEqual(newDashboards[action.dashboard].views.views, newDashboards[action.dashboard].originalConfigViews.views);

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};


const onDiscardManageCustomViews = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  newDashboards[action.dashboard].views = newDashboards[action.dashboard].originalConfigViews;

  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const onReorderCustomViews = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const views = newDashboards[action.dashboard].views.views;

  //Reorder custom views
  const customViews = views.filter(v => v.type === ComponentTypes.USER_VIEW);
  const [element] = customViews.splice(action.fromIndex, 1);
  customViews.splice(action.toIndex, 0, element);

  newDashboards[action.dashboard].views.views = views.filter(v => v.type !== ComponentTypes.USER_VIEW).concat(customViews);
  newDashboards[action.dashboard].hasChanges = !_.isEqual(newDashboards[action.dashboard].views.views, newDashboards[action.dashboard].originalConfigViews.views);
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  };
};

const validateCustomViews = (views) => {
  return !views.filter(v => v.type === ComponentTypes.USER_VIEW)
              .map((view) => !_.isEmpty(view.name))
              .includes(false);
};

const onRestoreDefaultView = (state, action) => {
  const newDashboards = _.cloneDeep(state.user.dashboards);
  const dashboardToUpdate = newDashboards[action.dashboard];

  if (dashboardToUpdate.editMode === true) {
    const defaultDashboard = _.find(action.queryResults.ownerDefaultDashboardConfiguration, (dashboard) => dashboard.name === action.dashboard);
    const defaultView = _.find(JSON.parse(defaultDashboard.config).views, (view) => view.id === action.view);

    const indexToReplace = _.findIndex(dashboardToUpdate.views.views, view => view.id === action.view);
    dashboardToUpdate.views.views[indexToReplace] = defaultView;

    // Check if the default layout is different from the previous layout
    dashboardToUpdate.hasChanges = isLayoutConfigChanged(
      getViewFromLayoutConfigViews(dashboardToUpdate.originalConfigViews, action.view).cards,
      defaultView.cards
    );
  }
  return {
    ...state,
    user: {
      ...state.user,
      dashboards: newDashboards
    }
  }
}

export default userConfigReducer;
