import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';

import fleetPumpStatusActionTypes from './fleetPumpStatusActionTypes';
import fetchFleetPumpStatusCardData from './fleetPumpStatusQueries';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_CLEAR_DATA, 'stateDef');
const setChartOptions = defaultActionFactory(fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_SET_CHART_OPTIONS, 'stateDef', 'chartOptions');

const queryData = queryActionFactory(
  fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_STARTING,
  fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_SUCCESS,
  fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEET_PUMP_STATUS_CARD_DATA,
  fetchFleetPumpStatusCardData
);

export {
  clearData,
  queryData,
  setChartOptions
}
