import * as pumpOnlyFleetMapSimulation from './pumpOnlyFleetMapSimulation';
import {performDisplayFetch} from '../../../../common/fetch';
import useSimulationData from '../../../../../helpers/simulationHelper';

const fetchPumpOnlyFleetMapComponentDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return pumpOnlyFleetMapSimulation.fetchPumpOnlyFleetMapComponentDetails(fleetId);
  }

  let queryString = '{ componentFleetMap(fleetId:{fleetId}) { id name componentViewData { ' +
      'datavan { id name alarmCounts { critical warning } } ' +
      'pumps { id name slot components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

const fetchPumpOnlyFleetMapHealthDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return pumpOnlyFleetMapSimulation.fetchPumpOnlyFleetMapHealthDetails(fleetId);
  }

  let queryString = '{ healthFleetMap(fleetId:{fleetId}) { id name healthViewData { ' +
      'datavan { id name alarmCounts { critical warning } } ' +
      'pumps { id name slot powerEnd fluidEnd iron alarmCounts { critical warning } } ' +
      'missile { slot value } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

const fetchPumpOnlyFleetMapOperationDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return pumpOnlyFleetMapSimulation.fetchPumpOnlyFleetMapOperationDetails(fleetId);
  }

  let queryString = '{ operationalFleetMap(fleetId:{fleetId}) { id name operationViewData { ' +
      'datavan { id name totalSand totalDischarge alarmCounts { critical warning } } ' +
      'sand1 { id name cleanRate massConc selectedMassFlow selectedSetPoint } ' +
      'sand2 { id name cleanRate massConc selectedMassFlow selectedSetPoint } ' +
      'wellHead { id name flowRate pressure density1 density2 } ' +
      'pumps { id name slot suction { value total } hhp { value total } discharge { value total } rpm gear rate { value stopped idle tripped } alarmCounts { critical warning } hasSand } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

export {
  fetchPumpOnlyFleetMapComponentDetails,
  fetchPumpOnlyFleetMapHealthDetails,
  fetchPumpOnlyFleetMapOperationDetails
}


