import {domToBlob} from 'modern-screenshot';

/**
 * Take a screenshot of the DOM subtree whose id has been annotated accordingly
 * @param {string | null} cardKey The UUID for the associated charting card.
 *    If null, then take a screenshot of the data exploration view
 * @param {function} preHook Function to be called before taking a screenshot
 * @param {function} preHook Function to be called after successfully taking a screenshot
 */
const takeScreenShot = (cardKey, preHook, postHook) => {
  if (!_.isNil(preHook)) { preHook(); }
  const tree = document.getElementById(
    _.isNil(cardKey) ? "screenshot-area" : "screenshot-area@" + cardKey
  );
  domToBlob(tree, {
    debug: false,
    filter: (node) => {
      try {
        return !node.hasAttribute("screenshotignore");
      } catch (err) {
        return true;
      }
    },
    style: {
      colorScheme: 'dark'
    }
  }).then((blob) => {
    window.navigator.clipboard.write(
      [ new ClipboardItem(
        Object.defineProperty({}, blob.type, {value: blob, enumerable: true})
      )]
    )
  }).then(() => {
    if (!_.isNil(postHook)) { postHook(); }
  });
};

export default takeScreenShot;