export default {
  UNIT_USER_CHART_QUERY_STARTING: 'UNIT_USER_CHART_QUERY_STARTING',
  UNIT_USER_CHART_QUERY_SUCCESS: 'UNIT_USER_CHART_QUERY_SUCCESS',
  UNIT_USER_CHART_QUERY_SENSORS_SUCCESS: 'UNIT_USER_CHART_QUERY_SENSORS_SUCCESS',
  UNIT_USER_CHART_QUERY_ERROR: 'UNIT_USER_CHART_QUERY_ERROR',
  UNIT_USER_CHART_DEFINITION_SET_DEFAULT_TRUCK: 'UNIT_USER_CHART_DEFINITION_SET_DEFAULT_TRUCK',
  UNIT_USER_CHART_DEFINITION_SET_START_TIME: 'UNIT_USER_CHART_DEFINITION_SET_START_TIME',
  UNIT_USER_CHART_DEFINITION_SET_SENSORS: 'UNIT_USER_CHART_DEFINITION_SET_SENSORS',
  UNIT_USER_CHART_DEFINITION_SET_AXES: 'UNIT_USER_CHART_DEFINITION_SET_AXES',

  UNIT_USER_CHART_CLEAR_DATA: 'UNIT_USER_CHART_CLEAR_DATA',
  UNIT_USER_CHART_SETUP_EDIT_MODE: 'UNIT_USER_CHART_SETUP_EDIT_MODE',
  
  CHART_CONFIG_OPEN_SENSOR_SELECTOR: 'CHART_CONFIG_OPEN_SENSOR_SELECTOR',
  CHART_CONFIG_CLOSE_SENSOR_SELECTOR: 'CHART_CONFIG_CLOSE_SENSOR_SELECTOR',
  CHART_CONFIG_SET_SELECTED_SENSORS: 'CHART_CONFIG_SET_SELECTED_SENSORS',
  CREATE_LINE_STYLES: 'CREATE_LINE_STYLES',

  CHART_CONFIG_UPDATE_DISPLAY_NAME: 'CHART_CONFIG_UPDATE_DISPLAY_NAME',
  CHART_CONFIG_UPDATE_UOM: 'CHART_CONFIG_UPDATE_UOM',
  CHART_CONFIG_UPDATE_YAXIS: 'CHART_CONFIG_UPDATE_YAXIS',
  CHART_CONFIG_UPDATE_LINE: 'CHART_CONFIG_UPDATE_LINE',
  CHART_CONFIG_UPDATE_COLOR: 'CHART_CONFIG_UPDATE_COLOR',
  CHART_CONFIG_SET_COLOR_PICKER_STATE: 'CHART_CONFIG_SET_COLOR_PICKER_STATE',

  CHART_CONFIG_DISCARD_DEFINITION: 'CHART_CONFIG_DISCARD_DEFINITION',
  CHART_CONFIG_SELECT_TAB: 'CHART_CONFIG_SELECT_TAB',
  CHART_CONFIG_SET_AXIS_PROPERTY_VALUE: 'CHART_CONFIG_SET_AXIS_PROPERTY_VALUE',
  CHART_CONFIG_SET_AXIS_AUTO: 'CHART_CONFIG_SET_AXIS_AUTO',
};