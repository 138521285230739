import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';

import fleetMapStyles from '../../fleetMapStyles';
import UnitAlarmBadge from '../unitAlarmBadge';

const styles = {
  ...fleetMapStyles,
};

class Chemvan extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.unitMapContainer}>
        <Box sx={styles.unitVisualContent}>
          <Box sx={styles.unitVisualHeaderContent}>
            <Typography variant={'subtitle1'}>{data.name}</Typography>
            <Box sx={styles.badgeContainer}>
              <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

Chemvan.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Chemvan;