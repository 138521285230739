import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';

import { Typography, Box, TextField, Button, InputAdornment, Autocomplete } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import * as expectedLifeActions from '../../../../state/displays/settings/actions/expectedLifeActions';
import { expectedLifeState } from '../../../../state/displays/settings/selectors/expectedLifeSelectors';
import { appState as applicationState } from '../../../../state/app/appSelectors';
import ComponentTypes from '../../../componentTypes';

import { trackPage } from '../../../../helpers/googleAnalyticsHelper';

const styles = {
  formContainer: {
    display: 'flex',
    width: '800px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '620px',
    padding: 3
  },
  formLabel: {
    marginTop: '4px'
  },
  formActionRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: 3,
    justifyContent: 'flex-end'
  },
  fieldsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  formField: {
    width: '260px',
    marginTop: 2
  },
  rightSideAction: {
    marginLeft: 3
  },
};

class ExpectedLifeSettings extends Component {

  componentDidMount() {
    trackPage(ComponentTypes.EXPECTED_LIFE_SETTINGS, this.props.user);

    this.props.queryExpectedLifeSettings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryExpectedLifeSettings();
    }
  }

  render() {
    const onSaveExpectedLifeSettings = (componentTypes) => {
      // Process the collection to remove all augmented UI properties. Extra properties makes the GraphQL API pissy
      this.props.saveExpectedLifeSettings(_.map(this.props.expectedLifeSelectedComponentTypes, componentType => {
        return {
          id: componentType.id,
          name: componentType.name,
          displayName: componentType.displayName,
          unitType: componentType.unitType,
          kind: componentType.kind,
          expectedLife: componentType.expectedLife
        };
      }));
    };

    return (
      <Box sx={styles.formContainer}>
        <Box sx={styles.form}>
          <div>
            {/* Here we are directly using Autocomplete instead of AutoCompleteMDT because
                we need to provide a different renderInput */}
            <Autocomplete
              sx={styles.formField}
              autoComplete={true}
              autoHighlight={true}
              options={this.props.expectedLifeUnitTypes}
              value={this.props.expectedLifeSelectedUnitType}
              onChange={(event, value, reason) => {this.props.setExpectedLifeUnitType(value)}}
              noOptionsText={'No unit types found...'}
              disableClearable
              renderInput={(params) => <TextField {...params} variant="standard" />} 
              popupIcon={<KeyboardArrowDownIcon/>}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <Typography variant={'caption'} sx={styles.formLabel}>Unit Type</Typography>
          </div>
          <Box sx={styles.fieldsContainer}>
            {
              this.props.expectedLifeSelectedComponentTypes.map((componentType, index) => {
                return (
                  <TextField
                    key={index}
                    sx={styles.formField}
                    variant='standard'
                    type='number'
                    value={componentType.expectedLife}
                    onChange={event => this.props.setExpectedLifeValue(componentType.id, event.target.value)}
                    helperText={componentType.label}
                    error={componentType.hasError}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Hours</InputAdornment>,
                      inputProps: {
                        max:100000,
                        min:0
                      }
                    }}
                  />
                )
              })
            }
          </Box>
          <Box sx={styles.formActionRow}>
            <Button
              sx={{...styles.rightSideAction,
                backgroundColor:'grey.200',
                color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                '&:hover': {
                  backgroundColor:'grey.400',
                  color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                }
              }}
              variant='contained'
              disabled={!this.props.expectedLifeCanSave}
              onClick={() => this.props.revertForm()}>
              Discard Changes
            </Button>
            <Button
              sx={styles.rightSideAction}
              variant='contained'
              color='primary'
              disabled={!this.props.expectedLifeCanSave}
              onClick={() => onSaveExpectedLifeSettings(this.props.expectedLifeSelectedComponentTypes)}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = expectedLifeState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    expectedLifeUnitTypes: componentState.expectedLife.expectedLifeUnitTypes,
    expectedLifeSelectedUnitType: componentState.expectedLife.expectedLifeSelectedUnitType,
    expectedLifeSelectedComponentTypes: componentState.expectedLife.expectedLifeSelectedComponentTypes,
    expectedLifeCanSave: componentState.expectedLife.expectedLifeCanSave,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryExpectedLifeSettings: () => { dispatch(expectedLifeActions.queryExpectedLifeSettings(props.stateDef)) },
    saveExpectedLifeSettings: (componentTypes) => { dispatch(expectedLifeActions.saveExpectedLifeSettings(props.stateDef, componentTypes)) },
    setExpectedLifeUnitType: (unitType) => { dispatch(expectedLifeActions.setExpectedLifeUnitType(props.stateDef, unitType)) },
    setExpectedLifeValue: (componentTypeId, expectedLife) => { dispatch(expectedLifeActions.setExpectedLifeValue(props.stateDef, componentTypeId, expectedLife)) },
    revertForm: () => { dispatch(expectedLifeActions.revertForm(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ExpectedLifeSettings))