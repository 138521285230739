import _ from 'lodash';

const reducerMapFactory = (reducers) => {
  return (state, action) => {
    let entry = _.find(reducers, {type: action.stateDef.type});
    if (!_.isNil(entry)) {
      return entry.reducer(state, action)
    } else {
      return state
    }
  }
};

export default reducerMapFactory