import defaultActionFactory from "../../../common/factories/defaultActionFactory";
import queryActionCustomFactory from "../../../common/factories/queryActionCustomFactory";
import routeAssetMgmtTypes from "../routeAssetMgmtActionTypes";

import { fetchRouteAssets, fetchConsumers, fetchTrucks } from "../routeAssetMgmtQueries";
import errorMessages from "../../../common/errorMessages";
import { handleError } from "../../../app/actions/appErrorActions";

const setCurrentPage = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_SET_CURRENT_PAGE, 'stateDef', 'currentPage');
const setRouteAssetsPerPage = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_SET_ROUTE_ASSETS_PER_PAGE, 'stateDef', 'routeAssetsPerPage');

const queryRouteAssetsStarting = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_STARTING, 'stateDef');
const queryRouteAssetsSuccess = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_SUCCESS, 'stateDef', 'queryResults');
const queryRouteAssetsError = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_ERROR, 'stateDef');

const queryRouteAssets = queryActionCustomFactory(
    queryRouteAssetsStarting,
    queryRouteAssetsSuccess,
    queryRouteAssetsError,
    errorMessages.ERROR_RETRIEVING_TRUCKS,
    fetchRouteAssets
);

const queryConsumersStarting = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_STARTING, 'stateDef'); 
const queryConsumersSuccess = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_SUCCESS, 'stateDef', 'results'); 
const queryConsumersError = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_ERROR, 'stateDef');

const queryConsumers = queryActionCustomFactory(
    queryConsumersStarting,
    queryConsumersSuccess,
    queryConsumersError,
    errorMessages.ERROR_RETRIEVING_CONSUMERS,
    fetchConsumers, 
);

const queryTrucksStarting = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_STARTING, 'stateDef');
const queryTrucksSuccess = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_SUCCESS, 'stateDef', 'results');
const queryTrucksError = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_ERROR, 'stateDef');

const queryTrucks = queryActionCustomFactory(
    queryTrucksStarting,
    queryTrucksSuccess,
    queryTrucksError,
    errorMessages.ERROR_RETRIEVING_TRUCKS,
    fetchTrucks,
);

const loadDisplay = (stateDef) => {
  return async (dispatch, getState) => {
    await dispatch(queryTrucks(stateDef));
    await dispatch(queryConsumers(stateDef));
    await dispatch(queryRouteAssets(stateDef));
  }
}

const openAddRouteAssetDialog = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_OPEN_ADD_ROUTE_ASSET_DIALOG, 'stateDef');
const openAddConsumerDialog = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_OPEN_ADD_CONSUMER_DIALOG, 'stateDef');
const closeAddConsumerDialog = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_CLOSE_ADD_CONSUMER_DIALOG, 'staeDef');
const openDeleteConfirmation = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_OPEN_DELETE_CONFIRMATION, 'stateDef', 'routeAsset', 'consumer');
const openEditDialog = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_OPEN_EDIT_DIALOG, 'stateDef', 'routeAsset');
const openEditConsumerDialog = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_OPEN_EDIT_CONSUMER_DIALOG, 'stateDef', 'consumer');
const setActiveStateStarting = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_SET_ACTIVE_STARTING, 'stateDef');
const setActiveStateSuccess = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_SET_ACTIVE_SUCCESS, 'stateDef', 'results');
const setActiveStateError = defaultActionFactory(routeAssetMgmtTypes.ROUTE_ASSET_MGMT_SET_ACTIVE_ERROR, 'stateDef');

const setActiveState = (stateDef) => {
  return async (dispatch, getState) => {
      try {
          await dispatch(queryRouteAssetsStarting(stateDef));
          const results = await fetchRouteAssets();
          await dispatch(queryRouteAssetsSuccess(stateDef, results));
      } catch (e) {
          await dispatch(queryRouteAssetsError(stateDef));
          return dispatch(handleError(errorMessages.ERROR_RETRIEVING_TRUCKS, e.message));
      }
  };
};

export {
    setCurrentPage,
    setRouteAssetsPerPage,
    queryRouteAssets,
    queryConsumers,
    queryRouteAssetsStarting,
    queryRouteAssetsSuccess,
    queryRouteAssetsError,
    openAddRouteAssetDialog,
    openAddConsumerDialog,
    closeAddConsumerDialog,
    openEditDialog,
    openEditConsumerDialog,
    openDeleteConfirmation,
    setActiveStateStarting,
    setActiveStateSuccess,
    setActiveStateError,
    setActiveState,
    queryTrucks,
    loadDisplay
}