export default {

  QUERY_DATA_STARTING: 'QUERY_DATA_STARTING',
  QUERY_DATA_SUCCESS: 'QUERY_DATA_SUCCESS',
  QUERY_DATA_ERROR: 'QUERY_DATA_ERROR',

  RESOLVE_FILTERS: 'RESOLVE_FILTERS',

  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

  SET_SUBSCRIPTION_NAME: 'SET_SUBSCRIPTION_NAME',

  SELECT_EVENT: 'SELECT_EVENT',
  SELECT_EVENT_TYPE: 'SELECT_EVENT_TYPE',

  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SELECT_FILTER_ATTRIBUTE: 'SELECT_FILTER_ATTRIBUTE',
  SELECT_FILTER_OPERATOR: 'SELECT_FILTER_OPERATOR',
  SET_FILTER_VALUE: 'SET_FILTER_VALUE',
  SET_FILTER_MULTI_VALUES: 'SET_MULTI_FILTER_VALUE',

  ADD_RECIPIENT: 'ADD_RECIPIENT',
  REMOVE_RECIPIENT: 'REMOVE_RECIPIENT',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SET_DESTINATION: 'SET_DESTINATION',

  CLEAR_FORM: 'CLEAR_FORM'
}