import _ from 'lodash';

const fetchFleetOverview = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let count = _.random(1, simData.length);
      let fleets = _.sampleSize(simData, count);
      resolve({
        fracOverview: fleets
      })
    }, 1000)
  });
};

const simData = [
  { id: 17, name: 'F.M.S', region: null, division: null, },
  { id: 18, name: 'K&K', region: null, division: 'Frac', },
  { id: 6, name: 'ETX-1', region: 'East', division: 'Wireline', },
  { id: 14, name: 'R&T Test', region: 'Mid-Con', division: null, },
  { id: 20, name: '790025', region: null, division: 'Frac', },
  { id: 10, name: 'WTX-2', region: 'West', division: 'Wireline', },
  { id: 21, name: 'WTX-7', region: 'West', division: null, },
  { id: 7, name: 'WTX-8', region: 'West', division: 'Frac', },
  { id: 22, name: 'STX-SCS', region: 'South', division: 'Wireline', },
  { id: 1, name: 'WTX-4', region: 'West', division: null, },
  { id: 4, name: 'STX-1', region: 'South', division: 'Frac', },
  { id: 72, name: '970025', region: null, division: 'Wireline', },
  { id: 25, name: 'WTX-6', region: 'West', division: null, },
  { id: 5, name: 'STX-2', region: 'South', division: 'Frac', },
  { id: 26, name: 'WTX-SCS1', region: 'West', division: 'Wireline', },
  { id: 74, name: 'WTX-X', region: 'West', division: null, },
  { id: 9, name: 'WTX-3', region: 'West', division: 'Frac', },
  { id: 24, name: 'NE-2', region: 'North East', division: 'Wireline', },
  { id: 43, name: 'NE-1', region: 'North East', division: null, },
  { id: 8, name: 'MC-3', region: 'Mid-Con', division: 'Frac', },
  { id: 12, name: 'MC-2', region: 'Mid-Con', division: 'Wireline', },
  { id: 3, name: 'MC-4', region: 'Mid-Con', division: null, },
  { id: 23, name: 'NW-1', region: 'North West', division: 'Frac', },
  { id: 19, name: 'San Angelo', region: null, division: 'Wireline', },
  { id: 13, name: 'MC-1', region: 'Mid-Con', division: null, }
];


export {
  fetchFleetOverview,
}
