import _ from 'lodash';
import fleetActivityActionTypes from './fleetActivityActionTypes';
import { fleetActivityState } from './fleetActivitySelectors';

const initialState = fleetActivityState();

const fleetActivityReducer = (state=initialState, action) => {

  switch (action.type) {

    case (fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_STARTING):
      return { ...state, queryRunning: true };

    case (fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_SUCCESS):
      return onQueryDataSuccess(state, action);

    case (fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_ERROR):
      return { ...state, queryRunning: false };

    case (fleetActivityActionTypes.FLEET_ACTIVITY_CLEAR_DATA):
      return onClearData(state, action);

    default: return state;
  }
};


const onQueryDataSuccess = (state, action) => {

  const noValue = '-';

  let pumping = null, idle = null, offline = null, days = null;

  //JG: Unlike all our other cards, fleetActivity returns null for the entire response when it fails to find data. In all
  //other cases, we will return an empty object. We have to do it this way because for some unknown reason, when the fleet
  //activity code in the card data service returns an empty object in the cloud, it results in the response never coming back
  //to the UI and eventually timing out. This does not happen in any other case, nor does it happen outside the cloud. It is
  //a mystery, but returning null appears to solve it. As a result this code is slightly different than in our other reducers
  //as it has to handle the case where fleet activity itself in null, rather than just its properties being null.
  if(action.queryResults.fleetActivity !== null) {
    pumping = action.queryResults.fleetActivity.pumping;
    idle = action.queryResults.fleetActivity.idle;
    offline = action.queryResults.fleetActivity.offline;
    days = action.queryResults.fleetActivity.days;
  }

  let displayValuePumping = _.isNil(pumping) ? noValue : parseFloat(pumping.toFixed(0)).toLocaleString('en') + '%';
  let displayValueIdle = _.isNil(idle) ? noValue : parseFloat(idle.toFixed(0)).toLocaleString('en')  + '%';
  let displayValueOffline = _.isNil(offline) ? noValue : parseFloat(offline.toFixed(0)).toLocaleString('en')  + '%';

  let displayDays = null;
  if (!_.isNil(days)) {
    if (days > 1) {
      displayDays = '{days} days'.replace('{days}', days);
    } else if (days === 1) {
      displayDays = '{days} day'.replace('{days}', days);
    }
  }

  return {
    ...state,
    queryRunning: false,
    rawValuePumping: pumping,
    rawValueIdle: idle,
    rawValueOffline: offline,
    displayDays: displayDays,
    displayValuePumping: displayValuePumping,
    displayValueIdle: displayValueIdle,
    displayValueOffline: displayValueOffline,
  };
};

const onClearData = (state, action) => {

  return {
    ...state,
    queryRunning: false,
    rawValuePumping: initialState.rawValuePumping,
    rawValueIdle: initialState.rawValueIdle,
    rawValueOffline: initialState.rawValueOffline,
    displayDays: initialState.displayDays,
    displayValuePumping: initialState.displayValuePumping,
    displayValueIdle: initialState.displayValueIdle,
    displayValueOffline: initialState.displayValueOffline,
  };

};

export default fleetActivityReducer