import componentsActionTypes from '../componentsActionTypes';
import { editComponentState, addComponentState } from '../componentsSelectors';

import * as validationService from '../services/validataionService';

const displayReducer = (state, action) => {

  switch (action.type) {

    case componentsActionTypes.COMPONENTS_SET_EDIT_MODE:
      return onSetEditMode(state, action);

    case componentsActionTypes.COMPONENTS_SET_NAVIGATION_CONTEXT:
      return onSetNavigationContext(state, action);

    case componentsActionTypes.COMPONENTS_OPEN_DELETE_CONFIRMATION:
      return onOpenDeleteConfirmation(state, action);

    case componentsActionTypes.COMPONENTS_CLOSE_DELETE_CONFIRMATION:
      return onCloseDeleteConfirmation(state, action);

    case componentsActionTypes.COMPONENTS_OPEN_CALCULATOR:
      return onOpenCalculator(state, action);

    case componentsActionTypes.COMPONENTS_CLOSE_CALCULATOR:
      return onCloseCalculator(state, action);

    default: return state;

  }
};

const onSetEditMode = (state, action) => {

  let formState = action.editMode ? editComponentState(state.selectedComponent) : addComponentState();

  let newState = {
    ...state,
    ...formState,
    editMode: action.editMode,
  };

  return {
    ...newState,
    ...validationService.resolveValidationState(newState)
  };
};

const onSetNavigationContext = (state, action) => {

  return {
    ...state,
    navigationContextUnitId: action.unitId,
    navigationContextComponentType: action.componentType,
  }
};

const onOpenDeleteConfirmation = (state, action) => {
  return {
    ...state,
    openDeleteConfirmation: true,
  }
};

const onCloseDeleteConfirmation = (state, action) => {
  return {
    ...state,
    openDeleteConfirmation: false,
  }
};

const onOpenCalculator = (state, action) => {
  return {
    ...state,
    openCalculator: true,
    calculatorInput: 0,
    hasErrorCalculatorInput: false,
  }
};

const onCloseCalculator = (state, action) => {
  return {
    ...state,
    openCalculator: false,
  }
};

export default displayReducer