import _ from 'lodash';

const coilDashboardState = state => {

    return {

        // The list of coil unit trucks (the available contexts for the cards)
        contexts: _.isNil(state) ? [] : state.contexts,

        coilUnits: _.isNil(state) ? [] : state.coilUnits,

        // The selected coil unit truck (the context for the cards)
        selectedContext: _.isNil(state) ? null : state.selectedContext,

        dataAgeActual: _.isNil(state) ? '' : state.dataAgeActual,
        dataAgeDisplay: _.isNil(state) ? '-' : state.dataAgeDisplay,
    }
};

export {
    coilDashboardState
};