import fleetOverviewActionTypes from '../fleetOverviewActionTypes';
import * as fleetOverviewService from "../services/fleetOverviewService";

const pagingReducer = (state, action) => {

  if (action.type === fleetOverviewActionTypes.FLEET_OVERVIEW_SET_CURRENT_PAGE) {
    return onSetCurrentPage(state, action);
  }

  return state;
};

const onSetCurrentPage = (state, action) => {

  // Reprocess the fleets
  let processDetails = fleetOverviewService.processFleets(state.fleets, state.appliedFilters, action.currentPage);

  return {
    ...state,
    pagedFleets: processDetails.pagedFleets,
    currentPage: action.currentPage,
  };
};

export default pagingReducer