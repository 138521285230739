import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Typography} from '@mui/material';
import ComponentTypes from "../../componentTypes";
import * as fleetMgmtActions from "../../../state/displays/fleetManagement/fleetMgmtActions";
import {fleetMgmtState} from "../../../state/displays/fleetManagement/fleetMgmtSelectors";

class RestartProducerConfirmationDialog extends Component {
  render() {
      return (<Dialog
              open={this.props.showRestartProducerDialog}
              keepMounted>
              <DialogTitle>Restart Cloud Producer</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                    <Typography variant='body1' component="span">Restarting the Cloud Producer will interrupt the data upload from fleet <strong>{this.props.fleetToSave.fleetName}</strong>.</Typography>
                    <br/><br/>
                    <Typography variant='body1' component="span">Are you sure you want to restart the Cloud Producer on fleet <strong>{this.props.fleetToSave.fleetName}</strong>?</Typography>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button color='inherit' onClick={() => this.props.onShowRestartProducerDialog(false)}>No, Cancel</Button>
                  <Button color='primary' onClick={() => this.props.onConfirmRestartProducer()}>Yes, Restart</Button>
              </DialogActions>
          </Dialog>
      );
  }
}

const stateDefinition = (props) => {
  return {
      stateDef: {
          key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
          type: ComponentTypes.FLEET_MANAGEMENT,
      }
  }
};

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let componentState = fleetMgmtState(state[stateDef.key]);
  return {
      fleetToSave: componentState.fleetToSave,
      showRestartProducerDialog: componentState.showRestartProducerDialog
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
      onShowRestartProducerDialog: (show) => {
          dispatch(fleetMgmtActions.showRestartProducerDialog(props.stateDef, null, show));
      },
      onConfirmRestartProducer: () => {
          dispatch(fleetMgmtActions.confirmRestartProducer(props.stateDef));
      }
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(RestartProducerConfirmationDialog))