import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import unitHoursDfpActionTypes from './unitHoursDfpActionTypes';
import * as unitHoursDfpQueries from './unitHoursDfpQueries';
import errorMessages from '../../common/errorMessages';
import unitChartDfpTypes from './unitHoursDfpTypes';

const clearData = defaultActionFactory(unitHoursDfpActionTypes.UNIT_HOURS_DFP_CLEAR_DATA, 'stateDef');

const queryData = (stateDef, chartType, truckId) => {
  return async (dispatch, getState) => {
    switch (chartType) {
      case unitChartDfpTypes.CURBSIDE:
        await dispatch(queryCurbsideData(stateDef, truckId));
        break;
      case unitChartDfpTypes.ROADSIDE:
        await dispatch(queryRoadsideData(stateDef, truckId));
        break;
      default:
        throw Error('Unknown chart type');
    }
  }
};

const queryCurbsideData = queryActionFactory(
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_STARTING,
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_SUCCESS,
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_HOURS_CURBSIDE_CARD_DATA,
  unitHoursDfpQueries.fetchUnitHoursCurbsideCardData
);

const queryRoadsideData = queryActionFactory(
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_STARTING,
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_SUCCESS,
  unitHoursDfpActionTypes.UNIT_HOURS_DFP_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_HOURS_ROADSIDE_CARD_DATA,
  unitHoursDfpQueries.fetchUnitHoursRoadsideCardData
);

export {
  queryData,
  clearData
}