import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';

import fleetActivityActionTypes from './fleetActivityActionTypes';
import fetchFleetActivityCardData from './fleetActivityQueries';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetActivityActionTypes.FLEET_ACTIVITY_CLEAR_DATA, 'statedef');

const queryData = queryActionFactory(
    fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_STARTING,
    fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_SUCCESS,
    fleetActivityActionTypes.FLEET_ACTIVITY_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_FLEET_ACTIVITY_CARD_DATA,
    fetchFleetActivityCardData
);

export {
  clearData,
  queryData
}
