import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import exportCsvActionTypes from './exportCsvActionTypes';
import * as exportCsvService from './exportCsvService';
import {handleError} from '../../app/actions/appErrorActions';
import {fetchExportCsv} from './exportCsvQueries';

import errorMessages from '../errorMessages';

const exportCsvStarting = defaultActionFactory(exportCsvActionTypes.EXPORT_CSV_STARTING, 'stateDef');
const exportCsvSuccess = defaultActionFactory(exportCsvActionTypes.EXPORT_CSV_SUCCESS, 'stateDef', 'queryResults');
const exportCsvError = defaultActionFactory(exportCsvActionTypes.EXPORT_CSV_ERROR, 'stateDef');

const exportCsvFactory = (filename, columns, errorMessage) => {
  return function (stateDef, ...csvParams) {
    return async (dispatch, getState) => {
      let queryResults = null;
      try {
        await dispatch(exportCsvStarting(stateDef));
        if (!_.isEmpty(columns)) {
          csvParams.splice(1, 0, columns);
        }
        let csv = exportCsvService.generateCSV(...csvParams);
        queryResults = await fetchExportCsv(filename, csv);
      } catch(e) {
        await dispatch(exportCsvError(stateDef));
        // If error message has been provided, use that instead
        // Otherwise use the default
        return dispatch(handleError(!_.isNil(errorMessage) ? errorMessage : errorMessages.ERROR_EXPORTING_CSV, e.message));
      }
      return dispatch(exportCsvSuccess(stateDef, queryResults));
    }
  }
};

export {
  exportCsvFactory
};