import defaultActionFactory from '../../common/factories/defaultActionFactory';

import appActionTypes from '../appActionTypes';

const expandContextDrawer = defaultActionFactory(appActionTypes.APP_CONTEXT_OPEN_DRAWER, 'open');
const setContextDrawerWidth = defaultActionFactory(appActionTypes.APP_CONTEXT_SET_DRAWER_WIDTH, 'width');

const openContextDrawer = (open, width) => {
  return async(dispatch, getState) => {
    if (open && !_.isNil(width)) {
      await dispatch(setContextDrawerWidth(width));
    }
    await dispatch(expandContextDrawer(open));
  }
}

const setContextType = defaultActionFactory(appActionTypes.APP_CONTEXT_SET_TYPE, 'contextType');
const setContext = defaultActionFactory(appActionTypes.APP_CONTEXT_SET, 'contextType', 'contextData');
const minimizeContextDrawer = defaultActionFactory(appActionTypes.APP_CONTEXT_MINIMIZE_DRAWER);

export {
  openContextDrawer,
  setContextType,
  setContext,
  minimizeContextDrawer,
  setContextDrawerWidth,
};