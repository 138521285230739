import React, { Fragment } from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import ComponentTypes from "../../componentTypes";
import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel, mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";
import {Box, Card, Checkbox, IconButton, Tooltip, Typography, TextField} from "@mui/material";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {Container, Draggable} from "@edorivai/react-smooth-dnd";
import Grid from "@mui/system/Unstable_Grid";
import * as auditLogActions from "../../../state/cards/auditLog/auditLogActions";
import {getCardFromLayoutConfigViews} from "../../common/layout/layoutHelper";
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";

class UnitAuditLogConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        defaultTitle: 'AUDIT LOG',
        availableSizes: []
    };

    getRenderedContent() {
        const currentConfig = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard].views, this.props.card.view, this.props.card.stateKey);
        const rowsizeOptions = currentConfig.configuration.rowSizes.map( size => { return { label: `${size}`, value: size}});

        return (
            <Box sx={{ width: '415px'}}>
            {/* box for row size config */}
            <Box sx={{marginBottom: 3}}>
                <AutoCompleteMDT
                    options={rowsizeOptions}
                    value={rowsizeOptions.find(size => size.value == currentConfig.configuration.rowSize)}
                    getOptionLabel={(option) => option.label}
                    noOptionsText={'No row sizes available...'}
                    renderInput={(params) => <TextField {...params} variant="standard" helperText="List Size"
                                                        inputProps={{ ...params.inputProps}}/>}
                    onChange={(event, value) => {
                        this.props.onChangeRowSize(value.value);
                    }}
                />
            </Box>
            {/* box for column config */}
            <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 350px)"}}>
                <Container lockAxis='y' dragHandleSelector=".drag-handle" onDrop={this.props.onReorderColumns}>
                    { _.map(currentConfig.configuration.columns, (column, index) => { return (
                        <Fragment key={index}>
                            <Draggable>
                                <Card sx={{marginBottom: 1}}>
                                    <Grid container alignItems={'center'} sx={{minHeight:'60px'}}>
                                        <Grid sx={{ width: '300px', height: '100%', paddingLeft: 2 }}>
                                            {/*for display name: add space between each uppercase character*/}
                                            <Typography  size='small' variant='standard'>{column.name.replace(/([A-Z])/g, ' $1').trim()}</Typography>
                                        </Grid>
                                        <Grid sx={{ width: '85px', height: '100%'}}>
                                            <Checkbox
                                                checked={column.visible}
                                                onChange={(event) => this.props.onToggleColumnVisibility(index)}
                                            />
                                            <IconButton className="drag-handle">
                                                <Tooltip title='Move component' disableInteractive={true}>
                                                    <DragHandleIcon/>
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Draggable>
                        </Fragment>
                    )})}
                </Container>
            </Box>
            </Box>
        )

    }

    hasConfigChanged(dashboard, view, cardKey) {
        let configChanged = super.hasConfigChanged(dashboard, view, cardKey);

        const currentDashboard = this.props.dashboards[dashboard];
        const card = getCardFromLayoutConfigViews(currentDashboard?.views, view, cardKey);
        const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

        if (_.isNil(originalCard)) return configChanged;

        const columnsChanged = !_.isEqual(card?.configuration.columns, originalCard?.configuration.columns);

        return configChanged || columnsChanged;
    };
}

UnitAuditLogConfigPanel.proptypes = mdtCardConfigPanelPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_AUDIT_LOG : props.stateKey,
            type: ComponentTypes.UNIT_AUDIT_LOG,
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        ...mdtCardConfigMapStateToProps(state, props)
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardConfigMapDispatchToProps(dispatch, props),
        onReorderColumns: ({ removedIndex, addedIndex }) => {
            dispatch(auditLogActions.onReorderColumns(props.stateDef, props.card.dashboard, props.card.view,props.card.card.i, removedIndex, addedIndex));
        },
        onToggleColumnVisibility: (componentIndex) => {
            dispatch(auditLogActions.onToggleColumnVisibility(props.stateDef, props.card.dashboard, props.card.view,props.card.card.i, componentIndex));
        },
        onChangeRowSize: (rowSize) => {
            dispatch(auditLogActions.onChangeRowSize(props.stateDef, props.card.dashboard, props.card.view,props.card.card.i, rowSize));
        }
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UnitAuditLogConfigPanel));