import React, { Component } from 'react';
import _ from 'lodash';

import { Typography, Box } from '@mui/material';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { mdtPalette } from '../../../common/styles/mdtPalette';
import getCardStyles from '../../cardStyles';

const cardStyles = getCardStyles();

const styles = {
  ...cardStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
  },
  resolution: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },

  resolutionLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },

  chartContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },

  chart: {
    display: 'flex',
    alignItems: 'center',
  },

  legendContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 2
  },

  legend: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '& h6': {
      marginLeft: '17px',
    }
  },

  percentageLabel: {
    width: '39px',
    marginLeft: '17px',
  }
};

class ActivityCard extends Component {

  render() {

    const {
      chartOptions,
      labelPumping,
      labelIdle,
      labelStopped,
      displayValueDays,
      displayValuePumping,
      displayValueIdle,
      displayValueStopped,
    } = this.props;

    return (
        <Box sx={styles.cardContent}>
          <Box sx={styles.resolution}>
            <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>{displayValueDays}</Typography>
          </Box>
          <Box sx={styles.chart}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Box>
          <Box sx={styles.legendContainer}>
            <Box sx={styles.legend}>
              <svg width="18" height="18"><rect width="18" height="18" style={{fill: mdtPalette().status.level2}} /></svg>
              <Typography sx={styles.percentageLabel} variant={'subtitle1'}>{displayValuePumping}</Typography>
              <Typography variant={'subtitle1'}>{_.isNil(labelPumping) ? 'Pumping' : labelPumping}</Typography>
            </Box>
            <Box sx={styles.legend}>
              <svg width="18" height="18"><rect width="18" height="18" style={{fill: mdtPalette().status.level4}} /></svg>
              <Typography sx={styles.percentageLabel} variant={'subtitle1'}>{displayValueIdle}</Typography>
              <Typography variant={'subtitle1'}>{_.isNil(labelIdle) ? 'Idle' : labelIdle}</Typography>
            </Box>
            <Box sx={styles.legend}>
              <svg width="18" height="18"><rect width="18" height="18" style={{fill: mdtPalette().status.level1}} /></svg>
              <Typography sx={styles.percentageLabel} variant={'subtitle1'}>{displayValueStopped}</Typography>
              <Typography variant={'subtitle1'}>{_.isNil(labelStopped) ? 'Stopped' : labelStopped}</Typography>
            </Box>
          </Box>
        </Box>
    )
  }
}

export default ActivityCard;
