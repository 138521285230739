import _ from 'lodash';
import moment from 'moment';

import errorMessages from '../../common/errorMessages';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import { handleError } from '../../app/actions/appErrorActions';

import {
  fetchCustomers,
  fetchJobsForTimeFrame,
  fetchSaveJob,
  fetchSaveJobWells,
  fetchActiveJobs,
  fetchPlannedJobs,
  fetchJobMetadata,
  fetchDeleteJob
} from './jobHistoryQueries';
import jobHistoryActionTypes from './jobHistoryActionTypes';
import * as navActions from "../../app/actions/appNavActions";

const showCreateEditJobDialog = defaultActionFactory(jobHistoryActionTypes.SHOW_CREATE_EDIT_JOB_DIALOG, 'stateDef', 'show', 'job');
const showDeleteJobDialog = defaultActionFactory(jobHistoryActionTypes.SHOW_DELETE_JOB_DIALOG, 'stateDef', 'show', 'job');
const dataGridSetRowSize = defaultActionFactory(jobHistoryActionTypes.SET_ROW_SIZE, 'stateDef', 'rowSize');
const createEditSetStartDate = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_START_DATE, 'stateDef', 'startDate');
const createEditSetEndDate = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_END_DATE, 'stateDef', 'endDate');
const createEditSetStartDateDisplay = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_START_DATE_DISPLAY, 'stateDef', 'startDate');
const createEditSetEndDateDisplay = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_END_DATE_DISPLAY, 'stateDef', 'endDate');
const createEditSelectCustomer = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SELECT_CUSTOMER, 'stateDef', 'customer');
const createEditSetJobName = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_JOB_NAME, 'stateDef', 'name');
const createEditSelectDatavan = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SELECT_DATAVAN, 'stateDef', 'datavan');
const createEditSelectJobType = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SELECT_JOBTYPE, 'stateDef', 'jobType');
const createEditSelectContact = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SELECT_CONTACT, 'stateDef', 'contact');

const createEditAddWell = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_ADD_WELL, 'stateDef', 'jobId');
const createEditRemoveWell = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_REMOVE_WELL, 'stateDef', 'wellId');
const createEditSelectWell = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SELECT_WELL, 'stateDef', 'wellId', 'well');
const createEditSetPlannedStages = defaultActionFactory(jobHistoryActionTypes.CREATE_EDIT_SET_PLANNED_STAGES, 'stateDef', 'wellId', 'stage');

const rowClick = defaultActionFactory(jobHistoryActionTypes.ROW_CLICK, 'stateDef', 'rowId');

const queryCustomers = queryActionFactory(
  jobHistoryActionTypes.QUERY_CUSTOMERS_STARTING,
  jobHistoryActionTypes.QUERY_CUSTOMERS_SUCCESS,
  jobHistoryActionTypes.QUERY_CUSTOMERS_ERROR,
  errorMessages.ERROR_RETRIEVING_CUSTOMERS_DISPLAY_DATA,
  fetchCustomers
);

const queryJobsForTimeFrame = queryActionFactory(
  jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_STARTING,
  jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_SUCCESS,
  jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_ERROR,
  errorMessages.ERROR_RETRIEVING_JOBS_HISTORY,
  fetchJobsForTimeFrame
);

const queryActiveJobs = queryActionFactory(
  jobHistoryActionTypes.QUERY_ACTIVE_JOBS_STARTING,
  jobHistoryActionTypes.QUERY_ACTIVE_JOBS_SUCCESS,
  jobHistoryActionTypes.QUERY_ACTIVE_JOBS_ERROR,
  errorMessages.ERROR_RETRIEVING_JOBS_HISTORY,
  fetchActiveJobs
);

const queryPlannedJobs = queryActionFactory(
  jobHistoryActionTypes.QUERY_PLANNED_JOBS_STARTING,
  jobHistoryActionTypes.QUERY_PLANNED_JOBS_SUCCESS,
  jobHistoryActionTypes.QUERY_PLANNED_JOBS_ERROR,
  errorMessages.ERROR_RETRIEVING_JOBS_HISTORY,
  fetchPlannedJobs
);

const queryJobMetadata = queryActionFactory(
  jobHistoryActionTypes.QUERY_JOB_METADATA_STARTING,
  jobHistoryActionTypes.QUERY_JOB_METADATA_SUCCESS,
  jobHistoryActionTypes.QUERY_JOB_METADATA_ERROR,
  errorMessages.ERROR_RETRIEVING_JOB_METADATA,
  fetchJobMetadata
);

const selectTimeFrame = defaultActionFactory(jobHistoryActionTypes.SELECT_TIMEFRAME, 'stateDef', 'timeFrame');

const loadDisplay = (stateDef, maxTimeFrame) => {
  return async (dispatch, getState) => {

    await dispatch(queryCustomers(stateDef));
    await dispatch(queryJobMetadata(stateDef));

    let selectedTimeFrame = getState()[stateDef.key].selectedTimeFrame;
    if(maxTimeFrame === true){
      const last90Days = getState()[stateDef.key].timeFrames.find(timeFrame => timeFrame.label === 'Last 90 Days');
      if(!_.isNil(last90Days)){
        //Set the time frame to the max one available in the current display to include as much as jobs within the query
        selectedTimeFrame = last90Days;
      }
    }
    await dispatch(selectTimeFrameAndLoadJobs(stateDef, selectedTimeFrame));
  };
};

const selectTimeFrameAndLoadJobs = (stateDef, timeFrame) => {
  return async (dispatch, getState) => {
    if (!_.isNil(timeFrame)) {
      // Select the timeframe
      await dispatch(selectTimeFrame(stateDef, timeFrame));

      //This is where we determine what query to call from Portal App Service
      //Either Jobs for time frame, active, or planned
      if(timeFrame.value === 1) {
        await dispatch(queryPlannedJobs(stateDef));
      } else if (timeFrame.value === 2) {
        await dispatch(queryActiveJobs(stateDef));
      } else {
        await dispatch(queryJobsForTimeFrame(stateDef, timeFrame));
      }
    }
  };
};

const saveJobStarting = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_STARTING, 'stateDef');
const saveJobSuccess = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_SUCCESS, 'stateDef', 'queryResults');
const saveJobError = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_ERROR, 'stateDef');

const saveJob = (stateDef, jobId, jobName, jobType, datavan, customerId, contactName, start, end, wells) => {
  return async (dispatch, getState) => {

    let queryResults = null;
    try {
      await dispatch(saveJobStarting(stateDef));

      let jobToSaveInput = {
        id: jobId,
        jobName: jobName,
        jobTypeId: jobType.value,
        datavan: _.isNil(datavan) ? null : datavan.label,
        customerId: customerId,
        contactName: contactName,
        startDate: moment(start*1000).unix(),
        endDate: moment(end*1000).unix()
      }

      queryResults = await fetchSaveJob(jobToSaveInput);

      if (!_.isNil(wells) && !_.isEmpty(wells)) {
        await dispatch(saveJobWells(stateDef, _.isNil(jobId) ? queryResults.saveJob.id : jobId, wells));
      }

      await dispatch(saveJobSuccess(stateDef, queryResults));

      let selectedTimeFrame = getState()[stateDef.key].selectedTimeFrame;
      await dispatch(refreshDisplay(stateDef, selectedTimeFrame));
    }
    catch(e) {
      await dispatch(saveJobError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_JOB_HISTORY, e.message));
    }
  }
};

const saveJobsWellsStarting = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_WELL_STARTING, 'stateDef');
const saveJobsWellsSuccess = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_WELL_SUCCESS, 'stateDef', 'queryResults');
const saveJobsWellsError = defaultActionFactory(jobHistoryActionTypes.SAVE_JOB_WELL_ERROR, 'stateDef');

const saveJobWells = (stateDef, jobId, jobWells) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(saveJobsWellsStarting(stateDef));

      let jobWellsInput = [];
      _.forEach(jobWells, (well) => {
        let jobWell = {
          jobId: jobId,
          wellId: well.wellId,
          plannedStages: _.isNil(well.plannedStages) || _.isEmpty(well.plannedStages.toString()) ? null : parseInt(well.plannedStages),
          toDelete: well.toDelete
        }
        jobWellsInput.push(jobWell);
      })

      queryResults = await fetchSaveJobWells(jobWellsInput);
      await dispatch(saveJobsWellsSuccess(stateDef, queryResults));
    }
    catch(e) {
      await dispatch(saveJobsWellsError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_JOB_WELLS, e.message));
    }
  }
};

const refreshDisplay = (stateDef, timeFrame) => {
  return async (dispatch, getState) => {
    if (!_.isNil(timeFrame)) {
      //This is where we determine what query to call from Portal App Service
      //Either Jobs for time frame, active, or planned
      if(timeFrame.value === 1) {
        await dispatch(queryPlannedJobs(stateDef));
      } else if (timeFrame.value === 2) {
        await dispatch(queryActiveJobs(stateDef));
      } else {
        await dispatch(queryJobsForTimeFrame(stateDef, timeFrame));
      }
    }
  }
};
const onFilterChange = defaultActionFactory(jobHistoryActionTypes.UPDATE_JOB_FILTER_MODEL, 'stateDef','filterModel');
const deleteJobStarting = defaultActionFactory(jobHistoryActionTypes.DELETE_JOB_STARTING, 'stateDef');
const deleteJobSuccess = defaultActionFactory(jobHistoryActionTypes.DELETE_JOB_SUCCESS, 'stateDef', 'queryResults');
const deleteJobError = defaultActionFactory(jobHistoryActionTypes.DELETE_JOB_ERROR, 'stateDef');

const deleteJob = (stateDef, jobId) => {
  return async (dispatch, getState) => {
    if (!_.isNil(jobId)) {
      let queryResults = null;
      try {
        await dispatch(deleteJobStarting(stateDef));

        queryResults = await fetchDeleteJob(jobId);

        await dispatch(deleteJobSuccess(stateDef, queryResults));

        let selectedTimeFrame = getState()[stateDef.key].selectedTimeFrame;
        await dispatch(refreshDisplay(stateDef, selectedTimeFrame));
      }
      catch(e) {
        await dispatch(deleteJobError(stateDef));

        return dispatch(handleError(errorMessages.ERROR_DELETING_JOB, e.message));
      }
    }
  }
};

export {
  showCreateEditJobDialog,
  dataGridSetRowSize,
  queryJobsForTimeFrame,
  loadDisplay,
  selectTimeFrameAndLoadJobs,
  createEditSetStartDate,
  createEditSetEndDate,
  createEditSetStartDateDisplay,
  createEditSetEndDateDisplay,
  createEditSelectCustomer,
  createEditAddWell,
  createEditRemoveWell,
  rowClick,
  createEditSelectWell,
  createEditSetPlannedStages,
  selectTimeFrame,
  queryCustomers,
  createEditSetJobName,
  createEditSelectDatavan,
  createEditSelectJobType,
  createEditSelectContact,
  saveJob,
  saveJobWells,
  refreshDisplay,
  deleteJob,
  showDeleteJobDialog,
  onFilterChange
};