import { appState } from './appSelectors';
import reduceReducers from "reduce-reducers";
import applicationReducer from "./reducers/applicationReducer";
import userConfigReducer from "./reducers/userConfigReducer";
import websocketReducer from './reducers/websocketReducer';

const initialState = appState();

const appReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
      applicationReducer,
      userConfigReducer,
      websocketReducer
  );

  return reducer(state, action);
};

export {
  appReducer as default,
  initialState,
}