import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import moment from "moment";

import { Box, Typography, IconButton, Button, Grid, Paper, Tooltip, Badge } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import {GridActionsCellItem, gridClasses, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridLinkOperator} from "@mui/x-data-grid-pro";

import { mdtPalette } from '../../common/styles/mdtPalette';
import Progress from '../../controls/progress';
import ComponentTypes from '../../componentTypes';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getDialogStyles from '../../common/styles/dialogStyles';
import {AutoCompleteMDT} from '../../controls/mdtMuiControls';
import MDTDataGrid from '../../common/table/MDTDataGrid';

import CreateEditJobDialog from './createEditJobDialog';
import { jobHistoryState } from '../../../state/displays/jobHistory/jobHistorySelectors';
import * as jobHistoryActions from '../../../state/displays/jobHistory/jobHistoryActions';
import DeleteJobDialog from './deleteJobDialog';
import {parse} from "query-string";

const dialogStyles = getDialogStyles();
const detailsPageStyles = getDetailsPageStyles();
const styles = {
  ...detailsPageStyles,

  detailsPage: {
    ...detailsPageStyles.detailsPage,
    height: '100vh'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw',
    height: 'inherit'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  detailsPageHeaderTitle: {
    ...detailsPageStyles.detailsPageHeaderTitle,
    width: '100px'
  },
  
  timeSelection: {
    paddingLeft: '10px',
    width: '200px'
  },

  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden'
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible'
      },
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: '#262626',
      '&:hover': {
        backgroundColor: '#424242',
        cursor: 'pointer'
      }
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#1e1e1e',
      '&:hover': {
        backgroundColor: '#424242',
        cursor: 'pointer'
      }
    },
  },

  headerRow: {
    height: '46px',
    backgroundColor: 'background.paper',
    marginTop: '5px',
  },
  headerCell: {
    padding: 1,
    '& h3': {
      fontSize: 'typography.fontSize'
    }
  },
  table: {
    boxShadow: 'none',
    backgroundColor: 'grey.900'
  },
  tableHeaderColumn: {
    fontSize: '0.875rem',
    paddingTop: '5px',
  },
  tableRow: {
    height: '42px'
  },
  tableCellTypography: {
    fontSize: '0.875rem'
  }
}

class JobHistoryDisplay extends Component {

  constructor(props) {
    super(props);

    // Generate the columns data for the data table.
    this.columns = this.generateTableColumns();
  }

  componentDidMount() {
    // This will use the parse function from query-string package to parse out the 'wellName' parameter
    const parsedLocation = parse(location.search);
    const filterWellName = parsedLocation.wellName;
    if(!_.isEmpty(filterWellName)){
      this.props.onFilterChange({
        items: [{ columnField: 'wells', operatorValue: 'contains', value: filterWellName }]
      })
    }
    this.props.onLoadDisplay(!_.isEmpty(filterWellName));
  }

  generateTableColumns() {
    let columns = [];
    columns.push({
      field: 'customerName',
      headerName: 'Customer',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'jobName',
      headerName: 'Job',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      hideable: false,
      pinnable: false,
      renderCell: (params) =>
      {
        return (
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'flex-end'}}>
            {
              (moment().isBefore(params.row.startDate)) &&
              <Tooltip followCursor title={'Planned'}> 
                <PendingActionsIcon size="small" sx={{marginRight: 1, color: mdtPalette().status.level3}} />
              </Tooltip>
            }
            {
              (moment().isBefore(params.row.endDate)) && (moment().isAfter(params.row.startDate)) &&
              <Tooltip followCursor title={'Active'}> 
                <PlayCircleOutlineIcon size="small" sx={{marginRight: 1, color: mdtPalette().status.level2}} />
              </Tooltip>
            }
            {
              (moment().isAfter(params.row.endDate)) && (moment().isAfter(params.row.startDate)) &&
              <Tooltip followCursor title={'Completed'}> 
                <DoneIcon size="small" sx={{marginRight: 1, color: mdtPalette().status.level1}} />
              </Tooltip>
            }
            {params.value}
          </Box>
        )
      },
      valueGetter: (params) => {
        if (_.isNil(params.row)) return '';
        return params.value;
      }
    });
    columns.push({
      field: 'datavan',
      headerName: 'Datavan',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'contactName',
      headerName: 'Contact',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'jobType',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'startDate',
      type: 'string',
      headerName: 'Start Date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 140,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'endDate',
      type: 'string',
      headerName: 'End Date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 140,
      editable: false,
      pinnable: false,
    });
    columns.push({
      field: 'wells',
      headerName: 'Wells',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      editable: false,
      hideable: false,
      pinnable: false,
      sortable: false,
      resizable: false,
      filterable: true,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params) => 
      {
        return (
          <Box>
            {
              (_.includes(this.props.expandedRows, params.row.id)) &&
              <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
                <Badge color='default' badgeContent={params.row.wells.length}>
                  <KeyboardArrowUpIcon color='primary' size='small'/>
                </Badge>
              </Box>
            }
            {
              (!_.includes(this.props.expandedRows, params.row.id)) &&
              <Badge color='default' badgeContent={params.row.wells.length}>
                <KeyboardArrowDownIcon size='small'/>
              </Badge>
            }
          </Box>
        );
      },
      valueGetter: (params) => {
        if (_.isNil(params.row)) return '';
        return params.row.wells.map((well) => well.wellName + '/' + well.apiNumber + '/' + well.pad + '/' + well.leaseName + '/' + well.leaseNumber).join(",");
      }
    });
    columns.push({
      // This is the 'Actions' field
      // However, we have set the field to be the GRID_DETAIL_PANEL_TOGGLE_FIELD so that 
      // we can hide the default GRID_DETAIL_PANEL_TOGGLE_FIELD column that has the '+' sign
      // and let the user click on the row itself to expand the master details
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      align: 'center',
      width: 30,
      editable: false,
      hideable: false,
      pinnable: false,
      sortable: false,
      resizable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',
      getActions: (params) => {
        let actions = [];
        actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'}/>} onClick={() => { this.props.onShowCreateEditJobDialog(true, params.row); }} label="Edit" showInMenu />);
        actions.push(<GridActionsCellItem icon={<DeleteForeverIcon fontSize={'small'}/>} onClick={() => { this.props.onShowDeleteJobDialog(true, params.row); }} label="Delete" showInMenu />);
        return actions;
      }
    });
    return columns;
  }

  // TODO - make into function component
  renderWellDetail(row) {
    return (
      <Grid container wrap='nowrap' direction='column' alignItems='center' 
        sx={{width: '100%', height: '100%', paddingLeft: 3, paddingRight: 3, paddingTop: '2px', paddingBottom: 4,
             backgroundColor: (row.row.orderingId % 2 === 0 ? '#262626' : '#1e1e1e')}}>
        {/* header */}
        <Grid container direction='row' alignItems='center' sx={styles.headerRow}>
          <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Well</Typography>
          </Grid>
          <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Api Number</Typography>
          </Grid>
          <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Pad</Typography>
          </Grid>
          <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Lease Name</Typography>
          </Grid>
          <Grid item sx={{...styles.headerCell, width: '10%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Lease Number</Typography>
          </Grid>
          <Grid item sx={{...styles.headerCell, width: '10%'}} display='flex' justifyContent='left'>
            <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Planned Stages</Typography>
          </Grid>
        </Grid>

        {/* contents */}
        <Paper sx={{...styles.table, width:'100%', height: '100%', borderRadius: '0px'}}>
          {
            _.map(row.row.wells, (well) => {
              return (
                <Grid key={well.wellId} container direction='row' alignItems='center' sx={styles.tableRow}>
                  <Grid item sx={{...dialogStyles.tableCellLeftMost, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.wellName}</Typography>
                  </Grid>
                  <Grid item sx={{...dialogStyles.tableCell, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.apiNumber}</Typography>
                  </Grid>
                  <Grid item sx={{...dialogStyles.tableCell, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.pad}</Typography>
                  </Grid>
                  <Grid item sx={{...dialogStyles.tableCell, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.leaseName}</Typography>
                  </Grid>
                  <Grid item sx={{...dialogStyles.tableCell, width: '10%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.leaseNumber}</Typography>
                  </Grid>
                  <Grid item sx={{...dialogStyles.tableCellRightMost, width: '10%', height: '42px'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.plannedStages}</Typography>
                  </Grid>
                </Grid>
              )
            })
          }
        </Paper>

      </Grid>
    );
  }

  render() {
    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={{...styles.detailsPageHeader, height: '56px', alignItems: 'center'}}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Jobs</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <Box sx={styles.selectionContainer}>
                {/* Time Frame - last 7,30,60,90 Days */}
                <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                <AutoCompleteMDT
                    sx={styles.timeSelection}
                    options={this.props.timeFrames}
                    value={this.props.selectedTimeFrame}
                    onChange={(event, value, reason) => { this.props.onSelectTimeFrame(value); }}
                    noOptionsText={'No time frames found...'}
                />
              </Box>
              <IconButton
                sx={{height: '48px'}}
                onClick={() => { this.props.onRefreshDisplay(this.props.selectedTimeFrame); }}
                size="large"><RefreshIcon/>
              </IconButton>
              <Button color='inherit' disableRipple 
                onClick={() => { this.props.onShowCreateEditJobDialog(true, null); }}>
                <AddIcon/>
                ADD JOB
              </Button>
            </Box>
          </Box>
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
                filterModel={this.props.filterModel}
                onFilterModelChange={this.props.onFilterChange}
                columns={this.columns}
                rows={this.props.jobs}
                disableSelectionOnClick
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: {
                      disableToolbarButton: true
                    },
                    csvOptions : {
                      fileName: 'jobs'
                    }
                  }
                }}
                stateDef={this.props.stateDef}
                rowsPerPage={this.props.rowsPerPage}
                setRowsPerPage={this.props.setRowsPerPage}
                rowsPerPageOptions={this.props.rowsPerPageOptions}
                getRowId={(row) => row.id}

                getDetailPanelContent={(row) => this.renderWellDetail(row)}
                getDetailPanelHeight={() => 'auto'}

                // This lets the user expand master details using a row click
                onRowClick={(params, event, details) => { this.props.onRowClick(params.id); }}
                detailPanelExpandedRowIds={this.props.expandedRows}

                // Since GRID_DETAIL_PANEL_TOGGLE_FIELD column is our row menu, do not expand the
                // the master details when the user clicks on this column; show the menu instead
                onCellClick={(params, event, details) => {
                  if (params.field === GRID_DETAIL_PANEL_TOGGLE_FIELD) {
                    event.stopPropagation();
                  }
                }}

                getRowClassName={(params) =>
                  params.row.orderingId % 2 === 0 ? 'even' : 'odd'
                }
            />
          </Box>
        </Box>
        <Progress open={this.props.queryRunning} />
        <CreateEditJobDialog />
        <DeleteJobDialog />
      </Box>
    )
  };

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_HISTORY : props.stateKey,
      type: ComponentTypes.JOB_HISTORY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobHistoryState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning, 
    customers: componentState.customers,
    selectedCustomer: componentState.selectedCustomer,
    showCreateEditJobDialog: componentState.showCreateEditJobDialog,
    jobs: componentState.jobs,
    rowsPerPage: componentState.rowsPerPage,
    rowsPerPageOptions: componentState.rowsPerPageOptions,
    expandedRows: componentState.expandedRows,
    timeFrames: componentState.timeFrames,
    selectedTimeFrame: componentState.selectedTimeFrame,
    filterModel: componentState.filterModel
  }
};


const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowCreateEditJobDialog: (show, job) => { dispatch(jobHistoryActions.showCreateEditJobDialog(props.stateDef, show, job)); },
    onShowDeleteJobDialog: (show, job) => dispatch(jobHistoryActions.showDeleteJobDialog(props.stateDef, show, job)),
    setRowsPerPage: (rowSize) => { dispatch(jobHistoryActions.dataGridSetRowSize(props.stateDef, rowSize)); },
    onLoadDisplay: (maxTimeFrame) => { dispatch(jobHistoryActions.loadDisplay(props.stateDef, maxTimeFrame)); },
    onSelectTimeFrame: (timeFrame) => { dispatch(jobHistoryActions.selectTimeFrameAndLoadJobs(props.stateDef, timeFrame)); },
    onRowClick: (rowId) => { dispatch(jobHistoryActions.rowClick(props.stateDef, rowId)); },
    onRefreshDisplay: (timeFrame) => { dispatch(jobHistoryActions.refreshDisplay(props.stateDef, timeFrame)); },
    onFilterChange: (filterModel) => { dispatch(jobHistoryActions.onFilterChange(props.stateDef, filterModel));}
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(JobHistoryDisplay));