import _ from 'lodash';

const filterState = state => {

  return {
    // Flag to open/close the filter dialog
    openFilterUI: _.isNil(state) ? false : state.openFilterUI,

    // The list of filters (types and values) generated from the items
    filters: _.isNil(state) ? [] : state.filters,

    // The list of filters currently applied by the user
    appliedFilters: _.isNil(state) ? [] : state.appliedFilters,

    // The list of filters currently available to the filter dialog (not filtered by search)
    currentFilters: _.isNil(state) ? [] : state.currentFilters,

    // The list of filters currently displayed in the filter dialog (may or may not be filtered by search)
    displayedFilters: _.isNil(state) ? [] : state.displayedFilters,

    // The search string used to reduce the filters displayed in the filter dialog
    searchValue: _.isNil(state) ? '' : state.searchValue,

    // The search string used to reduce the filters displayed in the filter dialog
    filteredJobs: _.isNil(state) ? '' : state.filteredJobs,

  };

};

export {
  filterState
};