import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import { handleError } from "../../../../app/actions/appErrorActions";

import fracFleetMapActionTypes from './fracFleetMapActionTypes';
import * as fracFleetMapQueries from './fracFleetMapQueries';
import fleetMapViews from '../../display/fleetMapViews';

import errorMessages from '../../../../common/errorMessages';

const queryFracFleetMapDetailsStarting = defaultActionFactory(fracFleetMapActionTypes.FRAC_FLEET_MAP_QUERY_DETAILS_STARTING, 'stateDef');
const queryFracFleetMapDetailsSuccess = defaultActionFactory(fracFleetMapActionTypes.FRAC_FLEET_MAP_QUERY_DETAILS_SUCCESS, 'stateDef', 'queryResults', 'view');
const queryFracFleetMapDetailsError = defaultActionFactory(fracFleetMapActionTypes.FRAC_FLEET_MAP_QUERY_DETAILS_ERROR, 'stateDef');

const queryFracFleetMapDetails = (stateDef, fleetId, view) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(queryFracFleetMapDetailsStarting(stateDef));
      switch (view) {
        case fleetMapViews.OPERATION:
          queryResults = await fracFleetMapQueries.fetchFracFleetMapOperationDetails(fleetId);
          break;
        case fleetMapViews.COMPONENT:
          queryResults = await fracFleetMapQueries.fetchFracFleetMapComponentDetails(fleetId);
          break;
        case fleetMapViews.HEALTH:
          queryResults = await fracFleetMapQueries.fetchFracFleetMapHealthDetails(fleetId);
          break;
        default:
          throw Error('Invalid Fleet Map view provided');
      }
    } catch(e) {
      await dispatch(queryFracFleetMapDetailsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_FLEET_MAP_DETAILS, e.message));
    }
    return dispatch(queryFracFleetMapDetailsSuccess(stateDef, queryResults, view));
  }
};

export {
  queryFracFleetMapDetails
}