import React, {Component} from "react";
import _ from "lodash";
import {Box, Typography} from "@mui/material";
import PropTypes from "prop-types";

const styles = {
    fracMetricsItemContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    fracMetricsItemKeyStyle: {
        display:'inline',
        align:'left',
        whiteSpace: 'noWrap',
        fontSize: '12px',
    },
    fracMetricsItemValueStyle: {
        display:'inline',
        align:'right',
        whiteSpace: 'noWrap',
        fontSize: '12px',
    },
};

export const FracpumpOperationMetricType = Object.freeze({
    Percent:   Symbol("percent"),
    Fraction:  Symbol("fraction"),
});

export class OperationKeyValueMetricDisplayComponent extends Component{
    hasNonNullValue(obj) {
        return !_.isNil(obj);
    }
    getDisplayValue(val, type) {
        switch (type) {
            case FracpumpOperationMetricType.Fraction:
                return [val.numerator, val.denominator].join('/');
            case FracpumpOperationMetricType.Percent:
                return val.toFixed(1);
            default:
                return 'NOT SUPPORTED';
        }
    }
    render() {
        const { label, value, formatType, noDataDisplayText } = this.props;
        return(
            <Box  sx={styles.fracMetricsItemContainer}>
                <Box >
                    <Typography sx={styles.fracMetricsItemKeyStyle} variant={"subtitle1"}>{label}</Typography>
                </Box>
                <Box >
                    <Typography sx={styles.fracMetricsItemValueStyle} variant={"subtitle1"}> { this.hasNonNullValue(value) ? this.getDisplayValue(value, formatType) : (noDataDisplayText ?? '-')}</Typography>
                </Box>
            </Box>
        )
    }
}

OperationKeyValueMetricDisplayComponent.propTypes = {
    label: PropTypes.string.isRequired,
    formatType: PropTypes.symbol.isRequired,
};
