import alarmsActionTypes from '../alarmsActionTypes';
import * as alarmsService from '../services/alarmsService';
import { alarmsState } from '../alarmsSelectors';

const initialState = alarmsState();

const queryReducer = (state, action) => {

  switch (action.type) {

    case alarmsActionTypes.ALARMS_QUERY_STARTING:
      return onQueryStarting(state, action);

    case alarmsActionTypes.ALARMS_QUERY_SUCCESS:
      return onQuerySuccess(state, action);

    case alarmsActionTypes.ALARMS_QUERY_ERROR:
      return onQueryError(state, action);

    default: return state;
  }
};

const  onQueryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onQuerySuccess = (state, action) => {

  let alarms = action.activeOnly === true ? action.queryResults.activeAlarmDetails : action.queryResults.historicalAlarmDetails;

  let transformedAlarms = alarmsService.transformAlarms(alarms);

  let alarmDetails = alarmsService.processAlarms(transformedAlarms, state.appliedFilters, state.excludeMessageAlarms);

  return {
    ...state,
    queryRunning: false,
    alarms: transformedAlarms,
    filteredAlarms: alarmDetails.filteredAlarms,
    filters: alarmDetails.filters,
    currentPage: initialState.currentPage,
  };
};

const  onQueryError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer