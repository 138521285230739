import fleetOverviewActionTypes from '../fleetOverviewActionTypes';
import * as fleetOverviewService from '../services/fleetOverviewService';
import { fleetOverviewState } from '../fleetOverviewSelectors';

const initialState = fleetOverviewState();

const queryReducer = (state, action) => {

  switch (action.type) {

    case fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    default: return state;

  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onQueryDataSuccess = (state, action) => {

  let processDetails = fleetOverviewService.processFleets(action.queryResults.fracOverview, state.appliedFilters, state.currentPage);

  return {
    ...state,
    queryRunning: false,
    fleets: action.queryResults.fracOverview,
    filteredFleets: processDetails.filteredFleets,
    pagedFleets: processDetails.pagedFleets,
    filters: processDetails.filters,
    currentPage: initialState.currentPage
  };
};

const  onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer