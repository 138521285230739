
const fetchComponentLifeCardData = (truckId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        componentLife: {
          components: [
            { type: 'Transmission', displayName: 'Transmission', model: 'XX-XXX-XXXX', expectedLife: 17000, primaryLife: { name: 'Transmission', value: 100 } },
            { type: 'Fluid Ends', displayName: 'Fluid End', model: 'XX-XXX-XXXX', expectedLife: 1500, primaryLife: { name: 'Fluid Ends', value: 1400 } },
            { type: 'Power Ends', displayName: 'Power End', model: 'XX-XXX-XXXX', expectedLife: 7500, primaryLife: { name: 'Power Ends', value: 7000 } },
            { type: 'Engines', displayName: 'Engine', model: 'XX-XXX-XXXX', expectedLife: 25000, primaryLife: { name: 'Engines', value: 0 } },
            { type: 'Engine Oil', displayName: 'Engine Oil', model: 'XX-XXX-XXXX', expectedLife: 500, primaryLife: { name: 'Engine Oil', value: 900 } },
            { type: 'Power End Lube', displayName: 'Power End Lube', model: 'XX-XXX-XXXX', expectedLife: 1000, primaryLife: { name: 'Power End Lube', value: 1693 } },
            { type: 'New Engine', displayName: 'New Engine', model: 'XX-XXX-XXXX', expectedLife: 1000, primaryLife: { name: 'New Engine', value: 999 } },
            { type: 'New Transmission', displayName: 'New Transmission', model: 'XX-XXX-XXXX', expectedLife: 1000, primaryLife: { name: 'New Transmission', value: 1000 } },
            { type: 'New Power End', displayName: 'New Power End', model: 'XX-XXX-XXXX', expectedLife: 1000, primaryLife: { name: 'New Power End', value: 800 } },
            { type: 'NeoArmstrong Cyclone Jet Armstrong Cannon', displayName: 'NeoArmstrong Cyclone Jet Armstrong Cannon', model: 'XX-XXX-XXXX', expectedLife: 0, primaryLife: { name: 'NeoArmstrong Cyclone Jet Armstrong Cannon', value: 1 } },
            { type: 'Sauce', displayName: 'Where is the Sauce?', model: 'XX-XXX-XXXX', expectedLife: 1, primaryLife: { name: 'Sauce', value: 0 } },
          ]
        }
      })
    }, 1000)
  });
};

const fetchComponentTypesByUnitType = (unitType) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        componentTypesByUnitType : [
          { id: 1, name: 'New Engine', displayName: 'Engine', unitType: 'FracPumper', kind: 'Engine', expectedLife: 100 },
          { id: 2, name: 'New Transmission', displayName: 'Transmission', unitType: 'FracPumper', kind: 'Transmission', expectedLife: 200 },
          { id: 3, name: 'New Power End', displayName: 'PowerEnd', unitType: 'FracPumper', kind: 'PowerEnd', expectedLife: 300 },
          { id: 4, name: 'NeoArmstrong Cyclone Jet Armstrong Cannon', displayName: 'Armstrong Cannon', unitType: 'FracPumper', kind: 'FancyBell', expectedLife: 0 },
          { id: 5, name: 'Sauce', displayName: 'Sauce', unitType: 'FracPumper', kind: 'FancyWhistle', expectedLife: 0 },
          { id: 116, name: 'Transmission', displayName: 'Transmission', unitType: 'FracPumper', kind: 'Transmission', expectedLife: 17000 },
          { id: 117, name: 'Fluid Ends', displayName: 'Fluid End', unitType: 'FracPumper', kind: 'FluidEnd', expectedLife: 1500 },
          { id: 118, name: 'Power Ends', displayName: 'Power End', unitType: 'FracPumper', kind: 'PowerEnd', expectedLife: 7500 },
          { id: 115, name: 'Engines', displayName: 'Engine', unitType: 'FracPumper', kind: 'Engine', expectedLife: 25000 },
          { id: 131, name: 'Engine Oil', displayName: 'Engine Oil', unitType: 'FracPumper', kind: 'Engine', expectedLife: 500 },
          { id: 132, name: 'Power End Lube', displayName: 'Power End Lube', unitType: 'FracPumper', kind: 'PowerEnd', expectedLife: 1000 },
        ]
      })
    }, 1000)
  });
};

export {
  fetchComponentLifeCardData,
  fetchComponentTypesByUnitType
}