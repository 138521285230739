import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, Box, Button, Tooltip, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

import ComponentTypes from "../../componentTypes";
import MDTDataGrid from '../../common/table/MDTDataGrid';
import getDataGridStyles from "../../common/styles/dataGridStyle";
import getDataGridDialogStyles from './styles/dataGridDialogStyles';

import { jobOverviewState } from "../../../state/displays/jobOverview/jobOverviewSelectors";
import * as jobOverviewActions from '../../../state/displays/jobOverview/jobOverviewActions';

const dataGridStyle = getDataGridStyles();
const dataGridDialogStyle = getDataGridDialogStyles();

const styles = {
  ...dataGridStyle,
  ...dataGridDialogStyle,
};

class EventHistoryViewer extends Component {

  constructor(props) {
    super(props);
  }

  eventLogColumns = 
    [
      {
        field: 'createdOn',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
      },
      {
        field: 'eventType',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        width: 250,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
      },
      {
        field: 'user',
        headerName: 'User',
        headerAlign: 'center',
        align: 'center',
        width: 250,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Tooltip title={_.isNil(params.value) ? '' : params.value} followCursor >
              <Typography variant={'body2'}>{params.value}</Typography>
            </Tooltip>
          )
        }
      },
      {
        field: 'note',
        headerName: 'Note',
        headerAlign: 'center',
        align: 'left',
        flex: 1,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Tooltip title={_.isNil(params.value) ? '' : params.value} followCursor >
              <Typography noWrap variant={'body2'}>{params.value}</Typography>
            </Tooltip>
          )
        }
      },
    ]

  customGridToolbarNoToolbar() {
    return (
      <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
      </GridToolbarContainer>
    )
  }

  customNoRowsOverlay() {
    return (
      <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 1 }}>
        <Typography variant='subtitle1'>{'Please load Job Data to view Event History.'}</Typography>
      </Box>
    )
  }

  render() {

    return (
      <Dialog maxWidth={'false'} fullWidth scroll="paper" open={this.props.showEventHistoryViewer} disableEscapeKeyDown>
        <DialogTitle>{_.isNil(this.props.selectedJobInfo) ? '' : 'Event History for ' + this.props.selectedJobInfo.name} </DialogTitle>
        <DialogContent sx={{height: '26vh'}}>

          <Box sx={{height: '100%', paddingBottom: 1}}>
            <Box sx={{
              ...styles.mdtDataGridContainer, 
              '& .MuiDataGrid-row:hover': {
                cursor: 'auto'
              }
              }}>
              <MDTDataGrid
                getRowId={row => row.id}
                pagination={false}
                hideFooter
                autoHeight={false}
                columns={this.eventLogColumns}
                rows={this.props.eventLogs}
                disableSelectionOnClick
                components={{
                  Toolbar: this.customGridToolbarNoToolbar,
                  NoRowsOverlay: this.customNoRowsOverlay,
                }}

                stateDef={this.props.stateDef}

                headerHeight={38}
                /> 
            </Box>
          </Box>
          
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'center', marginBottom: 2, marginTop: 1}}>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onShowEventHistoryViewer(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_OVERVIEW : props.stateKey,
      type: ComponentTypes.JOB_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobOverviewState(state[stateDef.key]);

  return {
    showEventHistoryViewer: componentState.showEventHistoryViewer,
    selectedJob: componentState.selectedJob,
    selectedJobInfo: componentState.selectedJobInfo,
    eventLogs: componentState.eventLogs,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowEventHistoryViewer: (show) => { dispatch(jobOverviewActions.showEventHistoryViewer(props.stateDef, show)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(EventHistoryViewer));