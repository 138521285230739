// Sample teams channel config data:
// {
//   "channels":
//   [
//     {
//       "name": "teams 1",
//       "webhook": "https://cjes.webhook.office.com/webhookb2/3da3262-test1"
//     },
//     {
//       "name": "teams 2",
//       "webhook": "https://cjes.webhook.office.com/webhookb2/3da3262-test2"
//     }
//   ]
//
// }

// Sample Twilio config data
// {
//     "accountSid": "TWILIO_ACCOUNT_SID_1",
//     "authToken": "TWILIO_AUTH_TOKEN_1",
//     "from": "403-001-0000"
// }

const fetchProviderSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        providerDetails: [
          {
            "id": 23,
            "type": "teams",
            "name": "teams test",
            "config": "{\"channels\":[{\"name\":\"Test team channel \",\"webhook\":\"https://cjes.webhook.office.com/webhookb2/3da3262-test1\"},{\"name\":\"teams2\",\"webhook\":\"https://cjes.webhook.office.com/webhookb2/3da3262-test2\"}]}"
          },
          {
            "id": 24,
            "type": "twilio",
            "name": "twilio test",
            "config": "{\"accountSid\":\"TESTe9bc3f77fc4c8d0a866748241b3500\",\"authToken\":\"TestToken15f306c81862632e000\",\"from\":\"+14030010000\"}"
          }
        ]
      })
    }, 1000)
  });
};

const fetchSaveProviderSettings = (providerInput) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        result: {
            "saveProvider": {
              "id": 24,
              "name": "twilio test",
              "type": "twilio",
              "config": "{\"accountSid\":\"TWILIO_ACCOUNT_SID_1\",\"authToken\":\"TWILIO_AUTH_TOKEN_1\",\"from\":\"403-001-0000\"}"
            }
          }
      })
    }, 1000)
  });
};

const fetchDeleteProviderSettings = (providerId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        result: {
          "deleteProvider": {
            "success": true
          }
        }
      })
    }, 1000)
  });
};


export {
  fetchProviderSettings,
  fetchSaveProviderSettings,
  fetchDeleteProviderSettings
}