export default {
  SETTINGS_PROVIDER_QUERY_STARTING: 'SETTINGS_PROVIDER_QUERY_STARTING',
  SETTINGS_PROVIDER_QUERY_SUCCESS: 'SETTINGS_PROVIDER_QUERY_SUCCESS',
  SETTINGS_PROVIDER_QUERY_ERROR: 'SETTINGS_PROVIDER_QUERY_ERROR',
  SETTINGS_PROVIDER_SAVE_STARTING: 'SETTINGS_PROVIDER_SAVE_STARTING',
  SETTINGS_PROVIDER_SAVE_SUCCESS: 'SETTINGS_PROVIDER_SAVE_SUCCESS',
  SETTINGS_PROVIDER_SAVE_ERROR: 'SETTINGS_PROVIDER_SAVE_ERROR',
  SETTINGS_PROVIDER_DELETE_STARTING: 'SETTINGS_PROVIDER_DELETE_STARTING',
  SETTINGS_PROVIDER_DELETE_SUCCESS: 'SETTINGS_PROVIDER_DELETE_SUCCESS',
  SETTINGS_PROVIDER_DELETE_ERROR: 'SETTINGS_PROVIDER_DELETE_ERROR',
  SETTINGS_SHOW_PROVIDER_DIALOG: 'SETTINGS_SHOW_PROVIDER_DIALOG',
  SETTINGS_SHOW_DELETE_PROVIDER_DIALOG: 'SETTINGS_SHOW_DELETE_PROVIDER_DIALOG',
  SETTINGS_PROVIDER_SET_ROW_SIZE: 'SETTINGS_PROVIDER_SET_ROW_SIZE'
};