import React, { Component } from 'react';
import _ from "lodash";
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Dialog, DialogContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";

import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';
import { componentsState } from '../../../state/displays/componentsDisplay/componentsSelectors';
import ComponentTypes from '../../componentTypes';

class ComponentDeleteConfirmation extends Component {

  render() {
    return (
      <Dialog open={this.props.openDeleteConfirmation} disableEscapeKeyDown>
        <DialogTitle>Delete Component</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this component?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.closeDeleteConfirmation()}} color='inherit'>Cancel</Button>
          <Button onClick={() => { this.props.deleteComponent(this.props.selectedComponentId)}} color='primary'>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENTS : props.stateKey,
      type: ComponentTypes.COMPONENTS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentsState(state[stateDef.key]);
  return {
    openDeleteConfirmation: componentState.openDeleteConfirmation,
    selectedComponentId: componentState.selectedComponentId,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeDeleteConfirmation: () => dispatch(componentsActions.closeDeleteConfirmation(props.stateDef)),
    deleteComponent: (componentId) => dispatch(componentsActions.deleteComponent(props.stateDef, componentId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ComponentDeleteConfirmation))
