export default {

  QUERY_JOBS_DATA_STARTING: 'QUERY_JOBS_DATA_STARTING',
  QUERY_JOBS_DATA_SUCCESS: 'QUERY_JOBS_DATA_SUCCESS',
  QUERY_JOBS_DATA_ERROR: 'QUERY_JOBS_DATA_ERROR',

  QUERY_JOB_DATA_STARTING: 'QUERY_JOB_DATA_STARTING',
  QUERY_JOB_DATA_SUCCESS: 'QUERY_JOB_DATA_SUCCESS',
  QUERY_JOB_DATA_ERROR: 'QUERY_JOB_DATA_ERROR',

  DIALOG_SHOW: 'DIALOG_SHOW',
  DIALOG_SET_NOTES: 'DIALOG_SET_NOTES',
  DIALOG_SUBMIT: 'DIALOG_SUBMIT',
  DIALOG_SET_TYPE: 'DIALOG_SET_TYPE',

  QUERY_EVENT_LOGS_STARTING: 'QUERY_EVENT_LOGS_STARTING',
  QUERY_EVENT_LOGS_SUCCESS: 'QUERY_EVENT_LOGS_SUCCESS',
  QUERY_EVENT_LOGS_ERROR: 'QUERY_EVENT_LOGS_ERROR',

  SAVE_JOB_STATUS_STARTING: 'SAVE_JOB_STATUS_STARTING',
  SAVE_JOB_STATUS_SUCCESS: 'SAVE_JOB_STATUS_SUCCESS',
  SAVE_JOB_STATUS_ERROR: 'SAVE_JOB_STATUS_ERROR',

  QUERY_STAGE_DATA_STARTING: 'QUERY_STAGE_DATA_STARTING',
  QUERY_STAGE_DATA_ERROR: 'QUERY_STAGE_DATA_ERROR',
  QUERY_STAGE_DATA_SUCCESS: 'QUERY_STAGE_DATA_SUCCESS',

  QUERY_STAGE_DEFINITION_STARTING: 'QUERY_STAGE_DEFINITION_STARTING',
  QUERY_STAGE_DEFINITION_SUCCESS: 'QUERY_STAGE_DEFINITION_SUCCESS',
  QUERY_STAGE_DEFINITION_ERROR: 'QUERY_STAGE_DEFINITION_ERROR',

  QUERY_STAGE_DETAILS_STARTING: 'QUERY_STAGE_DETAILS_STARTING',
  QUERY_STAGE_DETAILS_ERROR: 'QUERY_STAGE_DETAILS_ERROR',
  QUERY_STAGE_DETAILS_SUCCESS: 'QUERY_STAGE_DETAILS_SUCCESS',

  SELECT_STAGE: 'SELECT_STAGE',
  SELECT_UNIT_INDEX: 'SELECT_UNIT_INDEX',

  SHOW_STAGE_VIEWER: 'SHOW_STAGE_VIEWER',
  SHOW_EVENT_HISTORY_VIEWER: 'SHOW_EVENT_HISTORY_VIEWER',

  SAVE_STAGE_STATUS_STARTING: 'SAVE_STAGE_STATUS_STARTING',
  SAVE_STAGE_STATUS_SUCCESS: 'SAVE_STAGE_STATUS_SUCCESS',
  SAVE_STAGE_STATUS_ERROR: 'SAVE_STAGE_STATUS_ERROR',

  SELECT_VIEW: "SELECT_VIEW",
  CREATE_CHART_FOR_STAGE: "CREATE_CHART_FOR_STAGE",
  UPDATE_CHARTS_LAYOUT: "UPDATE_CHARTS_LAYOUT",
  CARDS_QUERY_RUNNING: "CARDS_QUERY_RUNNING",
  REMOVE_CHART: "REMOVE_CHART",
  REMOVE_ALL_CHARTS: "REMOVE_ALL_CHARTS",
}