import defaultActionFactory from '../../common/factories/defaultActionFactory'
import alarmCountActionTypes from "./alarmCountActionTypes";
import errorMessages from "../../common/errorMessages";
import * as cardQueries from "./alarmCountQueries";
import {handleError} from "../../app/actions/appErrorActions";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import {getCardFromLayoutConfigViews} from "../../../components/common/layout/layoutHelper";

const clearData = defaultActionFactory(alarmCountActionTypes.ALARM_COUNT_CLEAR_DATA, 'stateDef');
const startingAction = defaultActionFactory(alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_STARTING, 'stateDef');
const successAction = defaultActionFactory(alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const errorAction = defaultActionFactory(alarmCountActionTypes.ALARM_COUNT_QUERY_DATA_ERROR, 'stateDef');

const onReorderColumns = (stateDef, dashboard, view, cardKey, fromIndex, toIndex) => {
  return async (dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    let configuration = card.configuration;
    const [movedColumn] = configuration.columns.splice(fromIndex, 1);
    configuration.columns.splice(toIndex, 0, movedColumn);
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
  }
};

const onToggleColumnVisibility = (stateDef, dashboard, view, cardKey, columnIndex) => {
  return async (dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    let configuration = card.configuration;
    configuration.columns[columnIndex].visible = !configuration.columns[columnIndex].visible;
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
  }
};


const queryData = (stateDef, id, isFleetQuery) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(startingAction(stateDef));

      let query = null;
      if (isFleetQuery) {
        query = cardQueries.fetchFleetAlarmCountData;
      } else {
        query = cardQueries.fetchPumpAlarmCountData;
      }

      queryResults = await query(id);
    } catch (e) {
      await dispatch(errorAction(stateDef));
      return dispatch(handleError(isFleetQuery === true ? errorMessages.ERROR_RETRIEVING_FLEET_ALARM_COUNT_CARD_DATA : errorMessages.ERROR_RETRIEVING_UNIT_ALARM_COUNT_CARD_DATA, e.message));
    }

    //Index to the appropriate payload depending on what the query was for
    if (isFleetQuery) {
      return dispatch(successAction(stateDef, queryResults.fleetAlarmCount));
    } else {
      return dispatch(successAction(stateDef, queryResults.unitAlarmCount));
    }
  };
};

export {
  clearData,
  queryData,
  onReorderColumns,
  onToggleColumnVisibility,
}