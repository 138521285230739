import * as auditLogsSimulation from './auditLogsSimulation';

import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchAuditLogsMetadata = () => {

  if (useSimulationData('auditLogs')) {
    return auditLogsSimulation.fetchAuditLogsMetadata();
  }

  return performDisplayFetch({
  'query': 'query { auditMetaData { fields { label name type availableOperators { name label } } combinators { name label } } }'
  });
}

const fetchAuditLogs = (formattedQuery, pageable) => { 

  const { startTime, endTime, ...queryInput } = formattedQuery;

  const  input = {
      query: JSON.stringify(queryInput), // Serialize the query part of the formattedQuery
      startTime: formattedQuery.startTime,
      endTime: formattedQuery.endTime,
    }
    
  return performDisplayFetch({
    'query': 'query AuditLogQuery($input:QueryInputs!, $pageable:PageableInput!) { auditLogQuery(input:$input, pageable: $pageable) {datavanPid datavanName truckPid truckName actionType detail newState oldState source subType uiComponent timestamp } }',
    'variables' : {
      'input': input,
      'pageable': pageable 
    }
  })
}

export {
  fetchAuditLogsMetadata,
  fetchAuditLogs
}