import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import unitFleetActionTypes from './unitFleetActionTypes';

import fetchUnitFleetCardData from './unitFleetQueries';
import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(unitFleetActionTypes.UNIT_FLEET_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_STARTING,
  unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_SUCCESS,
  unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_FLEET_CARD_DATA,
  fetchUnitFleetCardData
);

export {
  queryData,
  clearData
}