import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class AlarmWarning extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M9.209,3.246l-8.681,13.974c-1.362,2.19 0.213,5.018 2.792,5.018l17.362,0c2.579,0 4.149,-2.828 2.792,-5.018l-8.68,-13.974c-1.284,-2.068 -4.295,-2.068 -5.585,0Zm4.508,13.469l-3.285,0l0,2.798l3.285,0l0,-2.798Zm0.121,-8.949l-3.528,0l-0.061,2.799l0.669,5.365l2.293,-0.011l0.592,-5.318l0.035,-2.835Z" style={{fillRule:'evenodd'}}/>
      </SvgIcon>
    )
  }
}

export default AlarmWarning;
