import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';

const exportState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    // Time zone information for the time zone form selection.
    timezones: _.isNil(state) ?  {
      caTimezones: _.isNil(state) ? null : state.timezones.caTimezones,
      usTimezones: _.isNil(state) ? null : state.timezones.usTimezones,
      localTimezone: _.isNil(state) ? null : state.timezones.localTimezone,
      locale: _.isNil(state) ? null : state.timezones.locale,
      groupedTimezones: _.isNil(state) ? [] : state.timezones.groupedTimezones,
    } : state.timezones,

    // Truck list for the truck form selection.
    trucks: _.isNil(state) ? [] : state.trucks,

    // Displayed trucks (filtered)
    filteredTrucks: _.isNil(state) ? [] : state.filteredTrucks,

    // The collection of data ranges for the selected truck and time
    dataRanges: _.isNil(state) ? null : state.dataRanges,

    // Duration list for the duration form selection.
    durations: _.isNil(state) ? [
      { value: 60, label: '1 hr' },
      { value: 120, label: '2 hrs' },
      { value: 240, label: '4 hrs' },
      { value: 480, label: '8 hrs' },
      { value: 720, label: '12 hrs' },
      { value: 1440, label: '24 hrs' },
      { value: 4320, label: '3 days' },
      { value: 10080, label: '7 days' },
    ] : state.durations,

    // Selected form values.
    selectedTruck: _.isNil(state) ? null : state.selectedTruck,
    selectedDateTime: _.isNil(state) ? null : state.selectedDateTime,
    selectedDuration: _.isNil(state) ? { value: 720, label: '12 hrs' } : state.selectedDuration,
    selectedTimezone: _.isNil(state) ? null : state.selectedTimezone,

    // Boolean to indicate the users selections exceed the maximum sensor minutes
    exceededMaximumDuration : _.isNil(state) ? false : state.exceededMaximumDuration,

    // Label to display to the user when their selections exceed the maximum sensor minutes
    maximumDurationLabel: _.isNil(state) ? null : state.maximumDurationLabel,

    // State information for the export job.
    exportJobState: _.isNil(state) ? {} : state.exportJobState,

    // Flag used to trigger the browser to download the CSV file.
    triggerExportByTruck: _.isNil(state) ? false : state.triggerExportByTruck,

    // Flag to disable export until all the form inputs are valid.
    canExport: _.isNil(state) ? false : state.canExport,

    // Flag to show the busy UI while the export is executing.
    dialogOpen: _.isNil(state) ? false : state.dialogOpen,

    truckQueryRunning: _.isNil(state) ? false : state.truckQueryRunning,
    truckDetailsQueryRunning: _.isNil(state) ? false : state.truckDetailsQueryRunning,

    selectedDateTimeDisplay: _.isNil(state) ? null : state.selectedDateTimeDisplay,

    showSensorSelector: _.isNil(state) ? false : state.showSensorSelector,
    selectedSensors: _.isNil(state) ? [] : state.selectedSensors
  };

};

export {
  exportState
};