import React, {Component} from "react";
import PropTypes from "prop-types";
import {Box, Button, Stack, TextField} from "@mui/material";

import * as appUserConfigActions from "../../../state/app/actions/appUserConfigActions";
import {appState as applicationState} from "../../../state/app/appSelectors";
import * as appContextActions from "../../../state/app/actions/appContextActions";
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";
import {getCardFromLayoutConfigViews} from "../../common/layout/layoutHelper";
import _ from "lodash";

const styles = {
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '65px',
        width: '95%'
    },
    button: {
        margin: '10px'
    },
    discardButton: {
        backgroundColor:'grey.200',
        color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
        '&:hover': {
            backgroundColor:'grey.400',
            color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
        }
    }
};

/**
 * Base class for all MDT card config panel components
 * This base class abstract the common rendered content along with some basic setting
 */
export class MdtCardConfigPanel extends Component {

    /**
     * This protected variable holds all essential information regarding to supply config panel the elements to render
     * It is a required variable, and it should be overridden when a config panel that derived this needs to set different content
     */
    _configurationProfile = {
        width: 450,           //width of the config panel
        canRename: true,      //can set the custom title of the card or not
        availableSizes: [],   //available sizes for setting the card size
    };

    /**
     * Get card config content for rendering
     */
    getRenderedContent() {
        return <></>;
    }

    /**
     * Get panel default width
     */
    getWidth() {
        return this._configurationProfile.width;
    }

    /**
     * Check if the card can be renamed or not
     */
    canRename() {
        return this._configurationProfile.canRename;
    }

    /**
     * Get available sizes for the card
     */
    getAvailableSizes() {
        return this._configurationProfile.availableSizes;
    }

    componentDidMount() {
        //Set the drawer to the width that suitable for the current config panel
        this.props.setContextMenuWidth(this.getWidth());
    }

    componentDidUpdate(prevProps) {
        //Set the card title to default title if it's empty
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);
        if(_.isEmpty(card?.configuration?.title)){
            this.props.onSetDefaultCardTitle(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, this.props.card.cardDefaultTitle);
        }
    }

    hasConfigChanged(dashboard, view, cardKey){
        const currentDashboard = this.props.dashboards[dashboard];
        const card = getCardFromLayoutConfigViews(currentDashboard?.views, view, cardKey);
        const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

        if (_.isNil(originalCard)) return false;

        const isSameSize = card?.w === originalCard?.w && card?.h === originalCard?.h;

        // Normalize empty configuration for cards
        const originalConfig = originalCard.configuration || {};
        const currentConfig  = card.configuration || {};

        //When user empty out the title in the textfield, we will apply default title back and treat that no title change has been made if the original card is using default title as well
        const hasSameTitle = originalConfig.title === this.props.card.cardDefaultTitle && _.isEmpty(currentConfig.title)?
                                    true : currentConfig.title === originalConfig.title;

        const isSameConfig = isSameSize && hasSameTitle;
        return !isSameConfig;
    }

    disableClose() {
        // Use this function in child components to conditionally disable close button (missing field, etc.)
        return false
    }

    render() {
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);
        const resizeOptions = this.getAvailableSizes().map( size => { return { label: `${size.w} X ${size.h}`, value: size}});
        return (
            <Stack spacing={2} ml={2} mr={2}>
                { this.canRename() === true && (
                    <Box>
                        <TextField fullWidth helperText='Title' size='small' variant='standard'
                                   inputProps={{maxLength: 25}}
                                   value={card?.configuration?.title || ''}
                                   onChange={(event) => this.props.onChangeCardTitle(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, event.target.value, this.props.card.cardDefaultTitle)}
                        />
                    </Box>
                )}
                { !_.isEmpty(this.getAvailableSizes()) && (
                    <Box>
                        <AutoCompleteMDT
                            options={resizeOptions}
                            value={resizeOptions.find(size => size.value.w === card.w && size.value.h === card.h)}
                            getOptionLabel={(option) => option.label}
                            noOptionsText={'No sizes available...'}
                            renderInput={(params) => <TextField {...params} variant="standard" helperText="Size"
                                                                inputProps={{ ...params.inputProps}}/>}
                            onChange={(event, value) => {
                                this.props.onChangeCardSize(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, value.value);
                            }}
                        />
                    </Box>
                )}
                <Box sx={{overflowY: "auto", maxHeight: _.isEmpty(this.getAvailableSizes()) ? "calc(100vh - 250px)" : "calc(100vh - 325px)"}}>
                    {this.getRenderedContent()}
                </Box>

                <Box sx={styles.buttonBox}>
                    <Button sx={{...styles.discardButton, ...styles.button}}
                            variant="contained"
                            onClick={() => this.props.onDiscardCardConfiguration(this.props.card.dashboard, this.props.card.view, this.props.card.card.i)}
                            disabled={!this.hasConfigChanged(this.props.card.dashboard, this.props.card.view, this.props.card.card.i)}>
                        Discard
                    </Button>

                    <Button sx={styles.button} variant="contained" 
                    onClick={() => this.props.onCloseCardConfiguration(this.props.card.dashboard, this.props.card.view, this.props.card.card.i)}
                    disabled={this.disableClose()}
                    >
                        Close
                    </Button>
                </Box>

            </Stack>
        )
    }
}

/**
 * Default MDT card proptypes
 */
export const mdtCardConfigPanelPropTypes = {
    card: PropTypes.object.isRequired,
}

/**
 * Default MDT card config panel states to props mapping
 */
export const mdtCardConfigMapStateToProps = (state, props) => {
    let appState = applicationState(state);
    return {
        dashboards: appState.user.dashboards
    }
};

/**
 * Default MDT card config panel dispatch props
 */
export const mdtCardConfigMapDispatchToProps = (dispatch, props) => {
    return {
        onCloseCardConfiguration: (dashboard, view, cardKey) => {
            dispatch(appUserConfigActions.onCloseCardConfiguration(dashboard, view, cardKey));
        },
        onChangeCardTitle: (dashboard, view, cardKey, title, defaultTitle) => {
            dispatch(appUserConfigActions.onChangeCardTitle(dashboard, view, cardKey, title, defaultTitle));
        },
        onSetDefaultCardTitle: (dashboard, view, cardKey, defaultTitle) => {
            dispatch(appUserConfigActions.onSetDefaultCardTitle(dashboard, view, cardKey, defaultTitle));
        },
        onChangeCardSize: (dashboard, view, cardKey, size) => {
            dispatch(appUserConfigActions.onChangeCardSize(dashboard, view, cardKey, size));
        },
        onDiscardCardConfiguration: (dashboard, view, cardKey) => {
            dispatch(appUserConfigActions.onDiscardCardConfiguration(dashboard, view, cardKey));
        },
        setContextMenuWidth: (width) => {
            dispatch(appContextActions.setContextDrawerWidth(width));
        },
    }
};