import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import downloadsDisplayActionTypes from './downloadsDisplayActionTypes'
import errorMessages from '../../common/errorMessages';
import {fetchLatestAvailableDownloads} from './downloadsDisplayQueries'

const queryDataStarting = defaultActionFactory(downloadsDisplayActionTypes.DOWNLOADS_QUERY_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(downloadsDisplayActionTypes.DOWNLOADS_QUERY_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(downloadsDisplayActionTypes.DOWNLOADS_QUERY_ERROR, 'stateDef');


const queryData = queryActionCustomFactory(
    queryDataStarting,
    queryDataSuccess,
    queryDataError,
    errorMessages.ERROR_RETRIEVING_DOWNLOADS_LIST,
    fetchLatestAvailableDownloads
);

export {
    queryDataStarting,
    queryDataSuccess,
    queryDataError,
    queryData,
};