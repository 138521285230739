import _ from 'lodash';

const searchState = state => {

    return {
        // The current search term entered by the user
        searchTerm: _.isNil(state) ? '' : state.searchTerm,

        // indicates whether the last search resulted in no matches
        searchNoMatch: _.isNil(state) ? false : state.searchNoMatch,

        // result coming from the search
        searchResults: _.isNil(state) ? null : state.searchResults,

    };
};

export {
    searchState
};