import { mdtPalette } from '../../common/styles/mdtPalette';
import getTypographyStyles from '../../common/styles/typographyStyles';

const chartOptions = (goodValue, agingValue, replaceValue, unknownValue) => {

  let typographyStyles = getTypographyStyles();

  let chartData = [];

  if (unknownValue > 0) {
    chartData.push({ y: unknownValue, color:  mdtPalette().status.level1 });
  }

  if (goodValue > 0) {
    chartData.push({ y: goodValue, color: mdtPalette().status.level2 });
  }

  if (agingValue > 0) {
    chartData.push({ y: agingValue, color: mdtPalette().status.level4 });
  }

  if (replaceValue > 0) {
    chartData.push({ y: replaceValue, color: mdtPalette().status.level5 });
  }

  return {

    title: { text: "" },

    credits: { enabled: false },

    chart: {
      type: 'pie',
      width: 85,
      height: 85,
      spacing: [-5,-5,-5,-5],
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      animation: false,
    },

    lang: {
      noData: 'No Data'
    },

    noData: {
      style: typographyStyles.noDataLabel
    },

    pane: {
      size: '100%',
      center: ['50%', "50%"],
      startAngle: -135,
      endAngle: 135,
      background: {
        backgroundColor: mdtPalette().materialUI.palette.grey['600'],
        borderColor: mdtPalette().materialUI.palette.grey['600'],
        shape: 'arc'
      }
    },

    tooltip: { enabled: false },

    plotOptions: {
      pie: {
        borderColor: null,
        allowPointSelect: false,
        dataLabels: { enabled: false, },
        animation: false,
      }
    },

    series: [{
      states: { 
        hover: { 
          enabled: false 
        }, 
        inactive: { 
          enabled: false  
        } 
      },
      data: chartData,
    }]

  }

};

export default chartOptions;