import _ from "lodash";
import moment from "moment";

const setSensorData = (definition, xValues, yValues, queryResponse) => {
    _.map(definition.sensors, (s, i) => {
        const curTruck = definition.trucks[i];
        let data = _.find(queryResponse,
            r => r.sensorSetId === s.sensorSetId &&
                r.unitOfMeasure.toLowerCase() === s.uom.toLowerCase() &&
                r.truckPid === curTruck.pid);

        // we really only need the sensor definition and the noData-filled values
        if (!_.isNil(data)) {
            insertData(data.sensorSetId, yValues[i], _.head(xValues), _.last(xValues), data.timestamps, data.values);
        }
    });
    return yValues;
}

/**
 * Generate a new XY values based on the previous time range and y values, injecting NaN onto the rest
 * @param startTimeToUse start time of the current visible time range
 * @param endTimeToUse  end time of the current visible time range, assert that it always bigger than start time
 * @param previousXValues xValues in data
 * @param previousYValues yValues in data
 * @returns {{yValues: *[], xValues: *[]}}
 */
const generateXYValues = (definition, startTime, endTime, previousXValues, previousYValues) => {

    if (_.isNil(definition) || _.isNil(startTime) || _.isNil(endTime) || _.isEmpty(previousXValues) || _.isEmpty(previousYValues)) {
        return {
            xValues: previousXValues,
            yValues: previousYValues
        }
    }

    const previousStartTime = _.head(previousXValues);
    const previousEndTime = _.last(previousXValues);

    let yValueFrom = 0;
    let yValueTo = previousXValues.length;
    let startTimeToUse = startTime;
    let endTimeToUse = endTime;
    if(startTimeToUse > previousStartTime){
        //Check if start time is on the right hand side of history data boundary or not
        startTimeToUse = previousStartTime;
    }
    if(endTimeToUse < previousEndTime){
        endTimeToUse = previousEndTime;
    }

    //Check to see if the requested time duration is over the maximum cap or not
    if(endTimeToUse - startTimeToUse > 72 * 3600) {  //TODO make the cap limit configurable and change to sensor hours
        //Figure out which side needs to be trimmed
        if(startTime < previousStartTime) {
            //Keep the left hand side and trim the right hand side
            endTimeToUse = moment.unix(startTimeToUse).add(72 , 'hours').unix();
            yValueTo = endTimeToUse - previousStartTime + 1;
        }
        if(endTime > previousEndTime){
            //Keep the right hand side and trim the left hand side
            startTimeToUse = moment.unix(endTimeToUse).add(-72 , 'hours').unix();
            if(startTimeToUse > previousStartTime){
                yValueFrom = startTimeToUse - previousStartTime;
            }
        }
    }

    // reset the startTime
    const xValues = generateSeriesXValues(startTimeToUse, endTimeToUse);
    let yValues = [];

    _.map(definition.sensors, (s, i) => {
        //Stitch the new yValue and fill the NaN as default filler
        if(_.isEmpty(previousYValues[i])){
            yValues[i] = [...Array(endTimeToUse - startTimeToUse).fill(NaN)];
        } else {
            // Push values in 3 sections
            yValues[i] = [
                // Everything from before previousStartTime
                ...Array(previousStartTime - startTimeToUse + yValueFrom).fill(NaN),
                // Everything between previousStartTime and previousEndTime
                ...(yValueTo - yValueFrom > 0 ? previousYValues[i].slice(yValueFrom, yValueTo) : []),
                // Everthing after previousEndTime
                ...(endTimeToUse - previousEndTime > 0 ? Array(endTimeToUse - previousEndTime).fill(NaN) : [])
            ];
        }
    });

    return {
        xValues: xValues,
        yValues: yValues
    }
}

const insertData = (name, target, startTimestamp, endTimestamp, sourceTimestamps, sourceValues) => {
    for (let i = 0; i < sourceTimestamps.length; i++) {
        if(sourceTimestamps[i] >= startTimestamp && sourceTimestamps[i] <= endTimestamp){
            let index = sourceTimestamps[i] - startTimestamp;
            target[index] = sourceValues[i];
        }
    }
};

const initializeData = (definition, data) => {

    let newData = _.cloneDeep(data);
  
    _.forEach(['primary'], (xAxisId) => {
        const axisDefinition = definition[xAxisId];

        // Initialize the data if not already done
        // This will handle initializing the x axis values
        if (_.isEmpty(newData[xAxisId]) && !_.isNil(axisDefinition) && !_.isNil(axisDefinition.timeRange) && !_.isNil(axisDefinition.timeRange.startTime)) {
            const startTime = axisDefinition.timeRange.startTime;
            const endTime = moment.unix(startTime).add(axisDefinition.timeRange.duration, 'minutes').unix();
            const xValues = generateSeriesXValues(startTime, endTime);
            newData[xAxisId] = {
                xValues: !_.isEmpty(axisDefinition.sensors) ? xValues : []
            }
        }

        // This will handle initializing the y axis values
        if (!_.isEmpty(newData[xAxisId])) {
            // Empty the yValues first so we start clean
            newData[xAxisId].yValues = [];
            // Using the order of the sensors so we keep the user selected order intact
            _.map(axisDefinition.displayOrder, (s, i) => {
                let orderValue = axisDefinition.displayOrder[i];
                // New sensor that is in the sensor list but not in the data we have
                if (_.isEmpty(data[xAxisId].yValues) || _.isEmpty(data[xAxisId].yValues[orderValue])) {
                    // Create the empty foundation for this new sensor
                    newData[xAxisId].yValues[i] = Array(newData[xAxisId].xValues.length).fill(NaN);
                } else {
                // We already have the sensor so just copy the data over
                    newData[xAxisId].yValues[i] = data[xAxisId].yValues[orderValue];
                }
            });
        }   
    });

    return newData;
};

/**
 * Generate the xAxis values for the given time range.
 * @param startTime Inclusive start time in epoch seconds.
 * @param endTime Inclusive end time in epoch seconds.
 * @return {unknown[]} Array of epoch seconds in the given time range.
 */
const generateSeriesXValues = (startTime, endTime) => {
    // There is a "+ 1" to account for a loss of a second here and there when panning 
    // Seems to be something scicharts related (TODO: debug more to find out)
    // Having a little overlap will not impact the data and the merging
    return [...Array(endTime - startTime + 1).keys()].map(i => i + startTime);
};

export {
    setSensorData,
    generateXYValues,
    initializeData,
    insertData
}