import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import chartOptions from '../common/activityCard/activityCardChartOptions';
import ActivityCard from '../common/activityCard/activityCard';
import ComponentTypes from '../../componentTypes';
import * as unitActivityActions from '../../../state/cards/unitActivity/unitActivityActions';
import { unitActivityState } from "../../../state/cards/unitActivity/unitActivitySelectors";
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

class UnitActivity extends MDTCard {

  getName() {
    return 'ACTIVITY';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck.id);
    }
  }

  getRenderedContent() {
    return (
      <ActivityCard
        chartOptions={this.props.chartOptions}
        displayValueDays={this.props.displayValueDays}
        displayValuePumping={this.props.displayValuePumping}
        displayValueIdle={this.props.displayValueIdle}
        displayValueStopped={this.props.displayValueStopped}
      />
    )
  }
}

UnitActivity.propTypes =  mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_ACTIVITY : props.stateKey,
      type: ComponentTypes.UNIT_ACTIVITY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitActivityState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    displayValuePumping : componentState.displayValuePumping,
    displayValueIdle: componentState.displayValueIdle,
    displayValueStopped: componentState.displayValueStopped,
    displayValueDays: componentState.displayDays,
    chartOptions: chartOptions(componentState.rawValuePumping, componentState.rawValueIdle, componentState.rawValueStopped),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(unitActivityActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(unitActivityActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitActivity));
