import _ from 'lodash';

const downloadsDisplayState = state => {
    return {
        // Raw item details returned from the service
        items: _.isNil(state) ? [] : state.items,
        // Flag to indicate one of the equipment list remote calls is executing
        queryRunning: _.isNil(state) ? false : state.queryRunning,
    };
};

export {
    downloadsDisplayState
};