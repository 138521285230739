import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import systemSoftwareActionTypes from './systemSoftwareActionTypes';

import {fetchSystemSoftware, fetchExportByTruck, fetchExportByTruckStatus} from './systemSoftwareQueries';
import errorMessages from '../../common/errorMessages';
import {handleError} from "../../app/actions/appErrorActions";
import _ from "lodash";

const POLLING_TIMEOUT = 2000;

const refreshUploadTime = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_REFRESH_UPLOAD_TIME, 'stateDef');

const queryDataStarting = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_ERROR, 'stateDef');

const queryData = queryActionCustomFactory(
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  errorMessages.ERROR_RETRIEVING_SYSTEM_SOFTWARE_LIST,
  fetchSystemSoftware
);

const dataGridSetRowSize = defaultActionFactory(systemSoftwareActionTypes.SET_ROW_SIZE, 'stateDef', 'rowSize');



const queryExportByTruckNameStarting = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_STARTING, 'stateDef');
const queryExportByTruckNameSuccess = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_SUCCESS, 'stateDef', 'results');
const queryExportByTruckNameError = defaultActionFactory(systemSoftwareActionTypes.SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_ERROR, 'stateDef');


/**
 * Action to keep checking for the status of an export and
 * dispatch appropriately. While the complete state for the
 * provided export is not complete this method will keep
 * polling for the status. When a complete status is detected
 * then it will trigger the status to be updated in the state
 * information.
 */
const pollExportStatus = (stateDef, exportId, timeout=POLLING_TIMEOUT) => {
  return async (dispatch, getState) => {
    let response = null;
    // Go get the status of the export.
    try {
      if (_.isNil(exportId)) {
        throw Error('Error: Invalid parameter (exportId)')
      }
      response = await fetchExportByTruckStatus(exportId);
    } catch(e) {
      await dispatch(queryExportByTruckNameError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING_TRUCK_CONFIG, e.message));
    }
    if (_.get(response, 'truckConfigExportStatus.error', false)) {
      // If the export error flag is set, cancel the export.
      await dispatch(queryExportByTruckNameError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING_TRUCK_CONFIG, 'Export failure status returned from server'));
    } else if (_.get(response, 'truckConfigExportStatus.complete', false)) {
      // If the export success flag is set, dispatch a success action.
      return dispatch(queryExportByTruckNameSuccess(stateDef, response));
    } else {
      // Else continue to poll for the status.
      return new Promise( (resolve) => {
        setTimeout(() => { resolve(dispatch(pollExportStatus(stateDef, exportId))) }, timeout);
      });
    }
  }
};

/**
 * Action to perform a remote query for a zip file of truck configs. This
 * is done in two steps. The first step is to initiate the export
 * which provides an export id and status. The second step is to keep
 * checking the status to determine when it is complete. See the
 * pollExportStatus for step 2 details.
 */
const exportByTruck = (stateDef, truckName) => {
  return async (dispatch, getState) => {
    // Signal that the export is starting
    await dispatch(queryExportByTruckNameStarting(stateDef));
    let exportId = null;
    // Initiate the export
    try {
      if (_.isNil(truckName)) {
        throw Error('Error: Invalid parameter (truckName)')
      }
      let response = await fetchExportByTruck(truckName);
      exportId = response.startTruckConfigExport.exportId;
    } catch(e) {
      await dispatch(queryExportByTruckNameError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING_TRUCK_CONFIG, e.message));
    }
    // Start polling the export status until it is ready for download.
    return dispatch(pollExportStatus(stateDef, exportId));
  }
};

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryData,
  refreshUploadTime,
  dataGridSetRowSize,
  exportByTruck,
  pollExportStatus,
  queryExportByTruckNameSuccess
};