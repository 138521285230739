import React from 'react';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Box} from '@mui/material';

import ComponentTypes from '../../componentTypes';
import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import {fleetVibrationState} from '../../../state/cards/fleetVibration/fleetVibrationSelectors';
import * as fleetVibrationActions from '../../../state/cards/fleetVibration/fleetVibrationActions';
import ButtonBase from "@mui/material/ButtonBase";
import HealthIcon from '@mui/icons-material/LocalHospital';
import fleetMapViews from "../../../state/displays/fleetMap/display/fleetMapViews";
import VerticalBarChart from "../../controls/charts/verticalBarChart/verticalBarChart";

import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,

  // Overall card style
  card: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardHeaderContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    alignItems: 'center',
  },
};

class FleetVibration extends MDTCard {

  getName(){
    return 'VIBRATION';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.id);
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  getRenderedButtonsInHeader() {
    return (
      !this.isInEditMode() &&
      <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.props.navigateToView(fleetMapViews.HEALTH)}>
        <HealthIcon/>
      </ButtonBase>
    )
  }

  getRenderedContent() {
    {/* Card Content */}
    return (
      <Box sx={styles.cardContent}>
        <VerticalBarChart data={this.props.powerEnd} label="Power End"
                          minLabel={0} maxLabel={20} total={20}
                          hiThreshold={4} hihiThreshold={6}/>
        <VerticalBarChart data={this.props.fluidEnd} label="Fluid End"
                          minLabel={0} maxLabel={20} total={20}
                          hiThreshold={4} hihiThreshold={6}/>
        <VerticalBarChart data={this.props.iron} label="Iron"
                          minLabel={0} maxLabel={20} total={20}
                          hiThreshold={4} hihiThreshold={6}/>
        <VerticalBarChart data={this.props.manifold} label="Manifold"
                          minLabel={0} maxLabel={20} total={20}
                          hiThreshold={4} hihiThreshold={6}/>
      </Box>
    )
  }
}

FleetVibration.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_VIBRATION : props.stateKey,
      type: ComponentTypes.FLEET_VIBRATION,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetVibrationState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    powerEnd: componentState.powerEnd,
    fluidEnd: componentState.fluidEnd,
    iron: componentState.iron,
    manifold: componentState.manifold,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(fleetVibrationActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetVibrationActions.clearData(props.stateDef)) },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetVibration))