import _ from "lodash";
import React, {Component} from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Typography, Box, Chip, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Progress from '../../controls/progress';
import FilterDialog from '../../common/filtering/filterDialog';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataTable from '../../common/table/dataTable';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportCsv from '../../controls/exportCsv/exportCsv';

import ComponentTypes from '../../componentTypes';
import * as equipmentListActions from '../../../state/displays/equipmentList/equipmentListActions';
import * as pumpDashboardActions from '../../../state/displays/pumpDashboard/pumpDashboardActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { equipmentListState } from '../../../state/displays/equipmentList/equipmentListSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';
import * as assetTypesService from '../../../state/common/services/assetTypeService';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getTableFilterStyles from '../../common/styles/tableFilter';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();
const tableFilterStyles = getTableFilterStyles();

const styles = {
  ...detailsPageStyles,
  ...tableFilterStyles,
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  }
};

class EquipmentList extends Component {

  constructor(props) {
    super(props);
    this.renderTableAction = this.renderTableAction.bind(this);
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.EQUIPMENT_LIST, this.props.user);
    this.props.queryEquipment();
    // Start the timer for refreshing the display.
    this.periodicEquipmentRefreshId = this.periodicRefreshEquipment();
    // Start the timer for refreshing the data age label
    this.periodicSensorAgeRefreshId = this.periodicRefreshSensorAge();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the equipment.
    if (!_.isNil(this.periodicEquipmentRefreshId)) {
      clearInterval(this.periodicEquipmentRefreshId);
    }
    // Stop the timer for refreshing the sensor age label
    if (!_.isNil(this.periodicSensorAgeRefreshId)) {
      clearInterval(this.periodicSensorAgeRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryEquipment();
    }
  }

  periodicRefreshEquipment() {

    // Periodically refresh the equipment.

    return setInterval(()=>{
      this.props.queryEquipment();
    }, 900000); // 15 minutes
  }

  periodicRefreshSensorAge() {

    // Periodically refresh the relative time for the sensor age.

    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  generateTableColumns() {
    let columns = [];
    columns.push({type: 'data', width: 130, label: 'Unit ID', displayProperty: 'name', sortProperty: 'name'});
    columns.push({type: 'data', width: 130, label: 'Type', displayProperty: 'displayValueType', sortProperty: 'displayValueType' });
    columns.push({type: 'data', width: 140, label: 'ECM Hours', displayProperty: 'displayValueEcmHours', sortProperty: 'ecmHours' });
    columns.push({type: 'data', width: 130, label: 'TCM Hours', displayProperty: 'displayValueTcmHours', sortProperty: 'tcmHours'});
    columns.push({type: 'data', width: 130, label: 'Pump Hours', displayProperty: 'displayValuePumpHours', sortProperty: 'pumpHours'});
    columns.push({type: 'data', width: 130, label: 'Fleet', displayProperty: 'fleetName', sortProperty: 'fleetName'});
    columns.push({type: 'data', width: 130, label: 'Upload Time', displayProperty: 'displayValueAge', sortProperty: 'timeStamp'});
    columns.push({type: 'action', width: 60, renderAction: this.renderTableAction});
    return columns;
  }

  renderTableAction(tableContext, item) {
    // We currently only allow table actions for frac pumps
    if (!_.isNil(item) && item.type === assetTypesService.assetTypes.FRAC_PUMP) {
      return <IconButton onClick={() => this.props.navigateToDashboard(item.id)} size="large"><DashboardIcon fontSize={'small'}/></IconButton>;
    }
  }

  render() {

    // Generate the columns data for the data table.
    let columns = this.generateTableColumns();

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Equipment Hours</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton onClick={() => this.props.queryEquipment()} size="large"><RefreshIcon/></IconButton>
              <IconButton
                onClick={() => this.props.exportCsv(this.props.equipment, this.props.sortContext, this.props.sortDirection)}
                size="large"><GetAppIcon/></IconButton>
            </Box>
          </Box>
          <Box sx={styles.tableFilterContainer}>
            <Typography variant={'subtitle1'}>FILTER:</Typography>
            {
              this.props.appliedFilters.map((filter, index) => {
                return (
                  <Box key={index} sx={styles.tableFilterComponent}>
                    <Chip label={filter.chipLabel} onDelete={() => this.props.deleteFilter(index)} />
                  </Box>
                )
              })
            }
            <Box sx={styles.tableFilterComponent}>
              <IconButton
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                size="large"><AddCircleIcon/></IconButton>
            </Box>
          </Box>
          <DataTable stateDef={this.props.stateDef} columns={columns} items={this.props.equipment} />
        </Box>
        <FilterDialog stateDef={this.props.stateDef} />
        <Progress open={this.props.queryRunning}/>
        <ExportCsv triggerExportCsv={this.props.triggerExportCsv} exportCsvUrl={this.props.exportCsvUrl}/>
      </Box>
    );
  }
}


const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.EQUIPMENT_LIST : props.stateKey,
      type: ComponentTypes.EQUIPMENT_LIST,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = equipmentListState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    equipment: componentState.filteredEquipment,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    sortContext: componentState.sortContext,
    sortDirection: componentState.sortDirection,
    exportCsvUrl: componentState.exportCsvUrl,
    triggerExportCsv: componentState.triggerExportCsv,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryEquipment: () => { dispatch(equipmentListActions.queryData(props.stateDef)) },
    refreshRelativeTime: () => { dispatch(equipmentListActions.refreshRelativeTime(props.stateDef)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    navigateToDashboard: (unitId) => dispatch(pumpDashboardActions.navigate(unitId)),
    exportCsv: (equipment, sortContext, sortDirection) => { dispatch(equipmentListActions.exportCsv(props.stateDef, equipment, sortContext, sortDirection))},
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(EquipmentList))