import deviceDialogActionTypes from './deviceDialogActionTypes';
import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import {handleError} from "../../../../app/actions/appErrorActions";
import errorMessages from "../../../../common/errorMessages";
import * as deviceActions from "../../actions/devicesActions";

const setDeviceOwner = defaultActionFactory(deviceDialogActionTypes.SET_NEW_DEVICE_OWNER, 'stateDef', 'value');
const setDeviceName = defaultActionFactory(deviceDialogActionTypes.SET_NEW_DEVICE_NAME, 'stateDef', 'value');
const saveNewDevice = defaultActionFactory(deviceDialogActionTypes.SAVE_NEW_DEVICE, 'stateDef');


const loadContextDatStarting = defaultActionFactory(deviceDialogActionTypes.LOAD_CONTEXT_DATA_STARTING, 'stateDef');
const loadContextDatSuccess = defaultActionFactory( deviceDialogActionTypes.LOAD_CONTEXT_DATA_SUCCESS, 'stateDef', 'context');
const loadContextDatError = defaultActionFactory(deviceDialogActionTypes.LOAD_CONTEXT_DATA_ERROR, 'stateDef');

const clearForm = defaultActionFactory(deviceDialogActionTypes.CLEAR_FORM, 'stateDef');

const loadContextData = (stateDef, context) => {
  return async (dispatch, getState) => {
    try {

      await dispatch(loadContextDatStarting(stateDef));

      await dispatch(loadContextDatSuccess(stateDef, context));

    } catch(e) {
      await dispatch(loadContextDatError(stateDef));

      await dispatch(deviceActions.showDeviceDialog(false, context));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_DEVICE_DIALOG_DATA, e.message));
    }
  }
};

export {
  loadContextData,
  saveNewDevice,
  setDeviceName,
  setDeviceOwner,
  clearForm,
}