import _ from 'lodash';

/**
 * Fill series data with NaN values to start.
 * Then fill in data when there is a value. Ignore nulls.
 *
 * @param seriesValues Array of series data values.
 * @param xValues Timestamps for each data value
 */
const fillSeriesWithData = (xValues, seriesValues) => {
  let values = Array(xValues.length).fill(NaN);
  _.forEach(xValues, (t, i) => {
    let value = seriesValues[i];
    if (!_.isNil(value)) {
      values[i] = value;
    }
  });
  return values;
};

/**
 * Normalize and prepare raw sensor data.
 * @param seriesData Data returned from the query
 * @param xValues Timestamps for each data value
 * @return {unknown[]} Normalized sensor data.
 */
const normalizeSensorData = (xValues, seriesData) => {
  let sensorData = {};

  _.map(seriesData, (series) => {
    sensorData[series.sensorSetId] = fillSeriesWithData(xValues, series.values);
  });

  return _.isNil(sensorData) ? [] : sensorData;
};

/**
 * Given a Stage Chart Definition, determine if the given stage
 * is primary or secondary.
 * @param {*} stage 
 * @param {*} definition 
 */
const checkStageAgainstDefinition = (stage, definition) => {
  const hasPrimary = !_.isNil(definition.primary.defaultContext);
  const hasSecondary = !_.isNil(definition.secondary.defaultContext);

  if (hasPrimary && _.isEqual(stage.id, definition.primary.defaultContext.stage.id)) {
    return 1;
  } else if (hasSecondary && _.isEqual(stage.id, definition.secondary.defaultContext.stage.id)) {
    return 2;
  } else {
    return 0;
  }

}

/**
 * Given two datasets, primary and secondary, ensure that they both have the same
 * timespan. Fill in the missing data with NaN values.
 * For example, if primary has a 4 hour timespan and secondary has a 2 hour timespan, we should make
 * secondary have a 4 hour timespan as well.
 * @param {*} primaryXValues Primary Definition's XValues as an array
 * @param {*} primaryYValues Primary Definition's YValues as an array keyed by sensorSetId
 * @param {*} secondaryXValues Secondary Definition's XValues as an array
 * @param {*} secondaryYValues Secondary Definition's YValues as an array keyed by sensorSetId
 */
const equalizeTimespansForPrimaryAndSecondary = (primaryXValues, primaryYValues, secondaryXValues, secondaryYValues) => {
  const primaryTimespan = primaryXValues[primaryXValues.length - 1] - primaryXValues[0];
  const secondaryTimespan = secondaryXValues[secondaryXValues.length - 1] - secondaryXValues[0];

  // Only do something if the timespans are different (which is almost always the case)
  if (primaryTimespan > secondaryTimespan) {
    equalizeSmallerTimespanToLargerTimespan(secondaryTimespan, primaryTimespan, secondaryXValues, secondaryYValues);
  } else if (secondaryTimespan > primaryTimespan) {
    equalizeSmallerTimespanToLargerTimespan(primaryTimespan, secondaryTimespan, primaryXValues, primaryYValues);
  }
}

/**
 * Given a larger and smaller timespan and the smaller timespan's x and y values, equalize the data for the smaller timespan to the larger timespan.
 * @param {*} smallerTimespan Smaller timespan
 * @param {*} largerTimespan Larger timespan
 * @param {*} smallerXValues XValues for Smaller timespan
 * @param {*} smallerYValues YValues for Smaller timespan keyed by sensorSetId
 */
const equalizeSmallerTimespanToLargerTimespan = (smallerTimespan, largerTimespan, smallerXValues, smallerYValues) => {

  // Find the difference between the two timespans, divide the difference by 2 and add that to each end of the smaller timespan
  const difference = largerTimespan - smallerTimespan;
  // Round up to the nearest whole number
  const halfDifference = Math.ceil(difference / 2);

  // Generate the new x values to be added to the beginning and end of the smaller timespan
  const valuesToAddAtBeginning = _.range(smallerXValues[0] - halfDifference, smallerXValues[0], 1);
  // _.range is inclusive of the starting value, exclusive of the ending value
  // so to avoid repeating the last value in the array of x values and include the next logical value, we add 1 to the ending value
  const valuesToAddAtEnd = _.range(smallerXValues[smallerXValues.length - 1] + 1, smallerXValues[smallerXValues.length - 1] + halfDifference + 1, 1);
  smallerXValues.unshift(...valuesToAddAtBeginning);
  smallerXValues.push(...valuesToAddAtEnd);

  // Generate the new y values to be added to the beginning and end of the smaller timespan
  const yValuesToAddAtBeginning = Array(valuesToAddAtBeginning.length).fill(NaN);
  const yValuesToAddAtEnd = Array(valuesToAddAtEnd.length).fill(NaN);
  
  // The y values are keyed by sensorSetId, so we need to process each key separately
  const yValueKeys = Object.keys(smallerYValues);
  _.forEach(yValueKeys, (key) => {
    smallerYValues[key].unshift(...yValuesToAddAtBeginning);
    smallerYValues[key].push(...yValuesToAddAtEnd);
  });
}

export {
    normalizeSensorData,
    checkStageAgainstDefinition,
    equalizeTimespansForPrimaryAndSecondary,
    equalizeSmallerTimespanToLargerTimespan
};