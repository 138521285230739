const getSensorSelectorStyles = () => ({

  sensorList: {
    backgroundColor: 'background.default',
    overflow: "auto",
    height: '640px',
    maxHeight: '640px',
    minHeight: '640px',
    color: 'rgba(255, 255, 255, 0.7)',
    // This gets the List Item to properly show a menu on hover
    ['& .MuiListItem-container:hover']: {
      backgroundColor: 'rgb(66, 66, 66)',
      '& .MuiListItemSecondaryAction-root': {
        visibility: 'visible'
      }
    },
    // Fixes some List Item styling consistency issues and only affects Sensor Selector
    ['& .MuiListItem-root:hover']: {
      backgroundColor: 'grey.800',
    },
    ['& .MuiListItem-root.Mui-selected']: {
      backgroundColor: 'grey.800',
      '&:hover': {
        backgroundColor: 'grey.700',
      }
    }
  },

  sensorGroupSecondaryText: {
    paddingLeft: 2,
    paddingTop: '3px',
    fontSize: '12px',
  },
  sensorGroupSecondaryTextAlreadySelected: {
    paddingLeft: 2,
    paddingTop: '3px',
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 0.35)' // emulates the text color of a disabled ListItem
  },
  listItem: {
    overflowWrap: 'break-word'
  },
  message: {
    padding: 1, 
    fontSize: '14px'
  }
})

export default getSensorSelectorStyles;
