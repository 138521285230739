import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import store from '../../helpers/storeHelper';
import {
  isAuthenticated,
  logout,
  getDisplayDataServiceToken,
  getCardDataServiceToken
} from '../../helpers/authHelper';
import {
  getCardApiUrl,
  getDisplayApiUrl
} from '../../helpers/configHelper';

/**
 * Performs a fetch against the Card Data service GraphQL API
 */
const performCardFetch = (query) => {
  return performFetch(getCardApiUrl(), getCardDataServiceToken(), query);
};

/**
 * Peforms a fetch against the Display Data service GraphQL API
 */
const performDisplayFetch = (query) => {
  return performFetch(getDisplayApiUrl(), getDisplayDataServiceToken(), query);
};

/**
 * Utility method to wrap the fetch call of a GraphQL query/mutation.
 * Ensures that any HTTP, GraphQL, and Schema errors are handled and
 * that the json response is returned.
 */
const performFetch = (url, token, query) => {
  // Ensure we are authenticated prior to performing the fetch call.
  if (!isAuthenticated()) {
    logout();
  }

  let queryBody = JSON.stringify(query);

  // If query body is greater than 10MB, throw an error
  // Don't send the request since it will get rejected
  // Use 1024 * 1024 Base2 for determining size
  if (queryBody.length / (1048576) > 10) {
    throw Error('Error: Query exceeded the limit of 10MB');
  }

  return fetch(url, {
    method: 'POST',
    headers: getHeaders(token, store.getState()),
    body: queryBody
  }).then(handleErrors)
    .then(response => { return response.json() })
    .then(validateBody)
};

/**
 * Utility method parsing a fetch response for HTTP errors
 */
const handleErrors = (response) => {
  if (!response.ok) {
    let message = 'Error: {url} {status} ({statusText})'
      .replace('{url}', response.url)
      .replace('{status}', response.status)
      .replace('{statusText}', response.statusText);
    throw Error(message);
  }
  return response;
};

/**
 * Utility error to handle any returned GraphQL errors and
 * ensure the schema is valid (ie. error or data
 * elements are present)
 */
const validateBody = (body) => {
  if (!_.isNil(body.errors)) {
    if (body.errors.length > 0) {
      throw Error('Error: Query ({error})'.replace('{error}', body.errors[0].message));
    }
    throw Error('Error: Query (unknown)');
  }
  if (_.isNil(body.data)) {
    throw Error('Error: Query (missing query results)');
  }
  return body.data;
};

/**
 * Utility method to generate the headers for the GraphQL queries.
 */
const getHeaders = (token, state) => {

  let header =  {
    'content-type': 'application/json',
    'X-CorrelationId' : uuidv4(),
    'Authorization': 'bearer {token}'.replace('{token}', token)
  };

  // If the user is a super user we will add the owner they are using,
  // the one they have selected in the appbar, to the header.
  if (!_.isNil(state)) {
    let owner = _.get(state, 'app.selectedOwner.value', null);
    if (owner !== null) {
      header['X-OwnerId'] = owner;
    }
  }

  // If we have the user email information use that as a X-CorrelationId instead of a guid
  if (!_.isNil(state)) {
    let email = _.get(state, 'app.user.email', null);
    if (!_.isNil(email)) {
      header['X-CorrelationId'] = '{email}-{epochMilli}'
        .replace('{email}', email)
        .replace('{epochMilli}', moment().valueOf());
    }
  }

  return header;
};

export {
  performDisplayFetch,
  performCardFetch,
  performFetch,
  getHeaders
}