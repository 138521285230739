import { assetTypes } from '../../common/services/assetTypeService';

const fetchComponentHoursDisplayDetails = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        componentHoursDisplayDetails : simData.componentHoursDisplayDetails
      })
    }, 1000)
  });
};

const createRandomHours = () => {
  return Math.floor(Math.random()*99999);
};

const simData = {
  componentHoursDisplayDetails: [

    // Blenders

    {
      truckId: 259,
      name: '750053',
      unitType: assetTypes.BLENDER,
      fleetId: null,
      fleetName: 'MC-4',
      region: 'Mid-Con',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 582,
      name: '750046',
      unitType: assetTypes.BLENDER,
      fleetId: null,
      fleetName: 'STX-1',
      region: 'South',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 442,
      name: '750058',
      unitType: assetTypes.BLENDER,
      fleetId: null,
      fleetName: 'WTX-8',
      region: 'West',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 365,
      name: '750074',
      unitType: assetTypes.BLENDER,
      fleetId: null,
      fleetName: 'ETX-1',
      region: 'East',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },


    // Chemvans

    {
      truckId: 325,
      name: '640002',
      unitType: assetTypes.CHEMVAN,
      fleetId: null,
      fleetName: 'MC-4',
      region: 'Mid-Con',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 147,
      name: '926735',
      unitType: assetTypes.CHEMVAN,
      fleetId: null,
      fleetName: 'STX-1',
      region: 'South',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 230,
      name: '640003',
      unitType: assetTypes.CHEMVAN,
      fleetId: null,
      fleetName: 'WTX-8',
      region: 'West',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 364,
      name: '926750',
      unitType: assetTypes.CHEMVAN,
      fleetId: null,
      fleetName: 'ETX-1',
      region: 'East',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },

    // Hydration

    {
      truckId: 455,
      name: '760029',
      unitType: assetTypes.HYDRATION,
      fleetId: null,
      fleetName: 'MC-4',
      region: 'Mid-Con',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 152,
      name: '760010',
      unitType: assetTypes.HYDRATION,
      fleetId: null,
      fleetName: 'STX-1',
      region: 'South',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 517,
      name: '760024',
      unitType: assetTypes.HYDRATION,
      fleetId: null,
      fleetName: 'WTX-8',
      region: 'West',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },
    {
      truckId: 537,
      name: '760033',
      unitType: assetTypes.HYDRATION,
      fleetId: null,
      fleetName: 'ETX-1',
      region: 'East',
      components: [
        { componentType: 'Hydraulic Engine', hours: createRandomHours() },
      ]
    },

    // Pumps

    {
      truckId: 447,
      name: '620268',
      unitType: assetTypes.FRAC_PUMP,
      fleetId: null,
      fleetName: 'MC-4',
      region: 'Mid-Con',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Transmission', hours: createRandomHours() },
        { componentType: 'Fluid End', hours: createRandomHours() },
        { componentType: 'Power End', hours: createRandomHours() },
      ]
    },
    {
      truckId: 648,
      name: '620395',
      unitType: assetTypes.FRAC_PUMP,
      fleetId: null,
      fleetName: 'STX-1',
      region: 'South',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Transmission', hours: createRandomHours() },
        { componentType: 'Fluid End', hours: createRandomHours() },
        { componentType: 'Power End', hours: createRandomHours() },
      ]
    },
    {
      truckId: 221,
      name: '620035',
      unitType: assetTypes.FRAC_PUMP,
      fleetId: null,
      fleetName: 'WTX-8',
      region: 'West',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Transmission', hours: createRandomHours() },
        { componentType: 'Fluid End', hours: createRandomHours() },
        { componentType: 'Power End', hours: createRandomHours() },
      ]
    },
    {
      truckId: 359,
      name: '620393',
      unitType: assetTypes.FRAC_PUMP,
      fleetId: null,
      fleetName: 'ETX-1',
      region: 'East',
      components: [
        { componentType: 'Engine', hours: createRandomHours() },
        { componentType: 'Transmission', hours: createRandomHours() },
        { componentType: 'Fluid End', hours: createRandomHours() },
        { componentType: 'Power End', hours: createRandomHours() },
      ]
    },
  ]
};

export {
  fetchComponentHoursDisplayDetails,
}
