import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Grid, CircularProgress } from '@mui/material';
import { handleLogin } from '../../../state/app/actions/appUserActions';

const styles = {
  page: {
    width: '100%',
    maxWidth: (theme) => theme.breakpoints.values.md,
    paddingTop: 16,
    paddingLeft: 2,
    paddingRight: 2
  }
};

class AuthCallbackPage extends Component {

  componentDidMount() {
    // Handle the Auth0 callback while we show a busy UI component.
    this.props.handleLogin();
  }

  render() {
    return (
      <Grid container justifyContent='center'>
        <Box sx={styles.page}>
          <Grid container spacing={24} justifyContent='center'>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <CircularProgress size={80} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align="center">Signing in...</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin: () => { dispatch(handleLogin()) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallbackPage);