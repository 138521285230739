import _ from "lodash";

import { defnEmpty } from "./services/dataExplorationChartDefinitionService";
import { exportCsvState } from'../../common/exportCsv/exportCSVSelectors';

const dataExplorationChartState = state => {

  let exportCsvDetails = exportCsvState(state);

  return {
    ...exportCsvDetails,

    // Flag to indicate a remote call is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    definition: _.isNil(state) ? defnEmpty() : state.definition,
    data: _.isNil(state) ? {primary: {}, secondary: {}} : state.data,
    stats: _.isNil(state) ? {primary: {}, secondary: {}} : state.stats,
    shouldOpenSensorSelector: _.isNil(state) ? false : state.shouldOpenSensorSelector,
    colorPickerState: _.isNil(state) ? null : state.colorPickerState,
    isRotated: _.isNil(state) ? false : state.isRotated,
    openChartSettingsPopup: _.isNil(state) ? false : state.openChartSettingsPopup,
    closeNaNGap: _.isNil(state) ? false : state.closeNaNGap,
    chartSettingButtonAnchor: _.isNil(state) ? null : state.chartSettingButtonAnchor,
    openScreenShotSettingsPopup: _.isNil(state) ? false : state.openScreenShotSettingsPopup,
    screenShotButtonAnchor: _.isNil(state) ? null : state.screenShotButtonAnchor,
    isDelayedScreenShot: _.isNil(state) ? false: state.isDelayedScreenShot,
  }
};

export {
  dataExplorationChartState
};