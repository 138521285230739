export default {

  ASSET_TRACKING_QUERY_ASSET_DATA_STARTING: 'ASSET_TRACKING_QUERY_ASSET_DATA_STARTING',
  ASSET_TRACKING_QUERY_ASSET_DATA_SUCCESS: 'ASSET_TRACKING_QUERY_ASSET_DATA_SUCCESS',
  ASSET_TRACKING_QUERY_ASSET_DATA_ERROR: 'ASSET_TRACKING_QUERY_ASSET_DATA_ERROR',
  ASSET_TRACKING_UPDATE_EDIT_MODE: 'ASSET_TRACKING_UPDATE_EDIT_MODE',

  ASSET_TRACKING_SAVE_LOCATIONS_STARTING: 'ASSET_TRACKING_SAVE_LOCATIONS_STARTING',
  ASSET_TRACKING_SAVE_LOCATIONS_SUCCESS: 'ASSET_TRACKING_SAVE_LOCATIONS_SUCCESS',
  ASSET_TRACKING_SAVE_LOCATIONS_ERROR: 'ASSET_TRACKING_SAVE_LOCATIONS_ERROR',

  ASSET_TRACKING_DELETE_LOCATION_STARTING: 'ASSET_TRACKING_DELETE_LOCATION_STARTING',
  ASSET_TRACKING_DELETE_LOCATION_SUCCESS: 'ASSET_TRACKING_DELETE_LOCATION_SUCCESS',
  ASSET_TRACKING_DELETE_LOCATION_ERROR: 'ASSET_TRACKING_DELETE_LOCATION_ERROR',

  ASSET_TRACKING_QUERY_LOCATIONS_STARTING: 'ASSET_TRACKING_QUERY_LOCATIONS_STARTING',
  ASSET_TRACKING_QUERY_LOCATIONS_SUCCESS: 'ASSET_TRACKING_QUERY_LOCATIONS_SUCCESS',
  ASSET_TRACKING_QUERY_LOCATIONS_ERROR: 'ASSET_TRACKING_QUERY_LOCATIONS_ERROR',  

  ASSET_TRACKING_UPDATE_CAN_SAVE: 'ASSET_TRACKING_UPDATE_CAN_SAVE',
  ASSET_TRACKING_SET_SHOW_INACTIVE: 'ASSET_TRACKING_SET_SHOW_INACTIVE',
}