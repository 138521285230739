import * as fracJobOverviewCardSimulation from './fracJobOverviewCardSimulation';
import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchFleetFracJobOverview = (fleetId) => {
  if (useSimulationData('fracJobOverviewCard')) {
    return fracJobOverviewCardSimulation.fetchFracJobOverview(fleetId);
  }

  let queryString = '{ fleetFracJobOverview(fleetId:{fleetId}) { fleetType customerName jobName state flowRate pressure ' +
      'alarmCounts {criticalAlarmCount infoAlarmCount warningAlarmCount } ' +
      'frontSideCounts { label low med high } ' +
      'backSideCounts { label low med high } ' +
      'wells {padName wellName plannedStages } ' +
      '} }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performCardFetch({
    'query': queryString
  })
};

export {
  fetchFleetFracJobOverview
}


