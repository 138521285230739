import exportCsvActionTypes from './exportCsvActionTypes';
import * as exportCsvService from './exportCsvService';

const exportCsvReducer = (state, action) => {

  switch (action.type) {

    case exportCsvActionTypes.EXPORT_CSV_STARTING:
      return { ...state, ...exportCsvService.onExportCsvStarting(state, action) };

    case exportCsvActionTypes.EXPORT_CSV_SUCCESS:
      return { ...state, ...exportCsvService.onExportCsvSuccess(state, action) };

    case exportCsvActionTypes.EXPORT_CSV_ERROR:
      return { ...state, ...exportCsvService.onExportCsvError(state, action) };

    default: return state;

  }
};

export default exportCsvReducer