import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import WarningAlarmBadge from '../../badges/warningAlarmBadge';
import CriticalAlarmBadge from '../../badges/criticalAlarmBadge';
import InfoAlarmBadge from '../../badges/infoAlarmBadge';
import { Typography, Box } from "@mui/material";
import getTypographyStyles from "../../../common/styles/typographyStyles";
import MDTDataGrid from "../../../common/table/MDTDataGrid";
import * as alarmStatusHelper from "../../../common/alarmStatusIconHelper";
import getCardStyles from "../../../cards/cardStyles";
import moment from "moment";

const typographyStyles = getTypographyStyles();
const alarmStatusStyles = alarmStatusHelper.getAlarmStatusIconStyles();
const cardStyles = getCardStyles();

const styles = {
  ...typographyStyles,
  ...alarmStatusStyles,
  ...cardStyles,

  alarmContent: {
    display: 'flex',
    flexFlow: 'row',
      width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
  },

  largeAlarmContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
      width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexGrow: 1,
    flexWrap: 'wrap',
  },

  noDataLabelContainer: {
    height: '100px',
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tableIconColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'5px',
  }
};


class Alarms extends Component {

  getRenderContentWithActiveAlarms(){
      //cover severity level digit to icon
      const parseSeverity = (params) => {
          const  {value} = params;
          return (
              <Box sx={{...styles.tableIconColumn, width: '100%'}}>
                  {alarmStatusHelper.getAlarmStatusIcon(value, styles)}
              </Box>
          )
      }

      // parse the age to expected format for display
      const parseAge = (params) => {
          // expect incoming age to be in unix seconds
          return moment.utc(params.row?.Age * 1000).fromNow()
      }

      const visibleColumns = _.filter(this.props.cardConfig.configuration.columns, ['visible', true]);
      if(_.isEmpty(visibleColumns)){
          return (
              <>
                  <Typography variant={'caption'} sx={styles.noDataLabel}>No Columns Selected</Typography>
              </>
          );
      }
      else if ( !_.isNil(this.props.alarmCounts) && !_.isEmpty(this.props.alarmCounts.activeAlarms)){
          //make data with expected column name
          const data = this.props.alarmCounts.activeAlarms.map(function (c){
              return {
                  'id': c.alarmKey,
                  'AlarmKey':c.alarmKey,
                  'RaisedTimestamp': c.raisedTimestamp,
                  'Severity': c.severityValue,
                  'Details':c.details,
                  'State': c.event,
                  'Age': c.raisedTimestamp, // raiseDTimestamp here is in unix format, which supports parseAge function
                  'Component': c.component,
                  'Type': c.type,
                  'SubType': c.subType,
                  'Truck Name': c.truckName,
              };
          }

          );
          // apply dataGrid configs for each visible column
          const columns = visibleColumns.map(function(c) {
              return {
                  field: c.name,
                  headerName: c.name,
                  headerAlign: 'center',
                  flex: c.name === 'Details' ? 3: 1,
                  editable: false,
                  hideable: false,
                  pinnable: false,
                  cellClassName: ['Details', 'Component'].includes(c.name) ? 'dataGridTableCellLeftAlign': 'dataGridTableCell',
                  renderCell: c.name === 'Severity' ? parseSeverity : c.name === 'Age' ? parseAge : null
              };
          });
          return (
              <Box sx={styles.mdtDataGridContainer}>
                  <MDTDataGrid
                      getRowId={row => row.id}
                      rows={data}
                      columns={columns}
                      getRowHeight={() => 'auto'}
                      stateDef={this.props.stateDef}
                      disableSelectionOnClick
                      pagination={false}
                      components={{Toolbar: null}}
                      disableColumnMenu={true}
                      disableColumnReorder={true}
                      disableColumnFilter={true}
                      disableColumnSelector={true}
                      hideFooter={true}
                      autoHeight={false}

                  />
              </Box>
          );
      } else {
          return (
              <>
                  <Typography variant={'caption'} sx={styles.noDataLabel}>No Active Alarm</Typography>
              </>
          );
      }

  }

  getRenderContentDefault(){
      const { alarmCounts } = this.props;
      let alarmContentClass = this.props.isLarge !== true ? styles.alarmContent : styles.largeAlarmContent;

      let criticalCount = !_.isNil(alarmCounts) && !_.isNil(alarmCounts.critical) ? alarmCounts.critical : 0;
      let warningCount = !_.isNil(alarmCounts) && !_.isNil(alarmCounts.warning) ? alarmCounts.warning : 0;
      let infoCount = !_.isNil(alarmCounts) && !_.isNil(alarmCounts.info) ? alarmCounts.info : 0;

      let noData = _.isNil(alarmCounts);

      return (

          <Box sx={alarmContentClass}>
            {
              noData && this.props.showIfZero !== true &&
              <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
            }
            <Box sx={{width: '90px', textAlign: 'center'}}>
                {
                    ((this.props.showIfZero === true) || (!this.props.showIfZero && criticalCount > 0)) &&
                    <CriticalAlarmBadge count={criticalCount} isLarge={this.props.isLarge}/>
                }
            </Box>
            <Box sx={{width: '75px', textAlign: 'center'}}>
                {
                  ((this.props.showIfZero === true) || (!this.props.showIfZero && warningCount > 0)) &&
                  <WarningAlarmBadge count={warningCount} isLarge={this.props.isLarge}/>
                }
            </Box>
            <Box sx={{width: '75px', textAlign: 'center'}}>
                {
                  ((this.props.showIfZero === true) || (!this.props.showIfZero && infoCount > 0)) &&
                  <InfoAlarmBadge count={infoCount} isLarge={this.props.isLarge}/>
                }
            </Box>
          </Box>
      );
  }

  render() {
    return (
        <>
          { ((_.isNil(this.props.cardConfig)) || [1, 2].includes(this.props.cardConfig?.w)) && this.getRenderContentDefault() }
          { !_.isNil(this.props.cardConfig) && ![1, 2].includes(this.props.cardConfig.w) && this.getRenderContentWithActiveAlarms() }
        </>
    )

  }
}

Alarms.propTypes = {
  alarmCounts: PropTypes.object,
  isLarge : PropTypes.bool,
  showIfZero: PropTypes.bool, // if true displays no data as no alarms,
  cardConfig: PropTypes.object,
  stateDef: PropTypes.object,
};

export default Alarms;