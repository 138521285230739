import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@mui/material';
import ComponentTypes from "../../componentTypes";
import * as fleetMgmtActions from "../../../state/displays/fleetManagement/fleetMgmtActions";
import {fleetMgmtState} from "../../../state/displays/fleetManagement/fleetMgmtSelectors";

class FleetToggleConfirmationDialog extends Component {
    render() {
        return (<Dialog
                open={this.props.openToggleFleetConfirmation}
                keepMounted>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm you want to set fleet as <strong>{this.props.fleetToSave.active? 'Inactive' : 'Active'}</strong>?
                    </DialogContentText>
                    <Typography>{this.props.fleetToSave.fleetName}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color='inherit' onClick={() => this.props.onCloseToggleFleetConfirmationDialog()}>Cancel</Button>
                    <Button color='primary' onClick={() => this.props.onConfirmToggleFleetActive({...this.props.fleetToSave, active: !this.props.fleetToSave.active})}>Confirm</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
            type: ComponentTypes.FLEET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    let componentState = fleetMgmtState(state[stateDef.key]);
    return {
        fleetToSave: componentState.fleetToSave,
        openToggleFleetConfirmation: componentState.openToggleFleetConfirmation
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onCloseToggleFleetConfirmationDialog: () => {
            dispatch(fleetMgmtActions.onCloseToggleFleetConfirmationDialog(props.stateDef))
        },
        onConfirmToggleFleetActive: (fleet) => {
            dispatch(fleetMgmtActions.onSaveFleet(props.stateDef, fleet));
        }
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FleetToggleConfirmationDialog))