import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import filterReducer from './reducers/filterReducer';
import displayReducer from './reducers/displayReducer';
import formReducer from './reducers/formReducer';
import commandReducer from './reducers/commandReducer';

import { componentsState } from './componentsSelectors';

const initialState = componentsState();

const componentsReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    filterReducer,
    displayReducer,
    formReducer,
    commandReducer,
  );

  return reducer(state, action);
};

export default componentsReducer
