import defaultActionFactory from '../../common/factories/defaultActionFactory'
import auditLogActionTypes from "./auditLogActionTypes";
import errorMessages from "../../common/errorMessages";
import * as cardQueries from "./auditLogQueries";
import {handleError} from "../../app/actions/appErrorActions";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import {getCardFromLayoutConfigViews} from "../../../components/common/layout/layoutHelper";
import componentTypes from "../../../components/componentTypes";
import moment from "moment";
import { push } from 'connected-react-router';
import * as queryString from "querystring";
import _ from "lodash";

const clearData = defaultActionFactory(auditLogActionTypes.AUDIT_LOG_CLEAR_DATA, 'stateDef');
const startingAction = defaultActionFactory(auditLogActionTypes.AUDIT_LOG_QUERY_DATA_STARTING, 'stateDef');
const successAction = defaultActionFactory(auditLogActionTypes.AUDIT_LOG_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const errorAction = defaultActionFactory(auditLogActionTypes.AUDIT_LOG_QUERY_DATA_ERROR, 'stateDef');

const onReorderColumns = (stateDef, dashboard, view, cardKey, fromIndex, toIndex) => {
  return async (dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    let configuration = card.configuration;
    const [movedColumn] = configuration.columns.splice(fromIndex, 1);
    configuration.columns.splice(toIndex, 0, movedColumn);
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
  }
};

const onToggleColumnVisibility = (stateDef, dashboard, view, cardKey, columnIndex) => {
  return async (dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    let configuration = card.configuration;
    configuration.columns[columnIndex].visible = !configuration.columns[columnIndex].visible;
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
  }
};

const onChangeRowSize = (stateDef, dashboard, view, cardKey, rowSize) => {
  return async (dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    let configuration = card.configuration;
    configuration.rowSize = rowSize;
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
  }
};

/**
 * redirect to audit log display page with truck and time frame
 * @param {*} assetName truckId/ datavanName
 * @param {*} startTime unix timestamp
 * @param {*} endTime unix timestamp
 */
const navigateByTruckAndTimeFrame = (assetName, startTime, endTime, dashboardType) => {
  return async (dispatch, getState) => {
    let queryParams = null;

    if (dashboardType === componentTypes.PUMP_DASHBOARD) {
        queryParams = {unitNumber:assetName};
    } else {
        queryParams = {datavanName:assetName};
    }

    if (!_.isNil(startTime) && !_.isNil(endTime)) {
      queryParams.startTime = startTime;
      queryParams.endTime = endTime;
    }

    queryParams.source = dashboardType;
    let url = "/audit-logs?" +  queryString.stringify(queryParams);
    return dispatch(push(url));
  };
};

const queryData = (stateDef, pid, dashboardType, startTime, endTime, rowSize) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(startingAction(stateDef));

      let query;
      if (dashboardType === componentTypes.FLEET_DASHBOARD) {
        // for fleetDashboard card display need to run auditLog query against datavan
        query = cardQueries.fetchFleetAuditLogData;
      } else {
        query = cardQueries.fetchPumpAuditLogData;
      }
       
      // construct query variables
      // if startTime and endTime are provided, use them, otherwise default to last 24 hours
      if (_.isNil(endTime) || _.isNil(startTime)) {
       endTime = moment().unix();
       startTime = moment.unix(endTime).subtract(24, 'hours').unix(); 
      }
      const pageable = {
        page: 0, // always just show the data from first page
        size: !rowSize ? 25: rowSize, // default for card to show 25 rows at most
        // default to sort by timestamp DESC
        sorts: [{
          field:"timestamp",
          direction: "DESC"
        }],
      }
      queryResults = await query(pid, startTime, endTime, pageable);
    } catch (e) {
      await dispatch(errorAction(stateDef));
      return dispatch(handleError(dashboardType === componentTypes.FLEET_DASHBOARD ? errorMessages.ERROR_RETRIEVING_FLEET_AUDIT_LOG_CARD_DATA : errorMessages.ERROR_RETRIEVING_UNIT_AUDIT_LOG_CARD_DATA, e.message));
    }

    return dispatch(successAction(stateDef, queryResults));
  };
};

export {
  clearData,
  queryData,
  onReorderColumns,
  onToggleColumnVisibility,
  onChangeRowSize,
  navigateByTruckAndTimeFrame,
}