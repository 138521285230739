import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const fetchJobEventLogData = (jobId) => {

  switch (jobId) {
    case 1: 
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: 'User A',
                  note: 'Hit the wrong button again. De Manifesto. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius interdum urna. Fusce aliquet bibendum augue, eget vestibulum dui fringilla eget. Curabitur posuere sapien ac lobortis maximus. Vivamus in arcu commodo, laoreet sapien vel, ullamcorper ligula. Vestibulum sed nibh leo. Aenean ornare mauris eu diam iaculis, vel porttitor neque fringilla. In ut venenatis magna. Aenean nisl libero, cursus vel tincidunt ac, rhoncus in sapien. Maecenas eu mattis orci. Sed vehicula lectus felis, a commodo sem scelerisque nec.' +
                  'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum aliquam, mauris at lobortis elementum, libero augue ornare diam, eget lobortis libero dolor ac dolor. Nunc scelerisque, massa in posuere dictum, mi nunc malesuada nisl, eu bibendum augue augue eu orci. Fusce accumsan pellentesque leo sit amet vulputate. Vivamus mattis, enim non ullamcorper imperdiet, sem dui commodo enim, eu tincidunt ligula est ullamcorper leo. Donec vitae eleifend sapien. Aenean vel consequat libero. Nulla posuere bibendum gravida. Nunc gravida, turpis id consequat convallis, eros augue finibus odio, non mattis augue urna vestibulum orci. Cras molestie euismod arcu eget semper. In neque nulla, condimentum sit amet convallis vitae, iaculis at est. Ut nunc lacus, condimentum id vulputate nec, convallis eu risus. Duis purus mauris, sollicitudin nec augue non, pretium dictum sem. Donec dui nisi, bibendum vel ultrices in, posuere non purus. Morbi luctus nulla a pellentesque gravida. Vestibulum rhoncus odio purus, in viverra mauris accumsan ut.' +
                  'Etiam at nunc magna. Nam massa massa, dignissim tempus viverra non, pellentesque sit amet nisl. Vivamus eu nisi et quam egestas laoreet nec scelerisque arcu. Donec sit amet lectus auctor, varius lorem sed, egestas arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam vehicula ut turpis sed ultrices. Duis quis erat consequat eros porta posuere ut dictum quam. Nullam rutrum lacus vitae felis semper, iaculis tincidunt urna fermentum. Aliquam vehicula congue magna a lobortis. Fusce sagittis lectus ut turpis ornare, non venenatis dolor suscipit. Nam ornare in nisi blandit suscipit. Nulla consequat tellus vitae accumsan consectetur. Morbi vestibulum in arcu ac dignissim. In ac euismod urna.' +
                  'Nunc nec enim nibh. Suspendisse pharetra enim ac faucibus fringilla. Donec pulvinar nunc id sodales suscipit. Suspendisse mollis velit et elit volutpat lobortis. Curabitur sodales tortor risus, eget congue felis aliquet at. Nunc vulputate auctor dignissim. Phasellus ullamcorper tempor consequat. Integer convallis consequat malesuada. Etiam mattis vitae turpis quis imperdiet. Sed porta felis quis odio hendrerit, vel faucibus urna finibus. Phasellus tincidunt lobortis ipsum at sagittis. Vivamus bibendum ligula non lacus congue efficitur. Donec bibendum ante sed tempus efficitur. Vestibulum faucibus blandit eros, ac malesuada tortor dictum non.' + 
                  'Aliquam libero orci, malesuada a mollis sit amet, suscipit at dui. Nunc odio eros, ullamcorper ac ornare cursus, mollis a urna. Morbi ante nunc, fermentum eu libero sed, tristique gravida sapien. Etiam consectetur, mi a tempor pharetra, justo.',
                  createdOn: '2022-08-12T15:00:00Z',
                }
              ]
            })
          }, 1000)
        }
      );
    case 2:
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: '',
                  note: '',
                  createdOn: '2022-08-10T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: '',
                  note: '',
                  createdOn: '2022-08-10T17:00:00Z',
                }
              ]
            })
          }, 1000)
        }
      );
    case 3:
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: 'MDT Cloud',
                  note: 'Job Approval Request from Frac 08',
                  createdOn: '2022-08-01T14:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Long Notes',
                  note: 'Hit the wrong button again. De Manifesto. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius interdum urna. Fusce aliquet bibendum augue, eget vestibulum dui fringilla eget. Curabitur posuere sapien ac lobortis maximus. Vivamus in arcu commodo, laoreet sapien vel, ullamcorper ligula. Vestibulum sed nibh leo. Aenean ornare mauris eu diam iaculis, vel porttitor neque fringilla. In ut venenatis magna. Aenean nisl libero, cursus vel tincidunt ac, rhoncus in sapien. Maecenas eu mattis orci. Sed vehicula lectus felis, a commodo sem scelerisque nec.' +
                        'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum aliquam, mauris at lobortis elementum, libero augue ornare diam, eget lobortis libero dolor ac dolor. Nunc scelerisque, massa in posuere dictum, mi nunc malesuada nisl, eu bibendum augue augue eu orci. Fusce accumsan pellentesque leo sit amet vulputate. Vivamus mattis, enim non ullamcorper imperdiet, sem dui commodo enim, eu tincidunt ligula est ullamcorper leo. Donec vitae eleifend sapien. Aenean vel consequat libero. Nulla posuere bibendum gravida. Nunc gravida, turpis id consequat convallis, eros augue finibus odio, non mattis augue urna vestibulum orci. Cras molestie euismod arcu eget semper. In neque nulla, condimentum sit amet convallis vitae, iaculis at est. Ut nunc lacus, condimentum id vulputate nec, convallis eu risus. Duis purus mauris, sollicitudin nec augue non, pretium dictum sem. Donec dui nisi, bibendum vel ultrices in, posuere non purus. Morbi luctus nulla a pellentesque gravida. Vestibulum rhoncus odio purus, in viverra mauris accumsan ut.' +
                        'Etiam at nunc magna. Nam massa massa, dignissim tempus viverra non, pellentesque sit amet nisl. Vivamus eu nisi et quam egestas laoreet nec scelerisque arcu. Donec sit amet lectus auctor, varius lorem sed, egestas arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam vehicula ut turpis sed ultrices. Duis quis erat consequat eros porta posuere ut dictum quam. Nullam rutrum lacus vitae felis semper, iaculis tincidunt urna fermentum. Aliquam vehicula congue magna a lobortis. Fusce sagittis lectus ut turpis ornare, non venenatis dolor suscipit. Nam ornare in nisi blandit suscipit. Nulla consequat tellus vitae accumsan consectetur. Morbi vestibulum in arcu ac dignissim. In ac euismod urna.' +
                        'Nunc nec enim nibh. Suspendisse pharetra enim ac faucibus fringilla. Donec pulvinar nunc id sodales suscipit. Suspendisse mollis velit et elit volutpat lobortis. Curabitur sodales tortor risus, eget congue felis aliquet at. Nunc vulputate auctor dignissim. Phasellus ullamcorper tempor consequat. Integer convallis consequat malesuada. Etiam mattis vitae turpis quis imperdiet. Sed porta felis quis odio hendrerit, vel faucibus urna finibus. Phasellus tincidunt lobortis ipsum at sagittis. Vivamus bibendum ligula non lacus congue efficitur. Donec bibendum ante sed tempus efficitur. Vestibulum faucibus blandit eros, ac malesuada tortor dictum non.' + 
                        'Aliquam libero orci, malesuada a mollis sit amet, suscipit at dui. Nunc odio eros, ullamcorper ac ornare cursus, mollis a urna. Morbi ante nunc, fermentum eu libero sed, tristique gravida sapien. Etiam consectetur, mi a tempor pharetra, justo.',
                  createdOn: '2022-08-11T14:00:00Z',
                },
                {
                  eventType: 'JobStart',
                  user: '',
                  note: 'Job Started',
                  createdOn: '2022-08-05T18:00:00Z',
                },
              ]
            })
          }, 1000)
        }
      );
    case 4:
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: 'MDT Cloud',
                  note: 'Job Approval Request from Frac 06',
                  createdOn: '2022-08-14T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Some Approver',
                  note: 'Something something Approved',
                  createdOn: '2022-08-15T17:00:00Z',
                },
                {
                  eventType: 'JobApprovalReject',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again. De Manifesto. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius interdum urna. Fusce aliquet bibendum augue, eget vestibulum dui fringilla eget. Curabitur posuere sapien ac lobortis maximus. Vivamus in arcu commodo, laoreet sapien vel, ullamcorper ligula. Vestibulum sed nibh leo. Aenean ornare mauris eu diam iaculis, vel porttitor neque fringilla. In ut venenatis magna. Aenean nisl libero, cursus vel tincidunt ac, rhoncus in sapien. Maecenas eu mattis orci. Sed vehicula lectus felis, a commodo sem scelerisque nec.' +
                        'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum aliquam, mauris at lobortis elementum, libero augue ornare diam, eget lobortis libero dolor ac dolor. Nunc scelerisque, massa in posuere dictum, mi nunc malesuada nisl, eu bibendum augue augue eu orci. Fusce accumsan pellentesque leo sit amet vulputate. Vivamus mattis, enim non ullamcorper imperdiet, sem dui commodo enim, eu tincidunt ligula est ullamcorper leo. Donec vitae eleifend sapien. Aenean vel consequat libero. Nulla posuere bibendum gravida. Nunc gravida, turpis id consequat convallis, eros augue finibus odio, non mattis augue urna vestibulum orci. Cras molestie euismod arcu eget semper. In neque nulla, condimentum sit amet convallis vitae, iaculis at est. Ut nunc lacus, condimentum id vulputate nec, convallis eu risus. Duis purus mauris, sollicitudin nec augue non, pretium dictum sem. Donec dui nisi, bibendum vel ultrices in, posuere non purus. Morbi luctus nulla a pellentesque gravida. Vestibulum rhoncus odio purus, in viverra mauris accumsan ut.' +
                        'Etiam at nunc magna. Nam massa massa, dignissim tempus viverra non, pellentesque sit amet nisl. Vivamus eu nisi et quam egestas laoreet nec scelerisque arcu. Donec sit amet lectus auctor, varius lorem sed, egestas arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam vehicula ut turpis sed ultrices. Duis quis erat consequat eros porta posuere ut dictum quam. Nullam rutrum lacus vitae felis semper, iaculis tincidunt urna fermentum. Aliquam vehicula congue magna a lobortis. Fusce sagittis lectus ut turpis ornare, non venenatis dolor suscipit. Nam ornare in nisi blandit suscipit. Nulla consequat tellus vitae accumsan consectetur. Morbi vestibulum in arcu ac dignissim. In ac euismod urna.' +
                        'Nunc nec enim nibh. Suspendisse pharetra enim ac faucibus fringilla. Donec pulvinar nunc id sodales suscipit. Suspendisse mollis velit et elit volutpat lobortis. Curabitur sodales tortor risus, eget congue felis aliquet at. Nunc vulputate auctor dignissim. Phasellus ullamcorper tempor consequat. Integer convallis consequat malesuada. Etiam mattis vitae turpis quis imperdiet. Sed porta felis quis odio hendrerit, vel faucibus urna finibus. Phasellus tincidunt lobortis ipsum at sagittis. Vivamus bibendum ligula non lacus congue efficitur. Donec bibendum ante sed tempus efficitur. Vestibulum faucibus blandit eros, ac malesuada tortor dictum non.' + 
                        'Aliquam libero orci, malesuada a mollis sit amet, suscipit at dui. Nunc odio eros, ullamcorper ac ornare cursus, mollis a urna. Morbi ante nunc, fermentum eu libero sed, tristique gravida sapien. Etiam consectetur, mi a tempor pharetra, justo.',
                  createdOn: '2022-08-15T18:00:00Z',
                },
              ]
            })
          }, 1000)
        }
      );
    case 5:
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: 'MDT Cloud',
                  note: 'Job Approval Request from Frac 18',
                  createdOn: '2022-08-10T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalReject',
                  user: 'LONGLONGLONNNNGGNAMENAMENAMENAME',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-17T16:45:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'name',
                  note: 'Something something Approved',
                  createdOn: '2022-08-10T17:00:00Z',
                },
                {
                  eventType: 'JobApprovalReject',
                  user: 'Some Approver',
                  note: 'Something something oops. Hit the wrong button.',
                  createdOn: '2022-08-11T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Lotsa Text',
                  note: 'Hit the wrong button again. De Manifesto. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius interdum urna. Fusce aliquet bibendum augue, eget vestibulum dui fringilla eget. Curabitur posuere sapien ac lobortis maximus. Vivamus in arcu commodo, laoreet sapien vel, ullamcorper ligula. Vestibulum sed nibh leo. Aenean ornare mauris eu diam iaculis, vel porttitor neque fringilla. In ut venenatis magna. Aenean nisl libero, cursus vel tincidunt ac, rhoncus in sapien. Maecenas eu mattis orci. Sed vehicula lectus felis, a commodo sem scelerisque nec.' +
                        'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum aliquam, mauris at lobortis elementum, libero augue ornare diam, eget lobortis libero dolor ac dolor. Nunc scelerisque, massa in posuere dictum, mi nunc malesuada nisl, eu bibendum augue augue eu orci. Fusce accumsan pellentesque leo sit amet vulputate. Vivamus mattis, enim non ullamcorper imperdiet, sem dui commodo enim, eu tincidunt ligula est ullamcorper leo. Donec vitae eleifend sapien. Aenean vel consequat libero. Nulla posuere bibendum gravida. Nunc gravida, turpis id consequat convallis, eros augue finibus odio, non mattis augue urna vestibulum orci. Cras molestie euismod arcu eget semper. In neque nulla, condimentum sit amet convallis vitae, iaculis at est. Ut nunc lacus, condimentum id vulputate nec, convallis eu risus. Duis purus mauris, sollicitudin nec augue non, pretium dictum sem. Donec dui nisi, bibendum vel ultrices in, posuere non purus. Morbi luctus nulla a pellentesque gravida. Vestibulum rhoncus odio purus, in viverra mauris accumsan ut.' +
                        'Etiam at nunc magna. Nam massa massa, dignissim tempus viverra non, pellentesque sit amet nisl. Vivamus eu nisi et quam egestas laoreet nec scelerisque arcu. Donec sit amet lectus auctor, varius lorem sed, egestas arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam vehicula ut turpis sed ultrices. Duis quis erat consequat eros porta posuere ut dictum quam. Nullam rutrum lacus vitae felis semper, iaculis tincidunt urna fermentum. Aliquam vehicula congue magna a lobortis. Fusce sagittis lectus ut turpis ornare, non venenatis dolor suscipit. Nam ornare in nisi blandit suscipit. Nulla consequat tellus vitae accumsan consectetur. Morbi vestibulum in arcu ac dignissim. In ac euismod urna.' +
                        'Nunc nec enim nibh. Suspendisse pharetra enim ac faucibus fringilla. Donec pulvinar nunc id sodales suscipit. Suspendisse mollis velit et elit volutpat lobortis. Curabitur sodales tortor risus, eget congue felis aliquet at. Nunc vulputate auctor dignissim. Phasellus ullamcorper tempor consequat. Integer convallis consequat malesuada. Etiam mattis vitae turpis quis imperdiet. Sed porta felis quis odio hendrerit, vel faucibus urna finibus. Phasellus tincidunt lobortis ipsum at sagittis. Vivamus bibendum ligula non lacus congue efficitur. Donec bibendum ante sed tempus efficitur. Vestibulum faucibus blandit eros, ac malesuada tortor dictum non.' + 
                        'Aliquam libero orci, malesuada a mollis sit amet, suscipit at dui. Nunc odio eros, ullamcorper ac ornare cursus, mollis a urna. Morbi ante nunc, fermentum eu libero sed, tristique gravida sapien. Etiam consectetur, mi a tempor pharetra, justo.',
                  createdOn: '2022-08-11T17:00:00Z',
                },
                {
                  eventType: 'JobApprovalReject',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-12T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-13T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalReject',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-17T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-17T16:05:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Some Approver',
                  note: 'Hit the wrong button again.',
                  createdOn: '2022-08-17T16:30:00Z',
                },
              ]
            })
          }, 1000)
        }
      );
    case 6: 
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob:
              [
                {
                  eventType: 'JobApprovalRequest',
                  user: 'MDT Cloud',
                  note: 'Job Approval Request from Frac 83',
                  createdOn: '2022-08-08T16:00:00Z',
                },
                {
                  eventType: 'JobApprovalAccept',
                  user: 'Approver',
                  note: 'Good to go!',
                  createdOn: '2022-08-09T15:45:00Z',
                },
                {
                  eventType: 'JobStart',
                  user: '',
                  note: 'Job Started',
                  createdOn: '2022-08-11T16:45:00Z',
                },
                {
                  eventType: 'JobEnd',
                  user: '',
                  note: 'Job End',
                  createdOn: '2022-08-24T14:00:00Z',
                },
              ]
            })
          }, 1000)
        }
      );
    default:
      return new Promise((resolve) => 
        {
          setTimeout(() => {
            resolve({
              getEventsForJob: []
            })
          }, 1000)
        }
      );
  }

};

const jobs =
    [
      {
        id: 1,
        name: 'J-8230',
        type: 'Frac',
        extId: 'a0C5G00000jNvKTUA0',
        status: 'JobApprovalRequest',
        fleetId: 1,
        fleetName: 'Frac 83',
        truckPid: 1,
        truckName: 'Datavan1',
        unitType: 'Datavan_Main',
        customerName: 'DEVON ENERGY CORP',
        padName: 'CBR 8-5B-56-1 332H / 333H / 322H / 336H',
        jobStartTimestamp: '2022-08-12T15:00:00Z',
        jobEndTimestamp: '2022-08-27T15:00:00Z',
        createdOn: '2022-08-06T15:00:00Z',
        lastModified: null,
        processType: 'Normal'
      },
      {
        id: 2,
        name: 'J-8378',
        type: 'Wireline',
        extId: 'a0C5G00000jOABhUAO',
        status: 'JobApprovalAccept',
        fleetId: 2,
        fleetName: 'WL 16',
        truckPid: 2,
        truckName: 'Datavan2',
        unitType: 'Datavan_Main',
        customerName: 'TITUS OIL & GAS PRODUCTION LLC',
        padName: 'Lonesome Dove Fed Com 402H/432H/512H',
        jobStartTimestamp: '2022-08-10T16:00:00Z',
        jobEndTimestamp: '2022-08-24T16:00:00Z',
        createdOn: '2022-08-08T16:00:00Z',
        lastModified: '2022-08-10T17:00:00Z',
        processType: 'Normal'
      },
      {
        id: 3,
        name: 'J-8733',
        type: 'Pumping',
        extId: 'a0C5G00000jPAfsUAG',
        status: 'JobStart',
        fleetId: 3,
        fleetName: 'Pump 01',
        truckPid: 3,
        truckName: 'Datavan3',
        unitType: 'Datavan_Main',
        customerName: 'HG ENERGY II APPALACHIA LLC',
        padName: 'HG 1213 Pad',
        jobStartTimestamp: '2022-08-05T14:00:00Z',
        jobEndTimestamp: '2022-08-31T14:00:00Z',
        createdOn: '2022-08-05T12:00:00Z',
        lastModified: '2022-08-05T18:00:00Z',
        processType: 'Normal'
      },
      {
        id: 4,
        name: 'J-9104',
        type: 'Coiled_Tubing',
        extId: 'a0C5G00000jPOASUA4',
        status: 'JobApprovalReject',
        fleetId: 4,
        fleetName: 'CTU-49',
        truckPid: 4,
        truckName: 'Datavan4',
        unitType: 'Datavan_Main',
        customerName: 'SENECA RESOURCES CO, LLC',
        padName: 'Beechwood  D09 - M #6 well pad',
        jobStartTimestamp: '2022-08-20T16:00:00Z',
        jobEndTimestamp: '2022-09-05T16:00:00Z',
        createdOn: '2022-08-15T18:00:00Z',
        lastModified: '2022-08-15T18:00:00Z',
        processType: 'Normal'
      },
      {
        id: 5,
        name: 'J-8667',
        type: 'Power_Solutions',
        extId: 'a0C5G00000ua9yFUAQ',
        status: 'JobApprovalReject',
        fleetId: 5,
        fleetName: 'Frac 18',
        truckPid: 5,
        truckName: 'Datavan5',
        unitType: 'Datavan_Main',
        customerName: 'COTERRA ENERGY INC',
        padName: 'Warriner R',
        jobStartTimestamp: '2022-08-10T15:00:00Z',
        jobEndTimestamp: '2022-08-31T16:00:00Z',
        createdOn: '2022-08-09T16:45:00Z',
        lastModified: '2022-08-17T16:45:00Z',
        processType: 'Normal'
      },
      {
        id: 6,
        name: 'J-9292',
        type: 'Frac',
        extId: 'a0C5G00000jPAfsUAG',
        status: 'JobEnd',
        fleetId: 6,
        fleetName: 'Frac 83',
        truckPid: 6,
        truckName: 'Datavan6',
        unitType: 'Datavan_Main',
        customerName: 'DEVON ENERGY CORP',
        padName: 'Lindsay 3-10O-55-1 442H / 10P-55-1 443H',
        jobStartTimestamp: '2022-08-11T16:00:00Z',
        jobEndTimestamp: '2022-08-24T16:00:00Z',
        createdOn: '2022-08-11T16:00:00Z',
        lastModified: '2022-08-24T14:00:00Z',
        processType: 'Normal'
      },
      {
        id: 7,
        name: 'J-3842',
        type: 'Wireline',
        extId: 'a0Cf400000UZ0bvEAD',
        status: 'JobApprovalRequest',
        fleetId: 7,
        fleetName: '200104',
        truckPid: 7,
        truckName: 'Datavan7',
        unitType: 'Datavan_Main',
        customerName: 'DISCOVERY NATURAL RESOURCES LLC FKA',
        padName: 'Mustang Draw Pipe Recovery',
        jobStartTimestamp: '2022-09-10T16:00:00Z',
        jobEndTimestamp: '2022-09-20T16:00:00Z',
        createdOn: '2022-09-01T16:00:00Z',
        lastModified: '2022-09-01T16:00:00Z',
        processType: 'Normal'
      },
    ];

const fetchJobsForOwner = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        getJobsForOwner: jobs
      })
    }, 1000)
  });

}

const fetchJobByName = (jobName) => {
  let foundJob = _.find(jobs, ['name', jobName]);
  let result = _.isNil(foundJob) ? {} : foundJob;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        job: result
      })
    }, 1000)
  });

}

const fetchStageData = (job) => {

  const foundJob = _.find(jobs, ['id', job]);

  let startTimestamp = moment(foundJob.jobStartTimestamp);
  
  let wellIndex1 = 1;
  let wellIndex2 = 1;
  let wellIndex3 = 1;

  let wells = ['9HE', '10HD', '11HF'];
  let wellsApi = ['4246142019', '4246142020', '4246142021'];
  let stages = [];

  for (let i = 0; i < 99; i++) {
    if (i % 2 === 0 || i % 3 === 0 || i % 4 === 0) {
      stages.push(createStage(i, startTimestamp.add(i, 'minutes').unix(), [wells[1], wells[2]], [wellIndex2, wellIndex3], [wellsApi[1], wellsApi[2]]));
      wellIndex2++;
      wellIndex3++;
    }
    else if (i % 11 === 0) {
      stages.push(
        {
          id: i,
          createdAt: startTimestamp.add(i, 'minutes').unix(),
          description: (i % 4 === 0) ? null : 'FT A - Template - Missing Stage Data ',
          status: null,
          timestamp: null,
          processes: (i % 4 === 0) ? [{ side: null, wellApiNumber: null, stage: null, guid: uuidv4()}] : createProcess([wells[0]], [wellIndex1], [wellsApi[0]]),
          stageStart: null,
          stageEnd: null
        }
      )
      wellIndex1++;
    } else {
      stages.push(createStage(i, startTimestamp.add(i, 'minutes').unix(), [wells[0]], [wellIndex1], [wellsApi[0]]));
      wellIndex1++;
    }
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        allStagesForJob: stages
      })
    }, 1000)
  });

};

const fetchStageDefinition = (stageId) => {

  const stageDefinitionFile = createStageDefinition(stageId, true, true, true, true, true, true); 

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        stageDefinitionFileForStage: { 
          stageDefinition: stageDefinitionFile,
        }
      })
    }, 1000); 
  })
}

const createStage = (index, timestamp, wells, wellIndexes, wellsApi) => {

  let isSimul = (index % 2 === 0 || index % 3 === 0 || index % 4 === 0) ? true : false;

  if (isSimul === true) {

    let wellNames = ''
    let wellsIndex = 0;
    _.forEach(wells, well => {
      // Last item
      if (wellsIndex === wells.length-1) {
        wellNames = wellNames + well + ' - Stage ' + wellIndexes[wellsIndex];
      } else {
        wellNames = wellNames + well + ' - Stage ' + wellIndexes[wellsIndex] + ' - ';
      }
      wellsIndex++;
    });

    return (
      {
        id: index,
        createdAt: timestamp,
        description: 'FT A - Template - Simul - ' + wellNames,
        status: 'StageApprovalRequest',
        timestamp: timestamp,
        processes: createProcess(wells, wellIndexes, wellsApi),
        stageStart: null,
        stageEnd: null
      }
    );

  } else {

    return (
      {
        id: index,
        createdAt: timestamp,
        description: 'FT A - Template - Stack - ' + wells[0] + ' - Stage ' + wellIndexes[0],
        status: 'Approved',
        timestamp: timestamp,
        processes:
        [
          {
            side: 'SIDE-A',
            wellApiNumber: wellsApi[0],
            stage: wellIndexes[0],
            guid: uuidv4()
          }
        ],
        stageDefinition: createStageDefinition(10, true, false, true, false, true, true),
        stageStart: null,
        stageEnd: null
      }
    );

  }

}

const createProcess = (wells, wellIndexes, wellsApi) => {

  let processes = [];
  let index = 0;

  _.forEach(wells, well => {

    processes.push(
      {
        side: 'SIDE-' + _.toUpper(Math.abs(index+10).toString(36)),
        wellApiNumber: wellsApi[index],
        guid: uuidv4(),
        stage: wellIndexes[index]
      }
    )

    index++;
  })

  return processes;
}

const createStageDefinition = (numSteps, blender1, blender2, chem1, chem2, hydration, datavan) => {

  let stageDefinition = [];
  let startConcentration = Number(0).toFixed(1);
  
  let basicDischargeTotal = Number(0).toFixed(1);
  let basicRate = Number(0).toFixed(1);

  for (let i = 0; i < numSteps; i++) {

    let basicStageStep = createBasicStageStep(i+1, basicDischargeTotal, basicRate);

    if (blender1 === true) {
      let blender1 = createBlender(1, 2, 8, startConcentration);
      basicStageStep.TruckFleet.push(blender1);
    }

    if (blender2 === true) {
      let blender2 = createBlender(2, 2, 8, startConcentration);
      basicStageStep.TruckFleet.push(blender2);
    }

    if (chem1 === true) {
      let chem = createChem(1, 6, startConcentration);
      basicStageStep.TruckFleet.push(chem);
    }

    if (chem2 === true) {
      let chem = createChem(2, 6, startConcentration);
      basicStageStep.TruckFleet.push(chem);
    }

    if (hydration === true) {
      let hydration = createHydration(4, startConcentration);
      basicStageStep.TruckFleet.push(hydration);
    }

    let unit = basicStageStep.TruckFleet[i];
    let endConcentration = !_.isNil(unit) ? basicStageStep.TruckFleet[i].AdditiveConfigurations[0].EndConcentration : startConcentration;

    if (datavan === true) {
      let datavan = 
      {
        Type: '0',
        Name: 'DATAVAN',        
        AdditiveConfigurations: 
        [
          {
            Name: 'INLINE_PROPPANT',
            CleanSource: 
            {
              Name: '',
              DisplayName: '(None)',
              IsNull: true
            },
            Concentration: 0.0,
            BaseRate: 0.0,
            Additive: {
              AbsoluteDensity: 22.076005598837355,
              BulkDensity: 13.368054740727478,
              Name: '',
              DisplayName: '(None)',
              Uuid: '00000000-0000-0000-0000-000000000000',
              IsNull: false,
              Type: 4
            },
            EndConcentration: endConcentration,
            SyncSource: 
            {
              AdditiveName: 'MAIN_HOPPER',
              DisplayName: 'Blender 1',
              Name: 'BLENDER 1',
              TruckName: 'BLENDER 1'
            }
          }
        ]
      };
      basicStageStep.TruckFleet.push(datavan);
    }

    stageDefinition.push(basicStageStep);

    startConcentration = (Number(startConcentration) + Number(endConcentration)).toFixed(1);
    basicDischargeTotal =  Number(Number(basicDischargeTotal) + _.random((Number(basicDischargeTotal))+1, (Number(basicDischargeTotal))+10, true)).toFixed(1);
    basicRate =  Number(Number(basicRate) + _.random((Number(basicRate))+1, (Number(basicRate))+10, true)).toFixed(1);
  }

  return JSON.stringify(stageDefinition);

}

const createBasicStageStep = (index, dischargeTotal, rate) => {

  return {
    StepNumber: index,
    BaseRate: rate,
    TruckFleet: [],
    StepTotal: dischargeTotal,
    FluidType: 'Slick Water',
  }

}

const createBlender = (index, numDry, numLA, prevMax) => {

  let blender =
  {
    Type: '0',
    Name: 'BLENDER ' + index,
    AdditiveConfigurations: []
  }

  let prevMaxNumber = Number(prevMax);
  let endConcentration = Number(prevMaxNumber + _.random(prevMaxNumber+1, prevMaxNumber+10, true)).toFixed(1);
    
  blender.AdditiveConfigurations.push(
    {
      Name: 'MAIN_HOPPER',
      CleanSource: {
        Name: 'BLENDER_SELECTED_BASE_RATE',
        DisplayName: 'Blender ' + index + ' Control Rate',
        IsNull: false
      },
      Concentration: 0.0,
      BaseRate: 0.0,
      Additive: {
        AbsoluteDensity: 22.076005598837355,
        BulkDensity: 13.368054740727478,
        Name: '100 Mesh',
        DisplayName: '100 Mesh',
        Uuid: '14a4fb63-0d5c-467f-b75d-97ebad5cb08c',
        IsNull: false,
        Type: 1
      },
      EndConcentration: endConcentration,
      SyncSource: null
    }
  )

  for (let i = 0; i < numDry; i++) {

    blender.AdditiveConfigurations.push(
      {
        Name: 'DRY_ADD_0' + (i+1),
        CleanSource: {
          Name: '',
          DisplayName: '(None)',
          IsNull: false
        },
        Concentration: 0.0,
        BaseRate: 0.0,
        Additive: {
          AbsoluteDensity: 22.076005598837355,
          BulkDensity: 13.368054740727478,
          Name: '',
          DisplayName: '(None)',
          Uuid: '00000000-0000-0000-0000-000000000000',
          IsNull: false,
          Type: 2
        },
        EndConcentration: endConcentration,
        SyncSource: null
      }
    )

  }

  let las = createLA(index, numLA, endConcentration);
  _.forEach(las, (la) => {
    blender.AdditiveConfigurations.push(la);
  });

  return blender;
}

const createChem = (index, numLA, prevMax) => {

  let chem =
  {
    Type: '0',
    Name: 'CHEM UNIT ' + index,
    AdditiveConfigurations: []
  }

  let prevMaxNumber = Number(prevMax);
  let endConcentration = Number(prevMaxNumber + _.random(prevMaxNumber+1, prevMaxNumber+10, true)).toFixed(1);

  let las = createLA(index, numLA, endConcentration);
  _.forEach(las, (la) => {
    chem.AdditiveConfigurations.push(la);
  });

  return chem;

}

const createHydration = (numLA, prevMax) => {

  let hydration =
  {
    Type: '0',
    Name: 'HYDRATION',
    AdditiveConfigurations: []
  }
  
  let prevMaxNumber = Number(prevMax);
  let endConcentration = Number(prevMaxNumber + _.random(prevMaxNumber+1, prevMaxNumber+10, true)).toFixed(1);

  let las = createLA(1, numLA, endConcentration);
  _.forEach(las, (la) => {
    hydration.AdditiveConfigurations.push(la);
  });

  let gels = createGelPump(1, 2, endConcentration);
  _.forEach(gels, (gel) => {
    hydration.AdditiveConfigurations.push(gel);
  });

  return hydration;
}

const createLA = (index, numLA, endConcentration) => {

  let additives = ['BK FR', 'ENDURACIDE', 'DIXICHLOR MAX', 'Q-50 S', 'SCL 9087', 'FR-1' ]

  let liquidAdds = [];

  for (let i = 0; i < numLA; i++) {

    let additiveIndex = _.random(1, additives.length-1);

    liquidAdds.push(
      {
        Name: 'LA_PUMP_0' + (i+1),
        CleanSource: {
          Name: 'BLENDER_SELECTED_BASE_RATE',
          DisplayName: 'Blender ' + index + ' Control Rate',
          IsNull: false
        },
        Concentration: 0.0,
        BaseRate: 0.0,
        Additive: {
          AbsoluteDensity: 22.076005598837355,
          BulkDensity: 13.368054740727478,
          Name: additives[additiveIndex],
          DisplayName: additives[additiveIndex],
          Uuid: '5e41d954-9085-4fe8-9b68-37761cd84473',
          IsNull: false,
          Type: 3
        },
        EndConcentration: endConcentration,
        SyncSource: null
      }
    )
  }

  return liquidAdds;
}

const createGelPump = (index, numGels, endConcentration) => {

  let gelPumps = [];

  for (let i = 0; i < numGels; i++) {

    gelPumps.push(
      {
        Name: 'GEL_PUMP_0' + (i+1),
        CleanSource: {
          Name: 'BLENDER_SELECTED_BASE_RATE',
          DisplayName: 'Blender ' + index + ' Control Rate',
          IsNull: false
        },
        Concentration: 0.0,
        BaseRate: 0.0,
        Additive: {
          AbsoluteDensity: 22.076005598837355,
          BulkDensity: 13.368054740727478,
          Name: 'GEL',
          DisplayName: 'GEL',
          Uuid: 'b3367a8d-8a05-4c02-b4a4-c0ba19f05558',
          IsNull: false,
          Type: 3
        },
        EndConcentration: endConcentration,
        SyncSource: null
      }
    )
  }

  return gelPumps;
}

export { fetchJobEventLogData, fetchStageData, fetchJobByName, fetchJobsForOwner, fetchStageDefinition }
