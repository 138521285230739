import React, { Component } from 'react';
import _ from "lodash";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Menu, MenuItem} from "@mui/material";

import { sensorSelectorState } from '../../../../../state/common/sensorSelector/sensorSelectorSelectors';
import * as sensorSelectorActions from '../../../../../state/common/sensorSelector/sensorSelectorActions';

import SensorGroupDeleteDialog from "./sensorGroupDeleteDialog";
import CreateEditSensorGroupDialog from "../createEditSensorGroupDialog";

class SensorGroupsContextMenu extends Component {

  render() {
    return (
        <span>
          <Menu
            anchorEl={this.props.contextMenuTargetElement}
            open={this.props.openContextMenuUI}
            onClose={() => this.props.closeContextMenu()}
            onKeyDown={() => this.props.closeContextMenu()}
          >
          <MenuItem onClick={() => {
            this.props.openEditDialog();
            this.props.closeContextMenu();
          }}>Edit</MenuItem>
          <MenuItem onClick={() => {
            this.props.openDeleteConfirmation();
            this.props.closeContextMenu();
          }}>Delete</MenuItem>
        </Menu>

        {/*
        Form for edit confirmation dialog
        */}
        <CreateEditSensorGroupDialog
          groupToEdit = {_.isNil(this.props.contextMenuSensorGroup)? {} :this.props.contextMenuSensorGroup }
          stateDef={this.props.stateDef}
          unitType={this.props.unitType}
          axisDefinition={this.props.axisDefinition}
          namespace={this.props.namespace}/>

        {/*
        Form for delete confirmation dialog
        */}
        <SensorGroupDeleteDialog
          groupToDelete={_.isNil(this.props.contextMenuSensorGroup) ? {} : this.props.contextMenuSensorGroup }
          stateDef={this.props.stateDef}
          unitType={this.props.unitType}
          namespace={this.props.namespace}/>
      </span>
    )
  }
}

SensorGroupsContextMenu.propTypes = {
  unitType: PropTypes.string,
  axisDefinition: PropTypes.object,
  namespace: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = sensorSelectorState(state[stateDef.key]);
  return {
    openContextMenuUI: componentState.openGroupsContextMenuUI,
    openCreateEditSensorGroup:  componentState.openCreateEditSensorGroup,
    contextMenuTargetElement: componentState.groupsContextMenuTargetElement,
    contextMenuSensorGroup: componentState.groupsContextMenuGroup,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeContextMenu: () => dispatch(sensorSelectorActions.closeContextMenu(props.stateDef)),
    openDeleteConfirmation: () => dispatch(sensorSelectorActions.openDeleteConfirmation(props.stateDef)),
    openEditDialog: () => dispatch(sensorSelectorActions.openEditDialog(props.stateDef)),
  }
};

export default (connect(mapStateToProps,mapDispatchToProps)(SensorGroupsContextMenu));
