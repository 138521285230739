import React, {Component} from 'react';
import { Box } from '@mui/material';
import fleetMapStyles from '../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class BlankDatavan extends Component {
  render() {
    return (
      <Box sx={styles.datavanMapContainer}/>
    );
  }
}

export default BlankDatavan;