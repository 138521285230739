import userMgmtActionTypes from '../userMgmtActionTypes';
import _ from "lodash";

const userMgmtDeleteDialogReducer = (state, action) => {

  switch (action.type) {
    case userMgmtActionTypes.USER_MGMT_DELETE_USER_STARTING:
      return { ...state, queryRunning: true };
    case userMgmtActionTypes.USER_MGMT_DELETE_USER_SUCCESS:
      return onDeleteUserSuccess(state, action);
    case userMgmtActionTypes.USER_MGMT_DELETE_USER_ERROR:
      return { ...state, queryRunning: false, openDeleteUserUI: false };
    case userMgmtActionTypes.USER_MGMT_CLOSE_DELETE_CONFIRMATION:
      return { ...state, openDeleteUserUI: false };
    default: return state;
  }

};

const onDeleteUserSuccess = (state, action) => {
  const updatedUsers = _.cloneDeep(state.users);

  // Update to remove the deleted user
  _.remove(updatedUsers, (x) => { return x.id === action.userId; });

  return {
    ...state,
    queryRunning: false,
    users: updatedUsers,
    openDeleteUserUI: false
  }
};

export default userMgmtDeleteDialogReducer