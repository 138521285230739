import _ from 'lodash';
import unitFleetActionTypes from './unitFleetActionTypes';
import { unitFleetState } from "./unitFleetSelectors";

const initialState = unitFleetState();

const unitFleetReducer = (state=initialState, action) => {

  switch (action.type) {

    case unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_STARTING:
      return onQueryStarting(state, action);

    case unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_SUCCESS:
      return onQuerySuccess(state, action);

    case unitFleetActionTypes.UNIT_FLEET_QUERY_DATA_ERROR:
      return onQueryError(state, action);

    case unitFleetActionTypes.UNIT_FLEET_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQuerySuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    name: _.isNil(action.queryResults.unitFleet.currentFleetName) ? initialState.name : action.queryResults.unitFleet.currentFleetName,
    type: _.isNil(action.queryResults.unitFleet.currentFleetType) ? initialState.type : action.queryResults.unitFleet.currentFleetType,
    previous: _.isNil(action.queryResults.unitFleet.previousFleetName) ? initialState.previous : action.queryResults.unitFleet.previousFleetName,
    fleetId: _.isNil(action.queryResults.unitFleet.currentFleetId) ? initialState.previous : action.queryResults.unitFleet.currentFleetId,
  };
};

const onQueryError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    name: initialState.name,
    type: initialState.type,
    previous: initialState.previous,
    fleetId : initialState.fleetId,
  };
};

export default unitFleetReducer