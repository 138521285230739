import _ from "lodash";
import * as customersDisplaySimulation from './customersDisplaySimulation';
import {performDisplayFetch} from "../../common/fetch";
import useSimulationData from "../../../helpers/simulationHelper";

const fetchCustomersDisplayData = () => {
    if (useSimulationData('customersDisplay')) {
        return customersDisplaySimulation.fetchCustomersDisplayData();
    }
    return performDisplayFetch(
        {
            'query': 'query customers { customers { id ownerId name location note active contactName email phone } }'
        }
    )
}

const fetchSaveCustomer = async (customer) => {
    return performDisplayFetch(
        {
            'query': 'mutation saveCustomer($input:CustomerInput!) { saveCustomer(input: $input) { id ownerId name location note active contactName email phone } }',
            'variables': {
                'input': {
                    id: customer.id,
                    name: customer.name,
                    location: customer.location,
                    active: true,
                    note: customer.note, 
                    contactName: customer.contactName,
                    email: customer.email,
                    phone: customer.phone,
                }
            }
        });
};

const fetchDeleteCustomer = (customerId) => {
    return performDisplayFetch(
        {
            'query': 'mutation deleteCustomer($id: Long!) {  deleteCustomer(id:$id) {success }}',
            'variables': {
                id: customerId
            }
        }
    );
};

export {
    fetchCustomersDisplayData,
    fetchSaveCustomer,
    fetchDeleteCustomer
};