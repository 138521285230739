import _ from "lodash";
import moment from "moment";
import * as filterService from '../../../common/filtering/filterService';
import * as assetTypeService from "../../../common/services/assetTypeService";

const processEquipment = (equipment, filters) => {
  // Filter the equipment list by the current filters (in order)
  let filteredEquipment = filterService.filterItems(equipment, filters);
  // Update any displayed values
  prettifyValues(filteredEquipment);
  // Generate filter data from the filtered equipment list
  let updatedFilters = filterService.generateFilters(filteredEquipment, filterDefinitions());

  return {
    filteredEquipment: filteredEquipment,
    filters: updatedFilters
  };
};

const prettifyValues = (equipment) => {
  if (!_.isNil(equipment) && !_.isEmpty(equipment)) {
    equipment.forEach(unit => {

      ////// TEMPORARY START
      // Hard code the asset type to a frac pumper for now. Remove this code when the type
      // is being returned from the portal app service
      unit.type = _.isUndefined(unit.type) ? assetTypeService.assetTypes.FRAC_PUMP : unit.type;
      ////// TEMPORARY END

      let noValue = '-';
      unit.displayValueType = !_.isNil(unit.type) ? assetTypeService.getAssetNameByType(unit.type) : noValue;
      unit.displayValueEcmHours = !_.isNil(unit.ecmHours) ? unit.ecmHours.toFixed(1) : noValue;
      unit.displayValuePumpHours = !_.isNil(unit.pumpHours) ? unit.pumpHours.toFixed(1) : noValue;
      unit.displayValueTcmHours = !_.isNil(unit.tcmHours) ? unit.tcmHours.toFixed(1) : noValue;
      unit.displayValueAge = !_.isNil(unit.timeStamp) ? moment.utc(unit.timeStamp*1000).fromNow() : noValue;
    })
  }
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Type', 'type'));
  definitions.push(filterService.createFilterDefinition('Unit ID', 'name'));
  return definitions;
};

export {
  processEquipment,
  prettifyValues
}