import _ from 'lodash';
import { exportCsvState } from './exportCSVSelectors';
import moment from 'moment';
import Papa from 'papaparse';

const initialState = exportCsvState();

const  onExportCsvStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
    triggerExportCsv: initialState.triggerExportCsv,
    exportCsvUrl: initialState.exportCsvUrl,
  };
};

const  onExportCsvSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    triggerExportCsv: true,
    exportCsvUrl: action.queryResults.startDownload.downloadUrl,
  };
};

const  onExportCsvError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    triggerExportCsv: initialState.triggerExportCsv,
    exportCsvUrl: initialState.exportCsvUrl,
  };
};

const generateCSV = (items, columns, sortContext, sortDirection) => {

  let csv = null;

  if (!_.isEmpty(items) && !_.isEmpty(columns)) {

    // Sort the units if we were provided a sort context and direction
    if (!_.isNil(sortContext) && !_.isNil(sortDirection)) {
      items = _.orderBy(items,item => {
        let value = item[sortContext];
        value = _.isNil(value) ? (-Number.MAX_VALUE) : value;
        // Convert strings to lower case
        value = _.isString(value) ? value.toLowerCase() : value;
        return value;
      }, sortDirection);
    }

    // Transform the items to include only what we want in the CSV data
    const csvItems = [];
    const headerRow = [];
    _.forEach(columns, (column) => {
      headerRow.push(column.name);
    });
    csvItems.push(headerRow);

    _.forEach(items, item => {
      let csvItem = [];
      _.forEach(headerRow, header => {
        const column = _.find(columns, {name: header});
        // format unix timeStamp to human readable dateTime
        if(column.property === "timeStamp"){
          csvItem.push(moment(item[column.property]).isValid() ? moment.unix(item[column.property]).format('YYYY-MM-DD HH:mm:ss') : '');
        }
        else{
          csvItem.push(_.isNil(item[column.property]) ? '' : item[column.property]);
        }
      });
      csvItems.push(csvItem);
    });

    csv = Papa.unparse(csvItems);
  }

  return csv;
};

export {
  onExportCsvStarting,
  onExportCsvSuccess,
  onExportCsvError,
  generateCSV,
}