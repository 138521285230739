import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';

const fleetOverviewState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    fleets: _.isNil(state) ? [] : state.fleets,
    filteredFleets: _.isNil(state) ? [] : state.filteredFleets,
    pagedFleets: _.isNil(state) ? [] : state.pagedFleets,
    currentPage: _.isNil(state) ? 0 : state.currentPage,
  };

};

export {
  fleetOverviewState
};