import _ from "lodash";
import React, {Component} from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Typography, Chip, Paper, Tabs, Tab, IconButton, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Progress from '../../controls/progress';
import FilterDialog from '../../common/filtering/filterDialog';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ComponentForm from './componentForm';
import ComponentDeleteConfirmation from './componentDeleteConfirmation';

import ComponentTypes from '../../componentTypes';
import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { componentsState } from '../../../state/displays/componentsDisplay/componentsSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import { AutoCompleteMDT } from '../../controls/mdtMuiControls';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  pageTitle: {
    alignSelf: 'center'
  },
  truckSelectionContainer: {
    display:'flex',
    alignItems: 'center',
    paddingBottom: 2
  },
  unitSelection: {
    width: '175px',
    marginLeft: 1,
    paddingRight: 2
  },
  componentSelection: {
    width: '200px',
    marginLeft: 1,
  },
  filterLabel: {
    alignSelf: 'center',
    marginLeft: 3
  },
  filterComponent: {
    marginLeft: 1,
  },
  filterChip: {
    alignSelf: 'center',
    marginLeft: 1,
  },
};

class ComponentsDisplay extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.COMPONENTS, this.props.user);

    this.props.loadUnits();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.loadUnits(true);
    }

    if (prevProps.selectedUnit !== this.props.selectedUnit && !_.isNil(this.props.selectedUnit)) {
      this.props.loadComponentDetails(this.props.selectedUnit.id);
    }

  }

  render() {

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    let noComponents = (_.isNil(this.props.componentTypes) || _.isEmpty(this.props.componentTypes));

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Typography sx={styles.pageTitle} variant={'h6'}>Components</Typography>
            <Typography sx={styles.filterLabel} variant={'subtitle1'}>FILTER:</Typography>
            {
              this.props.appliedFilters.map((filterContext, index) => {
                return (
                  <Chip 
                    sx={styles.filterChip}
                    key={index}
                    label={filterContext.chipLabel}
                    onDelete={() => this.props.deleteFilter(index)}
                  />
                )
              })
            }
            <IconButton
              sx={styles.filterComponent}
              onClick={() => this.props.openFilterDialog()}
              disabled={emptyFilters}
              size="large"><AddCircleIcon/>
            </IconButton>
          </Box>

          <Box sx={styles.truckSelectionContainer}>
            <Typography variant={'subtitle1'}>TRUCK:</Typography>
            <AutoCompleteMDT
              sx={styles.unitSelection}
              options={this.props.filteredUnits}
              value={this.props.selectedUnit}
              onChange={(event, value, reason) => {
                this.props.setSelectedUnit(value);
              }}
              noOptionsText={"No units found..."}
            />
            <Typography variant={'subtitle1'}>COMPONENT:</Typography>
            <AutoCompleteMDT
              sx={styles.componentSelection}
              options={this.props.componentTypes}
              value={this.props.selectedComponentType}
              onChange={(event, value, reason) => {
                this.props.setSelectedComponentType(value);
              }}
              noOptionsText={"No components found..."}
            />
          </Box>

          <Paper>
            <Tabs
              value={this.props.editMode ? 0 : 1}
              onChange={(event, value) => this.props.setEditMode(value === 0)}
              centered={true}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab icon={<EditIcon />} label='Edit Component' disabled={noComponents}/>
              <Tab icon={<AddIcon />} label={_.isNil(this.props.selectedComponent) ? 'Add Component' : 'Replace Component'} disabled={noComponents} />
            </Tabs>
            <ComponentForm/>
          </Paper>

        </Box>
        <FilterDialog stateDef={this.props.stateDef}/>
        <ComponentDeleteConfirmation/>
        <Progress open={this.props.queryRunning}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENTS : props.stateKey,
      type: ComponentTypes.COMPONENTS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentsState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    editMode: componentState.editMode,
    filteredUnits: componentState.filteredUnits,
    componentTypes: componentState.componentTypes,
    selectedUnit: componentState.selectedUnit,
    selectedComponentType: componentState.selectedComponentType,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    selectedComponent: componentState.selectedComponent,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadUnits: (switchingOwners) => { dispatch(componentsActions.loadUnits(props.stateDef, switchingOwners)) },
    loadComponentDetails: (unitId) => { dispatch(componentsActions.loadComponentDetails(props.stateDef, unitId)) },
    setEditMode: (editMode) => { dispatch(componentsActions.setEditMode(props.stateDef, editMode)) },
    setSelectedUnit: (unit) => { dispatch(componentsActions.setSelectedUnit(props.stateDef, unit)) },
    setSelectedComponentType: (type) => { dispatch(componentsActions.setSelectedComponentType(props.stateDef, type)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ComponentsDisplay))