import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from "@mui/material";
import VerticalHiHiBar from "../verticalHiHiBar/verticalHiHiBar";
import { mdtPalette } from "../../../common/styles/mdtPalette";
import _ from "lodash";
import getCardStyles from "../../../cards/cardStyles";
import getLayoutStyles from "../../layout/layoutStyles";
import getTypographyStyles from "../../../common/styles/typographyStyles";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,

  // Chart and associated label
  chartAndChartLabelContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    width: '95%',
    height: '100px',
    minWidth: '313px',
    marginTop: '10px',
  },
  chartAndBorderContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  chartContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    width: '274px', // For consistent sizing inside cards
    height: '100px', // For consistent sizing inside cards
    minHeight: '100px', // For consistent sizing inside cards with and without data
    alignItems: 'flex-end',
    borderStyle: 'solid',
    borderWidth: '1px 0px 0px 0px', // Top, Right, Bottom, Left
    borderTopColor: '#999999',
    borderBottomColor: '#999999',
  },
  chartContainerNoBorder: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    width: '274px', // For consistent sizing inside cards
    height: '100px', // For consistent sizing inside cards
    minHeight: '100px', // For consistent sizing inside cards with and without data
    alignItems: 'flex-end',
  },
  chartBottomBorder: {
    height: '0px',
    borderStyle: 'solid',
    borderWidth: '1px 0px 0px 0px', // Top, Right, Bottom, Left
    borderTopColor: '#999999',
  },
  chartLabelContainer: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
    paddingRight: '10px'
  },
  chartLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  chart: {
    paddingLeft: '2px',
  },

  chartLine1Label: {
    position: 'relative',
    bottom: '10px',
    right: '32px',
    fontSize: '10px',
    fontWeight: 500,
    width: '30px',
  },
  chartLine2Label: {
    justifyContent: 'flex-end',
    position: 'relative',
    bottom: '116px',
    right: '32px',
    fontSize: '10px',
    fontWeight: 500,
    width: '30px',
  },
  chartLineAndLabel: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },

  // No Data for charts
  noDataLabelContainer: {
    height: '100px',
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

// A vertical bar chart with thresholds to distinguish normal, hi, and
// hi-hi values. If neither hiThreshold nor hihiThreshold is specified
// then it acts as a simple bar chart.
class VerticalBarChart extends Component {

  calculateVerticalBarWidth() {

    // Some handy constants for the calculations below
    // Changing these will affect the calculations
    const maxSlots = 40;
    const minSlots = 20;
    // At 20 slots max, the ideal default width is 10
    const default20Width = 10;
    // At 40 slots max, the ideal default width is 4.75 
    const default40Width = 4.75;

    if (!_.isNil(this.props.data)) {
      // if it's 40 pumps, use the width appropriate for 40 pumps
      if (this.props.data.length == maxSlots) {
        return default40Width;
      }
      // if it's between 20 and 40 pumps, we can find an appropriate width
      else if (this.props.data.length > minSlots && this.props.data.length < maxSlots) {
        // Find the smallest increment by which we can increase/decrease the width value
        // This will change based on the gap between max and min slots
        let widthIncrement = ((default20Width - default40Width) / (maxSlots - minSlots)).toFixed(2);
        // Number of slots more than minSlots 
        let countDifference = this.props.data.length - minSlots;
        // Find the new width value by starting from largest width and going down
        // We increase the count by 1 here so we have a buffer, otherwise the chart will overflow
        // the area
        let newWidth = default20Width - ((countDifference + 1) * widthIncrement);
        // Return the new width back as a number
        return newWidth.toFixed(2) * 1;
      }
    }

    // If something isn't right, just return the default width for 20 slots
    return default20Width;
  }

  render() {
    let hasData = !(_.isNil(this.props.data) || _.isEmpty(this.props.data));

    return (
      <Box sx={styles.chartAndChartLabelContainer}>
        <Box sx={styles.chartLabelContainer}>
          <Typography sx={styles.chartLabel} variant={'subtitle1'}>{this.props.label}</Typography>
        </Box>
        <Box sx={styles.chartAndBorderContainer}>
          <Box sx={hasData ? styles.chartContainer : styles.chartContainerNoBorder}>
            {
              hasData &&
              this.props.data.map((pump, index) => {
                return (
                  <Box key={index} sx={styles.chart}>
                    <VerticalHiHiBar value={pump || 0}
                                     total={this.props.total}
                                     valueColor={mdtPalette().status.level1}
                                     hiThreshold={this.props.hiThreshold || this.props.total}
                                     hihiThreshold={this.props.hihiThreshold || this.props.total}
                                     hiColor={mdtPalette().status.level4}
                                     hihiColor={mdtPalette().status.level5}
                                     barWidth={this.calculateVerticalBarWidth()} />
                  </Box>
                )
              })
            }
            {
              !hasData &&
              <Box sx={styles.noDataLabelContainer}>
                <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
              </Box>
            }
          </Box>
          {
            hasData &&
            <Box>
              <Box sx={styles.chartBottomBorder}/>
              <Box sx={styles.chartLineAndLabel}>
                <Typography sx={styles.chartLine1Label} align={'right'}
                            variant={'caption'}>{this.props.minLabel}</Typography>
                <Typography sx={styles.chartLine2Label} align={'right'}
                            variant={'caption'}>{this.props.maxLabel}</Typography>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    );
  }
}

VerticalBarChart.propTypes = {
  label: PropTypes.string.isRequired,
  minLabel: PropTypes.number.isRequired,
  maxLabel: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  // VerticalHiHiBar properties
  total: PropTypes.number.isRequired,
  hihiThreshold: PropTypes.number,
  hiThreshold: PropTypes.number,
};

export default VerticalBarChart;