const fetchDataExplorationDisplay = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        dataExplorationDisplay: [
            {
                "datavan": "5111774",
                "truckId": 1229,
                "truckPid": 5268685,
                "truckName": "5111774",
                "fleetName": "fleet1",
                "unitType": "Datavan_Main"
            },
            {
                "datavan": "5111774",
                "truckId": 1565572,
                "truckPid": 57009492,
                "truckName": "621764",
                "fleetName": "fleet1",
                "unitType": "FracPumper"
            },
            {
                "datavan": "5111227",
                "truckId": 1557769,
                "truckPid": 5268542,
                "truckName": "5122416",
                "fleetName": "fleet2",
                "unitType": "Blender"
            },
            {
                "datavan": "5111227",
                "truckId": 1557227,
                "truckPid": 5268639,
                "truckName": "5122420",
                "fleetName": "fleet2",
                "unitType": "Chemvan"
            },
            {
                "datavan": "5111227",
                "truckId": 2519,
                "truckPid": 5268686,
                "truckName": "5111227",
                "fleetName": "fleet2",
                "unitType": "Datavan_Main"
            },
        ]
      });
    }, 1000);
  });
};

export { fetchDataExplorationDisplay };