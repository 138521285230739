import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null
  };

  let lubePressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.LUBE_PRESSURE]);
  transformedSeries.series1 = unitChartService.transformSeries(lubePressureSeries, 1);

  let lubeTempSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.LUBE_TEMP]);
  transformedSeries.series2 = unitChartService.transformSeries(lubeTempSeries, 1);

  let flowRateSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.FLOW_RATE]);
  transformedSeries.series3 = unitChartService.transformSeries(flowRateSeries);

  return transformedSeries;
};


export {
  transformSeries,
}