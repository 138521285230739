import _ from 'lodash';
import moment from 'moment';

const timeFrames = [
  {
    value: moment().subtract(7, 'days').unix(), 
    label: 'Last 7 Days'
  },
  {
    value: moment().subtract(30, 'days').unix(), 
    label: 'Last 30 Days'
  },
  {
    value: moment().subtract(60, 'days').unix(), 
    label: 'Last 60 Days'
  },
  {
    value: moment().subtract(90, 'days').unix(),
    label: 'Last 90 Days'
  },
  {
    value: 1,
    label: 'Planned'
  },
  {
    value: 2,
    label: 'Active'
  }
]

const jobHistoryState = state => {
  return {

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    customers: _.isNil(state) ? [] : state.customers,
    showCreateEditJobDialog: _.isNil(state) ? false : state.showCreateEditJobDialog,
    showDeleteJobDialog: _.isNil(state) ? false : state.showDeleteJobDialog,
    jobs: _.isNil(state) ? [] : state.jobs,
    rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
    rowsPerPageOptions: [25,50,100],
    expandedRows: _.isNil(state) ? [] : state.expandedRows,
    selectedJob: _.isNil(state) ? {} : state.selectedJob,

    createEditJobName: _.isNil(state) ? '' : state.createEditJobName,
    createEditSelectedCustomer: _.isNil(state) ? null : state.createEditSelectedCustomer,
    createEditStartDate: _.isNil(state) ? null : state.createEditStartDate,
    createEditStartDateDisplay: _.isNil(state) ? null : state.createEditStartDateDisplay,
    createEditEndDate: _.isNil(state) ? null : state.createEditEndDate,
    createEditEndDateDisplay: _.isNil(state) ? null : state.createEditEndDateDisplay,

    createEditCanSave: _.isNil(state) ? false : state.createEditCanSave,
    createEditHasChanges: _.isNil(state) ? false : state.createEditHasChanges,

    datavans: _.isNil(state) ? [] : state.datavans,
    createEditSelectedDatavan: _.isNil(state) ? null : state.createEditSelectedDatavan,
    jobTypes: _.isNil(state) ? [] : state.jobTypes,
    createEditSelectedJobType: _.isNil(state) ? null : state.createEditSelectedJobType,
    contacts: _.isNil(state) ? [] : state.contacts,
    createEditSelectedContact: _.isNil(state) ? null : state.createEditSelectedContact,
    createEditWells: _.isNil(state) ? [] : state.createEditWells,
    wells: _.isNil(state) ? [] : state.wells,

    hasJobNameError: _.isNil(state) ? false : state.hasJobNameError,
    hasStartDateError: _.isNil(state) ? false : state.hasStartDateError, 
    hasEndDateError: _.isNil(state) ? false : state.hasEndDateError, 
    hasJobTypeError: _.isNil(state) ? false : state.hasJobTypeError,
    hasDatavanError: _.isNil(state) ? false : state.hasDatavanError,

    timeFrames: timeFrames,
    selectedTimeFrame: _.isNil(state) ? timeFrames[0] : state.selectedTimeFrame,
    filterModel: _.isNil(state)? { items: []}: state.filterModel
  };
};

export {
  jobHistoryState
};