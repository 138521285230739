import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import chartOptions from '../common/activityCard/activityCardChartOptions';
import ActivityCard from '../common/activityCard/activityCard';
import ComponentTypes from '../../componentTypes';
import * as fleetActivityActions from '../../../state/cards/fleetActivity/fleetActivityActions';
import { fleetActivityState } from '../../../state/cards/fleetActivity/fleetActivitySelectors';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

class FleetActivity extends MDTCard {

  getName(){
    return 'ACTIVITY';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.id);
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  getRenderedContent() {
    return (
        <ActivityCard
            chartOptions={this.props.chartOptions}
            displayValueDays={this.props.displayValueDays}
            displayValuePumping={this.props.displayValuePumping}
            displayValueIdle={this.props.displayValueIdle}
            displayValueStopped={this.props.displayValueOffline}
            labelStopped='Offline'
        />
    )
  }
};

FleetActivity.propTypes = mdtCardPropTypes

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_ACTIVITY : props.stateKey,
      type: ComponentTypes.FLEET_ACTIVITY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetActivityState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    displayValuePumping : componentState.displayValuePumping,
    displayValueIdle: componentState.displayValueIdle,
    displayValueOffline: componentState.displayValueOffline,
    displayValueDays: componentState.displayDays,
    chartOptions: chartOptions(componentState.rawValuePumping, componentState.rawValueIdle, componentState.rawValueOffline),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(fleetActivityActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetActivityActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetActivity))