export default {
  SENSOR_SELECTOR_QUERY_SENSORS_STARTING: "SENSOR_SELECTOR_QUERY_SENSORS_STARTING",
  SENSOR_SELECTOR_QUERY_SENSORS_ERROR: "SENSOR_SELECTOR_QUERY_SENSORS_ERROR",
  SENSOR_SELECTOR_QUERY_SENSORS_SUCCESS: "SENSOR_SELECTOR_QUERY_SENSORS_SUCCESS",
  SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_STARTING: "SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_STARTING",
  SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_ERROR: "SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_ERROR",
  SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_SUCCESS: "SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_SUCCESS",
  SENSOR_SELECTOR_SET_SELECTED_SENSORS: "SENSOR_SELECTOR_SET_SELECTED_SENSORS",
  SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_ADD: "SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_ADD",
  SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_REMOVE: "SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_REMOVE",
  SENSOR_SELECTOR_TOGGLE_SELECT_GROUP_INDEX_FOR_ADD: "SENSOR_SELECTOR_TOGGLE_SELECT_GROUP_INDEX_FOR_ADD",
  SENSOR_SELECTOR_ADD_TO_SELECTED_SENSORS: "SENSOR_SELECTOR_ADD_TO_SELECTED_SENSORS",
  SENSOR_SELECTOR_REMOVE_FROM_SELECTED_SENSORS: "SENSOR_SELECTOR_REMOVE_FROM_SELECTED_SENSORS",
  SENSOR_SELECTOR_ADD_GROUP_TO_SELECTED_SENSORS: "SENSOR_SELECTOR_ADD_GROUP_TO_SELECTED_SENSORS",
  SENSOR_SELECTOR_SET_TRUCKS: "SENSOR_SELECTOR_SET_TRUCKS",
  SENSOR_SELECTOR_CLEAR_STATE: "SENSOR_SELECTOR_CLEAR_STATE",
  SENSOR_SELECTOR_SET_SELECTED_FORM_INDEX: 'SENSOR_SELECTOR_SET_SELECTED_FORM_INDEX',
  SENSOR_SELECTOR_OPEN_GROUPS_CONTEXT_MENU: 'SENSOR_SELECTOR_OPEN_GROUPS_CONTEXT_MENU',
  SENSOR_SELECTOR_SELECTED_SENSOR_UPDATE_UOM: 'SENSOR_SELECTOR_SELECTED_SENSOR_UPDATE_UOM',
  SENSOR_SELECTOR_SELECTED_SENSOR_OPEN_TARGET_UOM_MENU: 'SENSOR_SELECTOR_SELECTED_SENSOR_OPEN_TARGET_UOM_MENU',
  SENSOR_SELECTOR_SELECTED_SENSOR_CLOSE_TARGET_UOM_MENU: 'SENSOR_SELECTOR_SELECTED_SENSOR_CLOSE_TARGET_UOM_MENU',
  // Context menu actions
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_MENU: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_MENU',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_DELETE_CONFIRMATION: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_DELETE_CONFIRMATION',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_DELETE_CONFIRMATION: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_DELETE_CONFIRMATION',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_STARTING: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_STARTING',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_SUCCESS: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_SUCCESS',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_ERROR: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_ERROR',
  SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_EDIT_DIALOG: 'SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_EDIT_DIALOG',

  SENSOR_SELECTOR_GROUPS_OPEN_CREATE_SENSOR_GROUP: "SENSOR_SELECTOR_GROUPS_OPEN_CREATE_SENSOR_GROUP",
  SENSOR_SELECTOR_GROUPS_CLOSE_CREATE_SENSOR_GROUP: "SENSOR_SELECTOR_GROUPS_CLOSE_CREATE_SENSOR_GROUP",
  SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_NAME: "SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_NAME",
  SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_DESCRIPTION: "SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_DESCRIPTION",
  SENSOR_SELECTOR_GROUPS_SET_INIT_STATE_SENSOR_GROUP: "SENSOR_SELECTOR_GROUPS_SET_INIT_STATE_SENSOR_GROUP",
  SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP: "SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP",
  SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_STARTING: "SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_STARTING",
  SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_SUCCESS: "SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_SUCCESS",
  SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_ERROR: "SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_ERROR",
  SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_EXISTS_ERROR: "SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_EXISTS_ERROR",

  SENSOR_SELECTOR_REMOVE_ALL_SELECTED_SENSORS: "SENSOR_SELECTOR_REMOVE_ALL_SELECTED_SENSORS",
  SENSOR_SELECTOR_ADD_ALL_AVAILABLE_SENSORS: "SENSOR_SELECTOR_ADD_ALL_AVAILABLE_SENSORS",

  SENSOR_SELECTOR_SEARCH_QUERY: "SENSOR_SELECTOR_SEARCH_QUERY"

};