const createAlarmCounts = (alarmData) => {


  return {
    critical: alarmData.criticalAlarmCount,
    warning: alarmData.warningAlarmCount,
    info: alarmData.infoAlarmCount,
    activeAlarms: alarmData.activeAlarms
  };
};

export default createAlarmCounts