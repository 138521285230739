import _ from "lodash";

const getCardFromLayoutConfigViews = (views, view, cardKey) => {
    const foundViews = getViewFromLayoutConfigViews(views, view);
    return foundViews?.cards.find((card) => card.i === cardKey);
};

const getViewFromLayoutConfigViews= (views, view) => {
    return views?.views.find(v => v.id === view);
};

const getCardCountInViewForCardType = (views, view, cardType) => {
  const foundView = getViewFromLayoutConfigViews(views, view);
  return _.filter(foundView?.cards, (card) => card.type === cardType).length;
}

export {
    getCardFromLayoutConfigViews,
    getViewFromLayoutConfigViews,
    getCardCountInViewForCardType
}