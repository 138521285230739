import React, {Component} from 'react';
import { CircularProgress, Box } from "@mui/material";

const styles = {
  backdrop: {
    zIndex: 10000,
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100vw',
    height: '100vh',
    background:'rgba(0, 0, 0, 0.5)',
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  container: {
    zIndex: 10001,
    height: '100px',
    width: '100px'
  }
};

class Progress extends Component {
  render() {
    const { open } = this.props;
    return (
      <div>
      {
        open &&
        <Box sx={styles.backdrop}>
          <Box sx={styles.container}>
            <CircularProgress size={80} disableShrink />
          </Box>
        </Box>
      }
      </div>
    )
  }
}

export default Progress;

