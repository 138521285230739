import _ from 'lodash';
import React, {Component} from 'react';
import {withProps} from 'recompose';
import {connect} from 'react-redux';
import { compose } from 'recompose';

import { Typography, Box, IconButton, Chip, Switch } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from '../../common/table/dataTable';
import FilterDialog from '../../common/filtering/filterDialog';
import Progress from '../../controls/progress';
import RefreshIcon from '@mui/icons-material/Refresh';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportCsv from '../../controls/exportCsv/exportCsv';

import ComponentTypes from '../../componentTypes';
import * as componentHoursActions from '../../../state/displays/componentHours/componentHoursActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { componentHoursState } from '../../../state/displays/componentHours/componentHoursSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getTableFilterStyles from '../../common/styles/tableFilter';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();
const tableFilterStyles = getTableFilterStyles();

const styles = {
  ...detailsPageStyles,
  ...tableFilterStyles,
  detailsPageHeaderTitle: {
    ...detailsPageStyles.detailsPageHeaderTitle,
    width: '200px'
  },
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    minWidth: (theme) => theme.breakpoints.values.md
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  tableFilters: {
    ...tableFilterStyles.tableFilterContainer,
    flexGrow: 1
  },
  tableOptions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  }
};

class ComponentHours extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.COMPONENT_HOURS, this.props.user);
    // Load the display data
    this.props.queryData();
    // Start the timer for refreshing the display.
    this.periodicItemsRefreshId = this.periodicRefreshItems();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the items.
    if (!_.isNil(this.periodicItemsRefreshId)) {
      clearInterval(this.periodicItemsRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      // Query the data again if the owner changes.
      this.props.queryData();
    }
  }

  periodicRefreshItems() {
    // Periodically refresh the equipment.
    return setInterval(()=>{
      this.props.queryData();
    }, 900000); // 15 minutes
  }

  generateTableColumns(filteredComponents) {
    let columns = [];
    columns.push({type: 'data', width: 100, label: 'Unit ID', displayProperty: 'name', sortProperty: 'name'});
    columns.push({type: 'data', width: 160, label: 'Type', displayProperty: 'displayValueType', sortProperty: 'displayValueType' });
    _.forEach(filteredComponents, component => {
      columns.push({type: 'data', width: 155, label: component.label, displayProperty: component.property, sortProperty: component.property});
    });
    columns.push({type: 'data', width: 120, label: 'Fleet', displayProperty: 'fleetName', sortProperty: 'fleetName'});
    return columns;
  }

  render() {

    // Generate the columns data for the data table.
    let columns = this.generateTableColumns(this.props.filteredComponents);

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Component Hours</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton onClick={() => this.props.queryData()} size="large"><RefreshIcon/></IconButton>
              <IconButton
                onClick={() => this.props.exportCsv(this.props.filteredUnits, this.props.filteredComponents, this.props.sortContext, this.props.sortDirection)}
                size="large"><GetAppIcon/>
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.tableFilterContainer}>
            <Box sx={styles.tableFilters}>
              <Typography variant={'subtitle1'}>FILTER:</Typography>
              {
                this.props.appliedFilters.map((filter, index) => {
                  return (
                    <Box sx={styles.tableFilterComponent} key={index}>
                      <Chip label={filter.chipLabel} onDelete={() => this.props.deleteFilter(index)} />
                    </Box>
                  )
                })
              }
              <Box sx={styles.tableFilterComponent}>
                <IconButton
                  onClick={() => this.props.openFilterDialog()}
                  disabled={emptyFilters}
                  size="large"><AddCircleIcon/></IconButton>
              </Box>
            </Box>
            <Box sx={styles.tableOptions}>
              <Switch color='primary' checked={this.props.showConsumedHours === true} onChange={(event) => { this.props.setShowConsumedHours(event.target.checked)}}/>
              <Typography variant={'subtitle1'}>Show Consumed Hours</Typography>
            </Box>
          </Box>
          <DataTable stateDef={this.props.stateDef} columns={columns} items={this.props.filteredUnits} />
        </Box>
        <FilterDialog stateDef={this.props.stateDef} />
        <Progress open={this.props.queryRunning}/>
        <ExportCsv triggerExportCsv={this.props.triggerExportCsv} exportCsvUrl={this.props.exportCsvUrl}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENT_HOURS : props.stateKey,
      type: ComponentTypes.COMPONENT_HOURS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentHoursState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    filteredUnits: componentState.filteredUnits,
    filteredComponents: componentState.filteredComponents,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    sortContext: componentState.sortContext,
    sortDirection: componentState.sortDirection,
    exportCsvUrl: componentState.exportCsvUrl,
    triggerExportCsv: componentState.triggerExportCsv,
    showConsumedHours: componentState.showConsumedHours,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: () => { dispatch(componentHoursActions.queryData(props.stateDef)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    exportCsv: (units, components, sortContext, sortDirection) => { dispatch(componentHoursActions.exportHoursToCsv(props.stateDef, units, components, sortContext, sortDirection))},
    setShowConsumedHours: (show) => { dispatch(componentHoursActions.setShowConsumedHours(props.stateDef, show)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ComponentHours));