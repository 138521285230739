import _ from 'lodash';

const useSimulationData = (simKey) => {
  if (process.env.NODE_ENV === 'development' && !_.isEmpty(process.env.REACT_APP_USE_SIMULATION_DATA)) {
    const tokens = _.split(process.env.REACT_APP_USE_SIMULATION_DATA, ',');
    return (_.indexOf(tokens, simKey) >= 0);
  }

  return false;
};
export default useSimulationData
