import * as fracOverviewCardSimulation from './fracOverviewCardSimulation';
import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchFracOverview = (fleetId) => {
  if (useSimulationData('fracOverviewCard')) {
    return fracOverviewCardSimulation.fetchFracOverview(fleetId);
  }

  let queryString = '{ fleetFracOverview(fleetId:{fleetId}) { fleetType state flowRate pressure ' +
    'alarmCounts {criticalAlarmCount infoAlarmCount warningAlarmCount} ' +
    'vibrationCounts {label low med high} ' +
    'avgEngineLoad ' +
    'avgEngineTorque ' +
    'dgbBlendingRatio {numerator denominator} ' +
    'avgGasSubstitution ' +
    '} }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performCardFetch({
    'query': queryString
  })
};

export {
  fetchFracOverview
}


