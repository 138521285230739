export default {

  // Displays

  EXPORT : 'export',
  USER_MANAGEMENT : 'userMgmt',
  FLEET_MANAGEMENT : 'fleetManagement',
  ROUTE_ASSET_MANAGEMENT : 'routeAssetManagement',
  EQUIPMENT_LIST: 'equipmentList',
  PUMP_DASHBOARD: 'pumpDashboard',
  DFP_DASHBOARD: 'dfpDashboard',
  FLEET_DASHBOARD: 'fleetDashboard',
  CLIENT_STATUS : 'clientStatus',
  SYSTEM_SOFTWARE: 'systemSoftware',
  COMPONENTS: 'components',
  SETTINGS: 'settings',
  FRAC_FLEET_MAP: 'fracFleetMap',
  FRAC_FLEET_MAP_VIEW: 'fracFleetMapView',
  PUMP_ONLY_FLEET_MAP: 'pumpOnlyFleetMap',
  PUMP_ONLY_FLEET_MAP_VIEW: 'pumpOnlyFleetMapView',
  COMPONENT_HOURS: 'componentHours',
  ALARMS_DISPLAY: 'alarmsDisplay',
  FLEET_OVERVIEW: 'fleetOverview',
  FLEET_MAP_DGB_VIEW: 'fleetMapDgbView',
  COIL_DASHBOARD: 'coilDashboard',
  DATA_EXPLORATION_DISPLAY: 'dataExplorationDisplay',
  JOB_OVERVIEW: 'jobOverview',
  CUSTOMERS_DISPLAY: 'customersDisplay',
  WELLS_DISPLAY: 'wellsDisplay',
  DOWNLOADS: 'downloadsDisplay',
  OWNER_MANAGEMENT: 'ownerManagement',
  JOB_HISTORY: 'jobHistory',
  AUDIT_LOGS: 'auditLogs',
  ASSET_TRACKING: 'assetTracking',

  // Cards

  UNIT_HOURS: 'unitHours',
  UNIT_STATUS: 'unitStatus',
  UNIT_ACTIVITY: 'unitActivity',
  FLEET_ACTIVITY: 'fleetActivity',
  UNIT_FLEET: 'unitFleet',
  FLEET_PUMP_STATUS: 'fleetPumpStatus',
  FLEET_PRC_HISTORY: 'fleetPRCHistory',
  FLEET_CLIENT_STATUS: 'fleetClientStatus',
  COMPONENT_LIFE: 'componentLife',
  COMPONENT_LIFE_CURBSIDE: 'componentLifeCurbside',
  COMPONENT_LIFE_ROADSIDE: 'componentLifeRoadside',
  FLEET_ALARM_COUNT: 'fleetAlarmCount',
  UNIT_ALARM_COUNT: 'unitAlarmCount',
  UNIT_CHART_ENGINE: 'unitChartEngine',
  UNIT_CHART_TRANSMISSION: 'unitChartTransmission',
  UNIT_CHART_POWER_END: 'unitChartPowerEnd',
  UNIT_CHART_LOCKUP: 'unitChartLockup',
  FRAC_OVERVIEW_CARD: 'fracOverviewCard',
  FRAC_JOB_OVERVIEW_CARD: 'fracJobOverviewCard',
  FLEET_COMPONENTS: 'fleetComponents',
  FLEET_OPERATION: 'fleetOperation',
  FLEET_VIBRATION: 'fleetVibration',
  UNIT_CHART_COIL_DETAIL: 'unitChartCoilDetail',
  UNIT_CHART_COIL: 'unitChartCoil',
  UNIT_CHART_PRESSURE_HISTORY: 'unitChartPressureHistory',
  UNIT_CHART_OUTPUT_HISTORY: 'unitChartOutputHistory',
  UNIT_CHART_ROADSIDE_PRESSURE_HISTORY: 'unitChartRoadsidePressureHistory',
  UNIT_CHART_CURBSIDE_PRESSURE_HISTORY: 'unitChartCurbsidePressureHistory',
  UNIT_CHART_VIBRATION_HISTORY: 'unitChartVibrationHistory',
  UNIT_HOURS_CURBSIDE: 'unitHoursCurbside',
  UNIT_HOURS_ROADSIDE: 'unitHoursRoadside',
  UNIT_ACTIVITY_CURBSIDE: 'unitActivityCurbside',
  UNIT_ACTIVITY_ROADSIDE: 'unitActivityRoadside',
  UNIT_USER_CHART: 'unitUserChart',
  UNIT_AUDIT_LOG: 'unitAuditLog',
  DATA_GRID: 'dataGrid',
  JOB_STAGE_CHART: 'jobStageChart',
  FLEET_ACTIVITY: 'fleetActivity',
  FLEET_PRC_HISTORY: 'fleetPRCHistory',
  FLEET_ALARM: 'fleetAlarm',
  FLEET_CLIENT_STATUS: 'fleetClientStatus',
  FLEET_COMPONENTS: 'fleetComponents',
  FLEET_OPERATION: 'fleetOperation',
  FLEET_AUDIT_LOG: 'fleetAuditLog',
  
  // Common
  DATA_EXPLORATION_CHART: 'dataExplorationChart',
  SENSOR_SELECTOR: 'sensorSelector',
  MDT_DATA_GRID: 'mdtDataGrid',
  ICON_PICKER: 'iconPicker',  
  COLOR_PICKER: 'colorPicker',

  // Views
  // These will be used for both Google Analytics Tracking and for the display state
  FLEET_LIVE_VIEW: 'fleetLiveView',
  // These will only be used for Google Analytics Tracking
  OPERATION_VIEW: 'operationView',
  HEALTH_VIEW: 'healthView',
  COMPONENT_VIEW: 'componentView',
  DGB_VIEW: 'dgbView',
  WARM_START_SETTINGS: 'warmStartSettings',
  EXPECTED_LIFE_SETTINGS: 'expectedLifeSettings',
  PROVIDER_SETTINGS: 'providerSettings',
  DEVICE_SETTINGS: 'deviceSettings',
  ADD_COMPONENT: 'addComponent',
  EDIT_COMPONENT: 'editComponent',
  ACTIVE_ALARMS: 'activeAlarms',
  HISTORICAL_ALARMS: 'historicalAlarms',

  // Notifications 
  SUBSCRIPTION_DIALOG: 'subscriptionDialog',
  PROVIDER_DIALOG: 'providerDialog',
  DEVICE_DIALOG: 'deviceDialog',
  SUBSCRIPTIONS_DISPLAY: 'subscriptionsDisplay',

  // Config Panels
  CONFIG_PANEL_PUMP_DASHBOARD_CUSTOM_VIEWS: 'CONFIG_PANEL_PUMP_DASHBOARD_CUSTOM_VIEWS',
  CONFIG_PANEL_FLEET_DASHBOARD_CUSTOM_VIEWS: 'CONFIG_PANEL_FLEET_DASHBOARD_CUSTOM_VIEWS',
  USER_VIEW: 'USER',
  SYSTEM_VIEW: 'MDT'
};
