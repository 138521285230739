export default {
  OIL_PRESSURE: 'oilPressure',
  ENGINE_FUEL_PRESSURE: 'engineFuelPressure',
  ENGINE_TEMP: 'engineTemp',
  ENGINE_RPM: 'engineRpm',
  LUBE_PRESSURE: 'lubePressure',
  LUBE_TEMP: 'lubeTemp',
  FLOW_RATE: 'flowRate',
  TRANS_PRESSURE: 'transPressure',
  TRANS_TEMP: 'transTemp',
  GEAR: 'gear',
  LOCKUP: 'lockup',
  COIL_DEPTH: 'depth',
  COIL_WEIGHT: 'weight',
  COIL_WEIGHT_TARE: 'weightTare',
  COIL_WELLHEAD_PRESSURE: 'wellheadPressure',
  COIL_CIRCULATING_PRESSURE: 'circulatingPressure',
  DISCHARGE: 'discharge',
  SUCTION: 'suction',
  HYDRAULIC_HORSE_POWER: 'hydraulicHorsePower',
  POWER_END_FRONT: 'powerEndFrontScore',
  POWER_END_REAR: 'powerEndRearScore',
  FLUID_END_FRONT: 'fluidEndFrontScore',
  FLUID_END_REAR: 'fluidEndRearScore',
};


