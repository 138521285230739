import moment from 'moment';
import _ from 'lodash';

const fetchEquipmentList = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        pumpHoursDisplay: generateEquipment(100)
      })
    }, 1000)
  });
};

const generateEquipment = (numEquipment) => {
  let data = [];
  let i;
  for (i = 1; i <= numEquipment; i++) {
    let equipmentData = {
          id: i,
          name: _.random(620100, 620500).toString(),
          fleetId: 1,
          fleetName: 'SIM-1',
          ecmHours: _.random(1000.0, 10000.5),
          pumpHours: _.random(1000.0, 10000.5),
          tcmHours: _.random(1000.0, 10000.5),
          timeStamp: moment().utc().endOf('seconds').subtract(_.random(1,10), 'seconds')/1000
        };
    data.push(equipmentData);
  }
  return data;
};

export { fetchEquipmentList }