import { v4 } from 'uuid';
import moment from 'moment';

//mock set of fleets
let fleets = [
    {id:0, fleetName:"MOD2054", datavan:"MOD2054", fleetIdentifier:"5ec67f8c-4c80-419d-b97f-aa7ccad7d5b6", division: 'Wireline', active:false, communicationStatus:"Online", type: 'Test'},
    {id:1, fleetName:"DW1", datavan:"DW1", fleetIdentifier:"2yu66c9f-4c80-419d-b97f-aa7ccad7d5b6", division:'Wireline', active:false, communicationStatus:"Offline", type: 'Wireline'}
];
for (let i = 2; i < 100; i++){
  fleets.push({id:i, fleetName:`MOD${i.toString().padStart(4,'0')}`, datavan:`MOD${i.toString().padStart(4,'0')}`, fleetIdentifier: v4(), division:'Wireline', active:true, communicationStatus:"Online", type: 'Wireline'});
}

const fetchFleets = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                fleetManagement: fleets
            })
        }, 1000);
    });
};

const fetchOwners = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                ownersWithApiKey: [
                    {
                        id: 1,
                        ownerName: "owner A",
                        ownerApiKey: "apiKey A"
                    },
                    {
                        id: 2,
                        ownerName: "owner B",
                        ownerApiKey: "apiKey B"
                    },
                    {
                        id: 3,
                        ownerName: "owner C",
                        ownerApiKey: "apiKeyC"
                    },
                    {
                        id: 4,
                        ownerName: "owner D",
                        ownerApiKey: "apiKey D"
                    },
                    {
                        id: 5,
                        ownerName: "owner E",
                        ownerApiKey: "apiKey E"
                    },
                    {
                        id: 6,
                        ownerName: "owner F",
                        ownerApiKey: "apiKey F"
                    }
                ]
            })
        }, 1000);
    });
};

const fetchDatasourcesForFleet = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        datasourcesForFleet: [
          {
            id: 111111111111111,
            fleetId: fleetId,
            name: 'MPC HMI',
            databaseName: 'mdtmpc',
            databaseHost: 'http://localhost:8086',
            datasourceIdentifier: v4(),
            ipAddress: 'http://10.10.11.207:8080',
            sourceType: 'sample'
          },
          {
            id: 111111111111112,
            fleetId: fleetId,
            name: 'Treater HMI',
            databaseName: 'mdtspy',
            databaseHost: 'http://localhost:8086',
            datasourceIdentifier: v4(),
            ipAddress: 'http://10.10.12.217:8080',
            sourceType: 'sample'
          }
        ]
      })
    }, 1000);
  })
}

const fetchSaveFleet = (fleet) => {

  if (_.random(1,100) % 5 === 0) {
    throw new Error('Failed to save fleet');
  }

  return new Promise((resolve) => {
    setTimeout(() => {
        resolve({
          saveFleet: {
            ...fleet, 
            id: _.random(1, 1000),
            active: _.random(1, 100000) % 50 === 0 ? false : true
          }
        })
    }, 1000);
  });
}

const fetchRestartProducer = (fleet) => {
  return new Promise((resolve) => {
    setTimeout(() => {
        resolve({
          sendCommandForProducer: { createdOn: moment().unix()}
        })
    }, 1000);
  });
}

const fetchAssetInfoForTruck = (truckName) => {
  return new Promise((resolve) => {
      setTimeout(() => {
          resolve({
              assetInfoForTruck: truckName === 'test1'? {
                  "assetId": 123456,
                  "assetCategory": "MDT Ops Laptop"
              } : null
          })
      }, 1000);
  });
}

const fetchDeleteDatasource = () => {

  if (_.random(1,4) % 2 === 0) {
    throw new Error('Failed to delete datasource');
  }

  return new Promise((resolve) => {
    setTimeout(() => {
        resolve({
          deleteDatasource: { success: true}
        })
    }, 1000);
  });
}

const fetchSaveDatasource = (datasource) => {

  if (_.random(1,4) % 2 === 0) {
    throw new Error('Failed to save datasource');
  }

  return new Promise((resolve) => {
    setTimeout(() => {
        resolve({
          saveDatasource: {...datasource, id: _.random(1, 1000)}
        })
    }, 1000);
  });
}

export {
    fetchFleets,
    fetchOwners,
    fetchDatasourcesForFleet,
    fetchSaveFleet,
    fetchRestartProducer,
    fetchAssetInfoForTruck,
    fetchDeleteDatasource,
    fetchSaveDatasource
}