import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';

import AlarmWarningBadge from '../icons/alarmWarningBadge';
import { mdtPalette } from "../../common/styles/mdtPalette";

const styles = {
  smallWarningBadgeIcon: {
    color: mdtPalette().status.level4,
    fontSize: '40px'
  },
  smallWarningBadgeText: {
    color: mdtPalette().typography.black,
    marginTop: '-33px',
    textAlign: 'center'
  },
  smallWarningBadge: {
    height: '40px',
  },

  mediumWarningBadgeIcon: {
    color: mdtPalette().status.level4,
    fontSize: '48px'
  },
  mediumWarningBadgeText: {
    color: mdtPalette().typography.black,
    marginTop: '-42px',
    marginRight: '1px',
    textAlign: 'center'
  },
  mediumWarningBadge: {
    height: '48px',
  },

  largeWarningBadgeIcon: {
    color: mdtPalette().status.level4,
    fontSize: '64px'
  },
  largeWarningBadge: {
    height: '64px',
  },
  largeWarningBadgeText: {
    color: mdtPalette().typography.black,
    marginTop: '-52px',
    textAlign: 'center'
  },
};

class WarningAlarmBadge extends Component {

  render() {
    let count = !_.isNil(this.props.count) ? this.props.count : 0;

    return (
      <Box sx={this.props.isLarge === true ? {...styles.largeWarningBadge} : this.props.isMedium === true ? {...styles.mediumWarningBadge} : {...styles.smallWarningBadge}}>
        <AlarmWarningBadge sx={this.props.isLarge === true ? {...styles.largeWarningBadgeIcon} : this.props.isMedium === true ? {...styles.mediumWarningBadgeIcon} : {...styles.smallWarningBadgeIcon}}/>
        {
          this.props.isLarge === true &&
          <Typography variant={'h5'} sx={styles.largeWarningBadgeText}>{count}</Typography>
        }
        {
          this.props.isMedium === true &&
          <Typography variant={'h6'} sx={styles.mediumWarningBadgeText}>{count}</Typography>
        }
        {
          this.props.isLarge !== true &&  this.props.isMedium !== true &&
          <Typography variant={'subtitle2'} sx={styles.smallWarningBadgeText}>{count}</Typography>
        }
      </Box>
    );
  }
}

WarningAlarmBadge.propTypes = {
  count: PropTypes.number,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
};

export default WarningAlarmBadge;