import _ from 'lodash';

import defaultActionFactory from '../../common/factories/defaultActionFactory';

import appActionTypes from '../appActionTypes';

const displayError = defaultActionFactory(appActionTypes.APP_DISPLAY_ERROR, 'error');
const hideError = defaultActionFactory(appActionTypes.APP_HIDE_ERROR);
const errorUIOpened = defaultActionFactory(appActionTypes.APP_ERROR_UI_OPENED);
const errorUIClosed = defaultActionFactory(appActionTypes.APP_ERROR_UI_CLOSED);

/**
 * Action for error handling. Allows for console logging of a
 * returned error and the displaying of a user friendly error
 * in the UI.
 */
const handleError = (friendlyError, realError) => {
  return (dispatch, getState) => {
    // TODO: Better way to signaling success of an action to the user
    // Added this to allow for use of the Snackbar as a basic action confirmation 
    // and not log an essentially non-error
    // Rational:
    // The Portal App's current paradigm for creating/saving things is that you are creating 
    // things on the same display that you are on
    // However, with Subscriptions, that might not be the case - the user could be at 
    // the Alarms display and create a Subscription off an alarm in the list
    // So we are going to reuse the handle error for now to show a message on the snackbar
    if (!_.isNil(realError)) {
      console.error(realError);
    }
    return dispatch(displayError(friendlyError));
  }
};

export {
  handleError,
  displayError,
  hideError,
  errorUIOpened,
  errorUIClosed,
};