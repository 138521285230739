import _ from "lodash";
import moment from "moment";

const calculateStartTimeUsingTimeFrame = (timeFrame) => {
  if (!_.isNil(timeFrame)) {
    return moment().subtract(timeFrame.value, 'minutes').startOf('minute').unix();
  }
}

const calculateEndTimeUsingStartTimeAndDuration = (startTime, duration) => {
  if (!_.isNil(startTime) && !_.isNil(duration)) {
    // Make a copy so we aren't modifying the original
    let selectedCustomStartTime = moment(startTime);
    return selectedCustomStartTime.add(duration.value, 'minutes').startOf('minute').unix();
  }
}

export {
  calculateStartTimeUsingTimeFrame,
  calculateEndTimeUsingStartTimeAndDuration
}