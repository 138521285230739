import React, { Component } from 'react';
import {withProps} from 'recompose';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import WrenchIcon from '@mui/icons-material/Build';
import HealthIcon from '@mui/icons-material/LocalHospital';
import GearsIcon from '../../controls/icons/gears';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AlarmsIcon from '@mui/icons-material/Alarm';
import PodcastsIcon from '@mui/icons-material/Podcasts';

import ComponentTypes from "../../componentTypes";

import * as fracJobOverviewCardActions from '../../../state/cards/fracJobOverviewCard/fracJobOverviewCardActions';
import * as fleetDashboardActions from '../../../state/displays/fleetMap/display/fleetMapActions';
import * as alarmsActions from '../../../state/displays/alarms/alarmsActions';
import fleetMapViews from '../../../state/displays/fleetMap/display/fleetMapViews';
import FuelIcon from "@mui/icons-material/LocalGasStation";
import {appState as applicationState} from '../../../state/app/appSelectors';
import OWNERS from "../../common/owners";
import {fracJobOverviewCardState} from "../../../state/cards/fracJobOverviewCard/fracJobOverviewCardSelectors";

const styles = {
  menuIcon: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
};


class FracJobOverviewCardMenu extends Component {
  render() {
    let ownerId = _.isNil(this.props.selectedOwner) ? this.props.ownerId : this.props.selectedOwner.value;
    let displayDgbMenuOption = (ownerId === OWNERS.NEXTIER || ownerId === OWNERS.MDT);

    return (
      <Popover
        open={this.props.openMenu}
        anchorEl={this.props.menuTargetElement}
        onClose={() => this.props.closeMenu()}
        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
        transformOrigin={{vertical: 'top', horizontal: 'left', }}
      >
        <MenuList>
          <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.DASHBOARD); this.props.closeMenu() }}>
            <ListItemIcon><DashboardIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Dashboard View'/>
          </MenuItem>
          <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.OPERATION); this.props.closeMenu() }}>
            <ListItemIcon><GearsIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Operation View'/>
          </MenuItem>
          <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.COMPONENT); this.props.closeMenu() }}>
            <ListItemIcon><WrenchIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Component View'/>
          </MenuItem>
          <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.HEALTH); this.props.closeMenu() }}>
            <ListItemIcon><HealthIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Health View'/>
          </MenuItem>
          {
              displayDgbMenuOption && <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.DGB); this.props.closeMenu() }}>
                <ListItemIcon><FuelIcon sx={styles.menuIcon}/></ListItemIcon>
                <ListItemText primary='DGB View'/>
              </MenuItem>
          }
          <MenuItem onClick={() => { this.props.navigateToFleetDashboard(this.props.fleetId, fleetMapViews.LIVE_VIEW); this.props.closeMenu() }}>
            <ListItemIcon><PodcastsIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Live View'/>
          </MenuItem>
          <MenuItem onClick={() => { this.props.navigateToAlarmsDisplay(this.props.fleetName); this.props.closeMenu() }}>
            <ListItemIcon><AlarmsIcon sx={styles.menuIcon}/></ListItemIcon>
            <ListItemText primary='Alarm Display'/>
          </MenuItem>
        </MenuList>
      </Popover>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: props.stateKey,
      type: ComponentTypes.FRAC_JOB_OVERVIEW_CARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fracJobOverviewCardState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    openMenu: componentState.openMenu,
    menuTargetElement: componentState.menuTargetElement,
    selectedOwner: appState.selectedOwner,
    ownerId: appState.user.ownerId
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeMenu: () => dispatch(fracJobOverviewCardActions.closeMenu(props.stateDef)),
    navigateToFleetDashboard: (fleetId, view) => { dispatch(fleetDashboardActions.navigate(fleetId, view)) },
    navigateToAlarmsDisplay: (fleetName) => { dispatch(alarmsActions.navigateByFleet(fleetName)) },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FracJobOverviewCardMenu));