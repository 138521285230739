import {performCardFetch} from '../../common/fetch'
import _ from "lodash";
import useSimulationData from '../../../helpers/simulationHelper';

import * as unitUserChartSimulation from './unitUserChartSimulation'

const fetchUnitUserChart = (truck, startTime, endTime, sensors) => {

  if (useSimulationData('unitUserChart')) {
    return unitUserChartSimulation.fetchUnitUserChart(truck, startTime, endTime, sensors);
  }

  const sensorSetIds = _.map(sensors, s => {
    return {
      'sensorSetId': s.sensorSetId,
      'unitOfMeasure': s.uom,
    };
  });

  return performCardFetch({
    "operationName": "getUserUnitChart",
    'query': 'query UserUnitChart($input: UserUnitChartSeriesInput!) { userUnitChart(input:$input) {series { sensorSetId unitOfMeasure value timeStamp latest } } }',
    'variables': {
      'input': {
        'truckPid': truck.truckPid,
        'startTime': startTime,
        'endTime': endTime,
        'sensorSetIds': sensorSetIds,
        'downSamplingConfig': {
          'bucketCount': 1300, // This is about as many pixels wide as the chart will be when the card is set to the largest size
          'maxGapSeconds': null,
          'limit': null
        }
      }
    }
  });
};

export {
  fetchUnitUserChart
}