import _ from 'lodash';

const exportCsvState = state => {

  return {

    // The url returned from the server side allowing the browser to download the csv export
    exportCsvUrl: _.isNil(state) ? null : state.exportCsvUrl,

    // Flag to trigger the UI to get the browser to download the csv export
    triggerExportCsv: _.isNil(state) ? false : state.triggerExportCsv,

  };

};

export {
  exportCsvState
};