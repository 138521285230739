import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import dfpDashboardActionTypes from './dfpDashboardActionTypes';
import * as navActions from '../../app/actions/appNavActions';

import { fetchDfpDashboardDisplayData, fetchDfpDashboardDataAge } from "./dfpDashboardQueries";
import errorMessages from '../../common/errorMessages';

const selectContext = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_CONTEXT, 'stateDef', 'context');
const selectView = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_VIEW, 'stateDef', 'view');
const selectNavigationContext = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_NAVIGATION_CONTEXT, 'stateDef', 'context');

const setTimeFrame = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTime = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_SET_CUSTOM_DURATION, 'stateDef', 'duration');

const clearDataAge = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_DATA_AGE_CLEAR_DATA, 'stateDef');
const refreshRelativeTime = defaultActionFactory(dfpDashboardActionTypes.DFP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE, 'stateDef');

const queryData = queryActionFactory(
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_STARTING,
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_SUCCESS,
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_DFP_LIST,
  fetchDfpDashboardDisplayData
);

const queryDataAge = queryActionFactory(
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_STARTING,
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_SUCCESS,
  dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_ERROR,
  errorMessages.ERROR_RETRIEVING_DATA_AGE,
    fetchDfpDashboardDataAge
);

const loadDisplay = (stateDef) => {
  return async (dispatch, getState) => {

    // Check if there is a navigation context for this display in the app state
    let navigationContext = getState().app.navigation.context;
    let dashboardContext = _.isNil(navigationContext['/dfp-dashboard']) ? null : navigationContext['/dfp-dashboard'];
    if (!_.isNil(dashboardContext)) {
      // Clear out the context from the app state now that we have it
      await dispatch(navActions.setNavigationContext('/dfp-dashboard', null));
    }

    // Execute our display query
    await dispatch(queryData(stateDef));

    // Set the selected context
    if (_.isNil(dashboardContext)) {
      await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
    } else {
      await dispatch(selectNavigationContext(stateDef, dashboardContext));
    }

  }
};

const navigate = (unitId) => {
  return async (dispatch, getState) => {
    const url = '/dfp-dashboard';
    const context = _.isNil(unitId) ? null : { unitId: unitId };
    return dispatch(navActions.navigate(url, context));
  }
};

export {
  queryData,
  queryDataAge,
  clearDataAge,
  refreshRelativeTime,
  selectContext,
  selectView,
  selectNavigationContext,
  loadDisplay,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  navigate
}
