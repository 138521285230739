import { mdtPalette } from '../../common/styles/mdtPalette';

const chartOptions = (rawValuePumping, rawValueIdle, rawValueStopped, rawValueOffline) => {

  return {

    title: { text: "" },

    credits: { enabled: false },

    chart: {
      type: 'pie',
      width: 110,
      height: 110,
      spacing: [0,0,0,0],
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      animation: false,
    },

    pane: {
      size: '100%',
      center: ['50%', '50%'],
      startAngle: -135,
      endAngle: 135,
      background: {
        backgroundColor: mdtPalette().materialUI.palette.grey['600'],
        borderColor: mdtPalette().materialUI.palette.grey['600'],
        shape: 'arc'
      }
    },

    tooltip: { enabled: false },

    plotOptions: {
      pie: {
        borderColor: null,
        allowPointSelect: false,
        dataLabels: { enabled: false, },
        animation: false,
      }
    },

    series: [{
      states: { hover: { enabled: false } },
      data: [
        { y: rawValuePumping, color: mdtPalette().status.level2 },
        { y: rawValueIdle, color: mdtPalette().status.level4 },
        { y: rawValueStopped, color: mdtPalette().status.level5 },
        { y: rawValueOffline, color:  mdtPalette().status.level1 }
      ],
    }]
  }

};

export default chartOptions