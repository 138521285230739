import _ from 'lodash';
import fleetMapActionTypes from '../fleetMapActionTypes';
import fleetMapViews from '../fleetMapViews';
import { fleetMapState } from '../fleetMapSelectors';
import componentTypes from "../../../../../components/componentTypes";
import moment from 'moment';

const initialState = fleetMapState();

const displayReducer = (state, action) => {

  switch (action.type) {

    case fleetMapActionTypes.FLEET_MAP_SELECT_FLEET:
      return onSetSelectedFleet(state, action);

    case fleetMapActionTypes.FLEET_MAP_SELECT_VIEW:
      return onSetSelectedView(state, action);

    case fleetMapActionTypes.FLEET_MAP_UPDATE_CUSTOM_VIEW_INPUT:
      return onUpdateCustomViewInput(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_NAVIGATION_CONTEXT:
      return onSetNavigationContext(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_START_TIME:
      return onSetStartTime(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_DURATION:
      return onSetDuration(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_START_TIME_DISPLAY:
      return onSetStartTimeDisplay(state, action);

    case fleetMapActionTypes.FLEET_MAP_SHOW_DIALOG:
      return onShowDialog(state, action);

    case fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_SUCCESS:
      return onLoadCardsList(state, action);

    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_STARTING:
    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_STARTING:
    case fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_STARTING:
    case fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_STARTING:
      return { ...state, queryRunning: true };

    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_SUCCESS:
    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_ERROR:
    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_SUCCESS:
    case fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_ERROR:
    case fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_ERROR:
    case fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_SUCCESS:
    case fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_ERROR:
      return { ...state, queryRunning: false };
    
    case fleetMapActionTypes.FLEET_MAP_CARDS_QUERY_RUNNING:
      return onCardsQueryRunning(state, action);
    case fleetMapActionTypes.FLEET_MAP_DISCARD_CUSTOM_VIEW_CONFIGURATION:
      return onDiscardCustomViewConfiguration(state, action);

    case fleetMapActionTypes.FLEET_MAP_SET_MANUAL_REFRESH:
      return onSetManualRefresh(state, action);

    default: return state;
  }
};

const onSetSelectedFleet = (state, action) => {

  let selectedFleet = action.fleet;

  if (!_.isNil(state.navigationContextFleetId)) {
    // If there is a navigation context, try and resolve the fleet from the list of fleets.
    selectedFleet = _.find(state.fleets, {id: state.navigationContextFleetId});
  }

  if (!_.isNil(selectedFleet)) {
    // Always try and resolve the selection against the fleets collection. This will handle the
    // scenarios where we switch owners or we otherwise get the selection out of sync with the
    // list of fleets in the state.
    selectedFleet = _.find(state.fleets, {id: selectedFleet.id});
  }

  // Finally if we have no selection AND we have some fleets, set the selection to the first one
  selectedFleet = _.isNil(selectedFleet) && !_.isEmpty(state.fleets) ? state.fleets[0] : selectedFleet;

  return {
    ...state,
    selectedFleet: selectedFleet,
    navigationContextFleetId: initialState.navigationContextFleetId,
  };
};

const onSetSelectedView = (state, action) => {

  let selectedView = action.view;
  let selectedCustomView = state.selectedCustomView;

  if (action.view && !_.isNil(action.view.type) && action.view.type === componentTypes.USER_VIEW) {
    selectedCustomView = action.view;
  }

  // If we are given a view that we don't know about we just set it to the operation view
  selectedView = _.isNil(selectedView) ? fleetMapViews.OPERATION : selectedView;

  return {
    ...state,
    selectedView: selectedView,
    selectedCustomView: selectedCustomView,
    customViewInput: ''
  }
};

const onUpdateCustomViewInput = (state, action) => {
  const newSelectedView = _.cloneDeep(state.selectedView);
  let newCustomViewInput = state.customViewInput;
  if(!_.isNil(newSelectedView?.name)){
    if(newSelectedView.id === action.view?.id) {
      newSelectedView.name = action.customViewInput;
    } else if(_.isNil(action.view)){
      newCustomViewInput = action.customViewInput;
    }
  }
  return {
    ...state,
    selectedView: newSelectedView,
    customViewInput: newCustomViewInput
  };
};

const onSetNavigationContext = (state, action) => {

  return {
    ...state,
    navigationContextFleetId: action.fleetId,
  }
};

const onSetTimeFrame = (state, action) => {

  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    selectedCustomStartTime: initialState.selectedCustomStartTime,
    selectedCustomDuration: initialState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: initialState.selectedCustomStartTimeDisplay,
  };

  // If the time frame is set to custom, set the initial start time to the now.

  if (newState.selectedTimeFrame.label != null && newState.selectedTimeFrame.label === 'Custom') {

    let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');

    newState = {
      ...newState,
      selectedCustomStartTime: defaultTime,
      selectedCustomStartTimeDisplay: defaultTime,
    }
  }

  return newState;
};

const onSetStartTime = (state, action) => {

  return {
    ...state,
    selectedCustomStartTime: action.startTime,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onSetDuration = (state, action) => {

  return {
    ...state,
    selectedCustomDuration: action.duration
  }
};

const onSetStartTimeDisplay = (state, action) => {

  return {
    ...state,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onShowDialog = (state, action) => {

  return {
    ...state,
    showDialog: action.show
  }
}

const onLoadCardsList = (state, action) => {
  let formattedList = []
  _.forEach(JSON.parse(action.cardsList), function (card) {
    formattedList.push({
      ...card,
      id: card.type,
      text: {

        title: card.type === componentTypes.FLEET_ALARM_COUNT? "Fleet Active Alarms": _.startCase(card.type),
        description: card.description ? card.description : null,
      },
    })
  })

  return {
    ...state,
    cardsList: formattedList,
    queryRunning: false,
  }
};

const onCardsQueryRunning = (state, action) => {
  return {
    ...state,
    cardsQueryRunning: action.queryRunning
  }
};

const onDiscardCustomViewConfiguration = (state, action) => {
  const selectedView = action.originalViewConfig?.views.find( view => view.id === state.selectedView?.id);
  return {
    ...state,
    selectedView: selectedView || state.selectedView
  }
};

const onSetManualRefresh = (state, action) => {
  return {
    ...state,
    manualRefresh: action.isManualRefresh
  }
}

export default displayReducer