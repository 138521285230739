import _ from 'lodash';
import { expectedLifeState } from './expectedLifeSelectors';
import { warmStartState } from './warmStartSelectors';
import { providerState } from './providerSelectors';
import {deviceState} from "./deviceSelectors";

const settingsState = state => {

  let expectedLife = expectedLifeState(state);
  let warmStart = warmStartState(state);
  let provider = providerState(state);
  let device = deviceState(state);

  return {
    ...expectedLife,
    ...warmStart,
    ...provider,
    ...device,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    formIndex: _.isNil(state) ? 0 : state.formIndex,
  };

};

export {
  settingsState
};