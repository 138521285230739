import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";
import { v4 as uuidv4 } from 'uuid';

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import * as filterActions from '../../../state/common/filtering/filterActions';
import * as searchActions from '../../../state/common/search/searchActions';
import './assetTrackingDisplay.css';

import {
  Box,
  Typography,
  IconButton,
  Chip,
  Divider,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  Switch,
  FormControlLabel
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterDialog from '../../common/filtering/filterDialog';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import LocationOffIcon from '@mui/icons-material/LocationOff';

import Progress from '../../controls/progress';
import ComponentTypes from '../../componentTypes';
import { getAssetNameByType, getAssetIconByAssetName, assetNames } from '../../../state/common/services/assetTypeService';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import { assetTrackingState } from '../../../state/displays/assetTracking/assetTrackingSelectors';
import { queryAssetData, updateEditMode, queryLocations, saveLocations, updateCanSave, setShowInActive } from '../../../state/displays/assetTracking/assetTrackingActions';

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  detailsPage: {
    ...detailsPageStyles.detailsPage,
    height: '100vh'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw',
    height: 'inherit'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start',
    flexGrow: 0,
    alignItems: 'center'
  },
editModePageHeaderActions: {
 ...detailsPageStyles.detailsPageHeaderActions,
  alignItems: 'center',
  flexGrow: 1
},
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    alignItems: 'center'
  },
  viewContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1,
    paddingBottom: 4
  },
  contextSelection: {
    width: '150px',
    marginLeft: 1,
    paddingRight: 1,
  },
 filterContainer: {
    display:'flex',
    flexFlow:'row nowrap',
    alignItems: 'center',
    paddingLeft: 3,
    flexGrow: 1
  },
  filterItems: {
    paddingLeft: 3,
  },
  childComponent : {
    margin: 1,
  },
  actionButton: {
      backgroundColor:'grey.200',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
      '&:hover': {
        backgroundColor:'grey.400',
        color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
      }
    },
    editModeActions: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center'
      },
  actionsDivider: {
    height: '36px',
    marginLeft: '12px',
    marginRight: '12px'

  }
}

const defaultCircleOptions = {
  fillColor: "#7E2900",
  fillOpacity: 0.25,
  strokeWeight: 2,
  strokeColor: "#A83700",
  clickable: true,
  editable: true,
  zIndex: 1,
  draggable: true,
}

const defaultPolygonOptions = {
  fillColor: "#7E2900",
  fillOpacity: 0.25,
  strokeWeight: 2,
  strokeColor: "#A83700",
  clickable: true,
  editable: true,
  zIndex: 1,
  draggable: true,
}

let map = null;
let assetMarkers = [];
let assetHiddenMarkers = [];
let clusterHiddenMarkers = [];
let markerclusterer = null;

// The Google Maps Markers (Advanced Markers included) does not let us store custom data on the marker
// So this collection is a way to store the asset along with a Google Maps Position so 
// we can look it up when we need to (determining if a marker is within bounds of a cluster marker).
// Each object in here is: { asset: asset, position: google.maps.LatLng }
let assetPositions = [];

// This is a collection of the shapes (circles and polygons) that are drawn on the map
// Consider this the component level representation of the Locations
let shapes = [];
let toDeleteShapes = [];

let drawingManagerEventListenerHandle = null;
let mapZoomChangedEventHandler = null;

class ClusterRender {

  #buildClusterContent;
  #hiddenMarkerMouseOver;
  #hiddenMarkerMouseOut;

  constructor(buildClusterContent, hiddenMarkerMouseOver, hiddenMarkerMouseOut) {
    this.#buildClusterContent = buildClusterContent;
    this.#hiddenMarkerMouseOver = hiddenMarkerMouseOver;
    this.#hiddenMarkerMouseOut = hiddenMarkerMouseOut;
  }

  render({ count, position, bounds }, stats) {

    // Find the assets within the bounds of the cluster and check if any of those are datavans
    let assetsWithinBounds = _.filter(assetPositions, (asset) => {
      return bounds.contains(asset.position);
    });
    let anyDatavanAssets = _.filter(assetsWithinBounds, (asset) => {
      return asset.asset.unitType === assetNames.DATAVAN;
    });

    // Create the cluster marker
    // Always need to create a new one; not creating one or attempting to reuse one causes issues with the cluster markers
    let marker = new google.maps.marker.AdvancedMarkerElement({
      map: map,
      content: this.#buildClusterContent(assetsWithinBounds, anyDatavanAssets),
      position: position
    });
    marker.id = position.lat().toString() + position.lng().toString();
    
    // Our hidden marker has events and will be show/hidden based on the zoom level and if 
    // we have cluster markers at the same position
    let foundHiddenMarker = _.find(clusterHiddenMarkers, (hiddenMarker) => {
      return hiddenMarker.hiddenMarker.id === position.lat().toString() + position.lng().toString();
    });
    
    // We haven't create a hidden marker for this cluster yet
    if (_.isNil(foundHiddenMarker)) {
      // Create marker
      let hiddenMarker = new google.maps.Marker({
        map: map,
        position: position,
        opacity: 0,
        // Set a zIndex so these are (hopefully) always on top of everything else and can receive the mouseover events
        zIndex: 10001 + clusterHiddenMarkers.length, 
        optimized: true
      });
      hiddenMarker.id = marker.id;
      // Add event handlers
      // Click will zoom on the cluster
      const clickListenerHandle = hiddenMarker.addListener('click', () => {
        const tempBounds = new google.maps.LatLngBounds();
        _.forEach(assetsWithinBounds, (asset) => {
          tempBounds.extend(asset.position);
        });
        map.fitBounds(tempBounds);
      });

      // Mouse over and mouse out will show/hide the cluster marker details
      let mouseOverListenerHandle = null;
      let mouseOutListenerHandle = null;
      if (anyDatavanAssets.length > 0) {
        mouseOverListenerHandle = google.maps.event.addListener(hiddenMarker, 'mouseover', (event) => {
          this.#hiddenMarkerMouseOver(marker);
        });
        mouseOutListenerHandle = google.maps.event.addListener(hiddenMarker, 'mouseout', (event) => {
          this.#hiddenMarkerMouseOut(marker);
        });
      }

      // This is our custom container for the hidden marker and its event handlers
      // This lets us clear the listeners and remove the marker from the map when we need to (ie on zoom changed)
      const hiddenMarkerContainer = {
        hiddenMarker: hiddenMarker,
        clickListenerHandle: clickListenerHandle
      }
      if (!_.isNil(mouseOverListenerHandle)) {
        hiddenMarkerContainer.mouseOverListenerHandle = mouseOverListenerHandle;
      }
      if (!_.isNil(mouseOutListenerHandle)) {
        hiddenMarkerContainer.mouseOutListenerHandle = mouseOutListenerHandle;
      }

      clusterHiddenMarkers.push(hiddenMarkerContainer);
    } else {
      // We have already created it so just set it to the map and add the listeners back
      foundHiddenMarker.hiddenMarker.setMap(map);
      foundHiddenMarker.clickListenerHandle = foundHiddenMarker.hiddenMarker.addListener('click', () => {
        const tempBounds = new google.maps.LatLngBounds();
        _.forEach(assetsWithinBounds, (asset) => {
          tempBounds.extend(asset.position);
        });
        map.fitBounds(tempBounds);
      });
      if (anyDatavanAssets.length > 0) {
        foundHiddenMarker.mouseOverListenerHandle = google.maps.event.addListener(foundHiddenMarker.hiddenMarker, 'mouseover', (event) => {
          this.#hiddenMarkerMouseOver(marker);
        });
        foundHiddenMarker.mouseOutListenerHandle = google.maps.event.addListener(foundHiddenMarker.hiddenMarker, 'mouseout', (event) => {
          this.#hiddenMarkerMouseOut(marker);
        });
      }
    }

    return marker;
  }
}

class AssetTrackingDisplay extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    (async () => {
      await google.maps.importLibrary("maps");
      await google.maps.importLibrary("drawing");
      await google.maps.importLibrary("marker");
      await google.maps.importLibrary("core");
    })();

    // Give the libraries a moment to load before we start asking for data and using them
    setTimeout(() => {
      this.loadData();
      this.loadMap();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (_.isNil(this.props.refreshTimestamp) || (prevProps.refreshTimestamp !== this.props.refreshTimestamp)) {
      //Reset the markers on the map and rerender them
      assetPositions = [];
      _.forEach(assetMarkers, (marker) => {
        marker.setMap(null);
      });
      assetMarkers = [];
      _.forEach(assetHiddenMarkers, (hiddenMarker) => {
        hiddenMarker.setMap(null);
      });
      assetHiddenMarkers = [];
      this.addMarkersForAssets();
    }
    if (!_.isNil(this.props.searchResults) && !_.isNil(map)) {
      map.panTo({
          lat:  this.props.searchResults.lastKnownCoord.latitude,
          lng:  this.props.searchResults.lastKnownCoord.longitude
      });
      map.setZoom(16);
      }
    if (this.props.editMode !== prevProps.editMode) {
      this.updateDrawingManager();

      if (this.props.editMode === true) {
        document.getElementById("resetZoomButton").classList.add("edit");
        _.forEach(shapes, (shape) => {
          shape.marker.content.classList.add('edit');
        });
      } else {
        document.getElementById("resetZoomButton").classList.remove("edit");
        _.forEach(shapes, (shape) => {
          shape.marker.content.classList.remove('edit');
        });
      }
    }
    // Handle what happens after a save. A save can include deleting locations.
    if (!_.isEqual(prevProps.locations, this.props.locations) || (prevProps.refreshTimestamp !== this.props.refreshTimestamp)) {
      // Remove the shapes that were deleted
      toDeleteShapes = [];
      this.clearShapes();
      this.addShapesForLocations();
    }
  }

  componentWillUnmount() {
    //The drawingManagerEventListenerHandle is still bound from the previous map so it needs to be cleared when the display unmounts
    this.clearShapes();
    drawingManagerEventListenerHandle = null;
    mapZoomChangedEventHandler = null;
  }

  loadData() {
    this.props.onQueryLocations();
    this.props.onQueryAssetsForOwner();
  }

  // Calculates the centroid (geometric center) of a polygon given its vertices and adjusts based on the shape of the polygon
  getPolygonCenter(path) {
    let center = { lat: 0, lng: 0 };
    let signedArea = 0;
    let x0 = 0, y0 = 0,  x1 = 0, y1 = 0; // Initialize vertices positions
    let a = 0; // Signed area of the triangle (x0, y0, x1, y1, x2, y2)

    let i = 0;
      // Loop through all vertices to compute the signed area and centroid's coordinates.
    for (i = 0; i < path.getLength() - 1; ++i) {
      // Retrieve longitude and latitude of consecutive vertices.
      x0 = path.getAt(i).lng();
      y0 = path.getAt(i).lat();
      x1 = path.getAt(i+1).lng();
      y1 = path.getAt(i+1).lat();
      a = x0 * y1 - x1 * y0;
      signedArea += a;
      center.lng += (x0 + x1) * a;
      center.lat += (y0 + y1) * a;
    }
    
    // Close the polygon by connecting the last vertex to the first and updating the area and centroid.
    x0 = path.getAt(i).lng();
    y0 = path.getAt(i).lat();
    x1 = path.getAt(0).lng();
    y1 = path.getAt(0).lat();
    a = x0 * y1 - x1 * y0;
    signedArea += a;
    center.lng += (x0 + x1) * a;
    center.lat += (y0 + y1) * a;

    // Normalize the centroid coordinates by the total area.
    signedArea *= 0.5; // Actual area is half the signed area.
    center.lng /= (6 * signedArea);
    center.lat /= (6 * signedArea);

    return center;
  }

  loadMap() {
    map = new google.maps.Map(document.getElementById("map"), 
    {
      isFractionalZoomEnabled: false,
      clickableIcons: false,
      zoom: 6,
      maxZoom: 21,

      // The mapId links this instance of a map to a style defined in the Google Maps Platform on GCP
      mapId: '977f0d23177f5ad4',
      mapTypeId: 'roadmap',

      // These disable some of the default controls
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: true,

      // Disables keyboard shortcuts
      keyboardShortcuts: false,
    });

    // Move the map to center around Texas
    map.panTo(new google.maps.LatLng(41.850033, -87.6500523));
    map.setZoom(4);
    // Add the reset zoom button to the map
    const resetZoomDiv = document.createElement("div");
    const resetZoomControl = this.resetZoomButton(map);
    resetZoomDiv.appendChild(resetZoomControl);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(resetZoomDiv);

    if (_.isNil(mapZoomChangedEventHandler)) {
      mapZoomChangedEventHandler = map.addListener("zoom_changed", () => {
        // Remove the Hidden Markers for Clusters and clear the listeners
        // They will be added back when the cluster is re-rendered
        _.forEach(clusterHiddenMarkers, (hiddenMarker) => {
          google.maps.event.clearInstanceListeners(hiddenMarker.hiddenMarker);
          hiddenMarker.hiddenMarker.setMap(null);
        });
      });
    }

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      circleOptions: defaultCircleOptions,
      polygonOptions: defaultPolygonOptions
    });
  }

  generateShapeId() {
    return 's' + uuidv4().toString().replaceAll('-', '');
  }

  updateDrawingManager() {
    if (this.props.editMode) {

      shapes.forEach((group) => {
        group.shape.setEditable(true);
        group.shape.setDraggable(true);
        group.shape.clickable = true;
        group.hiddenMarker.setMap(null);
      });

      this.drawingManager.setMap(map);

      // Creates the event listener for the drawing manager once
      if (_.isNil(drawingManagerEventListenerHandle)) {
        drawingManagerEventListenerHandle = google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {

          const shape = event.overlay;

          // Polygons with less than 3 paths (strokes) are not valid so we should simply remove them from the map
          if ((event.type === google.maps.drawing.OverlayType.POLYGON) && (shape.getPath().getLength() < 3)) {
            shape.setMap(null);
            return;
          }

          const shapeId = this.generateShapeId();
          shape.set('id', shapeId);

          let position;
          let geomType;

          if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
            position = shape.getCenter();
            geomType = 'Circle';
          } else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            let polygonCenter = this.getPolygonCenter(shape.getPath());
            position = new google.maps.LatLng(polygonCenter.lat, polygonCenter.lng);
            geomType = 'Polygon';
          }

          this.commonSetupForShapes(shape, shapeId, position, '', '', geomType, null, true);

          this.updateCanSave();

          this.drawingManager.setDrawingMode(null);
        });
      }
    } else {
      this.drawingManager.setMap(null);

      // Mark all shapes in the shapes collection as not new and make them not editable or movable
      shapes.forEach((group) => {
        group.shape.setEditable(false);
        group.shape.setDraggable(false);
        group.hiddenMarker.setMap(map);
      });
    }
  }

  updateHasChangesForShapeId = (shapeId) => {
    let foundShape = _.find(shapes, (shape) => shape.id === shapeId);
    if (!_.isNil(foundShape)) {
      foundShape.hasChanges = true;
      this.updateCanSave();
    }
  }

  resetZoomButton(map) {
    const resetZoomButton = document.createElement('button');
    resetZoomButton.classList.add('reset-zoom-button');
    resetZoomButton.textContent = "Reset Zoom";
    resetZoomButton.title = "Click to recenter the map";
    resetZoomButton.type = "button";
    resetZoomButton.id = "resetZoomButton";
    resetZoomButton.addEventListener("click", () => {
      map.panTo(new google.maps.LatLng(41.850033, -87.6500523));
      map.setZoom(4);
    });
    return resetZoomButton;
 }

  buildLocationsContent(id, marker, shapes, locationTypes, locationName, locationType) {
    const content = document.createElement('div');
    content.classList.add("asset");
    if (_.isEmpty(locationName) || _.isEmpty(locationType)) {
      content.classList.add("error");
    }

    const popupDiv = document.createElement('div');
    popupDiv.classList.add("locations-popup");

    //Set location icon by type
    let locationIconSvg;
    switch (locationType) {
      case 'shop':
        locationIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 488V171.3c0-26.2 15.9-49.7 40.2-59.4L308.1 4.8c7.6-3.1 16.1-3.1 23.8 0L599.8 111.9c24.3 9.7 40.2 33.3 40.2 59.4V488c0 13.3-10.7 24-24 24H568c-13.3 0-24-10.7-24-24V224c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32V488c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zm488 24l-336 0c-13.3 0-24-10.7-24-24V432H512l0 56c0 13.3-10.7 24-24 24zM128 400V336H512v64H128zm0-96V224H512l0 80H128z"/></svg>';
        break;
      case 'yard':
        locationIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 32H181.2c-27.1 0-51.3 17.1-60.3 42.6L3.1 407.2C1.1 413 0 419.2 0 425.4C0 455.5 24.5 480 54.6 480H256V416c0-17.7 14.3-32 32-32s32 14.3 32 32v64H521.4c30.2 0 54.6-24.5 54.6-54.6c0-6.2-1.1-12.4-3.1-18.2L455.1 74.6C446 49.1 421.9 32 394.8 32H320V96c0 17.7-14.3 32-32 32s-32-14.3-32-32V32zm64 192v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>';
        break;
      case 'testStand':
        locationIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M36.8 192H603.2c20.3 0 36.8-16.5 36.8-36.8c0-7.3-2.2-14.4-6.2-20.4L558.2 21.4C549.3 8 534.4 0 518.3 0H121.7c-16 0-31 8-39.9 21.4L6.2 134.7c-4 6.1-6.2 13.2-6.2 20.4C0 175.5 16.5 192 36.8 192zM64 224V384v80c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V384 224H320V384H128V224H64zm448 0V480c0 17.7 14.3 32 32 32s32-14.3 32-32V224H512z"/></svg>';
        break;
      default:
        locationIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z"/></svg>';
        break;
    }
    
    const iconDiv = document.createElement('div');
    iconDiv.classList.add("icon");
    iconDiv.innerHTML = locationIconSvg;

    const popupIconDiv = document.createElement('div');
    popupIconDiv.classList.add("icon");
    popupIconDiv.innerHTML = locationIconSvg;

    const inputContainer = document.createElement('div');
    inputContainer.classList.add("input-container");

    const input = document.createElement('input');
    input.required = true;
    input.disabled = true;
    input.classList.add("input-field");
    input.id = id + '-input';
    input.type = "text";
    if (!_.isEmpty(locationName)) {
      input.value = locationName;
    }
    input.placeholder = "Label your Location";
    // Updates the label on the actual shape itself
    input.addEventListener('keyup', _.debounce((event) => {
      if (this.props.editMode === true) {
        const foundShape = shapes.find((group) => group.id === id);
        foundShape.label = event.target.value;
        foundShape.hasChanges = true;
        if (!_.isEmpty(event.target.value) && !_.isEmpty(foundShape.type)) {
          if (foundShape.marker.content.classList.contains("error")) {
            foundShape.marker.content.classList.remove("error");
          }
        } else {
          if (!foundShape.marker.content.classList.contains("error")) {
            foundShape.marker.content.classList.add("error");
          }
        }
        this.updateCanSave();
      }
    }, 500));

    input.addEventListener('keydown', (event) => {
      event.stopPropagation();  
    });

    const select = document.createElement('select');
    select.required = true;
    select.disabled = true;
    select.id = id + '-select';
    select.classList.add("select-field");

    _.forEach(locationTypes, (type) => {
      const option = document.createElement('option');
      option.value = type.value;
      option.text = type.label;
      select.appendChild(option);
    });

    if (!_.isEmpty(locationType)) {
      select.value = locationType;
    }

    select.addEventListener('change', (event) => {
      const foundShape = shapes.find((group) => group.id === id);
      foundShape.type = event.target.value;
      foundShape.hasChanges = true;
      if (!_.isEmpty(event.target.value) && !_.isEmpty(foundShape.label)) {
        if (foundShape.marker.content.classList.contains("error")) {
          foundShape.marker.content.classList.remove("error");
        }
      } else {
        if (!foundShape.marker.content.classList.contains("error")) {
          foundShape.marker.content.classList.add("error");
        }
      }
      this.updateCanSave();
    });
    
    const closeButton = document.createElement('button');
    closeButton.classList.add("close-button");
    closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
    closeButton.id = "closeButton";
    closeButton.addEventListener('click', (event) => {this.closeLocationPopup(id, marker, event, shapes)});

    const deleteButton = document.createElement('button');
    deleteButton.classList.add("delete-button");
    deleteButton.innerHTML =
    '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="17.5" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>'
    deleteButton.id = "deleteButton";
    deleteButton.addEventListener('click', (event) => { this.removeLocation(id, shapes) });

    if (this.props.editMode === true) {
      content.classList.add('edit');
    }

    popupDiv.appendChild(popupIconDiv);

    inputContainer.appendChild(input);
    inputContainer.appendChild(select);

    popupDiv.appendChild(inputContainer);
    popupDiv.appendChild(closeButton)
    popupDiv.appendChild(deleteButton);

    content.appendChild(iconDiv);
    content.appendChild(popupDiv);

    return content;
  }

  showLocationPopup(id, marker) {

    // For all others shapes, close their popups
    _.filter(shapes, (shape) => shape.id !== id).forEach((shape) => {
      if (shape.marker.content.classList.contains('highlight')) {
        shape.marker.content.classList.remove('highlight');
        shape.marker.zIndex = null;
        shape.shape.setEditable(true);
      }
    });

    if (!marker.content.classList.contains('highlight')) {
      marker.content.classList.add('highlight');
      marker.zIndex = 1;
     
      document.querySelector('#'+id+'-input').disabled = false;
      document.querySelector('#'+id+'-select').disabled = false;
      document.querySelector('#'+id+'-input').focus();
    }
  }

  closeLocationPopup(id, marker, event, shapes) {

    if (marker.content.classList.contains('highlight')) {

      // Enable the shape to be sizable again now that the location popup is closed
      let foundShape = _.find(shapes, (shape) => shape.id === id);
      if (!_.isNil(foundShape)) {
        foundShape.shape.setEditable(true);
      }

      document.querySelector('#'+id+'-input').disabled = true;
      document.querySelector('#'+id+'-select').disabled = true;

      marker.content.classList.remove('highlight');
      marker.zIndex = null;
      event.stopPropagation();
    } 
  }

  removeLocation(id, shapes) {

    let foundShape = _.find(shapes, (shape) => shape.id === id);

    if (!_.isNil(foundShape)) {
      foundShape.shape.setMap(null);
      foundShape.marker.setMap(null);
      foundShape.hiddenMarker.setMap(null);
      _.remove(shapes, (shape) => shape.id === id);

      // Only need to persist the delete if the shape has a locationId
      if (!_.isNil(foundShape.locationId)) {
        toDeleteShapes.push(foundShape);
      }

      this.updateCanSave();
    }
  }

  clearShapes() {
    // Clear out the existing shapes...
    _.forEach(shapes, (shape) => {
      shape.shape.setMap(null);
      shape.marker.setMap(null);
      shape.hiddenMarker.setMap(null);
    });
    shapes = [];
    toDeleteShapes = [];
  }

  onCancel() {
    this.clearShapes();

    // ...and reload from the Location service
    this.props.onQueryLocations();
    this.addShapesForLocations();
  }

  addMarkersForAssets() {

    if (!_.isEmpty(this.props.filteredAssets)) {

      _.forEach(this.props.filteredAssets, (asset) => {
        const marker = new google.maps.marker.AdvancedMarkerElement({
          map: map,
          content: this.buildContent(asset),
          position: new google.maps.LatLng(asset.lastKnownCoord.latitude, asset.lastKnownCoord.longitude),
        });

        // A Marker (legacy) has native mouse events other than click
        // Advanced Markers do not
        // So we need to create a hidden marker to handle mouse events
        // Alternatively we can use DOM events when legacy markeres are no longer supported
        const hiddenMarker = new google.maps.Marker({
          map: map,
          position: new google.maps.LatLng(asset.lastKnownCoord.latitude, asset.lastKnownCoord.longitude),
          opacity: 0,
          zIndex: 101,
          optimized: true,
        });

        assetMarkers.push(marker);
        assetHiddenMarkers.push(hiddenMarker);

        assetPositions.push({
          asset: asset,
          position: new google.maps.LatLng(asset.lastKnownCoord.latitude, asset.lastKnownCoord.longitude)
        })

        hiddenMarker.addListener('mouseover', () => {
          this.hiddenMarkerMouseOver(marker);
        });
        hiddenMarker.addListener('mouseout', () => {
          this.hiddenMarkerMouseOut(marker);
        });
      });

      // Setup the clustering for the assets have loaded     
      if (!_.isNil(markerclusterer)) {
        markerclusterer.clearMarkers();
      }

      markerclusterer = new MarkerClusterer({ 
        markers: assetMarkers, 
        map: map, 
        algorithmOptions: { maxZoom: 20 },
        renderer: new ClusterRender(this.buildClusterContent, this.hiddenMarkerMouseOver, this.hiddenMarkerMouseOut)
      });
    
    }
  }

  addShapesForLocations() {
    if (!_.isEmpty(this.props.locations)) {
      _.forEach(this.props.locations, (location) => {

        const shapeId = this.generateShapeId();

        let shape = null;
        let shapeCenter = null;

        // Generate the shape based on geomType
        if (location.geomType === 'Circle') {
          let circleOptions = {
            ...defaultCircleOptions,
            center: new google.maps.LatLng(location.coordinates[0].latitude, location.coordinates[0].longitude),
            radius: location.radius,
            map: map,
            editable: false,
            draggable: false,
          }

          shape = new google.maps.Circle(circleOptions);
          shapeCenter = shape.getCenter();
        } else {          
          let polygonOptions = {
            ...defaultPolygonOptions,
            map: map,
            editable: false,
            draggable: false,
          }

          let polygonPath = [];
          _.forEach(location.coordinates, (coordinate) => {
            polygonPath.push(new google.maps.LatLng(coordinate.latitude, coordinate.longitude));
          });
          polygonOptions.paths = polygonPath;

          shape = new google.maps.Polygon(polygonOptions);
          shapeCenter =  this.getPolygonCenter(shape.getPath());
        }

        this.commonSetupForShapes(shape, shapeId, shapeCenter, location.name, location.type, location.geomType, location.id, false);
      });
    }
  }

  /**
   * Sets up the common elements and event handlers for any shape, regardless of type and if it's new or existing.
   * @param {*} shape The Shape
   * @param {*} shapeId The Shape's Id
   * @param {*} shapeCenter The Shape's Center as LatLng
   * @param {*} name Location Name
   * @param {*} type Location Type
   * @param {*} geomType Type of Shape (Circle or Polygon)
   * @param {*} locationId Location Id if it's already existing
   * @param {*} hasChanges Initial value of the shape's changes
   */
  commonSetupForShapes(shape, shapeId, shapeCenter, name, type, geomType, locationId, hasChanges ) {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: map,
      position: shapeCenter
    });
    marker.content = this.buildLocationsContent(shapeId, marker, shapes, this.props.locationTypes, name, type);

    marker.content.parentElement.parentElement.addEventListener('focusin', (event) => {
      document.querySelector('#'+shapeId+'-input').focus();
    })

    const hiddenMarker = new google.maps.Marker({
        map: (this.props.editMode === true) ? null : map, 
        position: shapeCenter,
        opacity: 0,
        zIndex: 101,
        optimized: true
    });
    hiddenMarker.addListener('mouseover', () => {
        this.hiddenMarkerMouseOver(marker);

    });
    hiddenMarker.addListener('mouseout', () => {
        this.hiddenMarkerMouseOut(marker);
    });

    marker.addListener('click', () => {
      if (this.props.editMode === true) {
        // Disable the shape to be sizable when the location popup is closed due to ui conflicts
        shape.setEditable(false);
        this.showLocationPopup(shapeId, marker);
      }
    });

    shapes.push({ 
      id: shapeId, 
      shape: shape, 
      marker: marker, 
      label: name, 
      hiddenMarker: hiddenMarker, 
      hasChanges: hasChanges, 
      type: type, 
      geomType: geomType, 
      locationId: locationId 
    });

    // Events to handle when the shape is moved
    if (geomType === 'Circle') {
      google.maps.event.addListener(shape, 'center_changed', () => {
        marker.position = shape.getCenter();
        hiddenMarker.position = shape.getCenter();
        this.updateHasChangesForShapeId(shapeId);
      });
      google.maps.event.addListener(shape, 'radius_changed', () => {
        this.updateHasChangesForShapeId(shapeId);
      });
    } else {
      google.maps.event.addListener(shape.getPath(), 'set_at', () => {
        this.updatePolygonCenter(shape, marker, hiddenMarker);
        this.updateHasChangesForShapeId(shapeId);
      });

      google.maps.event.addListener(shape.getPath(), 'mouseup', (event) => {
        this.updateHasChangesForShapeId(shapeId);
      });

      google.maps.event.addListener(shape, 'mouseup', (event) => {
        if (!_.isNil(event.edge)) {
          this.updatePolygonCenter(shape, marker, hiddenMarker);
          this.updateHasChangesForShapeId(shapeId);
        }
      });
    }
  }

  updatePolygonCenter(shape, marker, hiddenMarker) {
    let polygonCenter = this.getPolygonCenter(shape.getPath());
    const markerPosition = new google.maps.LatLng(polygonCenter.lat, polygonCenter.lng);
    marker.position = markerPosition;
    hiddenMarker.position = markerPosition;
  }

  hiddenMarkerMouseOut(marker) {
    if (marker.content.classList.contains("highlight")) {
      marker.content.classList.remove("highlight");
      marker.zIndex = null;
    }
  }

  hiddenMarkerMouseOver(marker) {
    if (!marker.content.classList.contains("highlight")) {
      marker.content.classList.add("highlight");
      marker.zIndex = 1;
    }
  }

  buildContent(asset) {
    const content = document.createElement('div');

    const icon = this.getIconForAsset(asset); 

    if (asset.active === false) {
      content.classList.add("asset-inactive");
    } else {
      content.classList.add("asset");
    }
    content.innerHTML = `
      <div class="icon">
        ${icon}
      </div>
      <div class="popup">
        <div class="icon">
          ${icon}
        </div>
        <div class="details">
          ${asset.active === false ? '' : `<h6>${this.getTitleFromAsset(asset)}</h6>`}
          ${asset.active === false ? '' : `<h5>${asset.truckName}</h5>`}
          <h5>${asset.esn}</h5>
          ${asset.active === false ? '<h5>InActive</h5>' : ''}
        </div>
      </div>
      `;

    return content;
  }

  buildClusterContent(assets, datavans) {
    const content = document.createElement('div');

    content.classList.add("asset");
    content.innerHTML = `
      <div class="clusterIcon">
        <h6>${assets.length}</h6>
      </div>
      <div class="popup">
        <div class="clusterDetails">
          <sub>Datavans:</sub>
          ${
            _.map(datavans, (datavan) => {
              return `<sub>${datavan.asset.truckName}</sub>`
            }).join('')
          }
        </div>
      </div?
      `;

    return content;
  }

  getTitleFromAsset(asset) {
    return getAssetNameByType(asset.unitType);
  }

  getIconForAsset(asset) {
    if (asset.active === false) {
      return '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0zm11.75 11.47l-.11-.11z" fill="none"/><path d="M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z"/></svg>';
    }
    return getAssetIconByAssetName(asset.unitType);
  }

  updateCanSave() {
    const anyShapesMissingRequiredInformation = _.some(shapes, (shape) => { return _.isEmpty(shape.label) || _.isEmpty(shape.type) });
    const anyShapesHaveChanges = _.some(shapes, (shape) => { return shape.hasChanges });
    const anyShapesToDelete = !_.isEmpty(toDeleteShapes);
    this.props.updateCanSave(!anyShapesMissingRequiredInformation && (anyShapesHaveChanges || anyShapesToDelete));
  }

  render() {

    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));
    const { editMode } = this.props;

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Asset Tracking</Typography>
            </Box>
            {!editMode && (
            <>
              <Divider sx={styles.actionsDivider} orientation='vertical' />
              <Box sx={styles.detailsPageHeaderActionsButton}>
                <Tooltip title="Refresh Map">
                  <IconButton
                    onClick={() => this.loadData() }
                    size="large"><RefreshIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={styles.detailsPageHeaderActionsButton}>
                <Box sx={{...styles.pageHeaderButton, paddingLeft: 0 }}>
                  <Tooltip title="Edit Map">
                    <Box>
                      <IconButton
                        onClick={() => { this.props.updateEditMode (true)}}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
              <Divider sx={styles.actionsDivider} orientation='vertical' />
              <Box sx={styles.filterContainer}>
                <Typography variant={'subtitle1'}>FILTER:</Typography>
                <Box sx={styles.filterItems}>
                {
                  this.props.appliedFilters.map((filterContext, index) => {
                    return (
                      <Chip
                        key={index}
                        sx={styles.childComponent}
                        label={filterContext.chipLabel}
                        onDelete={() => this.props.deleteFilter(index)}
                      />
                    )
                  })
                }
                </Box>
                <IconButton
                  onClick={() => this.props.openFilterDialog()}
                  disabled={emptyFilters}
                  sx={styles.childComponent}
                  size="large"><AddCircleIcon/>
                </IconButton>
              </Box>
              <FormControlLabel control={<Switch checked={this.props.showInActive} onChange={(event) => { this.props.onSetShowInActive(event.target.checked)}} />} label="Show InActive Assets" />
              <TextField
                id="input-with-icon-textfield"
                placeholder='Search...'
                type="text"
                value={this.props.searchTerm}
                onChange={(event) => {
                    if (event.target.value === '') {
                        this.props.clearSearch(event.target.value);
                    } else {
                        this.props.updateSearchTerm(event.target.value);
                        this.props.startSearch(event.target.value);
                    }
                }}
                error={this.props.searchNoMatch}
                helperText={this.props.searchNoMatch ? 'No such Asset found' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !_.isEmpty(this.props.searchTerm) && (
                    <IconButton onClick={() => this.props.clearSearch(this.props.searchTerm)} >
                      <ClearIcon />
                    </IconButton>
                  ) 
                }}
                variant="standard"
                sx={{
                  marginLeft: 'auto',
                }}
              />
            </>
            )}

            {editMode && (
            <Box sx={styles.editModePageHeaderActions}>
              <Divider sx={styles.actionsDivider} orientation='vertical' />
              <Box sx={{...styles.editModeActions, margin: '10px'}}>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', marginRight: 2, flexGrow: 0.5, justifyContent: 'flex-end', alignItems: 'center'}}>
                  <Typography variant='caption' sx={{ fontSize: '16px' }} >Add and Edit Locations on the Map</Typography>
                </Box>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 0.5, justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Button
                    sx={{ ...styles.actionButton, marginRight: 2 }}
                    variant='contained'
                    onClick={() => {
                        this.onCancel();
                        this.props.updateEditMode(false);
                    }}
                  >Cancel</Button>

                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        this.props.updateEditMode(false);
                        this.props.onSaveLocations(shapes, toDeleteShapes);
                    }}
                    disabled={!this.props.canSave}
                  >Save</Button>
                </Box>
              </Box>
            </Box>
            )}
          </Box>
          <Box sx={styles.viewContainer}>
            <Box sx={{width: '100%', height: '100%'}} id="map" />
          </Box>
          <Progress open={this.props.queryRunning} />
          <FilterDialog
            stateDef={this.props.stateDef}
            appliedFilterLabels={this.props.appliedFilters.map(f => f.label)}
          />
        </Box>
      </Box>
    )
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.ASSET_TRACKING : props.stateKey,
      type: ComponentTypes.ASSET_TRACKING,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;
  const componentState = assetTrackingState(state[stateDef.key]);

  return {
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    queryRunning: componentState.queryRunning,
    assets: componentState.assets,
    filteredAssets: componentState.filteredAssets,
    searchTerm: componentState.searchTerm,
    searchResults: componentState.searchResults,
    searchNoMatch: componentState.searchNoMatch,
    editMode: componentState.editMode,
    locationTypes: componentState.locationTypes,
    locations: componentState.locations,
    canSave: componentState.canSave,
    refreshTimestamp: componentState.refreshTimestamp,
    showInActive: componentState.showInActive,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
   openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef)); },
   startSearch: _.debounce(() => { dispatch(searchActions.performSearch(props.stateDef)); }, 500),
   clearSearch: (searchTerm) => { dispatch(searchActions.clearSearch(props.stateDef, searchTerm)); },
   deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index)); },
   updateEditMode: (edit) => {dispatch(updateEditMode(props.stateDef, edit)); },
   updateCanSave: (canSave) => {dispatch(updateCanSave(props.stateDef, canSave)); },

   onQueryAssetsForOwner: () => { dispatch(queryAssetData(props.stateDef)); },
   onQueryLocations: () => { dispatch(queryLocations(props.stateDef)); },  
   onSaveLocations: (locations, toDelete) => { dispatch(saveLocations(props.stateDef, locations, toDelete)); },
   updateSearchTerm: (searchTerm) => { dispatch(searchActions.updateSearchTerm(props.stateDef, searchTerm)); },
   onSetShowInActive: _.debounce((showInActive) => { dispatch(setShowInActive(props.stateDef, showInActive)); }, 500)
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(AssetTrackingDisplay));