import _ from 'lodash';

const fetchFleetOperationCardData = (truckId) => {
  return new Promise((resolve) => {

    let totalPumps = 40;
    let numIdle = (_.random(1, 50) % 10 === 0) ? null : _.random(1, 5);
    let numStopped = (_.random(1, 50) % 10 === 0) ? null : _.random(1, 3);
    let numOffline = (_.random(1, 50) % 10 === 0) ? null : _.random(1, 3);
    let numPumping = (_.random(1, 50) % 10 === 0) ? null :
        totalPumps - (_.isNil(numIdle) ? 0 : numIdle) - (_.isNil(numStopped) ? 0 : numStopped) - (_.isNil(numOffline) ? 0 : numOffline);
    if (numPumping < 0) {
      numPumping = 0;
    }

    setTimeout(() => {
      resolve({
        fleetOperationStatus: {
          fleetPumpStatusInfo: {
            count: (_.isNil(numPumping) ? 0 : numPumping) + (_.isNil(numIdle) ? 0 : numIdle) + (_.isNil(numStopped) ? 0 : numStopped) + (_.isNil(numOffline) ? 0 : numOffline),
            pumping: numPumping,
            idle: numIdle,
            stopped: numStopped,
            offline: numOffline,
          },
          suctionData: (_.random(1, 10) % 5 === 0) ? [] : suctionData(totalPumps), // 10% of the time use no data
          hhp: (_.random(1, 10) % 5 === 0) ? [] : hhpData(totalPumps), // 10% of the time use no data
          sandTotal: _.random(1000000, 9000000),
          slurryTotal: _.random(100000, 900000),
        }
      })
    }, 1000)
  });

};

const suctionData = (pumps) => {

  let data = [];
  let i = 0;
  for (i = 0; i < pumps; i++) {
    // Sometimes push an empty object to show an empty slot
    if (_.random(1, 10) % 5 === 0) {
      data.push(null);
    } else {
      data.push(
        _.random(1, 10) % 5 === 0 ? 200 : _.random(1, 200) // 10% of the time use max else random
      );
    }
  }

  return data;

};

const hhpData = (pumps) => {

  let data = [];
  let i = 0;
  for (i = 0; i < pumps; i++) {
    data.push(
      _.random(1, 10) % 5 === 0 ? 2000 : _.random(1, 2000) // 10% of the time use max else random
    );
  }

  return data;

};

export { fetchFleetOperationCardData }