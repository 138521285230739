import { performDisplayFetch } from "../../common/fetch";
import * as sensorSelectorSimulation from "./sensorSelectorSimulation";
import useSimulationData from "../../../helpers/simulationHelper";

const fetchSensorSelectorSensors = (trucks, startTime, endTime, namespace) => {
  return performDisplayFetch({
    "operationName": "SensorForAssets",
    "query": "query sensorForAssets($input:SensorSetInput!) { sensorForAssets(input:$input) { sensorSetId alias uom targetUoms } }",
    "variables": {
      "input": {
        "trucks": trucks,
        "startTime": startTime,
        "endTime": endTime,
        "namespace": namespace
      }
    }
  });

};

const fetchSaveSharedSensorGroup = (sharedSensorGroupInput) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveSharedSensorGroup($sharedSensorGroup:SharedSensorGroupInput!) { saveSharedSensorGroup(sharedSensorGroup:$sharedSensorGroup) { success } }',
      'variables': {
        'sharedSensorGroup': sharedSensorGroupInput
      }
    }
  );
}

const fetchSharedSensorGroups = (unitType, namespace) => {
  if (useSimulationData('sensorSelectorSharedSensorGroups')) {
    return sensorSelectorSimulation.fetchSharedSensorGroups(unitType, namespace);
  }

  return performDisplayFetch({
    "operationName": "SharedSensorGroupsForUnitType",
    "query": "query sharedSensorGroupsForUnitType($unitType:String!, $namespace:SensorNamespace!) { sharedSensorGroupsForUnitType(unitType:$unitType,namespace:$namespace) { id unitType name description sensors { sensorSetId alias uom color axisPosition targetUoms } } }",
    "variables": {
      "unitType": unitType,
      "namespace": namespace
    }
  });
};

const fetchDeleteSharedSensorGroups = (groupId) => {

  if (useSimulationData('sensorSelectorDeleteSharedSensorGroup')) {
    return sensorSelectorSimulation.fetchDeleteSharedSensorGroup(groupId);
  }

  return performDisplayFetch({
    "operationName": "DeleteSharedSensorGroup",
    "query": "mutation DeleteSharedSensorGroup($id:Long!) { deleteSharedSensorGroup(id: $id) { success } }",
    "variables": {
      "id": groupId
    }
  });
};

export { fetchSensorSelectorSensors, fetchSharedSensorGroups, fetchDeleteSharedSensorGroups, fetchSaveSharedSensorGroup }