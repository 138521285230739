import _ from "lodash";
import React, {Component} from 'react';
import { Typography, Paper, Box, Tooltip } from '@mui/material';
import PropTypes from "prop-types";

const styles = {

  tableHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '40px',
    backgroundColor: 'grey.800',

    // borderStyle: 'solid',
    // borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    // borderBottomColor: theme.palette.primary.main,
  },

  tableHeaderColumn: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      paddingLeft: '25px'
    },
  },

  tableRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    minHeight: '26px',
    maxHeight: '30px',
    '&:hover #actionDiv': {
      display: 'block'
    },
  },

  tableHoverWithoutRowClickSupport: {
    '&:hover': {
      backgroundColor: 'grey.800',
    },
  },

  tableHoverWithRowClickSupport: {
    '&:hover': {
      backgroundColor: 'grey.800',
      cursor: 'pointer'
    },
  },

  tableColumn: {
    display: 'flex',
    justifyContent: 'center',
    color: 'text.primary'
  },

  tableActionDiv: {
    display: 'none'
  },

};

/**
 * This is a duplicate of the DataTableControl with the paging and sorting support removed.
 *
 * We tried to use it with the ExplorationChart control but something Redux-
 * related caused the paging to freak out and complain that it didn't have any
 * items, which was false. To save time and frustration before a demo, we
 * decided to go this route; we should revisit this and either fix the bug or
 * make paging optional in the original DataTableControl.
 */
class LegendTable extends Component {

  handleRowClicked(item) {
    if (this.props.rowClickSupport) {
      this.props.rowClicked(this.props.tableContext, item);
    }
  }

  render() {

    const { columns } = this.props;

    let items = this.props.items;

    // Handle the CSS class definition for the row here to keep the markup more readable
    let rowClassNames = [styles.tableRow];
    if (_.isNil(this.props.rowClickSupport) || !this.props.rowClickSupport) {
      rowClassNames.push(styles.tableHoverWithoutRowClickSupport);
    } else {
      rowClassNames.push(styles.tableHoverWithRowClickSupport);
    }

    return (
      <div>

        {/*Render the table header */}
        <Box sx={styles.tableHeader}>
          {
            columns.map((column, index) => {
              return (
                <div key={index}>
                  {/* Rendering for a data and custom column header */}
                  {
                    (column.type === 'data' || column.type === 'custom') &&
                    <Box sx={{...styles.tableHeaderColumn, width: column.width}}>
                      <Typography variant={'subtitle1'}>{column.label}</Typography>
                    </Box>
                  }
                  {/* Rendering for an action column header */}
                  {
                    column.type === 'action' &&
                    <Box sx={{...styles.tableHeaderColumn, width: column.width}}>
                      {/* Empty header column to account for the action rendering in the table rows*/}
                    </Box>
                  }
                </div>
              )
            })
          }
        </Box>

        {/*Render the table rows */}
        <Paper elevation={0}> {/* draws without shadow */}
          {
            items.map((item, index) => {
              return (
                <Box key={index} sx={rowClassNames} onClick={() => this.handleRowClicked(item)} >
                  {
                    columns.map((column, index) => {
                      return (
                        <div key={index}>
                          {/* Rendering for a custom column */}
                          {
                            column.type === 'custom' &&
                            this.props.renderTableCell(this.props.tableContext, column, item)
                          }
                          {/* Rendering for a data column */}
                          {
                            column.type === 'data' &&
                            <Tooltip followCursor title={item[column.displayProperty] || ''}>
                              <Box sx={{...styles.tableColumn, width: column.width, maxWidth: column.width, whiteSpace: 'nowrap'}}>
                                <Typography sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{item[column.displayProperty]}</Typography>
                              </Box>
                            </Tooltip>
                          }
                          {/* Rendering for an action column */}
                          {
                            column.type === 'action' &&
                            <Box sx={{...styles.tableColumn, width: column.width}}>
                              <Box id="actionDiv" sx={styles.tableActionDiv}>
                                { column.renderAction(this.props.tableContext, item) }
                              </Box>
                            </Box>
                          }
                        </div>
                      )
                    })
                  }
                </Box>
              )
            })
          }
        </Paper>

      </div>
    );

  }

}

LegendTable.propTypes = {

  // Properties
  tableContext: PropTypes.any,
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  secondarySortDefinitions: PropTypes.array,
  rowClickSupport: PropTypes.bool,

  // Actions
  rowClicked: PropTypes.func,
  renderTableCell: PropTypes.func,

};

export default LegendTable;
