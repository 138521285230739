import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, DialogContentText, Button } from '@mui/material';

import ComponentTypes from "../../componentTypes";
import { subscriptionsDisplayState } from "../../../state/displays/subscriptionsDisplay/subscriptionsDisplaySelectors";
import * as subscriptionsDisplayActions from '../../../state/displays/subscriptionsDisplay/subscriptionsDisplayActions';
import PropTypes from "prop-types";

class AssignSubscriptionDialog extends Component {

  render() {
    return (
      <Dialog open={this.props.showDialog} disableEscapeKeyDown>
        <DialogTitle>Self-Assign Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>Assign the notification with the following name to yourself?</DialogContentText>
          <Typography>{_.isNil(this.props.subscription) ? '' : this.props.subscription.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.onShowAssignSubscriptionDialog(false, this.props.subscription)}} color='inherit'>Cancel</Button>
          <Button onClick={() => 
          { 
            this.props.onAssignSubscription(this.props.subscription, this.props.currentUser);
            this.props.onShowAssignSubscriptionDialog(false, this.props.subscription);
          }} 
          color="primary">Assign</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

AssignSubscriptionDialog.propTypes = {
  currentUser: PropTypes.string.isRequired,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SUBSCRIPTIONS_DISPLAY : props.stateKey,
      type: ComponentTypes.SUBSCRIPTIONS_DISPLAY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = subscriptionsDisplayState(state[stateDef.key]);
  return {
    showDialog: componentState.showAssignSubscriptionDialog,
    subscription: componentState.subscription,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowAssignSubscriptionDialog: (show, subscription) => dispatch(subscriptionsDisplayActions.showAssignSubscriptionDialog(props.stateDef, show, subscription)),
    onAssignSubscription: (subscription, user) => { dispatch(subscriptionsDisplayActions.assignSubscription(props.stateDef, subscription, user)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(AssignSubscriptionDialog));
