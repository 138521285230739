import reduceReducers from 'reduce-reducers';

import displayReducer from './reducers/displayReducer';
import splitButtonReducer from './reducers/splitButtonReducer';

import { fleetMgmtState } from './fleetMgmtSelectors';

const initialState = fleetMgmtState();

const fleetMgmtReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    displayReducer,
    splitButtonReducer
  )

  return reducer(state, action);
};

export default fleetMgmtReducer;
