import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { routeAssetMgmtState } from "../routeAssetMgmtSelectors";
import * as routeAssetMgmtServices from '../services/routeAssetMgmtServices';

const initialState = routeAssetMgmtState();

const routeAssetMgmtRouteAssetDialogReducer = (state, action) => {
    switch(action.type){
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_STARTING:
            return { ...state, queryRunning: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SAVE_ROUTE_ASSET_SUCCESS:
            return { ...state, queryRunning: false, openAddRouteAssetUI: false, openEditRouteAssetUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_ADD_ROUTE_ASSET_ERROR:
            return { ...state, queryRunning: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_ADD_ROUTE_ASSET_DIALOG:
            return { ...state, openAddRouteAssetUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_ADD_ROUTE_ASSET_DIALOG:
            return { ...state, openAddRouteAssetUI: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_ROUTE_ASSET_CONSUMER_NAME:
            return { 
                ...state, 
                workingRouteAsset: { 
                    ...state.workingRouteAsset, 
                    consumerName: { value: action.consumerName, label: action.consumerName } 
                } 
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_WORKING_TRUCK_NAME:
            return { 
                ...state, 
                workingRouteAsset: { 
                    ...state.workingRouteAsset, 
                    truckName: { value: action.truckName, label: action.truckName } 
                } 
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_EDIT_DIALOG:
            return { ...state, openEditRouteAssetUI: true, workingRouteAsset: routeAssetMgmtServices.processRouteAsset(action.routeAsset) };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_EDIT_DIALOG:
            return { ...state, openEditRouteAssetUI: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_RESET_WORKING_ROUTE_ASSET:
            return {
                ...state,
                workingRouteAsset: initialState.workingRouteAsset
            };
        default: 
            return state;
    }
};

export default routeAssetMgmtRouteAssetDialogReducer;