import { performDisplayFetch } from '../../../common/fetch';
import * as expectedLifeSimulation from './expectedLifeSimulation';
import useSimulationData from '../../../../helpers/simulationHelper';

const fetchExpectedLifeSettings = () => {

  if (useSimulationData('expectedLifeSettings')) {
    return expectedLifeSimulation.fetchExpectedLifeSettings();
  }

  return performDisplayFetch({
    'query': '{ expectedLifeSettings { componentTypes  { id name displayName unitType kind expectedLife } } }'
  });
};

const fetchSaveExpectedLifeSettings = (componentTypes) => {

  if (useSimulationData('expectedLifeSettings')) {
    return expectedLifeSimulation.fetchSaveExpectedLifeSettings(componentTypes);
  }

  return performDisplayFetch({
    'query': 'mutation saveComponentTypes($componentTypeInputs:[ComponentTypeInput]!) { saveComponentTypes(componentTypeInputs:$componentTypeInputs) { id name displayName unitType kind expectedLife } }',
    'variables': {
      'componentTypeInputs': componentTypes
    }
  });
};

export {
  fetchExpectedLifeSettings,
  fetchSaveExpectedLifeSettings
}