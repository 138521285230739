import {performCardFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as unitHoursSimulation from './unitHoursSimulation';

/**
 * Method to perform the remote query for the unit hours card data
 */
const fetchUnitHoursCardData = (truckId) => {

  if (useSimulationData('unitHoursCard')) {
    return unitHoursSimulation.fetchUnitHoursCardData();
  }

  return performCardFetch({
    'query': '{ unitHours(truckId:{truckId}) { ecmHours pumpHours tcmHours warmStart { warmStartState data { type warmStartHrsReduced warmStartFuelSavings warmStartEmissionReduced } } } }'.replace('{truckId}', truckId)
  })
};

export default fetchUnitHoursCardData