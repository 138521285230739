import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';
import CategoryIcon from '@mui/icons-material/Category';
import { Typography, Box, IconButton, Grid, Icon } from '@mui/material';
import ComponentTypes from '../../componentTypes';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';

import componentLifeCardChartOptions from './componentLifeCardChartOptions';
import { componentLifeState } from '../../../state/cards/componentLife/componentLifeSelectors';
import * as componentLifeActions from '../../../state/cards/componentLife/componentLifeActions';
import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';
import { MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes,mdtCardMapStateToProps } from "../mdtCard/mdtCard";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,

  chartLabel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '125px',
  },
  chartSubLabel: {
    width: '125px',
  },
  chartTotalLabel: {
    display: 'flex',
    width: '50px',
    justifyContent: 'flex-end',
  },
  chartRowActionButton: {
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      color: 'grey.500'
    }
  },
  chart: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '100%',
  },
  chartRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  chartWithTotalLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  chartWithTotalLabelAndNavButton: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  chartNoDataHolder: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '185px'
  },
  chartNoDataTotalLabel: {
    width: '50px'
  },
  chartNoDataNavButton: {
    width: '48px'
  },
  noDataHolder: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    flexGrow: 1
  },
  cardContent: {
    marginTop: '3px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-evenly',
  },
};

class ComponentLife extends MDTCard {

  getName() {
    return 'COMPONENT LIFE';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck.id);
    }
  }

  getRenderedContentFullWidth(visibleComponents) {
    return visibleComponents.map((component, index) => {
      return (
          <Box key={index} sx={styles.chartRow}>
            <Box sx={styles.chartLabel}>
              <Typography sx={styles.chartLabel} variant={'subtitle1'}>{component.displayName}</Typography>
              <Typography sx={styles.chartSubLabel} variant={'caption'}>{component.model}</Typography>
            </Box>
            <Box sx={styles.chartWithTotalLabelAndNavButton}>
              {
                !(_.isNull(component.primaryLife.value)) &&
                <Box sx={styles.chartWithTotalLabel}>
                  <Box sx={styles.chart}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={componentLifeCardChartOptions(
                            _.isNil(component.primaryLife)
                                ? null
                                : component.primaryLife.value,
                            component.expectedLife
                        )}
                    />
                  </Box>
                  <Box sx={styles.chartTotalLabel}>
                    <Typography variant={'body2'}>{component.expectedLife}</Typography>
                  </Box>
                </Box>
              }
              {
                _.isNull(component.primaryLife.value) &&
                <Box sx={styles.chartNoDataHolder}>
                  <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
                </Box>
              }
              {
                _.isNull(component.primaryLife.value) &&
                <Box sx={styles.chartNoDataTotalLabel} />
              }
              {
                !this.isInEditMode() &&
                <IconButton
                    sx={styles.chartRowActionButton}
                    onClick={() => this.props.navigateToComponentsDisplay(this.props.context.truck.id, component.type)}
                    size="large">
                  <CategoryIcon />
                </IconButton>
              }
            </Box>
          </Box>
      );
    })
  }

  getRenderedContentLessWidth(visibleComponents) {
    return visibleComponents.map((component, index) => {
      let primaryLife = _.isNil(component.primaryLife) ? null : component.primaryLife.value;
      let chartOption = componentLifeCardChartOptions(primaryLife, component.expectedLife);
      return (
        <Grid container alignItems={'center'} key={index} sx={{
          ...styles.chartRow, minHeight: '50px', paddingLeft: '5px', paddingRight: '5px', alignItems: 'center'
        }}>
          <Grid item sx={{...styles.chartLabel, width: '125px', height: '100%'}}>
            <Typography sx={styles.chartLabel} variant={'subtitle1'}>{component.displayName}</Typography>
            <Typography sx={styles.chartSubLabel} variant={'caption'}>{component.model}</Typography>
          </Grid>
          { _.isNull(component.primaryLife.value) &&
            <Grid item sx={{...styles.chartNoDataHolder, width: '150px', height: '100%'}}>
              <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
            </Grid>
          }
          { !_.isNull(component.primaryLife.value) &&
            <>
            <Grid item sx={{...styles.chartTotalLabel, width: '75px', height: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
              <Typography variant={'body2'} sx={{color: chartOption.series[0].data[0].color}}>
                {primaryLife}
              </Typography>
            </Grid>
            <Grid item sx={{...styles.chartTotalLabel, width: '75px', height: '100%', alignItems: 'center', justifyContent: 'flex-start'}}>
              <Typography variant={'body2'}>
                 / {component.expectedLife}
              </Typography>
            </Grid>
            </>
          }
          <Grid item sx={{width: '30px', height: '100%', paddingTop: '2px'}}>
            <IconButton
                sx={styles.chartRowActionButton}
                onClick={() => this.props.navigateToComponentsDisplay(this.props.context.truck.id, component.type)}
                disabled={this.isInEditMode()}
                size="small">
              { !this.isInEditMode() && <CategoryIcon/> }
              { this.isInEditMode() && <Icon/> }
            </IconButton>
          </Grid>
        </Grid>
      );
    })
  }

  getRenderedContent() {
    let visibleComponents = _.filter(this.props.components, ['isVisible', true]);
    if (this.props.card.h === 2) {
      visibleComponents = _.take(visibleComponents, 6);
    }
    const isEvenlySpaced = this.props.card.configuration?.isEvenlySpaced ?? true;

    return (
      <Box>
        { !_.isEmpty(visibleComponents) &&
          <Box sx={{
            ...styles.cardContent,
            height: this.props.card.h === 3 ? '450px' : '290px',
            justifyContent: isEvenlySpaced === true ? 'space-evenly' : 'justify-start',
          }}>
            { this.props.card.w === 3 && this.getRenderedContentFullWidth(visibleComponents) }
            { this.props.card.w === 2 && this.getRenderedContentLessWidth(visibleComponents) }
          </Box>
        }
        { _.isEmpty(visibleComponents) &&
          <Box sx={{...styles.noDataHolder, height: this.props.card.h === 3 ? '450px' : '290px'}}>
            <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
          </Box>
        }
      </Box>
    );
  }
}

ComponentLife.propTypes =  mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENT_LIFE : props.stateKey,
      type: ComponentTypes.COMPONENT_LIFE,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentLifeState(state[stateDef.key]);

  return {
    ...mdtCardMapStateToProps(state, props),
    components: componentState.components
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(componentLifeActions.onQueryData(props.stateDef, context, props.dashboard, props.view, props.cardKey)) },
    clearData: () => { dispatch(componentLifeActions.clearData(props.stateDef)) },
    navigateToComponentsDisplay: (unitId, componentType) => { dispatch(componentsActions.navigate(unitId, componentType)) },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(ComponentLife))