import {performCardFetch} from '../../common/fetch'

/**
 * Method to perform the remote query for the fleet pump status card data
 */
const fetchFleetPumpStatusCardData = (fleetId) => {
  return performCardFetch({
    'query': '{ fleetPumpStatus(fleetId:{fleetId}) { count pumping idle stopped offline  } }'.replace('{fleetId}', fleetId)
  })
};

export default fetchFleetPumpStatusCardData