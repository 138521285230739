import { performDisplayFetch } from '../../common/fetch';

const fetchExportCsv = (filename, csv) => {

  return performDisplayFetch({
    'query': 'mutation startDownload($downloadRequest: DownloadInput!) { startDownload(downloadRequest: $downloadRequest) { downloadId downloadUrl } }',
    'variables': {
      'downloadRequest': {
        'filename': filename,
        'data': csv
      }
    }
  });

};

export {
  fetchExportCsv
}


