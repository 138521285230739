import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@mui/material';
import ComponentTypes from "../../componentTypes";
import {customersDisplayState} from "../../../state/displays/customersDisplay/customersDisplaySelectors";
import * as customersDisplayActions from "../../../state/displays/customersDisplay/customersDisplayActions";
import * as customersDisplayAction from "../../../state/displays/customersDisplay/customersDisplayActions";

class CustomerDeleteConfirmationDialog extends Component {
    render() {
        return (
            <Dialog open={this.props.openDeleteConfirmation} disableEscapeKeyDown>
                <DialogTitle>Delete Customer</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete the customer with the following name?</DialogContentText>
                    <Typography>{this.props.customerToDelete.name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.closeDeleteConfirmation()
                    }} color='inherit'>Cancel</Button>
                    <Button onClick={() => {
                        this.props.confirmCustomerDelete(this.props.customerToDelete.id)
                    }} color="primary">Delete</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.CUSTOMERS_DISPLAY : props.stateKey,
            type: ComponentTypes.CUSTOMERS_DISPLAY,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    let componentState = customersDisplayState(state[stateDef.key]);
    return {
        openDeleteConfirmation: componentState.openDeleteConfirmation,
        customerToDelete: componentState.customerToDelete
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeDeleteConfirmation: () => {
            dispatch(customersDisplayActions.onCloseDeleteConfirmation(props.stateDef))
        },
        confirmCustomerDelete: (customerId) => {
            dispatch(customersDisplayAction.onConfirmCustomerDelete(props.stateDef, customerId));
        }
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(CustomerDeleteConfirmationDialog))
