import _ from 'lodash';
import { filterState } from './filterSelectors';
import filterTypes from "../../../components/common/filterTypes";

const initialState = filterState();

//List of filters/labels support multi options selection
// each filter under structure of { filterLabel : chipPrefix }
const multiSelectionFilters = [
    {filterLabel: "Fleets", chipPrefix: "Fleet"},
];

const createFilterDefinition = (label, property) => {
  return { label: label, evaluationType: 'property', property: property };
};

const createExpressionFilterDefinition = (label, expressionDefinitions ) => {
  return { label: label, evaluationType: 'expression', expressionDefinitions: expressionDefinitions };
};

const createExpressionDefinition = (label, expression) => {
  return { label: label, expression: expression }
};

const chipLabelParser = (label, value) => {
  let chipPrefix = label;
  if(multiSelectionFilters.map(f => f.filterLabel).includes(label)) {
    chipPrefix = multiSelectionFilters.find(f => f.filterLabel === label).chipPrefix;
  }
  return chipPrefix + ': ' + value;
};

const createPropertyFilterValue = (label, property, value) => {
  return {
    type: multiSelectionFilters.map(f => f.filterLabel).includes(label)? filterTypes.multiFilterValue: filterTypes.filterValue, // type 'multiFilterValue' for filters support multi selections
    label: value,
    chipLabel: chipLabelParser(label, value),
    property: property,
    evaluation: [property, value],
  }
};

/**
 * Filter list items based on provided filters
 * @param items list of items to filter
 * @param filters filters applied on list items, which includes filters support single and multi selections.
 * @returns {string[]|*} list items applied all filters
 */
const filterItems = (items, filters) => {

  if (_.isNil(filters) || _.isEmpty(filters)) {
    return items;
  }

  let filteredItems = items;

  let multiSelectionList = [];
  let evaluationField;
  filters.forEach(filter => {
    if(filter.type === filterTypes.multiFilterValue){
      //When type "multiFilterValue" filter applied,
      // need to separate it from other filter type and apply different filter logic
      multiSelectionList.push(filter.evaluation[1]);
      evaluationField = filters.find(f => f.type === filterTypes.multiFilterValue).evaluation[0]; // support single "multiFilterValue" filter as per current requirement
    }else{
      //case when no "multiFilterValue" filter applied
      filteredItems = _.filter(filteredItems, filter.evaluation);
    }
  });

  // filter based on "multiFilterValue" filter
  if(!_.isEmpty(multiSelectionList)){
    filteredItems = _.filter(filteredItems, function(i){
      return multiSelectionList.includes(i[evaluationField]);
    });
  }

  return filteredItems;
};

/**
 * Filter list items based on provided filters, type "multiFilterValue" filters is skipped in this function to get result filtered list
 * This function makes sure filter options not selected still show for multi selections filter
 * @param items list of items to filter
 * @param filters filters applied on list items, note it should contain filter with type "multiFilterValue"
 * @returns {*} list items applied all other filters except for type "multiFilterValue" filters
 */
const filterItemsSkipMultiFilterValue = (items, filters) => {

  if (_.isNil(filters) || _.isEmpty(filters) || !filters.some(f => f.type === filterTypes.multiFilterValue)) {
    return items;
  }

  let filteredItems = items;
  filters.forEach(filter => {
    if(filter.type !== filterTypes.multiFilterValue){ //not apply multiFilterValue type filters
      filteredItems = _.filter(filteredItems, filter.evaluation);
    }
  });

  return filteredItems;

};


const generateFilters = (items, filterDefinitions, itemsSkipMultiFilterValue) => {

  let filters = [];

  if (!_.isNil(items) && !_.isEmpty(items)) {

    // Pre-populate the filters with the information from the filter definitions.
    filterDefinitions.forEach(filterDefinition => {
      filters.push({...filterDefinition, type:filterTypes.filterType, filterValues: [] });
    });

    // Process any property filters

    // Iterate the item list, we only want to do this once.
    items.forEach(item => {
      // For each items in the list process the filters
      filters.forEach(filter => {
        // Does this item have this filter property and have we already added it?
        if (!_.isNil(item[filter.property]) && !filter.filterValues.find(value => value.label === item[filter.property])) {
          filter.filterValues.push(createPropertyFilterValue(filter.label, filter.property, item[filter.property]));
        }
      });
    });

    // When itemsSkipMultiFilterValue is not null it means multi selection filter invoke,
    // then it needs to update options based on itemsSkipMultiFilterValue (items not filtered by multiFilterValue),
    // so that it can show the full list (selected/not selected) of items for multi selection filter.
    if(!_.isNil(itemsSkipMultiFilterValue)){
      const multiFilter = filters.find(f => multiSelectionFilters.map(f => f.filterLabel).includes(f.label));
      filters[filters.indexOf(multiFilter)].filterValues = itemsSkipMultiFilterValue.map(i =>
          createPropertyFilterValue(multiFilter.label, multiFilter.property, i[multiFilter.property])
      );
    }

    // Remove any property filters that have 1 or less items since these items cannot be filtered down any further
    filters = _.filter(filters, filter => { return (filter.evaluationType !== 'property' || filter.filterValues.length > 1) });

    // Process any expression filters
    let expressionFilters = _.filter(filters, ['evaluationType', 'expression']);
    expressionFilters.forEach(filter => {
      filter.expressionDefinitions.forEach(expressionDefinition => {
        // Check to see if this filter is valid for the current items. That is the expression must reduce
        // the item count, but not to nothing.
        let hits = _.filter(items, expressionDefinition.expression).length;
        if (hits > 0 && items.length !== hits) {
          filter.filterValues.push({
            type: filterTypes.filterValue,
            label: expressionDefinition.label,
            chipLabel: chipLabelParser(filter.label, expressionDefinition.label),
            evaluation: expressionDefinition.expression,
          });
        }
      });
    });

    // Remove any expression filters that do not have any filter values
    filters = _.filter(filters, filter => { return (filter.evaluationType !== 'expression' || filter.filterValues.length > 0) });

    // Ensure the remaining filter have sorted values
    filters.forEach(filter => {
      filter.filterValues = _.sortBy(filter.filterValues, [filterValue => filterValue.label.toLowerCase()]);
    });
  }

  return filters;
};

/**
 * Update our filter list based on the current list of filtered items.
 * To make sure all options (selected/unsSelected) available to show on GUI for multi selection support:
 * If filters contains type "multiFilterValue" filter, the updatedFilters list should not filter out any options by "multiFilterValue" filter
 */
const updateFilteredItems = (filters, items, filteredItems, filterDefinitions) => {
  let updatedFilters;
  if(!_.isEmpty(filters) && filters.some(f => f.type === filterTypes.multiFilterValue)){
    // The filteredItems Skip "MultiFilterValue" filters only used to generate updatedFilters; it should not impact filtered items to show on GUI
    const filteredItemsSkipMultiFilterValue = !_.isEmpty(items) ? filterItemsSkipMultiFilterValue(items, filters) : [];
    updatedFilters = generateFilters(filteredItems, filterDefinitions, filteredItemsSkipMultiFilterValue);
  } else {
    updatedFilters = generateFilters(filteredItems, filterDefinitions);
  }
  return updatedFilters;
}

const onOpenFilterDialog = (state, action) => {
  return {
    ...state,
    openFilterUI: true,
    currentFilters: state.filters,
    displayedFilters: state.filters,
    searchValue: initialState.searchValue,
  };
};

const onCloseFilterDialog = (state, action) => {
  return {
    ...state,
    openFilterUI: false,
  };
};

const onAddFilter = (state, action) => {

  // No filter? Just return the old state
  if (_.isNil(action.filter)) {
    return state;
  }

  // Just a filter navigation? Update the UI with the filter values.
  if (action.filter.type === filterTypes.filterType) {
    return {
      ...state,
      currentFilters: action.filter.filterValues,
      displayedFilters: action.filter.filterValues,
      searchValue: initialState.searchValue,
    };
  }

  // New filter selection, add it to the list
  let updatedFilters = [...state.appliedFilters];
  updatedFilters.push(action.filter);

  return {
    ...state,
    openFilterUI: action.filter.type === filterTypes.multiFilterValue, // should not close filter dialog right away after selecting one options of multi selections filter
    appliedFilters: updatedFilters,
  };
};

const onDeleteFilter = (state, action) => {

  // No filter index? Just return the old state
  if (_.isNil(action.index) || action.index < 0) {
    return state;
  }

  // Remove filter from list
  let updatedFilters = [...state.appliedFilters];
  updatedFilters.splice(action.index, 1);

  return {
    ...state,
    appliedFilters: updatedFilters,
  };
};

const onShowFilterTypes = (state, action) => {
  return {
    ...state,
    currentFilters: state.filters,
    displayedFilters: state.filters,
    searchValue: initialState.searchValue,
  };
};

const onSearchFilterValues = (state, action) => {

  let searchValue = _.isNil(action.searchValue) ? '' : action.searchValue.toLowerCase();
  let displayedFilters = _.filter(state.currentFilters, item => _.includes(item.label.toLowerCase(), searchValue));

  return {
    ...state,
    searchValue: searchValue,
    displayedFilters: displayedFilters
  };
};

export {
  createFilterDefinition,
  createExpressionFilterDefinition,
  createExpressionDefinition,
  createPropertyFilterValue,
  filterItems,
  generateFilters,
  onOpenFilterDialog,
  onCloseFilterDialog,
  onAddFilter,
  onDeleteFilter,
  onShowFilterTypes,
  onSearchFilterValues,
  filterItemsSkipMultiFilterValue,
  updateFilteredItems
}