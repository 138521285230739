export default {
    COIL_DASHBOARD_QUERY_DATA_STARTING: 'COIL_DASHBOARD_QUERY_DATA_STARTING',
    COIL_DASHBOARD_QUERY_DATA_SUCCESS: 'COIL_DASHBOARD_QUERY_DATA_SUCCESS',
    COIL_DASHBOARD_QUERY_DATA_ERROR: 'COIL_DASHBOARD_QUERY_DATA_ERROR',
    COIL_DASHBOARD_SELECT_CONTEXT: 'COIL_DASHBOARD_SELECT_CONTEXT',
    COIL_DASHBOARD_SELECT_NAVIGATION_CONTEXT: 'COIL_DASHBOARD_SELECT_NAVIGATION_CONTEXT',

    COIL_DASHBOARD_QUERY_DATA_AGE_STARTING: 'COIL_DASHBOARD_QUERY_DATA_AGE_STARTING',
    COIL_DASHBOARD_QUERY_DATA_AGE_SUCCESS: 'COIL_DASHBOARD_QUERY_DATA_AGE_SUCCESS',
    COIL_DASHBOARD_QUERY_DATA_AGE_ERROR: 'COIL_DASHBOARD_QUERY_DATA_AGE_ERROR',
    COIL_DASHBOARD_DATA_AGE_CLEAR_DATA: 'COIL_DASHBOARD_DATA_AGE_CLEAR_DATA',
    COIL_DASHBOARD_DATA_AGE_REFRESH_RELATIVE: 'COIL_DASHBOARD_DATA_AGE_REFRESH_RELATIVE'
};