import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import { handleError } from '../../app/actions/appErrorActions';
import { exportCsvFactory } from '../../common/exportCsv/exportCsvActions';

import alarmsActionTypes from './alarmsActionTypes';
import * as userConfigActions from '../../app/actions/appUserConfigActions';
import * as alarmsQueries from './alarmsQueries';
import * as navActions from '../../app/actions/appNavActions';

import errorMessages from '../../common/errorMessages';

const USER_CONFIG_NAME_ALARM_DISPLAY = 'alarmsDisplay';

const showActiveAlarms = defaultActionFactory(alarmsActionTypes.ALARMS_SHOW_ACTIVE, 'stateDef');
const showAlarmHistory = defaultActionFactory(alarmsActionTypes.ALARMS_SHOW_HISTORY, 'stateDef');
const setTimeFrame = defaultActionFactory(alarmsActionTypes.ALARMS_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const changeMessageAlarms = defaultActionFactory(alarmsActionTypes.ALARMS_SHOW_MESSAGE_ALARMS, 'stateDef', 'excludeMessageAlarms');
const setCustomStartTime = defaultActionFactory(alarmsActionTypes.ALARMS_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(alarmsActionTypes.ALARMS_SET_CUSTOM_DURATION, 'stateDef', 'duration');
const handleNavigationContext = defaultActionFactory(alarmsActionTypes.ALARMS_HANDLE_NAVIGATION_CONTEXT, 'stateDef', 'navigationContext');

const queryAlarmsStarting = defaultActionFactory(alarmsActionTypes.ALARMS_QUERY_STARTING, 'stateDef');
const queryAlarmsSuccess = defaultActionFactory(alarmsActionTypes.ALARMS_QUERY_SUCCESS, 'stateDef', 'queryResults', 'activeOnly');
const queryAlarmsError = defaultActionFactory(alarmsActionTypes.ALARMS_QUERY_ERROR, 'stateDef');

const queryAlarms = (stateDef, activeOnly, startTime, endTime) => {
  return async (dispatch, getState) => {
    // Check if there is a navigation context for this display in the app state

    let navigationState = getState().app.navigation.context;
    let navigationContext = _.isNil(navigationState['/alarms']) ? null : navigationState['/alarms'];
    if (!_.isNil(navigationContext)) {
      // Clear the app state if we find a navigation context
      await dispatch(navActions.setNavigationContext('/alarms', null));
      // Handle the navigation context
      await dispatch(handleNavigationContext(stateDef, navigationContext));
    }

    //Query user configuration
    let excludeMessageAlarms = true;
    try {
      const userId = getState().app.user.userId;
      const queryMessageAlarmResult = await dispatch(userConfigActions.queryUserConfigurationForPage(stateDef, userId, USER_CONFIG_NAME_ALARM_DISPLAY));
      
      if (!_.isEmpty(queryMessageAlarmResult)){
        const excludeMessageAlarmsFromConfiguration = queryMessageAlarmResult.find(config => typeof config === 'object' && config.hasOwnProperty('excludeMessageAlarms'))?.excludeMessageAlarms;
        excludeMessageAlarms = _.isNil(excludeMessageAlarmsFromConfiguration) ? excludeMessageAlarms : excludeMessageAlarmsFromConfiguration;
      }
      if (!excludeMessageAlarms){
         await dispatch(changeMessageAlarms(stateDef, excludeMessageAlarms));
      }
      
    } catch (error) {
      await dispatch(queryAlarmsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_CONFIG_FOR_PAGE, error.message));
    }

    //Query the alarm details
    let queryResults = null;
    try {
      await dispatch(queryAlarmsStarting(stateDef));
      if (activeOnly === true) {
        queryResults = await alarmsQueries.fetchActiveAlarmDetails();
      } else {
        queryResults = await alarmsQueries.fetchAlarmHistoryDetails(startTime, endTime);
      }
    } catch (e) {
      await dispatch(queryAlarmsError(stateDef));
      return dispatch(handleError(activeOnly === true ? errorMessages.ERROR_RETRIEVING_ACTIVE_ALARMS : errorMessages.ERROR_RETRIEVING_ALARM_HISTORY, e.message));
    }
    await dispatch(queryAlarmsSuccess(stateDef, queryResults, activeOnly));
  };
};

const exportCsv = exportCsvFactory(
  'alarms',
  [
    { name: 'Unit ID', property: 'unitName' },
    { name: 'Severity', property: 'severityName' },
    { name: 'Category', property: 'category' },
    { name: 'Details', property: 'details' },
    { name: 'Source', property: 'type' },
    { name: 'State', property: 'state' },
    { name: 'Timestamp', property: 'displayValueTimestamp' },
    { name: 'Fleet', property: 'fleetName' },
  ]
);

const navigateByTruck = (truckName) => {
  return async (dispatch, getState) => {
    const url = '/alarms';
    const context = { unitName: truckName };
    return dispatch(navActions.navigate(url, context));
  };
};

const navigateByFleet = (fleetName) => {
  return async (dispatch, getState) => {
    const url = '/alarms';
    const context = { fleetName: fleetName };
    return dispatch(navActions.navigate(url, context));
  };
};

const showMessageAlarms = (stateDef, config) => {
  return async (dispatch, getState) => {
    try { 
      const userId = getState().app.user.userId;
      if (!_.isEmpty(userId)) {
      const payload = [{ excludeMessageAlarms: config }];
      await dispatch(userConfigActions.saveUserConfigurationForPage(stateDef, userId, USER_CONFIG_NAME_ALARM_DISPLAY, payload));
      }
    } catch (error) {
      await dispatch(queryAlarmsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_USER_CONFIG_FOR_PAGE), error.message);
    }
     await dispatch(changeMessageAlarms(stateDef, config));
  }
};

const setCustomStartTimeDisplay = defaultActionFactory(alarmsActionTypes.ALARMS_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');

export {
  showActiveAlarms,
  showAlarmHistory,
  showMessageAlarms,
  queryAlarms,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  exportCsv,
  navigateByTruck,
  navigateByFleet,
  setCustomStartTimeDisplay,
};
