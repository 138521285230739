import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null
  };

  let LockupSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.LOCKUP]);
  transformedSeries.series1 = unitChartService.transformSeries(LockupSeries);

  let transTempSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.TRANS_TEMP]);
  transformedSeries.series2 = unitChartService.transformSeries(transTempSeries, 1);

  let engineRpmSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.ENGINE_RPM]);
  transformedSeries.series3 = unitChartService.transformSeries(engineRpmSeries);

  return transformedSeries;
};


export {
  transformSeries,
}