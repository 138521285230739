import React, {Component} from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Progress from '../../../../../controls/progress';
import FracFleetLayout from '../../../layouts/fracFleetLayout';
import EmptyUnit from '../../../visuals/emptyUnit';
import EmptyDatavan from '../../../visuals/emptyDatavan';
import Pump from '../../../visuals/componentView/pump';
import Blender from '../../../visuals/componentView/blender';
import Chemvan from '../../../visuals/componentView/chemvan';
import Hydration from '../../../visuals/componentView/hydration';
import Datavan from '../../../visuals/componentView/datavan';

import fleetMapStyles from '../../../fleetMapStyles';

import ComponentTypes from '../../../../../componentTypes';
import * as fracFleetMapActions from '../../../../../../state/displays/fleetMap/maps/frac/fracFleetMapActions';
import fleetMapViews from '../../../../../../state/displays/fleetMap/display/fleetMapViews';
import { fracFleetMapState } from '../../../../../../state/displays/fleetMap/maps/frac/fracFleetMapSelectors';
import * as pumpDashboardActions from '../../../../../../state/displays/pumpDashboard/pumpDashboardActions';
import NoData from '../../../visuals/noData';
import messages from '../../../../../common/messages';
import { evaluateHasMoreThan20Pumps } from '../../fleetMapHelper';
import { trackPage } from '../../../../../../helpers/googleAnalyticsHelper';
import { appState as applicationState } from '../../../../../../state/app/appSelectors';

const styles = {
  ...fleetMapStyles,
};

class FracFleetComponentView extends Component {

  constructor(props) {
    super(props);
    this.renderPump = this.renderPump.bind(this);
    this.renderBlender = this.renderBlender.bind(this);
    this.renderHydration = this.renderHydration.bind(this);
    this.renderChemvan = this.renderChemvan.bind(this);
    this.renderDatavan = this.renderDatavan.bind(this);
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.FLEET_DASHBOARD + '/' + ComponentTypes.COMPONENT_VIEW, this.props.user);
    
    if (!_.isNil(this.props.fleet)) {
      this.props.queryFracFleetMapDetails(this.props.fleet.id, fleetMapViews.COMPONENT);
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.fleet !== this.props.fleet && !_.isNil(this.props.fleet)) {
      this.props.queryFracFleetMapDetails(this.props.fleet.id, fleetMapViews.COMPONENT);
    }
  }

  renderPump(slot, isTopRow) {
    let pumpData = _.find(this.props.pumps, {'slot': slot});
    let visual = <EmptyUnit/>;
    if (!_.isNil(pumpData)) {
      visual = pumpData.empty === true ? <EmptyUnit/> : <Pump data={pumpData} navigateToDashboard={this.props.navigateToPumpDashboard}/>;
    }
    return visual;
  }

  renderBlender(blender) {
    let blenderData = blender === 1 ? this.props.blender1 : this.props.blender2;
    let visual = <EmptyUnit/>;
    if (!_.isNil(blenderData)) {
      visual = blenderData.empty === true ? <EmptyUnit/> : <Blender data={blenderData}/>;
    }
    return visual;
  }

  renderHydration() {
    let visual = <EmptyUnit/>;
    if (!_.isNil(this.props.hydration)) {
      visual = this.props.hydration.empty === true ? <EmptyUnit/> : <Hydration data={this.props.hydration}/>;
    }
    return visual;
  }

  renderChemvan() {
    let visual = <EmptyUnit/>;
    if (!_.isNil(this.props.chemvan)) {
      visual = this.props.chemvan.empty === true ? <EmptyUnit/> : <Chemvan data={this.props.chemvan}/>;
    }
    return visual;
  }

  renderDatavan() {
    let visual = <EmptyDatavan/>;
    if (!_.isNil(this.props.datavan)) {
      visual = this.props.datavan.empty === true ? <EmptyDatavan/> : <Datavan data={this.props.datavan}/>;
    }
    return visual;
  }

  evaluateNoData() {
    return ( _.isNil(this.props.blender1) && _.isNil(this.props.blender2) && _.isNil(this.props.hydration) &&
        _.isNil(this.props.chemvan) && _.isNil(this.props.datavan) && _.isEmpty(this.props.pumps) && (!this.props.queryRunning));
  }

  render() {

    const hasNoData = this.evaluateNoData();
    const hasMoreThan20Pumps = evaluateHasMoreThan20Pumps(this.props.pumps);

    return (
      <Box sx={styles.fleetMapLayoutWithNoDataWrapper}>
        <FracFleetLayout
          renderPump={this.renderPump}
          renderBlender={this.renderBlender}
          renderHydration={this.renderHydration}
          renderChemvan={this.renderChemvan}
          renderDatavan={this.renderDatavan}
          hasNoData={hasNoData}
          moreThan20Pumps={hasMoreThan20Pumps}
        />
        {
          (hasNoData) &&
          <NoData textToDisplay={messages.NO_DATA_FLEET_MAP}/>
        }
        <Progress open={this.props.queryRunning}/>
      </Box>
    )
  }
}

FracFleetComponentView.propTypes = {
  fleet: PropTypes.object,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FRAC_FLEET_MAP_VIEW : props.stateKey,
      type: ComponentTypes.FRAC_FLEET_MAP_VIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fracFleetMapState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    queryRunning: componentState.queryRunning,
    blender1: componentState.blender1,
    blender2: componentState.blender2,
    hydration: componentState.hydration,
    chemvan: componentState.chemvan,
    datavan: componentState.datavan,
    pumps: componentState.pumps,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryFracFleetMapDetails: (fleetId, view) => { dispatch(fracFleetMapActions.queryFracFleetMapDetails(props.stateDef, fleetId, view)) },
    navigateToPumpDashboard: (unitId) => { dispatch(pumpDashboardActions.navigate(unitId)) }
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FracFleetComponentView))