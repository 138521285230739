import React, {Component} from "react";
import {
    Box,
    Button,
    IconButton,
    Typography
} from "@mui/material";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import _ from "lodash";
import ComponentTypes from "../../componentTypes";
import RefreshIcon from "@mui/icons-material/Refresh";
import Progress from "../../controls/progress";
import getDetailsPageStyles from "../../common/styles/detailsPageStyles";
import {
    AddCircle
} from "@mui/icons-material";
import * as wellsDisplayAction from "../../../state/displays/wellsDisplay/wellsDisplayActions";
import {wellsDisplayState} from "../../../state/displays/wellsDisplay/wellsDisplaySelectors";
import {appState} from "../../../state/app/appSelectors";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import MDTDataGrid from "../../common/table/MDTDataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from '@mui/icons-material/Edit'
import WellDeleteConfirmationDialog from "./wellDeleteConfirmationDialog";
import SaveWellDialog from "./saveWellDialog";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
    ...detailsPageStyles,
    detailsPageContent: {
        ...detailsPageStyles.detailsPageContent,
        width: '80vw',
    },
    detailsPageHeaderActions: {
        ...detailsPageStyles.detailsPageHeaderActions,
        justifyContent: 'flex-start'
    },
    innerTable: {
        width: '100%',
    }
};

class WellsDisplay extends Component {

    constructor(props) {
        super(props);
        // Generate the columns data for the data table.
        this.columns = this.generateTableColumns();
    }

    componentDidMount() {
        this.props.onQueryData();
    }

    generateTableColumns() {
        let columns = [];
        columns.push({
            field: 'name',
            headerName: 'Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });
        columns.push({
            field: 'apiNumber',
            headerName: 'API Number',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false
        });
        columns.push({
            field: "leaseName",
            headerName: "Lease Name",
            headerAlign: "center",
            align: "center",
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'leaseNumber',
            headerName: 'Lease Number',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'padName',
            headerName: 'Pad Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false
        });
        columns.push({
            field: 'latitude',
            headerName: 'Latitude',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'longitude',
            headerName: 'Longitude',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'Actions',
            align: 'center',
            resizable: false,
            hideable: false,
            disableReorder: true,
            type: 'actions',
            getActions: (params) => {
                const actions = [];
                actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'}/>}
                                                  onClick={() => 
                                                    this.props.onSaveWellDialogOpen(params.row)}
                                                  label="Edit" showInMenu/>);
                actions.push(<GridActionsCellItem
                    icon={<DeleteForeverIcon fontSize={'small'}/>}
                    label="Delete"
                    showInMenu
                    onClick={() => { this.props.onWellDelete(params.row); }}
                />);
                return actions;
            }
        });
        return columns;
    }
    render() {
        return (<Box sx={styles.detailsPage}>
            <Box sx={styles.detailsPageContent}>
                <Box sx={styles.detailsPageHeader}>
                    <Box sx={{...styles.detailsPageHeaderTitle, width:"100px"}}>
                        <Typography variant={'h6'}>Wells</Typography>
                    </Box>
                    <Box sx={styles.detailsPageHeaderActions}>
                        <IconButton
                            onClick={() => {
                                this.props.onQueryData();
                            }}
                            size="large">
                            <RefreshIcon/>
                        </IconButton>
                        <Button onClick={() => this.props.onSaveWellDialogOpen({})} 
                                color={'inherit'}>
                            <AddCircle sx={{marginRight: '5px'}}/>
                            ADD WELL
                        </Button>
                    </Box>
                </Box>
                <Box sx={styles.mdtDataGridContainer}>
                    <MDTDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                        }}
                        columns={this.columns}
                        rows={this.props.wells}
                        disableSelectionOnClick
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                                csvOptions: {
                                    fileName: "wells",
                                },
                            },
                        }}
                        stateDef={this.props.stateDef}
                        rowsPerPage={this.props.rowsPerPage}
                        setRowsPerPage={this.props.setRowsPerPage}
                        rowsPerPageOptions={this.props.rowsPerPageOptions}
                    />
                </Box>
            </Box>
            <Progress open={this.props.queryRunning}/>
            <WellDeleteConfirmationDialog />
            <SaveWellDialog/>
        </Box>);
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.WELLS_DISPLAY : props.stateKey,
            type: ComponentTypes.WELLS_DISPLAY,
        }
    };
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props
    let applicationState = appState(state);
    const componentState = wellsDisplayState(state[stateDef.key]);

    return {
        user: applicationState.user,
        queryRunning: componentState.queryRunning,
        wells: componentState.wells,
        rowsPerPage: componentState.rowsPerPage,
        rowsPerPageOptions: componentState.rowsPerPageOptions,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        setRowsPerPage: (rowSize) => dispatch(wellsDisplayAction.setRowsPerPage(props.stateDef, rowSize)),
        onQueryData: () =>  dispatch(wellsDisplayAction.queryWell(props.stateDef)),
        onSaveWellDialogOpen: (wellForSave) => dispatch(wellsDisplayAction.onSaveWellDialogOpen(props.stateDef, wellForSave)),
        onSaveWellDialogClose: () => dispatch(wellsDisplayAction.onSaveWellDialogClose(props.stateDef)),
        onWellDelete: (wellToDelete) => dispatch(wellsDisplayAction.onWellDelete(props.stateDef, wellToDelete))
    };
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(WellsDisplay));