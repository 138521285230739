import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import userMgmtActionTypes from '../userMgmtActionTypes';

import { fetchDeleteUser } from '../userMgmtQueries';
import { handleError } from '../../../app/actions/appErrorActions';
import errorMessages from '../../../common/errorMessages';

import { queryUsers } from './userMgmtDisplayActions';

const closeDeleteConfirmation = defaultActionFactory(userMgmtActionTypes.USER_MGMT_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteUserStarting = defaultActionFactory(userMgmtActionTypes.USER_MGMT_DELETE_USER_STARTING, 'stateDef');
const deleteUserSuccess = defaultActionFactory(userMgmtActionTypes.USER_MGMT_DELETE_USER_SUCCESS, 'stateDef', 'userId');
const deleteUserError = defaultActionFactory(userMgmtActionTypes.USER_MGMT_DELETE_USER_ERROR, 'stateDef');

const deleteUser = (stateDef, userId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(deleteUserStarting(stateDef));
      await fetchDeleteUser(userId);
      await dispatch(deleteUserSuccess(stateDef, userId));
    } catch (e) {
      await dispatch(deleteUserError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_DELETING_USER, e.message));
    }
  }
};

export {
  closeDeleteConfirmation,
  deleteUserStarting,
  deleteUserSuccess,
  deleteUserError,
  deleteUser,
}