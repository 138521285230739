import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withProps } from "recompose";
import { Typography, Box, Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { gridClasses } from "@mui/x-data-grid-pro";
import UserMgmtUserDialog from './userMgmtUserDialog';
import Progress from '../../controls/progress';
import UserMgmtDeleteConfirmationDialog from './userMgmtDeleteConfirmationDialog';
import moment from 'moment';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import MDTDataGrid from '../../common/table/MDTDataGrid';

import * as userMgmtDisplayActions from '../../../state/displays/userMgmt/actions/userMgmtDisplayActions';
import { userMgmtState } from '../../../state/displays/userMgmt/userMgmtSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';
import ComponentTypes from "../../componentTypes";

import { trackPage } from '../../../helpers/googleAnalyticsHelper';
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  exportAnchor: {
    visibility: 'hidden'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden'
    },
    [`& .${gridClasses.row}:hover .MuiDataGrid-actionsCell`]: {
      visibility: 'visible'
    },
  },
  addUserButton: {
    marginLeft: '5px',
    display: 'flex',
  },
  addUserIcon: {
    marginRight: '8px',
  },
  rowActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  tableActions: {
    height: '64px',
    width: '100%',
  },
  iconButton: {
    padding: 0,
  },
  dateText: {
    flex: 1,
    textAlign: 'center',
  },
};

class UserMgmtPage extends Component {

  constructor(props) {
    super(props);
    this.columns = this.generateTableColumns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryUsers();
    }
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.USER_MANAGEMENT, this.props.user);

    this.props.queryUsers();
  }

  generateTableColumns() {
    let columns = [];
    columns.push({
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 255,
      editable: false,
      hideable: false,
      pinnable: false
    });
    columns.push({
      field: 'email',
      headerName: 'Email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 160,
      editable: false
    });
    columns.push({
      field: 'isEnabled',
      headerName: 'Enabled',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 250,
      editable: false,
      renderCell: (params) => params.value ? <CheckIcon sx={{ color: green[500] }}/> : <CloseIcon sx={{ color: red[500] }} />
    });
    columns.push({
      field: 'isAdmin',
      headerName: 'Admin',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false,
      renderCell: (params) => params.value ? <CheckIcon sx={{ color: green[500] }}/> : <CloseIcon sx={{ color: red[500] }} />
    });
    columns.push({
      field: 'lastLogin',
      headerName: 'Last Login',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false,
      renderCell: (params) => {
        const lastLogin = moment.unix(params.value);
        return (
          <Box sx={styles.rowActions}>
            <Typography variant="body2" sx={styles.dateText}>
              {params.value ? (lastLogin.isValid() ? lastLogin.format('YYYY-MM-DD HH:mm:ss') : '') : ''}
            </Typography>
          </Box>
        );
      }
    });
    columns.push({
      field: 'Actions',
      align: 'center',
      resizable: false,
      hideable: false,
      disableReorder: true,
      type: 'actions',
      getActions: (params) => {
        const actions = [];
        actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'}/>}
                                          onClick={() => this.props.openEditDialog(params.row)}
                                          label="Edit" showInMenu/>);
        {
          params.row?.isEnabled &&
          actions.push(<GridActionsCellItem icon={<CloseIcon fontSize={'small'}/>}
                                            onClick={() => this.props.setActiveState(params.row, false)}
                                            label="Disable" showInMenu/>);
        }
        {
          !params.row?.isEnabled &&
          actions.push(<GridActionsCellItem icon={<CheckIcon fontSize={'small'}/>}
                                          onClick={() => this.props.setActiveState(params.row, true)}
                                          label="Enable" showInMenu/>);
        }
        actions.push(<GridActionsCellItem icon={<DeleteForeverIcon fontSize={'small'}/>}
                                          onClick={() => this.props.openDeleteConfirmation(params.row)}
                                          label="Delete" showInMenu/>);
        return actions;
      }
    });
    return columns;
  }

  render() {
    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Users</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <Button onClick={() => { this.props.openAddDialog() }} color={'inherit'} sx={styles.addUserButton}>
                <PersonAddIcon sx={styles.addUserIcon} />
                ADD USER
              </Button>
            </Box>
          </Box>
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                }
              }}
              columns={this.columns}
              rows={this.props.users}
              disableSelectionOnClick
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { 
                    disableToolbarButton: true 
                  }
                } 
              }}
              stateDef={this.props.stateDef}
              rowsPerPage={this.props.usersPerPage}
              setRowsPerPage={this.props.setUsersPerPage}
              setCurrentPage={this.props.setCurrentPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </Box>
        </Box>

        {/* Form for adding and editing users */}
        <UserMgmtUserDialog />

        {/* Form for delete confirmation */}
        <UserMgmtDeleteConfirmationDialog />

        {/* Progress UI for remote queries*/}
        <Progress open={this.props.queryRunning} />

      </Box>
    );
  }
}

UserMgmtPage.propTypes = {
  width: PropTypes.string.isRequired,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.USER_MANAGEMENT : props.stateKey,
      type: ComponentTypes.USER_MANAGEMENT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = userMgmtState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    users: componentState.users,
    usersPerPage: componentState.usersPerPage,
    currentPage: componentState.currentPage,
    sortDirection: componentState.sortDirection,
    sortContext: componentState.sortContext,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryUsers: () => dispatch(userMgmtDisplayActions.queryUsers(props.stateDef)),
    setUsersPerPage: (usersPerPage) => dispatch(userMgmtDisplayActions.setUsersPerPage(props.stateDef, usersPerPage)),
    setCurrentPage: (currentPage) => dispatch(userMgmtDisplayActions.setCurrentPage(props.stateDef, currentPage)),
    openAddDialog: () => dispatch(userMgmtDisplayActions.openAddDialog(props.stateDef)),
    setActiveState: (user, isActive) => dispatch(userMgmtDisplayActions.setActiveState(props.stateDef, user, isActive)),
    openEditDialog: (user) => dispatch(userMgmtDisplayActions.openEditDialog(props.stateDef, user)),
    openDeleteConfirmation: (user) => dispatch(userMgmtDisplayActions.openDeleteConfirmation(props.stateDef, user)),
  }
};

export default compose(
  withWidth(),
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UserMgmtPage));
