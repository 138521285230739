import moment from "moment";

const fetchCustomers = () => {

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        customers:
        [
          {
            id: 1,
            ownerId: 1,
            name: 'DEVON ENERGY CORP',
            location: null,
            note: null,
            active: null,
            contactName: 'Darren',
            email: null,
            phone: null
          },
          {
            id: 2,
            ownerId: 1,
            name: 'Customer2',
            location: null,
            note: null,
            active: null,
            contactName: 'Some One',
            email: null,
            phone: null
          },
          {
            id: 3,
            ownerId: 1,
            name: 'Customer5',
            location: null,
            note: null,
            active: null,
            contactName: 'AAA',
            email: null,
            phone: null
          },
          {
            id: 4,
            ownerId: 1,
            name: 'Customer6',
            location: null,
            note: null,
            active: null,
            contactName: 'BBB',
            email: null,
            phone: null
          },
          {
            id: 5,
            ownerId: 1,
            name: 'Customer 20',
            location: null,
            note: null,
            active: null,
            contactName: 'ContactTwo',
            email: null,
            phone: null
          },
          {
            id: 6,
            ownerId: 1,
            name: 'Customer 99',
            location: null,
            note: null,
            active: null,
            contactName: 'Contact99',
            email: null,
            phone: null
          },
          {
            id: 7,
            ownerId: 1,
            name: 'DEVON ENERGY CORP',
            location: null,
            note: null,
            active: null,
            contactName: 'ContactOne',
            email: null,
            phone: null
          },
        ]
      })
    }, 1000)
  });
};


const fetchJobsForTimeFrame = (timeFrame) => {

  let jobs = []
  let last7Days = 
  [
    {
      id: 1,
      jobName: 'J-8229',
      jobType: 'Frac',
      datavan: 'DVS',
      customerId: 10,
      customerName: 'DEVON ENERGY CORP',
      contactName: 'Darren',
      startDate: moment().subtract(7, 'days').unix()*1000,
      endDate: moment().subtract(5, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 1,
          wellId: 7,
          apiNumber: '4230135611',
          wellName: 'CBR 8-5B-56-1 - 332H',
          pad: 'CBR 8-5B-56-1',
          leaseName: 'CBR 8-5B-56-1',
          leaseNumber: '',
          plannedStages: 5
        }
      ]
    },
    {
      id: 2,
      jobName: 'J-8230',
      jobType: 'Frac',
      datavan: 'DVS',
      customerId: 10,
      customerName: 'DEVON ENERGY CORP',
      contactName: 'Darren',
      startDate: moment().subtract(6, 'days').unix()*1000,
      endDate: moment().add(1, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 1,
          wellId: 8,
          apiNumber: '4230135612',
          wellName: 'CBR 8-5C-56-1 - 333H',
          pad: 'CBR 8-5C-56-1',
          leaseName: 'CBR 8-5C-56-1',
          leaseNumber: '',
          plannedStages: 5
        },
        {
          jobId: 2,
          wellId: 9,
          apiNumber: '4230135615',
          wellName: 'CBR 8-5G-56-1 - 322H',
          pad: 'CBR 8-5G-56-1',
          leaseName: 'CBR 8-5G-56-1',
          leaseNumber: '',
          plannedStages: 3
        }
      ]
    },
    {
      id: 3,
      jobName: 'J-8231',
      jobType: 'Wireline',
      datavan: 'DVS',
      customerId: 10,
      customerName: 'DEVON ENERGY CORP',
      contactName: 'Darren',
      startDate: moment().add(5, 'days').unix()*1000,
      endDate: moment().add(10, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 3,
          wellId: 10,
          apiNumber: '4230135616',
          wellName: 'CBR 8-5H-56-1 - 336H',
          pad: 'CBR 8-5H-56-1',
          leaseName: 'CBR 8-5H-56-1',
          leaseNumber: '',
          plannedStages: 2
        }
      ]
    },
  ];

  let last30Days = _.cloneDeep(last7Days);
  last30Days.push(
    {
      id: 4,
      jobName: 'Customer job-9980',
      jobType: 'Pumpdown',
      datavan: 'DVS_01',
      customerId: 5,
      customerName: 'Customer5',
      contactName: 'AAA',
      startDate: moment().subtract(28, 'days').unix()*1000,
      endDate: moment().subtract(20, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 4,
          wellId: 3,
          apiNumber: '003',
          wellName: 'WELL 03',
          pad: 'PAD 03',
          leaseName: 'LEASE NAME 03',
          leaseNumber: '0003',
          plannedStages: 10
        }
      ]
    },
    {
      id: 5,
      jobName: 'Customer job-8800',
      jobType: 'Pumpdown',
      datavan: 'DVS_02',
      customerId: 6,
      customerName: 'Customer6',
      contactName: 'BBB',
      startDate: moment().subtract(10, 'days').unix()*1000,
      endDate: moment().subtract(8, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 1,
          wellId: 1,
          apiNumber: '001',
          wellName: 'WELL 01',
          pad: 'PAD 01',
          leaseName: 'LEASE NAME 01',
          leaseNumber: '0001',
          plannedStages: 3
        }
      ]
    },
  );

  let last60Days = _.cloneDeep(last30Days);
  last60Days.push(
    {
      id: 6,
      jobName: 'job-0001',
      jobType: 'Pumpdown',
      datavan: 'MOD10011',
      customerId: 2,
      customerName: 'Customer2',
      contactName: 'Some Two',
      startDate: moment().subtract(58, 'days').unix()*1000,
      endDate: moment().subtract(55, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 99,
          wellId: 4,
          apiNumber: '099aaa',
          wellName: 'WELL 99',
          pad: 'PAD 99',
          leaseName: 'LEASE NAME 99',
          leaseNumber: '0099',
          plannedStages: 3
        }
      ]
    },
    {
      id: 7,
      jobName: 'job-0002',
      jobType: 'Frac',
      datavan: 'MOD10022',
      customerId: 2,
      customerName: 'Customer2',
      contactName: 'Some Two',
      startDate: moment().subtract(31, 'days').unix()*1000,
      endDate: moment().subtract(15, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 88,
          wellId: 5,
          apiNumber: '088bbb',
          wellName: 'WELL 88',
          pad: 'PAD 88',
          leaseName: 'LEASE NAME 88',
          leaseNumber: '0088',
          plannedStages: 30
        }
      ]
    },
    {
      id: 8,
      jobName: 'job-0003',
      jobType: 'Pumpdown',
      datavan: 'MOD10033',
      customerId: 2,
      customerName: 'Customer2',
      contactName: 'Some Two',
      startDate: moment().subtract(45, 'days').unix()*1000,
      endDate: moment().subtract(40, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 77,
          wellId: 6,
          apiNumber: '077ccc',
          wellName: 'WELL 77',
          pad: 'PAD 77',
          leaseName: 'LEASE NAME 77',
          leaseNumber: '0077',
          plannedStages: 5
        }
      ]
    },
  );

  let last90Days = _.cloneDeep(last60Days);
  last90Days.push(
    {
      id: 9,
      jobName: 'job-1111',
      jobType: 'Pumpdown',
      datavan: 'DV11111',
      customerId: 20,
      customerName: 'Customer 20',
      contactName: 'ContactTwo',
      startDate: moment().subtract(61, 'days').unix()*1000,
      endDate: moment().subtract(58, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 99,
          wellId: 4,
          apiNumber: '099aaa',
          wellName: 'WELL 99',
          pad: 'PAD 99',
          leaseName: 'LEASE NAME 99',
          leaseNumber: '0099',
          plannedStages: 3
        }
      ]
    },
    {
      id: 10,
      jobName: 'job-1112',
      jobType: 'Frac',
      datavan: 'DV11122',
      customerId: 20,
      customerName: 'Customer 20',
      contactName: 'ContactTwo',
      startDate: moment().subtract(89, 'days').unix()*1000,
      endDate: moment().subtract(85, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 88,
          wellId: 5,
          apiNumber: '088bbb',
          wellName: 'WELL 88',
          pad: 'PAD 88',
          leaseName: 'LEASE NAME 88',
          leaseNumber: '0088',
          plannedStages: 10
        }
      ]
    }
  );
  
  let planned = [];
  planned.push(
    {
      id: 11,
      jobName: 'job-55555',
      jobType: 'Pumpdown',
      datavan: 'DV991199',
      customerId: 99,
      customerName: 'Customer 99',
      contactName: 'Contact99',
      startDate: moment().add(10, 'days').unix()*1000,
      endDate: moment().add(15, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 111,
          wellId: 6,
          apiNumber: '077ccc',
          wellName: 'WELL 77',
          pad: 'PAD 77',
          leaseName: 'LEASE NAME 77',
          leaseNumber: '00077',
          plannedStages: 10
        }
      ]
    },
    {
      id: 12,
      jobName: 'W-55588',
      jobType: 'Wireline',
      datavan: 'DV991199',
      customerId: 99,
      customerName: 'Customer 99',
      contactName: 'Contact99',
      startDate: moment().add(1, 'days').unix()*1000,
      endDate: moment().add(3, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 111,
          wellId: 5,
          apiNumber: '088bbb',
          wellName: 'WELL 88',
          pad: 'PAD 88',
          leaseName: 'LEASE NAME 88',
          leaseNumber: '00088',
          plannedStages: 10
        }
      ]
    }
  );

  let active = [];
  active.push(
    {
      id: 2,
      jobName: 'Customer job-4567',
      jobType: 'Pumpdown',
      datavan: 'DVS_02',
      customerId: 1,
      customerName: 'Customer2',
      contactName: 'Some One',
      startDate: moment().subtract(6, 'days').unix()*1000,
      endDate: moment().add(1, 'days').unix()*1000,
      wells:
      [
        {
          jobId: 1,
          wellId: 1,
          apiNumber: '001',
          wellName: 'WELL 01',
          pad: 'PAD 01',
          leaseName: 'LEASE NAME 01',
          leaseNumber: '0001',
          plannedStages: 5
        },
        {
          jobId: 2,
          wellId: 2,
          apiNumber: '002',
          wellName: 'WELL 02',
          pad: 'PAD 01',
          leaseName: 'LEASE NAME 02',
          leaseNumber: '0002',
          plannedStages: 3
        }
      ]
    }
  );

  if (timeFrame === 'Last 7 Days') {
    jobs = last7Days;
  }
  else if (timeFrame === 'Last 30 Days') {
    jobs = last30Days;
  }
  else if (timeFrame === 'Last 60 Days') {
    jobs = last60Days;
  }
  else if (timeFrame === 'Last 90 Days') {
    jobs = last90Days;
  }
  else if (timeFrame === 'Planned') {
    jobs = planned;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          plannedJobs: jobs
        })
      }, 1000)
    });
  }
  else if (timeFrame === 'Active') {
    jobs = active;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          activeJobs: jobs
        })
      }, 1000)
    });
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        jobsForTimeFrame: jobs
      })
    }, 1000)
  });
};

const fetchJobMetadata = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        jobMetadataForOwner: 
        {
          datavans: [
            {
              id: 1,
              truckPid: 1,
              truckName: "DVS_01",
            },
            {
              id: 2,
              truckPid: 2,
              truckName: "DVS_02",
            },
            {
              id: 3,
              truckPid: 3,
              truckName: "DVS_02",
            },
            {
              id: 4,
              truckPid: 4,
              truckName: "MOD10011",
            },
            {
              id: 5,
              truckPid: 5,
              truckName: "MOD10022",
            },
            {
              id: 6,
              truckPid: 6,
              truckName: "MOD10033",
            },
            {
              id: 7,
              truckPid: 7,
              truckName: "DV11111",
            },  
            {
              id: 8,
              truckPid: 8,
              truckName: "DV11122",
            },
            {
              id: 9,
              truckPid: 9,
              truckName: "DV991199",
            },
            {
              id: 10,
              truckPid: 10,
              truckName: "DVS",
            }
          ],
          jobTypes: [
            {
              jobTypeId: 1,
              jobType: 'Frac'
            },
            {
              jobTypeId: 2,
              jobType: 'Pumpdown'
            },
            {
              jobTypeId: 3,
              jobType: 'Wireline'
            },
            {
              jobTypeId: 4,
              jobType: 'Cement'
            },
            {
              jobTypeId: 5,
              jobType: 'Coil Tubing'
            }
          ],
          wells: [
            {
              id: 1,
              name: 'WELL 01',
              apiNumber: '001',
              padName: 'PAD 01',
              leaseName: 'LEASE NAME 01',
              leaseNumber: '0001',
              latitude: null,
              longitude: null
            },
            {
              id: 2,
              name: 'WELL 02',
              apiNumber: '002',
              padName: 'PAD 01',
              leaseName: 'LEASE NAME 02',
              leaseNumber: '0002',
              latitude: null,
              longitude: null
            },
            {
              id: 3, 
              name: 'WELL 03',
              apiNumber: '003',
              padName: 'PAD 03',
              leaseName: 'LEASE NAME 03',
              leaseNumber: '0003',
              latitude: null,
              longitude: null
            },
            {
              id: 4,
              name: 'WELL 99',
              apiNumber: '099aaa',
              padName: 'PAD 99',
              leaseName: 'LEASE NAME 99',
              leaseNumber: '0099',
              latitude: null,
              longitude: null
            },
            {
              id: 5,
              name: 'WELL 88',
              apiNumber: '088bbb',
              padName: 'PAD 88',
              leaseName: 'LEASE NAME 88',
              leaseNumber: '0088',
              latitude: null,
              longitude: null
            },
            {
              id: 6,
              name: 'WELL 77',
              apiNumber: '077ccc',
              padName: 'PAD 77',
              leaseName: 'LEASE NAME 77',
              leaseNumber: '0077',
              latitude: null,
              longitude: null
            },
            {
              id: 7,
              name: 'CBR 8-5B-56-1 - 332H',
              apiNumber: '4230135611',
              padName: 'CBR 8-5B-56-1',
              leaseName: 'CBR 8-5B-56-1',
              leaseNumber: '',
              latitude: null,
              longitude: null
            },
            {
              id: 8,
              name: 'CBR 8-5C-56-1 - 333H',
              apiNumber: '4230135612',
              padName: 'CBR 8-5C-56-1',
              leaseName: 'CBR 8-5C-56-1',
              leaseNumber: '',
              latitude: null,
              longitude: null
            },
            {
              id: 9,
              name: 'CBR 8-5G-56-1 - 322H',
              apiNumber: '4230135615',
              padName: 'CBR 8-5G-56-1',
              leaseName: 'CBR 8-5G-56-1',
              leaseNumber: '',
              latitude: null,
              longitude: null
            },
            {
              id: 10,
              name: 'CBR 8-5H-56-1 - 336H',
              apiNumber: '4230135616',
              padName: 'CBR 8-5H-56-1',
              leaseName: 'CBR 8-5H-56-1',
              leaseNumber: '',
              latitude: null,
              longitude: null
            }
          ]
        }
        
      })
    }, 1000)
  });
}

export {
  fetchCustomers,
  fetchJobsForTimeFrame,
  fetchJobMetadata
}