import React, {Component} from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import {connect} from 'react-redux';

import Transition from '../../controls/dialogSlideTransition';
import { Dialog } from '@mui/material';
import ClientStatusDetails from './clientStatusDetails';

import ComponentTypes from '../../componentTypes';
import * as clientStatusActions from '../../../state/displays/clientStatus/clientStatusActions';
import { clientStatusState } from '../../../state/displays/clientStatus/clientStatusSelectors';

const styles = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
};

class ClientStatusDetailsDialog extends Component {

  render() {
    return (
      <Dialog PaperProps={{sx: styles.dialogPaper }} maxWidth={'lg'} open={this.props.openDetailsUI} onClose={() => this.props.closeDetailsDialog()} TransitionComponent={Transition}>
        {/* We wrap the dialog content in a separate component for performance reasons. The dialog will ensure */}
        {/* the child component is lazy loaded preventing the content from being added to the DOM until needed. */}
        {/* This also allows us to load the details from the server in the on mounted callback in the child */}
        {/* component. */}
        <ClientStatusDetails/>
      </Dialog>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.CLIENT_STATUS : props.stateKey,
      type: ComponentTypes.CLIENT_STATUS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = clientStatusState(state[stateDef.key]);
  return {
    openDetailsUI : componentState.openDetailsUI,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeDetailsDialog: () => { dispatch(clientStatusActions.closeDetailsDialog(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ClientStatusDetailsDialog))