import _ from 'lodash';

const fleetOperationState = state => {

  const noValue = '-';

  return {
    // The card metrics
    pumps: _.isNil(state) ?
    {
      count: '-',
      pumping: { display: noValue, raw: null },
      idle:  { display: noValue, raw: null },
      stopped: { display: noValue, raw: null },
      offline: { display: noValue, raw: null },
    } : state.pumps,
    suction:   _.isNil(state) ? [] : state.suction,
    hhp: _.isNil(state) ? [] : state.hhp,
    totalSand: _.isNil(state) ? '-' : state.totalSand,
    totalSlurry: _.isNil(state) ? '-' : state.totalSlurry,
  };

};

export {
  fleetOperationState
};