import _ from 'lodash';
import pumpOnlyFleetMapActionTypes from '../pumpOnlyFleetMapActionTypes';
import fleetMapViews from '../../../display/fleetMapViews';
import * as operationViewService from '../services/operationViewService';
import * as componentViewService from '../services/componentViewService';
import * as healthViewService from '../services/healthViewService';

const queryReducer = (state, action) => {

  switch (action.type) {

    case pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_STARTING:
      return onQueryDetailsStarting(state, action);

    case pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_SUCCESS:
      return onQueryDetailsSuccess(state, action);

    case pumpOnlyFleetMapActionTypes.PUMP_ONLY_FLEET_MAP_QUERY_DETAILS_ERROR:
      return onQueryDetailsError(state, action);

    default:
      return state;

  }
};

const  onQueryDetailsStarting = (state, action) => {

  // IMPORTANT - We are returning a new (cleared) state here on purpose. We need to clear
  // the state so the visuals are not rendering against old or incompatible data.

  return {
    queryRunning: true,
  };
};

const onQueryDetailsSuccess = (state, action) => {

  if (_.isNil(action.view)) {
    return state;
  }

  let newState = null;

  switch (action.view) {
    case fleetMapViews.OPERATION:
      newState = operationViewService.processDetails(action.queryResults);
      break;
    case fleetMapViews.COMPONENT:
      newState = componentViewService.processDetails(action.queryResults);
      break;
    case fleetMapViews.HEALTH:
      newState = healthViewService.processDetails(action.queryResults);
      break;
    default:
      break;
  }

  return {
    ...newState,
    queryRunning: false,
  };

};

const  onQueryDetailsError = (state, action) => {

  // IMPORTANT - We are returning a new (cleared) state here on purpose. We need to clear
  // the state so the visuals are not rendering against old or incompatible data.

  return {
    queryRunning: false,
  };
};

export default queryReducer