import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CirclePicker } from "react-color";
import { Box } from "@mui/system";
import { mdtPaletteChartingColors } from "../styles/mdtPalette";
import { IconButton } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";

const COLOR_PICKER_CIRCLE_SIZE = 28;
const COLOR_PICKER_CIRCLE_SPACING = 14;
const COLOR_PICKER_COLUMNS = 4;
const COLOR_PICKER_WIDTH = COLOR_PICKER_COLUMNS * (COLOR_PICKER_CIRCLE_SIZE + COLOR_PICKER_CIRCLE_SPACING);

const styles = {
    colorPickerPopover: {
        minWidth: COLOR_PICKER_WIDTH + (56) + 'px', // == COLOR_PICKER_WIDTH + (7 x theme.spacing()) == COLOR_PICKER_WIDTH + (7 x 8px)
        position: 'relative',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: 'background.paper',
        borderRadius: '20px',
        borderStyle: 'solid',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'background.default',
        zIndex: 2,
    },
};

{/* DOC - The color picker popup is displayed upon clicking on the item's color   */ }
{/* button. The picker will change the item's color on hovering over any color    */ }
{/* and closes when a color is clicked.                                           */ }
{/* The colorPickerState stores the original color but does not current use it.   */ }

class MdtColorPicker extends Component {
    render() {
        return (
            <>
                {this.props.showColorPicker ? (
                    <Box sx={{ ...styles.colorPickerPopover, ...this.props.popoverStyles }}
                        onMouseLeave={() => {
                            // reset to last permanent color and close the picker
                            this.props.onConfigChangedColor(this.props.id, this.props.origColor);
                            this.props.onSetColorPickerState(null, null);
                        }}>
                        <CirclePicker
                            onSwatchHover={(color, event) => {
                                event.currentTarget.title = '';
                                // temporarily update the color
                                this.props.onConfigChangedColor(this.props.id, color.hex);
                            }}
                            onChange={(color) => {
                                // permanently update the color and close the picker
                                this.props.onConfigChangedColor(this.props.id, color.hex);
                                this.props.onSetColorPickerState(null, null);
                            }}
                            backgroundcolor={'background.paper'}
                            circleSize={COLOR_PICKER_CIRCLE_SIZE}
                            circleSpacing={COLOR_PICKER_CIRCLE_SPACING}
                            width={COLOR_PICKER_COLUMNS * (COLOR_PICKER_CIRCLE_SIZE + COLOR_PICKER_CIRCLE_SPACING)}
                            color={this.props.color}
                            colors={mdtPaletteChartingColors()}
                        />
                    </Box>
                ) : (
                    <IconButton
                        onClick={() => this.props.onSetColorPickerState(this.props.id, this.props.color)}
                        sx={this.props.colorPreviewStyles}
                        size="large">
                        <FiberManualRecord colorRendering={this.props.color} style={{ color: this.props.color }} />
                    </IconButton>
                )}
            </>
        );
    }
}

MdtColorPicker.propTypes = {
    showColorPicker: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    origColor: PropTypes.string,
    onSetColorPickerState: PropTypes.func.isRequired,
    onConfigChangedColor: PropTypes.func.isRequired,
    popoverStyles: PropTypes.object,
    colorPreviewStyles: PropTypes.object,
};

export default MdtColorPicker;