import _ from 'lodash';

const fracFleetMapState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    blender1: _.isNil(state) ? null : state.blender1,
    blender2: _.isNil(state) ? null : state.blender2,
    hydration: _.isNil(state) ? null : state.hydration,
    chemvan: _.isNil(state) ? null : state.chemvan,
    datavan: _.isNil(state) ? null : state.datavan,
    wellHead: _.isNil(state) ? null : state.wellHead,
    pumps: _.isNil(state) ? null : state.pumps,
    sand1: _.isNil(state) ? null : state.sand1,
    sand2: _.isNil(state) ? null : state.sand2,

  };

};

export {
  fracFleetMapState
};