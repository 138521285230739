import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';

import { pumpOnlyFleetMapState } from './pumpOnlyFleetMapSelectors';

const initialState = pumpOnlyFleetMapState();

const pumpOnlyFleetMapReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
  );

  return reducer(state, action);
};

export default pumpOnlyFleetMapReducer
