export default {

  QUERY_DATA_STARTING: 'QUERY_DATA_STARTING',
  QUERY_DATA_SUCCESS: 'QUERY_DATA_SUCCESS',
  QUERY_DATA_ERROR: 'QUERY_DATA_ERROR',

  QUERY_DATA_FOR_OWNER_STARTING: 'QUERY_DATA_FOR_OWNER_STARTING',
  QUERY_DATA_FOR_OWNER_SUCCESS: 'QUERY_DATA_FOR_OWNER_SUCCESS',
  QUERY_DATA_FOR_OWNER_ERROR: 'QUERY_DATA_FOR_OWNER_ERROR',

  SET_SUBSCRIPTION_ACTIVE: 'SET_SUBSCRIPTION_ACTIVE',

  EDIT_SUBSCRIPTION: 'EDIT_SUBSCRIPTION',

  DELETE_SUBSCRIPTION_STARTING: 'DELETE_SUBSCRIPTION_STARTING',
  DELETE_SUBSCRIPTION_SUCCESS: 'DELETE_SUBSCRIPTION_SUCCESS',
  DELETE_SUBSCRIPTION_ERROR: 'DELETE_SUBSCRIPTION_ERROR',

  SAVE_SUBSCRIPTION_STARTING: 'SAVE_SUBSCRIPTION_STARTING',
  SAVE_SUBSCRIPTION_SUCCESS: 'SAVE_SUBSCRIPTION_SUCCESS',
  SAVE_SUBSCRIPTION_ERROR: 'SAVE_SUBSCRIPTION_ERROR',

  SHOW_DELETE_SUBSCRIPTION_DIALOG: 'SHOW_DELETE_SUBSCRIPTION_DIALOG',
  SHOW_ASSIGN_SUBSCRIPTION_DIALOG: 'SHOW_ASSIGN_SUBSCRIPTION_DIALOG',

  SET_ROW_SIZE: 'SET_ROW_SIZE'

}