import _ from 'lodash';
import componentLifeCurbsideActionTypes from './componentLifeCurbsideActionTypes';
import { componentLifeCurbsideState } from './componentLifeCurbsideSelectors';

const initialState = componentLifeCurbsideState();

const componentLifeCurbsideReducer = (state=initialState, action) => {

  switch (action.type) {
    case componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {
  let components = action.queryResults.componentLifeCurbside.components;

  components = _.isNil(components) ? [] : components;
  return {
    ...state,
    queryRunning: false,
    components: components,
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    components: []
  };
};

export default componentLifeCurbsideReducer;
