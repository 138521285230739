import useSimulationData from "../../../helpers/simulationHelper";
import { performDisplayFetch } from "../../common/fetch";
import { consumers, routeAssets, trucks } from "./routeAssetSimulation";

const fetchRouteAssets = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'query { routedAssetsForOwner { id truckName consumerName destinationPath destinationProject consumerId } }'
            }));
        }
    });
};

const fetchConsumers = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(consumers); 
        } else {
            resolve(performDisplayFetch({
                'query': 'query { consumersForOwner { id consumerName destinationPath destinationProject } }'
            }));
        }
    });
}; 

const fetchTrucks = () => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            resolve(trucks);
        } else {
            resolve(performDisplayFetch({
                'query': 'query { truckNamesByOwner { name } }'
            }));
        }
    });
};

const fetchAddRouteAsset = (workingRouteAsset) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const consumer = consumers.consumersForOwner.find(consumer => consumer.id === workingRouteAsset.consumerId);
            const newRouteAsset = { 
                ...workingRouteAsset, 
                id: routeAssets.routedAssetsForOwner.length + 1,
                consumerName: consumer.consumerName,
                destinationPath: consumer.destinationPath,
                destinationProject: consumer.destinationProject
            };
            routeAssets.routedAssetsForOwner = [...routeAssets.routedAssetsForOwner, newRouteAsset];
            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation routeAsset($asset:RoutedAssetInput!) { routeAsset(asset: $asset) { id truckName consumerName destinationPath destinationProject consumerId } }',
                'variables': {
                  'asset': workingRouteAsset
                }
            }));
        }
    });
};

const fetchUpdateRouteAsset = (workingRouteAsset) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const index = routeAssets.routedAssetsForOwner.findIndex(asset => asset.id === workingRouteAsset.id);
            if (index !== -1) {
                const consumer = consumers.consumersForOwner.find(consumer => consumer.id === workingRouteAsset.consumerId);
                const newRouteAsset = { 
                    ...workingRouteAsset, 
                    id: routeAssets.routedAssetsForOwner.length + 1,
                    consumerName: consumer.consumerName,
                    destinationPath: consumer.destinationPath,
                    destinationProject: consumer.destinationProject
                };
                routeAssets.routedAssetsForOwner = [
                    ...routeAssets.routedAssetsForOwner.slice(0, index),
                    newRouteAsset,
                    ...routeAssets.routedAssetsForOwner.slice(index + 1)
                ];
            }
            resolve(routeAssets);
        } else {
          resolve(performDisplayFetch({
            'query': 'mutation routeAsset($asset:RoutedAssetInput!) { routeAsset(asset: $asset) { id truckName consumerName destinationPath destinationProject consumerId } }',
            'variables': {
              'asset': workingRouteAsset
            }
          }));
        }
    });
};

const fetchDeleteRouteAsset = (truckName, consumerId) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')) {
            routeAssets.routedAssetsForOwner = routeAssets.routedAssetsForOwner.filter(
                asset => !(asset.truckName === truckName && asset.consumerId === consumerId)
            );

            resolve(routeAssets);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation stopRoutingAsset($truckName: String!, $consumerId: Long!) { stopRoutingAsset(truckName: $truckName, consumerId: $consumerId) { success } }',
                'variables': {
                    'truckName': truckName,
                    'consumerId': consumerId
                }
            }));
        }
    });
};

const fetchAddConsumer = (workingConsumer) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const newConsumer = { ...workingConsumer, id: consumers.consumersForOwner.length + 1 };
            consumers.consumersForOwner = [...consumers.consumersForOwner, newConsumer];
            resolve(consumers);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation saveConsumer($input:ConsumerInput!) { saveConsumer(input: $input) { id consumerName destinationPath destinationProject } }',
                'variables': {
                  'input': workingConsumer
                }
            }));
        }
    });
};

const fetchUpdateConsumer = (workingConsumer) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const index = consumers.consumersForOwner.findIndex(consumer => consumer.id === workingConsumer.id);
            if (index !== -1) {
                consumers.consumersForOwner = [
                    ...consumers.consumersForOwner.slice(0, index),
                    workingConsumer,
                    ...consumers.consumersForOwner.slice(index + 1)
                ];
            }
            resolve(consumers);
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation saveConsumer($input:ConsumerInput!) { saveConsumer(input: $input) { id consumerName destinationPath destinationProject } }',
                'variables': {
                  'input': workingConsumer
                }
            }));
        }
    });
}

const fetchDeleteConsumer = (consumerId) => {
    return new Promise((resolve) => {
        if (useSimulationData('routeAssetMgmt')){
            const consumerIndex = consumers.consumersForOwner.findIndex(consumer => consumer.id === consumerId);
            if (consumerIndex  !== -1) {
                consumers.consumersForOwner.splice(consumerIndex , 1);
            }

            routeAssets.routedAssetsForOwner = routeAssets.routedAssetsForOwner.filter(asset => asset.consumerId !== consumerId);
 
            resolve({ consumers, routeAssets });
        } else {
            resolve(performDisplayFetch({
                'query': 'mutation deleteConsumer($consumerId: Long!) { deleteConsumer(consumerId: $consumerId) { success } }',
                'variables': {
                  'consumerId': consumerId
                }
            }));
        }
    });
 };

export {
    fetchRouteAssets, 
    fetchAddRouteAsset,
    fetchAddConsumer,
    fetchUpdateConsumer,
    fetchUpdateRouteAsset,
    fetchDeleteRouteAsset,
    fetchDeleteConsumer,
    fetchConsumers,
    fetchTrucks,
}