import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import {Typography, Box, Card, Tooltip} from '@mui/material';

import ComponentTypes from '../../componentTypes';

import * as dataGridActions from '../../../state/cards/dataGrid/dataGridActions';
import * as appUserConfigActions from '../../../state/app/actions/appUserConfigActions';
import { dataGridState } from "../../../state/cards/dataGrid/dataGridSelectors";
import { appState as applicationState } from '../../../state/app/appSelectors';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps } from "../mdtCard/mdtCard";
import { mdtPalette } from '../../common/styles/mdtPalette';
import { sanitizeDefinition } from "../../../state/cards/dataGrid/services/dataGridService";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const getFormattingColor = (latestValue, conditionalFormattingRules) => {
  let formattingColor = null;
  let normalizedLatestValue = latestValue?.replace(new RegExp(',', 'g'), '');  //remove the unprocessable char from localized number string
  conditionalFormattingRules.forEach((conditionalFormattingRule) => {
    let { color, condition, value1, value2 } = conditionalFormattingRule;
    value1 = _.isEmpty(value1?.toString())? 0: value1;
    value2 = _.isEmpty(value2?.toString())? 0: value2;

    if (normalizedLatestValue !== "-"){
      normalizedLatestValue = parseFloat(normalizedLatestValue);
      switch (condition) {
        case "greater than":
          formattingColor = normalizedLatestValue > value1 ? color : formattingColor;
          break;
        case "less than":
          formattingColor = normalizedLatestValue < value1 ? color : formattingColor;
          break;
        case "between":
          //lodash inRange is checking the boundaries as lowerValue <= latestValue < higherValue
          formattingColor = _.inRange(normalizedLatestValue, value1, value2) ? color : formattingColor;
          break;
        case "not between":
          formattingColor = !_.inRange(normalizedLatestValue, value1, value2) ? color : formattingColor;
          break;
      }
    }
  });
  return formattingColor;
};

const styles = (sensor, sensorsData) => {
  let formattedColor = null;
  if (!_.isNil(sensor) && !_.isNil(sensorsData) && sensor.conditionalFormatting.applied === true) {
    formattedColor = getFormattingColor(sensorsData[sensor.sensorSetId], sensor.conditionalFormatting.rules);
  };

  const showFormatting = !_.isNil(formattedColor);
  return {
    ...cardStyles,
    ...layoutStyles,
    ...typographyStyles,

    cardContent: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden'
    },
    noSensorsContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    },
    noSensorsLabel: {
      ...typographyStyles.noDataLabel
    },
    dataGridContent: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: 1,
      height: 'calc(100%-16px)',
      m: 1,
      overflow: 'hidden',
      alignContent: 'flex-start',
      width: '100%'
    },
    sensorBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      border: '1px solid ' + (showFormatting ? formattedColor : mdtPalette().typography.color),
    },
    latestValue: {
      color: showFormatting ? formattedColor : mdtPalette().typography.color,
    },
    sensorName: {
      color: showFormatting ? formattedColor : mdtPalette().typography.color,
      width: '90%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
      lineHeight: '1.2'
    },
  };
};

class DataGrid extends MDTCard {

  getName() {
    return 'DATA GRID';
  };

  isContextReady() {
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  };

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck, this.props.card.configuration.sensors);
    };

    if (!this.isInEditMode() ) {
      // If edit mode is exited, the card should reflect the most recently saved configuration
      this.props.setDataGridDefinitionSensors(_.isNil(this.props.card.configuration.sensors) ? [] : this.props.card.configuration.sensors);
    }
  }

  componentDidMount() {
    // Initialize the truck, time range and sensors
    if (!_.isNil(this.props.context.truck)) {
      this.props.setDataGridDefinitionDefaultTruck(this.props.context.truck);
    };
    if (!_.isNil(this.props.context.startTime) && !_.isNil(this.props.context.endTime)) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setDataGridDefinitionStartTime(this.props.context.startTime, duration);
    };
    if (!_.isNil(this.props.card.configuration.sensors)) {
      this.props.setDataGridDefinitionSensors(this.props.card.configuration.sensors);
    } else {
      //Set the card sensors default value if not existing
      this.props.initializeDefinition(this.props.dashboard, this.props.view, this.props.cardKey, sanitizeDefinition(this.props.definition));
    };
    // Clear latest values if we are in edit mode, so there is no confusion between configured vs displayed values
    if (this.isInEditMode()) {
      this.props.setupEditMode();
    };
    super.componentDidMount();
  };

  componentDidUpdate(prevProps) {
    // If Truck has changed, change the default truck on the definition
    if (!_.isEqual(prevProps.context.truck, this.props.context.truck)) {
      this.props.setDataGridDefinitionDefaultTruck(this.props.context.truck);
    };
    // If Start Time or duration have changed, change the start time and duration on the definition
    if ((!_.isEqual(prevProps.context.startTime, this.props.context.startTime)) || (!_.isEqual(prevProps.context.endTime, this.props.context.endTime))) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setDataGridDefinitionStartTime(this.props.context.startTime, duration);
    };
    // If sensors change in configuration, then update the definition
    // This helps when discarding changes and we restore the configuration object before the definition, since the comparison is done at the configuration level,
    // which exists only with the layout object
    if (!_.isNil(this.props.card.configuration.sensors) && (!_.isEqual(prevProps.card.configuration.sensors, this.props.card.configuration.sensors))) {
      this.props.setDataGridDefinitionSensors(this.props.definition.primary.sensors);
    };
    // Clear latest values if we are in edit mode, so there is no confusion between configured vs displayed values
    if (this.isInEditMode()) {
      this.props.setupEditMode();
    };

    super.componentDidUpdate(prevProps);
  };

  getRenderedContent() {
    const getSize = () => {
      const card = this.props.card;
      // Width of parent is less than 100% since there are 8px gaps between boxes
      // This total width is split into equal portions depending on the dimensions of the card
      return {
        width: `calc((100% - ${(card.w - 1) * 8}px)/${card.w})`,
        height: `calc((100% - ${(card.h - 1) * 8}px)/${card.h})`
      };
    };

    return (
      <Box key='body' sx={styles(null).cardContent}>
        {_.isEmpty(this.props.definition.primary.sensors) ? (
          <Box sx={styles(null).noSensorsContainer}>
            <Typography variant={'caption'} sx={styles(null).noSensorsLabel}>No Sensors Selected</Typography>
          </Box>
        ) : (
          <Box sx={styles(null).dataGridContent}>
            {_.map(this.props.definition.primary.sensors, sensor => {
              if (sensor.isVisible) return (
                <Card sx={{ ...styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorBox, ...getSize() }} key={sensor.alias}>
                  <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).latestValue} variant={this.props.card.h === 1 ? 'h5':'h4'}>
                    {_.isNil(this.props.sensorsData[sensor.sensorSetId]) ? '-' : this.props.sensorsData[sensor.sensorSetId]}
                  </Typography>
                  <Tooltip title={`${sensor.displayName} (${sensor.uom})`} disableInteractive>
                    <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                      {`${sensor.displayName}`}
                    </Typography>
                  </Tooltip>
                  <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                    {` (${sensor.uom})`}
                  </Typography>
                </Card>
              )
            })}
          </Box>
        )}

      </Box>
    )
  }
}

DataGrid.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DATA_GRID : props.stateKey,
      type: ComponentTypes.DATA_GRID,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dataGridState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    ...mdtCardMapStateToProps(state, props),
    sensorsData: componentState.latestValues,
    definition: componentState.definition,
    dashboards: appState.user.dashboards
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (truck, sensors) => { dispatch(dataGridActions.queryData(props.stateDef, truck, sensors)); },
    clearData: () => { dispatch(dataGridActions.clearData(props.stateDef)); },
    setDataGridDefinitionDefaultTruck: (truck) => { dispatch(dataGridActions.setDefinitionDefaultTruck(props.stateDef, truck)); },
    setDataGridDefinitionStartTime: (startTime, duration) => { dispatch(dataGridActions.setDefinitionStartTime(props.stateDef, startTime, duration)); },
    setDataGridDefinitionSensors: (sensors) => { dispatch(dataGridActions.setDefinitionSensors(props.stateDef, sensors)); },
    setupEditMode: () => { dispatch(dataGridActions.setupEditMode(props.stateDef)); },
    initializeDefinition: (dashboard, view, cardKey, definition) => { dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, definition)); },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataGrid));