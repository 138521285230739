import _ from 'lodash';

const abbreviateLabel = (requestedLabel) => {

  // First split the camel cased word into words separated by a space
  // "TrailingTwelveMonths" -> "Trailing Twelve Months"
  let parsedString = requestedLabel.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

  if (lastThirtyDaysCheck(parsedString) === true) {
    return '30D';
  }

  // Then get the first letter of each of those words
  let matches = parsedString.match(/\b(\w)/g);
  // "Trailing Twelve Months" -> "TTM"
  let acronym = matches.join(''); 

  return acronym;
};

const lastThirtyDaysCheck = (label) => {

  let words = _.words(label);
  if (words.length === 3 && words[0].toLowerCase() == "last" && words[1].toLowerCase() == "thirty" && words[2].toLowerCase() == "days") {
    return true;
  }
  return false;
};

export default abbreviateLabel