import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import { Typography, Box } from '@mui/material';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import ComponentTypes from '../../componentTypes';

import getCardStyles from '../../cards/cardStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { mdtPalette } from '../../common/styles/mdtPalette';

import { fleetComponentsState } from '../../../state/cards/fleetComponents/fleetComponentsSelectors';
import * as fleetComponentsActions from '../../../state/cards/fleetComponents/fleetComponentsActions';
import chartOptions from './chartOptions';
import ButtonBase from "@mui/material/ButtonBase";
import BuildIcon from '@mui/icons-material/Build';
import fleetMapViews from "../../../state/displays/fleetMap/display/fleetMapViews";

import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

const cardStyles = getCardStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...typographyStyles,
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column wrap',
    height: '467px',
  },
  chartContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '165px'
  },
  chartLabel: {
    color: mdtPalette().typography.color,
    paddingTop: 1,
  },
  legendContainer: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '165px',
    top: '400px',
    left: '165px',
  },
  legendItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  legendLabel: {
    color: mdtPalette().typography.color,
    width: '58px',
  },
};

class FleetComponents extends MDTCard {

  getName() {
    return 'Fleet Component';
  }

  getTitle() {
    return 'COMPONENTS'
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  getRenderedButtonsInHeader() {
  return (
    !this.isInEditMode() &&
        <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.props.navigateToView(fleetMapViews.COMPONENT)}>
          <BuildIcon/>
        </ButtonBase>
      )
  }

  getRenderedContent() {
    const charts = _.map(this.props.components, (component) => {
          return {
            label: component.displayName,
            options: chartOptions(component.good, component.aging, component.replace, component.unknown)
          }
        });

    return (

        <Box sx={styles.cardContent}>

             {!_.isNil(this.props.components) && charts.map((chart, index) => (
               <Box key={index} sx={styles.chartContainer}>
                 <Typography sx={styles.chartLabel} variant={'body2'}>{chart.label}</Typography>
                 <HighchartsReact highcharts={Highcharts} options={chart.options}/>
               </Box>
             ))}
             {_.isNil(this.props.components) 
              &&
              <Box sx={{...styles.chartContainer, width: '100%', flexGrow: 1}}>
                <Typography variant={'caption'} sx={styles.noDataLabel}>No Components Loaded</Typography>
              </Box>
             }
             <Box sx={styles.legendContainer}>
               <Box sx={styles.legendItem}>
                 <svg width="30" height="15"><rect width="15" height="15" style={{fill:mdtPalette().status.level2}} /></svg>
                 <Typography variant={'body2'} sx={styles.legendLabel}>Good</Typography>
               </Box>
               <Box sx={styles.legendItem}>
                 <svg width="30" height="15"><rect width="15" height="15" style={{fill:mdtPalette().status.level4}} /></svg>
                 <Typography variant={'body2'} sx={styles.legendLabel}>Aging</Typography>
               </Box>
               <Box sx={styles.legendItem}>
                 <svg width="30" height="15"><rect width="15" height="15" style={{fill:mdtPalette().status.level5}} /></svg>
                 <Typography variant={'body2'} sx={styles.legendLabel}>Replace</Typography>
               </Box>
               <Box sx={styles.legendItem}>
                 <svg width="30" height="15"><rect width="15" height="15" style={{fill:mdtPalette().status.level1}} /></svg>
                 <Typography variant={'body2'} sx={styles.legendLabel}>Unknown</Typography>
               </Box>
             </Box>
           </Box>
           )
  }
}

FleetComponents.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_COMPONENTS : props.stateKey,
      type: ComponentTypes.FLEET_COMPONENTS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetComponentsState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    components: componentState.components,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(fleetComponentsActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetComponentsActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetComponents))


