import _ from "lodash";

/**
 * Validate well changes
 * @param well data to validate
 * @return list of errors
 */
const validateWellChanges = (well) => {
    const wellErrors = {};
    // name cannot be empty
    if (_.isNil(well.name) || _.isEmpty(well.name.trim())) {
        wellErrors.hasNameError = true;
    }
    // If latitude is not empty then it needs to be a number, without space; also within range (-90, 90) and precision no more than 6
    if (!_.isEmpty(well.latitude) &&
        (isNaN(well.latitude) || /\s/g.test(well.latitude) || !(well.latitude <= 90 && well.latitude >= -90) || exceedPrecision(well.latitude, 6))
    ) {
        wellErrors.hasLatitudeError = true;
    }
        // If longitude is not empty then it needs to be a number, without space; also within range (-180, 180) and precision no more than 6
    if (!_.isEmpty(well.longitude) &&
        (isNaN(well.longitude) || /\s/g.test(well.longitude) || !(well.longitude <= 180 && well.longitude >= -180) || exceedPrecision(well.longitude, 6))
    ) {
        wellErrors.hasLongitudeError = true;
    }
    return wellErrors;
};

/**
 * Check if a number exceed precision limit
 */
const exceedPrecision = function(num, precision) {
    const decimalPart = num.toString().split(".")[1];
    if (_.isNil(decimalPart)) {
        return false;
    }else{
        return decimalPart.length > precision;
    }
}

/**
 * Compare and check if well related info has been changed or not
 */
const hasWellChanged = (original, current) => {
    return !(
        original.name === current.name &&
        original.apiNumber === current.apiNumber &&
        original.leaseName === current.leaseName &&
        original.leaseNumber === current.leaseNumber &&
        original.padName === current.padName &&
        original.latitude === current.latitude &&
        original.longitude === current.longitude
    );
};

const parseWellInUseMessage = (message) => {

  let parsedMessage = {
    message: message,
    jobs: []
  };

  if (!_.isEmpty(message)) {
    // Example: YOUR_ERROR_MESSAGE_TEXT. Active Jobs - [CSV LIST OF JOBS]
    // Second part should contain the list of jobs
    let regexMatchedJobs = message.match(/(?<=\[).+?(?=\])/);
    parsedMessage.jobs = _.isNil(regexMatchedJobs) ? [] : regexMatchedJobs[0].split(',');
  } 

  return parsedMessage;

}

export {
    validateWellChanges,
    hasWellChanged,
    parseWellInUseMessage
}