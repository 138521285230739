import { v4 as uuidv4 } from 'uuid';
import fracOverviewCardActionTypes from './fracOverviewCardActionTypes';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import * as fracOverviewCardQueries from './fracOverviewCardQueries';
import {handleError} from '../../app/actions/appErrorActions';
import errorMessages from '../../common/errorMessages';

const queryDataStarting = defaultActionFactory(fracOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_STARTING, 'stateDef', 'transactionId');
const queryDataSuccess = defaultActionFactory(fracOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_SUCCESS, 'stateDef', 'transactionId', 'queryResults');
const queryDataError = defaultActionFactory(fracOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_ERROR, 'stateDef');

const openMenu = defaultActionFactory(fracOverviewCardActionTypes.FRAC_OVERVIEW_CARD_OPEN_MENU, 'stateDef', 'menuTargetElement');
const closeMenu = defaultActionFactory(fracOverviewCardActionTypes.FRAC_OVERVIEW_CARD_CLOSE_MENU, 'stateDef');

const queryFracOverviewData = (stateDef, client) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    let transactionId = uuidv4();

    try {
      await dispatch(queryDataStarting(stateDef, transactionId));
      queryResults = await fracOverviewCardQueries.fetchFracOverview(client);
    } catch(e) {
      // We want to ensure multiple errors coming back only
      // result in 1 error dialog (the last error), rather than for all errors
      if (getState()[stateDef.key].queryTransactionId === transactionId) {
        await dispatch(queryDataError(stateDef));
        return dispatch(handleError(errorMessages.ERROR_RETRIEVING_FLEET_COMPONENT_CARD_DATA, e.message));
      }
      return dispatch(queryDataError(stateDef, transactionId));
    }
    return dispatch(queryDataSuccess(stateDef, transactionId, queryResults));
  }
};

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryFracOverviewData,
  openMenu,
  closeMenu
}