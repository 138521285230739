import _ from 'lodash';

const subscriptionDialogState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    subscriptionName: _.isNil(state) ? '' : state.subscriptionName,
  
    activeStep: _.isNil(state) ? 0 : state.activeStep,

    // These are objects that we created from the results of the
    // `getEvents` method from Portal App Service
    allEvents: _.isNil(state) ? [] : state.allEvents,
    allEventTypes: _.isNil(state) ? [] : state.allEventTypes,

    availableEventTypes: _.isNil(state) ? [] : state.availableEventTypes,
    event: _.isNil(state) || _.isNil(state.event) ? null : state.event,     //when state.event is undefined, set it to null to prevent autocomplete warning
    eventType: _.isNil(state) ? null : state.eventType,
    canSelectEvent: _.isNil(state) ? true : state.canSelectEvent,
    canSelectEventType: _.isNil(state) ? false : state.canSelectEventType,

    filters: _.isNil(state) ? [] : state.filters,
    // Filter object: { id, attribute, operator, value }
    availableFilterAttributes: _.isNil(state) ? [] : state.availableFilterAttributes,
  
    recipients: _.isNil(state) ? [] : state.recipients,
    // Recipient object: { id, provider, destination, availableDestinations }
    // Available destinations: { id, value, label }
    availableProviders: _.isNil(state) ? [] : state.availableProviders,
    // Available providers: { id, value, label, type, availableDestinations }
    
    canSave: _.isNil(state) ? false : state.canSave,
    errorSubscriptionName: _.isNil(state) ? false : state.errorSubscriptionName,
    errorEventType: _.isNil(state) ? false : state.errorEventType,
    errorFilters: _.isNil(state) ? false : state.errorFilters,
    errorRecipients: _.isNil(state) ? false : state.errorRecipients,
  };
  
};

export {
  subscriptionDialogState
};