import settingsActionTypes from '../actionTypes/settingsActionTypes';

const displayReducer = (state, action) => {

  switch (action.type) {

    case settingsActionTypes.SETTINGS_SET_SELECTED_FORM_INDEX:
      return onSetSelectedFormIndex(state, action);

    default: return state;

  }
};

const onSetSelectedFormIndex = (state, action) => {
  return {
    ...state,
    formIndex: action.index,
  };
};

export default displayReducer