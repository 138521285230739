import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";
import { parse } from 'query-string';

import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  Divider,
  Tabs,
  Tab,
  Tooltip,
  Chip,
  Badge
} from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import getCardStyles from '../../cards/cardStyles';
import ComponentTypes from '../../componentTypes';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import Progress from '../../controls/progress';
import * as jobStatusConstants from '../../common/jobStatusConstants';
import MDTDataGrid from '../../common/table/MDTDataGrid';
import { trackPage } from '../../../helpers/googleAnalyticsHelper';
import { appState as applicationState } from '../../../state/app/appSelectors';

import * as jobOverviewServices from '../../../state/displays/jobOverview/services/jobOverviewServices';
import { jobOverviewState } from '../../../state/displays/jobOverview/jobOverviewSelectors';
import * as jobOverviewActions from '../../../state/displays/jobOverview/jobOverviewActions';
import jobOverviewViews from '../../../state/displays/jobOverview/jobOverviewViews';
import ApprovalDialog from './approvalDialog';
import * as assetTypeService from '../../../state/common/services/assetTypeService';
import StageDataGrid from './controls/stageDataGrid';
import StageViewer from './stageViewer';
import EventHistoryViewer from './eventHistoryViewer';
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";
import * as appNavActions from "../../../state/app/actions/appNavActions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as filterActions from "../../../state/common/filtering/filterActions";
import FilterDialog from "../../common/filtering/filterDialog";

import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import InsightsIcon from '@mui/icons-material/Insights';
import ChartView from './views/chartView';

const cardStyles = getCardStyles();
const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...cardStyles,
  ...detailsPageStyles,
  detailsPage: {
    ...detailsPageStyles.detailsPage,
    height: '100vh'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw',
    height: 'inherit'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start',
    flexGrow: 0,
    alignItems: 'center'
  },

  cardHeaderContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  cardHeader: {
    ...cardStyles.cardHeader,
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0
  },

  containerRowFlexNoWrap: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  containerColFlexNoWrap: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },

  stepDetailsAdditiveColumnHeader: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    width: '200px'
  },
  stepDetailsGroupingHeader: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100px',
    justifyContent: 'start',
    width: 'inherit',
    alignItems: 'center',
    '& .MuiFormLabel-root': {
      textAlign: 'center',
      width: '200%',
      float: 'left'
    },
    '& .MuiInputBase-input': {
      textAlign: 'center'
    }
  },

  eventHistoryColumn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent:'center'
  },
  eventHistoryHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '38px',
    backgroundColor: 'grey.800',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c6c6c6',
    width: '100%'
  },
  eventHistoryHeaderText: {
    fontWeight: 500
  },
  eventHistoryValue: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '52px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },

  detailsDataColumn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    height: '92%',
    justifyContent: 'space-between',
    marginLeft: 2,
    marginTop: '3px'
  },

  jobSelection: {
    width: '200px',
    height: '100%',

},

  filterContainer: {
    display:'flex',
    flexFlow:'row nowrap',
    alignItems: 'center',
    paddingLeft: 3,
  },

  childComponent : {
    margin: 1,  
  },

  filterItems: {
    paddingLeft: 3,
  },

  selectionContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  dropdown: {
    width: '200px',
    display: 'flex',
    alignItems: 'center'
  },

  dropdownText : {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px' },

  actionsDivider: {
    height: '36px',
    marginLeft: '12px',
    marginRight: '12px'
  },
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    alignItems: 'center'
  },
  detailsPageHeaderButton: {
    paddingLeft: 1.5,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1
  }
};

class JobOverview extends Component {

  columns = [];
  unitGroupingModel = [];

  constructor(props) {
    super(props);
  }

  customGridToolbarStages() {
    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
          <GridToolbarQuickFilter sx={{width: '255px'}} />
        </GridToolbarContainer>
    )
  }


  customGridToolbarNoToolbar() {
    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
        </GridToolbarContainer>
    )
  }

  periodicRefreshDisplay() {
    return setInterval(()=>{
      this.manualRefreshDisplay();
    }, 180000); // 3 minutes
  }

  componentDidMount() {
    // GA Tracking
    trackPage(ComponentTypes.JOB_OVERVIEW, this.props.user);

    // This will use the parse function from query-string package to parse out the 'job' parameter
    const parsedLocation = parse(location.search);
    this.foundJobName = parsedLocation.job;

    // This will load the user configuration into state...
    this.props.loadDisplay(this.props.user.userId);

    // ...where it will be subsequently used here to apply the user configuration to the Job Overview
    this.props.onQueryJobsData();
    
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
  }

  componentWillUnmount() {
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedUnitIndex !== this.props.selectedUnitIndex) {
      this.columns = null;
      this.unitGroupingModel = null;
      this.columns = [];
      this.unitGroupingModel = [];
    }
    if (prevProps.selectedJob?.value !== this.props.selectedJob?.value) {
      this.queryJobData(this.props.selectedJob.value);
    }
  }

  manualRefreshDisplay() {
    this.props.loadDisplay(this.props.user.userId);
  }

  queryJobData(jobName) {
    if (!_.isNil(this.foundJobName)) { // If deep linking provide a jobName, it will display that job
      this.props.onQueryJobData(this.foundJobName);
      this.props.resetUrl();
      this.foundJobName = null;
    }
    else if (!_.isNil(jobName)){ // selected job from jobs dropdown list will display
      this.props.onQueryJobData(jobName);
    }
  }

  addAdditivesColumn(columns, additiveTotalCount, additiveName ) {

    for (let i = 0; i < additiveTotalCount; i++) {
      let prevColumns = columns;
      columns = [...prevColumns,
        {
          field: additiveName + i,
          width: 200,
          align: 'center',
          editable: false,
          sortable: false,
          hideable: false,
          pinnable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          renderHeader: (params) =>
          {
            return (
                <Box sx={styles.stepDetailsAdditiveColumnHeader}>
                  <Typography variant='subtitle2'>Concentration</Typography>
                  <Typography variant='subtitle2'>{(additiveName === 'LiquidAddConcentration' ? '(GAL/MGAL)' : '(LBS/MGAL)')}</Typography>
                </Box>
            );
          },
        }];
    }

    return columns;

  }

  renderStepDetails(unitIndex, unit) {

    if (_.isNil(unit)) {
      return (
          <Box />
      );
    }

    const initialState = { pinnedColumns: { left: ['Step', 'StepTotal', 'BaseRate', 'FluidType']}}

    this.columns = [
      {
        field: 'Step',
        headerAlign: 'center',
        align: 'center',
        width: 50,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderHeader: (params) =>
        {
          return (
              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '50px'}}>
                <Typography variant='subtitle2'>Step</Typography>
              </Box>
          );
        },
      },
      {
        field: 'StepTotal',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderHeader: (params) =>
        {
          return (
              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '125px'}}>
                <Typography variant='subtitle2'>Discharge Total</Typography>
                <Typography variant='subtitle2'>(BBL)</Typography>
              </Box>
          );
        },
      },
      {
        field: 'BaseRate',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderHeader: (params) =>
        {
          return (
              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '100px'}}>
                <Typography variant='subtitle2'>Rate</Typography>
                <Typography variant='subtitle2'>(BBL/MIN)</Typography>
              </Box>
          );
        },
      },
      {
        field: 'FluidType',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        editable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        resizable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderHeader: (params) =>
        {
          return (
              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '200px'}}>
                <Typography variant='subtitle2'>Fluid Type</Typography>
              </Box>
          );
        },
      }
    ];

    this.columns = this.addAdditivesColumn(this.columns, unit.Steps[0].DryAddsCount, 'DryAddConcentration');

    this.columns = this.addAdditivesColumn(this.columns, unit.Steps[0].LiquidAddsCount, 'LiquidAddConcentration');

    this.columns = this.addAdditivesColumn(this.columns, unit.Steps[0].GelsCount, 'GelPumpConcentration');


    this.unitGroupingModel =
        [
          {
            groupId: 'unit',
            children: [{ field: 'Step' }, { field: 'StepTotal' }, { field: 'BaseRate' }, { field: 'FluidType' }],
            renderHeaderGroup: (params) =>
                (
                    <Box sx={{...styles.containerColFlexNoWrap, height: '80px', justifyContent: 'end', width: 'inherit', alignItems: 'center'}}>
                      <Typography variant="subtitle2">{_.isNil(this.props.selectedStage) ? '' : this.props.selectedStage.description}</Typography>
                    </Box>
                )
          },
        ];

    if (unit.UnitType === assetTypeService.assetTypes.BLENDER || unit.UnitType === assetTypeService.assetTypes.DATAVAN) {
      let prevColumns = this.columns;
      this.columns = [
        {
          field: 'ProppantType',
          headerAlign: 'center',
          align: 'center',
          width: 200,
          editable: false,
          sortable: false,
          hideable: false,
          pinnable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          renderHeader: (params) =>
          {
            return (
                <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '200px'}}>
                  <Typography variant='subtitle2'>Proppant Type</Typography>
                </Box>
            );
          },
        },
        {
          field: 'Concentration',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          editable: false,
          sortable: false,
          hideable: false,
          pinnable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          renderHeader: (params) =>
          {
            return (
                <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '100px'}}>
                  <Typography variant='subtitle2'>Begin Conc.</Typography>
                  <Typography variant='subtitle2'>(LBS/GAL)</Typography>
                </Box>
            );
          },
        },
        {
          field: 'EndConcentration',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          editable: false,
          sortable: false,
          hideable: false,
          pinnable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          disableReorder: true,
          disableExport: true,
          renderHeader: (params) =>
          {
            return (
                <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'center', width: '100px'}}>
                  <Typography variant='subtitle2'>End Conc.</Typography>
                  <Typography variant='subtitle2'>(LBS/GAL)</Typography>
                </Box>
            );
          },
        },
        ...prevColumns
      ];

      let prevUnitGroupingModel = this.unitGroupingModel;
      this.unitGroupingModel = [...prevUnitGroupingModel,
        {
          groupId: 'Proppant',
          children: [{ field: 'ProppantType' }, { field: 'Concentration' }, { field: 'EndConcentration' }],
          renderHeaderGroup: (params) =>
              (
                  <Box sx={styles.stepDetailsGroupingHeader}>
                    <Typography variant="subtitle2" sx={{marginBottom: '5px'}}>Proppant</Typography>
                    <TextField
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Base Rate Source'
                        value={unit.Steps[0].ProppantSource}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' }
                          }
                        }}
                    />
                  </Box>
              )
        }
      ]
    }

    _.filter(this.columns, (column) => { return _.startsWith(column.field, 'DryAddConcentration'); }).map((dryAdd, key) => {
      let prevUnitGroupingModel = this.unitGroupingModel;
      this.unitGroupingModel = [...prevUnitGroupingModel,
        {
          groupId: 'Dry Add ' + (key + 1),
          children: [{ field: 'DryAddConcentration' + key}],
          renderHeaderGroup: (params) =>
              (
                  <Box sx={styles.stepDetailsGroupingHeader}>
                    <Typography variant="subtitle2" sx={{marginBottom: '5px'}}>{'Dry Add ' + (key + 1)}</Typography>
                    <TextField
                        sx={{marginBottom: '4px'}}
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Type'
                        value={unit.Steps[0]['DryAddType' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' },

                          }
                        }}
                    />
                    <TextField
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Base Rate Source'
                        value={unit.Steps[0]['DryAddSource' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' }
                          }
                        }}
                    />
                  </Box>
              )
        }
      ]
    });

    _.filter(this.columns, (column) => { return _.startsWith(column.field, 'LiquidAddConcentration'); }).map((liquidAdd, key) => {
      let prevUnitGroupingModel = this.unitGroupingModel;
      this.unitGroupingModel = [...prevUnitGroupingModel,
        {
          groupId: 'Liquid Add ' + (key + 1),
          children: [{ field: 'LiquidAddConcentration' + key}],
          renderHeaderGroup: (params) =>
              (
                  <Box sx={styles.stepDetailsGroupingHeader}>
                    <Typography variant="subtitle2" sx={{marginBottom: '5px'}}>{'Liquid Add ' + (key + 1)}</Typography>
                    <TextField
                        sx={{marginBottom: '4px'}}
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Type'
                        value={unit.Steps[0]['LiquidAddType' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' },
                          }
                        }}
                    />
                    <TextField
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Base Rate Source'
                        value={unit.Steps[0]['LiquidAddSource' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' }
                          }
                        }}
                    />
                  </Box>
              )
        }
      ]
    });

    _.filter(this.columns, (column) => { return _.startsWith(column.field, 'GelPumpConcentration'); }).map((gelPump, key) => {
      let prevUnitGroupingModel = this.unitGroupingModel;
      this.unitGroupingModel = [...prevUnitGroupingModel,
        {
          groupId: 'Gel Pump ' + (key + 1),
          children: [{ field: 'GelPumpConcentration' + key}],
          renderHeaderGroup: (params) =>
              (
                  <Box sx={styles.stepDetailsGroupingHeader}>
                    <Typography variant="subtitle2" sx={{marginBottom: '5px'}}>{'Gel Pump ' + (key + 1)}</Typography>
                    <TextField
                        sx={{marginBottom: '4px'}}
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Type'
                        value={unit.Steps[0]['GelPumpType' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' },
                          }
                        }}
                    />
                    <TextField
                        size={'small'}
                        type='text'
                        disabled
                        variant={'standard'}
                        label='Base Rate Source'
                        value={unit.Steps[0]['GelPumpSource' + key]}
                        InputProps={{
                          inputProps: {
                            style: { fontSize: 12, height: '10px' }
                          }
                        }}
                    />
                  </Box>
              )
        }
      ]
    });

    return (
        <Box sx={{
          '& div[class*="MuiDataGrid-columnHeaderRow"]:first-of-type': {
            minHeight: '100px',
            maxHeight: '100px',
            alignItems: 'self-end'
          },
          '& div[class*="MuiDataGrid-columnHeaderRow"]:last-of-type': {
            minHeight: '70px',
            maxHeight: '70px',
            position: 'relative',
            top: '-5px'
          },
          '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
            display: "none"
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            overflow: "visible",
            lineHeight: 1,
            whiteSpace: "normal",
            width: 'inherit'
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: 'inherit'
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            width: 'inherit',
            height: '100px'
          },
          boxShadow: '0px 10px 10px 3px rgb(0 0 0 / 20%)'
        }}>
          <MDTDataGrid
              getRowId={row => row.Step}
              pagination={false}
              hideFooter
              columns={this.columns}
              rows={unit.Steps}
              disableSelectionOnClick
              components={{
                Toolbar: this.customGridToolbarNoToolbar,
              }}

              stateDef={this.props.stateDef}

              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={this.unitGroupingModel}

              initialState={initialState}

              // The fortunate and unfortunate thing with HeaderHeight is that it is the only way we can control
              // Column Header Height.
              // If we don't do this, MUI will runtime inject a style with { height: 56px } on top of any css you have,
              // thus making any css based height control impossible. My assumption is that this behaviour is a bug.

              // Rather than wait for Google to fix it, we can leverage Header Height in our own way:
              // Set the value here. What ultimately happens it that it creates a space for the headers = (n * HeaderHeight), where
              // n is the number of header rows you have (ie. if you have header groups, you will have more than 1 header row).
              // Then using css and styles for the headers themselves, arrange the header content within this header space.
              // For example,
              // The current setup is two header rows - one for groups (call this H1) and one for the data (call this H2).
              // We want the H1 row to be taller than the H2 row. We accomplished this by having the H1 row configured to take up most of the
              // space that was created here using the Header Height value. The H2 row will then take up the remainder of that space.
              headerHeight={90}
          />
        </Box>
    )

  }

  render() {
    const noValue = '-';
    const topEventLogUserHasValue = !_.isEmpty(this.props.eventLogs) && !_.isNil(this.props.eventLogs[0].user);
    const topEventLogNoteHasValue = !_.isEmpty(this.props.eventLogs) && !_.isNil(this.props.eventLogs[0].note);

    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    return (
        <Box sx={styles.detailsPage}>
          <Box sx={styles.detailsPageContent}>
            <Box sx={styles.detailsPageHeader}>
              <Box sx={styles.detailsPageHeaderTitle}>
                <Typography variant={'h6'}>Job Overview</Typography>
              </Box>
              <Box sx={styles.selectionContainer}>
                <Typography variant={"subtitle1"} sx={styles.dropdownText}> JOB:</Typography>
                <AutoCompleteMDT
                    sx={styles.dropdown}
                    options={this.props.filteredJobs}
                    value={this.props.selectedJob}
                    onChange={(event, value, reason) => {
                      const jobName = value ? value.value : null;
                      this.queryJobData(jobName);
                    }}
                    noOptionsText={'No jobs found...'}
                  />
              </Box>
              <Box sx={styles.detailsPageHeaderActions}>
                <IconButton
                    onClick={() => this.queryJobData(this.props.selectedJob.value)}
                    size="large"><RefreshIcon/>
                </IconButton>
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Box sx={styles.detailsPageHeaderButton}>
                  <Tooltip title="Overview">
                    <IconButton 
                      size='large'
                      color={
                        this.props.selectedView === jobOverviewViews.OVERVIEW
                            ? "primary"
                            : "default"
                      }
                      onClick={() => this.props.onSelectView(jobOverviewViews.OVERVIEW)}
                      >
                        <ViewComfyIcon/>
                    </IconButton>
                  </Tooltip>
                  <svg height="3" width="51">
                    <line
                      x1="0"
                      y1="0"
                      x2="51"
                      y2="0"
                      stroke="#CE4300"
                      strokeWidth="3"
                      visibility={
                        this.props.selectedView === jobOverviewViews.OVERVIEW
                            ? "visible"
                            : "hidden"
                      }
                    />
                  </svg>
                </Box>
                <Box sx={styles.detailsPageHeaderButton}>
                  <Tooltip title="Treatment Plot">
                    <IconButton
                      color={
                        this.props.selectedView === jobOverviewViews.TREATMENTPLOT
                            ? "primary"
                            : "default"
                      } 
                      size='large'
                      onClick={() => this.props.onSelectView(jobOverviewViews.TREATMENTPLOT)}
                      >
                        <Badge badgeContent={this.props.charts.length} variant="dot" size="large" color='primary'>
                        <InsightsIcon/>
                        </Badge>
                    </IconButton>
                  </Tooltip>
                  <svg height="3" width="51">
                    <line
                      x1="0"
                      y1="0"
                      x2="51"
                      y2="0"
                      stroke="#CE4300"
                      strokeWidth="3"
                      visibility={
                        this.props.selectedView === jobOverviewViews.TREATMENTPLOT
                            ? "visible"
                            : "hidden"
                      }
                    />
                  </svg>
                </Box>
              </Box>
                  
              <Box sx={styles.filterContainer}>
                <Typography variant={'subtitle1'}>FILTER:</Typography>
                <Box sx={styles.filterItems}>
                  {
                    this.props.appliedFilters.map((filterContext, index) => {
                      return (
                          <Chip
                              key={index}
                              sx={styles.childComponent}
                              label={filterContext.chipLabel}
                              onDelete={() => {
                                this.props.deleteFilter(index);
                              }}
                          />
                      )
                    })
                  }
                </Box>
                <IconButton
                    onClick={() => this.props.openFilterDialog()}
                    disabled={emptyFilters}
                    sx={styles.childComponent}
                    size="large"><AddCircleIcon/></IconButton>
              </Box>
            </Box>
            <Box sx={styles.viewContainer}>
              {this.props.selectedView === jobOverviewViews.OVERVIEW && 
                (
                <Box sx={{...styles.containerColFlexNoWrap, flexGrow: 1}}>
                  <Box sx={{...styles.containerRowFlexNoWrap, maxHeight: '180px', minHeight: '180px'}}>
                    <Card sx={{display: 'inline-block', minWidth: _.isNil(this.props.selectedJobInfo) ? '0px' : (!jobOverviewServices.jobStatusIsApprovalRequest(this.props.selectedJobInfo.status) ? '650px' : '850px')}}>
                      <CardContent sx={{minWidth: _.isNil(this.props.selectedJobInfo) ? '0px' : (!jobOverviewServices.jobStatusIsApprovalRequest(this.props.selectedJobInfo.status) ? '650px' : '850px')}}>
                        <Box sx={styles.cardHeader}>
                          <Typography variant='subtitle1' gutterBottom>DETAILS</Typography>
                        </Box>
                        <Box sx={{...styles.containerRowFlexNoWrap, paddingTop: 1}}>
                          <Box sx={{...styles.containerRowFlexNoWrap, flexGrow: 1, justifyContent: 'space-between'}}>
                            <Box sx={{...styles.containerRowFlexNoWrap, alignItems: 'center'}}>
                              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'flex-end', height: '100%'}}>
                                <Typography variant='subtitle1'>Datavan</Typography>
                                <Typography variant='subtitle1'>Type</Typography>
                                <Typography variant='subtitle1'>Pad</Typography>
                                <Typography variant='subtitle1'>Process Type</Typography>
                              </Box>

                              <Box sx={styles.detailsDataColumn}>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.truckName}</Typography>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.type}</Typography>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.padName}</Typography>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.processType}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{...styles.containerRowFlexNoWrap, alignItems: 'center', marginLeft: 2}}>
                              <Box sx={{...styles.containerColFlexNoWrap, alignItems: 'flex-end', height: '100%'}}>
                                <Typography variant='subtitle1'>Customer</Typography>
                                <Typography variant='subtitle1'>Fleet</Typography>
                                <Typography variant='subtitle1'>Status</Typography>
                                <Box sx={{height: '22px'}} />
                              </Box>

                              <Box sx={styles.detailsDataColumn}>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.customerName}</Typography>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.fleetName}</Typography>
                                <Typography variant='subtitle2'>{_.isNil(this.props.selectedJobInfo) ? noValue : this.props.selectedJobInfo.status}</Typography>
                                <Box sx={{height: '22px'}} />
                              </Box>
                            </Box>
                          </Box>
                          {
                              this.props.user.hasJobApproverPermission &&
                              !_.isNil(this.props.selectedJobInfo) &&
                              jobOverviewServices.jobStatusIsApprovalRequest(this.props.selectedJobInfo.status) &&
                              <Box sx={{...styles.containerRowFlexNoWrap, justifyContent: 'center'}}>
                                <Divider sx={{marginLeft: 2, marginRight: 2}} orientation='vertical' flexItem />
                                <Box sx={{...styles.containerColFlexNoWrap, justifyContent: 'center'}}>
                                  <Box sx={{...styles.containerRowFlexNoWrap, justifyContent: 'center', paddingRight: '2px'}}>
                                    <Typography variant='subtitle1'>Approve | Reject</Typography>
                                  </Box>
                                  <Box>
                                    <IconButton sx={{marginLeft: '15px'}}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.props.onDialogSetType(jobStatusConstants.JOB_APPROVAL_ACCEPT);
                                                  this.props.onShowDialog(true);}}>
                                      <CheckIcon/>
                                    </IconButton>
                                    <IconButton sx={{marginLeft: '19px'}}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.props.onDialogSetType(jobStatusConstants.JOB_APPROVAL_REJECT);
                                                  this.props.onShowDialog(true);}}>
                                      <CancelIcon/>
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                          }
                        </Box>
                      </CardContent>
                    </Card>
                    <Card sx={{...styles.containerRowFlexNoWrap, flexGrow: 1, marginLeft: 2}}>
                      <CardContent sx={{width: '100%'}}>
                        <Box sx={styles.cardHeader}>
                          <Box sx={styles.cardHeaderContent}>
                            <Typography variant='subtitle1' gutterBottom>EVENT HISTORY</Typography>
                            <IconButton sx={styles.cardActionButton}
                                        disabled={_.isNil(this.props.selectedJob) || _.isEmpty(this.props.eventLogs)}
                                        onClick={(e) => { e.stopPropagation(); this.props.onShowEventHistoryViewer(true); }}>
                              <OpenInFullIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box sx={{...styles.containerColFlexNoWrap, flexGrow: 1, paddingTop: 1, maxHeight: '100px', minHeight: '100px', width: '100%'}}>
                          <Box sx={{display: 'flex', flexFlow: 'row nowrap', boxShadow: '0px 10px 10px 3px rgb(0 0 0 / 20%)'}}>
                            <Box sx={{...styles.eventHistoryColumn, minWidth: '15%', maxWidth: '15%'}}>
                              <Box sx={styles.eventHistoryHeader}>
                                <Typography variant='body2' sx={styles.eventHistoryHeaderText}>Date</Typography>
                              </Box>
                              <Box sx={styles.eventHistoryValue}>
                                <Typography variant='body2'>{ _.isEmpty(this.props.eventLogs) ? noValue : this.props.eventLogs[0].createdOn}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{...styles.eventHistoryColumn, minWidth: '20%', maxWidth: '20%'}}>
                              <Box sx={styles.eventHistoryHeader}>
                                <Typography variant='body2' sx={styles.eventHistoryHeaderText}>Status</Typography>
                              </Box>
                              <Box sx={styles.eventHistoryValue}>
                                <Typography variant='body2'>{ _.isEmpty(this.props.eventLogs) ? noValue : this.props.eventLogs[0].eventType}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{...styles.eventHistoryColumn, minWidth: '20%', maxWidth: '20%'}}>
                              <Box sx={styles.eventHistoryHeader}>
                                <Typography variant='body2' sx={styles.eventHistoryHeaderText}>User</Typography>
                              </Box>
                              <Box sx={styles.eventHistoryValue}>
                                <Tooltip title={ !topEventLogUserHasValue ? '' : this.props.eventLogs[0].user} followCursor >
                                  <Typography noWrap variant='body2'>{ !topEventLogUserHasValue ? noValue : this.props.eventLogs[0].user}</Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Box sx={{...styles.eventHistoryColumn, minWidth: '45%', maxWidth: '45%' }}>
                              <Box sx={{...styles.eventHistoryHeader}}>
                                <Typography variant='body2' sx={styles.eventHistoryHeaderText}>Note</Typography>
                              </Box>
                              <Box sx={{...styles.eventHistoryValue, justifyContent: !topEventLogNoteHasValue ? 'center' : 'flex-start'}}>
                                <Tooltip title={ !topEventLogNoteHasValue ? '' : this.props.eventLogs[0].note} followCursor >
                                  <Typography noWrap variant='body2'>{ !topEventLogNoteHasValue ? noValue : this.props.eventLogs[0].note}</Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>

                  {/* Stages */}
                  <Card sx={{marginTop: 2}}>
                    <CardContent sx={{height: '40vh', paddingBottom: 1}}>
                      <Box sx={styles.cardHeader}>
                        <Box sx={styles.cardHeaderContent}>
                          <Typography variant='subtitle1' gutterBottom>STAGES</Typography>
                          <IconButton sx={styles.cardActionButton}
                                      disabled={_.isNil(this.props.selectedJobInfo)}
                                      onClick={(e) => { e.stopPropagation(); this.props.onShowStageViewer(true); }}>
                            <OpenInFullIcon />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box sx={{height: '100%', paddingBottom: 3}}>
                        <StageDataGrid
                            stateDef={this.props.stateDef}
                            stages={this.props.stages}
                            selectStageCallback={this.props.onSelectStage}
                            createChartForStageCallback={this.props.onCreateChartForStage}
                            selectedStage={this.props.selectedStage}
                            charts={this.props.charts}
                        />
                      </Box>
                    </CardContent>
                  </Card>

                  <Card sx={{marginTop: 2, flexGrow: 1, marginBottom: 2}}>
                    <CardContent sx={{height: '100%'}}>
                      {/* Step Details for selected Stage  */}
                      {
                          !_.isNil(this.props.selectedStage) &&
                          <Box sx={{...styles.containerColFlexNoWrap, flexGrow: 0}}>
                            <Tabs
                                value={this.props.selectedUnitIndex}
                                onChange={(event, value) => this.props.onSelectUnitIndex(value)}
                                indicatorColor='primary'
                                textColor='primary'
                            >
                              {
                                  (!_.isNil(this.props.selectedStageDefinition)) &&
                                  _.map(this.props.selectedStageDefinition, (unit, key) => {
                                    return <Tab key={key} value={key} label={unit.Name}/>
                                  })
                              }
                            </Tabs>
                          </Box>
                      }
                      {
                          _.isNil(this.props.selectedStageDefinition) &&
                          <Box sx={{...styles.containerColFlexNoWrap, flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginTop: 2, height: '100%' }}>
                            <Typography variant='subtitle1'>Select a Stage above to view Step Details.</Typography>
                          </Box>
                      }

                      {
                          !_.isNil(this.props.selectedStageDefinition) &&
                          this.renderStepDetails(this.props.selectedUnitIndex, this.props.selectedStageDefinition[this.props.selectedUnitIndex])
                      }
                    </CardContent>
                  </Card>

                </Box>
                )
              }
              {
                this.props.selectedView === jobOverviewViews.TREATMENTPLOT &&
                (
                  <ChartView />
                )
              }
            </Box>
          </Box>
          <FilterDialog
              stateDef={this.props.stateDef}
              appliedFilterLabels={this.props.appliedFilters.map(f => f.label)}
          />
          <Progress open={this.props.queryRunning}/>
          <ApprovalDialog />
          <StageViewer />
          <EventHistoryViewer />
        </Box>
    );
  }
}


const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_OVERVIEW : props.stateKey,
      type: ComponentTypes.JOB_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;
  let componentState = jobOverviewState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    queryRunning: componentState.queryRunning,

    user: appState.user,

    jobs: componentState.jobs,
    selectedJob: componentState.selectedJob,
    selectedJobInfo: componentState.selectedJobInfo,
    stages: componentState.stages,
    selectedUnitIndex: componentState.selectedUnitIndex,
    selectedStage: componentState.selectedStage,
    selectedStageDefinition: componentState.selectedStageDefinition,
    eventLogs: componentState.eventLogs,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    filteredJobs: componentState.filteredJobs,

    selectedView: componentState.selectedView,
    charts: componentState.charts
  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onQueryJobsData: () => { dispatch(jobOverviewActions.queryJobsData(props.stateDef)); },
    onQueryJobData: (jobName) => { dispatch(jobOverviewActions.queryJobData(props.stateDef, jobName)); },

    onDialogSetType: (dialogType) => { dispatch(jobOverviewActions.dialogSetType(props.stateDef, dialogType)); },
    onShowDialog: (show) => { dispatch(jobOverviewActions.showDialog(props.stateDef, show)); },

    onSelectStage: (stage) => { dispatch(jobOverviewActions.selectStage(props.stateDef, stage)); },
    onSelectUnitIndex: (index) => { dispatch(jobOverviewActions.selectUnitIndex(props.stateDef, index)); },

    onShowStageViewer: (show) => { dispatch(jobOverviewActions.showStageViewer(props.stateDef, show)); },
    onShowEventHistoryViewer: (show) => { dispatch(jobOverviewActions.showEventHistoryViewer(props.stateDef, show)); },

    onSaveJobStatus: (id, status, user, notes) => { dispatch(jobOverviewActions.saveJobStatus(props.stateDef, id, status, user, notes)); },

    resetUrl: () => { dispatch(appNavActions.resetUrl('/job-overview')); },

    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    loadDisplay: (userId) => { dispatch(jobOverviewActions.loadDisplay(props.stateDef, userId)) },

    onSelectView: (view) => { dispatch(jobOverviewActions.selectView(props.stateDef, view)) },
    onCreateChartForStage: (stage) => { dispatch(jobOverviewActions.createChartForStage(props.stateDef, stage)) },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(JobOverview));