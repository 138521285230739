import { performDisplayFetch } from '../../common/fetch'

const fetchUsers = () => {

  return performDisplayFetch({
    'query': '{users(includeDisabled:true){ id name email isEnabled isAdmin lastLogin } }'
  })

};

const fetchAddUser = (userObj) => {

  return performDisplayFetch({
    'query': 'mutation createUser($userInput:UserCreateInput!) { createUser(userInput:$userInput) { id name email isEnabled isAdmin } }',
    'variables': {
      userInput: {
        name: userObj.name,
        email: userObj.email,
        isAdmin: userObj.isAdmin,
      }
    }
  })

};

const fetchUpdateUser = (userObj) => {

  return performDisplayFetch({
    'query': 'mutation updateUser($userInput:UserUpdateInput!) { updateUser(userInput:$userInput) { id name email isEnabled isAdmin } }',
    'variables': {
      userInput: {
        id: userObj.id,
        name: userObj.name,
        isEnabled: userObj.isEnabled,
        isAdmin: userObj.isAdmin,
      }
    }
  })
};

const fetchDeleteUser = (userId) => {

  return performDisplayFetch({
    'query': 'mutation deleteUser($userId:String!) { deleteUser(userId:$userId) { result } }',
    'variables': {
      userId: userId
    }
  })

};

export {
  fetchUsers,
  fetchAddUser,
  fetchUpdateUser,
  fetchDeleteUser,
}