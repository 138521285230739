import _ from 'lodash';

/**
 * Given a collection of Recipients, return a collection of RecipientInput objects.
 * @param {*} recipients Collection of Recipients object with id, value, label, type, availableDestinations
 */
const mapRecipientsToRecipientInput = (recipients) => {

  let recipientInputs = [];

  _.forEach(recipients, (recipient) => {

    // Set the destination with consideration if there were a list of available destinations 
    // If so, then get the value of the selected destination
    // If not, then just take the destination as is
    let destinationInput = !_.isEmpty(recipient.availableDestinations) ? recipient.destination.value : recipient.destination;

    recipientInputs.push(
      {
        providerId: recipient.provider.id,
        destination: destinationInput
      }
    )

  });

  return recipientInputs;
}

/**
 * Given a collection of Filters, return a collection of ConditionInput objects.
 * @param {*} filters Collection of Filters with id, attribute, operator, value, availableValues
 */
const mapFiltersToConditionsInput = (filters) => {

  let conditionInput = [];

  _.forEach(filters, (filter) => {
    
    // Set the value with consideration if there were a list of available values 
    // If so, then get the value of the selected value
    // If not, then just take the value as is
    let valueInput = _.has(filter.value, 'value') ? filter.value.value : filter.value;

    //parse values array into json string
    if(Array.isArray(filter.value)){
        valueInput = _.has(filter.value[0], 'value') ? JSON.stringify(filter.value.map(v => v.value)) : JSON.stringify(filter.value);
    }

    conditionInput.push(
      {
        name: filter.attribute.value,
        operator: filter.operator.value,
        value: valueInput
      }
    )

  });

  return conditionInput;
}

export {
  mapRecipientsToRecipientInput,
  mapFiltersToConditionsInput
}