import { performDisplayFetch } from '../../../common/fetch';
import * as deviceSimulation from './deviceSimulation';
import useSimulationData from '../../../../helpers/simulationHelper';

const fetchDeviceSettings = () => {

  if (useSimulationData('deviceSettings')) {
    return deviceSimulation.fetchDeviceSettings();
  }

  return performDisplayFetch({
    'query': '{ allDevices {id truckPid ownerId esn active lastPolledTime lastKnownCoord {latitude longitude}} }'
  });
};

const pairDeviceSettings = (pairInput) => {

  if (useSimulationData('deviceSettings')) {
    return deviceSimulation.fetchSaveDeviceSettings(pairInput);
  }

  return performDisplayFetch({
    'query': 'mutation pairDevice($input:PairDeviceInput!) {  pairDevice(input: $input) { success }}',
    'variables': {
      'input': pairInput
    }
  });
};

const saveDeviceSettings = (deviceInput) => {

  if (useSimulationData('deviceSettings')) {
    return deviceSimulation.fetchSaveDeviceSettings(deviceInput);
  }

  return performDisplayFetch({
    'query': 'mutation SaveDevice($deviceInput:DeviceInput!) {  saveDevice(deviceInput: $deviceInput) {id ownerId truckPid esn active } }',
    'variables': {
      'deviceInput': deviceInput
    }
  });
};

const fetchTrucksForOwner = () => {

  if (useSimulationData('deviceSettings')) {
    return deviceSimulation.fetchTrucks();
  }

  return performDisplayFetch({
    'query': '{ trucksForDevice { truckPid truckName } }'
  });
};


export {
  fetchDeviceSettings,
  pairDeviceSettings,
  fetchTrucksForOwner,
  saveDeviceSettings
}