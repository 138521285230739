import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';
import CategoryIcon from '@mui/icons-material/Category';
import { Typography, IconButton, Box } from '@mui/material';

import ComponentTypes from '../../componentTypes';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';

import componentLifeCardChartOptions from '../componentLife/componentLifeCardChartOptions';
import { componentLifeRoadsideState } from '../../../state/cards/componentLifeRoadside/componentLifeRoadsideSelectors';
import * as componentLifeRoadsideActions from '../../../state/cards/componentLifeRoadside/componentLifeRoadsideActions';
import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();


const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,

  // TODO START - Rename and move these to common card styles with legacy cards are updated
  cardContainerReplace: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    justifyContent: 'space-evenly',
  },
  chartLabel: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '125px',
  },
  chartSubLabel: {
    width: '125px',
  },
  chartTotalLabel: {
    display: 'flex',
    width: '50px',
    justifyContent: 'flex-end',
  },
  chartRowActionButton: {
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      color: 'grey.500'
    }
  },
  cardHeaderContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  chart: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '100%',
  },
  chartRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  chartWithTotalLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  chartWithTotalLabelAndNavButton: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  chartNoDataHolder: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '185px'
  },
  chartNoDataTotalLabel: {
    width: '50px'
  },
  chartNoDataNavButton: {
    width: '48px'
  },
  noDataHolder: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    flexGrow: 1
  },
};

class ComponentLifeRoadside extends Component {

  componentDidMount() {
    // Load the card data if we have a context.
    if (!_.isNil(this.props.selectedContext))  {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    // Refresh the card data when the context changes.
    if (prevProps.selectedContext !== this.props.selectedContext)  {
      this.refresh();
    }
  }

  refresh() {
    if (_.isNil(this.props.selectedContext)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.selectedContext.id);
    }
  }

  render() {

    return (
      <Box sx={styles.cardContainerReplace}>
        {/* Card Header */}
        <Box sx={styles.cardHeader}>
          <Box sx={styles.cardHeaderContent}>
            <Typography variant={'subtitle1'}>ROADSIDE COMPONENT LIFE</Typography>
          </Box>
        </Box>
        {/* Card Content */}
        {
          !(_.isEmpty(this.props.components)) &&
          <Box sx={styles.cardContent}>
            {
              this.props.components.map((component, index) => {
                return (
                  <Box key={index} sx={styles.chartRow}>
                    <Box sx={styles.chartLabel}>
                      <Typography sx={styles.chartLabel} variant={'subtitle1'}>{component.displayName}</Typography>
                      <Typography sx={styles.chartSubLabel} variant={'caption'}>{component.model}</Typography>
                    </Box>
                    <Box sx={styles.chartWithTotalLabelAndNavButton}>
                      {
                        !(_.isNull(component.primaryLife.value)) &&
                        <Box sx={styles.chartWithTotalLabel}>
                          <Box sx={styles.chart}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={componentLifeCardChartOptions(
                                _.isNil(component.primaryLife)
                                  ? null
                                  : component.primaryLife.value,
                                component.expectedLife
                              )}
                            />
                          </Box>
                          <Box sx={styles.chartTotalLabel}>
                            <Typography variant={'body2'}>{component.expectedLife}</Typography>
                          </Box>
                        </Box>
                      }
                      {
                        _.isNull(component.primaryLife.value) &&
                        <Box sx={styles.chartNoDataHolder}>
                          <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
                        </Box>
                      }
                      {
                        _.isNull(component.primaryLife.value) &&
                        <Box sx={styles.chartNoDataTotalLabel} />
                      }
                      <IconButton
                        sx={styles.chartRowActionButton}
                        onClick={() => this.props.navigateToComponentsDisplay(this.props.selectedContext.id, component.type)}
                        size="large">
                        <CategoryIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })
            }
          </Box>
        }
        {
          (_.isEmpty(this.props.components)) &&
          <Box sx={styles.noDataHolder}>
            <Typography variant={'caption'} sx={styles.noDataLabel}>No Data</Typography>
          </Box>
        }
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENT_LIFE_ROADSIDE : props.stateKey,
      type: ComponentTypes.COMPONENT_LIFE_ROADSIDE,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentLifeRoadsideState(state[stateDef.key]);
  return {
    components: componentState.components
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: (context) => { dispatch(componentLifeRoadsideActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(componentLifeRoadsideActions.clearData(props.stateDef)) },
    navigateToComponentsDisplay: (unitId, componentType) => { dispatch(componentsActions.navigate(unitId, componentType)) },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(ComponentLifeRoadside))
