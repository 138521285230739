import {performCardFetch} from "../../common/fetch";
import useSimulationData from "../../../helpers/simulationHelper";
import * as alarmCountSimulation from "./alarmCountSimulation";

const fetchFleetAlarmCountData = (fleetId) => {
    if (useSimulationData('alarmCountCard')) {
      return alarmCountSimulation.fetchAlarmCountData(true);
    }

    return performCardFetch({
        'query': '{ fleetAlarmCount(fleetId: {fleetId}) {criticalAlarmCount warningAlarmCount infoAlarmCount name activeAlarms{truckId alarmKey raisedTimestamp acknowledgedTimestamp component details displayName event severityValue subType type truckName}} }'
          .replace('{fleetId}', fleetId)
    })
};

const fetchPumpAlarmCountData = (truckId) => {
  if (useSimulationData('alarmCountCard')) {
    return alarmCountSimulation.fetchAlarmCountData(false);
  }

  return performCardFetch({
    'query': '{ unitAlarmCount(truckId: {truckId}) {criticalAlarmCount warningAlarmCount infoAlarmCount name activeAlarms{truckId alarmKey raisedTimestamp acknowledgedTimestamp component details displayName event severityValue subType type}} }'
      .replace('{truckId}', truckId)
  })
};


export {
  fetchFleetAlarmCountData,
  fetchPumpAlarmCountData
}