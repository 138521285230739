import _ from 'lodash';

const componentLifeRoadsideState = state => {

  return {
    queryRunning : _.isNil(state) ? false : state.queryRunning,
    components: _.isNil(state) ? [] : state.components,
  }
};

export {
  componentLifeRoadsideState
};
