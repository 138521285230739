import React, {Component} from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import {compose, withProps} from 'recompose';
import moment from 'moment';
import {parse} from 'query-string';
import * as appNavActions from '../../../state/app/actions/appNavActions';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Icon,
  Popover,
  TablePagination,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import componentTypes from "../../../components/componentTypes";

import {MaterialActionElement, MaterialValueEditor, QueryBuilderMaterial} from '@react-querybuilder/material';
import {QueryBuilderDnD} from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import {QueryBuilder} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer-continued';

import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';

import Progress from '../../controls/progress';
import getCardStyles from '../../cards/cardStyles';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getDataGridStyles from "../../common/styles/dataGridStyle";
import getDiffViewerStyles from '../../common/styles/diffViewerStyles';
import ComponentTypes from '../../componentTypes';
import {AutoCompleteMDT, DateTimePickerMDT} from '../../controls/mdtMuiControls';
import MDTDataGrid from '../../common/table/MDTDataGrid';
import {mdtPalette} from '../../common/styles/mdtPalette';

import {appState as applicationState} from '../../../state/app/appSelectors';

import {auditLogsState} from '../../../state/displays/auditLogs/auditLogsSelectors';
import * as auditLogsActions from '../../../state/displays/auditLogs/auditLogsActions';

import {trackPage} from '../../../helpers/googleAnalyticsHelper';
import {v4 as uuidv4} from 'uuid';

const cardStyles = getCardStyles();
const detailsPageStyles = getDetailsPageStyles();
const dataGridStyle = getDataGridStyles();
const diffViewerStyle = getDiffViewerStyles();

const styles = {
  ...detailsPageStyles,
  detailsPage: {
    ...detailsPageStyles.detailsPage,
    height: '100vh'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw',
    height: 'inherit'
  },
  viewContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1
  },
  cardHeader: {
    ...cardStyles.cardHeader,
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0
  },
  cardFooter: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    borderStyle: 'solid',
    borderBottomWidth: '0px',
    borderTopWidth: '1px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderTopColor: 'primary.main',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
  },
  actionButton: {
    backgroundColor:'grey.200',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
    '&:hover': {
      backgroundColor:'grey.400',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
    }, 
    marginBottom: 1.25
  },
  queryBuilderHeadersIcon: {
    width: '17px'
  },
  queryBuilderHeadersChevronRightIcon: {
    position: 'relative', 
    left: '-3px'
  },
  queryBuilderContentDivider: {
    height: 'auto', 
    margin: 1, 
  },
  queryBuilderTimeSection: {
    display: 'flex', 
    flexFlow: 'column',  
    width: '45%', 
    maxWidth: '796px'
  },
  queryBuilderRulesSection: {
    display: 'flex', 
    flexFlow: 'column', 
    width: '45%', 
    flexGrow: 1
  },
  queryBuilderRunSection: {
    display: 'flex', 
    flexFlow: 'column nowrap', 
    width: '10%', 
    maxWidth: '150px'
  },
  queryBuilderContainer: {
    '& .queryBuilder-branches': {
      width: '100%',
    },
    '& .ruleGroup': {
      borderWidth: '0px',
      background: 'transparent',
    },
    '& .queryBuilder-branches .betweenRules': {
      display: 'none'
    },
    '& .queryBuilder-branches .rule::before, .queryBuilder-branches .ruleGroup .ruleGroup::before': {
      borderColor: '#ce4300'
    },
    '& .queryBuilder-branches .rule::after, .queryBuilder-branches .ruleGroup .ruleGroup::after': {
      borderColor: '#ce4300'
    },
    '& .ruleGroup-addRule': {
      backgroundColor: '#ce4300',
      color: 'white',
      '&:hover': {
        backgroundColor: '#902E00',
      }
    },
    '& .rule-remove': {
      backgroundColor: '#ce4300',
      color: 'white',
      minWidth: '16px',
      maxWidth: '16px',
      maxHeight: '16px',
      minHeight: '16px',
      '&:hover': {
        backgroundColor: '#902E00',
      }
    },
    '& .rule-fields': {
      minWidth: '200px',
    },
    '& .rule-operators': {
      minWidth: '130px',
    },
    '[data-inlinecombinators=enabled]': {
      '.dndOver.ruleGroup-header': {
        borderBottomStyle: 'dashed', 
        borderBottomColor: '#ce4300',
        borderBottomWidth: '1px'
      },
      '.dndOver.rule:last-child': {
        borderBottomStyle: 'dashed', 
        borderBottomColor: '#ce4300',
        borderBottomWidth: '1px'
      },
      '.dndOver.betweenRules': {
        borderBottomStyle: 'dashed', 
        borderBottomColor: '#ce4300',
        borderBottomWidth: '1px'
      }
    },
    '& .rule-value': {
      display: 'flex',
      flexFlow: 'row nowrap',
      flexGrow: 1,
    },
    '& .rule.queryBuilder-invalid': {
      '& .rule-value': {
        '::before': {
          borderBottomColor: 'red'
        }
      },
      '& .MuiTextField-root': {
        '> ::before': {
          borderBottomColor: 'red'
        }
      },
    }
  }
}

const customGridToolbar = () => {
  return (
    <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1}}>
        <GridToolbarColumnsButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Box>
      <GridToolbarQuickFilter sx={{width: '255px'}} />
    </GridToolbarContainer>
  )
}

const customNoRowsOverlay = () => {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
      <Typography variant='subtitle1'>{'Use the Query Builder to query for Audit Log Data'}</Typography>
    </Box>
  )
}

const CustomDragHandle = React.forwardRef((props, ref) => {

  // Removes some warning about the testID and ruleOrGroup props being invalid React props
  // see https://legacy.reactjs.org/warnings/unknown-prop.html
  const newProps = Object.assign({}, props);
  newProps.testid = props.testID;
  newProps.ruleorgroup = props.ruleOrGroup;
  delete newProps.testID;
  delete newProps.ruleOrGroup;

  return (
    <Icon ref={ref} {...newProps}>
      <Tooltip title={'Reorder Rule'} disableInteractive followCursor>
        <DragHandleIcon />
      </Tooltip>
    </Icon>
  )
});

const CustomValueEditor = (props) => {

  // In and Not In require an AutocompleteMDT control to allow for free form entry of multiple values
  if (props.operator.toLowerCase() === 'in' || props.operator.toLowerCase() === 'not_in') {
    return (
      // The position and top values here will make the autocomplete control line up with the rest of the controls in the query builder
      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, '& .MuiTextField-root': { width: '100%', position: 'relative', top: (_.isEmpty(props.value) ? '0px' : '-3.5px') }}}>
        <AutoCompleteMDT
          sx={{width: '100%'}}
          multiple
          freeSolo
          options={[]}
          value={props.value}
          isOptionEqualToValue={(option, value) => option !== option}
          onChange={(event, value, reason) => {
            if(value.filter(v => v == event.target.value).length < 2 ){ // avoid duplicate input
              props.handleOnChange(value);
            }
          }}
          renderInput={(params) => <TextField {...params} variant='standard' />}
        />
      </Box>
    )
  }

  // Default value editor
  return <MaterialValueEditor {...props} />;
}

const CustomRemoveActionElement = (props) => {

  const newProps = Object.assign({}, props);

  if (_.includes(props.ruleOrGroup.field.toLowerCase(), 'timestamp')) {
    newProps.disabled = true;
  }

  // Default remove action element (button)
  return <MaterialActionElement {...newProps} />;
}

/**
 * This function is used by the QueryBuilder to validate the query.
 * By using this function, rather than our own custom validation on the Redux side, we can take advantage of the QueryBuilder's built-in
 * validation UI.
 * @param {*} query 
 * @returns 
 */
const QueryValidator = (query) => {

  const validationMap = {};
  let validQuery = true;

  _.forEach(query.rules, (rule) => {
    if (!_.isNil(rule.value) && _.isEmpty(rule.value.toString().trim())) {
      validationMap[rule.id] = {valid: false, reasons: ['Value is required']};
    } else {
      if (!_.isNil(rule.id)) {
        validationMap[rule.id] = "true";
      }
    }
  });

  Object.keys(validationMap).forEach(key => {
    if (validationMap[key] !== "true") {
      validQuery = false; // Found an invalid rule
    }
  });

  if (_.isEmpty(validationMap)) {
    validQuery = false;
  }

  return {validationMap, validQuery} ;
}

/**
 * Use the default TablePagination control to create a custom one that will disable the Next button if the current page has no rows.
 * This will prevent needless queries to the server since we are doing server side pagination.
 * @returns A Custom TablePagination control where the Next button is disabled if the current page has no rows.
 */
const CustomTablePagination = (rowsPerPageOptions) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <TablePagination
      component='div'
      slotProps={{
        actions: {
          nextButton: { disabled: ( apiRef.current.state.rows.ids.length === 0 ) }
        }
      }}
      count={pageCount}
      rowsPerPage={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      page={page}
      onPageChange={(event, page) => apiRef.current.setPage(page)}
      onRowsPerPageChange={(event) => apiRef.current.setPageSize(parseInt(event.target.value, 10))}
    />
  );
}

class AuditLogsDisplay extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      diffViewerRows: [], 
      anchorEl: null,
      hoverContentOldState: '',
      hoverContentNewState: '',
    }
  }

  componentDidMount() {
    this.props.onLoadMetaData();

    // Track GA page views
    trackPage(ComponentTypes.AUDIT_LOGS, this.props.user);

    // This will use the parse function from query-string package to parse out the query parameters from the URL
    const parsedLocation = parse(location.search);
    this.foundStartTime = parsedLocation.startTime;
    this.foundEndTime = parsedLocation.endTime;
    this.foundAssetName = parsedLocation.datavanName || parsedLocation.unitNumber;
    this.foundSource = parsedLocation.source;
  }

  componentDidUpdate() {
      this.parseAndLoadQuery();
  }

  /**
   * This function will parse the query parameters from the URL and load in state.
   * It will also load the query results.
   */
  parseAndLoadQuery() {
    // We will only load the query and query results when:
    // 1. The metadata availableFields has been loaded
    // 2. The assetName provided in the URL
    if (!_.isNil(this.foundAssetName) && !_.isEmpty(this.props.availableFields)) {
      // set the timeframe in state, if not sufficient parameters are found, or invalid time value provided, the default timeframe will be used
      if (!_.isEmpty(this.foundStartTime) && !_.isEmpty(this.foundEndTime)
          && moment.unix(this.foundStartTime).isValid() && moment.unix(this.foundEndTime).isValid()
          && !_.isEmpty(this.props.customDurations)) {
        this.props.setTimeFrame({"value": null, "label": "Custom"});
        // expect passed in unix timestamp
        this.props.setCustomStartTime(moment.unix(this.foundStartTime));
        //calculate the duration in minutes
        const durationMin = (this.foundEndTime - this.foundStartTime) / 60;
        let selectedDuration = this.props.customDurations.find(option => option.value === durationMin);
        // if no match duration option found, default to use the first option
        if (_.isNil(selectedDuration)) {
          selectedDuration = this.props.customDurations[0];
        }
        this.props.setCustomDuration(selectedDuration);
      }
      // construct the query  
      let query = {
        id: uuidv4(),
        rules: [
          {
            id: uuidv4(),
            field: this.foundSource === componentTypes.PUMP_DASHBOARD ? "unitNumber" : "datavanName",
            operator: "=",
            valueSource: "value",
            value: this.foundAssetName
          }
        ],
        not: false
      }
      const validQuery = QueryValidator(query).validQuery;
      if(validQuery){
        // set the query in state
        this.props.onSetQuery(query, validQuery);
        // load the query results with page 0
        this.props.onQueryAuditLogs(query, 0);
      }

      // Whether or not the provided context is valid...
      // Reset the URL to not include the context - we need to do this otherwise it will remain and any further actions on
      // this display will not know this context is not applicable anymore (ie. user selects a new context via the context selector)
      this.props.resetUrl();
      this.foundStartTime = null;
      this.foundEndTime = null;
      this.foundAssetName = null;
      this.foundSource = null;
    }
  }

  getOperators(fieldName) {
    const field = this.props.availableFields.find(f => f.name === fieldName);
    return field ? field.availableOperators : [];
  }

  handleSearch = () => {
    // Search should always start at page 0, regardless of what page we were at before
    this.props.onQueryAuditLogs(this.props.query, 0);
  }

  handleReset = () => {
    this.props.onLoadDefaultQuery();
  }

  handlePopoverOpen = (event, oldState, newState) => {
    this.setState({
      anchorEl: event.currentTarget,
      hoverContentOldState: oldState,
      hoverContentNewState: newState,
    });
  };

    handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
      hoverContentOldState: '',
      hoverContentNewState: ''
    });
  };

  queryResultsColumns =
  [
    {
      field: 'timestamp',
      headerName: 'Time',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'datavanName',
      headerName: 'Datavan Name',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'truckName',
      headerName: 'Truck Name',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'actionType',
      headerName: 'Action Type',
      headerAlign: 'center',
      align: 'left',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'detail',
      headerName: 'Detail',
      headerAlign: 'center',
      align: 'left',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'oldNewState',
      headerName: 'State',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: false,
      sortable: false,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
        renderCell: (params) => {
          const { oldState, newState } = params.row;
          // As oldState and newState are not JSON strings, we would need to heavily manipulate the data before parsing and stringify-ing it to get the correct format
          return (
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between', width: '80%'}}>
              <Typography 
              variant='body2'
              aria-owns={this.state.anchorEl ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => this.handlePopoverOpen(e, oldState, newState)}
              onMouseLeave={this.handlePopoverClose}
              sx={{cursor: 'pointer', paddingLeft: '5px', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis'}}>{newState}</Typography>
            </Box>
          );
        }
      },
    {
      field: 'source',
      headerName: 'Source',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'subType',
      headerName: 'Sub Type',
      headerAlign: 'center',
      align: 'left',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
    {
      field: 'uiComponent',
      headerName: 'UI Component',
      headerAlign: 'center',
      align: 'left',
      width: 200,
      flex: 1,
      editable: false,
      sortable: true,
      hideable: true,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: false,
    },
  ]
  
  render() {

    let showAdditionalTimeFilters = !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';
    
    let startTime = this.props.selectedTimeFrame.label === 'Custom' ? moment(this.props.selectedCustomStartTime).format('lll') :  moment().subtract(this.props.selectedTimeFrame.value, 'minutes').startOf('minute').format('lll');
    let endTime = this.props.selectedTimeFrame.label === 'Custom' ? moment(this.props.selectedCustomStartTime).add(this.props.selectedCustomDuration.value, 'minutes').startOf('minute').format('lll') : moment().format('lll');
    const { anchorEl, hoverContentOldState, hoverContentNewState } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Audit Logs</Typography>
            </Box>
          </Box>
          <Box sx={styles.viewContainer}>
            {/* Query Card */}
            <Accordion sx={{
                '& .MuiAccordionSummary-content': {
                  marginBottom: 1,
                  marginTop: 1,
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  marginBottom: 1,
                  marginTop: 1
                }
              }}
              expanded={this.props.shouldExpandQueryPanel}
              onChange={() => { this.props.onExpandQueryPanel(!this.props.shouldExpandQueryPanel)}} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1}}>
                  <Typography variant='caption' gutterBottom>QUERY BUILDER</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{paddingTop: 0}}>
                <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, width: '100%', marginBottom: 1}}>
                  {/* Headers */}
                  <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                    <Box sx={styles.queryBuilderTimeSection}>
                      <Typography sx={{marginLeft: 1}} variant='caption'>SET your query time frame</Typography>
                    </Box>
                    <Icon sx={styles.queryBuilderHeadersIcon}><ChevronRightIcon sx={styles.queryBuilderHeadersChevronRightIcon} color='primary' size='small'/></Icon>
                    <Box sx={styles.queryBuilderRulesSection}>
                      <Typography sx={{marginLeft: 1}} variant='caption'>DEFINE your query parameters</Typography>
                    </Box>
                    <Icon sx={styles.queryBuilderHeadersIcon}><ChevronRightIcon sx={styles.queryBuilderHeadersChevronRightIcon} color='primary' size='small'/></Icon>
                    <Box sx={styles.queryBuilderRunSection}>
                      <Typography sx={{marginLeft: 1}} variant='caption'>RUN your query</Typography>
                    </Box>
                  </Box>
                  {/* Content */}
                  <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
                    <Box sx={{...styles.queryBuilderTimeSection, borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: '#ce4300'}}>
                      <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center'}}>
                          <Box sx={styles.timeFilterContainer}>
                            <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                            <Box sx={styles.timeFilterItem}>
                              <AutoCompleteMDT
                                sx={styles.timeFrameSelection}
                                getOptionLabel={(timeFrame) => timeFrame.label}
                                options={this.props.timeFrames}
                                value={this.props.selectedTimeFrame}
                                onChange={(event, value, reason) => {
                                  this.props.setTimeFrame(value);
                                }}
                                noOptionsText={"No data found..."}
                              />
                            </Box>
                          </Box>
                          {showAdditionalTimeFilters === true && (
                            <Box sx={styles.timeFilterContainer}>
                              <Typography variant={"subtitle1"}>START:</Typography>
                              <Box sx={styles.timeFilterItem}>
                                <div>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePickerMDT
                                      value={this.props.selectedCustomStartTimeDisplay}
                                      onAccept={(value) => {
                                        if (!_.isNil(value)) {
                                          this.props.setCustomStartTime(value);
                                        }
                                      }}
                                      onChange={(value) => {
                                        if (!_.isNil(value)) {
                                          this.props.setCustomStartTimeDisplay(value);
                                        }
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </Box>
                              <Typography variant={"subtitle1"}>DURATION:</Typography>
                              <Box sx={{...styles.timeFilterItem, paddingRight: 0}}>
                                <AutoCompleteMDT
                                  sx={styles.timeFrameSelection}
                                  options={this.props.customDurations}
                                  value={this.props.selectedCustomDuration}
                                  onChange={(event, value, reason) => {
                                    this.props.setCustomDuration(value);
                                  }}
                                  noOptionsText={"No durations found..."}
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly'}}>
                          <Typography variant='subtitle1'>Start Time: {startTime}</Typography>
                          <Typography variant='subtitle1'>End Time: {endTime}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={styles.queryBuilderContentDivider} orientation='vertical' />
                    <Box sx={{...styles.queryBuilderRulesSection, maxHeight: '200px', overflowY: 'auto', borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: '#ce4300'}}>
                      <Box sx={{...styles.queryBuilderContainer, display: 'flex', flexFlow: 'row', width: '100%', height: '100%', justifyContent: 'flex-start'}}>
                        {
                          <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                            <QueryBuilderMaterial>
                              <QueryBuilder 
                                controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                                independentCombinators={true}
                                combinators={this.props.combinators}
                                getOperators={(fieldName) => { return this.getOperators(fieldName);}}
                                fields={this.props.availableFields}
                                controlElements={{
                                  addGroupAction: () => null,
                                  dragHandle: CustomDragHandle,
                                  valueEditor: CustomValueEditor,
                                  removeRuleAction: CustomRemoveActionElement
                                }}
                                onQueryChange={(query) => { 
                                  const validQuery = QueryValidator(query).validQuery;
                                  this.props.onSetQuery(query, validQuery);
                                }}
                                query={this.props.query}
                                validator={(query) => { return QueryValidator(query).validationMap;}}
                                translations={{
                                  "dragHandle": {
                                    "title": ""
                                  }
                                }}
                              />
                            </QueryBuilderMaterial>
                          </QueryBuilderDnD>
                        }
                      </Box>
                    </Box>                    
                    <Divider sx={styles.queryBuilderContentDivider} orientation='vertical' />
                    <Box sx={{...styles.queryBuilderRunSection, justifyContent: 'center', paddingLeft: 2, paddingRight: 2, borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: '#ce4300'}}>
                      <Button sx={styles.actionButton} variant={'contained'} size={'small'} onClick={this.handleReset}>Reset Query</Button>
                      <Button variant={'contained'} color={'primary'} size={'small'} onClick={this.handleSearch} disabled={!this.props.isQueryValid}>Search</Button>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* Query Results Card */}
            <Card sx={{marginBottom: 1, width: '100%', display: 'flex', flexFlow: 'column', flexGrow: 1}}>
              <CardContent sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1}}>
                <Box sx={styles.cardHeader}>
                  <Typography variant='caption' gutterBottom>QUERY RESULTS</Typography>
                </Box>
                <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, position: 'relative'}}>
                  <Backdrop sx={{position: 'absolute', zIndex: 1}} open={this.props.shouldDisableGrid}>
                    <Typography variant='h5'>Your Query Has Changed. Please 'Search' to get new results.</Typography>
                  </Backdrop>
                  <MDTDataGrid 
                    sx={{ 
                      ...dataGridStyle.root,
                      '& .MuiTablePagination-root': {
                        backgroundColor:  mdtPalette().materialUI.palette.background.paper,
                        backgroundImage: 'none',
                        display: 'flex'
                      },
                      '& .MuiTablePagination-toolbar': {
                        flexGrow: 1
                      },
                      '& .MuiTablePagination-displayedRows': { 
                        display: 'none'
                      },
                      '&  .MuiTablePagination-actions': {
                        display: 'flex',
                        flexFlow: 'row-reverse nowrap',
                        flexGrow: 1
                      },
                      '& .MuiIconButton-root:first-of-type': {
                        position: 'relative',
                        left: '-61px'
                      },
                      '& .MuiIconButton-root:last-child': {
                        position: 'relative',
                        left: '21px'
                      }
                    }}

                    columns={this.queryResultsColumns}
                    rows={this.props.auditLogs}
                    getRowId={(row) => row.id}
                    disableSelectionOnClick
                    stateDef={this.props.stateDef}
                    /* We use a super large number here because the DataGrid **requires** a rowCount, regardless if you're using server or client
                      side pagination. We hide the text that would show this value.
                      To do this properly, we'd need to run second query to get total rowCount for the current query.  */
                    rowCount={Number.MAX_SAFE_INTEGER}

                    pagination
                    paginationMode="server"

                    page={this.props.page}
                    onPageChange={this.props.setPage}
                    pageSize={this.props.rowsPerPage}
                    onPageSizeChange={this.props.setRowsPerPage}
                    rowsPerPageOptions={this.props.rowsPerPageOptions}

                    sorting 
                    sortingMode="server"
                    sortModel={this.props.sortModel}
                    onSortModelChange={this.props.setSortModel}

                    autoHeight={false}

                    components={{
                      Toolbar: customGridToolbar,
                      NoRowsOverlay: customNoRowsOverlay,
                      Pagination: (() => { return CustomTablePagination(this.props.rowsPerPageOptions) })
                    }}

                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            maxWidth: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Box p={1} sx={{ maxWidth: 600, minWidth: 200, overflow: 'auto' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
              <Typography variant="body2" sx={{ p: 1, color: '#bdbdbd', fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>
                Old State
              </Typography>
              <Typography variant="body2" sx={{ p: 1, color: '#bdbdbd', fontSize: '14px', fontWeight: 500, textAlign: 'center'}}>
                New State
              </Typography>
            </Box>
            <ReactDiffViewer
              oldValue={hoverContentOldState}
              newValue={hoverContentNewState}
              compareMethod={DiffMethod.WORDS}
              splitView={true}
              hideMarkers={true}
              showDiffOnly={false}
              hideLineNumbers={true}
              styles = {diffViewerStyle}
            />
          </Box>
        </Popover>
        <Progress open={this.props.queryRunning}/>
      </Box>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.AUDIT_LOGS : props.stateKey,
      type: ComponentTypes.AUDIT_LOGS,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;
  let componentState = auditLogsState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    queryRunning: componentState.queryRunning,
    auditLogs: componentState.auditLogs,

    availableFields: componentState.availableFields,
    combinators: componentState.combinators,

    query: componentState.query,

    page: componentState.page,
    rowsPerPage: componentState.rowsPerPage,
    rowsPerPageOptions: componentState.rowsPerPageOptions,
    sortModel: componentState.sortModel,

    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,

    shouldDisableGrid: componentState.shouldDisableGrid,
    shouldExpandQueryPanel: componentState.shouldExpandQueryPanel,

    isQueryValid: componentState.isQueryValid,

    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onLoadMetaData: () => { dispatch(auditLogsActions.queryAuditLogsMetadata(props.stateDef)); },
    onSetQuery: (query, isQueryValid) => { dispatch(auditLogsActions.setQuery(props.stateDef, query, isQueryValid)); },
    onLoadDefaultQuery: () => { dispatch(auditLogsActions.loadDefaultQuery(props.stateDef)); },
    onQueryAuditLogs: (query, page) => { dispatch(auditLogsActions.queryAuditLogs(props.stateDef, query, page)); },
    onClearAuditLogs: () => { dispatch(auditLogsActions.clearAuditLogs(props.stateDef)); },
    setPage: (page) => {dispatch(auditLogsActions.setPageAndQuery(props.stateDef, page)); },
    setRowsPerPage: (rowsPerPage) => {dispatch(auditLogsActions.setRowsPerPageAndQuery(props.stateDef, rowsPerPage)); },
    setSortModel: (sortModel) => {dispatch(auditLogsActions.setSortModelAndQuery(props.stateDef, sortModel)); },
    setTimeFrame: (timeFrame) => {dispatch(auditLogsActions.setTimeFrame(props.stateDef, timeFrame)); },
    setCustomStartTime: (startTime) => {dispatch(auditLogsActions.setCustomStartTime(props.stateDef, startTime)); },
    setCustomDuration: (duration) => {dispatch(auditLogsActions.setCustomDuration(props.stateDef, duration)); },
    setCustomStartTimeDisplay: (startTime) => {dispatch(auditLogsActions.setCustomStartTimeDisplay(props.stateDef, startTime)); },
    onExpandQueryPanel: (expanded) => {dispatch(auditLogsActions.expandQueryPanel(props.stateDef, expanded)); },
    resetUrl: () => { dispatch(appNavActions.resetUrl('/audit-logs')); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(AuditLogsDisplay));
