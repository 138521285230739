import reduceReducers from 'reduce-reducers';

import { pumpDashboardState } from './pumpDashboardSelectors';
import pumpReducer from './reducers/pumpReducer';
import filterReducer from './reducers/filterReducer';

const initialState = pumpDashboardState();

const pumpDashboardReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    pumpReducer,
    filterReducer
  );

  return reducer(state, action);

};

export default pumpDashboardReducer