import _ from 'lodash';

const unitFleetState = state => {

  return {
    // The card metrics
    name: _.isNil(state) ? '-' : state.name,
    type: _.isNil(state) ? '-' : state.type,
    previous: _.isNil(state) ? '-' : state.previous,
    fleetId: _.isNil(state) ? null : state.fleetId,
  };

};

export {
  unitFleetState
};