import reduceReducers from 'reduce-reducers';
import queryReducer from './reducers/queryReducer';
import formReducer from './reducers/formReducer';
import explorerReducer from './reducers/explorerReducer';
import filterReducer from './reducers/filterReducer';

import { exportState } from './exportSelectors';

const initialState = exportState();

const exportReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    formReducer,
    explorerReducer,
    filterReducer
  );

  return reducer(state, action);

};

export default exportReducer