import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import unitActivityDpfActionTypes from './unitActivityDfpActionTypes';
import * as unitActivityDfpQueries from './unitActivityDfpQueries';
import errorMessages from '../../common/errorMessages';
import unitActivityDfpTypes from './unitActivityDfpTypes';

const clearData = defaultActionFactory(unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_CLEAR_DATA, 'stateDef');

const queryData = (stateDef, chartType, truckId) => {
  return async (dispatch, getState) => {
    switch (chartType) {
      case unitActivityDfpTypes.CURBSIDE:
        await dispatch(queryCurbsideData(stateDef, truckId));
        break;
      case unitActivityDfpTypes.ROADSIDE:
        await dispatch(queryRoadsideData(stateDef, truckId));
        break;
      default:
        throw Error('Unknown chart type');
    }
  }
};

const queryCurbsideData = queryActionFactory(
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_STARTING,
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_SUCCESS,
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_ACTIVITY_CURBSIDE_CARD_DATA,
  unitActivityDfpQueries.fetchUnitActivityCurbsideCardData
);

const queryRoadsideData = queryActionFactory(
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_STARTING,
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_SUCCESS,
  unitActivityDpfActionTypes.UNIT_ACTIVITY_DFP_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_ACTIVITY_ROADSIDE_CARD_DATA,
  unitActivityDfpQueries.fetchUnitActivityRoadsideCardData
);

export {
  queryData,
  clearData
}