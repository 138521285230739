import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import appActionTypes from '../appActionTypes';
import { isGuid } from '../../../helpers/websocketHelper';

const closeWebSocket = defaultActionFactory(appActionTypes.APP_CLOSE_WEBSOCKET);

const sendMessage = defaultActionFactory(appActionTypes.APP_SEND_WEBSOCKET_MESSAGE, 'payload');

const dispatchProcessWebSocketData = (data) => {
  return async (dispatch, getState) => {
    try {

      if (isGuid(data.data) === true) {
        await dispatch(saveWebSocketClientId(data.data));
      } else {
        const dataObj = JSON.parse(data.data);
        await dispatch(processWebSocketData(dataObj.stateDef, dataObj));
      }
    } catch (error) {
      // Do nothing
    }
  };
};

const processWebSocketData = defaultActionFactory(appActionTypes.APP_PROCESS_WEBSOCKET_DATA, 'stateDef', 'data');
const saveWebSocketClientId = defaultActionFactory(appActionTypes.APP_SAVE_WEBSOCKET_CLIENTID, 'clientId');
const updateWebSocketConnectionStatus = defaultActionFactory(appActionTypes.APP_UPDATE_WEBSOCKET_CONNECTION_STATE, 'status');
const saveWebsocketIntervalId = defaultActionFactory(appActionTypes.APP_SET_WEBSOCKET_INTERVAL_ID, 'intervalId');
const connectWebSocket = defaultActionFactory(appActionTypes.APP_WEBSOCKET_CONNECT);
const saveSubscription = defaultActionFactory(appActionTypes.APP_WEBSOCKET_SAVE_SUBSCRIPTION, 'subscription');

export {
  closeWebSocket,
  sendMessage,
  processWebSocketData,
  dispatchProcessWebSocketData,
  saveWebSocketClientId,
  updateWebSocketConnectionStatus,
  saveWebsocketIntervalId,
  connectWebSocket,
  saveSubscription
}