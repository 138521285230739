import React, {Component} from 'react';
import ReactGridLayout from 'react-grid-layout';
import _ from 'lodash';

// These two stylesheets come from the react-grid-layout library.
// We reference local versions because we updated some of the colors etc.
import './react-grid-layout.css';
import './react-resizable.css';
import { Box } from '@mui/system';
import { mdtPalette } from '../../common/styles/mdtPalette';
import PropTypes from 'prop-types';

const styles = (props) => {
  return {
    container: {
      backgroundColor: mdtPalette().materialUI.palette.grey[800],
      width: (props.width - 10) + "px",
      height: '100%',
      // Creates grid with a border the same colour as background, making it look like filled in squares
      backgroundImage: `repeating-linear-gradient(${mdtPalette().materialUI.palette.background.default} 0 12px, transparent 12px 100%), repeating-linear-gradient(90deg, ${mdtPalette().materialUI.palette.background.default} 0 12px, transparent 12px 100%)`,
      backgroundSize: "170px 170px",
      marginBottom: "10px",
    }
  }
}

class LayoutFixedWidth extends Component {
  render() {
    const { isEditMode, ...other } = this.props;
    return (
      // Only apply grid in edit mode
      <Box sx={isEditMode === true ? styles(this.props).container : {}}>
        <ReactGridLayout
          isResizable={false}
          isDraggable={false}
          compactType={null}
          preventCollision={true}
          // Since we are not using resizing now, disable the handles
          resizeHandles={[]}
          style={isEditMode === true ? {background: "none", transform: "translate(1px, 1px)"} : {}} // transform is to handle grid showing through corners of cards
          { ...other }>
        </ReactGridLayout>
      </Box>
    )
  }
}

LayoutFixedWidth.propTypes = {
  width: PropTypes.number.isRequired,
}

export default LayoutFixedWidth;
