export default {
  DASHBOARD: 'DASHBOARD',
  OPERATION: 'OPERATION',
  COMPONENT: 'COMPONENT',
  HEALTH: 'HEALTH',
  DGB: 'DGB',
  LIVE_VIEW: 'LIVE_VIEW',
};


