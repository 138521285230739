import _ from 'lodash';
import React, {Component} from 'react';
import { Typography, Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { mdtPalette } from '../../../common/styles/mdtPalette';

const styles = {
  chartAndChartLabelsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    width: '100px'
  },

  chartContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100px',
  },

  chart: {
    width: '100px',
    height: '15px',
  },

  chartLabelsContainers: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },

  chartInnerTextLabel: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#000000',
    fontSize: '12px'
  },

  tooltipValuesContainer: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },

  tooltipValuesSpacer: {
    width: '5px'
  },

  tooltipTitle: {
    fontWeight: 700
  },
};

class Bar extends Component {

  // Do some math to calculate the amount of bar to show as a percentage of the total bar size
  // We will return a value between 0 and the total passed in
  // For example, if total = 100, then we will return a value between 0 and 100
  calculateSize(value, total) {
    if (isNaN(value) || isNaN(total)) {
      return 0;
    }

    if (value < 0 || total <= 0 || !total) {
      // If we have a valid total but value is negative, then just return 0
      // so it shows as an empty bar
      if (value < 0 && total > 0) {
        return 0;
      }
      // In case we get an invalid value or total,
      // let's just return 100 for the size so it displays properly
      return 100;
    }

    let returnValue = (value/total) * 100;

    return returnValue > 100 ? 100 : returnValue;
  }

  render() {
    const warnThreshold = this.props.total * 0.8;

    let valueColor = this.props.valueColor;
    if (this.props.enableThresholds === true) {
      valueColor = this.props.value > warnThreshold ? mdtPalette().status.level4 : valueColor;
      valueColor = this.props.value > this.props.total ? mdtPalette().status.level5 : valueColor;
    }

    let barValue = this.calculateSize(this.props.value, this.props.total);
    let barTotal = this.calculateSize(this.props.total, this.props.total);

    let barValueStyle = {
      width: barValue + 'px',
      height: '15px',
      backgroundColor: valueColor,
      position: 'absolute'
    };

    let barValueLabelStyle = {
      height: '15px',
      position: 'absolute',
      paddingLeft: '2px',
      width: '100px',
      display: 'flex'
    };

    let barTotalStyle = {
      width: barTotal + 'px',
      height: '15px',
      backgroundColor: this.props.totalColor,
      position: 'absolute'
    };

    return (
        <Tooltip disableFocusListener followCursor={true} title={
          <React.Fragment>
            <Typography variant={'caption'} color="inherit" sx={styles.tooltipTitle}>{this.props.label}</Typography>
            <Box sx={styles.tooltipValuesContainer}>
              <Typography variant={'caption'} color="inherit">Min: 0</Typography>
              <Box sx={styles.tooltipValuesSpacer}/>
              <Typography variant={'caption'} color="inherit">Max: {this.props.total}</Typography>
            </Box>
          </React.Fragment>
        } >
          <Box sx={styles.chartAndChartLabelsContainer}>
            {/* Chart */}
            <Box sx={styles.chart}>
              <Box sx={barTotalStyle}/>
              <Box sx={barValueStyle}>
                <Box sx={barValueLabelStyle}>
                  <Typography sx={styles.chartInnerTextLabel} variant={'caption'}>{_.isNil(this.props.value) ? 'No Data' : this.props.value}</Typography>
                </Box>
              </Box>
            </Box>
            {/* Labels below the chart */}
            <Box sx={styles.chartLabelsContainers}>
              <Typography variant={'caption'}>{this.props.label}</Typography>
            </Box>
          </Box>
        </Tooltip>
    );
  }
}

Bar.propTypes = {
  label: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  totalColor: PropTypes.string.isRequired,
  valueColor: PropTypes.string.isRequired,
  enableThresholds: PropTypes.bool,
};

export default Bar;