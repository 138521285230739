import _ from "lodash";
import moment from "moment";

/**
 * Validate email format
 * @param email
 */
const validateEmail = (email) => {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};

/**
 * Validate customer changes
 * @param customer
 * @return list of errors
 */
const validateCustomerChanges = (customer) => {
    const customerErrors = {};
    if (_.isNil(customer.name) || _.isEmpty(customer.name.trim())) {
        customerErrors.hasNameError = true;
    }
    if (!_.isEmpty(customer.email) && !validateEmail(customer.email)) {
        customerErrors.hasEmailError = true;
    }
    return customerErrors;
};

/**
 * Compare and check if customer related info has been changed or not
 * @param original
 * @param current
 */
const hasCustomerChanged = (original, current) => {
    return !(
        original.name === current.name &&
        original.location === current.location &&
        original.note === current.note &&
        original.contactName === current.contactName &&
        original.email === current.email &&
        original.phone === current.phone
    );
};

export {
    validateEmail,
    validateCustomerChanges,
    hasCustomerChanged,
}