import _ from "lodash";
import moment from "moment";

import * as filterService from '../../../common/filtering/filterService';
import { getAssetNameByType, assetNames } from '../../../common/services/assetTypeService';

const prettifyValue = (value) => {
  let noValue = '-';
  return !_.isNil(value) ? moment.utc(value*1000).fromNow() : noValue;
};

const resolveSelectedContext = (contexts, contextId) => {

  let selectedContext = null;

  // Validate that we have a context with the requests id
  if (!_.isNil(contextId) && !_.isEmpty(contexts)) {
    let match = _.find(contexts, { truckId: contextId });
    selectedContext = _.isNil(match) ? null : match;
  }

  // If we did not resolve a selected context, but we do have contexts, set it to the first element.
  if (_.isNil(selectedContext) && !_.isEmpty(contexts)) {
    selectedContext = contexts[0];
  }

  return selectedContext;
};

const processTrucks = (trucks, filters) => {
  // Map the trucks into context objects for the dashboard UI
  let contexts = mapTrucksToContexts(trucks);
  // Filter the pump contexts by the current filters (in order)
  let filteredContexts = filterService.filterItems(contexts, filters);
  // Generate filter data from the filtered equipment list
  let updatedFilters = filterService.generateFilters(filteredContexts, filterDefinitions());

  return {
    contexts: filteredContexts,
    filters: updatedFilters
  };
};

const mapTrucksToContexts = (trucks) => {

  let contexts = [];

  if (!_.isNil(trucks) && !_.isEmpty(trucks)) {
    contexts = _.orderBy(trucks, 'truckName').map((truck, index) => {
      return {
        ...truck,
        displayType: getAssetNameByType(truck.unitType),
        value: index,
        label: truck.truckName,
      };
    });
  }

  return contexts;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition(assetNames.DATAVAN, 'datavan'));
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Type', 'displayType'));
  return definitions;
};

export {
  prettifyValue,
  resolveSelectedContext,
  processTrucks
}