import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import UnitAlarmBadge from '../unitAlarmBadge';

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,

  totalsContainer: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },

  valueStyle: {
    fontSize: '20px',
    fontWeight: 500
  },

  // Override the default unitContent style
  unitContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '90%'
  },
};

class Datavan extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.datavanMapContainer}>
        <Box sx={styles.datavanVisualContent}>
          <Box sx={styles.datavanVisualHeaderContent}>
            <Typography sx={styles.datavanAssetLabel} variant={'subtitle1'}>{data.name}</Typography>
          </Box>

          <Box sx={styles.unitVisualContent}>
            <Box sx={styles.unitContent}>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.totalSand}</Typography>
                <Typography variant={'caption'}>Total Sand (lbs)</Typography>
              </Box>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.totalDischarge}</Typography>
                <Typography variant={'caption'}>Total Discharge (bbl)</Typography>
              </Box>
              <Box sx={styles.dataVanBadgeContainer}>
                <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

Datavan.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Datavan;