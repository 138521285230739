import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Typography, Box, CircularProgress } from '@mui/material';
import { loadUserInformation } from '../../../state/app/actions/appUserActions';

const styles = {
  page: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 18,
  },
  message: {
    paddingTop: 2
  }
};

class LoadingPage extends Component {

  componentDidMount() {
    // Load the user information if it has not yet been loaded.
    if (!this.props.userInfoLoaded) {
      this.props.loadUserInformation();
    }
  }

  render() {
    return (
      <Box sx={styles.page}>
        <CircularProgress size={80} />
        <Typography variant={'subtitle1'} sx={styles.message}>Loading...</Typography>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfoLoaded: state.app.user.loaded
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserInformation: () => dispatch(loadUserInformation())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);