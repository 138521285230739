import _ from 'lodash';

const fetchSaveLandingPage = () => {

  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({
        saveUserProfileSetting: {
          success: true
        }
      })
    }, 1000);

  });
};

const fetchAvailableDisplays = (routes) => {

  const simAvailableDisplays = [];

  if (!_.isEmpty(routes)) {

    _.forEach(routes, (route) => {
      simAvailableDisplays.push(
      {
       value: route.value,
       label: route.label, 
      })
    });

  }

  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({
        availableDisplays: simAvailableDisplays
      })
    }, 1000);

  });

}

const fetchUserDashboardSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
          userDashboardConfiguration: [
          {
            name: "pumpDashboard",
            config: JSON.stringify({
              views: [
                {
                  id: 'DASHBOARD',
                  name: '',
                  type: 'MDT',
                  allowCustomization: true,
                  cards:
                      [
                        {i: 'unitHours', type: 'unitHours', x: 6, y: 0, w: 3, h: 2 },
                        {i: 'unitHistory', type: 'unitChartPressureHistory', x: 0, y: 0, w: 4, h: 2, configuration: { showRate: true } },
                        {i: 'unitActivity', type:'unitActivity', x: 4, y: 1, w: 2, h: 1 },
                        {i: 'unitFleet', type: 'unitFleet', x: 4, y: 0, w: 2, h: 1 },
                        {i: 'componentLife', type: 'componentLife', x: 4, y: 2, w: 3, h: 2  },
                        {i: 'vibrationHistory', type: 'unitChartVibrationHistory', x: 0, y: 2, w: 4, h: 2 },
                        {i: 'pumpAlarm', type: 'unitAlarmCount', x:7, y:2, w:2, h:1},
                      ]
                },
                {
                  id: 'OPERATION',
                  name: '',
                  type: 'MDT',
                  allowCustomization: true,
                  cards:
                      [
                        {i: 'unitChartEngine', type: 'unitChartEngine',  x: 0, y: 0, w: 4, h: 2 },
                        {i: 'unitChartTransmission', type: 'unitChartTransmission',  x: 4, y: 0, w: 4, h: 2 },
                        {i: 'unitChartPowerEnd', type: 'unitChartPowerEnd',  x: 0, y: 2, w: 4, h: 2 },
                        {i: 'unitChartLockup', type: 'unitChartLockup',  x: 4, y: 2, w: 4, h: 2 },
                      ]
                },
                {
                  id: 'DATA_EXPLORATION',
                  name: '',
                  type: 'MDT',
                  allowCustomization: false
                },
                {
                  id: '80343ead-39c8-449a-909c-a688635bce60',
                  name: 'My Favorite',
                  type: 'USER',
                  isFavorite: true,
                  allowCustomization: true,
                  icon: 'favorite',
                  cards: [],
                },
                {
                  id: '8135b5e2-77b7-4c4e-918b-10ee127236fb',
                  name: 'Another Favorite',
                  type: 'USER',
                  isFavorite: true,
                  allowCustomization: true,
                  icon: 'star',
                  cards: [],
                },
                {
                  id: '2f36e204-4552-46c0-8f83-8bb53562cdeb',
                  name: 'My Dashboard',
                  type: 'USER',
                  isFavorite: false,
                  allowCustomization: true,
                  icon: 'accessible',
                  cards: [],
                },
                {
                  id: 'b3959cc3-7e48-4012-aea6-9e7ba0853cb0',
                  name: 'My Dashboard',
                  type: 'USER',
                  isFavorite: false,
                  allowCustomization: true,
                  icon: 'bookmark',
                  cards: [],
                },
              ]
            })
          }
        ]
      })
    }, 1000);
  });
};

export { fetchSaveLandingPage, fetchAvailableDisplays, fetchUserDashboardSettings }