import _ from "lodash";

const canSave = (state) => {
  // If values are valid, the changes can be saved. hasChanges by default checks if values are valid in the lines below
  if (!(isValueValid(state.averageFuelCost) && isValueValid(state.originalAverageFuelCost))) {
    return false;
  }

  // If there are changes from the initial values and the values are valid
  if ((hasChanges(state.averageFuelCost, state.originalAverageFuelCost)) && !state.avgFuelCostHasError) {
    return true;
  }

  return false;
};

const isValueValid = (value) => { 
  // Check if value is not null and an integer with values between 100,000 and 0 for it to be valid
  return (!_.isNil(value) && _.isNumber(value) &&  value <= 100000 && value > 0) 
}

const hasChanges = (newValue, originalValue) => {
  // Check if the new values differ from the original
  if (newValue !== originalValue) {
    return true;
  }

  return false;
};

export {
  canSave,
  hasChanges,
  isValueValid
}