import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import Alarms from "../../../../controls/charts/alarms/alarms";

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
  dataVanAlarmContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '125px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: 1,
  },
  alarmsContainer: {
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1
  }
};

class Datavan extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.datavanMapContainer}>
        <Box sx={styles.datavanVisualContent}>
          <Box sx={styles.datavanVisualHeaderContent}>
            <Typography sx={styles.datavanAssetLabel} variant={'subtitle1'}>{data.name}</Typography>
          </Box>
          <Box sx={styles.datavanUnitContent}>
            <Box sx={styles.dataVanAlarmContainer}>
              <Typography variant={'caption'} sx={styles.healthViewContentLabel}>Alarms</Typography>
              <Box sx={styles.alarmsContainer}>
                <Alarms alarmCounts={data.alarmCounts}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

Datavan.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Datavan;