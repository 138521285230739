import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Alert, AlertTitle, Box} from '@mui/material';
import ComponentTypes from "../../componentTypes";
import {wellsDisplayState} from "../../../state/displays/wellsDisplay/wellsDisplaySelectors";
import * as wellsDisplayActions from "../../../state/displays/wellsDisplay/wellsDisplayActions";
import * as wellsDisplayAction from "../../../state/displays/wellsDisplay/wellsDisplayActions";

const styles = {
  flexColumnContainer: {
    display: 'flex',
    flexFlow: 'column nowrap'
  }
};

class WellDeleteConfirmationDialog extends Component {
    render() {
        return (
            <Dialog open={this.props.openDeleteConfirmation} disableEscapeKeyDown>
                <DialogTitle>Delete Well</DialogTitle>
                <DialogContent>
                  <Box sx={styles.flexColumnContainer}>
                    <Box sx={styles.flexColumnContainer}>
                      <DialogContentText>Are you sure you want to delete the well with the following name?</DialogContentText>
                      <Typography>{this.props.wellToDelete.name}</Typography>
                    </Box>
                    {
                      this.props.showWellInUseMessage === true &&
                      <Box sx={{marginTop: 1}}>
                        <Alert severity='error' variant='outlined' action={
                            <Button onClick={() => {
                                this.props.navigateToJobHistoryByWellName(this.props.wellToDelete.name);
                            }} color="warning" size="small">View Jobs</Button>
                        }>
                          <AlertTitle>Unable to Delete</AlertTitle>
                          This Well is used on the following Jobs:
                          <Box sx={styles.flexColumnContainer}>
                            {
                              _.map(this.props.wellInUseMessage.jobs, (job) => {
                                return (
                                  <Typography variant='caption'>{_.trim(job)}</Typography>
                                )
                              })
                            }
                          </Box>
                        </Alert>
                      </Box>
                    }
                  </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.closeDeleteConfirmation()
                    }} color='inherit'>Cancel</Button>
                    <Button disabled={this.props.showWellInUseMessage} onClick={() => {
                        this.props.confirmWellDelete(this.props.wellToDelete.id)
                    }} color="primary">Delete</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.WELLS_DISPLAY : props.stateKey,
            type: ComponentTypes.WELLS_DISPLAY,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    let componentState = wellsDisplayState(state[stateDef.key]);
    return {
        openDeleteConfirmation: componentState.openDeleteConfirmation,
        wellToDelete: componentState.wellToDelete,

        showWellInUseMessage: componentState.showWellInUseMessage,
        wellInUseMessage: componentState.wellInUseMessage
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeDeleteConfirmation: () => dispatch(wellsDisplayActions.onCloseDeleteConfirmation(props.stateDef)),
        confirmWellDelete: (wellId) => dispatch(wellsDisplayAction.onConfirmWellDelete(props.stateDef, wellId)),
        navigateToJobHistoryByWellName: (wellName) => dispatch(wellsDisplayAction.navigateToJobHistoryByWellName(props.stateDef, wellName))
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(WellDeleteConfirmationDialog))
