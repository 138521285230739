import {performDisplayFetch} from '../../common/fetch';
import * as fleetMgmtSimulation from './fleetMgmtSimulation';

import useSimulationData from "../../../helpers/simulationHelper";

const fetchFleets = () => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchFleets();
    }

    return performDisplayFetch({
        'query': 'query fleetManagement { fleetManagement { id fleetIdentifier fleetName datavan active region division communicationStatus type } }'
    });
};

const fetchOwners = () => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchOwners();
    }

    return performDisplayFetch({
        'query': 'query ownersWithApiKey { ownersWithApiKey{ id ownerName ownerApiKey } }'
    });
};

const fetchSaveFleet = (fleet) => {

  if (useSimulationData('fleetManagement')) {
    return fleetMgmtSimulation.fetchSaveFleet(fleet);
  }

  // To be extra safe when running sim data, comment out the below when running with sim data
  return performDisplayFetch({
      'query': 'mutation saveFleet($input:FleetInput!) {  saveFleet(input: $input) { id fleetIdentifier fleetName datavan active region division communicationStatus type } }',
      'variables': {
          'input': {
              'id': fleet.id,
              'fleetName': fleet.fleetName,
              'datavan': fleet.datavan,
              'region': fleet.region,
              'division': fleet.division,
              'type': fleet.type,
              'active': fleet.active
          }
      }
  });
};

const fetchMoveFleet = (fleet) => {
  // To be extra safe when running sim data, comment out the below when running with sim data
  return performDisplayFetch({
      'query': 'mutation moveFleet($input:MoveFleetInput!) {  moveFleet(input: $input) { success } }',
      'variables': {
          'input': {
              'srcFleetId': fleet.id,
              'destOwnerId': fleet.destinationOwnerId.id,
              'destFleetName': fleet.destinationFleetName,
              'destDatavan': fleet.destinationDatavan,
              'destType': fleet.type
          }
      }
  });
};

const fetchDeleteDatasource = (fleetId, datasourceId) => {

  if (useSimulationData('fleetManagement')) {
    return fleetMgmtSimulation.fetchDeleteDatasource();
  }

  // To be extra safe when running sim data, comment out the below when running with sim data
  return performDisplayFetch({
    'query': 'mutation deleteDatasource($fleetId: Long!, $datasourceId: Long!) { deleteDatasource(fleetId: $fleetId, datasourceId: $datasourceId) { success } }',
    'variables': {
      'fleetId': fleetId,
      'datasourceId': datasourceId
    }
  });
}

const fetchDatasourcesForFleet = (fleetId) => {

  if (useSimulationData('fleetManagement')) {
    return fleetMgmtSimulation.fetchDatasourcesForFleet(fleetId);
  }

  return performDisplayFetch({
    'query': 'query datasourcesForFleet($fleetId:Long!) { datasourcesForFleet(fleetId: $fleetId) { id fleetId name datasourceIdentifier databaseName ipAddress databaseHost sourceType } }',
    'variables': {
      'fleetId': fleetId
    }
  });
}

const fetchSaveDatasource = (datasourceInput) => {

  if (useSimulationData('fleetManagement')) {
    return fleetMgmtSimulation.fetchSaveDatasource(datasourceInput);
  }

  // To be extra safe when running sim data, comment out the below when running with sim data
  return performDisplayFetch({
    'query': 'mutation saveDatasource($input: DatasourceInput!) { saveDatasource(input: $input) { id fleetId name datasourceIdentifier databaseName ipAddress databaseHost sourceType } }',
    'variables': {
      'input': datasourceInput
    }
  });
}

const fetchRestartProducer = (fleetGuid) => {

  if (useSimulationData('fleetManagement')) {
    return fleetMgmtSimulation.fetchRestartProducer(fleetGuid);
  }

  // To be extra safe when running sim data, comment out the below when running with sim data
  return performDisplayFetch({
    'query': 'mutation sendCommandForProducer($input: ProducerCommandInput!) { sendCommandForProducer(input: $input) { createdOn } }',
    'variables': {
      'input': {
        'clientId': fleetGuid,
        'command': 'restart'
      }
    }
  });
}

const fetchAssetInfoForDatavan = (datavan) => {
  if (useSimulationData('fleetManagement')) {
      return fleetMgmtSimulation.fetchAssetInfoForTruck(datavan);
  }
  return performDisplayFetch({
      'query': 'query AssetInfoForTruck($truckName:String!){  assetInfoForTruck(truckName: $truckName) {  assetId assetCategory  }}',
      'variables': {
          'truckName': datavan
      }
  });
}

export {
    fetchFleets,
    fetchOwners,
    fetchSaveFleet,
    fetchMoveFleet,
    fetchDeleteDatasource,
    fetchDatasourcesForFleet,
    fetchSaveDatasource,
    fetchRestartProducer,
    fetchAssetInfoForDatavan
};