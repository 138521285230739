import { createBrowserHistory as createHistory } from "history";

/**
 * Creates the browser history object that is used by the react router.
 *
 * We use the 'history' javascript library for this instead of the default
 * history object in the react router so that we can leverage it in the
 * couple authentication hooks that are not in a UI component. Specifically
 * the authHelper and userActions modules.
 *
 * https://www.npmjs.com/package/history
 */
export default createHistory({
  basename: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_ROUTE : ''
});