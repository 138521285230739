import React, {Component} from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

import fleetMapStyles from '../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class NoData extends Component {
  render() {
    return (
        <Box sx={styles.blankDataContainer}>
          <Box sx={styles.blankDataDescriptionContainer}>
            <Typography variant={'h4'} sx={styles.blankDataLabel} gutterBottom={true}>{this.props.textToDisplay}</Typography>
          </Box>
        </Box>
    );
  }
}

NoData.propTypes = {
  textToDisplay: PropTypes.string.isRequired
};

export default NoData;