import _ from 'lodash';
import warmStartActionTypes from '../actionTypes/warmStartActionTypes';
import * as warmStartService from '../services/warmStartService';

const warmStartReducer = (state, action) => {

  switch (action.type) {

    case warmStartActionTypes.SETTINGS_WARM_START_QUERY_STARTING:
      return onwarmStartQueryStarting(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_QUERY_ERROR:
      return onwarmStartQueryError(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_QUERY_SUCCESS:
      return onwarmStartQuerySuccess(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_STARTING:
      return onwarmStartTruckQueryStarting(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_ERROR:
      return onwarmStartTruckQueryError(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_SUCCESS:
      return onwarmStartTruckQuerySuccess(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_SAVE_STARTING:
      return onwarmStartSaveStarting(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_SAVE_ERROR:
      return onwarmStartSaveError(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_SAVE_SUCCESS:
      return onwarmStartSaveSuccess(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_SET_AVERAGE_FUEL_COST:
      return onWarmStartSetAverageFuelCost(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_REVERT_FORM:
      return onwarmStartRevertForm(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_STARTING:
      return onwarmStartTruckSaveStarting(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_SUCCESS:
      return onwarmStartTruckSaveSuccess(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_ERROR:
      return onwarmStartTruckSaveError(state, action);

    case warmStartActionTypes.SETTINGS_WARM_START_SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);

    default: return state;
  }
};

const onwarmStartQueryStarting = (state, action) => {
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      loadingOwnerWarmStartSetting: true,
    },
    queryRunning: true,
  };
};

const onwarmStartQueryError = (state, action) => {
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      loadingOwnerWarmStartSetting: false,
    },
    queryRunning: state.warmStart.loadingTruckWarmStartSetting,
  };
};

const onwarmStartQuerySuccess = (state, action) => {

  let newState = {
    ...state.warmStart,
    averageFuelCost: action.queryResults.ownerWarmStartSetting.avgFuelCost,
    originalAverageFuelCost: action.queryResults.ownerWarmStartSetting.avgFuelCost,
    avgFuelCostHasError: false,
  };

  return {
    ...state,
    warmStart: {
      ...newState,
      loadingOwnerWarmStartSetting: false,
      warmStartCanSave: warmStartService.canSave(newState)
    },
    queryRunning: state.warmStart.loadingTruckWarmStartSetting,
  };
};

const onwarmStartTruckQueryStarting = (state, action) => {
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      loadingTruckWarmStartSetting: true,
    },
    queryRunning: true,
  };
};

const onwarmStartTruckQueryError = (state, action) => {
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      loadingTruckWarmStartSetting: false,
    },
    queryRunning: state.warmStart.loadingOwnerWarmStartSetting
  };
};

const onwarmStartTruckQuerySuccess = (state, action) => {
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      warmStartTruckSettings: action.queryResults.allTrucksWarmStartSettingForOwner,
      loadingTruckWarmStartSetting: false,
    },
    queryRunning: state.warmStart.loadingOwnerWarmStartSetting
  };
};

const onwarmStartSaveStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onwarmStartSaveError = (state, action) => {
  return {
    ...state,
    queryRunning: false,  
  };
};

const onwarmStartSaveSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onWarmStartSetAverageFuelCost = (state, action) => {
 
  let averageFuelCost = !_.isEmpty(action.averageFuelCost) ? Number(action.averageFuelCost) : action.averageFuelCost;
  
  let newState = {
    ...state.warmStart,
    averageFuelCost: averageFuelCost,
    avgFuelCostHasError: !warmStartService.isValueValid(averageFuelCost),
  };
 
  return {
    ...state,
    warmStart: {
      ...newState,
      warmStartCanSave: warmStartService.canSave(newState)
    }
  };
};

const onwarmStartRevertForm = (state, action) => {

  let newState = {
    ...state.warmStart,
    averageFuelCost: state.originalAverageFuelCost,
    avgFuelCostHasError: false,
  };

  return {
    ...state,
    warmStart: {
      ...newState,
      warmStartCanSave: warmStartService.canSave(newState)
    }
  };
};

const onwarmStartTruckSaveStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onwarmStartTruckSaveError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onwarmStartTruckSaveSuccess = (state, action) => {
  const newWarmStartTruckSettings = state.warmStart.warmStartTruckSettings.map(truck => {
    if (truck.truckId === action.truckId) {
      return {...truck, avgIdelFuelConsumption: action.averageFuelConsumption};
    }
    return truck;
  });
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      warmStartTruckSettings: newWarmStartTruckSettings,
    },
    queryRunning: false
  };
};

const onDataGridSetRowSize = (state, action) => {
  
  return {
    ...state,
    warmStart: {
      ...state.warmStart,
      rowsPerPage: action.rowSize,
    }
  }

}

export default warmStartReducer