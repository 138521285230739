import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import UnitAlarmBadge from '../unitAlarmBadge';

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class Datavan extends Component {
  render() {
    const { classes } = this.props;

    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.datavanMapContainer}>
        <Box sx={styles.datavanVisualContent}>
          <Box sx={styles.datavanVisualHeaderContent}>
            <Typography sx={styles.datavanAssetLabel} variant={'subtitle1'}>{data.name}</Typography>
          </Box>
          <Box sx={styles.unitVisualContent}>
            <Box sx={styles.unitContent}>
              <Box sx={styles.dataVanBadgeContainer}>
                <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

Datavan.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Datavan;