import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as fleetVibrationSimulation from '../fleetVibration/fleetVibrationSimulation';

const fetchFleetVibrationCardData = (fleetId) => {

  if (useSimulationData('fleetVibrationCard')) {
    return fleetVibrationSimulation.fetchFleetVibrationCardData(fleetId);
  }

  return performCardFetch({
      'query': '{ fleetVibration(fleetId:{fleetId}) { powerEnd fluidEnd iron manifold } }'.replace('{fleetId}', fleetId)
  })
};

export default fetchFleetVibrationCardData