import defaultActionFactory from '../../common/factories/defaultActionFactory';
import searchActionTypes from './searchActionTypes';
import _ from "lodash";

const performSearch = defaultActionFactory(searchActionTypes.SEARCH_PERFORM_SEARCH, 'stateDef', 'searchTerm');
const clearSearch = defaultActionFactory(searchActionTypes.SEARCH_CLEAR_SEARCH, 'stateDef', 'searchTerm');
const updateSearchTerm = defaultActionFactory(searchActionTypes.SEARCH_UPDATE_SEARCH_TERM, 'stateDef', 'searchTerm');

export {
    performSearch,
    clearSearch,
    updateSearchTerm,
};
