import { mdtPalette } from '../../common/styles/mdtPalette';
import getTypographyStyles from '../../common/styles/typographyStyles';

const componentLifeCardChartOptions = (currentValue, totalValue) => {

  let typographyStyles = getTypographyStyles();

  let delta = totalValue - currentValue;
  let warnThreshold = totalValue * 0.8;

  return {
    title: {
      text: ""
    },

    boost: {
      enabled: true,
    },
    time:{
      useUTC: false,
    },
    chart: {
      type: 'bar',
      spacing: [0,0,0,0],
      height: 40,
      width: 185,
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      plotBackgroundColor: mdtPalette().materialUI.palette.background.paper,
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'normal'
      },
      reflow: false,
      animation: false,
    },

    lang: {
      noData: 'No Data'
    },

    noData: {
      style: typographyStyles.noDataLabel
    },

    tooltip: {
      enabled: false,
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      bar: {
        states: {
          hover: {
            enabled: false
          }
        },
        pointPadding: 0.01,
        pointWidth: 40,
      },
      series: {
        borderWidth: 0,
        animation: false,
        stacking: 'percent',
      }
    },

    yAxis: {
      visible: false,
      reversed: true
    },

    xAxis: {
      visible: false,
      categories: ['component'],
      reversed: true
    },

    series: [
      {
        states: { 
          hover: { 
            enabled: false 
          }, 
          inactive: { 
            enabled: false 
          } 
        },
        // Current Value
        data: [
          {
            dataLabels: {
              enabled: true,
              align: 'left',
              format: '{y} hrs',
              padding: 7,
              crop: false,
              style: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                textOutline:  'none',
                fontSize: '16px',
                color: '#000000',
              },
            },
            y: currentValue,
            color: (currentValue > totalValue ? mdtPalette().status.level5 : (currentValue > warnThreshold ? mdtPalette().status.level4 : mdtPalette().status.level2)),
          },
        ],
      },
      {
        states: { 
          hover: { 
            enabled: false 
          }, 
          inactive: { 
            enabled: false 
          } 
        },
        // Total Value
        data: [
          {
            y: (currentValue < totalValue ? delta : 0),
            color: mdtPalette().status.level1,
          },
        ]
      }
    ]
  }
};

export default componentLifeCardChartOptions;