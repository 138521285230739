import _ from 'lodash';
import moment from 'moment';

const fetchDfpUnits = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                dfpDashboard: generateDfpUnits(10)
            })
        }, 1000)
    });
};

const fetchDataAge = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                truckDataAge: {
                    timestamp: moment().utc().endOf('seconds').subtract(_.random(1,10), 'seconds')/1000
                }
            })
        }, 1000)
    });
};

const generateDfpUnits = (numDpfUnits) => {
    let data = [];
    let i;
    for (i = 1; i <= numDpfUnits; i++) {
        let dfpUnitData = {
            id: i,
            name: _.random(900100, 900500).toString(),
            fleetId: 1,
            fleetName: 'SIM-1',
            region: 'West',
            division: 'DFP',
        };
        data.push(dfpUnitData);
    }
    let sim10Dfp = {
        id: 1314,
        name: '921257',
        fleetId: 20,
        fleetName: 'SIM10',
        region: null,
        division: 'DFP',
    };
    data.push(sim10Dfp);
    return data;
};

export {
    fetchDfpUnits,
    fetchDataAge
};
