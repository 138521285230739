import _ from 'lodash';
import moment from 'moment';

const fetchCoilTubingUnits = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                coilDashboard: generateCoilTubingUnits(10)
            })
        }, 1000)
    });
};

const fetchDataAge = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                truckDataAge: {
                    timestamp: moment().utc().endOf('seconds').subtract(_.random(1,10), 'seconds')/1000
                }
            })
        }, 1000)
    });
};

const generateCoilTubingUnits = (numCoilUnits) => {
    let data = [];
    let i;
    for (i = 1; i <= numCoilUnits; i++) {
        let coilUnitData = {
            id: i,
            name: _.random(900100, 900500).toString(),
            fleetId: 1,
            fleetName: 'SIM-1',
            region: 'West',
            division: 'Coil',
        };
        data.push(coilUnitData);
    }
    let sim10Coil = {
        id: 1314,
        name: '921257',
        fleetId: 20,
        fleetName: 'SIM10',
        region: null,
        division: 'Coil',
    };
    data.push(sim10Coil);
    return data;
};

export {
    fetchCoilTubingUnits,
    fetchDataAge
};