export default {

  // Navigation actions
  APP_OPEN_DRAWER: 'APP_OPEN_DRAWER',
  APP_CLOSE_DRAWER: 'APP_CLOSE_DRAWER',
  APP_SET_OWNER: 'APP_SET_OWNER',
  APP_SET_NAVIGATION_CONTEXT: 'APP_SET_NAVIGATION_CONTEXT',
  APP_SET_SHOW_DEVELOPMENT_CONTENT: 'APP_SET_SHOW_DEVELOPMENT_CONTENT',
  APP_SET_SHOW_PRE_RELEASE_CONTENT: 'APP_SET_SHOW_PRE_RELEASE_CONTENT',

  // Error handling actions
  APP_DISPLAY_ERROR: 'APP_DISPLAY_ERROR',
  APP_HIDE_ERROR: 'APP_HIDE_ERROR',
  APP_ERROR_UI_OPENED: 'APP_ERROR_UI_OPENED',
  APP_ERROR_UI_CLOSED: 'APP_ERROR_UI_CLOSED',

  // User actions
  APP_OPEN_USER_MENU: 'APP_OPEN_USER_MENU',
  APP_CLOSE_USER_MENU: 'APP_CLOSE_USER_MENU',
  APP_LOAD_USER_INFO_STARTING: 'APP_LOAD_USER_INFO_STARTING',
  APP_LOAD_USER_INFO_SUCCESS: 'APP_LOAD_USER_INFO_SUCCESS',
  APP_LOAD_USER_INFO_ERROR: 'APP_LOAD_USER_INFO_ERROR',
  APP_CLEAR_USER_INFO: 'APP_CLEAR_USER_INFO',
  APP_SET_USER_INFO: 'APP_SET_USER_INFO',
  APP_SET_OWNERS: 'APP_SET_OWNERS',
  APP_SET_USER_ROUTES: 'APP_SET_USER_ROUTES',

  APP_SHOW_WHATS_NEW: 'APP_SHOW_WHATS_NEW',
  APP_WHATS_NEW_PANEL_EXPANDED: 'APP_WHATS_NEW_PANEL_EXPANDED',
  APP_REFRESH_TIMESTAMP: 'APP_REFRESH_TIME',

  APP_GET_AVAILABLE_DISPLAYS_STARTING: 'APP_GET_AVAILABLE_DISPLAYS_STARTING',
  APP_GET_AVAILABLE_DISPLAYS_SUCCESS: 'APP_GET_AVAILABLE_DISPLAYS_SUCCESS',
  APP_GET_AVAILABLE_DISPLAYS_ERROR: 'APP_GET_AVAILABLE_DISPLAYS_ERROR',

  APP_SAVE_LANDING_PAGE_STARTING: 'APP_SAVE_LANDING_PAGE_STARTING',
  APP_SAVE_LANDING_PAGE_SUCCESS: 'APP_SAVE_LANDING_PAGE_SUCCESS',
  APP_SAVE_LANDING_PAGE_ERROR: 'APP_SAVE_LANDING_PAGE_ERROR',

  // Notifications actions
  APP_SHOW_SUBSCRIPTION_DIALOG: 'APP_SHOW_SUBSCRIPTION_DIALOG',

  // User Configuration Settings actions
  APP_USER_PROFILE_SETTINGS_LOADED: 'APP_USER_PROFILE_SETTINGS_LOADED',
  APP_USER_PROFILE_LASTWHATSNEW_UPDATED: 'APP_USER_PROFILE_LASTWHATSNEW_UPDATED',

  USER_CONFIG_QUERY_PAGE_CONFIG_STARTING: "USER_CONFIG_QUERY_PAGE_CONFIG_STARTING",
  USER_CONFIG_QUERY_PAGE_CONFIG_ERROR: "USER_CONFIG_QUERY_PAGE_CONFIG_ERROR",
  USER_CONFIG_QUERY_PAGE_CONFIG_SUCCESS: "USER_CONFIG_QUERY_PAGE_CONFIG_SUCCESS",

  USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_STARTING: "USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_STARTING",
  USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_ERROR: "USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_ERROR",
  USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_SUCCESS: "USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_SUCCESS",

  USER_CONFIG_SAVE_PAGE_CONFIG_STARTING: "USER_CONFIG_SAVE_PAGE_CONFIG_STARTING",
  USER_CONFIG_SAVE_PAGE_CONFIG_ERROR: "USER_CONFIG_SAVE_PAGE_CONFIG_ERROR",
  USER_CONFIG_SAVE_PAGE_CONFIG_SUCCESS: "USER_CONFIG_SAVE_PAGE_CONFIG_SUCCESS",

  USER_CONFIG_UPDATE_DASHBOARD_LAYOUT: 'USER_CONFIG_UPDATE_DASHBOARD_LAYOUT',
  USER_CONFIG_ADD_CARD_TO_DASHBOARD_LAYOUT: 'USER_CONFIG_ADD_CARD_TO_DASHBOARD_LAYOUT',
  USER_CONFIG_DASHBOARD_LAYOUT_EDIT: 'USER_CONFIG_DASHBOARD_LAYOUT_EDIT',
  USER_CONFIG_DASHBOARD_EDIT_LAYOUT_SELECT_CARD: 'USER_CONFIG_DASHBOARD_EDIT_LAYOUT_SELECT_CARD',
  USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CARD: 'USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CARD',
  USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG: 'USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG',
  USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_TITLE: 'USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_TITLE',
  USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_SET_DEFAULT_TITLE: 'USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_SET_DEFAULT_TITLE',
  USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_SIZE: 'USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_SIZE',
  USER_CONFIG_DASHBOARD_CONFIG_PANEL_CHANGES_DISCARD: 'USER_CONFIG_DASHBOARD_CONFIG_PANEL_CHANGES_DISCARD',
  USER_CONFIG_DASHBOARD_LAYOUT_OPEN_CARD_CONFIG: 'USER_CONFIG_DASHBOARD_LAYOUT_OPEN_CARD_CONFIG',

  USER_CONFIG_DASHBOARD_LAYOUT_MANAGE_CUSTOM_VIEW: 'USER_CONFIG_DASHBOARD_LAYOUT_MANAGE_CUSTOM_VIEW',
  USER_CONFIG_DASHBOARD_LAYOUT_ADD_CUSTOM_VIEW: 'USER_CONFIG_DASHBOARD_LAYOUT_ADD_CUSTOM_VIEW',
  USER_CONFIG_DASHBOARD_LAYOUT_TOGGLE_FAVORITE_VIEW: 'USER_CONFIG_DASHBOARD_LAYOUT_TOGGLE_FAVORITE_VIEW',
  USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CUSTOM_VIEW: 'USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CUSTOM_VIEW',
  USER_CONFIG_DASHBOARD_LAYOUT_CHANGE_CUSTOM_VIEW_NAME: 'USER_CONFIG_DASHBOARD_LAYOUT_CHANGE_CUSTOM_VIEW_NAME',
  USER_CONFIG_DASHBOARD_LAYOUT_DISCARD_CUSTOM_VIEW_CHANGES: 'USER_CONFIG_DASHBOARD_LAYOUT_DISCARD_CUSTOM_VIEW_CHANGES',
  USER_CONFIG_DASHBOARD_LAYOUT_REORDER_CUSTOM_VIEW: 'USER_CONFIG_DASHBOARD_LAYOUT_REORDER_CUSTOM_VIEW',
  USER_CONFIG_DASHBOARD_LAYOUT_SELECT_CUSTOM_VIEW_ICON: 'USER_CONFIG_DASHBOARD_LAYOUT_SELECT_CUSTOM_VIEW_ICON',

  APP_CONTEXT_OPEN_DRAWER: 'APP_CONTEXT_OPEN_DRAWER',
  APP_CONTEXT_MINIMIZE_DRAWER: 'APP_CONTEXT_MINIMIZE_DRAWER',
  APP_CONTEXT_SET_TYPE: 'APP_CONTEXT_SET_TYPE',
  APP_CONTEXT_SET: 'APP_CONTEXT_SET',
  APP_CONTEXT_SET_DRAWER_WIDTH: 'APP_CONTEXT_SET_DRAWER_WIDTH',

  USER_CONFIG_RESTORE_DEFAULT_VIEW: 'USER_CONFIG_RESTORE_DEFAULT_VIEW',

  APP_RECEIVE_WEBSOCKET_MESSAGE: 'APP_RECEIVE_WEBSOCKET_MESSAGE',
  APP_SEND_WEBSOCKET_MESSAGE: 'APP_SEND_WEBSOCKET_MESSAGE',
  APP_SET_WEBSOCKET_INTERVAL_ID: 'APP_SET_WEBSOCKET_INTERVAL_ID',
  APP_CLOSE_WEBSOCKET: 'APP_CLOSE_WEBSOCKET',
  APP_PROCESS_WEBSOCKET_DATA: 'APP_PROCESS_WEBSOCKET_DATA',
  APP_SAVE_WEBSOCKET_CLIENTID: 'APP_SAVE_WEBSOCKET_CLIENTID',
  APP_UPDATE_WEBSOCKET_CONNECTION_STATE: 'APP_UPDATE_WEBSOCKET_CONNECTION_STATE',
  APP_WEBSOCKET_CONNECT: 'APP_WEBSOCKET_CONNECT',
  APP_WEBSOCKET_SAVE_SUBSCRIPTION: 'APP_WEBSOCKET_SAVE_SUBSCRIPTION',
};
