import componentUnitsActionTypes from '../componentHoursActionTypes';
import * as componentHoursService from "../services/componentHoursService";
import { componentHoursState } from '../componentHoursSelectors';

const initialState = componentHoursState();

const queryReducer = (state, action) => {

  switch (action.type) {

    case componentUnitsActionTypes.COMPONENT_HOURS_QUERY_STARTING:
      return onQueryStarting(state, action);

    case componentUnitsActionTypes.COMPONENT_HOURS_QUERY_SUCCESS:
      return onQuerySuccess(state, action);

    case componentUnitsActionTypes.COMPONENT_HOURS_QUERY_ERROR:
      return onQueryError(state, action);

    default: return state;
  }
};

const  onQueryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onQuerySuccess = (state, action) => {

  let transformedUnits = componentHoursService.transformUnits(action.queryResults.componentHoursDisplayDetails);

  let processDetails = componentHoursService.processUnits(transformedUnits, state.appliedFilters, state.showConsumedHours);

  return {
    ...state,
    queryRunning: false,
    units: transformedUnits,
    filteredUnits: processDetails.filteredUnits,
    filteredComponents: processDetails.filteredComponents,
    filters: processDetails.filters,
    currentPage: initialState.currentPage
  };
};

const  onQueryError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer