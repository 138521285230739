import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Box, Dialog, DialogContent, IconButton, Tooltip} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import ComponentTypes from "../../componentTypes";
import {fleetMgmtState} from "../../../state/displays/fleetManagement/fleetMgmtSelectors";
import * as fleetMgmtActions from "../../../state/displays/fleetManagement/fleetMgmtActions";
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";
import {appState as applicationState} from "../../../state/app/appSelectors";
import getDialogStyles from "../../common/styles/dialogStyles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {fleetTypes, getFleetTypeNameByType} from "../../../state/common/services/fleetTypeService";

const typeOptions = Object.values(fleetTypes).map(type => {
    return {
        label: getFleetTypeNameByType(type),
        value: type
    };
});

const dialogStyles = getDialogStyles();

const styles = {
    formField: {
        marginTop: 2
    }
};

class MoveFleetDialog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const selectedOwnerApiKey = this.props.fleetToMove.destinationOwnerId?.ownerApiKey || '';
        return (
            <Dialog
                open={this.props.openMoveFleetDialog}
                disableEscapeKeyDown>
                <DialogTitle>Move Fleet</DialogTitle>
                <DialogContent>
                    <Box>Current Fleet: {this.props.fleetToMove.fleetName}</Box>
                    <Box>
                        <AutoCompleteMDT
                            sx={{...styles.formField, width: '200px'}}
                            options={this.props.availableOwners}
                            getOptionLabel={option => option.ownerName}
                            value={this.props.fleetToMove.destinationOwnerId || null}
                            noOptionsText={'No owners found...'}
                            renderInput={(params) => <TextField {...params} autoFocus variant="standard"
                                                                helperText="Destination Owner"
                                                                error={this.props.errorsOnFleetMove.hasDestinationOwnerIdError}/>}
                            onChange={(event, value) => {
                                this.props.onChangeMoveFleet('destinationOwnerId', value);
                            }}
                            isOptionEqualToValue={(option, value) => option.id == value?.id}
                        />
                    </Box>
                    <Box sx={styles.formField}>
                        <TextField
                            value={selectedOwnerApiKey}
                            variant='standard'
                            size='small'
                            helperText="Owner Api Key"
                            aria-readonly={true}
                            disabled={true}
                        />
                        <Tooltip disableInteractive title="Copy to Clipboard" sx={{
                            display: _.isEmpty(selectedOwnerApiKey) ? "none" : "inline"
                        }}>
                            <IconButton
                                size="small"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(selectedOwnerApiKey);
                                    this.props.onOpenCopyToClipboardNotification();
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <TextField
                        sx={styles.formField}
                        required
                        variant='standard'
                        size='small'
                        helperText="Destination Fleet Name"
                        inputProps={{maxLength: 255}}
                        onChange={(event) => {
                            this.props.onChangeMoveFleet('destinationFleetName', event.target.value);
                        }}
                        error={this.props.errorsOnFleetMove.hasDestinationFleetNameError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        required
                        variant='standard'
                        size='small'
                        helperText="Destination Fleet Datavan"
                        inputProps={{maxLength: 255}}
                        onChange={(event) => {
                            this.props.onChangeMoveFleet('destinationDatavan', event.target.value);
                        }}
                        error={this.props.errorsOnFleetMove.hasDestinationDatavanError}
                        fullWidth
                    />
                    <Box>
                        <AutoCompleteMDT
                            sx={{...styles.formField, width: '200px'}}
                            options={typeOptions}
                            value={typeOptions.find(option => option.value === this.props.fleetToMove.type)?.label || null}
                            noOptionsText={'No types found...'}
                            renderInput={(params) => <TextField {...params} variant="standard"
                                                                helperText="Destination Type"
                                                                error={this.props.errorsOnFleetMove.hasDestinationTypeError}
                                                                inputProps={{...params.inputProps, readOnly: true}}/>}
                            onChange={(event, value, reason) => {
                                this.props.onChangeMoveFleet('type', value.value);
                            }}
                            isOptionEqualToValue={(option, value) => option.label == value}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button sx={dialogStyles.actionButton} variant={'contained'}
                            onClick={() => this.props.onCloseMoveFleetDialog()}>Cancel</Button>
                    <Button variant={'contained'} color='primary'
                            onClick={() => this.props.onConfirmMoveFleet(this.props.fleetToMove)}
                            disabled={!_.isEmpty(this.props.errorsOnFleetMove)}>Move</Button>
                </DialogActions>
            </Dialog>

        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
            type: ComponentTypes.FLEET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = fleetMgmtState(state[stateDef.key]);
    const appState = applicationState(state);

    return {
        fleetToMove: componentState.fleetToMove,
        openMoveFleetDialog: componentState.openMoveFleetDialog,
        errorsOnFleetMove: componentState.errorsOnFleetMove,
        availableOwners: componentState.owners.filter(owner => owner.id !== (appState.selectedOwner?.value || appState.user.ownerId)),
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onCloseMoveFleetDialog: () => {
            dispatch(fleetMgmtActions.onCloseMoveFleetDialog(props.stateDef))
        },
        onConfirmMoveFleet: (fleetToMove) => {
            dispatch(fleetMgmtActions.onMoveFleet(props.stateDef, fleetToMove));
        },
        onChangeMoveFleet: (property, value) => {
            dispatch(fleetMgmtActions.onChangeMoveFleet(props.stateDef, property, value));
        },
        onOpenCopyToClipboardNotification: () => {
            dispatch(fleetMgmtActions.onOpenCopyToClipboardNotification(props.stateDef));
        }
    }
}
;

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(MoveFleetDialog))