import _ from 'lodash';

import {EAxisAlignment} from 'scichart/types/AxisAlignment';

import { mdtPalette } from '../../../../components/common/styles/mdtPalette';

const defaultSensors = [
  {
    sensorSetId: 17689,
    alias: 'MAINLINE',
    uom: 'PSI',
    displayName: 'Mainline Pressure',
    color: mdtPalette().charting.red,
    isVisible: true,
    yAxisId: -2,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.redOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17705,
    alias: 'PUMPSIDE',
    uom: 'PSI',
    displayName: 'Pumpside Pressure',
    color: mdtPalette().charting.pink,
    isVisible: true,
    yAxisId: -2,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.pinkOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17697,
    alias: 'SLURRY_RATE',
    uom: 'BBL/MIN',
    displayName: 'Slurry Rate',
    color: mdtPalette().charting.cyan,
    isVisible: true,
    yAxisId: -1,
    lineStyle: 
      {
        value: [10, 0],
      },
    secondary: {
      color: mdtPalette().chartingOpposites.cyanOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17346,
    alias: 'CLEAN_RATE',
    uom: 'BBL/MIN',
    displayName: 'Clean Rate',
    color: mdtPalette().charting.lightBlue,
    isVisible: true,
    yAxisId: -1,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.lightBlueOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17678,
    alias: 'INLINE_CONCENTRATION',
    uom: 'LBS/GAL',
    displayName: 'Inline Denso Proppant',
    color: mdtPalette().charting.lightGreen,
    isVisible: true,
    yAxisId: 1,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.lightGreenOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17716,
    alias: 'BLENDER_CONCENTRATION',
    uom: 'LBS/GAL',
    displayName: 'Blender Denso Proppant',
    color: mdtPalette().charting.lime,
    isVisible: true,
    yAxisId: 1,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.limeOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  },
  {
    sensorSetId: 17676,
    alias: 'TARGET',
    uom: 'LBS/GAL',
    displayName: 'Target Concentration',
    color: mdtPalette().charting.orange,
    isVisible: true,
    yAxisId: 1,
    lineStyle: 
      {
        value: [10, 0]
      },
    secondary: {
      color: mdtPalette().chartingOpposites.orangeOpposite,
      lineStyle: 
        {
          value: [4, 4]
        },
    },
    value: null
  }
]

const defnEmpty = () => {
  return {
    // the chart definition can be shared; a subset of the definition
    // should be used as input to the dataExploration component
    name: "",
    author: "",
    type: 'template',

    // first x-Axis id
    primary: {

      // time range
      timeRange: {
        startTime: null,
        endTime: null
      },

      // default context should be used for all new sensors (must include name)
      defaultContext: null,

      // contexts, one per sensor
      contexts: [],

      // sensor definitions (from sensor selector) and configuration (objects must include sensorSetId, alias, uom, yAxisId, color, isVisible)
      // Note that yAxisId is 0, 1, 2, 3 for outer left, inner left, inner
      // right, and outer right respectively
      sensors: defaultSensors,

      axisTitle: '',

      // Indicates if the x-axis is visible or not
      isVisible: true,

      // Placement of the x-axis
      placement: EAxisAlignment.Bottom,

      // Indicates if the y-axis should start at zero or not
      zeroYRanges: true,
    },

    secondary: {
      
      // time range
      timeRange: {
        startTime: null,
        endTime: null
      },

      // default context should be used for all new sensors (must include name)
      defaultContext: null,

      // contexts, one per sensor
      contexts: [],

      // same default sensors but with different colors and line styles
      sensors: [
        ..._.map(defaultSensors, (sensor) => {
          return {
            ...sensor,
            color: sensor.secondary.color,
            lineStyle: sensor.secondary.lineStyle,
          }
        })
      ],

      axisTitle: '',

      // Indicates if the x-axis is visible or not
      isVisible: false,

      // Placement of the x-axis
      placement: EAxisAlignment.Top,

      // Indicates if the y-axis should start at zero or not
      zeroYRanges: true,
    }
  };
};

const jobStageChartState = (state) => {
  return {
    queryRunning : _.isNil(state) ? false : state.queryRunning,
    definition: _.isNil(state) ? defnEmpty() : state.definition,

    primaryXValues: _.isNil(state) ? [] : state.primaryXValues,
    primaryYValues: _.isNil(state) ? [] : state.primaryYValues,

    secondaryXValues: _.isNil(state) ? [] : state.secondaryXValues,
    secondaryYValues: _.isNil(state) ? [] : state.secondaryYValues,
  };
};

export {
  jobStageChartState
};
