export default {

  LOAD_CONTEXT_DATA_STARTING: 'LOAD_CONTEXT_DATA_STARTING',
  LOAD_CONTEXT_DATA_SUCCESS: 'LOAD_CONTEXT_DATA_SUCCESS',
  LOAD_CONTEXT_DATA_ERROR: 'LOAD_CONTEXT_DATA_ERROR',

  SAVE_NEW_DEVICE: 'SAVE_NEW_DEVICE',

  SET_NEW_DEVICE_OWNER: 'SET_NEW_DEVICE_OWNER',
  SET_NEW_DEVICE_NAME: 'SET_NEW_DEVICE_NAME',

  CLEAR_FORM: 'CLEAR_FORM',

}