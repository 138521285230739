import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';

import fleetMapStyles from '../../fleetMapStyles';
import Bar from '../../../../controls/charts/bar/bar';
import Rate from '../../../../controls/charts/rate/rate';
import UnitAlarmBadge from '../unitAlarmBadge';
import AutoManualIndicator from "../../../../controls/charts/indicator/autoManualIndicator";
import { mdtPalette } from "../../../../common/styles/mdtPalette";

const styles = {
  ...fleetMapStyles,

  // Override the default unitContent style
  unitContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '90%'
  },
};

class Blender extends Component {
  render() {
    const valueColor = mdtPalette().categories.category4;
    const totalColor = mdtPalette().categories.category1;

    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;
    data.density = _.isNil(this.props.data.density) ? {} : this.props.data.density;
    data.discharge = _.isNil(this.props.data.discharge) ? {} : this.props.data.discharge;
    data.rate = _.isNil(this.props.data.rate) ? {} : this.props.data.rate;

    return (
      <Box sx={styles.unitMapContainer}>
        <Box sx={styles.unitVisualContent}>
          <Box sx={styles.unitVisualHeaderContent}>
            <Typography variant={'subtitle1'}>{data.name}</Typography>
          </Box>

          <Box sx={styles.unitContent}>
            <Bar value={data.density.value} total={data.density.total} label="Density (ppa)" valueColor={valueColor} totalColor={totalColor}/>
            <Bar value={data.discharge.value} total={data.discharge.total} label="Discharge (psi)" valueColor={valueColor} totalColor={totalColor}/>
            {/* Blender does not have a Tripped state but since we are using a universal Rate control, let's just set it to false */}
            <Rate value={data.rate.value} label="Rate" tripped={false} idle={data.rate.idle} stopped={data.rate.stopped}/>
            <AutoManualIndicator title="Tub Mode" value={data.tubAutoEnabled === true ? data.tubAutoMode : null}/>
          </Box>

          <Box sx={styles.badgeContainer}>
            <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
          </Box>
        </Box>
      </Box>
    );
  }
}

Blender.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Blender;
