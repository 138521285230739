import React, { Component } from 'react';
import _ from "lodash";
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Dialog, DialogContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Switch from "@mui/material/Switch";
import { Typography } from '@mui/material';

import * as userMgmtUserDialogActions from '../../../state/displays/userMgmt/actions/userMgmtUserDialogActions';
import { userMgmtState } from '../../../state/displays/userMgmt/userMgmtSelectors';
import ComponentTypes from "../../componentTypes";

class UserMgmtUserDialog extends Component {

  render() {
    return (
      <Dialog
        open={this.props.openAddUserUI || this.props.openEditUserUI}
        disableEscapeKeyDown>
        { this.props.openAddUserUI && <DialogTitle>Add User</DialogTitle> }
        { this.props.openEditUserUI && <DialogTitle>Edit User</DialogTitle> }
        <DialogContent>
          <TextField 
            sx={{marginTop: 2}}
            autoFocus 
            required 
            variant='standard' 
            size="small" 
            label="Name" 
            fullWidth 
            inputProps={{maxLength:60}} 
            error={this.props.workingUserValidation.hasErrorName} 
            value={this.props.workingUser.name} 
            onChange={(event) => this.props.setWorkingName(event.target.value)} 
            onBlur={() => this.props.validateWorkingUser()}
          />
          <TextField 
            sx={{marginTop: 2}}
            required={this.props.openAddUserUI} 
            variant='standard' 
            size="small" 
            label="Email" 
            fullWidth 
            disabled={this.props.openEditUserUI} 
            error={this.props.workingUserValidation.hasErrorEmail} 
            value={this.props.workingUser.email} 
            onChange={(event) => this.props.setWorkingEmail(event.target.value)} 
            onBlur={() => this.props.validateWorkingUser()}
          />
          { this.props.openAddUserUI && 
            <TextField 
              variant='standard'  
              sx={{marginTop: 2}}
              required size="small" 
              label="Confirm Email" 
              fullWidth 
              error={this.props.workingUserValidation.hasErrorConfirmEmail} 
              value={this.props.workingUser.confirmEmail} 
              onChange={(event) => this.props.setWorkingConfirmEmail(event.target.value)} 
              onBlur={() => this.props.validateWorkingUser()}
            /> 
          }
          <Grid container direction={'column'}>
            { this.props.openEditUserUI && 
              <FormControlLabel 
                label="Enabled" 
                control={<Switch color='primary' checked={this.props.workingUser.isEnabled}/>} 
                onChange={(event) => this.props.setWorkingIsEnabled(event.target.checked)}
              /> 
            }
            <FormControlLabel 
              label={<Typography 
              variant='body2'>Administrator</Typography>} 
              control={<Switch color='primary' checked={this.props.workingUser.isAdmin}/>} 
              onChange={(event) => this.props.setWorkingIsAdmin(event.target.checked)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          { this.props.openAddUserUI && 
            <Button color='inherit' onClick={() => { this.props.closeAddDialog()}}>Cancel</Button> 
          }
          { this.props.openAddUserUI && 
            <Button disabled={this.props.workingUserValidation.hasErrors} onClick={() => { this.props.addUser(this.props.workingUser) }} color="primary">Add</Button> 
          }
          { this.props.openEditUserUI && 
            <Button color='inherit' onClick={() => { this.props.closeEditDialog()}}>Cancel</Button> 
          }
          { this.props.openEditUserUI && 
            <Button disabled={this.props.workingUserValidation.hasErrors} onClick={() => { this.props.updateUser(this.props.workingUser)}} color="primary">Save</Button> 
          }
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.USER_MANAGEMENT : props.stateKey,
      type: ComponentTypes.USER_MANAGEMENT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = userMgmtState(state[stateDef.key]);
  return {
    openAddUserUI: componentState.openAddUserUI,
    openEditUserUI: componentState.openEditUserUI,
    workingUser: componentState.workingUser,
    workingUserValidation: componentState.workingUserValidation,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeAddDialog: () => dispatch(userMgmtUserDialogActions.closeAddDialog(props.stateDef)),
    closeEditDialog: () => dispatch(userMgmtUserDialogActions.closeEditDialog(props.stateDef)),
    setWorkingName: (name) => dispatch(userMgmtUserDialogActions.setWorkingName(props.stateDef, name)),
    setWorkingEmail: (email) => dispatch(userMgmtUserDialogActions.setWorkingEmail(props.stateDef, email)),
    setWorkingIsEnabled: (isEnabled) => dispatch(userMgmtUserDialogActions.setWorkingIsEnabled(props.stateDef, isEnabled)),
    setWorkingIsAdmin: (isAdmin) => dispatch(userMgmtUserDialogActions.setWorkingIsAdmin(props.stateDef, isAdmin)),
    setWorkingConfirmEmail: (email) => dispatch(userMgmtUserDialogActions.setWorkingConfirmEmail(props.stateDef, email)),
    validateWorkingUser: () => dispatch(userMgmtUserDialogActions.validateWorkingUser(props.stateDef)),
    updateUser: (workingUser) => dispatch(userMgmtUserDialogActions.updateUser(props.stateDef, workingUser)),
    addUser: (workingUser) => dispatch(userMgmtUserDialogActions.addUser(props.stateDef, workingUser)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UserMgmtUserDialog))
