import * as subscriptionDialogSimulation from './subscriptionDialogSimulation';

import {performDisplayFetch} from '../../../common/fetch';
import useSimulationData from '../../../../helpers/simulationHelper';

/**
 * Get data for the Subscription Dialog based on where it was called
 */
const fetchSubscriptionDialogEvents = () => {

  if (useSimulationData('subscriptionsDialog')) {
    return subscriptionDialogSimulation.fetchSubscriptionDialogEvents();
  }

  return performDisplayFetch(
    {
      'query': 'query Events { events { id entity type filterAttributes { label name type availableOperators { label value } availableValues { label value } } } }'
    });

};

const fetchSubscriptionDialogProviders = () => {
  
  if (useSimulationData('subscriptionsDialog')) {
    return subscriptionDialogSimulation.fetchSubscriptionDialogProviders();
  }

  return performDisplayFetch(
    {
      'query': 'query ProviderBasics { providerBasics { id type config name } }'
    });
}

export {
  fetchSubscriptionDialogEvents,
  fetchSubscriptionDialogProviders
};