import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import ComponentTypes from '../../componentTypes';
import UnitChart from './common/unitChart';

import * as unitChartActions from '../../../state/cards/unitChart/unitChartActions';
import { unitChartState } from '../../../state/cards/unitChart/unitChartSelectors';
import unitChartTypes from '../../../state/cards/unitChart/unitChartTypes';
import { mdtPalette } from "../../common/styles/mdtPalette";
import * as validationHelpers from '../../../helpers/validationHelper';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

class UnitChartPressureHistory extends MDTCard {

  getName() {
    return 'PRESSURE HISTORY';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck) && !_.isNil(this.props.context.startTime && !_.isNil(this.props.context.endTime));
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else if ((validationHelpers.checkValueAgainstNotFunctions(this.props.context.startTime, [_.isNil, _.isNaN]) === true) &&
              (validationHelpers.checkValueAgainstNotFunctions(this.props.context.endTime, [_.isNil, _.isNaN]) === true)) {
      this.props.queryData(this.props.context.truck.id, this.props.context.startTime, this.props.context.endTime);
    }
  }

  getRenderedContent() {

    const cardConfig = {
      startTime: this.props.context.startTime,
      endTime: this.props.context.endTime,
      resolutionLabel: '6 Hours',
      series1: {
        ...this.props.series1,
        latestLabel: 'Flow Rate (bpm)',
        chartUom: 'BPM',
        color: mdtPalette().categories.category2
      },
      series2: {
        ...this.props.series2,
        latestLabel: 'Suction (psi)',
        chartUom: 'PSI',
        color: mdtPalette().categories.category3
      },
      series3: {
        ...this.props.series3,
        latestLabel: 'Discharge (psi)',
        chartUom: 'PSI',
        color: mdtPalette().categories.category4
      }
    };

    if (!this.props.card.configuration.showRate) {
      // If flow rate is not to be displayed then remove it and shuffle the other two series around.
      cardConfig.series1 = {
        ...this.props.series2,
        latestLabel: 'Suction (psi)',
        chartUom: 'PSI',
        color: mdtPalette().categories.category2
      };
      cardConfig.series2 = {
        ...this.props.series3,
        latestLabel: 'Discharge (psi)',
        chartUom: 'PSI',
        color: mdtPalette().categories.category3
      }
      cardConfig.series3 = undefined;
    }

    return (<UnitChart cardConfig={cardConfig}/>)
  }
}

UnitChartPressureHistory.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_CHART_PRESSURE_HISTORY : props.stateKey,
      type: ComponentTypes.UNIT_CHART_PRESSURE_HISTORY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitChartState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    series1: componentState.series1,
    series2: componentState.series2,
    series3: componentState.series3,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (truckId, startTime, endTime) => { dispatch(unitChartActions.queryData(props.stateDef, unitChartTypes.PRESSURE_HISTORY, truckId, startTime, endTime)) },
    clearData: () => { dispatch(unitChartActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitChartPressureHistory));

