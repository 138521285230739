import _ from 'lodash';

const sensorSelectorState = state => {

  return {
    sensors: _.isNil(state) ? [] : state.sensors,
    groups: _.isNil(state) ? [] : state.groups,
    trucks: _.isNil(state) ? [] : state.trucks,
    startTime: _.isNil(state) ? null: state.startTime,
    endTime: _.isNil(state) ? null: state.endTime,

    selectedSensors: _.isNil(state) ? [] : state.selectedSensors,

    selectedIndexesForAdd: _.isNil(state) ? [] : state.selectedIndexesForAdd,
    selectedIndexesForRemove: _.isNil(state) ? [] : state.selectedIndexesForRemove,
    selectedGroupIndexesForAdd: _.isNil(state) ? [] : state.selectedGroupIndexesForAdd,
    lastQueryKey: _.isNil(state) ? '' : state.lastQueryKey,

    formIndex: _.isNil(state) ? 0 : state.formIndex,
    openGroupsContextMenuUI: _.isNil(state) ? false : state.openGroupsContextMenuUI,
    openDeleteGroupUI: _.isNil(state) ? false : state.openDeleteGroupUI,
    // Anchor element and user for the user context menu.
    groupsContextMenuTargetElement: _.isNil(state) ? null : state.groupsContextMenuTargetElement,
    groupsContextMenuGroup: _.isNil(state) ? null : state.groupsContextMenuGroup,

    showTargetUomMenu: _.isNil(state) ? false : state.showTargetUomMenu,
    // Anchor for the Selected Sensor Target UoM menu
    selectedSensorMenuTargetElement: _.isNil(state) ? null : state.selectedSensorMenuTargetElement,
    selectedSensorTargetUoms: _.isNil(state) ? [] : state.selectedSensorTargetUoms,
    selectedSensorForNewTargetUom: _.isNil(state) ? null : state.selectedSensorForNewTargetUom,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    isEditMode: _.isNil(state) ? false : state.isEditMode,

    shouldOpenCreateEditSensorGroup: _.isNil(state) ? false : state.shouldOpenCreateEditSensorGroup,
    sensorGroupNameHelperText: _.isNil(state) ? 'Name' : state.sensorGroupNameHelperText,
    hasSensorGroupNameError: _.isNil(state) ? false : state.hasSensorGroupNameError,
    userCanSaveGroup: _.isNil(state) ? false : state.userCanSaveGroup,
    groupToSave: _.isNil(state) ? { name: '', description: '' } : state.groupToSave,
  };
};

export {
  sensorSelectorState
};
