import React, {Component} from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {withProps} from 'recompose';
import {connect} from 'react-redux';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography, Tooltip, Chip, Box, IconButton } from '@mui/material';
import Progress from '../../controls/progress';
import ClientStatusDetailsDialog from './clientStatusDetailsDialog';
import FilterDialog from '../../common/filtering/filterDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from '../../common/table/dataTable';
import DashboardIcon from '@mui/icons-material/Dashboard';

import ComponentTypes from '../../componentTypes';
import * as clientStatusActions from '../../../state/displays/clientStatus/clientStatusActions';
import * as fleetMapActions from '../../../state/displays/fleetMap/display/fleetMapActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import * as statusIconHelpers from '../../common/statusIconHelper';
import { clientStatusState } from '../../../state/displays/clientStatus/clientStatusSelectors';
import { appState as applicationState} from '../../../state/app/appSelectors';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getTableFilterStyles from '../../common/styles/tableFilter';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();
const tableFilterStyles = getTableFilterStyles();
const statusIconStyles = statusIconHelpers.getStatusIconStyles();

const styles = {
  ...detailsPageStyles,
  ...tableFilterStyles,
  ...statusIconStyles,
  tableIconColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.primary'
  },
  tooltipTitle: {
    paddingBottom: 1
  },
  detailsPageHeaderTitle: {
    ...detailsPageStyles.detailsPageHeaderTitle,
    width: '220px'
  },
  filterItems: {
    paddingLeft: 3,
  },
  childComponent : {
    margin: 1,
  },
  filterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingLeft: 3,
    width: '800px'
  },
};

class ClientStatus extends Component {

  constructor(props) {
    super(props);
    this.renderTableCell = this.renderTableCell.bind(this);
    this.renderTableAction = this.renderTableAction.bind(this);
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.CLIENT_STATUS, this.props.user);

    this.queryData();
    this.startPeriodicDisplayRefresh();
    this.startPeriodicRelativeTimeRefresh();
  }

  componentWillUnmount() {
    this.stopPeriodicDisplayRefresh();
    this.stopPeriodicRelativeTimeRefresh();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      // Refresh the data if the owner changes.
      this.queryData();
    }
    if (prevProps.openDetailsUI === false && this.props.openDetailsUI === true) {
      // Disable the display refresh if we opening the details dialog.
      this.stopPeriodicDisplayRefresh();
    }
    if (prevProps.openDetailsUI === true && this.props.openDetailsUI === false) {
      // Refresh the data when coming back from viewing the client details.
      this.queryData();
      // Restart the display refresh when closing the details dialog
      this.startPeriodicDisplayRefresh();
    }
  }

  startPeriodicDisplayRefresh() {
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
  }

  startPeriodicRelativeTimeRefresh() {
    // Start the timer for refreshing the data age label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshDataAge();
  }

  stopPeriodicDisplayRefresh() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
      this.periodicDisplayRefreshId = null;
    }
  }

  stopPeriodicRelativeTimeRefresh() {
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
      this.periodicRelativeTimeRefreshId = null;
    }
  }

  periodicRefreshDisplay() {

    // Periodically refresh the display data.

    return setInterval(()=>{
      this.queryData();
    }, 900000); // 15 minutes
  }

  periodicRefreshDataAge() {

    // Periodically refresh the relative time for the data age.

    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  queryData() {
    if (!this.props.openDetailsUI) {
      // If the details dialog is not open, query the client status information. If the details
      // dialog is open it will take care of getting it's own data.
      this.props.loadDisplay(this.props.user.userId);
    }
  }

  generateTableColumns() {
    let columns = [];
    columns.push({type: 'data', width: 150, label: 'Fleet', displayProperty: 'fleetName', sortProperty: 'fleetName'});
    columns.push({type: 'custom', width: 150, label: 'Cloud Client', displayProperty: 'status', sortProperty: 'status' });
    columns.push({type: 'custom', width: 150, label: 'Sensor Upload', displayProperty: 'isUploading', sortProperty: 'isUploading' });
    columns.push({type: 'custom', width: 150, label: 'Sensor Data', displayProperty: 'dataUploadStatus', sortProperty: 'dataUploadStatus' });
    columns.push({type: 'custom', width: 135, label: 'Data Sources', displayProperty: 'datasourceStatus', sortProperty: 'datasourceStatus' });
    columns.push({type: 'action', width: 60, renderAction: this.renderTableAction});
    return columns;
  }

  renderTableCell(tableContext, column, item) {

    if (column.displayProperty === 'status') {
      return (
        <Box sx={{...styles.tableIconColumn, width: column.width}}>
          <Tooltip title={this.renderTooltip(item.description.title, item.description.caption)} followCursor={true}>
            {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
            {/* returning a child element will not work so wrap the function in a div */}
            <div>
              { statusIconHelpers.getClientStatusIcon(item.status, styles) }
            </div>
          </Tooltip>
        </Box>
      )
    }

    if (column.displayProperty === 'isUploading') {
      return (
        <Box sx={{...styles.tableIconColumn, width: column.width}}>
          <Tooltip title={this.renderTooltip(item.sensorUploadDescription.title, item.sensorUploadDescription.caption)} followCursor={true}>
            {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
            {/* returning a child element will not work so wrap the function in a div */}
            <div>
              { statusIconHelpers.getUploadStatusIcon(item.isUploading, styles) }
            </div>
          </Tooltip>
        </Box>
      )
    }

    if (column.displayProperty === 'dataUploadStatus') {
      return (
        <Box sx={{...styles.tableIconColumn, width: column.width}}>
          <Tooltip title={this.renderTooltip(item.sensorDataDescription.title, item.sensorDataDescription.caption)} followCursor={true}>
            {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
            {/* returning a child element will not work so wrap the function in a div */}
            <div>
              { statusIconHelpers.getDataStatusIcon(item.dataUploadStatus, styles) }
            </div>
          </Tooltip>
       </Box>
      )
    }

    if (column.displayProperty === 'datasourceStatus') {
      return (
        <Box sx={{...styles.tableIconColumn, width: column.width}}>
          <Tooltip title={this.renderTooltip(item.dataSourceAggregateStatusDescription.title, item.dataSourceAggregateStatusDescription.caption)} followCursor={true}>
            {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
            {/* returning a child element will not work so wrap the function in a div */}
            <div>
              { statusIconHelpers.getProductStatusIcon(item.datasourceStatus, styles) }
            </div>
          </Tooltip>
        </Box>
      )
    }

    return (<div></div>);
  }

  renderTooltip(title, caption) {
    return (
      <div>
        <Typography sx={styles.tooltipTitle} variant={'h6'}>{title}</Typography>
        <Typography variant={'caption'}>{caption}</Typography>
      </div>
    )
  }

  renderTableAction(tableContext, item) {
    if (!_.isNil(item)) {
      // NOTE: Since our table has a click handler for the row AND we are returning markup that also handles a click
      // event, we need to make sure to remember to stop the event propagation or else the client details window
      // will open in addition to navigating away.
      return (
        <IconButton
          onClick={(e) => { e.stopPropagation(); this.props.navigateToDashboard(item.fleetId) }}
          size="large"><DashboardIcon fontSize={'small'}/></IconButton>
      );
    }
  }

  render() {

    // Generate the columns data for the data table.
    let columns = this.generateTableColumns();

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Communication Status</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton onClick={() => this.queryData()} size="large"><RefreshIcon/></IconButton>
            </Box>
          </Box>
          <Box sx={styles.filterContainer}>
            <Typography variant={'subtitle1'}>FILTER:</Typography>
            <Box sx={styles.filterItems}>
            {
              this.props.appliedFilters.map((filter, index) => {
                return (
                    <Chip
                        label={filter.chipLabel}
                        onDelete={() => this.props.deleteFilter(index)}
                        sx={styles.childComponent}
                        key={index} />
                )
              })
            }
            </Box>
            <Box sx={styles.childComponent}>
              <IconButton
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                size="large"><AddCircleIcon/>
              </IconButton>
            </Box>
          </Box>
          <DataTable stateDef={this.props.stateDef} columns={columns} items={this.props.clients} rowClickSupport={true} renderTableCell={this.renderTableCell} />
        </Box>
        <ClientStatusDetailsDialog />
        <FilterDialog stateDef={this.props.stateDef}
                      appliedFilterLabels={this.props.appliedFilters.map(f => f.label)}/>
        <Progress open={this.props.queryRunning}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.CLIENT_STATUS : props.stateKey,
      type: ComponentTypes.CLIENT_STATUS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = clientStatusState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    clients: componentState.filteredClients,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    openDetailsUI : componentState.openDetailsUI,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: (userId) => { dispatch(clientStatusActions.loadDisplay(props.stateDef, userId)) },
    refreshRelativeTime: () => { dispatch(clientStatusActions.refreshRelativeTime(props.stateDef)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef)) },
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    navigateToDashboard: (fleetId) => dispatch(fleetMapActions.navigate(fleetId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ClientStatus))