import _ from 'lodash';
import providerTypes from '../../../../../components/common/providerTypes';

const providerDialogState = state => {

  return {

    activeStep: _.isNil(state) ? 0 : state.activeStep,

    providerName: _.isNil(state) ? '' : state.providerName,
    //Provider Types list should be updated when new type introduced.
    //With label/value pair to support material UI Autocomplete.
    availableProviderTypes: [{label:"Microsoft Teams", value:providerTypes.TEAMS},{label:"Twilio SMS", value:providerTypes.TWILIO}],
    //default provider type to teams on creation
    providerType: _.isNil(state) ? {label:"Microsoft Teams", value:providerTypes.TEAMS} : state.providerType,


    configs: _.isNil(state) ? {

      // Teams Channel object: { channelName, webhook }
      teamsChannels: _.isNil(state) ? [] : state.configs.teamsChannels,

      twilioAcctSid: _.isNil(state) ? '': state.twilioAcctSid,
      twilioAuthToken: _.isNil(state) ? '': state.twilioAuthToken,
      twilioFromNumber: _.isNil(state) ? '': state.twilioFromNumber,

    } :  state.configs,
    
    canSave: _.isNil(state) ? false : state.canSave,
    errorProviderName: _.isNil(state) ? false : state.errorProviderName,
    errorProviderType: _.isNil(state) ? false : state.errorProviderType,
    errorTeamsChannels: _.isNil(state) ? false : state.errorTeamsChannels,
    errorTwilio: _.isNil(state) ? false : state.errorTwilio,
  };
  
};

export {
  providerDialogState
};