import _ from 'lodash';

const unitChartState = state => {
  return {
    queryRunning : _.isNil(state) ? false : state.queryRunning,
    series1: _.isNil(state) ? [] : state.series1,
    series2: _.isNil(state) ? [] : state.series2,
    series3: _.isNil(state) ? [] : state.series3,
    series4: _.isNil(state) ? [] : state.series4,
  };
};

export {
  unitChartState
};
