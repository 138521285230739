import _ from 'lodash';

import filterActionTypes from '../../../common/filtering/filterActionTypes';
import * as filterService from '../../../common/filtering/filterService';
import * as componentsService from "../services/componentsService";

const filterReducer = (state, action) => {

  switch (action.type) {

    case filterActionTypes.FILTERS_OPEN_FILTER_DIALOG:
      return { ...state, ...filterService.onOpenFilterDialog(state, action) };

    case filterActionTypes.FILTERS_CLOSE_FILTER_DIALOG:
      return { ...state, ...filterService.onCloseFilterDialog(state, action) };

    case filterActionTypes.FILTERS_ADD_FILTER:
      return onAddFilter(state, action);

    case filterActionTypes.FILTERS_DELETE_FILTER:
      return onDeleteFilter(state, action);

    case filterActionTypes.FILTERS_SHOW_FILTER_TYPES:
      return { ...state, ...filterService.onShowFilterTypes(state, action) };

    case filterActionTypes.FILTERS_SEARCH_FILTER_VALUES:
      return { ...state, ...filterService.onSearchFilterValues(state, action) };

    default: return state;

  }
};

const onAddFilter = (state, action) => {

  //No filter? Just return the old state
  if (_.isNil(action.filter)) {
    return state;
  }

  // Let the common filter service handle the filter state.
  let filterState = filterService.onAddFilter(state, action);

  // If this is just a dialog navigation down from a filter type we do not need to do anything else.
  if (action.filter.type === 'filterType') {
    return {
      ...state,
      ...filterState
    };
  }

  // Otherwise we need to reprocess the units to reflect the new applied filters
  let unitDetails = componentsService.processUnits(state.units, filterState.appliedFilters);

  let selectedUnit = componentsService.resolveSelectedUnit(unitDetails.filteredUnits, _.isNil(state.selectedUnit) ? null : state.selectedUnit.id);

  return {
    ...state,
    ...filterState,
    filteredUnits: unitDetails.filteredUnits,
    filters: unitDetails.filters,
    selectedUnit: selectedUnit,
  };
};

const onDeleteFilter = (state, action) => {

  // // No filter index? Just return the old state
  if (_.isNil(action.index) || action.index < 0) {
    return state;
  }

  // Let the common filter service handle the filter state.
  let filterState = filterService.onDeleteFilter(state, action);

  // Reprocess the units
  let unitDetails = componentsService.processUnits(state.units, filterState.appliedFilters);

  return {
    ...state,
    ...filterState,
    filteredUnits: unitDetails.filteredUnits,
    filters: unitDetails.filters,
  };
};

export default filterReducer