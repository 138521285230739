import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {LinearScale} from "@mui/icons-material";
import {Grid} from '@mui/material';

/**
 * Draws a graphical indicator for each series of the given y-Axis Id.
 * 
 * This version is specific to the Card Chart.
 */
class CardChartAxisIndicator extends Component {

  render() {
    const {definition} = this.props;

    // divide up the configurations by yAxisId
    let allConfigs = [];
    if (!_.isNil(definition.primary) && !_.isNil(definition.primary.sensors) && !_.isEmpty(definition.primary.sensors) && !_.isNil(definition.primary.defaultContext)) {
      allConfigs.push(definition.primary.sensors);
    }
    if (!_.isNil(definition.secondary) && !_.isNil(definition.secondary.sensors) && !_.isEmpty(definition.secondary.sensors) && !_.isNil(definition.secondary.defaultContext)) {
      allConfigs.push(definition.secondary.sensors);
    }
    allConfigs = _.flatten(allConfigs);
    allConfigs = _.filter(allConfigs, ['isVisible', true]);
    let configs = _.filter(allConfigs, ['yAxisId', this.props.yAxisId]);
    return (
          <Grid container direction={(!_.isNil(this.props.reversed) && (this.props.reversed === true) ? 'column-reverse' : 'column')}
                        justifyContent={'center'}
                        alignItems={'stretch'}>
            {
              configs.map((item, index) => {
                return (
                <Grid item key={'yAxisId=' + index}>
                  <div>
                    <LinearScale key={'lo' + index} sx={{color: item.color}} fontSize={'small'}/>
                  </div>
                </Grid>
                );
              })
            }
          </Grid>
    );
  }
}

CardChartAxisIndicator.propTypes = {
  definition: PropTypes.object.isRequired,
  yAxisId: PropTypes.number.isRequired,
  isRotated: PropTypes.bool,
  reversed: PropTypes.bool,
};

export default CardChartAxisIndicator;
