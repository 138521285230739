import _ from 'lodash';
import unitChartActionTypes from './unitChartActionTypes';
import { unitChartState } from './unitChartSelectors';
import * as unitChartEngineService from './services/unitChartEngineService';
import * as unitChartTransmissionService from './services/unitChartTransmissionService';
import * as unitChartPowerEndService from './services/unitChartPowerEndService';
import * as unitChartCoilDetailService from './services/unitChartCoilDetailService';
import * as unitChartCoilService from './services/unitChartCoilService';
import * as unitChartLockupService from './services/unitChartLockupService';
import * as unitChartPressureHistoryService from './services/unitChartPressureHistoryService';
import * as unitChartOutputHistoryService from './services/unitChartOutputHistoryService';
import * as unitChartDfpPressureHistoryService from './services/unitChartDfpPressureHistoryService';
import * as unitChartVibrationHistory from "./services/unitChartVibrationHistoryService";

import unitChartTypes from './unitChartTypes';

const initialState = unitChartState();

const unitChartReducer = (state=initialState, action) => {
  switch (action.type) {
    case unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);
    case unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);
    case unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);
    case unitChartActionTypes.UNIT_CHART_CLEAR_DATA:
      return onClearData(state, action);
    default:
      return state;
  }
};

const onQueryDataStarting = (state, action) => {
  return { ...state, queryRunning: true };
};

const onQueryDataError = (state, action) => {
  return { ...state, queryRunning: false };
};

const onQueryDataSuccess = (state, action) => {
  let series;
  let chartType = action.queryResults.chartType;

  switch (chartType) {
    case unitChartTypes.ENGINE:
      series = unitChartEngineService.transformSeries(action.queryResults.unitEngineChart.series);
      break;
    case unitChartTypes.TRANSMISSION:
      series = unitChartTransmissionService.transformSeries(action.queryResults.unitTransmissionChart.series);
      break;
    case unitChartTypes.POWER_END:
      series = unitChartPowerEndService.transformSeries(action.queryResults.unitPowerEndChart.series);
      break;
    case unitChartTypes.LOCKUP:
      series = unitChartLockupService.transformSeries(action.queryResults.unitLockupChart.series);
      break;
    case unitChartTypes.COIL_DETAIL:
      series = unitChartCoilDetailService.transformSeries(action.queryResults.unitCoilDetailChart.series);
      break;
    case unitChartTypes.COIL:
      series = unitChartCoilService.transformSeries(action.queryResults.unitCoilChart.series);
      break;
    case unitChartTypes.PRESSURE_HISTORY:
      series = unitChartPressureHistoryService.transformSeries(action.queryResults.unitPressureHistoryChart.series);
      break;
    case unitChartTypes.OUTPUT_HISTORY:
      series = unitChartOutputHistoryService.transformSeries(action.queryResults.unitOutputHistoryChart.series);
      break;
    case unitChartTypes.ROADSIDE_PRESSURE_HISTORY:
      series = unitChartDfpPressureHistoryService.transformSeries(action.queryResults.unitPressureHistoryRoadsideChart.series);
      break;
    case unitChartTypes.CURBSIDE_PRESSURE_HISTORY:
      series = unitChartDfpPressureHistoryService.transformSeries(action.queryResults.unitPressureHistoryCurbsideChart.series);
      break;
    case unitChartTypes.VIBRATION_HISTORY:
      series = unitChartVibrationHistory.transformSeries(action.queryResults.unitVibrationHistory.series);
      break;
    default:
      series = null;
  }

  return {
    ...state,
    queryRunning: false,
    series1: _.isNil(series) ? null : series.series1,
    series2: _.isNil(series) ? null : series.series2,
    series3: _.isNil(series) ? null : series.series3,
    series4: _.isNil(series) ? null : series.series4,
  };
};

const onClearData = (state, action) => {
  return { ...state, series: initialState.series };
};

export default unitChartReducer
