import _ from 'lodash';
import moment from "moment";

import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from '../../app/actions/appErrorActions';

import auditLogsActionsTypes from './auditLogsActionTypes';

import { fetchAuditLogs, fetchAuditLogsMetadata } from './auditLogsQueries';

import { formatQuery, formatPageable, calculateEndTimeUsingStartTimeAndDuration, calculateStartTimeUsingTimeFrame } from './auditLogServices/auditLogService';

const queryAuditLogsMetadataStarting = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_STARTING, 'stateDef');
const queryAuditLogsMetadataSuccess = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_SUCCESS, 'stateDef', 'queryResults');
const queryAuditLogsMetadataError = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_ERROR, 'stateDef');

const queryAuditLogsMetadata = (stateDef) => {

  return async (dispatch, getState) => {

    let queryResults = null;

    try {
      await dispatch(queryAuditLogsMetadataStarting(stateDef));

      queryResults = await fetchAuditLogsMetadata();

      await dispatch(queryAuditLogsMetadataSuccess(stateDef, queryResults));

    } catch (e) {
      await dispatch(queryAuditLogsMetadataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_AUDIT_LOGS_METADATA, e.message));
    }
  }
}

const queryAuditLogsStarting = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_STARTING, 'stateDef');
const queryAuditLogsSuccess = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_SUCCESS, 'stateDef','queryResults');
const queryAuditLogsError = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_ERROR, 'stateDef');
  
const queryAuditLogs = (stateDef, query, page) => {

  return async (dispatch, getState) => {

    let queryResults = null;

    try {
      await dispatch(queryAuditLogsStarting(stateDef));

      const auditLogState = getState()[stateDef.key];
      let startTime = auditLogState.selectedTimeFrame.label === 'Custom' ? moment(auditLogState.selectedCustomStartTime).unix() : calculateStartTimeUsingTimeFrame(auditLogState.selectedTimeFrame);
      let endTime = auditLogState.selectedTimeFrame.label === 'Custom' ? calculateEndTimeUsingStartTimeAndDuration(auditLogState.selectedCustomStartTime, auditLogState.selectedCustomDuration) : moment().unix();
      const pageable = {
        page: page,
        size: auditLogState.rowsPerPage,
        sorts: auditLogState.sortModel.map(s=> ({ field: s.field, direction: s.sort.toUpperCase() })),
      }

      // If we are on a different page than what we are querying for, set the page to the one we requested for
      // This helps in cases where we are on page 2 with no results and hit Search again, we want to see page 1
      if (auditLogState.page !== page) {
        await dispatch(setPage(stateDef, page));
      }

      await dispatch(clearAuditLogs(stateDef));

      const formattedQuery = formatQuery(query, startTime, endTime);
      const formattedPageable = formatPageable(pageable);

      queryResults = await fetchAuditLogs(formattedQuery, formattedPageable);

      await dispatch(queryAuditLogsSuccess(stateDef, queryResults));

    } catch (e) {
      await dispatch(queryAuditLogsError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_AUDIT_LOGS, e.message));
    }
  }
}

const setPageAndQuery = (stateDef, page) => {
  return async (dispatch, getState) => {
    await dispatch(setPage(stateDef, page));
    const auditLogState = getState()[stateDef.key];
    const newPage = auditLogState.page;
    dispatch(queryAuditLogs(stateDef, auditLogState.query, newPage));
  }
}

const setRowsPerPageAndQuery = (stateDef, rowsPerPage) => {
  return async (dispatch, getState) => {
    await dispatch(setRowsPerPage(stateDef, rowsPerPage));
    const auditLogState = getState()[stateDef.key];
    const newPage = 0;
    if ((!_.isEmpty(auditLogState.auditLogs)) && (auditLogState.isQueryValid === true)) {
      dispatch(queryAuditLogs(stateDef, auditLogState.query, newPage));
    }
  }
}

const setSortModelAndQuery = (stateDef, sortModel) => {
  return async (dispatch, getState) => {
    await dispatch(setSortModel(stateDef, sortModel));
    const auditLogState = getState()[stateDef.key];
    const newPage = 0;
    if ((!_.isEmpty(auditLogState.auditLogs)) && (auditLogState.isQueryValid === true)) {
      dispatch(queryAuditLogs(stateDef, auditLogState.query, newPage));
    }
  }
}

const setQuery = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_QUERY, 'stateDef', 'query', 'isQueryValid');
const loadDefaultQuery = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_LOAD_DEFAULT_QUERY, 'stateDef');
const clearAuditLogs = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_CLEAR_AUDIT_LOGS, 'stateDef');
const setPage = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_PAGE, 'stateDef', 'page');
const setRowsPerPage = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_ROWS_PER_PAGE, 'stateDef', 'rowsPerPage');
const setSortModel = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_SORT_MODEL, 'stateDef', 'sortModel');

const setTimeFrame = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTime = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_CUSTOM_DURATION, 'stateDef', 'duration');
const setCustomStartTimeDisplay = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');

const expandQueryPanel = defaultActionFactory(auditLogsActionsTypes.AUDIT_LOGS_EXPAND_QUERY_PANEL, 'stateDef', 'expanded');

export {
  queryAuditLogsMetadata,
  queryAuditLogs,
  setQuery,
  loadDefaultQuery,
  clearAuditLogs,
  setPageAndQuery,
  setRowsPerPageAndQuery,
  setSortModelAndQuery,
  setPage,
  setRowsPerPage,
  setSortModel,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  setCustomStartTimeDisplay,
  expandQueryPanel
}