export default {
  FLEET_MAP_SELECT_FLEET: 'FLEET_MAP_SELECT_FLEET',
  FLEET_MAP_SELECT_VIEW: 'FLEET_MAP_SELECT_VIEW',
  FLEET_MAP_UPDATE_CUSTOM_VIEW_INPUT: 'FLEET_MAP_UPDATE_CUSTOM_VIEW_INPUT',
  FLEET_MAP_SET_NAVIGATION_CONTEXT: 'FLEET_MAP_SET_NAVIGATION_CONTEXT',

  FLEET_MAP_SET_TIME_FRAME: 'FLEET_MAP_SET_TIME_FRAME',
  FLEET_MAP_SET_CUSTOM_START_TIME: 'FLEET_MAP_SET_CUSTOM_START_TIME',
  FLEET_MAP_SET_CUSTOM_DURATION: 'FLEET_MAP_SET_CUSTOM_DURATION',

  FLEET_MAP_QUERY_SUMMARY_STARTING: 'FLEET_MAP_QUERY_SUMMARY_STARTING',
  FLEET_MAP_QUERY_SUMMARY_SUCCESS: 'FLEET_MAP_QUERY_SUMMARY_SUCCESS',
  FLEET_MAP_QUERY_SUMMARY_ERROR: 'FLEET_MAP_QUERY_SUMMARY_ERROR',

  FLEET_MAP_SET_CUSTOM_START_TIME_DISPLAY: 'FLEET_MAP_SET_CUSTOM_START_TIME_DISPLAY',
  FLEET_MAP_SHOW_DIALOG: 'FLEET_MAP_SHOW_DIALOG',

  FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_STARTING: 'FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_STARTING',
  FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_SUCCESS: 'FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_SUCCESS',
  FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_ERROR: 'FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_ERROR',

  FLEET_MAP_SAVE_DASHBOARD_TO_USER_STARTING: 'FLEET_MAP_SAVE_DASHBOARD_TO_USER_STARTING',
  FLEET_MAP_SAVE_DASHBOARD_TO_USER_SUCCESS: 'FLEET_MAP_SAVE_DASHBOARD_TO_USER_SUCCESS',
  FLEET_MAP_SAVE_DASHBOARD_TO_USER_ERROR: 'FLEET_MAP_SAVE_DASHBOARD_TO_USER_ERROR',

  FLEET_MAP_RESTORE_DEFAULTS_STARTING: 'FLEET_MAP_RESTORE_DEFAULTS_STARTING',
  FLEET_MAP_RESTORE_DEFAULTS_SUCCESS: 'FLEET_MAP_RESTORE_DEFAULTS_SUCCESS',
  FLEET_MAP_RESTORE_DEFAULTS_ERROR: 'FLEET_MAP_RESTORE_DEFAULTS_ERROR',

  FLEET_MAP_LOAD_CARDS_STARTING: 'FLEET_MAP_LOAD_CARDS_STARTING',
  FLEET_MAP_LOAD_CARDS_SUCCESS: 'FLEET_MAP_LOAD_CARDS_SUCCESS',
  FLEET_MAP_LOAD_CARDS_ERROR: 'FLEET_MAP_LOAD_CARDS_ERROR',
  
  FLEET_MAP_DISCARD_CUSTOM_VIEW_CONFIGURATION: 'FLEET_MAP_DISCARD_CUSTOM_VIEW_CONFIGURATION',
  FLEET_MAP_CARDS_QUERY_RUNNING: 'FLEET_MAP_CARDS_QUERY_RUNNING',

  FLEET_MAP_SET_MANUAL_REFRESH: 'FLEET_MAP_SET_MANUAL_REFRESH',
};