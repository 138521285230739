import fleetVibrationActionTypes from './fleetVibrationActionTypes';
import { fleetVibrationState } from "./fleetVibrationSelectors";

const initialState = fleetVibrationState();

const fleetVibrationReducer = (state=initialState, action) => {

  switch (action.type) {

    case fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetVibrationActionTypes.FLEET_VIBRATION_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    case fleetVibrationActionTypes.FLEET_VIBRATION_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQueryDataSuccess = (state, action) => {

  let { powerEnd, fluidEnd, iron, manifold } = action.queryResults.fleetVibration;

  return {
    ...state,
    queryRunning: false,
    powerEnd: powerEnd,
    fluidEnd: fluidEnd,
    iron: iron,
    manifold: manifold,
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    powerEnd: initialState.powerEnd,
    fluidEnd: initialState.fluidEnd,
    iron: initialState.iron,
    manifold: initialState.manifold,
  };
};

export default fleetVibrationReducer