import _ from 'lodash';

import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from '../../app/actions/appErrorActions';

import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import * as filterActions from "../../common/filtering/filterActions";

import * as rootActions from '../../common/rootActions';

import {
  fetchJobEventLogs,
  fetchSaveJobStatus,
  fetchStageData,
  fetchJobByName,
  fetchSaveStageStatus,
  fetchJobsForOwner,
  fetchStageStatus,
  fetchStageDefinition
} from './jobOverviewQueries';
import jobOverviewActionTypes from './jobOverviewActionTypes';
import * as jobStatusConstants from "../../../components/common/jobStatusConstants";
import jobOverviewViews from './jobOverviewViews';

const queryJobDataStarting = defaultActionFactory(jobOverviewActionTypes.QUERY_JOB_DATA_STARTING, 'stateDef');
const queryJobDataSuccess = defaultActionFactory(jobOverviewActionTypes.QUERY_JOB_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryJobDataError = defaultActionFactory(jobOverviewActionTypes.QUERY_JOB_DATA_ERROR, 'stateDef');


const queryJobsDataStarting = defaultActionFactory(jobOverviewActionTypes.QUERY_JOBS_DATA_STARTING, 'stateDef');
const queryJobsDataSuccess = defaultActionFactory(jobOverviewActionTypes.QUERY_JOBS_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryJobsDataError = defaultActionFactory(jobOverviewActionTypes.QUERY_JOBS_DATA_ERROR, 'stateDef');

const loadDisplay = (stateDef, userId) => {
  return async (dispatch, getState) => {

    //load if any user display configuration for this page
    const userConfigForPage = await dispatch(appUserConfigActions.queryUserConfigurationForPage(stateDef, userId, "jobOverview"));
    if (!_.isEmpty(userConfigForPage)){
      try{
        // load user saved configuration for the page to state
        await dispatch(filterActions.onSetAppliedFilters(stateDef, userConfigForPage));
      } catch (err){
        //just log the error and let it continue, as once page loaded then user can do filter again which can trigger save with valid data.
      }
    }

  }
};

const queryJobsData = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(queryJobsDataStarting(stateDef));

      queryResults = await fetchJobsForOwner();

      await dispatch(queryJobsDataSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(queryJobsDataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_JOBS_OVERVIEW, e.message));
    }
  }
}

const queryJobData = (stateDef, jobName) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(queryJobDataStarting(stateDef));

      queryResults = await fetchJobByName(jobName);

      await dispatch(queryJobDataSuccess(stateDef, queryResults));

      if (!_.isNil(queryResults.job)) {
        await dispatch(queryEventLogs(stateDef, queryResults.job.id));

        await dispatch(queryStageData(stateDef, queryResults.job));
      }

    } catch(e) {
      await dispatch(queryJobDataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_JOB_OVERVIEW, e.message));
    }
  }
}

const showDialog = defaultActionFactory(jobOverviewActionTypes.DIALOG_SHOW, 'stateDef', 'show');
const dialogSetType = defaultActionFactory(jobOverviewActionTypes.DIALOG_SET_TYPE, 'stateDef', 'dialogType');
const dialogSetNotes = defaultActionFactory(jobOverviewActionTypes.DIALOG_SET_NOTES, 'stateDef', 'value');

const queryEventLogsStarting = defaultActionFactory(jobOverviewActionTypes.QUERY_EVENT_LOGS_STARTING, 'stateDef', 'jobId');
const queryEventLogsSuccess = defaultActionFactory(jobOverviewActionTypes.QUERY_EVENT_LOGS_SUCCESS, 'stateDef', 'queryResults');
const queryEventLogsError = defaultActionFactory(jobOverviewActionTypes.QUERY_EVENT_LOGS_ERROR, 'stateDef', 'jobId');

const queryEventLogs = (stateDef, jobId) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(queryEventLogsStarting(stateDef, jobId));

      queryResults = await fetchJobEventLogs(jobId);

      await dispatch(queryEventLogsSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(queryEventLogsError(stateDef, jobId));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_JOB_EVENT_LOGS, e.message));
    }
  }
}


const saveJobStatusStarting = defaultActionFactory(jobOverviewActionTypes.SAVE_JOB_STATUS_STARTING, 'stateDef');
const saveJobStatusSuccess = defaultActionFactory(jobOverviewActionTypes.SAVE_JOB_STATUS_SUCCESS, 'stateDef', 'queryResults');
const saveJobStatusError = defaultActionFactory(jobOverviewActionTypes.SAVE_JOB_STATUS_ERROR, 'stateDef');

const saveJobStatus = (stateDef, id, status, user, notes) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(saveJobStatusStarting(stateDef));

      let job = {
        id: id,
        status: status,
        user: user,
        note: notes
      }

      queryResults = await fetchSaveJobStatus(job);

      await dispatch(saveJobStatusSuccess(stateDef, queryResults));

      await dispatch(queryEventLogs(stateDef, id));

    } catch(e) {
      await dispatch(saveJobStatusError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_JOB, e.message));
    }
  }
}


const queryStageDataStarting = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DATA_STARTING, 'stateDef');
const queryStageDataSuccess = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DATA_SUCCESS, 'stateDef', 'queryResults', 'job');
const queryStageDataError = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DATA_ERROR, 'stateDef');

const queryStageData = (stateDef, job) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {
      await dispatch(queryStageDataStarting(stateDef));

      queryResults = await fetchStageData(job.id);

      await dispatch(queryStageDataSuccess(stateDef, queryResults, job));

    } catch(e) {
      await dispatch(queryStageDataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_STAGE_DATA, e.message));
    }
  }
}

const queryStageDefinitionStarting = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DEFINITION_STARTING, 'stateDef');
const queryStageDefinitionSuccess = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DEFINITION_SUCCESS, 'stateDef', 'queryResults');
const queryStageDefinitionError = defaultActionFactory(jobOverviewActionTypes.QUERY_STAGE_DEFINITION_ERROR, 'stateDef');

const queryStageDefinition = (stateDef, stageId) => {
  return async (dispatch, getState) => {
    let queryResults = null; 

    try {
      await dispatch(queryStageDefinitionStarting(stateDef));
      
      queryResults = await fetchStageDefinition(stageId);

      await dispatch(queryStageDefinitionSuccess(stateDef, queryResults));
      
    } catch (e) {
      await dispatch(queryStageDefinitionError(stateDef)); 

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_STAGE_DEFINITION));
    }
  }
}

const onSelectStage = defaultActionFactory(jobOverviewActionTypes.SELECT_STAGE, 'stateDef', 'stage'); 
const selectStage = (stateDef, stage) => {
  return async (dispatch, getState) => {
    await dispatch(onSelectStage(stateDef, stage));

    await dispatch(queryStageDefinition(stateDef, stage.id)); 
  }
};

const selectUnitIndex = defaultActionFactory(jobOverviewActionTypes.SELECT_UNIT_INDEX, 'stateDef', 'unitIndex');

const showStageViewer = defaultActionFactory(jobOverviewActionTypes.SHOW_STAGE_VIEWER, 'stateDef', 'show');
const showEventHistoryViewer = defaultActionFactory(jobOverviewActionTypes.SHOW_EVENT_HISTORY_VIEWER, 'stateDef', 'show');

const saveStageStatusStarting = defaultActionFactory(jobOverviewActionTypes.SAVE_STAGE_STATUS_STARTING, 'stateDef');
const saveStageStatusSuccess = defaultActionFactory(jobOverviewActionTypes.SAVE_STAGE_STATUS_SUCCESS, 'stateDef', 'queryResults');
const saveStageStatusError = defaultActionFactory(jobOverviewActionTypes.SAVE_STAGE_STATUS_ERROR, 'stateDef');

const saveStageStatus = (stateDef, id, status, user, notes, jobName, stageGuid) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {

      await dispatch(saveStageStatusStarting(stateDef));

      // If a Stage already in a new status rather than approval request/accept/reject,
      // then its status is not allowed te change.
      const currentStageContext = await fetchStageStatus(jobName, stageGuid);
      if(!(currentStageContext.stage.status == jobStatusConstants.STAGE_APPROVAL_ACCEPT
          || currentStageContext.stage.status == jobStatusConstants.STAGE_APPROVAL_REJECT
          || currentStageContext.stage.status == jobStatusConstants.STAGE_APPROVAL_REQUEST)){
        await dispatch(saveStageStatusError(stateDef)); // reset state on save status error
        await dispatch(queryJobData(stateDef, jobName)); // force reload to get latest job/stage/event data
        return dispatch(handleError(errorMessages.ERROR_ACCEPT_REJECT_STAGE)); // show error msg for user
      }

      let stage = {
        id: id,
        status: status,
        user: user,
        note: notes
      }

      queryResults = await fetchSaveStageStatus(stage);

      await dispatch(saveStageStatusSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(saveStageStatusError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_SAVING_STAGE, e.message));
    }
  }
}

const selectView = defaultActionFactory(jobOverviewActionTypes.SELECT_VIEW, 'stateDef', 'view');
const addChartForStage = defaultActionFactory(jobOverviewActionTypes.CREATE_CHART_FOR_STAGE, 'stateDef', 'stage');

const createChartForStage = (stateDef, stage) => {
  return async (dispatch, getState) => {
    await dispatch(addChartForStage(stateDef, stage));
    await dispatch(selectView(stateDef, jobOverviewViews.TREATMENTPLOT));
  }
}

const updateChartsLayout = defaultActionFactory(jobOverviewActionTypes.UPDATE_CHARTS_LAYOUT, 'stateDef', 'newLayout');
const cardsQueryRunning = defaultActionFactory(jobOverviewActionTypes.CARDS_QUERY_RUNNING, 'stateDef', 'queryRunning');
const removeChart = defaultActionFactory(jobOverviewActionTypes.REMOVE_CHART, 'stateDef', 'chartKey');
const removeAllCharts = defaultActionFactory(jobOverviewActionTypes.REMOVE_ALL_CHARTS, 'stateDef');

const removeChartAndCleanUp = (stateDef, chartKey) => {
  return async (dispatch, getState) => {
    await dispatch(removeChart(stateDef, chartKey));

    await dispatch(rootActions.removeState([chartKey]));
  }
}

export {
  queryJobsData,
  queryJobData,
  dialogSetNotes,
  showDialog,
  queryEventLogs,
  saveJobStatus,
  queryStageData,
  selectStage,
  selectUnitIndex,
  showStageViewer,
  saveStageStatus,
  dialogSetType,
  showEventHistoryViewer,
  loadDisplay,
  selectView,
  createChartForStage,
  updateChartsLayout,
  cardsQueryRunning,
  removeAllCharts,
  removeChartAndCleanUp
}