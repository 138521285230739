import _ from 'lodash';

const fleetComponentsState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    components: _.isNil(state) ? null : state.components,
  };

};

export {
  fleetComponentsState
};