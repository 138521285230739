import _ from "lodash";
import React, { Component } from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';

import { Dialog, DialogContent, DialogActions, DialogTitle, Box, Button } from '@mui/material';

import ComponentTypes from "../../componentTypes";

import { jobOverviewState } from "../../../state/displays/jobOverview/jobOverviewSelectors";
import * as jobOverviewActions from '../../../state/displays/jobOverview/jobOverviewActions';
import StageDataGrid from './controls/stageDataGrid';

class StageViewer extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Dialog maxWidth={'false'} fullWidth scroll="paper" open={this.props.showStageViewer} disableEscapeKeyDown>
        <DialogTitle>{_.isNil(this.props.selectedJobInfo) ? '' : 'Stages for ' + this.props.selectedJobInfo.name} </DialogTitle>
        <DialogContent sx={{ height: '95vh' }} >

          <Box sx={{height: '100%', paddingBottom: 1}}>
            <StageDataGrid 
              stateDef={this.props.stateDef} 
              stages={this.props.stages} 
              selectStageCallback={this.props.onSelectStage} 
              approvalCallback={this.props.onShowDialog} 
              selectedStage={this.props.selectedStage}
              dialogSetTypeCallback={this.props.onDialogSetType}
              />
          </Box>
          
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'center', marginBottom: 2, marginTop: 1}}>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onShowStageViewer(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_OVERVIEW : props.stateKey,
      type: ComponentTypes.JOB_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobOverviewState(state[stateDef.key]);

  return {
    showStageViewer: componentState.showStageViewer,
    stages: componentState.stages,
    selectedStage: componentState.selectedStage,
    selectedJobInfo: componentState.selectedJobInfo,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowStageViewer: (show) => { dispatch(jobOverviewActions.showStageViewer(props.stateDef, show)); },
    onSelectStage: (stage) => { dispatch(jobOverviewActions.selectStage(props.stateDef, stage)); },
    onShowDialog: (show) => { dispatch(jobOverviewActions.showDialog(props.stateDef, show)); },
    onDialogSetType: (dialogType) => { dispatch(jobOverviewActions.dialogSetType(props.stateDef, dialogType)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(StageViewer));