import fleetPumpStatusActionTypes from './fleetPumpStatusActionTypes';
import { fleetPumpStatusState } from './fleetPumpStatusSelectors';
import _ from "lodash";

const initialState = fleetPumpStatusState();

const fleetPumpStatusReducer = (state=initialState, action) => {

  switch (action.type) {
    case fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_CLEAR_DATA:
      return onClearData(state, action);

    case fleetPumpStatusActionTypes.FLEET_PUMP_STATUS_SET_CHART_OPTIONS:
      return { ...state, chartOptions: action.chartOptions, updateChartOptions: false };

    default: return state;
  }
};


const onQueryDataSuccess = (state, action) => {

  const noValue = '-';

  let { count, pumping, idle, stopped, offline } = action.queryResults.fleetPumpStatus;

  let displayValueCount = _.isNil(count) ? noValue : parseFloat(count.toFixed(0)).toLocaleString('en');
  let displayValuePumping = _.isNil(pumping) ? noValue : parseFloat(pumping.toFixed(0)).toLocaleString('en');
  let displayValueIdle = _.isNil(idle) ? noValue : parseFloat(idle.toFixed(0)).toLocaleString('en');
  let displayValueStopped = _.isNil(stopped) ? noValue : parseFloat(stopped.toFixed(0)).toLocaleString('en');
  let displayValueOffline = _.isNil(offline) ? noValue : parseFloat(offline.toFixed(0)).toLocaleString('en');

  return {
    ...state,
    queryRunning: false,
    rawValueCount: count,
    rawValuePumping: pumping,
    rawValueIdle: idle,
    rawValueStopped: stopped,
    rawValueOffline: offline,
    displayValueCount: displayValueCount,
    displayValuePumping: displayValuePumping,
    displayValueIdle: displayValueIdle,
    displayValueStopped: displayValueStopped,
    displayValueOffline: displayValueOffline,
    updateChartOptions: !_.isNil(count) && count > 0 ? true : false,
  };
};

const onClearData = (state, action) => {

  return {
    ...state,
    queryRunning: false,
    rawValueCount: initialState.rawValueCount,
    rawValuePumping: initialState.rawValuePumping,
    rawValueIdle: initialState.rawValueIdle,
    rawValueStopped: initialState.rawValueStopped,
    rawValueOffline: initialState.rawValueOffline,
    displayValueCount: initialState.displayValueCount,
    displayValuePumping: initialState.displayValuePumping,
    displayValueIdle: initialState.displayValueIdle,
    displayValueStopped: initialState.displayValueStopped,
    displayValueOffline: initialState.displayValueOffline,
    updateChartOptions: true,
    chartOptions: initialState.chartOptions,
  };

};

export default fleetPumpStatusReducer