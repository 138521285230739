import { handleError } from '../../../app/actions/appErrorActions';
import * as notificationsActions from '../../../app/actions/appNotificationsActions';
import subscriptionDialogActionTypes from './subscriptionDialogActionTypes';
import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';

import { fetchSubscriptionDialogProviders, fetchSubscriptionDialogEvents } from "./subscriptionDialogQueries";

const setActiveStep = defaultActionFactory(subscriptionDialogActionTypes.SET_ACTIVE_STEP, 'stateDef', 'step');

const setSubscriptionName = defaultActionFactory(subscriptionDialogActionTypes.SET_SUBSCRIPTION_NAME, 'stateDef', 'value');
const selectEvent = defaultActionFactory(subscriptionDialogActionTypes.SELECT_EVENT, 'stateDef', 'value');
const selectEventType = defaultActionFactory(subscriptionDialogActionTypes.SELECT_EVENT_TYPE, 'stateDef', 'value');

const queryDataStarting = defaultActionFactory(subscriptionDialogActionTypes.QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory( subscriptionDialogActionTypes.QUERY_DATA_SUCCESS, 'stateDef', 'queryResults', 'context', 'entity','showPreReleaseContent');
const queryDataError = defaultActionFactory(subscriptionDialogActionTypes.QUERY_DATA_ERROR, 'stateDef');

const queryData = (stateDef, context, entity, showPreReleaseContent) => {
  return async (dispatch, getState) => {
    let eventsQueryResults = null;
    let providersQueryResults = null;

    try {
      await dispatch(queryDataStarting(stateDef));

      eventsQueryResults = await fetchSubscriptionDialogEvents();
      providersQueryResults = await fetchSubscriptionDialogProviders();

      let queryResults = {
        events: eventsQueryResults.events,
        providerBasics: providersQueryResults.providerBasics,
      }
  
      // This can work with both cases where:
      // 1. we are working with an existing entity and context (creating a Subscription from the Alarms display)
      // 2. we have no existing context and entity (creating a Subscription from the Subscription display)
      await dispatch(queryDataSuccess(stateDef, queryResults, context, entity, showPreReleaseContent));
    } catch(e) {
      await dispatch(queryDataError(stateDef));

      await dispatch(notificationsActions.showSubscriptionDialog(false, context));
      
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_SUBSCRIPTION_DIALOG_DATA, e.message));
    }
  }
};

const onSetFilterMultiValues = (stateDef, value, id) => {
  return async (dispatch, getState) => {

    try {
      await dispatch(setFilterMultiValues(stateDef, value, id));

      if(getState()[stateDef.key].invalidInput){
        return dispatch(handleError(`Invalid value input.`, null));
      }

    } catch(e) {

      return dispatch(handleError(errorMessages.ERROR_INPUT_FILTER_CONDITION, e.message));
    }
  }
};

const resolveFilters = defaultActionFactory(subscriptionDialogActionTypes.RESOLVE_FILTERS, 'stateDef', 'event', 'eventType');

const addFilter = defaultActionFactory(subscriptionDialogActionTypes.ADD_FILTER, 'stateDef');
const removeFilter = defaultActionFactory(subscriptionDialogActionTypes.REMOVE_FILTER, 'stateDef', 'id');
const selectFilterAttribute = defaultActionFactory(subscriptionDialogActionTypes.SELECT_FILTER_ATTRIBUTE, 'stateDef', 'value', 'id');
const selectFilterOperator = defaultActionFactory(subscriptionDialogActionTypes.SELECT_FILTER_OPERATOR, 'stateDef', 'value', 'id');
const setFilterValue = defaultActionFactory(subscriptionDialogActionTypes.SET_FILTER_VALUE, 'stateDef', 'value', 'id');
const setFilterMultiValues = defaultActionFactory(subscriptionDialogActionTypes.SET_FILTER_MULTI_VALUES, 'stateDef', 'value', 'id');

const addRecipient = defaultActionFactory(subscriptionDialogActionTypes.ADD_RECIPIENT, 'stateDef');
const removeRecipient = defaultActionFactory(subscriptionDialogActionTypes.REMOVE_RECIPIENT, 'stateDef', 'id');
const selectProvider = defaultActionFactory(subscriptionDialogActionTypes.SELECT_PROVIDER, 'stateDef', 'value', 'id');
const setDestination = defaultActionFactory(subscriptionDialogActionTypes.SET_DESTINATION, 'stateDef', 'value', 'id');

const clearForm = defaultActionFactory(subscriptionDialogActionTypes.CLEAR_FORM, 'stateDef');

export {
  setActiveStep,
  setSubscriptionName,
  selectEvent,
  selectEventType,
  queryData,
  addFilter,
  removeFilter,
  selectFilterAttribute,
  selectFilterOperator,
  setFilterValue,
  onSetFilterMultiValues,
  addRecipient,
  removeRecipient,
  selectProvider,
  setDestination,
  resolveFilters,
  clearForm
}