import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import {withProps} from 'recompose';
import {connect} from 'react-redux';
import { compose } from 'recompose';

import { Typography, Tooltip, Chip, Box, IconButton, Switch } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from '../../common/table/dataTable';
import FilterDialog from '../../common/filtering/filterDialog';
import Progress from '../../controls/progress';
import RefreshIcon from '@mui/icons-material/Refresh';
import GetAppIcon from '@mui/icons-material/GetApp';
import ActiveAlarmIcon from '../../controls/icons/activeAlarms';
import HistoryIcon from '@mui/icons-material/History';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

import ExportCsv from '../../controls/exportCsv/exportCsv';
import { AutoCompleteMDT, DateTimePickerMDT } from '../../controls/mdtMuiControls';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import getTableFilterStyles from '../../common/styles/tableFilter';
import ComponentTypes from '../../componentTypes';
import * as alarmsActions from '../../../state/displays/alarms/alarmsActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import * as alarmStatusHelper from  '../../common/alarmStatusIconHelper';
import { alarmsState } from '../../../state/displays/alarms/alarmsSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';
import * as notificationsActions from '../../../state/app/actions/appNotificationsActions';
import SubscriptionDialog from '../../controls/notifications/subscriptionDialog';
import subscriptionEntity from '../../common/subscriptionEntity';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();
const tableFilterStyles = getTableFilterStyles();
const alarmStatusStyles = alarmStatusHelper.getAlarmStatusIconStyles();

const styles = {
  ...detailsPageStyles,
  ...tableFilterStyles,
  ...alarmStatusStyles,
  detailsPageHeaderTitle: {
    ...detailsPageStyles.detailsPageHeaderTitle,
    width: '150px'
  },
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    paddingBottom: 2,
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  },
  timeFilterItem: {
    paddingLeft: 1,
    paddingRight: 3,
  },
  pageHeaderButtonActive: {
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px',
    borderColor: 'primary.main',
  },
  viewSeparator: {
    width: '96px'
  },
  tableIconColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableTextColumn: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 1,
    paddingRight: 1,
    wordBreak: 'break-word'
  },
  raisedTimestampLabel: {
    paddingTop: 1
  },
  timeFrameSelection: {
    width: '150px',
  },
  startTimeSelection: {
    width: '200px'
  },
  tableFilters: {
    ...tableFilterStyles.tableFilterContainer,
    flexGrow: 1
  },
  tableOptions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  }
};

class AlarmsDisplay extends Component {

  constructor(props) {
    super(props);
    this.renderTableCell = this.renderTableCell.bind(this);
    this.renderTableAction = this.renderTableAction.bind(this);
  }

  componentDidMount() {
    // Track GA View (on initial load)
    trackPage(ComponentTypes.ALARMS_DISPLAY + '/' + (this.props.showingActiveAlarms ? ComponentTypes.ACTIVE_ALARMS : ComponentTypes.HISTORICAL_ALARMS), 
      this.props.user);
    // Load the display data
    this.queryData();
    // Start the timer for refreshing the display.
    this.periodicItemsRefreshId = this.periodicRefreshItems();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the items.
    if (!_.isNil(this.periodicItemsRefreshId)) {
      clearInterval(this.periodicItemsRefreshId);
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      // Query the data again if the owner changes.
      this.queryData();
    }

    if (prevProps.showingActiveAlarms !== this.props.showingActiveAlarms ||
        prevProps.selectedTimeFrame !== this.props.selectedTimeFrame ||
        prevProps.selectedCustomStartTime !== this.props.selectedCustomStartTime ||
        prevProps.selectedCustomDuration !== this.props.selectedCustomDuration) {
      this.queryData();
    }

    // Track GA View (when view changes)
    if (prevProps.showingActiveAlarms !== this.props.showingActiveAlarms) {
      trackPage(ComponentTypes.ALARMS_DISPLAY + '/' + (this.props.showingActiveAlarms ? ComponentTypes.ACTIVE_ALARMS : ComponentTypes.HISTORICAL_ALARMS), 
        this.props.user);
    }
  }

  periodicRefreshItems() {
    // Periodically refresh the alarms.
    return setInterval(() => this.queryData(), 900000); // 15 minutes
  }

  queryData() {

    let startTime = null;
    let endTime = null;

    if (this.props.showingActiveAlarms === false) {
      if (this.props.selectedTimeFrame.label !== 'Custom') {
        endTime = moment().startOf('minute').unix();
        startTime = moment.unix(endTime).subtract(this.props.selectedTimeFrame.value, 'minutes').unix();
      } else {
        startTime = moment(this.props.selectedCustomStartTime).unix();
        endTime = moment.unix(startTime).add(this.props.selectedCustomDuration.value, 'minutes').unix();
      }
    }

    this.props.queryAlarms(this.props.showingActiveAlarms, startTime, endTime);
  }

  generateTableColumns(showingActiveAlarms) {
    let columns = [];
    columns.push({type: 'data', width: 125, label: 'Unit ID', displayProperty: 'unitName', sortProperty: 'unitName'});
    columns.push({type: 'custom', width: 100, label: 'Severity', displayProperty: 'severity', sortProperty: 'severity'});
    columns.push({type: 'custom', width: 300, label: 'Category', displayProperty: 'category', sortProperty: 'category'});
    columns.push({type: 'custom', width: 500, label: 'Details', displayProperty: 'details', sortProperty: 'details'});
    columns.push({type: 'data', width: 130, label: 'Source', displayProperty: 'type', sortProperty: 'type'});
    columns.push({type: 'data', width: 100, label: 'State', displayProperty: 'state', sortProperty: 'state'});
    if (showingActiveAlarms) {
      columns.push({type: 'data', width: 180, label: 'Age', displayProperty: 'displayValueAge', sortProperty: 'age'});
    } else {
      columns.push({type: 'data', width: 180, label: 'Timestamp', displayProperty: 'displayValueTimestamp', sortProperty: 'timestamp'});
    }
    columns.push({type: 'data', width: 150, label: 'Fleet', displayProperty: 'fleetName', sortProperty: 'fleetName'});
    columns.push({type: 'action', width: 60, renderAction: this.renderTableAction});
    return columns;
  }

  renderTableCell(tableContext, column, item) {

    if (column.displayProperty === 'category') {
      return (
        <Box sx={{...styles.tableTextColumn, width: column.width}}>
          <Typography variant={'body2'}>{item.category}</Typography>
        </Box>
      );
    }

    if (column.displayProperty === 'details') {
      let showRaisedTimestamp = this.props.showingActiveAlarms === true && item.state === 'Acknowledged';
      return (
        <Box sx={{...styles.tableTextColumn, width: column.width}}>
          <Typography variant={'body2'}>{item.details}</Typography>
          {
            showRaisedTimestamp === true &&
            <Typography sx={styles.raisedTimestampLabel} variant={'caption'}>Raised: {item.displayValueRaisedTimestamp}</Typography>
          }
        </Box>
      );
    }

    if (column.displayProperty === 'severity') {
      return (
        <Box sx={{...styles.tableIconColumn, width: column.width}}>
          { alarmStatusHelper.getAlarmStatusIcon(item.severity, styles) }
        </Box>
      )
    }

    return (<div></div>);
  }

  renderTableAction(tableContext, item) {
    // Allow creation of subscriptions on Raised Alarms
    return <Tooltip title="Create Notification" disableInteractive={true}><IconButton onClick={() => this.props.onShowSubscriptionDialog(true, item)} size="large"><NotificationAddIcon fontSize={'small'}/></IconButton></Tooltip>;
  }

  render() {

    let columns = this.generateTableColumns(this.props.showingActiveAlarms);

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    let showAdditionalTimeFilters = this.props.showingActiveAlarms === false && !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>
                { 
                  this.props.showingActiveAlarms === true
                  ? 'Active Alarms'
                  : 'Alarm History'
                }
              </Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <Tooltip title='Refresh'>
                <IconButton onClick={() => this.queryData()} size='large'>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Download CSV'>
                <IconButton
                  onClick={() =>
                    this.props.exportCsv(
                      this.props.alarms,
                      this.props.sortContext,
                      this.props.sortDirection
                    )
                  }
                  size='large'
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              <Box sx={styles.viewSeparator} />
              <Tooltip title='Active Alarms'>
                <Box sx={this.props.showingActiveAlarms === true ? {...styles.pageHeaderButtonActive} : {}}>
                  <IconButton color={this.props.showingActiveAlarms === true ? 'primary' : 'inherit'} onClick={() => this.props.showActiveAlarms()}>
                    <ActiveAlarmIcon/>
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title="Alarm History">
                <Box sx={this.props.showingActiveAlarms === false ? {...styles.pageHeaderButtonActive} : {}}>
                  <IconButton color={this.props.showingActiveAlarms === false ? 'primary' : 'inherit'} onClick={() => this.props.showAlarmHistory()}>
                    <HistoryIcon/>
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          </Box>
          {
            this.props.showingActiveAlarms === false && (
            <Box sx={styles.timeFilterContainer}>
              <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
              <Box sx={styles.timeFilterItem}>
                <AutoCompleteMDT
                  sx={styles.timeFrameSelection}
                  getOptionLabel={(timeFrame) => timeFrame.label}
                  options={this.props.timeFrames}
                  value={this.props.selectedTimeFrame}
                  onChange={(event, value, reason) => {
                    this.props.setTimeFrame(value);
                  }}
                  noOptionsText={"No data found..."}
                />
              </Box>
              {
                showAdditionalTimeFilters === true && 
                (
                <Box sx={styles.timeFilterContainer}>
                  <Typography variant={"subtitle1"}>START:</Typography>
                  <Box sx={styles.timeFilterItem}>
                    <Box sx={styles.startTimeSelection}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePickerMDT
                          value={this.props.selectedCustomStartTimeDisplay}
                          onAccept={(value) => {
                            if (!_.isNil(value)) {
                              this.props.setCustomStartTime(value);
                            }
                          }}
                          onChange={(value) => {
                            if (!_.isNil(value)) {
                              this.props.setCustomStartTimeDisplay(value);
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Typography variant={"subtitle1"}>DURATION:</Typography>
                  <Box sx={styles.timeFilterItem}>
                    <AutoCompleteMDT
                      sx={styles.timeFrameSelection}
                      options={this.props.customDurations}
                      value={this.props.selectedCustomDuration}
                      onChange={(event, value, reason) => {
                        this.props.setCustomDuration(value);
                      }}
                      noOptionsText={"No durations found..."}
                    />
                  </Box>
                </Box>
                )
              }
            </Box>
          )}
          <Box sx={styles.tableFilterContainer}>
            <Box sx={styles.tableFilters}>
              <Typography variant="subtitle1">FILTER:</Typography>
              {this.props.appliedFilters.map((filter, index) => {
                return (
                  <Box sx={styles.tableFilterComponent} key={index}>
                    <Chip
                      label={_.truncate(filter.chipLabel, { length: 40 })}
                      onDelete={() => this.props.deleteFilter(index)}
                    />
                  </Box>
                );
              })}
              <Box sx={styles.tableFilterComponent}>
                <IconButton
                  onClick={() => this.props.openFilterDialog()}
                  disabled={emptyFilters}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={styles.tableOptions}>
              <Switch
                color="primary"
                checked={!this.props.excludeMessageAlarms}
                onChange={(event) => {
                  this.props.showMessageAlarms(!event.target.checked);
                }}
              />
              <Typography variant={"subtitle1"}>Show Message Alarms</Typography>
            </Box>
          </Box>
          <DataTable
            stateDef={this.props.stateDef}
            columns={columns}
            items={this.props.alarms}
            renderTableCell={this.renderTableCell}
          />
        </Box>
        <FilterDialog stateDef={this.props.stateDef} />
        <Progress open={this.props.queryRunning} />
        <ExportCsv
          triggerExportCsv={this.props.triggerExportCsv}
          exportCsvUrl={this.props.exportCsvUrl}
        />
        <SubscriptionDialog entity={subscriptionEntity.ALARM}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.ALARMS_DISPLAY : props.stateKey,
      type: ComponentTypes.ALARMS_DISPLAY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = alarmsState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    showingActiveAlarms: componentState.showingActiveAlarms,
    alarms: componentState.filteredAlarms,
    filters: componentState.filters,
    appliedFilters: componentState.appliedFilters,
    sortContext: componentState.sortContext,
    sortDirection: componentState.sortDirection,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    exportCsvUrl: componentState.exportCsvUrl,
    triggerExportCsv: componentState.triggerExportCsv,
    excludeMessageAlarms: componentState.excludeMessageAlarms,
    user: appState.user,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    showActiveAlarms: () => {dispatch(alarmsActions.showActiveAlarms(props.stateDef))},
    showAlarmHistory: () => {dispatch(alarmsActions.showAlarmHistory(props.stateDef))},
    showMessageAlarms: (show) => {dispatch(alarmsActions.showMessageAlarms(props.stateDef, show))},
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    queryAlarms: (activeOnly, startTime, endTime) => {dispatch(alarmsActions.queryAlarms(props.stateDef, activeOnly, startTime, endTime))},
    setTimeFrame: (timeFrame) => {dispatch(alarmsActions.setTimeFrame(props.stateDef, timeFrame))},
    setCustomStartTime: (startTime) => {dispatch(alarmsActions.setCustomStartTime(props.stateDef, startTime))},
    setCustomDuration: (duration) => {dispatch(alarmsActions.setCustomDuration(props.stateDef, duration))},
    exportCsv: (alarms, sortContext, sortDirection) => { dispatch(alarmsActions.exportCsv(props.stateDef, alarms, sortContext, sortDirection))},
    onShowSubscriptionDialog: (show, context) => { dispatch(notificationsActions.showSubscriptionDialog(show, context)); },
    setCustomStartTimeDisplay: (startTime) => {dispatch(alarmsActions.setCustomStartTimeDisplay(props.stateDef, startTime))},
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(AlarmsDisplay))