import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Dialog, Button,DialogContent, DialogActions,DialogTitle,Typography,DialogContentText} from '@mui/material';

import * as sensorSelectorActions from '../../../../../state/common/sensorSelector/sensorSelectorActions';
import {sensorSelectorState} from '../../../../../state/common/sensorSelector/sensorSelectorSelectors';

class SensorGroupDeleteDialog extends Component {

  render() {
    return (
      <Dialog open={this.props.openDeleteGroupUI} disableEscapeKeyDown>
        <DialogTitle>Delete Shared Sensor Group</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete shared sensor group with the following name?</DialogContentText>
          <Typography>{this.props.groupToDelete.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.closeDeleteConfirmation()}} color='inherit'>Cancel</Button>
          <Button onClick={() => { this.props.deleteGroup(this.props.groupToDelete.id, this.props.unitType, this.props.namespace)}} color='primary'>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SensorGroupDeleteDialog.propTypes = {
  unitType: PropTypes.string,
  namespace: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = sensorSelectorState(state[stateDef.key]);
  return {
    openDeleteGroupUI: componentState.openDeleteGroupUI,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeDeleteConfirmation: () => dispatch(sensorSelectorActions.closeDeleteConfirmation(props.stateDef)),
    deleteGroup: (groupId, unitType, namespace) => dispatch(sensorSelectorActions.deleteGroup(props.stateDef, groupId, unitType, namespace)),
  }
};

export default (connect(mapStateToProps,mapDispatchToProps)(SensorGroupDeleteDialog));
