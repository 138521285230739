import _ from 'lodash';

const unitActivityDfpState = state => {

  const noValue = '-';

  return {

    queryRunning : _.isNil(state) ? false : state.queryRunning,

    rawValuePumping: _.isNil(state) ? null : state.rawValuePumping,
    rawValueIdle: _.isNil(state) ? null : state.rawValueIdle,
    rawValueStopped: _.isNil(state) ? null : state.rawValueStopped,

    displayDays: _.isNil(state) ? null : state.displayDays,

    displayValuePumping: _.isNil(state) ? noValue : state.displayValuePumping,
    displayValueIdle: _.isNil(state) ? noValue : state.displayValueIdle,
    displayValueStopped: _.isNil(state) ? noValue : state.displayValueStopped,
  };

};

export {
  unitActivityDfpState
};