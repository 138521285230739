import _ from 'lodash';

const fetchComponentsDisplay = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let units = [];
      testData.units.forEach(unit => {
        units.push({id:unit.id, name:unit.name, type:unit.type, fleetName:unit.fleetName, region:unit.region});
      });
      resolve({
        componentsDisplay: {
          units: units
        }
      })
    }, 1000)
  });
};

const fetchComponentsDisplayComponentDetails = (unitId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let unit = _.find(testData.units, {'id': unitId});
      resolve({
        componentsDisplayComponentDetails: {
          earliestData: unit.earliestData,
          componentTypes: unit.componentTypes,
          components: unit.components
        }
      })
    }, 1000)
  });
};

const fetchSaveComponent = (componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      let unit = _.find(testData.units, {'id': unitId});
      let component = _.find(unit.components, {'componentType': componentType});

      if (!_.isNil(component)) {
        component.model = model;
        component.serialNumber = serialNumber;
        component.referenceNumber = referenceNumber;
        component.effectiveDate = effectiveDate;
        component.unitOffset = unitOffset;
        component.snapshotStart = snapshotStart;
      } else {
        component = {
          id:getComponentId(),
          truckId: unitId,
          componentType: componentType,
          model: model,
          serialNumber: serialNumber,
          referenceNumber: referenceNumber,
          effectiveDate: effectiveDate,
          unitOffset: unitOffset,
          snapshotStart: snapshotStart,
        };
        unit.components.push(component)
      }

      resolve({
        saveComponent: {
          ...component
        }
      });

    }, 1000)
  });
};

const fetchDeleteComponent = (componentId) => {
  return new Promise((resolve) => {
    setTimeout(() => {

      for(let i=0; i<testData.units.length; i++) {
        let index = _.findIndex(testData.units[i].components, {'id': componentId});
        if (index >= 0) {
          _.remove(testData.units[i].components, (component) => {
            return component.id === componentId;
          });
          break;
        }
      }

      resolve({
        deleteComponent: {
          result: true
        }
      })

    }, 1000)
  });
};

const fetchEstimatePumpHours = (unitId, engineHours, engineComponentTypeId, pumpComponentTypeId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        estimatedPumpHours: {
          estimatedPumpHours: engineHours
        }
      })
    }, 1000)
  });
};

let componentId = 0;

const getComponentId = () => {
  return componentId++;
};

const testData = {

  units: [
    {
      id:0,
      name: '620026',
      type: 'FracPumper',
      fleetName: 'WTX-8',
      region: 'West',
      earliestData: [
        { id: 0, earliestData: 1538322135}, // 2018-09-30T15:42:15Z - 1538322135
        { id: 1, earliestData: 1538322135}, // 2018-09-30T15:42:15Z - 1538322135
        { id: 2, earliestData: 1538322135}, // 2018-09-30T15:42:15Z - 1538322135
        { id: 3, earliestData: 1538322135}, // 2018-09-30T15:42:15Z - 1538322135
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: [
        { id:getComponentId(), componentType: 'Engines', model: 'XX-XXX-XXXX', serialNumber: 'SN620026Engine', referenceNumber: 'FRAC#######', effectiveDate: 1528889532, snapshotStart: 1234, unitOffset: 1000 }, // 2018-06-13T11:32:12Z - 1528889532
        { id:getComponentId(), componentType: 'Transmissions', model: 'XX-XXX-XXXX', serialNumber: 'SN620026Transmission', referenceNumber: 'FRAC#######', effectiveDate: 1535664255, snapshotStart: 1234, unitOffset: 2000 }, // 2018-08-30T21:24:15Z - 1535664255
        { id:getComponentId(), componentType: 'Fluid Ends', model: 'XX-XXX-XXXX', serialNumber: 'SN620026FluidEnd', referenceNumber: 'FRAC#######', effectiveDate: 1538388000, snapshotStart: null, unitOffset: 3000 }, // 2018-10-01T10:00:00Z - 1538388000
      ]
    },
    {
      id:1,
      name: '620027',
      type: 'FracPumper',
      fleetName: 'WTX-8',
      region: 'West',
      earliestData: [
        { id: 0, earliestData: 1538357520}, // 2018-10-01T01:32:00Z - 1538357520
        { id: 1, earliestData: 1538357520}, // 2018-10-01T01:32:00Z - 1538357520
        { id: 2, earliestData: 1538357520}, // 2018-10-01T01:32:00Z - 1538357520
        { id: 3, earliestData: 1538357520}, // 2018-10-01T01:32:00Z - 1538357520
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: [
        { id:getComponentId(), componentType: 'Engines', model: 'XX-XXX-XXXX', serialNumber: 'SN620027Engine', referenceNumber: 'FRAC#######', effectiveDate: 1539189852, snapshotStart: 1234, unitOffset: 1000 }, // 2018-10-10T16:44:12Z - 1539189852
        { id:getComponentId(), componentType: 'Transmissions', model: 'XX-XXX-XXXX', serialNumber: 'SN620027Transmission', referenceNumber: 'FRAC#######', effectiveDate: 1545893661, snapshotStart: 1234, unitOffset: 2000 }, // 2018-12-27T06:54:21Z - 1545893661
        { id:getComponentId(), componentType: 'Fluid Ends', model: 'XX-XXX-XXXX', serialNumber: 'SN620027FluidEnd', referenceNumber: 'FRAC#######', effectiveDate: 1553356293, snapshotStart: null, unitOffset: 3000 }, // 2019-03-23T15:51:33Z - 1553356293
      ]
    },
    {
      id:2,
      name: '620028',
      type: 'FracPumper',
      fleetName: 'STX-1',
      region: 'South',
      earliestData: [
        { id: 0, earliestData: 1542021836}, // 2018-11-12T11:23:56Z - 1542021836
        { id: 1, earliestData: 1542021836}, // 2018-11-12T11:23:56Z - 1542021836
        { id: 2, earliestData: 1542021836}, // 2018-11-12T11:23:56Z - 1542021836
        { id: 3, earliestData: 1542021836}, // 2018-11-12T11:23:56Z - 1542021836
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: [
        { id:getComponentId(), componentType: 'Engines', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1555032724, snapshotStart: 1234, unitOffset: 1000 }, // 2019-04-12T01:32:04Z - 1555032724
        { id:getComponentId(), componentType: 'Transmissions', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1550821449, snapshotStart: 1234, unitOffset: 2000 }, // 2019-02-22T07:44:09Z - 1550821449
        { id:getComponentId(), componentType: 'Fluid Ends', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1541269624, snapshotStart: null, unitOffset: 3000 }, // 2018-11-03T18:27:04Z - 1541269624
      ]
    },
    {
      id:3,
      name: '620029',
      type: 'FracPumper',
      fleetName: 'STX-1',
      region: 'South',
      earliestData: [
        { id: 0, earliestData: 1544251507}, // 2018-12-08T06:45:07Z - 1544251507
        { id: 1, earliestData: 1544251507}, // 2018-12-08T06:45:07Z - 1544251507
        { id: 2, earliestData: 1544251507}, // 2018-12-08T06:45:07Z - 1544251507
        { id: 3, earliestData: 1544251507}, // 2018-12-08T06:45:07Z - 1544251507
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: [
        { id:getComponentId(), componentType: 'Engines', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1546920840, snapshotStart: 1234, unitOffset: 1000 }, // 2019-01-08T04:14:00Z - 1546920840
        { id:getComponentId(), componentType: 'Transmissions', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1547190427, snapshotStart: 1234, unitOffset: 2000 }, // 2019-01-11T07:07:07Z - 1547190427
        { id:getComponentId(), componentType: 'Fluid Ends', model: 'XX-XXX-XXXX', serialNumber: 'SN############', referenceNumber: 'FRAC#######', effectiveDate: 1554145461, snapshotStart: null, unitOffset: 3000 }, // 2019-04-01T19:04:21Z - 1554145461
      ]
    },
    {
      id:4,
      name: '620030',
      type: 'FracPumper',
      fleetName: 'ETX-3',
      region: 'East',
      earliestData: [
        { id: 0, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 1, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 2, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 3, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: []
    },
    {
      id:5,
      name: '620031',
      type: 'Blender',
      fleetName: 'ETX-3',
      region: 'East',
      earliestData: [
        { id: 0, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 1, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 2, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
        { id: 3, earliestData: 1547512637}, // 2019-01-15T00:37:17Z - 1547512637
      ],
      componentTypes: [
        { id:0, name: 'Engines', displayName: 'Engine', kind: 'Engine' },
        { id:1, name: 'Transmissions', displayName: 'Transmission', kind: 'Transmission' },
        { id:2, name: 'Fluid Ends', displayName: 'Fluid End', kind: 'FluidEnd' },
        { id:3, name: 'Power Ends', displayName: 'Power End', kind: 'PowerEnd' },
      ],
      components: []
    },
  ]
};

export {
  fetchComponentsDisplay,
  fetchComponentsDisplayComponentDetails,
  fetchSaveComponent,
  fetchDeleteComponent,
  fetchEstimatePumpHours,
}