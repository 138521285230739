import _ from 'lodash';
import {fleetTypes} from "../../../common/services/fleetTypeService";
import {fleetDivisions} from "../../../common/services/fleetDivisionService";
import { datasourceSourceTypes } from '../fleetMgmtSelectors';

const validateFleetChanges = (fleet, datasources) => {
    const fleetSaveErrors = {};
    if (_.isEmpty(fleet.fleetName)) {
        fleetSaveErrors.hasFleetNameError = true;
    }
    if (_.isEmpty(fleet.datavan)) {
        fleetSaveErrors.hasDatavanError = true;
    }

    if (_.isEmpty(fleet.type) || _.isNil(Object.values(fleetTypes).find( type => type === fleet.type))) {
        fleetSaveErrors.hasTypeError = true;
    }
    if (_.isEmpty(fleet.division) || _.isNil(Object.values(fleetDivisions).find(division => division === fleet.division))) {
        fleetSaveErrors.hasDivisionError = true;
    }
    if (_.isEmpty(datasources)) {
      fleetSaveErrors.hasDatasourceError = true;
    }
    if (!_.isEmpty(datasources)) {
      _.forEach(datasources, (datasource) => {
        if (datasource.hasDuplicateNameError === true) {
          fleetSaveErrors.hasDatasourceError = true;
        }
        if ((!_.isEmpty(datasource.databaseHostIP) && !isValidURL(datasource.databaseHostIP)) ||
            (!_.isEmpty(datasource.databaseHostPort) && !isValidPort(datasource.databaseHostPort)) ||
            (!_.isEmpty(datasource.registrationServerHostIP) && !isValidURL(datasource.registrationServerHostIP)) ||
            (!_.isEmpty(datasource.registrationServerHostPort) && !isValidPort(datasource.registrationServerHostPort))
            ) {
          fleetSaveErrors.hasDatasourceError = true;
        }
        if (_.some(removeUiPropertiesForDatasource(datasource), (value) => { return _.isString(value) && _.isEmpty(value); })) {
          fleetSaveErrors.hasDatasourceError = true;
        }
      });
    }
    return fleetSaveErrors;
};

const validateMoveFleetChanges = (fleet) => {
    const fleetMoveErrors = {};
    if (_.isEmpty(fleet.destinationFleetName)) {
        fleetMoveErrors.hasDestinationFleetNameError = true;
    }
    if (_.isEmpty(fleet.destinationDatavan)) {
        fleetMoveErrors.hasDestinationDatavanError = true;
    }
    if (_.isEmpty(fleet.destinationOwnerId)) {
        fleetMoveErrors.hasDestinationOwnerIdError = true;
    }
    if (_.isEmpty(fleet.type) || _.isNil(Object.values(fleetTypes).find( type => type === fleet.type))) {
        fleetMoveErrors.hasDestinationTypeError = true;
    }
    return fleetMoveErrors;
};

const datasourcesHavePropertyChanges = (datasources, originalDatasources) => {
  // Only check the relevant properties for changes
  const processedDatasources = datasources.map(datasource => removeUiPropertiesForDatasource(datasource));
  const processedOriginalDatasources = originalDatasources.map(datasource => removeUiPropertiesForDatasource(datasource));
  return !_(processedOriginalDatasources).xorWith(processedDatasources, _.isEqual).isEmpty();
}

const datasourcesHaveChanges = (datasources, originalDatasources) => {
  return datasourcesHavePropertyChanges(datasources, originalDatasources) || anyDatasourcesToDelete(datasources);
}

const anyDatasourcesToDelete = (datasources) => {
  return _.some(datasources, (datasource) => { return datasource.willDelete === true; });
}

const removeUiPropertiesForDatasource = (datasource) => {
  return _.pick(datasource, [
    'id',
    'name',
    'databaseName',
    'databaseHostIP',
    'databaseHostPort',
    'datasourceGuid',
    'registrationServerHostIP',
    'registrationServerHostPort',
    'sourceType'
  ]);
}

const processDatasource = (datasource) => {
  const processedDatasource = {
    id: datasource.id,
    name: datasource.name,
    databaseName: datasource.databaseName,
    datasourceGuid: datasource.datasourceIdentifier,
    sourceType: datasourceSourceTypes().find( source => source.value === datasource.sourceType),
    delete: false,
    deleteValidation: '',
    willDelete: false,
    expanded: false,
    hasChanges: false,
    hasDuplicateNameError: false,
  }

  const databaseHost = new URL(datasource.databaseHost);
  processedDatasource.databaseHostIP = databaseHost.protocol + '//' + databaseHost.hostname;
  processedDatasource.databaseHostPort = databaseHost.port;

  const ipAddress = new URL(datasource.ipAddress);
  processedDatasource.registrationServerHostIP = ipAddress.protocol + '//' + ipAddress.hostname;
  processedDatasource.registrationServerHostPort = ipAddress.port;

  return processedDatasource;
}

const isValidURL = (url) => {
  const urlRegex = /https?:\/\/(localhost|(?:\d{1,3}\.){3}\d{1,3})$/;
  return urlRegex.test(url);
}

const isValidPort = (port) => {
  const urlRegex = /\b\d{1,6}\b/;
  return urlRegex.test(port);
}

export {
    validateFleetChanges,
    validateMoveFleetChanges,
    datasourcesHaveChanges,
    removeUiPropertiesForDatasource,
    processDatasource,
    anyDatasourcesToDelete,
    isValidURL,
    isValidPort
}