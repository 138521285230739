export default {

  LOAD_CONTEXT_DATA_STARTING: 'LOAD_CONTEXT_DATA_STARTING',
  LOAD_CONTEXT_DATA_SUCCESS: 'LOAD_CONTEXT_DATA_SUCCESS',
  LOAD_CONTEXT_DATA_ERROR: 'LOAD_CONTEXT_DATA_ERROR',

  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

  SELECT_PROVIDER_TYPE: 'SELECT_PROVIDER_TYPE',
  SET_PROVIDER_NAME: 'SET_PROVIDER_NAME',

  ADD_TEAMS_CHANNEL: 'ADD_TEAMS_CHANNEL',
  REMOVE_TEAMS_CHANNEL: 'REMOVE_TEAMS_CHANNEL',
  SET_TEAMS_CHANNEL_NAME: 'SET_TEAMS_CHANNEL_NAME',
  SET_TEAMS_CHANNEL_WEBHOOK: 'SET_TEAMS_CHANNEL_WEBHOOK',

  SET_TWILIO_ACCT_SID: 'SET_TWILIO_ACCT_SID',
  SET_TWILIO_AUTH_TOKEN: 'SET_TWILIO_AUTH_TOKEN',
  SET_TWILIO_FROM_NUMBER: 'SET_TWILIO_FROM_NUMBER',

  CLEAR_FORM: 'CLEAR_FORM',

}