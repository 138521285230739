import _ from 'lodash';
import * as filterService from '../../../common/filtering/filterService';
import * as assetTypeService from '../../../common/services/assetTypeService';

const processUnits = (units, filters, showConsumedHours) => {

  // Filter the units down based on the used selected fitlers.
  let filteredUnits = !_.isEmpty(units) ? filterService.filterItems(units, filters) : [];

  // Reconcile the union of components to display from the list of filtered units
  let filteredComponents = resolveComponents(filteredUnits, showConsumedHours);

  // Update our filter list based on the current list of filtered units.
  let updatedFilters = filterService.generateFilters(filteredUnits, filterDefinitions());

  return {
    filteredUnits: filteredUnits,
    filteredComponents: filteredComponents,
    filters: updatedFilters
  };
};

const transformUnits = (units) => {

  // Take the raw units returned from the service and augment them with additional properties
  // that will be leverage by the UI logic.

  return _.map(units, unit => {

    // Clone the raw unit...
    let transformedUnit = _.cloneDeep(unit);

    // Attach any UI properties...
    transformedUnit.displayValueType = assetTypeService.getAssetNameByType(transformedUnit.unitType);

    // Promote the components associated with this unit to properties on the unit itself so
    // we can bind/sort them in the UI. We add a property for the remaining hours and a
    // property for the consumed hours.
    _.forEach(transformedUnit.components, component => {
      transformedUnit[resolveConsumedHoursPropertyName(component.componentType)] = !_.isNil(component.hours) ? Math.round(component.hours) : null;
      transformedUnit[resolveRemainingHoursPropertyName(component.componentType)] = !_.isNil(component.remaining) ? Math.round(component.remaining) : null;
    });

    return transformedUnit;
  });

};

const resolveComponents = (units, showConsumedHours) => {

  let components = [];

  // Generate the list of components across the units

  _.forEach(units, unit => {
    if (!_.isEmpty(unit.components)) {
      components = _.unionBy(components, unit.components, 'componentType');
    }
  });

  // Sort and transform the list. We include the names of the properties so they can
  // be more easily resolved later when switching back and forth between consumed
  // and remaining hours.

  return _.sortBy(components, ['componentType']).map(component => {
    return {
      label: component.componentType,
      property: showConsumedHours === true ? resolveConsumedHoursPropertyName(component.componentType) : resolveRemainingHoursPropertyName(component.componentType),
    }
  });
};

const resolveSortContext = (currentSortContext, oldFilteredComponents, newFilteredComponents) => {

  // If the current sort context is not for one of the static columns make
  // sure it is for one of the component columns. If not we need to default
  // to the name column.

  let defaultSortContext = 'name';
  let unitTypeSortContext = 'displayValueType';
  let fleetSortContext = 'fleetName';

  if (_.isNil(currentSortContext)) {
    return defaultSortContext;
  }

  if (currentSortContext === defaultSortContext || currentSortContext === unitTypeSortContext || currentSortContext === fleetSortContext) {
    return currentSortContext;
  }

  // If we are here then the current sort context is associated with a component column. This is where it gets tricky
  // as a component column will bind to a different property (sort context) depending on if the user is viewing
  // remaining hours or consumed hours. To resolve this correctly we will use the column name (component label)
  // which is the same for both remaining and consumed hours.

  // First try and find which column the current sort context belongs to using the old filtered components list.
  let currentComponent = _.find(oldFilteredComponents,['property', currentSortContext]);

  if (_.isNil(currentComponent)) {
    // This should never happen as it would mean we are currently sorting the table on a component column
    // that we do not know about. Handle it anyways as things will go poorly if this happens.
    return defaultSortContext;
  }

  // Now try and find the same component in the new filtered component list.
  let newComponent = _.find(newFilteredComponents,['label', currentComponent.label]);

  // If we found the same component in the new list use it, other wise return the default as that component
  // column does not exist anymore.
  currentSortContext = _.isNil(newComponent) ? defaultSortContext : newComponent.property;

  return currentSortContext;
};

const resolveCsvColumns = (components) => {

  let columns = [];

  columns.push({name: 'Unit Id', property: 'name'});
  columns.push({name: 'Type', property: 'displayValueType'});
  _.forEach(components, component => {
    columns.push({name: component.label, property: component.property});
  });
  columns.push({name: 'Fleet', property: 'fleetName'});

  return columns;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createFilterDefinition('Type', 'displayValueType'));
  definitions.push(filterService.createFilterDefinition('Unit ID', 'name'));
  return definitions;
};

const resolveConsumedHoursPropertyName = (component) => {
  return component + ' Consumed';
};

const resolveRemainingHoursPropertyName = (component) => {
  return component + ' Remaining';
};

export {
  processUnits,
  transformUnits,
  resolveComponents,
  resolveSortContext,
  resolveCsvColumns
}