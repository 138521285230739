import React, { Component } from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import MDTDataGrid from '../../../common/table/MDTDataGrid';
import * as userConfigActions from '../../../../state/app/actions/appUserConfigActions';
import { fleetMapState } from '../../../../state/displays/fleetMap/display/fleetMapSelectors';
import ComponentTypes from "../../../componentTypes";
import OWNERS from "../../../common/owners";

import { Box } from '@mui/system';
import { Button, Stack, Typography } from "@mui/material";

import VibrationIcon from '@mui/icons-material/Vibration';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BuildIcon from '@mui/icons-material/Build';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GearsIcon from '../../../controls/icons/gears';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import ListIcon from '@mui/icons-material/List';

const styles = {
    cardsList: {
        minHeight: 'calc(100vh - 180px)'
    },
    cardsContent: {
        display: 'flex',
        height: '100%'
    },
    dataGrid: {
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
            border: 'none'
        },
        '& .MuiTextField-root': {
            width: '100%'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
        {
            outline: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected': {
            '&:hover': {
                backgroundColor: '#ce4300'
            },
            backgroundColor: 'grey.800'
        }
    },
    cardDescription: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'normal'
    },
    addButtonBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '65px',
        width: '100%'
    },
    addButton: {
        margin: '10px'
    }
}

const unitUserChartLimit = 8;

class ConfigPanelFleetDashboard extends Component {

    render() {

      return (
        <Stack spacing={1}>
            <Box sx={styles.cardsList}>
                <Box sx={styles.cardsContent} >
                    <MDTDataGrid
                        sx={styles.dataGrid}
                        autoHeight={false}
                        minHeight='40px'
                        getRowHeight={() => 'auto'}
                        // Used to track when a card has been selected
                        onSelectionModelChange={([id]) => {
                            if (_.isNil(id)) {
                                this.props.onSelectCard(null);
                            } else {
                                this.props.onSelectCard(this.props.cardsList.find(card => card.id === id));
                            }
                        }}
                        rowSelectionModel={this.props.selectedCard}
                        selectionModel={this.props.selectedCard ? [this.props.selectedCard.type] : []}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnResize
                        disableMultipleSelection
                        columns={[
                            {
                                field: 'icon',
                                editable: false,
                                hideable: false,
                                pinnable: false,
                                width: 25,
                                minWidth: 0,
                                height: 25,
                                renderCell: (params) => {
                                  return (
                                    <div>
                                      {params.row.icon}
                                    </div>
                                    )
                                }
                            },
                            {
                                field: 'text',
                                editable: false,
                                hideable: false,
                                pinnable: false,
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <div style={{ margin: '10px', cursor:'default' }}>
                                            <Typography>{params.row.text.title}</Typography>
                                            <Typography sx={styles.cardDescription} fontSize={12}>{params.row.text.description}</Typography>
                                        </div>
                                    )
                                },
                                // Since search fields are nested, take text property and turn into a delimited string
                                // This lets us apply the quick filter against the data in here
                                valueGetter: (params) => {
                                    if (_.isNil(params.row)) return '';
                                    return params.row.text.title + '/' + params.row.text.description;
                                }
                            }]}
                        rows={this.props.cardsList}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true
                                },
                                csvOptions: { disableToolbarButton: true }
                            }
                        }}
                        stateDef={this.props.stateDef}
                        pagination={false}
                        getRowId={(row) => row.id}
                        hideFooter
                        headerHeight={0}
                    />
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
              <Box sx={styles.addButtonBox}>
                <Button sx={styles.addButton} variant="contained" disabled={(_.isNil(this.props.selectedCard))} onClick={() => { this.props.onAddCardToDashboard(this.props.selectedView, this.props.selectedCard); }}>
                    Add
                </Button>
              </Box>
            </Box>
        </Stack>
      )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_DASHBOARD : props.stateKey,
            type: ComponentTypes.FLEET_DASHBOARD,
        }
    }
};

const getDisplay = (type, owner) => {
    switch (type) {
        default:
            return true
    }
}

const getIcon = (type) => {
    switch (type) {
        case ComponentTypes.FLEET_PRC_HISTORY:
            return <StackedLineChartIcon />;
        case ComponentTypes.FLEET_ACTIVITY:
            return <LocalShippingIcon />;
        case ComponentTypes.FLEET_ALARM_COUNT:
            return <AccessAlarmIcon />;
        case ComponentTypes.FLEET_VIBRATION:
            return <VibrationIcon />;
        case ComponentTypes.FLEET_COMPONENTS:
            return <BuildIcon />;
        case ComponentTypes.FLEET_OPERATION:
            return <GearsIcon />;
        case ComponentTypes.FLEET_CLIENT_STATUS:
            return <SettingsRemoteIcon />;
        case ComponentTypes.UNIT_USER_CHART:
            return <StackedLineChartIcon />;
        case ComponentTypes.FLEET_AUDIT_LOG:
            return <ListIcon />;
        default:
            return <OilBarrelIcon />;
    }
}

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let appState = applicationState(state);
    let componentState = fleetMapState(state[stateDef.key]);

    const cardsForDashboard = _.filter(_.map(componentState.cardsList, function (card) {
        return ({
            ...card,
            show: getDisplay(card.type, appState.selectedOwner),
            icon: getIcon(card.type),

        })
    }), ['show', true]);

    return {
        selectedView: componentState.selectedView,
        selectedCard: appState.user.dashboards[ComponentTypes.FLEET_DASHBOARD]?.selectedCard,
        dashboards: appState.user.dashboards,
        cardsList: cardsForDashboard,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddCardToDashboard: (view, card) => { dispatch(userConfigActions.addCardToDashboardLayout(ComponentTypes.FLEET_DASHBOARD, view, card)); },
        onSelectCard: (card) => { dispatch(userConfigActions.onSelectedCard(ComponentTypes.FLEET_DASHBOARD, card)); },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(ConfigPanelFleetDashboard))