import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';

import unitChartActionTypes from './unitChartActionTypes';
import * as unitChartQueries from './unitChartQueries';
import unitChartTypes from './unitChartTypes';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(unitChartActionTypes.UNIT_CHART_CLEAR_DATA, 'stateDef');

const queryData = (stateDef, chartType, truckId, startTime, endTime) => {
  return async (dispatch, getState) => {
    switch (chartType) {
      case unitChartTypes.ENGINE:
        await dispatch(queryUnitEngineCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.TRANSMISSION:
        await dispatch(queryUnitTransmissionCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.POWER_END:
        await dispatch(queryUnitPowerEndCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.LOCKUP:
        await dispatch(queryUnitLockupCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.COIL_DETAIL:
        await dispatch(queryUnitCoilDetailCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.COIL:
        await dispatch(queryUnitCoilCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.PRESSURE_HISTORY:
        await dispatch(queryUnitPressureHistoryCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.OUTPUT_HISTORY:
        await dispatch(queryUnitOutputHistoryCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.ROADSIDE_PRESSURE_HISTORY:
        await dispatch(queryUnitPressureHistoryRoadsideCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.CURBSIDE_PRESSURE_HISTORY:
        await dispatch(queryUnitPressureHistoryCurbsideCardData(stateDef, truckId, startTime, endTime));
        break;
      case unitChartTypes.VIBRATION_HISTORY:
        await dispatch(queryUnitVibrationHistoryCardData(stateDef, truckId, startTime, endTime));
        break;
      default:
        throw Error('Unknown chart type');
    }
  }
};

const queryUnitEngineCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_ENGINE_CARD_DATA,
  unitChartQueries.fetchUnitEngineCardData
);

const queryUnitTransmissionCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_TRANSMISSION_CARD_DATA,
  unitChartQueries.fetchUnitTransmissionCardData
);

const queryUnitPowerEndCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_POWER_END_CARD_DATA,
  unitChartQueries.fetchUnitPowerEndCardData
);

const queryUnitLockupCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_LOCKUP_CARD_DATA,
  unitChartQueries.fetchUnitLockUpCardData
);

const queryUnitCoilDetailCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_COIL_DETAIL_CARD_DATA,
  unitChartQueries.fetchUnitCoilDetailCardData
);

const queryUnitCoilCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_COIL_CARD_DATA,
  unitChartQueries.fetchUnitCoilCardData
);

const queryUnitPressureHistoryCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_HISTORY_CARD_DATA,
  unitChartQueries.fetchUnitPressureHistory
);

const queryUnitOutputHistoryCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_OUTPUT_HISTORY_CARD_DATA,
  unitChartQueries.fetchUnitOutputHistory
);

const queryUnitPressureHistoryRoadsideCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_PRESSURE_HISTORY_ROADSIDE_CARD_DATA,
  unitChartQueries.fetchUnitRoadsidePressureHistory
);

const queryUnitPressureHistoryCurbsideCardData = queryActionFactory(
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
  unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_PRESSURE_HISTORY_CURBSIDE_CARD_DATA,
  unitChartQueries.fetchUnitCurbsidePressureHistory
);

const queryUnitVibrationHistoryCardData = queryActionFactory(
    unitChartActionTypes.UNIT_CHART_QUERY_DATA_STARTING,
    unitChartActionTypes.UNIT_CHART_QUERY_DATA_SUCCESS,
    unitChartActionTypes.UNIT_CHART_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_VIBRATION_HISTORY_CARD_DATA,
    unitChartQueries.fetchUnitVibrationHistory
);

export {
  queryData,
  clearData
}
