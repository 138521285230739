import _ from "lodash";
import React, {Component} from 'react';
import {compose} from "recompose";
import { connect } from 'react-redux';
import { withProps } from "recompose";
import providerTypes from '../../../common/providerTypes';

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepButton,
  Paper,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { AutoCompleteMDT } from '../../../controls/mdtMuiControls';
import Progress from '../../../controls/progress';
import ComponentTypes from '../../../componentTypes';
import { providerDialogState } from '../../../../state/displays/settings/dialog/providerDialog/providerDialogSelectors';
import * as providerDialogActions from '../../../../state/displays/settings/dialog/providerDialog/providerDialogActions';
import * as providerActions from "../../../../state/displays/settings/actions/providerActions";
import getDialogStyles from "../../../common/styles/dialogStyles";
import {providerState} from "../../../../state/displays/settings/selectors/providerSelectors";
import PropTypes from "prop-types";

const dialogStyles = getDialogStyles();

const styles = {
  providerNameContents: {
    height: '80px',
  },
  selectProviderTypeContents: {
    height: '80px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start'
  },
  selectProviderTypeSelectorGroup: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    width: "50%",
    marginRight: 2,
  },
  providerProviderTypeLabel: {
    marginTop: '4px'
  },
  teamsChannelsContents: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: '120px',
  },
};

const steps = ['Select Provider Type', 'Name Your Provider', 'Enter Provider Configuration'];

class ProviderDialog extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {

    // If we are going from hide -> show...
    if (prevProps.showDialog === false && this.props.showDialog === true) {

      // Reset the stepper to 0 and query data for the dialog if the context has changed
      this.props.onSetActiveStep(0);
      this.props.onLoadContextData(this.props.context);

    }

  }

  renderNameProvider() 
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.providerNameContents}>
          <TextField 
            sx={{marginTop: 2}}
            autoFocus  
            variant='standard' 
            size="small" 
            fullWidth 
            inputProps={{maxLength:255}} 
            value={this.props.providerName}
            onChange={event => this.props.onSetProviderName(event.target.value)}
            helperText='Provider Name'
          />
        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep + 1); }}>Next</Button>
        </Box>
      </Box>
    );
  }

  renderSelectProviderType()
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.selectProviderTypeContents}>

          <Box sx={styles.selectProviderTypeSelectorGroup}>
            <AutoCompleteMDT
                options={this.props.availableProviderTypes}
                getOptionLabel={(provider) => provider.label}
                value={this.props.providerType}
                onChange={(event, value, reason) => {
                  this.props.onSelectProviderType(value);
                }}
                disableClearable
                renderInput={(params) => <TextField {...params} variant="standard" />}
                noOptionsText={'No providers found...'}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <Typography variant={'caption'} sx={styles.providerProviderTypeLabel}>Provider Type</Typography>
          </Box>
        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} disabled>Prev</Button>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep + 1); }}>Next</Button>
        </Box>
      </Box>
    );
  }

  renderTeamsChannels()
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.teamsChannelsContents}>

          <Grid container direction={'column'} alignItems={'center'}>

            <Grid container direction={'row-reverse'} alignItems={'flex-end'} justifyContent={'flex-end'} sx={dialogStyles.tableHeader}>
              <Button onClick={() => {this.props.onAddTeamsChannel()}} color={'inherit'}>
                <AddIcon sx={dialogStyles.tableActionButton}/>
                ADD
              </Button>
            </Grid>

            {/* Header */}
            <Grid container direction={'row'} alignItems={'center'} sx={dialogStyles.headerRow}>
              <Grid item sx={{...dialogStyles.headerCell, width: '160px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Channel</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '260px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Webhook</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '57px'}}/>
            </Grid>

            {/* Contents */}
            <Paper sx={dialogStyles.table}>
              <Grid container>
                {
                  this.props.configs.teamsChannels.map((channel) => {
                    return (
                      <Grid container key={channel.id} alignItems={'center'} sx={dialogStyles.tableRow}>
                        <Grid item sx={{...dialogStyles.tableCellLeftMost, width: '160px'}}>
                          {/* Channel name column */}
                          {
                            <Tooltip title={_.isEmpty(channel.name) ? '' : channel.name} followCursor={true}>
                              <TextField
                                  required
                                  variant='standard'
                                  size="small"
                                  fullWidth
                                  value={channel.name}
                                  onChange={event => this.props.onSetTeamsChannelName(event.target.value, channel.id)}
                                  inputProps={{maxLength:60}}
                                  InputProps={{disableUnderline: true }}
                                  type='text'
                              />
                            </Tooltip>
                          }
                        </Grid>
                        <Grid item sx={{...dialogStyles.tableCell, width: '260px', height: '49px'}}>
                          {/* Webhook column */}
                          {
                            <Tooltip title={_.isEmpty(channel.webhook) ? '' : channel.webhook} followCursor={true}>
                              <TextField
                                required
                                variant='standard'
                                size="small"
                                fullWidth
                                value={channel.webhook}
                                onChange={event => this.props.onSetTeamsChannelWebhook(event.target.value, channel.id)}
                                inputProps={{maxLength:500}}
                                InputProps={{disableUnderline: true }}
                                type='text'
                              />
                            </Tooltip>
                          }
                        </Grid>
                        <Grid item sx={dialogStyles.tableActionCell}>
                          {/* Action column */}
                          <IconButton
                            onClick={(event) => { this.props.onRemoveTeamsChannel(channel.id); }}
                            size='small'>
                            <Tooltip title='Remove Teams Channel' followCursor={true}>
                              <DeleteOutlineIcon sx={dialogStyles.tableRowActionButton}/>
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Paper>

          </Grid>

        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
          <Button variant={'contained'} color={'primary'} disabled>Next</Button>
        </Box>
      </Box>
    );  
  }

  renderTwilioSettings()
  {
    return (
        <Box sx={dialogStyles.stepContents}>
          <Box sx={styles.providerNameContents}>
            <TextField
                sx={{marginTop: 2}}
                autoFocus
                variant='standard'
                size="small"
                fullWidth
                inputProps={{maxLength:255}}
                value={this.props.configs.twilioAcctSid}
                onChange={event => this.props.onSetTwilioAcctSid(event.target.value)}
                helperText='Twilio Account Sid'
            />
          </Box>
          <Box sx={styles.providerNameContents}>
            <TextField
                sx={{marginTop: 2}}
                variant='standard'
                size="small"
                fullWidth
                inputProps={{maxLength:255}}
                value={this.props.configs.twilioAuthToken}
                onChange={event => this.props.onSetTwilioAuthToken(event.target.value)}
                helperText='Twilio Auth Token'
            />
          </Box>
          <Box sx={styles.providerNameContents}>
            <TextField
                sx={{marginTop: 2}}
                variant='standard'
                size="small"
                fullWidth
                inputProps={{maxLength:255}}
                value={this.props.configs.twilioFromNumber}
                onChange={event => this.props.onSetTwilioFromNumber(event.target.value)}
                helperText='Twilio From Number'
            />
          </Box>
          <Box sx={dialogStyles.stepActionContents}>
            <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
            <Button variant={'contained'} color={'primary'} disabled>Next</Button>
          </Box>
        </Box>
    );
  }

  renderStep(label, index) {
    switch (index) {
      case 0:
        return this.renderSelectProviderType();
      case 1:
        return this.renderNameProvider();
      case 2:
        if(!_.isNil(this.props.providerType) && this.props.providerType.value === providerTypes.TWILIO){
          return this.renderTwilioSettings();
        }else{
          //default provider type set as teams
          return this.renderTeamsChannels();
        }
      default:
        return {label};
    }
  }

  mapErrorToStep(index) {
    switch (index) {
      case 0:
        return this.props.errorProviderType;
      case 1:
        return this.props.errorProviderName;
      case 2:
        if(!_.isNil(this.props.providerType) && this.props.providerType.value === providerTypes.TWILIO){
          return this.props.errorTwilio;
        }else{
          //default provider type set as teams
          return this.props.errorTeamsChannels;
        }
      default:
        return false;
    }
  }

  render() {
    return (
      <Dialog open={this.props.showDialog} maxWidth={'lg'} maxLength={'lg'} disableEscapeKeyDown>
        <DialogTitle>
          <Box sx={dialogStyles.dialogTitleContainer}>
            {
              (!_.isNil(this.props.context)) &&
              <Typography variant={'subtitle1'}>Edit Provider</Typography>
            }
            {
              (_.isNil(this.props.context)) &&
              <Typography variant={'subtitle1'}>Create Provider</Typography>
            }
          </Box>
        </DialogTitle>
        <DialogContent sx={dialogStyles.dialogContent}>
          <Stepper nonLinear orientation={'vertical'} activeStep={this.props.activeStep}>
            {
              steps.map((label, index) =>
              {
                return (
                  <Step key={label}>
                    <StepButton color='inherit' onClick={() => { this.props.onSetActiveStep(index)}}>
                      <StepLabel error={this.mapErrorToStep(index)}>
                        {label}
                      </StepLabel>
                    </StepButton>
                    <StepContent>
                      { this.renderStep(label, index) }
                    </StepContent>
                  </Step>
                )
              })
            }
          </Stepper>
        </DialogContent>
        <DialogActions sx={dialogStyles.buttonsContainer}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => {
            this.props.onClearForm();
            this.props.onShowProviderDialog(false, this.props.context);
          }}>Cancel</Button>

          <Button variant={'contained'} color={'primary'}
                  onClick={
                    () => {
                      this.props.onSaveProviderSettings(
                          this.props.context,
                          this.props.providerName,
                          this.props.providerType.value,
                          this.props.configs
                      );
                      this.props.onClearForm();
                      this.props.onShowProviderDialog(false, this.props.context);
                    }
                  }
                  disabled={!this.props.canSave}>Save</Button>
        </DialogActions>

        <Progress open={this.props.queryRunning}/>
      </Dialog>

    );
  }
}

ProviderDialog.propTypes = {
  providerType: PropTypes.object.isRequired,
};


const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.PROVIDER_DIALOG : props.stateKey,
      type: ComponentTypes.PROVIDER_DIALOG,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;

  let componentState = providerDialogState(state[stateDef.key]);
  let settingsState = providerState(state.settings);

  return {
    context: settingsState.provider.providerDialogContext,
    showDialog: settingsState.provider.showProviderDialog,

    canSave: componentState.canSave,

    errorProviderName: componentState.errorProviderName,
    errorProviderType: componentState.errorProviderType,
    errorTeamsChannels: componentState.errorTeamsChannels,
    errorTwilio: componentState.errorTwilio,

    providerName: componentState.providerName,
    activeStep: componentState.activeStep,
    availableProviderTypes: componentState.availableProviderTypes,
    providerType: componentState.providerType,

    configs: {
      teamsChannels: componentState.configs.teamsChannels,

      twilioAcctSid: componentState.configs.twilioAcctSid,
      twilioAuthToken: componentState.configs.twilioAuthToken,
      twilioFromNumber: componentState.configs.twilioFromNumber,

    },

  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onLoadContextData: (context) => { dispatch(providerDialogActions.loadContextData(props.stateDef, context)); },
    onShowProviderDialog: (show, context) => { dispatch(providerActions.showProviderDialog(props.stateDef, show, context)); },
    onSetActiveStep: (step) => { dispatch(providerDialogActions.setActiveStep(props.stateDef, step)); },
    
    onSetProviderName: (name) => { dispatch(providerDialogActions.setProviderName(props.stateDef, name)); },
    onSelectProviderType: (providerType) => { dispatch(providerDialogActions.selectProviderType(props.stateDef, providerType)); },

    onAddTeamsChannel: () => { dispatch(providerDialogActions.addTeamsChannel(props.stateDef)); },
    onRemoveTeamsChannel: (id) => { dispatch(providerDialogActions.removeTeamsChannel(props.stateDef, id)); },
    onSetTeamsChannelName: (value, id) => { dispatch(providerDialogActions.setTeamsChannelName(props.stateDef, value, id)); },
    onSetTeamsChannelWebhook: (value, id) => { dispatch(providerDialogActions.setTeamsChannelWebhook(props.stateDef, value, id)); },

    onSetTwilioAcctSid: (value) => { dispatch(providerDialogActions.setTwilioAcctSid(props.stateDef, value)); },
    onSetTwilioAuthToken: (value) => { dispatch(providerDialogActions.setTwilioAuthToken(props.stateDef, value)); },
    onSetTwilioFromNumber: (value) => { dispatch(providerDialogActions.setTwilioFromNumber(props.stateDef, value)); },

    onSaveProviderSettings: (context, name, type, configs) => { dispatch(providerActions.saveProviderSettings(props.stateDef, context, name, type, configs)) },
    onClearForm: () => { dispatch(providerDialogActions.clearForm(props.stateDef)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ProviderDialog));