import {performDisplayFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as coilDashboardSimulation from './coilDashboardSimulation';

/**
 * Method to perform the remote query for the coil dashboard display data
 */
const fetchCoilDashboardDisplayData = () => {

    if (useSimulationData('coilDashboard')) {
        return coilDashboardSimulation.fetchCoilTubingUnits();
    }

    return performDisplayFetch({
        'query': '{ coilDashboard { id name fleetId fleetName region division } }'
    })
};

const fetchCoilDashboardDataAge = (truckId) => {

    if (useSimulationData('coilDashboard')) {
        return coilDashboardSimulation.fetchDataAge();
    }

    return performDisplayFetch({
        'query': 'query TruckDataAge($truckId:Long!){ truckDataAge(truckId: $truckId) { timestamp }}',
        'variables': {
            'truckId': truckId
        }
    })
};

export {
    fetchCoilDashboardDisplayData,
    fetchCoilDashboardDataAge
};
