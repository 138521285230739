import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel, mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";
import * as alarmCountActions from "../../../state/cards/alarmCount/alarmCountActions";
import {getCardFromLayoutConfigViews} from "../../common/layout/layoutHelper";
import AlarmCountConfigPanel from "../common/alarmCountCard/alarmCountConfigPanel";
import PropTypes from 'prop-types';

class FleetAlarmCountConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        defaultTitle: 'ACTIVE ALARMS',
        // {w:1, h:1} card is not exposed waiting for finalized design
        availableSizes:[{w:2, h:1}, {w:4, h:2},  {w:6, h:2},  {w:8, h:2}]
    };

    getRenderedContent() {
        return (
            <AlarmCountConfigPanel
                card={this.props.card}
                onReorderColumns={this.props.onReorderColumns}
                onToggleColumnVisibility={this.props.onToggleColumnVisibility}
                dashboards={this.props.dashboards}
            />
        );
    }

    hasConfigChanged(dashboard, view, cardKey) {
        let configChanged = super.hasConfigChanged(dashboard, view, cardKey);

        const currentDashboard = this.props.dashboards[dashboard];
        const card = getCardFromLayoutConfigViews(currentDashboard?.views, view, cardKey);
        const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

        if (_.isNil(originalCard)) return configChanged;

        const columnsChanged = !_.isEqual(card?.configuration?.columns, originalCard?.configuration?.columns);

        return configChanged || columnsChanged;
    };
}

FleetAlarmCountConfigPanel.proptypes = {
   ...mdtCardConfigPanelPropTypes,
   stateKey: PropTypes.string.isRequired,
   componentType: PropTypes.string.isRequired
}

const stateDefinition = (props) => {
   return {
       stateDef: {
           key: props.stateKey,
           type: props.componentType,
       }
   }
};

const mapStateToProps = (state, props) => {
    return {
        ...mdtCardConfigMapStateToProps(state, props)
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardConfigMapDispatchToProps(dispatch, props),
        onReorderColumns: ({ removedIndex, addedIndex }) => {
            dispatch(alarmCountActions.onReorderColumns(props.stateDef, props.card.dashboard, props.card.view,props.card.card.i, removedIndex, addedIndex));
        },
        onToggleColumnVisibility: (componentIndex) => {
            dispatch(alarmCountActions.onToggleColumnVisibility(props.stateDef, props.card.dashboard, props.card.view,props.card.card.i, componentIndex));
        },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FleetAlarmCountConfigPanel));