import _ from 'lodash';
import providerTypes from '../../../../../../components/common/providerTypes';

const evaluateCanSave = (state) => {

  let newState = _.cloneDeep(state);

  // If any of the required fields are empty, return false
  newState.errorProviderName = _.isEmpty(state.providerName);

  newState.errorProviderType = _.isNil(state.providerType);
  
  // Check the teams channels are all properly filled out
  if ( state.providerType.value === providerTypes.TEAMS &&
      (
          (_.isEmpty(state.configs.teamsChannels)) ||
          (!_.isEmpty(state.configs.teamsChannels) &&
          (!_.isEmpty(_.filter(state.configs.teamsChannels, (x) => { return _.isEmpty(x.name) || _.isEmpty(x.webhook); }))) )
      )
    ) {
    newState.errorTeamsChannels = true;
  } else {
    newState.errorTeamsChannels = false;
  }

  // Check the twilio fields are all properly filled out
  if (state.providerType.value === providerTypes.TWILIO &&
      (
         _.isEmpty(state.configs.twilioAcctSid) || _.isEmpty(state.configs.twilioAuthToken) || _.isEmpty(state.configs.twilioFromNumber)
         || !state.configs.twilioAcctSid.match(/^AC[0-9a-fA-F]{32}$/)  //Check if twilioSid is valid or not,source:https://raw.githubusercontent.com/twilio/twilio-oai/main/spec/json/twilio_api_v2010.json
      )
  ) {
    newState.errorTwilio = true;
  } else {
    newState.errorTwilio = false;
  }


  if (newState.errorProviderName === true ||
      newState.errorProviderType === true ||
      newState.errorTeamsChannels === true ||
      newState.errorTwilio === true
    ) {
    newState.canSave = false;
  } else {
    newState.canSave = true;
  }

  return newState;
};

const updateIdsAfterRemoval = (collection) => {

  // Update the ids so they match their indexes
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].id !== i) {
      collection[i].id = i;
    }
  }

};

export {
  evaluateCanSave,
  updateIdsAfterRemoval
}