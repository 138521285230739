import React, {Component} from 'react';
import { Typography, Box } from '@mui/material';
import fleetMapStyles from '../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
  emptyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

class EmptySand extends Component {
  render() {
    return (
      <Box sx={styles.sandMapContainer}>
        <Box sx={styles.emptyContainer}>
          <Typography variant={'subtitle1'} sx={styles.fleetMapText}>Empty</Typography>
        </Box>
      </Box>
    );
  }
}

export default EmptySand;