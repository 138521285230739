import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import queryActionCustomFactory from "../../../common/factories/queryActionCustomFactory";
import userMgmtActionTypes from '../userMgmtActionTypes';

import {fetchUpdateUser, fetchUsers} from '../userMgmtQueries';
import errorMessages from '../../../common/errorMessages';
import {handleError} from "../../../app/actions/appErrorActions";

const setCurrentPage = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_CURRENT_PAGE, 'stateDef', 'currentPage');
const setUsersPerPage = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_USERS_PER_PAGE, 'stateDef', 'usersPerPage');


const queryUsersStarting = defaultActionFactory(userMgmtActionTypes.USER_MGMT_QUERY_USERS_STARTING, 'stateDef');
const queryUsersSuccess = defaultActionFactory(userMgmtActionTypes.USER_MGMT_QUERY_USERS_SUCCESS, 'stateDef', 'queryResults');
const queryUsersError = defaultActionFactory(userMgmtActionTypes.USER_MGMT_QUERY_USERS_ERROR, 'stateDef');

const queryUsers = queryActionCustomFactory(
  queryUsersStarting,
  queryUsersSuccess,
  queryUsersError,
  errorMessages.ERROR_RETRIEVING_USERS,
  fetchUsers
);

const openAddDialog = defaultActionFactory(userMgmtActionTypes.USER_MGMT_OPEN_ADD_DIALOG, 'stateDef');
const openDeleteConfirmation = defaultActionFactory(userMgmtActionTypes.USER_MGMT_OPEN_DELETE_CONFIRMATION, 'stateDef', 'user');
const openEditDialog = defaultActionFactory(userMgmtActionTypes.USER_MGMT_OPEN_EDIT_DIALOG, 'stateDef', 'user');
const setActiveStateStarting = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_ACTIVE_STARTING, 'stateDef');
const setActiveStateSuccess = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_ACTIVE_SUCCESS, 'stateDef', 'results');
const setActiveStateError = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_ACTIVE_ERROR, 'stateDef');

/**
 * This function is used to update the user's active state.
 */
const setActiveState = (stateDef, userObj, isActive) => {
  return async (dispatch, getState) => {
    let results = null;
    try {
      await dispatch(setActiveStateStarting(stateDef));
      results = await fetchUpdateUser({...userObj, isEnabled: isActive });
    } catch(e) {
      await dispatch(setActiveStateError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_UPDATING_USER, e.message));
    }

    await dispatch(setActiveStateSuccess(stateDef, results.updateUser));
    await dispatch(queryUsers(stateDef));
  }
};

export {
  setCurrentPage,
  setUsersPerPage,
  openAddDialog,
  queryUsersStarting,
  queryUsersSuccess,
  queryUsersError,
  queryUsers,
  openDeleteConfirmation,
  openEditDialog,
  setActiveStateStarting,
  setActiveStateSuccess,
  setActiveStateError,
  setActiveState,
}