import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import queryActionCustomFactory from "../../../common/factories/queryActionCustomFactory";
import userMgmtActionTypes from '../userMgmtActionTypes';

import {fetchAddUser, fetchUpdateUser } from '../userMgmtQueries';
import { handleError } from '../../../app/actions/appErrorActions';
import errorMessages from '../../../common/errorMessages';

import { queryUsers } from './userMgmtDisplayActions';

const closeAddDialog = defaultActionFactory(userMgmtActionTypes.USER_MGMT_CLOSE_ADD_DIALOG, 'stateDef');
const closeEditDialog = defaultActionFactory(userMgmtActionTypes.USER_MGMT_CLOSE_EDIT_DIALOG, 'stateDef');
const setWorkingName = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_WORKING_NAME, 'stateDef', 'name');
const setWorkingEmail = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_WORKING_EMAIL, 'stateDef', 'email');
const setWorkingIsEnabled = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_WORKING_IS_ENABLED, 'stateDef', 'isEnabled');
const setWorkingIsAdmin = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_WORKING_IS_ADMIN, 'stateDef', 'isAdmin');
const setWorkingConfirmEmail = defaultActionFactory(userMgmtActionTypes.USER_MGMT_SET_WORKING_CONFIRM_EMAIL, 'stateDef', 'email');
const validateWorkingUser = defaultActionFactory(userMgmtActionTypes.USER_MGMT_VALIDATE_WORKING, 'stateDef');

const updateUserStarting = defaultActionFactory(userMgmtActionTypes.USER_MGMT_UPDATE_USER_STARTING, 'stateDef');
const updateUserSuccess = defaultActionFactory(userMgmtActionTypes.USER_MGMT_UPDATE_USER_SUCCESS, 'stateDef', 'queryResults');
const updateUserError = defaultActionFactory(userMgmtActionTypes.USER_MGMT_UPDATE_USER_ERROR, 'stateDef');

const updateUser = queryActionCustomFactory(
  updateUserStarting,
  updateUserSuccess,
  updateUserError,
  errorMessages.ERROR_UPDATING_USER,
  fetchUpdateUser
);

const addUserStarting = defaultActionFactory(userMgmtActionTypes.USER_MGMT_ADD_USER_STARTING, 'stateDef');
const addUserSuccess = defaultActionFactory(userMgmtActionTypes.USER_MGMT_ADD_USER_SUCCESS, 'stateDef', 'results');
const addUserError = defaultActionFactory(userMgmtActionTypes.USER_MGMT_ADD_USER_ERROR, 'stateDef');

const addUser = (stateDef, workingUser) => {
  return async (dispatch, getState) => {
    let results = null;
    try {
      await dispatch(addUserStarting(stateDef));
      results = await fetchAddUser(workingUser);
      await dispatch(addUserSuccess(stateDef, results));
    } catch(e) {
      await dispatch(addUserError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_ADDING_USER, e.message));
    }
    return dispatch(queryUsers(stateDef, getState().userMgmt.includeDisabledUsers));
  }
};

export {
  closeAddDialog,
  closeEditDialog,
  setWorkingName,
  setWorkingEmail,
  setWorkingIsEnabled,
  setWorkingIsAdmin,
  setWorkingConfirmEmail,
  validateWorkingUser,
  updateUserStarting,
  updateUserSuccess,
  updateUserError,
  updateUser,
  addUserStarting,
  addUserSuccess,
  addUserError,
  addUser,
}