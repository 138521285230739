import _ from 'lodash';
import * as descriptionService from '../../../displays/clientStatus/services/descriptionService';

const processClients = (fleetId, clients) => {
  let client = findClient(fleetId, clients);
  prettifyClient(client);
  return client;
};

const prettifyClient = (client) => {
  if (!_.isNil(client)) {
    // Generate the text descriptions rendered in the UI tooltips, detail window, etc.
    client.description = descriptionService.generateCloudClientDescription(client);
    client.sensorUploadDescription = descriptionService.generateSensorUploadDescription(client);
    client.sensorDataDescription = descriptionService.generateSensorDataDescription(client);
    client.dataSourceAggregateStatusDescription = descriptionService.generateDataSourceAggregateStatusDescription(client);
  }
};

const findClient = (fleetId, clients) => {

  if (_.isNil(clients) || _.isNil(fleetId)) {
    return null;
  }

  return _.find(clients, {fleetId: fleetId});
};

export {
  processClients,
  prettifyClient,
}