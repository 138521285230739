import ReactGA from 'react-ga4';
import _ from "lodash";
import { getGoogleAnalyticsId } from './configHelper';

const initializeTracking = () => { ReactGA.initialize(getGoogleAnalyticsId()); }

const trackPage = (page, user) => {
  const payload = { hitType: "pageview", page: page};
  if (!_.isNil(user.ownerId)) {
    payload.dimension1 = user.ownerId;
  }
  if (!_.isNil(user.userId)) {
    payload.dimension3 = user.userId;
  }
  if (!_.isNil(user.role)) {
    payload.dimension2 = user.role;
  }

  ReactGA.send(payload);
}

export {
  initializeTracking,
  trackPage
}