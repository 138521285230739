import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';

import { 
  Typography, 
  Box, 
  Stack,
  Button, 
  Card,
  CardContent,
  TextField,
  Tabs,
  Tab,
  Switch
} from '@mui/material';

import ComponentTypes from '../../../../componentTypes';
import {AutoCompleteMDT} from '../../../../controls/mdtMuiControls';

import SensorConfigControl from "../../../../controls/configPanel/sensorConfigControl";

import { liveViewState } from '../../../../../state/displays/fleetMap/maps/liveView/liveViewSelectors';
import * as liveViewActions from '../../../../../state/displays/fleetMap/maps/liveView/liveViewActions';

const styles = {
  card: {
    marginTop: 1, 
    '& .MuiCardContent-root:last-child': { paddingBottom: 1 }, 
    minHeight: '56px', 
    maxHeight: '56px'   
  }
}

const seriesTabContent = (props) => {

  return (
    <Box sx={{height: '100%', display: 'flex', flexFlow: 'column nowrap', width: '95%'}}>
      <Card sx={styles.card}>
        <CardContent>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography sx={{fontSize: 16}} variant='body2'>Close Gaps</Typography>
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 2}}>
              <Switch 
                checked={props.closeGaps}
                onChange={() => { props.onCloseGaps(!props.closeGaps); }}
                />
            </Box>
          </Box>
        </CardContent>
      </Card>
      {
        _.map(props.selectedTrucks, (truck) => {
          const truckColor = _.find(props.colorOptions, (color) => color.value === truck.color);
          return (
            <Card key={truck.truckPid} sx={styles.card}>
              <CardContent>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography sx={{fontSize: 16}} variant='body2'>{truck.truckName}</Typography>
                  <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 2}}>
                    <Typography variant='body2'>Color:</Typography>
                    <AutoCompleteMDT
                        sx={{marginLeft: 1, width: '120px', 
                            '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                              color: 'black',
                            }
                        }}
                        getOptionLabel={(colorOption) => colorOption.value}
                        options={props.colorOptions}
                        value={truckColor}
                        onChange={(event, value, reason) => {
                          props.setContextColor(truck.truckPid, value.value);
                        }}
                        noOptionsText={"No colors found..."}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.value}>
                              <div style={{backgroundColor: option.value, width: '100%', height: '20px', display: 'flex', justifyContent:'center', color: 'black'}}>{option.value}</div>
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{backgroundColor: (_.isNil(params.inputProps.value) ? 'transparent' : truckColor.value), input: { color: 'black'}}}
                            variant='standard'
                            {...params}
                            InputProps={{ ...params.InputProps, disableUnderline: true}}
                          />
                        )}
                        // This lets us show options using the hex value but filter using the label (like 'red')
                        filterOptions={(options, state) => {
                          let newOptions = [];
                          _.forEach(options, (option) => {
                            if (option.label.toLowerCase().includes(state.inputValue.toLowerCase())) {
                              newOptions.push(option);
                            }
                          })
                          return newOptions;
                        }}
                      />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )
        })
      }
    </Box>
  )

}

const dataTabContent = (props) => {
  return (
    <Box sx={{height: '100%', display: 'flex', flexFlow: 'column nowrap', width: '90%'}}>
      {
        _.isEmpty(props.selectedSensors) &&
        <Box sx={{height: '100%', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center'}}>
          <Typography>Select Sensors to Configure</Typography>
        </Box>
      }
      {
        !_.isEmpty(props.selectedSensors) &&
        <SensorConfigControl
          sensors={props.selectedSensors}
          onDragDropCallback={props.onMoveSensor}
        
          canRemoveSensor={false}
        
          onChangeSensorDisplayNameCallback={props.setSensorDisplayName}
          onChangeSensorUOMCallback={props.setSensorUOM}
        
          onChangeSensorPropertyCallback={props.dataRuleSetProperty}
        
          onAddRuleCallback={props.onAddRule}
        
          colorPickerStates={props.ruleColorPickerStates}
          onSetColorPickerStateCallback={props.dataRuleSetColorPickerState}
        
          onRemoveRuleCallback={props.onRemoveRule}   
          
          conditionOptions={props.configRuleConditions}
        />
      }
    </Box>
  )
}

const LiveViewConfigPanel = (props) => {

  return (
    <Stack sx={{height: 'calc(100% - 56px)'}} spacing={1} >
      <Box sx={{display: 'flex', flex: '1 1 93%', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', overflowY: 'auto'}}>
        <Tabs
            value={props.configTabIndex}
            onChange={(event, value) => props.selectConfigTab(value)}
            indicatorColor='primary'
            textColor='primary'
            variant={'fullWidth'}>
          <Tab value={0} label='Assets' />
          <Tab value={1} label='Sensors'/>
        </Tabs>
        {
          props.configTabIndex === 0 ? seriesTabContent(props) : dataTabContent(props)
        }
      </Box>
      <Box sx={{display: 'flex', flex: '0 0 2%', flexFlow: 'row nowrap', justifyContent: 'center', paddingBottom: 2}}>
        <Button variant='contained' color={'primary'} onClick={() => { props.onClose(); }}>
          OK
        </Button>
      </Box>
      
    </Stack>
  )
}

LiveViewConfigPanel.propTypes = {
  fleetName: PropTypes.string.isRequired
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_LIVE_VIEW : props.stateKey,
      type: ComponentTypes.FLEET_LIVE_VIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  const componentState = liveViewState(state[stateDef.key])
  return {
    selectedTrucks: componentState.selectedTrucks,
    selectedSensors: componentState.selectedSensors,

    colorOptions: componentState.colorOptions,
    configTabIndex: componentState.configTabIndex,
    configuredRules: componentState.configuredRules,
    configRuleConditions: componentState.configRuleConditions,
    ruleColorPickerStates: componentState.ruleColorPickerStates,

    // Since both primary and secondary closeGaps values are the same, we can just one of them
    closeGaps: componentState.definition.primary.closeGaps,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return { 

    onClose: () => { dispatch(liveViewActions.setConfigureChart(props.stateDef, false)) },
    setContextColor: (truckPid, color) => { dispatch(liveViewActions.setContextColor(props.stateDef, truckPid, color)); },
    selectConfigTab: (tabIndex) => { dispatch(liveViewActions.selectConfigTab(props.stateDef, tabIndex)); },

    onAddRule: (sensorSetId) => { dispatch(liveViewActions.addDataRuleForSensor(props.stateDef, sensorSetId)); },
    dataRuleSetColorPickerState: (sensorSetId, index, color) => { dispatch(liveViewActions.dataRuleSetColorPickerState(props.stateDef, sensorSetId, index, color)); },
    onRemoveRule: (sensorSetId, ruleIndex) => { dispatch(liveViewActions.removeDataRuleForSensor(props.stateDef, sensorSetId, ruleIndex)); },
    onMoveSensor: ({ removedIndex, addedIndex }) => { dispatch(liveViewActions.moveSensor(props.stateDef, removedIndex, addedIndex)); },
    setSensorDisplayName: (sensorSetId, displayName) => { dispatch(liveViewActions.setSensorDisplayName(props.stateDef, sensorSetId, displayName)); },
    setSensorUOM: (sensorSetId, uom) => { dispatch(liveViewActions.setSensorUOM(props.stateDef, sensorSetId, uom, props.fleetName)); },
    dataRuleSetProperty: (sensorSetId, index, property, value) => { dispatch(liveViewActions.dataRuleSetProperty(props.stateDef, sensorSetId, index, property, value)); },
    onCloseGaps: (closeGaps) => { dispatch(liveViewActions.onCloseGaps(props.stateDef, closeGaps)); },
  }
}


export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(LiveViewConfigPanel));