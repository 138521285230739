import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import * as routeAssetMgmtServices from '../services/routeAssetMgmtServices';

const routeAssetMgmtDisplayReducer = (state, action) => {
  switch (action.type) {
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_SET_ROUTE_ASSETS_PER_PAGE:
      return onSetRouteAssetsPerPage(state, action);
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_STARTING:
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_STARTING:
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_STARTING:
      return {
        ...state,
        queryRunning: true
      }
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_ERROR:
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_ERROR:
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_ERROR:
      return {
        ...state,
        queryRunning: false
      }
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_ROUTE_ASSETS_SUCCESS:
      return { ...state, routeAssets: action.queryResults.routedAssetsForOwner, queryRunning: false };
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_CONSUMERS_SUCCESS:
      return { ...state, consumers: routeAssetMgmtServices.processConsumers(action.results), queryRunning: false  }; 
    case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_QUERY_TRUCKS_SUCCESS:
      return { ...state, trucks: routeAssetMgmtServices.processTrucks(action.results), queryRunning: false };
    default:
      return state;
  }
}

const onSetRouteAssetsPerPage = (state, action) => {
  return {
    ...state,
    routeAssetsPerPage: action.routeAssetsPerPage
  }
}

export default routeAssetMgmtDisplayReducer;