import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Typography, Box, CircularProgress } from '@mui/material';
import {performLogin} from "../../../helpers/authHelper";

const styles = {
  page: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 18,
  },
  message: {
    paddingTop: 2
  }
};

class AuthPage extends Component {

  componentDidMount() {
    // Initiate the Auth0 authentication while we show a busy UI component.
    // We do not do this during our tests as this attempts to navigate away
    // and causes all sorts of issues.
    if (process.env.NODE_ENV !== 'test') {
      performLogin();
    }
  }

  render() {
    return (
      <Box sx={styles.page}>
        <CircularProgress size={80} />
        <Typography variant={'subtitle1'} sx={styles.message}>Signing in...</Typography>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);