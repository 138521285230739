import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import { tableState } from '../../common/table/tableSelectors';
import { exportCsvState } from'../../common/exportCsv/exportCSVSelectors';

const equipmentListState = state => {

  let filterDetails = filterState(state);
  let tableDetails = tableState(state);
  let exportCsvDetails = exportCsvState(state);

  return {

    ...filterDetails,
    ...tableDetails,
    ...exportCsvDetails,

    // Raw equipment details returned from the service
    equipment: _.isNil(state) ? [] : state.equipment,

    // Displayed equipment list and paging details
    filteredEquipment: _.isNil(state) ? [] : state.filteredEquipment,

    // Flag to indicate one of the equipment list remote calls is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

  };

};

export {
  equipmentListState
};