import { mdtPalette } from "./mdtPalette";

const getDataGridStyles = () => ({
  root: {
    border: '0px',
    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders': {
      ...mdtPalette().typography,
      backgroundColor: '#424242',
      backgroundImage: 'none',
      border: '0px'
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'block'
    },
    '& .MuiDataGrid-row': {
      backgroundColor:  mdtPalette().materialUI.palette.background.paper,
      backgroundImage: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#424242'
    },
    '& .MuiDataGrid-cell': {
      border: '0px'
    },
    '& .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus,.MuiIconButton-label:focus-visible,.MuiDataGrid-columnHeader:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: mdtPalette().materialUI.palette.background.default,
      display: 'block'
    },
    '& .MuiTablePagination-spacer': {
      flex: 'none',
    },
    '& .MuiTablePagination-toolbar p:nth-of-type(2)': {
      flex: 1,
      marginLeft: '-10%',
      textAlign: 'center'
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 'auto'
    },
    // This keeps the pinned columns hover highlighting the same as if there were no pinned columns
    '& .MuiDataGrid-row.Mui-hovered': {
      backgroundColor: 'grey.800'
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowX: 'auto',
      overflowY: 'auto',
    },
  }
});

export default getDataGridStyles;