import _ from "lodash";
import * as filterService from '../../../common/filtering/filterService';
import {getAssetNameByType} from "../../../common/services/assetTypeService";

/**
 * Given a collection of Assets, apply any filters and return the filtered assets and updated filters
 * @param {*} assets Assets queried from the Location Service
 * @param {*} filters UI level filters to be applied on the Assets
 * @returns Assets with the filters applied and updated filters
 */
const processAssets = (assets, filters) => {
  const prettifiedAssets = _.map(assets, (asset) => {
    return {
      ...asset,
      unitType: getAssetNameByType(asset.unitType)
    }
  });
  // Automatically filter our any assets that don't have a valid lastKnownCoord 
  let assetsWithCoordinates = _.filter(prettifiedAssets, (asset) => {
    return !_.isNil(asset.lastKnownCoord);
  });

  // Filter the asset contexts by the current filters (in order)
  let filteredAssets = filterService.filterItems(assetsWithCoordinates, filters);
  // Generate filter data from the filtered equipment list
  let updatedFilters = filterService.generateFilters(filteredAssets, filterDefinitions());

  return {
    assets: assetsWithCoordinates,
    filteredAssets: filteredAssets,
    filters: updatedFilters
  };
};

/**
 * @returns Filter Definitions created using the properties on Assets and work with the Filter Control
 */
const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Asset Type', 'unitType'));
  definitions.push(filterService.createFilterDefinition('Asset Name', 'truckName'));
  return definitions;
};

const searchAssets = (assets, searchTerm) => {
   return _.find(assets, ['truckName', searchTerm]) || null;
}

/**
 * Given a collection of Locations, process the data to include the geomType
 * @param {*} locations Locations queried from the Location Service
 * @returns Locations with the geomType included
 */
const processLocations = (locations) => {

  return _.map(locations, (location) => {
    return {
      ...location,
      geomType: (_.isNil(location.radius)) ? 'Polygon' : 'Circle'
    }
  });

}

const createLocationInputFromLocation = (location) => {
  let inputObject = {
    name: location.label,
    type: location.type,
  }

  if (!_.isNil(location.locationId)) {
    inputObject.id = location.locationId;
  }

  let coordinates = [];
  let radius = null;
  if (location.geomType === 'Circle') {
    coordinates.push({
      latitude: location.shape.getCenter().lat(),
      longitude: location.shape.getCenter().lng()
    });
    radius = location.shape.getRadius();
    inputObject.radius = radius;
  } else {
    _.forEach(location.shape.getPath().getArray(), (point) => {
      coordinates.push({
        latitude: point.lat(),
        longitude: point.lng()
      })
    });
    // For Polygons, the first and last point must be the same
    if (!_.isEqual(coordinates[0], coordinates[coordinates.length - 1])) {
      coordinates.push({
        latitude: location.shape.getPath().getAt(0).lat(),
        longitude: location.shape.getPath().getAt(0).lng()
      });
    }
  }
  inputObject.coordinates = coordinates;

  return inputObject;
}

const processAssetsAndFilterInActiveAssets = (assets, filters, showInActive) => {

  let processedAssets = processAssets(assets, filters);
  let filteredAssetsBasedOnShowInActive = _.filter(processedAssets.filteredAssets, (asset) => { return (showInActive === false) ? asset.active === true : asset; });

  return {
    assets: processedAssets.assets,
    filteredAssets: filteredAssetsBasedOnShowInActive,
    filters: processedAssets.filters
  }
}

export {
  processAssets,
  searchAssets,
  processLocations,
  createLocationInputFromLocation,
  processAssetsAndFilterInActiveAssets
}