import _ from 'lodash';
import moment from 'moment';
import getTypographyStyles from '../../../common/styles/typographyStyles';
import { mdtPalette } from "../../../common/styles/mdtPalette";

/**
 * Converts a MDT yAxis value to a HighCharts yAxis value
 * MDT is setup as:
 * -2 -1 [Chart] 1 2
 * HighChart is setup as:
 * 3 2 [Chart] 0 1
 * @param {*} yAxisId MDT yAxis value
 * @returns A HighCharts compatible yAxis value
 */
const translateMdtYAxisId = (yAxisId) => {
  switch(yAxisId) {
    case 1:
      return 2;
    case 2:
      return 3;
    case -1:
      return 0;
    case -2:
      return 1;
    default:
      console.error(`Invalid numerical Y-axis position '${yAxisId}', defaulting to 0`);
      return 0;
  }
}

const translateLineStyleToDashStyle = (lineStyleId) => {
  switch(lineStyleId) {
    case 109:
      return 'Solid';
    case 110:
      return 'Dash';
    case 112:
      return 'ShortDot';
    case 113:
      return 'Dot';
    default:
      console.error(`Invalid line style id '${lineStyleId}', defaulting to Solid`);
      return 'Solid';
  }
}

const cardChartOptionsTemplate = (minDate, maxDate, tickInterval) => {

  let typographyStyles = getTypographyStyles();
  const borderColor = mdtPalette().categories.category1;

  return  {
    title: {
      text: ""
    },
    boost: {
      enabled: false,
      pixelRatio: 0
    },
    time:{
      useUTC: false,
    },
    chart: {
      spacing: [0,0,0,0],
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      plotBackgroundColor: mdtPalette().materialUI.palette.background.paper,
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
      },
      reflow: false,
      animation: false,
      marginBottom: 20,
      marginTop: 8,
      alignTicks: false,
    },
    lang: {
      noData: 'No Data'
    },
    noData: {
      style: typographyStyles.noDataLabel
    },
    tooltip: {
      enabled: true,
      shared: true,
      split: false,
      backgroundColor: mdtPalette().materialUI.palette.background.paper,
      borderColor: borderColor,
      style: {
        color: mdtPalette().typography.color,
        fontSize: '10px',
      },
      useHTML: true,
      headerFormat: '<table><tr><td colspan="3" align="center">{point.key}</td></tr>',
      pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
        '<td style="text-align: right"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      stickOnContact: true,
      animation: false
    },
    exporting: {
      enabled:false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      align: 'center',
      verticalAlign: 'center',
      layout: 'vertical',
      itemStyle: {
        color: mdtPalette().typography.white,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        cursor: 'default'
      },
      itemHoverStyle: {
        color: mdtPalette().typography.white,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        cursor: 'default'
      },
      x: 278,
      y: 20,
      floating: true
    },
    plotOptions: {
      series: {
        animation: false,
        connectNulls: false
      },
      line: {
        animation: false,
        lineWidth: 1,
        enableMouseTracking: false, // enable this for tooltips
        turboThreshold: 500,
        stickyTracking: true,
        shadow: false,
        dataLabels: {
          style: {
            textShadow: false
          }
        },
        events: {
          legendItemClick: function() {
            return false;
          }
        }
      }
    },
    yAxis: null,
    xAxis: {
      crosshair: {
        color: mdtPalette().typography.noData,
      },
      type: 'datetime',
      lineWidth: 0.5,
      lineColor: borderColor,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      tickWidth: 0.5,
      tickPosition: 'inside',
      tickInterval: tickInterval,
      tickColor: borderColor,
      labels: {
        enabled: true,
        format: '{value:%H:%M}',
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          color: mdtPalette().materialUI.palette.grey[400] // Material UI Grey 400, because mdtPallete().typography.color is still too bright
        }
      },
      min: minDate,
      max: maxDate
    },
    series: null
  }
}

/**
 * Given a Definition, use that to create a valid HighCharts Options JSON object that represents the configuration in that Definition. 
 * @param {*} definition A definition JSON for a single Unit User Chart
 * @param {*} xValues Collection of timestamps representing the time range of the collected data in yValues
 * @param {*} yValues JSON object indexed by sensor set id that contains all the data for each sensor in the Definition
 * @returns A HighCharts Options JSON object used to configure a HighCharts chart
 */
const cardChartOptions = (definition, xValues, yValues) => {

  // CLAIM: We should have a definition here

  const borderColor = mdtPalette().categories.category1;

  let startTimeMoment = new moment.unix(definition.primary.timeRange.startTime).utc();
  let endTime = definition.primary.timeRange.startTime + (60 * definition.primary.timeRange.duration);
  let endTimeMoment = new moment.unix(endTime).utc();

  let durationHours = endTimeMoment.diff(startTimeMoment, 'hours');

  // The highcharts configuration requires time based axis to be unix milliseconds instead
  // of unix seconds. As well the tick interval must be in milliseconds.
  let maxDate = endTimeMoment.valueOf();
  let minDate = startTimeMoment.valueOf();
  let hourTicks = 60 * 60 * 1000; // every hour
  let minuteTicks = 10 * 60 * 1000; // every 10 minutes

  let tickInterval = hourTicks;
  if (durationHours < 6) {
    // IMPORTANT - We are assuming here that there is only one duration less than the 6 hour duration (last hour),
    // and if we add more then we need to adjust this tick count.
    tickInterval = minuteTicks ;
  } else if (durationHours > 12) {
    tickInterval = hourTicks * 2;
  }

  let cardChartOptionsTemplateDefinition = cardChartOptionsTemplate(minDate, maxDate, tickInterval);

  if (_.isEmpty(xValues) && _.isEmpty(yValues)) {
    return {
      ...cardChartOptionsTemplateDefinition,
      yAxis: [],
      series: []
    }
  }

  // For each sensor in the definition, translate that to an axis and series for HighCharts
  let series = [];
  let yAxis = [];

  let yAxisDefinitionTemplate = {
    labels: { format: '{value}', enabled: true, style: { color: '#BDBDBD', fontSize: '9px' }},
    lineWidth: null,
    lineColor: borderColor,
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    title: { enabled: false },
    opposite: null,
    allowDecimals: false,
    visible: true
  };

  // Create the y axes based on what sensors are configured for what MDT yAxisIds
  // If there is no sensor for a particular yAxisId, we still add it but make it hidden
  // This simplifies the configuration of the series data because we don't need to keep track of what
  // yAxisIds are at what array index in the yAxis collection
  let hasInnerLeftYAxis = _.some(definition.primary.sensors, ['yAxisId', -1]);
  let hasInnerRightYAxis = _.some(definition.primary.sensors, ['yAxisId', 1]);
  if (hasInnerLeftYAxis === true) {
    const innerLeftYAxis = {...yAxisDefinitionTemplate, lineWidth: 0.5, opposite: false };
    if (!_.isEmpty(definition.primary.axes[-1]?.min)) {
      innerLeftYAxis.min = Number(definition.primary.axes[-1].min);
    }
    if (!_.isEmpty(definition.primary.axes[-1]?.max)) {
      innerLeftYAxis.max = Number(definition.primary.axes[-1].max);
    }
    yAxis.push(innerLeftYAxis);
  } else {
    yAxis.push({...yAxisDefinitionTemplate, visible: false });
  }
  if (_.some(definition.primary.sensors, ['yAxisId', -2])) {
    // if the left outer is the only axis on the left, we want to draw the line for the y axis
    // if we have both left inner and outer axis, then we only draw a line for the inner 
    const outerLeftYAxis = {...yAxisDefinitionTemplate, lineWidth: hasInnerLeftYAxis === false ? 0.5 : 0.0, opposite: false };
    if (!_.isEmpty(definition.primary.axes[-2]?.min)) {
      outerLeftYAxis.min = Number(definition.primary.axes[-2].min);
    }
    if (!_.isEmpty(definition.primary.axes[-2]?.max)) {
      outerLeftYAxis.max = Number(definition.primary.axes[-2].max);
    }
    yAxis.push(outerLeftYAxis);
  } else {
    yAxis.push({...yAxisDefinitionTemplate, visible: false });
  }
  if (hasInnerRightYAxis === true) {
    const innerRightYAxis = {...yAxisDefinitionTemplate, lineWidth: 0.5, opposite: true };
    if (!_.isEmpty(definition.primary.axes[1]?.min)) {
      innerRightYAxis.min = Number(definition.primary.axes[1].min);
    }
    if (!_.isEmpty(definition.primary.axes[1]?.max)) {
      innerRightYAxis.max = Number(definition.primary.axes[1].max);
    }
    yAxis.push(innerRightYAxis);
  } else {
    yAxis.push({...yAxisDefinitionTemplate, visible: false });
  }
  if (_.some(definition.primary.sensors, ['yAxisId', 2])) {
    // if the right outer is the only axis on the right, we want to draw the line for the y axis
    // if we have both right inner and outer axis, then we only draw a line for the inner 
    const outerRightYAxis = {...yAxisDefinitionTemplate, lineWidth: hasInnerRightYAxis === false ? 0.5 : 0.0, opposite: true };
    if (!_.isEmpty(definition.primary.axes[2]?.min)) {
      outerRightYAxis.min = Number(definition.primary.axes[2].min);
    }
    if (!_.isEmpty(definition.primary.axes[2]?.max)) {
      outerRightYAxis.max = Number(definition.primary.axes[2].max);
    }
    yAxis.push(outerRightYAxis);
  } else {
    yAxis.push({...yAxisDefinitionTemplate, visible: false });
  }

  _.forEach(_.orderBy(definition.primary.sensors, ['yAxisId'], ['asc']), (sensor) => {

    let seriesData = [];
    let yValuesData = _.isNil(yValues) || _.isNil(yValues[sensor.sensorSetId]) ? [] : yValues[sensor.sensorSetId];

    if (!_.isEmpty(yValuesData)) {
      _.forEach(xValues, (x, index) => {
        seriesData.push([x * 1000, yValuesData[index]]);
      })
    }

    let seriesDataOptions = {
      type: 'line',
      data: seriesData,
      states: { hover: { enabled: false } },
      color: sensor.color,
      name: sensor.displayName,
      // Convert a MDT yAxis to a Highchart yAxis
      yAxis: translateMdtYAxisId(sensor.yAxisId),
      // Convert the MDT Line Style to Highchart DashStyle
      dashStyle: translateLineStyleToDashStyle(sensor.lineStyle.id),
      marker: { enabled: false, radius: 3, states: { hover: { enabled: false } } }
    }

    series.push(seriesDataOptions);

  });

  return {
    ...cardChartOptionsTemplateDefinition,
    yAxis: yAxis,
    series: series
  }
};

export default cardChartOptions;
