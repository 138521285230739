import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class Calculator extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <g transform='matrix(0.04897959183673469, 0, 0, 0.04897959183673469, 0, -0.000002543131586207892)'>
          <path d='M409.9,0H80.1C68,0,58.3,9.7,58.3,21.8v446.4c0,12.1,9.7,21.8,21.8,21.8h329.8c12.1,0,21.8-9.7,21.8-21.8V21.8     C431.7,9.7,421.9,0,409.9,0z M139.6,442.6c-15.9,0-29.2-12.8-29.2-29.2c0-15.9,12.8-29.2,29.2-29.2s29.2,12.8,29.2,29.2     C168.8,429.7,155.6,442.6,139.6,442.6z M139.6,346.5c-15.9,0-29.2-12.8-29.2-29.2c0-16.3,12.8-29.2,29.2-29.2     s29.2,12.8,29.2,29.2S155.6,346.5,139.6,346.5z M139.6,250.1c-15.9,0-29.2-12.8-29.2-29.2s12.8-29.2,29.2-29.2     s29.2,12.8,29.2,29.2S155.6,250.1,139.6,250.1z M245,442.6c-15.9,0-29.2-12.8-29.2-29.2c0-15.9,12.8-29.2,29.2-29.2     c16.3,0,29.2,12.8,29.2,29.2C274.2,429.7,260.9,442.6,245,442.6z M245,346.5c-15.9,0-29.2-12.8-29.2-29.2     c0-16.3,12.8-29.2,29.2-29.2c16.3,0,29.2,12.8,29.2,29.2S260.9,346.5,245,346.5z M245,250.1c-15.9,0-29.2-12.8-29.2-29.2     s12.8-29.2,29.2-29.2c16.3,0,29.2,12.8,29.2,29.2S260.9,250.1,245,250.1z M350.4,442.6c-15.9,0-29.2-12.8-29.2-29.2     c0-15.9,12.8-29.2,29.2-29.2s29.2,12.8,29.2,29.2C379.2,429.7,366.3,442.6,350.4,442.6z M350.4,346.5     c-15.9,0-29.2-12.8-29.2-29.2c0-16.3,12.8-29.2,29.2-29.2s29.2,12.8,29.2,29.2S366.3,346.5,350.4,346.5z M350.4,250.1     c-15.9,0-29.2-12.8-29.2-29.2s12.8-29.2,29.2-29.2s29.2,12.8,29.2,29.2S366.3,250.1,350.4,250.1z M381.5,141.9h-273V47.4h273.4     v94.5H381.5z'/>
        </g>
      </SvgIcon>
    )
  }
}

export default Calculator;
