import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import Vibration from '../../../../controls/charts/vibration/vibration';
import Alarms from '../../../../controls/charts/alarms/alarms';

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,

  missileTopRow: {
    position: 'absolute',
    bottom: '-21px'
  },

  missileBottomRow: {
    position: 'absolute',
    top: '-48px'
  },

  missileTopRowEmpty: {
    position: 'absolute',
    bottom: '-20px'
  },

  missileBottomRowEmpty: {
    position: 'absolute',
    top: '-19px'
  },

  emptyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  alarmsContainer: {
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1
  }

};

class Pump extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;
    let isTopRow = this.props.isTopRow;
    let isEmpty = !this.props.data.empty ? false : this.props.data.empty;

    let navigate = _.isNil(this.props.navigateToDashboard) ? {} : this.props.navigateToDashboard;

    return (
      <Box sx={styles.unitMapContainer}>
        {
          isEmpty === true &&
          <Box sx={styles.emptyContainer}>
            <Typography variant={'subtitle1'} sx={styles.fleetMapText}>Empty</Typography>
            {
              isTopRow === true && data.value &&
              <Box sx={styles.missileTopRowEmpty}>
                <Vibration component={""} value={data.value}/>
              </Box>
            }
            {
              isTopRow === false && data.value &&
              <Box sx={styles.missileBottomRowEmpty}>
                <Vibration component={""} value={data.value}/>
              </Box>
            }
          </Box>
        }
        {
          isEmpty === false &&
          <Box sx={styles.pumpUnitVisualContentWithCursor} onClick={() => navigate(data.id)}>
            <Box sx={styles.unitVisualHeaderContent}>
              <Typography variant={'subtitle1'}>{data.name}</Typography>
            </Box>
            <Box sx={styles.unitContent}>
              <Typography variant={'caption'} sx={styles.healthViewContentLabel}>Alarms</Typography>
              <Box sx={styles.alarmsContainer}>
                <Alarms alarmCounts={data.alarmCounts}/>
              </Box>
              <Typography variant={'caption'} sx={styles.healthViewContentLabel}>Vibration</Typography>
              <Box sx={styles.unitContentItem}>
                <Vibration component={"Power End"} value={data.powerEnd}/>
              </Box>
              <Box sx={styles.unitContentItem}>
                <Vibration component={"Fluid End"} value={data.fluidEnd}/>
              </Box>
              <Box sx={styles.unitContentItem}>
                <Vibration component={"Iron"} value={data.iron}/>
              </Box>
              {
                isTopRow === true &&
                <Box sx={styles.missileTopRow}>
                  <Vibration component={""} value={data.value}/>
                </Box>
              }
              {
                isTopRow === false &&
                <Box sx={styles.missileBottomRow}>
                  <Vibration component={""} value={data.value}/>
                </Box>
              }
            </Box>
          </Box>
        }
      </Box>
    );
  }
}

Pump.propTypes = {
  data: PropTypes.object.isRequired,
  isTopRow: PropTypes.bool,
  navigateToDashboard: PropTypes.func.isRequired,
};

export default Pump;
