
const fetchSubscriptionsDisplayData = (user) => {

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        subscriptionsByCreator:
        [
          {
            id: 1,
            name: 'My Subscription on a PRS Alarms',
            eventId: 1,
            eventEntity: 'Alarm',
            eventType: 'AlarmRaised',
            createdBy: user,
            active: true,
            interval: 500,
            recipients:
            [
              {
                providerId: 0,
                destination: '(999)999-9999'
              },
              {
                providerId: 0,
                destination: '(888)888-8888'
              }
            ],
            conditions:
            [
              {
                name: 'fleetName',
                operator: '==',
                value: 'P1005'
              },
              {
                name: 'truckName',
                operator: '==',
                value: 'P1005'
              },
              {
                name: 'unitType',
                operator: '==',
                value: 'PRS'
              },
              {
                name: 'category',
                operator: '==',
                value: 'FG_SOFT_ESD_CRITICAL PowertrainShutdown'
              },
              {
                name: 'severityValue',
                operator: '==',
                value: '100'
              },
              {
                name: 'source',
                operator: '==',
                value: 'STW'
              },
            ],
            conditionOperator: 'AND'
          },
          {
            id: 2,
            name: 'Another PRS Alarms',
            eventId: 1,
            eventEntity: 'Alarm',
            eventType: 'AlarmRaised',
            createdBy: user,
            active: true,
            interval: 500,
            recipients:
            [
              {
                providerId: 0,
                destination: '(999)999-9999'
              },
              {
                providerId: 1,
                destination: 'alarmDemo'
              }
            ],
            conditions:
            [
              {
                name: 'fleetName',
                operator: '==',
                value: 'P1008'
              },
              {
                name: 'truckName',
                operator: '==',
                value: 'P1008'
              },
              {
                name: 'unitType',
                operator: '==',
                value: 'PRS'
              },
              {
                name: 'category',
                operator: '==',
                value: 'E-Kill Source'
              },
              {
                name: 'severityValue',
                operator: '==',
                value: '300'
              },
              {
                name: 'source',
                operator: '==',
                value: 'STW'
              },
            ],
            conditionOperator: 'AND'
          }
        ]
      })
    }, 1000)
  });

};

export { fetchSubscriptionsDisplayData }
