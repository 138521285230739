import _ from "lodash";
import React, {Component} from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Typography, Box, Paper, IconButton, Select, MenuItem } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from "prop-types";

const styles = {

  tableHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
    backgroundColor: 'grey.800',
    paddingLeft: '15px',
  },

  tableHeaderColumn: {
    display: 'flex',
    justifyContent: 'center',
    '& h6': {
      fontSize: '0.875rem',
      paddingTop: '5px',
    },
  },

  tableRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    minHeight: '56px',
    '&:hover #actionDiv': {
      display: 'block'
    },
  },

  tableHoverWithoutRowClickSupport: {
    '&:hover': {
      backgroundColor: 'grey.800',
    },
  },

  tableHoverWithRowClickSupport: {
    '&:hover': {
      backgroundColor: 'grey.800',
      cursor: 'pointer'
    },
  },

  tableColumn: {
    display: 'flex',
    justifyContent: 'center',
    color: 'text.primary'
  },

  tableActionDiv: {
    display: 'none'
  },

  pagingContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 1
  },

  pagingSelector: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '155px'
  },

  pagingLabel: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },

  pagingActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    width: '150px'
  },

  perPageLabel: {
    // Quick hack to space the users per
    // page label away from the select
    paddingLeft: '10px'
  },

};

class DataTableControl extends Component {

  handleRowClicked(item) {
    if (this.props.rowClickSupport) {
      this.props.rowClicked(this.props.tableContext, item);
    }
  }

  render() {

    const { columns } = this.props;

    let items = this.props.items;
    let pages = [];

    if (!_.isNil(items) && !_.isEmpty(items)) {

      // Sort the items

      const resolvePrimaryContext = item => {
        let value = item[this.props.sortContext];
        // We want to handle missing values such that in DESC order they appear at the end. By default null
        // and undefined values will appear at the beginning of a list sorted in DESC order. To reverse this
        // we set missing values to the largest negative value so it appears as we want.
        //
        // IMPORTANT - If for some reason we have a legitimate value equal the -Number.MAX_VALUE then it will
        // be grouped in with the empty values. The line of code below is the culprit...
        //
        // NOTE: Just some background on this annoying issue, we cannot simply set null or undefined values
        // to an empty string as these equates to true when comparing to values of 0. So empty values and 0 values
        // will be grouped together. We figured value of 0 are more likely to be in our system then values
        // of -Number.MAX_VALUE.
        value = _.isNil(value) ? (-Number.MAX_VALUE) : value;
        // Convert strings to lower case
        value = _.isString(value) ? value.toLowerCase() : value;
        return value;
      };

      let secondaryContexts = _.map(this.props.secondarySortDefinitions, 'context');
      let secondaryDirections = _.map(this.props.secondarySortDefinitions, 'direction');

      items = _.orderBy(
        items,
        _.concat(resolvePrimaryContext, secondaryContexts),
        _.concat(this.props.sortDirection, secondaryDirections)
      );

      // Page the items
      pages = _.chunk(items, this.props.itemsPerPage);
      // Select the current page
      items = pages.length > 0 ? pages[this.props.currentPage] : items;
    }

    // Handle the CSS class definition for the row here to keep the markup more readable
    let rowClassNames = [styles.tableRow];
    if (_.isNil(this.props.rowClickSupport) || !this.props.rowClickSupport) {
      rowClassNames.push(styles.tableHoverWithoutRowClickSupport);
    } else {
      rowClassNames.push(styles.tableHoverWithRowClickSupport);
    }

    return (
      <div>
        {/*Render the table header */}
        <Box sx={styles.tableHeader}>
          {columns.map((column, index) => {
            return (
              <div key={index}>
                {/* Rendering for a data and custom column header */}
                {(column.type === "data" || column.type === "custom") && (
                  <Box sx={{...styles.tableHeaderColumn, width: column.width }}>
                    <TableSortLabel
                      active={this.props.sortContext === column.sortProperty}
                      direction={this.props.sortDirection}
                      onClick={() => {
                        this.props.sortItems(
                          this.props.tableContext,
                          column.sortProperty
                        );
                      }}
                    >
                      <Typography variant={"subtitle1"}>{column.label}</Typography>
                    </TableSortLabel>
                  </Box>
                )}
                {/* Rendering for an action column header */}
                {column.type === "action" && (
                  <Box sx={{...styles.tableHeaderColumn, width: column.width }}>
                    {/* Empty header column to account for the action rendering in the table rows*/}
                  </Box>
                )}
              </div>
            );
          })}
        </Box>

        {/*Render the table rows */}
        <Paper>
          {items.map((item, index) => {
            return (
              <Box sx={rowClassNames}
                key={index}
                onClick={() => this.handleRowClicked(item)}
              >
                {columns.map((column, index) => {
                  return (
                    <div key={index}>
                      {/* Rendering for a custom column */}
                      {column.type === "custom" &&
                        this.props.renderTableCell(
                          this.props.tableContext,
                          column,
                          item
                        )}
                      {/* Rendering for a data column */}
                      {column.type === "data" && (
                        <Box sx={{...styles.tableColumn, width: column.width }}>
                          <Typography variant={"body2"}>
                            {item[column.displayProperty]}
                          </Typography>
                        </Box>
                      )}
                      {/* Rendering for an action column */}
                      {column.type === "action" && (
                        <Box sx={{...styles.tableColumn, width: column.width }}>
                          <Box sx={styles.tableActionDiv} id="actionDiv">
                            {column.renderAction(this.props.tableContext, item)}
                          </Box>
                        </Box>
                      )}
                    </div>
                  );
                })}
              </Box>
            );
          })}
        </Paper>

        {/* Render the paging components */}
        <Box sx={styles.pagingContainer}>
          <Box sx={styles.pagingSelector}>
            <Select
              variant='standard'
              value={this.props.itemsPerPage}
              onChange={(event) => {
                this.props.setItemsPerPage(
                  this.props.tableContext,
                  event.target.value
                );
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </Select>
            <Typography sx={styles.perPageLabel} variant={"body2"}>
              Items per page
            </Typography>
          </Box>
          <Box sx={styles.pagingLabel}>
            <Typography variant={"body2"}>
              {"Page " + (this.props.currentPage + 1) + " of " + pages.length}
            </Typography>
          </Box>
          <Box sx={styles.pagingActions}>
            <IconButton
              disabled={this.props.currentPage - 1 < 0}
              onClick={() => {
                this.props.setCurrentPage(
                  this.props.tableContext,
                  this.props.currentPage - 1
                );
              }}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={this.props.currentPage + 1 >= pages.length}
              onClick={() => {
                this.props.setCurrentPage(
                  this.props.tableContext,
                  this.props.currentPage + 1
                );
              }}
              size="large"
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
    );

  }

}

DataTableControl.propTypes = {

  // Properties
  tableContext: PropTypes.any,
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  sortContext: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  secondarySortDefinitions: PropTypes.array,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowClickSupport: PropTypes.bool,

  // Actions
  sortItems: PropTypes.func.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  rowClicked: PropTypes.func,
  renderTableCell: PropTypes.func,
};

export default DataTableControl;