import _ from "lodash";
import React, {Component} from "react";
import {DialogContent, DialogTitle, Dialog, TextField, DialogActions, Button} from "@mui/material";
import ComponentTypes from "../../componentTypes";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {customersDisplayState} from "../../../state/displays/customersDisplay/customersDisplaySelectors";
import * as customersDisplayAction from "../../../state/displays/customersDisplay/customersDisplayActions";
import PropTypes from "prop-types";
import getDialogStyles from "../../common/styles/dialogStyles";
import { hasCustomerChanged } from "../../../state/displays/customersDisplay/services/customersDisplayServices";

const dialogStyles = getDialogStyles();

const styles = {
    formField: {
        marginTop: 2
    }
};
class SaveCustomerDialog extends Component {
    render() {
        return (
         <Dialog
                open={this.props.openSaveCustomer}
                disableEscapeKeyDown>
                <DialogTitle>{this.props.customerForSave.id ? 'Edit' : 'Create'} Customer</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={styles.formField}
                        autoFocus
                        variant='standard'
                        size='small'
                        helperText="Name"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.name || ''}
                        onChange={(event) => {
                            this.props.onCustomerNameChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasNameError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Location"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.location || ''}
                        onChange={(event) => {
                            this.props.onCustomerLocationChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasLocationError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Contact"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.contactName || ''}
                        onChange={(event) => {
                            this.props.onCustomerContactChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasContactError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Phone"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.phone || ''}
                        onChange={(event) => {
                           this.props.onCustomerPhoneChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasPhoneError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Email"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.email || ''}
                        onChange={(event) => {
                            this.props.onCustomerEmailChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasEmailError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Note"
                        inputProps={{maxLength: 255}}
                        value={this.props.customerForSave.note || ''}
                        onChange={(event) => {
                           this.props.onCustomerNoteChange(event.target.value);
                        }}
                        error={this.props.errorsOnCustomerSave.hasNoteError}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={dialogStyles.actionButton} variant={'contained'}
                            onClick={this.props.onCloseDialog}>Cancel</Button>
                    <Button variant={'contained'} color='primary' onClick={() => this.props.onSaveCustomer(this.props.customerForSave)}
                            disabled={!_.isEmpty(Object.keys(this.props.errorsOnCustomerSave)) || !hasCustomerChanged(this.props.customerForSave, this.props.originalCustomerForSave)}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SaveCustomerDialog.propTypes = {
    isCreate: PropTypes.bool
};

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.CUSTOMERS_DISPLAY : props.stateKey,
            type: ComponentTypes.CUSTOMERS_DISPLAY,
        }
    };
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = customersDisplayState(state[stateDef.key]);

    return {
        customerForSave: componentState.customerForSave,
        errorsOnCustomerSave: componentState.errorsOnCustomerSave,
        openSaveCustomer: componentState.openSaveCustomer,
        originalCustomerForSave: componentState.originalCustomerForSave,
        canDiscardSave: componentState.canDiscardSave
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onCustomerNameChange: (value) => {
            dispatch(customersDisplayAction.onCustomerValidateAndChange(props.stateDef, value, 'name'));
        },
        onCustomerContactChange: (value) => {
            dispatch(customersDisplayAction.onCustomerChange(props.stateDef, value, 'contactName'));
        },
        onCustomerNoteChange: (value) => {
            dispatch(customersDisplayAction.onCustomerChange(props.stateDef, value, 'note'));
        },
        onCustomerLocationChange: (value) => {
            dispatch(customersDisplayAction.onCustomerChange(props.stateDef, value, 'location'));
        },
        onCustomerEmailChange: (value) => {
            dispatch(customersDisplayAction.onCustomerValidateAndChange(props.stateDef, value, 'email'));
        },
         onCustomerPhoneChange: (value) => {
            dispatch(customersDisplayAction.onCustomerChange(props.stateDef, value, 'phone'));
        },
        onCloseDialog: () => {
            dispatch(customersDisplayAction.onSaveCustomerDialogClose(props.stateDef));
        },
        onSaveCustomer: (customerToSave) => {
            dispatch(customersDisplayAction.onSaveCustomer(props.stateDef, customerToSave))
        },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(SaveCustomerDialog));