import _ from 'lodash';

const fleetPRCHistoryState = state => {

  return {
    // The card metrics
    flowRate: _.isNil(state) ? [] : state.flowRate,
    pressure:   _.isNil(state) ? [] : state.pressure,
    concentration: _.isNil(state) ? [] : state.concentration,
    latestFlowRate: _.isNil(state) ? null : state.latestFlowRate,
    latestPressure: _.isNil(state) ? null : state.latestPressure,
    latestConcentration: _.isNil(state) ? null : state.latestConcentration,
  };

};

export {
  fleetPRCHistoryState
};