import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { displayConfigurationState } from '../../../../state/app/appSelectors';
import dfpDashboardViews from '../../../../state/displays/dfpDashboard/dfpDashboardViews';
import ComponentTypes from '../../../componentTypes';

import LayoutFixedWidth from '../../../controls/layout/layoutFixedWidth';

import UnitAlarmCount from '../../../cards/unitAlarmCount/unitAlarmCount';
import ComponentLifeCurbside from "../../../cards/componentLifeCurbside/componentLifeCurbside";
import ComponentLifeRoadside from "../../../cards/componentLifeRoadside/componentLifeRoadside";
import UnitFleet from "../../../cards/unitFleet/unitFleet";
import UnitChartPressureHistoryRoadside from '../../../cards/unitChart/unitChartPressureHistoryRoadside';
import UnitChartPressureHistoryCurbside from '../../../cards/unitChart/unitChartPressureHistoryCurbside';
import UnitHoursCurbside from '../../../cards/unitHoursCurbside/unitHoursCurbside';
import UnitHoursRoadside from '../../../cards/unitHoursRoadside/unitHoursRoadside';
import UnitActivityCurbside from '../../../cards/unitActivityCurbside/unitActivityCurbside';
import UnitActivityRoadside from '../../../cards/unitActivityRoadside/unitActivityRoadside';

class DashboardView extends Component {

  render() {

    let viewConfiguration = _.isNil(this.props.configuration) || _.isNil(this.props.configuration.views) ?
      {} : _.find(this.props.configuration.views, ['id', dfpDashboardViews.DASHBOARD]);

    let dashboardConfiguration = viewConfiguration.configuration;

    let cardsLayout = dashboardConfiguration.layout;

    // Determine the start/end time for the 6 hour charts since this view does not have a duration picker.
    let endTime = moment().startOf('minute').unix();
    let startTime = moment.unix(endTime).subtract(360, 'minutes').unix();

    let cardElements = _.map(dashboardConfiguration.cards, (card) => {
      switch (card.type) {
        case ComponentTypes.UNIT_HOURS_CURBSIDE:
          return { key: 'unitHoursCurbside', element: (<UnitHoursCurbside selectedContext={this.props.dfp}/>)};
        case ComponentTypes.UNIT_HOURS_ROADSIDE:
          return { key: 'unitHoursRoadside', element: (<UnitHoursRoadside selectedContext={this.props.dfp}/>)};
        case ComponentTypes.UNIT_ACTIVITY_CURBSIDE:
          return { key: 'unitActivityCurbside', element: (<UnitActivityCurbside cardKey='unitActivityCurbside' dashboard={ComponentTypes.DFP_DASHBOARD} view={viewConfiguration.id} context={this.props.dfp} editMode={this.props.editMode}/>)};
        case ComponentTypes.UNIT_ACTIVITY_ROADSIDE:
          return { key: 'unitActivityRoadside', element: (<UnitActivityRoadside cardKey='unitActivityRoadside' dashboard={ComponentTypes.DFP_DASHBOARD} view={viewConfiguration.id} context={this.props.dfp} editMode={this.props.editMode}/>)};
        case ComponentTypes.UNIT_CHART_ROADSIDE_PRESSURE_HISTORY:
          return { key: 'unitPressureHistoryRoadside', element: (<UnitChartPressureHistoryRoadside cardKey='unitPressureHistoryRoadside' dashboard={ComponentTypes.DFP_DASHBOARD} view={viewConfiguration.id} context={{...this.props.dfp, startTime:startTime, endTime:endTime, showSuction:true}} editMode={this.props.editMode}/>)};
        case ComponentTypes.UNIT_CHART_CURBSIDE_PRESSURE_HISTORY:
          return { key: 'unitPressureHistoryCurbside', element: (<UnitChartPressureHistoryCurbside cardKey='unitPressureHistoryCurbside' dashboard={ComponentTypes.DFP_DASHBOARD} view={viewConfiguration.id} context={{...this.props.dfp, startTime:startTime, endTime:endTime, showSuction:true}} editMode={this.props.editMode}/>)};
        case ComponentTypes.UNIT_FLEET:
          return { key: 'unitFleet', element: (<UnitFleet selectedContext={this.props.dfp}/>)};
        case ComponentTypes.COMPONENT_LIFE_CURBSIDE:
          return { key: 'componentLifeCurbside', element: (<ComponentLifeCurbside selectedContext={this.props.dfp}/>)};
        case ComponentTypes.COMPONENT_LIFE_ROADSIDE:
          return { key: 'componentLifeRoadside', element: (<ComponentLifeRoadside selectedContext={this.props.dfp}/>)};
        case ComponentTypes.UNIT_ALARM_COUNT:
          return { key: 'pumpAlarm', element: (<UnitAlarmCount selectedContext={this.props.dfp}/>)};
        default:
          return { key: 'unknown', element: (<div/>)};
      }
    });

    return (
      <LayoutFixedWidth layout={cardsLayout} cols={9} rowHeight={160} width={1540}>
        {
          cardElements.map((card) => {
            return (
              <div key={card.key}>{card.element}</div>
            )
          })
        }
      </LayoutFixedWidth>
    )
  }
}

DashboardView.propTypes = {
  dfp: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    configuration: displayConfigurationState(state, ComponentTypes.DFP_DASHBOARD),
  }
};

export default (connect(mapStateToProps)(DashboardView));
