export default {
  AUDIT_LOGS_QUERY_AUDIT_LOGS_STARTING: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_STARTING',
  AUDIT_LOGS_QUERY_AUDIT_LOGS_SUCCESS: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_SUCCESS',
  AUDIT_LOGS_QUERY_AUDIT_LOGS_ERROR: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_ERROR',
  
  AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_STARTING: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_STARTING',
  AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_SUCCESS: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_SUCCESS',
  AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_ERROR: 'AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_ERROR',
  
  AUDIT_LOGS_SET_QUERY: 'AUDIT_LOGS_SET_QUERY',
  AUDIT_LOGS_LOAD_DEFAULT_QUERY: 'AUDIT_LOGS_LOAD_DEFAULT_QUERY',
  AUDIT_LOGS_CLEAR_AUDIT_LOGS: 'AUDIT_LOGS_CLEAR_AUDIT_LOGS',
  AUDIT_LOGS_SET_PAGE: 'AUDIT_LOGS_SET_PAGE',
  AUDIT_LOGS_SET_ROWS_PER_PAGE:'AUDIT_LOGS_SET_ROWS_PER_PAGE',
  AUDIT_LOGS_SET_SORT_MODEL: 'AUDIT_LOGS_SET_SORT_MODEL',

  AUDIT_LOGS_SET_TIME_FRAME: 'AUDIT_LOGS_SET_TIME_FRAME',
  AUDIT_LOGS_SET_CUSTOM_START_TIME: 'AUDIT_LOGS_SET_CUSTOM_START_TIME',
  AUDIT_LOGS_SET_CUSTOM_DURATION: 'AUDIT_LOGS_SET_CUSTOM_DURATION',
  AUDIT_LOGS_SET_CUSTOM_START_TIME_DISPLAY: 'AUDIT_LOGS_SET_CUSTOM_START_TIME_DISPLAY',

  AUDIT_LOGS_EXPAND_QUERY_PANEL: 'AUDIT_LOGS_EXPAND_QUERY_PANEL',
}