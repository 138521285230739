import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';
import warmStartActionTypes from "../actionTypes/warmStartActionTypes";
import * as warmStartQueries from '../queries/warmStartQueries';
import { handleError } from '../../../app/actions/appErrorActions';

const setAverageFuelCost = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_SET_AVERAGE_FUEL_COST, 'stateDef', 'averageFuelCost');

const revertForm = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_REVERT_FORM, 'stateDef');

const warmStartQueryDataStarting = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_QUERY_STARTING, 'stateDef');
const warmStartQueryDataSuccess = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_QUERY_SUCCESS, 'stateDef', 'queryResults');
const warmStartQueryDataError = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_QUERY_ERROR, 'stateDef');

const queryWarmStartSettings = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(warmStartQueryDataStarting(stateDef));
      queryResults = await warmStartQueries.fetchWarmStartSettings();
    }catch(e) {
      await dispatch(warmStartQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_WARM_START_SETTINGS, e.message));
    }
    await dispatch(warmStartQueryDataSuccess(stateDef, queryResults));
  }
};

const warmStartTruckQueryDataStarting = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_STARTING, 'stateDef');
const warmStartTruckQueryDataSuccess = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_SUCCESS, 'stateDef', 'queryResults');
const warmStartTruckQueryDataError = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_QUERY_ERROR, 'stateDef');

const queryTruckWarmStartSettings = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(warmStartTruckQueryDataStarting(stateDef));
      queryResults = await warmStartQueries.fetchTruckWarmStartSettings();
    }catch(e) {
      await dispatch(warmStartTruckQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_WARM_START_TRUCK_SETTINGS, e.message));
    }
    await dispatch(warmStartTruckQueryDataSuccess(stateDef, queryResults));
  }
};

const saveWarmStartSettingsStarting = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_SAVE_STARTING, 'stateDef');
const saveWarmStartSettingsSuccess = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_SAVE_SUCCESS, 'stateDef', 'queryResults');
const saveWarmStartSettingsError = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_SAVE_ERROR, 'stateDef');

const saveWarmStartSettings = (stateDef, averageFuelCost) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(saveWarmStartSettingsStarting(stateDef));
      queryResults = await warmStartQueries.fetchSaveWarmStartSettings(averageFuelCost);
    }catch(e) {
      await dispatch(saveWarmStartSettingsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_WARM_START_SETTINGS, e.message));
    }
    await dispatch(saveWarmStartSettingsSuccess(stateDef, queryResults));
    return dispatch(queryWarmStartSettings(stateDef));
  }
};

const saveWarmStartTruckSettingsStarting = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_STARTING, 'stateDef');
const saveWarmStartTruckSettingsSuccess = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_SUCCESS, 'stateDef', 'truckId', 'averageFuelConsumption');
const saveWarmStartTruckSettingsError = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_TRUCK_SAVE_ERROR, 'stateDef');

const saveWarmStartTruckSettings = (stateDef, truckId, averageFuelConsumption) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(saveWarmStartTruckSettingsStarting(stateDef));
      await warmStartQueries.fetchSaveTruckWarmStartSettings(truckId, averageFuelConsumption);
    }catch(e) {
      await dispatch(saveWarmStartTruckSettingsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_WARM_START_SETTINGS, e.message));
    }
    await dispatch(saveWarmStartTruckSettingsSuccess(stateDef,truckId, averageFuelConsumption));
    return;
  }
};

const dataGridSetRowSize = defaultActionFactory(warmStartActionTypes.SETTINGS_WARM_START_SET_ROW_SIZE, 'stateDef', 'rowSize');

export {
  queryWarmStartSettings,
  queryTruckWarmStartSettings,
  saveWarmStartSettings,
  revertForm,
  setAverageFuelCost,
  saveWarmStartTruckSettings,
  dataGridSetRowSize
};