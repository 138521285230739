import _ from 'lodash';

const fetchPumpOnlyFleetMapComponentDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = _.find(simData, {'id': fleetId});
      resolve({
        componentFleetMap: {
          componentViewData: {
            ...fleetData.componentSimData
          }
        }
      })
    }, 1000)
  });
};

const fetchPumpOnlyFleetMapHealthDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = _.find(simData, {'id': fleetId});
      resolve({
        healthFleetMap: {
          healthViewData: {
            ...fleetData.healthSimData
          }
        }
      })
    }, 1000)
  });
};

const fetchPumpOnlyFleetMapOperationDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = _.find(simData, {'id': fleetId});
      resolve({
        operationalFleetMap: {
          operationViewData: {
            ...fleetData.operationSimData
          }
        }
      })
    }, 1000)
  });
};

const sandCleanRate = _.random(3800, 9200); // gal/min 
const sandSelectedMassFlow = _.random(3800, 9200); // lbs/min (rate * conc = flow)
const sandMassConc = (sandSelectedMassFlow/sandCleanRate); // lbs/gal (flow / rate = conc)
const sandSelectedSetPoint = _.random(3800, 9200); // lbs/min

const simData = [
  {
    id:21,
    name:'WTX-7',
    operationSimData: {
      blender1: { id:333, name: '780333', alarmCounts: { critical: 0, warning: 0 } },
      blender2: { id:334, name: '780334', alarmCounts: { critical: 0, warning: 0 } },
      hydration: { id:335, name: '780335', alarmCounts: { critical: 0, warning: 0 } },
      chemvan: { id:336, name: '980336', alarmCounts: { critical: 0, warning: 0 } },
      datavan: { id:337, name: '789337',
        totalSand: 541387,
        totalDischarge: 6872
      },
      sand1: (_.random(1,20) % 7 === 0 ? null : {
        id: 101200, name: '550002',
        cleanRate: (_.random(1,20) % 5 === 0 ? null : sandCleanRate),
        massConc: (_.random(1,20) % 5 === 0 ? null : sandMassConc),
        selectedMassFlow: (_.random(1,20) % 5 === 0 ? null : sandSelectedMassFlow) ,
        selectedSetPoint: (_.random(1,20) % 5 === 0 ? null : sandSelectedSetPoint)
      }),
      sand2: (_.random(1,20) % 7 === 0 ? null : {
        id: 101200, name: '550002',
        cleanRate: (_.random(1,20) % 5 === 0 ? null : sandCleanRate),
        massConc: (_.random(1,20) % 5 === 0 ? null : sandMassConc),
        selectedMassFlow: (_.random(1,20) % 5 === 0 ? null : sandSelectedMassFlow) ,
        selectedSetPoint: (_.random(1,20) % 5 === 0 ? null : sandSelectedSetPoint)
      }),
      wellHead: { flowRate: 52.8, pressure: 9586, density1: 99.3, density2: 99.2 },
      pumps: [
        { id: 513, name: '620263', slot: 1,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 538, name: '620254', slot: 2,
          suction: { value: 64, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 900,
          gear: '4',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 660, name: '620521', slot: 3,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 200,
          gear: 'N',
          rate: { value: 5.3, tripped: true, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 350, name: '620484', slot: 4,
          suction: { value: 66, total: 100 },
          hhp: { value: 2750, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '5',
          rate: { value: 4.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 354, name: '620587', slot: 5,
          suction: { value: 65, total: 100 },
          hhp: { value: 600, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '2',
          rate: { value: 2.9, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 357, name: '620575', slot: 6,
          suction: { value: 63, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 150,
          gear: 'N',
          rate: { value: 5.3, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 122, name: '620122', slot: 7, empty: true },
        { id: 123, name: '620123', slot: 8, empty: true },
        { id: 358, name: '620505', slot: 9,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '3',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 359, name: '620393', slot: 10,
          suction: { value: 64, total: 100 },
          hhp: { value: 1900, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 360, name: '620496', slot: 11,
          suction: { value: 63, total: 100 },
          hhp: { value: 2145, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '5',
          rate: { value: 4.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 361, name: '620410', slot: 12,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '3',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 363, name: '620406', slot: 13,
          suction: { value: 65, total: 100 },
          hhp: { value: 1200, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 600,
          gear: '2',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 129, name: '620129', slot: 14, empty: true },
        { id: 396, name: '620477', slot: 15,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 0, total: 15000 },
          rpm: 0,
          gear: null,
          rate: { value: null, tripped: false, idle: false, stopped: true },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 418, name: '620588', slot: 16,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 400,
          gear: 'N',
          rate: { value: 4.0, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 132, name: '620132', slot: 17, empty: true },
        { id: 419, name: '620189', slot: 18,
          suction: { value: 65, total: 100 },
          hhp: { value: 725, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '2',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 420, name: '620188', slot: 19,
          suction: { value: 65, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 5.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 505, name: '620453', slot: 20,
          suction: { value: 64, total: 100 },
          hhp: { value: 2500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1300,
          gear: '4',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 513, name: '620263', slot: 21,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 538, name: '620254', slot: 22,
          suction: { value: 64, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 900,
          gear: '4',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 660, name: '620521', slot: 23,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 200,
          gear: 'N',
          rate: { value: 5.3, tripped: true, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 350, name: '620484', slot: 24,
          suction: { value: 66, total: 100 },
          hhp: { value: 2750, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '5',
          rate: { value: 4.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 354, name: '620587', slot: 25,
          suction: { value: 65, total: 100 },
          hhp: { value: 600, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '2',
          rate: { value: 2.9, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 357, name: '620575', slot: 26,
          suction: { value: 63, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 150,
          gear: 'N',
          rate: { value: 5.3, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 122, name: '620122', slot: 27, empty: true },
        { id: 123, name: '620123', slot: 28, empty: true },
        { id: 358, name: '620505', slot: 29,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '3',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 359, name: '620393', slot: 30,
          suction: { value: 64, total: 100 },
          hhp: { value: 1900, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 360, name: '620496', slot: 31,
          suction: { value: 63, total: 100 },
          hhp: { value: 2145, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '5',
          rate: { value: 4.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 361, name: '620410', slot: 32,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '3',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 363, name: '620406', slot: 33,
          suction: { value: 65, total: 100 },
          hhp: { value: 1200, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 600,
          gear: '2',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 129, name: '620129', slot: 34, empty: true },
        { id: 396, name: '620477', slot: 35,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 0, total: 15000 },
          rpm: 0,
          gear: null,
          rate: { value: null, tripped: false, idle: false, stopped: true },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 418, name: '620588', slot: 36,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 400,
          gear: 'N',
          rate: { value: 4.0, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 132, name: '620132', slot: 37, empty: true },
        { id: 419, name: '620189', slot: 38,
          suction: { value: 65, total: 100 },
          hhp: { value: 725, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '2',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 420, name: '620188', slot: 39,
          suction: { value: 65, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 5.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 505, name: '620453', slot: 40,
          suction: { value: 64, total: 100 },
          hhp: { value: 2500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1300,
          gear: '4',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    componentSimData: {
      blender1: { id:333, name: '780333', alarmCounts: { critical: 0, warning: 0 } },
      blender2: { id:334, name: '780334', alarmCounts: { critical: 0, warning: 0 } },
      hydration: { id:335, name: '780335', alarmCounts: { critical: 0, warning: 0 } },
      chemvan: { id:336, name: '980336', alarmCounts: { critical: 0, warning: 0 } },
      datavan: { id:337, name: '789337' },
      pumps: [
        { id: 338, name: '680338', slot: 1,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 339, name: '680339', slot: 2,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 340, name: '680340', slot: 3,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 341, name: '680341', slot: 4,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 342, name: '680342', slot: 5,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 343, name: '680343', slot: 6,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 344, name: '680344', slot: 7,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 345, name: '680345', slot: 8,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 346, name: '680346', slot: 9,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 347, name: '680347', slot: 10,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 348, name: '680348', slot: 11,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 349, name: '680349', slot: 12,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 350, name: '680350', slot: 13,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 351, name: '680351', slot: 14,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 352, name: '680352', slot: 15,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 353, name: '680353', slot: 16,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 354, name: '680354', slot: 17,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 355, name: '680355', slot: 18,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 356, name: '680356', slot: 19,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 357, name: '680357', slot: 20,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 338, name: '680338', slot: 21,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 339, name: '680339', slot: 22,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 340, name: '680340', slot: 23,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 341, name: '680341', slot: 24,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 342, name: '680342', slot: 25,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 343, name: '680343', slot: 26,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 344, name: '680344', slot: 27,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 345, name: '680345', slot: 28,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 346, name: '680346', slot: 29,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 347, name: '680347', slot: 30,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 348, name: '680348', slot: 31,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 349, name: '680349', slot: 32,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 350, name: '680350', slot: 33,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 351, name: '680351', slot: 34,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 352, name: '680352', slot: 35,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 353, name: '680353', slot: 36,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 354, name: '680354', slot: 37,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 355, name: '680355', slot: 38,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 356, name: '680356', slot: 39,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 357, name: '680357', slot: 40,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    healthSimData: {
      blender1: { id:333, name: '780333', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      blender2: { id:334, name: '780334', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      hydration: { id:335, name: '780335', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      chemvan: { id:336, name: '980336', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      datavan: { id:337, name: '789337' },
      pumps: [
        { id: 338, name: '680338', slot: 1,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 339, name: '680339', slot: 2,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 340, name: '680340', slot: 3,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 341, name: '680341', slot: 4,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 342, name: '680342', slot: 5,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 343, name: '680343', slot: 6,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 344, name: '680344', slot: 7,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 345, name: '680345', slot: 8,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 346, name: '680346', slot: 9,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 347, name: '680347', slot: 10,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 348, name: '680348', slot: 11,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 349, name: '680349', slot: 12,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 350, name: '680350', slot: 13,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 351, name: '680351', slot: 14,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 352, name: '680352', slot: 15,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 353, name: '680353', slot: 16,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 354, name: '680354', slot: 17,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 355, name: '680355', slot: 18,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 356, name: '680356', slot: 19,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 357, name: '680357', slot: 20,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 338, name: '680338', slot: 21,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 339, name: '680339', slot: 22,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 340, name: '680340', slot: 23,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 341, name: '680341', slot: 24,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 342, name: '680342', slot: 25,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 343, name: '680343', slot: 26,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 344, name: '680344', slot: 27,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 345, name: '680345', slot: 28,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 346, name: '680346', slot: 29,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 347, name: '680347', slot: 30,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 348, name: '680348', slot: 31,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 349, name: '680349', slot: 32,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 350, name: '680350', slot: 33,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 351, name: '680351', slot: 34,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 352, name: '680352', slot: 35,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 353, name: '680353', slot: 36,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 354, name: '680354', slot: 37,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 355, name: '680355', slot: 38,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 356, name: '680356', slot: 39,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 357, name: '680357', slot: 40,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
      ],
      missile: [
        {
          slot: 1,
          value: 2.8
        },
        {
          slot: 2,
          value: 2.8
        },
        {
          slot: 3,
          value: 4.8
        },
        {
          slot: 4,
          value: 2.8
        },
        {
          slot: 5,
          value: 2.8
        },
        {
          slot: 6,
          value: 2.8
        },
        {
          slot: 7,
          value: 2.8
        },
        {
          slot: 9,
          value: 2.8
        },
        {
          slot: 10,
          value: 2.8
        },
        {
          slot: 11,
          value: 2.8
        },
        {
          slot: 12,
          value: 2.8
        },
        {
          slot: 13,
          value: 22.8
        },
        {
          slot: 14,
          value: 2.8
        },
        {
          slot: 15,
          value: 2.8
        },
        {
          slot: 16,
          value: 2.8
        },
        {
          slot: 17,
          value: 2.8
        },
        {
          slot: 18,
          value: 2.8
        },
        {
          slot: 19,
          value: 2.8
        },
        {
          slot: 20,
          value: 2.8
        },
        {
          slot: 21,
          value: 2.8
        },
        {
          slot: 22,
          value: 2.8
        },
        {
          slot: 23,
          value: 4.8
        },
        {
          slot: 24,
          value: 2.8
        },
        {
          slot: 25,
          value: 2.8
        },
        {
          slot: 26,
          value: 2.8
        },
        {
          slot: 27,
          value: 2.8
        },
        {
          slot: 29,
          value: 2.8
        },
        {
          slot: 30,
          value: 2.8
        },
        {
          slot: 31,
          value: 2.8
        },
        {
          slot: 32,
          value: 2.8
        },
        {
          slot: 33,
          value: 22.8
        },
        {
          slot: 34,
          value: 2.8
        },
        {
          slot: 35,
          value: 2.8
        },
        {
          slot: 36,
          value: 2.8
        },
        {
          slot: 37,
          value: 2.8
        },
        {
          slot: 38,
          value: 2.8
        },
        {
          slot: 39,
          value: 2.8
        },
        {
          slot: 40,
          value: 2.8
        }
      ]
    }
  },
  {
    id:12,
    name:'MC-2',
    operationSimData: {
      blender1: { id:444, name: '710444', alarmCounts: { critical: 0, warning: 0 } },
      blender2: { id:445, name: '710445', alarmCounts: { critical: 0, warning: 0 } },
      hydration: { id:446, name: '710446', alarmCounts: { critical: 0, warning: 0 } },
      chemvan: { id:447, name: '910447', alarmCounts: { critical: 0, warning: 0 } },
      datavan: { id:448, name: '710448',
        totalSand: 465921,
        totalDischarge: 4981
      },
      sand: (_.random(1,20) % 7 === 0 ? null : {
        id: 101200, name: '710448',
        cleanRate: (_.random(1,20) % 5 === 0 ? null : sandCleanRate), 
        massConc: (_.random(1,20) % 5 === 0 ? null : sandMassConc), 
        selectedMassFlow: (_.random(1,20) % 5 === 0 ? null : sandSelectedMassFlow) 
      }),
      wellHead: { flowRate: 52.8, pressure: 9586, density1: 99.3, density2: 99.2 },
      pumps: [
        { id: 449, name: '610449', slot: 1,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 450, name: '610450', slot: 2,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 451, name: '610451', slot: 3,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 452, name: '610452', slot: 4,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 453, name: '610453', slot: 5,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 454, name: '610454', slot: 6,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 455, name: '610455', slot: 7,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 456, name: '610456', slot: 8,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 457, name: '610457', slot: 9,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 458, name: '610458', slot: 10,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 459, name: '610459', slot: 11,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 460, name: '610460', slot: 12,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 461, name: '610461', slot: 13,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 462, name: '610462', slot: 14,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 463, name: '610463', slot: 15, empty: true },
        { id: 464, name: '610464', slot: 16,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 465, name: '610465', slot: 17,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 466, name: '610466', slot: 18,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 467, name: '610467', slot: 19,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 468, name: '610468', slot: 20,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 449, name: '610449', slot: 21,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 450, name: '610450', slot: 22,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 451, name: '610451', slot: 23,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 452, name: '610452', slot: 24,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 453, name: '610453', slot: 25,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 454, name: '610454', slot: 26,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 455, name: '610455', slot: 27,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 456, name: '610456', slot: 28,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 457, name: '610457', slot: 29,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 458, name: '610458', slot: 30,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 459, name: '610459', slot: 31,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 460, name: '610460', slot: 32,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 461, name: '610461', slot: 33,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 462, name: '610462', slot: 34,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 463, name: '610463', slot: 35, empty: true },
        { id: 464, name: '610464', slot: 16,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 465, name: '610465', slot: 37,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 466, name: '610466', slot: 38,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 467, name: '610467', slot: 39,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 468, name: '610468', slot: 40,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    componentSimData: {
      blender1: { id:444, name: '710444', alarmCounts: { critical: 0, warning: 0 } },
      blender2: { id:445, name: '710445', alarmCounts: { critical: 0, warning: 0 } },
      hydration: { id:446, name: '710446', alarmCounts: { critical: 0, warning: 0 } },
      chemvan: { id:447, name: '910447', alarmCounts: { critical: 0, warning: 0 } },
      datavan: { id:448, name: '710448' },
      pumps: [
        { id: 449, name: '610449', slot: 1,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 450, name: '610450', slot: 2,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 451, name: '610451', slot: 3,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 452, name: '610452', slot: 4,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 453, name: '610453', slot: 5,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 454, name: '610454', slot: 6,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 455, name: '610455', slot: 7,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 456, name: '610456', slot: 8,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 457, name: '610457', slot: 9,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 458, name: '610458', slot: 10,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 459, name: '610459', slot: 11,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 460, name: '610460', slot: 12,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 461, name: '610461', slot: 13,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 462, name: '610462', slot: 14,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 463, name: '610463', slot: 15,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 464, name: '610464', slot: 16,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 465, name: '610465', slot: 17,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 466, name: '610466', slot: 18,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 467, name: '610467', slot: 19,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 468, name: '610468', slot: 20,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 449, name: '610449', slot: 21,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 450, name: '610450', slot: 22,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 451, name: '610451', slot: 23,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 452, name: '610452', slot: 24,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 453, name: '610453', slot: 25,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 454, name: '610454', slot: 26,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 455, name: '610455', slot: 27,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 456, name: '610456', slot: 28,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 457, name: '610457', slot: 29,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 458, name: '610458', slot: 30,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 459, name: '610459', slot: 31,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 460, name: '610460', slot: 32,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 461, name: '610461', slot: 33,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 462, name: '610462', slot: 34,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 463, name: '610463', slot: 35,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 464, name: '610464', slot: 36,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 465, name: '610465', slot: 37,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 466, name: '610466', slot: 38,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 467, name: '610467', slot: 39,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 468, name: '610468', slot: 40,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    healthSimData: {
      blender1: { id:444, name: '710444', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      blender2: { id:445, name: '710445', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      hydration: { id:446, name: '710446', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      chemvan: { id:447, name: '910447', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      datavan: { id:448, name: '710448' },
      pumps: [
        { id: 449, name: '610449', slot: 1,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 450, name: '610450', slot: 2,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 451, name: '610451', slot: 3,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 452, name: '610452', slot: 4,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 453, name: '610453', slot: 5,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 454, name: '610454', slot: 6,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 455, name: '610455', slot: 7,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 456, name: '610456', slot: 8,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 457, name: '610457', slot: 9,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 458, name: '610458', slot: 10,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 459, name: '610459', slot: 11,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 460, name: '610460', slot: 12,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 461, name: '610461', slot: 13,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 462, name: '610462', slot: 14,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 463, name: '610463', slot: 15, empty: true },
        { id: 464, name: '610464', slot: 16,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 465, name: '610465', slot: 17,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 466, name: '610466', slot: 18,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 467, name: '610467', slot: 19,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 468, name: '610468', slot: 20,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 449, name: '610449', slot: 21,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 450, name: '610450', slot: 22,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 451, name: '610451', slot: 23,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 452, name: '610452', slot: 24,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 453, name: '610453', slot: 25,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 454, name: '610454', slot: 26,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 455, name: '610455', slot: 27,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 456, name: '610456', slot: 28,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 457, name: '610457', slot: 29,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 458, name: '610458', slot: 30,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 459, name: '610459', slot: 31,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 460, name: '610460', slot: 32,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 461, name: '610461', slot: 33,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 462, name: '610462', slot: 34,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 463, name: '610463', slot: 35, empty: true },
        { id: 464, name: '610464', slot: 36,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 465, name: '610465', slot: 37,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 466, name: '610466', slot: 38,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 467, name: '610467', slot: 39,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 468, name: '610468', slot: 40,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
      ],
      missile: [
      ]
    }
  },
];


export {
  fetchPumpOnlyFleetMapComponentDetails,
  fetchPumpOnlyFleetMapHealthDetails,
  fetchPumpOnlyFleetMapOperationDetails
}
