import reduceReducers from 'reduce-reducers';

import equipmentReducer from './reducers/equipmentReducer';
import filterReducer from './reducers/filterReducer';
import tableReducer from './reducers/tableReducer';
import exportCsvReducer from '../../common/exportCsv/exportCsvReducer';

import { equipmentListState } from './equipmentListSelectors';

const initialState = equipmentListState();

const equipmentListReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    equipmentReducer,
    filterReducer,
    tableReducer,
    exportCsvReducer
  );

  return reducer(state, action);
};

export default equipmentListReducer
