import _ from 'lodash';
import unitChartDfpTypes from './unitHoursDfpTypes';

const fetchUnitHoursDfpCardData = (chartType) => {

  return new Promise((resolve) => {
    setTimeout(() => {

      const data = {
        ecmHours: _.random(1000, 10000),
        pumpHours: _.random(1000, 10000),
      };

      if (chartType === unitChartDfpTypes.CURBSIDE) {
        resolve({ unitHoursCurbside: data });
      } else {
        resolve({ unitHoursRoadside: data });
      }
    }, 1000)
  });

};

export { fetchUnitHoursDfpCardData }