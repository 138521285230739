import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import { searchState } from '../../common/search/searchSelectors';

const assetTrackingState = state => {

  let filterDetails = filterState(state);
  let searchDetails = searchState(state);

  let defaultLocationTypes = [
    {
      value: "",
      label: "Set Location Type"
    },
    {
      value: "yard",
      label: "Yard"
    },
    {
      value: "shop",
      label: "Shop"
    },
    {
      value: "testStand",
      label: "Test Stand"
    }

  ];

  return {
    ...filterDetails,
    ...searchDetails,
    queryRunning: _.isNil(state) ? false : state.queryRunning, 
    assets: _.isNil(state) ? [] : state.assets,
    filteredAssets:  _.isNil(state) ? [] : state.filteredAssets,
    editMode: _.isNil(state) ? false : state.editMode,

    locations: _.isNil(state) ? [] : state.locations,
    locationTypes: defaultLocationTypes,

    canSave: _.isNil(state) ? false : state.canSave,
    refreshTimestamp: _.isNil(state) ? null : state.refreshTimestamp,

    showInActive: _.isNil(state) ? false : state.showInActive,
  };
  
};

export {
  assetTrackingState
}