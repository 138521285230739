import _ from 'lodash';

const fetchFleetComponents = () => {
  return new Promise((resolve) => {

    // Randomize the length of the simulation query so all the cards do not complete their
    // queries at once
    let queryDuration = _.random(500, 3000);

    setTimeout(() => {
      resolve({
        fleetComponents: generateSimData()
      })
    }, queryDuration)
  });
};

const generateSimData = () => {

  const componentList = [
    { name: 'engine', displayName: 'Engine', },
    { name: 'engineOil', displayName: 'Engine Oil', },
    { name: 'fluidEnd', displayName: 'Fluid End', },
    { name: 'powerEnd', displayName: 'Power End', },
    { name: 'powerEndLube', displayName: 'Power End Lube', },
    { name: 'transmission', displayName: 'Transmission', },
    { name: 'hydraulicEngine', displayName: 'Hydraulic Engine' },
  ];

  let components = [];

  let count = _.random(4, 7);
  _.times(count, () => {

    let component = _.sample(componentList);
    while (_.findIndex(components, ['name', component.name]) >= 0) {
      component = _.sample(componentList);
    }

    let noData = _.random(0, 5) === 0;
    components.push({
      ...component,
      good: noData ? 0 : _.random(0, 100),
      aging: noData ? 0 : _.random(0, 100),
      replace: noData ? 0 : _.random(0, 100),
      unknown: noData ? 0 : _.random(0, 100),
    });
  });

  return components;
};


export {
  fetchFleetComponents,
}
