import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { jobOverviewState } from './jobOverviewSelectors';
import jobOverviewActionTypes from './jobOverviewActionTypes';
import {
  filterJobs,
  processJob,
  processJobForSelection,
  processEventLogs,
  prettifyStatus,
  processStageDefinition,
  processJobs,
  resolveSelectedJob,
  flattenStageForCompare,
  processStages
} from './services/jobOverviewServices';
import * as jobStatusConstants from '../../../components/common/jobStatusConstants';
import filterReducer from './reducers/filterReducer';
import reduceReducers from "reduce-reducers";

import ComponentTypes from '../../../components/componentTypes';

const initialState = jobOverviewState();

const displayReducer = (state=initialState, action) => {

  switch (action.type) {

    case jobOverviewActionTypes.QUERY_JOBS_DATA_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.QUERY_JOBS_DATA_ERROR:
      return { ...state, queryRunning: false };
    case jobOverviewActionTypes.QUERY_JOBS_DATA_SUCCESS:
      return onQueryJobsDataSuccess(state, action);

    case jobOverviewActionTypes.QUERY_JOB_DATA_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.QUERY_JOB_DATA_ERROR:
      return { ...state, queryRunning: false };
    case jobOverviewActionTypes.QUERY_JOB_DATA_SUCCESS:
      return onQueryJobDataSuccess(state, action);

    case jobOverviewActionTypes.DIALOG_SHOW:
      return onDialogShow(state, action);
    case jobOverviewActionTypes.DIALOG_SET_TYPE:
      return onDialogSetType(state, action);
    case jobOverviewActionTypes.DIALOG_SET_NOTES:
      return onDialogSetNotes(state, action);
    case jobOverviewActionTypes.DIALOG_SUBMIT:
      return onDialogSubmit(state, action);

    case jobOverviewActionTypes.QUERY_EVENT_LOGS_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.QUERY_EVENT_LOGS_ERROR:
      return { ...state, queryRunning: false };
    case jobOverviewActionTypes.QUERY_EVENT_LOGS_SUCCESS:
      return onQueryEventLogsSuccess(state, action);

    case jobOverviewActionTypes.SAVE_JOB_STATUS_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.SAVE_JOB_STATUS_ERROR:
      return { ...state, queryRunning: false };
    case jobOverviewActionTypes.SAVE_JOB_STATUS_SUCCESS:
        return onSaveJobStatusSuccess(state, action);

    case jobOverviewActionTypes.QUERY_STAGE_DATA_SUCCESS:
      return onQueryStageDataSuccess(state, action);
    case jobOverviewActionTypes.QUERY_STAGE_DATA_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.QUERY_STAGE_DATA_ERROR:
      return { ...state, queryRunning: false };

    case jobOverviewActionTypes.QUERY_STAGE_DEFINITION_SUCCESS:
      return onQueryStageDefinitionSuccess(state, action);   
    case jobOverviewActionTypes.QUERY_STAGE_DEFINITION_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.QUERY_STAGE_DEFINITION_ERROR:
      return { ...state, queryRunning: false };

    case jobOverviewActionTypes.SELECT_STAGE:
      return onSelectStage(state, action);
    case jobOverviewActionTypes.SELECT_UNIT_INDEX:
      return onSelectUnitIndex(state, action);

    case jobOverviewActionTypes.SHOW_STAGE_VIEWER:
      return onShowStageViewer(state, action);
    case jobOverviewActionTypes.SHOW_EVENT_HISTORY_VIEWER:
      return onShowEventHistoryViewer(state, action);

    case jobOverviewActionTypes.SAVE_STAGE_STATUS_STARTING:
      return { ...state, queryRunning: true };
    case jobOverviewActionTypes.SAVE_STAGE_STATUS_ERROR:
      return { ...state,
        queryRunning: false,
        dialogType: null // reset dialog type on status update failure, avoid unexpected rendering on approvalDialog
      };
    case jobOverviewActionTypes.SAVE_STAGE_STATUS_SUCCESS:
      return onSaveStageStatusSuccess(state, action);

    case jobOverviewActionTypes.SELECT_VIEW:
      return onSelectView(state, action);
    case jobOverviewActionTypes.CREATE_CHART_FOR_STAGE:
      return onCreateChartForStage(state, action);
    case jobOverviewActionTypes.UPDATE_CHARTS_LAYOUT:
      return onUpdateChartsLayout(state, action);
    case jobOverviewActionTypes.REMOVE_CHART:
      return onRemoveChart(state, action);
    case jobOverviewActionTypes.REMOVE_ALL_CHARTS:
      return { ...state, charts: [] };

    default:
      return state;
  }

};

const jobOverviewReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
      displayReducer,
      filterReducer
  );

  return reducer(state, action);
};

const onQueryJobsDataSuccess = (state, action) => {

  const jobs = action.queryResults.getJobsForOwner;
  let selectedJob = state.selectedJob;
  if(!_.isEmpty(jobs) && _.isNil(selectedJob)){
    selectedJob = processJobForSelection(jobs[0]); // default to select first job in jobs list
  }

  let filteredJobs = filterJobs(jobs, state.appliedFilters);

  let resolvedSelectedJob = resolveSelectedJob(
    filteredJobs.filteredJobs,
    _.isNil(selectedJob) ? null : selectedJob.value
  );

  return {
    ...state,
    queryRunning: false,
    jobs: processJobs(jobs),
    filters: filteredJobs.filters,
    selectedJob: resolvedSelectedJob.selectedJob,
    selectedJobInfo: resolvedSelectedJob.selectedJobInfo,
    filteredJobs: processJobs(filteredJobs.filteredJobs),
  }

}

const onQueryJobDataSuccess = (state, action) => {

  const job = action.queryResults.job;
  const jobForSelection =  processJobForSelection(job);
  const jobInfo = processJob(job);

  return {
    ...state,
    queryRunning: false,
    selectedJob: jobForSelection,
    selectedJobInfo: jobInfo,
    //reset selected stage on job data reload.
    // e.g. when select a different job it should not keep old stage selected.
    selectedStage: null,
    selectedStageDefinition: {}
  }

}

const onDialogShow = (state, action) => {

  return {
    ...state,
    showDialog: action.show,
    notes: '',
  }

}

const onDialogSetType = (state, action) => {

  return {
    ...state,
    dialogType: action.dialogType,
  }

}

const onDialogSetNotes = (state, action) => {

  return {
    ...state,
    notes: action.value
  }

}

const onDialogSubmit = (state, action) => {

  return {
    ...state,
    showDialog: false,
    dialogContext: null
  }

}

const onQueryEventLogsSuccess = (state, action) => {

  // These are the event logs we got back for our given job id
  const eventLogs = action.queryResults.getEventsForJob;
  
  return {
    ...state,
    eventLogs: processEventLogs(eventLogs),
    queryRunning: false,
  }

}

const onSaveJobStatusSuccess = (state, action) => {

  // Make a working copy of the selected job
  const newJobsState = _.cloneDeep(state.selectedJob);

  // Update the job
  newJobsState.status = prettifyStatus(action.queryResults.saveJobStatus.status);
  newJobsState.lastModified = action.queryResults.saveJobStatus.lastModified;

  return {
    ...state,
    queryRunning: false,
    selectedJob: newJobsState,
    selectedJobInfo: newJobsState,
    dialogType: null // reset dialog type on status update success, avoid unexpected rendering on approvalDialog
  }

}

const onQueryStageDataSuccess = (state, action) => {

  // The sort here matches what is done on the component level since we need the 'scroll to selected row' to work properly
  // Using a padStart to make sure the sort works properly for stages 1 to 999
  // "Created On" is the "timestamp" of the Stage and can possibly be null 
  let stages = _.orderBy(action.queryResults.allStagesForJob, ((stage) => { return (stage.id + '').padStart(3, '0') + '_' + stage.createdOn; }), ['desc'])

  // Select the first Stage that is in Request
  const stageRequestIndex = _.findIndex(stages, ['status', jobStatusConstants.STAGE_APPROVAL_REQUEST]);

  const flattenedStagesForCompare = [];

  if (stages.length > 0) {
    processStages(stages, action.job);
    _.forEach(stages, (stage) => 
    {
      flattenedStagesForCompare.push(...flattenStageForCompare(stage, action.job));
    });
  }

  let selectedStageState = {};
  if (stageRequestIndex > -1) {
    selectedStageState = onSelectStage(state, { stage: stages[stageRequestIndex] });
  }

  return {
    ...state,
    ...selectedStageState,
    stages: stages,
    stagesForCompare: flattenedStagesForCompare,
    queryRunning: false,
  }

}

const onQueryStageDefinitionSuccess = (state, action) => {

  let newState = { ... state, queryRunning: false };
  
  if (!_.isNil(action.queryResults.stageDefinitionFileForStage)) {
    const processedStageDefinition = processStageDefinition(JSON.parse(action.queryResults.stageDefinitionFileForStage.stageDefinition));
    newState.selectedStageDefinition = processedStageDefinition;
  }

  return newState;
}

const onSelectStage = (state, action) => {

  let selectedStage = action.stage;

  return {
    ...state,
    selectedStage: selectedStage,
    selectedUnitIndex: 0,
  }

}

const onSelectUnitIndex = (state, action) => {

  return {
    ...state,
    selectedUnitIndex: action.unitIndex,
  }

}

const onShowStageViewer = (state, action) => {

  return {
    ...state,
    showStageViewer: action.show,
  }

}

const onShowEventHistoryViewer = (state, action) => {

  return {
    ...state,
    showEventHistoryViewer: action.show,
  }

}

const onSaveStageStatusSuccess = (state, action) => {

  // Make a working copy of the stages
  const newStagesState = _.cloneDeep(state.stages);

  // Find the stage we just updated
  const stageToProcessIndex = _.findIndex(newStagesState, ['id', action.queryResults.saveStageStatus.id]);

  // Update the stage
  newStagesState[stageToProcessIndex].status = prettifyStatus(action.queryResults.saveStageStatus.status);
  newStagesState[stageToProcessIndex].lastModified = action.queryResults.saveStageStatus.lastModified;

  return {
    ...state,
    queryRunning: false,
    stages: newStagesState,
    selectedStage: newStagesState[stageToProcessIndex],
    dialogType: null // reset dialog type on status update success, avoid unexpected rendering on approvalDialog
  }

}

const onSelectView = (state, action) => {

  return {
    ...state,
    selectedView: action.view
  }

}

const onCreateChartForStage = (state, action) => {

  const newCharts = _.cloneDeep(state.charts);

  const defaultCardLayout = {
    i: uuidv4(),
    type: ComponentTypes.JOB_STAGE_CHART,
    x: ((newCharts.length + 1) * 14) % 14,
    y: Infinity,
    w: 14,
    h: 5,
    minW: 7,
    minH: 4,
    configuration: {
      sensors: [],
      title: action.stage.description,
      stage: _.omit(action.stage,  ['stageDefinition', 'processedStageDefinition'])
    }
  }

  // We have Simul frac
  if (action.stage.processes.length > 1) {

    _.forEach(action.stage.processes, (process) => {
        
        const newCardLayout = _.cloneDeep(defaultCardLayout);
  
        newCardLayout.i = uuidv4();
        newCardLayout.configuration.title = action.stage.job + ' - Stage ' + process.stage + ' - ' + process.side + ' - ' + process.wellApiNumber;

        newCardLayout.configuration.stage.processes = [process];
  
        newCharts.push(newCardLayout);
    });

  } else {
  // We have Normal frac

    defaultCardLayout.configuration.title = action.stage.job + ' - Stage ' + action.stage.processes[0].stage + ' - ' + action.stage.processes[0].side + ' - ' + action.stage.processes[0].wellApiNumber;
    newCharts.push(defaultCardLayout);
  }

  return {
    ...state,
    charts: newCharts
  }

}

const onUpdateChartsLayout = (state, action) => {
  
  const newCharts = _.cloneDeep(state.charts);

  _.forEach(action.newLayout, (card) => {

    const cardInOriginal = _.find(newCharts, ['i', card.i]);
    cardInOriginal.x = card.x;
    cardInOriginal.y = card.y;
    cardInOriginal.w = card.w;
    cardInOriginal.h = card.h;
  });

  return {
    ...state,
    charts: newCharts
  }
}

const onRemoveChart = (state, action) => {

  const newCharts = _.cloneDeep(state.charts);

  const foundChart = _.find(newCharts, ['i', action.chartKey]);
  if (!_.isNil(foundChart)) {
    _.remove(newCharts, foundChart);
  }

  return {
    ...state,
    charts: newCharts
  }

}


export default jobOverviewReducer;