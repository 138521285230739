import * as componentHoursSimulation from './componentHoursSimulation';
import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchComponentHoursDisplayDetails = () => {

  if (useSimulationData('componentHours')) {
    return componentHoursSimulation.fetchComponentHoursDisplayDetails();
  }

  return performDisplayFetch({
    'query': '{  componentHoursDisplayDetails { truckId name unitType fleetId fleetName region components { componentType hours remaining } } }'
  })
};

export {
  fetchComponentHoursDisplayDetails,
}


