const getDialogStyles = () => ({

  dialogContent: {
    width: '600px',
    paddingLeft: 2,
    paddingRight: 2,
    overflowY: 'auto'
  },
  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 2,
    marginRight: 2,
  },
  actionButton: {
    backgroundColor:'grey.200',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
    '&:hover': {
      backgroundColor:'grey.400',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
    }
  },
  stepContents: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
  },
  stepActionContents: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    marginTop: 2,
    gap: 1
  },
  filterConditionsContents: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: '120px',
  },
  table: {
    width:'477px',
    marginRight: '50px',
    boxShadow: 'none'
  },
  tableHeader: {
    height: '40px',
    width: '477px',
    marginRight: '50px'
  },
  tableActionButton: {
    fontSize: '25px',
  },
  tableRowActionButton: {
    fontSize: '20px',
  },
  headerRow: {
    height: '46px',
    backgroundColor: 'grey.800',
    marginTop: '5px',
    marginRight: '50px',
    width: '477px'
  },
  headerCell: {
    padding: 1,
    '& h3': {
      fontSize: 'typography.fontSize'
    }
  },
  tableHeaderColumn: {
    fontSize: '0.875rem',
    paddingTop: '5px',
  },
  tableRow: {
    height: '46px'
  },
  tableCell: {
    padding: 1,
    color: 'text.primary',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 0px',
    borderColor: 'grey.800'
  },
  tableCellLeftMost: {
    padding: 1,
    color: 'text.primary',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 1px',
    borderColor: 'grey.800'
  },
  tableCellRightMost: {
    padding: 1,
    color: 'text.primary',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 0px',
    borderColor: 'grey.800'
  },
  tableActionCell: {
    padding: 1,
    color: 'text.primary',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 0px',
    borderColor: 'grey.800',
    width: '57px',
    display: 'flex',
    justifyContent: 'center',
    height: '48px'
  },
  filterConditionsContentsMessage: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    justifyContent: 'center'
  }

});

export default getDialogStyles;