import _ from "lodash";

const customersDisplayState = state => {

    return {
        queryRunning: _.isNil(state?.queryRunning) ? false : state.queryRunning,
        customers: _.isNil(state) ? [] : state.customers,
        rowsPerPage: _.isNil(state) ? 10 : state.rowsPerPage,
        rowsPerPageOptions: [5,10,25],
        customerToDelete: _.isNil(state) ? {} : state.customerToDelete,
        customerForSave: _.isNil(state?.customerForSave) ? {} : state.customerForSave,
        originalCustomerForSave: _.isNil(state?.originalCustomerForSave) ? {} : state.originalCustomerForSave,
        canDelete: _.isNil(state?.canDelete) ? false : state.canDelete,
        errorsOnCustomerSave: _.isNil(state?.errorsOnCustomerSave) ? {} : state.errorsOnCustomerSave,
        openDeleteConfirmation: _.isNil(state?.openDeleteConfirmation) ? false : state.openDeleteConfirmation,
        openSaveCustomer: _.isNil(state?.openSaveCustomer) ? false : state.openSaveCustomer,
    };
};

export {
    customersDisplayState
};