import reduceReducers from "reduce-reducers";
import {dataExplorationChartState} from "./dataExplorationChartSelectors";
import chartReducer from './reducers/chartReducer';
import exportCsvReducer from '../../common/exportCsv/exportCsvReducer';

const initialState = dataExplorationChartState();

const dataExplorationChartReducer = (state = initialState, action) => {
  const reducer = reduceReducers(
    chartReducer,
    exportCsvReducer
  );

  return reducer(state, action);
};

export default dataExplorationChartReducer;
