/**
 * Splits a time range into multiple time chunks which can be used for queries.
 * @param startEpochSeconds start time
 * @param endEpochSeconds end time
 * @param chunkSeconds chunk duration
 * @return array of chunk-ed time ranges
 */
 const createTimeRangeChunks = (startEpochSeconds, endEpochSeconds, chunkSeconds) => {
  const chunks = [];
  let startTime = startEpochSeconds;
  while (startTime < endEpochSeconds) {
    let endTime = Math.min(startTime + chunkSeconds, endEpochSeconds);
    chunks.push({
      startTime: startTime,
      endTime: endTime
    });
    startTime += chunkSeconds;
  }
  return chunks;
};

/**
 * Splits a time range into multiple chunks using the given hoursPerChunk.
 * @param startEpochSeconds start time
 * @param endEpochSeconds end time
 * @param hoursPerChunk number of hours per chunk
 */
 const chunkTimeRange = (startEpochSeconds, endEpochSeconds, hoursPerChunk) => {
  // Determine appropriate chunk duration
  const timeRangeSeconds = endEpochSeconds - startEpochSeconds;
  const totalSensorHours = (timeRangeSeconds / 3600);
  const chunks = Math.ceil(totalSensorHours / hoursPerChunk);
  let chunkSeconds = Math.ceil(timeRangeSeconds / chunks);
 
  // Break up into chunks to be used in queries
  return createTimeRangeChunks(startEpochSeconds, endEpochSeconds, chunkSeconds);
};

export {
  chunkTimeRange
};