import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import componentTypes from "../../componentTypes";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import * as routeAssetMgmtDeleteDialogActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtDeleteDialogActions";

class RouteAssetMgmtDeleteConfirmationDialog extends Component {

    render() {
        const { workingConsumer, workingRouteAsset, openDeleteUI } = this.props;

        if (openDeleteUI === false || (_.isNil(workingConsumer) && _.isNil(workingRouteAsset))) {
            return null;
        }

        const isConsumer = workingConsumer.id !== null && workingRouteAsset.id === null;
        const isRouteAsset = workingConsumer.id !== null && workingRouteAsset.id !== null;

        if (isConsumer === false && isRouteAsset === false) {
            return null;
        }

        return (
            <Dialog aria-modal open={this.props.openDeleteUI} disableEscapeKeyDown>
                <DialogTitle>{isConsumer ? "Delete Consumer" : "Delete Asset Routing"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isConsumer === true
                            ? "Are you sure you want to delete the following consumer?"
                            : "Are you sure you want to delete the following asset routing?"}
                    </DialogContentText>
                    <Typography>
                        {isConsumer === true ? workingConsumer.consumerName : workingRouteAsset.truckName.value
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDeleteConfirmation} color='inherit'>Cancel</Button>
                    <Button
                        onClick={() => {
                            if (isConsumer === true) {
                                this.props.deleteConsumer(this.props.workingConsumer.id);
                            } else if (isRouteAsset === true) {
                                this.props.deleteRouteAsset(this.props.workingRouteAsset.truckName.value, this.props.workingConsumer.id);
                            }
                        }}
                        color='primary'
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    };
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    return {
        openDeleteUI: componentState.openDeleteUI,
        workingRouteAsset: componentState.workingRouteAsset || null,
        workingConsumer: componentState.workingConsumer || null,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeDeleteConfirmation: () => dispatch(routeAssetMgmtDeleteDialogActions.closeDeleteConfirmation(props.stateDef)),
        deleteRouteAsset: (truckName, consumerId) => dispatch(routeAssetMgmtDeleteDialogActions.deleteRouteAsset(props.stateDef, truckName, consumerId)),
        deleteConsumer: (consumerId) => dispatch(routeAssetMgmtDeleteDialogActions.deleteConsumer(props.stateDef, consumerId))
    };
};


export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(RouteAssetMgmtDeleteConfirmationDialog));
