import React, {Component} from 'react';
import {connect} from "react-redux";
import { Typography, Box, Grid, Button } from '@mui/material';
import {performLogin} from "../../../helpers/authHelper";

const styles = {
  page: {
    width: '100%',
    maxWidth: (theme) => theme.breakpoints.values.md,
    paddingTop: 16,
    paddingLeft: 2,
    paddingRight: 2,
  }
};

class AuthErrorPage extends Component {
  render() {
    return (
      <Grid container justifyContent={'center'}>
        <Box sx={styles.page}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant={'h5'} align={'center'} noWrap >
                Oops! Something went wrong.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align={'center'} noWrap >
                We were unable to sign you into the application. Please try again.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div align={'center'}>
                <Button variant={'contained'} color={'primary'} onClick={() => { performLogin() } }>Try Again</Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthErrorPage);