import React, {Component} from 'react';
import _ from "lodash";
import PropTypes from 'prop-types';

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

class ConfirmationDialog extends Component {
  render() {
    let dialogContent = {title: "", text: ""};
    switch (this.props.showDialog) {
      case "undoChanges":
        dialogContent = {title: "Undo Changes", text: "Are you sure you want to undo all of your customizations?"};
        break;
      case "restoreDefault":
        dialogContent = {title: "Restore Default", text: "Are you sure you want to restore the owner default for this view?"};
        break;
      case "setOwnerDefault":
        dialogContent = {title: "Save as Owner Default", text: "Are you sure you want to save this configuration as the owner default for this view?"};
        break;
      default:
        break;
    }
    return (
      <Dialog open={this.props.showDialog !== ""} disableEscapeKeyDown>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.cancelCallback(); }} color='inherit'>Cancel</Button>
          <Button onClick={() => { this.props.okCallback(); }} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  showDialog: PropTypes.string.isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default ConfirmationDialog;
