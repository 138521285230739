import equipmentListActionTypes from '../equipmentListActionTypes';
import * as equipmentService from '../services/equipmentService';

const equipmentReducer = (state, action) => {

  switch (action.type) {

    case equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true, equipment: [], currentPage: 0 };

    case equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false, equipment: [], currentPage: 0 };

    case equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case equipmentListActionTypes.EQUIPMENT_LIST_SET_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };

    case equipmentListActionTypes.EQUIPMENT_LIST_SET_UNITS_PER_PAGE:
      return { ...state, unitsPerPage: action.unitsPerPage, currentPage: 0 };

    case equipmentListActionTypes.EQUIPMENT_LIST_SORT_EQUIPMENT:
      return onSortEquipment(state, action);

    case equipmentListActionTypes.EQUIPMENT_LIST_REFRESH_RELATIVE_TIME:
      return onRefreshRelativeTime(state, action);

    default: return state;

  }
};

const onQueryDataSuccess = (state, action) => {

  let equipmentDetails = equipmentService.processEquipment(action.queryResults.pumpHoursDisplay, state.appliedFilters);

  return {
    ...state,
    queryRunning: false,
    equipment: action.queryResults.pumpHoursDisplay,
    filteredEquipment: equipmentDetails.filteredEquipment,
    filters: equipmentDetails.filters,
    currentPage: 0
  };
};

const onSortEquipment = (state, action) => {

  let sortDirection = 'asc';
  if (state.sortContext === action.sortContext) {
    sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  return {
    ...state,
    sortContext: action.sortContext,
    sortDirection: sortDirection,
    currentPage: 0
  };
};

const onRefreshRelativeTime = (state, action) => {

  let updatedEquipment = [...state.filteredEquipment];
  equipmentService.prettifyValues(updatedEquipment);

  return {
    ...state,
    filteredEquipment: updatedEquipment
  };
};

export default equipmentReducer