import _ from "lodash";

const transformSeries = (series, decimals) => {
  const noValue = '-';
  let seriesToTransform = _.isNil(series) ? {} : series;
  return {
    data: formatForSeries(seriesToTransform.data),
    latest: _.isNil(seriesToTransform.latest) ? noValue : parseFloat(seriesToTransform.latest.toFixed(decimals)).toLocaleString('en'),
  };
};

const formatForSeries = (dataPoints) => {
  return _.map(dataPoints, dataPoint => {
    return [
      dataPoint.timestamp * 1000,
      dataPoint.value
    ]
  });
};

export {
  transformSeries,
  formatForSeries
}