const getDiffViewerStyles = () => ({
    variables: {
        dark: {
          addedColor: 'white',
        }
      },
      diffContainer: { 
        background: 'transparent',
      },
      diffRemoved: {
        background: '#424242',
      },
      diffAdded: {
        background: '#424242',
        '> pre': {
          color: '#ffffff'
         }
      }, 
      wordDiff: {
        color: '#ffffff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 350, 
        fontSize: '14px',
        padding: '0px',
      },
      wordAdded: {
        background: '#ce4300',
        color: '#ffffff', 
        paddingLeft: '2px',
        paddingRight: '2px',
      },
      wordRemoved: {
        background: '#7e2900', 
        color: '#ffffff',
        paddingLeft: '2px',
        paddingRight: '2px',
      },
      line: {
        background: '#424242',
      },
      emptyLine: {
        background: '#424242',
      },
      contentText: {
        color: '#ffffff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 350, 
        fontSize: '14px',
        paddingLeft: '10px',
        paddingRight: '10px'
      },
});

export default getDiffViewerStyles;
