import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import cardReducer from './reducers/cardReducer';

import { fracOverviewCardState } from './fracOverviewCardSelectors';

const initialState = fracOverviewCardState();

const fracOverviewCardReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    cardReducer
  );

  return reducer(state, action);
};

export default fracOverviewCardReducer
