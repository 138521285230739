import _ from 'lodash';
import React, {Component} from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { mdtPalette } from '../../../common/styles/mdtPalette';

const styles = {
  chartAndChartLabelsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    width: '145px',
    marginTop: '-2px'
  },

  chart: {
    width: '120px',
    height: '15px',
  },

  chartLabelsContainers: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    width: '20px',
    marginRight: '2px'
  },

  chartInnerTextLabel: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#000000',
    fontSize: '12px',
  },
};

class StackedThreeBar extends Component {

  // Do some math to calculate the amount of bar to show as a percentage of the total bar size
  calculateSize(value1, value2, value3) {
    let realVal1 = value1;
    if (_.isNil(value1) || isNaN(value1) || value1 < 0) {
      realVal1 = 0;
    }

    let realVal2 = value2;
    if (_.isNil(value2) || isNaN(value2) || value2 < 0) {
      realVal2 = 0;
    }

    let realVal3 = value3;
    if (_.isNil(value3) || isNaN(value3) || value3 < 0) {
      realVal3 = 0;
    }

    // Total bar size is value1 + value2 + value3
    let total = realVal1 + realVal2 + realVal3;

    // not much to calculate if total is zero
    if (total === 0) {
      return {
        value1: 0,
        value2: 0,
        value3: 0,
      }
    }

    // Calculate each as a value of the total
    // The value we multiply here is the width of the chart
    // So 150 means the chart is 150px wide
    let value1Part = (realVal1 / total) * 120;
    let value2Part = (realVal2 / total) * 120;
    let value3Part = (realVal3 / total) * 120;

    return {
      value1: Math.round(value1Part),
      value2: Math.round(value1Part + value2Part),
      value3: Math.round(value1Part + value2Part + value3Part)
    }
  }

  render() {
    let values = this.calculateSize(this.props.value1, this.props.value2, this.props.value3);
    let lowValueColor = !_.isNil(this.props.lowValueColor) ? this.props.lowValueColor : mdtPalette().status.level1;
    let medValueColor = !_.isNil(this.props.medValueColor) ? this.props.medValueColor : mdtPalette().status.level4;
    let highValueColor = !_.isNil(this.props.highValueColor) ? this.props.highValueColor : mdtPalette().status.level5;

    let highValueLabelPaddingLeft = Math.round(values.value2 + ((values.value3 - values.value2)/2))-3;
    let medValueLabelPaddingLeft = Math.round(values.value1 + ((values.value2 - values.value1)/2))-3;
    // Sometimes the low value padding calculation can result in a negative number
    // so just set padding to 0 if that is the case
    let lowValueLabelPaddingLeft = (Math.round((values.value1/2))-5) < 0 ? 0 : Math.round((values.value1/2))-5;

    let highValueStyle = {
      width: values.value3 + 'px',
      height: '15px',
      backgroundColor: highValueColor,
      position: 'absolute'
    };

    let highValueLabelStyle = {
      height: '15px',
      position: 'absolute',
      paddingLeft: highValueLabelPaddingLeft + 'px',
      display: 'flex'
    };

    let medValueStyle = {
      width: values.value2 + 'px',
      height: '15px',
      backgroundColor: medValueColor,
      position: 'absolute'
    };

    let medValueLabelStyle = {
      height: '15px',
      position: 'absolute',
      paddingLeft: medValueLabelPaddingLeft + 'px',
      display: 'flex'
    };

    let lowValueStyle = {
      width: values.value1 + 'px',
      height: '15px',
      backgroundColor: lowValueColor,
      position: 'absolute'
    };

    let lowValueLabelStyle = {
      height: '15px',
      position: 'absolute',
      paddingLeft: lowValueLabelPaddingLeft + 'px',
      display: 'flex'
    };

    return (
        <Box sx={styles.chartAndChartLabelsContainer}>
          {/* Chart */}
          {/* Labels left of the chart */}
          {
            !_.isEmpty(this.props.label) &&
            <Box sx={styles.chartLabelsContainers}>
              <Typography variant={'caption'}>{this.props.label}</Typography>
            </Box>
          }
          <Box sx={styles.chart}>
            <Box sx={highValueStyle}>
              <Box sx={highValueLabelStyle}>
                <Typography sx={styles.chartInnerTextLabel} variant={'caption'}>{(_.isNil(this.props.value3) || this.props.value3 === 0) ? '' : this.props.value3}</Typography>
              </Box>
            </Box>
            <Box sx={medValueStyle}>
              <Box sx={medValueLabelStyle}>
                <Typography sx={styles.chartInnerTextLabel} variant={'caption'}>{(_.isNil(this.props.value2) || this.props.value2 === 0) ? '' : this.props.value2}</Typography>
              </Box>
            </Box>
            <Box sx={lowValueStyle}>
              <Box sx={lowValueLabelStyle}>
                <Typography sx={styles.chartInnerTextLabel} variant={'caption'}>{(_.isNil(this.props.value1) || this.props.value1 === 0) ? '' : this.props.value1}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
    );
  }
}

StackedThreeBar.propTypes = {
  label: PropTypes.string,
  value1: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
  value3: PropTypes.number.isRequired,
  lowValueColor: PropTypes.string,
  medValueColor: PropTypes.string,
  highValueColor: PropTypes.string
};

export default StackedThreeBar;
