import _ from 'lodash';
import moment from "moment";

import * as jobStatusConstants from '../../../../components/common/jobStatusConstants';
import * as assetTypeService from '../../../common/services/assetTypeService';
import * as filterService from "../../../common/filtering/filterService";
import {updateFilteredItems} from "../../../common/filtering/filterService";

const noValue = '-';

/**
 * process a list of jobs so it can properly display on dropdown list
 * @param jobs
 * @returns {*[]}
 */
const processJobs = (jobs) => {
  let processedJobs = [];

  if (!_.isEmpty(jobs)) {
    processedJobs = _.orderBy(jobs, 'name').map(job => {
      return {
        ...job,
        value: job.name,
        label: job.name,
      }
    });
  }

  return processedJobs;
}

/**
 * Processes a given Job so it can be displayed properly.
 * @param {*} job Job (of type JobContextInfo) to process
 * @returns A Job with some of its data processed for display purposes.
 */
const processJob = (job) => {
  return {
    ...job,
    type: prettifyType(job.type),
    status: prettifyStatus(job.status),
    jobStartTimestamp: prettifyTime(job.jobStartTimestamp),
    jobStartTimestampRaw: job.jobStartTimestamp,
    jobEndTimestamp: prettifyTime(job.jobEndTimestamp),
    jobEndTimestampRaw: job.jobEndTimestamp,
    createdOn: prettifyTime(job.createdOn),
    createdOnRaw: job.createdOn,
    lastModified: _.isNil(job.lastModified) ? job.lastModified : prettifyTime(job.lastModified),
    lastModifiedRaw: job.lastModified,
    events: { events: [], activeStep: -1, queryRunning: false, error: false },
    processType: prettifyProcessType(job.processType)
  };
}

const processJobForSelection = (job) => {
  return {
    value: job.name, //dropdown list support
    label: job.name //dropdown list support
  }
}

/**
 * Given a Stage Definition JSON object, process it so that it's easier to display in the Job Overview.
 * The Stage Definition as received from Spyglass/Process Control does not have data grouped by Unit. Instead, it
 * has data grouped by step and all units under each step.
 * 
 * We need to process the file so it can be grouped by Unit and have all Steps under each Unit.
 * 
 * We are trying to not modify the information in the stage definition in any way. We simply want to normalize it so 
 * it can be displayed like it is in the Stage Editor in Spyglass.
 */
const processStageDefinition = (stageDefinition) => {

  let processed = [];
  let steps = _.map(stageDefinition, 'StepNumber');

  // All Steps should have the same number of units so just use the first step to get the desired units
  let units = _.map(stageDefinition[0].TruckFleet, 'Name');  

  // For each unit we find...
  _.forEach(units, (unit) => {

    let unitDefinition = {};
    // This name will be displayed on the tab in the Step Details of a selected Stage
    unitDefinition.Name = unit;
    // These are the Steps for the selected Stage
    unitDefinition.Steps = [];
    let stepIndex = 0;

    _.forEach(steps, (step) => {

      let unitAtStep = _.find(stageDefinition[stepIndex].TruckFleet, (truck) => {
        return truck.Name === unit;
      });

      let normalizedStep = {};

      // Stage Definition file does not have the actual unit name or any unit types
      // It just has: Blender 1/2, Chem Unit 1/2, Hydration and/or Datavan
      // So we have to infer the unit type from the name so we can do some unit type processing
      let unitType = processUnitNameForUnitType(unit);

      normalizedStep.Step = step;
      normalizedStep.BaseRate = Number(stageDefinition[stepIndex].BaseRate/42).toFixed(2);
      normalizedStep.FluidType = stageDefinition[stepIndex].FluidType;
      normalizedStep.StepTotal = Number(stageDefinition[stepIndex].StepTotal/42).toFixed(2);
      normalizedStep.DryAddsCount = _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'DRY_ADD'); }).length;
      normalizedStep.LiquidAddsCount = _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'LA_PUMP'); }).length;
      normalizedStep.GelsCount = _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'GEL_PUMP'); }).length;

      // For the Main Hopper, set the Proppant Source and Type
      let mainHopper = _.find(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'MAIN_HOPPER'); });
      // If we are a Datavan, then the Main Hopper will be defined in the Sync Source
      if (unitType === assetTypeService.assetTypes.DATAVAN) {
        // First find the truck with the same name as in defined in Sync Source
        let syncSourceTruck = _.find(stageDefinition[stepIndex].TruckFleet, (truck) => { return truck.Name === unitAtStep.AdditiveConfigurations[0].SyncSource?.Name; });
        if (!_.isNil(syncSourceTruck)) {
          mainHopper = _.find(syncSourceTruck.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, unitAtStep.AdditiveConfigurations[0].SyncSource.AdditiveName); })
        }
      }
      normalizedStep.ProppantType = !_.isNil(mainHopper) ? mainHopper.Additive.DisplayName : 'Main Hopper Not Found';
      normalizedStep.ProppantSource = !_.isNil(mainHopper) ? mainHopper.CleanSource.DisplayName : 'Main Hopper Not found';
      normalizedStep.Concentration = !_.isNil(mainHopper) ? processGalValue(mainHopper.Concentration) : 0;
      normalizedStep.EndConcentration = !_.isNil(mainHopper) ? processGalValue(mainHopper.EndConcentration) : 0;

      // For each Dry Add, create Type, Source, and Concentration values
      _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'DRY_ADD'); }).map((dryAdd, key) => {
        normalizedStep['DryAddType' + key] = dryAdd.Additive.DisplayName;
        normalizedStep['DryAddSource' + key] = dryAdd.CleanSource.DisplayName;
        normalizedStep['DryAddConcentration' + key] = processMGalValue(dryAdd.Concentration);
      });

      // For each Liquid Add
      _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'LA_PUMP'); }).map((liquidAdd, key) => {
        normalizedStep['LiquidAddType' + key] = liquidAdd.Additive.DisplayName;
        normalizedStep['LiquidAddSource' + key] = liquidAdd.CleanSource.DisplayName;
        normalizedStep['LiquidAddConcentration' + key] = processMGalValue(liquidAdd.Concentration);
      });

      // For each Gel Pump
      _.filter(unitAtStep.AdditiveConfigurations, (config) => { return _.startsWith(config.Name, 'GEL_PUMP'); }).map((gelPump, key) => {
        normalizedStep['GelPumpType' + key] = gelPump.Additive.DisplayName;
        normalizedStep['GelPumpSource' + key] = gelPump.CleanSource.DisplayName;
        normalizedStep['GelPumpConcentration' + key] = processMGalValue(gelPump.Concentration);
      });

      unitDefinition.Steps.push(normalizedStep);

      if (stepIndex === 0) {
        unitDefinition.UnitType = unitType;
      }

      stepIndex++;
    });

    processed.push(unitDefinition);

  });

  return processed;

}

/**
 * Given a unit name in the Stage Definition file, return a matching Unit Type.
 * @param {*} unitName Name of a Unit in the Stage Definition file. Can be one of Blender 1/2, Chem Unit 1/2, Hydration or Datavan.
 * @returns A matching Unit Type based on the name. If a name is not found, null is returned.
 */
const processUnitNameForUnitType = (unitName) => {

  if (_.startsWith(_.toLower(unitName), 'blender')) {
    return assetTypeService.assetTypes.BLENDER;
  }

  if (_.startsWith(_.toLower(unitName), 'chem')) {
    return assetTypeService.assetTypes.CHEMVAN;
  }

  if (_.startsWith(_.toLower(unitName), 'hydration')) {
    return assetTypeService.assetTypes.HYDRATION;
  }

  if (_.startsWith(_.toLower(unitName), 'datavan')) {
    return assetTypeService.assetTypes.DATAVAN;
  }

  return null;
}

/**
 * Because there are no UoMs in the Stage Definition file itself, we are using this as a hard coded
 * conversion for Concentration values to '/MGAL' UoM like 'LBS/MGAL'.
 * 
 * This makes the data in Step Details match was is shown in the field in Spyglass' Stage Editor.
 * @param {*} value Concentration value
 * @returns '/MGAL' value
 */
const processMGalValue = (value) => {
  if (_.isNil(value)) {
    return 0;
  }
  return (Number(value) * 1000).toFixed(2);
}

/**
 * Because there are no UoMs in the Stage Definition file itself, we are using this as a hard coded
 * conversion for Concentration values to '/GAL' UoM like 'LBS/GAL'
 * 
 * This makes the data in Step Details match was is shown in the field in Spyglass' Stage Editor.
 * @param {*} value Concentration value
 * @returns '/GAL' value
 */
const processGalValue = (value) => {
  if (_.isNil(value)) {
    return 0;
  }
  return (Number(value)).toFixed(2);
}

const prettifyStatus = (status) => {
  return !_.isNil(status) ? status.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : noValue;
}

const prettifyTime = (time) => {
  const momentValue = moment(time);

  return momentValue.isValid() ? momentValue.fromNow() : noValue;
}

const prettifyType = (type) => {
  return !_.isNil(type) ? type.replace('_', ' ') : noValue;
}

const prettifyProcessType = (processType) => {
  return !_.isNil(processType) ? processType : noValue;
}

/**
 * For each event on a job, we should:
 * 1. Prettify the status
 * 2. Setup properties for the stepper control
 * 
 * We should return an object that contains:
 * 1. Events
 * 2. An active step property 
 * @param {*} events 
 */
const processEventLogs = (events) => {

  let sortedEventLogs = _.orderBy(events, 'createdOn', 'desc');

  // we need an id
  let id = 0;

  // Prettify the status
  let processedEventLogs =
    
    _.map(sortedEventLogs, (event) => {
      return {
        ...event,
        id: id++,
        eventType: prettifyStatus(event.eventType),
        createdOn: moment(event.createdOn).format('lll')
      }
    });

  return processedEventLogs;
}

const jobStatusIsApprovalRequest = (status) => {
  return _.lowerCase(status) === _.lowerCase(prettifyStatus(jobStatusConstants.JOB_APPROVAL_REQUEST));
}

const stageStatusIsApprovalRequest = (status) => {
  return _.lowerCase(status) === _.lowerCase(prettifyStatus(jobStatusConstants.STAGE_APPROVAL_REQUEST));
}

const filterJobs = (jobs, appliedFilters) => {
// Filter the fleets down based on the user selected filters.
  let filteredJobs = !_.isEmpty(jobs) ? filterService.filterItems(jobs, appliedFilters) : [];
  const updatedFilters = updateFilteredItems(appliedFilters, jobs, filteredJobs, filterDefinitions());

  return {
    filteredJobs: filteredJobs,
    filters: updatedFilters,
  };
}

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Customer Name', 'customerName'));
  definitions.push(filterService.createFilterDefinition('Datavan', 'truckName'));
  definitions.push(filterService.createFilterDefinition('Job Name', 'name'));
  definitions.push(filterService.createFilterDefinition('Job Status', 'status'));
  definitions.push(filterService.createFilterDefinition('Pad', 'padName'));
  definitions.push(filterService.createFilterDefinition('Type', 'type'));
  return definitions;
}

const resolveSelectedJob = (jobs, jobName) => {

  let selectedJob = null;

  // Validate that we have a context with the requests id
  if (!_.isNil(jobName) && !_.isEmpty(jobs)) {
    selectedJob = _.find(jobs, { name: jobName });
  }

  // If we did not resolve a selected context, but we do have contexts, set it to the first element.
  if (_.isNil(selectedJob) && !_.isEmpty(jobs)) {
    selectedJob = jobs[0];
  }

  return {
    selectedJob: processJobForSelection(selectedJob),
    selectedJobInfo: processJob(selectedJob)
  };
};

const processStage = (stage, job) => {
  let processes = _.orderBy(stage.processes, ['side'], ['asc']).map(process => { return prettifyProcess(process); });
  
  stage.status = prettifyStatus(stage.status);
  stage.createdAt = _.isNil(stage.timestamp) ? noValue : moment.unix(stage.timestamp).format('lll');
  stage.stageDefinition = _.isNil(stage.stageDefinition) ? null : JSON.parse(stage.stageDefinition);
  stage.processes = processes;
  stage.description = _.isNil(stage.description) ? noValue : stage.description;
  stage.job = job.name,
  
  // Currently, we don't get stage start and stage end from the stage row in the sql db
  // So to facilitate the stage data query, we set the start and end time to these values
  // Because the stage data is keyed by the process id, we can pick some arbitrary timestamps to use for
  // the stage start and end here and it will not affect the stage data query.
  // The timestamps we pick are simply just to bound the query so it doesn't do a full table scan
  stage.stageStart = _.isNil(stage.timestamp) ? noValue : stage.timestamp;
  stage.stageEnd = _.isNil(job.jobEndTimestamp) ? moment().unix() : moment(job.jobEndTimestamp).unix();
}

const prettifyProcess = (process) => {
  return {
    ...process,
    side: _.isNil(process.side) ? noValue : process.side,
    wellApiNumber: _.isNil(process.wellApiNumber) ? noValue : process.wellApiNumber,
    guid: _.isNil(process.guid) ? noValue : process.guid,
    stage: _.isNil(process.stage) ? noValue : process.stage
  };
}

/**
 * Readies a stage so it can be selected from the Stage Compare dropdown in a Treatment Plot
 * @param {*} stage 
 * @param {*} job 
 */
const flattenStageForCompare = (stage, job) => {

  const newStage = _.omit(stage, ['stageDefinition', 'processedStageDefinition']);
  const flattenedStage = [];

  _.forEach(stage.processes, (process) => {
    flattenedStage.push({
      ...newStage,
      processes: [process],
      job: job.name,
      value: stage.id,
      label: job.name + ' - Stage ' + process.stage + ' - ' + process.side + ' - ' + process.wellApiNumber
    });
  });

  return flattenedStage;
}

/**
 * @param {*} stages 
 */
const processStages = (stages, job) => {

  _.map(stages, (stage) => {
    processStage(stage, job);
    return stage;
  })

}

export {
  processJobs,
  filterJobs,
  processJob,
  processJobForSelection,
  processEventLogs,
  prettifyStatus,
  prettifyProcessType,
  prettifyType,
  processMGalValue,
  prettifyTime,
  resolveSelectedJob,

  jobStatusIsApprovalRequest,
  stageStatusIsApprovalRequest,

  processStageDefinition,
  processStage,
  prettifyProcess,
  flattenStageForCompare,
  processStages
}