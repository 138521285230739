import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';

import { fracFleetMapState } from './fracFleetMapSelectors';

const initialState = fracFleetMapState();

const fracFleetMapReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
      queryReducer,
  );

  return reducer(state, action);
};

export default fracFleetMapReducer
