import _ from 'lodash';
import subscriptionEntity from '../../../../../components/common/subscriptionEntity';

/**
 * Given a context object and entity, use the entity to resolve relevant information
 * so we can use it in a Subscription Dialog component.
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 * @param {*} allEvents Collection of Events
 * @param {*} allEventTypes Collection of Event Types
 * @param {*} availableProviders Collection of available Providers
 */
const resolveContextEntityForSubscriptionDialogState = (allEvents, allEventTypes, availableProviders, context, entity) => {

  const noData = {};

  // If we have no context and entity...
  if (_.isNil(entity)) {
    return handleNoEntity(allEvents, allEventTypes);
  }
  
  switch (entity) 
  {
    case subscriptionEntity.ALARM:
      return handleAlarmEntity(allEvents, allEventTypes, context, entity);
    case subscriptionEntity.TRUCKCONFIGURATION:
      return handleTruckConfigurationEntity(allEvents, allEventTypes, context, entity);
    case subscriptionEntity.SUBSCRIPTIONS:
      return handleSubscriptionsEntity(allEvents, allEventTypes, availableProviders, context, entity);
    default:
      return noData;
  }
  
};

/**
 * Handles the Resolved Context object in case we are not given an Entity type.
 */
const handleNoEntity = () => {

  const resolvedContext = {};

  resolvedContext.canSelectEvent = true;

  return resolvedContext;
}

/**
 * Handles the basic common resolved context object for an entity type and context.
 * @param resolvedContext original resolved context
 * @param {*} allEvents Collection of all Events in value/label format
 * @param {*} allEventTypes Collection of all Event Types in id/event/value/label/filterAttributes format
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const handleBasicContextForEntity = (resolvedContext, allEvents, allEventTypes, context, entity) => {
  // Set selected event
  const entityToSearch = _.isNil(context) || _.isNil(context.eventEntity) ? entity : context.eventEntity;
  resolvedContext.event = !_.isNil(entityToSearch) ? _.find(allEvents, ['value', entityToSearch]) : null;

  // Set the collection of available event types based on the event
  resolvedContext.availableEventTypes =  !_.isNil(entityToSearch) ? _.filter(allEventTypes, ['event', entityToSearch]) : allEventTypes;

  // Default to allow the user to change it if they want to
  resolvedContext.canSelectEvent = true;
  resolvedContext.canSelectEventType = !_.isNil(resolvedContext.event);
}

/**
 * Handles the Resolved Context object for an TruckConfiguration entity type and context.
 * @param {*} allEvents Collection of all Events in value/label format
 * @param {*} allEventTypes Collection of all Event Types in id/event/value/label/filterAttributes format
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const handleTruckConfigurationEntity = (allEvents, allEventTypes, context, entity) => {
  const resolvedContext = {};

  handleBasicContextForEntity(resolvedContext,allEvents, allEventTypes, context, entity);
  // Set the selected event type
  resolvedContext.eventType = _.isEmpty(allEventTypes)? null : _.find(allEventTypes, {event : entity});

  // If we have already selected an event and eventType, let's setup the filter attributes to match
  createFilterAttributesForEntity(resolvedContext, allEventTypes, context, entity);

  resolvedContext.canSelectEvent = false;
  return resolvedContext;
}

/**
 * Handles the Resolved Context object for an Alarm entity type and context.
 * @param {*} allEvents Collection of all Events in value/label format
 * @param {*} allEventTypes Collection of all Event Types in id/event/value/label/filterAttributes format
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const handleAlarmEntity = (allEvents, allEventTypes, context, entity) => {

  const resolvedContext = {};

  handleBasicContextForEntity(resolvedContext,allEvents, allEventTypes, context, entity);

  // Set the selected event type
  resolvedContext.eventType = _.isNil(resolvedContext.event) ? null : _.find(allEventTypes, ['value', resolveAlarmStateToEntityType(context.state)]);

  // If we have already selected an event and eventType, let's setup the filter attributes to match
  createFilterAttributesForEntity(resolvedContext, allEventTypes, context, entity);
  resolvedContext.canSelectEvent = false;

  return resolvedContext;
}

/**
 * Handles the Resolved Context object for a Subscription entity type and context.
 * @param {*} allEvents Collection of all Events in value/label format
 * @param {*} allEventTypes Collection of all Event Types in id/event/value/label/filterAttributes format
 * @param {*} availableProviders Collection of all Providers in id/value/label/availableDestinations format
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const handleSubscriptionsEntity = (allEvents, allEventTypes, availableProviders, context, entity) => {

  const resolvedContext = {};

  handleBasicContextForEntity(resolvedContext,allEvents, allEventTypes, context, entity);
  // Set the name
  resolvedContext.subscriptionName = !_.isNil(context) ? context.name : '';

  // Set the selected event type
  resolvedContext.eventType = _.isNil(resolvedContext.event) ? null : _.find(allEventTypes, ['id', context.eventId]);

  // If we have already selected an event and eventType, let's setup the filter attributes to match
  createFilterAttributesForEntity(resolvedContext, allEventTypes, context, entity);

  // If there are recipients in the Subscription object, then let's populate the recipients in the dialog
  if (!_.isNil(context) && !_.isEmpty(context.recipients)) {
    resolvedContext.recipients = createRecipients(availableProviders, context.recipients);
  }

  return resolvedContext;
}

/**
 * Common helper method to create filters for an Entity.
 * @param {*} resolvedContext Resolved Context object for the Entity
 * @param {*} allEventTypes All Event Types
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data 
 */
const createFilterAttributesForEntity = (resolvedContext, allEventTypes, context, entity) => {

  // If we have already selected an event and eventType, let's setup the filter attributes to match
  if (!_.isNil(resolvedContext.event) && !_.isNil(resolvedContext.eventType)) {
    resolvedContext.availableFilterAttributes = _.filter(allEventTypes, { 'event': resolvedContext.event.value, 'value': resolvedContext.eventType.value })[0].filterAttributes;

    // And also pre-fill the filters using the given context
    if (!_.isNil(context) && !_.isNil(entity)) {
      resolvedContext.filters = createFilters(resolvedContext.availableFilterAttributes, context, entity);

      //update availableFilterAttributes to exclude filtered attributes
      resolvedContext.availableFilterAttributes = _.filter(resolvedContext.availableFilterAttributes,attr => {
        return !resolvedContext.filters.map(f => f.attribute.value).includes(attr.value);
      });
    }
  }
}

/**
 * Creates a list of Filters based on a context and entity type. This is to assist the user in creating
 * a Subscription. The user can be looking at some data in the UI and say "I want to be notified of this exact data"
 * and this method helps facilitate that behaviour.
 * @param {*} availableAttributes List of Filter Attributes
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const createFilters = (availableAttributes, context, entity) => {

  let filters = [];
  let index = 0;

  // For each available filter attribute, check the context to see if there is a match
  _.forEach(availableAttributes, (attribute) => {
    // For the given attribute, get the correct property name that is in the available context object
    const property = mapAttributeToContextPropertyForEntity(attribute.value, entity)

    // Only create filter if an attribute exists on the context object
    if (checkContextForProperty(context, entity, property)) {

      // Get existing value and operator from the context
      const value = getPropertyValueFromContext(context, entity, property);
      let existingOperator = getOperatorValueFromContext(context, entity, property);

      // Default to the equals operator since we are looking to match a given context
      let operator = _.find(attribute.availableOperators, ['value', _.isNil(existingOperator) ? '==' : existingOperator]);

      let valueParsed = value;
      // If attribute has a defined list of values, use that instead
      if (!_.isEmpty(attribute.availableValues)) {
        valueParsed = _.find(attribute.availableValues, ['value', String(value)]);
      }

      //parse values array String to Array
      if(!_.isNil(existingOperator) && (existingOperator === 'in' || existingOperator === 'not_in')){
        valueParsed = JSON.parse(value);
        // If attribute has a defined list of values, use that instead
        if (!_.isEmpty(attribute.availableValues)) {
          valueParsed = valueParsed.map(v => _.find(attribute.availableValues, ['value', String(v)]));
        }
      }

      filters.push(
      {
        id: index,
        attribute: attribute,
        operator: operator,
        value: valueParsed
      });
      index++;
    }
  });

  return filters;
}

/**
 * Using the context object, create a list of recipients using the data in the context object.
 * @param {*} availableProviders Collection of available Providers
 * @param {*} context An object that can be classified under the given entity
 */
const createRecipients = (availableProviders, recipients) => {

  let recipientsCreated = [];
  let index = 0;

  _.forEach(recipients, (recipient) => {

    let provider = _.find(availableProviders, ['id', recipient.providerId]);
    // We found the provider so use its information to populate the recipient collection
    if (!_.isNil(provider)) {

      let destination = recipient.destination;

      // If the provider has a defined list of value, use that instead
      if (!_.isEmpty(provider.availableDestinations)) {
        destination = _.find(provider.availableDestinations, ['value', destination])
      }

      recipientsCreated.push(
      {
          id: index,
          provider: provider, 
          destination: destination,
          availableDestinations: provider.availableDestinations
      });
    }
    index++;
  });

  return recipientsCreated;
}

/**
 * Given a context object, check to see if it has a property value
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 * @param {*} property Property to check if it exists in the Context
 */
const checkContextForProperty = (context, entity, property) => {

  switch (entity) {
    case subscriptionEntity.ALARM:
    case subscriptionEntity.TRUCKCONFIGURATION:
      return context.hasOwnProperty(property);
    case subscriptionEntity.SUBSCRIPTIONS:
      return !_.isEmpty(_.filter(context.conditions, ['name', property]));
    default:
      return false;
  }
}

/**
 * Given a context object and a property name, get the property value from the context.
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 * @param {*} property Property to get from the Context.
 */
const getPropertyValueFromContext = (context, entity, property) => {

  switch (entity) {
    case subscriptionEntity.ALARM:
    case subscriptionEntity.TRUCKCONFIGURATION:
      return context[property];
    case subscriptionEntity.SUBSCRIPTIONS:
      let foundProperty = _.find(context.conditions, ['name', property]);
      return _.isNil(foundProperty) ? null : foundProperty.value;
    default:
      return null;
  }
}

/**
 * Given a context object and property name, get the current operator for the property.
 * @param {*} context An object that can be classified under the given entity
 * @param {*} entity  A tag or label that helps identify otherwise anonymous data
 * @param {*} property Property to get an operator from the Context
 */
const getOperatorValueFromContext = (context, entity, property) => {

  let foundProperty = _.find(context.conditions, ['name', property]);

  switch (entity) {
    case subscriptionEntity.ALARM:
    case subscriptionEntity.TRUCKCONFIGURATION:
    case subscriptionEntity.SUBSCRIPTIONS:
      return _.isNil(foundProperty) ? null : foundProperty.operator;
    default:
      return null;
  }
}

/**
 * Given an attribute and entity, map the attribute to the correct context property name for 
 * the given entity type.
 * @param {*} attribute A Filter Attribute
 * @param {*} entity A tag or label that helps identify otherwise anonymous data
 */
const mapAttributeToContextPropertyForEntity = (attribute, entity) => {
  switch (entity) {
    case subscriptionEntity.ALARM:
      return getAttributePropertyForAlarmEntity(attribute);
    case subscriptionEntity.TRUCKCONFIGURATION:
      return getAttributePropertyForTruckConfigurastionEntity(attribute);
    case subscriptionEntity.SUBSCRIPTIONS:
    default:
      return attribute;
  }
}

/**
 * Some attributes for Alarm Events do not match the property names in the actual
 * Alarm context.
 * This method will give you the correct property in the context for a given attribute.
 * @param {*} attribute A Filter Attribute
 */
const getAttributePropertyForAlarmEntity = (attribute) => {
  switch (attribute) {
    case 'truckName':
      return 'unitName';
    case 'severityValue':
      return 'severity';
    case 'source':
      return 'type';
    default:
      return attribute;
  }
}

/**
 * Some attributes for TruckConfiguration Events do not match the property names in the actual
 * TruckConfiguration context.
 * This method will give you the correct property in the context for a given attribute.
 * @param {*} attribute A Filter Attribute
 */
const getAttributePropertyForTruckConfigurastionEntity = (attribute) => {
  switch (attribute) {
    case 'truckName':
      return 'name';
    case 'unitType':
      return 'type';
    default:
      return attribute;
  }
}

/**
 * Resolves a given Alarm State to an Entity Type
 * @param {*} state An Alarm State
 */
const resolveAlarmStateToEntityType = (state) => {
  switch (state) {
    case 'Acknowledged': 
    case 'Cleared': 
    case 'Raised': 
      return 'AlarmRaised';
    default:
      return null;
  }

}

export {
  resolveContextEntityForSubscriptionDialogState,
  createFilters,
  createRecipients,
}