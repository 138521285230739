import _ from 'lodash';

const fleetVibrationState = state => {

  return {
    // The card metrics
    powerEnd: _.isNil(state) ? [] : state.powerEnd,
    fluidEnd:   _.isNil(state) ? [] : state.fluidEnd,
    iron: _.isNil(state) ? [] : state.iron,
    manifold: _.isNil(state) ? [] : state.manifold,
  };

};

export {
  fleetVibrationState
};