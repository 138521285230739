import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';

import { 
  Typography, 
  Box, 
  Divider, 
  Dialog, 
  Button, 
  Tooltip, 
  Skeleton, 
  Card,
  CardContent,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress
} from '@mui/material';
import {
  gridClasses, 
  GridToolbarContainer,
  GridColumnMenuContainer,
} from "@mui/x-data-grid-pro";
import SettingsIcon from '@mui/icons-material/Settings';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import GridOnIcon from '@mui/icons-material/GridOn';
import DownloadIcon from '@mui/icons-material/Download';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import PauseIcon from '@mui/icons-material/Pause';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { mdtPalette } from "../../../../common/styles/mdtPalette";
import ComponentTypes from '../../../../componentTypes';
import getDetailsPageStyles from '../../../../common/styles/detailsPageStyles';

import {AutoCompleteMDT} from '../../../../controls/mdtMuiControls';
import Transition from "../../../../controls/dialogSlideTransition";
import Progress from '../../../../controls/progress';

import { assetTypes } from '../../../../../state/common/services/assetTypeService';
import SensorSelector from '../../../../common/dataExploration/chart/sensorSelector';
import ExportCsv from '../../../../controls/exportCsv/exportCsv';

import MDTDataGrid from '../../../../common/table/MDTDataGrid';
import getDataGridStyles from '../../../../common/styles/dataGridStyle';

import { CardChart } from '../../../../controls/charts/cardChart/cardChart';
import takeScreenShot from '../../../../../helpers/screenshotHelper'

import {appState as applicationState} from '../../../../../state/app/appSelectors';
import * as appErrorActions from '../../../../../state/app/actions/appErrorActions';
import { liveViewState } from '../../../../../state/displays/fleetMap/maps/liveView/liveViewSelectors';
import * as liveViewActions from '../../../../../state/displays/fleetMap/maps/liveView/liveViewActions';

import { trackPage } from '../../../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();
const dataGridStyles = getDataGridStyles();

const styles = {
  ...detailsPageStyles,
  ...dataGridStyles,
  detailsPage: {
    ...detailsPageStyles.detailsPage,
    paddingTop: '5px'
  },
  viewContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1
  },
  headerSection: {
    display: 'flex', 
    flexFlow: 'row nowrap', 
    width: 'calc(100%/5)',
    flexGrow: 1,
    justifyContent: 'center' 
  },
  headersIcon: {
    width: '17px'
  },
  headerContentDivider: {
    height: '24px', 
    margin: 1, 
  },
  truckSelection: {
    width: '200px'
  },
  dialogPaper: {
    minHeight: '840px',
    maxHeight: '840px',
  },
  selectionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    justifyContent: 'center'
  },
  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '48px',
  },
  buttonContainer: {
    width: '100%', 
    display: 'flex', 
    flexFlow: 'row nowrap', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '48px'
  },
  mdtDataGridContainer: {
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#424242',
    },
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: '#424242'
    },
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered': {
      backgroundColor: '#424242'
    },
    '& .sensorRow--0:hover': {
      backgroundColor: 'unset'
    },
    '& .sensorRow--0.MuiDataGrid-row.Mui-hovered': {
      backgroundColor: 'unset'
    },
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden'
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible'
      },
    },
    // Styling cells in the Data Grid is limited to applying classes so inorder to do the conditional formatting, we have to use the following classes
    '& .cell-E57373': {
      backgroundColor: mdtPalette().charting.red
    },
    '& .cell-F06292': {
      backgroundColor: mdtPalette().charting.pink
    },
    '& .cell-BA68C8': {
      backgroundColor: mdtPalette().charting.purple
    },
    '& .cell-9575CD': {
      backgroundColor: mdtPalette().charting.deepPurple
    },
    '& .cell-4DD0E1': {
      backgroundColor: mdtPalette().charting.cyan
    },
    '& .cell-4FC3F7': {
      backgroundColor: mdtPalette().charting.lightBlue
    },
    '& .cell-64B5F6': {
      backgroundColor: mdtPalette().charting.blue
    },
    '& .cell-7986CB': {
      backgroundColor: mdtPalette().charting.indigo
    },
    '& .cell-4DB6AC': {
      backgroundColor: mdtPalette().charting.teal
    },
    '& .cell-81C784': {
      backgroundColor: mdtPalette().charting.green
    },
    '& .cell-AED581': {
      backgroundColor: mdtPalette().charting.lightGreen
    },
    '& .cell-DCE775': {
      backgroundColor: mdtPalette().charting.lime
    },
    '& .cell-FF8A65': {
      backgroundColor: mdtPalette().charting.deepOrange
    },
    '& .cell-FFB74D': {
      backgroundColor: mdtPalette().charting.orange
    },
    '& .cell-FFD54F': {
      backgroundColor: mdtPalette().charting.amber
    },
    '& .cell-FFF176': {
      backgroundColor: mdtPalette().charting.yellow
    },
    height: '100%'
  },
}

const customColumnMenu = (props) => {
  if (props.currentColumn.field !== 'sensorSetId' && props.currentColumn.field !== 'config') {

    const { toggletruckvisibility, toggletruckvisibilityallothers, toggletruckvisibilityothersafter, selectedtrucks, ...others } = props;

    const currentTruck = _.find(selectedtrucks, {id: Number(props.currentColumn.field)});
    const currentTruckIndex = _.findIndex(selectedtrucks, {id: Number(props.currentColumn.field)});
    const currentTruckHidden = (!_.isNil(currentTruck) && currentTruck.visible === false) ? true : false;

    const allOtherTrucksHidden = _.filter(selectedtrucks, (truck) => truck.id !== Number(props.currentColumn.field)).every(truck => truck.visible === false);

    const allOtherTrucksAfterHidden = selectedtrucks.slice(currentTruckIndex + 1).every(truck => truck.visible === false);

    return (
      <GridColumnMenuContainer {...others}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => { toggletruckvisibility(Number(props.currentColumn.field)); props.hideMenu(event); }}>
              <ListItemText primary={currentTruckHidden ? 'Show' : 'Hide'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton 
              disabled={selectedtrucks.length === 1}
              onClick={(event) => { 
                (allOtherTrucksHidden ? toggletruckvisibilityallothers(Number(props.currentColumn.field), true) : toggletruckvisibilityallothers(Number(props.currentColumn.field), false) ); 
                props.hideMenu(event); 
              }}>
              <ListItemText primary={(allOtherTrucksHidden ? 'Show' : 'Hide') + ' Others'}/>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton 
              disabled={currentTruckIndex === selectedtrucks.length - 1}
              onClick={(event) => { 
                (allOtherTrucksAfterHidden ? toggletruckvisibilityothersafter(Number(props.currentColumn.field), true) : toggletruckvisibilityothersafter(Number(props.currentColumn.field), false)); 
                props.hideMenu(event); 
              }}>
              <ListItemText primary={(allOtherTrucksAfterHidden && (currentTruckIndex < selectedtrucks.length - 1) ? 'Show' : 'Hide') + ' to the Right'}/>
            </ListItemButton>
          </ListItem>
        </List>
      </GridColumnMenuContainer>
    );
    
  }
  return (
    <GridColumnMenuContainer {...others }/>
  );
}

const customNoRowsOverlay = () => {
  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
      <Typography variant='subtitle1'>{'Please Select Sensors'}</Typography>
    </Box>
  )
}

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const LiveView = (props) => {

  const startTimeUnix = moment().subtract(props.selectedTimeFrame.value, 'minutes').startOf('minute').unix();
  const endTimeUnix = moment().startOf('minute').unix();
  const startTime = moment().subtract(props.selectedTimeFrame.value, 'minutes').startOf('minute').format('lll');
  const endTime = moment().startOf('minute').format('lll');

  const timeFrameToolTip = `Start time: ${startTime}\nEnd time: ${endTime}`;

  let prevFleetId = usePrevious(props.fleetId);
  let prevSelectedTimeFrame = usePrevious(props.selectedTimeFrame);
  let prevRefreshTimestamp = usePrevious(props.refreshTimestamp);

  // ComponentDidMount
  React.useEffect(() => {

    // GA Tracking
    trackPage(ComponentTypes.FLEET_LIVE_VIEW, props.user);

    props.loadLiveViewConfiguration(props.fleetName);
    // Give the load action a little time to complete
    setTimeout(() => { props.queryTrucks(props.fleetId, props.datavanPid, assetTypes.FRAC_PUMP)}, 250);
    // Including this here is effectively a componentDidUnmount/componentWillUnmount
    return () => { 
      // This will close the subscription if we switch views
      // This does not handle when the browser or tab is closed - that is handled at the app level
      props.closeSubscription();
      props.setConfigureChart(false);
    }
  }, []);

  React.useEffect(() => { 
    if ((!_.isNil(prevFleetId) && prevFleetId !== props.fleetId) || !_.isEqual(prevSelectedTimeFrame, props.selectedTimeFrame)) {
      props.reset();
      props.setFleetNameForDefinition(props.fleetName);
      props.queryTrucks(props.fleetId, props.datavanPid, assetTypes.FRAC_PUMP);
    }
  }, [props.fleetId, props.selectedTimeFrame]);

  React.useEffect(() => {
    if (!_.isNil(prevRefreshTimestamp) && prevRefreshTimestamp !== props.refreshTimestamp && props.isManualRefresh === true) {
      props.queryTrucks(props.fleetId, props.datavanPid, assetTypes.FRAC_PUMP);
      // Give the query action a little time to complete
      setTimeout(() => { props.onQuerySensorDataForTrucks() }, 250);
    }
  }, [props.refreshTimestamp]);

  const columns = [
    {
      field: 'sensorSetId',
      headerName: 'Sensor (UoM)',
      headerAlign: 'center',
      align: 'left',
      width: 300,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      // Parse out the delimited string so we can display it nicely
      renderCell: (params) => 
        {
          return (
            <Box>
              <Typography variant='body2'>{(params.row.sensorSetId === 0 && params.value === 0) ? '-' : params.value}</Typography>
            </Box>
          )
        },
      valueGetter: (params) => {
        if (_.isNil(params.row)) return '';
        if (params.row.sensorSetId === 0) return params.row.displayName;
        const sensor = props.selectedSensors.find(sensor => sensor.sensorSetId === params.row.sensorSetId);
        return sensor.displayName + ' (' + sensor.uom + ')';
      }
    },
    {
      field: 'config',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      width: 60,
      editable: false,
      hideable: false,
      pinnable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      valueGetter: (params) => {
        return '';
      },
      renderHeader: (params) => {
        return (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
              props.userConfigurationSaving === true &&
              <CircularProgress color='primary' disableShrink size={20}/>
            }
            {
              props.userConfigurationSaving === false &&
              <Tooltip title={'Configure Chart'} disableInteractive>
                <IconButton onClick={() => { props.setConfigureChart(true); }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            }
          </Box>
        )
      }
    }
  ]

  _.forEach(props.selectedTrucks, truck => {
    columns.push(
      {
        field: truck.truckPid+'',
        headerName: truck.truckName,
        headerAlign: 'center',
        align: 'center',
        width: 100,
        editable: false,
        hideable: false,
        pinnable: false,
        resizable: true,
        filterable: false,
        sortable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,

        valueGetter: (params) => {
          if (_.isNil(params.value) || isNaN(params.value)) return '-';
          return params.value;
        },
      
        renderHeader: (params) => {
          const selectedTrucks = (isAbsolute() === true ? props.definition.primary.contexts : props.definition.secondary.contexts);
          const truckConfig = _.find((isAbsolute() === true ? props.definition.primary.contexts : props.definition.secondary.contexts) , {id: truck.truckPid});
          const allOtherTrucksHidden = _.filter(selectedTrucks, (otherTruck) => otherTruck.id !== truck.truckPid).every(otherTruck => otherTruck.visible === false);
          const toolTipLabel = selectedTrucks.length > 1 ? (allOtherTrucksHidden === false ? 'Click to Hide Others' : 'Click to Show Others') : '';
          return (
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', width: '110px', justifyContent: 'center', alignItems: 'center', 
                      borderBottomWidth: '3px', borderBottomStyle: 'solid', borderBottomColor: (!_.isNil(truck) ? truck.color : '#c6c6c6')}}>
              <Tooltip title={toolTipLabel} disableInteractive> 
                <Button sx={{color: (!_.isNil(truckConfig) && (truckConfig.visible === true) ? '#c6c6c6' : 'rgba(255,255,255,0.3)')}}
                        variant='text' color={'inherit'} disableRipple
                        onClick={() => { props.toggleTruckVisibilityAllOthers(truck.truckPid, allOtherTrucksHidden === true ? true : false) }}
                        onMouseLeave={() => props.highlightTruck(null)}
                        onMouseEnter={() => props.highlightTruck(truck.truckPid)}>{truck.truckName}</Button>
              </Tooltip>
            </Box>
          )
        },
      }
    )
  });

  /**
   * In MuiX v5, this is experimental. 
   * Need to include the actual row data for the pinned row to show data correctly.
   * @returns Row definition for pinned row
   */
  const getPinnedRow = () => {
    let pinnedRow = {
      sensorSetId: 0,
      displayName: 'Slot Number',
      alias: 'slotNumber',
    }

    _.forEach(props.selectedTrucks, truck => {
      pinnedRow[truck.truckPid+''] = truck.slotNumber;
    });

    return pinnedRow;
  }

  const customGridToolbar = () => {
    return (
      <GridToolbarContainer />
    )
  }

  const getGridHeight = () => {
    switch(props.fullScreenToggle) {
      case props.fullScreenToggleValues[0]:
        return '100%';
      case props.fullScreenToggleValues[2]:
        return '56px';
      case props.fullScreenToggleValues[1]:
      default:
        return '30%'
    }
  }

  const getChartHeight = () => {
    switch(props.fullScreenToggle) {
      case props.fullScreenToggleValues[0]:
        return '0%';
      case props.fullScreenToggleValues[2]:
        return 'calc(100% - 56px)';
      case props.fullScreenToggleValues[1]:
      default:
        return '70%'
    }
  }

  const hasData = () => {
    return !_.isEmpty(props.primaryXValues) && !_.isEmpty(props.primaryYValues);
  }

  const setConditionalFormattingOnCell = (params) => {
    let className = '';

    if (params.row.sensorSetId !== 0) {
      const currentSensor = props.selectedSensors.find(sensor => sensor.sensorSetId === params.row.sensorSetId);
      if (!_.isNil(currentSensor) && currentSensor.conditionalFormatting.applied === true && !isNaN(params.value) && !_.isNil(params.value)) {
        // Evaluate all the rules for the cell
        _.forEach(currentSensor.conditionalFormatting.rules, (rule) => {
          if (rule.condition === props.configRuleConditions[0]) {
            if (!_.isEmpty(rule.value1+'') && (params.value > rule.value1)) {
              className = rule.color.substring(1);
            }
          }
          else if (rule.condition === props.configRuleConditions[1]) {
            if (!_.isEmpty(rule.value1+'') && (params.value < rule.value1)) {
              className = rule.color.substring(1);
            }
          }
          else if (rule.condition === props.configRuleConditions[2]) {
            if (!_.isEmpty(rule.value1+'') && !_.isEmpty(rule.value2+'') &&
                (params.value >= rule.value1 && params.value <= rule.value2)) {
              className = rule.color.substring(1);
            }
          }
          else if (rule.condition === props.configRuleConditions[3]) {
            if (!_.isEmpty(rule.value1+'') && !_.isEmpty(rule.value2+'') &&
                (params.value < rule.value1 || params.value > rule.value2)) {
              className = rule.color.substring(1);
            }
          }
        })
      }
    }

    return _.isEmpty(className) ? '' : 'cell-' + className.toUpperCase();
  }

  const isAbsolute = () => { return props.dataDisplayModeToggle === props.dataDisplayModes[0] };

  return (
    <Box id={"screenshot-area@" + props.stateDef.key} sx={{...styles.detailsPage, height: 'calc(100vh - 56px - 64px)'}}>
      <Box sx={{...styles.detailsPageContent, height: 'calc(100vh - 56px - 64px)'}}>
        <Box sx={{...styles.detailsPageHeader, paddingTop: '5px'}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, width: '95vw', height: '40px', marginBottom: '5px'}}>
            {/* Header Contents */}
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', width: '45%', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '42%', flexGrow: 1, justifyContent: 'center' }}>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center'}}>
                  <Tooltip title={<Box sx={{whiteSpace: 'pre-line'}}>{timeFrameToolTip}</Box>} placement='top' disableInteractive>
                    <Box sx={styles.timeFilterContainer}>
                      <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                      <Box sx={styles.timeFilterItem}>
                        <AutoCompleteMDT
                          sx={styles.timeFrameSelection}
                          getOptionLabel={(timeFrame) => timeFrame.label}
                          options={props.timeFrames}
                          value={props.selectedTimeFrame}
                          onChange={(event, value, reason) => {
                            props.setTimeFrame(value, props.fleetId, props.datavanPid, assetTypes.FRAC_PUMP);
                          }}
                          noOptionsText={"No timeframes found..."}
                        />
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
              <Divider sx={styles.headerContentDivider} orientation='vertical' />
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '33%', flexGrow: 1, justifyContent: 'center' }}>
                <Box sx={styles.selectionContainer}>
                  <AutoCompleteMDT
                    sx={styles.truckSelection}
                    getOptionLabel={(truckFilter) => truckFilter.label}
                    options={props.truckFilters}
                    value={props.selectedTruckFilter}
                    onChange={(event, value, reason) => {
                      props.setTruckFilter(value);
                    }}
                    noOptionsText={"No trucks found..."}
                  />
                </Box>
              </Box>
              <Divider sx={styles.headerContentDivider} orientation='vertical' />
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '25%', flexGrow: 1, justifyContent: 'center' }}>
                <Box sx={styles.buttonContainer} textAlign={'center'}>
                  <Button variant='contained' color='primary'
                    onClick={() => {
                      props.openSensorSelector();
                    }}
                  >
                    Select Sensors
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', width: '55%', alignItems: 'center'}}>
              <Divider sx={styles.headerContentDivider} orientation='vertical' />
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '25%', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{marginRight: 1}} variant={"subtitle1"}>VIEW:</Typography>
                <ToggleButtonGroup value={props.fullScreenToggle} exclusive
                  onChange={(event, value) => { 
                    if (!_.isNil(value)) {
                      props.setFullScreenOption(value);
                    }
                  }}>
                  <ToggleButton value={props.fullScreenToggleValues[0]} aria-label='Grid Only'>
                    <Tooltip title={'Grid Only'} disableInteractive>
                      <GridOnIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={props.fullScreenToggleValues[1]} aria-label='Split'>
                    <Tooltip title={'Split'} disableInteractive>
                      <SplitscreenIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={props.fullScreenToggleValues[2]} aria-label='Chart Only'>
                    <Tooltip title={'Chart Only'} disableInteractive>
                      <ShowChartIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Divider sx={styles.headerContentDivider} orientation='vertical' />
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '42%', flexGrow: 1, justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center'  }}>
                  <Typography sx={{marginRight: 1}} variant={"subtitle1"}>LIVE FEED:</Typography>
                  <Button
                    sx={{paddingRight: 0, paddingLeft: 0, maxWidth: '24px', minWidth: '24px',
                      '& .MuiButton-startIcon': {
                        marginLeft: 0,
                        marginRight: 0
                      }}}
                    startIcon={(props.pauseLiveFeed === true ? <RestartAltIcon /> : <PauseIcon />)}
                    onClick={() => { props.toggleLiveFeed(!props.pauseLiveFeed);}}
                  />
                </Box>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center'  }}>
                  <Typography sx={{marginRight: 1}} variant={"subtitle1"}>SHOW DATA:</Typography>
                  <ToggleButtonGroup value={props.dataDisplayModeToggle} exclusive
                    onChange={(event, value) => { 
                      if (!_.isNil(value)) {
                        props.setDataDisplayModeOption(value);
                      }
                    }}>
                    <ToggleButton value={props.dataDisplayModes[0]} aria-label='Absolute'>
                      <Tooltip title={'Absolute'} disableInteractive>
                        <AlignVerticalBottomIcon />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={props.dataDisplayModes[1]} aria-label='Relative to Average'>
                      <Tooltip title={'Relative to Average'} disableInteractive>
                        <AlignVerticalCenterIcon />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Divider sx={styles.headerContentDivider} orientation='vertical' />
              <Box sx={{ display: 'flex', flexFlow: 'row nowrap', width: '33%', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{display: (!hasData() ? 'none' : 'flex'), flexFlow: 'row nowrap', justifyContent: 'flex-end', flexGrow: 1, alignItems: 'center'}}>
                  <Button
                    component='label'
                    onClick={() => { props.downloadData(props.fleetName); }}
                    variant='standard'
                    endIcon={<DownloadIcon />}
                    disableRipple
                    disableTouchRipple
                    >
                    Download Visible Data
                  </Button>
                  <Divider sx={styles.headerContentDivider} orientation='vertical' />
                  <Tooltip title={'Take Screenshot w/ 2s Delay'} disableInteractive>
                    <Button
                      sx={{paddingRight: 0, paddingLeft: 0, marginRight: '36px', maxWidth: '24px', minWidth: '24px',
                        '& .MuiButton-startIcon': {
                          marginLeft: 0,
                          marginRight: 0
                        }}}
                      component='label'
                      onClick={() => setTimeout(() => { 
                        takeScreenShot(props.stateDef.key);
                        props.signalScreenshotTaken();
                      }, 2000)}
                      variant='standard'
                      startIcon={<ScreenshotIcon />}
                      disableRipple
                      disableTouchRipple
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{...styles.viewContainer, height: 'calc(100% - 50px - 56px)', marginTop: '2px'}}>
          <Card sx={{marginBottom: 1, '& .MuiCardContent-root:last-child': { paddingBottom: 0 }, height: getGridHeight() }}>
            <CardContent sx={{padding: 0, height: '100%'}}>
              <Box sx={styles.mdtDataGridContainer}>
                
                <MDTDataGrid
                  
                  getRowId={row => row.sensorSetId}
                  pagination={false}
                  autoHeight={false}
                  rowHeight={30}
                  hideFooter
                  columns={columns}
                  rows={props.data}
                  
                  stateDef={props.stateDef}

                  disableMultipleSelection={true}

                  components={{
                    Toolbar: customGridToolbar,
                    NoRowsOverlay: customNoRowsOverlay,
                    ColumnMenu: customColumnMenu,
                  }}

                  // pass in props to the column menu so it can be used
                  // note the names have to be all lowercase since they get passed to the DOM element and
                  // html only allows lowercase attribute names
                  componentsProps={{
                    columnMenu: { 
                      toggletruckvisibility: props.toggleTruckVisibility, 
                      toggletruckvisibilityallothers: props.toggleTruckVisibilityAllOthers, 
                      toggletruckvisibilityothersafter: props.toggleTruckVisibilityOthersAfter,
                      selectedtrucks: (isAbsolute() === true ? props.definition.primary.contexts : props.definition.secondary.contexts)
                    }
                  }}

                  onRowClick={(params, event, details) => { 
                    if (params.row.sensorSetId > 0) {
                      const foundSensor = props.selectedSensors.find(sensor => sensor.sensorSetId === params.row.sensorSetId);
                      props.setSensorForDefinition(foundSensor, props.fleetName);
                    }
                  }}

                  isRowSelectable={(params) => params.row.sensorSetId > 0}

                  initialState={{ pinnedColumns: { left: ['sensorSetId', '0'], right: ['config']} }}

                  getRowClassName={(params) => `sensorRow--${params.row.sensorSetId}`}

                  experimentalFeatures={{ rowPinning: true }}
                  pinnedRows={{ top: [ getPinnedRow() ] }}

                  getCellClassName={(params) => { return setConditionalFormattingOnCell(params); }}

                  selectionModel={_.isNil(props.selectedSensor) ? [] : [props.selectedSensor.sensorSetId]}
                />
              
              </Box>
            </CardContent>
          </Card>
          <Box sx={{width: '100%', height: getChartHeight(), display: 'flex', flexFlow: 'row nowrap'}}>
            <Card sx={{'& .MuiCardContent-root:last-child': { paddingBottom: 0 }, height: '100%', marginBottom: 1, width: (props.showLegend === true ? '87%' : '100%')}}>
              <CardContent sx={{padding: 0, height: '100%'}}>
                {
                  hasData() === false &&
                  <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant='subtitle1'>No Data Available</Typography>
                  </Box>
                }
                {
                  hasData() === true &&
                  (
                  props.chartQueryRunning === true ?
                  <Skeleton variant="rectangular" sx={{ height: '100%', display: 'flex', flexFlow: 'row nowrap', backgroundColor: 'rgba(35,35,35,0.4)' }} />
                  :
                  <Box sx={{ height: 'calc(100%)', display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                    <CardChart
                      chartId={getStateKey(props)}
                      chartContainerStyle={{ height: 'calc(100%)', width: 'calc(100%)' }}
                      primaryDefinition={props.definition.primary}
                      secondaryDefinition={props.definition.secondary}
                      
                      primaryXValues={props.primaryXValues}
                      primaryYValues={props.primaryYValues}

                      secondaryXValues={props.primaryXValues}
                      secondaryYValues={props.relativeYValues}

                      primaryZeroRanges={true}
                      secondaryZeroRanges={true}

                      onRollOver={props.onRollOver}
                      showTooltips={false}

                      onXAxisVisibleRangeChanged={props.onXAxisVisibleRangeChanged}
                      onYAxisVisibleRangeChanged={props.onYAxisVisibleRangeChanged}
                      xAxes={props.definition.xAxes}
                      yAxes={props.definition.yAxes}

                      shouldRefreshChart={props.shouldRefreshChart}
                      shouldUpdateChartOnDataChange={!props.pauseLiveFeed}
                    />  
                  </Box>
                  )
                }
              </CardContent>
            </Card>
            <Divider sx={{width: '0px', position: 'relative', left: '-19px', '&:before': { borderLeftWidth: '0px'}, '&:after': { borderLeftWidth: '0px'}}} orientation='vertical'>
              <Tooltip title={props.showLegend === true ? 'Hide the Legend' : 'Show the Legend'} disableInteractive>
                <IconButton size='small' onClick={() => { props.onToggleLegend(); }}>
                  {
                    props.showLegend === true &&
                    <ChevronRightIcon />
                  }
                  {
                    props.showLegend === false &&
                    <ChevronLeftIcon />
                  }
                  
                </IconButton>
                </Tooltip>
            </Divider>
            <Card sx={{height: '100%', width: (props.showLegend === true ? '13%' : '0%'), marginBottom: 1, marginLeft: 1, overflowY: 'scroll'}}>
              <CardContent sx={{padding: 0, height: '100%', display: 'flex', flexFlow: 'column nowrap'}}>
                <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', marginTop: '5px'}}>
                  <Typography variant='caption' color='inherit'>
                    {_.isNil(props.rollOverTimestamp) ? '-' : props.rollOverTimestamp}
                  </Typography>
                </Box>
                {
                   (isAbsolute() === true ? props.definition.primary.contexts : props.definition.secondary.contexts).map((truck, index) => {
                    return (
                      <Box sx={{width: '100%', display: 'flex', flexFlow: 'row nowrap', marginLeft: 1, marginRight: 1, marginBottom: '5px', alignItems: 'center'}} key={index}>
                        <Box sx={{backgroundColor: truck.color, height: '2px', width: '10%'}} />
                        <Box sx={{width: '30%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end'}}>
                          <Typography variant='caption' sx={{marginLeft: '5px'}}>{truck.name}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexFlow: 'row nowrap', width: '52%', justifyContent: 'flex-end'}}>
                          <Typography variant='caption' sx={{marginLeft: '5px'}}>{isNaN(truck.value) ? '-' : truck.value}</Typography>
                          <Typography variant='caption' sx={{marginLeft: '5px'}}>{_.isNil(props.selectedSensor) ? '---' : props.selectedSensor.uom}</Typography>
                        </Box>
                      </Box>
                    )
                  })
                }
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Dialog
          maxWidth={'lg'}
          fullWidth={true}
          open={props.sensorSelectorOpen}
          TransitionComponent={Transition}
          PaperProps={{ paper: styles.dialogPaper }}
      >
          <SensorSelector
              parentCallback={(sensors) => { 
                props.setSelectedSensors(sensors, props.fleetName); 
                props.createSubscription();
              }}
              enableGroupEdit={false}
              initalSelectedSensors={props.selectedSensors}
              trucks={_.map(props.selectedTrucks, (truck) => truck.truckPid)}
              startTime={startTimeUnix}
              endTime={endTimeUnix}
              onClose={props.closeSensorSelector}
              stateKey={ComponentTypes.SENSOR_SELECTOR + '_' + (_.isNil(props.stateKey) ? ComponentTypes.FLEET_LIVE_VIEW : props.stateKey)}
              namespace={'DXP'}
              unitType={assetTypes.FRAC_PUMP}
          />
      </Dialog>
      <ExportCsv triggerExportCsv={props.triggerExportCsv} exportCsvUrl={props.exportCsvUrl}/>
      <Progress open={props.queryRunning || props.chartQueryRunning}/>
      </Box>
    </Box>
  )
}

LiveView.propTypes = {
  datavanPid: PropTypes.number.isRequired,
  fleetId: PropTypes.number.isRequired,
  fleetName: PropTypes.string.isRequired,
  isManualRefresh: PropTypes.bool.isRequired
}

const getStateKey = (props) => {
  return _.isNil(props.stateKey) ? ComponentTypes.FLEET_LIVE_VIEW : props.stateKey;
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: getStateKey(props),
      type: ComponentTypes.FLEET_LIVE_VIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  const componentState = liveViewState(state[stateDef.key]);
  const appState = applicationState(state);
  return {
    queryRunning: componentState.queryRunning,
    chartQueryRunning: componentState.chartQueryRunning,
    userConfigurationSaving: componentState.userConfigurationSaving,

    timeFrames: componentState.timeFrames,
    selectedTimeFrame: componentState.selectedTimeFrame,

    truckFilters: componentState.truckFilters,
    selectedTruckFilter: componentState.selectedTruckFilter,

    selectedSensors: componentState.selectedSensors, 
    sensorSelectorOpen: componentState.sensorSelectorOpen,
    selectedSensor: componentState.selectedSensor,

    selectedTrucks: componentState.selectedTrucks,

    data: componentState.data,

    definition: componentState.definition,
    primaryXValues: componentState.primaryXValues,
    primaryYValues: componentState.primaryYValues,

    shouldRefreshChart: componentState.shouldRefreshChart,

    fullScreenToggle: componentState.fullScreenToggle,
    fullScreenToggleValues: componentState.fullScreenToggleValues,

    configRuleConditions: componentState.configRuleConditions,

    triggerExportCsv: componentState.triggerExportCsv,
    exportCsvUrl: componentState.exportCsvUrl,

    pauseLiveFeed: componentState.pauseLiveFeed,
    dataDisplayModes: componentState.dataDisplayModes,
    dataDisplayModeToggle: componentState.dataDisplayModeToggle,
    relativeYValues: componentState.relativeYValues,

    refreshTimestamp: appState.refreshTimestamp,

    rollOverTimestamp: componentState.rollOverTimestamp,
    
    user: appState.user,

    showLegend: componentState.showLegend,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {

    setTimeFrame: (timeFrame, fleetId, datavanPid, unitType) => { dispatch(liveViewActions.setTimeFrame(props.stateDef, timeFrame, fleetId, datavanPid, unitType)); },
    setTruckFilter: (truckFilter) => { dispatch(liveViewActions.setTruckFilter(props.stateDef, truckFilter)); },

    openSensorSelector: () => { dispatch(liveViewActions.openSensorSelector(props.stateDef, true)); },
    closeSensorSelector: () => { dispatch(liveViewActions.openSensorSelector(props.stateDef, false)); },
  
    setSelectedSensors: (selectedSensors, fleetName) => { dispatch(liveViewActions.setSelectedSensors(props.stateDef, selectedSensors, fleetName)); },

    queryTrucks: (fleetId, datavanPid, unitType) => { dispatch(liveViewActions.queryTrucks(props.stateDef, fleetId, datavanPid, unitType)); },

    setSensorForDefinition: (sensor, fleetName) => { dispatch(liveViewActions.setSensorForDefinition(props.stateDef, sensor, fleetName)); },
    toggleTruckVisibility: _.debounce((truckPid) => { dispatch(liveViewActions.toggleContextVisibility(props.stateDef, truckPid));}, 50),
    toggleTruckVisibilityAllOthers: (truckPid, isVisible) => { dispatch(liveViewActions.toggleContextVisibilityAllOthers(props.stateDef, truckPid, isVisible)); },
    toggleTruckVisibilityOthersAfter: (truckPid, isVisible) => { dispatch(liveViewActions.toggleContextVisibilityOtherAfter(props.stateDef, truckPid, isVisible)); },  

    setConfigureChart: (open) => { dispatch(liveViewActions.setConfigureChart(props.stateDef, open, props.fleetName)); },

    setFullScreenOption: (option) => { dispatch(liveViewActions.setFullScreenOption(props.stateDef, option)); },

    highlightTruck: (truckPid) => { dispatch(liveViewActions.highlightTruck(props.stateDef, truckPid)); },

    onXAxisVisibleRangeChanged: (xAxisId, xMin, xMax) => { dispatch(liveViewActions.xAxisVisibleRangeChanged(props.stateDef, xAxisId, xMin, xMax)); },
    onYAxisVisibleRangeChanged: (yAxisId, yMin, yMax) => { dispatch(liveViewActions.yAxisVisibleRangeChanged(props.stateDef, yAxisId, yMin, yMax)); },

    createSubscription: () => { dispatch(liveViewActions.createSubscription(props.stateDef)); },
    closeSubscription: () => { dispatch(liveViewActions.closeSubscription(props.stateDef)); },

    signalScreenshotTaken: () => { dispatch(appErrorActions.displayError('Screenshot of chart taken and saved to clipboard')); },
    downloadData: (fleetName) => { dispatch(liveViewActions.downloadData(props.stateDef, fleetName)); },
    toggleLiveFeed: (isPaused) => { dispatch(liveViewActions.toggleLiveFeed(props.stateDef, isPaused)); },
    setDataDisplayModeOption: (option) => { dispatch(liveViewActions.setDataDisplayModeOption(props.stateDef, option)); },

    loadLiveViewConfiguration: (fleetName) => { dispatch(liveViewActions.loadLiveViewConfiguration(props.stateDef, fleetName)); },

    onQuerySensorDataForTrucks: () => { dispatch(liveViewActions.onQuerySensorDataForTrucks(props.stateDef)); },

    onRollOver: (xValue, xAxis, yValue, contextId) => { dispatch(liveViewActions.onRollover(props.stateDef, xValue, xAxis, yValue, contextId)); },

    onToggleLegend: () => { dispatch(liveViewActions.onToggleLegend(props.stateDef)); },

    reset: () => { dispatch(liveViewActions.liveViewReset(props.stateDef)); },
    setFleetNameForDefinition: (fleetName) => { dispatch(liveViewActions.setFleetNameForDefinition(props.stateDef, fleetName)); },
  }
}

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(LiveView));