import React, { Component } from 'react';
import _ from "lodash";
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, TextField } from '@mui/material';

import * as componentsActions from '../../../state/displays/componentsDisplay/componentsActions';
import { componentsState } from '../../../state/displays/componentsDisplay/componentsSelectors';
import ComponentTypes from '../../componentTypes';

const styles = {
  calculatorInput: {
    width: '260px'
  }
};

class ComponentCalculatorDialog extends Component {

  render() {

    const onCalculatePumpHours = () => {

      // When we start calculating the estimated pump hours for unit types that have more than one engine
      // then we will make the user input which engine component type they are entering the engine hours
      // for. Until we assume there is only one engine and we just grab that from the collection by kind.
      let engineComponentType = _.find(this.props.componentTypes, { 'kind': 'Engine' });

      let engineComponentTypeId = _.isNil(engineComponentType) ? null : engineComponentType.id;
      let pumpComponentTypeId = _.isNil(this.props.selectedComponentType) ? null : this.props.selectedComponentType.id;
      let selectedUnitId = _.isNil(this.props.selectedUnit) ? null : this.props.selectedUnit.id;

      if (!_.isNil(selectedUnitId) && !_.isNil(this.props.calculatorInput) && !_.isNil(engineComponentTypeId) && !_.isNil(pumpComponentTypeId)) {
        this.props.calculatePumpHours(selectedUnitId, this.props.calculatorInput, engineComponentTypeId, pumpComponentTypeId);
      }
    };

    return (
      <Dialog open={this.props.openCalculator} maxWidth={'sm'} disableEscapeKeyDown>
        <DialogTitle>Estimate Pump Hours</DialogTitle>
        <DialogContent>
          <DialogContentText>The system does not have recorded Pump Hours for this date. To estimate Pump Hours please enter the Engine Hours at the time the component was replaced.</DialogContentText>
          <TextField 
            sx={styles.calculatorInput}
            type='number'
            helperText='Engine Hours'
            value={this.props.calculatorInput}
            onChange={event => this.props.setCalculatorInput(event.target.value)}
            error={this.props.hasErrorCalculatorInput}
            onFocus={(e) => e.currentTarget.select()}
            InputProps={{
              inputProps: {
                max: 50000,
                min: 0,
                autoFocus: true
              }
            }}
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.props.closeCalculator()}} color='inherit'>Cancel</Button>
          <Button onClick={() => onCalculatePumpHours()} disabled={this.props.hasErrorCalculatorInput} color='primary'>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.COMPONENTS : props.stateKey,
      type: ComponentTypes.COMPONENTS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = componentsState(state[stateDef.key]);
  return {
    openCalculator: componentState.openCalculator,
    calculatorInput: componentState.calculatorInput,
    componentTypes: componentState.componentTypes,
    selectedUnit: componentState.selectedUnit,
    selectedComponentType: componentState.selectedComponentType,
    hasErrorCalculatorInput: componentState.hasErrorCalculatorInput,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeCalculator: () => dispatch(componentsActions.closeCalculator(props.stateDef)),
    setCalculatorInput: (input) => dispatch(componentsActions.setCalculatorInput(props.stateDef, input)),
    calculatePumpHours: (unitId, pumpHours, engineComponentTypeId, pumpComponentTypeId) => dispatch(componentsActions.calculatePumpHours(props.stateDef, unitId, pumpHours, engineComponentTypeId, pumpComponentTypeId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ComponentCalculatorDialog))
