import _ from 'lodash';
import moment from "moment";

/**
 * Metadata for Audit Logs includes combinators and fields.
 * The fields are what will be shown in the query builder in the Audit Log display.
 * We are removing fields that are not relevant to the user, such as messageId, datavanPid, truckPid, and timestamp.
 * The Start and End times of the query will be handled separately from the fields.
 * @param {*} metadata 
 */
const processAuditLogsMetadataFields = (fields) => {

  // remove the message id, datavanPid, and truckPid fields
  _.remove(fields, field => (field.name === 'messageId' || field.name === 'datavanPid' || field.name === 'truckPid' || field.name === 'timestamp'));  

  const processedFields = fields.map(field => {
    const newField = { ...field };

    if (field.name === 'unitNumber') {
      newField.label = 'Truck Name';
    }

    return newField;
  });

  return processedFields;
}

const processAuditLogsMetadataCombinators = (combinators) => {

  const andCombinator = _.find(combinators, (combinator) => { return combinator.name.toLowerCase() === 'and'; });

  if (_.isNil(andCombinator)) {
    andCombinator =  
    {
      name: "AND",
      label: "AND"
    }
  }

  return [andCombinator];

}

const processAuditLogs = (auditLogs) => {
  const processedLogs = [];
  _.forEach(auditLogs, (log, index) => {
    const formattedTimestamp = moment.unix(log.timestamp).format('YYYY-MM-DD HH:mm:ss');
    processedLogs.push({...log, id: index, timestamp: formattedTimestamp });
  });
  return processedLogs;
};

const formatQuery = (query, startTime, endTime) => {

  const processRules = query.rules.reduce((acc, rule) => {
    if (typeof rule === 'string' && rule.toUpperCase() === 'AND') {
      acc.push({ "combinator": "and" }); 
    } else if (typeof rule === 'object') {
      const newRule = { ...rule };
      // If it's an array of values, we need to convert it to a string
      if (Array.isArray(rule.value)) {
        newRule.value = JSON.stringify(rule.value);
      }
      acc.push(newRule); 
    }
    return acc;
  }, []);

  return {
    ...query,
    rules: processRules,
    startTime,
    endTime, 
  };
}

/**
 * Parse and update values as the operators they are used for change.
 * Some operators support multiple values, some don't so we need to convert the value of a rule to support both
 * @param {*} query Query to process
 * @returns Query with updated rules and proper values that match their operators
 */
const processQueryForSpecificOperators = (query) => {

  const processedRules = query.rules.reduce((acc, rule) => {
    if (typeof rule === 'object') {
      const newRule = { ...rule };
      if (rule.operator === 'in' || rule.operator === 'not_in') {
        newRule.value = handleValueChangeToArray(rule.value);
      }
      acc.push(newRule); 
    } else {
      acc.push(rule);
    }

    return acc;
  }, []);

  return {
    ...query,
    rules: processedRules
  };
}

/**
 * Give a value, if it's not an array, create one and return it. If it's already an array, return it.
 * If it's empty, we return an empty array.
 * @param {*} value The Value we are checking
 * @returns An array of value(s)
 */
const handleValueChangeToArray = (value) => {
  if (_.isEmpty(value)) {
    return [];
  } else if (!Array.isArray(value)) {
    return [].concat(value);
  } else {
    return value;
  }
}

const formatPageable = (pageable) => ({
  ...pageable,
  sorts: pageable?.sorts?.map(sort => ({
    ...sort,
    field: sort.field === 'truckName' ? 'unitNumber' : sort.field,
  })) || [],
});

const calculateEndTimeUsingStartTimeAndDuration = (startTime, duration) => {
  if (!_.isNil(startTime) && !_.isNil(duration)) {
    // Make a copy so we aren't modifying the original
    let selectedCustomStartTime = moment(startTime);
    return selectedCustomStartTime.add(duration.value, 'minutes').startOf('minute').unix();
  }
}

const calculateStartTimeUsingTimeFrame = (timeFrame) => {
  if (!_.isNil(timeFrame)) {
    return moment().subtract(timeFrame.value, 'minutes').startOf('minute').unix();
  }
}

export {
  processAuditLogsMetadataFields,
  processAuditLogsMetadataCombinators,
  formatQuery,
  formatPageable, 
  processAuditLogs,

  calculateEndTimeUsingStartTimeAndDuration,
  calculateStartTimeUsingTimeFrame,

  processQueryForSpecificOperators,
  handleValueChangeToArray
}