import * as jobOverviewSimulation from './jobOverviewSimulation';
import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchJobEventLogs = (jobId) => {

  if (useSimulationData('jobOverview')) {
    return jobOverviewSimulation.fetchJobEventLogData(jobId);
  }

  return performDisplayFetch(
    {
      'query': 'query GetEventsForJob($jobId:String!) { getEventsForJob(jobId: $jobId) { eventType user note createdOn } }',
      'variables': {
        'jobId': jobId
      }
    }
  );

}

const fetchSaveJobStatus = (job) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveJobStatus($input:JobStatusInput!) { saveJobStatus(input: $input) { id name type extId status fleetId fleetName truckPid truckName unitType customerName padName jobStartTimestamp jobEndTimestamp createdOn lastModified } }',
      'variables': {
        'input': job
      }
    }
  );

}

const fetchStageData = (jobId) => {

  if (useSimulationData('jobOverview')) {
    return jobOverviewSimulation.fetchStageData(jobId);
  }

  return performDisplayFetch(
    {
      'query': 'query AllStagesForJob($jobId:Long!) { allStagesForJob(jobId: $jobId) { id ownerId status description type stageGuid jobId processes { id ownerId guid processType jobName stageGuid wellApiNumber stage side loadedOn } stageStart stageEnd timestamp location } }',
      'variables': {
        'jobId': jobId
      }
    }
  )

}

const fetchStageDefinition = (stageId) => {
  
  if (useSimulationData('jobOverview')){
    return jobOverviewSimulation.fetchStageDefinition(stageId); 
  }

  return performDisplayFetch(
    {
      'query': 'query StageDefinitionFileForStage($stageId:Long!) { stageDefinitionFileForStage(stageId: $stageId) { stageDefinition } }',
      'variables': {
        'stageId': stageId
      }
    }
  )

}

const fetchJobsForOwner = () => {

  if (useSimulationData('jobOverview')) {
    return jobOverviewSimulation.fetchJobsForOwner();
  }

  return performDisplayFetch(
    {
      'query': 'query getJobsForOwner { getJobsForOwner{ id name type extId status fleetId fleetName truckPid truckName unitType customerName padName lastStageId jobStartTimestamp jobEndTimestamp createdOn lastModified processType } }'
    }
  )

}

const fetchJobByName = (jobName) => {

    if (useSimulationData('jobOverview')) {
        return jobOverviewSimulation.fetchJobByName(jobName);
    }

    return performDisplayFetch(
        {
            'query': 'query Job($jobName:String!) { job(jobName: $jobName) { id name type extId status fleetId fleetName truckPid truckName unitType customerName padName jobStartTimestamp jobEndTimestamp createdOn lastModified processType } }',
            'variables': {
                'jobName': jobName
            }
        }
    )

}

const fetchStageStatus = (jobName, stageGuid) => {
    return performDisplayFetch(
        {
            'query': 'query stage($jobName:String!, $stageGuid : String!) { stage(jobName: $jobName, stageGuid : $stageGuid) { id status stageGuid timestamp } }',
            'variables': {
                'jobName': jobName,
                'stageGuid': stageGuid
            }
        }
    )

}

const fetchSaveStageStatus = (stage) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveStageStatus($input:StageStatusInput!) { saveStageStatus(input: $input) { id ownerId status description type stageGuid jobId processes { id ownerId guid processType jobName stageGuid wellApiNumber stage side loadedOn } stageDefinition stageStart stageEnd timestamp location } }',
      'variables': {
        'input': stage
      }
    }
  )
    
}


export {
  fetchJobsForOwner,
  fetchJobByName,
  fetchJobEventLogs,
  fetchSaveJobStatus,
  fetchStageData,
  fetchSaveStageStatus,
  fetchStageStatus, 
  fetchStageDefinition
};