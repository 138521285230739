import _ from 'lodash';
import {filterState} from "../../common/filtering/filterSelectors";
import jobOverviewViews from './jobOverviewViews';

const jobOverviewState = state => {

  let filterDetails = filterState(state);

  return {
    ...filterDetails,
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    showDialog: _.isNil(state) ? false : state.showDialog,
    notes: _.isNil(state) ? '' : state.notes,
    dialogType: _.isNil(state) ? null : state.dialogType,
    dialogEvent: _.isNil(state) ? null : state.dialogEvent,

    jobs: _.isNil(state) ? [] : state.jobs,
    selectedJobInfo: _.isNil(state) ? null : state.selectedJobInfo,
    selectedJob: _.isNil(state) ? null : state.selectedJob,
    filteredJobs: _.isNil(state) ? [] : state.filteredJobs,
    eventLogs: _.isNil(state) ? [] : state.eventLogs,
    stages: _.isNil(state) ? [] : state.stages,
    selectedStage: _.isNil(state) ? null : state.selectedStage,
    selectedStageDefinition: _.isNil(state) ? {} : state.selectedStageDefinition,
    steps: _.isNil(state) ? [] : state.steps,

    selectedUnitIndex: _.isNil(state) ? 0 : state.selectedUnitIndex,

    showStageViewer: _.isNil(state) ? false : state.showStageViewer,
    showEventHistoryViewer: _.isNil(state) ? false : state.showEventHistoryViewer,

    selectedView: _.isNil(state) ? jobOverviewViews.OVERVIEW : state.selectedView,
    charts: _.isNil(state) ? [] : state.charts,
    cardsQueryRunning: _.isNil(state) ? false : state.cardsQueryRunning,
    stagesForCompare: _.isNil(state) ? [] : state.stagesForCompare,
  };
  
};

export {
  jobOverviewState
};