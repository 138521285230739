import { performDisplayFetch } from '../../common/fetch';

const fetchSystemSoftware = () => {
    return fetchControllerSoftware();
};

const fetchControllerSoftware = () => {

  return performDisplayFetch({
    'query': '{ systemSoftwareDisplay { id name type firmwareVersion softwareVersion kernelVersion serialNumber configName configVersion hardwareVersion hasConfigFile fleetName region timeStamp } }'
  })

};

/**
 * Method to execute the remote mutation to trigger an export of config files for a given truck.
 */
const fetchExportByTruck = (truckName) => {

  return performDisplayFetch({
    'query': 'mutation startTruckConfigExport($truckName:String!) { startTruckConfigExport(truckName : $truckName) { exportId, complete, error, exportZipUrl }}',
    'variables': {
      'truckName': truckName
    }
  })
};

/**
 * Method to perform the remote query for the status of a given
 * export job.
 */
const fetchExportByTruckStatus = (exportId) => {
  return performDisplayFetch({
    'query': 'query truckConfigExportStatus($exportId: String!) {  truckConfigExportStatus(exportId : $exportId) { exportId complete error exportZipUrl}}',
    'variables': {
      'exportId': exportId
    }
  })
};

export {
  fetchSystemSoftware,
  fetchExportByTruck,
  fetchExportByTruckStatus
}