import _ from "lodash";
import {performCardFetch} from '../../common/fetch'
import useSimulationData from "../../../helpers/simulationHelper";
import * as unitActivityDfpSimulation from '../unitActivityDfp/unitActivityDfpSimulation';
import unitActivityDfpTypes from '../unitActivityDfp/unitActivityDfpTypes';

const fetchUnitActivityCurbsideCardData = (truckId) => {

  if (useSimulationData('unitActivityCurbsideCard')) {
    return unitActivityDfpSimulation.fetchUnitActivityDfpCardData(unitActivityDfpTypes.CURBSIDE)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitActivityDfpTypes.CURBSIDE; }
        return response;
      });
  }

  return performCardFetch({
    'query': '{ unitActivityCurbside(truckId:{truckId}) { truckId pumping idle stopped days } }'.replace('{truckId}', truckId)
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitActivityDfpTypes.CURBSIDE; }
    return response;
  });

};

const fetchUnitActivityRoadsideCardData = (truckId) => {

  if (useSimulationData('unitActivityRoadsideCard')) {
    return unitActivityDfpSimulation.fetchUnitActivityDfpCardData(unitActivityDfpTypes.ROADSIDE)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitActivityDfpTypes.ROADSIDE; }
        return response;
      });
  }

  return performCardFetch({
    'query': '{ unitActivityRoadside(truckId:{truckId}) { truckId pumping idle stopped days } }'.replace('{truckId}', truckId)
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitActivityDfpTypes.ROADSIDE; }
    return response;
  });

};

export {
  fetchUnitActivityCurbsideCardData,
  fetchUnitActivityRoadsideCardData
}