import _ from 'lodash';
import componentLifeRoadsideActionTypes from './componentLifeRoadsideActionTypes';
import { componentLifeRoadsideState } from './componentLifeRoadsideSelectors';

const initialState = componentLifeRoadsideState();

const componentLifeRoadsideReducer = (state=initialState, action) => {

  switch (action.type) {
    case componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {
  let components = action.queryResults.componentLifeRoadside.components;

  components = _.isNil(components) ? [] : components;

  return {
    ...state,
    queryRunning: false,
    components: components,
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    components: []
  };
};

export default componentLifeRoadsideReducer;
