export default {

  fleetMapLayout: {
    position: 'relative',
    width: '1885px',
    height: '805px',
  },

  fleetMapText: {
    color: '#9e9e9e'
  },

  unitMapContainer: {
    // Make the unit container use relative positioning as to allow views to position child elements
    // by x/y coordinates if they need to. For example if a unit wants to move a label down on to the
    // iron which is rendered outside the unit container.
    position:'relative',
    width: '125px',
    height: '250px',
    borderRadius: '7px',
    background: '#424242',
  },

  unitMapLabel: {
    width: '125px',
    textAlign: 'center',
    color: '#757575'
  },

  unitVisualContent: {
    width: '100%',
    height: '100%',
  },

  pumpUnitVisualContentWithCursor: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },

  unitVisualHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  unitVisualHeaderHasSandContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7b239',
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '7px 7px 0 0',
  },

  // This is the default behaviour
  // Override on the card itself to change how items are displayed
  unitContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // This ensures the contents fit within the remaining space on the card
    height: '88%'
  },

  // This is the default behaviour
  unitContentItem: {
    paddingTop: '10px',
  },

  datavanMapContainer: {
    // Make the datavan container use relative positioning as to allow views to position child elements
    // by x/y coordinates if they need to.
    position:'relative',
    width: '250px',
    height: '125px',
    borderRadius: '7px',
    background: '#424242'
  },

  datavanMapLabel: {
    width: '250px',
    textAlign: 'center',
    color: '#757575'
  },

  datavanVisualContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
  },

  datavanVisualHeaderContent: {
    width: '29px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
  },

  datavanUnitContent: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  datavanAssetLabel: {
    whiteSpace: 'nowrap',
    transform: 'rotate(-90deg)',
  },

  wellHeadMapContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  blankDataContainer: {
    display: 'flex',
    flexFlow: 'column',
    position: 'absolute',
    width: '100%',
    height: '102%',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0px',
    left: '0px',
    background: 'rgba(33, 33, 33, 0.93)',
  },

  blankDataHeaderContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    justifyContent: 'center'
  },

  blankDataDescriptionContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '900px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '300px',
  },

  blankDataLabel: {
    textAlign: 'center',
    color: '#9c9c9c'
  },

  fleetMapLayoutWithNoDataWrapper: {
    position: 'relative'
  },

  badgeContainer: {
    position: 'absolute',
    left: '-10px',
    top: '-25px',
  },

  dataVanBadgeContainer: {
    position: 'absolute',
    left: '-39px',
    top: '-25px',
  },

  healthViewContentLabel: {
    fontStyle: 'italic',
    textDecoration: 'underline',
    fontSize: '14px'
  },

  sandMapContainer: {
    position:'relative',
    width: '330px',
    height: '125px',
    borderRadius: '7px',
    background: '#424242'
  },
  sandMapLabel: {
    width: '250px',
    textAlign: 'center',
    color: '#757575'
  },
  sandVisualContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  sandVisualHeaderContent: {
    width: '29px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
  },
  sandAssetLabel: {
    whiteSpace: 'nowrap',
    transform: 'rotate(-90deg)',
  },

};

