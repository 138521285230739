import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class AlarmInfoBadge extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M0.687,10.595l9.908,-9.908c0.776,-0.776 2.034,-0.776 2.81,0l9.908,9.908c0.776,0.776 0.776,2.034 0,2.81l-9.908,9.908c-0.776,0.776 -2.034,0.776 -2.81,0l-9.908,-9.908c-0.776,-0.776 -0.776,-2.034 0,-2.81Z"/>
      </SvgIcon>
    )
  }
}

export default AlarmInfoBadge;
