export default {
  EXPORT_QUERY_TRUCKS_STARTING: 'EXPORT_QUERY_TRUCKS_STARTING',
  EXPORT_QUERY_TRUCKS_SUCCESS: 'EXPORT_QUERY_TRUCKS_SUCCESS',
  EXPORT_QUERY_TRUCKS_ERROR: 'EXPORT_QUERY_TRUCKS_ERROR',
  EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_STARTING: 'EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_STARTING',
  EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_SUCCESS: 'EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_SUCCESS',
  EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_ERROR: 'EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_ERROR',
  EXPORT_QUERY_TRUCK_DETAILS_STARTING: 'EXPORT_QUERY_TRUCK_DETAILS_STARTING',
  EXPORT_QUERY_TRUCK_DETAILS_SUCCESS: 'EXPORT_QUERY_TRUCK_DETAILS_SUCCESS',
  EXPORT_QUERY_TRUCK_DETAILS_ERROR: 'EXPORT_QUERY_TRUCK_DETAILS_ERROR',
  EXPORT_SELECT_TRUCK: 'EXPORT_SELECT_TRUCK',
  EXPORT_SELECT_DATE_TIME: 'EXPORT_SELECT_DATE_TIME',
  EXPORT_SELECT_DURATION: 'EXPORT_SELECT_DURATION',
  EXPORT_SELECT_TIMEZONE: 'EXPORT_SELECT_TIMEZONE',
  EXPORT_INIT_TIMEZONES: 'EXPORT_INIT_TIMEZONES',
  EXPORT_FIND_NEXT_RANGE: 'EXPORT_FIND_NEXT_RANGE',
  EXPORT_FIND_PREVIOUS_RANGE: 'EXPORT_FIND_PREVIOUS_RANGE',
  EXPORT_SELECT_DATE_TIME_DISPLAY: 'EXPORT_SELECT_DATE_TIME_DISPLAY',

  EXPORT_SHOW_SENSOR_SELECTOR: 'EXPORT_SHOW_SENSOR_SELECTOR',
  EXPORT_SET_SELECTED_SENSORS: 'EXPORT_SET_SELECTED_SENSORS',

  EXPORT_SELECT_NEXT_TRUCK: 'EXPORT_SELECT_NEXT_TRUCK',
  EXPORT_SELECT_PREV_TRUCK: 'EXPORT_SELECT_PREV_TRUCK',
};
