import _ from 'lodash';

const fetchFleetPRCHistoryCardData = (truckId, startTime, endTime, buckets) => {

  const flowRateData = generateDataSeries(startTime, endTime, buckets);
  const pressureData = generateDataSeries(startTime, endTime, buckets);
  const concentrationData = generateDataSeries(startTime, endTime, buckets);

  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({
        fleetPRCHistoryChart: {
          series: 
          [
            {
              id: 'flowRate',
              data: flowRateData,
              latest: flowRateData[flowRateData.length-1] ? flowRateData[flowRateData.length-1].value : 0
            },
            {
              id: 'pressure',
              data: pressureData,
              latest: pressureData[pressureData.length-1] ? pressureData[pressureData.length-1].value : 0
            },
            {
              id: 'concentration',
              data: concentrationData,
              latest: concentrationData[concentrationData.length-1] ? pressureData[pressureData.length-1].value : 0
            }
          ]
        }
      })
    }, 1000);

  });
};

const generateDataSeries = (startTime, endTime, buckets) => {

  let data = [];

  // First divide the time range into the 400 buckets
  // We'll default to 400 if no buckets are provided

  const actualBuckets = _.isNil(buckets) ? 400 : buckets;

  const timeIntervalPerBucket = _.floor((endTime - startTime) / actualBuckets);

  for (let i = startTime; i < endTime; i = i + timeIntervalPerBucket) {
    data.push
    (
      {
        value: _.random(1000,15000, true),
        timestamp: i
      }
    )
  }

  return data;
}

export { fetchFleetPRCHistoryCardData }