import React, {Component} from 'react';
import { SvgIcon } from '@mui/material';

class AlarmCritical extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M16.933,0.089l6.978,6.978l0,9.866l-6.978,6.978l-9.866,0l-6.978,-6.978l0,-9.866l6.978,-6.978l9.866,0Zm-1.789,11.966l3.822,-3.883l-3.108,-3.156l-3.821,3.883l-3.841,-3.902l-3.119,3.163l3.846,3.901l-3.828,3.883l3.108,3.156l3.828,-3.883l3.84,3.902l3.113,-3.163l-3.84,-3.901Z" style={{fillRule:'evenodd'}}/>
      </SvgIcon>
    )
  }
}

export default AlarmCritical;
