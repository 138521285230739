import { performDisplayFetch } from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as clientStatusSimulation from './clientStatusSimulation';

const fetchClientStatus = () => {

  if (useSimulationData('clientStatus')) {
    return clientStatusSimulation.fetchClientStatus();
  }

  return performDisplayFetch({
    'query': '{ clientStatus { fleetId fleetIdentifier fleetName region status isUploading dataUploadStatus datasourceStatus division } }'
  });
};

const fetchClientDetails = (client) => {

  if (useSimulationData('clientStatus')) {
    return clientStatusSimulation.fetchClientDetail(client.fleetId);
  }

  return performDisplayFetch({
    'query': '{ clientDetail(fleetId:{fleetId}) { fleetIdentifier fleetName status isUploading dataUploadStatus producerVersion lastContactSensor lastContactEdge computer datasources { id name status lastContact lastSensorUpload lastSensorDataAge database samplesRemaining truckDetails { id name type lastSensorUpload lastSensorDataAge } } } }'
      .replace('{fleetId}', client.fleetId)
  });
};

export {
  fetchClientStatus,
  fetchClientDetails,
}