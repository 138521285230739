export default {

  COMPONENTS_SET_EDIT_MODE: 'COMPONENTS_SET_EDIT_MODE',
  COMPONENTS_SET_NAVIGATION_CONTEXT: 'COMPONENTS_SET_NAVIGATION_CONTEXT',

  COMPONENTS_QUERY_DISPLAY_DATA_STARTING: 'COMPONENTS_QUERY_DISPLAY_DATA_STARTING',
  COMPONENTS_QUERY_DISPLAY_DATA_SUCCESS: 'COMPONENTS_QUERY_DISPLAY_DATA_SUCCESS',
  COMPONENTS_QUERY_DISPLAY_DATA_ERROR: 'COMPONENTS_QUERY_DISPLAY_DATA_ERROR',

  COMPONENTS_QUERY_COMPONENT_DETAILS_STARTING: 'COMPONENTS_QUERY_DISPLAY_DATA_STARTING',
  COMPONENTS_QUERY_COMPONENT_DETAILS_SUCCESS: 'COMPONENTS_QUERY_COMPONENT_DETAILS_SUCCESS',
  COMPONENTS_QUERY_COMPONENT_DETAILS_ERROR: 'COMPONENTS_QUERY_COMPONENT_DETAILS_ERROR',

  COMPONENTS_SET_SELECTED_UNIT: 'COMPONENTS_SET_SELECTED_UNIT',
  COMPONENTS_SET_SELECTED_COMPONENT_TYPE: 'COMPONENTS_SET_SELECTED_COMPONENT_TYPE',
  COMPONENTS_SET_SELECTED_EFFECTIVE_DATE: 'COMPONENTS_SET_SELECTED_EFFECTIVE_DATE',
  COMPONENTS_SET_SELECTED_MODEL: 'COMPONENTS_SET_SELECTED_MODEL',
  COMPONENTS_SET_SELECTED_SERIAL_NUMBER: 'COMPONENTS_SET_SELECTED_SERIAL_NUMBER',
  COMPONENTS_SET_SELECTED_REF_NUMBER: 'COMPONENTS_SET_SELECTED_REF_NUMBER',
  COMPONENTS_SET_SELECTED_UNIT_OFFSET: 'COMPONENTS_SET_SELECTED_UNIT_OFFSET',
  COMPONENTS_SET_SELECTED_SNAPSHOT_START: 'COMPONENTS_SET_SELECTED_SNAPSHOT_START',
  COMPONENTS_SET_SELECTED_ECM_RESET: 'COMPONENTS_SET_SELECTED_ECM_RESET',

  COMPONENTS_REVERT_FORM: 'COMPONENTS_REVERT_FORM',

  COMPONENTS_SAVE_COMPONENT_STARTING: 'COMPONENTS_SAVE_COMPONENT_STARTING',
  COMPONENTS_SAVE_COMPONENT_SUCCESS: 'COMPONENTS_SAVE_COMPONENT_SUCCESS',
  COMPONENTS_SAVE_COMPONENT_ERROR: 'COMPONENTS_SAVE_COMPONENT_ERROR',

  COMPONENTS_DELETE_COMPONENT_STARTING: 'COMPONENTS_DELETE_COMPONENT_STARTING',
  COMPONENTS_DELETE_COMPONENT_SUCCESS: 'COMPONENTS_DELETE_COMPONENT_SUCCESS',
  COMPONENTS_DELETE_COMPONENT_ERROR: 'COMPONENTS_DELETE_COMPONENT_ERROR',

  COMPONENTS_OPEN_DELETE_CONFIRMATION: 'COMPONENTS_OPEN_DELETE_CONFIRMATION',
  COMPONENTS_CLOSE_DELETE_CONFIRMATION: 'COMPONENTS_CLOSE_DELETE_CONFIRMATION',

  COMPONENTS_OPEN_CALCULATOR: 'COMPONENTS_OPEN_CALCULATOR',
  COMPONENTS_CLOSE_CALCULATOR: 'COMPONENTS_CLOSE_CALCULATOR',
  COMPONENTS_SET_CALCULATOR_INPUT: 'COMPONENTS_SET_CALCULATOR_INPUT',

  COMPONENTS_CALCULATE_PUMP_HOURS_STARTING: 'COMPONENTS_CALCULATE_PUMP_HOURS_STARTING',
  COMPONENTS_CALCULATE_PUMP_HOURS_SUCCESS: 'COMPONENTS_CALCULATE_PUMP_HOURS_SUCCESS',
  COMPONENTS_CALCULATE_PUMP_HOURS_ERROR: 'COMPONENTS_CALCULATE_PUMP_HOURS_ERROR',

  COMPONENTS_SET_SELECTED_EFFECTIVE_DATE_DISPLAY: 'COMPONENTS_SET_SELECTED_EFFECTIVE_DATE_DISPLAY',
};