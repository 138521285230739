import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose} from 'recompose';
import {withProps} from 'recompose';

import {Button, Box, Grid, Typography} from '@mui/material';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import * as providerActions from '../../../../state/displays/settings/actions/providerActions';
import { providerState } from '../../../../state/displays/settings/selectors/providerSelectors';
import ComponentTypes from '../../../componentTypes';

import { trackPage } from '../../../../helpers/googleAnalyticsHelper';
import MDTDataGrid from "../../../common/table/MDTDataGrid";
import {GridActionsCellItem, gridClasses, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import ProviderDialog from '../dialogs/providerDialog';
import DeleteProviderDialog from '../dialogs/deleteProviderDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import detailsPageStyles from "../../../common/styles/detailsPageStyles";
import providerTypes from '../../../common/providerTypes';

const styles = {
  formContainer: {
    width: '85vw',
  },
  detailsPageHeader: {
    ...detailsPageStyles.detailsPageHeader,
    paddingTop: '50px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start',
  },
  providerActionsButton: {
    marginLeft: 2,
  },
  textRow: {
    padding: '45px',
  },
  mdtDataGridContainer: {
    display:'flex',
    flexFlow:'column noWrap',
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible',
      },
    },
    [`& .${gridClasses.row}`]: {
      '.MuiDataGrid-cell': {
        paddingTop:'5px',
        paddingBottom:'20px',
      },
    },
    '& .providerTableCell': {
      display:'flex',
      flexFlow:'column noWrap',
      fontWeight: '500',
    },
  },
  innerTable: {
    width:'100%',
  },
  innerTableCell: {
    color: 'text.primary',
    paddingBottom: '15px',
    paddingLeft: '15px',
    width:"33%",
  },
  innerTableCellText: {
    wordWrap: 'break-word',
    fontSize: '0.875rem',
  },
};



class ProviderSettings extends Component {

  componentDidMount() {
    trackPage(ComponentTypes.PROVIDER_SETTINGS, this.props.user);
    this.props.queryProviderSettings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryProviderSettings();
    }
  }

  customGridToolbar() {
    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
          <GridToolbarQuickFilter sx={{width: '255px'}}/>
        </GridToolbarContainer>
    )
  }

  render() {
    const parseConfig = (params) => {
      const  {value} = params;
      const jsonValues = JSON.parse(value);
      if(params.row.type.value === providerTypes.TEAMS){
        {/*Inner table for Teams */}
        return (
          <Box sx={{flexGrow: 1}} >
            <Grid sx={styles.innerTable}>
              <Grid container>
                {/*Inner table header */}
                <Grid item sx={{...styles.innerTableCell}}>
                  <Typography sx={styles.innerTableCellText} variant={'subtitle1'}>Channel</Typography>
                </Grid>
                <Grid item sx={{...styles.innerTableCell, width:"67%",}}>
                  <Typography sx={styles.innerTableCellText} variant={'subtitle1'}>Webhook</Typography>
                </Grid>
                {/*Inner table body*/}
                {
                  jsonValues.channels.map((channel) => {
                    return (
                        <Grid container key={channel.name} alignItems={'left'}>
                          <Grid item sx={{...styles.innerTableCell}}>
                            {/* Channel name column */}
                            <Typography sx={styles.innerTableCellText} variant={'standard'}>{channel.name}</Typography>
                          </Grid>
                          <Grid item sx={{...styles.innerTableCell, width:"67%",}}>
                            {/* Webhook column */}
                            <Typography sx={styles.innerTableCellText} variant={'standard'}>{channel.webhook}</Typography>
                          </Grid>
                        </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Box>

        );
      }else{
        {/*Inner table for Twilio */}
        return (
          <Box sx={{flexGrow: 1}}>
            <Grid sx={{...styles.innerTable}}>
              <Grid container>
                {/*Inner table header */}
                <Grid item sx={{...styles.innerTableCell}}>
                  <Typography sx={styles.innerTableCellText} variant={'subtitle1'}>Twilio Account SID</Typography>
                </Grid>
                <Grid item sx={{...styles.innerTableCell}}>
                  <Typography sx={styles.innerTableCellText} variant={'subtitle1'}>Twilio Auth Token</Typography>
                </Grid>
                <Grid item sx={{...styles.innerTableCell}}>
                  <Typography sx={styles.innerTableCellText} variant={'subtitle1'}>Twilio From Number</Typography>
                </Grid>
                {/*Inner table body*/}
                <Grid container key={jsonValues.accountSid} alignItems={'left'}>
                  <Grid item sx={{...styles.innerTableCell}}>
                    <Typography sx={styles.innerTableCellText} variant={'standard'}>{jsonValues.accountSid}</Typography>
                  </Grid>
                  <Grid item sx={{...styles.innerTableCell}}>
                    <Typography sx={styles.innerTableCellText} variant={'standard'}>{jsonValues.authToken}</Typography>
                  </Grid>
                  <Grid item sx={{...styles.innerTableCell}}>
                    <Typography sx={styles.innerTableCellText} variant={'standard'}>{jsonValues.from}</Typography>
                  </Grid>
                </Grid>


              </Grid>
            </Grid>
          </Box>
        );
      }

    };

    const columns = [
      {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'center',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
        cellClassName: 'providerTableCell',
      },
      {
        field: 'type',
        headerName: 'Type',
        headerAlign: 'center',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
        valueGetter: ((params) => { return params.value.label;}),
        cellClassName: 'providerTableCell',
      },
      {
        field: 'config',
        headerName: 'Configuration',
        headerAlign: 'center',
        flex: 5,
        editable: false,
        hideable: false,
        pinnable: false,
        renderCell: parseConfig,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        editable: false,
        hideable: false,
        disableReorder: true,
        pinnable: false,
        getActions: (data) => [
          <GridActionsCellItem
              onClick={() => { this.props.onShowProviderDialog(true, data.row); }}
              label="Edit"
              showInMenu />,
          <GridActionsCellItem
              onClick={() => { this.props.onShowDeleteProviderDialog(true, data.row); }}
              label="Delete"
              showInMenu />,
        ],
      }
    ];

    return (
      <Box sx={styles.formContainer}>
        <Box sx={styles.detailsPageHeader}>
          <Box sx={styles.detailsPageHeaderActions}>
            <Button  sx={styles.providerActionsButton} onClick={() => { this.props.onShowProviderDialog(true, null); }} color={'inherit'}>
              <AddCircleIcon sx={{marginRight: '5px'}}/>
              CREATE PROVIDER
            </Button>
          </Box>
        </Box>
        <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
              getRowId={row => row.id}
              rows={this.props.providerSettings}
              columns={columns}
              getEstimatedRowHeight={() => 100}
              getRowHeight={() => 'auto'}
              components={{Toolbar: this.customGridToolbar,}}
              disableSelectionOnClick
              stateDef={this.props.stateDef}
              rowsPerPage={this.props.rowsPerPage}
              setRowsPerPage={this.props.setRowsPerPage}
              rowsPerPageOptions={this.props.rowsPerPageOptions}
            />
        </Box>
        <ProviderDialog />
        <DeleteProviderDialog />
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = providerState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    providerCanSave: componentState.provider.providerCanSave,
    providerSettings: componentState.provider.providerSettings,
    showProviderDialog: componentState.provider.showProviderDialog,
    providerDialogContext: componentState.provider.providerDialogContext,
    rowsPerPage: componentState.provider.rowsPerPage,
    user: appState.user,
    rowsPerPageOptions: componentState.provider.rowsPerPageOptions,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryProviderSettings: () => { dispatch(providerActions.queryProviderSettings(props.stateDef)) },
    onShowProviderDialog: (show, context) => { dispatch(providerActions.showProviderDialog(props.stateDef, show, context)); },
    onShowDeleteProviderDialog: (show, context) => { dispatch(providerActions.showDeleteProviderDialog(props.stateDef, show, context)); },
    onDeleteProvider: (id) => { dispatch(providerActions.deleteProviderSettings(props.stateDef, id)); },
    setRowsPerPage: (rowSize) => { dispatch(providerActions.dataGridSetRowSize(props.stateDef, rowSize)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ProviderSettings));