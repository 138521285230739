import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import Alarms from "../../../../controls/charts/alarms/alarms";

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
  alarmsContainer: {
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1
  }
};

class Blender extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.unitMapContainer}>
        <Box sx={styles.unitVisualContent}>
          <Box sx={styles.unitVisualHeaderContent}>
            <Typography variant={'subtitle1'}>{data.name}</Typography>
          </Box>
          <Box sx={styles.unitContent}>
            <Typography variant={'caption'} sx={styles.healthViewContentLabel}>Alarms</Typography>
            <Box sx={styles.alarmsContainer}>
              <Alarms alarmCounts={data.alarmCounts}/>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

Blender.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Blender;