export default {

  SHOW_CREATE_EDIT_JOB_DIALOG: 'SHOW_CREATE_EDIT_JOB_DIALOG',
  SELECT_CUSTOMER: 'SELECT_CUSTOMER',
  SET_ROW_SIZE: 'SET_ROW_SIZE',
  ROW_CLICK: 'ROW_CLICK',
  SELECT_TIMEFRAME: 'SELECT_TIMEFRAME',

  QUERY_JOBS_FOR_TIMEFRAME_STARTING: 'QUERY_JOBS_FOR_TIMEFRAME_STARTING',
  QUERY_JOBS_FOR_TIMEFRAME_SUCCESS: 'QUERY_JOBS_FOR_TIMEFRAME_SUCCESS',
  QUERY_JOBS_FOR_TIMEFRAME_ERROR: 'QUERY_JOBS_FOR_TIMEFRAME_ERROR',

  QUERY_ACTIVE_JOBS_STARTING: 'QUERY_ACTIVE_JOBS_STARTING',
  QUERY_ACTIVE_JOBS_SUCCESS: 'QUERY_ACTIVE_JOBS_SUCCESS',
  QUERY_ACTIVE_JOBS_ERROR: 'QUERY_ACTIVE_JOBS_ERROR',
  
  QUERY_PLANNED_JOBS_STARTING: 'QUERY_PLANNED_JOBS_STARTING',
  QUERY_PLANNED_JOBS_SUCCESS: 'QUERY_PLANNED_JOBS_SUCCESS',
  QUERY_PLANNED_JOBS_ERROR: 'QUERY_PLANNED_JOBS_ERROR',

  QUERY_JOB_METADATA_STARTING: 'QUERY_JOB_METADATA_STARTING',
  QUERY_JOB_METADATA_SUCCESS: 'QUERY_JOB_METADATA_SUCCESS',
  QUERY_JOB_METADATA_ERROR: 'QUERY_JOB_METADATA_ERROR',

  QUERY_CUSTOMERS_STARTING: 'QUERY_CUSTOMERS_STARTING',
  QUERY_CUSTOMERS_SUCCESS: 'QUERY_CUSTOMERS_SUCCESS',
  QUERY_CUSTOMERS_ERROR: 'QUERY_CUSTOMERS_ERROR',

  CREATE_EDIT_SET_JOB_NAME: 'CREATE_EDIT_SET_JOB_NAME',
  CREATE_EDIT_SET_START_DATE: 'CREATE_EDIT_SET_START_DATE',
  CREATE_EDIT_SET_START_DATE_DISPLAY: 'CREATE_EDIT_SET_START_DATE_DISPLAY',
  CREATE_EDIT_SET_END_DATE: 'CREATE_EDIT_SET_END_DATE',
  CREATE_EDIT_SET_END_DATE_DISPLAY: 'CREATE_EDIT_SET_END_DATE_DISPLAY',
  CREATE_EDIT_SELECT_CUSTOMER: 'CREATE_EDIT_SELECT_CUSTOMER',
  CREATE_EDIT_SELECT_DATAVAN: 'CREATE_EDIT_SELECT_DATAVAN',
  CREATE_EDIT_SELECT_JOBTYPE: 'CREATE_EDIT_SELECT_JOBTYPE',
  CREATE_EDIT_SELECT_CONTACT: 'CREATE_EDIT_SELECT_CONTACT',
  CREATE_EDIT_ADD_WELL: 'CREATE_EDIT_ADD_WELL',
  CREATE_EDIT_REMOVE_WELL: 'CREATE_EDIT_REMOVE_WELL',
  CREATE_EDIT_SELECT_WELL: 'CREATE_EDIT_SELECT_WELL',
  CREATE_EDIT_SET_PLANNED_STAGES: 'CREATE_EDIT_SET_PLANNED_STAGES',

  SAVE_JOB_STARTING: 'SAVE_JOB_STARTING',
  SAVE_JOB_SUCCESS: 'SAVE_JOB_SUCCESS',
  SAVE_JOB_ERROR: 'SAVE_JOB_ERROR',

  SAVE_JOB_WELL_STARTING: 'SAVE_JOB_WELL_STARTING',
  SAVE_JOB_WELL_SUCCESS: 'SAVE_JOB_WELL_SUCCESS',
  SAVE_JOB_WELL_ERROR: 'SAVE_JOB_WELL_ERROR',

  DELETE_JOB_STARTING: 'DELETE_JOB_STARTING',
  DELETE_JOB_SUCCESS: 'DELETE_JOB_SUCCESS',
  DELETE_JOB_ERROR: 'DELETE_JOB_ERROR',
  SHOW_DELETE_JOB_DIALOG: 'SHOW_DELETE_JOB_DIALOG',

  UPDATE_JOB_FILTER_MODEL: 'UPDATE_JOB_FILTER_MODEL'
}