import { gridClasses } from '@mui/x-data-grid-pro';

import { mdtPalette } from "../../../common/styles/mdtPalette";

const getDataGridDialogStyles = () => ({
  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible',
      },          
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer'
    },
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: mdtPalette().materialUI.palette.primary.main,
    },
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: mdtPalette().materialUI.palette.primary.main
    },
    '& .MuiDataGrid-root .hasStageDefinition--false:hover': {
      cursor: 'default',
      backgroundColor: 'inherit'
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none"
    },
    height: 'inherit',
    width: '100%',
    boxShadow: '0px 10px 10px 3px rgb(0 0 0 / 20%)'
  },
});

export default getDataGridDialogStyles;