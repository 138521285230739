import {performDisplayFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as dataExplorationDisplay from './dataExplorationDisplaySimulation';

const fetchDataExplorationDisplayDataAge = (truckId) => {
  return performDisplayFetch({
    'query': 'query TruckDataAge($truckId:Long!){ truckDataAge(truckId: $truckId) { timestamp }}',
    'variables': {
      'truckId': truckId
    }
  })
};

const fetchDataExplorationDisplayData = (startTime, endTime) => {

   if (useSimulationData('dataExplorationDisplayPage')) {
       return dataExplorationDisplay.fetchDataExplorationDisplay();
   }

  return performDisplayFetch({
    'query': 'query dataExplorationDisplay($input:DataExplorationDisplayInput!) { dataExplorationDisplay(input:$input) { datavan truckId truckPid truckName unitType fleetName } }',
    'variables': {
      'input': {
        'startTime': startTime,
        'endTime': endTime
      }
    }
  })
};

export {
  fetchDataExplorationDisplayDataAge,
  fetchDataExplorationDisplayData
};
