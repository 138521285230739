import _ from 'lodash';

import * as coilService from '../services/coilService';
import coilDashboardActionTypes from '../coilDashboardActionTypes';
import { coilDashboardState } from '../coilDashboardSelectors';
import { prettifyValue } from '../services/coilService';

const initialState = coilDashboardState();

const coilReducer = (state=initialState, action) => {

    switch (action.type) {

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_STARTING:
            return { ...state, queryRunning: true };

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_SUCCESS:
            return onQueryDataSuccess(state, action);

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_ERROR:
            return { ...state, queryRunning: false };

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_SUCCESS:
            let actualTimestamp = action.queryResults.truckDataAge.timestamp;
            let displayTimestamp = prettifyValue(actualTimestamp);
            return { ...state, dataAgeDisplay: displayTimestamp, dataAgeActual: actualTimestamp, queryRunning: false };

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_ERROR:
            return { ...state, queryRunning: false };

        case coilDashboardActionTypes.COIL_DASHBOARD_QUERY_DATA_AGE_STARTING:
            return { ...state, queryRunning: true };

        case coilDashboardActionTypes.COIL_DASHBOARD_DATA_AGE_CLEAR_DATA:
            return { ...state, dataAgeActual: initialState.dataAgeActual, dataAgeDisplay:  initialState.dataAgeDisplay };

        case coilDashboardActionTypes.COIL_DASHBOARD_DATA_AGE_REFRESH_RELATIVE:
            let refreshDisplayTimestamp = prettifyValue(state.dataAgeActual);
            return { ...state, dataAgeDisplay: refreshDisplayTimestamp };

        case coilDashboardActionTypes.COIL_DASHBOARD_SELECT_CONTEXT:
            return onSelectContext(state, action);

        case coilDashboardActionTypes.COIL_DASHBOARD_SELECT_NAVIGATION_CONTEXT:
            return onSelectNavigationContext(state, action);

        default: return state;

    }

};

const onQueryDataSuccess = (state, action) => {

    let contextDetails = coilService.processCoilUnits(action.queryResults.coilDashboard);

    return {
        ...state,
        queryRunning: false,
        coilUnits: action.queryResults.coilDashboard,
        contexts: contextDetails.contexts,
    };

};

const onSelectContext = (state, action) => {

    if (!_.isNil(action.context) && _.isEmpty(action.context)) {

        // The AutoComplete control we use will output an empty array when using the keyboard to clear
        // out the selected value. Catch that here and set it to null so the cards all behave properly.

        return {...state, selectedContext: null };
    }

    let selectedContext = coilService.resolveSelectedContext(
        state.contexts,
        _.isNil(action.context) ? action.context : action.context.id
    );

    return {...state, selectedContext: selectedContext };
};

const onSelectNavigationContext = (state, action) => {

    if (_.isNil(action.context) || _.isEmpty(state.contexts)) {

        // If we do not have a context or any contexts just set the selected context to null

        return {...state, selectedContext: null };
    }

    // Check to see if the context exists in the current contexts

    let selectedContext = _.find(state.contexts, { id: action.context.unitId });

    if (!_.isNil(selectedContext)) {

        // If we have a match then set the selection

        return {...state, selectedContext: selectedContext };
    }

    // Process the coil units and try to resolve the selected context normally.

    let contextDetails = coilService.processCoilUnits(state.coilUnits);

    selectedContext = coilService.resolveSelectedContext(contextDetails.contexts, action.context.unitId);

    return {
        ...state,
        contexts: contextDetails.contexts,
        selectedContext: selectedContext,
    }
};

export default coilReducer