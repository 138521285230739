export default {
  CLIENT_STATUS_QUERY_DATA_STARTING: 'CLIENT_STATUS_QUERY_DATA_STARTING',
  CLIENT_STATUS_QUERY_DATA_SUCCESS: 'CLIENT_STATUS_QUERY_DATA_SUCCESS',
  CLIENT_STATUS_QUERY_DATA_ERROR: 'CLIENT_STATUS_QUERY_DATA_ERROR',
  CLIENT_STATUS_QUERY_DETAILS_STARTING: 'CLIENT_STATUS_QUERY_DETAILS_STARTING',
  CLIENT_STATUS_QUERY_DETAILS_SUCCESS: 'CLIENT_STATUS_QUERY_DETAILS_SUCCESS',
  CLIENT_STATUS_QUERY_DETAILS_ERROR: 'CLIENT_STATUS_QUERY_DETAILS_ERROR',
  CLIENT_STATUS_REFRESH_RELATIVE_TIME: 'CLIENT_STATUS_REFRESH_RELATIVE_TIME',
  CLIENT_STATUS_CLOSE_DETAILS_DIALOG: 'CLIENT_STATUS_CLOSE_DETAILS_DIALOG',
  CLIENT_STATUS_SHOW_DATASOURCE_DETAILS: 'CLIENT_STATUS_SHOW_DATASOURCE_DETAILS',
  CLIENT_STATUS_HIDE_DATASOURCE_DETAILS: 'CLIENT_STATUS_HIDE_DATASOURCE_DETAILS',
  CLIENT_STATUS_SORT_DATASOURCE_TRUCKS: 'CLIENT_STATUS_SORT_DATASOURCE_TRUCKS',
  CLIENT_STATUS_SET_DATASOURCE_TRUCKS_ITEMS_PER_PAGE: 'CLIENT_STATUS_SET_DATASOURCE_TRUCKS_ITEMS_PER_PAGE',
  CLIENT_STATUS_SET_DATASOURCE_TRUCKS_CURRENT_PAGE: 'CLIENT_STATUS_SET_DATASOURCE_TRUCKS_CURRENT_PAGE',
};