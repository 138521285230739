import React, {Component} from 'react';
import {connect} from 'react-redux';

import DataTableControl from '../../controls/table/dataTableControl';

import { tableState as commonTableState } from '../../../state/common/table/tableSelectors';
import * as tableActions from '../../../state/common/table/tableActions';
import PropTypes from 'prop-types';

class DataTable extends Component {

  render() {
    return (
      <DataTableControl
        tableContext={this.props.tableContext}
        columns={this.props.columns}
        items={this.props.items}
        sortContext={this.props.sortContext}
        sortDirection={this.props.sortDirection}
        secondarySortDefinitions={this.props.secondarySortDefinitions}
        itemsPerPage={this.props.unitsPerPage}
        currentPage={this.props.currentPage}
        rowClickSupport={this.props.rowClickSupport}
        sortItems={this.props.sortItems}
        setItemsPerPage={this.props.setUnitsPerPage}
        setCurrentPage={this.props.setCurrentPage}
        rowClicked={this.props.rowClicked}
        renderTableCell={this.props.renderTableCell}
      />
    )
  }
}

DataTable.propTypes = {

  // Properties
  stateDef: PropTypes.object.isRequired,
  tableContext: PropTypes.any,
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  rowClickSupport: PropTypes.bool,

  // Actions
  renderTableCell: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let tableState = commonTableState(state[stateDef.key]);

  return {
    sortContext: tableState.sortContext,
    sortDirection: tableState.sortDirection,
    secondarySortDefinitions: tableState.secondarySortDefinitions,
    unitsPerPage: tableState.unitsPerPage,
    currentPage: tableState.currentPage,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    sortItems: (tableContext, sortContext) => { dispatch(tableActions.sortItems(props.stateDef, sortContext)) },
    setUnitsPerPage: (tableContext, unitsPerPage) => {dispatch(tableActions.setUnitsPerPage(props.stateDef, unitsPerPage))},
    setCurrentPage: (tableContext, currentPage) => {dispatch(tableActions.setCurrentPage(props.stateDef, currentPage))},
    rowClicked: (tableContext, item) => {dispatch(tableActions.rowClicked(props.stateDef, item))},
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(DataTable)