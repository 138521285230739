import _ from 'lodash';
import fleetMapViews from './fleetMapViews';

const fleetMapState = state => {

  return {

    timeFrames: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: 'Last 6 hours' },
      { value: 720, label: 'Last 12 hours' },
      { value: 1440, label: 'Last 24 hours' },
      { value: null, label: 'Custom' },
    ] : state.timeFrames,

    customDurations: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: '6 hours' },
      { value: 720, label: '12 hours' },
      { value: 1440, label: '24 hours' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedCustomDuration,

    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    fleets: _.isNil(state) ? [] : state.fleets,

    selectedFleet: _.isNil(state) ? null : state.selectedFleet,
    selectedView: _.isNil(state) ? fleetMapViews.DASHBOARD : state.selectedView,

    // This is set when the display is loaded and there is a navigation context for the display
    // in the app state (ie. one display linked to another display).
    navigationContextFleetId: _.isNil(state) ? null : state.navigationContextFleetId,

    editMode: _.isNil(state) ? false : state.editMode,
    showDialog: _.isNil(state) ? "" : state.showDialog,

    selectedCard: _.isNil(state) ? null : state.selectedCard,

    cardsList: _.isNil(state) ? [] : state.cardsList,

    configPanelWidth: 350,
    customViewsConfigPanelWidth: 400,
    customViewInput: _.isNil(state) ? '' : state.customViewInput,
    selectedCustomView: _.isNil(state) ? null : state.selectedCustomView,

    cardsQueryRunning: _.isNil(state) ? false : state.cardsQueryRunning,

    manualRefresh: _.isNil(state) ? false : state.manualRefresh,    

  };

};

export {
  fleetMapState
};