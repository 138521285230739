const fetchAuditLogData = (isFleet) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (isFleet) {
        resolve(simData.auditForDatavan)
      } else {
        resolve(simData.auditForTruck)
      }
    }, 1000)
  });
};

const simData = {
  auditForDatavan: {
    auditLogs: [
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Activated",
        "detail": "",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "FP3B DENSITY REQUESTED",
        "timestamp": 1706137607
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1235,
        "truckName": "testTruck2",
        "actionType": "Activated",
        "detail": "",
        "newState": "Test",
        "oldState": "N/A",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "GLOBAL_PRESSURE_TRIP_TRIGGER",
        "timestamp": 1706136929
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1235,
        "truckName": "testTruck2",
        "actionType": "Deactivated",
        "detail": "test details",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "FP3B DENSITY REQUESTED",
        "timestamp": 1706137608
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Deactivated",
        "detail": "",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "GLOBAL_PRESSURE_TRIP_TRIGGER",
        "timestamp": 1706136968
      },
      {
        "datavanPid": 12216357,
        "datavanName": "790019",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Dialog shown",
        "detail": "This is some test details",
        "newState": "test new state",
        "oldState": "test old state",
        "source": "LYNX",
        "subType": "-",
        "uiComponent": "CSV Data Unit Config Form",
        "timestamp": 1706137063
      }
    ]
  },

  auditForTruck: {
    auditLogs: [
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Activated",
        "detail": "test details for 790009",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "FP3B DENSITY REQUESTED",
        "timestamp": 1706137607
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Activated",
        "detail": "",
        "newState": "Test",
        "oldState": "N/A",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "GLOBAL_PRESSURE_TRIP_TRIGGER",
        "timestamp": 1706136929
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Deactivated",
        "detail": "test details",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "FP3B DENSITY REQUESTED",
        "timestamp": 1706137608
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Deactivated",
        "detail": "",
        "newState": "",
        "oldState": "",
        "source": "Spyglass",
        "subType": "Trigger",
        "uiComponent": "GLOBAL_PRESSURE_TRIP_TRIGGER",
        "timestamp": 1706136968
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Dialog shown",
        "detail": "This is some test details",
        "newState": "test new state",
        "oldState": "test old state",
        "source": "LYNX",
        "subType": "-",
        "uiComponent": "CSV Data Unit Config Form",
        "timestamp": 1706137063
      },
      {
        "datavanPid": 12216394,
        "datavanName": "790009",
        "truckPid": 1234,
        "truckName": "testTruck1",
        "actionType": "Dialog shown",
        "detail": "This is some test details",
        "newState": "test new state",
        "oldState": "test old state",
        "source": "LYNX",
        "subType": "-",
        "uiComponent": "CSV Data Unit Config Form",
        "timestamp": 1706137073
      }
    ]
  }
};

export {
  fetchAuditLogData, simData
}
