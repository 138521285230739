import _ from 'lodash';
import { getUniqueSeriesColor } from '../../../common/dataExplorationChart/services/dataExplorationChartService';

/**
 * Update definition when sensor selection is modified.
 * @param oldDefn Definition currently stored in state
 * @param newSensors New list of sensors (some may be repeated)
 * @param xAxisId Primary or Secondary
 * @return definition Updated definition with appropriate sensors added.
 */

const updateDefn = (oldDefn, newSensors, xAxisId) => {
    let definition = _.cloneDeep(oldDefn);

    // Find common sensors, don't modify these 
    let updatedSensorList = _.intersectionBy(oldDefn[xAxisId].sensors, newSensors, 'alias');
    let aliases = []
    updatedSensorList.forEach((s, index) => {
        const newUOM = _.find(newSensors, function (sensor) { return sensor?.alias === s?.alias; }).uom;
        s.uom = newUOM;
        aliases.push(s.alias);
    });

    const sensorsToAdd = _.differenceBy(newSensors, oldDefn[xAxisId].sensors, 'alias');

    sensorsToAdd.forEach(sensor => {
        if (!aliases.includes(sensor.alias)) {
            let sensorColor = sensor.color;
            if (_.isNil(sensorColor)) {
                sensorColor = getUniqueSeriesColor(updatedSensorList.map(s => s.conditionalFormatting.defaultSensorColor));
            };

            const modifiedSensor = _.omit(sensor, ['color', 'axisPosition']);

            updatedSensorList.push({
                ...modifiedSensor,
                displayName: sensor?.alias,
                isVisible: false,
                conditionalFormatting: {
                    applied: false,
                    defaultSensorColor:sensorColor,
                    rules: []
                }
            });
            aliases.push(sensor.alias);
        };
    });

    definition.primary.sensors = updatedSensorList;
    definition.primary.trucks = Array(newSensors.length).fill(oldDefn.primary.defaultTruck);

    return definition;
};

/**
 * Given a Definition that returned only the sensor configuration, which comes from the sensor selector for the data grid card config panel, only pick the following properties from
 * the Primary chart: 
 *  sensors
 * @param {*} definition 
 */
const sanitizeDefinition = (definition) => {
    let sensors = _.cloneDeep(definition.primary.sensors);
    sensors = _.map(sensors, element => { return _.omit(element, ['targetUoms']) });
    return { sensors: sensors }
};

export {
    updateDefn,
    sanitizeDefinition
};