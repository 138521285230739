import componentTypes from "../../../components/componentTypes";

const fetchPumpDashboardCards = () => {

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(JSON.stringify([
              { type: componentTypes.UNIT_HOURS, w: 3, h: 2, metaConfiguration: { configurable: true} },
              { type: componentTypes.UNIT_ACTIVITY, w: 2, h: 1, metaConfiguration: { configurable: false }  },
              { type: componentTypes.UNIT_FLEET, w: 2, h: 1, metaConfiguration: { configurable: false }  },
              { type: componentTypes.COMPONENT_LIFE, w: 3, h: 2, metaConfiguration: { configurable: true } },
                { type: componentTypes.UNIT_ALARM_COUNT, w: 2, h: 1,
                    metaConfiguration: { configurable: true} ,
                    configuration: {
                      columns: [
                          {name: "Severity", visible: true},
                          {name: "Details", visible: true},
                          {name: "State", visible: true},
                          {name: "Age", visible: true},
                          {name: "Component", visible: false},
                          {name: "Type", visible: false},
                          {name: "SubType", visible: false}
                      ]

                    },
                },
                { type: componentTypes.UNIT_USER_CHART, w: 4, h: 2, minW: 3, minH: 2, metaConfiguration: { configurable: true }, configuration: { canFreeSize: true } },
                { type: componentTypes.DATA_GRID, w: 2, h: 2, metaConfiguration: { configurable: true }},
                { type: componentTypes.UNIT_AUDIT_LOG, w: 4, h: 3, minW: 4, minH: 2, 
                    metaConfiguration: { configurable: true} ,
                    configuration: {
                        canFreeSize: true,
                        columns: [
                            {name: "ActionType", visible: true},
                            {name: "NewState", visible: true},
                            {name: "Age", visible: true},
                            {name: "TruckName", visible: false},
                            {name: "DatavanName", visible: false},
                            {name: "RaisedTimestamp", visible: false},
                            {name: "OldState", visible: false},
                            {name: "Source", visible: false},
                            {name: "Detail", visible: false},
                            {name: "SubType", visible: false},
                            {name: "UiComponent", visible: false}
                        ],
                        rowSizes: [25, 50, 100],
                        rowSize: 25 
                    },
                },
            ]))
        }, 1000)
    });

};

export { fetchPumpDashboardCards }
