import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import * as routeAssetMgmtServices from '../services/routeAssetMgmtServices';
import { routeAssetMgmtState } from "../routeAssetMgmtSelectors";

const initialState = routeAssetMgmtState();

const routeAssetMgmtDeleteConfirmationReducer = (state, action) => {
    switch (action.type) {
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING:
            return { ...state, queryRunning: true };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS:
            return {
                ...state,
                queryRunning: false,
                openDeleteUI: false,
                workingRouteAsset: initialState.workingRouteAsset,
                workingConsumer: initialState.workingConsumer
            };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR:
            return { ...state, queryRunning: false };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_OPEN_DELETE_CONFIRMATION:
            return { ...state, openDeleteUI: true, workingRouteAsset: routeAssetMgmtServices.processRouteAsset(action.routeAsset), workingConsumer: action.consumer  };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION:
            return { ...state, openDeleteUI: false, workingRouteAsset: initialState.workingRouteAsset };
        case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_STARTING:
           return {
               ...state,
               queryRunning: true,
               deleteConsumerError: null
           };
       case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_SUCCESS:
           return {
               ...state,
               queryRunning: false,
               openDeleteUI: false,
               workingConsumer: initialState.workingConsumer
           };
       case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_ERROR:
           return {
               ...state,
               queryRunning: false,
               deleteConsumerError: true
           };
       case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_OPEN_DELETE_CONFIRMATION:
           return {
               ...state,
               openDeleteUI: true,
               workingConsumer: action.consumer
           };
       case routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_CLOSE_DELETE_CONFIRMATION:
           return {
               ...state,
               openDeleteUI: false,
               workingConsumer: initialState.workingConsumer
           };
       default:
           return state;
    }
};

export default routeAssetMgmtDeleteConfirmationReducer;
