import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import fetchComponentLifeCurbsideCardData from './componentLifeCurbsideQueries';

import componentLifeCurbsideActionTypes from './componentLifeCurbsideActionTypes';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_CLEAR_DATA, 'statedef');

const queryData = queryActionFactory(
    componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_STARTING,
    componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_SUCCESS,
    componentLifeCurbsideActionTypes.COMPONENT_LIFE_CURBSIDE_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_COMPONENT_LIFE_CURBSIDE_CARD_DATA,
    fetchComponentLifeCurbsideCardData
);

export {
  clearData,
  queryData
};
