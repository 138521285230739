import { performDisplayFetch } from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as equipmentListSimulation from './equipmentListSimulation';

const fetchEquipmentList = () => {

  if (useSimulationData('equipmentList')) {
    return equipmentListSimulation.fetchEquipmentList();
  }

  return performDisplayFetch({
    'query': '{ pumpHoursDisplay { id name fleetId fleetName ecmHours pumpHours tcmHours timeStamp } }'
  })
};

export default fetchEquipmentList