import _ from "lodash";
import React, { Component } from 'react';
import { withProps,compose } from "recompose";
import {connect} from "react-redux";

import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Box, Button, Grid, Paper, Typography, Divider, Tooltip, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import getDialogStyles from '../../common/styles/dialogStyles';
import ComponentTypes from '../../componentTypes';
import {AutoCompleteMDT, DateTimePickerMDT} from '../../controls/mdtMuiControls';

import { jobHistoryState } from '../../../state/displays/jobHistory/jobHistorySelectors';
import * as jobHistoryActions from '../../../state/displays/jobHistory/jobHistoryActions';

const dialogStyles = getDialogStyles();
const styles = {
  customerSelection: {
    paddingLeft: '3px',
  },
  headerRow: {
    height: '46px',
    backgroundColor: 'grey.800',
    marginTop: '5px',
  },
  headerCell: {
    padding: 1,
    '& h3': {
      fontSize: 'typography.fontSize'
    }
  },
  table: {
    boxShadow: 'none'
  },
  tableHeaderColumn: {
    fontSize: '0.875rem',
    paddingTop: '5px',
  },
  tableRow: {
    height: '42px'
  },
  tableCellTypography: {
    fontSize: '0.875rem'
  }
};

class CreateEditJobDialog extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Dialog open={this.props.showCreateEditJobDialog} disableEscapeKeyDown PaperProps={{ sx: { minWidth: '1300px' }}}>
        <DialogTitle>{_.isNil(this.props.selectedJob.id) ? 'Create' : 'Edit'} a Job</DialogTitle>
        <DialogContent sx={{paddingBottom: 1}}>
          <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '100%'}}>

            {/* Job Fields */}
            <Box sx={{display: 'flex', flexFow: 'row nowrap', height: '50%'}}>
              <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '50%', justifyContent: 'space-evenly', padding: 1 }}>
                <TextField sx={{marginTop: '3px'}} autoFocus fullWidth margin='none' label='Job Name' size='small' variant='standard'
                  error={this.props.hasJobNameError} 
                  value={this.props.createEditJobName}
                  onChange={(event) => this.props.onCreateEditSetJobName(event.target.value)}
                  />
                <AutoCompleteMDT
                  sx={{marginTop: 1}}
                  options={this.props.customers}
                  value={this.props.createEditSelectedCustomer}
                  onChange={(event, value, reason) => { this.props.onCreateEditSelectCustomer(value); }}
                  noOptionsText={'No customers found...'}
                  renderInput={(params) => <TextField {...params} variant='standard' label='Customer' />} 
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePickerMDT 
                    disableFuture={false}
                    value={this.props.createEditStartDateDisplay}
                    onAccept={(value) => {
                      if (!_.isNil(value)) {
                        this.props.onCreateEditSetStartDate(value);
                      }
                    }}
                    onChange={(value) => {
                      if (!_.isNil(value)) {
                        this.props.onCreateEditSetStartDateDisplay(value);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} sx={{marginTop: 1}} error={this.props.hasStartDateError} variant='standard' label='Start Date' />}
                  />
                </LocalizationProvider>
                <AutoCompleteMDT
                  sx={{marginTop: 1}}
                  options={this.props.datavans}
                  value={this.props.createEditSelectedDatavan}
                  onChange={(event, value, reason) => { this.props.onCreateEditSelectDatavan(value); }}
                  noOptionsText={'No datavans found...'}
                  renderInput={(params) => <TextField {...params} error={this.props.hasDatavanError} variant='standard' label='Datavan' />} 
                />
              </Box>
              <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '50%', padding: 1 }}>
                <AutoCompleteMDT
                  sx={{marginTop: '1px'}}
                  options={this.props.jobTypes}
                  value={this.props.createEditSelectedJobType}
                  onChange={(event, value, reason) => { this.props.onCreateEditSelectJobType(value); }}
                  noOptionsText={'No job types found...'}
                  renderInput={(params) => <TextField {...params} error={this.props.hasJobTypeError} variant='standard' label='Job Type' />} 
                />
                <AutoCompleteMDT
                  sx={{marginTop: '10px'}}
                  options={_.isNil(this.props.createEditSelectedCustomer) ? [] : this.props.createEditSelectedCustomer.contacts}
                  value={this.props.createEditSelectedContact}
                  onChange={(event, value, reason) => { this.props.onCreateEditSelectContact(value); }}
                  noOptionsText={'No contacts found...'}
                  renderInput={(params) => <TextField {...params} variant='standard' disabled={_.isNil(this.props.createEditSelectedCustomer)} label='Contact' />} 
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePickerMDT 
                    disableFuture={false}
                    value={this.props.createEditEndDateDisplay}
                    onAccept={(value) => {
                      if (!_.isNil(value)) {
                        this.props.onCreateEditSetEndDate(value);
                      }
                    }}
                    onChange={(value) => {
                      if (!_.isNil(value)) {
                        this.props.onCreateEditSetEndDateDisplay(value);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} sx={{marginTop: '10px'}} error={this.props.hasEndDateError} variant='standard' label='End Date' />}
                  />
                </LocalizationProvider>
                <Box sx={{display: 'flex', flexFlow: 'row-reverse nowrap', flexGrow: 1, padding: 1.5}}>
                  <Button sx={{alignItems: 'center', position: 'relative', top: '36px', left: '4px'}} disableRipple color={'primary'} onClick={() => { this.props.onCreateEditAddWell(_.isNil(this.props.selectedJob) ? null : this.props.selectedJob.id); }}><AddIcon/>ASSIGN WELL</Button>
                </Box>
              </Box>
            </Box>

            {/* Wells */}
            <Box sx={{display: 'flex', flexFow: 'row nowrap', height: '50%'}}>
              <Grid container wrap='nowrap' direction='column' alignItems='center' sx={{width: '100%', height: '100%', padding: 2}}>
                {/* header */}
                <Grid container direction='row' alignItems='center' sx={styles.headerRow}>
                  <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Well</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Api Number</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '20%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Pad</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '15%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Lease Name</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '10%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Lease Number</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '10%'}} display='flex' justifyContent='left'>
                    <Typography sx={styles.tableHeaderColumn} variant={'subtitle1'}>Planned Stages</Typography>
                  </Grid>
                  <Grid item sx={{...styles.headerCell, width: '5%'}} display='flex' justifyContent='center'>
                  </Grid>
                </Grid>

                {/* contents */}
                <Paper sx={{...styles.table, width:'100%', height: '100%', borderRadius: '0px', maxHeight: '300px', overflowY: 'scroll'}}>
                  {
                    (_.filter(this.props.createEditWells, ['toDelete', false]).length === 0) &&
                    <Box sx={{width: '100%', height: '75px', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                      <Typography variant="subtitle1">No Wells Assigned to Job</Typography>
                    </Box>
                  }
                  {
                    (_.filter(this.props.createEditWells, ['toDelete', false]).length > 0) &&
                    _.map(_.filter(this.props.createEditWells, ['toDelete', false]), (well) => {

                      // Filters out wells that have already been picked AND not marked for delete
                      let availableWells = _.filter(this.props.wells, (w) => { return !_.some(this.props.createEditWells, {'wellName': w.name, 'toDelete': false})});

                      return (
                        <Grid key={well.wellId} container direction='row' alignItems='center' sx={styles.tableRow}>
                          <Grid item sx={{...dialogStyles.tableCellLeftMost, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                            <AutoCompleteMDT
                              fullWidth
                              // The following two settings (options, filterSelectedOptions) work together to allow for removing items already selected
                              options={[_.isNil(well.well) ? {value: null, label: ''} : well.well, ...availableWells]}
                              filterSelectedOptions
                              value={well.well}
                              onChange={(event, value, reason) => { this.props.onCreateEditSelectWell(well.wellId, value); }}
                              noOptionsText={'No wells found...'} 
                              renderInput={(params) => <TextField {...params} variant='standard' InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                            />
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCell, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                            <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.apiNumber}</Typography>
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCell, width: '20%', height: '42px'}} display='flex' justifyContent='left'>
                            <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.pad}</Typography>
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCell, width: '15%', height: '42px'}} display='flex' justifyContent='left'>
                            <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.leaseName}</Typography>
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCell, width: '10%', height: '42px'}} display='flex' justifyContent='left'>
                            <Typography sx={styles.tableCellTypography} variant={'subtitle1'}>{well.leaseNumber}</Typography>
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCell, width: '10%', height: '42px'}} display='flex' justifyContent='left'>
                            <TextField
                              sx={styles.tableCellTypography}
                              variant='standard'
                              size="small"
                              fullWidth
                              value={well.plannedStages}
                              onChange={(event) => { this.props.onCreateEditSetPlannedStages(well.wellId, event.target.value); }}
                              inputProps={{maxLength:255}}
                              InputProps={{disableUnderline: true }}
                            />
                          </Grid>
                          <Grid item sx={{...dialogStyles.tableCellRightMost, width: '5%', height: '42px'}} display='flex' justifyContent='center'>
                            {/* Action column */}
                            <IconButton
                              onClick={(event) => { this.props.onCreateEditRemoveWell(well.wellId); }}
                              size='small'>
                              <Tooltip title='Remove Well' followCursor={true}>
                                <DeleteOutlineIcon sx={dialogStyles.tableRowActionButton}/>
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Paper>

              </Grid>
            </Box>

            <Divider />
          </Box>
        </DialogContent>
        <DialogActions sx={{paddingTop: 1}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, width: '100%'}}>
            <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', width: '170px'}}>
              <Button 
                  sx={{
                    backgroundColor:'grey.200',
                    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                    '&:hover': {
                      backgroundColor:'grey.400',
                      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                    }
                  }}
                  disableRipple 
                  variant='contained' 
                  onClick={() => { this.props.onShowCreateEditJobDialog(false)}} 
                  >
                  CANCEL
                </Button>
              <Button onClick={() => {
                this.props.onSaveJob(
                  _.isNil(this.props.selectedJob) ? null : this.props.selectedJob.id,
                  this.props.createEditJobName,
                  this.props.createEditSelectedJobType,
                  this.props.createEditSelectedDatavan,
                  this.props.createEditSelectedContact,
                  this.props.createEditStartDateDisplay,
                  this.props.createEditEndDateDisplay,
                  this.props.createEditWells
                ); 
                this.props.onShowCreateEditJobDialog(false); 
                }} disabled={!this.props.createEditCanSave || !this.props.createEditHasChanges} variant='contained' >Save</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    )

  }

};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_HISTORY : props.stateKey,
      type: ComponentTypes.JOB_HISTORY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = jobHistoryState(state[stateDef.key]);
  return {
    showCreateEditJobDialog: componentState.showCreateEditJobDialog, 
    customers: componentState.customers,
    selectedJob: componentState.selectedJob,
    createEditJobName: componentState.createEditJobName,
    createEditSelectedCustomer: componentState.createEditSelectedCustomer,
    createEditStartDateDisplay: componentState.createEditStartDateDisplay,
    createEditEndDateDisplay: componentState.createEditEndDateDisplay,

    datavans: componentState.datavans,
    jobTypes: componentState.jobTypes,
    contacts: componentState.contacts,
    createEditSelectedDatavan: componentState.createEditSelectedDatavan,
    createEditSelectedJobType: componentState.createEditSelectedJobType,
    createEditSelectedContact: componentState.createEditSelectedContact,
    createEditWells: componentState.createEditWells,
    wells: componentState.wells,

    hasJobNameError: componentState.hasJobNameError,
    hasStartDateError: componentState.hasStartDateError,
    hasEndDateError: componentState.hasEndDateError,
    hasJobTypeError: componentState.hasJobTypeError,
    hasDatavanError: componentState.hasDatavanError,
    createEditCanSave: componentState.createEditCanSave,
    createEditHasChanges: componentState.createEditHasChanges,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onShowCreateEditJobDialog: (show) => { dispatch(jobHistoryActions.showCreateEditJobDialog(props.stateDef, show)); },
    onCreateEditSetStartDate: (startDate) => { dispatch(jobHistoryActions.createEditSetStartDate(props.stateDef, startDate)); },
    onCreateEditSetEndDate: (endDate) => { dispatch(jobHistoryActions.createEditSetEndDate(props.stateDef, endDate)); },
    onCreateEditSetStartDateDisplay: (startDate) => { dispatch(jobHistoryActions.createEditSetStartDateDisplay(props.stateDef, startDate)); },
    onCreateEditSetEndDateDisplay: (endDate) => { dispatch(jobHistoryActions.createEditSetEndDateDisplay(props.stateDef, endDate)); },
    onCreateEditSelectCustomer: (customer) => { dispatch(jobHistoryActions.createEditSelectCustomer(props.stateDef, customer)); },
    onCreateEditAddWell: (jobId) => { dispatch(jobHistoryActions.createEditAddWell(props.stateDef, jobId)); },
    onCreateEditRemoveWell: (wellId) => { dispatch(jobHistoryActions.createEditRemoveWell(props.stateDef, wellId)); },
    onCreateEditSelectWell: (wellId, well) => { dispatch(jobHistoryActions.createEditSelectWell(props.stateDef, wellId, well)); },
    onCreateEditSetPlannedStages: (wellId, stages) => { dispatch(jobHistoryActions.createEditSetPlannedStages(props.stateDef, wellId, stages)); },
    onCreateEditSetJobName: (name) => { dispatch(jobHistoryActions.createEditSetJobName(props.stateDef, name)); },
    onCreateEditSelectDatavan: (datavan) => { dispatch(jobHistoryActions.createEditSelectDatavan(props.stateDef, datavan)); },
    onCreateEditSelectJobType: (jobType) => { dispatch(jobHistoryActions.createEditSelectJobType(props.stateDef, jobType)); },
    onCreateEditSelectContact: (contact) => { dispatch(jobHistoryActions.createEditSelectContact(props.stateDef, contact)); },

    onSaveJob: (jobId, jobName, jobType, datavan, contact, start, end, wells) => { dispatch(jobHistoryActions.saveJob(props.stateDef, jobId, jobName, jobType, datavan, contact?.value, contact?.label, start, end, wells)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CreateEditJobDialog));