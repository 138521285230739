import _ from "lodash";
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from '../../app/actions/appErrorActions';

import { transformAxisToSensorGroup, getContextOwner, getUnitTypeFromContextOwner } from './services/sensorSelectorService';
import { fetchSensorSelectorSensors, fetchSharedSensorGroups, fetchDeleteSharedSensorGroups, fetchSaveSharedSensorGroup } from "./sensorSelectorQueries";
import sensorSelectorActionTypes from "./sensorSelectorActionTypes";

const querySensors = queryActionFactory(
  sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_STARTING,
  sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_SUCCESS,
  sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSORS_ERROR,
  "Error retrieving sensors",
  fetchSensorSelectorSensors
);

const querySensorGroupStarting = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_STARTING, 'stateDef');
const querySensorGroupSuccess = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_SUCCESS, 'stateDef', 'queryResults');
const querySensorGroupError = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_QUERY_SENSOR_GROUPS_ERROR, 'stateDef');

const querySensorGroups = (stateDef, namespace, unitType) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {
      await dispatch(querySensorGroupStarting(stateDef));

      // If unitType is available, then send the query to get the sensor groups
      if (!_.isNil(unitType)) {
        queryResults = await fetchSharedSensorGroups(unitType, namespace);
      }

      await dispatch(querySensorGroupSuccess(stateDef, queryResults));
    } catch (e) {
      await dispatch(querySensorGroupError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_SENSOR_GROUPS, e.message));
    }
  }
}

const setSensorSelectorSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SET_SELECTED_SENSORS, 'stateDef', 'sensors');
const addToSelectedSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_TO_SELECTED_SENSORS, 'stateDef', 'indexes');
const addGroupToSelectedSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_GROUP_TO_SELECTED_SENSORS, 'stateDef', 'indexes');
const removeFromSelectedSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_REMOVE_FROM_SELECTED_SENSORS, 'stateDef', 'indexes');
const toggleSelectIndexForAdd = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_ADD, 'stateDef', 'index');
const toggleSelectIndexForRemove = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_INDEX_FOR_REMOVE, 'stateDef', 'index');
const toggleSelectGroupIndexForAdd = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_TOGGLE_SELECT_GROUP_INDEX_FOR_ADD, 'stateDef', 'index');
const clearState = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_CLEAR_STATE, 'stateDef');

const setTrucks = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SET_TRUCKS, 'stateDef', 'trucks');

const setSelectedFormIndex = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SET_SELECTED_FORM_INDEX, 'stateDef', 'index');

const openContextMenu = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_OPEN_GROUPS_CONTEXT_MENU, 'stateDef', 'groupsContextMenuTargetElement', 'groupsContextMenuGroup');
const closeContextMenu = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_MENU, 'stateDef');
const openEditDialog = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_EDIT_DIALOG, 'stateDef', 'group');
const openDeleteConfirmation = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_OPEN_DELETE_CONFIRMATION, 'stateDef', 'groupToDelete');
const closeDeleteConfirmation = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteGroupStarting = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_STARTING, 'stateDef');
const deleteGroupSuccess = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_SUCCESS, 'stateDef', 'results');
const deleteGroupError = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CONTEXT_MENU_DELETE_ERROR, 'stateDef');

const deleteGroup = (stateDef, groupId, unitType, namespace) => {
  return async (dispatch, getState) => {
    let result = null;
    try {
      await dispatch(deleteGroupStarting(stateDef));

      result = await fetchDeleteSharedSensorGroups(groupId)
      await dispatch(deleteGroupSuccess(stateDef, result));
    } catch (e) {
      await dispatch(deleteGroupError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_DELETING_SENSOR_GROUP, e.message));
    }

    return dispatch(querySensorGroups(stateDef, namespace, unitType));
  }
};

const updatedSelectedSensorUom = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_UPDATE_UOM, 'stateDef', 'sensor', 'uom');
const openTargetUomMenu = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_OPEN_TARGET_UOM_MENU, 'stateDef', 'uomMenuTargetElement', 'sensor');
const closeTargetUomMenu = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SELECTED_SENSOR_CLOSE_TARGET_UOM_MENU, 'stateDef');

const openCreateSensorGroup = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_OPEN_CREATE_SENSOR_GROUP, 'stateDef');
const closeCreateSensorGroup = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_CLOSE_CREATE_SENSOR_GROUP, 'stateDef');
const setSensorGroupName = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_NAME, 'stateDef', 'name');
const setSensorGroupDescription = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_SENSOR_GROUP_DESCRIPTION, 'stateDef', 'description')
const setInitStateSensorGroup = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SET_INIT_STATE_SENSOR_GROUP, 'stateDef', 'groupToSave')

const saveSensorGroupStarting = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_STARTING, 'stateDef');
const saveSensorGroupSuccess = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_SUCCESS, 'stateDef', 'queryResults');
const saveSensorGroupError = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_GROUPS_SAVE_SENSOR_GROUP_ERROR, 'stateDef');

const saveSharedSensorGroup = (stateDef, groupToSave, unitType, axisDefinition, namespace) => {
  return async (dispatch, getState) => {
    let result = null;

    const isEditGroup = !_.isNil(groupToSave.id);

    try {
      await dispatch(saveSensorGroupStarting(stateDef));
      // Use name and unit type to convert the given axis definition to a ShareSensorGroupInput
      const sharedSensorGroupInput = transformAxisToSensorGroup(axisDefinition, groupToSave, unitType);

      // Delete sensor group that has the same name with the one to be saved
      if (isEditGroup) {
        await fetchDeleteSharedSensorGroups(groupToSave.id);
      }

      // Then save
      result = await fetchSaveSharedSensorGroup(sharedSensorGroupInput);
    } catch (e) {
      await dispatch(saveSensorGroupError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_SENSOR_GROUP, e.message));
    }
    await dispatch(saveSensorGroupSuccess(stateDef, result));

    //Refresh the list if it's editing.
    if (isEditGroup) {
      return dispatch(querySensorGroups(stateDef, namespace, unitType));
    }
  }
};

const removeAllSelectedSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_REMOVE_ALL_SELECTED_SENSORS, 'stateDef');
const addAllAvailableSensors = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_ADD_ALL_AVAILABLE_SENSORS, 'stateDef');

const onUpdateSensorSearch = defaultActionFactory(sensorSelectorActionTypes.SENSOR_SELECTOR_SEARCH_QUERY, 'stateDef', 'sensorSearchQuery');

export {
  querySensors,
  querySensorGroups,
  setSensorSelectorSensors,
  addToSelectedSensors,
  addGroupToSelectedSensors,
  removeFromSelectedSensors,
  toggleSelectIndexForAdd,
  toggleSelectIndexForRemove,
  toggleSelectGroupIndexForAdd,
  setTrucks,
  clearState,
  setSelectedFormIndex,
  openContextMenu,
  closeContextMenu,
  openEditDialog,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  deleteGroup,
  updatedSelectedSensorUom,
  openTargetUomMenu,
  closeTargetUomMenu,

  openCreateSensorGroup,
  closeCreateSensorGroup,
  setSensorGroupName,
  setSensorGroupDescription,
  setInitStateSensorGroup,
  saveSharedSensorGroup,

  removeAllSelectedSensors,
  addAllAvailableSensors,

  onUpdateSensorSearch
}