export default {
  SETTINGS_WARM_START_QUERY_STARTING: 'SETTINGS_WARM_START_QUERY_STARTING',
  SETTINGS_WARM_START_QUERY_SUCCESS: 'SETTINGS_WARM_START_QUERY_SUCCESS',
  SETTINGS_WARM_START_QUERY_ERROR: 'SETTINGS_WARM_START_QUERY_ERROR',
  SETTINGS_WARM_START_TRUCK_QUERY_STARTING: 'SETTINGS_WARM_START_TURCK_QUERY_STARTING',
  SETTINGS_WARM_START_TRUCK_QUERY_SUCCESS: 'SETTINGS_WARM_START_TURCK_QUERY_SUCCESS',
  SETTINGS_WARM_START_TRUCK_QUERY_ERROR: 'SETTINGS_WARM_START_TURCK_QUERY_ERROR',
  SETTINGS_WARM_START_SAVE_STARTING: 'SETTINGS_WARM_START_SAVE_STARTING',
  SETTINGS_WARM_START_SAVE_SUCCESS: 'SETTINGS_WARM_START_SAVE_SUCCESS',
  SETTINGS_WARM_START_SAVE_ERROR: 'SETTINGS_WARM_START_SAVE_ERROR',
  SETTINGS_WARM_START_SET_AVERAGE_FUEL_COST: 'SETTINGS_WARM_START_SET_AVERAGE_FUEL_COST',
  SETTINGS_WARM_START_REVERT_FORM: 'SETTINGS_WARM_START_REVERT_FORM',
  SETTINGS_WARM_START_TRUCK_SAVE_STARTING: 'SETTINGS_WARM_START_TRUCK_SAVE_STARTING',
  SETTINGS_WARM_START_TRUCK_SAVE_SUCCESS: 'SETTINGS_WARM_START_TRUCK_SAVE_SUCCESS',
  SETTINGS_WARM_START_TRUCK_SAVE_ERROR: 'SETTINGS_WARM_START_TRUCK_SAVE_ERROR',
  SETTINGS_WARM_START_TRUCK_SET_ROW_SIZE: 'SETTINGS_WARM_START_TRUCK_SET_ROW_SIZE',
  SETTINGS_WARM_START_SET_ROW_SIZE: 'SETTINGS_WARM_START_SET_ROW_SIZE'
};