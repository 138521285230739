const OWNERS = {
    MDT: 1,
    NEXTIER: 2,
    WEBBTEX: 3,
    NICHOLS_OIL_TOOLS: 4,
    DEMO: 8,
    GLADIATOR: 26,
    BLACKSTAR: 16,
    PUMPED_EQUIPMENT: 30,
    BEDROCK_ENERGY: 31,
    GOLIATH_PUMPING: 32,
};

export default OWNERS;