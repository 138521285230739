import {performDisplayFetch} from '../../common/fetch'
import useSimulationData from "../../../helpers/simulationHelper";
import * as dfpDashboardSimulation from "../dfpDashboard/dfpDashboardSimulation";

/**
 * Method to perform the remote query for the dfp dashboard display data
 */
const fetchDfpDashboardDisplayData = () => {

  if (useSimulationData('dfpDashboard')) {
    return dfpDashboardSimulation.fetchDfpUnits();
  }

  return performDisplayFetch({
    'query': '{ dfpDashboard { id name fleetId fleetName region division } }'
  })
};

const fetchDfpDashboardDataAge = (truckId) => {

  if (useSimulationData('dfpDashboard')) {
    return dfpDashboardSimulation.fetchDataAge();
  }

  return performDisplayFetch({
    'query': 'query TruckDataAge($truckId:Long!){ truckDataAge(truckId: $truckId) { timestamp }}',
    'variables': {
      'truckId': truckId
    }
  })
};

export {
  fetchDfpDashboardDisplayData,
  fetchDfpDashboardDataAge
};
