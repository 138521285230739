import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null
  };

  let flowRateSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.FLOW_RATE]);
  transformedSeries.series1 = unitChartService.transformSeries(flowRateSeries, 1);

  let suctionSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.SUCTION]);
  transformedSeries.series2 = unitChartService.transformSeries(suctionSeries, 1);

  let dischargeSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.DISCHARGE]);
  transformedSeries.series3 = unitChartService.transformSeries(dischargeSeries, 1);

  return transformedSeries;
};


export {
  transformSeries,
}
