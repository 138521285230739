import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import { Typography, Box } from '@mui/material';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import ComponentTypes from '../../componentTypes';

import chartOptions from './chartOptions';
import getCardStyles from '../../cards/cardStyles';

import { fleetPumpStatusState } from '../../../state/cards/fleetPumpStatus/fleetPumpStatusSelectors';
import * as fleetPumpStatusActions from '../../../state/cards/fleetPumpStatus/fleetPumpStatusActions';
import { mdtPalette } from '../../common/styles/mdtPalette';

const cardStyles = getCardStyles();

const styles = {
  ...cardStyles,

  // TODO START - Rename and move these to common card styles with legacy cards are updated
  cardContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardHeaderLabel: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
  },
  // TODO END - Rename and move these to common card styles with legacy cards are updated

  pumpCountContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1
  },

  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '110px'
  },

  legendContainer: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    paddingLeft: 1,
    justifyContent: 'center'
  },

  legendItem: {
    display:'flex',
    flexFlow:'row nowrap',
    alignItems: 'center'
  },

  legendValue: {
    width: '30px'
  }
};

class FleetPumpStatusCard extends Component {

  componentDidMount() {
    // Load the card data if we have a context.
    if (!_.isNil(this.props.selectedContext))  {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {

    // Refresh the card data when the context changes.
    if (prevProps.selectedContext !== this.props.selectedContext)  {
      this.refresh();
    }

    // Update the chart options if necessary
    if (prevProps.updateChartOptions === false && this.props.updateChartOptions === true) {
      this.props.setChartOptions(
        chartOptions(
          this.props.rawValuePumping,
          this.props.rawValueIdle,
          this.props.rawValueStopped,
          this.props.rawValueOffline
        )
      );
    }
  }

  refresh() {
    if (_.isNil(this.props.selectedContext)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.selectedContext.id);
    }
  }

  render() {
    return (
      <Box sx={styles.cardContainer}>
        {/* Card Header */}
        <Box sx={styles.cardHeader}>
          <Typography sx={styles.cardHeaderLabel} variant={'subtitle1'}>PUMP STATUS</Typography>
        </Box>
        {/* Card Content */}
        <Box sx={styles.cardContent}>
          {/* Pump Counts*/}
          <Box sx={styles.pumpCountContainer}>
            <Typography variant={'h3'}>{this.props.displayValueCount}</Typography>
            <Typography variant={'subtitle1'}>Pumps</Typography>
          </Box>
          {/* Chart */}
          <Box sx={styles.chartContainer}>
            { !_.isNil(this.props.rawValueCount) &&
              <HighchartsReact highcharts={Highcharts} options={this.props.chartOptions}/>
            }
          </Box>
          {/* Legend */}
          <Box sx={styles.legendContainer}>
            <Box sx={styles.legendItem}>
              <svg width="30" height="15"><rect width="15" height="15" style={{fill: mdtPalette().status.level2}} /></svg>
              <Typography variant={'subtitle1'} sx={styles.legendValue}>{this.props.displayValuePumping}</Typography>
              <Typography variant={'subtitle1'}>Pumping</Typography>
            </Box>
            <Box sx={styles.legendItem}>
              <svg width="30" height="15"><rect width="15" height="15" style={{fill: mdtPalette().status.level4}} /></svg>
              <Typography variant={'subtitle1'} sx={styles.legendValue}>{this.props.displayValueIdle}</Typography>
              <Typography variant={'subtitle1'}>Idle</Typography>
            </Box>
            <Box sx={styles.legendItem}>
              <svg width="30" height="15"><rect width="15" height="15" style={{fill: mdtPalette().status.level5}} /></svg>
              <Typography variant={'subtitle1'} sx={styles.legendValue}>{this.props.displayValueStopped}</Typography>
              <Typography variant={'subtitle1'}>Stopped</Typography>
            </Box>
            <Box sx={styles.legendItem}>
              <svg width="30" height="15"><rect width="15" height="15" style={{fill: mdtPalette().status.level1}} /></svg>
              <Typography variant={'subtitle1'} sx={styles.legendValue}>{this.props.displayValueOffline}</Typography>
              <Typography variant={'subtitle1'}>Offline</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_PUMP_STATUS : props.stateKey,
      type: ComponentTypes.FLEET_PUMP_STATUS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetPumpStatusState(state[stateDef.key]);

  return {
    rawValueCount: componentState.rawValueCount,
    rawValuePumping: componentState.rawValuePumping,
    rawValueIdle: componentState.rawValueIdle,
    rawValueStopped: componentState.rawValueStopped,
    rawValueOffline: componentState.rawValueOffline,
    displayValueCount: componentState.displayValueCount,
    displayValuePumping: componentState.displayValuePumping,
    displayValueIdle: componentState.displayValueIdle,
    displayValueStopped: componentState.displayValueStopped,
    displayValueOffline: componentState.displayValueOffline,
    updateChartOptions: componentState.updateChartOptions,
    chartOptions: componentState.chartOptions,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: (context) => { dispatch(fleetPumpStatusActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetPumpStatusActions.clearData(props.stateDef)) },
    setChartOptions: (chartOptions) => { dispatch(fleetPumpStatusActions.setChartOptions(props.stateDef, chartOptions)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetPumpStatusCard));


