import _ from 'lodash';

const providerState = state => {

  return {

    provider: {
      rowsPerPage: _.isNil(state) ? 10 : state.provider.rowsPerPage,
      rowsPerPageOptions: [5,10,25],

      providerCanSave: _.isNil(state) ? true : state.provider.providerCanSave,
      loadingProviderSetting: _.isNil(state) ? false : state.provider.loadingProviderSetting,
      providerSettings: _.isNil(state) ? [] : state.provider.providerSettings,

      showProviderDialog: _.isNil(state) ? false : state.provider.showProviderDialog,
      providerDialogContext: _.isNil(state) ?  null  : state.provider.providerDialogContext,
      showDeleteProviderDialog: _.isNil(state) ? false : state.provider.showDeleteProviderDialog,
    }

  };

};

export {
  providerState
};