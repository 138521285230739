import _ from 'lodash';
import moment from 'moment';

import searchActionTypes from '../../../common/search/searchActionTypes';
import * as assetTrackingService from '../services/assetTrackingService';

const searchReducer = (state, action) => {
    switch (action.type) {

    case searchActionTypes.SEARCH_PERFORM_SEARCH:
        return performSearch (state, action);

    case searchActionTypes.SEARCH_CLEAR_SEARCH:
        return clearSearch(state, action);

    case searchActionTypes.SEARCH_UPDATE_SEARCH_TERM:
        return updateSearchTerm(state, action);

    default: return state;

    }
};

const performSearch = (state, action) => {
    // No term to search for? Just return the old state
      if (_.isNil(state.searchTerm)) {
        return state;
      }

      let processedSearch = assetTrackingService.searchAssets(state.filteredAssets, state.searchTerm);

      return {
        ...state,
        searchResults: processedSearch,
        searchNoMatch: _.isNil(processedSearch) ? true : false,
        refreshTimestamp: moment().unix()
      };

}

const clearSearch = (state, action) => {

      return {
        ...state,
        searchTerm: '',
        searchResults: null,
        searchNoMatch: false,
        refreshTimestamp: moment().unix()
      };
}

const updateSearchTerm = (state, action) => {
  return {
    ...state,
    searchTerm: action.searchTerm,
  }
}

export default searchReducer