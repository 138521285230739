import React, {Component} from 'react';
import SvgIcon from '@mui/material/SvgIcon';

class AlarmCriticalBadge extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M16.924,0.11l-9.848,0l-6.966,6.966l0,9.848l6.966,6.966l9.848,0l6.966,-6.966l0,-9.848l-6.966,-6.966Z"/>
      </SvgIcon>
    )
  }
}

export default AlarmCriticalBadge;
