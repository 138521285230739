import _ from 'lodash';
import providerTypes from '../../../../../../components/common/providerTypes';

/**
 * Given a context object to resolve relevant information
 * so we can use it in a Provider Dialog component.
 * @param {*} context A provider object contains its name, type & configs.
 */
const resolveContextEntityForProviderDialogState = (context) => {

  const noData = {};

  if(_.isNil(context)){
    return noData;
  }
  
  switch (context.type.value)
  {
    case providerTypes.TEAMS:
      return parseTeamsEntity(context);
    case providerTypes.TWILIO:
      return parseTwilioEntity(context);
    default:
      return noData;
  }
  
};

const parseTeamsEntity = (context) => {

  const resolvedContext = {};

  // Set the name
  resolvedContext.providerName = context.name;

  // Set the type
  resolvedContext.providerType = context.type;

  // If there are team channels in the Provider object, then let's populate the team channels in the dialog
  let channelsCreated = [];
  if (!_.isEmpty(context.config)) {
    const jsonValues = JSON.parse(context.config);
    if(!_.isNil(jsonValues.channels)){
      //parse config for Team channels
      let index = 0;
      _.forEach(jsonValues.channels, (channel) => {

        channelsCreated.push(
              {
                id: index,
                name: channel.name,
                webhook: channel.webhook,
              });

        index++;
      });
    }
  }

  //set the new values for teams channels
  resolvedContext.configs = {};
  resolvedContext.configs.teamsChannels = channelsCreated;

  return resolvedContext;
}

const parseTwilioEntity = (context) => {

  const resolvedContext = {};

  // Set the name
  resolvedContext.providerName = context.name;

  // Set the type
  resolvedContext.providerType = context.type;

  if (!_.isEmpty(context.config)) {
    const jsonValues = JSON.parse(context.config);

    resolvedContext.configs = {};
    resolvedContext.configs.teamsChannels = [];

    // Set the twilioAcctSid
    resolvedContext.configs.twilioAcctSid = jsonValues?.accountSid;

    // Set the twilioAuthToken
    resolvedContext.configs.twilioAuthToken = jsonValues?.authToken;

    // Set the twilioFromNumber
    resolvedContext.configs.twilioFromNumber = jsonValues?.from;
  }

  return resolvedContext;
}

export {
  resolveContextEntityForProviderDialogState,
}