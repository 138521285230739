import _ from 'lodash';
import providerActionTypes from '../actionTypes/providerActionTypes';
import providerTypes from '../../../../components/common/providerTypes';

const providerReducer = (state, action) => {

  switch (action.type) {

    case providerActionTypes.SETTINGS_PROVIDER_QUERY_STARTING:
      return onProviderQueryStarting(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_QUERY_ERROR:
      return onProviderQueryError(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_QUERY_SUCCESS:
      return onProviderQuerySuccess(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_SAVE_STARTING:
      return onProviderSaveStarting(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_SAVE_ERROR:
      return onProviderSaveError(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_SAVE_SUCCESS:
      return onProviderSaveSuccess(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_DELETE_STARTING:
      return onProviderDeleteStarting(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_DELETE_ERROR:
      return onProviderDeleteError(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_DELETE_SUCCESS:
      return onProviderDeleteSuccess(state, action);

    case providerActionTypes.SETTINGS_SHOW_PROVIDER_DIALOG:
      return onShowProviderDialog(state, action);

    case providerActionTypes.SETTINGS_SHOW_DELETE_PROVIDER_DIALOG:
      return onShowDeleteProviderDialog(state, action);

    case providerActionTypes.SETTINGS_PROVIDER_SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);

    default: return state;
  }
};

const onProviderQueryStarting = (state, action) => {
  return {
    ...state,
    provider: {
      ...state.provider,
      loadingProviderSetting: true,
      queryRunning: true,
    }
  };
};

const onProviderQueryError = (state, action) => {
  return {
    ...state,
    provider: {
      ...state.provider,
      loadingProviderSetting: false,
      queryRunning: false,
    }
  };
};

const onProviderQuerySuccess = (state, action) => {

  const providerDetails = _.cloneDeep(action.queryResults.providerDetails);
  //align label/value data structure for display support
  providerDetails.forEach((provider) => {
    if(provider.type === providerTypes.TEAMS){
      provider.type = {label:"Microsoft Teams", value:providerTypes.TEAMS};
    }else if(provider.type === providerTypes.TWILIO){
      provider.type = {label:"Twilio SMS", value:providerTypes.TWILIO};
    }

  });

  return {
    ...state,
    provider: {
      ...state.provider,
      loadingProviderSetting: false,
      queryRunning: false,
      providerSettings: providerDetails
    }
  };
};

const onProviderSaveStarting = (state, action) => {
  return {
    ...state,
    provider: {
      ...state.provider,
      queryRunning: true,
    }
  };
};

const onProviderSaveError = (state, action) => {
  return {
    ...state,
    provider: {
      ...state.provider,
      queryRunning: false,
    }
  };
};

const parseProviderType = (providerType) => {
  if(providerType === providerTypes.TEAMS){
    return {label:"Microsoft Teams", value:providerTypes.TEAMS};
  }else if(providerType === providerTypes.TWILIO){
    return  {label:"Twilio SMS", value:providerTypes.TWILIO};
  }else{
    return null;
  }
};

const onProviderSaveSuccess = (state, action) => {
  const updatedProviderSettings = _.cloneDeep(state.provider.providerSettings);

  //align type to label/value pair format
  action.queryResults.saveProvider.type = parseProviderType(action.queryResults.saveProvider.type);

  // Update the saved provider
  let foundIndex = _.findIndex(updatedProviderSettings, ['id', action.queryResults.saveProvider.id]);
  if (!_.isNil(updatedProviderSettings[foundIndex])) {
    updatedProviderSettings[foundIndex] = action.queryResults.saveProvider;
  } else {
    updatedProviderSettings.push( action.queryResults.saveProvider);
  }

  return {
    ...state,
    provider: {
      ...state.provider,
      providerSettings: updatedProviderSettings
    },
    queryRunning: false,
  }
};

const onProviderDeleteStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,    
  };
};

const onProviderDeleteError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

const onProviderDeleteSuccess = (state, action) => {
  const updatedProviderSettings = _.cloneDeep(state.provider.providerSettings);

  // Update the saved provider
  _.remove(updatedProviderSettings, (x) => { return x.id === action.queryResults.providerId; });

  return {
    ...state,
    provider: {
      ...state.provider,
      providerSettings: updatedProviderSettings
    },
    queryRunning: false,
  }
};

const onShowProviderDialog = (state, action) => {
  return {
    ...state,
    provider: {
      ...state.provider,
      showProviderDialog: action.show,
      providerDialogContext: action.context,
    }
  }
}

const onShowDeleteProviderDialog = (state, action) => {

  return {
    ...state,
    provider: {
      ...state.provider,
      showDeleteProviderDialog: action.show,
      providerDialogContext: action.context,
    }
  }

}

const onDataGridSetRowSize = (state, action) => {
  
  return {
    ...state,
    provider: {
      ...state.provider,
      rowsPerPage: action.rowSize,
    }
  }

}


export default providerReducer