import _ from 'lodash';
import {normalizeAlarmCounts} from "../../common/alarmCountNormalization";

const processDetails = (queryResults) => {

  let state = _.cloneDeep(queryResults.healthFleetMap.healthViewData);

  if (!_.isNil(state.blender1)) {
    state.blender1.alarmCounts = normalizeAlarmCounts(state.blender1.alarmCounts);
  }
  if (!_.isNil(state.blender2)) {
    state.blender2.alarmCounts = normalizeAlarmCounts(state.blender2.alarmCounts);
  }
  if (!_.isNil(state.hydration)) {
    state.hydration.alarmCounts = normalizeAlarmCounts(state.hydration.alarmCounts);
  }
  if (!_.isNil(state.chemvan)) {
    state.chemvan.alarmCounts = normalizeAlarmCounts(state.chemvan.alarmCounts);
  }
  if (!_.isNil(state.datavan)) {
    state.datavan.alarmCounts = normalizeAlarmCounts(state.datavan.alarmCounts);
  }

  let pumpsWithMissileData = [];

  for (let i = 0; i < state.pumps.length; i++) {
    pumpsWithMissileData.push({
      ...state.pumps[i],
      ...(state.missile.find((missileItem) => missileItem.slot === state.pumps[i].slot))
    });
  }

  return {
    ...state,
    pumps: _.map(pumpsWithMissileData, parsePump),
  }
};


const parsePump = (pump) => {

  if (!pump) {
    return null;
  }

  let { powerEnd, fluidEnd, iron, value, alarmCounts } = pump;

  let displayPowerEnd = _.isNil(powerEnd) ? '-' : powerEnd.toFixed(1);
  let displayFluidEnd = _.isNil(fluidEnd) ? '-' : fluidEnd.toFixed(1);
  let displayIron = _.isNil(iron) ? '-' : iron.toFixed(1);
  let displayMissile = _.isNil(value) ? '-' : value.toFixed(1);
  let displayAlarmCounts = normalizeAlarmCounts(alarmCounts);

  return {
    ...pump,
    powerEnd: displayPowerEnd,
    fluidEnd: displayFluidEnd,
    iron: displayIron,
    value: displayMissile,
    alarmCounts: displayAlarmCounts,
  }

};

export {
  processDetails
}