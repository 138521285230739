import React, {Component} from 'react';
import _ from 'lodash';
import moment from "moment";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import LayoutFixedWidth from '../../controls/layout/layoutFixedWidth';
import FleetClientStatus from "../../cards/fleetClientStatus/fleetClientStatus";
import UnitChartCoilDetail from "../../cards/unitChart/unitChartCoilDetail";
import UnitChartCoil from "../../cards/unitChart/unitChartCoil";
import UnitChartPressureHistoryRoadside from '../../cards/unitChart/unitChartPressureHistoryRoadside';
import UnitChartPressureHistoryCurbside from '../../cards/unitChart/unitChartPressureHistoryCurbside';

import ComponentTypes from '../../componentTypes';
import { trackPage } from '../../../helpers/googleAnalyticsHelper';


class DashboardView extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.COIL_DASHBOARD, this.props.user);
  }

  render() {
      let endTime = moment().startOf('minute').unix();
      let startTime = moment.unix(endTime).subtract(6, 'hours').unix();

      let fleet = _.isNil(this.props.coilTubingUnit) ? null : this.props.coilTubingUnit.fleet;

      let cardsLayout = [
        {i: 'coilDetail',  x: 0, y: 0, w: 4, h: 2 },
        {i: 'coil',  x: 4, y: 0, w: 4, h: 2 },
        {i: 'roadside', x: 0, y: 2, w: 4, h: 2 },
        {i: 'curbside',  x: 4, y: 2, w: 4, h: 2 },
        {i: 'status',  x: 0, y: 4, w: 2, h: 1 },
      ];

      return (
      <LayoutFixedWidth layout={cardsLayout} cols={9} rowHeight={160} width={1540}>
        <div key={'coilDetail'}>
          <UnitChartCoilDetail cardKey='coilDetail' dashboard={ComponentTypes.COIL_DASHBOARD} context={{truck: this.props.coilTubingUnit, startTime:startTime, endTime:endTime}} editMode={this.props.editMode} />
        </div>
        <div key={'coil'}>
          <UnitChartCoil cardKey='coil' dashboard={ComponentTypes.COIL_DASHBOARD} context={{truck: this.props.coilTubingUnit, startTime:startTime, endTime:endTime}} editMode={this.props.editMode} />
        </div>
        <div key={'roadside'}>
          <UnitChartPressureHistoryRoadside cardKey='roadside' dashboard={ComponentTypes.COIL_DASHBOARD} context={{truck: this.props.coilTubingUnit, startTime:startTime, endTime:endTime}} configuration={{showSuction:false}} editMode={this.props.editMode} />
        </div>
        <div key={'curbside'}>
          <UnitChartPressureHistoryCurbside cardKey='curbside' dashboard={ComponentTypes.COIL_DASHBOARD} context={{truck: this.props.coilTubingUnit, startTime:startTime, endTime:endTime}} configuration={{showSuction:false}} editMode={this.props.editMode} />
        </div>
        <div key={'status'}>
          <FleetClientStatus selectedContext={fleet} />
        </div>
      </LayoutFixedWidth>
    )
  }
}

DashboardView.propTypes = {
  coilTubingUnit: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {}
};

export default connect(mapStateToProps)(DashboardView)
