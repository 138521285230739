import * as alarmsSimulation from './alarmsSimulation';
import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchActiveAlarmDetails = () => {

  if (useSimulationData('alarms')) {
    return alarmsSimulation.fetchActiveAlarmDetails();
  }

  return performDisplayFetch({
    'query': '{ activeAlarmDetails { unitName unitType fleetName severity state type details category raisedTimestamp acknowledgedTimestamp } }'
  })
};

const fetchAlarmHistoryDetails = (startTime, endTime) => {

  if (useSimulationData('alarms')) {
    return alarmsSimulation.fetchAlarmHistoryDetails(startTime, endTime);
  }

  return performDisplayFetch({
    'query': 'query historicalAlarmDetails($timeRange:HistoricalAlarmsInput!) { historicalAlarmDetails(timeRange:$timeRange) { unitName unitType fleetName severity state type details category timestamp } }',
    'variables': {
      'timeRange': {
        'startTime': startTime,
        'endTime': endTime
      }
    }
  })
};

export {
  fetchActiveAlarmDetails,
  fetchAlarmHistoryDetails
}


