import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';
import deviceActionTypes from "../actionTypes/deviceActionTypes";
import * as deviceQueries from '../queries/deviceQueries';
import { handleError } from '../../../app/actions/appErrorActions';
import _ from "lodash";
import ComponentTypes from "../../../../components/componentTypes";

const deviceQueryDataStarting = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_QUERY_STARTING, 'stateDef');
const deviceQueryDataSuccess = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_QUERY_SUCCESS, 'stateDef', 'queryResults');
const deviceQueryDataError = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_QUERY_ERROR, 'stateDef');

const queryDeviceSettings = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(deviceQueryDataStarting(stateDef));
      queryResults = await deviceQueries.fetchDeviceSettings();

      await dispatch(deviceQueryDataSuccess(stateDef, queryResults));
    }catch(e) {
      await dispatch(deviceQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_DEVICE_SETTINGS, e.message));
    }
  }
};

const trucksQueryDataStarting = defaultActionFactory(deviceActionTypes.SETTINGS_TRUCK_QUERY_STARTING, 'stateDef');
const trucksQueryDataSuccess = defaultActionFactory(deviceActionTypes.SETTINGS_TRUCK_QUERY_SUCCESS, 'stateDef', 'queryResults');
const trucksQueryDataError = defaultActionFactory(deviceActionTypes.SETTINGS_TRUCK_QUERY_ERROR, 'stateDef');

const queryTrucksForOwner = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(trucksQueryDataStarting(stateDef));
      queryResults = await deviceQueries.fetchTrucksForOwner();

      await dispatch(trucksQueryDataSuccess(stateDef, queryResults));
    }catch(e) {
      await dispatch(trucksQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_DEVICE_TRUCKS, e.message));
    }
  }
};

const saveDeviceSettingsStarting = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_SAVE_STARTING, 'stateDef');
const saveDeviceSettingsSuccess = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_SAVE_SUCCESS, 'stateDef', 'queryResults');
const saveDeviceSettingsError = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_SAVE_ERROR, 'stateDef');

/**
 * function to create/update the device settings for a device
 * if function called from device creation dialog, the deviceContext will be null and the deviceName will be used to create a new device in backend
 * if function called from device display dropdown setting for owner change, it will use the deviceContext with id to update the existing device settings in backend
 */
const saveDeviceSettings = (stateDef, deviceContext, owner, deviceName) => {
  return async (dispatch, getState) => {

    let queryResults = null;
    let stateDefinition = stateDef;
    const esn = _.isNil(deviceContext) ? deviceName : deviceContext.esn;

    try {
      // We maybe calling this from DEVICE_DIALOG
      // so let's make sure we use the Settings stateKey
      if (stateDefinition.key !== ComponentTypes.SETTINGS) {
        stateDefinition = {
          key: ComponentTypes.SETTINGS,
          type: ComponentTypes.SETTINGS,
        }
      }

      if( !_.isNil(deviceContext) && !_.isNil(deviceContext.truckPid)){
        // if device already paired to a truck,
        // call savePairSettings to unpair the device from the truck, since the device already assigned to new owner
        await dispatch(savePairSettings(stateDef, deviceContext, {label: null, value: null}));
      }

      await dispatch(saveDeviceSettingsStarting(stateDefinition));

      const deviceToSave =
          {
            id: _.isNil(deviceContext) ? null : deviceContext.id,
            truckPid: null, //when assign device to new owner, truckPid should always be null
            ownerId: owner.value,
            esn: esn,
            active: true,
          }
      queryResults = await deviceQueries.saveDeviceSettings(deviceToSave);

      await dispatch(saveDeviceSettingsSuccess(stateDefinition, queryResults));
    }catch(e) {
      await dispatch(saveDeviceSettingsError(stateDefinition));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DEVICE_SETTINGS, e.message));
    }

    // reuse the handle error for now to show a message on the snackbar
    return dispatch(handleError(`Assigned device '${esn}' to owner '${owner.label}'.`, null));
  }
};


const savePairSettingsStarting = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_STARTING, 'stateDef');
const savePairSettingsSuccess = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_SUCCESS, 'stateDef', 'esn', 'newTruckPid');
const savePairSettingsError = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_PAIR_TRUCK_ERROR, 'stateDef');

const savePairSettings = (stateDef, deviceContext, truck) => {
  return async (dispatch, getState) => {
    let stateDefinition = stateDef;

    try {
      // We maybe calling this from DEVICE_DIALOG
      // so let's make sure we use the Settings stateKey
      if (stateDefinition.key !== ComponentTypes.SETTINGS) {
        stateDefinition = {
          key: ComponentTypes.SETTINGS,
          type: ComponentTypes.SETTINGS,
        }
      }

      await dispatch(savePairSettingsStarting(stateDefinition));

      let deviceToPair =
          {
            truckPid: truck.value,
            esn: _.isNil(deviceContext) ? null : deviceContext.esn
          }
      await deviceQueries.pairDeviceSettings(deviceToPair);

      await dispatch(savePairSettingsSuccess(stateDefinition, deviceContext.esn, truck.value));
    }catch(e) {
      await dispatch(savePairSettingsError(stateDefinition));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DEVICE_SETTINGS, e.message));
    }

    // reuse the handle error for now to show a message on the snackbar
    let responseMsg = `Paired device '${deviceContext.esn}' with truck '${truck.label}'.`;
    if(truck.value === null) responseMsg = `Unpaired device '${deviceContext.esn}' from truck.`;
    return dispatch(handleError(responseMsg, null));
  }
};

const showDeviceDialogSetting = defaultActionFactory(deviceActionTypes.SETTINGS_SHOW_DEVICE_DIALOG, 'stateDef','show');

const showDeviceDialog = (stateDef, show) => {
  return async (dispatch, getState) => {
    let stateDefinition = stateDef;

    // We maybe calling this from DEVICE_DIALOG component
    // so let's make sure we use the SETTINGS stateKey
    if (stateDefinition.key !== ComponentTypes.SETTINGS) {
      stateDefinition = {
        key: ComponentTypes.SETTINGS,
        type: ComponentTypes.SETTINGS,
      }
    }
    await dispatch(showDeviceDialogSetting(stateDefinition, show));
  }
};


const dataGridSetRowSize = defaultActionFactory(deviceActionTypes.SETTINGS_DEVICE_SET_ROW_SIZE, 'stateDef', 'rowSize');

export {
  queryDeviceSettings,
  savePairSettings,
  saveDeviceSettings,
  showDeviceDialog,
  dataGridSetRowSize,
  queryTrucksForOwner
};