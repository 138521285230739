import _ from "lodash";

const wellsDisplayState = state => {

    return {
        queryRunning: _.isNil(state?.queryRunning) ? false : state.queryRunning,
        wells: _.isNil(state) ? [] : state.wells,
        rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
        rowsPerPageOptions: [25,50,100],
        wellToDelete: _.isNil(state) ? {} : state.wellToDelete,
        wellForSave: _.isNil(state?.wellForSave) ? {} : state.wellForSave,
        originalWellForSave: _.isNil(state?.originalWellForSave) ? {} : state.originalWellForSave,
        canDelete: _.isNil(state?.canDelete) ? false : state.canDelete,
        errorsOnWellSave: _.isNil(state?.errorsOnWellSave) ? {} : state.errorsOnWellSave,
        openDeleteConfirmation: _.isNil(state?.openDeleteConfirmation) ? false : state.openDeleteConfirmation,
        openSaveWell: _.isNil(state?.openSaveWell) ? false : state.openSaveWell,

        showWellInUseMessage: _.isNil(state) ? false : state.showWellInUseMessage,
        wellInUseMessage: _.isNil(state) ? {} : state.wellInUseMessage,
    };
};

export {
    wellsDisplayState
};