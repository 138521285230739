import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';
import expectedLifeActionTypes from "../actionTypes/expectedLifeActionTypes";
import * as expectedLifeQueries from '../queries/expectedLifeQueries';
import { handleError } from '../../../app/actions/appErrorActions';

const setExpectedLifeUnitType = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SET_SELECTED_UNIT_TYPE, 'stateDef', 'unitType');
const setExpectedLifeValue = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SET_EXPECTED_LIFE, 'stateDef', 'componentTypeId', 'expectedLife');
const revertForm = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_REVERT_FORM, 'stateDef');

const expectedLifeQueryDataStarting = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_STARTING, 'stateDef');
const expectedLifeQueryDataSuccess = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_SUCCESS, 'stateDef', 'queryResults');
const expectedLifeLifeQueryDataError = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_QUERY_ERROR, 'stateDef');

const queryExpectedLifeSettings = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(expectedLifeQueryDataStarting(stateDef));
      queryResults = await expectedLifeQueries.fetchExpectedLifeSettings();
    }catch(e) {
      await dispatch(expectedLifeLifeQueryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_EXPECTED_LIFE_SETTINGS, e.message));
    }
    await dispatch(expectedLifeQueryDataSuccess(stateDef, queryResults));
    return dispatch(setExpectedLifeUnitType(stateDef, getState()[stateDef.key].expectedLife.expectedLifeSelectedUnitType))
  }
};

const saveExpectedLifeSettingsStarting = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_STARTING, 'stateDef');
const saveExpectedLifeSettingsSuccess = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_SUCCESS, 'stateDef', 'queryResults');
const saveExpectedLifeSettingsError = defaultActionFactory(expectedLifeActionTypes.SETTINGS_EXPECTED_LIFE_SAVE_ERROR, 'stateDef');

const saveExpectedLifeSettings = (stateDef, componentTypes) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(saveExpectedLifeSettingsStarting(stateDef));
      queryResults = await expectedLifeQueries.fetchSaveExpectedLifeSettings(componentTypes);
    }catch(e) {
      await dispatch(saveExpectedLifeSettingsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_EXPECTED_LIFE_SETTINGS, e.message));
    }
    await dispatch(saveExpectedLifeSettingsSuccess(stateDef, queryResults));
    return dispatch(queryExpectedLifeSettings(stateDef));
  }
};

export {
  queryExpectedLifeSettings,
  saveExpectedLifeSettings,
  setExpectedLifeUnitType,
  revertForm,
  setExpectedLifeValue,
};