import _ from 'lodash';

const processPumpStatusInfo = (pumpStatusInfo) => {

  const noValue = '-';
  const noValueObj = { display: noValue, raw: null };
  const { count, pumping, idle, stopped, offline } = _.isNil(pumpStatusInfo) ? {} : pumpStatusInfo;

  return {
    count: _.isNil(count) ? noValue : count,
    pumping: _.isNil(pumping) ? noValueObj : { display: pumping, raw: pumping},
    idle: _.isNil(idle) ? noValueObj : { display: idle, raw: idle},
    stopped: _.isNil(stopped) ? noValueObj : { display: stopped, raw: stopped},
    offline: _.isNil(offline) ? noValueObj : { display: offline, raw: offline},
  };
};

export {
  processPumpStatusInfo
}