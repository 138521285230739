import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";

import { Box, Typography, IconButton, List, ListItem, ListItemButton, ListItemText, Divider, Card, CardContent, Checkbox, Button, Tooltip } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { mdtPalette } from '../../common/styles/mdtPalette';
import Progress from '../../controls/progress';
import ComponentTypes from '../../componentTypes';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';

import { ownerManagementState } from '../../../state/displays/ownerManagement/ownerManagementSelectors';
import * as ownerManagementActions from '../../../state/displays/ownerManagement/ownerManagementActions';
import CreateOwnerDialog from './createOwnerDialog';

const detailsPageStyles = getDetailsPageStyles();
const styles = {
  ...detailsPageStyles,

  detailsPage: {
    ...detailsPageStyles.detailsPage,
    height: '100vh'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw',
    height: 'inherit'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  detailsPageHeaderTitle: {
    ...detailsPageStyles.detailsPageHeaderTitle,
    width: '250px'
  },
  
  ownerSelection: {
    paddingLeft: '3px',
    width: '200px'
  }
}

const ownerSettings =
[
  { id: 1, label: 'Component Life Tracking'},
  { id: 2, label: 'Subscriptions'},
  { id: 3, label: 'Displays'}
]

const componentLifeTrackingOptions =
[
  { id: 1, label: 'This owner has Frac Pumps'},
  { id: 2, label: 'This owner has Blenders'},
  { id: 3, label: 'This owner has Chemvans'},
  { id: 4, label: 'This owner has Hydration'},
  { id: 5, label: 'This owner has DFPs'},
  { id: 6, label: 'This owner wants to track Oil/Lube Changes'},
]

const subscriptionsOptions =
[
  { id: 1, label: 'This owner subscribes to Warm Start'},
]

const commonDisplaysOptions = 
[
  { id: 1, label: 'Fleet Overview'},
  { id: 2, label: 'Fleet Dashboard'},
  { id: 3, label: 'Pump Dashboard'},
  { id: 4, label: 'Data Exploration'},
  { id: 5, label: 'Equipment Hours'},
  { id: 6, label: 'Component Hours'},
  { id: 7, label: 'Export'},
  { id: 8, label: 'System Software'},
  { id: 9, label: 'Alarms'},
  { id: 10, label: 'Notifications'},
  { id: 11, label: 'Communication Status'},
  { id: 12, label: 'Components'},
  { id: 13, label: 'Settings'},
]

const subscribedDisplaysOptions = 
[
  { id: 1, label: 'DFP Dashboard'},
  { id: 2, label: 'Coil Dashboard'},
]

class OwnerManagementDisplay extends Component {

  renderComponentLifeTracking() {

    return (
      <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%'}}>
        <List>
          {
            _.map(componentLifeTrackingOptions, (option) => {
              return (
                <Box key={option.id}>
                  <ListItem 
                  disablePadding
                  secondaryAction={
                    <Checkbox disabled/>
                  }
                  >
                    <ListItemButton 
                      disabled
                      >
                      <ListItemText primary={option.label} />
                    </ListItemButton>

                </ListItem>
                { 
                  /* Do not show divider for last option */
                  option.id !== componentLifeTrackingOptions.length &&
                  <Divider />
                }
                </Box>        
              )                                              
            })
          }
        </List>
        <Divider />
        <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, justifyContent: 'flex-end', marginTop: 2}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end'}}>
            <Typography variant='subtitle1'>These are set when the Owner was created and cannot be edited here.</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  renderSubscriptions() {

    return (
      <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%'}}>
        <List>
          {
            _.map(subscriptionsOptions, (option) => {
              return (
                <Box key={option.id}>
                  <ListItem 
                  disablePadding
                  secondaryAction={
                    <Checkbox />
                  }
                  >
                    <ListItemButton 
                      disableRipple
                      sx={{
                        '&:hover': {
                          background:  mdtPalette().materialUI.palette.grey['800']
                        }
                      }}
                      >
                      <ListItemText primary={option.label} />
                    </ListItemButton>

                </ListItem>
                { 
                  /* Do not show divider for last option */
                  option.id !== subscriptionsOptions.length &&
                  <Divider />
                }
                </Box>
              )
            })
          }
        </List>
        <Divider />
        <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, justifyContent: 'flex-end', marginTop: 2}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end'}}>
            <Button 
              sx={{
                backgroundColor:'grey.200',
                color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                '&:hover': {
                  backgroundColor:'grey.400',
                  color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                }
              }}
              disableRipple 
              variant='contained' 
              >
                CANCEL
            </Button>
            <Button sx={{marginLeft: 3}} disableRipple variant='contained'>SAVE</Button>
          </Box>
        </Box>
      </Box>
    );
  }

  renderDisplays() {
    return (
      <Box sx={{display: 'flex', flexFlow: 'column nowrap', height: '100%'}}>
        <Box sx={{display: 'flex', flexFlow: 'row nowrap'}}>
          <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '50%', padding: 2}} >
            <Typography variant='subtitle1'>Common Displays</Typography>
            <List>
              {
                _.map(commonDisplaysOptions, (option) => {
                  return (
                    <Box key={option.id}>
                      <ListItem 
                      disablePadding
                      secondaryAction={
                        <Checkbox checked disabled/>
                      }
                      >
                        <ListItemButton 
                          disabled
                          >
                          <ListItemText primary={option.label} />
                        </ListItemButton>

                    </ListItem>
                    { 
                      /* Do not show divider for last option */
                      option.id !== commonDisplaysOptions.length &&
                      <Divider />
                    }
                    </Box>
                  )
                })
              }
            </List>
          </Box>
          <Divider orientation='vertical' />
          <Box sx={{display: 'flex', flexFlow: 'column nowrap', width: '50%', padding: 2}}>
            <Typography variant='subtitle1'>Subscribed Displays</Typography>
            <List>
              {
                _.map(subscribedDisplaysOptions, (option) => {
                  return (
                    <Box key={option.id}>
                      <ListItem 
                      disablePadding
                      secondaryAction={
                        <Checkbox />
                      }
                      >
                        <ListItemButton 
                          disableRipple
                          sx={{
                            '&:hover': {
                              background:  mdtPalette().materialUI.palette.grey['800']
                            }
                          }}
                          >
                          <ListItemText primary={option.label} />
                        </ListItemButton>

                    </ListItem>
                    { 
                      /* Do not show divider for last option */
                      option.id !== subscribedDisplaysOptions.length &&
                      <Divider />
                    }
                    </Box>
                  )
                })
              }
            </List>
          </Box>
        </Box>
        <Divider />
        <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, justifyContent: 'flex-end', marginTop: 2}}>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end'}}>
            <Button 
              sx={{
                backgroundColor:'grey.200',
                color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                '&:hover': {
                  backgroundColor:'grey.400',
                  color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                }
              }}
              disableRipple 
              variant='contained' 
              >
                CANCEL
            </Button>
            <Button sx={{marginLeft: 3}} disableRipple variant='contained'>SAVE</Button>
          </Box>
        </Box>
      </Box>
    );
  }

  renderSelectedSetting(setting) {
    switch(setting) {
      case 1:
        return this.renderComponentLifeTracking();
      case 2:
        return this.renderSubscriptions();
      case 3:
        return this.renderDisplays();
      default:
        return (<Box/>)
    };
  }

  render() {
    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={{...styles.detailsPageHeader, height: '56px', alignItems: 'center'}}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Owner Management</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton
                onClick={() => {}}
                size="large"><RefreshIcon/>
              </IconButton>
              <Button color='inherit' disableRipple 
                onClick={() => this.props.onShowCreateOwnerDialog(true)}>
                <AddIcon/>
                CREATE OWNER
              </Button>
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexFlow: 'row nowrap', marginTop: 2}}>
            {/* Settings Groups */}
            <Box sx={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', height: '100%'}}>
              <Card sx={{marginBottom: 2}}>
                <CardContent>
                  <Typography variant='subtitle1'>Owner API Key</Typography>
                  <Box sx={{display: 'flex', flexFlow: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant='h6' sx={{alignContent: 'baseline'}}>API-SomeAPIKey</Typography>
                    <Tooltip title='Copy to Clipboard' followCursor>
                      <IconButton
                        onClick={() => {}}
                        size="large"><ContentCopyIcon/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
              <Card>
                <CardContent sx={{
                  padding: 1, 
                  '&:last-child': 
                  {
                    paddingBottom: 1
                  }
                  }}>
                  <List>
                    {
                      _.map(ownerSettings, (setting) => {
                        return (
                          <Box key={setting.id}>
                            <ListItem disablePadding >
                              <ListItemButton 
                                sx={{
                                  '&.Mui-selected': {
                                    background: mdtPalette().materialUI.palette.primary.main,
                                    '&:hover':{
                                      background: mdtPalette().materialUI.palette.primary.main,
                                    }
                                  },
                                  '&:hover': {
                                    background:  mdtPalette().materialUI.palette.grey['800']
                                  }
                                }}
                                disableRipple 
                                onClick={() => { this.props.onSelectSetting(setting.id);}}
                                selected={(!_.isNil(this.props.selectedSetting) && setting.id === this.props.selectedSetting) }
                                >
                                <ListItemText primary={setting.label} />
                              </ListItemButton>
                            </ListItem>
                            { 
                              /* Do not show divider for last option */
                              setting.id !== ownerSettings.length &&
                              <Divider />
                            }
                          </Box>
                        )
                      })
                    }
                  </List>
                </CardContent>
              </Card>
            </Box>
            {/* Options for a selected Settings Group */}
            <Box sx={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', height: '100%', flexGrow: 1, marginLeft: 2, marginBottom: 3}}>
              <Card sx={{marginBottom: 3}}>
                <CardContent sx={{height: '100%'}}>
                  {
                    this.renderSelectedSetting(this.props.selectedSetting)
                  }
                </CardContent>
              </Card>
            </Box>

          </Box>
        </Box>
        <Progress open={this.props.queryRunning}/>
        <CreateOwnerDialog />
      </Box>
      
    );
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.OWNER_MANAGEMENT : props.stateKey,
      type: ComponentTypes.OWNER_MANAGEMENT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = ownerManagementState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning, 
    owners: componentState.owners,
    selectedOwner: componentState.selectedOwner,
    selectedSetting: componentState.selectedSetting,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSelectSetting: (setting) => { dispatch(ownerManagementActions.selectSetting(props.stateDef, setting)); },
    onShowCreateOwnerDialog: (show) => { dispatch(ownerManagementActions.showCreateOwnerDialog(props.stateDef, show)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(OwnerManagementDisplay))