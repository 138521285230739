import React from 'react';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

/**
 * https://github.com/react-grid-layout/react-resizable#resize-handle
 */
const CustomResizeHandle = React.forwardRef((props, ref) => {
  const {handleAxis, ...restProps} = props;
  return (
    // Adding 1 more layer of divs lets us hide the built in handle and retain all of its benefits
    // Attempting to access the built in handle via css was not successful due to specificity
    <div>
      {/* Using the class name of react-resizable-handle lets us use the benefits of the built in handle:
          1. Having the built in handle lets us keep the great reactive sizing behaviour as we drag-resize a card
            i. Removing the built in handle loses this for an unknown reason
          2. No need to specific have react layout grid recognize this custom handle as one to prevent dragging when resizing
            i.  Per documentation on https://github.com/react-grid-layout/react-grid-layout#grid-layout-props
                // Custom component for resize handles
                // See `handle` as used in https://github.com/react-grid-layout/react-resizable#resize-handle
                // Your component should have the class `.react-resizable-handle`, or you should add your custom
                // class to the `draggableCancel` prop.
       */}
      <div className='react-resizable-handle' ref={ref} {...restProps}>
        <OpenInFullIcon fontSize='small' sx={{transform: 'rotate(270deg)', cursor: 'se-resize', color: '#9e9e9e', background: '#303030', position: 'relative', bottom: '2px', right: '2px'}}/>
      </div>
    </div>
  ) 
})

export {
  CustomResizeHandle
}