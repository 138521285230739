export default {
  SETTINGS_DEVICE_QUERY_STARTING: 'SETTINGS_DEVICE_QUERY_STARTING',
  SETTINGS_DEVICE_QUERY_SUCCESS: 'SETTINGS_DEVICE_QUERY_SUCCESS',
  SETTINGS_DEVICE_QUERY_ERROR: 'SETTINGS_DEVICE_QUERY_ERROR',
  SETTINGS_DEVICE_SAVE_STARTING: 'SETTINGS_DEVICE_SAVE_STARTING',
  SETTINGS_DEVICE_SAVE_SUCCESS: 'SETTINGS_DEVICE_SAVE_SUCCESS',
  SETTINGS_DEVICE_SAVE_ERROR: 'SETTINGS_DEVICE_SAVE_ERROR',
  SETTINGS_DEVICE_PAIR_TRUCK_STARTING: 'SETTINGS_DEVICE_PAIR_TRUCK_STARTING',
  SETTINGS_DEVICE_PAIR_TRUCK_SUCCESS: 'SETTINGS_DEVICE_PAIR_TRUCK_SUCCESS',
  SETTINGS_DEVICE_PAIR_TRUCK_ERROR: 'SETTINGS_DEVICE_PAIR_TRUCK_ERROR',
  SETTINGS_SHOW_DEVICE_DIALOG: 'SETTINGS_SHOW_DEVICE_DIALOG',
  SETTINGS_DEVICE_SET_ROW_SIZE: 'SETTINGS_DEVICE_SET_ROW_SIZE',
  SETTINGS_TRUCK_QUERY_STARTING: 'SETTINGS_TRUCK_QUERY_STARTING',
  SETTINGS_TRUCK_QUERY_SUCCESS: 'SETTINGS_TRUCK_QUERY_SUCCESS',
  SETTINGS_TRUCK_QUERY_ERROR: 'SETTINGS_TRUCK_QUERY_ERROR',
};