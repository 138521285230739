import React from 'react';
import { Slide } from '@mui/material';

/**
 * It seems that since the upgrade to MUI v4, Dialog and Modal children must be able to hold a ref.
 * https://stackoverflow.com/questions/57817994/migration-to-material-ui-v4-i-get-warning-for-modal-component
 * https://stackoverflow.com/a/57819438
 * 
 * https://mui.com/components/transitions/#child-requirement
 */

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});

export default Transition