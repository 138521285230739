import * as dgbSimulation from './dgbFleetMapSimulation';
import {performDisplayFetch} from '../../../../common/fetch';
import useSimulationData from '../../../../../helpers/simulationHelper';

const fetchDGBViewDetails = (fleetId) => {

  if (useSimulationData('fleetMap_DGB')) {
    return dgbSimulation.fetchDGBViewDetails(fleetId);
  }

  let queryString = '{ dgbFleetMap(fleetId:{fleetId}) { id name hasActiveData trucks { id name gasSupplyPressure gasPostFilterPressure gasSubstitution gasSubstitutionSetPoint engineLoad engineTorque engineRpm } } }'
    .replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

export {
  fetchDGBViewDetails
}


