import _ from 'lodash';
import { jobHistoryState } from './jobHistorySelectors';
import jobHistoryActionTypes from './jobHistoryActionTypes';
import * as jobHistoryService from './services/jobHistoryService';
import moment from 'moment';

const initialState = jobHistoryState();

const jobHistoryReducer = (state=initialState, action) => {

  switch (action.type) {
    
    case jobHistoryActionTypes.SHOW_CREATE_EDIT_JOB_DIALOG:
      return onShowCreateEditJobDialog(state, action);
    case jobHistoryActionTypes.SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);

    case jobHistoryActionTypes.SELECT_TIMEFRAME:
      return onSelectTimeFrame(state, action);

    case jobHistoryActionTypes.QUERY_CUSTOMERS_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.QUERY_CUSTOMERS_ERROR:
      return { ...state, queryRunning: false };
    case jobHistoryActionTypes.QUERY_CUSTOMERS_SUCCESS:
      return onQueryCustomersSuccess(state, action);

    case jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_ERROR:
      return { ...state, queryRunning: false };
    case jobHistoryActionTypes.QUERY_JOBS_FOR_TIMEFRAME_SUCCESS:
      return onQueryJobsForTimeFrameSuccess(state, action);

    case jobHistoryActionTypes.QUERY_ACTIVE_JOBS_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.QUERY_ACTIVE_JOBS_ERROR:
      return { ...state, queryRunning: false };
    case jobHistoryActionTypes.QUERY_ACTIVE_JOBS_SUCCESS:
      return onQueryJobsForTimeFrameSuccess(state, action);

    case jobHistoryActionTypes.QUERY_PLANNED_JOBS_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.QUERY_PLANNED_JOBS_ERROR:
      return { ...state, queryRunning: false };
    case jobHistoryActionTypes.QUERY_PLANNED_JOBS_SUCCESS:
      return onQueryJobsForTimeFrameSuccess(state, action);

    case jobHistoryActionTypes.QUERY_JOB_METADATA_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.QUERY_JOB_METADATA_ERROR:
      return { ...state, queryRunning: false };
    case jobHistoryActionTypes.QUERY_JOB_METADATA_SUCCESS:
      return onQueryJobMetadataSuccess(state, action);

    case jobHistoryActionTypes.CREATE_EDIT_SET_START_DATE:
      return onCreateEditSetStartDate(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SET_END_DATE:
      return onCreateEditSetEndDate(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SET_START_DATE_DISPLAY:
      return onCreateEditSetStartDateDisplay(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SET_END_DATE_DISPLAY:
      return onCreateEditSetEndDateDisplay(state, action);

    case jobHistoryActionTypes.CREATE_EDIT_SELECT_CUSTOMER:
      return onCreateEditSelectCustomer(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SELECT_DATAVAN:
      return onCreateEditSelectDatavan(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SELECT_JOBTYPE:
      return onCreateEditSelectJobType(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SELECT_CONTACT:
      return onCreateEditSelectContact(state, action);

    case jobHistoryActionTypes.CREATE_EDIT_ADD_WELL:
      return onCreateEditAddWell(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_REMOVE_WELL:
      return onCreateEditRemoveWell(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SELECT_WELL:
      return onCreateEditSelectWell(state, action);
    case jobHistoryActionTypes.CREATE_EDIT_SET_PLANNED_STAGES:
      return onCreateEditSetPlannedStages(state, action);

    case jobHistoryActionTypes.CREATE_EDIT_SET_JOB_NAME:
      return onCreateEditSetJobName(state, action);

    case jobHistoryActionTypes.ROW_CLICK:
      return onRowClick(state, action);

    case jobHistoryActionTypes.SAVE_JOB_WELL_STARTING:
    case jobHistoryActionTypes.DELETE_JOB_STARTING:
      return { ...state, queryRunning: true };
    case jobHistoryActionTypes.SAVE_JOB_ERROR:
    case jobHistoryActionTypes.SAVE_JOB_SUCCESS:
    case jobHistoryActionTypes.DELETE_JOB_ERROR:
    case jobHistoryActionTypes.DELETE_JOB_SUCCESS:
      return { ...state, queryRunning: false };

    case jobHistoryActionTypes.SHOW_DELETE_JOB_DIALOG:
      return onShowDeleteJobDialog(state, action);
    case jobHistoryActionTypes.UPDATE_JOB_FILTER_MODEL:
      return {
        ...state,
        filterModel: action.filterModel
      };
    default:
      return state;
  };
};

const onShowCreateEditJobDialog = (state, action) => {

  let newState =  {
    ...state,
    showCreateEditJobDialog: action.show
  };

  // If we have an existing job to modify, then let's bind the dialog values to the job
  if ((action.show === true) && !_.isNil(action.job)) {
    newState.createEditJobName = action.job.jobName;

    let foundCustomer = _.find(state.customers, (customer) => { return customer.label === action.job.customerName; });
    newState.createEditSelectedCustomer = _.defaultTo(foundCustomer, null);
    if (!_.isNil(foundCustomer)) {
      let foundContact = _.find(foundCustomer.contacts, (contact) => { return contact.label === action.job.contactName; });
      newState.createEditSelectedContact = _.defaultTo(foundContact, null);
    }
    let momentStartDate = moment(action.job.startDate);
    newState.createEditStartDate = momentStartDate;
    newState.createEditStartDateDisplay = momentStartDate;
    let momentEndDate =  moment(action.job.endDate);
    newState.createEditEndDate = momentEndDate;
    newState.createEditEndDateDisplay = momentEndDate;
    let foundJobType = _.find(state.jobTypes, (jobType) => { return jobType.label === action.job.jobType; });
    newState.createEditSelectedJobType = _.defaultTo(foundJobType, null);
    let foundDatavan = _.find(state.datavans, (datavan) => { return datavan.label === action.job.datavan; });
    newState.createEditSelectedDatavan = _.defaultTo(foundDatavan, null);

    newState.createEditWells = _.cloneDeep(action.job.wells);

    newState.selectedJob = action.job;
  }

  if (action.show === true && _.isNil(action.job)) {
    newState.hasJobNameError = true;
    newState.hasStartDateError = false;
    newState.hasEndDateError = true;
    newState.hasJobTypeError = true;
    newState.hasDatavanError = true;

    let now = moment();
    newState.createEditStartDate = now;
    newState.createEditStartDateDisplay = now;
    newState.createEditEndDate = now;
    newState.createEditEndDateDisplay = now;
  }

  // Reset the state of the dialog whenever we close it
  if (action.show === false) {
    newState.selectedJob = {};
    newState.createEditJobName = '';
    newState.createEditSelectedCustomer = null;
    newState.createEditSelectedContact = null;
    newState.createEditStartDate = null;
    newState.createEditStartDateDisplay = null;
    newState.createEditEndDate = null;
    newState.createEditEndDateDisplay = null;
    newState.createEditSelectedJobType = null;
    newState.createEditSelectedDatavan = null;
    newState.createEditWells = [];
    newState.hasJobNameError = false;
    newState.hasStartDateError = false;
    newState.hasEndDateError = false;
    newState.hasJobTypeError = false;
    newState.hasDatavanError = false;
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onDataGridSetRowSize = (state, action) => {

  return {
    ...state,
    rowsPerPage: action.rowSize,
  }
};

const onQueryCustomersSuccess = (state, action) => {

  const processedCustomers = jobHistoryService.processCustomersForAutocomplete(action.queryResults.customers);

  return {
    ...state,
    queryRunning: false,
    customers: processedCustomers
  }
};

const onSelectTimeFrame = (state, action) => {

  return {
    ...state,
    selectedTimeFrame: action.timeFrame,
  }
};

const onQueryJobsForTimeFrameSuccess = (state, action) => {

  let processedJobs;
  if(state.selectedTimeFrame.value === 1) {
    processedJobs = jobHistoryService.processJobs(action.queryResults.plannedJobs);
  } else if (state.selectedTimeFrame.value === 2) {
    processedJobs = jobHistoryService.processJobs(action.queryResults.activeJobs);
  } else {
    processedJobs = jobHistoryService.processJobs(action.queryResults.jobsForTimeFrame);
  }

  return {
    ...state,
    queryRunning: false,
    jobs: processedJobs
  }
};

const onQueryJobMetadataSuccess = (state, action) => {
  let datavans = jobHistoryService.processDatavans(action.queryResults.jobMetadataForOwner.datavans);
  let jobTypes = jobHistoryService.processJobTypes(action.queryResults.jobMetadataForOwner.jobTypes);
  let wells = jobHistoryService.processWells(action.queryResults.jobMetadataForOwner.wells);

  return {
    ...state,
    queryRunning: false,
    datavans: datavans,
    jobTypes: jobTypes,
    wells: wells
  }
}

const onCreateEditSetStartDate = (state, action) => {

  let newState = {
    ...state,
    createEditStartDate: action.startDate,
    createEditStartDateDisplay: action.startDate,
    hasStartDateError: moment(action.startDate).isAfter(state.createEditEndDate)
  };
  
  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onCreateEditSetStartDateDisplay = (state, action) => {

  let newState = {
    ...state,
    createEditStartDateDisplay: action.startDate,
  };

  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onCreateEditSetEndDate = (state, action) => {

  let newState = {
    ...state,
    createEditEndDate: action.endDate,
    createEditEndDateDisplay: action.endDate,
    hasEndDateError: moment(action.endDate).isBefore(state.createEditStartDate)
  };

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;
};

const onCreateEditSetEndDateDisplay = (state, action) => {

  let newState = {
    ...state,
    createEditEndDateDisplay: action.endDate,
  };

  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;
};

const onCreateEditSelectCustomer = (state, action) => {

  let newState = {
    ...state,
    createEditSelectedCustomer: action.customer,
    contacts: action.customer.contacts
  };

  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;
}

const onCreateEditAddWell = (state, action) => {

  const newWell = 
  {
    jobId: action.jobId,
    wellId: null,
    wellName: '',
    apiNumber: '',
    pad: '',
    leaseName: '',
    leaseNumber: '',
    plannedStages: '',
    well: { value: null, label: '' },
    toDelete: false
  }

  // Set the temporary well id of this new well
  // We will update the well id with the actual well id when they select a well
  let maxId = _.maxBy(state.createEditWells, 'wellId');
  if (_.isNil(maxId)) {
    newWell.wellId = 1;
  } else {
    let maxIdCopy = {...maxId};
    newWell.wellId = maxIdCopy.wellId+1;
  }

  let newCreateEditWells = _.cloneDeep(state.createEditWells);
  newCreateEditWells.push(newWell);

  let newState = {
    ...state,
    createEditWells: newCreateEditWells
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onCreateEditRemoveWell = (state, action) => {

  const newCreateEditWells = _.cloneDeep(state.createEditWells);
  const indexOfRowId = _.findIndex(newCreateEditWells, (well) => { return well.wellId === action.wellId});
  newCreateEditWells[indexOfRowId].toDelete = true;

  let newState = {
    ...state,
    createEditWells: newCreateEditWells
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onCreateEditSelectWell = (state, action) => {

  const newCreateEditWells = _.cloneDeep(state.createEditWells);
  const indexOfRowId =  _.findIndex(newCreateEditWells, (well) => { return well.wellId === action.wellId; });

  // Check if we already have the same well selected previously (ie added/existed before, then marked as deleted)
  const indexOfSelectedWell = _.findIndex(newCreateEditWells, (well) => { return well.wellId === action.well.id; });
  // Only do something if we have found the row we are modifying; otherwise we can't really be modifying a row in state
  if (indexOfRowId > -1) {
    // We found a well that was there previously but we deleted
    if (indexOfSelectedWell > -1 && indexOfSelectedWell !== indexOfRowId) {
      // Reset the previous well to be in a state that allows for modification
      newCreateEditWells[indexOfSelectedWell].toDelete = false;
      newCreateEditWells[indexOfSelectedWell].plannedStages = '';
      // Set the new row to be the values from the old one
      newCreateEditWells[indexOfRowId] = { ...newCreateEditWells[indexOfSelectedWell] };
      // Delete the old one, so we maintain the position of the newly added one
      newCreateEditWells.splice(indexOfSelectedWell, 1);
    }
    else {
      // We picked a well that didn't exist before
      newCreateEditWells[indexOfRowId].well = { value: action.well.value, label: action.well.label };
      newCreateEditWells[indexOfRowId].apiNumber = action.well.apiNumber;
      newCreateEditWells[indexOfRowId].pad = action.well.padName;
      newCreateEditWells[indexOfRowId].leaseName = action.well.leaseName;
      newCreateEditWells[indexOfRowId].leaseNumber = action.well.leaseNumber;
      newCreateEditWells[indexOfRowId].plannedStages = '';
      newCreateEditWells[indexOfRowId].wellId = action.well.value;
      newCreateEditWells[indexOfRowId].wellName = action.well.label;
    }
  }

  let newState = {
    ...state,
    createEditWells: newCreateEditWells
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;
};

const onCreateEditSetPlannedStages = (state, action) => {

  const newCreateEditWells = _.cloneDeep(state.createEditWells);
  const indexOfRowId =  _.findIndex(newCreateEditWells, (well) => { return well.wellId === action.wellId});

  newCreateEditWells[indexOfRowId].plannedStages = action.stage;

  let newState = {
    ...state,
    createEditWells: newCreateEditWells
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;

};

const onRowClick = (state, action) => {

  // Make a copy so we aren't modifying state directly
  const newExpandedRowsCollection = _.cloneDeep(state.expandedRows);

  // This is for opening master detail for a row
  if (!newExpandedRowsCollection.includes(action.rowId)) {
    newExpandedRowsCollection.push(action.rowId);
  } else {
  // This is for closing an already open master detail
    const indexOfRowId = newExpandedRowsCollection.indexOf(action.rowId);
    newExpandedRowsCollection.splice(indexOfRowId, 1);
  }

  return {
    ...state,
    expandedRows: newExpandedRowsCollection
  }
};

const onCreateEditSetJobName = (state, action) => {

  let newState = {
    ...state,
    createEditJobName: action.name,
    hasJobNameError: _.isEmpty(action.name)
  }

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;

  return newState;

};

const onCreateEditSelectDatavan = (state, action) => {
  
  let newState = {
    ...state,
    createEditSelectedDatavan: action.datavan,
    hasDatavanError: _.isNil(action.datavan)
  };

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;

};

const onCreateEditSelectJobType = (state, action) => {
  
  let newState = {
    ...state,
    createEditSelectedJobType: action.jobType,
    hasJobTypeError: _.isNil(action.jobType)
  };

  let canSave = jobHistoryService.validateCanSave(newState);
  newState.createEditCanSave = canSave;
  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;

};

const onCreateEditSelectContact = (state, action) => {
  
  let newState = {
    ...state,
    createEditSelectedContact: action.contact,
  };

  let hasChanges = jobHistoryService.evaluateHasChanges(newState);
  newState.createEditHasChanges = hasChanges;
  
  return newState;

};

const onShowDeleteJobDialog = (state, action) => {

  let newState = {
    ...state,
    showDeleteJobDialog: action.show,
    selectedJob: (action.show === true) ? action.job : {}
  };

  return newState;
};

export default jobHistoryReducer;