import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import equipmentListActionTypes from './equipmentListActionTypes';
import {exportCsvFactory} from '../../common/exportCsv/exportCsvActions';

import fetchEquipmentList from './equipmentListQueries';
import errorMessages from '../../common/errorMessages';

const refreshRelativeTime = defaultActionFactory(equipmentListActionTypes.EQUIPMENT_LIST_REFRESH_RELATIVE_TIME, 'stateDef');

const queryDataStarting = defaultActionFactory(equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(equipmentListActionTypes.EQUIPMENT_LIST_QUERY_DATA_ERROR, 'stateDef');

const queryData = queryActionCustomFactory(
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  errorMessages.ERROR_RETRIEVING_EQUIPMENT_LIST,
  fetchEquipmentList
);

const exportCsv = exportCsvFactory(
  'equipment-hours',
  [
    { name: 'Unit ID', property: 'name' },
    { name: 'Type', property: 'displayValueType' },
    { name: 'ECM Hours', property: 'displayValueEcmHours' },
    { name: 'Pump Hours', property: 'displayValuePumpHours' },
    { name: 'TCM Hours', property: 'displayValueTcmHours' },
    { name: 'Fleet', property: 'fleetName' },
    { name: 'Upload Time', property: 'displayValueAge' },
  ]
);

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryData,
  refreshRelativeTime,
  exportCsv
};