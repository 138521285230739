import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import AppWrapper from './components/app/appWrapper';
import {mdtPalette} from './components/common/styles/mdtPalette';

const theme = createTheme(mdtPalette().materialUI);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <AppWrapper/>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
