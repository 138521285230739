import _ from 'lodash';

const systemSoftwareState = state => {
  return {
    // Raw item details returned from the service
    items: _.isNil(state) ? [] : state.items,

    // Flag to indicate one of the equipment list remote calls is executing
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
    rowsPerPageOptions: [25,50,100],

    // State information for the export job.
    exportJobState: _.isNil(state) ? {} : state.exportJobState,

    // Flag used to trigger the browser to download the truck config file.
    triggerExportByTruck: _.isNil(state) ? false : state.triggerExportByTruck,

    // Flag to show the busy UI while the export is executing.
    dialogOpen: _.isNil(state) ? false : state.dialogOpen,

  };
};

export {
  systemSoftwareState
};