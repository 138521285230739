import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import ComponentTypes from '../../../componentTypes';
import LayoutFixedWidth from '../../../controls/layout/layoutFixedWidth';

import { CustomResizeHandle } from '../../../cards/mdtCard/controls/resizeHandle';
import JobStageChart from '../../../cards/job/stageChart/stageChart';

import { jobOverviewState } from '../../../../state/displays/jobOverview/jobOverviewSelectors';
import * as jobOverviewActions from '../../../../state/displays/jobOverview/jobOverviewActions';

class ChartView extends Component {

  render() {
    return (
      <LayoutFixedWidth layout={this.props.charts} cols={14} rowHeight={130} width={1820} isDraggable={false} compactType={'vertical'} preventCollision={false}
        onLayoutChange={(layout) => this.props.onUpdateChartsLayout(layout)} resizeHandle={<CustomResizeHandle />}>
        {
          this.props.charts.map((chart) => {
            return (
              <div key={chart.i}>
                <JobStageChart stateKey={chart.i} context={chart.configuration} cardQueryRunningCallback={this.props.onCardsQueryRunning} 
                  removeCardCallback={this.props.onRemoveCard}
                  availableStages={this.props.stagesForCompare} />
              </div>
            )
          })
        }
      </LayoutFixedWidth>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.JOB_OVERVIEW : props.stateKey,
      type: ComponentTypes.JOB_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;
  let componentState = jobOverviewState(state[stateDef.key]);

  return {
    charts: componentState.charts,
    selectedStage: componentState.selectedStage,
    stagesForCompare: componentState.stagesForCompare,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onUpdateChartsLayout: (layout) => dispatch(jobOverviewActions.updateChartsLayout(props.stateDef, layout)),
    onCardsQueryRunning: (queryRunning) => dispatch(jobOverviewActions.cardsQueryRunning(props.stateDef, queryRunning)),

    onRemoveCard: (chartKey) => dispatch(jobOverviewActions.removeChartAndCleanUp(props.stateDef, chartKey)),
  }
}

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ChartView));