import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { 
  Button,
  ButtonGroup,
  Grow, 
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { splitButtonState } from '../../../state/common/splitButton/splitButtonSelectors';
import * as splitButtonActions from '../../../state/common/splitButton/splitButtonActions';

const SplitButton = (props) => {

  const anchorRef = React.useRef(null);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
      >
        <Button onClick={() => { props.click(); }}>{props.options[props.selectedIndex]}</Button>
        <Button
          size="small"
          onClick={() => { props.toggle(); }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={props.open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => { props.close(); }}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === props.selectedIndex}
                      onClick={(event) => { props.menuItemClick(index); }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SplitButton.propTypes = {
  stateDef: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let commonSplitButtonState = splitButtonState(state[stateDef.key]);

  return {
    open: commonSplitButtonState.splitButtonOpen,
    selectedIndex: commonSplitButtonState.splitButtonSelectedIndex,
    options: commonSplitButtonState.splitButtonOptions,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    click: () => dispatch(splitButtonActions.click(props.stateDef)),
    menuItemClick: (selectedIndex) => dispatch(splitButtonActions.menuItemClick(props.stateDef, selectedIndex)),
    toggle: () => dispatch(splitButtonActions.toggle(props.stateDef)),
    close: () => dispatch(splitButtonActions.close(props.stateDef)),
  };
};

export default (connect(mapStateToProps,mapDispatchToProps)(SplitButton));