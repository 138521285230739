import * as fracFleetMapSimulation from './fracFleetMapSimulation';
import {performDisplayFetch} from '../../../../common/fetch';
import useSimulationData from '../../../../../helpers/simulationHelper';

const fetchFracFleetMapComponentDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return fracFleetMapSimulation.fetchFracFleetMapComponentDetails(fleetId);
  }

  let queryString = '{ componentFleetMap(fleetId:{fleetId}) { id name componentViewData { ' +
      'blender1 { id name components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      'blender2 { id name components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      'hydration { id name components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      'chemvan { id name components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      'datavan { id name alarmCounts { critical warning } } ' +
      'pumps { id name slot components { displayName currentLife expectedLife } alarmCounts { critical warning } } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

const fetchFracFleetMapHealthDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return fracFleetMapSimulation.fetchFracFleetMapHealthDetails(fleetId);
  }

  let queryString = '{ healthFleetMap(fleetId:{fleetId}) { id name healthViewData { ' +
      'blender1 { id name alarmCounts { critical warning } } ' +
      'blender2 { id name alarmCounts { critical warning } } ' +
      'hydration { id name alarmCounts { critical warning } } ' +
      'chemvan { id name alarmCounts { critical warning } } ' +
      'datavan { id name alarmCounts { critical warning } } ' +
      'pumps { id name slot powerEnd fluidEnd iron alarmCounts { critical warning } } ' +
      'missile { slot value } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

const fetchFracFleetMapOperationDetails = (fleetId) => {

  if (useSimulationData('fracFleetMap')) {
    return fracFleetMapSimulation.fetchFracFleetMapOperationDetails(fleetId);
  }

  let queryString = '{ operationalFleetMap(fleetId:{fleetId}) { id name operationViewData { ' +
      'blender1 { id name density { value total } discharge { value total } rate { value stopped idle tripped } tubAutoEnabled tubAutoMode alarmCounts { critical warning } } ' +
      'blender2 { id name density { value total } discharge { value total } rate { value stopped idle tripped } tubAutoEnabled tubAutoMode alarmCounts { critical warning } } ' +
      'hydration { id name alarmCounts { critical warning } } ' +
      'chemvan { id name alarmCounts { critical warning } } ' +
      'datavan { id name totalSand totalDischarge alarmCounts { critical warning } } ' +
      'sand1 { id name cleanRate massConc selectedMassFlow selectedSetPoint } ' +
      'sand2 { id name cleanRate massConc selectedMassFlow selectedSetPoint } ' +
      'wellHead { id name flowRate pressure density1 density2 } ' +
      'pumps { id name slot suction { value total } hhp { value total } discharge { value total } rpm gear rate { value stopped idle tripped } alarmCounts { critical warning } hasSand } ' +
      ' } } }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performDisplayFetch({
    'query': queryString
  });
};

export {
  fetchFracFleetMapComponentDetails,
  fetchFracFleetMapHealthDetails,
  fetchFracFleetMapOperationDetails
}


