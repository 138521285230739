import { performDisplayFetch } from '../../../common/fetch';
import * as providerSimulation from './providerSimulation';
import useSimulationData from '../../../../helpers/simulationHelper';

const fetchProviderSettings = () => {

  if (useSimulationData('providerSettings')) {
    return providerSimulation.fetchProviderSettings();
  }

  return performDisplayFetch({
    'query': '{ providerDetails { id type config name} }'
  });
};

const fetchSaveProviderSettings = (providerInput) => {

  if (useSimulationData('providerSettings')) {
    return providerSimulation.fetchSaveProviderSettings(providerInput);
  }

  return performDisplayFetch({
    'query': 'mutation SaveProvider($input:ProviderInput) {  saveProvider(input: $input) {id name type config } }',
    'variables': {
      'input': providerInput
    }
  });
};

const fetchDeleteProviderSettings = (providerId) => {

  if (useSimulationData('providerSettings')) {
    return providerSimulation.fetchDeleteProviderSettings(providerId);
  }

  return performDisplayFetch({
    'query': 'mutation deleteProvider($id: Long!) {  deleteProvider(id:$id) {success }}',
    'variables': {
      'id': providerId
    }
  });
};

export {
  fetchProviderSettings,
  fetchSaveProviderSettings,
  fetchDeleteProviderSettings
}