import React, {Component, Fragment} from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";
import { v4 as uuidv4 } from 'uuid';

import { Typography, Box, IconButton, Tooltip, Button, Divider, Icon, Grid} from '@mui/material';
import { AutoCompleteMDT, DateTimePickerMDT } from '../../controls/mdtMuiControls';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import WrenchIcon from '@mui/icons-material/Build';
import HealthIcon from '@mui/icons-material/LocalHospital';
import FuelIcon from '@mui/icons-material/LocalGasStation';
import GearsIcon from '../../controls/icons/gears';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import Progress from '../../controls/progress';
import { mdtPalette } from '../../common/styles/mdtPalette';
import getDisplayStyles from '../../common/styles/displayStyles';
import moment from "moment";
import PodcastsIcon from '@mui/icons-material/Podcasts';

import fracFleetViewResolver from './maps/frac/fracFleetViewResolver';
import pumpOnlyFleetViewResolver from './maps/pumperOnly/pumpOnlyFleetViewResolver';

import ComponentTypes from '../../componentTypes';
import * as fleetMapActions from '../../../state/displays/fleetMap/display/fleetMapActions';
import fleetMapViews from '../../../state/displays/fleetMap/display/fleetMapViews';

import { fleetMapState } from '../../../state/displays/fleetMap/display/fleetMapSelectors';
import {appState as applicationState} from '../../../state/app/appSelectors';
import { fleetTypes } from '../../../state/common/services/fleetTypeService';
import ConfirmationDialog from '../../controls/dashboards/confirmationDialog';
import * as contextActions from '../../../state/app/actions/appContextActions';
import * as appUserActions from '../../../state/app/actions/appUserActions';
import * as appUserConfigActions from "../../../state/app/actions/appUserConfigActions";

const displayStyles = getDisplayStyles();
const styles = {
  ...displayStyles,
  fleetSelection: {
    paddingLeft: 3,
    width: '175px'
  },
  pageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
  },
  editModePageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1
  },
  editModeActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center'
  },
  actionButton: {
    backgroundColor:'grey.200',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
    '&:hover': {
      backgroundColor:'grey.400',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
    }
  },
  actionsDivider: {
    height: '36px',
    marginLeft: '12px',
    marginRight: '12px'
  },
  pageHeaderActionButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  pageHeaderButton: {
    ... displayStyles.pageHeaderButton,
    paddingLeft: '6px',
  }, 
};

class FleetMap extends Component {

  componentDidMount() {
    this.props.queryFleetMapSummary();
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();

    if (this.props.editMode === true) {
      this.props.onEditDashboard(true);
    }

    if (this.props.isManageCustomViews === true) {
      this.props.onManageCustomViews(true);
    }
  }

  componentWillUnmount() {
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }

    if ((this.props.editMode === true || this.props.isManageCustomViews ) &&
        (this.props.contextDrawerOpen || this.props.contextDrawerMinimized)) {
      this.props.onOpenContextDrawer(false, this.props.configPanelWidth);
      this.props.onSelectCard(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryFleetMapSummary();
    }
  }

  periodicRefreshDisplay() {
    return setInterval(()=>{
      if (this.props.editMode !== true && this.props.isManageCustomViews !== true) {
          this.props.onSetManualRefresh(false);
          this.props.queryFleetMapSummary();
          this.props.onRefreshTimestamp(moment().unix());
        }
    }, 180000); // 3 minutes
  }

  manualRefreshDisplay() {
    this.props.onSetManualRefresh(true);
    this.props.queryFleetMapSummary();
    this.props.onRefreshTimestamp(moment().unix());
  }

  resolveMap(navigateToView) {

    if (_.isNil(this.props.selectedFleet)) {
      return <div/>;
    }

    switch (this.props.selectedFleet.type) {
      case fleetTypes.FRAC:
        return fracFleetViewResolver(
          this.props.selectedFleet, this.props.selectedView, this.props.selectedTimeFrame, 
          this.props.selectedCustomStartTime, this.props.selectedCustomDuration, navigateToView, 
          this.props.user, this.props.editMode, this.props.cardsList, 
          this.props.dashboardViews.find(view => view.id === this.props.selectedView?.id), this.props.manualRefresh);
      case fleetTypes.PUMPONLY:
        return pumpOnlyFleetViewResolver(
          this.props.selectedFleet, this.props.selectedView, this.props.selectedTimeFrame, 
          this.props.selectedCustomStartTime, this.props.selectedCustomDuration, navigateToView, 
          this.props.user, this.props.editMode, this.props.cardsList, 
          this.props.dashboardViews.find(view => view.id === this.props.selectedView?.id), this.props.manualRefresh);
      default:
        return <div/>
    }
  }

  render() {

    const navigateToView = (view) => {
      this.props.selectView(view)
    };

    const  showTimeFilters = this.props.selectedView.type === ComponentTypes.USER_VIEW;
    const showAdditionalTimeFilters = showTimeFilters === true && !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    const foundView = _.isEmpty(this.props.user.dashboards) ? null : _.find(this.props.user.dashboards[ComponentTypes.FLEET_DASHBOARD].views.views, ['id', this.props.selectedView?.id ?? this.props.selectedView]);
    const allowCustomization = (_.isNil(foundView) ? false : foundView.allowCustomization);

    const manageViewOption = {id: 'MANAGE_VIEWS', name: 'Manage Views', type: ComponentTypes.USER_VIEW, isFavorite: false, icon: 'dashboard_customize'};
    const customViews = [
      ... _.filter(this.props.dashboardViews, ['type', ComponentTypes.USER_VIEW]),
      manageViewOption
    ];

    const favoriteViews = _.filter(this.props.dashboardViews, ['isFavorite', true]);
    const filterOptions = createFilterOptions({
      stringify: (option) => option.name,
    });
    let isInvalidSelectedCustomView = _.isNil(this.props?.selectedCustomView?.name) || !_.includes(customViews.map(x => x.id), this.props?.selectedCustomView?.id);

    // We should really be rendering the available views based on what is in the display configuraion, but since
    // the DGB View is the only dynamic one at the moment, we just set a flag to render it or not.
    const showDgbView = _.find(this.props.dashboardViews, ['id', fleetMapViews.DGB]) != null;

    //local component for the SVG underline
    const SvgUnderline = ({ view }) => (
      <svg height="3" width="51" >
        <line x1="0" y1="0" x2="51" y2="0" stroke={mdtPalette().materialUI.palette.primary.main} strokeWidth="3" visibility={this.props.selectedView === view ? 'visible' : 'hidden'} />
      </svg>
    );

    return (
      <Box sx={styles.page}>
        <Box sx={{...styles.pageContent, width: '100%', flexFlow: 'row nowrap', justifyContent: 'flex-start'}}>
          <Box sx={{...styles.pageHeader, flexGrow: 1}}>
            <Box sx={{ ...styles.pageHeaderTitle, width: '220px' }}>
              <Typography variant={"h6"}>Fleet Dashboard</Typography>
            </Box>
            {
              this.props.editMode === true &&
              <Box sx={{...styles.editModePageHeaderActions, width: '100%'}}>
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Box sx={{ ...styles.editModeActions, visibility: (!_.isNil(this.props.contextData.card) ? 'hidden' : 'visibile') }}>
                  <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', marginRight: 2, flexGrow: 0.5 }}>
                    <Typography variant='caption'>Add, Remove, Configure, and Position Cards</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexFlow: 'row nowrap', marginLeft: 2, flexGrow: 0.5 }}>
                    <Button sx={{ ...styles.actionButton, marginRight: 2 }} variant='contained'
                      onClick={() => { this.props.user.dashboards[ComponentTypes.FLEET_DASHBOARD].hasChanges ? this.props.onShowDialog("undoChanges") : this.props.onUndoChangesDialogOK(); }}>
                      {
                        this.props.user.dashboards[ComponentTypes.FLEET_DASHBOARD].hasChanges ? 'Discard Changes' : 'Cancel'
                      }
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => { this.props.onSaveDashboardToUser(ComponentTypes.FLEET_DASHBOARD); }}
                      disabled={!this.props.user.dashboards[ComponentTypes.FLEET_DASHBOARD].hasChanges}>Save</Button>

                    <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', flexGrow: 1 }}>
                      {
                        // These buttons are only available for system views, which do not have a type property
                        _.isNil(this.props.selectedView.type) &&
                        <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', flexGrow: 1, marginRight: 9 }}>
                          {this.props.user.showDevelopmentContent === true &&
                            <Button sx={{ marginLeft: '10px' }} variant='contained' color='primary' onClick={() => this.props.onShowDialog("setOwnerDefault")}>Save as Owner Default</Button>
                          }
                          <Button variant='contained' color='primary' onClick={() => this.props.onShowDialog("restoreDefault")}>Restore Default</Button>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            {
              this.props.editMode !== true &&
              <>
                <AutoCompleteMDT
                  sx={styles.fleetSelection}
                  options={this.props.fleets}
                  value={this.props.selectedFleet}
                  onChange={(event, value, reason) => { this.props.selectFleet(value) }}
                  noOptionsText={'No fleets found...'}
                />
                <Box sx={styles.pageHeaderActions}>
                  <Divider sx={styles.actionsDivider} orientation='vertical' />
                  {
                    allowCustomization &&
                    <>
                      <Box sx={{ ...styles.pageHeaderButton, paddingLeft: 0 }}>
                        <Tooltip title="Edit View" className='editViewButton'>
                          <Box>
                            <IconButton
                              onClick={() => { this.props.onEditDashboard(true); }}
                              size="large"
                              disabled={this.props.isManageCustomViews === true}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                  }
                  <Box sx={styles.pageHeaderButton}>

                    <Tooltip title='Refresh'>
                      <IconButton onClick={() => this.manualRefreshDisplay()} size="large"><RefreshIcon /></IconButton>
                    </Tooltip>
                  </Box>

                  <Divider sx={styles.actionsDivider} orientation='vertical' />
                  <Box sx={{ ...styles.pageHeaderActionButtons, paddingLeft: 0 }}>
                    <Box sx={styles.pageHeaderButton}>
                      <Tooltip title='Dashboard View'>
                        <IconButton
                          onClick={() => this.props.selectView(fleetMapViews.DASHBOARD)}
                          color={this.props.selectedView === fleetMapViews.DASHBOARD ? 'primary' : 'default'}
                          size="large">
                          <DashboardIcon />
                        </IconButton>
                      </Tooltip>
                      <SvgUnderline view={fleetMapViews.DASHBOARD} />
                    </Box>
                    <Box sx={styles.pageHeaderButton}>
                      <Tooltip title='Operation View'>
                        <IconButton
                          onClick={() => this.props.selectView(fleetMapViews.OPERATION)}
                          color={this.props.selectedView === fleetMapViews.OPERATION ? 'primary' : 'default'}
                          size="large">
                          <GearsIcon />
                        </IconButton>
                      </Tooltip>
                      <SvgUnderline view={fleetMapViews.OPERATION} />
                    </Box>
                    <Box sx={styles.pageHeaderButton}>
                      <Tooltip title='Component View'>
                        <IconButton
                          onClick={() => this.props.selectView(fleetMapViews.COMPONENT)}
                          color={this.props.selectedView === fleetMapViews.COMPONENT ? 'primary' : 'default'}
                          size="large">
                          <WrenchIcon />
                        </IconButton>
                      </Tooltip>
                      <SvgUnderline view={fleetMapViews.COMPONENT} />
                    </Box>
                    <Box sx={styles.pageHeaderButton}>
                      <Tooltip title='Health View'>
                        <IconButton
                          onClick={() => this.props.selectView(fleetMapViews.HEALTH)}
                          color={this.props.selectedView === fleetMapViews.HEALTH ? 'primary' : 'default'}
                          size="large">
                          <HealthIcon />
                        </IconButton>
                      </Tooltip>
                      <SvgUnderline view={fleetMapViews.HEALTH} />
                    </Box>
                    {
                      showDgbView === true &&
                      <Box sx={styles.pageHeaderButton}>
                        <Tooltip title='DGB View'>
                          <IconButton
                            onClick={() => this.props.selectView(fleetMapViews.DGB)}
                            color={this.props.selectedView === fleetMapViews.DGB ? 'primary' : 'default'}
                            size="large">
                            <FuelIcon />
                          </IconButton>
                        </Tooltip>
                        <SvgUnderline view={fleetMapViews.DGB} />
                      </Box>
                    }
                    <Box sx={styles.pageHeaderButton}>
                      <Tooltip title='Live View'>
                        <IconButton
                          onClick={() => this.props.selectView(fleetMapViews.LIVE_VIEW)}
                          color={this.props.selectedView === fleetMapViews.LIVE_VIEW ? 'primary' : 'default'}
                          size="large">
                          <PodcastsIcon />
                        </IconButton>
                      </Tooltip>
                      <SvgUnderline view={fleetMapViews.LIVE_VIEW} />
                    </Box>
                  </Box>
                </Box>
             { 
              <>
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Box sx={styles.filterContainer} className="customViewsComboBox">
                  <AutoCompleteMDT
                    sx={{ ...styles.dashboardSelection, width: '200px', paddingLeft: '0px' }}
                    fullWidth forcePopupIcon
                    options={customViews}
                    filterOptions={filterOptions}
                    getOptionLabel={(view) => view.name ?? ''}
                    renderOption={(props, option, state) => { return (
                      <Fragment key={option.id}>
                      { option.id === manageViewOption.id && <hr/> }
                      <li {...props}>
                        <Grid container spacing={1}>
                          <Grid item sx={{width: '30px'}}>
                            <Icon font='small'>{option.icon}</Icon>
                          </Grid>
                          <Grid item sx={{
                            width: '125px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                          }}>{option.name}</Grid>
                        </Grid>
                      </li>
                      </Fragment>
                    )}}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={this.props.selectedCustomView}
                    inputValue={this.props.customViewInput}  //set input value to keep selected option as is when click on manage view option
                    getOptionDisabled={option => option.id === manageViewOption.id && this.props.isManageCustomViews}
                    renderInput={(params) => {
                      const inputProps = { ...params.InputProps, style: {fontSize: "1.15rem"}, disableUnderline: true};
                      return <>
                        <TextField {...params}
                                    InputProps={inputProps}
                                    autoFocus variant="standard"
                                    placeholder={isInvalidSelectedCustomView ? "Custom Views" : this.props.selectedCustomView.name}
                        />
                      </>;
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (!_.isNil(event) && event && event.type !== 'blur' && newInputValue !== manageViewOption.name) {
                        this.props.onUpdateCustomViewInput(newInputValue);
                      }
                    }}
                    onChange={(event, value, reason) => {
                      if (value.id === manageViewOption.id) {
                        this.props.onManageCustomViews(true);
                      } else {
                        this.props.selectView(value);
                      }
                    }}
                  />
                </Box>
                {
                  !_.isEmpty(favoriteViews) && favoriteViews.slice(0, 3).map(favoriteView => {
                    return (
                        <Fragment key={favoriteView.id}>
                          <Box sx={styles.pageHeaderButton}>
                            <Tooltip title={favoriteView.name}>
                              <IconButton
                                  onClick={() =>
                                      this.props.selectView(favoriteView)
                                  }
                                  color={
                                    this.props.selectedView?.id === favoriteView.id
                                        ? 'primary'
                                        : 'inherit'
                                  }
                                  size="large"
                              >
                                <Icon>{favoriteView.icon}</Icon>
                              </IconButton>
                            </Tooltip>
                            <svg height="3" width="51">
                              <line
                                  x1="0"
                                  y1="0"
                                  x2="51"
                                  y2="0"
                                  stroke="#CE4300"
                                  strokeWidth="3"
                                  visibility={
                                    this.props.selectedView?.id === favoriteView.id
                                        ? "visible"
                                        : "hidden"
                                  }
                              />
                            </svg>
                          </Box>
                        </Fragment>
                    );
                  })
                }
              </>
            }
        </>
        }
          </Box>
          <Box id="screenshot-area" sx={{ height: "max-content", width: "fit-content", backgroundColor: "grey.900" }}>
            {
              this.props.editMode !== true &&
              <Box sx={{...styles.selectionContainer, display: (!_.isNil(this.props.selectedView.type) && this.props.selectedView.type ===  ComponentTypes.USER_VIEW ? 'flex' : 'none')}}>
                {showTimeFilters === true && (
                  <Box sx={styles.timeFilterContainer}>
                    <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <AutoCompleteMDT
                        sx={styles.timeFrameSelection}
                        getOptionLabel={(timeFrame) => timeFrame.label}
                        options={this.props.timeFrames}
                        value={this.props.selectedTimeFrame}
                        onChange={(event, value, reason) => {
                          this.props.setTimeFrame(value);
                        }}
                        noOptionsText={"No data found..."}
                      />
                    </Box>
                  </Box>
                )}
                {showAdditionalTimeFilters === true && (
                  <Box sx={styles.timeFilterContainer}>
                    <Typography variant={"subtitle1"}>START:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePickerMDT
                            value={this.props.selectedCustomStartTimeDisplay}
                            onAccept={(value) => {
                              if (!_.isNil(value)) {
                                this.props.setCustomStartTime(value);
                              }
                            }}
                            onChange={(value) => {
                              if (!_.isNil(value)) {
                                this.props.setCustomStartTimeDisplay(value);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Typography variant={"subtitle1"}>DURATION:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <AutoCompleteMDT
                        sx={styles.timeFrameSelection}
                        options={this.props.customDurations}
                        value={this.props.selectedCustomDuration}
                        onChange={(event, value, reason) => {
                          this.props.setCustomDuration(value);
                        }}
                        noOptionsText={"No durations found..."}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            }
          </Box>
          <Progress open={this.props.queryRunning} />
          <ConfirmationDialog showDialog={this.props.showDialog}
            okCallback={() => { this.props.showDialog === "undoChanges" ? this.props.onUndoChangesDialogOK() : this.props.showDialog === "setOwnerDefault" ? this.props.onSetOwnerDefaultDialogOK() : this.props.onRestoreDefaultDialogOK(this.props.selectedView);} }
            cancelCallback={() => { this.props.onDialogCancel();} } />
        </Box>
        {this.resolveMap(navigateToView)}
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_DASHBOARD : props.stateKey,
      type: ComponentTypes.FLEET_DASHBOARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetMapState(state[stateDef.key]);
  let appState = applicationState(state);
  const dashboard = appState.user.dashboards[ComponentTypes.FLEET_DASHBOARD];

  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    fleets: componentState.fleets,
    selectedFleet: componentState.selectedFleet,
    selectedView: componentState.selectedView,
    user: appState.user,
    contextDrawerOpen: appState.context.contextDrawerOpen,
    contextDrawerMinimized: appState.context.contextDrawerMinimized,

    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,

    editMode: dashboard?.editMode,
    showDialog: componentState.showDialog,
    contextData: appState.context.contextData,
    configPanelWidth: componentState.configPanelWidth,
    dashboardViews: dashboard?.views?.views ?? [],
    cardsList: componentState.cardsList,
    cardsQueryRunning: componentState.cardsQueryRunning,
    isManageCustomViews: dashboard?.isManageCustomViews,
    customViewInput: componentState.customViewInput,
    selectedCustomView: componentState.selectedCustomView,
    manualRefresh: componentState.manualRefresh,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setTimeFrame: (timeFrame) => {dispatch(fleetMapActions.setTimeFrame(props.stateDef, timeFrame))},
    setCustomStartTime: (startTime) => {dispatch(fleetMapActions.setCustomStartTime(props.stateDef, startTime))},
    setCustomDuration: (duration) => {dispatch(fleetMapActions.setCustomDuration(props.stateDef, duration))},
    setCustomStartTimeDisplay: (startTime) => {dispatch(fleetMapActions.setCustomStartTimeDisplay(props.stateDef, startTime))},
    selectFleet: (fleet) => { dispatch(fleetMapActions.selectFleet(props.stateDef, fleet)) },
    selectView: (view) => { dispatch(fleetMapActions.selectView(props.stateDef, view)) },
    queryFleetMapSummary: () => { dispatch(fleetMapActions.queryFleetMapSummary(props.stateDef)) },
    onEditDashboard: (isEdit) => { dispatch(fleetMapActions.editDashboard(props.stateDef, isEdit, ComponentTypes.FLEET_DASHBOARD)); },
    onShowDialog: (show) => { dispatch(fleetMapActions.showDialog(props.stateDef, show)); },
    onUndoChangesDialogOK: () => { dispatch(fleetMapActions.undoChangesDialogOK(props.stateDef)); },
    onDialogCancel: () => { dispatch(fleetMapActions.dialogCancel(props.stateDef)); },
    onSaveDashboardToUser: (name) => { dispatch(fleetMapActions.saveDashboardToUser(props.stateDef, name)); },
    onSetOwnerDefaultDialogOK: () => { dispatch(fleetMapActions.saveDashboardToOwner(props.stateDef, ComponentTypes.FLEET_DASHBOARD)); },
    onSelectCard: (card) => { dispatch(appUserConfigActions.onSelectedCard(props.stateDef, card)); },
    onRefreshTimestamp: (timestamp) => {dispatch(appUserActions.onRefreshTimestamp(timestamp));},
    onRestoreDefaultDialogOK: (view) => { dispatch(fleetMapActions.restoreDefaultDialogOK(props.stateDef, ComponentTypes.FLEET_DASHBOARD, view)); },

    onUpdateCustomViewInput: (customViewInput) => { dispatch(fleetMapActions.onUpdateCustomViewInput(props.stateDef, null ,customViewInput))},
    onManageCustomViews: (isManage) => { dispatch(fleetMapActions.manageCustomViews(props.stateDef, isManage, ComponentTypes.FLEET_DASHBOARD)); },

    onOpenContextDrawer: (open, width) => { dispatch(contextActions.openContextDrawer(open, width)); },
    onSetManualRefresh: (isManualRefresh) => { dispatch(fleetMapActions.onSetManualRefresh(props.stateDef, isManualRefresh))},
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FleetMap))
