import _ from 'lodash';
import subscriptionDialogActionTypes from './subscriptionDialogActionTypes';
import { subscriptionDialogState } from './subscriptionDialogSelectors';
import { resolveContextEntityForSubscriptionDialogState } from './services/entityResolver';
import {
  evaluateCanSave,
  updateIds,
  getEvents,
  getEventTypes,
  convertProviderBasicsToAvailableProviders
} from './services/subscriptionDialogServices';
import subscriptionEntity from "../../../../components/common/subscriptionEntity";

const initialState = subscriptionDialogState();

const noValue = null;
const TRUCK_CONFIG_NOTIFICAION_FILE_EXCLUDE = "sensorTotals.json";

const subscriptionDialogReducer = (state=initialState, action) => {

  switch (action.type) {

    case subscriptionDialogActionTypes.QUERY_DATA_STARTING: 
      return { ...state, queryRunning: true }
    case subscriptionDialogActionTypes.QUERY_DATA_ERROR: 
      return { ...state, queryRunning: false }
    case subscriptionDialogActionTypes.QUERY_DATA_SUCCESS: 
      return onQueryDataSuccess(state, action);
    case subscriptionDialogActionTypes.SET_SUBSCRIPTION_NAME:
      return onSetSubscriptionName(state, action);
    case subscriptionDialogActionTypes.SELECT_EVENT:
      return onSelectEvent(state, action);
    case subscriptionDialogActionTypes.SELECT_EVENT_TYPE:
      return onSelectEventType(state, action);      
    case subscriptionDialogActionTypes.SET_ACTIVE_STEP:
      return onSubscriptionDialgoSetActiveStep(state, action);
    case subscriptionDialogActionTypes.ADD_FILTER:
      return onAddFilter(state, action);
    case subscriptionDialogActionTypes.REMOVE_FILTER:
      return onRemoveFilter(state, action);
    case subscriptionDialogActionTypes.SELECT_FILTER_ATTRIBUTE:
      return onSelectFilterAttribute(state, action);
    case subscriptionDialogActionTypes.SELECT_FILTER_OPERATOR:
      return onSelectFilterOperator(state, action);
    case subscriptionDialogActionTypes.SET_FILTER_VALUE:
      return onSetFilterSingleValue(state, action);
    case subscriptionDialogActionTypes.SET_FILTER_MULTI_VALUES:
      return onSetFilterMultiValues(state, action);
    case subscriptionDialogActionTypes.ADD_RECIPIENT:
      return onAddRecipient(state, action);
    case subscriptionDialogActionTypes.REMOVE_RECIPIENT:
      return onRemoveRecipient(state, action);
    case subscriptionDialogActionTypes.SELECT_PROVIDER:
      return onSelectProvider(state, action);
    case subscriptionDialogActionTypes.SET_DESTINATION:
      return onSetDestination(state, action);
    case subscriptionDialogActionTypes.RESOLVE_FILTERS:
      return onResolveFilters(state, action);
    case subscriptionDialogActionTypes.CLEAR_FORM:
      return onClearForm(state, action);
    default:
      return state;
  }

};

const onQueryDataSuccess = (state, action) => {

  // Parse out events and event types from the query results
  const allEventsToDisplay = getEvents(action.queryResults.events);
  const allEventTypesToDisplay = getEventTypes(action.queryResults.events);
  const availableProviders = convertProviderBasicsToAvailableProviders(action.queryResults.providerBasics);
  
  const resolvedContext = resolveContextEntityForSubscriptionDialogState(allEventsToDisplay, allEventTypesToDisplay, availableProviders, action.context, action.entity);

  //Add events/types for displaying to the resolve context
  resolvedContext.allEvents = allEventsToDisplay;
  resolvedContext.allEventTypes = allEventTypesToDisplay;

  const newState = {
    ...state,
    ...resolvedContext,

    availableProviders: availableProviders,

    queryRunning: false
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

};

const onResolveFilters = (state, action) => {

  const newState = {
    ...state,
  }

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

}

const onSetSubscriptionName = (state, action) => {

  const newState = {
    ...state,
    subscriptionName: action.value,
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onSelectEvent = (state, action) => {
  const newState = {
    ...state,
    event: action.value,
    eventType: null,
    availableEventTypes: _.filter(state.allEventTypes, ['event', action.value.value]),
    // Clear out the available attribute when we switch Events
    availableFilterAttributes: [],
    filters: [],
    canSelectEventType: true
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onSelectEventType = (state, action) => {

  let availableFilterAttributes = [];

  if ((!_.isNil(state.event)) && (!_.isNil(action.value))) {
    availableFilterAttributes = _.filter(state.allEventTypes, { 'event': state.event.value, 'value': action.value.value })[0].filterAttributes;
  }
  let currentFilters = _.cloneDeep(state.filters);

  currentFilters = _.filter( currentFilters,filter => {
    return availableFilterAttributes.map(attribute => attribute.value).includes(filter.attribute.value);
  });

  // default to exclude "sensorTotals.json" for truck configuration notification when event type is "Change"
  if(action.value.event === subscriptionEntity.TRUCKCONFIGURATION
      && action.value.value === "Change"
      && !currentFilters.some( item => item.attribute.value === "fileName") ){
    const fileNameAttribute = availableFilterAttributes.find( attribute => attribute.value === "fileName");
    if(!_.isNil(fileNameAttribute)){
      currentFilters.push(
          {
            id: currentFilters.length,
            attribute: fileNameAttribute,
            operator: _.find(fileNameAttribute.availableOperators, ['value', '!=']),
            value: TRUCK_CONFIG_NOTIFICAION_FILE_EXCLUDE
          });

    }
  }

  //Exclude attributes that in the currentFilters
  availableFilterAttributes = _.filter(availableFilterAttributes, attribute => {
    return !currentFilters.map(filter => filter.attribute.value).includes(attribute.value);
  });

  updateIds(currentFilters);

  const newState = {
    ...state,
    eventType: action.value,
    availableFilterAttributes: availableFilterAttributes,
    // Clear out the filters when we switch Event Type
    filters: currentFilters
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
}

const onSubscriptionDialgoSetActiveStep = (state, action) => {
  return {
    ...state,
    activeStep: action.step
  }
};

const onAddFilter = (state, action) => {

  const newFiltersCollection = _.cloneDeep(state.filters);
  const id = state.filters.length;
  newFiltersCollection.push(
    { 
      id: id,
      attribute: 
      {
        value: noValue,
        label: '',
        type: noValue,
        availableOperators: [],
        availableValues: []
      },
      operator: noValue,
      value: '',
    });

  const newState = {
    ...state,
    filters: newFiltersCollection
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onRemoveFilter = (state, action) => {
  const currentAvailableFilterAttributes = _.cloneDeep(state.availableFilterAttributes);
  const currentFilters = _.cloneDeep(state.filters);  
  const attributeToRemove = _.find(currentFilters, {id: action.id});

  if(!_.isNil(attributeToRemove)){
    //Add it back to the availableFilterAttributes
    if(!_.isNil(attributeToRemove.attribute.value)){
      currentAvailableFilterAttributes.push(attributeToRemove.attribute);
    }

    // Remove the item
    _.remove(currentFilters, (x) => { return x.id === action.id; });

    // Update the ids so they match their indexes
    updateIds(currentFilters);
  }

  const newState = {
    ...state,
    filters: currentFilters,
    availableFilterAttributes:currentAvailableFilterAttributes
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

};

const onSelectFilterAttribute = (state, action) => {

  const currentFilters = _.cloneDeep(state.filters);
  const foundItem = _.find(currentFilters, ['id', action.id]);
  const currentAvailableFilterAttributes = _.cloneDeep(state.availableFilterAttributes);
  if (!_.isNil(foundItem)) {
    if(!_.isNil(foundItem.attribute.value)){
      //Add the filter back to availableFilterAttributes if it's an updated filter attribute
      currentAvailableFilterAttributes.push(foundItem.attribute);
    }
    //Exclude the selected filter attribute from availableFilterAttributes
    _.remove(currentAvailableFilterAttributes, {value : action.value.value});

    //Reset the fount item values
    foundItem.attribute = action.value;
    foundItem.operator = null;
    if(_.isEmpty(foundItem.attribute.availableValues)){
      //Clear value for Textfield component
      foundItem.value = '';
    } else {
      //Clear value for AutoComplete component
      foundItem.value = null;
    }
  }

  const newState = {
    ...state,
    filters: currentFilters,
    availableFilterAttributes: currentAvailableFilterAttributes
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  };
};

const onSelectFilterOperator = (state, action) => {

  const currentFilters = _.cloneDeep(state.filters);
  const foundItem = _.find(currentFilters, ['id', action.id]);
  if (!_.isNil(foundItem)) {

    const currentOperator = _.isNil(foundItem.operator)? null : foundItem.operator.value;
    const newOperator = action.value.value;
    let itemValue = foundItem.value;
    if( (currentOperator === 'in' || currentOperator === 'not_in')
        && !(newOperator === 'in' || newOperator === 'not_in')){
      //switch from multi value operator to single value operator will pick the first item as value
      foundItem.value = itemValue.length > 0 ? itemValue[0] : _.isEmpty(foundItem.attribute.availableValues)? '' : null;
    }else if( !(currentOperator === 'in' || currentOperator === 'not_in')
        && (newOperator === 'in' || newOperator === 'not_in')){
      //switch from single value operator to multi value operator will wrap current value into array
      foundItem.value = (!_.isEmpty(itemValue) && (typeof itemValue === 'object' || !_.isEmpty(itemValue.trim())))?[itemValue]:[];
    }
    foundItem.operator = action.value;

  }

  const newState = {
    ...state,
    filters: currentFilters
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

/**
 * common filter value handling function shared by onSetFilterMultiValues & onSetFilterSingleValue
 */
const setFilterValue = (state, action) => {

  const currentFilters = _.cloneDeep(state.filters);
  const foundItem = _.find(currentFilters, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.value = action.value;
  }

  const newState = {
    ...state,
    filters: currentFilters
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onSetFilterMultiValues = (state, action) => {

  let lastValue = action.value.at(-1);
  if(action.value.length > 0 && _.isNil(lastValue.value)
      && (_.isEmpty(lastValue.trim()) )){
    //For addition: new item is empty, just space input, then should ignore
    //deletion should always update with new state
    //dropdown list should always skip this too
    return { ...state, invalidInput: true } ;
  }

  return setFilterValue({ ...state, invalidInput: false }, action);
};

const onSetFilterSingleValue = (state, action) => {

  return setFilterValue(state, action);
};

const onAddRecipient = (state, action) => {

  const newRecipientsCollection = _.cloneDeep(state.recipients);
  const id = state.recipients.length;
  newRecipientsCollection.push(
    { 
      id: id,
      provider: noValue,
      destination: ''
    });

  const newState = {
    ...state,
    recipients: newRecipientsCollection
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onRemoveRecipient = (state, action) => {

  const currentRecipients = _.cloneDeep(state.recipients);  
  
  // Remove the item
  _.remove(currentRecipients, (x) => { return x.id === action.id; });

  // Update the ids so they match their indexes
  updateIds(currentRecipients);

  const newState = {
    ...state,
    recipients: currentRecipients
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

};

const onSelectProvider = (state, action) => {

  const currentRecipients = _.cloneDeep(state.recipients);
  const foundItem = _.find(currentRecipients, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.provider = action.value;
    foundItem.destination = noValue;
    foundItem.availableDestinations = action.value.availableDestinations;
  }

  const newState = {
    ...state,
    recipients: currentRecipients,
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onSetDestination = (state, action) => {

  const currentRecipients = _.cloneDeep(state.recipients);
  const foundItem = _.find(currentRecipients, ['id', action.id]);
  if (!_.isNil(foundItem)) {
    foundItem.destination = action.value;
  }

  const newState = {
    ...state,
    recipients: currentRecipients
  };

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }
};

const onClearForm = (state, action) => {

  const newState = {
    ...state,
    subscriptionName: '',
    event: null,
    eventType: null,
    canSelectEvent: true,
    canSelectEventType: false,
    filters: [],
    recipients: [],
    availableFilterAttributes: [],
    availableProviders: [],
    availableEventTypes: [],
    allEvents: [],
    allEventTypes: [],
    activeStep: 0,
  }

  const canSave = evaluateCanSave(newState);

  return {
    ...newState,
    ...canSave,
  }

}

export default subscriptionDialogReducer;