import _ from 'lodash';

const splitButtonState = (state) => {
  return {
    splitButtonOpen: _.isNil(state) ? false : state.splitButtonOpen,
    splitButtonSelectedIndex: _.isNil(state) ? 0 : state.splitButtonSelectedIndex,
    splitButtonOptions: _.isNil(state) ? [] : state.splitButtonOptions,
  };
};

export {
  splitButtonState
};