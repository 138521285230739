import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import pagingReducer from './reducers/pagingReducer';
import filterReducer from './reducers/filterReducer';

import { fleetOverviewState } from './fleetOverviewSelectors';

const initialState = fleetOverviewState();

const fleetOverviewReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    pagingReducer,
    filterReducer
  );

  return reducer(state, action);
};

export default fleetOverviewReducer
