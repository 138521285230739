import _ from "lodash";
import { validateWorkingUser } from './helpers/userHelpers';
import userMgmtActionTypes from '../userMgmtActionTypes';

const userMgmtUserDialogReducer = (state, action) => {

  switch (action.type) {
    case userMgmtActionTypes.USER_MGMT_UPDATE_USER_STARTING:
      return { ...state, queryRunning: true };
    case userMgmtActionTypes.USER_MGMT_UPDATE_USER_SUCCESS:
      // With updates we do not refresh the user list, instead we find and
      // replace the existing user with the returned user data.
      let updatedUsers = [...state.users];
      if (!_.isNil(action.queryResults.updateUser) && !_.isNil(action.queryResults.updateUser) && !_.isNil(action.queryResults.updateUser.id)) {
        let index = _.findIndex(updatedUsers, (user) => {return user.id === action.queryResults.updateUser.id });
        if (index >= 0) {
          updatedUsers[index] = {...action.queryResults.updateUser};
        }
      }
      return { ...state, users: updatedUsers, queryRunning: false, openEditUserUI: false };
    case userMgmtActionTypes.USER_MGMT_UPDATE_USER_ERROR:
      return { ...state, queryRunning: false };
    case userMgmtActionTypes.USER_MGMT_ADD_USER_STARTING:
      return { ...state, queryRunning: true };
    case userMgmtActionTypes.USER_MGMT_ADD_USER_SUCCESS:
      return { ...state, queryRunning: false, openAddUserUI: false };
    case userMgmtActionTypes.USER_MGMT_ADD_USER_ERROR:
      return { ...state, queryRunning: false };
    case userMgmtActionTypes.USER_MGMT_CLOSE_ADD_DIALOG:
      return { ...state, openAddUserUI: false };
    case userMgmtActionTypes.USER_MGMT_CLOSE_EDIT_DIALOG:
      return { ...state, openEditUserUI: false };
    case userMgmtActionTypes.USER_MGMT_SET_WORKING_NAME:
      return { ...state, workingUser: {...state.workingUser, name: action.name} };
    case userMgmtActionTypes.USER_MGMT_SET_WORKING_EMAIL:
      return { ...state, workingUser: {...state.workingUser, email: action.email} };
    case userMgmtActionTypes.USER_MGMT_SET_WORKING_IS_ENABLED:
      return { ...state, workingUser: {...state.workingUser, isEnabled: action.isEnabled} };
    case userMgmtActionTypes.USER_MGMT_SET_WORKING_IS_ADMIN:
      return { ...state, workingUser: {...state.workingUser, isAdmin: action.isAdmin} };
    case userMgmtActionTypes.USER_MGMT_SET_WORKING_CONFIRM_EMAIL:
      return { ...state, workingUser: {...state.workingUser, confirmEmail: action.email} };
    case userMgmtActionTypes.USER_MGMT_VALIDATE_WORKING:
      let newState =  {...state};
      validateWorkingUser(newState);
      return newState;
    default: return state;
  }

};

export default userMgmtUserDialogReducer