import exportActionTypes from '../exportActionTypes';
import * as exportService from '../services/exportService';

const queryReducer = (state, action) => {

  switch (action.type) {

    case exportActionTypes.EXPORT_QUERY_TRUCKS_STARTING:
      return onQueryTrucksStarting(state, action);

    case exportActionTypes.EXPORT_QUERY_TRUCKS_SUCCESS:
      return onQueryTrucksSuccess(state, action);

    case exportActionTypes.EXPORT_QUERY_TRUCKS_ERROR:
      return onQueryTrucksError(state, action);

    case exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_STARTING:
      return onQueryExportInfoStarting(state, action);

    case exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_SUCCESS:
      return onQueryExportInfoSuccess(state, action);

    case exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_ERROR:
      return onQueryExportInfoError(state, action);

    case exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_STARTING:
      return onQueryTruckDetailsStarting(state, action);

    case exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_SUCCESS:
      return onQueryTruckDetailsSuccess(state, action);

    case exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_ERROR:
      return onQueryTruckDetailsError(state, action);

    default: return state;

  }
};

const onQueryTrucksStarting = (state, action) => {
  return {
    ...state,
    truckQueryRunning: true,
  };
};

const onQueryTrucksError = (state, action) => {
  return {
    ...state,
    truckQueryRunning: false,
  };
};

const onQueryTrucksSuccess = (state, action) => {

  // Process the list of trucks returned against the applie filters
  // Also check if the currently selected truck is still valid with the new list of trucks
  let truckDetails = exportService.processTrucks(action.queryResults.exportDetails.trucks, state.appliedFilters, state.selectedTruck);

  let selectionState = {
    ...state,
    selectedTruck: truckDetails.selectedTruck,
  };
  selectionState.canExport = exportService.resolveCanExport(selectionState);

  return {
    ...selectionState,
    truckQueryRunning: false,
    trucks: action.queryResults.exportDetails.trucks,
    filteredTrucks: truckDetails.filteredTrucks,
    filters: truckDetails.filters,
  };
};

const onQueryExportInfoStarting = (state, action) => {
  return {
    ...state,
    dialogOpen: true,
    triggerExportByTruck: false
  };
};

const onQueryExportInfoError = (state, action) => {
  return {
    ...state,
    dialogOpen: false,
    triggerExportByTruck: false
  };
};

const onQueryExportInfoSuccess = (state, action) => {
  return {
    ...state,
    dialogOpen: false,
    exportJobState: action.results.exportByTruckStatus,
    triggerExportByTruck: true
  };
};

const onQueryTruckDetailsStarting = (state, action) => {

  const newState = {
    ...state,
    truckDetailsQueryRunning: true,
    dataRanges: [],
  }

  return {
    ...newState,
    canExport: exportService.resolveCanExport(newState)
  };
};

const onQueryTruckDetailsError = (state, action) => {
  return {
    ...state,
    truckDetailsQueryRunning: false,
    dataRanges: [],
  };
};

const onQueryTruckDetailsSuccess = (state, action) => {
  let newState = {
    ...state,
    truckDetailsQueryRunning: false,
    dataRanges: action.queryResults.truckExportDetails.availabilityBlocks,

    // Flags to indicate current query range
    // In theory there is potential bug for scenario:
    // "If a truck has sensor data before 2011-01-01, and after 2011-12-01. and if we input "start time" of 2021-03-01,
    // then on GUI display it will not show right arrow to indicate there is more data on right. "
    // But we don't find any real data like that yet, so decision is to do enhancement when there is real case reported.
    // One potential solution: enhance PortalAppService to return 2 more fields to indicate data range in DB (e.g. dataAvailableStartTime, dataAvailableEndTime),
    // then replace those 2 new fields with the 2 below.
    queryStartTime: action.queryResults.truckExportDetails.startTime,
    queryEndTime: action.queryResults.truckExportDetails.endTime

  };
  newState.exceededMaximumDuration = exportService.hasExceededMaximumDuration(newState);
  newState.canExport = exportService.resolveCanExport(newState);
  newState.maximumDurationLabel = exportService.getMaximumDurationLabel(newState);
  return newState;
};

export default queryReducer