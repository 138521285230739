import _ from "lodash";
import React, {Component} from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withProps } from "recompose";

import { Typography, IconButton, Chip, Tooltip, Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FilterDialog from '../../common/filtering/filterDialog';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";

import Progress from "../../controls/progress";
import { AutoCompleteMDT, DateTimePickerMDT } from "../../controls/mdtMuiControls";
import * as dataExplorationDisplayActions from '../../../state/displays/dataExplorationDisplay/dataExplorationDisplayActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import ComponentTypes from '../../componentTypes';
import { dataExplorationDisplayState } from '../../../state/displays/dataExplorationDisplay/dataExplorationDisplaySelector';
import * as timeService from '../../../state/displays/dataExplorationDisplay/services/timeService';
import DataExplorationView from "../../common/dataExploration/view/dataExploration";
import getDisplayStyles from '../../common/styles/displayStyles';
import { appState as applicationState } from '../../../state/app/appSelectors';

const displayStyles = getDisplayStyles();

const styles = {
  ...displayStyles,
  contextSelection: {
    width: '180px',
    marginLeft: 1,
    paddingRight: 1,
  },
  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
    marginRight: 6
  },
  truckNextPrevActions: {
    marginRight: 6
  }
};

class DataExplorationDisplay extends Component {

  constructor(props) {
    super(props);
    this.manualRefreshTime = null;
  }

  componentDidMount() {
    this.loadDisplayWithStartAndEndTimes();
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
    // Start the timer for refreshing the relative time label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshRelativeTime();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
    }
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(()=>{
      this.loadDisplayWithStartAndEndTimes();
    }, 180000); // 3 minutes
  }

  periodicRefreshRelativeTime() {
    // Periodically refresh the label for the data age
    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  componentDidUpdate(prevProps) {
    // Load the data age if the selected context changes
    if (prevProps.selectedContext !== this.props.selectedContext) {
      this.loadDataAge();
    }
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.loadDisplayWithStartAndEndTimes();
    this.manualRefreshTime = moment().unix();
  }

  loadDataAge() {
    if (!_.isNil(this.props.selectedContext) && !_.isNil(this.props.selectedContext.truckId)) {
      // Update the Data Age
      this.props.queryDataAge(this.props.selectedContext.truckId);
    } else {
      // When the selected owner has no trucks, we want to clear the existing data age
      this.props.clearDataAge();
    }
  }

  loadDisplayWithStartAndEndTimes() {
    // First calculate the start time and end time 
    // We have to do this because when the display loads, this componentDidMount gets called in the component lifecycle
    // Normally we would be able to look at the state when we dispatch the loadDisplay action 
    // But since we haven't even loaded the display yet, the state for it does not exist 
    // There we have to manually setup the start and end time parameters that are used in the Data Exploration Display query
    let startTime, endTime;
    // Create a copy since we don't want to modify the original
    let selectedCustomStartTime = moment(this.props.selectedCustomStartTime);
    if (this.props.selectedTimeFrame.label === 'Custom') {
      startTime = selectedCustomStartTime.unix();
      endTime = timeService.calculateEndTimeUsingStartTimeAndDuration(selectedCustomStartTime, this.props.selectedCustomDuration);
    } else {
      endTime = moment().startOf('minute').unix();
      startTime = timeService.calculateStartTimeUsingTimeFrame(this.props.selectedTimeFrame);
    }

    // Load the display data. This should include getting trucks for the currently selected time frame.
    this.props.loadDisplay(startTime, endTime);
  }

  render() {
    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));
    let showAdditionalTimeFilters = !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    return (
      <Box sx={styles.page}>
        <Box sx={styles.pageContent}>
          <Box sx={styles.pageHeader}>
            <Box sx={styles.pageHeaderTitle}>
              <Typography variant={'h6'}>Data Exploration</Typography>
            </Box>
            <Box sx={styles.pageHeaderActions}>
              <Box sx={styles.pageHeaderButton}>
                <Tooltip title='Refresh'>
                  <IconButton onClick={() => this.manualRefreshDisplay()} size='large'>
                    <RefreshIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={styles.filterContainer}>
              <Typography variant={'subtitle1'}>FILTER:</Typography>
              {
                this.props.appliedFilters.map((filterContext, index) => {
                  return (
                    <Chip
                      key={index}
                      sx={styles.childComponent}
                      label={filterContext.chipLabel}
                      onDelete={() => this.props.deleteFilterContext(index)}
                    />
                  )
                })
              }
              <IconButton
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                sx={styles.childComponent}
                size='large'><AddCircleIcon/></IconButton>
            </Box>
            <Box sx={styles.dataAgeContainer}>
              <Typography sx={styles.childComponent}>Data as of:</Typography>
              <Typography sx={styles.childComponent}>{this.props.dataAgeDisplay}</Typography>
            </Box>
          </Box>
          <Box id="screenshot-area" sx={{height: "max-content", width: "fit-content", backgroundColor: "#212121"}}>
            <Box sx={styles.selectionContainer}>
              <Box sx={styles.timeFilterContainer}>
                <Typography variant={'subtitle1'}>TIME FRAME:</Typography>
                <Box sx={styles.timeFilterItem}>
                  <AutoCompleteMDT
                    sx={styles.timeFrameSelection}
                    getOptionLabel={(timeFrame) => timeFrame.label}
                    options={this.props.timeFrames}
                    value={this.props.selectedTimeFrame}
                    onChange={(event, value, reason) => {
                      this.props.setTimeFrame(value);
                    }}
                    noOptionsText={'No data found...'}
                  />
                </Box>
              </Box>
              {
                showAdditionalTimeFilters === true &&
                <Box sx={styles.timeFilterContainer}>
                  <Typography variant={'subtitle1'}>START:</Typography>
                  <Box sx={styles.timeFilterItem}>
                    <Box sx={styles.timeSelection}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePickerMDT
                          value={this.props.selectedCustomStartTimeDisplay}
                          onAccept={(value) => {
                            if (!_.isNil(value)) {
                              this.props.setCustomStartTime(value);
                            }
                          }}
                          onChange={(value) => {
                            if (!_.isNil(value)) {
                              this.props.setCustomStartTimeDisplay(value);
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Typography variant={'subtitle1'}>DURATION:</Typography>
                  <Box sx={styles.timeFilterItem}>
                    <AutoCompleteMDT
                      sx={styles.timeFrameSelection}
                      options={this.props.customDurations}
                      value={this.props.selectedCustomDuration}
                      onChange={(event, value, reason) => {
                        this.props.setCustomDuration(value);
                      }}
                      noOptionsText={'No durations found...'}
                    />
                  </Box>
                </Box>
              }
              <Typography variant={'subtitle1'}>UNIT:</Typography>
              <AutoCompleteMDT
                sx={styles.contextSelection}
                options={this.props.contexts}
                value={this.props.selectedContext}
                onChange={(event, value, reason) => {
                  this.props.selectContext(value);
                }}
                renderOption={(props, option, state) =>
                  <li {...props} key={option.value}>{option.label}</li>
                }
                noOptionsText={'No units found...'}
              />
              <Box sx={styles.truckNextPrevActions}>
                <Tooltip title={
                        (_.isEmpty(this.props.contexts) || (!_.isEmpty(this.props.contexts) && _.first(this.props.contexts) === this.props.selectedContext)) ?
                        'No Prev Unit' :
                        'Prev Unit'
                        }>
                  <span>
                    <IconButton
                      disabled={_.isEmpty(this.props.contexts) || (!_.isEmpty(this.props.contexts) && _.first(this.props.contexts) === this.props.selectedContext)}
                      onClick={()=>this.props.selectPrevTruck()}
                      size='large'>
                      <NavigateBeforeIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title={
                        (_.isEmpty(this.props.contexts) || (!_.isEmpty(this.props.contexts) && _.last(this.props.contexts) === this.props.selectedContext)) ?
                        'No Next Unit' :
                        'Next Unit'
                        }>
                  <span>
                    <IconButton
                      disabled={_.isEmpty(this.props.contexts) || (!_.isEmpty(this.props.contexts) && _.last(this.props.contexts) === this.props.selectedContext)}
                      onClick={()=>this.props.selectNextTruck()}
                      size='large'>
                      <NavigateNextIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>

            <Box sx={styles.viewContainer}>
              <DataExplorationView
                asset={(_.isNil(this.props.selectedContext) ? {} : this.props.selectedContext)}
                selectedTimeFrame={this.props.selectedTimeFrame}
                selectedCustomStartTime={this.props.selectedCustomStartTime}
                selectedCustomDuration={this.props.selectedCustomDuration}
                manualRefreshTime={this.manualRefreshTime}
                owner={ComponentTypes.DATA_EXPLORATION_DISPLAY}
                user={this.props.user}
              />
            </Box>
          </Box>
          <FilterDialog stateDef={this.props.stateDef}/>
          <Progress open={this.props.queryRunning}/>
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DATA_EXPLORATION_DISPLAY : props.stateKey,
      type: ComponentTypes.DATA_EXPLORATION_DISPLAY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dataExplorationDisplayState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    contexts: componentState.contexts,
    selectedContext: componentState.selectedContext,
    dataAgeDisplay: componentState.dataAgeDisplay,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    user: appState.user,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,
    queryRunning: componentState.queryRunning,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: (startTime, endTime) => { dispatch(dataExplorationDisplayActions.loadDisplay(props.stateDef, startTime, endTime))},
    queryDataAge: (truckId) => { dispatch(dataExplorationDisplayActions.queryDataAge(props.stateDef, truckId))},
    clearDataAge: () => { dispatch(dataExplorationDisplayActions.clearDataAge(props.stateDef))},
    refreshRelativeTime: () => { dispatch(dataExplorationDisplayActions.refreshRelativeTime(props.stateDef))},
    selectContext: (context) => {dispatch(dataExplorationDisplayActions.selectContext(props.stateDef, context))},
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    setTimeFrame: (timeFrame) => {dispatch(dataExplorationDisplayActions.setTimeFrame(props.stateDef, timeFrame))},
    setCustomStartTime: (startTime) => {dispatch(dataExplorationDisplayActions.setCustomStartTime(props.stateDef, startTime))},
    setCustomDuration: (duration) => {dispatch(dataExplorationDisplayActions.setCustomDuration(props.stateDef, duration))},
    selectNextTruck: () => { dispatch(dataExplorationDisplayActions.selectNextTruck(props.stateDef))},
    selectPrevTruck: () => { dispatch(dataExplorationDisplayActions.selectPrevTruck(props.stateDef))},
    setCustomStartTimeDisplay: (startTime) => {dispatch(dataExplorationDisplayActions.setCustomStartTimeDisplay(props.stateDef, startTime))},
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataExplorationDisplay))
