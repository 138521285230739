import * as fleetComponentsSimulation from './fleetComponentsSimulation';
import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchFleetComponents = (fleetId) => {

  if (useSimulationData('fleetComponentsCard')) {
    return fleetComponentsSimulation.fetchFleetComponents(fleetId);
  }

  return performCardFetch({
    'query': '{ fleetComponents(fleetId:{fleetId}) { displayName good aging replace unknown } }'.replace('{fleetId}', fleetId)
  })
};

export {
  fetchFleetComponents,
}


