import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  Box,
  MenuList,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import Transition from '../../controls/dialogSlideTransition';

import * as filterActions from '../../../state/common/filtering/filterActions';
import { filterState as commonFilterState } from '../../../state/common/filtering/filterSelectors';
import PropTypes from "prop-types";
import filterTypes from "../filterTypes";

const styles = {
  dialogHeader: {
    paddingBottom: '0px'
  },
  dialogHeaderContent: {
    display:'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  dialogHeaderDivider: {
    marginTop: 1,
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(255, 255, 255, 0.7);',
  },
  dialogHeaderTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: '5px'
  },
  dialogHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width:'100%'
  }
};

class FilterDialog extends Component {

  checkBoxController(selection) {
    const currentIndex = this.props.appliedFilterLabels.indexOf(selection.label);
    if (currentIndex === -1) {
      //new selection added
      this.props.addFilter(selection);
    }else{
      //uncheck box, remove selection from list
      this.props.deleteFilter(currentIndex);
    }
  }

  render() {
    let showingFilterTypes = !_.isEmpty(this.props.currentFilters) && this.props.currentFilters[0].type === filterTypes.filterType;

    return (
      <Dialog open={this.props.openFilterUI} disableRestoreFocus={true} onClose={() => this.props.closeFilterDialog()} TransitionComponent={Transition}>
        <DialogTitle sx={styles.dialogHeader}>
          <Box sx={styles.dialogHeaderContent}>
            <Box sx={styles.dialogHeaderTitle}>
              <Typography variant={'subtitle1'}>Filter</Typography>
              <Box sx={styles.dialogHeaderActions}>
                <IconButton
                  onClick={() => this.props.showFilterTypes()}
                  disabled={showingFilterTypes}
                  size="large"><ArrowBackIcon fontSize="small"/></IconButton>
                <IconButton onClick={() => this.props.closeFilterDialog()} size="large"><ClearIcon fontSize="small"/></IconButton>
              </Box>
            </Box>
            <div><Typography variant={'subtitle1'}>What do you want to filter by?</Typography></div>
            {
              !showingFilterTypes &&
              <TextField size="small" label="Search" variant='standard' fullWidth value={this.props.searchValue} onChange={(event) => this.props.searchFilterValues(event.target.value)} />
            }
            {
              showingFilterTypes &&
              <Box sx={styles.dialogHeaderDivider}/>
            }
          </Box>
        </DialogTitle>
        <DialogContent>
          <MenuList>
            {
              this.props.displayedFilters.map((selection, index) => {
                if(this.props.displayedFilters.some(filter => filter.type === filterTypes.multiFilterValue)){ //Check if it's multi selections Filter
                  //enable multi selection checkbox
                  return (
                      <ListItem key={selection.label}  >
                        <ListItemButton dense onClick={() => this.checkBoxController(selection)} >
                          <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={this.props.appliedFilterLabels.includes(selection.label)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': selection.label }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<React.Fragment>{selection.label}</React.Fragment>}/>
                        </ListItemButton>
                      </ListItem>
                  )

                }else{
                  //For filter not support multi selections: click to select a single filter option, then filter dialog auto closed.
                  return (
                      <ListItem key={selection.label} button onClick={() => this.props.addFilter(selection)}>
                        <ListItemText primary={<React.Fragment>{selection.label}</React.Fragment>}/>
                      </ListItem>
                  )
                }
              })
            }
          </MenuList>
        </DialogContent>
      </Dialog>
    );
  }
}

FilterDialog.propTypes = {
  appliedFilterLabels: PropTypes.array, //list of options already selected for multi selection filters
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let filterState = commonFilterState(state[stateDef.key]);

  return {
    openFilterUI: filterState.openFilterUI,
    currentFilters: filterState.currentFilters,
    displayedFilters: filterState.displayedFilters,
    filterValueKey: filterState.filterValueKey,
    searchValue: filterState.searchValue,

  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeFilterDialog: () => { dispatch(filterActions.closeFilterDialog(props.stateDef)) },
    addFilter: (filterContext) => { dispatch(filterActions.addFilter(props.stateDef, filterContext)) },
    showFilterTypes: () => { dispatch(filterActions.showFilterTypes(props.stateDef)) },
    searchFilterValues: (searchValue) => { dispatch(filterActions.searchFilterValues(props.stateDef, searchValue)) },
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
  }
};

export default (connect(mapStateToProps,mapDispatchToProps)(FilterDialog));