import errorMessages from "../../common/errorMessages";
import {
    fetchWellsDisplayData,
    fetchDeleteWell,
    fetchSaveWell
} from "./wellsDisplayQueries";
import wellsDisplayActionTypes from "./wellsDisplayActionTypes";
import defaultActionFactory from "../../common/factories/defaultActionFactory";
import {handleError} from "../../app/actions/appErrorActions"
import * as navActions from "../../app/actions/appNavActions";
import {stringifyUrl} from "query-string";

//function for setting the rows per page in the table
const setRowsPerPage = defaultActionFactory(wellsDisplayActionTypes.SET_ROWS_PER_PAGE, "stateDef", "rowSize");

const onSaveWellDialogOpen = defaultActionFactory(wellsDisplayActionTypes.SAVE_WELL_DIALOG_OPEN, 'stateDef', 'wellForSave');
const onSaveWellDialogClose = defaultActionFactory(wellsDisplayActionTypes.SAVE_WELL_DIALOG_CLOSE, 'stateDef');

const onWellChange = defaultActionFactory(wellsDisplayActionTypes.CHANGE_WELL, 'stateDef', 'value', 'property');
const onWellValidateAndChange = defaultActionFactory(wellsDisplayActionTypes.VALIDATE_CHANGE_WELL, 'stateDef', 'value', 'property');

const onWellDelete = defaultActionFactory(wellsDisplayActionTypes.OPEN_DELETE_DIALOG, 'stateDef', 'wellToDelete');
const onCloseDeleteConfirmation = defaultActionFactory(wellsDisplayActionTypes.CLOSE_DELETE_DIALOG, 'stateDef');

const queryWellDataStarting = defaultActionFactory(wellsDisplayActionTypes.QUERY_WELL_DATA_STARTING, 'stateDef');
const queryWellDataSuccess = defaultActionFactory(wellsDisplayActionTypes.QUERY_WELL_DATA_SUCCESS, 'stateDef', 'wellsQueryResults');
const queryWellDataError = defaultActionFactory(wellsDisplayActionTypes.QUERY_WELL_DATA_ERROR, 'stateDef');

const saveWellDataStarting = defaultActionFactory(wellsDisplayActionTypes.SAVE_WELL_DATA_STARTING, 'stateDef');
const saveWellDataSuccess = defaultActionFactory(wellsDisplayActionTypes.SAVE_WELL_DATA_SUCCESS, 'stateDef', 'queryResults');
const saveWellDataError = defaultActionFactory(wellsDisplayActionTypes.SAVE_WELL_DATA_ERROR, 'stateDef');

const deleteWellDataStarting = defaultActionFactory(wellsDisplayActionTypes.DELETE_WELL_DATA_STARTING, 'stateDef');
const deleteWellDataSuccess = defaultActionFactory(wellsDisplayActionTypes.DELETE_WELL_DATA_SUCCESS, 'stateDef');
const deleteWellDataError = defaultActionFactory(wellsDisplayActionTypes.DELETE_WELL_DATA_ERROR, 'stateDef');

const queryWell = (stateDef) => {
    return async (dispatch, getState) => {
        let wellsQueryResult = null;

        try {
            await dispatch(queryWellDataStarting(stateDef));
            wellsQueryResult = await fetchWellsDisplayData();
            await dispatch(queryWellDataSuccess(stateDef, wellsQueryResult));
        } catch (e) {
            await dispatch(queryWellDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_RETRIEVING_WELLS_DISPLAY_DATA, e.message));
        }
    };
};

const onSaveWell = (stateDef, well) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(saveWellDataStarting(stateDef));
            // For scenario when user removes existing latitude/longitude value,
            // it'll pass in as empty string which not supported by backend as BigDecimal expected
            if(well.latitude === "") well.latitude = null;
            if(well.longitude === "") well.longitude = null;

            const queryResults = await fetchSaveWell(well);
            await dispatch(saveWellDataSuccess(stateDef, queryResults));
            await dispatch(queryWell(stateDef));
        } catch (e) {
            await dispatch(saveWellDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_SAVING_WELL, e.message));
        }
    };
};

const onConfirmWellDelete = (stateDef, wellId) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(deleteWellDataStarting(stateDef));
            let result = await fetchDeleteWell(wellId);
            if (result.deleteWell.success === true) {
              await dispatch(deleteWellDataSuccess(stateDef));
              await dispatch(queryWell(stateDef));
            }
            else {
              await dispatch(deleteWellDataErrorWellInUse(stateDef, result.deleteWell.message));
            }
        } catch (e) {
            await dispatch(deleteWellDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_DELETE_WELL, e.message));
        }
    };
};

const deleteWellDataErrorWellInUse = defaultActionFactory(wellsDisplayActionTypes.DELETE_WELL_ERROR_WELL_IN_USE, 'stateDef', 'message');
const navigateToJobHistoryByWellName = (stateDef, wellName) => {
    return async (dispatch, getState) => {
        await dispatch(onCloseDeleteConfirmation(stateDef));
        const url = stringifyUrl({url: '/jobs', query: {wellName: wellName}});

        return dispatch(navActions.navigate(url, null));
    };
};

export {
    queryWell,
    onSaveWellDialogOpen,
    onSaveWellDialogClose,
    onWellChange,
    onWellDelete,
    onCloseDeleteConfirmation,
    onSaveWell,
    onConfirmWellDelete,
    setRowsPerPage,
    onWellValidateAndChange,
    deleteWellDataErrorWellInUse,
    navigateToJobHistoryByWellName
};