import _ from "lodash";
import defaultActionFactory from "../../common/factories/defaultActionFactory";

import splitButtonActionTypes from "./splitButtonActionTypes";

const click = defaultActionFactory(splitButtonActionTypes.SPLIT_BUTTON_CLICK, 'stateDef');
const menuItemClick = defaultActionFactory(splitButtonActionTypes.SPLIT_BUTTON_MENU_ITEM_CLICK, 'stateDef', 'selectedIndex');
const toggle = defaultActionFactory(splitButtonActionTypes.SPLIT_BUTTON_TOGGLE, 'stateDef');
const close = defaultActionFactory(splitButtonActionTypes.SPLIT_BUTTON_CLOSE, 'stateDef');

export {
  click,
  menuItemClick,
  toggle,
  close
};