import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import { Typography, Box } from '@mui/material';

import ComponentTypes from '../../componentTypes';

import * as unitHoursDfpActions from '../../../state/cards/unitHoursDfp/unitHoursDfpActions';
import { unitHoursDfpState } from '../../../state/cards/unitHoursDfp/unitHoursDfpSelectors';
import unitHoursDfpTypes from '../../../state/cards/unitHoursDfp/unitHoursDfpTypes';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { mdtPalette } from "../../common/styles/mdtPalette";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,
  ecmHoursLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category2
  },
  pumpHoursLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category4
  },
  cardContainerReplace: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardHeaderLabel: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1,
  },
  cardLastRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  resolution: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  resolutionLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  latestValuesContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: 2,
    paddingBottom: 2,
  },
};

class UnitHoursCurbside extends Component {

  componentDidMount() {
    // Load the card data if we have a context.
    if (!_.isNil(this.props.selectedContext))  {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    // Refresh the card data when the context changes.
    if (prevProps.selectedContext !== this.props.selectedContext)  {
      this.refresh();
    }
  }

  refresh() {
    if (_.isNil(this.props.selectedContext)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.selectedContext.id);
    }
  }

  render() {
    return (
      <Box sx={styles.cardContainerReplace}>
        <Box key='header' sx={styles.cardHeader}>
          <Typography sx={styles.cardHeaderLabel} variant={'subtitle1'}>CURBSIDE UNIT HOURS</Typography>
        </Box>
        <Box key='body' sx={styles.cardContent}>
          <Box sx={styles.cardLastRow}>
            <Box sx={styles.resolution}>
              <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>Lifetime</Typography>
            </Box>
            <Box sx={styles.latestValuesContainer}>
              <div>
                <Typography variant={'h4'} sx={styles.ecmHoursLabel}>{this.props.ecmHours}</Typography>
                <Typography variant={'subtitle1'} sx={styles.ecmHoursLabel}>ECM Hours</Typography>
              </div>
              <div>
                <Typography variant={'h4'} sx={styles.pumpHoursLabel}>{this.props.pumpHours}</Typography>
                <Typography variant={'subtitle1'} sx={styles.pumpHoursLabel}>Pump Hours</Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_HOURS_CURBSIDE : props.stateKey,
      type: ComponentTypes.UNIT_HOURS_CURBSIDE,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitHoursDfpState(state[stateDef.key]);
  return {
    ecmHours: componentState.ecmHours,
    pumpHours: componentState.pumpHours,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: (context) => { dispatch(unitHoursDfpActions.queryData(props.stateDef, unitHoursDfpTypes.CURBSIDE, context)) },
    clearData: () => { dispatch(unitHoursDfpActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitHoursCurbside));

