import systemSoftwareActionTypes from '../systemSoftwareActionTypes';
import * as itemsService from '../services/itemsService';

const itemsReducer = (state, action) => {

  switch (action.type) {
    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    case systemSoftwareActionTypes.SYSTEM_SOFTWARE_REFRESH_UPLOAD_TIME:
      return onRefreshUpload(state, action);

    case systemSoftwareActionTypes.SET_ROW_SIZE:
      return onDataGridSetRowSize(state, action);
    default: return state;
  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
    items: []
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    items: []
  };
};

const onQueryDataSuccess = (state, action) => {
  let items = itemsService.processItems(action.queryResults.systemSoftwareDisplay);

  return {
    ...state,
    queryRunning: false,
    items: items
  };
};

const onRefreshUpload = (state, action) => {
  let updatedItems = [...state.items];
  itemsService.prettifyValues(updatedItems);

  return {
    ...state,
    items: updatedItems
  };
};

const onDataGridSetRowSize = (state, action) => {
  return {
    ...state,
    rowsPerPage: action.rowSize,
  }

}

export default itemsReducer