import _ from 'lodash';

const fetchFracJobOverview = (fleetId) => {
    return new Promise((resolve) => {

        // Randomize the length of the simulation query so all the cards do not complete their
        // queries at once
        let queryDuration = _.random(500, 3000);

        setTimeout(() => {
            resolve({
                fleetFracJobOverview: simJobFracOverviewData()
            })
        }, queryDuration)
    });
};

const simJobFracOverviewData = () =>
{
    return {
        state: 'Pumping',
        flowRate: _.round(_.random(1.1, 100.01), 2),
        pressure: _.random(10000, 20000),
        fleetType: _.sample([null, 'Frac Fleet', 'Pumper Only']),
        jobName: (_.random(0,1) === 0)? 'TestJob'+_.random(0, 25): null,
        customerName: (_.random(0,1) === 0)? 'cName'+_.random(0, 25): null,
        alarmCounts: (_.random(1, 50) % 15 > 0 ?
            {
                criticalAlarmCount: (_.random(0, 25) % 5 === 0 ? 0 : _.random(0, 25)),
                infoAlarmCount: (_.random(0, 100) % 20 === 0 ? null : _.random(0, 100)),
                warningAlarmCount: (_.random(0, 50) % 15 === 0 ? 0 : _.random(0, 50))
            } : null),
        backSideCounts: (_.random(1, 50) % 2 > 0 ?
            [
                {
                    label: 'All',
                    low: _.random(1, 10),
                    med: _.random(1, 10),
                    high: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10))
                }
            ] : null),
        frontSideCounts: (_.random(1, 50) % 15 > 0 ?
            _.random(1, 10) % 2 === 0 ?
                [
                    {
                        label: 'Eng',
                        low: _.random(1, 10),
                        med: (_.random(1, 10) % 3 === 0 ? null :  _.random(1, 10)),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'Tran',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PE',
                        low: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10)),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    (_.random(1, 10) % 2 === 0 ? {
                        label: 'FE',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    } : {})
                ]:
                [
                    {
                        label: 'Eng',
                        low: _.random(1, 10),
                        med: (_.random(1, 10) % 3 === 0 ? null :  _.random(1, 10)),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'Tran',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PE',
                        low: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10)),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PEL',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    (_.random(1, 10) % 2 === 0 ? {
                        label: 'EO',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    } : {})
                ]
             : null),
        wells: Array.from({length: _.random(0, 4)}, (_, index) => {
            return {
                padName: 'padName'+ (index+1),
                wellName: 'wellName'+ (index+1),
                plannedStages: (index+1).toString()}
        })
    }
};
export {
  fetchFracJobOverview
}
