import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DataExplorationChart from "../chart/dataExplorationChart";
import ComponentTypes from "../../../componentTypes";
import { trackPage } from '../../../../helpers/googleAnalyticsHelper';

class DataExplorationView extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(this.props.owner + '/' + ComponentTypes.DATA_EXPLORATION_CHART, this.props.user);
  }

  /**
   * Generate a partial chart definition from the dashboard state and pass to
   * the DataExploration component.
   * @return {JSX.Element}
   */
  render() {

    let startTime = null;
    let duration = null;
    if (this.props.selectedTimeFrame.label !== 'Custom') {
      startTime = null;
      duration = this.props.selectedTimeFrame.value;
    } else {
      startTime = moment(this.props.selectedCustomStartTime).unix();
      duration = this.props.selectedCustomDuration.value;
    }

    const truck = _.isNil(this.props.asset)
      // Rather than create a null truck object, which then causes more issues downstream
      // create a truck with pid=0 so the current processing can handle it
      // This will mimic the current handling (see below) for null persistence id - truck is null so persistence id is null too
      ? { pid: 0 }
      : {
        // It is possible for a NexTier truck to have a null PersistenceId (if it has no asset_id)
        // when this happens we will allow the processing to happen but use a 'fake' Pid so we
        // can let downstream processing handle xValues etc.
        pid: _.isNil(this.props.asset.truckPid) ? 0 : this.props.asset.truckPid,

        // The asset may have differently named attributes depending on who our
        // parent is. The Pump Dashboard uses 'id' and 'name' whereas the
        // standalone Data Exploration Display uses 'truckId' and 'truckName'.
        id: this.props.asset.id || this.props.asset.truckId,
        name: this.props.asset.name || this.props.asset.truckName
      };

    // DOC: the definition should be stored in redux and generated upon
    //       dispatching any of the parameters
    let definition = {
      // the chart definition can be shared; a subset of the definition
      // should be used as input to the dataExploration component
      name: "PumpDashboard Data Exploration",
      author: "CloudTeam",
      type: 'template',

      // first x-Axis id
      primary: {

        // time range
        timeRange: {
          // epoch seconds or null: indicating last N minutes from now
          startTime: startTime,
          // duration in minutes
          duration: duration
        },

        // default truck should be used for all new sensors
        defaultTruck: truck,

        // sensor/series display order -> this means Oranges, Apples, Bananas
        displayOrder: [],

        // trucks, one per sensor
        trucks: [],

        // default sensors
        sensors: [],

        // series configuration - maintain same color order as existing charts
        // Note that yAxisId is -2, -1, 1, 2 for outer left, inner left, inner
        // right, and outer right respectively
        config: [],
      },

      // second x-Axis id (may be null)
      secondary: null,
    };

    let chartKey = ComponentTypes.DATA_EXPLORATION_CHART + '-' + this.props.owner;

    return (
          <DataExplorationChart template={definition}
                                manualRefreshTime={this.props.manualRefreshTime}
                                stateKey={chartKey}
                                selectedTimeFrame={this.props.selectedTimeFrame.label}
                                owner={this.props.owner}
                                closeNaNGap={this.props.closeNaNGap}
                                unitType={this.props.asset?.unitType}/>
    );
  }
}

DataExplorationView.propTypes = {
  owner: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
}

export default DataExplorationView;
