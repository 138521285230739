import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import fleetClientStatusActionTypes from './fleetClientStatusActionTypes';

import * as fetchClientQueries from './fleetClientStatusQueries';
import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_CLEAR_DATA, 'stateDef');

const queryDataStarting = defaultActionFactory(fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(fleetClientStatusActionTypes.FLEET_CLIENT_STATUS_QUERY_DATA_ERROR, 'stateDef');

const queryData = queryActionCustomFactory(
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  errorMessages.ERROR_RETRIEVING_FLEET_CLIENT_STATUS_CARD_DATA,
  fetchClientQueries.fetchClientStatus
);

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryData,
  clearData,
};