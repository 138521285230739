import _ from 'lodash';

import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from '../../app/actions/appErrorActions';

import {
  fetchDevicesForOwner,
  fetchSaveLocations,
  fetchDeleteLocation,
  fetchLocations
} from './assetTrackingQueries';
import assetTrackingActionTypes from './assetTrackingActionTypes';
import * as assetTrackingService from './services/assetTrackingService';

const queryAssetDataStarting = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_STARTING, 'stateDef');
const queryAssetDataSuccess = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryAssetDataError = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_ERROR, 'stateDef');
const updateEditMode = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_UPDATE_EDIT_MODE, 'stateDef', 'editMode');

const queryAssetData = (stateDef) => {
  return async (dispatch, getState) => {
    let queryResults = null;

    try {
      await dispatch(queryAssetDataStarting(stateDef));

      queryResults = await fetchDevicesForOwner();
      await dispatch(queryAssetDataSuccess(stateDef, queryResults));
    } catch (error) {
      await dispatch(queryAssetDataError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_ASSET_TRACKING_GETTING_ASSETS, error));
    }
  }
}

const saveLocationsStarting = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_STARTING, 'stateDef');
const saveLocationsSuccess = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_SUCCESS, 'stateDef', 'queryResults');
const saveLocationsError = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_ERROR, 'stateDef');

const saveLocations = (stateDef, locations, toDelete) => {
  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(saveLocationsStarting(stateDef));

      const locationsInput = [];

      _.forEach(locations, (location) => {
        locationsInput.push(assetTrackingService.createLocationInputFromLocation(location));
      });

      queryResults = await fetchSaveLocations(locationsInput);

      _.forEach(toDelete, async (location) => {
        await dispatch(deleteLocation(stateDef, location));
      });

      await dispatch(saveLocationsSuccess(stateDef, queryResults));
    } catch (error) {
      await dispatch(saveLocationsError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_ASSET_TRACKING_SAVING_LOCATIONS, error));
    }
  }
}

const deleteLocationStarting = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_STARTING, 'stateDef');
const deleteLocationSuccess = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_SUCCESS, 'stateDef', 'queryResults');
const deleteLocationError = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_ERROR, 'stateDef');

const deleteLocation = (stateDef, location) => {
  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(deleteLocationStarting(stateDef));

      queryResults = await fetchDeleteLocation(location.locationId);

      await dispatch(deleteLocationSuccess(stateDef, queryResults));
    } catch (error) {
      await dispatch(deleteLocationError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_ASSET_TRACKING_DELETE_LOCATION, error));
    }
  }

}

const queryLocationsStarting = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_STARTING, 'stateDef');
const queryLocationsSuccess = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_SUCCESS, 'stateDef', 'queryResults');
const queryLocationsError = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_ERROR, 'stateDef');

const queryLocations = (stateDef) => {
  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(queryLocationsStarting(stateDef));

      queryResults = await fetchLocations();

      await dispatch(queryLocationsSuccess(stateDef, queryResults));

    } catch (error) {
      await dispatch(queryLocationsError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_ASSET_TRACKING_GETTING_LOCATIONS, error));
    }
  }
}

const updateCanSave = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_UPDATE_CAN_SAVE, 'stateDef', 'canSave');
const setShowInActive = defaultActionFactory(assetTrackingActionTypes.ASSET_TRACKING_SET_SHOW_INACTIVE, 'stateDef', 'showInActive');

export {
  queryAssetData,
  updateEditMode,
  saveLocations,
  deleteLocation,
  queryLocations,
  updateCanSave,
  setShowInActive
}