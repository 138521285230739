import _ from "lodash";
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import { handleError } from '../../app/actions/appErrorActions';
import errorMessages from '../../common/errorMessages';

import * as appContextActions from "../../app/actions/appContextActions";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import unitUserChartActionTypes from './unitUserChartActionTypes';
import * as unitUserChartQueries from './unitUserChartQueries';
import { sanitizeDefinition } from "./services/unitUserChartService";
import { fetchSensorSelectorSensors } from "../../common/sensorSelector/sensorSelectorQueries";
import { determineTruckFromDashboard } from "./services/unitUserChartService";
import ComponentTypes from "../../../components/componentTypes";

const clearData = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_CLEAR_DATA, 'stateDef');

const queryDataStarting = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_QUERY_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SUCCESS, 'stateDef', 'queryResults', 'truck');
const queryDataError = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_QUERY_ERROR, 'stateDef');

const queryData = (stateDef, truck, startTime, endTime, sensors, cardQueryRunningCallback, dashboard) => {
  return async (dispatch, getState) => {
    try {

      let queryResults = null;
      await dispatch(queryDataStarting(stateDef));
      // Tell whover is displaying us that we have started running a query
      if (!_.isNil(cardQueryRunningCallback)) {
        cardQueryRunningCallback(true);
      }

      let truckForQuery = determineTruckFromDashboard(truck, dashboard);

      queryResults = await unitUserChartQueries.fetchUnitUserChart(truckForQuery, startTime, endTime, sensors);

      await dispatch(queryDataSuccess(stateDef, queryResults, truckForQuery));
      // Tell whover is displaying us that we are done running a query
      if (!_.isNil(cardQueryRunningCallback)) {
        cardQueryRunningCallback(false);
      }

    } catch (e) {
      await dispatch(queryDataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_USER_UNIT_CHART_DATA, e.message));
    }

  }
};

const querySensors = queryActionFactory(
  unitUserChartActionTypes.UNIT_USER_CHART_QUERY_STARTING,
  unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SENSORS_SUCCESS,
  unitUserChartActionTypes.UNIT_USER_CHART_QUERY_ERROR,
  "Error retrieving sensors",
  fetchSensorSelectorSensors
);

const openSensorSelector = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_OPEN_SENSOR_SELECTOR, 'stateDef');

const closeSensorSelector = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_CLOSE_SENSOR_SELECTOR, 'stateDef');
const setSelectedSensors = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_SET_SELECTED_SENSORS, 'stateDef', 'xAxisId', 'sensors');

const onChangeDisplayName = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_UPDATE_DISPLAY_NAME, 'stateDef', 'sensor', 'displayName');
const onChangeUOM = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_UPDATE_UOM, 'stateDef', 'sensor', 'uom');
const onChangeYAxis = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_UPDATE_YAXIS, 'stateDef', 'sensor', 'yAxis');
const onChangeLine = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_UPDATE_LINE, 'stateDef', 'sensor', 'lineStyle');
const onConfigChangedColor = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_UPDATE_COLOR, 'stateDef', 'sensor', 'color');
const onSetColorPickerState = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_SET_COLOR_PICKER_STATE, 'stateDef', 'sensor', 'origColor');
const onDiscardDefinition = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_DISCARD_DEFINITION, 'stateDef', 'originalViews', 'view', 'cardKey');

const setDefinitionDefaultTruck = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_DEFAULT_TRUCK, 'stateDef', 'truck', 'dashboard');
const setDefinitionStartTime = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_START_TIME, 'stateDef', 'startTime', 'duration');
const setDefinitionSensors = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_SENSORS, 'stateDef', 'sensors');
const setDefinitionAxes = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_AXES, 'stateDef', 'axes');

const onCloseCardConfiguration = (dashboard, view, cardKey) => {
  return async(dispatch, getState) => {
    await dispatch(selectTab({key: cardKey, type: ComponentTypes.UNIT_USER_CHART}, 0));
    // Sanitize the definition before saving it to config
    let sanitizedDefinition = sanitizeDefinition(getState()[cardKey].definition);
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, sanitizedDefinition));
    await dispatch(appContextActions.setContext(dashboard, {}));
    await dispatch(appContextActions.openContextDrawer(true, getState()[dashboard].configPanelWidth));
  }
}

const onDiscardCardConfiguration = (stateDef, dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    const originalViews = getState().app.user.dashboards[dashboard].originalConfigViews;
    await dispatch(onDiscardDefinition(stateDef, originalViews, view, cardKey));
    await dispatch(appUserConfigActions.onDiscardCardConfiguration(dashboard, view, cardKey));
  }
};

const setupEditMode = defaultActionFactory(unitUserChartActionTypes.UNIT_USER_CHART_SETUP_EDIT_MODE, 'stateDef');
const selectTab = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_SELECT_TAB, 'stateDef', 'tabIndex');
const setAxisPropertyValue = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_SET_AXIS_PROPERTY_VALUE, 'stateDef', 'axisId', 'property', 'value');
const setAxisAuto = defaultActionFactory(unitUserChartActionTypes.CHART_CONFIG_SET_AXIS_AUTO, 'stateDef', 'axisId');

export {
  queryData,
  clearData,
  querySensors,
  openSensorSelector,
  closeSensorSelector,
  setSelectedSensors,
  onChangeDisplayName,
  onChangeUOM,
  onChangeYAxis,
  onChangeLine,
  onConfigChangedColor,
  onSetColorPickerState,
  setDefinitionDefaultTruck,
  setDefinitionStartTime,
  onDiscardDefinition,
  setDefinitionSensors,
  onCloseCardConfiguration,
  onDiscardCardConfiguration,
  setupEditMode,
  selectTab,
  setAxisPropertyValue,
  setAxisAuto,
  setDefinitionAxes
}