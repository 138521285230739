import _ from 'lodash';
import React, {Component} from 'react';
import { Typography, Box, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip, IconButton }from '@mui/material';
import PropTypes from 'prop-types';

import getSensorSelectorStyles from './sensorSelectorStyles';

import {includesSensor} from "../../../../../state/common/sensorSelector/services/sensorSelectorService";
import MoreVertIcon from "@mui/icons-material/MoreVert"
import SensorGroupsContextMenu from "./sensorGroupsContextMenu";

const sensorSelectorStyles = getSensorSelectorStyles();

const styles = {
  ...sensorSelectorStyles,
  groupActions: {
    visibility: 'hidden',
    textAlign:'center'
  },
};

class SensorGroups extends Component {

  getSensorDisplayString(sensor) {
    return sensor.alias + ' (' + sensor.uom + ')';
  }

  render() {

    return (
      <div>
        {
          _.isEmpty(this.props.groups) &&
          <Box sx={styles.sensorList}>
            <Typography variant={'subtitle1'}
                        sx={styles.message}>
              No groups available.
            </Typography>
          </Box>
        }
        {
          !_.isEmpty(this.props.groups) &&
          <List sx={styles.sensorList}>
            {
              _.isNil(this.props.groups) ? [] : this.props.groups.map((group, index) => {
                return (
                  <ListItem
                    selected={this.props.selectedIndexesForAdd.findIndex(item => item === index) >= 0}
                    disabled={false}
                    key={group.name}
                    onClick={() => this.props.selectGroupToAdd(index)}
                    sx={{paddingRight: 0, overflowWrap: 'break-word'}}
                  >
                    <Tooltip placement={'left'}
                            // Fixes a console error when description is null (usually on first load)
                            title={_.isNil(group.description) ? '' : group.description}>
                      <ListItemText disableTypography={true}
                                    primary={
                                      <React.Fragment>
                                        <Typography variant={'body1'} sx={{fontSize: '14px'}}>
                                          {group.name}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    secondary={
                                      <React.Fragment>
                                        {group.sensors.map((s) =>
                                          <Tooltip title={this.getSensorDisplayString(s)} followCursor key={s.sensorSetId}>
                                            <Typography
                                              noWrap
                                              sx={includesSensor(this.props.selectedSensors, s) ? styles.sensorGroupSecondaryTextAlreadySelected : styles.sensorGroupSecondaryText}
                                              variant={'body1'}>
                                              ・{this.getSensorDisplayString(s)}
                                            </Typography>
                                          </Tooltip>
                                        )}
                                      </React.Fragment>
                                    }/>
                    </Tooltip>
                    { this.props.canEditGroups &&
                      <ListItemSecondaryAction sx={styles.groupActions}>
                        <IconButton
                          edge="end"
                          onClick={(event) => {
                            this.props.openContextMenu(event.currentTarget, group)
                          }}
                          size="large">
                          <MoreVertIcon/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                );
              })
            }
          </List>
        }
        { this.props.canEditGroups &&
          <SensorGroupsContextMenu stateDef={this.props.stateDef} unitType={this.props.unitType} axisDefinition={this.props.axisDefinition} namespace={this.props.namespace}/>}
      </div>
    );
  }
}

SensorGroups.props = {
  groups: PropTypes.array.isRequired,
  selectedIndexesForAdd: PropTypes.array.isRequired,
  selectGroupToAdd: PropTypes.func.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  canEditGroups: PropTypes.bool.isRequired,
  openContextMenu: PropTypes.func.isRequired,
  unitType: PropTypes.string,
  axisDefinition: PropTypes.object,
  namespace: PropTypes.string,
}

export default SensorGroups;
