const fleetDivisions = {
    FRAC : 'Frac',
    POWERSYSTEMS : 'Power Systems',
    WIRELINE : 'Wireline'
};

const fleetDivisionNames = {
    FRAC : 'Frac',
    POWERSYSTEMS : 'Power Systems',
    WIRELINE : 'Wireline'
};

const getFleetDivisionNameByDivision = (division) => {
    switch (type) {
        case fleetDivisions.FRAC:
            return fleetDivisionNames.FRAC;
        case fleetDivisions.POWERSYSTEMS:
            return fleetDivisionNames.POWERSYSTEMS;
        case fleetDivisions.WIRELINE:
            return fleetDivisionNames.WIRELINE;
        default:
            return type;
    }
};

export {
    fleetDivisions,
    fleetDivisionNames,
    getFleetDivisionNameByDivision
};