export default {
    SET_ROWS_PER_PAGE:'SET_ROWS_PER_PAGE',
    QUERY_CUSTOMER_DATA_STARTING: 'QUERY_CUSTOMER_DATA_STARTING',
    QUERY_CUSTOMER_DATA_SUCCESS: 'QUERY_CUSTOMER_DATA_SUCCESS',
    QUERY_CUSTOMER_DATA_ERROR: 'QUERY_CUSTOMER_DATA_ERROR',
    SAVE_CUSTOMER_DIALOG_OPEN: 'SAVE_CUSTOMER_DIALOG_OPEN',
    SAVE_CUSTOMER_DIALOG_CLOSE: 'SAVE_CUSTOMER_DIALOG_CLOSE',
    CHANGE_CUSTOMER: 'CHANGE_CUSTOMER',
    VALIDATE_CHANGE_CUSTOMER: 'VALIDATE_CHANGE_CUSTOMER',
    OPEN_DELETE_DIALOG: 'OPEN_DELETE_DIALOG',
    CLOSE_DELETE_DIALOG: 'CLOSE_DELETE_DIALOG',
    SAVE_CUSTOMER_DATA_STARTING: 'SAVE_CUSTOMER_DATA_STARTING',
    SAVE_CUSTOMER_DATA_SUCCESS: 'SAVE_CUSTOMER_DATA_SUCCESS',
    SAVE_CUSTOMER_DATA_ERROR: 'SAVE_CUSTOMER_DATA_ERROR',
    DELETE_CUSTOMER_DATA_STARTING: 'DELETE_CUSTOMER_DATA_STARTING',
    DELETE_CUSTOMER_DATA_SUCCESS: 'DELETE_CUSTOMER_DATA_SUCCESS',
    DELETE_CUSTOMER_DATA_ERROR: 'DELETE_CUSTOMER_DATA_ERROR',
}