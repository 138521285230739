import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {

  let transformedSeries = {
    series1: null,
    series2: null,
    series3: null,
  };

  let depthSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.COIL_DEPTH]);
  transformedSeries.series1 = unitChartService.transformSeries(depthSeries, 1);

  let wellheadPressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.COIL_WELLHEAD_PRESSURE]);
  transformedSeries.series2 = unitChartService.transformSeries(wellheadPressureSeries, 1);

  let circulatingPressureSeries = _.find(rawSeries, ['id', unitChartSeriesTypes.COIL_CIRCULATING_PRESSURE]);
  transformedSeries.series3 = unitChartService.transformSeries(circulatingPressureSeries, 1);

  return transformedSeries;
};

export {
  transformSeries,
}
