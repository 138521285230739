import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Typography, Box, Tooltip} from '@mui/material';

import fleetMapStyles from '../../fleetMapStyles';

import Bar from '../../../../controls/charts/bar/bar';
import RpmGear from '../../../../controls/charts/rpmgear/rpmGear';
import Rate from '../../../../controls/charts/rate/rate';
import UnitAlarmBadge from '../unitAlarmBadge';
import { mdtPalette } from "../../../../common/styles/mdtPalette";

const styles = {
  ...fleetMapStyles,
  // Override the default unitContent style
  unitContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '90%'
  },
};

class Pump extends Component {
  render() {
    const valueColor = mdtPalette().categories.category6;
    const totalColor = mdtPalette().categories.category1;

    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;
    data.suction =  _.isNil(this.props.data.suction) ? {} : this.props.data.suction;
    data.hhp =  _.isNil(this.props.data.hhp) ? {} : this.props.data.hhp;
    data.discharge =  _.isNil(this.props.data.discharge) ? {} : this.props.data.discharge;
    data.rate =  _.isNil(this.props.data.rate) ? {} : this.props.data.rate;
    data.hasSand =  _.isNil(this.props.data.hasSand) ? false : this.props.data.hasSand;

    let navigate = _.isNil(this.props.navigateToDashboard) ? {} : this.props.navigateToDashboard;

    return (
      <Box sx={styles.unitMapContainer}>
        <Box sx={styles.pumpUnitVisualContentWithCursor} onClick={() => navigate(data.id)}>
          {
            data.hasSand === true && <Box sx={styles.unitVisualHeaderHasSandContent}>
              <Tooltip disableFocusListener followCursor={true} title={'Has Sand'}
                 PopperProps={{
                   modifiers: [
                     {
                       name: 'offset',
                       options: {
                         offset: [0, 14]
                       },
                     },
                   ]
               }}>
                <Typography variant={'subtitle1'}>{data.name}</Typography>
              </Tooltip>
            </Box>
          }
          {
            data.hasSand !== true && <Box sx={styles.unitVisualHeaderContent}>
              <Typography variant={'subtitle1'}>{data.name}</Typography>
            </Box>
          }
          <Box sx={styles.unitContent}>
            <Bar value={data.suction.value} total={data.suction.total} label="Suction" valueColor={valueColor} totalColor={totalColor}/>
            <Bar value={data.hhp.value} total={data.hhp.total} label="HHP" valueColor={valueColor} totalColor={totalColor}/>
            <RpmGear rpmValue={data.rpm} gearValue={data.gear}/>
            <Bar value={data.discharge.value} total={data.discharge.total} label="Discharge" valueColor={valueColor} totalColor={totalColor}/>
            <Rate value={data.rate.value} label="Rate" tripped={data.rate.tripped} idle={data.rate.idle} stopped={data.rate.stopped}/>
          </Box>
          <Box sx={styles.badgeContainer}>
            <UnitAlarmBadge alarmCounts={data.alarmCounts}/>
          </Box>
        </Box>
      </Box>
    );
  }
}

Pump.propTypes = {
  data: PropTypes.object.isRequired,
  navigateToDashboard: PropTypes.func.isRequired,
};

export default Pump;
