export default {
  SETTINGS_EXPECTED_LIFE_QUERY_STARTING: 'SETTINGS_EXPECTED_LIFE_QUERY_STARTING',
  SETTINGS_EXPECTED_LIFE_QUERY_SUCCESS: 'SETTINGS_EXPECTED_LIFE_QUERY_SUCCESS',
  SETTINGS_EXPECTED_LIFE_QUERY_ERROR: 'SETTINGS_EXPECTED_LIFE_QUERY_ERROR',
  SETTINGS_EXPECTED_LIFE_SAVE_STARTING: 'SETTINGS_EXPECTED_LIFE_SAVE_STARTING',
  SETTINGS_EXPECTED_LIFE_SAVE_SUCCESS: 'SETTINGS_EXPECTED_LIFE_SAVE_SUCCESS',
  SETTINGS_EXPECTED_LIFE_SAVE_ERROR: 'SETTINGS_EXPECTED_LIFE_SAVE_ERROR',
  SETTINGS_EXPECTED_LIFE_SET_SELECTED_UNIT_TYPE: 'SETTINGS_EXPECTED_LIFE_SET_SELECTED_UNIT_TYPE',
  SETTINGS_EXPECTED_LIFE_SET_EXPECTED_LIFE: 'SETTINGS_EXPECTED_LIFE_SET_EXPECTED_LIFE',
  SETTINGS_EXPECTED_LIFE_REVERT_FORM: 'SETTINGS_EXPECTED_LIFE_REVERT_FORM',
};