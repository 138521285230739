import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudIcon from '@mui/icons-material/CloudOutlined';
import CloudDoneIcon from '@mui/icons-material/CloudDoneOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutline';

import { mdtPalette } from './styles/mdtPalette';

const getStatusIconStyles = () => ({
  onlineStatus : {
    color: mdtPalette().status.level2,
    fontSize: '36px',
  },
  offlineStatus: {
    color: mdtPalette().status.level1,
    fontSize: '36px',
  },
  inactiveStatus: {
    color: mdtPalette().status.level2,
    fontSize: '36px',
  },
  currentStatus : {
    color: mdtPalette().status.level2,
    fontSize: '36px',
  },
  behindStatus : {
    color: mdtPalette().status.level4,
    fontSize: '36px',
  },
  unknownStatus : {
    color: mdtPalette().status.level1,
    fontSize: '36px',
  },
  uploadingStatus : {
    color: mdtPalette().status.level2,
    fontSize: '36px',
  },
  notUploadingStatus : {
    color: mdtPalette().status.level1,
    fontSize: '36px',
  }
});

const getMediumStatusIconStyles = () => ({
  onlineStatus : {
    color: mdtPalette().status.level2,
    fontSize: '32px',
  },
  offlineStatus: {
    color: mdtPalette().status.level1,
    fontSize: '32px',
  },
  inactiveStatus: {
    color: mdtPalette().status.level2,
    fontSize: '32px',
  },
  currentStatus : {
    color: mdtPalette().status.level2,
    fontSize: '32px',
  },
  behindStatus : {
    color: mdtPalette().status.level4,
    fontSize: '32px',
  },
  unknownStatus : {
    color: mdtPalette().status.level1,
    fontSize: '32px',
  },
  uploadingStatus : {
    color: mdtPalette().status.level2,
    fontSize: '32px',
  },
  notUploadingStatus : {
    color: mdtPalette().status.level1,
    fontSize: '32px',
  }
});

const getClientStatusIcon = (status, styles) => {
  switch (status) {
    case 'Online':
      return <CloudDoneIcon sx={styles.onlineStatus}/>;
    case 'Offline':
      return <CloudOffIcon sx={styles.offlineStatus}/>;
    case 'Inactive':
      return <DesktopAccessDisabledIcon sx={styles.inactiveStatus}/>;
    default:
      return <HelpOutlinedIcon sx={styles.unknownStatus}/>
  }
};

const getDataStatusIcon = (status, styles) => {
  switch (status) {
    case 'Current':
      return <CheckCircleOutlinedIcon sx={styles.currentStatus}/>;
    case 'Behind':
      return <ScheduleOutlinedIcon sx={styles.behindStatus}/>;
    case 'Unknown':
      return <HelpOutlinedIcon sx={styles.unknownStatus}/>;
    default:
      return <div/>
  }
};

const getProductStatusIcon = (status, styles) => {
  switch (status) {
    case 'Online':
      return <CheckCircleOutlinedIcon sx={styles.onlineStatus}/>;
    case 'Offline':
      return <BlockOutlinedIcon sx={styles.offlineStatus}/>;
    default:
      return <div/>
  }
};

const getUploadStatusIcon = (isUploading, styles) => {
  if (isUploading) {
    return <CloudUploadIcon sx={styles.uploadingStatus}/>;
  }
  return <CloudIcon sx={styles.notUploadingStatus}/>
};

export {
  getClientStatusIcon,
  getUploadStatusIcon,
  getDataStatusIcon,
  getProductStatusIcon,
  getStatusIconStyles,
  getMediumStatusIconStyles,
}