import _ from 'lodash';

import { splitButtonState } from '../../common/splitButton/splitButtonSelectors';

const datasourceTemplates = () => {
  return [
    {
      id: null,
      name: 'MPC HMI',
      databaseName: 'mdtmpc',
      databaseHostIP: 'http://localhost',
      databaseHostPort: '8086',
      datasourceGuid: '',
      registrationServerHostIP: 'http://10.10.11.207',
      registrationServerHostPort: '8080',
      sourceType: datasourceSourceTypes()[0],
      delete: false,
      deleteValidation: '',
      willDelete: false,
      expanded: false,
      hasChanges: false,
      hasDuplicateNameError: false,
    },
    {
      id: null,
      name: 'Treater HMI',
      databaseName: 'mdtspy',
      databaseHostIP: 'http://localhost',
      databaseHostPort: '8086',
      datasourceGuid: '',
      registrationServerHostIP: 'http://10.10.12.217',
      registrationServerHostPort: '8080',
      sourceType: datasourceSourceTypes()[0],
      delete: false,
      deleteValidation: '',
      willDelete: false,
      expanded: false,
      hasChanges: false,
      hasDuplicateNameError: false,
    },
    {
      id: null,
      name: 'Local',
      databaseName: '',
      databaseHostIP: 'http://localhost',
      databaseHostPort: '8086',
      datasourceGuid: '',
      registrationServerHostIP: 'http://localhost',
      registrationServerHostPort: '8080',
      sourceType: datasourceSourceTypes()[0],
      delete: false,
      deleteValidation: '',
      willDelete: false,
      expanded: false,
      hasChanges: false,
      hasDuplicateNameError: false,
    },
    {
      id: null,
      name: 'Custom',
      databaseName: '',
      databaseHostIP: '',
      databaseHostPort: '',
      datasourceGuid: '',
      registrationServerHostIP: '',
      registrationServerHostPort: '',
      sourceType: datasourceSourceTypes()[0],
      delete: false,
      deleteValidation: '',
      willDelete: false,
      expanded: false,
      hasChanges: false,
      hasDuplicateNameError: false,
    }
  ]
}

const datasourceSourceTypes = () => {
  return [
    {
      value: 'sample',
      label: 'Sample'
    },
    {
      value: 'analytics',
      label: 'Analytics',
    }
  ]
}

const fleetMgmtState = state => {

  const commonSplitButtonState = splitButtonState(state);
  const options = _.map(datasourceTemplates(), 'name');

  return {
    ...commonSplitButtonState,
    splitButtonOptions: options,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    fleets: _.isNil(state) ? [] : state.fleets,
    rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    fleetToSave: _.isNil(state) ? {} : state.fleetToSave,
    originalFleet: _.isNil(state) ? {} : state.originalFleet,
    openSaveFleet: _.isNil(state) ? false : state.openSaveFleet,
    fleetToMove: _.isNil(state) ? {} : state.fleetToMove,
    errorsOnFleetSave: _.isNil(state) ? {} : state.errorsOnFleetSave,
    openToggleFleetConfirmation: _.isNil(state) ? false : state.openToggleFleetConfirmation,
    openMoveFleetDialog: _.isNil(state) ? false : state.openMoveFleetDialog,
    availableOwners: _.isNil(state) ? [] : state.availableOwners,
    errorsOnFleetMove: _.isNil(state) ? {} : state.errorsOnFleetMove,
    owners: _.isNil(state) ? [] : state.owners,
    fleetHasChanges: _.isNil(state) ? false : state.fleetHasChanges,

    saveFleetTabIndex: _.isNil(state) ? 0 : state.saveFleetTabIndex,
    datasources: _.isNil(state) ? [] : state.datasources,
    originalDatasources:  _.isNil(state) ? [] : state.originalDatasources, // This gets updated when the Save/Edit dialog is opened
    datasourceTemplates: datasourceTemplates(),
    datasourceSourceTypes: datasourceSourceTypes(),
    datasourcesChanged: _.isNil(state) ? false : state.datasourcesChanged,
    datasourceDeleteValidationKeyword: 'DELETE',

    showRestartProducerDialog: _.isNil(state) ? false : state.showRestartProducerDialog,

    datavanNameHelperText: _.isNil(state) ? 'Datavan' : state.datavanNameHelperText,
    warningDatavanOnFleetSave: _.isNil(state) ? false : state.warningDatavanOnFleetSave,

    saveStatus: {
      fleetOk: _.isNil(state) ? null : state.saveStatus.fleetOk,
      datasourcesOk: _.isNil(state) ? null : state.saveStatus.datasourcesOk,
    }
  };
};

export { fleetMgmtState, datasourceSourceTypes};