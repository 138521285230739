import _ from 'lodash';
import moment from 'moment';

import assetTrackingActionTypes from '../assetTrackingActionTypes';
import { assetTrackingState } from '../assetTrackingSelectors';
import * as assetTrackingService from '../services/assetTrackingService';

const initialState = assetTrackingState();

const assetReducer = (state = initialState, action) => {

  switch (action.type) {

    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_STARTING:
    case assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_STARTING:
    case assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_STARTING:
    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_STARTING:
      return { ...state, queryRunning: true };
    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_ERROR:
    case assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_ERROR:
    case assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_ERROR:
    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_ERROR:
      return { ...state, queryRunning: false };
    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_ASSET_DATA_SUCCESS:
      return onAssetTrackingQuerySuccess(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_UPDATE_EDIT_MODE:
      return updateEditMode(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_SAVE_LOCATIONS_SUCCESS:
      return onSaveLocationsSuccess(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_DELETE_LOCATION_SUCCESS:
      return onDeleteLocationSuccess(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_QUERY_LOCATIONS_SUCCESS:
      return onQueryLocationsSuccess(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_UPDATE_CAN_SAVE:
      return onUpdateCanSave(state, action);
    case assetTrackingActionTypes.ASSET_TRACKING_SET_SHOW_INACTIVE:
      return onSetShowInActive(state, action);
    default:
      return state;
  }
};

const onAssetTrackingQuerySuccess = (state, action) => {

  let processAssetsAndFilterInActiveAssets = assetTrackingService.processAssetsAndFilterInActiveAssets(action.queryResults.devicesForOwner, state.appliedFilters, state.showInActive);

  return {
    ...state,
    queryRunning: false,
    assets: processAssetsAndFilterInActiveAssets.assets,
    filteredAssets: processAssetsAndFilterInActiveAssets.filteredAssets,
    filters: processAssetsAndFilterInActiveAssets.filters,
    editMode: false,
    refreshTimestamp: moment().unix()
  }

}

const updateEditMode = (state, action) => {
  return {
      ...state,
      editMode: action.editMode,
  }
}

const onSaveLocationsSuccess = (state, action) => {

  // At the component level, we have shape groups to represent the locations so here we just
  // save the returned locations back to the state and update shape groups at the component level to have the any new location ids
  let locations = assetTrackingService.processLocations(action.queryResults.saveLocations);

  return {
    ...state,
    queryRunning: false,
    locations: locations,
    canSave: false
  }
}

const onDeleteLocationSuccess = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    canSave: false
  }
}

const onQueryLocationsSuccess = (state, action) => {

  let locations = assetTrackingService.processLocations(action.queryResults.locationsForOwner);

  return {
    ...state,
    locations: locations,
    queryRunning: false,
    canSave: false
  }
}

const onUpdateCanSave = (state, action) => {
  return {
    ...state,
    canSave: action.canSave
  }
}

const onSetShowInActive = (state, action) => {

  let processAssetsAndFilterInActiveAssets = assetTrackingService.processAssetsAndFilterInActiveAssets(state.assets, state.appliedFilters, action.showInActive);
  
  return {
    ...state,
    showInActive: action.showInActive,
    assets: processAssetsAndFilterInActiveAssets.assets,
    filteredAssets: processAssetsAndFilterInActiveAssets.filteredAssets,
    filters: processAssetsAndFilterInActiveAssets.filters,
    refreshTimestamp: moment().unix()
  }
}


export default assetReducer