import React, { Component , Fragment } from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import ComponentTypes from "../../../componentTypes";
import {Box, Card, Checkbox, IconButton, Tooltip, Typography} from "@mui/material";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {Container, Draggable} from "@edorivai/react-smooth-dnd";
import Grid from "@mui/system/Unstable_Grid";
import {getCardFromLayoutConfigViews} from "../../../common/layout/layoutHelper";
import PropTypes from 'prop-types';

class AlarmCountConfigPanel extends Component {

    render() {
        return (
            this.getAlarmConfigContent()
        )
    }

    getAlarmConfigContent() {
        const currentConfig = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard].views, this.props.card.view, this.props.card.stateKey);
        if( [1, 2].includes(currentConfig.w)){
            return <></>;
        }
        //only show columns options for bigger cards
        else {
            return (
                <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)"}}>
                    <Container lockAxis='y' dragHandleSelector=".drag-handle" onDrop={this.props.onReorderColumns}>
                        { _.map(currentConfig.configuration.columns, (column, index) => { return (
                            <Fragment key={index}>
                                <Draggable>
                                    <Card sx={{marginBottom: 1}}>
                                        <Grid container alignItems={'center'} sx={{minHeight:'60px'}}>
                                            <Grid sx={{ width: '330px', height: '100%', paddingLeft: 2 }}>
                                                <Typography  size='small' variant='standard'>{column.name}</Typography>
                                            </Grid>
                                            <Grid sx={{ width: '85px', height: '100%'}}>
                                                <Checkbox
                                                    checked={column.visible}
                                                    onChange={(event) => this.props.onToggleColumnVisibility(index)}
                                                />
                                                <IconButton className="drag-handle">
                                                    <Tooltip title='Move component' disableInteractive={true}>
                                                        <DragHandleIcon/>
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Draggable>
                            </Fragment>
                        )})}
                    </Container>
                </Box>
            )
        }

    }


}

AlarmCountConfigPanel.propTypes = {
  card: PropTypes.object,
  onReorderColumns: PropTypes.func,
  onToggleColumnVisibility: PropTypes.func,
  dashboards: PropTypes.object
};

export default AlarmCountConfigPanel;