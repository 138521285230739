import React, {Component} from 'react';

import {Box, Typography} from '@mui/material'

import getCardStyles from '../../cardStyles';
import getLayoutStyles from '../../../controls/layout/layoutStyles';
import PropTypes from 'prop-types';
import _ from "lodash";
import MDTDataGrid from "../../../common/table/MDTDataGrid";
import getTypographyStyles from "../../../common/styles/typographyStyles";
import moment from "moment";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...typographyStyles,
  ...cardStyles,
  ...layoutStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

class AuditLogCard extends Component {

  getRenderContentWithAuditLog(){
    // parse the age to expected format for display
    const parseAge = (params) => {
      // expect incoming age to be in unix seconds
      return moment.unix(params.row?.Age).fromNow();
    }

    const visibleColumns = _.filter(this.props.cardConfig.configuration.columns, ['visible', true]);
    if(_.isEmpty(visibleColumns)){
      return (
          <>
            <Typography variant={'caption'} sx={styles.noDataLabel}>No Columns Selected</Typography>
          </>
      );
    }
    else if ( !_.isEmpty(this.props.auditLogs)){
      //make data with expected column name
      const data = this.props.auditLogs.map(function (c){
            return {
              'id': c.id,
              'DatavanName':c.datavanName,
              'TruckName':c.truckName,
              'Age': c.age,
              'ActionType': c.actionType,
              'Detail':c.detail,
              'NewState': c.newState,
              'OldState': c.oldState,
              'Source': c.source,
              'SubType': c.subType,
              'UiComponent': c.uiComponent,
              'RaisedTimestamp': c.timestamp
            };
          }

      );
      // apply dataGrid configs for each visible column
      const columns = visibleColumns.map(function(c) {
        return {
          field: c.name,
          headerName: c.name.replace(/([A-Z])/g, ' $1').trim(), //add space between each uppercase character
          headerAlign: 'center',
          flex: ['ActionType', 'Detail', 'RaisedTimestamp', 'UiComponent'].includes(c.name) ? 2: 1,
          editable: false,
          hideable: false,
          pinnable: false,
          cellClassName: ['ActionType', 'Detail', 'SubType', 'UiComponent'].includes(c.name) ? 'dataGridTableCellLeftAlign': 'dataGridTableCell',
          // parse the age to expected format
          renderCell: c.name === 'Age' ?  parseAge : null
        };
      });
      return (
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
                initialState={{
                  sorting: {
                    // use Age instead of RaisedTimestamp to initial sort the data
                    // because we use Age as default field
                    sortModel: [{ field: 'Age', sort: 'desc' }],
                  },
                }}
                getRowId={row => row.id}
                rows={data}
                columns={columns}
                getRowHeight={() => 'auto'}
                stateDef={this.props.stateDef}
                disableSelectionOnClick
                pagination={false}
                components={{Toolbar: null}}
                disableColumnMenu={true}
                disableColumnReorder={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                hideFooter={true}
                autoHeight={false}

            />
          </Box>
      );
    } else {
      return (
          <>
            <Typography variant={'caption'} sx={styles.noDataLabel}>No Audit Log</Typography>
          </>
      );
    }

  }

  render() {
    return (
        <Box sx={styles.cardContent}>
          {this.getRenderContentWithAuditLog()}
        </Box>
    )
  }
}

AuditLogCard.propTypes = {
  auditlogs: PropTypes.object,
  cardConfig: PropTypes.object,
  stateDef: PropTypes.object,
};

export default AuditLogCard;