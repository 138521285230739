import _ from 'lodash';

const processDetails = (queryResults) => {

  let state = _.cloneDeep(queryResults.operationalFleetMap.operationViewData);

  return {
    ...state,
    wellHead: parseWellHead(state.wellHead),
    datavan: parseDatavan(state.datavan),
    pumps: _.map(state.pumps, parsePump),
    sand1: parseSand(state.sand1),
    sand2: parseSand(state.sand2)
  };
};

const parseWellHead = (wellHead) => {

  if (!wellHead) {
    return null;
  }

  let { flowRate, pressure, density1, density2 } = wellHead;

  let displayFlowRate = _.isNil(flowRate) ? '-' : Number(flowRate).toFixed(1);
  let displayPressure = _.isNil(pressure) ? '-' : Number(pressure).toFixed(1);
  let displayDensity1 = _.isNil(density1) ? '-' : Number(density1).toFixed(1);
  let displayDensity2 = _.isNil(density2) ? '-' : Number(density2).toFixed(1);

  return {
    ...wellHead,
    flowRate: displayFlowRate,
    pressure: displayPressure,
    density1: displayDensity1,
    density2: displayDensity2
  }

};

const parseDatavan = (datavan) => {

  if (!datavan) {
    return null;
  }

  let { totalSand, totalDischarge } = datavan;

  // Convert these to strings so we can see the commas
  let displayTotalSandValue = _.isNil(totalSand) ? '-' : Number(totalSand.toFixed(1)).toLocaleString('en');
  let displayTotalDischargeValue = _.isNil(totalDischarge) ? '-' : Number(totalDischarge.toFixed(1)).toLocaleString('en');

  return {
    ...datavan,
    totalSand: displayTotalSandValue,
    totalDischarge: displayTotalDischargeValue
  }

};

const parsePump = (pump) => {

  if (!pump) {
    return null;
  }

  let { id, name, slot, suction, hhp, discharge, gear, rpm, rate, empty } = pump;

  let displayGear = _.isNil(gear) ? '-' : gear;
  let displayRpm = _.isNil(rpm) ? 0 : rpm.toFixed(0);

  if (empty) {
    return {
      ...pump,
      id: id,
      name: name,
      slot: slot,
      empty: empty
    }
  } else {
    return {
      ...pump,
      suction: parseSuction(suction),
      hhp: parseHHP(hhp),
      discharge: parseDischarge(discharge),
      rpm: Number(displayRpm),
      gear: displayGear,
      rate: parseRate(rate)
    }
  }
};

const parseRate = (rate) => {

  let { value } = rate;

  let displayValue = (value) ? value.toFixed(1).toLocaleString('en') : '-';

  return {
    ...rate,
    value: displayValue,
    // Always force idle false if tripped is true, otherwise the UI will render for
    // both conditions.
    idle: rate.tripped === true ? false : rate.idle
  }
};

const parseSuction = (suction) => {
  if (!suction) {
    return {
      ...suction,
      value: 0,
      total: 0
    }
  }

  let { value, total } = suction;

  let displayValue = _.isNil(value) ? 0 : Number(value.toFixed(0));
  let displayTotal = _.isNil(total) ? 0 : Number(total.toFixed(0));

  return {
    ...suction,
    value: displayValue,
    total: displayTotal,
  }
};

const parseHHP = (hhp) => {
  if (!hhp) {
    return {
      ...hhp,
      value: 0,
      total: 0
    }
  }

  let { value, total } = hhp;

  let displayValue = _.isNil(value) ? 0 : Number(value.toFixed(0));
  let displayTotal = _.isNil(total) ? 0 : Number(total.toFixed(0));

  return {
    ...hhp,
    value: displayValue,
    total: displayTotal,
  }
};

const parseDischarge = (discharge) => {
  if (!discharge) {
    return {
      ...discharge,
      value: 0,
      total: 0
    }
  }

  let { value, total } = discharge;

  let displayValue = _.isNil(value) ? 0 : Number(value.toFixed(0));
  let displayTotal = _.isNil(total) ? 0 : Number(total.toFixed(0));

  return {
    ...discharge,
    value: displayValue,
    total: displayTotal,
  }
};

const parseSand = (sand) => {

  if (_.isNil(sand)) {
    return null;
  }

  let { cleanRate, massConc, selectedMassFlow } = sand;

  // Convert these to strings so we can see the commas
  let displayCleanRateValue = _.isNil(cleanRate) ? '-' : Number(cleanRate.toFixed(2)).toLocaleString('en');
  let displayMassConcValue = _.isNil(massConc) ? '-' : Number(massConc.toFixed(2)).toLocaleString('en');
  let displaySelectedMassFlowValue = _.isNil(selectedMassFlow) ? '-' : Number(selectedMassFlow.toFixed(2)).toLocaleString('en');
  let displaySelectedSetPointValue = _.isNil(sand.selectedSetPoint) ? '-' : Number(sand.selectedSetPoint.toFixed(2)).toLocaleString('en');

  return {
    ...sand,
    cleanRate: displayCleanRateValue,
    massConc: displayMassConcValue,
    selectedMassFlow: displaySelectedMassFlowValue,
    selectedSetPoint: displaySelectedSetPointValue
  }

}

export {
  processDetails
}