import _ from "lodash";

const fetchWellsDisplayData = () => {

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                wells:
                    [
                        {
                            id: 1,
                            name: 'wellA',
                            apiNumber: '432523444432',
                            leaseName: 'lease1',
                            leaseNumber: '12345',
                            padName: 'pad1',
                            latitude: '41.40338',
                            longitude: '2.17403'
                        },
                        {
                            id: 2,
                            name: 'wellB',
                            apiNumber: null,
                            leaseName: null,
                            leaseNumber: null,
                            padName: null,
                            latitude: null,
                            longitude: null
                        }
                    ]
            })
        }, 10)
    });
};

const fetchDeleteWell = () => {

  let simulatedFail = _.random(1, 10) % 2 === 0 ? true : false;
  let successReturn = {
    success: true
  };

  if (simulatedFail === true) {
    successReturn.success = false;
    successReturn.message = 'Well is currently in use by jobs. Active jobs - [J-1234,J-ABCEFGHI,J-JOBJOBJOB,J-TEST1234]';
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ...successReturn
      })
    }, 1000)
  });

}

export {
    fetchWellsDisplayData,
    fetchDeleteWell
}
