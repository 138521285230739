import { performDisplayFetch } from '../../../common/fetch';
import * as warmStartSimulation from './warmStartSimulation';
import useSimulationData from '../../../../helpers/simulationHelper';

const fetchWarmStartSettings = () => {

  if (useSimulationData('warmStartSettings')) {
    return warmStartSimulation.fetchWarmStartSettings();
  }

  return performDisplayFetch({
    'query': '{ ownerWarmStartSetting { avgFuelCost } }'
  });
};

const fetchTruckWarmStartSettings = () => {

  if (useSimulationData('truckWarmStartSettings')) {
    return warmStartSimulation.fetchTruckWarmStartSettings();
  }

  return performDisplayFetch({
    'query': '{ allTrucksWarmStartSettingForOwner { truckId truckName truckEngineModel avgIdelFuelConsumption} }'
  });
};

const fetchSaveWarmStartSettings = (averageFuelCost) => {

  if (useSimulationData('warmStartSettings')) {
    return warmStartSimulation.fetchSaveWarmStartSettings(averageFuelCost);
  }

  return performDisplayFetch({
    'query': 'mutation SaveOwnerWarmStartSetting($warmStartSettingInput:WarmStartSettingInput!) { saveOwnerWarmStartSetting(warmStartSettingInput:$warmStartSettingInput) { avgFuelCost } }',
    'variables': {
      'warmStartSettingInput': {
        'avgFuelCost': averageFuelCost
      }
    }
  });
};

const fetchSaveTruckWarmStartSettings = (truckId, averageFuelConsumption) => {

  return performDisplayFetch({
    'query': 'mutation SaveTruckWarmStartSetting($truckInput:TruckWarmStartSettingInput!) { saveTruckWarmStartSetting(truckInput: $truckInput) { success } }',
    'variables': {
      'truckInput': {
        'truckId': truckId,
        'avgFuelConsumption': averageFuelConsumption
      }
    }
  });
};

export {
  fetchWarmStartSettings,
  fetchTruckWarmStartSettings,
  fetchSaveWarmStartSettings,
  fetchSaveTruckWarmStartSettings
}