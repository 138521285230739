import React, {Component} from 'react';

import {Box } from '@mui/material'

import Alarms from '../../../controls/charts/alarms/alarms'

import getCardStyles from '../../cardStyles';
import getLayoutStyles from '../../../controls/layout/layoutStyles';
import PropTypes from 'prop-types';

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

class AlarmCountCard extends Component {

  render() {
    return (
        <Box sx={styles.cardContent}>
          <Alarms isLarge={this.props.cardConfig?.w !== 1} showIfZero={true} alarmCounts={this.props.alarmCounts} cardConfig={this.props.cardConfig} stateDef={this.props.stateDef}/>
        </Box>
    )
  }
}

AlarmCountCard.propTypes = {
  alarmCounts: PropTypes.object,
  cardConfig: PropTypes.object,
  stateDef: PropTypes.object,
};

export default AlarmCountCard;