import defaultActionFactory from "../../common/factories/defaultActionFactory";
import fleetMgmtActionTypes from "./fleetMgmtActionTypes";
import {
  fetchFleets, 
  fetchMoveFleet, 
  fetchOwners, 
  fetchSaveFleet, 
  fetchDeleteDatasource,
  fetchDatasourcesForFleet,
  fetchSaveDatasource,
  fetchRestartProducer,
  fetchAssetInfoForDatavan } from "./fleetMgmtQueries";
import errorMessages from "../../common/errorMessages";
import queryActionFactory from "../../common/factories/queryActionFactory";
import { handleError } from "../../app/actions/appErrorActions";
import _ from "lodash";
import OWNERS from "../../../components/common/owners";

const onOpenSaveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG, "stateDef", "fleetToSave");
const queryDatasourcesForFleet = queryActionFactory(
  fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_STARTING,
  fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_SUCCESS,
  fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_ERROR,
  errorMessages.ERROR_RETRIEVING_DATASOURCES_FOR_FLEET,
  fetchDatasourcesForFleet
);

const openSaveFleetDialog = (stateDef, fleetToSave) => {
  return async (dispatch, getState) => {
    if (!_.isNil(fleetToSave.id)) {
      await dispatch(queryDatasourcesForFleet(stateDef, fleetToSave.id));
    }
    return await dispatch(onOpenSaveFleetDialog(stateDef, fleetToSave));
  }
}

const onCloseSaveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG, "stateDef");
const onOpenToggleFleetConfirmationDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG, "stateDef", "fleet");
const onCloseToggleFleetConfirmationDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG, "stateDef");
const onOpenMoveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG, "stateDef", "fleet");
const onCloseMoveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG, "stateDef");

const queryFleets = queryActionFactory(
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_STARTING,
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_SUCCESS,
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEETS,
  fetchFleets
);

const queryOwners = queryActionFactory(
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_STARTING,
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_SUCCESS,
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_ERROR,
    errorMessages.ERROR_RETRIEVING_OWNERS,
    fetchOwners
);
const onChangeFleet = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CHANGE_FLEET_DATA, "stateDef", "property", "value");
const onChangeMoveFleet = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CHANGE_MOVE_FLEET_DATA, "stateDef", "property", "value");

const validateDatavanAssetIdStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING, "stateDef");
const validateDatavanAssetIdSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS, "stateDef", "assetInfo");
const validateDatavanAssetIdError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR, "stateDef");

const onBlurDatavan = (stateDef, datavanName, ownerId) => {
    return async (dispatch, getState) => {
        if(ownerId === OWNERS.NEXTIER && !_.isEmpty(datavanName)) {
            let queryResult;
            try {
                await dispatch(validateDatavanAssetIdStarting(stateDef));
                queryResult = await fetchAssetInfoForDatavan(datavanName);
                await dispatch(validateDatavanAssetIdSuccess(stateDef, queryResult.assetInfoForTruck));
            } catch (e) {
                await dispatch(validateDatavanAssetIdError(stateDef));
                return dispatch(handleError(errorMessages.ERROR_RETRIEVING_ASSETID_FOR_DATAVAN, e.message));
            }
        }
    };
};

const saveFleetDataStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_STARTING, "stateDef");
const saveFleetDataSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_SUCCESS, "stateDef");
const saveFleetDataError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_ERROR, "stateDef");

const onSaveFleet = (stateDef, fleet) => {
  return async (dispatch, getState) => {
    let savedFleet = null;
    try {
      await dispatch(saveFleetDataStarting(stateDef));
      savedFleet = await fetchSaveFleet(fleet);

      await dispatch(setSaveFleetStatus(stateDef, true));

      // delete any datasources confirmed for delete
      const datasources = getState()[stateDef.key].datasources;
      const datasourcesToDelete = datasources.filter(datasource => datasource.willDelete === true);
      await Promise.all(datasourcesToDelete.map(datasource => {
        fetchDeleteDatasource(fleet.id, datasource.id);
      }));

      // save any datasources that have been added or updated
      const datasourcesToSave = datasources.filter(datasource => datasource.hasChanges === true);
      await Promise.all(datasourcesToSave.map(datasource => {
        const datasourceInput = {
          id: datasource.id,
          fleetId: _.isNil(fleet.id) ? savedFleet.saveFleet.id : fleet.id,
          name: datasource.name,
          datasourceIdentifier: datasource.datasourceGuid,
          databaseName: datasource.databaseName,
          ipAddress: datasource.registrationServerHostIP + ':' + datasource.registrationServerHostPort,
          databaseHost: datasource.databaseHostIP + ':' + datasource.databaseHostPort,
          sourceType: datasource.sourceType.value
        };
        fetchSaveDatasource(datasourceInput);
      }));

      await dispatch(setSaveDatasourcesStatus(stateDef, true));

      await dispatch(saveFleetDataSuccess(stateDef));
      await dispatch(queryFleets(stateDef));

      //We have a case of set a active fleet inactive when saving a nextier fleet without a datavan with assetId assigned
      //So we check the active status and assume if the returned fleet active state is different from the fleet requested, we will send a notification message
      if (!_.isNil(savedFleet.saveFleet) && savedFleet.saveFleet.active !== fleet.active) {
        const errorMessage = errorMessages.WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_DISPLAY.replaceAll("{fleet}", savedFleet.saveFleet.fleetName)
          .replaceAll("{datavan}", savedFleet.saveFleet.datavan);
        await dispatch(handleError(errorMessage));
      }
    } catch (e) {

      // The absence of any value in any of the saveStatus properties means that we did not successfully execute the save
      // If the save was successful, the corresponding saveStatus property will be set to true
      const saveStatus = getState()[stateDef.key].saveStatus;
      if (_.isNil(saveStatus.fleetOk)) {
        await dispatch(setSaveFleetStatus(stateDef, false));
      }
      if (_.isNil(saveStatus.datasourcesOk)) {
        await dispatch(setSaveDatasourcesStatus(stateDef, false));
        if (!_.isNil(fleet.id)) {
          await dispatch(queryDatasourcesForFleet(stateDef, fleet.id));
        }
        else if (_.isNil(fleet.id) && !_.isNil(savedFleet) && !_.isNil(savedFleet.saveFleet)) {
          // Setting the fleet id on a new fleet that was saved but the datasources were not saved
          // So that we can issue an update when the user hits save again to save the datasources
          await dispatch(setFleetToSaveId(stateDef, savedFleet.saveFleet.id));
          await dispatch(queryDatasourcesForFleet(stateDef, savedFleet.saveFleet.id));
        }
      }

      await dispatch(saveFleetDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_FLEET, e.message));
    }
  };
};

const moveFleetDataStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_STARTING, "stateDef");
const moveFleetDataSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_SUCCESS, "stateDef");
const moveFleetDataError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_ERROR, "stateDef");

const onMoveFleet = (stateDef, fleetToMove) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(moveFleetDataStarting(stateDef));
      await fetchMoveFleet(fleetToMove);
      await dispatch(moveFleetDataSuccess(stateDef));
      await dispatch(queryFleets(stateDef));
    } catch (e) {
      await dispatch(moveFleetDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_MOVING_FLEET, e.message));
    }
  };
};

const onOpenCopyToClipboardNotification = (stateDef) => {
    return async (dispatch, getState) => {
        return await dispatch(handleError("Copied to clipboard"));
    };
};

//function for setting the rows per page in the table
const setRowsPerPage = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_ROWS_PER_PAGE, "stateDef", "rowSize");

const setSaveFleetTabIndex = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_FLEET_TAB_INDEX, "stateDef", "tabIndex");

const updateDatasource = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_UPDATE_DATASOURCE, "stateDef", "index", "property", "value");
const removeDatasource = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_REMOVE_DATASOURCE, "stateDef", "index");
const confirmRemoveDatasource = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_REMOVE_DATASOURCE, "stateDef", "index", "value");
const restoreDatasource = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_RESTORE_DATASOURCE, "stateDef", "index");
const expandDatasource = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_EXPAND_DATASOURCE, "stateDef", "index");

const showRestartProducerDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SHOW_RESTART_PRODUCER_DIALOG, "stateDef", "fleet", "show");
const onConfirmRestartProducer = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_RESTART_PRODUCER, "stateDef");
const onConfirmRestartProducerError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_RESTART_PRODUCER_ERROR, "stateDef");

const confirmRestartProducer = (stateDef) => {
  return async (dispatch, getState) => {
    try {
      const fleetToRestart = getState()[stateDef.key].fleetToSave;
      await fetchRestartProducer(fleetToRestart.fleetIdentifier);
    } catch (e) {
      await dispatch(onConfirmRestartProducerError(stateDef));
      await dispatch(handleError(errorMessages.ERROR_RESTARTING_PRODUCER, e.message));
    }
    return await dispatch(onConfirmRestartProducer(stateDef));
  }
}

const setSaveDatasourcesStatus = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_DATASOURCES_STATUS, "stateDef", "status");
const setSaveFleetStatus = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_FLEET_STATUS, "stateDef", "status");
const setFleetToSaveId = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_FLEET_TO_SAVE_ID, "stateDef", "id");

export {
    queryFleets,
    setRowsPerPage,
    openSaveFleetDialog,
    onCloseSaveFleetDialog,
    onChangeFleet,
    onChangeMoveFleet,
    onSaveFleet,
    onOpenToggleFleetConfirmationDialog,
    onCloseToggleFleetConfirmationDialog,
    onOpenMoveFleetDialog,
    onCloseMoveFleetDialog,
    onMoveFleet,
    queryOwners,
    onOpenCopyToClipboardNotification,
    setSaveFleetTabIndex,
    
    updateDatasource,
    removeDatasource,
    confirmRemoveDatasource,
    restoreDatasource,
    expandDatasource,
    showRestartProducerDialog,
    confirmRestartProducer,

    onBlurDatavan
}
