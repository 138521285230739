import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import { mdtPalette } from '../../common/styles/mdtPalette';

import AlarmCriticalBadge from '../icons/alarmCriticalBadge';

const styles = {
  smallCriticalBadgeIcon: {
    color: mdtPalette().status.level5,
    fontSize: '40px'
  },
  smallCriticalBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-37px',
    textAlign: 'center',
  },
  smallCriticalBadge: {
    height: '40px',
  },

  mediumCriticalBadgeIcon: {
    color: mdtPalette().status.level5,
    fontSize: '48px'
  },
  mediumCriticalBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-46px',
    textAlign: 'center',
  },
  mediumCriticalBadge: {
    height: '48px',
  },

  largeCriticalBadgeIcon: {
    color: mdtPalette().status.level5,
    fontSize: '64px'
  },
  largeCriticalBadge: {
    height: '64px',
  },
  largeCriticalBadgeText: {
    color: mdtPalette().typography.white,
    marginTop: '-53px',
    textAlign: 'center'
  },
};

class CriticalAlarmBadge extends Component {

  render() {
    let count = !_.isNil(this.props.count) ? this.props.count : 0;

    return (
      <Box sx={this.props.isLarge === true ? {...styles.largeCriticalBadge} : this.props.isMedium === true ? {...styles.mediumCriticalBadge} : {...styles.smallCriticalBadge}}>
        <AlarmCriticalBadge sx={this.props.isLarge === true ? {...styles.largeCriticalBadgeIcon} : this.props.isMedium === true ? {...styles.mediumCriticalBadgeIcon} : {...styles.smallCriticalBadgeIcon}}/>
        {
          this.props.isLarge === true &&
          <Typography variant={'h5'} sx={styles.largeCriticalBadgeText}>{count}</Typography>
        }
        {
          this.props.isMedium === true &&
          <Typography variant={'h6'} sx={styles.mediumCriticalBadgeText}>{count}</Typography>
        }
        {
          this.props.isLarge !== true &&  this.props.isMedium !== true &&
          <Typography variant={'subtitle2'} sx={styles.smallCriticalBadgeText}>{count}</Typography>
        }
      </Box>
    );
  }
}

CriticalAlarmBadge.propTypes = {
  count: PropTypes.number,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
};

export default CriticalAlarmBadge;