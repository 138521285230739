import _ from 'lodash';

import * as dfpService from '../services/dfpService';
import dfpDashboardActionTypes from '../dfpDashboardActionTypes';
import { dfpDashboardState } from '../dfpDashboardSelectors';
import { prettifyValue } from '../services/dfpService';
import dfpDashboardViews from "../dfpDashboardViews";
import moment from "moment";

const initialState = dfpDashboardState();

const dfpReducer = (state=initialState, action) => {

  switch (action.type) {

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_SUCCESS:
      let actualTimestamp = action.queryResults.truckDataAge.timestamp;
      let displayTimestamp = prettifyValue(actualTimestamp);
      return { ...state, dataAgeDisplay: displayTimestamp, dataAgeActual: actualTimestamp, queryRunning: false };

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_ERROR:
      return { ...state, queryRunning: false };

    case dfpDashboardActionTypes.DFP_DASHBOARD_QUERY_DATA_AGE_STARTING:
      return { ...state, queryRunning: true };

    case dfpDashboardActionTypes.DFP_DASHBOARD_DATA_AGE_CLEAR_DATA:
      return { ...state, dataAgeActual: initialState.dataAgeActual, dataAgeDisplay:  initialState.dataAgeDisplay };

    case dfpDashboardActionTypes.DFP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE:
      let refreshDisplayTimestamp = prettifyValue(state.dataAgeActual);
      return { ...state, dataAgeDisplay: refreshDisplayTimestamp };

    case dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_CONTEXT:
      return onSelectContext(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_NAVIGATION_CONTEXT:
      return onSelectNavigationContext(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_SELECT_VIEW:
      return onSetSelectedView(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_SET_CUSTOM_START_TIME:
      return onSetStartTime(state, action);

    case dfpDashboardActionTypes.DFP_DASHBOARD_SET_CUSTOM_DURATION:
      return onSetDuration(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {

  let contextDetails = dfpService.processDfps(action.queryResults.dfpDashboard, state.appliedFilters);

  return {
    ...state,
    queryRunning: false,
    dfps: action.queryResults.dfpDashboard,
    contexts: contextDetails.contexts,
    filters: contextDetails.filters,
  };

};

const onSelectContext = (state, action) => {

  if (!_.isNil(action.context) && _.isEmpty(action.context)) {

    // The AutoComplete control we use will output an empty array when using the keyboard to clear
    // out the selected value. Catch that here and set it to null so the cards all behave properly.

    return {...state, selectedContext: null };
  }

  let selectedContext = dfpService.resolveSelectedContext(
    state.contexts,
    _.isNil(action.context) ? action.context : action.context.id
  );

  return {...state, selectedContext: selectedContext };
};

const onSelectNavigationContext = (state, action) => {

  if (_.isNil(action.context) || _.isEmpty(state.contexts)) {

    // If we do not have a context or any contexts just set the selected context to null

    return {...state, selectedContext: null };
  }

  // Check to see if the context exists in the current contexts

  let selectedContext = _.find(state.contexts, { id: action.context.unitId });

  if (!_.isNil(selectedContext)) {

    // If we have a match then set the selection

    return {...state, selectedContext: selectedContext };
  }

  if (_.isEmpty(state.appliedFilters)) {

    // If we didn't find a match and there are no configured filters, then set the selection to the first context

    return {...state, selectedContext: state.contexts[0] };
  }

  // If we get here, then the context is not in the current list of filtered contexts. Process
  // the dfps without any filters and try to resolve the selected context normally.

  let contextDetails = dfpService.processDfps(state.dfps, []);

  selectedContext = dfpService.resolveSelectedContext(contextDetails.contexts, action.context.unitId);

  return {
    ...state,
    contexts: contextDetails.contexts,
    filters: contextDetails.filters,
    selectedContext: selectedContext,
    appliedFilters: initialState.appliedFilters,
  }
};

const onSetSelectedView = (state, action) => {

  let selectedView = action.view;

  // If we are given a view that we don't know about we just set it to the operation view
  selectedView = _.isNil(selectedView) ? dfpDashboardViews.OPERATION : selectedView;

  return {
    ...state,
    selectedView: selectedView,
  }
};

const onSetTimeFrame = (state, action) => {

  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    selectedCustomStartTime: initialState.selectedCustomStartTime,
    selectedCustomDuration: initialState.selectedCustomDuration,
  };

  // If the time frame is set to custom, set the initial start time to the now.

  if (newState.selectedTimeFrame.label === 'Custom') {

    let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');

    newState = {
      ...newState,
      selectedCustomStartTime: defaultTime
    }
  }

  return newState;
};

const onSetStartTime = (state, action) => {

  return {
    ...state,
    selectedCustomStartTime: action.startTime
  }
};

const onSetDuration = (state, action) => {

  return {
    ...state,
    selectedCustomDuration: action.duration
  }
};

export default dfpReducer
