import reduceReducers from 'reduce-reducers';

import { assetTrackingState } from './assetTrackingSelectors';
import filterReducer from './reducers/filterReducer';
import assetReducer from './reducers/assetReducer';
import searchReducer from './reducers/searchReducer';

const initialState = assetTrackingState();

const assetTrackingReducer = (state = initialState, action) => {

  const reducer = reduceReducers(
    assetReducer,
    filterReducer,
    searchReducer
  );

  return reducer(state, action);

};


export default assetTrackingReducer