export default {
  DFP_DASHBOARD_QUERY_DATA_STARTING: 'DFP_DASHBOARD_QUERY_DATA_STARTING',
  DFP_DASHBOARD_QUERY_DATA_SUCCESS: 'DFP_DASHBOARD_QUERY_DATA_SUCCESS',
  DFP_DASHBOARD_QUERY_DATA_ERROR: 'DFP_DASHBOARD_QUERY_DATA_ERROR',
  DFP_DASHBOARD_SELECT_CONTEXT: 'DFP_DASHBOARD_SELECT_CONTEXT',
  DFP_DASHBOARD_SELECT_NAVIGATION_CONTEXT: 'DFP_DASHBOARD_SELECT_NAVIGATION_CONTEXT',

  DFP_DASHBOARD_QUERY_DATA_AGE_STARTING: 'DFP_DASHBOARD_QUERY_DATA_AGE_STARTING',
  DFP_DASHBOARD_QUERY_DATA_AGE_SUCCESS: 'DFP_DASHBOARD_QUERY_DATA_AGE_SUCCESS',
  DFP_DASHBOARD_QUERY_DATA_AGE_ERROR: 'DFP_DASHBOARD_QUERY_DATA_AGE_ERROR',
  DFP_DASHBOARD_DATA_AGE_CLEAR_DATA: 'DFP_DASHBOARD_DATA_AGE_CLEAR_DATA',
  DFP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE: 'DFP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE',

  DFP_DASHBOARD_SELECT_VIEW: 'DFP_DASHBOARD_SELECT_VIEW',
  DFP_DASHBOARD_SET_TIME_FRAME: 'DFP_DASHBOARD_SET_TIME_FRAME',
  DFP_DASHBOARD_SET_CUSTOM_START_TIME: 'DFP_DASHBOARD_SET_CUSTOM_START_TIME',
  DFP_DASHBOARD_SET_CUSTOM_DURATION: 'DFP_DASHBOARD_SET_CUSTOM_DURATION',
};
