import React, {Component} from 'react';
import PropTypes from "prop-types";

import { Typography, Paper, Box } from '@mui/material';

import * as dataExplorerService from './dataExplorerService';

const styles = {
  container: {
    position: 'relative',
    width: dataExplorerService.SPECS.CHART_WIDTH,
    height: dataExplorerService.SPECS.TICK_CONTAINER_HEIGHT,
    borderRadius: 0,
  },
  tick: {
    position: 'absolute',
    width: '1px',
    height: dataExplorerService.SPECS.TICK_HEIGHT,
    backgroundColor: 'grey.500',
  },
  label: {
    position: 'absolute',
  },
};

class DataExplorerTicks extends Component {

  render() {

    let ticks = dataExplorerService.getTicksToRender(this.props.selectedDateTime, this.props.selectedDuration);

    return (
      <Paper sx={styles.container}>
        {
          ticks.map((tick, index) => {
            return (
              <div key={index}>
                <Box sx={{...styles.tick, top: '0px', left: tick.left}} />
                {
                  !tick.isFirst &&
                  !tick.isLast &&
                  <Typography variant={'caption'} sx={{...styles.label, top: '8px', left: tick.left - 37}}>{tick.label}</Typography>
                }
                {
                  tick.isFirst &&
                  !tick.isLast &&
                  <Typography variant={'caption'} sx={{...styles.label, top: '8px', left: tick.left}}>{tick.label}</Typography>
                }
                {
                  !tick.isFirst &&
                  tick.isLast &&
                  <Typography variant={'caption'} sx={{...styles.label, top: '8px', left: tick.left - 80}}>{tick.label}</Typography>
                }
              </div>
            )
          })
        }
      </Paper>
    )
  }

}

DataExplorerTicks.propTypes = {
  selectedDateTime: PropTypes.object,
  selectedDuration: PropTypes.object,
};

export default DataExplorerTicks;
