import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const styles = {
  chartAndChartLabelsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
  },

  chartContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexDirection: 'column-reverse',
    height: '100px', //This keeps in the bar from exceeding the height of the chart
  },
};

// A vertical bar with thresholds to distinguish normal, hi, and hi-hi
// values. If neither hiThreshold nor hihiThreshold is specified then
// it acts as a simple bar.
class VerticalHiHiBar extends Component {

  // Do some math to calculate the amount of bar to show as a percentage of the total bar size
  calculateSize(value, total) {
    if (isNaN(value) || isNaN(total)) {
      return 0;
    }

    if (value < 0 || total <= 0 || !total) {
      // If we have a valid total but value is negative, then just return 0
      // so it shows as an empty bar
      if (value < 0 && total > 0) {
        return 0;
      }
      // In case we get an invalid value or total,
      // let's just return 100 for the size so it displays properly
      return 100;
    }
    return (value/total) * 100 + 'px';
  }

  render() {
    let valueColor = this.props.valueColor;

    if (this.props.value > this.props.hihiThreshold) {
      valueColor = this.props.hihiColor;
    } else if (this.props.value > this.props.hiThreshold) {
      valueColor = this.props.hiColor;
    }

    let chartStyle = {
      height: this.calculateSize(this.props.value, this.props.total),
      width: '100%',
      background: valueColor,
    };

    return (
        <Box sx={{...styles.chartAndChartLabelsContainer, width: this.props.barWidth + 'px'}}>
          <Box sx={{...styles.chartContainer, width: this.props.barWidth + 'px'}}>
            {/* Chart */}
            <Box sx={chartStyle}/>
          </Box>
        </Box>
    );
  }
}

VerticalHiHiBar.propTypes = {
  value: PropTypes.number.isRequired,
  valueColor: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  hihiThreshold: PropTypes.number.isRequired,
  hiThreshold: PropTypes.number.isRequired,
  hiColor: PropTypes.string.isRequired,
  hihiColor: PropTypes.string.isRequired,
  barWidth: PropTypes.number.isRequired,
};

export default VerticalHiHiBar;