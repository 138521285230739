/**
 * This module attempts to bring the various palettes used in the application together and abstract away any
 * future need to switch between a dark/light mode. Import this module anywhere you need to define a palette
 * style.
 *
 * Currently there we only support a dark mode.
 *
 * The 'materialUI' property is used to seed the Material UI theme object. It is appended directly the
 *
 */
import _ from 'lodash';

import {
  blue, cyan, deepPurple, indigo, lightBlue, pink, purple, red,
  teal, green, deepOrange, lightGreen, lime, yellow, amber, orange,
} from "@mui/material/colors";

const mdtDark = {
  materialUI: {
    palette: {
      mode: 'dark',
      primary: { 
        main: '#ce4300' 
      },
      action: { 
        hover: '#ce4300'
      },
      background: {
        default: '#212121',
        paper: '#303030'
      },
      grey: { // MUI DEFAULT
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#f5f5f5',
        A200: '#eeeeee',
        A400: '#bdbdbd',
        A700: '#616161',
      }
    },
    typography: {
      subtitle1: {
        color: 'rgba(255, 255, 255, 0.7)'
      }
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          // This overrides the default linear-gradient that gets applied to the background image on Paper,
          // which causes a faded/washed out look with the colors that we do not want
          root: {
            backgroundImage: 'none'
          }
        }
      },
      // This is to make the Autocomplete styling match what we had before moving to MUI v5
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            '.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'rgba(255, 255, 255, 0.16)',
              '&:hover': {
                backgroundColor: '#ce4300'
              },
              '&.Mui-focused': {
                backgroundColor: '#ce4300'
              }
            },
          }
        }
      },
      // This is to make the ListItem styling match what we had before moving to MUI v5
      // Generally for: Paging list (5/10/25)
      MuiList: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            '& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected': {
              backgroundColor: '#424242',
              '&:hover': {
                backgroundColor: '#424242'
              },
            }
          }
        }
      },
    }
  },
  typography: {
    // provides multiple levels of text shades (use 'color' for a less bright white)
    color: '#c6c6c6', // This should be the same as rgba(255, 255, 255, 0.7) on a #424242 (paper) background
    white: '#FFFFFF',
    black: '#000000',

    // should only be used to indicate no data available (this is too dim for normal readable text)
    noData: 'rgba(255, 255, 255, 0.3)',
  },
  status: {
    // used for status (ie. info, good, warn, error)
    level1: '#757575', // 600 Gray (was #A5A5A5)
    level2: '#8BC34A', // 500 Light Green
    level3: '#42A5F5', // 400 Blue
    level4: '#FFF176', // 300 Yellow
    level5: '#e57373', // 300 Red
  },
  categories: {
    // Categories used for charting and other non-status coloring
    category0: 'rgba(255, 255, 255, 0.7)',
    category1: '#757575', // 600 Gray
    category2: '#FFB74D', // 300 Orange
    category3: '#4DD0E1', // 300 Cyan
    category4: '#AED581', // 300 Light Green
    category5: '#F06292', // 300 Pink
    category6: '#9575CD', // 300 Deep Purple
    category7: '#e57373', // 300 Red
  },
  charting: {
    // Organizing colors by shades
    red: red[300],
    pink: pink[300],
    purple: purple[300],
    deepPurple: deepPurple[300],
    indigo: indigo[300],
    blue: blue[300],
    lightBlue: lightBlue[300],
    cyan: cyan[300],
    teal: teal[300],
    green: green[300],
    lightGreen: lightGreen[300],
    lime: lime[300],
    yellow: yellow[300],
    amber: amber[300],
    orange: orange[300],
    deepOrange: deepOrange[300],

    red500: red[500],
    pink500: pink[500],
    purple500: purple[500],
    deepPurple500: deepPurple[500],
    indigo500: indigo[500],
    blue500: blue[500],
    lightBlue500: lightBlue[500],
    cyan500: cyan[500],
    teal500: teal[500],
    green500: green[500],
    lightGreen500: lightGreen[500],
    lime500: lime[500],
    yellow500: yellow[500],
    amber500: amber[500],
    orange500: orange[500],
    deepOrange500: deepOrange[500],

    red700: red[700],
    pink700: pink[700],
    purple700: purple[700],
    deepPurple700: deepPurple[700],
    indigo700: indigo[700],
    blue700: blue[700],
    lightBlue700: lightBlue[700],
    cyan700: cyan[700],
    teal700: teal[700],
    green700: green[700],
    lightGreen700: lightGreen[700],
    lime700: lime[700],
    yellow700: yellow[700],
    amber700: amber[700],
    orange700: orange[700],
    deepOrange700: deepOrange[700],

    red900: red[900],
    pink900: pink[900],
    purple900: purple[900],
    deepPurple900: deepPurple[900],
    indigo900: indigo[900],
    blue900: blue[900],
    lightBlue900: lightBlue[900],
    cyan900: cyan[900],
    teal900: teal[900],
    green900: green[900],
    lightGreen900: lightGreen[900],
    lime900: lime[900],
    yellow900: yellow[900],
    amber900: amber[900],
    orange900: orange[900],
    deepOrange900: deepOrange[900],

    redA700: red.A700,
    pinkA700: pink.A700,
    purpleA700: purple.A700,
    deepPurpleA700: deepPurple.A700,
    indigoA700: indigo.A700,
    blueA700: blue.A700,
    lightBlueA700: lightBlue.A700,
    cyanA700: cyan.A700,
    tealA700: teal.A700,
    greenA700: green.A700,
    lightGreenA700: lightGreen.A700,
    limeA700: lime.A700,
    yellowA700: yellow.A700,
    amberA700: amber.A700,
    orangeA700: orange.A700,
    deepOrangeA700: deepOrange.A700,
  },
  /**
   * The chartingOpposites are used to provide a contrasting color for the charting colors. 
   * Generated going to https://paletton.com/, selecting the 'tetrad' option and inputting each
   * charting color above as the base color. The 'opposite' color is the color directly across
   * from the base color on the color wheel.
   */
  chartingOpposites: {
    redOpposite: '#5CB75C',
    pinkOpposite: '#A9F263',
    purpleOpposite: '#ECFB80',
    deepPurpleOpposite: '#FFF186',
    indigoOpposite: '#FFDD89',
    blueOpposite: '#FFBB5D',
    lightBlueOpposite: '#FFA949',
    cyanOpposite: '#FFA654',
    tealOpposite: '#FFAA6C',
    greenOpposite: '#FDA6A4',
    lightGreenOpposite: '#CB7B9A',
    limeOpposite: '#8C519B',
    yellowOpposite: '#7B59AD',
    amberOpposite: '#4D49B2',
    orangeOpposite: '#3B71A6',
    deepOrangeOpposite: '#47B37C',
  }
};

const mdtLight = {

  // Define a light palette when we support a light mode.

};

let useDarkPalette = true;

const mdtPalette = () => {
  return useDarkPalette === true ? mdtDark : mdtLight;
};

const mdtPaletteChartingColors = () => {
  return [

    mdtPalette().charting.red,
    mdtPalette().charting.pink,
    mdtPalette().charting.purple,
    mdtPalette().charting.deepPurple,

    mdtPalette().charting.cyan,
    mdtPalette().charting.lightBlue,
    mdtPalette().charting.blue,
    mdtPalette().charting.indigo,

    mdtPalette().charting.teal,
    mdtPalette().charting.green,
    mdtPalette().charting.lightGreen,
    mdtPalette().charting.lime,

    mdtPalette().charting.deepOrange,
    mdtPalette().charting.orange,
    mdtPalette().charting.amber,
    mdtPalette().charting.yellow,
  ]
};

const mdtPaletteChartingColorsAsComboBoxOptions = () => {

  const options = [];

  const colorKeys = _.keys(mdtPalette().charting);

  _.forEach(colorKeys, (colorKey, index) => {
    options.push({
      value: mdtPalette().charting[colorKey],
      label: colorKey
    })
  });


  return options;
}

export { mdtPalette, mdtPaletteChartingColors, mdtPaletteChartingColorsAsComboBoxOptions };

