import providerDialogActionTypes from './providerDialogActionTypes';
import defaultActionFactory from '../../../../common/factories/defaultActionFactory';
import {handleError} from "../../../../app/actions/appErrorActions";
import errorMessages from "../../../../common/errorMessages";
import * as providerActions from "../../actions/providerActions";

const setActiveStep = defaultActionFactory(providerDialogActionTypes.SET_ACTIVE_STEP, 'stateDef', 'step');

const setProviderName = defaultActionFactory(providerDialogActionTypes.SET_PROVIDER_NAME, 'stateDef', 'value');
const selectProviderType = defaultActionFactory(providerDialogActionTypes.SELECT_PROVIDER_TYPE, 'stateDef', 'value');

const addTeamsChannel = defaultActionFactory(providerDialogActionTypes.ADD_TEAMS_CHANNEL, 'stateDef');
const removeTeamsChannel = defaultActionFactory(providerDialogActionTypes.REMOVE_TEAMS_CHANNEL, 'stateDef', 'id');
const setTeamsChannelName = defaultActionFactory(providerDialogActionTypes.SET_TEAMS_CHANNEL_NAME, 'stateDef', 'value', 'id');
const setTeamsChannelWebhook = defaultActionFactory(providerDialogActionTypes.SET_TEAMS_CHANNEL_WEBHOOK, 'stateDef', 'value', 'id');


const setTwilioAcctSid = defaultActionFactory(providerDialogActionTypes.SET_TWILIO_ACCT_SID, 'stateDef', 'value');
const setTwilioAuthToken = defaultActionFactory(providerDialogActionTypes.SET_TWILIO_AUTH_TOKEN, 'stateDef', 'value');
const setTwilioFromNumber = defaultActionFactory(providerDialogActionTypes.SET_TWILIO_FROM_NUMBER, 'stateDef', 'value');


const loadContextDatStarting = defaultActionFactory(providerDialogActionTypes.LOAD_CONTEXT_DATA_STARTING, 'stateDef');
const loadContextDatSuccess = defaultActionFactory( providerDialogActionTypes.LOAD_CONTEXT_DATA_SUCCESS, 'stateDef', 'context');
const loadContextDatError = defaultActionFactory(providerDialogActionTypes.LOAD_CONTEXT_DATA_ERROR, 'stateDef');

const clearForm = defaultActionFactory(providerDialogActionTypes.CLEAR_FORM, 'stateDef');

const loadContextData = (stateDef, context) => {
  return async (dispatch, getState) => {
    try {

      await dispatch(loadContextDatStarting(stateDef));

      await dispatch(loadContextDatSuccess(stateDef, context));

    } catch(e) {
      await dispatch(loadContextDatError(stateDef));

      await dispatch(providerActions.showProviderDialog(false, context));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_PROVIDER_DIALOG_DATA, e.message));
    }
  }
};

export {
  loadContextData,
  setActiveStep,
  setProviderName,
  selectProviderType,
  addTeamsChannel,
  removeTeamsChannel,
  setTeamsChannelName,
  setTeamsChannelWebhook,
  setTwilioAcctSid,
  setTwilioAuthToken,
  setTwilioFromNumber,
  clearForm,
}