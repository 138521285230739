import {
  validateWorkingUser,
  defaultWorkingUser,
  defaultWorkingUserValidation,
  createWorkingUser
} from './helpers/userHelpers';
import userMgmtActionTypes from '../userMgmtActionTypes';
import _ from "lodash";

const userMgmtDisplayReducer = (state, action) => {

  switch (action.type) {
    case userMgmtActionTypes.USER_MGMT_QUERY_USERS_STARTING:
      return { ...state, queryRunning: true, users: [], currentPage: 0 };
    case userMgmtActionTypes.USER_MGMT_QUERY_USERS_SUCCESS:
      return { ...state, queryRunning: false, users: action.queryResults.users, currentPage: 0 };
    case userMgmtActionTypes.USER_MGMT_QUERY_USERS_ERROR:
      return { ...state, queryRunning: false, users: [], currentPage: 0 };
    case userMgmtActionTypes.USER_MGMT_SET_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };
    case userMgmtActionTypes.USER_MGMT_SET_USERS_PER_PAGE:
      return { ...state, usersPerPage: action.usersPerPage, currentPage: 0 };
    case userMgmtActionTypes.USER_MGMT_OPEN_ADD_DIALOG:
      return onOpenAddUserDialog(state, action);
    case userMgmtActionTypes.USER_MGMT_SET_ACTIVE_STARTING:
      return { ...state, queryRunning: true };
    case userMgmtActionTypes.USER_MGMT_SET_ACTIVE_SUCCESS:
      return onSetActiveStateSuccess(state, action);
    case userMgmtActionTypes.USER_MGMT_SET_ACTIVE_ERROR:
      return { ...state, queryRunning: false };
    case userMgmtActionTypes.USER_MGMT_OPEN_DELETE_CONFIRMATION:
      return { ...state, openDeleteUserUI: true, workingUser: createWorkingUser(action.user), workingUserValidation: {...defaultWorkingUserValidation} };
    case userMgmtActionTypes.USER_MGMT_OPEN_EDIT_DIALOG:
      return onOpenEditDialog(state, action);
    default: return state;
  }

};

const onOpenEditDialog = (state, action) => {
  let newState = { ...state, openEditUserUI: true, workingUser: createWorkingUser(action.user), workingUserValidation: {...defaultWorkingUserValidation} };
  validateWorkingUser(newState);
  return newState;
};

const onOpenAddUserDialog = (state, action) => {
  let newState = { ...state, openAddUserUI: true, workingUser: {...defaultWorkingUser}, canSaveWorkingUser:false, workingUserValidation: {...defaultWorkingUserValidation} };
  validateWorkingUser(newState);
  return newState;
};

const onSetActiveStateSuccess = (state, action) => {
  // With updates we do not refresh the user list, instead we find and
  // replace the existing user with the returned user data.
  let updatedUsers = [...state.users];
  if (!_.isNil(action.results) && !_.isNil(action.results.id)) {
    let index = _.findIndex(updatedUsers, (user) => {return user.id === action.results.id });
    if (index >= 0) {
      updatedUsers[index] = {...action.results};
    }
  }
  return { ...state, users: updatedUsers, queryRunning: false };
}



export default userMgmtDisplayReducer