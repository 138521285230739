const cloudClientStatuses = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
  INACTIVE: 'Inactive',
};

const cloudClientStatusNames = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
  INACTIVE: 'Inactive',
};

const sensorUploadStatusNames = {
  UPLOADING : 'Uploading',
  NOT_UPLOADING: 'Not Uploading',
};

const sensorDataStatuses = {
  CURRENT : 'Current',
  BEHIND: 'Behind',
  UNKNOWN: 'Unknown',
};

const sensorDataStatusNames = {
  CURRENT : 'Current',
  BEHIND: 'Behind',
  UNKNOWN: 'Unknown',
};

const dataSourceStatuses = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
};

const dataSourceStatusNames = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
};

const dataSourceAggregateStatuses = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
};

const dataSourceAggregateStatusNames = {
  ONLINE : 'Online',
  OFFLINE: 'Offline',
};

const getCloudClientStatusName = (status) => {
  switch (status) {
    case cloudClientStatuses.ONLINE:
      return cloudClientStatusNames.ONLINE;
    case cloudClientStatuses.OFFLINE:
      return cloudClientStatusNames.OFFLINE;
    case cloudClientStatuses.INACTIVE:
      return cloudClientStatusNames.INACTIVE;
    default:
      return status;
  }
};

const getSensorUploadStatusName = (isUploading) => {
  return isUploading ? sensorUploadStatusNames.UPLOADING : sensorUploadStatusNames.NOT_UPLOADING;
};

const getSensorDataStatusName = (status) => {
  switch (status) {
    case sensorDataStatuses.CURRENT:
      return sensorDataStatusNames.CURRENT;
    case sensorDataStatuses.BEHIND:
      return sensorDataStatusNames.BEHIND;
    case sensorDataStatuses.UNKNOWN:
      return sensorDataStatusNames.UNKNOWN;
    default:
      return status;
  }
};

const getDataSourceStatusName = (status) => {
  switch (status) {
    case dataSourceStatuses.ONLINE:
      return dataSourceStatusNames.ONLINE;
    case dataSourceStatuses.OFFLINE:
      return dataSourceStatusNames.OFFLINE;
    default:
      return status;
  }
};

const getDataSourceAggregateStatusName = (status) => {
  switch (status) {
    case dataSourceAggregateStatuses.ONLINE:
      return dataSourceAggregateStatusNames.ONLINE;
    case dataSourceAggregateStatuses.OFFLINE:
      return dataSourceAggregateStatusNames.OFFLINE;
    default:
      return status;
  }
};

export {
  cloudClientStatuses,
  cloudClientStatusNames,
  sensorUploadStatusNames,
  sensorDataStatuses,
  sensorDataStatusNames,
  dataSourceStatuses,
  dataSourceStatusNames,
  dataSourceAggregateStatuses,
  dataSourceAggregateStatusNames,
  getCloudClientStatusName,
  getSensorUploadStatusName,
  getSensorDataStatusName,
  getDataSourceStatusName,
  getDataSourceAggregateStatusName
}