import _ from 'lodash';

const pumpOnlyFleetMapState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,

    datavan: _.isNil(state) ? null : state.datavan,
    wellHead: _.isNil(state) ? null : state.wellHead,
    pumps: _.isNil(state) ? null : state.pumps,
    sand1: _.isNil(state) ? null : state.sand1,
    sand2: _.isNil(state) ? null : state.sand2,

  };

};

export {
  pumpOnlyFleetMapState
};