import {performCardFetch, performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as componentLifeSimulation from './componentLifeSimulation';

const fetchComponentLifeCardData = (truckId) => {

  if (useSimulationData('componentLifeCard')) {
    return componentLifeSimulation.fetchComponentLifeCardData(truckId);
  }

  return performCardFetch({
    'query': '{ componentLife(truckId:{truckId}) { components { type displayName model expectedLife primaryLife { name value } } } }'.replace('{truckId}', truckId)
  })
};

const fetchComponentTypesByUnitType = (unitType) => {
  if (useSimulationData('componentLifeCard')) {
    return componentLifeSimulation.fetchComponentTypesByUnitType(unitType);
  }

  return performDisplayFetch({
    'query': 'query componentTypesByUnitType($input:String!) {  componentTypesByUnitType(unitType:$input) { id name displayName unitType kind expectedLife } }',
    'variables': {
      'input': unitType,
    }
  })
};

export {
  fetchComponentLifeCardData,
  fetchComponentTypesByUnitType
}