import _ from 'lodash';

// Simplify alarm counts object as null or valid alarm counts;
// we wish to treat all zero counts the same as no data.
const normalizeAlarmCounts = (alarmCounts) => {
  return _.isNil(alarmCounts) || (
    (_.isNil(alarmCounts.critical) || alarmCounts.critical === 0) &&
    (_.isNil(alarmCounts.warning)  || alarmCounts.warning === 0) &&
    (_.isNil(alarmCounts.info)     || alarmCounts.info === 0)
  ) ? null : alarmCounts;
};

export {
  normalizeAlarmCounts
}