import _ from 'lodash';
import fleetPRCHistoryActionTypes from './fleetPRCHistoryActionTypes';
import * as fleetPRCHistoryService from './services/fleetPRCHistoryService';
import { fleetPRCHistoryState } from "./fleetPRCHistorySelectors";

const initialState = fleetPRCHistoryState();

const fleetPRCHistoryReducer = (state=initialState, action) => {

  switch (action.type) {

    case fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_QUERY_DATA_ERROR:
      return onQueryDataError(state, action);

    case fleetPRCHistoryActionTypes.FLEET_PRC_HISTORY_CLEAR_DATA:
      return onClearData(state, action);

    default: return state;

  }

};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true
  };
};

const onQueryDataSuccess = (state, action) => {

  let transformedSeries = fleetPRCHistoryService.transformSeries(action.queryResults.fleetPRCHistoryChart.series);

  return {
    ...state,
    queryRunning: false,
    ...transformedSeries
  };
};

const onQueryDataError = (state, action) => {
  return {
    ...state,
    queryRunning: false
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    flowRate: initialState.flowRate,
    pressure: initialState.pressure,
    concentration: initialState.concentration,
    latestFlowRate: initialState.latestFlowRate,
    latestPressure: initialState.latestPressure,
    latestConcentration: initialState.latestConcentration
  };
};

export default fleetPRCHistoryReducer;