import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import cardReducer from './reducers/cardReducer';

import { fleetComponentsState } from './fleetComponentsSelectors';

const initialState = fleetComponentsState();

const fleetComponentReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    cardReducer
  );

  return reducer(state, action);
};

export default fleetComponentReducer
