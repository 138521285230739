import fleetComponentsActionTypes from '../fleetComponentsActionTypes';

import { fleetComponentsState } from '../fleetComponentsSelectors';

const initialState = fleetComponentsState();

const cardReducer = (state, action) => {

  if (action.type === fleetComponentsActionTypes.FLEET_COMPONENTS_CLEAR_DATA)  {
    return onClearData(state, action);
  }
  return state;
};

const onClearData = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export default cardReducer