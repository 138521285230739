import React, { Component } from 'react';
import _ from "lodash";
import {connect} from "react-redux";

import { MenuList, MenuItem, Popover } from '@mui/material';

import { sensorSelectorState } from '../../../../../state/common/sensorSelector/sensorSelectorSelectors';
import * as sensorSelectorActions from '../../../../../state/common/sensorSelector/sensorSelectorActions';

class SelectedSensorTargetUomMenu extends Component {
  
  render() {

    return (
        <Popover
          open={this.props.showTargetUomMenu}
          anchorEl={this.props.uomMenuTargetElement}
          onClose={() => this.props.closeTargetUomMenu()}
          anchorOrigin={{vertical: 'top', horizontal: 'left',}}
          transformOrigin={{vertical: 'top', horizontal: 'left', }}
        >
          <MenuList>
          {
            this.props.targetUoms.map((uom, index) => {
              return (
                <MenuItem 
                  key={index}
                  sx={{fontSize: '14px'}}
                  onClick={() => {this.props.selectTargetUom(this.props.selectedSensor, uom); this.props.closeTargetUomMenu()}}
                  >
                  {uom}
                </MenuItem>
              )
            })
          }
          </MenuList>
      </Popover>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = sensorSelectorState(state[stateDef.key]);
  return {
    showTargetUomMenu: componentState.showTargetUomMenu,
    uomMenuTargetElement: componentState.selectedSensorMenuTargetElement,
    targetUoms: componentState.selectedSensorTargetUoms,
    selectedSensor: componentState.selectedSensorForNewTargetUom,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeTargetUomMenu: () => {dispatch(sensorSelectorActions.closeTargetUomMenu(props.stateDef))},
    selectTargetUom: (sensor, uom) => {dispatch(sensorSelectorActions.updatedSelectedSensorUom(props.stateDef, sensor, uom))}
  }
};

export default (connect(mapStateToProps,mapDispatchToProps)(SelectedSensorTargetUomMenu));
