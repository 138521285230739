import _ from 'lodash';

const processDetails = (queryResults) => {

  let state = _.cloneDeep(queryResults.dgbFleetMap);

  let noValue = '-';

  _.forEach(state.trucks, truck => {
    truck.gasSupplyPressureDisplay = !_.isNil(truck.gasSupplyPressure) ? Math.round(truck.gasSupplyPressure) : noValue;
    truck.gasPostFilterPressureDisplay = !_.isNil(truck.gasPostFilterPressure) ? Math.round(truck.gasPostFilterPressure) : noValue;
    truck.gasSubstitutionDisplay = !_.isNil(truck.gasSubstitution) ? Math.round(truck.gasSubstitution) : noValue;
    truck.gasSubstitutionSetPointDisplay = !_.isNil(truck.gasSubstitutionSetPoint) ? Math.round(truck.gasSubstitutionSetPoint) : noValue;
    truck.engineLoadDisplay = !_.isNil(truck.engineLoad) ? Math.round(truck.engineLoad) : noValue;
    truck.engineTorqueDisplay = !_.isNil(truck.engineTorque) ? Math.round(truck.engineTorque) : noValue;
    truck.engineRpmDisplay = !_.isNil(truck.engineRpm) ? Math.round(truck.engineRpm) : noValue;
  });

  return {
    ...state,
  };
};

export {
  processDetails
}