import { tableState } from './tableSelectors';

const initialState = tableState();

const onSetCurrentPage = (state, action) => {
  return {
    ...state,
    currentPage: action.currentPage,
  };
};

const onSetItemsPerPage = (state, action) => {
  return {
    ...state,
    unitsPerPage: action.unitsPerPage,
    currentPage: initialState.currentPage,
  };
};

const onSortItems = (state, action) => {

  let sortDirection = initialState.sortDirection;
  if (state.sortContext === action.sortContext) {
    sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  return {
    ...state,
    sortContext: action.sortContext,
    sortDirection: sortDirection,
    currentPage: initialState.currentPage
  };
};

export {
  onSetCurrentPage,
  onSetItemsPerPage,
  onSortItems,
}