import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';
import { Typography, Box, ButtonBase, Tooltip  } from '@mui/material';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

import ComponentTypes from '../../componentTypes';

import * as fleetClientStatusActions from '../../../state/cards/fleetClientStatus/fleetClientStatusActions';
import * as clientStatusActions from '../../../state/displays/clientStatus/clientStatusActions';
import { fleetClientStatusState } from "../../../state/cards/fleetClientStatus/fleetClientStatusSelectors";
import * as statusIconHelpers from '../../common/statusIconHelper';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';

import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const statusIconStyles = statusIconHelpers.getMediumStatusIconStyles();


const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...statusIconStyles,
  cardContainerReplace: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardHeaderContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  cardValue: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  detailsContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  },
  tooltipTitle: {
    paddingBottom: 1
  },
  statusContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center'
  }
};

class FleetClientStatus extends MDTCard {

  getName() {
      return 'Client Status';
  }

  getTitle() {
      return 'COMMUNICATION';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.id);
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.id);
    }
  }

  renderTooltip(description) {

    if (_.isNil(description)) {
      return <div></div>;
    }

    return (
      <div>
        <Typography variant={'subtitle1'} sx={styles.tooltipTitle}>{description.title}</Typography>
        <Typography variant={'caption'}>{description.caption}</Typography>
      </div>
    )
  }

  getRenderedButtonsInHeader() {
    return (
        !_.isNil(this.props.context) && !this.isInEditMode() &&
        <ButtonBase sx={styles.cardActionButton} focusRipple onClick={() => this.props.navigateToClientStatus(this.props.context.id)}><SettingsRemoteIcon/></ButtonBase>
    )
  }

  getRenderedContent() {
    return (
     <Box sx={styles.cardContent}>
      <Box sx={styles.detailsContent}>
        {
          !_.isNil(this.props.status) &&
          <Box sx={styles.statusContent}>

            <Tooltip title={this.renderTooltip(this.props.description)}>
              {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
              {/* returning a child element will not work so wrap the function in a div */}
              <div>
                {statusIconHelpers.getClientStatusIcon(this.props.status, styles)}
              </div>
            </Tooltip>
            <Typography variant={'caption'} sx={styles.cardValue}>Cloud Client</Typography>
          </Box>
        }
        {
          !_.isNil(this.props.isUploading) &&
          <Box sx={styles.statusContent}>
            <Tooltip title={this.renderTooltip(this.props.sensorUploadDescription)}>
              {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
              {/* returning a child element will not work so wrap the function in a div */}
              <div>
                {statusIconHelpers.getUploadStatusIcon(this.props.isUploading, styles)}
              </div>
            </Tooltip>
            <Typography variant={'caption'} sx={styles.cardValue}>Sensor Upload</Typography>
          </Box>
        }
      </Box>
      <Box sx={styles.detailsContent}>
        {
          !_.isNil(this.props.dataUploadStatus) &&
          <Box sx={styles.statusContent}>
            <Tooltip title={this.renderTooltip(this.props.sensorDataDescription)}>
              {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
              {/* returning a child element will not work so wrap the function in a div */}
              <div>
                {statusIconHelpers.getDataStatusIcon(this.props.dataUploadStatus, styles)}
              </div>
            </Tooltip>
            <Typography variant={'caption'} sx={styles.cardValue}>Sensor Data</Typography>
          </Box>
        }
        {
          !_.isNil(this.props.datasourceStatus) &&
          <Box sx={styles.statusContent}>
            <Tooltip title={this.renderTooltip(this.props.dataSourceAggregateStatusDescription)}>
              {/* The tooltip needs to apply DOM event listeners to its child element. An inline function */}
              {/* returning a child element will not work so wrap the function in a div */}
              <div>
                {statusIconHelpers.getProductStatusIcon(this.props.datasourceStatus, styles)}
              </div>
            </Tooltip>
            <Typography variant={'caption'} sx={styles.cardValue}>Data Sources</Typography>
          </Box>
        }
      </Box>
     </Box>
    )
  }
}

FleetClientStatus.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_CLIENT_STATUS : props.stateKey,
      type: ComponentTypes.FLEET_CLIENT_STATUS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetClientStatusState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    status: componentState.status,
    isUploading: componentState.isUploading,
    dataUploadStatus: componentState.dataUploadStatus,
    datasourceStatus: componentState.datasourceStatus,
    description: componentState.description,
    sensorUploadDescription: componentState.sensorUploadDescription,
    sensorDataDescription: componentState.sensorDataDescription,
    dataSourceAggregateStatusDescription: componentState.dataSourceAggregateStatusDescription,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(fleetClientStatusActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(fleetClientStatusActions.clearData(props.stateDef)) },
    navigateToClientStatus: (fleetId) => dispatch(clientStatusActions.navigate(fleetId)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetClientStatus))

