import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import fetchComponentLifeRoadsideCardData from './componentLifeRoadsideQueries';

import componentLifeRoadsideActionTypes from './componentLifeRoadsideActionTypes';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_CLEAR_DATA, 'statedef');

const queryData = queryActionFactory(
    componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_STARTING,
    componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_SUCCESS,
    componentLifeRoadsideActionTypes.COMPONENT_LIFE_ROADSIDE_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_COMPONENT_LIFE_ROADSIDE_CARD_DATA,
    fetchComponentLifeRoadsideCardData
);

export {
  clearData,
  queryData
};
