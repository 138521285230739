import reduceReducers from "reduce-reducers";
import { routeAssetMgmtState } from "./routeAssetMgmtSelectors";
import routeAssetMgmtConsumerDialogReducer from "./reducers/routeAssetMgmtConsumerDialogReducer";
import routeAssetMgmtRouteAssetDialogReducer from "./reducers/routeAssetMgmtRouteAssetDialogReducer";
import routeAssetMgmtDeleteConfirmationReducer from "./reducers/routeAssetMgmtDeleteDialogReducer";
import routeAssetMgmtDisplayReducer from './reducers/routeAssetMgmtDisplayReducer';

const initialState = routeAssetMgmtState();

const routeAssetMgmtStateReducer = (state=initialState, action) => {
    const reducer =  reduceReducers(
        routeAssetMgmtConsumerDialogReducer,
        routeAssetMgmtRouteAssetDialogReducer,
        routeAssetMgmtDeleteConfirmationReducer,
        routeAssetMgmtDisplayReducer
    );
    return reducer(state, action);
}; 

export default routeAssetMgmtStateReducer;