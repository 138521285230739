import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as componentLifeCurbsideSimulation from './componentLifeCurbsideSimulation';

const fetchComponentLifeCurbsideCardData = (truckId) => {

  if (useSimulationData('componentLifeCurbsideCard')) {
    return componentLifeCurbsideSimulation.fetchComponentLifeCurbsideCardData(truckId);
  }

  return performCardFetch({
    'query': '{ componentLifeCurbside(truckId:{truckId}) { components { type displayName model expectedLife primaryLife { name value } } } }'.replace('{truckId}', truckId)
  })
};

export default fetchComponentLifeCurbsideCardData
