import _ from "lodash";
import * as filterService from '../../../common/filtering/filterService';
import moment from "moment";
import * as assetTypeService from '../../../common/services/assetTypeService';
import * as descriptionService from './descriptionService';
import {updateFilteredItems} from "../../../common/filtering/filterService";

const processClients = (clients, filters) => {
  // Filter the clients list by the current filters (in order)
  let filteredClients = filterService.filterItems(clients, filters);
  // Update any displayed values
  prettifyClients(filteredClients);
  // Generate filter data from the filtered clients list
  const updatedFilters = updateFilteredItems(filters, clients, filteredClients, filterDefinitions());

  return {
    filteredClients: filteredClients,
    filters: updatedFilters
  };
};

const processDetails = (details) => {

  if (_.isNil(details)) {
    return details;
  }

  // Update display values for the UI
  prettifyDetails(details);
  // Initialize UI state properties
  initializeDataSourceStates(details);
  return details;
};

const prettifyClients = (clients) => {
  if (!_.isNil(clients) && !_.isEmpty(clients)) {
    clients.forEach(client => {

      // Generate the text descriptions rendered in the UI tooltips, detail window, etc.
      client.description = descriptionService.generateCloudClientDescription(client);
      client.sensorUploadDescription = descriptionService.generateSensorUploadDescription(client);
      client.sensorDataDescription = descriptionService.generateSensorDataDescription(client);
      client.dataSourceAggregateStatusDescription = descriptionService.generateDataSourceAggregateStatusDescription(client);

    })
  }
};

const prettifyDetails = (details) => {

  if (_.isNil(details)) {
    return details;
  }

  let noValue = '-';

  // Generate the text descriptions rendered in the UI tooltips, detail window, etc.
  details.description = descriptionService.generateCloudClientDescription(details);
  details.sensorUploadDescription = descriptionService.generateSensorUploadDescription(details);
  details.sensorDataDescription = descriptionService.generateSensorDataDescription(details);

  // Update the displayed times
  details.displayValueLastContactSensor = !_.isNil(details.lastContactSensor) ? moment.utc(details.lastContactSensor*1000).fromNow() : noValue;
  details.displayValueLastContactEdge = !_.isNil(details.lastContactEdge) ? moment.utc(details.lastContactEdge*1000).fromNow() : noValue;
  details.datasources.forEach((dataSource) => {
    dataSource.description = descriptionService.generateDataSourceDescription(dataSource);
    dataSource.displayValueSamplesRemaining = !_.isNil(dataSource.samplesRemaining) ? dataSource.samplesRemaining : noValue;
    dataSource.displayValueLastContact = !_.isNil(dataSource.lastContact) ? moment.utc(dataSource.lastContact*1000).fromNow() : noValue;
    dataSource.displayValueLastSensorUpload = !_.isNil(dataSource.lastSensorUpload) ? moment.utc(dataSource.lastSensorUpload*1000).fromNow() : noValue;
    dataSource.displayValueLastSensorDataAge = !_.isNil(dataSource.lastSensorDataAge) ? moment.utc(dataSource.lastSensorDataAge*1000).fromNow() : noValue;
    dataSource.truckDetails.forEach((truck) => {
      truck.displayValueType = !_.isNil(truck.type) ? assetTypeService.getAssetNameByType(truck.type) : noValue;
      truck.displayValueLastSensorUpload = !_.isNil(truck.lastSensorUpload) ? moment.utc(truck.lastSensorUpload*1000).fromNow() : noValue;
      truck.displayValueLastSensorDataAge = !_.isNil(truck.lastSensorDataAge) ? moment.utc(truck.lastSensorDataAge*1000).fromNow() : noValue;
    });
  });

};

const initializeDataSourceStates = (details) => {

  details.datasources.forEach((dataSource) => {
    dataSource.showDetails = false;
    dataSource.trucksSortContext = 'name';
    dataSource.trucksSortDirection = 'asc';
    dataSource.trucksPerPage = 5;
    dataSource.trucksCurrentPage = 0;
  });
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Division', 'division'));
  definitions.push(filterService.createFilterDefinition('Fleets', 'fleetName')); // label 'fleets' in 'multiSelectionFilters' list at filterService.js
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createExpressionFilterDefinition('Problems', [
    filterService.createExpressionDefinition('Uploading but sensor data Unknown', (item) => { return item.isUploading === true && item.dataUploadStatus === 'Unknown'; }),
    filterService.createExpressionDefinition('Online but not uploading',          (item) => { return item.status === 'Online' && item.isUploading === false ; }),
    filterService.createExpressionDefinition('Online but datasource offline',     (item) => { return item.status === 'Online' && item.datasourceStatus === 'Offline' ; }),
    filterService.createExpressionDefinition('Uploading but data behind',         (item) => { return item.isUploading === true  && item.dataUploadStatus === 'Behind';}),
    filterService.createExpressionDefinition('Offline and data behind',           (item) => { return (item.status === 'Offline' && item.dataUploadStatus === 'Behind');}),
    filterService.createExpressionDefinition('Offline',                           (item) => { return item.status === 'Offline' })
  ]));

  return definitions;
};

const getDataSourceFromClientDetails = (clientDetails, dataSourceId) => {

  if (_.isNil(clientDetails) || _.isNil(dataSourceId)) {
    return null;
  }

  return _.find(clientDetails.datasources, {id: dataSourceId});
};

export {
  processClients,
  processDetails,
  prettifyClients,
  prettifyDetails,
  getDataSourceFromClientDetails,
  filterDefinitions
}