import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';

import { Typography, Box, Paper, CircularProgress, ButtonBase } from '@mui/material';
import ActionIcon from '@mui/icons-material/MoreHoriz';
import FracOverviewCardMenu from './fracOverviewCardMenu';

import StackedThreeBar from '../../controls/charts/stackedThreeBar/stackedThreeBar';
import * as fracOverviewCardActions from '../../../state/cards/fracOverviewCard/fracOverviewCardActions';
import {fracOverviewCardState} from '../../../state/cards/fracOverviewCard/fracOverviewCardSelectors';
import CriticalAlarmBadge from '../../controls/badges/criticalAlarmBadge';
import InfoAlarmBadge from '../../controls/badges/infoAlarmBadge';
import WarningAlarmBadge from '../../controls/badges/warningAlarmBadge';

import ComponentTypes from "../../componentTypes";
import {fleetTypes} from "../../../state/common/services/fleetTypeService";
import getTypographyStyles from "../../common/styles/typographyStyles";
import {FracpumpOperationMetricType,OperationKeyValueMetricDisplayComponent} from "./operationKeyValueMetricDisplayComponent"
const typographyStyles = getTypographyStyles();

const styles = {
  ...typographyStyles,
  card: {
    width: '277px',
    height: '401px',
    marginRight: '10px',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  headerTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerProgress: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBorder: {
    height: '3px',
    width: '90%',
    marginLeft: '13px',
    marginBottom: '5px',
    borderBottom: '1px solid #999999'
  },
  headerContent: {
    display: 'flex',
    flexFlow: 'column',
  },

  // Styles for card content
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: '20px',
    height: '373px' // Helps with alignment across cards
  },

  cardContentCharts: {
    display: 'flex',
    flexFlow: 'column',
  },

  // General styles for all categories
  category: {
    display: 'flex',
    flex: '0 0 40px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  categoryLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  categorySubLabel: {
    whiteSpace: 'nowrap',
    marginBottom: '2px'
  },
  categoryRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: '10px',
    minHeight: '65px',
    alignItems: 'center'
  },
  categoryBorder: {
    height: '10px',
    width: '80%',
    marginLeft: '30px',
    marginTop: '5px',
    borderBottom: '1px solid #999999'
  },
  categorySubsectionBorder: {
    height: '10px',
    width: '100%',
    marginTop: '5px',
    borderBottom: '1px solid #999999'
  },

  // Styles for Component and Health
  chartsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  frontSideComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    height: '90px', // Helps with alignment across cards
  },
  stackedThreeBarChartContainer: {
    marginBottom: '0px'
  },
  emptyComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '127px', // Helps with alignment across cards
    width: '145px', // Helps with alignment across cards
  },
  frontSideNoDataLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '72px' // Helps with alignment across cards
  },
  backSideComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    height: '36px' // Helps with alignment across cards
  },
  vibrationComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    height: '90px', // Helps with alignment across cards
    width: '145px', // Helps with alignment across cards
  },
  vibrationNoDataLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '72px' // Helps with alignment across cards
  },

  // Styles for Operation
  fracMetricsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '145px',
    paddingTop: '10px',
  },
  statusContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '145px',
    height: '50px',
    alignContent: 'center'
  },
  statusLabel: {
    whiteSpace: 'nowrap',
  },
  statusSubLabel: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  labelContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center'
  },

  // Styles for Alarms
  alarmsContainer: {
    display: 'flex',
    width: '59px', // Ensures the container is the same size regardless if there are badges or not
    flexFlow: 'column nowrap',
    alignContent: 'space-between',
    alignItems: 'center',
    justifyContent: 'stretch',
    paddingTop: '10px',
    paddingLeft: '10px',
    borderLeft: '1px solid #999999'
  },
  alarmsContainerLabel: {
    whiteSpace: 'nowrap',
  },
  alarmBadgeContainer: {
    paddingTop: '10px',
  },
  alarmsNoDataLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '337px' // Helps with alignment across cards
  },

  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
};

function componentHasNoData(component) {
  return (!_.isNil(component) && component.low === 0 && component.med === 0 && component.high === 0);
}

function alarmHasNoData(alarm) {
  return (!_.isNil(alarm) && alarm.criticalAlarmCount === 0 && alarm.warningAlarmCount === 0 && alarm.infoAlarmCount === 0);
}

class FracOverviewCard extends Component {

  componentDidMount() {
    if (!_.isNil(this.props.selectedContext)) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    // Refresh the card data when the context changes.
    if (prevProps.selectedContext !== this.props.selectedContext) {
      this.refresh();
    }
  }

  refresh() {
    if (!_.isNil(this.props.selectedContext)) {
      this.props.queryData(this.props.selectedContext.id);
    }
  }

  render() {

    let fleet = _.isNil(this.props.selectedContext) ? {} : this.props.selectedContext;
    let status = _.isNil(this.props.fleetFracOverview) || _.isNil(this.props.fleetFracOverview.state)
      ? '' : this.props.fleetFracOverview.state;
    let fleetType = _.isNil(this.props.fleetFracOverview) || _.isNil(this.props.fleetFracOverview.fleetType)
      ? '-' : this.props.fleetFracOverview.fleetType;
    let flowRate = _.isNil(this.props.fleetFracOverview) || _.isNil(this.props.fleetFracOverview.flowRate)
      ? '-' : parseFloat(this.props.fleetFracOverview.flowRate).toFixed(1);
    let pressure = _.isNil(this.props.fleetFracOverview) || _.isNil(this.props.fleetFracOverview.pressure)
      ? '-' : parseFloat(this.props.fleetFracOverview.pressure).toFixed(0);
    let alarmCounts = _.isNil(this.props.fleetFracOverview) || _.isEmpty(this.props.fleetFracOverview.alarmCounts)
      ? {} : this.props.fleetFracOverview.alarmCounts;
    let vibration = _.isNil(this.props.fleetFracOverview) || _.isNil(this.props.fleetFracOverview.vibrationCounts) ? [] :
        this.props.fleetFracOverview.vibrationCounts.reduce(function(result, component) {
          if (!componentHasNoData(component)) {
            result.push(component);
          }
          return result;
        }, []);
    let pressureLabel = fleetType === fleetTypes.PUMPONLY ? "" : "@ " + pressure.toLocaleString() + " psi";
    let noData = 'No Data';
    return (
      <Paper sx={styles.card}>

        <Box sx={styles.headerContent}>
          <Box sx={styles.header}>
            <Box sx={styles.headerProgress}>
              {
                this.props.queryRunning === true &&
                <CircularProgress size={20}/>
              }
            </Box>
            <Box sx={styles.headerTitle}>
              <Typography variant={'subtitle1'}>{fleet.name}</Typography>
            </Box>
            <Box sx={styles.headerActions}>
              <ButtonBase sx={styles.cardActionButton} focusRipple onClick={(event) => this.props.openMenu(event.currentTarget)}><ActionIcon/></ButtonBase>
            </Box>
          </Box>
          <Box sx={styles.headerBorder} />
        </Box>

        <Box sx={styles.cardContent}>
          <Box sx={styles.cardContentCharts}>

            <Box sx={styles.categoryRow}>
              <Box sx={styles.category}>
                <Typography sx={styles.categoryLabel} variant={'caption'}>Operation</Typography>
              </Box>
              <Box>
                <Box sx={styles.statusContainer}>
                  <Box sx={styles.labelContainer}>
                    <Typography sx={styles.statusLabel} variant={'subtitle1'}>{status}</Typography>
                  </Box>
                  <Box sx={styles.labelContainer}>
                    {
                      this.props.queryRunning === false &&
                      <Typography sx={styles.statusSubLabel} variant={'subtitle1'}>{flowRate} bpm {pressureLabel}</Typography>
                    }
                  </Box>
                </Box>

                <Box sx={styles.categorySubsectionBorder}/>

                <Box sx={styles.fracMetricsContainer}>
                  <OperationKeyValueMetricDisplayComponent label={'Engine Load (%)'}   value={this.props.fleetFracOverview.avgEngineLoad}      formatType={FracpumpOperationMetricType.Percent} />
                  <OperationKeyValueMetricDisplayComponent label={'Engine Torque (%)'} value={this.props.fleetFracOverview.avgEngineTorque}    formatType={FracpumpOperationMetricType.Percent} />
                  <OperationKeyValueMetricDisplayComponent label={'DGB Blending (#)'}  value={this.props.fleetFracOverview.dgbBlendingRatio}   formatType={FracpumpOperationMetricType.Fraction} />
                  <OperationKeyValueMetricDisplayComponent label={'Substitution (%)'}  value={this.props.fleetFracOverview.avgGasSubstitution} formatType={FracpumpOperationMetricType.Percent} />
                </Box>
              </Box>
            </Box>

            <Box sx={styles.categoryBorder} />
            {
              (!_.isEmpty(vibration)) &&
              <Box sx={styles.categoryRow}>
                <Box sx={styles.category}>
                  <Typography sx={styles.categoryLabel}
                              variant={'caption'}>Health</Typography>
                </Box>
                <Box sx={styles.chartsContainer}>
                  <div>
                    <Typography sx={styles.categorySubLabel}
                                variant={'caption'}>Vibration</Typography>
                  </div>
                  {
                    vibration.map((component, index) => {
                      return (
                          <Box key={index} sx={styles.stackedThreeBarChartContainer}>
                            <StackedThreeBar label={component.label}
                                             value1={component.low}
                                             value2={component.med}
                                             value3={component.high}/>
                          </Box>
                      )
                    })
                  }
                </Box>
              </Box>
            }
            {
              (_.isEmpty(vibration)) &&
              <Box sx={styles.categoryRow}>
                <Box sx={styles.category}>
                  <Typography sx={styles.categoryLabel}
                              variant={'caption'}>Health</Typography>
                </Box>
                <Box sx={styles.vibrationComponentContainer}>
                  <div>
                    <Typography sx={styles.categorySubLabel}
                                variant={'caption'}>Vibration</Typography>
                  </div>
                  <Box sx={styles.vibrationNoDataLabelContainer}>
                    <Typography sx={styles.noDataLabel}
                                variant={'caption'}>{noData}</Typography>
                  </Box>
                </Box>
              </Box>
            }

          </Box>
          <Box sx={styles.alarmsContainer}>
            <Typography sx={styles.alarmsContainerLabel} variant={'caption'}>Alarms</Typography>
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.criticalAlarmCount) && alarmCounts.criticalAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <CriticalAlarmBadge count={alarmCounts.criticalAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.warningAlarmCount) && alarmCounts.warningAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <WarningAlarmBadge count={alarmCounts.warningAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.infoAlarmCount) && alarmCounts.infoAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <InfoAlarmBadge count={alarmCounts.infoAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (_.isEmpty(alarmCounts) || alarmHasNoData(alarmCounts)) &&
              <Box sx={styles.alarmsNoDataLabelContainer}>
                <Typography sx={styles.noDataLabel}
                            variant={'caption'}>{noData}</Typography>
              </Box>
            }
          </Box>
        </Box>

        <FracOverviewCardMenu stateKey={this.props.stateKey} fleetId={fleet.id} fleetName={fleet.name} />

      </Paper>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: props.stateKey,
      type: ComponentTypes.FRAC_OVERVIEW_CARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let componentState = fracOverviewCardState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning,
    fleetFracOverview: componentState.fleetFracOverview,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: (context) => { dispatch(fracOverviewCardActions.queryFracOverviewData(props.stateDef, context)) },
    openMenu: (menuTargetElement) => { dispatch(fracOverviewCardActions.openMenu(props.stateDef, menuTargetElement)) },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FracOverviewCard));