import React, {Component} from 'react';
import {compose, withProps} from "recompose";

import PropTypes from 'prop-types';
import ComponentTypes from '../../../../componentTypes';

import { appState as applicationState } from '../../../../../state/app/appSelectors';
import * as appUserConfigActions from '../../../../../state/app/actions/appUserConfigActions';
import * as fleetMapActions from '../../../../../state/displays/fleetMap/display/fleetMapActions';

import LayoutFixedWidth from '../../../../controls/layout/layoutFixedWidth';

import { trackPage } from '../../../../../helpers/googleAnalyticsHelper';
import {connect} from "react-redux";
import _ from "lodash";
import moment from 'moment';

import { getCardElementsFromLayout, addResizeHandle } from '../../../../../helpers/cardElementsHelper';
import { CustomResizeHandle } from '../../../../cards/mdtCard/controls/resizeHandle';


class CustomView extends Component {

    componentDidMount() {
        // Track GA View
        trackPage(ComponentTypes.FLEET_DASHBOARD + '/' + this.props.viewConfig.id, this.props.user);
    }

    render() {
        const viewConfiguration = this.props.viewConfig ?? {};
        let startTime, endTime;

        if (this.props.selectedTimeFrame.label !== 'Custom') {
            endTime = moment().startOf('minute').unix();
            startTime = moment.unix(endTime).subtract(this.props.selectedTimeFrame.value, 'minutes').unix();
        } else {
            startTime = moment(this.props.selectedCustomStartTime).unix();
            endTime = moment.unix(startTime).add(this.props.selectedCustomDuration.value, 'minutes').unix();
        }

        const cardElements = getCardElementsFromLayout(ComponentTypes.FLEET_DASHBOARD, viewConfiguration.id, viewConfiguration.cards, { truck: this.props.fleet, startTime:startTime, endTime:endTime}, this.props.editMode, this.props.selectedCard?.type, this.props.contextData, this.props.onCardsQueryRunning, this.props.cardsMetaconfig, this.props.navigateToView);

        const showResizeHandles = (!_.isNil(this.props.editMode) && this.props.editMode === true);
        addResizeHandle(showResizeHandles, viewConfiguration.cards);

        return (
            <LayoutFixedWidth layout={viewConfiguration.cards} cols={11} rowHeight={160} width={1880} isDraggable={_.isNil(this.props.editMode) ? false : this.props.editMode}
                              compactType={_.isNil(this.props.editMode) ? null : 'vertical'} preventCollision={!!_.isNil(this.props.editMode)}
                              onLayoutChange={(layout) => this.props.onUpdateDashboardLayout(ComponentTypes.FLEET_DASHBOARD, viewConfiguration, layout)}
                              resizeHandle={<CustomResizeHandle />} isEditMode={this.props.editMode}>
                {
                    cardElements.map((card) => {
                        return (
                            <div key={card.key}>{card.element}</div>
                        )
                    })
                }
            </LayoutFixedWidth>
        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_DASHBOARD : props.stateKey,
            type: ComponentTypes.FLEET_DASHBOARD,
        }
    }
};

CustomView.propTypes = {
    editMode: PropTypes.bool,
    cardsMetaconfig: PropTypes.array,
};

const mapStateToProps = (state, props) => {
    let appState = applicationState(state);

    return {
        dashboards: appState.user.dashboards,
        selectedCard: appState.user.dashboards[ComponentTypes.FLEET_DASHBOARD]?.selectedCard,
        contextData:appState.context.contextData
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onUpdateDashboardLayout: (dashboard, view, newLayout) => { dispatch(appUserConfigActions.updateDashboardLayout(dashboard, view, newLayout)); },
        onCardsQueryRunning: (queryRunning) => { dispatch(fleetMapActions.onCardsQueryRunning(props.stateDef, queryRunning)); }
    }
}

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CustomView));
