import _ from 'lodash';

import componentTypes from '../../../../components/componentTypes';

/**
 * Sort a collection of sensors
 * @param sensorCollection Input collection of sensors.
 * @return {unknown[]} Sorted array of sensors.
 */
const sortSensors = (sensorCollection) => {
  return _.orderBy(sensorCollection, ['alias', 'uom']);
}

/**
 * Sort and normalize a collection of sensor groups
 * @param sensorGroupCollection Input collection of sensor groups.
 * @return {unknown[]} Sorted array of sensor groups.
 */
const sortSensorGroups = (sensorGroupCollection) => {
  return _.orderBy(sensorGroupCollection, ['name']);
}

/**
 * Determine if a sensor collection includes a specific sensor.
 * @param sensorCollection Input collection of sensors.
 * @param targetSensor Sensor to look for.
 * @return {boolean} {@code true} if sensor exists in the collection; else {@code false}.
 */
const includesSensor = (sensorCollection, targetSensor) => {
  const foundSensor = _.find(sensorCollection, {'alias': targetSensor.alias});
  return !_.isNil(foundSensor);
}

/**
 * Take the given x axis configuration (in state) and 
 * create a Sensor Group of the given name and unit type with 
 * the sensors, axis positions, and colors.
 * 
 * Returns a JSON string of the Sensor Group.
 * @param axisDefinition State configuration (Primary or Secondary) of a x-axis 
 * @param group Sensor group that holds the information needs to be saved
 * @param unitType Type of Truck
 */
 const transformAxisToSensorGroup = (axisDefinition, group, unitType) => {
  const sensorConfigInput = []
  if(_.isEmpty(group.sensors) && axisDefinition){
    //Convert axisDefinition into sensor configs
    // NOTE: this will not work with the unit user chart if we ever decide to create sensor groups from there
    // Unit User Chart Definition does not use display order as of June 04 2023
    _.forEach(axisDefinition.displayOrder, i => {
      sensorConfigInput.push({
        sensorSetId: axisDefinition.sensors[i].sensorSetId,
        color: (axisDefinition.sensors[i].color || axisDefinition.config[i].color),
        axisPosition: (axisDefinition.sensors[i].yAxisId || axisDefinition.sensors[i].axisPosition || axisDefinition.config[i].yAxisId)
      });
    });
  } else {
    //Carry over the existing sensor configs
    sensorConfigInput.push(...group.sensors.map( s=> {
      return {
        sensorSetId: s.sensorSetId,
        color: s.color,
        axisPosition: s.axisPosition
      }
    })) ;
  }

  const sharedSensorGroupInput = 
  {
    unitType: unitType,
    name: _.trim(group.name),
    description: group.description,
    sensors: sensorConfigInput 
  };

  return sharedSensorGroupInput;
};

const validateSensorGroupName = (name) => {

  // If empty/null or too long, return not valid
  if (_.isNil(name) || name.length == 0 || name.length > 40) {
    return true;
  }

  return false;
};

/**
 * Sensor Selector's State Key is a composite of:
 * 1. Sensor Selector
 * 2. _ literal
 * 3. The Parent Component that hosts the Sensor Selector
 * 
 * Sometimes the Parent Component can itself also have a composite state key, like
 * Data Exploration Chart
 * @param {*} parentStateKey State Key of the Parent component that hosts the Sensor Selector
 */
const getContextOwner = (parentStateKey) => {

  // If it matches an existing state key, just return it
  if (_.isEqual(parentStateKey, componentTypes.EXPORT)) {
    return parentStateKey;
  }

  // If it doesn't, try to see if it is a composite key like Data Exploration which has a 
  // state key that is composite of Data Exploration and the Parent Component that hosts the Data Exploration View
  let parentCompositeKey = parentStateKey.split('-');
  if (parentCompositeKey.length > 0) {
    return parentCompositeKey[1];
  }

  // Otherwise we couldn't find a value state key so throw an error indicating so
  throw new Error('Sensor Selector: Could not find a valid Context Owner from the Parent State Key');
}

/**
 * Attempts to get the Unit Type by looking at known slices of state for Context Owners.
 * Currently we support the following Context Owners:
 * 1. Export
 * 2. Data Exploration Display
 * 3. Pump Dashboard
 * @param {*} contextOwnerState Slice of State for a Context Owner
 * @param {*} stateKey Key for the Slice of State for a Context Owner
 */
const getUnitTypeFromContextOwner = (stateKey, contextOwnerState) => {

  switch(stateKey) {
    case componentTypes.EXPORT:
      return contextOwnerState.selectedTruck.type;
    case componentTypes.PUMP_DASHBOARD:
    case componentTypes.DATA_EXPLORATION_DISPLAY:
      return contextOwnerState.selectedContext.unitType;
    default:
      return null;
  }

}

export {
  sortSensors,
  sortSensorGroups,
  includesSensor,
  transformAxisToSensorGroup,
  validateSensorGroupName,
  getContextOwner,
  getUnitTypeFromContextOwner
};
