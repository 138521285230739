import React, {Component} from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Progress from '../../../../../controls/progress';
import FracFleetLayout from '../../../layouts/fracFleetLayout';

import EmptyUnit from '../../../visuals/emptyUnit';
import EmptyDatavan from '../../../visuals/emptyDatavan';
import Pump from '../../../visuals/componentView/pump';
import Datavan from '../../../visuals/componentView/datavan';

import fleetMapStyles from '../../../fleetMapStyles';

import ComponentTypes from '../../../../../componentTypes';
import * as pumpOnlyFleetMapActions from '../../../../../../state/displays/fleetMap/maps/pumpOnly/pumpOnlyFleetMapActions';
import fleetMapViews from '../../../../../../state/displays/fleetMap/display/fleetMapViews';
import { pumpOnlyFleetMapState } from '../../../../../../state/displays/fleetMap/maps/pumpOnly/pumpOnlyFleetMapSelectors';
import NoData from '../../../visuals/noData';
import messages from '../../../../../common/messages';
import * as pumpDashboardActions from '../../../../../../state/displays/pumpDashboard/pumpDashboardActions';
import { evaluateHasMoreThan20Pumps } from '../../fleetMapHelper';
import { trackPage } from '../../../../../../helpers/googleAnalyticsHelper';
import { appState as applicationState } from '../../../../../../state/app/appSelectors';

const styles = {
  ...fleetMapStyles,
};

class PumpOnlyFleetComponentView extends Component {

  constructor(props) {
    super(props);
    this.renderPump = this.renderPump.bind(this);
    this.renderDatavan = this.renderDatavan.bind(this);
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.FLEET_DASHBOARD + '/' + ComponentTypes.COMPONENT_VIEW, this.props.user);

    if (!_.isNil(this.props.fleet)) {
      this.props.queryPumpOnlyFleetMapDetails(this.props.fleet.id, fleetMapViews.COMPONENT);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fleet !== this.props.fleet && !_.isNil(this.props.fleet)) {
      this.props.queryPumpOnlyFleetMapDetails(this.props.fleet.id, fleetMapViews.COMPONENT);
    }
  }

  renderPump(slot, isTopRow) {
    let pumpData = _.find(this.props.pumps, {'slot': slot});
    let visual = <EmptyUnit/>;
    if (!_.isNil(pumpData)) {
      visual = pumpData.empty === true ? <EmptyUnit/> : <Pump data={pumpData} navigateToDashboard={this.props.navigateToPumpDashboard}/>;
    }
    return visual;
  }

  renderDatavan() {
    let visual = <EmptyDatavan/>;
    if (!_.isNil(this.props.datavan)) {
      visual = this.props.datavan.empty === true ? <EmptyDatavan/> : <Datavan data={this.props.datavan}/>;
    }
    return visual;
  }

  evaluateNoData() {
    return (_.isNil(this.props.datavan) && _.isEmpty(this.props.pumps) && (!this.props.queryRunning));
  }

  render() {

    const hasNoData = this.evaluateNoData();
    const hasMoreThan20Pumps = evaluateHasMoreThan20Pumps(this.props.pumps);

    return (
      <Box sx={styles.fleetMapLayoutWithNoDataWrapper}>
        <FracFleetLayout
          renderPump={this.renderPump}
          renderDatavan={this.renderDatavan}
          hasNoData={hasNoData}
          moreThan20Pumps={hasMoreThan20Pumps}
        />
        {
          (hasNoData) &&
          <NoData textToDisplay={messages.NO_DATA_FLEET_MAP}/>
        }
        <Progress open={this.props.queryRunning}/>
      </Box>
    )
  }
}

PumpOnlyFleetComponentView.propTypes = {
  fleet: PropTypes.object,
};

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.PUMP_ONLY_FLEET_MAP_VIEW : props.stateKey,
      type: ComponentTypes.PUMP_ONLY_FLEET_MAP_VIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = pumpOnlyFleetMapState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    queryRunning: componentState.queryRunning,
    datavan: componentState.datavan,
    pumps: componentState.pumps,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryPumpOnlyFleetMapDetails: (fleetId, view) => { dispatch(pumpOnlyFleetMapActions.queryPumpOnlyFleetMapDetails(props.stateDef, fleetId, view)) },
    navigateToPumpDashboard: (unitId) => { dispatch(pumpDashboardActions.navigate(unitId)) }
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(PumpOnlyFleetComponentView))