import * as liveViewSimulation from './liveViewSimulation';
import {performDisplayFetch} from '../../../../common/fetch';
import useSimulationData from '../../../../../helpers/simulationHelper';

const fetchTrucksForLiveView = (input) => {
  if (useSimulationData('fleetLiveView')) {
    return liveViewSimulation.fetchTrucksForLiveView();
  }

  return performDisplayFetch({
    'query': 'query TrucksForLiveView($input:TrucksForLiveViewInput!) { trucksForLiveView(input: $input) { truckPid truckName unitType slotNumber labels } }',
    'variables': {
      'input': input
    }
  });
}

const fetchSensorDataForTrucks = (input) => {
  if (useSimulationData('fleetLiveView')) {
    return liveViewSimulation.fetchSensorDataForTrucks(input);
  }

  return performDisplayFetch({
    'query': 'query chartSeries($input:[ChartSeriesInput]!) { chartSeries(input:$input) { truckPid sensorSetId unitOfMeasure timestamps values } }',
    'variables': {
      'input': input
    }
  });
}

const fetchSaveUserDashboard = (input) => {
  return performDisplayFetch(
    {
      'query': 'mutation SaveUserDashboardConfiguration($input:UserDashboardConfigurationInput!) { saveUserDashboardConfiguration(input: $input) { success } }',
      'variables': {
        'input': input
      }
    });
};

export {
  fetchTrucksForLiveView,
  fetchSensorDataForTrucks,
  fetchSaveUserDashboard
}