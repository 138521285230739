import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Typography, Box, CircularProgress } from '@mui/material';
import { loadUserRoutes } from '../../../state/app/actions/appUserActions';

const styles = {
  page: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 18,
  },
  message: {
    paddingTop: 2
  }
};

class LoadingUserRoutesPage extends Component {

  componentDidMount() {
    if (_.isEmpty(this.props.userRoutes.routes)) {
      this.props.loadUserRoutes();
    }
  }

  render() {
    return (
      <Box sx={styles.page}>
        <CircularProgress size={80} />
        <Typography variant={'subtitle1'} sx={styles.message}>Loading...</Typography>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userRoutes: state.app.user.routes,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserRoutes: (selectedOwner) => dispatch(loadUserRoutes(selectedOwner))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingUserRoutesPage);