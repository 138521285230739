import _ from "lodash";
import React, {Component} from 'react';
import {compose} from "recompose";
import { connect } from 'react-redux';
import { withProps } from "recompose";

import {
  Box,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import ComponentTypes from '../../../componentTypes';
import { deviceDialogState } from '../../../../state/displays/settings/dialog/deviceDialog/deviceDialogSelectors';
import * as deviceDialogActions from '../../../../state/displays/settings/dialog/deviceDialog/deviceDialogActions';
import * as deviceActions from "../../../../state/displays/settings/actions/devicesActions";
import getDialogStyles from "../../../common/styles/dialogStyles";
import {deviceState} from "../../../../state/displays/settings/selectors/deviceSelectors";
import PropTypes from "prop-types";
import {AutoCompleteMDT} from "../../../controls/mdtMuiControls";

const dialogStyles = getDialogStyles();

const styles = {
  deviceNameContents: {
    height: '80px',
  },
  selectProviderTypeContents: {
    height: '80px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start'
  },
  selectProviderTypeSelectorGroup: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    width: "50%",
    marginRight: 2,
  },
  deviceProviderTypeLabel: {
    marginTop: '4px'
  },
  teamsChannelsContents: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: '120px',
  },
};

class DeviceDialog extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {

    // If we are going from hide -> show...
    if (prevProps.showDialog === false && this.props.showDialog === true) {
      this.props.onLoadContextData();
    }

  }


  render() {
    return (
        <Dialog open={this.props.showDialog} disableEscapeKeyDown>
          <DialogTitle>New Device</DialogTitle>
          <DialogContent sx={{width:'400px'}}>
            <Box sx={{width:'100%', marginBottom:'20px'}}>
              <AutoCompleteMDT
                  options={this.props.owners}
                  value={this.props.owners.find(owner => owner.value === this.props.deviceOwner.value)}
                  onChange={(event, value, reason) => {
                    this.props.onSelectDeviceOwner(value);
                  }}
                  noOptionsText={'No owners found...'}
              />
              <Typography fontSize={'small'}  variant={'subtitle1'} sx={styles.providerProviderTypeLabel}>Owner</Typography>
            </Box>
            <TextField
                sx={styles.formField}
                variant='standard'
                size='small'
                helperText="ESN"
                inputProps={{maxLength: 255}}
                value={this.props.deviceName || ''}
                onChange={(event) => {
                  this.props.onSetDeviceName(event.target.value);
                }}
                error={this.props.errorDeviceName}
                fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => {
              this.props.onClearForm();
              this.props.onShowDeviceDialog(false);
            }}>Cancel</Button>
            <Button variant={'contained'} color='primary' onClick={() => this.props.onSaveDeviceSettings(this.props.deviceOwner, this.props.deviceName)}
                    disabled={!this.props.canSave}>Save</Button>
          </DialogActions>
        </Dialog>
    );
  }
}

DeviceDialog.propTypes = {
  owners: PropTypes.array.isRequired,
};


const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DEVICE_DIALOG : props.stateKey,
      type: ComponentTypes.DEVICE_DIALOG,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;

  let componentState = deviceDialogState(state[stateDef.key]);
  let settingsState = deviceState(state.settings);

  return {
    showDialog: settingsState.device.showDeviceDialog,

    canSave: componentState.canSave,

    errorDeviceName: componentState.errorDeviceName,
    errorDeviceOwner: componentState.errorDeviceOwner,

    deviceName: componentState.deviceName,
    deviceOwner: componentState.deviceOwner,

  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onLoadContextData: () => { dispatch(deviceDialogActions.loadContextData(props.stateDef)); },
    onShowDeviceDialog: (show) => { dispatch(deviceActions.showDeviceDialog(props.stateDef, show)); },
    
    onSetDeviceName: (name) => { dispatch(deviceDialogActions.setDeviceName(props.stateDef, name)); },
    onSelectDeviceOwner: (owner) => { dispatch(deviceDialogActions.setDeviceOwner(props.stateDef, owner)); },

    onSaveDeviceSettings: (owner, name) => { dispatch(deviceActions.saveDeviceSettings(props.stateDef, null, owner, name)) },
    onClearForm: () => { dispatch(deviceDialogActions.clearForm(props.stateDef)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DeviceDialog));