import { mdtPalette } from "./mdtPalette";

const getTypographyStyles = () => ({

  // The preferred style for No Data labels via
  //   <Typography className={noDataLabel} variant={'caption'}>No Data</Typography>
  // Note the use of the 'caption' variant to achieve the correct font size.
  noDataLabel: {
    color: mdtPalette().typography.noData,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },

});

export default getTypographyStyles;