import _ from 'lodash';
import moment from 'moment';
import * as filterService from '../../../common/filtering/filterService';
import * as assetTypeService from '../../../common/services/assetTypeService';
import * as alarmSeverityService from '../../../common/services/alarmSeverityService';
import {alarmSeverities} from '../../../common/services/alarmSeverityService';

const processAlarms = (alarms, filters, excludeMessageAlarms) => {

  // If requested, exclude message alarms from the alarm processing
  let filteredAlarms = excludeMessageAlarms === true ? _.filter(alarms, (alarm) => { return alarm.severity !== alarmSeverities.MESSAGE}) : alarms;

  // Filter the alarms down based on the used selected filters.
  filteredAlarms = !_.isEmpty(filteredAlarms) ? filterService.filterItems(filteredAlarms, filters) : [];

  // Update our filter list based on the current list of filtered alarms.
  let updatedFilters = filterService.generateFilters(filteredAlarms, filterDefinitions());

  return {
    filteredAlarms: filteredAlarms,
    filters: updatedFilters
  };

};

const transformAlarms = (alarms) => {

  // Take the raw alarms returned from the service and augment them with additional properties
  // that will be leverage by the UI logic.

  return _.map(alarms, alarm => {

    // Clone the raw alarm...
    let transformedAlarm = _.cloneDeep(alarm);

    transformedAlarm.displayValueUnitType = _.isNil(transformedAlarm.unitType) ? 'N/A' : assetTypeService.getAssetNameByType(transformedAlarm.unitType);

    transformedAlarm.severityName = alarmSeverityService.getSeverityName(transformedAlarm.severity);

    if (!_.isNil(transformedAlarm.timestamp)) {
      transformedAlarm.displayValueTimestamp = moment(transformedAlarm.timestamp*1000).format('MMM DD yyyy HH:mm:ss A');
    }

    if (!_.isNil(transformedAlarm.raisedTimestamp)) {

      if (!_.isNil(transformedAlarm.acknowledgedTimestamp)) {
        transformedAlarm.age = transformedAlarm.acknowledgedTimestamp;
        transformedAlarm.displayValueAge = moment.utc(transformedAlarm.acknowledgedTimestamp*1000).fromNow();
        transformedAlarm.displayValueRaisedTimestamp = moment.utc(transformedAlarm.raisedTimestamp*1000).fromNow();
      } else {
        transformedAlarm.age = transformedAlarm.raisedTimestamp;
        transformedAlarm.displayValueAge = moment.utc(transformedAlarm.raisedTimestamp * 1000).fromNow();
      }

      // To simplify the CSV export we just export the formatted timestamp value. So for active alarms that do
      // not have a timestamp value we format the data age.
      transformedAlarm.displayValueTimestamp = moment.utc(transformedAlarm.age*1000).format('MMM DD yyyy HH:mm:ss A');
    }

    return transformedAlarm;
  });
};

const resolveSortContext = (currentSortContext, showingActiveAlarms) => {

  // All the columns should be the same between active and historical alarms except
  // the timestamp/age columns. Switch those around if necessary.

  if (showingActiveAlarms === true && currentSortContext === 'timestamp') {
    currentSortContext = 'age';
  }

  if (showingActiveAlarms === false && currentSortContext === 'age') {
    currentSortContext = 'timestamp';
  }

  return currentSortContext;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Category', 'category'));
  definitions.push(filterService.createFilterDefinition('Details', 'details'));
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Severity', 'severityName'));
  definitions.push(filterService.createFilterDefinition('State', 'state'));
  definitions.push(filterService.createFilterDefinition('Source', 'type'));
  definitions.push(filterService.createFilterDefinition('Unit ID', 'unitName'));
  definitions.push(filterService.createFilterDefinition('Unit Type', 'displayValueUnitType'));
  return definitions;
};

export {
  processAlarms,
  transformAlarms,
  resolveSortContext,
}