import React, {Component} from "react";
import {
    Box,
    Button,
    IconButton,
    Typography
} from "@mui/material";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import _ from "lodash";
import ComponentTypes from "../../componentTypes";
import RefreshIcon from "@mui/icons-material/Refresh";
import Progress from "../../controls/progress";
import getDetailsPageStyles from "../../common/styles/detailsPageStyles";
import {
    GroupAdd
} from "@mui/icons-material";
import * as customersDisplayAction from "../../../state/displays/customersDisplay/customersDisplayActions";
import {customersDisplayState} from "../../../state/displays/customersDisplay/customersDisplaySelectors";
import {appState} from "../../../state/app/appSelectors";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import MDTDataGrid from "../../common/table/MDTDataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from '@mui/icons-material/Edit'
import CustomerDeleteConfirmationDialog from "./customerDeleteConfirmationDialog";
import SaveCustomerDialog from "./saveCustomerDialog";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
    ...detailsPageStyles,
    detailsPageContent: {
        ...detailsPageStyles.detailsPageContent,
        width: '80vw',
    },
    detailsPageHeaderActions: {
        ...detailsPageStyles.detailsPageHeaderActions,
        justifyContent: 'flex-start'
    },
    innerTable: {
        width: '100%',
    }
};

class CustomersDisplay extends Component {

    constructor(props) {
        super(props);
        // Generate the columns data for the data table.
        this.columns = this.generateTableColumns();
    }

    componentDidMount() {
        this.props.onQueryData();
    }

    generateTableColumns() {
        let columns = [];
        columns.push({
            field: 'name',
            headerName: 'Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });
        columns.push({
            field: 'contactName',
            headerName: 'Contact',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false
        });
        columns.push({
            field: "phone",
            headerName: "Phone",
            headerAlign: "center",
            align: "center",
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'email',
            headerName: 'Email',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        });
        columns.push({
            field: 'location',
            headerName: 'Location',
            headerAlign: 'center',
            align: 'center',
            editable: false
        });
        columns.push({
            field: 'note',
            headerName: 'Notes',
            headerAlign: 'center',
            align: 'center',
            editable: false,
        });
        columns.push({
            field: 'Actions',
            align: 'center',
            resizable: false,
            hideable: false,
            disableReorder: true,
            type: 'actions',
            getActions: (params) => {
                const actions = [];
                actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'}/>}
                                                  onClick={() => 
                                                    this.props.onSaveCustomerDialogOpen(params.row)}
                                                  label="Edit" showInMenu/>);
                actions.push(<GridActionsCellItem
                    icon={<DeleteForeverIcon fontSize={'small'}/>}
                    label="Delete"
                    showInMenu
                    onClick={() => { this.props.onCustomerDelete(params.row); }}
                />);
                return actions;
            }
        });
        return columns;
    }
    render() {
        return (<Box sx={styles.detailsPage}>
            <Box sx={styles.detailsPageContent}>
                <Box sx={styles.detailsPageHeader}>
                    <Box sx={styles.detailsPageHeaderTitle}>
                        <Typography variant={'h6'}>Customers</Typography>
                    </Box>
                    <Box sx={styles.detailsPageHeaderActions}>
                        <IconButton
                            onClick={() => {
                                this.props.onQueryData();
                            }}
                            size="large">
                            <RefreshIcon/>
                        </IconButton>
                        <Button onClick={() => this.props.onSaveCustomerDialogOpen({})} 
                                color={'inherit'}>
                            <GroupAdd sx={{marginRight: '5px'}}/>
                            CREATE CUSTOMER
                        </Button>
                    </Box>
                </Box>
                <Box sx={styles.mdtDataGridContainer}>
                    <MDTDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                        }}
                        columns={this.columns}
                        rows={this.props.customers}
                        disableSelectionOnClick
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                                csvOptions: {
                                    fileName: "customers",
                                },
                            },
                        }}
                        stateDef={this.props.stateDef}
                        rowsPerPage={this.props.rowsPerPage}
                        setRowsPerPage={this.props.setRowsPerPage}
                        rowsPerPageOptions={this.props.rowsPerPageOptions}
                    />
                </Box>
            </Box>
            <Progress open={this.props.queryRunning}/>
            <CustomerDeleteConfirmationDialog />
            <SaveCustomerDialog/>
        </Box>);
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.CUSTOMERS_DISPLAY : props.stateKey,
            type: ComponentTypes.CUSTOMERS_DISPLAY,
        }
    };
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props
    let applicationState = appState(state);
    const componentState = customersDisplayState(state[stateDef.key]);

    return {
        user: applicationState.user,
        queryRunning: componentState.queryRunning,
        customers: componentState.customers,
        rowsPerPage: componentState.rowsPerPage,
        rowsPerPageOptions: componentState.rowsPerPageOptions,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        setRowsPerPage: (rowSize) => dispatch(customersDisplayAction.setRowsPerPage(props.stateDef, rowSize)),
        onQueryData: () => {
            dispatch(customersDisplayAction.queryCustomer(props.stateDef));
        },
        onSaveCustomerDialogOpen: (customerForSave) => {
            dispatch(customersDisplayAction.onSaveCustomerDialogOpen(props.stateDef, customerForSave));
        },
        onSaveCustomerDialogClose: () => {
            dispatch(customersDisplayAction.onSaveCustomerDialogClose(props.stateDef));
        },
        onCustomerDelete: (customerToDelete) => {
            dispatch(customersDisplayAction.onCustomerDelete(props.stateDef, customerToDelete));
        },
    };
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(CustomersDisplay));