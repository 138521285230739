import _ from "lodash";
import * as unitChartService from '../../../common/services/unitChartService';
import unitChartSeriesTypes from '../unitChartSeriesTypes';

const transformSeries = (rawSeries) => {
    let powerEndFrontScore = _.find(rawSeries, ['id', unitChartSeriesTypes.POWER_END_FRONT]);
    let powerEndRearScore  = _.find(rawSeries, ['id', unitChartSeriesTypes.POWER_END_REAR]);
    let fluidEndFrontScore = _.find(rawSeries, ['id', unitChartSeriesTypes.FLUID_END_FRONT]);
    let fluidEndRearScore  = _.find(rawSeries, ['id', unitChartSeriesTypes.FLUID_END_REAR]);

    return {
        series1: unitChartService.transformSeries(powerEndFrontScore, 1),
        series2: unitChartService.transformSeries(fluidEndFrontScore, 1),
        series3: unitChartService.transformSeries(fluidEndRearScore, 1),
        series4: unitChartService.transformSeries(powerEndRearScore, 1),
    }
};

export {
    transformSeries,
}
