import _ from "lodash";
import React, {Component} from 'react';
import moment from "moment";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from "prop-types";
import DataExplorerTicks from './dataExplorerTicks';
import { Typography, Button, ButtonBase, CircularProgress, Box } from '@mui/material';

import * as dataExplorerService from './dataExplorerService';

const styles = {
  chartContainer: {
    width: dataExplorerService.SPECS.CHART_WIDTH,
    height: dataExplorerService.SPECS.CHART_HEIGHT,
    overflowX:'hidden',
    overflowY:'hidden',
    userSelect: 'none',
    cursor:'ew-resize',
    backgroundColor: 'background.default',
    MozUserSelect: '-moz-none',
    KhtmlUserSelect: 'none',
    WebkitUserSelect: 'none',
    OUserSelect: 'none',
  },
  centerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'grey.500'
  },
  block: {
    height: '40px',
    backgroundColor:"#355B9C"
  },
  selectionWindow: {
    width: dataExplorerService.SPECS.SELECTION_WINDOW_WIDTH,
    height: dataExplorerService.SPECS.SELECTION_WINDOW_HEIGHT,
    pointerEvents:'none',
    backgroundColor: 'grey.500',
    opacity:0.2
  },
  selectionWindowBorder: {
    width: '1px',
    height: '95px',
    pointerEvents:'none',
    backgroundColor: 'grey.500',
  },
  hoverButton: {
    width: dataExplorerService.SPECS.HOVER_BUTTON_WIDTH,
    height: dataExplorerService.SPECS.HOVER_BUTTON_HEIGHT,
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      color: 'grey.500'
    }
  },
  durationButtonContainer: {
    display:'flex',
    alignItems: 'center',
    paddingBottom: 1,
  },
  busyOverlay: {
    width: dataExplorerService.SPECS.CHART_WIDTH,
    height: dataExplorerService.SPECS.CHART_HEIGHT,
    backgroundColor: 'background.default',
    opacity: 0.50
  }
};

class DataExplorer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dragScrolling: false,
      clientX: 0,
    }
  }

  onStartDragScroll = (event) => {

    this.setState({
      dragScrolling: true,
      clientX: event.type === 'touchstart' ? event.touches[0].clientX : event.clientX,
    });

  };

  onDragScroll = (event) => {

    if (!_.isNil(this.state) && this.state.dragScrolling) {

      let clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
      this.setState({clientX: clientX});

      let diff =  this.state.clientX - clientX;
      let minutesAdjustment = diff * (this.props.selectedDuration.value/dataExplorerService.SPECS.SELECTION_WINDOW_WIDTH);

      this.onSelectDateTime(moment(this.props.selectedDateTime).add(minutesAdjustment, 'minutes'));
    }
  };

  onStopDragScroll = () => {
    this.setState({
      dragScrolling: false,
      clientX: 0,
    });
  };

  onSelectDateTime(dateTime) {
    if (!_.isNil(dateTime) && !_.isNil(this.props.onDateTimeChange)) {
      this.props.onDateTimeChange(dateTime);
    }
  }

  onSelectDuration(duration) {
    if (!_.isNil(duration) && !_.isNil(this.props.onDurationChange)) {
      this.props.onDurationChange(duration);
    }
  };

  onFindNext() {
    if (!_.isNil(this.props.onFindNext)) {
      this.props.onFindNext();
    }
  };

  onFindPrevious() {
    if (!_.isNil(this.props.onFindPrevious)) {
      this.props.onFindPrevious();
    }
  };

  render() {

    let blocks = dataExplorerService.getBlocksToRender(this.props.selectedDateTime, this.props.dataRanges, this.props.selectedDuration);
    let moreDataLeft = _.isNil(this.props.selectedDateTime) ? false : dataExplorerService.getPreviousBlockEdge(this.props.selectedDateTime, this.props.dataRanges) != null;
    let moreDataRight = _.isNil(this.props.selectedDateTime) ? false : dataExplorerService.getNextBlockEdge(this.props.selectedDateTime, this.props.dataRanges) != null;

    return (
      <div >
        {/* Render the duration buttons */}
        <Box sx={styles.durationButtonContainer}>
          <Typography variant={'caption'}>Duration:</Typography>
          {
            this.props.durations.map((duration, index) => {
              return (
                <Button
                  key={index}
                  // Note: "color" here tries to access 'main' property of the value you set
                  // Therefore you cannot just set a color here
                  color={duration.value === this.props.selectedDuration.value ? 'primary' : 'inherit'}
                  size="small"
                  onClick={() => { return this.onSelectDuration(duration) }}
                >
                  {duration.label}
                </Button>
              )
            })
          }
        </Box>
        {/* Render the main chart area */}
        <Box sx={{position: 'relative'}}>
          <Box
            sx={styles.chartContainer}
            // Desktop event handlers
            onMouseDown={this.onStartDragScroll}
            onMouseMove={this.onDragScroll}
            onMouseUp={this.onStopDragScroll}
            onMouseLeave={this.onStopDragScroll}
            // Touch screen event handlers
            onTouchStart={this.onStartDragScroll}
            onTouchMove={this.onDragScroll}
            onTouchEnd={this.onStopDragScroll}
          >
            <Box sx={{position: 'relative', width: '900px', height: '130px'}}>
              {/* Render the ticks */}
              <Box sx={{position: 'absolute', top: '95px', left: '0px'}}>
                <DataExplorerTicks selectedDateTime={this.props.selectedDateTime} selectedDuration={this.props.selectedDuration} />
              </Box>
              {/* Render the center line of the chart */}
              <Box sx={{...styles.centerLine, position: 'relative', top: '47.5px', left: '0px',}}/>
              {/* Render any blocks */}
              {
                blocks &&
                blocks.map((block, index) => {
                  return (
                    <Box key={index} 
                      sx={{
                          ...styles.block,
                          position: 'absolute',
                          top: '27.5px', 
                          left: block.left, 
                          width: block.width,
                      }}/>
                  )
                })
              }
            </Box>
          </Box>
          {/* Render the selection window */}
          <Box sx={{
                ...styles.selectionWindowBorder, 
                position: 'absolute',
                top: '0px', 
                left: '299px',
                }}/>
          <Box sx={{
                ...styles.selectionWindowBorder, 
                position: 'absolute',
                top: '0px', 
                left: '599px'
                }}/>
          <Box sx={{
                ...styles.selectionWindow,
                position: 'absolute',
                top: '0px', 
                left: '299px',
                }}/>
          {/* Render the previous button */}
          {
            moreDataLeft &&
            <ButtonBase focusRipple onClick={() => this.onFindPrevious()}
                        sx={{...styles.hoverButton, position: 'absolute', top: '0px', left: '0px'}}>
              <ChevronLeftIcon fontSize={'large'}/>
            </ButtonBase>
          }
          {/* Render the next button */}
          {
            moreDataRight &&
            <ButtonBase focusRipple onClick={() => this.onFindNext()}
                        sx={{...styles.hoverButton,  position: 'absolute', top: '0px', left: '852px'}}>
              <ChevronRightIcon fontSize={'large'}/>
            </ButtonBase>
          }
          {/* Render the busy overlay */}
          {
            this.props.isBusy &&
            <div>
              <Box sx={{...styles.busyOverlay, position: 'absolute', top: '0px', left: '0px'}}/>
              <CircularProgress size={60} sx={{position: 'absolute', top: '17.5px', left: '420px'}}/>
            </div>
          }
          {/* Show a message when we have no data ranges to render */}
          {
            !this.props.isBusy &&
            !_.isNil(this.props.dataRanges) &&
            _.isEmpty(this.props.dataRanges) &&
            <div>
              <Box sx={{...styles.busyOverlay, position: 'absolute', top: '0px', left: '0px'}}/>
              <Typography variant={'h4'} sx={{position: 'absolute', top: '27.5px', left: '388px'}}>No Data</Typography>
            </div>
          }
        </Box>
      </div>
    );
  }
}

DataExplorer.propTypes = {

  // Properties
  selectedDateTime: PropTypes.object,
  selectedDuration: PropTypes.object,
  durations: PropTypes.array,
  dataRanges: PropTypes.array,
  isBusy: PropTypes.bool,

  // Actions
  onDateTimeChange: PropTypes.func,
  onDurationChange: PropTypes.func,
  onFindNext: PropTypes.func,
  onFindPrevious: PropTypes.func,

};

export default DataExplorer;