import _ from "lodash";
import React, {Component} from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withProps } from "recompose";
import PropTypes from "prop-types";

import {
  Box, Stepper, Step, StepLabel, StepContent, StepButton, Paper,
  DialogTitle, DialogActions, Dialog, DialogContent, Typography, Button, TextField,
  Grid, IconButton, Tooltip, Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { AutoCompleteMDT } from '../../controls/mdtMuiControls';
import Progress from '../../controls/progress';
import ComponentTypes from '../../../components/componentTypes';
import { appState } from '../../../state/app/appSelectors';
import subscriptionEntity from "../../common/subscriptionEntity";
import { subscriptionDialogState } from '../../../state/common/notifications/subscriptionDialog/subscriptionDialogSelectors';
import * as notificationsActions from '../../../state/app/actions/appNotificationsActions';
import * as subscriptionDialogActions from '../../../state/common/notifications/subscriptionDialog/subscriptionDialogActions';
import * as subscriptionsDisplayActions from '../../../state/displays/subscriptionsDisplay/subscriptionsDisplayActions';
import getDialogStyles from "../../common/styles/dialogStyles";

const dialogStyles = getDialogStyles();

const styles = {
  subscriptionNameContents: {
    height: '80px',
  },
  selectEventContents: {
    height: '80px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start'
  },
  selectEventSelectorGroup: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    marginRight: 2,
  },
  eventAutoComplete: {
    width: '200px',
  },
  eventTypeAutoComplete: {
    width: '200px',
  },
  eventEventTypeLabel: {
    marginTop: '4px'
  },
  recipientsContents: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: '120px',
  }

};

const steps = ['Name Your Notification', 'Select Your Event', 'Define Filter Conditions', 'Who Do You Want to Notify?'];

class SubscriptionDialog extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {

    // If we are going from hide -> show...
    if (prevProps.showDialog === false && this.props.showDialog === true) {

      // Reset the stepper to 0 and query data for the dialog if the context has changed
      this.props.onSetActiveStep(0);
      this.props.onQueryData(this.props.context, this.props.entity, this.props.user.showPreReleaseContent);

    }

  }

  renderNameSubscription() 
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.subscriptionNameContents}>
          <TextField 
            sx={{marginTop: 2}}
            autoFocus  
            variant='standard' 
            size="small" 
            fullWidth 
            inputProps={{maxLength:255}} 
            value={this.props.subscriptionName}
            onChange={event => this.props.onSetSubscriptionName(event.target.value)}
            helperText='Notification Name'
          />
        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} disabled>Prev</Button>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep + 1); }}>Next</Button>
        </Box>
      </Box>
    );
  }

  renderSelectEvent() 
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.selectEventContents}>
          <Box sx={styles.selectEventSelectorGroup}>
            <AutoCompleteMDT
              disabled={!this.props.canSelectEvent}
              sx={styles.eventAutoComplete}
              getOptionLabel={(option) => option.label}
              options={this.props.allEvents}
              value={this.props.event}
              onChange={(event, value, reason) => {
                this.props.onSelectEvent(value);
              }}
              noOptionsText={'No events found...'}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
            <Typography variant={'caption'} sx={styles.eventEventTypeLabel}>Event</Typography>
          </Box>
          <Box sx={styles.selectEventSelectorGroup}>
            <AutoCompleteMDT
              disabled={!this.props.canSelectEventType}
              sx={styles.eventTypeAutoComplete}
              getOptionLabel={(option) => option.label}
              options={this.props.availableEventTypes}
              value={this.props.eventType}
              onChange={(event, value, reason) => {
                this.props.onSelectEventType(value);
              }}
              noOptionsText={'No event types found...'}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
            <Typography variant={'caption'} sx={styles.eventEventTypeLabel}>Event Type</Typography>
          </Box>
        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep + 1); }}>Next</Button>
        </Box>
      </Box>
    );
  }

  renderFilterConditions() 
  {
    const emptyAttributeOption =
    {
      label: '',
      value: null,
      type: null,
      availableOperators: [],
      availableValues: []
    };

    const emptyOperatorOption = 
    {
      label: '',
      value: null
    };

    const shouldUseMultiTagsField =(operator) => {
      return !_.isNil(operator)
          && (operator.value === "in" || operator.value === "not_in");
    }

    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={dialogStyles.filterConditionsContents}>

          {
            (_.isEmpty(this.props.availableFilterAttributes) && _.isEmpty(this.props.filters)) &&
            <Box sx={dialogStyles.filterConditionsContentsMessage}>
              <Typography> To set Filters, please select an Event and Event Type. </Typography>
            </Box>
          }

          { !(_.isEmpty(this.props.availableFilterAttributes) && _.isEmpty(this.props.filters)) &&
          <Grid container direction={'column'} alignItems={'center'}>

            <Grid container direction={'row-reverse'} alignItems={'flex-end'} justifyContent={'flex-end'} sx={{...dialogStyles.tableHeader, width:"660px"}}>
              <Button onClick={() => {this.props.onAddFilter()}} color={'inherit'} disabled={_.isEmpty(this.props.availableFilterAttributes)}>
                <AddIcon sx={dialogStyles.tableActionButton}/>
                ADD
              </Button>
            </Grid>

            {/* Header */}
            <Grid container direction={'row'} alignItems={'center'} sx={{...dialogStyles.headerRow, width:"660px"}}>
              <Grid item sx={{...dialogStyles.headerCell, width: '160px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Attribute</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '118px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Operator</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '325px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Value</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '57px'}}/>
            </Grid>

            {/* Contents */}
            <Paper sx={{...dialogStyles.table, width:"660px"}}>
              <Grid container>
                {
                  this.props.filters.map((filter) => {
                    const currentFilterValue = filter.value;
                    const currentFilterOperator = filter.operator;
                    return (
                      <Grid container key={filter.id} alignItems={'center'} sx={{minHeight:'49px'}}>
                        <Grid item sx={{...dialogStyles.tableCellLeftMost, width: '160px', height: '100%'}}>
                          {/* Attribute column */}
                          <AutoCompleteMDT
                            getOptionLabel={(attribute) => attribute.label}
                            // Added the empty option and filterOptions function to resolve console warnings coming from MUI about Autocomplete not matching when nothing selected
                            options={[_.isNil(filter.attribute.value)? emptyAttributeOption: filter.attribute, ...this.props.availableFilterAttributes]}
                            value={filter.attribute}
                            filterOptions={() => this.props.availableFilterAttributes}
                            onChange={(event, value, reason) => {
                              this.props.onSelectFilterAttribute(value, filter.id);
                            }}
                            noOptionsText={'No attributes found...'}
                          />
                        </Grid>  
                        <Grid item sx={{...dialogStyles.tableCell, width: '118px', height: '100%'}}>
                          {/* Operator column */}
                          <AutoCompleteMDT
                            getOptionLabel={(operator) => operator.label}
                            // Added the empty option and filterOptions function to resolve console warnings coming from MUI about Autocomplete not matching when nothing selected
                            options={[emptyOperatorOption, ...filter.attribute.availableOperators]}
                            value={currentFilterOperator}
                            filterOptions={() => filter.attribute.availableOperators}
                            onChange={(event, value, reason) => {
                              this.props.onSelectFilterOperator(value, filter.id);
                            }}
                            noOptionsText={'No operators found...'}
                          />
                        </Grid>
                        <Grid item sx={{...dialogStyles.tableCell, width: '325px', minHeight: '49px', height: '100%'}}>
                          {/* Value column */}
                          {
                            (!_.isEmpty(filter.attribute.availableValues)) && !shouldUseMultiTagsField(currentFilterOperator)  &&
                            <AutoCompleteMDT
                              getOptionLabel={(value) => value.label}
                              options={filter.attribute.availableValues}
                              value={currentFilterValue}
                              onChange={(event, value, reason) => {
                                this.props.onSetFilterValue(value, filter.id);
                              }}
                              noOptionsText={'No values found...'}
                            />
                          }
                          {
                            (!_.isEmpty(filter.attribute.availableValues)) && shouldUseMultiTagsField(currentFilterOperator) &&
                            <AutoCompleteMDT
                                multiple
                                getOptionLabel={(value) => value.label}
                                options={filter.attribute.availableValues}
                                value={currentFilterValue}
                                onChange={(event, value, reason) => {
                                  this.props.onSetFilterMultiValues(value, filter.id);
                                }}
                                noOptionsText={'No values found...'}
                            />
                          }
                          {
                            (_.isEmpty(filter.attribute.availableValues)) && shouldUseMultiTagsField(currentFilterOperator) &&
                            <AutoCompleteMDT
                                multiple
                                freeSolo
                                options={[]}
                                value={currentFilterValue}
                                isOptionEqualToValue={(option, value) => option !== option}
                                onChange={(event, value, reason) => {
                                  if(value.filter(v => v == event.target.value).length < 2 ){ // avoid duplicate input
                                    this.props.onSetFilterMultiValues(value, filter.id);
                                  }
                                }}
                            />
                          }
                          {
                            (_.isEmpty(filter.attribute.availableValues)) && !shouldUseMultiTagsField(currentFilterOperator) &&
                            <Tooltip title={currentFilterValue} followCursor={true}>
                              <TextField
                                sx={{paddingTop: '5px'}}
                                variant='standard'
                                size="small"
                                fullWidth
                                value={currentFilterValue}
                                onChange={(event, value, reason) => this.props.onSetFilterValue(event.target.value, filter.id)}
                                inputProps={{maxLength:255}}
                                InputProps={{disableUnderline: true }}
                              />
                            </Tooltip>
                          }
                        </Grid>
                        <Grid item sx={{...dialogStyles.tableActionCell, height: '100%'}}>
                          {/* Action column */}
                          <IconButton
                            onClick={(event) => { this.props.onRemoveFilter(filter.id); }}
                            size='small'>
                            <Tooltip title='Remove Filter' followCursor={true}>
                              <DeleteOutlineIcon sx={dialogStyles.tableRowActionButton}/>
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Paper>

          </Grid>
          }

        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep + 1); }}>Next</Button>
        </Box>
      </Box>
    );
  }

  setDestinationType(type) 
  {
    switch (type) {
      case 'email':
      case 'number':
        return type;
      default:
        return 'text';
    }
  }

  renderRecipients() 
  {
    return (
      <Box sx={dialogStyles.stepContents}>
        <Box sx={styles.recipientsContents}>

          <Grid container direction={'column'} alignItems={'center'}>

            <Grid container direction={'row-reverse'} alignItems={'flex-end'} justifyContent={'flex-end'} sx={{...dialogStyles.tableHeader, width:"660px"}}>
              <Button onClick={() => {this.props.onAddRecipient()}} color={'inherit'}>
                <AddIcon sx={dialogStyles.tableActionButton}/>
                ADD
              </Button>
            </Grid>

            {/* Header */}
            <Grid container direction={'row'} alignItems={'center'} sx={{...dialogStyles.headerRow, width:"660px"}}>
              <Grid item sx={{...dialogStyles.headerCell, width: '180px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Provider</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '430px'}}>
                <Typography sx={dialogStyles.tableHeaderColumn} variant={'subtitle1'}>Destination</Typography>
              </Grid>
              <Grid item sx={{...dialogStyles.headerCell, width: '50px'}}/>
            </Grid>

            {/* Contents */}
            <Paper sx={{...dialogStyles.table, width:"660px"}}>
              <Grid container>
                {
                  this.props.recipients.map((recipient) => {
                    return (
                      <Grid container key={recipient.id} alignItems={'center'} sx={{minHeight:'49px'}}>
                        <Grid item sx={{...dialogStyles.tableCellLeftMost, width: '180px', height: '100%'}}>
                          {/* Provider column */}
                          <AutoCompleteMDT
                            getOptionLabel={(provider) => provider.label}
                            options={this.props.availableProviders}
                            value={recipient.provider}
                            onChange={(event, value, reason) => {
                              this.props.onSelectProvider(value, recipient.id);
                            }}
                            noOptionsText={'No providers found...'}
                          />
                        </Grid>  
                        <Grid item sx={{...dialogStyles.tableCell, width: '430px', height: '49px'}}>
                          {/* Destination column */}
                          {
                            (!_.isEmpty(recipient.availableDestinations)) &&
                            <AutoCompleteMDT
                              getOptionLabel={(availableDestination) => availableDestination.label}
                              options={recipient.availableDestinations}
                              value={recipient.destination}
                              onChange={(event, value, reason) => {
                                this.props.onSetDestination(value, recipient.id)
                              }}
                              noOptionsText={'No destinations found...'}
                            />
                          }
                          {
                            (_.isEmpty(recipient.availableDestinations)) &&
                            <Tooltip title={_.isEmpty(recipient.destination) ? '' : recipient.destination} followCursor={true}>
                              <TextField 
                                sx={{paddingTop: '5px'}} 
                                variant='standard' 
                                size="small" 
                                fullWidth 
                                value={recipient.destination}
                                onChange={event => this.props.onSetDestination(event.target.value, recipient.id)}
                                inputProps={{maxLength:60}}
                                InputProps={{disableUnderline: true }}
                                type={(_.isNil(recipient.provider) ? 'text' : this.setDestinationType(recipient.provider.type))} 
                              />
                            </Tooltip>
                          }
                        </Grid>
                        <Grid item sx={{...dialogStyles.tableActionCell, width: '50px', height: '100%'}}>
                          {/* Action column */}
                          <IconButton
                            onClick={(event) => { this.props.onRemoveRecipient(recipient.id); }}
                            size='small'>
                            <Tooltip title='Remove Destination' followCursor={true}>
                              <DeleteOutlineIcon sx={dialogStyles.tableRowActionButton}/>
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Paper>

          </Grid>

        </Box>
        <Box sx={dialogStyles.stepActionContents}>
          <Button sx={dialogStyles.actionButton} variant={'contained'} onClick={() => { this.props.onSetActiveStep(this.props.activeStep - 1); }}>Prev</Button>
          <Button variant={'contained'} color={'primary'} disabled>Next</Button>
        </Box>
      </Box>
    );  
  }

  renderStep(label, index) {
    switch (index) {
      case 0:
        return this.renderNameSubscription();
      case 1:
        return this.renderSelectEvent();
      case 2:
        return this.renderFilterConditions();
      case 3:
        return this.renderRecipients();
      default:
        return {label};
    }
  }

  mapErrorToStep(index) {
    switch (index) {
      case 0:
        return this.props.errorSubscriptionName;
      case 1:
        return this.props.errorEventType;
      case 2:
        return this.props.errorFilters;
      case 3:
        return this.props.errorRecipients;
      default:
        return false;
    }
  }

  render() {
    return (
      <Dialog open={this.props.showDialog} maxWidth={'lg'} maxLength={'lg'} disableEscapeKeyDown>
        <DialogTitle>
          <Box sx={dialogStyles.dialogTitleContainer}>
            {
              (!_.isNil(this.props.context) && (this.props.entity === subscriptionEntity.SUBSCRIPTIONS)) &&
              <Typography variant={'subtitle1'}>Edit Notification</Typography>
            }
            {
              (_.isNil(this.props.context) ||  (!_.isNil(this.props.context) && (this.props.entity !== subscriptionEntity.SUBSCRIPTIONS))) &&
              <Typography variant={'subtitle1'}>Create Notification</Typography>
            }
          </Box>
        </DialogTitle>
        <DialogContent sx={{...dialogStyles.dialogContent, width:"750px"}}>
          <Stepper nonLinear orientation={'vertical'} activeStep={this.props.activeStep}>
            {
              steps.map((label, index) =>
              {
                return (
                  <Step key={label}>
                    <StepButton color='inherit' onClick={() => { this.props.onSetActiveStep(index)}}>
                      <StepLabel error={this.mapErrorToStep(index)}>
                        {label}
                      </StepLabel>
                    </StepButton>
                    <StepContent>
                      { this.renderStep(label, index) }
                    </StepContent>
                  </Step>
                )
              })
            }
          </Stepper>
        </DialogContent>
        <DialogActions sx={dialogStyles.buttonsContainer}>
          <Button sx={dialogStyles.actionButton} variant={'contained'}
            onClick={() => { 
              this.props.onClearForm();
              this.props.onShowSubscriptionDialog(false, this.props.context)
              }
            }>Cancel</Button>
          <Button variant={'contained'} color={'primary'} 
                  onClick=
                  {() => 
                    {  
                      this.props.onSaveSubscription(
                        this.props.context, 
                        this.props.entity, 
                        this.props.user.email,
                        this.props.subscriptionName, 
                        this.props.eventType.id, 
                        (_.isNil(this.props.context) || _.isNil(this.props.context.active)) ? true : this.props.context.active,
                        this.props.filters, 
                        this.props.recipients); 
                      this.props.onClearForm();
                      this.props.onShowSubscriptionDialog(false, this.props.context); 
                    }
                  }
                  disabled={!this.props.canSave}>Save</Button>
        </DialogActions>

        <Progress open={this.props.queryRunning}/>
      </Dialog>

    );
  }
}

SubscriptionDialog.propTypes = {
  entity: PropTypes.string,
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SUBSCRIPTION_DIALOG : props.stateKey,
      type: ComponentTypes.SUBSCRIPTION_DIALOG,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;

  let applicationState = appState(state);
  let componentState = subscriptionDialogState(state[stateDef.key]);

  return {
    user: applicationState.user,

    context: applicationState.notifications.subscriptionDialogContext,
    showDialog: applicationState.notifications.showSubscriptionDialog,

    queryRunning: componentState.queryRunning,
    canSave: componentState.canSave,
    errorSubscriptionName: componentState.errorSubscriptionName,
    errorEventType: componentState.errorEventType,
    errorFilters: componentState.errorFilters,
    errorRecipients: componentState.errorRecipients,
    activeStep: componentState.activeStep,

    subscriptionName: componentState.subscriptionName,

    allEvents: componentState.allEvents,
    availableEventTypes: componentState.availableEventTypes,
    event: componentState.event,
    eventType: componentState.eventType,
    canSelectEvent: componentState.canSelectEvent,
    canSelectEventType: componentState.canSelectEventType,

    filters: componentState.filters,
    availableFilterAttributes: componentState.availableFilterAttributes,

    recipients: componentState.recipients,
    availableProviders: componentState.availableProviders
  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onQueryData: (context, entity, showPreReleaseContent) => { dispatch(subscriptionDialogActions.queryData(props.stateDef, context, entity, showPreReleaseContent)); },
    onShowSubscriptionDialog: (show, context) => { dispatch(notificationsActions.showSubscriptionDialog(show, context)); },
    onSetActiveStep: (step) => { dispatch(subscriptionDialogActions.setActiveStep(props.stateDef, step)); },
    
    onSetSubscriptionName: (name) => { dispatch(subscriptionDialogActions.setSubscriptionName(props.stateDef, name)); },

    onSelectEvent: (event) => { dispatch(subscriptionDialogActions.selectEvent(props.stateDef, event)); },
    onSelectEventType: (eventType) => { dispatch(subscriptionDialogActions.selectEventType(props.stateDef, eventType)); },

    onAddFilter: () => { dispatch(subscriptionDialogActions.addFilter(props.stateDef)); },
    onRemoveFilter: (id) => { dispatch(subscriptionDialogActions.removeFilter(props.stateDef, id)); },
    onSelectFilterAttribute: (value, id) => { dispatch(subscriptionDialogActions.selectFilterAttribute(props.stateDef, value, id)); },
    onSelectFilterOperator: (value, id) => { dispatch(subscriptionDialogActions.selectFilterOperator(props.stateDef, value, id)); },
    onSetFilterValue: (value, id) => { dispatch(subscriptionDialogActions.setFilterValue(props.stateDef, value, id)); },
    onSetFilterMultiValues: (value, id) => { dispatch(subscriptionDialogActions.onSetFilterMultiValues(props.stateDef, value, id)); },

    onAddRecipient: () => { dispatch(subscriptionDialogActions.addRecipient(props.stateDef)); },
    onRemoveRecipient: (id) => { dispatch(subscriptionDialogActions.removeRecipient(props.stateDef, id)); },
    onSelectProvider: (value, id) => { dispatch(subscriptionDialogActions.selectProvider(props.stateDef, value, id)); },
    onSetDestination: (value, id) => { dispatch(subscriptionDialogActions.setDestination(props.stateDef, value, id)); },

    onSaveSubscription: (context, entity, user, name, eventId, active, filters, recipients) => { dispatch(subscriptionsDisplayActions.saveSubscription(props.stateDef, context, entity, user, name, eventId, active, filters, recipients)); },

    onClearForm: () => { dispatch(subscriptionDialogActions.clearForm(props.stateDef)); }
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(SubscriptionDialog));