import {performCardFetch} from "../../common/fetch";
import useSimulationData from "../../../helpers/simulationHelper";
import * as auditLogSimulation from "./auditLogSimulation";

const fetchFleetAuditLogData = (datavanPid, startTime, endTime, pageable) => {
    if (useSimulationData('auditLogCard')) {
      return auditLogSimulation.fetchAuditLogData(true);
    }
    // for cardDataService it does not need to support complicate queries
    const input = {
        'datavanPid': datavanPid, // auditLog bigQuery table only stores datavanPid and datavanName but no datavanId, so use datavanPid instead of datavanId
        'startTime': startTime,
        'endTime': endTime,
        'pageable': pageable
    }
    // TODO: When start on story for fleet auditLog card, make sure cardDataService implemented fleetAuditLog query
    return performCardFetch({
        'query': 'query fleetAuditLog($input:FleetAuditLogInput!) {fleetAuditLog(input: $input) {datavanPid datavanName truckPid truckName actionType detail newState oldState source subType uiComponent timestamp}}',
        'variables' : {
            'input': input
        }
    })
};

const fetchPumpAuditLogData = (truckPid, startTime, endTime, pageable) => {
  if (useSimulationData('auditLogCard')) {
    return auditLogSimulation.fetchAuditLogData(false);
  }
  // for cardDataService it does not need to support complicate queries
  const input = {
      'truckPid': truckPid, // auditLog bigQuery table only stores truckPid and truckName but no truckId, so use truckPid instead of truckId
      'startTime': startTime,
      'endTime': endTime,
      'pageable': pageable
  }

  return performCardFetch({
    'query': 'query unitAuditLog($input:UnitAuditLogInput!) {unitAuditLog(input: $input) {datavanPid datavanName truckPid truckName actionType detail newState oldState source subType uiComponent timestamp}}',
      'variables' : {
          'input': input
      }
  })
};


export {
  fetchFleetAuditLogData,
  fetchPumpAuditLogData
}