import _ from 'lodash';

const deviceDialogState = state => {

  return {

    deviceName: _.isNil(state) ? null : state.deviceName,
    deviceOwner: _.isNil(state) ? {label:"MDT", value: 1} : state.deviceOwner, //default to set as MDT owner
    
    canSave: _.isNil(state) ? false : state.canSave,
    errorDeviceName: _.isNil(state) ? false : state.errorDeviceName,
    errorDeviceOwner: _.isNil(state) ? false : state.errorDeviceOwner
  };
  
};

export {
  deviceDialogState
};