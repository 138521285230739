import _ from "lodash";

const fetchExpectedLifeSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        expectedLifeSettings: {
          componentTypes: testComponentTypes
        }
      })
    }, 1000)
  });
};

const fetchSaveExpectedLifeSettings = (componentTypes) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      for (let i=0; i<componentTypes.length; i++) {
        let current = componentTypes[i];
        let original = _.find(testComponentTypes, {'id': current.id});
        original.expectedLife = current.expectedLife;
      }
      resolve({
        result: true
      })
    }, 1000)
  });
};

let testComponentTypes = [
  { id:0, name: 'Engines', displayName: 'Engine', unitType: 'FracPumper', kind: 'Engine', expectedLife: 1000 },
  { id:1, name: 'Transmissions', displayName: 'Tranny', unitType: 'FracPumper', kind: 'Transmission', expectedLife: 2000 },
  { id:2, name: 'Fluid Ends', displayName: 'Fluid End', unitType: 'FracPumper', kind: 'FluidEnd', expectedLife: 3000 },
  { id:3, name: 'Power Ends', displayName: 'Power End', unitType: 'FracPumper', kind: 'PowerEnd', expectedLife: 4000 },


  { id:4, name: 'Component1', displayName: 'Component1', unitType: 'UnitType1', kind: 'Engine', expectedLife: 1000 },
  { id:5, name: 'Component2', displayName: 'Component2', unitType: 'UnitType1', kind: 'Transmission', expectedLife: 2000 },
  { id:6, name: 'Component3', displayName: 'Component3', unitType: 'UnitType1', kind: 'FluidEnd', expectedLife: 3000 },

  { id:7, name: 'Component1', displayName: 'Component1', unitType: 'UnitType2', kind: 'Engine', expectedLife: 1000 },
  { id:8, name: 'Component2', displayName: 'Component2', unitType: 'UnitType2', kind: 'Transmission', expectedLife: 2000 },
  { id:9, name: 'Component3', displayName: 'Component3', unitType: 'UnitType2', kind: 'FluidEnd', expectedLife: 3000 },
  { id:10, name: 'Component4', displayName: 'Component4', unitType: 'UnitType2', kind: 'PowerEnd', expectedLife: 3000 },

];

export {
  fetchExpectedLifeSettings,
  fetchSaveExpectedLifeSettings
}