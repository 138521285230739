export default {
    SET_ROWS_PER_PAGE:'SET_ROWS_PER_PAGE',
    QUERY_WELL_DATA_STARTING: 'QUERY_WELL_DATA_STARTING',
    QUERY_WELL_DATA_SUCCESS: 'QUERY_WELL_DATA_SUCCESS',
    QUERY_WELL_DATA_ERROR: 'QUERY_WELL_DATA_ERROR',
    SAVE_WELL_DIALOG_OPEN: 'SAVE_WELL_DIALOG_OPEN',
    SAVE_WELL_DIALOG_CLOSE: 'SAVE_WELL_DIALOG_CLOSE',
    CHANGE_WELL: 'CHANGE_WELL',
    VALIDATE_CHANGE_WELL: 'VALIDATE_CHANGE_WELL',
    OPEN_DELETE_DIALOG: 'OPEN_DELETE_DIALOG',
    CLOSE_DELETE_DIALOG: 'CLOSE_DELETE_DIALOG',
    SAVE_WELL_DATA_STARTING: 'SAVE_WELL_DATA_STARTING',
    SAVE_WELL_DATA_SUCCESS: 'SAVE_WELL_DATA_SUCCESS',
    SAVE_WELL_DATA_ERROR: 'SAVE_WELL_DATA_ERROR',
    DELETE_WELL_DATA_STARTING: 'DELETE_WELL_DATA_STARTING',
    DELETE_WELL_DATA_SUCCESS: 'DELETE_WELL_DATA_SUCCESS',
    DELETE_WELL_DATA_ERROR: 'DELETE_WELL_DATA_ERROR',
    DELETE_WELL_ERROR_WELL_IN_USE: 'DELETE_WELL_ERROR_WELL_IN_USE',
}