import { performDisplayFetch } from '../../common/fetch';
import * as assetTrackingSimulation from './assetTrackingSimulation';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchDevicesForOwner = () => {
  if (useSimulationData('assetTracking')) {
    return assetTrackingSimulation.fetchDevicesForOwner();
  }

  return performDisplayFetch(
    {
      'query': 'query DevicesForOwner { devicesForOwner { id ownerId ownerName truckPid truckName esn active unitType lastKnownCoord { latitude longitude } lastPolledTime } }',
    }
  )
}

const fetchLocations = () => {

  if (useSimulationData('assetTracking')) {
    return assetTrackingSimulation.fetchLocations();
  }

  return performDisplayFetch(
    {
      'query': 'query LocationsForOwner { locationsForOwner { id name type coordinates { latitude longitude } radius } }',
    });
}

const fetchSaveLocations = (locations) => {

  return performDisplayFetch(
    {
      'query': 'mutation SaveLocations($locations: [LocationInput!]!) { saveLocations(locations: $locations) { id name type coordinates { latitude longitude } radius } }',
      'variables': {
        'locations': locations
      }
    });

}

const fetchDeleteLocation = (locationId) => {

  return performDisplayFetch(
    {
      'query': 'mutation DeleteLocation($locationId: Long!) { deleteLocation(locationId: $locationId) { success message } }',
      'variables': {
        'locationId': locationId
      }
    });
}

export {
  fetchDevicesForOwner,
  fetchLocations,
  fetchSaveLocations,
  fetchDeleteLocation
}