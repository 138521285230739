import dgbFleetMapActionTypes from '../dgbFleetMapActionTypes';
import * as dgbViewService from '../services/dgbViewService';

const queryReducer = (state, action) => {

  switch (action.type) {

    case dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_STARTING:
      return onQueryDetailsStarting(state, action);

    case dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_SUCCESS:
      return onQueryDetailsSuccess(state, action);

    case dgbFleetMapActionTypes.DGB_FLEET_MAP_QUERY_DETAILS_ERROR:
      return onQueryDetailsError(state, action);

    default:
      return state;

  }
};

const  onQueryDetailsStarting = (state, action) => {

  return {
    ...state,
    queryRunning: true,
  };
};

const onQueryDetailsSuccess = (state, action) => {

  return {
    ...state,
    ...dgbViewService.processDetails(action.queryResults),
    queryRunning: false,
  };

};

const  onQueryDetailsError = (state, action) => {

  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer