import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import unitHoursActionTypes from './unitHoursActionTypes';

import fetchUnitHoursCardData from './unitHoursQueries';
import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(unitHoursActionTypes.UNIT_HOURS_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
  unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_STARTING,
  unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_SUCCESS,
  unitHoursActionTypes.UNIT_HOURS_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_UNIT_HOURS_CARD_DATA,
  fetchUnitHoursCardData
);

const metricIndexChanged = defaultActionFactory(unitHoursActionTypes.UNIT_HOURS_METRIC_INDEX_CHANGED, 'stateDef', 'index');

export {
  queryData,
  clearData,
  metricIndexChanged
}