export default {
  SYSTEM_SOFTWARE_QUERY_DATA_STARTING: 'SYSTEM_SOFTWARE_QUERY_DATA_STARTING',
  SYSTEM_SOFTWARE_QUERY_DATA_SUCCESS: 'SYSTEM_SOFTWARE_QUERY_DATA_SUCCESS',
  SYSTEM_SOFTWARE_QUERY_DATA_ERROR: 'SYSTEM_SOFTWARE_QUERY_DATA_ERROR',
  SYSTEM_SOFTWARE_REFRESH_UPLOAD_TIME: 'SYSTEM_SOFTWARE_REFRESH_UPLOAD_TIME',
  SYSTEM_SOFTWARE_SET_IS_COMPUTER_SOFTWARE: 'SYSTEM_SOFTWARE_SET_IS_COMPUTER_SOFTWARE',
  SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_STARTING: 'SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_STARTING',
  SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_SUCCESS: 'SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_SUCCESS',
  SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_ERROR: 'SYSTEM_SOFTWARE_EXPORT_BY_TRUCK_NAME_ERROR',
  SET_ROW_SIZE: 'SET_ROW_SIZE'
};