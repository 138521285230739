import React, {Component} from 'react';
import { Box } from '@mui/material';
import fleetMapStyles from '../fleetMapStyles';

const styles = {
  ...fleetMapStyles,
};

class BlankUnit extends Component {
  render() {
    return (
      <Box sx={styles.unitMapContainer}/>
    );
  }
}

export default BlankUnit;