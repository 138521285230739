import _ from 'lodash';

const fetchFleetVibrationCardData = (truckId) => {
  return new Promise((resolve) => {

    let totalPumps = 40;

    setTimeout(() => {
      resolve({
        fleetVibration: {
          powerEnd: (_.random(1, 10) % 2 === 0) ? [] : generateData(totalPumps),
          fluidEnd: (_.random(1, 10) % 2 === 0) ? [] : generateData(totalPumps),
          iron: (_.random(1, 10) % 2 === 0) ? [] : generateData(totalPumps),
          manifold: (_.random(1, 10) % 2 === 0) ? [] : generateData(totalPumps),
        }
      })
    }, 1000)
  });

};

const generateData = (pumps) => {

  let data = [];
  let i;
  for (i = 0; i < pumps; i++) {
    // Sometimes push an empty object to show an empty slot
    if (_.random(1, 40) % 5 === 0) {
      data.push(null);
    } else {
      data.push(_.random(1, 40) % 3 === 0 ? 40 : _.random(1, 40));
    }
  }

  return data;

};

export { fetchFleetVibrationCardData }