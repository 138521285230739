import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';
import ComponentTypes from '../../componentTypes';
import UnitChart from './common/unitChart';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";
import * as unitChartActions from '../../../state/cards/unitChart/unitChartActions';
import { unitChartState } from '../../../state/cards/unitChart/unitChartSelectors';
import unitChartTypes from '../../../state/cards/unitChart/unitChartTypes';
import { mdtPalette } from "../../common/styles/mdtPalette";
import * as validationHelpers from '../../../helpers/validationHelper';

class UnitChartVibrationHistory extends MDTCard {

  getName(){
    return 'VIBRATION HISTORY';
  }

  isContextReady(){
      return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck) && !_.isNil(this.props.context.startTime && !_.isNil(this.props.context.endTime));
  }

  refresh() {
      if (!this.isContextReady()) {
          this.props.clearData();
      } else if ((validationHelpers.checkValueAgainstNotFunctions(this.props.context.startTime, [_.isNil, _.isNaN]) === true) &&
          (validationHelpers.checkValueAgainstNotFunctions(this.props.context.endTime, [_.isNil, _.isNaN]) === true)) {
          this.props.queryData(this.props.context.truck.id, this.props.context.startTime, this.props.context.endTime);
      }
  }

  getRenderedContent() {
      const cardConfig = {
          startTime: this.props.context.startTime,
          endTime: this.props.context.endTime,
          resolutionLabel: '6 Hours',
          series1: {
              ...this.props.series1,
              latestLabel: 'Power End Front',
              chartUom: 'RAW',
              color: mdtPalette().categories.category2
          },
          series4: {
              ...this.props.series4,
              latestLabel: 'Power End Rear',
              chartUom: 'RAW',
              color: mdtPalette().categories.category3
          },
          series2: {
              ...this.props.series2,
              latestLabel: 'Fluid End Front',
              chartUom: 'RAW',
              color: mdtPalette().categories.category4
          },
          series3: {
              ...this.props.series3,
              latestLabel: 'Fluid End Rear',
              chartUom: 'RAW',
              color: mdtPalette().categories.category5
          }
      };

      return (<UnitChart cardConfig={cardConfig}/>)
  }
}

UnitChartVibrationHistory.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_CHART_VIBRATION_HISTORY : props.stateKey,
            type: ComponentTypes.UNIT_CHART_VIBRATION_HISTORY,
        }
    }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = unitChartState(state[stateDef.key]);
    return {
        ...mdtCardMapStateToProps(state, props),
        series1: componentState.series1,
        series2: componentState.series2,
        series3: componentState.series3,
        series4: componentState.series4,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardMapDispatchToProps(dispatch, props),
        queryData: (truckId, startTime, endTime) => { dispatch(unitChartActions.queryData(props.stateDef, unitChartTypes.VIBRATION_HISTORY, truckId, startTime, endTime)) },
        clearData: () => { dispatch(unitChartActions.clearData(props.stateDef)) },
    }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitChartVibrationHistory));

