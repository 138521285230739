import _ from 'lodash';
import moment from 'moment';
import * as filterService from '../../../common/filtering/filterService';
import * as assetTypeService from '../../../common/services/assetTypeService.js'

const processUnits = (units, filters) => {

  // Map the units in the display data to UI contexts that can be displayed in the auto-complete control
  let contexts = prettifyUnits(units);
  // Filter the units by the current filters (in order)
  let filteredContexts = filterService.filterItems(contexts, filters);
  // Generate filter data from the filtered list
  let updatedFilters = filterService.generateFilters(filteredContexts, filterDefinitions());

  return {
    filteredUnits: filteredContexts,
    filters: updatedFilters,
  }

};

const processComponentDetails = (details) => {

  let componentTypes = prettifyComponentTypes(_.isNil(details) ? null : details.componentTypes, _.isNil(details) ? null : details.earliestData);
  let components = prettifyComponents(_.isNil(details) ? null : details.components);

  return {
    componentTypes: componentTypes,
    components: components,
  };
};

const prettifyUnits = (units) => {

  let results = [];

  if (!_.isNil(units) && !_.isEmpty(units)) {
    results = _.orderBy(units, 'name').map(unit => {
      return {
        ...unit,
        displayValueType: assetTypeService.getAssetNameByType(unit.type),
        value: unit.name,
        label: unit.name,
      }
    });
  }

  return results;
};


const prettifyComponentTypes = (componentTypes, earliestData) => {

  let results = [];

  if (!_.isNil(componentTypes) && !_.isEmpty(componentTypes)) {
    results = _.map(componentTypes, componentType => {
      let earliestDataRecord = _.find(earliestData, {'id': componentType.id});
      let earliestDataMoment = _.isNil(earliestDataRecord) ? null : moment.unix(earliestDataRecord.earliestData);
      return {
        ...componentType,
        value: componentType.name,
        label: componentType.name,
        earliestDataMoment: earliestDataMoment,
      }
    });
    results = _.orderBy(results, 'label');
  }

  return results;
};

const prettifyComponents = (components) => {

  let results = [];

  if (!_.isNil(components) && !_.isEmpty(components)) {
    results = _.map(components, component => {
      let effectiveDateMoment = _.isNil(component.effectiveDate) ? null : moment.unix(component.effectiveDate);
      return {
        ...component,
        effectiveDateMoment: effectiveDateMoment,
      }
    });
  }

  return results;
};

const resolveSelectedUnit = (units, unitId) => {

  let selectedUnit = null;

  // Validate that we have a unit with the requested id
  if (!_.isNil(unitId) && !_.isEmpty(units)) {
    let match = _.find(units, {'id': unitId});
    selectedUnit = _.isNil(match) ? null : match;
  }

  // If we did not resolve a selected unit, but we do have units, set it to the first element.
  if (_.isNil(selectedUnit) && !_.isEmpty(units)) {
    selectedUnit = units[0];
  }

  return selectedUnit;
};

const resolveSelectedComponent = (components, componentType) => {

  let selectedComponent = null;

  if (!_.isNil(componentType) && !_.isEmpty(components)) {
    let match = _.find(components, {'componentType': componentType.name});
    selectedComponent = _.isNil(match) ? null : match;
  }

  return selectedComponent;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createFilterDefinition('Type', 'displayValueType'));
  definitions.push(filterService.createFilterDefinition('Unit ID', 'name'));
  return definitions;
};

export {
  processUnits,
  processComponentDetails,
  prettifyUnits,
  prettifyComponentTypes,
  prettifyComponents,
  resolveSelectedUnit,
  resolveSelectedComponent,
}