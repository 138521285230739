import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';

import { TextField, Button, InputAdornment, Box } from '@mui/material';
import NumberFormat from 'react-number-format';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import * as warmStartActions from '../../../../state/displays/settings/actions/warmStartActions';
import { warmStartState } from '../../../../state/displays/settings/selectors/warmStartSelectors';
import ComponentTypes from '../../../componentTypes';

import { trackPage } from '../../../../helpers/googleAnalyticsHelper';
import MDTDataGrid from "../../../common/table/MDTDataGrid";

const styles = {
  formContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '800px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '620px',
    padding: 3
  },
  formLabel: {
    marginTop: '4px'
  },
  formActionRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: 3,
    justifyContent: 'flex-end'
  },
  fieldsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  formField: {
    width: '260px'
  },
  truckConfigContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '800px',
    paddingTop: 3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  truckConfigDataGrid: {
    width: '100%',
  },
  rightSideAction: {
    marginLeft: 3
  },
  avgFuelCostFormFieldLabel: {
    width: '21%'
  }
};

class WarmStartSettings extends Component {

  componentDidMount() {
    trackPage(ComponentTypes.WARM_START_SETTINGS, this.props.user);

    this.props.queryWarmStartSettings();
    this.props.queryTruckWarmStartSettings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryWarmStartSettings();
      this.props.queryTruckWarmStartSettings();
    }
  }

  render() {
    const columns = [
      {
        field: 'truckName',
        headerName: 'Unit Number',
        headerAlign: 'center',
        align: 'center',
        width: 240,
        editable: false,
        hideable: false,
        pinnable: false      //Set to not allowing this column to change pinning state
      },
      {
        field: 'truckEngineModel',
        headerName: 'Engine Model',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        editable: false,
      },
      {
        field: 'avgIdelFuelConsumption',
        headerName: 'Avg Idle Fuel Consumption - GPH',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 1,
        editable: true,
      }
    ];
    const initialState = {
      pinnedColumns: {
        left:["truckName"]    //Set init pinned columns
      }
    };
    const handleRowEditSaving = (model) => {
      let selectedTruckSetting = _.find(this.props.warmStartTruckSettings, {truckId: model.id})
      if(selectedTruckSetting && selectedTruckSetting.avgIdelFuelConsumption !== model.value){
        this.props.saveWarmStartTruckSettings(selectedTruckSetting.truckId, model.value);
      }
    };
    return (
        <Box sx={styles.formContainer}>
          <Box sx={styles.form}>
            <Box sx={styles.fieldsContainer}>
              <NumberFormat
                  key={1}
                  variant='standard'
                  sx={styles.formField}
                  customInput={TextField}
                  value={this.props.averageFuelCost}
                  inputMode="numeric"
                  thousandSeparator={true}
                  allowNegative={false}
                  prefix="$"
                  decimalScale="2"
                  onValueChange={(values) => {
                    const { value } = values;
                    this.props.setAverageFuelCost(value)
                  }}
                  helperText={'Average Fuel Cost'}
                  error={this.props.avgFuelCostHasError}
                  InputProps={{
                    endAdornment: <InputAdornment sx={styles.avgFuelCostFormFieldLabel} position="end">per gal</InputAdornment>,
                    inputProps: {
                      max:100000,
                      min:0
                    }
                  }}
              />
            </Box>
            <Box sx={styles.formActionRow}>
              <Button
                  sx={{...styles.rightSideAction,
                    backgroundColor:'grey.200',
                    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
                    '&:hover': {
                      backgroundColor:'grey.400',
                      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
                    }
                  }}
                  variant='contained'
                  disabled={!this.props.warmStartCanSave}
                  onClick={() => this.props.revertForm()}>
                Discard Changes
              </Button>
              <Button
                  sx={styles.rightSideAction}
                  variant='contained'
                  color='primary'
                  disabled={!this.props.warmStartCanSave}
                  onClick={() => {
                    this.props.saveWarmStartSettings(this.props.averageFuelCost);
                  }
                  }>
                Save
              </Button>
            </Box>
          </Box>
          <Box sx={styles.truckConfigContainer}>
            <Box sx={styles.truckConfigDataGrid}>
              <MDTDataGrid
                  getRowId={row => row.truckId}
                  rows={this.props.warmStartTruckSettings}
                  columns={columns}
                  onCellEditCommit={handleRowEditSaving}
                  disableSelectionOnClick
                  initialState={initialState}
                  exportFileName={'warmStart_setting_export'}
                  stateDef={this.props.stateDef}
                  rowsPerPage={this.props.rowsPerPage}
                  setRowsPerPage={this.props.setRowsPerPage}
                  rowsPerPageOptions={this.props.rowsPerPageOptions}
              />
            </Box>
          </Box>
        </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = warmStartState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    averageFuelCost: componentState.warmStart.averageFuelCost,
    originalAverageFuelCost: componentState.warmStart.originalAverageFuelCost,
    warmStartCanSave: componentState.warmStart.warmStartCanSave,
    avgFuelCostHasError: componentState.warmStart.avgFuelCostHasError,
    warmStartTruckSettings: componentState.warmStart.warmStartTruckSettings,
    rowsPerPage: componentState.warmStart.rowsPerPage,
    rowsPerPageOptions: componentState.warmStart.rowsPerPageOptions,
    user: appState.user
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryWarmStartSettings: () => { dispatch(warmStartActions.queryWarmStartSettings(props.stateDef)) },
    queryTruckWarmStartSettings: () => { dispatch(warmStartActions.queryTruckWarmStartSettings(props.stateDef)) },
    saveWarmStartSettings: (averageFuelCost) => { dispatch(warmStartActions.saveWarmStartSettings(props.stateDef, averageFuelCost)) },
    setAverageFuelCost: (averageFuelCost) => { dispatch(warmStartActions.setAverageFuelCost(props.stateDef, averageFuelCost)) },
    revertForm: () => { dispatch(warmStartActions.revertForm(props.stateDef)) },
    saveWarmStartTruckSettings: (truckId, averageFuelConsumption) => { dispatch(warmStartActions.saveWarmStartTruckSettings(props.stateDef, truckId, averageFuelConsumption)); },
    setRowsPerPage: (rowSize) => { dispatch(warmStartActions.dataGridSetRowSize(props.stateDef, rowSize)); },
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(WarmStartSettings));