import _ from 'lodash';

const checkValueAgainstFunctions = (value, functions) => {
  let result = true;
  _.forEach(functions, 
    function(func) {
      result = result && func(value);
  });

  return result;
}

const checkValueAgainstNotFunctions = (value, functions) => {
  let result = true;
  _.forEach(functions, 
    function(func) {
      result = result && !func(value);
  });

  return result;
}

export {
  checkValueAgainstFunctions, 
  checkValueAgainstNotFunctions,
}