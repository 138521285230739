import _ from 'lodash';

import filterActionTypes from '../../../common/filtering/filterActionTypes';
import * as filterService from '../../../common/filtering/filterService';
import * as jobOverviewServices from '../services/jobOverviewServices';
import {processJobs} from "../services/jobOverviewServices";

const filterReducer = (state, action) => {

  switch (action.type) {

    case filterActionTypes.FILTERS_OPEN_FILTER_DIALOG:
      return { ...state, ...filterService.onOpenFilterDialog(state, action) };

    case filterActionTypes.FILTERS_CLOSE_FILTER_DIALOG:
      return { ...state, ...filterService.onCloseFilterDialog(state, action) };

    case filterActionTypes.FILTERS_ADD_FILTER:
      return onAddFilter(state, action);

    case filterActionTypes.FILTERS_DELETE_FILTER:
      return onDeleteFilter(state, action);

    case filterActionTypes.FILTERS_SHOW_FILTER_TYPES:
      return { ...state, ...filterService.onShowFilterTypes(state, action) };

    case filterActionTypes.FILTERS_SEARCH_FILTER_VALUES:
      return { ...state, ...filterService.onSearchFilterValues(state, action) };

    case filterActionTypes.FILTERS_SET_APPLIED_FILTERS:
      return onSetAppliedFilters(state, action);

    default: return state;

  }
};

const onAddFilter = (state, action) => {

  // No filter? Just return the old state
  if (_.isNil(action.filter)) {
    return state;
  }

  // Let the common filter service handle the filter state.
  let filterState = filterService.onAddFilter(state, action);

  // If this is just a dialog navigation down from a filter type, we do not need to do anything else.
  if (action.filter.type === 'filterType') {
    return {
      ...state,
      ...filterState,
    };
  }

  // Otherwise we need to reprocess the fleets to reflect the new applied filters
  let filteredJobs = jobOverviewServices.filterJobs(state.jobs, filterState.appliedFilters);

  //The currently selected jobs may have been filtered out as a result of the newly applied filters
  let resolvedSelectedJob = jobOverviewServices.resolveSelectedJob(
    filteredJobs.filteredJobs,
    _.isNil(state.selectedJob) ? null : state.selectedJob.value
  );

  return {
    ...state,
    ...filterState,
    filters: filteredJobs.filters,
    filteredJobs: processJobs(filteredJobs.filteredJobs),
    selectedJob:  resolvedSelectedJob.selectedJob,
    selectedJobInfo: resolvedSelectedJob.selectedJobInfo
  };
};

const onDeleteFilter = (state, action) => {

  // No filter index? Just return the old state
  if (_.isNil(action.index) || action.index < 0) {
    return state;
  }

  // Let the common filter service handle the filter state.
  let filterState = filterService.onDeleteFilter(state, action);

  // Otherwise we need to reprocess the fleets to reflect the new applied filters
  let filteredJobs = jobOverviewServices.filterJobs(state.jobs, filterState.appliedFilters);

  //The currently selected jobs may have been filtered out as a result of the newly removed filters
  let resolvedSelectedJob = jobOverviewServices.resolveSelectedJob(
    filteredJobs.filteredJobs,
    _.isNil(state.selectedJob) ? null : state.selectedJob.value
  );

  return {
    ...state,
    ...filterState,
    filteredJobs: processJobs(filteredJobs.filteredJobs),
    filters: filteredJobs.filters,
    selectedJob: resolvedSelectedJob.selectedJob,
    selectedJobInfo: resolvedSelectedJob.selectedJobInfo
  };
};

const onSetAppliedFilters = (state, action) => {

  return {
    ...state,
    appliedFilters: action.appliedFilters
  };

}

export default filterReducer