import _ from 'lodash';

const ownerManagementState = state => {
  return {

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    owners: _.isNil(state) ? [] : state.owners,

    selectedOwner: _.isNil(state) ? null : state.selectedOwner,

    selectedSetting: _.isNil(state) ? 1 : state.selectedSetting,

    showCreateOwnerDialog: _.isNil(state) ? false : state.showCreateOwnerDialog,

    createOwnerName: _.isNil(state) ? '' : state.createOwnerName,

  };
};

export {
  ownerManagementState
};