import _ from 'lodash';
import * as filterService from '../../../common/filtering/filterService';
import filterTypes from "../../../../components/common/filterTypes";
import {updateFilteredItems} from "../../../common/filtering/filterService";

const processFleets = (fleets, filters, currentPage) => {

  // Filter the fleets down based on the user selected filters.
  let filteredFleets = !_.isEmpty(fleets) ? filterService.filterItems(fleets, filters) : [];


  // Sort the fleets alphabetically
  filteredFleets = _.orderBy(filteredFleets, 'name');

  // Resolve the current page of fleets. We keep track of the current page in the state instead of on
  // render as this makes it easier to trigger a state cleanup action when the page changes and
  // the react onChange event will provide us the old and new page of fleets which makes it easier to
  // determine what state properties need to be removed.
  let pagedFleets = resolveCurrentPage(filteredFleets, currentPage);

  // Generate filter data from the filtered clients list
  const updatedFilters = updateFilteredItems(filters, fleets, filteredFleets, filterDefinitions());

  return {
    filteredFleets: filteredFleets,
    filters: updatedFilters,
    pagedFleets: pagedFleets,
  };
};

const resolveCurrentPage = (fleets, currentPage) => {
  let pages = _.chunk(fleets, 12);
  // Include additional condition where the currentPage is past the number of new pages
  let index = _.isNil(currentPage) || currentPage < 0 || currentPage > (pages.length-1) ? 0 : currentPage;
  return pages.length > 0 ? pages[index] : pages;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Division', 'division'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createFilterDefinition('Fleets', 'name')); //different from 'Fleet', 'Fleets' means this filter can select multi fleets
  return definitions;
};

export {
  processFleets,
  resolveCurrentPage
}