import _ from "lodash";
import React, {Component} from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withProps } from "recompose";

import { Box, Typography, IconButton, Switch, Button } from '@mui/material';
import { GridActionsCellItem, gridClasses, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PeopleIcon from '@mui/icons-material/People';
import Progress from '../../controls/progress';
import SubscriptionDialog from '../../controls/notifications/subscriptionDialog';
import MDTDataGrid from "../../common/table/MDTDataGrid";
import ComponentTypes from '../../../components/componentTypes';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import { appState } from '../../../state/app/appSelectors';
import { subscriptionsDisplayState } from "../../../state/displays/subscriptionsDisplay/subscriptionsDisplaySelectors";
import * as subscriptionsDisplayActions from '../../../state/displays/subscriptionsDisplay/subscriptionsDisplayActions';
import subscriptionEntity from '../../common/subscriptionEntity';

import * as notificationsActions from '../../../state/app/actions/appNotificationsActions';
import DeleteSubscriptionDialog from "./deleteSubscriptionDialog";
import AssignSubscriptionDialog from "./assignSubscriptionDialog";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible',
      },          
    },
  },
};

class SubscriptionsDisplay extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadDisplayData();
  }

  loadDisplayData = () => {
    if(this.props.isUserAdmin) {
      this.props.onQueryDataForOwner();
    }else{
      this.props.onQueryDataForUser(this.props.user);
    }
  }

  customGridToolbar() {
    return (
      <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
        <GridToolbarQuickFilter sx={{width: '255px'}} />
      </GridToolbarContainer>
    )
  }

  render() {

    const columns = [
      {
        field: 'name',
        headerName: 'Notification Name',
        headerAlign: 'center',
        align: 'left',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
      },
      {
        field: 'eventEntity',
        headerName: 'Event',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
      },
      {
        field: 'eventType',
        headerName: 'Event Type',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        editable: false,
        hideable: false,
        pinnable: false,
        valueFormatter: (params) =>
        {
          if (_.isNil(params.value)) {
            return '';
          }

          return params.value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        }
      },
      {
        field: 'active',
        headerName: 'Active',
        headerAlign: 'center',
        align: 'center',
        width: 255,
        editable: false,
        hideable: false,
        pinnable: false,
        renderCell: (params) => 
        {
          const onChange = (event) => {
            event.stopPropagation();
            this.props.onSetSubscriptionActive( {...params.row, active: event.target.checked });
          }

          return <Box><Switch checked={params.value} onChange={(event) => { onChange(event); }} /></Box>
        }
      },
      {
        field: 'actions',
        align: 'center',
        width: 50,
        editable: false,
        hideable: false,
        pinnable: false,
        disableReorder: true,
        type: 'actions',
        getActions: (params) => {
          let columns = [
            <GridActionsCellItem
                icon={<EditIcon fontSize={'small'}/>}
                label="Edit"
                showInMenu
                onClick={() => { this.props.onShowSubscriptionDialog(true, params.row); }}
            />,
            <GridActionsCellItem
                icon={<DeleteForeverIcon fontSize={'small'}/>}
                label="Delete"
                showInMenu
                onClick={() => { this.props.onShowDeleteSubscriptionDialog(true, params.row); }}
            />
          ]
          if (this.props.isUserAdmin) {
            columns.push(
                <GridActionsCellItem
                    icon={<PeopleIcon fontSize={'small'}/>}
                    label="Self-Assign"
                    showInMenu
                    onClick={() => { this.props.onShowAssignSubscriptionDialog(true, params.row); }}
                />
            );
          }
          return columns;
        }

      }
    ];

    // only show the createdBy column if the user is an admin
    if (this.props.isUserAdmin) {
        // put the user column right before the actions column
        columns.splice(columns.length - 1, 0,
      {
                field: 'createdBy',
                headerName: 'User',
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                editable: false,
                hideable: false,
                pinnable: false,
            }
        );
    }

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Notifications</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton
                onClick={() => { this.loadDisplayData(); }}
                size="large">
                <RefreshIcon/>
              </IconButton>
              <Button onClick={() => { this.props.onShowSubscriptionDialog(true, null); }} color={'inherit'}>
                <NotificationAddIcon sx={{marginRight: '5px'}}/>
                CREATE NOTIFICATION
              </Button>
            </Box>
          </Box>
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
              getRowId={row => row.id}
              columns={columns}
              rows={this.props.subscriptions}
              disableSelectionOnClick
              components={{
                Toolbar: this.customGridToolbar,
              }}
              stateDef={this.props.stateDef}
              rowsPerPage={this.props.rowsPerPage}
              setRowsPerPage={this.props.setRowsPerPage}
              rowsPerPageOptions={this.props.rowsPerPageOptions}
              />
          </Box>
        </Box>
        <Progress open={this.props.queryRunning}/>
        <SubscriptionDialog entity={subscriptionEntity.SUBSCRIPTIONS} />
        <DeleteSubscriptionDialog />
        <AssignSubscriptionDialog currentUser={this.props.user} />
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SUBSCRIPTIONS_DISPLAY : props.stateKey,
      type: ComponentTypes.SUBSCRIPTIONS_DISPLAY,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;
  let applicationState = appState(state);
  let componentState = subscriptionsDisplayState(state[stateDef.key]);

  return {
    user: applicationState.user.email,
    isUserAdmin: applicationState.user.isUserAdmin,
    queryRunning: componentState.queryRunning,
    subscriptions: componentState.subscriptions,
    rowsPerPage: componentState.rowsPerPage,
    rowsPerPageOptions: componentState.rowsPerPageOptions,
  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
    onQueryDataForUser: (user) => { dispatch(subscriptionsDisplayActions.queryDataForUser(props.stateDef, user)); },
    onQueryDataForOwner: () => { dispatch(subscriptionsDisplayActions.queryDataForOwner(props.stateDef)); },
    onSetSubscriptionActive: (subscription) => { dispatch(subscriptionsDisplayActions.setSubscriptionActive(props.stateDef, subscription)); },
    onDeleteSubscription: (id) => { dispatch(subscriptionsDisplayActions.deleteSubscription(props.stateDef, id)); }, 

    onShowSubscriptionDialog: (show, context) => { dispatch(notificationsActions.showSubscriptionDialog(show, context)); },
    onShowDeleteSubscriptionDialog: (show, subscription) => { dispatch(subscriptionsDisplayActions.showDeleteSubscriptionDialog(props.stateDef, show, subscription)); },
    onShowAssignSubscriptionDialog: (show, subscription) => { dispatch(subscriptionsDisplayActions.showAssignSubscriptionDialog(props.stateDef, show, subscription)); },

    setRowsPerPage: (rowSize) => { dispatch(subscriptionsDisplayActions.dataGridSetRowSize(props.stateDef, rowSize)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(SubscriptionsDisplay));