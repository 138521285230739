import _ from 'lodash';

import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import errorMessages from '../../../common/errorMessages';
import * as navActions from "../../../app/actions/appNavActions";
import * as appUserConfigActions from '../../../app/actions/appUserConfigActions';
import * as appContextActions from '../../../app/actions/appContextActions';
import { handleError } from "../../../app/actions/appErrorActions";
import fleetMapViews from './fleetMapViews';

import ComponentTypes from '../../../../components/componentTypes';

import * as fleetMapQueries from './fleetMapQueries';
import fleetMapActionTypes from './fleetMapActionTypes';

const selectFleet = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SELECT_FLEET, 'stateDef', 'fleet');
const selectView = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SELECT_VIEW, 'stateDef', 'view');
const setNavigationContext = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_NAVIGATION_CONTEXT, 'stateDef', 'fleetId');
const onUpdateCustomViewInput = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_UPDATE_CUSTOM_VIEW_INPUT, 'stateDef', 'view', 'customViewInput');

const setTimeFrame = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTime = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_DURATION, 'stateDef', 'duration');

const queryFleetMapSummaryStarting = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_STARTING, 'stateDef');
const queryFleetMapSummarySuccess = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_SUCCESS, 'stateDef', 'queryResults');
const queryFleetMapSummaryError = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_ERROR, 'stateDef');

const queryFleetMapSummary = (stateDef) => {
  return async (dispatch, getState) => {

    // Check if there is a navigation context for this display in the app state
    const navigationState = getState().app.navigation.context;
    const navigationContext = _.isNil(navigationState['/fleet-dashboard']) ? null : navigationState['/fleet-dashboard'];
    if (!_.isNil(navigationContext)) {
      // Clear the app state if we find a navigation context
      await dispatch(navActions.setNavigationContext('/fleet-dashboard', null));
      // Set the individual context in the display state accordingly.
      await dispatch(setNavigationContext(stateDef, navigationContext.fleetId));
      // If view information came along with the navigation context the set it now.
      if (!_.isNil(navigationContext.view)) {
        await dispatch(selectView(stateDef, navigationContext.view));
      }
    }

    // Execute the query for data from the server
    let queryResults = null;
    try {
      await dispatch(queryFleetMapSummaryStarting(stateDef));
      queryResults = await fleetMapQueries.fetchFleetMapSummary();
    } catch(e) {
      await dispatch(queryFleetMapSummaryError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_FLEET_MAP_SUMMARY, e.message));
    }
    await dispatch(queryFleetMapSummarySuccess(stateDef, queryResults));

    // Trigger a set selected fleet action, this will resolve the selection appropriately
    return dispatch(selectFleet(stateDef, getState()[stateDef.key].selectedFleet));
  }
};

const navigate = (fleetId, view) => {
  return async (dispatch, getState) => {
    const url = '/fleet-dashboard';
    const context = _.isNil(fleetId) ? null : { fleetId: fleetId, view: view };
    return dispatch(navActions.navigate(url, context));
  }
};

const setCustomStartTimeDisplay = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');
const showDialog = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SHOW_DIALOG, 'stateDef', 'show');


const editDashboard = (stateDef, isEdit, dashboard) => {
  return async(dispatch, getState) => {
    await dispatch(appContextActions.setContext(ComponentTypes.FLEET_DASHBOARD, {}));
    await dispatch(appContextActions.openContextDrawer(isEdit, getState()[stateDef.key].configPanelWidth));
    await dispatch(appUserConfigActions.onEditDashboard(isEdit, dashboard));
    if (isEdit) {
      const selectedOwner = getState().app.selectedOwner.value;
      await dispatch(loadCardsList(stateDef, selectedOwner));
    } 
  }
};

const manageCustomViews = (stateDef, isManage, dashboard) => {
  return async(dispatch, getState) => {
    if(isManage){
      await dispatch(appContextActions.setContext(ComponentTypes.CONFIG_PANEL_FLEET_DASHBOARD_CUSTOM_VIEWS, {dashboard: dashboard}));
    }
    await dispatch(appContextActions.openContextDrawer(isManage, getState()[stateDef.key].customViewsConfigPanelWidth));
    await dispatch(appUserConfigActions.onManageDashboardCustomViews(isManage, dashboard));
  }
}

const undoChangesDialogOK = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
    await dispatch(editDashboard(stateDef, false));
    const userId = getState().app.user.userId;
    await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));
  }
};

const dialogCancel = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
  }
};

const saveDashboardToOwnerStarting = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_STARTING, 'stateDef');
const saveDashboardToOwnerSuccess = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_SUCCESS, 'stateDef', 'queryResults');
const saveDashboardToOwnerError = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_OWNER_ERROR, 'stateDef');

const saveDashboardToOwner = (stateDef, name) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(showDialog(stateDef, ""));

      await dispatch(saveDashboardToOwnerStarting(stateDef));

      // Only store system views 
      const configToSave = _.cloneDeep(getState().app.user.dashboards[name].views);
      _.remove(configToSave.views, function(view) {
        return view.type === "USER"
      });      

      // Setup the input object for the mutation
      let input = 
      {
        name: name,
        config: JSON.stringify(configToSave)
      }
      queryResults = await fleetMapQueries.fetchSaveOwnerDashboard(input);

      await dispatch(saveDashboardToOwnerSuccess(stateDef, queryResults));

      // After saving, reload the user's dashboard config to reset them back to where they were before
      const userId = getState().app.user.userId;
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));

      await dispatch(editDashboard(stateDef, false));
      await dispatch(manageCustomViews(stateDef, false, name));

    } catch(e) {
      await dispatch(saveDashboardToOwnerError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DASHBOARD, e.message));
    }
  }
};

const saveDashboardToUserStarting = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_STARTING, 'stateDef');
const saveDashboardToUserSuccess = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_SUCCESS, 'stateDef', 'queryResults');
const saveDashboardToUserError = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SAVE_DASHBOARD_TO_USER_ERROR, 'stateDef');

const saveDashboardToUser = (stateDef, name) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {

      await dispatch(saveDashboardToUserStarting(stateDef));

      const userId = getState().app.user.userId;
      // Setup the input object for the mutation
      let input = 
      {
        userId: userId,
        name: name,
        config: JSON.stringify(getState().app.user.dashboards[name].views)
      }
      queryResults = await fleetMapQueries.fetchSaveUserDashboard(input);

      await dispatch(saveDashboardToUserSuccess(stateDef, queryResults));

      // After saving, reload the user's dashboard config
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));

      await dispatch(editDashboard(stateDef, false));
      await dispatch(manageCustomViews(stateDef, false, name));

    } catch(e) {
      await dispatch(saveDashboardToUserError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DASHBOARD, e.message));
    }
  }
};

const loadCardsStarting = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_STARTING, 'stateDef');
const loadCardsSuccess = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_SUCCESS, 'stateDef', 'cardsList');
const loadCardsError = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_LOAD_CARDS_ERROR, 'stateDef');

const loadCardsList = (stateDef, ownerId) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(loadCardsStarting(stateDef));

      queryResults = await fleetMapQueries.fetchFleetDashboardCards(ownerId);

      await dispatch(loadCardsSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(loadCardsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LOADING_CARDS_LIST, e.message));
    }
  }
};

const onRemoveCustomView = (stateDef, dashboard, viewId) => {
  return async(dispatch, getState) => {
    const selectedView = getState()[dashboard].selectedView;
    if (viewId === selectedView.id) {
      await dispatch(selectView(stateDef, fleetMapViews.DASHBOARD));
    }
    await dispatch(appUserConfigActions.onRemoveCustomView(dashboard, viewId));
  }
};

const restoreDefaultDashboardsStarting = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_STARTING, 'stateDef');
const restoreDefaultDashboardsSuccess = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_SUCCESS, 'stateDef', 'queryResults');
const restoreDefaultDashboardsError = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_RESTORE_DEFAULTS_ERROR, 'stateDef');

const restoreDefaultDialogOK = (stateDef, dashboard, view) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(showDialog(stateDef, ""));

      await dispatch(restoreDefaultDashboardsStarting(stateDef));
      queryResults = await fleetMapQueries.fetchOwnerDefaultDashboardConfiguration();
      await dispatch(restoreDefaultDashboardsSuccess(stateDef, queryResults));

      await dispatch(appUserConfigActions.onRestoreDefaultView(dashboard, view, queryResults));

    } catch(e) {
      await dispatch(restoreDefaultDashboardsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RESTORING_DEFAULTS, e.message));
    }
  }
};

const discardCustomViewConfiguration = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_DISCARD_CUSTOM_VIEW_CONFIGURATION, 'stateDef', 'originalViewConfig');

const onDiscardCustomViewConfiguration = (stateDef) => {
  return async(dispatch, getState) => {
    const originalDashboardConfigViews = getState().app.user.dashboards[ComponentTypes.FLEET_DASHBOARD].originalConfigViews;
    await dispatch(appUserConfigActions.onDiscardCustomViewConfiguration(ComponentTypes.FLEET_DASHBOARD));
    await dispatch(discardCustomViewConfiguration(stateDef, originalDashboardConfigViews));
  }
};

const onChangeCustomViewName = (stateDef, dashboard, view, name) => {
  return async(dispatch, getState) => {
    await dispatch(appUserConfigActions.onChangeCustomViewName(dashboard, view.id, name));
    await dispatch(onUpdateCustomViewInput(stateDef, view, name));
  }
};

const onCardsQueryRunning = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_CARDS_QUERY_RUNNING, 'stateDef', 'queryRunning');
const onSetManualRefresh = defaultActionFactory(fleetMapActionTypes.FLEET_MAP_SET_MANUAL_REFRESH, 'stateDef', 'isManualRefresh');


export {
  selectFleet,
  selectView,
  onUpdateCustomViewInput, 
  queryFleetMapSummary,
  navigate,
  setNavigationContext,
  showDialog,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  setCustomStartTimeDisplay,
  editDashboard,
  manageCustomViews,
  undoChangesDialogOK, 
  dialogCancel, 
  saveDashboardToOwner, 
  saveDashboardToUser,
  restoreDefaultDialogOK, 
  loadCardsList,
  onRemoveCustomView,
  onDiscardCustomViewConfiguration, 
  onChangeCustomViewName, 
  onCardsQueryRunning,
  onSetManualRefresh
}