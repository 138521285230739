import _ from "lodash";
import errorMessages from "../../common/errorMessages";
import {
    fetchCustomersDisplayData,
    fetchDeleteCustomer,
    fetchSaveCustomer
} from "./customersDisplayQueries";
import customersDisplayActionTypes from "./customersDisplayActionTypes";
import defaultActionFactory from "../../common/factories/defaultActionFactory";
import {handleError} from "../../app/actions/appErrorActions"

//function for setting the rows per page in the table
const setRowsPerPage = defaultActionFactory(customersDisplayActionTypes.SET_ROWS_PER_PAGE, "stateDef", "rowSize");

const onSaveCustomerDialogOpen = defaultActionFactory(customersDisplayActionTypes.SAVE_CUSTOMER_DIALOG_OPEN, 'stateDef', 'customerForSave');
const onSaveCustomerDialogClose = defaultActionFactory(customersDisplayActionTypes.SAVE_CUSTOMER_DIALOG_CLOSE, 'stateDef');

const onCustomerChange = defaultActionFactory(customersDisplayActionTypes.CHANGE_CUSTOMER, 'stateDef', 'value', 'property');
const onCustomerValidateAndChange = defaultActionFactory(customersDisplayActionTypes.VALIDATE_CHANGE_CUSTOMER, 'stateDef', 'value', 'property');

const onCustomerDelete = defaultActionFactory(customersDisplayActionTypes.OPEN_DELETE_DIALOG, 'stateDef', 'customerToDelete');
const onCloseDeleteConfirmation = defaultActionFactory(customersDisplayActionTypes.CLOSE_DELETE_DIALOG, 'stateDef');

const queryCustomerDataStarting = defaultActionFactory(customersDisplayActionTypes.QUERY_CUSTOMER_DATA_STARTING, 'stateDef');
const queryCustomerDataSuccess = defaultActionFactory(customersDisplayActionTypes.QUERY_CUSTOMER_DATA_SUCCESS, 'stateDef', 'customersQueryResults');
const queryCustomerDataError = defaultActionFactory(customersDisplayActionTypes.QUERY_CUSTOMER_DATA_ERROR, 'stateDef');

const saveCustomerDataStarting = defaultActionFactory(customersDisplayActionTypes.SAVE_CUSTOMER_DATA_STARTING, 'stateDef');
const saveCustomerDataSuccess = defaultActionFactory(customersDisplayActionTypes.SAVE_CUSTOMER_DATA_SUCCESS, 'stateDef');
const saveCustomerDataError = defaultActionFactory(customersDisplayActionTypes.SAVE_CUSTOMER_DATA_ERROR, 'stateDef');

const deleteCustomerDataStarting = defaultActionFactory(customersDisplayActionTypes.DELETE_CUSTOMER_DATA_STARTING, 'stateDef');
const deleteCustomerDataSuccess = defaultActionFactory(customersDisplayActionTypes.DELETE_CUSTOMER_DATA_SUCCESS, 'stateDef');
const deleteCustomerDataError = defaultActionFactory(customersDisplayActionTypes.DELETE_CUSTOMER_DATA_ERROR, 'stateDef');

const queryCustomer = (stateDef) => {
    return async (dispatch, getState) => {
        let customersQueryResult = null;

        try {
            await dispatch(queryCustomerDataStarting(stateDef));
            customersQueryResult = await fetchCustomersDisplayData();
            await dispatch(queryCustomerDataSuccess(stateDef, customersQueryResult));
        } catch (e) {
            await dispatch(queryCustomerDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_RETRIEVING_CUSTOMERS_DISPLAY_DATA, e.message));
        }
    };
};

const onSaveCustomer = (stateDef, customer) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(saveCustomerDataStarting(stateDef));
            await fetchSaveCustomer(customer);
            await dispatch(saveCustomerDataSuccess(stateDef));
            await dispatch(queryCustomer(stateDef));
        } catch (e) {
            await dispatch(saveCustomerDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_SAVING_CUSTOMER, e.message));
        }
    };
};

const onConfirmCustomerDelete = (stateDef, customerId) => {
    return async (dispatch, getState) => {
        try {
            await dispatch(deleteCustomerDataStarting(stateDef));
            await fetchDeleteCustomer(customerId);
            await dispatch(deleteCustomerDataSuccess(stateDef));
            await dispatch(queryCustomer(stateDef));
        } catch (e) {
            await dispatch(deleteCustomerDataError(stateDef));
            return dispatch(handleError(errorMessages.ERROR_DELETE_CUSTOMER, e.message));
        }
    };
};


export {
    queryCustomer,
    onSaveCustomerDialogOpen,
    onSaveCustomerDialogClose,
    onCustomerChange,
    onCustomerDelete,
    onCloseDeleteConfirmation,
    onSaveCustomer,
    onConfirmCustomerDelete,
    setRowsPerPage,
    onCustomerValidateAndChange
};