import _ from 'lodash';

const fetchJobStageChart = (stage, startTime, endTime, sensors) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let seriesData = [];
      _.map(sensors, (sensor) => {
        let data = generateChartData(0, _.random(500,1000), startTime, endTime);
        seriesData.push(
          {
            sensorSetId: sensor.sensorSetId,
            unitOfMeasure: sensor.uom,
            values: data.values,
            timestamps: data.timestamps
          }
        )
      })

      resolve( {
        processChart: seriesData
      });
    }, 1000)
  });
}


const generateChartData = (min, max, startTime, endTime) => {

  let steps = (endTime - startTime);
  let timestamps = _.range(startTime, endTime, 1);

  // generate a random walk within the min and max for a smoother chart
  let data = [];
  data.timestamps = timestamps
  data.values = [];
  let range = min + max;
  let value = range / 2.0;
  let step = range / 100.0;
  for (let i=0; i<steps; i++) {
    value += _.random(-step, step, true);
    data.values.push(value);
  }

  return data;
};

export {
  fetchJobStageChart
}