import React, {Fragment} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import { Typography, Box, Divider } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

import ComponentTypes from '../../componentTypes';

import * as unitHoursActions from '../../../state/cards/unitHours/unitHoursActions';
import { unitHoursState } from "../../../state/cards/unitHours/unitHoursSelectors";
import { appState as applicationState } from '../../../state/app/appSelectors';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";
import { getCardFromLayoutConfigViews } from '../../common/layout/layoutHelper';
import { mdtPalette } from "../../common/styles/mdtPalette";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  ...layoutStyles,
  ...typographyStyles,
  ecmHoursLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  tcmHoursLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  pumpHoursLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  warmStartStateDisabledLabel: {
    mt: 1,
    textAlign: 'center',
    color: mdtPalette().status.level5
  },
  warmStartStateSuspendedLabel: {
    textAlign: 'center',
    color: mdtPalette().status.level4
  },
  warmStartStateEnabledLabel: {
    textAlign: 'center',
    color: mdtPalette().status.level2
  },
  warmStartStateNoValueLabel: {
    textAlign: 'center',
    color: mdtPalette().status.category0
  },
  hoursReducedLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  savingsLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  emissionsLabel: {
    textAlign: 'center',
    color: mdtPalette().categories.category0
  },
  cardRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1
  },
  cardLastRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1
  },
  resolution: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  resolutionLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  latestValuesContainer: {
    display: 'flex',
    flexFlow: 'row',
    flexGrow: 1,
    width:'100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  warmStartValuesContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  warmStartCardContent: {
    display:'flex',
    flexFlow:'column',
    height:'100%',
    width:'100%',
    justifyContent:'space-evenly',
    alignItems:'center',
  },
  warmStartValueAndLabelContainer: {
    width: '145px',
    textAlign: 'center',
  },
  divider: {
    marginLeft: 1,
    marginRight: 1,
  },
  carouselContainer: {
    width: '100%',
    minHeight: '125px',
    display: 'flex',
    flexGrow: 1,
    justifyContent:'space-evenly',
    alignItems:'center'
  },
};

/*
Fixing an issue with the Carousel and potential memory leaks regarding async state updates that
finish/are triggered after the Carousel has been unmounted.
We need it here because this Carousel interacts (onChange) with state but the one in FracFleetLayout does not.
We don't want to patch as it's an extra step required during install that we have to manage.
see https://github.com/Learus/react-material-ui-carousel/issues/44
see also https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
*/
let isUnmounting = false;

class UnitHours extends MDTCard {

  #noValue = '-';

  getName(){
    return 'UNIT HOURS';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  }

  componentDidMount() {
    super.componentDidMount();
    isUnmounting = false;
  }

  componentWillUnmount() {
    isUnmounting = true;
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck.id);
    }
  }

  formatFuelSavingValue(warmStartFuelSavings) {
    if (_.isNil(warmStartFuelSavings) || warmStartFuelSavings === this.#noValue) {
      return this.#noValue;
    }
    else {
      return '$' + warmStartFuelSavings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  getWarmStartStateLabelStyle() {
    switch (this.props.warmStartState) {
      case 'Disabled':
        return styles.warmStartStateDisabledLabel;
      case 'Suspended':
        return styles.warmStartStateSuspendedLabel;
      case 'Enabled':
        return styles.warmStartStateEnabledLabel;
    }
  }

  getRenderedLifetimeFullWidth() {
    return (
      <>
      <Box sx={styles.cardRow}>
        <Box sx={styles.resolution}>
          <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>Lifetime</Typography>
        </Box>
        <Box sx={styles.latestValuesContainer}>
          <div>
            <Typography variant={'h4'} sx={styles.ecmHoursLabel}>{this.props.ecmHours}</Typography>
            <Typography variant={'subtitle1'} sx={styles.ecmHoursLabel}>ECM Hours</Typography>
          </div>
          <div>
            <Typography variant={'h4'} sx={styles.tcmHoursLabel}>{this.props.tcmHours}</Typography>
            <Typography variant={'subtitle1'} sx={styles.tcmHoursLabel}>TCM Hours</Typography>
          </div>
          <div>
            <Typography variant={'h4'} sx={styles.pumpHoursLabel}>{this.props.pumpHours}</Typography>
            <Typography variant={'subtitle1'} sx={styles.pumpHoursLabel}>Pump Hours</Typography>
          </div>
        </Box>
      </Box>
      </>
    )
  }

  getRenderedLifetimeLessWidth() {
    return (
        <>
          <Box sx={styles.cardRow}>
            <Box sx={styles.resolution}>
              <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>Lifetime</Typography>
            </Box>
            <Box sx={{...styles.latestValuesContainer, maxWidth:'300px'}}>
              <Box sx={{ width: '145px', textAlign: 'center'}}>
                <Typography variant={'h5'} sx={styles.ecmHoursLabel}>{this.props.ecmHours}</Typography>
                <Typography variant={'caption'} sx={styles.ecmHoursLabel}>ECM Hours</Typography>
              </Box>
              <Box sx={{ width: '145px', textAlign: 'center'}}>
                <Typography variant={'h5'} sx={styles.tcmHoursLabel}>{this.props.tcmHours}</Typography>
                <Typography variant={'caption'} sx={styles.tcmHoursLabel}>TCM Hours</Typography>
              </Box>
              <Box sx={{ width: '145px', textAlign: 'center'}}>
                <Typography variant={'h5'} sx={styles.pumpHoursLabel}>{this.props.pumpHours}</Typography>
                <Typography variant={'caption'} sx={styles.pumpHoursLabel}>Pump Hours</Typography>
              </Box>
            </Box>
          </Box>
        </>
    )
  }

  getRenderContentForSizeThreeByFour() {
    return this.props.warmStartMetrics.map((metric, index) => { return(
      <Fragment key={index}>
        <Divider sx={styles.divider}/>
        <Box sx={styles.cardLastRow}>
          <Box sx={styles.resolution}>
            <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>{ metric.label }</Typography>
          </Box>
          <Box sx={styles.warmStartCardContent}>
            { index == 0 &&
              <Typography variant={'h4'} sx={this.getWarmStartStateLabelStyle()}>
                { this.props.warmStartState === this.noValue ? this.noValue : this.props.warmStartState }
              </Typography>
            }
            <Box key={index} sx={styles.warmStartValuesContainer}>
              <Box sx={styles.warmStartValueAndLabelContainer}>
                <Typography variant={'h4'} sx={styles.hoursReducedLabel}>{ metric.warmStartHrsReduced }</Typography>
                <Typography variant={'subtitle1'} sx={styles.hoursReducedLabel}>Hours Reduced</Typography>
              </Box>
              <Box sx={styles.warmStartValueAndLabelContainer}>
                <Typography variant={'h4'} sx={styles.savingsLabel}>{ this.formatFuelSavingValue(metric.warmStartFuelSavings) }</Typography>
                <Typography variant={'subtitle1'} sx={styles.savingsLabel}>Fuel Savings</Typography>
              </Box>
              <Box sx={styles.warmStartValueAndLabelContainer}>
                <Typography variant={'h4'} sx={styles.emissionsLabel}>{ metric.warmStartEmissionReduced }</Typography>
                <Typography variant={'subtitle1'} sx={styles.emissionsLabel}>Emissions Reduced</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>
    ) })
  }

  getRenderContentForSizeTwoByTwo(){
    return <>
      <Divider sx={styles.divider}/>
      <Box sx={styles.cardLastRow}>
        <Box sx={styles.resolution}>
          <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>{ this.props.warmStartMetricsLabel }</Typography>
        </Box>
        <Box sx={styles.warmStartCardContent}>
          {
            ( this.props.warmStartState === 'Disabled' ) &&
            <Typography variant={'h5'} sx={styles.warmStartStateDisabledLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === 'Suspended' ) &&
            <Typography variant={'h5'} sx={styles.warmStartStateSuspendedLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === 'Enabled' ) &&
            <Typography variant={'h5'} sx={styles.warmStartStateEnabledLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === this.noValue ) &&
            <Typography variant={'h5'} sx={styles.warmStartStateNoValueLabel}>{this.noValue}</Typography>
          }
          <Box sx={styles.carouselContainer}>
            <Carousel animation={'slide'} interval={5000}
                      index={this.props.warmStartMetricsIndex}
                      indicatorContainerProps={{style: {marginTop: '-5px'}}}
                // To achieve the desired effect of the active indicator taking on the primary.main color
                // We need to set the style here instead of using a class
                // If we use a class, I suspect there is a bug where it's not overriding the color
                // it gets from indicatorIconButtonProps
                // However, using a style here will achieve that override
                      activeIndicatorIconButtonProps={{style: {color: mdtPalette().materialUI.palette.primary.main} }}
                      onChange={
                        (isUnmounting === true || _.isNil(this.props.warmStartMetrics) || _.isEmpty(this.props.warmStartMetrics)) ?
                            undefined :
                            (now, previous) =>
                            {
                              this.props.metricIndexChanged(now);
                            }
                      }

                      indicators={!this.isInEditMode()}
                      autoPlay={!this.isInEditMode()}
                      navButtonsAlwaysInvisible={this.isInEditMode()}
            >
              {
                this.props.warmStartMetrics.map((metrics, index) =>
                {
                  return (
                      <Box key={index} sx={styles.warmStartValuesContainer}>
                        <Box sx={{...styles.warmStartValueAndLabelContainer,width:'145px'}}>
                          <Typography variant={'h5'} sx={styles.hoursReducedLabel}>{ metrics.warmStartHrsReduced }</Typography>
                          <Typography variant={'caption'} sx={styles.hoursReducedLabel}>Hours Reduced</Typography>
                        </Box>
                        <Box sx={{...styles.warmStartValueAndLabelContainer,width:'145px'}}>
                          <Typography variant={'h5'} sx={styles.savingsLabel}>{ this.formatFuelSavingValue(metrics.warmStartFuelSavings) }</Typography>
                          <Typography variant={'caption'} sx={styles.savingsLabel}>Fuel Savings</Typography>
                        </Box>
                        <Box sx={{...styles.warmStartValueAndLabelContainer,width:'145px'}}>
                          <Typography variant={'h5'} sx={styles.emissionsLabel}>{ metrics.warmStartEmissionReduced }</Typography>
                          <Typography variant={'caption'} sx={styles.emissionsLabel}>Emissions Reduced</Typography>
                        </Box>
                      </Box>
                  )
                })
              }
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>;
  }

  getRenderContentForSizeThreeByTwo(){
    return <>
      <Divider sx={styles.divider}/>
      <Box sx={styles.cardLastRow}>
        <Box sx={styles.resolution}>
          <Typography sx={styles.resolutionLabel} variant={'subtitle1'}>{ this.props.warmStartMetricsLabel }</Typography>
        </Box>
        <Box sx={styles.warmStartCardContent}>
          {
            ( this.props.warmStartState === 'Disabled' ) &&
            <Typography variant={'h4'} sx={styles.warmStartStateDisabledLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === 'Suspended' ) &&
            <Typography variant={'h4'} sx={styles.warmStartStateSuspendedLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === 'Enabled' ) &&
            <Typography variant={'h4'} sx={styles.warmStartStateEnabledLabel}>{this.props.warmStartState}</Typography>
          }
          {
            ( this.props.warmStartState === this.noValue ) &&
            <Typography variant={'h4'} sx={styles.warmStartStateNoValueLabel}>{this.noValue}</Typography>
          }
          <Box sx={styles.carouselContainer}>
            <Carousel animation={'slide'} interval={5000}
                      index={this.props.warmStartMetricsIndex}
                      indicatorContainerProps={{style: {marginTop: '-5px'}}}
                // To achieve the desired effect of the active indicator taking on the primary.main color
                // We need to set the style here instead of using a class
                // If we use a class, I suspect there is a bug where it's not overriding the color
                // it gets from indicatorIconButtonProps
                // However, using a style here will achieve that override
                      activeIndicatorIconButtonProps={{style: {color: mdtPalette().materialUI.palette.primary.main} }}
                      onChange={
                        (isUnmounting === true || _.isNil(this.props.warmStartMetrics) || _.isEmpty(this.props.warmStartMetrics)) ?
                            undefined :
                            (now, previous) =>
                            {
                              this.props.metricIndexChanged(now);
                            }
                      }

                      indicators={!this.isInEditMode()}
                      autoPlay={!this.isInEditMode()}
                      navButtonsAlwaysInvisible={this.isInEditMode()}
            >
              {
                this.props.warmStartMetrics.map((metrics, index) =>
                {
                  return (
                      <Box key={index} sx={{...styles.warmStartValuesContainer, paddingBottom: 2}}>
                        <Box sx={styles.warmStartValueAndLabelContainer}>
                          <Typography variant={'h4'} sx={styles.hoursReducedLabel}>{ metrics.warmStartHrsReduced }</Typography>
                          <Typography variant={'subtitle1'} sx={styles.hoursReducedLabel}>Hours Reduced</Typography>
                        </Box>
                        <Box sx={styles.warmStartValueAndLabelContainer}>
                          <Typography variant={'h4'} sx={styles.savingsLabel}>{ this.formatFuelSavingValue(metrics.warmStartFuelSavings) }</Typography>
                          <Typography variant={'subtitle1'} sx={styles.savingsLabel}>Fuel Savings</Typography>
                        </Box>
                        <Box sx={styles.warmStartValueAndLabelContainer}>
                          <Typography variant={'h4'} sx={styles.emissionsLabel}>{ metrics.warmStartEmissionReduced }</Typography>
                          <Typography variant={'subtitle1'} sx={styles.emissionsLabel}>Emissions Reduced</Typography>
                        </Box>
                      </Box>
                  )
                })
              }
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>;
  }

  getRenderedContent() {
    return (
      <Box key='body' sx={styles.cardContent}>
        { this.props.card.w === 3 && this.getRenderedLifetimeFullWidth() }
        { this.props.card.w === 2 && this.getRenderedLifetimeLessWidth() }
        { this.props.card.w === 2 && this.props.card.h === 2 && this.getRenderContentForSizeTwoByTwo() }
        { this.props.card.w === 3 && this.props.card.h === 2 && this.getRenderContentForSizeThreeByTwo() }
        { this.props.card.w === 3 && this.props.card.h === 4 && this.getRenderContentForSizeThreeByFour() }
      </Box>
    )
  }
}

UnitHours.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_HOURS : props.stateKey,
      type: ComponentTypes.UNIT_HOURS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitHoursState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    ...mdtCardMapStateToProps(state, props),
    card: getCardFromLayoutConfigViews(appState.user.dashboards[props.dashboard].views, props.view, props.cardKey),
    ecmHours: componentState.ecmHours,
    pumpHours: componentState.pumpHours,
    tcmHours: componentState.tcmHours,
    warmStartState: componentState.warmStartState,
    warmStartMetrics: componentState.warmStartMetrics,
    warmStartMetricsLabel: componentState.warmStartMetricsLabel,
    warmStartMetricsIndex: componentState.warmStartMetricsIndex,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context) => { dispatch(unitHoursActions.queryData(props.stateDef, context)) },
    clearData: () => { dispatch(unitHoursActions.clearData(props.stateDef)) },
    // debounce this call so it doesn't get into a bad state with too many clicks
    metricIndexChanged: _.debounce((index) => { dispatch(unitHoursActions.metricIndexChanged(props.stateDef, index)) }, 250),
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitHours));