import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as componentLifeRoadsideSimulation from './componentLifeRoadsideSimulation';

const fetchComponentLifeRoadsideCardData = (truckId) => {

  if (useSimulationData('componentLifeRoadsideCard')) {
    return componentLifeRoadsideSimulation.fetchComponentLifeRoadsideCardData(truckId);
  }

  return performCardFetch({
    'query': '{ componentLifeRoadside(truckId:{truckId}) { components { type displayName model expectedLife primaryLife { name value } } } }'.replace('{truckId}', truckId)
  })
};

export default fetchComponentLifeRoadsideCardData
