
const STRING_OPERATORS = 
[
  {
    value: '==',
    label: '==',
  }, 
  {
    value: '!=',
    label: '!=',
  },
];

const NUMBER_OPERATORS = 
[
  {
    value: '==',
    label: '==',
  }, 
  {
    value: '!=',
    label: '!=',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '<=',
    label: '<=',
  },
  {
    value: '>',
    label: '>',
  },
  {
    value: '>=',
    label: '>=',
  },
];

const fetchSubscriptionDialogEvents = () => {

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        events:
        [
          {
            id: 3,
            entity: 'Alarm',
            type: 'AlarmRaised',
            filterAttributes:
            [
              {
                value: "fleetName",
                label: "Fleet Name",
                type: "String",
                availableOperators: STRING_OPERATORS,
                availableValues: [],
              },
              {
                value: "truckName",
                label: "Truck Name",
                type: "String",
                availableOperators: STRING_OPERATORS,
                availableValues: [],
              },
              {
                value: "unitType",
                label: "Unit Type",
                type: "String",
                availableOperators: STRING_OPERATORS,
                availableValues: [],
              },
              {
                value: "category",
                label: "Category",
                type: "String",
                availableOperators: STRING_OPERATORS,
                availableValues: [],
              },
              {
                value: "severityValue",
                label: "Severity",
                type: "Number",
                availableOperators:
                [
                  {
                    value: '==',
                    label: '==',
                  },
                  {
                    value: '>=',
                    label: '>=',
                  },
                  {
                    value: '<=',
                    label: '<=',
                  },
                ],
                availableValues: 
                [
                  {
                    value: '100',
                    label: 'Critical',
                  },
                  {
                    value: '200',
                    label: 'Warning',
                  },
                  {
                    value: '300',
                    label: 'Info',
                  },
                  {
                    value: '400',
                    label: 'Message',
                  },
                ]
              },
              {
                value: "source",
                label: "Source",
                type: "String",
                availableOperators: STRING_OPERATORS,
                availableValues: [],
              }
            ]
          }
        ],
      })
    }, 1000)
  });

};

const fetchSubscriptionDialogProviders = () => {

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        providerBasics:
        [
          {
            id: 5,
            name: "test_twilio",
            type: "twilio",
            config: "{\"}"
          },
          {
            id: 2,
            name: "MDT Teams",
            type: "teams",
            config: "{\"channels\":[{\"name\":\"AlarmDemo\"}]}"
          }
        ],
      })
    }, 1000)
  });
};

export { fetchSubscriptionDialogEvents, fetchSubscriptionDialogProviders, STRING_OPERATORS, NUMBER_OPERATORS }
