import * as componentsSimulation from './componentsSimulation';
import { performDisplayFetch } from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchComponentsDisplay = () => {

  if (useSimulationData('componentsDisplay')) {
    return componentsSimulation.fetchComponentsDisplay();
  }

  return performDisplayFetch({
    'query': '{ componentsDisplay { units { id name type fleetName region } } }'
  });
};

const fetchComponentsDisplayComponentDetails = (unitId) => {

  if (useSimulationData('componentsDisplay')) {
    return componentsSimulation.fetchComponentsDisplayComponentDetails(unitId);
  }

  return performDisplayFetch({
    'query': '{ componentsDisplayComponentDetails(truckId:{truckId}) { componentTypes { id name displayName kind } components { id componentType truckId model serialNumber referenceNumber effectiveDate unitOffset snapshotStart } earliestData { id earliestData } } }'
      .replace('{truckId}', unitId)
  });
};

const fetchSaveComponent = (componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart) => {

  if (useSimulationData('componentsDisplay')) {
    return componentsSimulation.fetchSaveComponent(componentId, unitId, componentType, model, serialNumber, referenceNumber, effectiveDate, unitOffset, snapshotStart);
  }

  return performDisplayFetch({
    'query': 'mutation saveComponent($componentInput:ComponentDetailsInput) { saveComponent(componentInput:$componentInput) { id componentType truckId model serialNumber referenceNumber effectiveDate unitOffset snapshotStart } }',
    'variables': {
      'componentInput': {
        'component': {
          'id': componentId,
          'truckId': unitId,
          'componentType': componentType,
          'model': model,
          'serialNumber': serialNumber,
          'referenceNumber': referenceNumber,
          'effectiveDate': effectiveDate,
          'unitOffset': unitOffset,
        },
        'snapshotStart': snapshotStart,
      }
    }
  });
};

const fetchDeleteComponent = (componentId) => {

  if (useSimulationData('componentsDisplay')) {
    return componentsSimulation.fetchDeleteComponent(componentId);
  }

  return performDisplayFetch({
    'query': 'mutation deleteComponent($componentId:Long!) { deleteComponent(componentId:$componentId) { result } }',
    'variables': {
      'componentId': componentId
    }
  });
};

const fetchEstimatePumpHours = (unitId, engineHours, engineComponentTypeId, pumpComponentTypeId) => {

  if (useSimulationData('componentsDisplay')) {
    return componentsSimulation.fetchEstimatePumpHours(unitId, engineHours, engineComponentTypeId, pumpComponentTypeId);
  }

  return performDisplayFetch({
    'query': 'query estimatedPumpHours($estimateInput: EstimatePumpHoursInput!) { estimatedPumpHours(estimateInput:$estimateInput) { estimatedPumpHours } }',
    'variables': {
      'estimateInput': {
        'truckId': unitId,
        'engineHours': engineHours,
        'engineComponentTypeId': engineComponentTypeId,
        'pumpComponentTypeId': pumpComponentTypeId
      }
    }
  });

};

export {
  fetchComponentsDisplay,
  fetchComponentsDisplayComponentDetails,
  fetchSaveComponent,
  fetchDeleteComponent,
  fetchEstimatePumpHours
}