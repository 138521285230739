export default {

  ALARMS_QUERY_STARTING: 'ALARMS_QUERY_STARTING',
  ALARMS_QUERY_SUCCESS: 'ALARMS_QUERY_SUCCESS',
  ALARMS_QUERY_ERROR: 'ALARMS_QUERY_ERROR',

  ALARMS_SHOW_ACTIVE: 'ALARMS_SHOW_ACTIVE',
  ALARMS_SHOW_HISTORY: 'ALARMS_SHOW_HISTORY',

  ALARMS_SHOW_MESSAGE_ALARMS: 'ALARMS_SHOW_MESSAGE_ALARMS',

  ALARMS_SET_TIME_FRAME: 'ALARMS_SET_TIME_FRAME',
  ALARMS_SET_CUSTOM_START_TIME: 'ALARMS_SET_CUSTOM_START_TIME',
  ALARMS_SET_CUSTOM_DURATION: 'ALARMS_SET_CUSTOM_DURATION',

  ALARMS_HANDLE_NAVIGATION_CONTEXT: 'ALARMS_HANDLE_NAVIGATION_CONTEXT',

  ALARMS_SET_CUSTOM_START_TIME_DISPLAY: 'ALARMS_SET_CUSTOM_START_TIME_DISPLAY',
};