import _ from "lodash";

const routeAssetMgmtState = state => {
    return {
        // Displayed Route Asset list and paging details
        routeAssets: _.isNil(state) ? [] : state.routeAssets,
        consumers: _.isNil(state) ? [] : state.consumers,
        trucks: _.isNil(state) ? [] : state.trucks,
        routeAssetsPerPage: _.isNil(state) ? 25 : state.routeAssetsPerPage,
        currentPage: _.isNil(state) ? 0 : state.currentPage,
        routeAssetsPerPageOptions: [25,50,100],

        // Flag to indicate one of the route asset management remote calls is executing
        queryRunning: _.isNil(state) ? false : state.queryRunning,

        // Flags to indicate that the specific forms should be displayed 
        openAddRouteAssetUI: _.isNil(state) ? false : state.openAddRouteAssetUI,
        openAddConsumerUI: _.isNil(state) ? false : state.openAddConsumerUI,
        openDeleteUI: _.isNil(state) ? false : state.openDeleteUI,
        openEditRouteAssetUI: _.isNil(state) ? false : state.openEditRouteAssetUI,
        openEditConsumerUI: _.isNil(state) ? false : state.openEditConsumerUI,

        // State to hold the values for the asset being manipulated
        // for usage in add/edit dialogs
        workingRouteAsset: _.isNil(state) ? {
            id: null,
            truckName: null,
            consumerId: null,
            consumerName: null,
            destinationPath: '',
            destinationProject: ''
        } : state.workingRouteAsset,

        // State to hold the values for the consumer being manipulated 
        // for usage in add/edit dialogs 
        workingConsumer: _.isNil(state) ? {
          id: null,
          consumerName: null,
          destinationPath: '',
          destinationProject: '',
        } : state.workingConsumer,

        // Validation state for working consumer
        workingConsumerValidation: _.isNil(state) ? {
          hasErrors: false,
          hasErrorConsumerName: false,
          hasErrorDestinationProject: false,
          hasErrorDestinationPath: false,
        } : state.workingConsumerValidation,
    };
};

export {
    routeAssetMgmtState,
}