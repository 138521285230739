const getDetailsPageStyles = () => ({

  detailsPage: {
    display:'flex',
    flexFlow: 'column',
    alignItems: 'center',
    paddingTop: '64px', // Height of the AppBar
    backgroundColor: 'background.default',
  },

  detailsPageContent: {
    display:'flex',
    flexDirection: 'column',
    flex: '1 0 100%',
    margin: 'auto',
    paddingLeft: 2,
    paddingRight: 2,
  },

  detailsPageHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: 1,
    width: '100%',
  },

  detailsPageHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '165px',
  },

  detailsPageHeaderActions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },

  selectionContainer: {
    display:'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'center',
    height: '56px',
    paddingTop: 1,
  },

  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  },
  timeFilterItem: {
    paddingLeft: 1,
    paddingRight: 3,
  },
  timeFrameSelection: {
    width: '150px',
  },
  startTimeSelection: {
    width: '200px'
  },
  
  detailsPageHeaderActionsButton: {
    paddingLeft: 1,
    paddingRight: 1
  }

});

export default getDetailsPageStyles;