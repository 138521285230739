import DownloadsDisplayActionTypes from './downloadsDisplayActionTypes';

const downloadsReducer = (state, action) => {

    switch (action.type) {
        case DownloadsDisplayActionTypes.DOWNLOADS_QUERY_STARTING:
            return onQueryDataStarting(state, action);

        case DownloadsDisplayActionTypes.DOWNLOADS_QUERY_SUCCESS:
            return onQueryDataSuccess(state, action);

        case DownloadsDisplayActionTypes.DOWNLOADS_QUERY_ERROR:
            return onQueryDataError(state, action);

        default: return state;
    }
};

const onQueryDataStarting = (state, action) => {
    return {
        ...state,
        queryRunning: true,
        items: []
    };
};

const onQueryDataError = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        items: []
    };
};

const onQueryDataSuccess = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        items: action.queryResults.allLatestDownloadableAssets
    };
};


export default downloadsReducer