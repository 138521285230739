import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import ComponentTypes from '../../componentTypes';

import * as fleetPRCHistoryActions from '../../../state/cards/fleetPRCHistory/fleetPRCHistoryActions';
import { fleetPRCHistoryState } from '../../../state/cards/fleetPRCHistory/fleetPRCHistorySelectors';

import { mdtPalette } from '../../common/styles/mdtPalette';
import UnitChart from '../unitChart/common/unitChart';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";

//Override default unitChart styles
let endTime = moment().startOf('minute').unix();
let startTime = moment.unix(endTime).subtract(360, 'minutes').unix();

class FleetPRCHistory extends MDTCard {

  getName(){
      return 'PRC HISTORY';
  }

  refresh() {
    if (_.isNil(this.props.context)) {
      this.props.clearData();
    } else {
      endTime = moment().startOf('minute').unix();
      startTime = moment.unix(endTime).subtract(360, 'minutes').unix();
      this.props.queryData(this.props.context.datavanId || this.props.context.id, startTime, endTime);
    }
  }

  getRenderedContent() {
    const cardConfig = {
      startTime:  startTime,
      endTime: endTime,
      resolutionLabel: '6 Hours',
      series1: {
        data: [...this.props.pressure],
        latest: this.props.latestPressure,
        latestLabel: 'Pressure (psi)',
        chartUom: 'PSI',
        color: mdtPalette().categories.category7
      },
      series2: {
        data: [...this.props.flowRate],
        latest: this.props.latestFlowRate,
        latestLabel: 'Flow Rate (bpm)',
        chartUom: 'BPM',
        color: mdtPalette().categories.category3
      },
      series3: {
        data: [...this.props.concentration],
        latest: this.props.latestConcentration,
        latestLabel: 'Concentration (lbs/gal)',
        chartUom: 'LBS/GAL',
        color: mdtPalette().categories.category4
      }
    };

    return (
      <UnitChart cardConfig={cardConfig} />
    )
  }
}

FleetPRCHistory.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_PRC_HISTORY : props.stateKey,
      type: ComponentTypes.FLEET_PRC_HISTORY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = fleetPRCHistoryState(state[stateDef.key]);
  return {
    ...mdtCardMapStateToProps(state, props),
    pressure: componentState.pressure,
    flowRate: componentState.flowRate,
    concentration: componentState.concentration,
    latestFlowRate: componentState.latestFlowRate,
    latestPressure: componentState.latestPressure,
    latestConcentration: componentState.latestConcentration
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (context, startTime, endTime) => { dispatch(fleetPRCHistoryActions.queryData(props.stateDef, context, startTime, endTime)) },
    clearData: () => { dispatch(fleetPRCHistoryActions.clearData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetPRCHistory));

