import reduceReducers from 'reduce-reducers';
import displayReducer from './displayReducer';
import expectedLifeReducer from './expectedLifeReducer';
import warmStartReducer from './warmStartReducer';
import providerReducer from './providerReducer';

import { settingsState } from '../selectors/settingsSelectors';
import deviceReducer from "./deviceReducer";

const initialState = settingsState();

const settingsReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    displayReducer,
    expectedLifeReducer,
    warmStartReducer,
    providerReducer,
    deviceReducer,
  );

  return reducer(state, action);
};

export default settingsReducer
