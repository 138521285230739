import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';
import * as fleetOperationSimulation from '../fleetOperation/fleetOperationSimulation';

const fetchFleetOperationCardData = (fleetId) => {

  if (useSimulationData('fleetOperationCard')) {
    return fleetOperationSimulation.fetchFleetOperationCardData(fleetId);
  }

  return performCardFetch({
    'query': '{ fleetOperationStatus(fleetId:{fleetId}) { fleetPumpStatusInfo { count pumping idle stopped offline } suctionData hhp sandTotal slurryTotal } }'.replace('{fleetId}', fleetId)
  })
};

export default fetchFleetOperationCardData