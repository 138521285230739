import fleetMapActionTypes from '../fleetMapActionTypes';
import * as fleetMapService from '../services/fleetService';

const queryReducer = (state, action) => {

  switch (action.type) {

    case fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_STARTING:
      return onQuerySummaryStarting(state, action);

    case fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_SUCCESS:
      return onQuerySummarySuccess(state, action);

    case fleetMapActionTypes.FLEET_MAP_QUERY_SUMMARY_ERROR:
      return onQuerySummaryError(state, action);

    default: return state;

  }
};

const  onQuerySummaryStarting = (state, action) => {
  return {
    ...state,
    queryRunning: true,
  };
};

const onQuerySummarySuccess = (state, action) => {

  let fleets = fleetMapService.processFleets(action.queryResults.fleetDashboardFleetSummary);

  return {
    ...state,
    queryRunning: false,
    fleets: fleets,
  };
};

const  onQuerySummaryError = (state, action) => {
  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer