import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import { fetchComponentLifeCardData, fetchComponentTypesByUnitType } from './componentLifeQueries';

import componentLifeActionTypes from './componentLifeActionTypes';
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import { getCardFromLayoutConfigViews } from '../../../components/common/layout/layoutHelper';
import { extractConfiguration } from '../../../components/cards/componentLife/componentLifeConfigPanel';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(componentLifeActionTypes.COMPONENT_LIFE_CLEAR_DATA, 'statedef');

const queryData = queryActionFactory(
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_STARTING,
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_SUCCESS,
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_COMPONENT_LIFE_CARD_DATA,
    fetchComponentLifeCardData
);

const queryComponentTypes = queryActionFactory(
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_STARTING,
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_TYPES_SUCCESS,
    componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_COMPONENT_LIFE_CARD_TYPES,
    fetchComponentTypesByUnitType
);

const applyConfiguration = defaultActionFactory(componentLifeActionTypes.COMPONENT_LIFE_APPLY_CONFIGURATION, 'stateDef', 'configuration');

const onReorderComponents = (stateDef, cardProp, fromIndex, toIndex) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[cardProp.dashboard].views, cardProp.view, cardProp.card.i);
    let configuration = card.configuration;

    const newOrder = _.cloneDeep(configuration.components);
    const [movedComponent] = newOrder.splice(fromIndex, 1);
    newOrder.splice(toIndex, 0, movedComponent);

    configuration = {...configuration, components: newOrder}
    await dispatch(appUserConfigActions.onChangeConfig(cardProp.dashboard, cardProp.view, cardProp.card.i, configuration));
    await dispatch(applyConfiguration(stateDef, newOrder));
  }
};

const onToggleComponentVisibility = (stateDef, cardProp, index) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[cardProp.dashboard].views, cardProp.view, cardProp.card.i);
    let configuration = card.configuration;

    const newComponents = _.cloneDeep(configuration.components);
    newComponents[index].isVisible = !newComponents[index].isVisible;

    configuration = {...configuration, components: newComponents};
    await dispatch(appUserConfigActions.onChangeConfig(cardProp.dashboard, cardProp.view, cardProp.card.i, configuration));
    await dispatch(applyConfiguration(stateDef, newComponents));
  }
};

const onToggleJustifyContent = (cardProp) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[cardProp.dashboard].views, cardProp.view, cardProp.card.i);
    const configuration = {...card.configuration, isEvenlySpaced: !card.configuration.isEvenlySpaced};
    await dispatch(appUserConfigActions.onChangeConfig(cardProp.dashboard, cardProp.view, cardProp.card.i, configuration));
  }
};

const onDiscardCardConfiguration = (stateDef, dashboard, view, cardKey) => {
  return async(dispatch, getState) => {
    await dispatch(appUserConfigActions.onDiscardCardConfiguration(dashboard, view, cardKey));
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    await dispatch(applyConfiguration(stateDef, card.configuration?.components));
  }
};

const onQueryData = (stateDef, context, dashboard, view, cardKey) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    await dispatch(queryData(stateDef, context));
    await dispatch(applyConfiguration(stateDef, card.configuration?.components));
  }
};

const onQueryComponentTypes = (stateDef, unitType, dashboard, view, cardKey) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard].views, view, cardKey);
    await dispatch(queryComponentTypes(stateDef, unitType));

    if (_.isNil(card.configuration?.components)) {
      // no configuration for this card yet; create a new one
      let configuration = extractConfiguration(getState()[cardKey]?.componentTypes);
      configuration = { components: configuration, isEvenlySpaced: true };
      await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
    } else {
      // already have configuration; update based on component types
      const types = getState()[cardKey]?.componentTypes.map(type => type.name);
      let configuration = card.configuration.components.filter(component => types.includes(component.type));
      let existing = configuration.map(element => element.type);
      let configFromTypes = types.filter(type => !existing.includes(type)).map(type => ({ type: type, isVisible: true }));
      configuration = [...configuration, ...configFromTypes];
      configuration = { ...card.configuration, components: configuration };
      await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, configuration));
    }
  }
};

export {
  clearData,
  onQueryData,
  onReorderComponents,
  onToggleComponentVisibility,
  onToggleJustifyContent,
  onDiscardCardConfiguration,
  onQueryComponentTypes,
};