import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";

import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton, Typography, Tooltip, Box } from "@mui/material";
import FilterDialog from '../../common/filtering/filterDialog';
import DashboardView from './views/dashboard';

import getDisplayStyles from '../../common/styles/displayStyles';
import ComponentTypes from '../../componentTypes';
import * as dfpDashboardActions from '../../../state/displays/dfpDashboard/dfpDashboardActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { dfpDashboardState } from '../../../state/displays/dfpDashboard/dfpDashboardSelectors';
import { displayConfigurationState} from '../../../state/app/appSelectors';
import dfpDashboardViews from '../../../state/displays/dfpDashboard/dfpDashboardViews';
import { appState as applicationState } from '../../../state/app/appSelectors';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';
import { AutoCompleteMDT } from '../../controls/mdtMuiControls';

const displayStyles = getDisplayStyles();

const styles = {
  ...displayStyles,
  dateTimeControl: {
    // NOTE: The padding below is a bit of a hack to
    // get the 3rd Party datetime picker to align
    // correctly with the other controls.
    paddingTop: '2px',
  },
};

class DfpDashboard extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.DFP_DASHBOARD, this.props.user);
    // Load the dashboard data. NOTE: Loading the display data implicitly
    // handles the setting of the selected context which will trigger the
    // loading of the data age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
    // Start the timer for refreshing the relative time label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshRelativeTime();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    // Load the data age if the selected context changes
    if (prevProps.selectedContext !== this.props.selectedContext) {
      this.loadDataAge();
    }
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(()=>{
      this.props.loadDisplay();
    }, 180000); // 3 minutes
  }

  periodicRefreshRelativeTime() {
    // Periodically refresh the label for the data age
    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
  }

  loadDataAge() {
    if (!_.isNil(this.props.selectedContext) && !_.isNil(this.props.selectedContext.id)) {
      // Update the Data Age
      this.props.queryDataAge(this.props.selectedContext.id);
    } else {
      // When the selected owner has no trucks, we want to clear the existing data age
      this.props.clearDataAge();
    }
  }

  render() {
    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    return (
      <Box sx={styles.page}>
         <Box sx={styles.pageContent}>
          <Box sx={styles.pageHeader}>
            <Box sx={styles.pageHeaderTitle}>
              <Typography variant={'h6'}>DFP Dashboard</Typography>
            </Box>
            <Box sx={styles.pageHeaderActions}>
              <Box sx={styles.pageHeaderButton}>
                <Tooltip title="Refresh">
                  <IconButton onClick={() => this.manualRefreshDisplay()} size="large">
                    <RefreshIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={styles.filterContainer}>
              <Typography variant={'subtitle1'}>FILTER:</Typography>
              {
                this.props.appliedFilters.map((filterContext, index) => {
                  return (
                    <Chip
                      sx={styles.childComponent}
                      key={index}
                      label={filterContext.chipLabel}
                      onDelete={() => this.props.deleteFilterContext(index)}
                    />
                  )
                })
              }
              <IconButton
                sx={styles.childComponent}
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                size="large">
                  <AddCircleIcon/>
              </IconButton>
            </Box>
            <Box sx={styles.dataAgeContainer}>
              <Typography sx={styles.childComponent}>Data as of:</Typography>
              <Typography sx={styles.childComponent}>
                {this.props.dataAgeDisplay}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.selectionContainer}>
            <Typography variant={'subtitle1'}>DFP:</Typography>
            <AutoCompleteMDT
              sx={styles.contextSelection}
              options={this.props.contexts}
              value={this.props.selectedContext}
              onChange={(event, value, reason) => {
                this.props.selectContext(value);
              }}
              noOptionsText={"No DFPs found..."}
            />
          </Box>
          <Box sx={styles.viewContainer}>
            {
              this.props.selectedView === dfpDashboardViews.DASHBOARD &&
              <DashboardView dfp={this.props.selectedContext}/>
            }
          </Box>
          <FilterDialog stateDef={this.props.stateDef}/>
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DFP_DASHBOARD : props.stateKey,
      type: ComponentTypes.DFP_DASHBOARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dfpDashboardState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    configuration: displayConfigurationState(state, ComponentTypes.DFP_DASHBOARD),
    contexts: componentState.contexts,
    selectedContext: componentState.selectedContext,
    dataAgeDisplay: componentState.dataAgeDisplay,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    selectedView: componentState.selectedView,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: () => { dispatch(dfpDashboardActions.loadDisplay(props.stateDef))},
    queryDataAge: (truckId) => { dispatch(dfpDashboardActions.queryDataAge(props.stateDef, truckId))},
    clearDataAge: () => { dispatch(dfpDashboardActions.clearDataAge(props.stateDef))},
    refreshRelativeTime: () => { dispatch(dfpDashboardActions.refreshRelativeTime(props.stateDef))},
    selectContext: (context) => {dispatch(dfpDashboardActions.selectContext(props.stateDef, context))},
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    selectView: (view) => { dispatch(dfpDashboardActions.selectView(props.stateDef, view))},
    setTimeFrame: (timeFrame) => {dispatch(dfpDashboardActions.setTimeFrame(props.stateDef, timeFrame))},
    setCustomStartTime: (startTime) => {dispatch(dfpDashboardActions.setCustomStartTime(props.stateDef, startTime))},
    setCustomDuration: (duration) => {dispatch(dfpDashboardActions.setCustomDuration(props.stateDef, duration))},
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DfpDashboard))
