import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from "recompose";

import ComponentTypes from "../../componentTypes";
import Transition from "../../controls/dialogSlideTransition";
import { MdtYAxisPosition } from "../../../state/common/dataExplorationChart/mdtYAxisPosition";
import { getCardFromLayoutConfigViews } from "../../common/layout/layoutHelper";
import MdtColorPicker from "../../common/colorPicker/mdtColorPicker";

import * as unitUserChartActions from '../../../state/cards/unitUserChart/unitUserChartActions';
import * as appUserConfigActions from '../../../state/app/actions/appUserConfigActions';
import { unitUserChartState } from '../../../state/cards/unitUserChart/unitUserChartSelectors';

import { 
  Typography, 
  Radio, 
  TextField, 
  FormControl, 
  Select, 
  MenuItem, 
  Button, 
  Dialog, 
  Card, 
  Tabs, 
  Tab,
  Chip,
  Tooltip,
  Box
} from "@mui/material";
import SensorSelector from "../../common/dataExploration/chart/sensorSelector";

import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel, mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";

import { sanitizeDefinition } from "../../../state/cards/unitUserChart/services/unitUserChartService";

const styles = {
    sensorCardsList: {
        p: '0px 0px 8px 0px',
        marginTop: 2,
    },
    actionButton: {
        marginBottom: '16px',
    },
    dialogPaper: {
        minHeight: '840px',
        maxHeight: '840px',
    },
    sensorCard: {
        border: 1,
        p: '8px',
        m: '10px 0',
    },
    sensorTitle: {
        fontSize: '1.25rem',
    },
    sensorDisplayText: {
        m: '10px 0',
        width: '100%'
    },
    sensorUom: {
        display: 'flex',
        m: '0px 0px 10px 0px',
        alignItems: 'center'
    },
    uomSelection: {
        flexGrow: 1,
        marginLeft: 1,
    },
    sensorAxis: {
        m: '0px 0px 10px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    axisSelection: {
        marginLeft: 1,
        display: 'flex',
    },
    axisPositionSpacer: {
        width: '20px',
        height: '100%',
    },
    sensorColor: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '48px',
        height: '48px',
        overflow: 'visible'
    },
    colorPickerPopover: {
        top: '-80px', 
        left: '0px',
    },
    sensorLineStyle: {
        display: 'flex',
        alignItems: 'center'
    },
    lineSelection: {
        flexGrow: 1,
        marginLeft: 1,
    },

    dottedLine: {
        overflow: 'hidden',
        width: '100px',
        display: 'flex',
        height: '20px',
        alignItems: 'center'
    },

    configAxisLeftAxisContainer: {
      width: '15%', 
      height: '100%', 
      marginRight: 1
    },
    configAxisRightAxisContainer: {
      width: '15%', 
      height: '100%', 
      marginLeft: 1
    },
    configAxisControlsContainer: {
      display: 'flex', 
      flexFlow: 'column nowrap', 
      height: '100%', 
      justifyContent: 'space-between', 
      alignItems: 'center'
    },
    configAxisChip: {
      width: '24px'
    },
    configAxisButtonContainer: {
      display: 'flex', 
      flexFlow: 'row nowrap', 
      width: '15%', 
      height: '100%', 
      justifyContent: 'center'
    },
    configAxisButton: {
      width: '45px'
    }
}

const lineStyles = (line) => {
    const lineLength = line[0];
    const gapLength = line[1];
    return ({
        dottedBg: {
            backgroundColor: 'transparent',
            height: '5px',
            width: gapLength + 'px',
            minWidth: gapLength + 'px',
        },
        dottedFill: {
            backgroundColor: '#FFF',
            height: '5px',
            width: lineLength + 'px',
            minWidth: lineLength + 'px',
        }
    });
}

const seriesTabContent = (props) => {
  return (
    <Box sx={styles.sensorCardsList}>
      <Box textAlign={'center'}>
        <Button sx={styles.actionButton} variant='contained' color='primary'
          onClick={() => {
              props.openSensorSelector()
          }}
        >
          Select Sensors
        </Button>
      </Box>
      <Dialog maxWidth={'lg'}
        fullWidth={true}
        open={props.shouldOpenConfigSensorSelector}
        TransitionComponent={Transition}
        PaperProps={{ paper: styles.dialogPaper }}
      >
        <SensorSelector
          parentCallback={(sensors) => { props.setSelectedSensors('primary', sensors); }}
          enableGroupEdit={false}
          initalSelectedSensors={_.isNil(props.definition?.primary.sensors) ? [] : props.definition.primary.sensors}
          trucks={_.isNil(props.definition?.primary.defaultTruck) ? [] : [props.definition.primary.defaultTruck.pid]}
          startTime={props.definition?.primary.timeRange.startTime}
          endTime={props.definition.primary.timeRange.startTime + (60 * props.definition.primary.timeRange.duration)}
          onClose={props.closeSensorSelector}
          stateKey={ComponentTypes.SENSOR_SELECTOR + '_' + props.stateKey}
          namespace={'DXP'}
          config={true}
          unitType={props.definition.primary.defaultTruck.unitType}
        />
      </Dialog>

      {
        props.definition?.primary.sensors.map((item) => {
          const showColorPicker = !_.isNil(props.colorPickerState) && props.colorPickerState.sensor === item.alias
          return (
            <Card sx={{marginBottom: 2, padding: 1, boxShadow: '4px 5px 8px 3px rgb(0 0 0 / 40%)'}}  key={item.alias}>
              <Typography sx={styles.sensorTitle}>{item.alias}</Typography>
              <TextField
                sx={styles.sensorDisplayText}
                id="display-text"
                size="small"
                helperText="Display Text"
                variant="standard"
                value={item.displayName}
                onChange={(event) => {
                  props.onChangeDisplayName(item.alias, event.target.value, props.card.dashboard, props.card.view, props.card.card.i);
                }}
              />
              <Box sx={styles.sensorUom}>
                <Typography variant={'subtitle1'}>Unit of Measure:</Typography>
                <Box sx={styles.uomSelection}>
                  <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 16px)' }} size="small">
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      value={item.uom}
                      onChange={(event) => {
                        props.onChangeUOM(item.alias, event.target.value, props.card.dashboard, props.card.view, props.card.card.i)
                      }}
                      displayEmpty
                    >
                      {_.isEmpty(item.targetUoms) ? (
                        <MenuItem key={item.alias + '_' + item.uom} value={item.uom}>{item.uom}</MenuItem>
                      ) : (
                        item.targetUoms.map(uom => (
                          <MenuItem key={item.alias + '_' + uom} value={uom}>{uom}</MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={styles.sensorAxis}>
                <Typography variant={'subtitle1'}>Axis Position:</Typography>
                  <Box sx={styles.axisSelection}>
                    <Radio
                      checked={item.yAxisId === MdtYAxisPosition.LeftOuter}
                      color={"default"}
                      onChange={() => props.onChangeYAxis(item.alias, MdtYAxisPosition.LeftOuter, props.card.dashboard, props.card.view, props.card.card.i)}
                      value={MdtYAxisPosition.LeftOuter}
                      name="axis"
                    />
                    <Radio
                      checked={item.yAxisId === MdtYAxisPosition.LeftInner}
                      color={"default"}
                      onChange={() => props.onChangeYAxis(item.alias, MdtYAxisPosition.LeftInner, props.card.dashboard, props.card.view, props.card.card.i)}
                      value={MdtYAxisPosition.LeftInner}
                      name="axis"
                    />
                    <Box sx={styles.axisPositionSpacer} />
                    <Radio
                      checked={item.yAxisId === MdtYAxisPosition.RightInner}
                      color={"default"}
                      onChange={() => props.onChangeYAxis(item.alias, MdtYAxisPosition.RightInner, props.card.dashboard, props.card.view, props.card.card.i)}
                      value={MdtYAxisPosition.RightInner}
                      name="axis"
                    />
                    <Radio
                      checked={item.yAxisId === MdtYAxisPosition.RightOuter}
                      color={"default"}
                      onChange={() => props.onChangeYAxis(item.alias, MdtYAxisPosition.RightOuter, props.card.dashboard, props.card.view, props.card.card.i)}
                      value={MdtYAxisPosition.RightOuter}
                      name="axis"
                    />
                </Box>
              </Box>
              <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box sx={styles.sensorColor}>
                  <Typography variant={'subtitle1'}>Color:</Typography>
                  <MdtColorPicker
                    showColorPicker={showColorPicker}
                    id={item.alias}
                    color={item.color}
                    origColor={props.colorPickerState ? props.colorPickerState.origColor : null}
                    onSetColorPickerState={props.onSetColorPickerState}
                    onConfigChangedColor={props.onConfigChangedColor}
                    popoverStyles={styles.colorPickerPopover}
                  />
                </Box>
                <Box sx={styles.sensorLineStyle}>
                  <Typography variant={'subtitle1'}>Line:</Typography>
                  <Box sx={styles.lineSelection}>
                    <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 16px)' }} size="small">
                      <Select
                        MenuProps={{ disableScrollLock: true }}
                        value={_.isNil(item.lineStyle?.id) ? '' : item.lineStyle.id}
                        
                        onChange={(event) => {
                          props.onChangeLine(item.alias, _.find(props.lineOptions, { id: event.target.value }), props.card.dashboard, props.card.view, props.card.card.i)
                        }}
                        displayEmpty
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {_.map(props.lineOptions, (line) => (
                          <MenuItem value={line.id} key={line.id}>
                            <Box sx={styles.dottedLine}>
                              {_.times(_.ceil(100 / (line.value[0] + line.value[1])), (index) => (
                                <Box sx={{ display: 'flex' }} key={index}>
                                  <Box sx={lineStyles(line.value).dottedFill}></Box>
                                  <Box sx={lineStyles(line.value).dottedBg}></Box>
                                </Box>))
                              }
                            </Box>
                          </MenuItem>))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Card>
            )
          }
        )
      }
    </Box>
  )
}

const isNumeric = (value) => {
  return /^-?[0-9]*$/.test(value);
}

const axisControlsContainerContent = (props, sensorsOnAxis, shouldDisable, axisId) => {

  const axisConfig = props.definition?.primary.axes[axisId];

  return (
    <Box sx={styles.configAxisControlsContainer}>
      <Tooltip title={(_.isNil(axisConfig?.errors?.max) ? '' : axisConfig.errors.max)}>
        <TextField disabled={shouldDisable} label={'Max'} variant='standard' value={axisConfig?.max}
          error={!_.isNil(axisConfig?.errors?.max)}
          onChange={(event) => { 
            if (!isNumeric(event.target.value)) { 
              return;
            };
            props.onSetAxisPropertyValue(axisId, 'max', event.target.value); 
          }}
          InputProps={{
            inputProps: {
              maxLength: 5,
            }
          }}/>
      </Tooltip>
      { /* Show the sensors (display name) that are on this axis */
        !shouldDisable &&
        <Tooltip 
          title={
            <span style={{whiteSpace: 'pre-line'}}>
            {
              _.map(sensorsOnAxis, (sensor, index) => {
                return sensor.displayName + (index < sensorsOnAxis.length - 1 ? '\n' : '')
              })
            }
            </span>
          }>
          <Chip sx={styles.configAxisChip} size="small" label={sensorsOnAxis.length}/>
        </Tooltip>
      }
      <Tooltip title={(_.isNil(axisConfig?.errors?.min) ? '' : axisConfig.errors.min)}>
        <TextField disabled={shouldDisable} label={'Min'} variant='standard' value={axisConfig?.min}
          error={!_.isNil(axisConfig?.errors?.min)}
          onChange={(event) => { 
            if (!isNumeric(event.target.value)) { 
              return;
            };
            props.onSetAxisPropertyValue(axisId, 'min', event.target.value); 
          }}
          InputProps={{
            inputProps: {
              maxLength: 5,
            }
          }}/>
      </Tooltip>
    </Box>
  )
}

const configAxisButtonContainerContent = (props, axisId, shouldDisable) => {
  return (
    <Button sx={styles.configAxisButton} size='small' disabled={shouldDisable} disableRipple onClick={() => { props.onSetAxisAuto(axisId);}}>Auto</Button>
  )
}

const axisTabContent = (props) => {

  const sensorsLeftOuter = _.filter(props.definition?.primary.sensors, sensor => sensor.yAxisId === MdtYAxisPosition.LeftOuter);
  const sensorsLeftInner = _.filter(props.definition?.primary.sensors, sensor => sensor.yAxisId === MdtYAxisPosition.LeftInner);
  const sensorsRightInner = _.filter(props.definition?.primary.sensors, sensor => sensor.yAxisId === MdtYAxisPosition.RightInner);
  const sensorsRightOuter = _.filter(props.definition?.primary.sensors, sensor => sensor.yAxisId === MdtYAxisPosition.RightOuter);

  const shouldDisableLeftOuter = sensorsLeftOuter.length === 0;
  const shouldDisableLeftInner = sensorsLeftInner.length === 0;
  const shouldDisableRightInner = sensorsRightInner.length === 0;
  const shouldDisableRightOuter = sensorsRightOuter.length === 0;

  return (
    <Box sx={{display: 'flex', flexFlow: 'column nowrap', flexGrow: 1, justifyContent: 'space-evenly', height: '300px', width: '100%', marginTop: 2, paddingLeft: 1, paddingRight: 1}} >

      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, justifyContent: 'space-evenly', height: '250px', width: '100%'}}>
        <Box sx={styles.configAxisLeftAxisContainer}>
          {
            axisControlsContainerContent(props, sensorsLeftOuter, shouldDisableLeftOuter, MdtYAxisPosition.LeftOuter)
          }
        </Box>
        <Box sx={styles.configAxisLeftAxisContainer}>
          {
            axisControlsContainerContent(props, sensorsLeftInner, shouldDisableLeftInner, MdtYAxisPosition.LeftInner)
          }
        </Box>
          
        <Box sx={{width: '40%', height: '100%', borderBottom: '3px solid rgba(255,255,255,0.25)', borderLeft: '3px solid rgba(255,255,255,0.25)', borderRight:'3px solid rgba(255,255,255,0.25)'}}/>

        <Box sx={styles.configAxisRightAxisContainer}>
          {
            axisControlsContainerContent(props, sensorsRightInner, shouldDisableRightInner, MdtYAxisPosition.RightInner)
          }
        </Box>
        <Box sx={styles.configAxisRightAxisContainer}>
          {
            axisControlsContainerContent(props, sensorsRightOuter, shouldDisableRightOuter, MdtYAxisPosition.RightOuter)
          }
        </Box>
      </Box>

      <Box sx={{display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, justifyContent: 'space-evenly', height: '50px', width: '100%'}}>
        <Box sx={styles.configAxisButtonContainer}>
          {
            configAxisButtonContainerContent(props, MdtYAxisPosition.LeftOuter, shouldDisableLeftOuter)
          }
        </Box>
        <Box sx={styles.configAxisButtonContainer}>
          {
            configAxisButtonContainerContent(props, MdtYAxisPosition.LeftInner, shouldDisableLeftInner)
          }
        </Box>
          
        <Box sx={{width: '40%', height: '100%'}}/>

        <Box sx={styles.configAxisButtonContainer}>
          {
            configAxisButtonContainerContent(props, MdtYAxisPosition.RightInner, shouldDisableRightInner)
          }
        </Box>
        <Box sx={styles.configAxisButtonContainer}>
          {
            configAxisButtonContainerContent(props, MdtYAxisPosition.RightOuter, shouldDisableRightOuter)
          }
        </Box>
      </Box>

    </Box>
  )

}

class UnitUserChartConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        width: 400,
        defaultTitle: 'UNIT GRAPH',
        availableSizes: []
    };

    constructor(props) {
        super(props);

        //Set the card title and definition if not existing
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);
        if (_.isEmpty(card?.configuration?.title)) {
            this.props.onChangeCardTitle(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, this.props.card.cardDefaultTitle);
        }
        if (_.isEmpty(card?.configuration?.definition)) {
            this.props.initializeDefinition(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, sanitizeDefinition(this.props.definition));
        }
    }

    componentDidMount() {
      super.componentDidMount();

      //Set the card title and definition if not existing
      const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);
      if (_.isEmpty(card?.configuration?.definition)) {
        this.props.initializeDefinition(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, sanitizeDefinition(this.props.definition));
      }

      // load all sensors so we can get the target uoms of the selected sensors
      const trucks = _.uniq(_.map(this.props.definition.primary.trucks, elem => elem.pid));
      const startTime = this.props.definition.primary.timeRange.startTime;
      const endTime = startTime + this.props.definition.primary.timeRange.duration * 60;
      const namespace = 'DXP';
      if (!_.isNil(trucks) && !_.isEmpty(trucks) && !_.isNil(startTime) && !_.isNil(endTime)) {
        this.props.loadSensors(trucks, startTime, endTime, namespace);
      }
    }

    hasConfigChanged(dashboard, view, cardKey) {
      let hasChanges = super.hasConfigChanged(dashboard, view, cardKey);

      const currentDashboard = this.props.dashboards[dashboard];
      const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

      if (_.isNil(originalCard)) return false;

      // Check for changes in sensor config
      // Normalize empty configuration for cards
      const originalConfig = originalCard?.configuration || {};
      const currSensors = _.map(this.props.definition.primary?.sensors || [], sensor => { return _.omit(sensor, ['targetUoms']) });
      const originalSensors = _.map(originalConfig.sensors || [], sensor => { return _.omit(sensor, ['targetUoms']) });
      const hasSensorsChanges = !_.isEqual(currSensors, originalSensors);

      const currAxes = this.props.definition.primary?.axes || {};
      const originalAxes = originalConfig.axes || {};
      const hasAxesChanges = !_.isEqual(currAxes, originalAxes);

      return hasChanges || hasSensorsChanges || hasAxesChanges;
    }

    getRenderedContent() {
      return (
        <Box sx={{display: 'flex', flex: '1 1 100%', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', overflowY: 'auto'}}>
          <Tabs
              value={this.props.configTabIndex}
              onChange={(event, value) => this.props.onSelectTab(value)}
              indicatorColor='primary'
              textColor='primary'
              variant={'fullWidth'}>
            <Tab value={0} label='Series' />
            <Tab value={1} label='Axis'/>
          </Tabs>
          {
            this.props.configTabIndex === 0 ? seriesTabContent(this.props) : axisTabContent(this.props)
          }
      </Box>
      )
    }

    disableClose() {
      return _.some(this.props.definition.primary?.axes, (axis) => !_.isEmpty(axis.errors));
    }
}

UnitUserChartConfigPanel.propTypes = mdtCardConfigPanelPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_USER_CHART : props.stateKey,
            type: ComponentTypes.UNIT_USER_CHART,
        }
    }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = unitUserChartState(state[stateDef.key]);

    return {
      ...mdtCardConfigMapStateToProps(state, props),
      shouldOpenConfigSensorSelector: componentState.shouldOpenConfigSensorSelector,
      colorPickerState: componentState.colorPickerState,
      lineOptions: componentState.lineStylesList,
      truck: componentState.truck,
      definition: componentState.definition,

      configTabIndex: componentState.configTabIndex
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardConfigMapDispatchToProps(dispatch, props),
        openSensorSelector: () => {
            dispatch(unitUserChartActions.openSensorSelector(props.stateDef));
        },
        closeSensorSelector: () => {
            dispatch(unitUserChartActions.closeSensorSelector(props.stateDef));
        },
        setSelectedSensors: (xAxisId, selectedSensors) => {
            dispatch(unitUserChartActions.setSelectedSensors(props.stateDef, xAxisId, selectedSensors))
        },
        loadSensors: (trucks, startTime, endTime, namespace) => {
            dispatch(unitUserChartActions.querySensors(props.stateDef, trucks, startTime, endTime, namespace));
        },
        onChangeDisplayName: (sensor, displayName, dashboard, view, cardKey) => {
            dispatch(unitUserChartActions.onChangeDisplayName(props.stateDef, sensor, displayName, dashboard, view, cardKey))
        },
        onChangeUOM: (sensor, uom, dashboard, view, cardKey) => {
            dispatch(unitUserChartActions.onChangeUOM(props.stateDef, sensor, uom, dashboard, view, cardKey))
        },
        onChangeYAxis: (sensor, yAxis, dashboard, view, cardKey) => {
            dispatch(unitUserChartActions.onChangeYAxis(props.stateDef, sensor, yAxis, dashboard, view, cardKey))
        },
        onChangeLine: (sensor, lineStyle, dashboard, view, cardKey) => {
            dispatch(unitUserChartActions.onChangeLine(props.stateDef, sensor, lineStyle, dashboard, view, cardKey))
        },
        onConfigChangedColor: (sensor, color) => {
            dispatch(unitUserChartActions.onConfigChangedColor(props.stateDef, sensor, color, props.card.dashboard, props.card.view, props.card.card.i))
        },
        onSetColorPickerState: (sensor, origColor) => {
            dispatch(unitUserChartActions.onSetColorPickerState(props.stateDef, sensor, origColor));
        },
        initializeDefinition: (dashboard, view, cardKey, definition) => {
            dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, definition))
        },
        onChangeCardTitle: (dashboard, view, cardKey, title) => {
            dispatch(appUserConfigActions.onChangeCardTitle(dashboard, view, cardKey, title))
        },
        onCloseCardConfiguration: (dashboard, view, cardKey) => {
          dispatch(unitUserChartActions.onCloseCardConfiguration(dashboard, view, cardKey));
        },
        onDiscardCardConfiguration: (dashboard, view, cardKey) => {
          dispatch(unitUserChartActions.onDiscardCardConfiguration(props.stateDef, dashboard, view, cardKey));
        },
        onSelectTab: (tabIndex) => { dispatch(unitUserChartActions.selectTab(props.stateDef, tabIndex)); },
        onSetAxisAuto: (axisId) => { dispatch(unitUserChartActions.setAxisAuto(props.stateDef, axisId)); },
        onSetAxisPropertyValue: (axisId, property, value) => { dispatch(unitUserChartActions.setAxisPropertyValue(props.stateDef, axisId, property, value)); },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(UnitUserChartConfigPanel))