import _ from 'lodash';

const defnEmpty = () => {
  return {
    // the chart definition can be shared; a subset of the definition
    // should be used as input to the dataExploration component
    name: "",
    author: "",
    type: 'template',

    // first x-Axis id
    primary: {

      // time range
      timeRange: {
        // epoch seconds or null: indicating last N minutes from now
        startTime: null,
        // duration in minutes
        duration: 1440
      },

      // default truck should be used for all new sensors (object must include pid and name)
      defaultTruck: null,

      // trucks, one per sensor
      trucks: [],

      // sensor definitions (from sensor selector) and configuration (objects must include sensorSetId, alias, uom, targetUoms, color, displayName, conditionalFormatting, isVisible)
      sensors: [],
    },
  };
};

const conditionOptionsList = [
  "greater than",
  "less than",
  "between",
  "not between"
];

const dataGridState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    latestValues: _.isNil(state) ? {} : state.latestValues,
    shouldOpenConfigSensorSelector: _.isNil(state) ? false : state.shouldOpenConfigSensorSelector,
    definition: _.isNil(state) ? defnEmpty() : state.definition,
    conditionOptions: _.isNil(state) ? conditionOptionsList : state.conditionOptions,
    colorPickerStates: _.isNil(state) ? [] : state.colorPickerStates
  };

};

export {
  dataGridState
};