import {performCardFetch} from '../../common/fetch'
import _ from "lodash";
import unitChartTypes from './unitChartTypes';
import useSimulationData from '../../../helpers/simulationHelper';
import * as unitChartSimulation from './unitChartSimulation';

const fetchUnitEngineCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitEngineCard')) {
    return unitChartSimulation.fetchUnitEngineCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.ENGINE; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitEngineChart($input:ChartSeriesInput!) { unitEngineChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
      if (!_.isNil(response)) { response.chartType = unitChartTypes.ENGINE; }
      return response;
  });
};

const fetchUnitTransmissionCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitTransmissionCard')) {
    return unitChartSimulation.fetchUnitTransmissionCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.TRANSMISSION; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitTransmissionChart($input:ChartSeriesInput!) { unitTransmissionChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.TRANSMISSION; }
    return response;
  });
};

const fetchUnitPowerEndCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitPowerEndCard')) {
    return unitChartSimulation.fetchUnitPowerEndCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.POWER_END; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitPowerEndChart($input:ChartSeriesInput!) { unitPowerEndChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.POWER_END; }
    return response;
  });
};

const fetchUnitLockUpCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitLockupCard')) {
    return unitChartSimulation.fetchUnitLockupCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.LOCKUP; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitLockupChart($input:ChartSeriesInput!) { unitLockupChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.LOCKUP; }
    return response;
  });
};

const fetchUnitCoilDetailCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitCoilDetailCard')) {
    return unitChartSimulation.fetchUnitCoilDetailCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.COIL_DETAIL; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitCoilDetailChart($input:ChartSeriesInput!) { unitCoilDetailChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.COIL_DETAIL; }
    return response;
  });
};

const fetchUnitCoilCardData = (truckId, startTime, endTime) => {

  if (useSimulationData('unitCoilCard')) {
    return unitChartSimulation.fetchUnitCoilCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.COIL; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitCoilChart($input:ChartSeriesInput!) { unitCoilChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.COIL; }
    return response;
  });
};

const fetchUnitPressureHistory = (truckId, startTime, endTime) => {

  if (useSimulationData('unitPressureHistoryCard')) {
    return unitChartSimulation.fetchUnitPressureHistoryCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.PRESSURE_HISTORY; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitPressureHistoryChart($input:ChartSeriesInput!) { unitPressureHistoryChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.PRESSURE_HISTORY; }
    return response;
  });
};

const fetchUnitOutputHistory = (truckId, startTime, endTime) => {

  if (useSimulationData('unitOutputHistoryCard')) {
    return unitChartSimulation.fetchUnitOutputHistoryCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.OUTPUT_HISTORY; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitOutputHistoryChart($input:ChartSeriesInput!) { unitOutputHistoryChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.OUTPUT_HISTORY; }
    return response;
  });
};

const fetchUnitRoadsidePressureHistory = (truckId, startTime, endTime) => {

  if (useSimulationData('unitRoadsidePressureHistoryCard')) {
    return unitChartSimulation.fetchUnitPressureHistoryRoadsideCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.ROADSIDE_PRESSURE_HISTORY; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitPressureHistoryRoadsideChart($input:ChartSeriesInput!) { unitPressureHistoryRoadsideChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.ROADSIDE_PRESSURE_HISTORY; }
    return response;
  });
};

const fetchUnitCurbsidePressureHistory = (truckId, startTime, endTime) => {

  if (useSimulationData('unitCurbsidePressureHistoryCard')) {
    return unitChartSimulation.fetchUnitPressureHistoryCurbsideCardData(truckId, startTime, endTime)
      .then((response) => {
        if (!_.isNil(response)) { response.chartType = unitChartTypes.CURBSIDE_PRESSURE_HISTORY; }
        return response;
      });
  }

  return performCardFetch({
    'query': 'query unitPressureHistoryCurbsideChart($input:ChartSeriesInput!) { unitPressureHistoryCurbsideChart(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.CURBSIDE_PRESSURE_HISTORY; }
    return response;
  });
};

const fetchUnitVibrationHistory = (truckId, startTime, endTime) => {

  if (useSimulationData('unitVibrationHistoryCard')) {
    return unitChartSimulation.fetchUnitVibrationHistoryCardData(truckId, startTime, endTime)
        .then((response) => {
          if (!_.isNil(response)) { response.chartType = unitChartTypes.VIBRATION_HISTORY; }
          return response;
        });
  }

  return performCardFetch({
    'query': 'query unitVibrationHistory($input:ChartSeriesInput!) { unitVibrationHistory(input:$input) { series { id latest data { timestamp value } } } }',
    'variables': {
      'input': {
        'truckId': truckId,
        'startTime': startTime,
        'endTime': endTime,
        'bucketCount': 400,
      }
    }
  }).then((response) => {
    if (!_.isNil(response)) { response.chartType = unitChartTypes.VIBRATION_HISTORY; }
    return response;
  });
};


export {
  fetchUnitEngineCardData,
  fetchUnitTransmissionCardData,
  fetchUnitPowerEndCardData,
  fetchUnitLockUpCardData,
  fetchUnitCoilDetailCardData,
  fetchUnitCoilCardData,
  fetchUnitPressureHistory,
  fetchUnitOutputHistory,
  fetchUnitRoadsidePressureHistory,
  fetchUnitCurbsidePressureHistory,
  fetchUnitVibrationHistory,
}
