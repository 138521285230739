import tableActionTypes from '../../../common/table/tableActionTypes';
import * as tableService from '../../../common/table/tableService';

const tableReducer = (state, action) => {

  switch (action.type) {

    case tableActionTypes.TABLE_SET_CURRENT_PAGE:
      return { ...state, ...tableService.onSetCurrentPage(state, action) };

    case tableActionTypes.TABLE_SET_ITEMS_PER_PAGE:
      return { ...state, ...tableService.onSetItemsPerPage(state, action) };

    case tableActionTypes.TABLE_SORT_ITEMS:
      return { ...state, ...tableService.onSortItems(state, action) };

    case tableActionTypes.TABLE_ROW_CLICKED:
      return onTableRowClicked(state, action);

    default: return state;

  }
};

const onTableRowClicked = (state, action) => {

  return {
    ...state,
    openDetailsUI: true,
    selectedClient: action.item
  }

};

export default tableReducer