import React, { Component } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import PropTypes from 'prop-types';
import getDetailsPageStyles from "../../common/styles/detailsPageStyles";
import MDTDataGrid from "../../common/table/MDTDataGrid";
import { gridClasses } from "@mui/x-data-grid-pro";
import componentTypes from "../../componentTypes";
import Progress from "../../controls/progress";
import * as routeAssetMgmtActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtDisplayActions";
import * as routeAssetMgmtDeleteDialogActions from "../../../state/displays/routeAssetManagement/actions/routeAssetMgmtDeleteDialogActions";
import RouteAssetManagementDeleteConfirmationDialog from "./routeAssetManagementDeleteConfirmationDialog";
import RouteAssetManagementRouteAssetDialog from "./routeAssetManagementRouteAssetDialog";
import RouteAssetManagementConsumerDialog from "./routeAssetManagementConsumerDialog";
import { routeAssetMgmtState } from "../../../state/displays/routeAssetManagement/routeAssetMgmtSelectors";
import { appState as applicationState } from '../../../state/app/appSelectors';

const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const detailsPageStyles = getDetailsPageStyles();

const styles = {
    ...detailsPageStyles,
    exportAnchor: {
        visibility: 'hidden'
    },
    detailsPageContent: {
        ...detailsPageStyles.detailsPageContent,
        width: '98vw'
    },
    detailsPageHeaderActions: {
        ...detailsPageStyles.detailsPageHeaderActions,
        justifyContent: 'flex-start'
    },
    mdtDataGridContainer: {
        '& .MuiDataGrid-actionsCell': {
            visibility: 'hidden'
        },
        [`& .${gridClasses.row}:hover .MuiDataGrid-actionsCell`]: {
            visibility: 'visible'
        },
    },
    addRouteAssetButton: {
        marginLeft: '5px',
        display: 'flex',
    },
    addRouteAssetIcon: {
        marginRight: '8px',
    },
    rowActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    tableActions: {
        height: '64px',
        width: '100%',
    },
    iconButton: {
        padding: 0,
    },
    dateText: {
        flex: 1,
        textAlign: 'center',
    },
    customToolBar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
};

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            size="small"
            sx={{ textTransform: 'none' }}
          >
            <span>{props.value}</span>
          </Button>
        ) : (
          <span></span>
        )}
      </div>
    </Box>
  );
};

class RouteAssetMgmtPage extends Component {
    constructor(props) {
        super(props);
        this.columns = this.generateTableColumns();
    }

    CustomToolbar = () => {
      return (
        <GridToolbarContainer sx={styles.customToolBar}>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>
          <div>
            <GridToolbarQuickFilter />
          </div>
        </GridToolbarContainer>
      );
    };

    handleDeleteClick = (isConsumer, rowData, params) => {
        if (isConsumer === true) {
            const consumer = this.props.consumers.find(consumer => consumer.consumerName === params.id.split('/')[1]);
            this.props.openDeleteConsumerConfirmation(consumer);
        } else {
            const associatedConsumer = this.props.consumers.find(consumer => consumer.consumerName === rowData.consumerName);
            this.props.openDeleteConfirmation(rowData, associatedConsumer);
        }
    };

    componentDidMount() {
        this.props.loadDisplay();
    }

    generateTableColumns() {
        let columns = [];

        columns.push({
            field: 'consumerName',
            headerName: 'Consumer',
            hide: true,
        });

        columns.push({
            field: 'truckName',
            headerName: 'Truck Name',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false,
            renderCell: (params) => {
              const isConsumer = params.rowNode.depth === 0;
              if (isConsumer === true) {
                return '';
              }
              return params.value;
            }
        });

        columns.push({
          field: 'destinationProject',
          headerName: 'Destination Project',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          editable: false,
          hideable: false,
          pinnable: false,
          sortable: false,
          renderCell: (params) => {
            const isConsumer = params.rowNode.depth === 0 && !_.isNil(params.rowNode.groupingKey);
            if (isConsumer === true) {
                const consumerRow = this.props.consumers.find(consumer =>
                    consumer.consumerName === params.rowNode.groupingKey
                );
                return !_.isNil(consumerRow) ? consumerRow.destinationProject : 'N/A';
            }
            return '';
          }
        });

        columns.push({
          field: 'destinationPath',
          headerName: 'Destination Path',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          editable: false,
          hideable: false,
          pinnable: false,
          sortable: false,
          renderCell: (params) => {
            const isConsumer = params.rowNode.depth === 0 && !_.isNil(params.rowNode.groupingKey);
            if (isConsumer === true) {
                const consumerRow = this.props.consumers.find(consumer =>
                    consumer.consumerName === params.rowNode.groupingKey
                );
                return !_.isNil(consumerRow) ? consumerRow.destinationPath : 'N/A';
            }
            return '';
          }
        });

        columns.push({
          field: 'Actions',
          align: 'center',
          resizable: false,
          hideable: false,
          disableReorder: true,
          type: 'actions',
          getActions: (params) => {
              const actions = [];
              const rowData = params.row;
              const isConsumer = !_.isNil(rowData) && Object.keys(rowData).length === 0;

              if (rowData?.truckName !== 'No assets available') {
                  actions.push(
                      <GridActionsCellItem
                          icon={<EditIcon fontSize={'small'} />}
                          onClick={() => {
                            if (isConsumer === true) {
                                const consumer = this.props.consumers.find(consumer =>
                                  consumer.consumerName === params.id.split('/')[1]
                                );
                                this.props.openEditConsumerDialog(consumer);
                            } else {
                                this.props.openEditDialog(rowData || params);
                            }
                          }}
                          label={isConsumer === true ? "Edit Consumer" : "Edit Asset"}
                          showInMenu
                      />
                  );

                  actions.push(
                      <GridActionsCellItem
                          icon={<DeleteForeverIcon fontSize={'small'} />}
                          onClick={() => this.handleDeleteClick(isConsumer, rowData, params)}
                          label={isConsumer ? "Delete Consumer" : "Delete Asset"}
                          showInMenu
                      />
                  );
              }

              return actions;
          }
        });

        return columns;
    }

    getTreeDataPath = (row) => row.hierarchy;

    groupingColDef = {
      headerName: 'Consumer',
      renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
      sortable: true,
      filterable: false,
      disableColumnMenu: false,
      editable: false,
      hideable: false,
      pinnable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    };

    convertAssetsArray = (assets, consumers) => {
      let mappedAssets = assets.map((asset) => ({
        hierarchy: [asset.consumerName, asset.truckName],
        consumerName: asset.consumerName,
        truckName: asset.truckName,
        destinationProject: asset.destinationProject,
        destinationPath: asset.destinationPath,
        id: asset.id,
      }));

      if (!_.isNil(consumers) && consumers.length > 0) {
        consumers.forEach((consumer) => {
          const consumerHasAsset = mappedAssets.some(
            (asset) => asset.consumerName === consumer.consumerName
          );
          if (consumerHasAsset === false) {
            mappedAssets.push({
              hierarchy: [`${consumer.consumerName}`, 'N/A'],
              consumerName: consumer.consumerName,
              truckName: 'No assets available',
              destinationProject: consumer.destinationProject,
              destinationPath: consumer.destinationPath,
              id: String(consumer.id + 'empty-placeholder'),
            });
          }
        });
      }

      return mappedAssets;
    };

    render() {
        const rows = this.convertAssetsArray(this.props.routeAssets, this.props.consumers);

        return (
            <Box sx={styles.detailsPage}>
                <Box sx={styles.detailsPageContent}>
                    <Box sx={styles.detailsPageHeader}>
                        <Box sx={styles.detailsPageHeaderTitle}>
                            <Typography variant="h6">Asset Routing</Typography>
                        </Box>
                        <Box sx={styles.detailsPageHeaderActions}>
                            <Button onClick={() => this.props.openAddRouteAssetDialog()} sx={styles.addRouteAssetButton} color="inherit">
                                <PersonAddIcon sx={styles.addRouteAssetIcon} />
                                ROUTE AN ASSET
                            </Button>
                            <Button onClick={() => this.props.openAddConsumerDialog()} sx={styles.addRouteAssetButton} color="inherit">
                                <PersonAddIcon sx={styles.addRouteAssetIcon} />
                                ADD CONSUMER
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={styles.mdtDataGridContainer}>
                        <MDTDataGrid
                            apiRef={this.apiRef}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'consumerName', sort: 'asc' }],
                                }
                            }}
                            treeData
                            getTreeDataPath={this.getTreeDataPath}
                            groupingColDef={this.groupingColDef}
                            columns={this.columns}
                            rows={rows}
                            disableSelectionOnClick
                            disableColumnSelector
                            components={{
                                Toolbar: this.CustomToolbar
                            }}
                            stateDef={this.props.stateDef}
                            rowsPerPage={this.props.routeAssetsPerPage}
                            setRowsPerPage={this.props.setRouteAssetsPerPage}
                            setCurrentPage={this.props.setCurrentPage}
                            rowsPerPageOptions={this.props.routeAssetsPerPageOptions}
                        />
                    </Box>
                </Box>

                <RouteAssetManagementRouteAssetDialog open={this.props.openAddRouteAssetUI || this.props.openEditRouteAssetUI} />

                <RouteAssetManagementDeleteConfirmationDialog open={this.props.openDeleteUI} />

                <RouteAssetManagementConsumerDialog />

                <Progress open={this.props.queryRunning} />

            </Box>
        );
    }
}

RouteAssetMgmtPage.propTypes = {
    width: PropTypes.string.isRequired,
};

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? componentTypes.ROUTE_ASSET_MANAGEMENT : props.stateKey,
            type: componentTypes.ROUTE_ASSET_MANAGEMENT,
        }
    };
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = routeAssetMgmtState(state[stateDef.key]);
    let appState = applicationState(state);
    return {
        selectedOwner: appState.selectedOwner,
        queryRunning: componentState.queryRunning,
        routeAssets: componentState.routeAssets,
        consumers: componentState.consumers,
        trucks: componentState.trucks,
        routeAssetsPerPage: componentState.routeAssetsPerPage,
        currentPage: componentState.currentPage,
        workingRouteAsset: componentState.workingRouteAsset,
        workingConsumer: componentState.workingConsumer,
        openAddRouteAssetUI: componentState.openAddRouteAssetUI,
        openEditRouteAssetUI: componentState.openEditRouteAssetUI,
        openDeleteUI: componentState.openDeleteUI,
        user: appState.user,
        routeAssetsPerPageOptions: componentState.routeAssetsPerPageOptions
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        setRouteAssetsPerPage: (routeAssetsPerPage) => dispatch(routeAssetMgmtActions.setRouteAssetsPerPage(props.stateDef, routeAssetsPerPage)),
        setCurrentPage: (currentPage) => dispatch(routeAssetMgmtActions.setCurrentPage(props.stateDef, currentPage)),
        openAddConsumerDialog: () => dispatch(routeAssetMgmtActions.openAddConsumerDialog(props.stateDef)),
        closeAddConsumerDialog: () => dispatch(routeAssetMgmtActions.closeAddConsumerDialog(props.stateDef)),
        openAddRouteAssetDialog: () => {
          dispatch(routeAssetMgmtActions.openAddRouteAssetDialog(props.stateDef));
        },
        setActiveState: () => dispatch(routeAssetMgmtActions.setActiveState(props.stateDef)),
        openEditDialog: (routeAsset) => dispatch(routeAssetMgmtActions.openEditDialog(props.stateDef, routeAsset)),
        openEditConsumerDialog: (consumer) => dispatch(routeAssetMgmtActions.openEditConsumerDialog(props.stateDef, consumer)),
        openDeleteConfirmation: (routeAsset, consumerId) => dispatch(routeAssetMgmtActions.openDeleteConfirmation(props.stateDef, routeAsset, consumerId)),
        openDeleteConsumerConfirmation: (consumer) => dispatch(routeAssetMgmtDeleteDialogActions.openDeleteConsumerConfirmation(props.stateDef, consumer)),
        loadDisplay: () => dispatch(routeAssetMgmtActions.loadDisplay(props.stateDef)),
    };
};

export default compose(
    withWidth(),
    withProps(stateDefinition),
    connect(mapStateToProps, mapDispatchToProps)
)(RouteAssetMgmtPage);
