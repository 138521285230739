import _ from 'lodash';
import { mdtPalette } from "../../../components/common/styles/mdtPalette";

export const fetchSharedSensorGroups = (unitType, namespace) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(getSharedSensorGroups(unitType, namespace)), 1000)
  });
};

export const fetchDeleteSharedSensorGroup = (groupId) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve({ success: true} ), 1000)
  });
};

export const getSharedSensorGroups = (unitType, namespace) => {
  return {sharedSensorGroupsForUnitType: _.filter(testData, {'unitType': unitType, 'namespace': namespace})};
};

const testData = [
  {
    id: 11,
    unitType: 'FracPumper',
    name: 'TransmissionChart',
    description: 'Pump Dashboard Operational Transmission',
    sensors: [
      {sensorSetId: 1384, alias: 'Trans_Oil_Press', uom: 'psi', color: mdtPalette().charting.orange, axisPosition: -1}, // Trans Pressure (psi)
      {sensorSetId: 1615, alias: 'Torque_Conv_Out_Temp', uom: 'F', color: mdtPalette().charting.cyan, axisPosition: 1}, // Trans Temp (F)
      {sensorSetId: 1318, alias: 'Actual_Gear', uom: '#', color: mdtPalette().charting.lightGreen, axisPosition: 2},    // Gear
    ],
    namespace: 'DXP',
  },
  {
    id: 12,
    unitType: 'FracPumper',
    name: 'LockupChart',
    description: 'Pump Dashboard Operational Lockup',
    sensors: [
      {sensorSetId: 1620, alias: 'Lock_Up', uom: 'BOOL', color: mdtPalette().charting.orange, axisPosition: -1},        // Lockup
      {sensorSetId: 1615, alias: 'Torque_Conv_Out_Temp', uom: 'F', color: mdtPalette().charting.cyan, axisPosition: 1}, // Trans Temp (F)
      {sensorSetId: 777, alias: 'RPM', uom: 'RAW', color: mdtPalette().charting.lightGreen, axisPosition: 2},           // Engine (rpm)
    ],
    namespace: 'DXP',
  },
  {
    id: 13,
    unitType: 'Blender',
    name: 'Fake Sensor Group',
    description: 'Fakey McFakerson',
    sensors: [
      {sensorSetId: 0, alias: 'sensor1', uom: 'raw', color: mdtPalette().charting.orange, axisPosition: -2},
      {sensorSetId: 1, alias: 'sensor2', uom: 'raw', color: mdtPalette().charting.pink, axisPosition: -1},
      {sensorSetId: 2, alias: 'sensor3', uom: 'raw', color: mdtPalette().charting.cyan, axisPosition: 1},
      {sensorSetId: 3, alias: 'sensor4', uom: 'raw', color: mdtPalette().charting.lightGreen, axisPosition: 2},
    ],
    namespace: 'DXP',
  },
  {
    id: 14,
    unitType: 'FracPumper',
    name: 'TransmissionChart',
    description: 'Pump Dashboard Operational Transmission',
    sensors: [
      {sensorSetId: 1384, alias: 'TRANS.LUBE_PRESS', uom: 'psi', color: mdtPalette().charting.orange, axisPosition: -1}, // Trans Pressure (psi)
      {sensorSetId: 1615, alias: 'TEMP_TRANS_TC_01', uom: 'F', color: mdtPalette().charting.cyan, axisPosition: 1}, // Trans Temp (F)
      {sensorSetId: 1318, alias: 'POWERTRAIN1.TRANS_LOGICAL_CURRENT_GEAR', uom: '#', color: mdtPalette().charting.lightGreen, axisPosition: 2},    // Gear
    ],
    namespace: 'MDT',
  },
  {
    id: 15,
    unitType: 'FracPumper',
    name: 'LockupChart',
    description: 'Pump Dashboard Operational Lockup',
    sensors: [
      {sensorSetId: 1620, alias: 'POWERTRAIN1.TRANS_TORQUE_CONVERTER_LOCKUP_STATE', uom: 'BOOL', color: mdtPalette().charting.orange, axisPosition: -1},        // Lockup
      {sensorSetId: 1615, alias: 'TEMP_TRANS_TC_01', uom: 'F', color: mdtPalette().charting.cyan, axisPosition: 1}, // Trans Temp (F)
      {sensorSetId: 777, alias: 'ENGINE1.EEC1.ES', uom: 'RPM', color: mdtPalette().charting.lightGreen, axisPosition: 2},           // Engine (rpm)
    ],
    namespace: 'MDT',
  },
  {
    id: 16,
    unitType: 'Blender',
    name: 'Fake Sensor Group',
    description: 'Fakey McFakerson',
    sensors: [
      {sensorSetId: 0, alias: 'sensor1_MDT', uom: 'raw', color: mdtPalette().charting.orange, axisPosition: -2},
      {sensorSetId: 1, alias: 'sensor2_MDT', uom: 'raw', color: mdtPalette().charting.pink, axisPosition: -1},
      {sensorSetId: 2, alias: 'sensor3_MDT', uom: 'raw', color: mdtPalette().charting.cyan, axisPosition: 1},
      {sensorSetId: 3, alias: 'sensor4_MDT', uom: 'raw', color: mdtPalette().charting.lightGreen, axisPosition: 2},
    ],
    namespace: 'MDT',
  },
];
