const getTableFilterStyles = () => ({

  tableFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  },

  tableFilterComponent: {
    marginLeft: 1,
  },

});

export default getTableFilterStyles;