import React, {Component} from 'react';
import { Box } from '@mui/material';

const styles = {
  wellHead: {
    paddingTop: 2,
    paddingBottom: 1,
  }
};

class BlankWellHead extends Component {
  render() {
    return (
      <Box sx={styles.wellHead}>
        <svg height='80' width='80'>
          <circle cx='40' cy='40' r='40' fill='#9E9E9E'/>
        </svg>
      </Box>
    );
  }
}

export default BlankWellHead;