import _ from "lodash";
import providerTypes from '../../../../components/common/providerTypes';

const constructConfigString = (providerType, configs) => {
  if(providerType === providerTypes.TEAMS){
    const channels = {};
    channels.channels = _.map(configs.teamsChannels, (channel) => {
      return {
        name: channel.name,
        webhook: channel.webhook,
      };
    });
    return JSON.stringify(channels);

  }else if(providerType === providerTypes.TWILIO){
    const twilioConfig = {
      accountSid: configs.twilioAcctSid,
      authToken: configs.twilioAuthToken,
      from: configs.twilioFromNumber,
    };
    return JSON.stringify(twilioConfig);

  }
  return null;
}

export {
  constructConfigString,
}