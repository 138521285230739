import defaultActionFactory from '../../common/factories/defaultActionFactory';
import filterActionTypes from './filterActionTypes';
import _ from "lodash";
import componentTypes from "../../../components/componentTypes";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";

const openFilterDialog = defaultActionFactory(filterActionTypes.FILTERS_OPEN_FILTER_DIALOG, 'stateDef');
const closeFilterDialog = defaultActionFactory(filterActionTypes.FILTERS_CLOSE_FILTER_DIALOG, 'stateDef');
const onAddFilter = defaultActionFactory(filterActionTypes.FILTERS_ADD_FILTER, 'stateDef', 'filter');
const onDeleteFilter = defaultActionFactory(filterActionTypes.FILTERS_DELETE_FILTER, 'stateDef', 'index');
const showFilterTypes = defaultActionFactory(filterActionTypes.FILTERS_SHOW_FILTER_TYPES, 'stateDef');
const searchFilterValues = defaultActionFactory(filterActionTypes.FILTERS_SEARCH_FILTER_VALUES, 'stateDef', 'searchValue');
const onSetAppliedFilters = defaultActionFactory(filterActionTypes.FILTERS_SET_APPLIED_FILTERS, 'stateDef', 'appliedFilters');

// list of pages with user configurations stored in backend DB
const PAGES_SAVE_USER_CONFIG = [componentTypes.FLEET_OVERVIEW, componentTypes.CLIENT_STATUS, componentTypes.JOB_OVERVIEW];

//Common function to update user configuration for page (stateDef.key) in PAGES_SAVE_USER_CONFIG list
const updateUserConfigForPage = async (stateDef, getState, dispatch) => {
    const userId = getState()["app"].user.userId;
    if (PAGES_SAVE_USER_CONFIG.includes(stateDef.key) && !_.isEmpty(userId)) {
        await dispatch(appUserConfigActions.saveUserConfigurationForPage(stateDef, userId, stateDef.key, getState()[stateDef.key].appliedFilters));
    }
}

const addFilter = (stateDef, filter) => {
    return async (dispatch, getState) => {
      await dispatch(onAddFilter(stateDef, filter));
      //update user config for the page after appliedFilter change
      await updateUserConfigForPage(stateDef, getState, dispatch);

    }
}

const deleteFilter = (stateDef, index) => {
    return async (dispatch, getState) => {
        await dispatch(onDeleteFilter(stateDef, index));
        //update user config for the page after appliedFilter change
        await updateUserConfigForPage(stateDef, getState, dispatch);
    }
}


export {
  openFilterDialog,
  closeFilterDialog,
  addFilter,
  deleteFilter,
  showFilterTypes,
  searchFilterValues,
  onSetAppliedFilters
};