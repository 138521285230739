
const defaultActionFactory = (type, ...argNames) => {
  return function (...args) {
    // Generate the action object
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    });
    // Return back the function that the thunk middleware will call.
    return (dispatch, getState) => {
      return Promise.resolve(
        dispatch(action)
      )
    }
  }
};

export default defaultActionFactory