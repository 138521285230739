import _ from 'lodash';

const fleetPumpStatusState = state => {

  const noValue = '-';

  return {

    queryRunning : _.isNil(state) ? false : state.queryRunning,

    rawValueCount: _.isNil(state) ? null : state.rawValueCount,
    rawValuePumping: _.isNil(state) ? null : state.rawValuePumping,
    rawValueIdle: _.isNil(state) ? null : state.rawValueIdle,
    rawValueStopped: _.isNil(state) ? null : state.rawValueStopped,
    rawValueOffline: _.isNil(state) ? null : state.rawValueOffline,

    updateChartOptions: _.isNil(state) ? false : state.updateChartOptions,
    chartOptions: _.isNil(state) ? null : state.chartOptions,

    displayValueCount: _.isNil(state) ? noValue : state.displayValueCount,
    displayValuePumping: _.isNil(state) ? noValue : state.displayValuePumping,
    displayValueIdle: _.isNil(state) ? noValue : state.displayValueIdle,
    displayValueStopped: _.isNil(state) ? noValue : state.displayValueStopped,
    displayValueOffline: _.isNil(state) ? noValue : state.displayValueOffline,

  };

};

export {
  fleetPumpStatusState
};