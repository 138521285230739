export default {
    FLEET_MGMT_QUERY_FLEETS_STARTING: "FLEET_MGMT_QUERY_FLEETS_STARTING",
    FLEET_MGMT_QUERY_FLEETS_SUCCESS: "FLEET_MGMT_QUERY_FLEETS_SUCCESS",
    FLEET_MGMT_QUERY_FLEETS_ERROR: "FLEET_MGMT_QUERY_FLEETS_ERROR",
    FLEET_MGMT_QUERY_OWNERS_STARTING: "FLEET_MGMT_QUERY_OWNERS_STARTING",
    FLEET_MGMT_QUERY_OWNERS_SUCCESS: "FLEET_MGMT_QUERY_OWNERS_SUCCESS",
    FLEET_MGMT_QUERY_OWNERS_ERROR: "FLEET_MGMT_QUERY_OWNERS_ERROR",

    FLEET_MGMT_SAVE_FLEET_STARTING: "FLEET_MGMT_SAVE_FLEET_STARTING",
    FLEET_MGMT_SAVE_FLEET_SUCCESS: "FLEET_MGMT_SAVE_FLEET_SUCCESS",
    FLEET_MGMT_SAVE_FLEET_ERROR: "FLEET_MGMT_SAVE_FLEET_ERROR",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR",

    FLEET_MGMT_SET_ROWS_PER_PAGE: "FLEET_MGMT_SET_ROWS_PER_PAGE",

    FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG: "FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG",
    FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG: "FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG",
    FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG: "FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG",
    FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG: "FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG",
    FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG: "FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG",
    FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG: "FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG",
    FLEET_MGMT_OPEN_COPY_TO_CLIPBOARD_NOTIFICATION: "FLEET_MGMT_OPEN_COPY_TO_CLIPBOARD_NOTIFICATION",
    FLEET_MGMT_CLOSE_COPY_TO_CLIPBOARD_NOTIFICATION: "FLEET_MGMT_CLOSE_COPY_TO_CLIPBOARD_NOTIFICATION",
    FLEET_MGMT_CHANGE_FLEET_DATA: "FLEET_MGMT_CHANGE_FLEET_DATA",
    FLEET_MGMT_CHANGE_MOVE_FLEET_DATA: "FLEET_MGMT_CHANGE_MOVE_FLEET_DATA",

    FLEET_MGMT_MOVE_FLEET_STARTING: "FLEET_MGMT_MOVE_FLEET_STARTING",
    FLEET_MGMT_MOVE_FLEET_SUCCESS: "FLEET_MGMT_MOVE_FLEET_SUCCESS",
    FLEET_MGMT_MOVE_FLEET_ERROR: "FLEET_MGMT_MOVE_FLEET_ERROR",

    FLEET_MGMT_SET_SAVE_FLEET_TAB_INDEX: "FLEET_MGMT_SET_SAVE_FLEET_TAB_INDEX",
    FLEET_MGMT_UPDATE_DATASOURCE: "FLEET_MGMT_UPDATE_DATASOURCE",
    FLEET_MGMT_REMOVE_DATASOURCE: "FLEET_MGMT_REMOVE_DATASOURCE",
    FLEET_MGMT_CONFIRM_REMOVE_DATASOURCE: "FLEET_MGMT_CONFIRM_REMOVE_DATASOURCE",
    FLEET_MGMT_RESTORE_DATASOURCE: "FLEET_MGMT_RESTORE_DATASOURCE",
    FLEET_MGMT_EXPAND_DATASOURCE: "FLEET_MGMT_EXPAND_DATASOURCE",
    
    FLEET_MGMT_SHOW_RESTART_PRODUCER_DIALOG: 'FLEET_MGMT_SHOW_RESTART_PRODUCER_DIALOG',
    FLEET_MGMT_CONFIRM_RESTART_PRODUCER: 'FLEET_MGMT_CONFIRM_RESTART_PRODUCER',
    FLEET_MGMT_CONFIRM_RESTART_PRODUCER_ERROR: 'FLEET_MGMT_CONFIRM_RESTART_PRODUCER_ERROR',

    FLEET_MGMT_GET_DATASOURCES_STARTING: 'FLEET_MGMT_GET_DATASOURCES_STARTING',
    FLEET_MGMT_GET_DATASOURCES_SUCCESS: 'FLEET_MGMT_GET_DATASOURCES_SUCCESS',
    FLEET_MGMT_GET_DATASOURCES_ERROR: 'FLEET_MGMT_GET_DATASOURCES_ERROR',

    FLEET_MGMT_SET_SAVE_DATASOURCES_STATUS: 'FLEET_MGMT_SET_SAVE_DATASOURCES_STATUS',
    FLEET_MGMT_SET_SAVE_FLEET_STATUS: 'FLEET_MGMT_SET_SAVE_FLEET_STATUS',
    FLEET_MGMT_SET_FLEET_TO_SAVE_ID: 'FLEET_MGMT_SET_FLEET_TO_SAVE_ID',

};
