import reduceReducers from 'reduce-reducers';

import displayReducer from './reducers/displayReducer';
import filterReducer from './reducers/filterReducer';
import tableReducer from './reducers/tableReducer';
import queryReducer from './reducers/queryReducer';
import exportCsvReducer from '../../common/exportCsv/exportCsvReducer';
import userConfigReducer from '../../app/reducers/userConfigReducer';

import { alarmsState } from './alarmsSelectors';

const initialState = alarmsState();

const alarmsReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    displayReducer,
    filterReducer,
    tableReducer,
    queryReducer,
    exportCsvReducer,
    userConfigReducer
  );

  return reducer(state, action);
};

export default alarmsReducer
