import _ from "lodash";
import React, {Component} from "react";
import {DialogContent, DialogTitle, Dialog, TextField, DialogActions, Button} from "@mui/material";
import ComponentTypes from "../../componentTypes";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {wellsDisplayState} from "../../../state/displays/wellsDisplay/wellsDisplaySelectors";
import * as wellsDisplayAction from "../../../state/displays/wellsDisplay/wellsDisplayActions";
import PropTypes from "prop-types";
import getDialogStyles from "../../common/styles/dialogStyles";
import { hasWellChanged } from "../../../state/displays/wellsDisplay/services/wellsDisplayServices";

const dialogStyles = getDialogStyles();

const styles = {
    formField: {
        marginTop: 2
    }
};
class SaveWellDialog extends Component {
    render() {
        return (
         <Dialog
                open={this.props.openSaveWell}
                disableEscapeKeyDown>
                <DialogTitle>{this.props.wellForSave.id ? 'Edit' : 'Create'} Well</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={styles.formField}
                        autoFocus
                        variant='standard'
                        size='small'
                        helperText="Name"
                        inputProps={{maxLength: 255}}
                        value={this.props.wellForSave.name || ''}
                        onChange={(event) => {
                            this.props.onValidateAndChange(event.target.value, 'name');
                        }}
                        error={this.props.errorsOnWellSave.hasNameError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="API Number"
                        inputProps={{maxLength: 255}}
                        value={this.props.wellForSave.apiNumber || ''}
                        onChange={(event) => {
                            this.props.onWellChange(event.target.value, 'apiNumber');
                        }}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Lease Name"
                        inputProps={{maxLength: 255}}
                        value={this.props.wellForSave.leaseName || ''}
                        onChange={(event) => {
                            this.props.onWellChange(event.target.value, 'leaseName');
                        }}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Lease Number"
                        inputProps={{maxLength: 255}}
                        value={this.props.wellForSave.leaseNumber || ''}
                        onChange={(event) => {
                            this.props.onWellChange(event.target.value, 'leaseNumber');
                        }}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Pad Name"
                        inputProps={{maxLength: 255}}
                        value={this.props.wellForSave.padName || ''}
                        onChange={(event) => {
                            this.props.onWellChange(event.target.value, 'padName');
                        }}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Latitude"
                        inputProps={{maxLength: 10}}
                        value={this.props.wellForSave.latitude || ''}
                        onChange={(event) => {
                            this.props.onValidateAndChange(event.target.value, 'latitude');
                        }}
                        error={this.props.errorsOnWellSave.hasLatitudeError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Longitude"
                        inputProps={{maxLength: 11}}
                        value={this.props.wellForSave.longitude || ''}
                        onChange={(event) => {
                            this.props.onValidateAndChange(event.target.value, 'longitude');
                        }}
                        error={this.props.errorsOnWellSave.hasLongitudeError}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button sx={dialogStyles.actionButton} variant={'contained'}
                            onClick={this.props.onCloseDialog}>Cancel</Button>
                    <Button variant={'contained'} color='primary' onClick={() => this.props.onSaveWell(this.props.wellForSave)}
                            disabled={!_.isEmpty(Object.keys(this.props.errorsOnWellSave)) || !hasWellChanged(this.props.wellForSave, this.props.originalWellForSave)}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SaveWellDialog.propTypes = {
    isCreate: PropTypes.bool
};

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.WELLS_DISPLAY : props.stateKey,
            type: ComponentTypes.WELLS_DISPLAY,
        }
    };
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = wellsDisplayState(state[stateDef.key]);

    return {
        wellForSave: componentState.wellForSave,
        errorsOnWellSave: componentState.errorsOnWellSave,
        openSaveWell: componentState.openSaveWell,
        originalWellForSave: componentState.originalWellForSave,
        canDiscardSave: componentState.canDiscardSave
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onValidateAndChange: (value, field) => dispatch(wellsDisplayAction.onWellValidateAndChange(props.stateDef, value, field)),
        onWellChange: (value, field) => dispatch(wellsDisplayAction.onWellChange(props.stateDef, value, field)),
        onCloseDialog: () => dispatch(wellsDisplayAction.onSaveWellDialogClose(props.stateDef)),
        onSaveWell: (wellToSave) => dispatch(wellsDisplayAction.onSaveWell(props.stateDef, wellToSave))
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(SaveWellDialog));