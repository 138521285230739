import {gridClasses} from "@mui/x-data-grid-pro";

const getCardStyles = () => ({
  cardContainer: {
    backgroundColor: 'background.paper',
    borderRadius: '4px',
  },
  cardHeader: {
    marginTop: 1,
    marginLeft: 1,
    marginRight: 1,
    borderStyle: 'solid',
    borderBottomWidth: '1px',
    borderTopWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderBottomColor: 'primary.main',
  },
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  mdtDataGridContainer: {
    display:'flex',
    flexFlow:'column noWrap',
    overflow: 'auto',
    height: '90%',
    width: '95%',
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible',
      },
    },
    [`& .${gridClasses.row}`]: {
      '.MuiDataGrid-cell': {
        paddingTop:'5px',
        paddingBottom:'20px',
        justifyContent: 'center',
      },
    },
    '& .dataGridTableCell': {
      display:'flex',
      flexFlow:'column noWrap',
      fontWeight: '400',
      overflowWrap: 'anywhere',
    },
    '& .dataGridTableCellLeftAlign': {
      display:'flex',
      flexFlow:'column noWrap',
      fontWeight: '400',
      overflowWrap: 'anywhere',
      flexDirection: 'row',
      '&.MuiDataGrid-cell': {
        justifyContent: 'flex-start',
      }
    },

  },
});

export default getCardStyles;