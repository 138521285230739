import _ from 'lodash';
import * as unitChartService from '../../../common/services/unitChartService';

const PRESSURE = 'pressure';
const FLOW_RATE = 'flowRate';
const CONCENTRATION = 'concentration';

const transformSeries = (data) => {
  
  let transformedSeries = {
    pressure: null,
    flowRate: null,
    concentration: null,
    latestPressure: null,
    latestFlowRate: null,
    latestConcentration: null
  };

  let pressureSeries = _.find(data, ['id', PRESSURE]);
  let transformedPressureSeries = unitChartService.transformSeries(pressureSeries, 1);
  transformedSeries.pressure = transformedPressureSeries.data;
  transformedSeries.latestPressure = transformedPressureSeries.latest;

  let flowRateSeries = _.find(data, ['id', FLOW_RATE]);
  let transformedFlowRateSeries = unitChartService.transformSeries(flowRateSeries, 1);
  transformedSeries.flowRate = transformedFlowRateSeries.data;
  transformedSeries.latestFlowRate = transformedFlowRateSeries.latest;

  let concentrationSeries = _.find(data, ['id', CONCENTRATION]);
  let transformedConcentrationSeries = unitChartService.transformSeries(concentrationSeries, 1);
  transformedSeries.concentration = transformedConcentrationSeries.data;
  transformedSeries.latestConcentration = transformedConcentrationSeries.latest;

  return transformedSeries;
};

export {
  transformSeries
}