import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const getDefaultEmptyQuery = () => {
  return {
    id: uuidv4(),
    rules:[],
    not: false
  }
}

const getDefaultTimeFrame = () => { return { value: 360, label: 'Last 6 hours' } };
const getDefaultCustomDuration = () => { return { value: 360, label: '6 hours' } };

const getTimeFrames = () => {
  return [
    { value: 60, label: 'Last hour' },
    { value: 360, label: 'Last 6 hours' },
    { value: 720, label: 'Last 12 hours' },
    { value: 1440, label: 'Last 24 hours' },
    { value: null, label: 'Custom' },
  ]
}

const getCustomDurations = () => {
  return [
    { value: 60, label: '1 hour' },
    { value: 360, label: '6 hours' },
    { value: 720, label: '12 hours' },
    { value: 1440, label: '24 hours' },
  ]
}

const auditLogsState = (state) => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    auditLogs: _.isNil(state) ? [] : state.auditLogs,

    availableFields: _.isNil(state) ? [] : state.availableFields,
    usedFields: _.isNil(state) ? [] : state.usedFields,
    combinators: _.isNil(state) ? [] : state.combinators,

    query: _.isNil(state) ? getDefaultEmptyQuery() : state.query,
    page: _.isNil(state) ? 0 : state.page,
    rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
    rowsPerPageOptions: [25,50,100],
    sortModel: _.isNil(state) ? [{field:'timestamp', sort:'desc'}] : state.sortModel,

    shouldDisableGrid: _.isNil(state) ? false : state.shouldDisableGrid,

    timeFrames: _.isNil(state) ? getTimeFrames() : state.timeFrames,
    customDurations: _.isNil(state) ? getCustomDurations() : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? getDefaultTimeFrame() : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? getDefaultCustomDuration() : state.selectedCustomDuration,
    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    shouldExpandQueryPanel: _.isNil(state) ? false : state.shouldExpandQueryPanel,
    isQueryValid: _.isNil(state) ? false : state.isQueryValid,
  }
}

export {
  auditLogsState,
  getDefaultEmptyQuery,
  getDefaultTimeFrame,
  getDefaultCustomDuration
}