import React, {Component} from 'react';
import { Typography, Box, Grid } from '@mui/material';

const styles = {
  page: {
    width: '100%',
    maxWidth: (theme) => theme.breakpoints.values.md,
    paddingTop: 16,
    paddingLeft: 2,
    paddingRight: 2
  }
};

class NotSupportedPage extends Component {
  render() {
    return (
      <Grid container justifyContent={'center'}>
        <Box sx={styles.page}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h5'} align={'center'} noWrap >
                Browser Compatibility
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align={'center'} noWrap >
                The MDT Cloud Portal has known compatibility problems with this browser
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align={'center'} noWrap >
                The portal has been optimized and verified with the following browsers
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} align={'center'} noWrap >Google Chrome</Typography>
              <Typography variant={'subtitle1'} align={'center'} noWrap >Mozilla Firefox</Typography>
              <Typography variant={'subtitle1'} align={'center'} noWrap >Apple Safari</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

export default NotSupportedPage;