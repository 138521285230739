import moment from 'moment';

const fetchClientStatus = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                clientStatus: generateClientStatus()
            })
        }, 1000)
    });
};

const fetchClientDetail = (fleetId) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                clientDetail: generateClientDetails(fleetId)
            })
        }, 1000)
    });
};

const generateClientStatus = () => {
    let data = [];
    let clientStatusData1 = {
        fleetId: 20,
        fleetIdentifier: "f1fc56f5-7b3d-464c-aaab-fb268ef0a07f",
        fleetName: "SIM10",
        region: null,
        status: "Online",
        isUploading: true,
        dataUploadStatus: "Behind",
        datasourceStatus: "Online",
        division: null,
    };
    let clientStatusData2 = {
        fleetId: 7,
        fleetIdentifier: "3e650fe4-9087-44c5-aac9-92fcec5ac5a0",
        fleetName: "SIM-8",
        region: "West",
        status: "Online",
        isUploading: true,
        dataUploadStatus: "Current",
        datasourceStatus: "Online",
        division: 'Frac'
    };
    let clientStatusData3 = {
        fleetId: 1,
        fleetIdentifier: "6f83e64f-39c4-4f8e-b5cc-5424faa96315",
        fleetName: "SIM-4",
        region: "West",
        status: "Online",
        isUploading: true,
        dataUploadStatus: "Unknown",
        datasourceStatus: "Online"
    };
    let clientStatusData4 = {
        fleetId: 4,
        fleetIdentifier: "abc099dc-de7d-4b1a-b475-7bf50093ee68",
        fleetName: "SIM-1",
        region: "South",
        status: "Offline",
        isUploading: false,
        dataUploadStatus: "Unknown",
        datasourceStatus: "Offline",
        division: 'Power Solutions'
    };
    data.push(clientStatusData1);
    data.push(clientStatusData2);
    data.push(clientStatusData3);
    data.push(clientStatusData4);
    return data;
};

const generateClientDetails = (fleetId) => {
    let data = null;
    switch(fleetId) {
        // SIM10
        case 20: {
            data = {
                fleetIdentifier: "f1fc56f5-7b3d-464c-aaab-fb268ef0a07f",
                fleetName: "SIM10",
                status: "Online",
                isUploading: true,
                dataUploadStatus: "Behind",
                producerVersion: "2.0.5",
                lastContactSensor: moment().subtract(1, 'hour').format('X'),
                lastContactEdge: moment().subtract(10, 'minute').format('X'),
                computer: "790025MAINDAQ",
                datasources: [
                    {
                        id: 36,
                        name: "MPC HMI",
                        status: "Online",
                        lastContact: moment().subtract(10, 'minute').format('X'),
                        lastSensorUpload: moment().subtract(1, 'hour').format('X'),
                        lastSensorDataAge: moment().subtract(1, 'hour').format('X'),
                        database: "mdtmpc",
                        samplesRemaining: 6,
                        truckDetails: [
                            {
                                id: 1314,
                                name: "921257",
                                type: "CoilTubingUnit",
                                lastSensorUpload: moment().subtract(1, 'hour').format('X'),
                                lastSensorDataAge: moment().subtract(1, 'hour').format('X'),
                            },
                        ]
                    }
                ]
            };
            break;
        }
        //SIM-8
        case 7: {
            data = {
                fleetIdentifier: "3e650fe4-9087-44c5-aac9-92fcec5ac5a0",
                fleetName: "SIM-8",
                status: "Online",
                isUploading: true,
                dataUploadStatus: "Current",
                producerVersion: "2.0.5",
                lastContactSensor: moment().subtract(1, 'second').format('X'),
                lastContactEdge: moment().subtract(1, 'second').format('X'),
                computer: "790018MAINDAQ",
                datasources: [
                    {
                        id: 25,
                        name: "MPC HMI",
                        status: "Online",
                        lastContact: moment().subtract(1, 'second').format('X'),
                        lastSensorUpload: moment().subtract(1, 'second').format('X'),
                        lastSensorDataAge: moment().subtract(1, 'second').format('X'),
                        database: "mdtmpc",
                        samplesRemaining: 7607,
                        truckDetails: [
                            {
                                id: 1276,
                                name: "620263",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(1, 'second').format('X'),
                                lastSensorDataAge: moment().subtract(1, 'second').format('X'),
                            },
                            {
                                id: 1214,
                                name: "620513",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(1, 'second').format('X'),
                                lastSensorDataAge: moment().subtract(1, 'second').format('X'),
                            },
                        ]
                    },
                    {
                        id: 26,
                        name: "Treater HMI",
                        status: "Online",
                        lastContact: moment().subtract(1, 'second').format('X'),
                        lastSensorUpload: moment().subtract(1, 'second').format('X'),
                        lastSensorDataAge: moment().subtract(1, 'second').format('X'),
                        database: "mdtspy",
                        samplesRemaining: 5760,
                        truckDetails: [
                            {
                                id: 412,
                                name: "750058",
                                type: "Blender",
                                lastSensorUpload: moment().subtract(1, 'second').format('X'),
                                lastSensorDataAge: moment().subtract(1, 'second').format('X'),
                            },
                        ]
                    }
                ]
            };
            break;
        }
        //SIM-4
        case 1: {
            data = {
                fleetIdentifier: "6f83e64f-39c4-4f8e-b5cc-5424faa96315",
                fleetName: "SIM-4",
                status: "Online",
                isUploading: true,
                dataUploadStatus: "Behind",
                producerVersion: "2.0.5",
                lastContactSensor: moment().subtract(2, 'day').format('X'),
                lastContactEdge: moment().subtract(2, 'day').format('X'),
                computer: "790009-MAINDAQ",
                datasources: [
                    {
                        id: 15,
                        name: "MPC HMI",
                        status: "Online",
                        lastContact: moment().subtract(2, 'day').format('X'),
                        lastSensorUpload: moment().subtract(2, 'day').format('X'),
                        lastSensorDataAge: moment().subtract(2, 'day').format('X'),
                        database: "mdtmpc",
                        samplesRemaining: 306070,
                        truckDetails: [
                            {
                                id: 1176,
                                name: "620369",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(2, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(2, 'day').format('X'),
                            },
                            {
                                id: 1214,
                                name: "620357",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(2, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(2, 'day').format('X'),
                            },
                        ]
                    },
                    {
                        id: 16,
                        name: "Treater HMI",
                        status: "Online",
                        lastContact: moment().subtract(2, 'day').format('X'),
                        lastSensorUpload: moment().subtract(2, 'day').format('X'),
                        lastSensorDataAge: moment().subtract(2, 'day').format('X'),
                        database: "mdtspy",
                        samplesRemaining: 150760,
                        truckDetails: [
                            {
                                id: 312,
                                name: "790009",
                                type: "Datavan_Main",
                                lastSensorUpload: moment().subtract(2, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(2, 'day').format('X'),
                            },
                        ]
                    }
                ]
            };
            break;
        }
        //SIM-1
        case 4: {
            data = {
                fleetIdentifier: "abc099dc-de7d-4b1a-b475-7bf50093ee68",
                fleetName: "SIM-1",
                status: "Offline",
                isUploading: false,
                dataUploadStatus: "Unknown",
                producerVersion: "2.0.5",
                lastContactSensor: moment().subtract(14, 'day').format('X'),
                lastContactEdge: moment().subtract(14, 'day').format('X'),
                computer: "790023-MAINDAQ",
                datasources: [
                    {
                        id: 46,
                        name: "MPC HMI",
                        status: "Offline",
                        lastContact: moment().subtract(14, 'day').format('X'),
                        lastSensorUpload: moment().subtract(14, 'day').format('X'),
                        lastSensorDataAge: moment().subtract(14, 'day').format('X'),
                        database: "mdtmpc",
                        samplesRemaining: 0,
                        truckDetails: [
                            {
                                id: 1076,
                                name: "620044",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(14, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(14, 'day').format('X'),
                            },
                            {
                                id: 1114,
                                name: "620461",
                                type: "FracPumper",
                                lastSensorUpload: moment().subtract(14, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(14, 'day').format('X'),
                            },
                        ]
                    },
                    {
                        id: 47,
                        name: "Treater HMI",
                        status: "Offline",
                        lastContact: moment().subtract(14, 'day').format('X'),
                        lastSensorUpload: moment().subtract(14, 'day').format('X'),
                        lastSensorDataAge: moment().subtract(14, 'day').format('X'),
                        database: "mdtspy",
                        samplesRemaining: 0,
                        truckDetails: [
                            {
                                id: 212,
                                name: "790023",
                                type: "Datavan_Main",
                                lastSensorUpload: moment().subtract(14, 'day').format('X'),
                                lastSensorDataAge: moment().subtract(14, 'day').format('X'),
                            },
                        ]
                    }
                ]
            };
            break;
        }
        default: break;
    }
    return data;
};

export {
    fetchClientStatus,
    fetchClientDetail
};