import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import fleetMgmtActionTypes from "../fleetMgmtActionTypes";
import {fleetMgmtState} from "../fleetMgmtSelectors";
import {validateFleetChanges, validateMoveFleetChanges, datasourcesHaveChanges, processDatasource} from "../services/fleetMgmtService";
import errorMessages from "../../../common/errorMessages";

const initialState = fleetMgmtState();

const displayReducer = (state = initialState, action) => {
    switch (action.type) {
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_STARTING:
            return onQueryFleetsStarting(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_SUCCESS:
            return onQueryFleetsSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_ERROR:
            return onQueryFleetsError(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING:
            return {
                ...state,
                queryRunning: true
            };
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_SUCCESS:
            return onQueryOwnersSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR:
            return {
                ...state,
                queryRunning: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_SUCCESS:
            return {
                ...state,
                queryRunning: false,
                openSaveFleet: false,
                openToggleFleetConfirmation: false,
                saveStatus: {
                  fleetOk: null,
                  datasourcesOk: null
                },
                fleetToSave: {},
                originalFleet: {},
                fleetHasChanges: false,
                datasources: [],
                originalDatasources: [],
                splitButtonSelectedIndex: 0,
                datasourcesChanged: false,
                saveFleetTabIndex: 0,
                errorsOnFleetSave: {}
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CHANGE_FLEET_DATA:
            return onChangeFleet(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_CHANGE_MOVE_FLEET_DATA:
            return onChangeMoveFleet(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG:
            return {
                ...state,
                fleetToSave: action.fleetToSave,
                originalFleet: action.fleetToSave,
                errorsOnFleetSave: validateFleetChanges(action.fleetToSave, state.datasources),
                openSaveFleet: true,
                originalDatasources: _.cloneDeep(state.datasources),
                warningDatavanOnFleetSave: false,
                datavanNameHelperText: 'Datavan',
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG:
            return {
                ...state,
                openSaveFleet: false,
                errorsOnFleetSave: {},
                datasources: [],
                originalDatasources: [],
                saveFleetTabIndex: 0,
                datasourcesChanged: false,
                splitButtonSelectedIndex: 0,
                fleetToSave: {},
                originalFleet: {},
                saveStatus: {
                  fleetOk: null,
                  datasourcesOk: null
                },
                fleetHasChanges: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG:
            return {
                ...state,
                openToggleFleetConfirmation: true,
                fleetToSave: action.fleet,
                originalFleet: action.fleet,
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG:;
            return {
                ...state,
                openToggleFleetConfirmation: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG:
            return {
                ...state,
                fleetToMove: action.fleet,
                errorsOnFleetMove: validateMoveFleetChanges(action.fleet),
                openMoveFleetDialog: true
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG:
            return {
                ...state,
                openMoveFleetDialog: false
            }
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_SUCCESS:
            return {
                ...state,
                queryRunning: false,
                openMoveFleetDialog: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.rowSize,
            };
        case fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_FLEET_TAB_INDEX:
          return onSetSaveFleetTabIndex(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_UPDATE_DATASOURCE:
          return onUpdateDatasource(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_REMOVE_DATASOURCE:
          return onRemoveDatasource(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_RESTORE_DATASOURCE:
          return onRestoreDatasource(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_REMOVE_DATASOURCE:
          return onConfirmRemoveDatasource(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_EXPAND_DATASOURCE:
          return onExpandDatasource(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_SHOW_RESTART_PRODUCER_DIALOG:
          return onShowRestartProducerDialog(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_RESTART_PRODUCER:
        case fleetMgmtActionTypes.FLEET_MGMT_CONFIRM_RESTART_PRODUCER_ERROR:
          return {
            ...state,
            showRestartProducerDialog: false,
            fleetToSave: {},
            originalFleet: {},
          }
        case fleetMgmtActionTypes.FLEET_MGMT_GET_DATASOURCES_SUCCESS:
          return onGetDatasourcesSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS:
          return onQueryAssetIdSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_FLEET_STATUS: 
          return onSetSaveFleetStatus(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_SET_SAVE_DATASOURCES_STATUS:
          return onSetSaveDatasourcesStatus(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_SET_FLEET_TO_SAVE_ID:
          return onSetFleetToSaveId(state, action);
        default:
            return state;
    }
};

const onQueryOwnersSuccess = (state, action) => {

  const owners = _.orderBy(action.queryResults.ownersWithApiKey, 'ownerName');

  return {
    ...state,
    queryRunning: false,
    owners: owners
  };
}

const onQueryFleetsStarting = (state, action) => {
    return {
        ...state,
        queryRunning: true,
        fleets: [],
    };
};

const onQueryFleetsSuccess = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        fleets: action.queryResults.fleetManagement,
    };
};

const onQueryFleetsError = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        fleets: [],
    };
};
const onChangeFleet = (state, action) => {
    const fleetToSave = _.cloneDeep(state.fleetToSave);
    fleetToSave[action.property] = action.value;
    let hasChanges = false;

    if (!_.isEqual(fleetToSave, state.originalFleet)) {
      hasChanges = true;
    }

    return {
        ...state,
        fleetToSave: fleetToSave,
        errorsOnFleetSave: validateFleetChanges(fleetToSave, state.datasources),
        fleetHasChanges: hasChanges
    };
};
const onChangeMoveFleet = (state, action) => {
    const fleetToMove = _.cloneDeep(state.fleetToMove);
    fleetToMove[action.property] = action.value;

    return {
        ...state,
        fleetToMove: fleetToMove,
        errorsOnFleetMove: validateMoveFleetChanges(fleetToMove),
    };
};

const onSetSaveFleetTabIndex = (state, action) => {
  return {
    ...state,
    saveFleetTabIndex: action.tabIndex,
  };
};

const onUpdateDatasource = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  const datasourceToUpdate = datasources[action.index];

  if (action.property !== 'datasourceGuid') {
    datasourceToUpdate[action.property] = action.value;


    if (action.property === 'name') {
      const duplicateName = datasources.find((datasource, index) => {
        return index !== action.index && datasource.name === action.value;
      });

      datasourceToUpdate.hasDuplicateNameError = !_.isNil(duplicateName);
    }

  } else {
    datasourceToUpdate[action.property] = uuidv4();
  }

  datasourceToUpdate.hasChanges = true;

  return {
    ...state,
    datasources: datasources,
    datasourcesChanged: datasourcesHaveChanges(datasources, state.originalDatasources),
    errorsOnFleetSave: validateFleetChanges(state.fleetToSave, datasources),
  };
};

const onRemoveDatasource = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  // If the datasource is new, then we don't need to subject the user to the delete confirmation workflow
  // Just delete it cause it doesn't even exist in the database
  if (_.isNil(datasources[action.index].id)) {
    datasources.splice(action.index, 1);
  } else {
  // Otherrwise we need to subject the user to the delete confirmation workflow before we can delete it
    datasources[action.index].delete = true;
    datasources[action.index].expanded = true;
  }

  return {
    ...state,
    datasources: datasources,
    errorsOnFleetSave: validateFleetChanges(state.fleetToSave, datasources),
    datasourcesChanged: datasourcesHaveChanges(datasources, state.originalDatasources),
  };
}

const onRestoreDatasource = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  datasources[action.index].delete = false;
  datasources[action.index].willDelete = false;
  datasources[action.index].deleteValidation = '';

  return {
    ...state,
    datasources: datasources,
    datasourcesChanged: datasourcesHaveChanges(datasources, state.originalDatasources),
  };
}

const onConfirmRemoveDatasource = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  datasources[action.index].deleteValidation = action.value;

  if (action.value === state.datasourceDeleteValidationKeyword) {
    datasources[action.index].willDelete = true;
    datasources[action.index].expanded = false;
  }

  return {
    ...state,
    datasources: datasources,
    datasourcesChanged: datasourcesHaveChanges(datasources, state.originalDatasources),
  };

}

const onExpandDatasource = (state, action) => {
  const datasources = _.cloneDeep(state.datasources);
  datasources[action.index].expanded = !state.datasources[action.index].expanded;

  return {
    ...state,
    datasources: datasources
  };
}

const onShowRestartProducerDialog = (state, action) => {
  const newState = _.cloneDeep(state);

  newState.showRestartProducerDialog = action.show;
  if (!_.isNil(action.fleet)) {
    newState.fleetToSave = action.fleet;
    newState.originalFleet = action.fleet;
  } else {
    newState.fleetToSave = {}
    newState.originalFleet = {};
  }

  return newState;
}

const onGetDatasourcesSuccess = (state, action) => {

  let datasources = [];

  _.forEach(action.queryResults.datasourcesForFleet, (datasource) => {
    const datasourceToAdd = processDatasource(datasource);
    const duplicateName = datasources.find((datasource) => {
      return datasource.name === datasourceToAdd.name;
    });
    datasourceToAdd.hasDuplicateNameError = !_.isNil(duplicateName);

    datasources.push(datasourceToAdd);
  });

  return {
    ...state,
    queryRunning: false,
    datasources: datasources
  };
}

const onQueryAssetIdSuccess = (state, action) => {
  const shouldWarn = _.isNil(action.assetInfo) || _.isNil(action.assetInfo.assetId);
  return {
      ...state,
      warningDatavanOnFleetSave: shouldWarn,
      datavanNameHelperText: shouldWarn ? errorMessages.WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_INLINE : 'Datavan',
      queryRunning: false
  };
}

const onSetSaveFleetStatus = (state, action) => {
  return {
    ...state,
    saveStatus: {
      ...state.saveStatus,
      fleetOk: action.status
    }
  };
}

const onSetSaveDatasourcesStatus = (state, action) => {
  return {
    ...state,
    saveStatus: {
      ...state.saveStatus,
      datasourcesOk: action.status
    }
  };
}

const onSetFleetToSaveId = (state, action) => {
  return {
    ...state,
    fleetToSave: {
      ...state.fleetToSave,
      id: action.id
    }
  }
}

export default displayReducer;
