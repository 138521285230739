  import _ from 'lodash';
  import moment from "moment";

  import { auditLogsState, getDefaultEmptyQuery, getDefaultTimeFrame, getDefaultCustomDuration } from './auditLogsSelectors';
  import auditLogsActionTypes from './auditLogsActionTypes';

  import { processAuditLogsMetadataFields, processAuditLogsMetadataCombinators, processAuditLogs, processQueryForSpecificOperators } from './auditLogServices/auditLogService';

  const initialState = auditLogsState();

  const auditLogsReducer = (state = initialState, action) => {

    switch(action.type) {
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_STARTING:
        return { ...state, queryRunning: true };
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_ERROR:
        return { ...state, queryRunning: false };
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_METADATA_SUCCESS:
        return onQueryAuditLogsMetadataSuccess(state, action);
    
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_STARTING:
        return { ...state, queryRunning: true, shouldDisableGrid: false };
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_ERROR:
        return { ...state, queryRunning: false };
      case auditLogsActionTypes.AUDIT_LOGS_QUERY_AUDIT_LOGS_SUCCESS:
        return onQueryAuditLogsSuccess(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_SET_QUERY:
        return onSetQuery(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_LOAD_DEFAULT_QUERY:
        return onLoadDefaultQuery(state, action);
      
      case auditLogsActionTypes.AUDIT_LOGS_CLEAR_AUDIT_LOGS:
        return onClearAuditLogs(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_SET_PAGE:
        return onSetPage(state, action );

      case auditLogsActionTypes.AUDIT_LOGS_SET_ROWS_PER_PAGE:
        return onSetRowsPerPage(state , action);

      case auditLogsActionTypes.AUDIT_LOGS_SET_SORT_MODEL:
        return onSetSortModel(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_SET_TIME_FRAME:
        return onSetTimeFrame(state, action);
  
      case auditLogsActionTypes.AUDIT_LOGS_SET_CUSTOM_START_TIME:
        return onSetStartTime(state, action);
  
      case auditLogsActionTypes.AUDIT_LOGS_SET_CUSTOM_DURATION:
        return onSetDuration(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_SET_CUSTOM_START_TIME_DISPLAY:
        return onSetStartTimeDisplay(state, action);

      case auditLogsActionTypes.AUDIT_LOGS_EXPAND_QUERY_PANEL:
        return onExpandQueryPanel(state, action);

      default:
        return state;
    }

  }


  const onQueryAuditLogsMetadataSuccess = (state, action) => {

    const newAvailableFields = processAuditLogsMetadataFields(action.queryResults.auditMetaData.fields);
    const newCombinators = processAuditLogsMetadataCombinators(action.queryResults.auditMetaData.combinators);

    return {
      ...state,
      combinators: newCombinators,
      availableFields: newAvailableFields,
      queryRunning: false,
      shouldExpandQueryPanel: true
    }
  }

  const onQueryAuditLogsSuccess = (state, action) => {

    const newAuditLogs = action.queryResults && action.queryResults.auditLogQuery ? action.queryResults.auditLogQuery : [];
    const processedAuditLogs = processAuditLogs(newAuditLogs);

    return {
      ...state, 
      auditLogs: processedAuditLogs,
      queryRunning: false,
      // Whenever we get data, we set this flag to false
      // This will be set to true if we have data and the query changes
      shouldDisableGrid: false,
      // If we have data, collapse the Query Panel
      shouldExpandQueryPanel: _.isEmpty(processedAuditLogs) && state.page === 0
    }
  }

  const onSetQuery = (state, action) => {

    const shouldDisableGrid = !_.isEmpty(state.auditLogs) && !_.isEqual(state.query, action.query); 

    return {
      ...state,
      // Need to handle when the query is changing operators that support or don't support multiple values
      query: processQueryForSpecificOperators(action.query),
      shouldDisableGrid: shouldDisableGrid,
      isQueryValid: action.isQueryValid
    }
  }

  const onLoadDefaultQuery = (state, action) => {

    return {
      ...state,
      query: getDefaultEmptyQuery(),
      isQueryValid: false,
      selectedTimeFrame: getDefaultTimeFrame(),
      selectedCustomDuration: getDefaultCustomDuration(),
      shouldDisableGrid: (_.isEmpty(state.auditLogs)) ? false : true
    }
  }

  const onClearAuditLogs = (state, action) => {
    return {
      ...state,
      auditLogs: [],
    }
  }

  const onSetPage = (state, action) => {
    return {
      ...state,
      page: action.page
    }
  }

  const onSetRowsPerPage = (state, action) => { 
    return {
      ...state,
      rowsPerPage: action.rowsPerPage,
      page: 0
    }
  }

  const onSetSortModel = (state, action) => {
    return {
      ...state,
      sortModel: action.sortModel,
      page: 0
    }
  }

  const onSetTimeFrame = (state, action) => {

    const shouldDisableGrid = !_.isEmpty(state.auditLogs) && !_.isEqual(state.selectedTimeFrame, action.timeFrame); 

    let newState = {
      ...state,
      selectedTimeFrame: action.timeFrame,
      selectedCustomStartTime: initialState.selectedCustomStartTime,
      selectedCustomDuration: initialState.selectedCustomDuration,
      selectedCustomStartTimeDisplay: initialState.selectedCustomStartTimeDisplay,
      shouldDisableGrid: shouldDisableGrid
    };
  
    // If the time frame is set to custom, set the initial start time to the now.
  
    if (newState.selectedTimeFrame.label === 'Custom') {
  
      let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');
  
      newState = {
        ...newState,
        selectedCustomStartTime: defaultTime,
        selectedCustomStartTimeDisplay: defaultTime,
      }
    }
  
    return newState;
  };
  
  const onSetStartTime = (state, action) => {
  
    return {
      ...state,
      selectedCustomStartTime: action.startTime,
      selectedCustomStartTimeDisplay: action.startTime
    }
  };
  
  const onSetDuration = (state, action) => {
  
    return {
      ...state,
      selectedCustomDuration: action.duration
    }
  };

  const onSetStartTimeDisplay = (state, action) => {

    return {
      ...state,
      selectedCustomStartTimeDisplay: action.startTime
    }
  };

  const onExpandQueryPanel = (state, action) => { 
    return {
      ...state,
      shouldExpandQueryPanel: action.expanded
    }
  };

  export default auditLogsReducer;