import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { assetTypes } from '../../common/services/assetTypeService';

const fetchDevicesForOwner = () => {

  const assets = [];

  createFleets();
  _.forEach(fleets, (fleet) => { 
    frontside = [];
    blenders = [];
    hydration = {};
    chems = [];
    datavan = {};
    createFrontSide(fleet);
    createBlenders(fleet);
    createHydration(fleet);
    createChems(fleet);
    createDatavan(fleet);
    assets.push(...frontside);
    assets.push(...blenders);
    assets.push(hydration);
    assets.push(...chems);
    assets.push(datavan);
  });

  createPrs();
  createFacilities();
  createWells();
  
  assets.push(...prs);
  assets.push(...facilities);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        devicesForOwner: assets
      })
    }, 1000)
  });
}

const fetchLocations = () => {

  const locations = [];

  const numOfLocations = _.random(1,5);

  for (let i = 0; i < numOfLocations; i++) {

    let location = {
      id: i + 1,
      name: 'Location ' + (i + 1),
      type: 'yard'
    }

    const locationGeomType = _.random(1, 50);
    let geomType = 'Circle';
    if ( locationGeomType % 2 !== 0) {
      geomType = 'Polygon';
    }

    if (geomType === 'Circle') {
      let coordinates = [];
      coordinates.push({
        latitude: getRandomInRange(29.9, 33, 6),
        longitude: getRandomInRange(-94, -102, 6)
      });
      location.coordinates = coordinates;
      location.radius = _.random(10000, 100000); // in meters
    } else {
      let coordinates = [];
      let numOfSides = _.random(3, 10);
      _.forEach(_.range(numOfSides), (side) => {
        coordinates.push({
          latitude: getRandomInRange(29.9, 31, 6),
          longitude: getRandomInRange(-94, -96, 6)
        });
      })
      // Enclose the polygon as required by the Location service
      coordinates.push(coordinates[0]);
      location.coordinates = coordinates;
    }

    locations.push(location);
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        locationsForOwner: locations
      })
    }, 1000)
  });
}

const getRandomInRange = (from, to, fixed) => {
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  // .toFixed() returns string, so ' * 1' is a trick to convert to number
}

const generateEsn = (length) => {
  const min = Math.pow(10, length - 1);
  const max = Math.pow(10, length) - 1;
  return '0-' + Math.floor(Math.random() * (max - min + 1) + min);
}

let fleets = [];
let frontside = [];
let blenders = [];
let hydration = {};
let chems = [];
let datavan = {};
let prs = [];
let facilities = [];
let wells = [];

const createFleets = () => {

  fleets = [];

  const numOfFleets = _.random(3,10);

  for (let i = 0; i < numOfFleets; i++) {

    fleets.push({
      fleetIdentifier: uuidv4(),
      fleetId: i + 1,
      fleetName: 'Frac ' + (i < 9 ? '0' + (i + 1) : (i + 1)),
      fleetStatus: _.random (1, 10) % 2 === 0 ? 'Active' : 'Offline',

      // Lets us generate assets around this location
      lat: getRandomInRange(29.9, 33, 6), 
      long: getRandomInRange(-94, -102, 6),

      ownerId: 2,
      ownerName: 'NexTier'
    })
  }
}

const createFrontSide = (fleet) => {

  frontside = [];

  const numOfPumps = _.random(10,80);

  for (let i = 0; i < numOfPumps; i++) {

    frontside.push({
      ...fleet,
      unitType: assetTypes.FRAC_PUMP,
      truckId: i + 1,
      truckName: '6201' + (i < 9 ? '0' + (i + 1) : (i + 1)),
      esn: generateEsn(7),
      active: true,
      lastKnownCoord: {
        latitude: getRandomInRange(fleet.lat - (_.random(0.000125, 0.000999, true)), fleet.lat + (_.random(0.000125, 0.000999, true)), 6),
        longitude: getRandomInRange(fleet.long - (_.random(0.000125, 0.000999, true)), fleet.long + (_.random(0.000125, 0.000999, true)), 6),
      },
      lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
    })
  }
}

const createBlenders = (fleet) => {

  blenders = [];

  const numOfBlenders = _.random(1,2);

  for (let i = 0; i < numOfBlenders; i++) {

    blenders.push({
      ...fleet,
      unitType: assetTypes.BLENDER,
      truckId: i + 1,
      truckName: '75000' + (i + 1),
      esn: generateEsn(7),
      active: true,
      lastKnownCoord: {
        latitude: getRandomInRange(fleet.lat - (_.random(0.000125, 0.000999, true)), fleet.lat + (_.random(0.000125, 0.000999, true)), 6),
        longitude: getRandomInRange(fleet.long - (_.random(0.000125, 0.000999, true)), fleet.long + (_.random(0.000125, 0.000999, true)), 6),
      },
      lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
    })
  }
}

const createHydration = (fleet) => {

  let id = _.random(10,20);

  hydration = {
    ...fleet,
    unitType: assetTypes.HYDRATION,
    truckId: id,
    truckName: '7600' + id,
    esn: generateEsn(7),
    active: true,
    lastKnownCoord: {
      latitude: getRandomInRange(fleet.lat - (_.random(0.000125, 0.000999, true)), fleet.lat + (_.random(0.000125, 0.000999, true)), 6),
      longitude: getRandomInRange(fleet.long - (_.random(0.000125, 0.000999, true)), fleet.long + (_.random(0.000125, 0.000999, true)), 6),
    },
    lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
  }
}

const createChems = (fleet) => {

  chems = [];

  const numOfChems = _.random(1,2);

  for (let i = 0; i < numOfChems; i++) {

    chems.push({
      ...fleet,
      unitType: assetTypes.CHEMVAN,
      truckId: i + 1,
      truckName: '64000' + (i + 1),
      esn: generateEsn(7),
      active: true,
      lastKnownCoord: {
        latitude: getRandomInRange(fleet.lat - (_.random(0.000125, 0.000999, true)), fleet.lat + (_.random(0.000125, 0.000999, true)), 6),
        longitude: getRandomInRange(fleet.long - (_.random(0.000125, 0.000999, true)), fleet.long + (_.random(0.000125, 0.000999, true)), 6),
      },
      lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
    })
  }
}

const createDatavan = (fleet) => {

  
  let id = _.random(10,20);

  datavan = {
    ...fleet,
    unitType: assetTypes.DATAVAN,
    truckId: id,
    truckName: '7900' + id,
    esn: generateEsn(7),
    active: true,
    lastKnownCoord: {
      latitude: getRandomInRange(fleet.lat - (_.random(0.000125, 0.000999, true)), fleet.lat + (_.random(0.000125, 0.000999, true)), 6),
      longitude: getRandomInRange(fleet.long - (_.random(0.000125, 0.000999, true)), fleet.long + (_.random(0.000125, 0.000999, true)), 6),
    },
    lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
  }
}

const createPrs = () => {

  prs = [];

  const numOfPRS = _.random(3,8);

  for (let i = 0; i < numOfPRS; i++) {

    let prsFleetId = _.random(400, 499, false) + (i + 1);

    let prsFleet = {
      fleetIdentifier: uuidv4(),
      fleetId: prsFleetId,
      fleetName: 'PRS ' + prsFleetId,
      fleetStatus: 'Active',
      lat: getRandomInRange(29.9, 33, 6),
      long: getRandomInRange(-94, -102, 6),
      ownerId: 2,
      ownerName: 'NexTier'
    }

    prs.push({
      ...prsFleet,
      unitType: assetTypes.PRS,
      truckId: i + 1,
      truckName: 'PRS' + (i + 1),
      esn: generateEsn(7),
      active: true,
      lastKnownCoord: {
        latitude: prsFleet.lat,
        longitude: prsFleet.long
      },
      lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
    })
  }
}

const createFacilities = () => {

  facilities = [];

  const numOfFacilities = _.random(1,5);

  for (let i = 0; i < numOfFacilities; i++) {

    let facilitiesFleetId = _.random(500, 599, false) + (i + 1);

    let facilityName = Math.random().toString(36).slice(2).toUpperCase().substring(1,4) + 'XPS';

    let facilitiesFleet = {
      fleetIdentifier: uuidv4(),
      fleetId: facilitiesFleetId,
      fleetName: facilityName,
      fleetStatus: 'Active',
      lat: getRandomInRange(29.9, 33, 6),
      long: getRandomInRange(-94, -102, 6),
      ownerId: 2,
      ownerName: 'NexTier'
    }

    facilities.push({
      ...facilitiesFleet,
      unitType: assetTypes.CNG_FACILITY,
      truckId: i + 1,
      truckName: facilityName,
      esn: generateEsn(7),
      active: true,
      lastKnownCoord: {
        latitude: facilitiesFleet.lat,
        longitude: facilitiesFleet.long
      },
      lastPolledTime: moment().subtract(_.random(1, 60), 'minutes').unix()
    })
  }
}

const createWells = () => {

  wells = [];

  const numOfWells = _.random(50,80);

  for (let i = 0; i < numOfWells; i++) {

    wells.push({
      type: 'Well',
      id: i + 1,
      name: _.random(4240000000, 4249999999) + '',
      lat: getRandomInRange(29.9, 33, 6),
      long: getRandomInRange(-94, -102, 6),
    })
  }

}

export {
  fetchDevicesForOwner,
  fetchLocations
}