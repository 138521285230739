const getDisplayStyles = () => ({

  page: {
    display:'flex',
    flexFlow: 'column',
    backgroundColor: 'background.default',
    paddingTop: '64px',
  },
  pageContent: {
    // Set the size of the page, this needs to match the size of the views
    width: '1540px',
    // Prevents the left size of the disimage.pngplay from becoming unreachable when
    // horizontal scrolling is required
    margin: 'auto',
  },
  pageHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingLeft: '10px', //Plus the internal spacing of the layout grid (10px)
    paddingRight: '10px', //Plus the internal spacing of the layout grid (10px)
    height: '56px',
  },
  pageHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '170px',
  },
  pageHeaderActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  pageHeaderButton: {
    paddingLeft: 1.5,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1
  },
  contextSelection: {
    width: '150px',
    marginLeft: 1,
    paddingRight: 3,
  },
  filterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingLeft: 3,
  },
  childComponent : {
    marginLeft: 1,
  },
  dataAgeContainer : {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  selectionContainer: {
    display:'flex',
    flexGrow: 1,
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'center',
    height: '56px',
    paddingTop: 1,
  },
  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
  },
  timeFilterItem: {
    paddingLeft: 1,
    paddingRight: 3,
  },
  timeFrameSelection: {
    width: '150px',
  },
  startTimeSelection: {
    width: '200px'
  },
  dashboardSelection: {
    width: '175px',
  },
});

export default getDisplayStyles;