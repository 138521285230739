import _ from 'lodash';
import moment from 'moment';

import {EAxisAlignment} from 'scichart/types/AxisAlignment';

import { exportCsvState } from'../../../../common/exportCsv/exportCSVSelectors';

import { X_AXIS_IDS, Y_AXIS_IDS } from '../../../../../components/controls/charts/cardChart/cardChart';
import { mdtPaletteChartingColorsAsComboBoxOptions } from "../../../../../components/common/styles/mdtPalette";

const getRuleTemplate = () => {
  return {
    id: null,
    condition: null,
    value1: '',
    value2: '',
    color: null
  }
}

const getDefaultTimeFrame = () => { return getTimeFrames()[1]; };

const getTimeFrames = () => {
  return [
    { value: 60, label: 'Last hour' },
    { value: 360, label: 'Last 6 hours' },
    { value: 720, label: 'Last 12 hours' },
  ]
}

const defnEmpty = () => {
  return {
    name: "",
    author: "",
    type: 'template',

    // Collection of x and y axes to use on this chart
    xAxes: [X_AXIS_IDS[0], X_AXIS_IDS[1]],
    yAxes: [Y_AXIS_IDS[0]],

    // first x-Axis id
    primary: {

      // time range
      timeRange: {
        startTime:  moment().subtract(getDefaultTimeFrame().value, 'minutes').startOf('minute').unix(),
        endTime: moment().startOf('minute').unix()
      },

      // default context should be used for all new sensors (must include name)
      defaultContext: null,

      // contexts, one per truck
      contexts: [],

      // sensor definitions (from sensor selector) and configuration (objects must include sensorSetId, alias, uom, yAxisId, color, isVisible)
      // Note that yAxisId is 0, 1, 2, 3 for outer left, inner left, inner right, and outer right respectively
      sensors: [],

      axisTitle: '',

      // List of annotations to show on the chart
      annotations: [],      
      
      // Context to highlight on the chart
      highlightContext: null,

      // Indicates if the x-axis is visible or not
      isVisible: true,

      // Placement of the x-axis
      placement: EAxisAlignment.Bottom,

      // Indicates if the y-axis should start at zero or not
      zeroYRanges: true,

      // This sets the 'drawNaNAs' value for a SciChart dataseries
      // True is PolyLine, False is DiscontinuousLine
      closeGaps: false,
    },
    // second x-Axis id
    secondary: {

      // time range
      timeRange: {
        startTime:  moment().subtract(getDefaultTimeFrame().value, 'minutes').startOf('minute').unix(),
        endTime: moment().startOf('minute').unix()
      },

      // default context should be used for all new sensors (must include name)
      defaultContext: null,

      // contexts, one per truck
      contexts: [],

      // sensor definitions (from sensor selector) and configuration (objects must include sensorSetId, alias, uom, yAxisId, color, isVisible)
      // Note that yAxisId is 0, 1, 2, 3 for outer left, inner left, inner right, and outer right respectively
      sensors: [],

      axisTitle: '',

      // List of annotations to show on the chart
      annotations: [],

      // Context to highlight on the chart
      highlightContext: null,

      // Indicates if the x-axis is visible or not
      isVisible: false,

      // Placement of the x-axis
      placement: EAxisAlignment.Bottom,

      // Indicates if the y-axis should start at zero or not
      zeroYRanges: false,

      // This sets the 'drawNaNAs' value for a SciChart dataseries
      // True is PolyLine, False is DiscontinuousLine
      closeGaps: false,
    }
  };
};


const truckFilters = () => {
  return [
    {
      label: 'All Pumps', value: 'allPumps'
    },
    {
      label: 'High Side', value: 'highSide'
    },
    {
      label: 'Low Side', value: 'lowSide'
    },
    {
      label: 'Clean', value: 'clean'
    },
    {
      label: 'Dirty', value: 'dirty'
    },
  ]
}

const ruleConditions = () => {
  return [
    "greater than",
    "less than",
    "between",
    "not between"
  ];  
}


const fullScreenToggleValues = () => {
  return [
    "grid",
    "split",
    "chart"
  ];
}

const dataDisplayModes = () => {
  return [
    'absolute',
    'relative'
  ]
}

const liveViewState = state => {

  const exportCsvDetails = exportCsvState(state);

  return {
    ...exportCsvDetails,

    queryRunning: _.isNil(state) ? false : state.queryRunning,
    chartQueryRunning: _.isNil(state) ? false : state.chartQueryRunning,

    trucks: _.isNil(state) ? [] : state.trucks,
    selectedTrucks: _.isNil(state) ? [] : state.selectedTrucks,

    timeFrames: _.isNil(state) ? getTimeFrames() : state.timeFrames,
    selectedTimeFrame: _.isNil(state) ? getDefaultTimeFrame() : state.selectedTimeFrame,

    truckFilters: _.isNil(state) ? truckFilters() : state.truckFilters,
    selectedTruckFilter: _.isNil(state) ? truckFilters()[0] : state.selectedTruckFilter,

    sensorSelectorOpen: _.isNil(state) ? false : state.sensorSelectorOpen,
    selectedSensors: _.isNil(state) ? [] : state.selectedSensors,
    selectedSensor: _.isNil(state) ? null : state.selectedSensor,

    data: _.isNil(state) ? [] : state.data,

    definition: _.isNil(state) ? defnEmpty() : state.definition,
    primaryXValues: _.isNil(state) ? [] : state.primaryXValues,
    primaryYValues: _.isNil(state) ? [] : state.primaryYValues,

    // useEffect does not look into changes on objects inside an array - it looks at the array itself
    // therefore we can use this flag to force a refresh of the chart by updating it with the moment() value when needed
    shouldRefreshChart: _.isNil(state) ? null : state.shouldRefreshChart, 

    configPanelWidth: 570,
    configureChart: _.isNil(state) ? false : state.configureChart,
    colorOptions: mdtPaletteChartingColorsAsComboBoxOptions(),
    configTabIndex: _.isNil(state) ? 0 : state.configTabIndex,
    // Keyed by sensor set id, this holds the configured data rules for each sensor
    // Each rule will have 
    configuredRules: _.isNil(state) ? {} : state.configuredRules,
    configRuleConditions: ruleConditions(),
    ruleColorPickerStates: _.isNil(state) ? [] : state.ruleColorPickerStates,

    fullScreenToggle: _.isNil(state) ? fullScreenToggleValues()[1] : state.fullScreenToggle,
    fullScreenToggleValues: fullScreenToggleValues(),

    // List the current min and max ranges for all x and y axes
    ranges: _.isNil(state) ? { x: {}, y: {} } : state.ranges,

    // Indicates if the live feed is paused or not
    pauseLiveFeed: _.isNil(state) ? false : state.pauseLiveFeed,

    // Indicates if what mode the chart is in - absolute or relative (to the average value)
    dataDisplayModeToggle: _.isNil(state) ? dataDisplayModes()[0] : state.dataDisplayModeToggle,
    dataDisplayModes: dataDisplayModes(),
    relativeYValues: _.isNil(state) ? [] : state.relativeYValues,

    // Drives a small spinner that indicates the user configuration is being saved
    userConfigurationSaving: _.isNil(state) ? false : state.userConfigurationSaving,

    // Object that holds the last timestamp for each truck that was processed
    lastProcessedTruckTimestamps: _.isNil(state) ? {} : state.lastProcessedTruckTimestamps,

    rollOverTimestamp: _.isNil(state) ? null : state.rollOverTimestamp,
    showLegend: _.isNil(state) ? true : state.showLegend,
  }

}

export {
  liveViewState,
  getRuleTemplate,
  getDefaultTimeFrame,
  defnEmpty,
  ruleConditions,
  fullScreenToggleValues,
  dataDisplayModes
}