import _ from 'lodash';
import componentLifeActionTypes from './componentLifeActionTypes';
import { componentLifeState } from './componentLifeSelectors';

const initialState = componentLifeState();

const componentLifeReducer = (state=initialState, action) => {

  switch (action.type) {
    case componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case componentLifeActionTypes.COMPONENT_LIFE_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case componentLifeActionTypes.COMPONENT_LIFE_CLEAR_DATA:
      return onClearData(state, action);

    case componentLifeActionTypes.COMPONENT_LIFE_APPLY_CONFIGURATION:
      return applyConfiguration(state, action);

    case componentLifeActionTypes.COMPONENT_LIFE_QUERY_TYPES_SUCCESS:
      return onQueryTypesSuccess(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {
  let components = action.queryResults.componentLife.components;

  components = _.isNil(components) ? [] : components;

  return {
    ...state,
    queryRunning: false,
    components: components,
  };
};

const onClearData = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    components: []
  };
};

const applyConfiguration = (state, action) => {
  const newComponents = _.cloneDeep(state.components);
  const configuration = action.configuration;
    if (_.isNil(configuration)) {
      // do not have a user configuration yet; setting every component to be visible
      newComponents.forEach( (element) => { element.isVisible = true });
    } else {
      // has a user configuration; change the order and toggle visibility
      const positions = {};
      for (const [index, type] of _.map(configuration, (elem) => elem.type).entries()) {
        positions[type] = index;
      };
      newComponents.sort(
        (a, b) => positions[a.type] - positions[b.type]
      ).forEach( (element, index) => {
        element.isVisible = configuration[index]?.isVisible ?? false
      });
    }

  return {
    ...state,
    components: newComponents,
  }
};

const onQueryTypesSuccess = (state, action) => {
  let componentTypes = action.queryResults.componentTypesByUnitType;
  let components = state.components;

  componentTypes = _.isNil(componentTypes) ? [] : componentTypes;
  if (_.isNil(state.components) || _.isEmpty(state.components)) {
    // we queried the component types before we fetched any component life data
    // this means we created a new component life card, and immediately start configuring it
    // create empty data for all available components
    components = componentTypes.map(element => ({
      ...element, primaryLife: {name: element.name, value: null}, type: element.name, isVisible: true
    }));
  }

  return {
    ...state,
    queryRunning: false,
    componentTypes: componentTypes,
    components: components,
  }
};

export default componentLifeReducer;