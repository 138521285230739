import _ from 'lodash';

const fetchComponentLifeRoadsideCardData = (truckId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        componentLifeRoadside: {
          components: [
            { type: 'Roadside Engines', displayName: 'Engine', model: 'XX-XXX-XXXX', expectedLife: 17000, primaryLife: { name: 'primary', value: _.random(0,20000) } },
            { type: 'Roadside FluidEnds', displayName: 'Fluid End', model: 'XX-XXX-XXXX', expectedLife: 17000, primaryLife: { name: 'primary', value: _.random(0,20000) } },
            { type: 'Roadside PowerEnds', displayName: 'Power End', model: 'XX-XXX-XXXX', expectedLife: 7500, primaryLife: { name: 'primary', value: _.random(0,10000) } },
            { type: 'Roadside Transmissions', displayName: 'Transmission', model: 'XX-XXX-XXXX', expectedLife: 1500, primaryLife: { name: 'primary', value: _.random(0,2000) } },
          ]
        }
      })
    }, 1000)
  });

};

export { fetchComponentLifeRoadsideCardData }
