import fleetOverviewCardActionTypes from '../fracOverviewCardActionTypes';

const queryReducer = (state, action) => {

  switch (action.type) {

    case fleetOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_STARTING:
      return onQueryDataStarting(state, action);

    case fleetOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case fleetOverviewCardActionTypes.FRAC_OVERVIEW_CARD_QUERY_DATA_ERROR:
      return onQueryDataError(state);

    default: return state;

  }
};

const onQueryDataStarting = (state, action) => {
  return {
    ...state,
    queryTransactionId: action.transactionId,
    queryRunning: true,
  };
};

const onQueryDataSuccess = (state, action) => {

  if (action.transactionId !== state.queryTransactionId) {
    return state;
  }

  return {
    ...state,
    ...action.queryResults,
    queryRunning: false
  };
};

const onQueryDataError = (state) => {

  return {
    ...state,
    queryRunning: false,
  };
};

export default queryReducer