import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import fleetOperationActionTypes from './fleetOperationActionTypes';
import fetchFleetOperationCardData from './fleetOperationQueries';

import errorMessages from '../../common/errorMessages';

const clearData = defaultActionFactory(fleetOperationActionTypes.FLEET_OPERATION_CLEAR_DATA, 'stateDef');

const queryData = queryActionFactory(
    fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_STARTING,
    fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_SUCCESS,
    fleetOperationActionTypes.FLEET_OPERATION_QUERY_DATA_ERROR,
    errorMessages.ERROR_RETRIEVING_FLEET_OPERATION_CARD_DATA,
    fetchFleetOperationCardData
);

export {
  queryData,
  clearData,
}