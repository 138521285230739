import { performDisplayFetch } from "../../common/fetch";

const fetchDataExploration = (truck, startTime, endTime, sensors) => {
  const input = _.map(sensors, s => {
    return {
      'truckPid': truck.pid,
      'sensorSetId': s.sensorSetId,
      'unitOfMeasure': s.uom,
      'startTime': startTime,
      'endTime': endTime,
    };
  });

  return performDisplayFetch({
    "operationName": "DataExplorationChartData",
    'query': 'query chartSeries($input:[ChartSeriesInput]!) { chartSeries(input:$input) { truckPid sensorSetId unitOfMeasure timestamps values } }',
    'variables': {
      'input': input
    }
  });
};

export { fetchDataExploration }
