import { handleError } from "../../../app/actions/appErrorActions";
import errorMessages from "../../../common/errorMessages";
import defaultActionFactory from "../../../common/factories/defaultActionFactory";
import routeAssetMgmtActionTypes from "../routeAssetMgmtActionTypes";
import { fetchDeleteRouteAsset, fetchDeleteConsumer } from "../routeAssetMgmtQueries";
import { queryRouteAssets, queryConsumers } from "./routeAssetMgmtDisplayActions";

const closeDeleteConfirmation = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteRouteAssetStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_STARTING, 'stateDef');
const deleteRouteAssetSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_SUCCESS, 'stateDef', 'truckName', 'consumerId');
const deleteRouteAssetError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_DELETE_ROUTE_ASSET_ERROR, 'stateDef');

const deleteRouteAsset = (stateDef, truckName, consumerId) => {
    return async (dispatch) => {
        try {
            await dispatch(deleteRouteAssetStarting(stateDef));
            await fetchDeleteRouteAsset(truckName, consumerId);
            await dispatch(deleteRouteAssetSuccess(stateDef, truckName, consumerId));
        } catch (e) {
            await dispatch(deleteRouteAssetError(stateDef));
            await dispatch(handleError(errorMessages.ERROR_DELETING_ROUTE_ASSET, e.message));
        }
        return dispatch(queryRouteAssets(stateDef));
    };
};

const openDeleteConsumerConfirmation = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_OPEN_DELETE_CONFIRMATION, 'stateDef', 'consumer');
const deleteConsumerConfirmation = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_CLOSE_DELETE_CONFIRMATION, 'stateDef');
const deleteConsumerStarting = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_STARTING, 'stateDef');
const deleteConsumerSuccess = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_SUCCESS, 'stateDef', 'consumerId');
const deleteConsumerError = defaultActionFactory(routeAssetMgmtActionTypes.ROUTE_ASSET_MGMT_CONSUMER_DELETE_ROUTE_ASSET_ERROR, 'stateDef');

const deleteConsumer = (stateDef, consumerId) => {
   return async (dispatch) => {
       try {
           await dispatch(deleteConsumerStarting(stateDef));
           await fetchDeleteConsumer(consumerId);
           await dispatch(deleteConsumerSuccess(stateDef, consumerId));
       } catch (e) {
           console.error('Error during deletion:', e);
           await dispatch(deleteConsumerError(stateDef));
           await dispatch(handleError(errorMessages.ERROR_DELETING_CONSUMER, e.message || 'Unknown error'));
       }
       await dispatch(queryConsumers(stateDef));
       return dispatch(queryRouteAssets(stateDef));
   };
};

export {
   deleteRouteAsset,
   deleteConsumer,
   deleteRouteAssetStarting,
   deleteRouteAssetSuccess,
   deleteRouteAssetError,
   closeDeleteConfirmation,
   openDeleteConsumerConfirmation,
   deleteConsumerConfirmation,
   deleteConsumerStarting,
   deleteConsumerSuccess,
   deleteConsumerError
};
