import _ from 'lodash';
import React, {Component} from 'react';
import {withProps, compose} from 'recompose';
import {connect} from 'react-redux';

import {Typography, Box, IconButton} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import ComponentTypes from '../../componentTypes';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import {gridClasses} from "@mui/x-data-grid-pro";

import MDTDataGrid from "../../common/table/MDTDataGrid";
import {downloadsDisplayState} from "../../../state/displays/downloads/downloadsDisplaySelector";
import * as downloadsDisplayActions from "../../../state/displays/downloads/downloadsDisplayActions";
import { appState as applicationState } from '../../../state/app/appSelectors';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {trackPage} from '../../../helpers/googleAnalyticsHelper';
import Progress from "../../controls/progress";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  exportAnchor: {
    visibility: 'hidden'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '45vw'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden'
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible'
      },
    },
  },
};

class DownloadsDisplay extends Component {

  onDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  generateTableColumns() {
    // Columns match detail documented under README.md section "SystemSoftware Display columns match"
    let columns = [];
    columns.push({
      field: 'type',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      editable: false,
      hideable: false,
      pinnable: false
    });
    columns.push({
      field: 'version',
      headerName: 'Version',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      editable: false
    });
    columns.push({
      field: 'fileName',
      headerName: 'File Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1.75,
      editable: false
    });
    columns.push({
      field: 'downloadUrl',
      headerName: 'Download',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
            <IconButton
                color="primary"
                disabled={!params.row.downloadUrl}
                onClick={() => this.onDownload(params.row.downloadUrl, params.row.fileName)}>
              <SystemUpdateAltIcon />
            </IconButton>
        );
      }
    });
    return columns;
  }

  constructor(props) {
    super(props);
    // Generate the columns data for the data table.
    this.columns = this.generateTableColumns();
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.DOWNLOADS, this.props.user);
    // Load the data from the service layer.
    this.props.queryItems();
  }

  render() {
    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>Downloads</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton
                onClick={() => this.props.queryItems()}
                size="large"><RefreshIcon/></IconButton>
            </Box>
          </Box>
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
                disableColumnFilter
                disableColumnSelector
                columns={this.columns}
                rows={this.props.items}
                disableSelectionOnClick
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: {
                      disableToolbarButton: true
                    },
                    csvOptions : { disableToolbarButton: true }
                  }
                }}
                stateDef={this.props.stateDef}
                pagination={false}
                getRowId={(row) => row.type}
            />
          </Box>
        </Box>

        <Progress open={this.props.queryRunning}/>

      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DOWNLOADS : props.stateKey,
      type: ComponentTypes.DOWNLOADS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = downloadsDisplayState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    queryRunning: componentState.queryRunning,
    items: componentState.items,
    user: appState.user,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryItems: () => { dispatch(downloadsDisplayActions.queryData(props.stateDef)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DownloadsDisplay))