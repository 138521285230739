import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';

import fleetMapStyles from '../../fleetMapStyles';

const styles = {
  ...fleetMapStyles,

  totalsContainer: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },

  valueStyle: {
    fontSize: '20px',
    fontWeight: 500
  },

  // Override the default unitContent style
  unitContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '90%'
  },
};

class Sand extends Component {
  render() {
    // For convenience purposes just set a null or undefined data property to an empty object.
    // The allows the markup to be cleaner as we don't have to constantly check data when binding
    // its properties.
    let data = _.isNil(this.props.data) ? {} : this.props.data;

    return (
      <Box sx={styles.sandMapContainer}>
        <Box sx={styles.sandVisualContent}>
          <Box sx={styles.sandVisualHeaderContent}>
            <Typography sx={styles.sandAssetLabel} variant={'subtitle1'}>{data.name}</Typography>
          </Box>

          <Box sx={styles.unitVisualContent}>
            <Box sx={styles.unitContent}>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.cleanRate}</Typography>
                <Typography variant={'caption'}>Rate (gpm)</Typography>
              </Box>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.massConc}</Typography>
                <Typography variant={'caption'}>Concentration (lbs/gal)</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.unitVisualContent}>
            <Box sx={{...styles.unitContent}}>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.selectedMassFlow}</Typography>
                <Typography variant={'caption'}>Mass Flow (lbs/min)</Typography>
              </Box>
              <Box sx={styles.totalsContainer}>
                <Typography sx={styles.valueStyle} variant={'subtitle1'}>{data.selectedSetPoint}</Typography>
                <Typography variant={'caption'}>Set Point (lbs/min)</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );

  }
}

Sand.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sand;