import defaultActionFactory from '../../common/factories/defaultActionFactory';
import tableActionTypes from './tableActionTypes';

const setCurrentPage = defaultActionFactory(tableActionTypes.TABLE_SET_CURRENT_PAGE, 'stateDef', 'currentPage');
const setUnitsPerPage = defaultActionFactory(tableActionTypes.TABLE_SET_ITEMS_PER_PAGE, 'stateDef', 'unitsPerPage');
const sortItems = defaultActionFactory(tableActionTypes.TABLE_SORT_ITEMS, 'stateDef', 'sortContext');
const rowClicked = defaultActionFactory(tableActionTypes.TABLE_ROW_CLICKED, 'stateDef', 'item');

export {
  setCurrentPage,
  setUnitsPerPage,
  sortItems,
  rowClicked,
};