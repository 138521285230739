import _ from 'lodash';

const warmStartState = state => {

  // Define the two types (Average fuel consumption and Average Fuel Cost) along with their original values for warm start selector
  return {
    warmStart: {
      rowsPerPage: _.isNil(state) ? 10 : state.warmStart.rowsPerPage,
      rowsPerPageOptions: [5,10,25],

      // Define no value for initial state as '' to keep component bound to this in "controlled" mode
      // https://reactjs.org/docs/forms.html#controlled-components
      // Null or undefined will make components "uncontrolled"
      averageFuelConsumption: _.isNil(state) ? '' : state.warmStart.averageFuelConsumption,
      averageFuelCost: _.isNil(state) ? '' : state.warmStart.averageFuelCost,
      originalAverageFuelConsumption: _.isNil(state) ? '' : state.warmStart.originalAverageFuelConsumption,
      originalAverageFuelCost: _.isNil(state) ? '' : state.warmStart.originalAverageFuelCost,
      warmStartCanSave: _.isNil(state) ? false : state.warmStart.warmStartCanSave,
      avgFuelConsumptionHasError: _.isNil(state) ? false : state.warmStart.avgFuelConsumptionHasError,
      avgFuelCostHasError: _.isNil(state) ? false : state.warmStart.avgFuelCostHasError,
      warmStartTruckSettings: _.isNil(state) ? [] : state.warmStart.warmStartTruckSettings,
      loadingOwnerWarmStartSetting: _.isNil(state) ? false : state.warmStart.loadingOwnerWarmStartSetting,
      loadingTruckWarmStartSetting: _.isNil(state) ? false : state.warmStart.loadingTruckWarmStartSetting
    }
  };

};

export {
  warmStartState
};