import _ from "lodash";
import moment from 'moment';

const processCustomersForAutocomplete = (customers) => {
  let customersForAutocomplete = [];
  Object.values(_.groupBy(customers, (customer) => customer.name)).forEach((items) => {
    const customerToAdd = items[0];    //There will be at least one element for each grouped customer item
    customerToAdd.contacts = items.map( item => {
      return {
        value: item.id, //customer Id for given contact
        label: item.contactName,
      }
    });
    customersForAutocomplete.push(
        {
          value: customerToAdd.name,
          label: customerToAdd.name,
          contacts: customerToAdd.contacts
        }
    );
  });
  return customersForAutocomplete;
}

const processJobs = (jobs) => {
  let noValue = '-';
  let processedJobs = [];
  let orderingId = 0;
  _.forEach(jobs, (job) => {

    let processedWells = [];
    _.forEach(job.wells, (well) => {
      well.well = { value: well.wellId, label: _.isNil(well.wellName) ? well.apiNumber : well.wellName } 
      well.toDelete = false;
      processedWells.push(well);
    });
  
    processedJobs.push(
      {
        ...job,
        startDate: !_.isNil(job.startDate) ? moment.unix(job.startDate/1000).format('YYYY-MM-DD HH:mm') : noValue,
        endDate: !_.isNil(job.endDate) ? moment.unix(job.endDate/1000).format('YYYY-MM-DD HH:mm') : noValue,
        wells: processedWells,
        orderingId: orderingId
      }
    )
    orderingId++;
  });
  return processedJobs;
}

const processWells = (wells) => {
  let processedWells = [];
  _.forEach(wells, (well) => {

    // Only include wells that have a name
    if (!_.isNil(well.name)) {
      let processedWell = {...well};
      processedWell.value = well.id, 
      processedWell.label = well.name,
      processedWell.toDelete = false;
    
      processedWells.push(processedWell);
    }
  });
  return processedWells;
}

const processDatavans = (datavans) => {
  let processedDatavans = [];
  _.forEach(datavans, (van) => {

    let processedVan = van;
    processedVan.value = van.id, 
    processedVan.label = van.truckName,
  
    processedDatavans.push(processedVan);
  });
  return processedDatavans;
}

const processJobTypes = (jobTypes) => {
  let processedJobTypes = [];
  _.forEach(jobTypes, (jobType) => {

    let processedJobType = jobType;
    processedJobType.value = jobType.jobTypeId, 
    processedJobType.label = jobType.jobType,
  
    processedJobTypes.push(processedJobType);
  });
  return processedJobTypes;
}

const validateCanSave = (state) => {

  if (_.isEmpty(state.createEditJobName)) {
    return false;
  }

  if (_.isNil(state.createEditStartDate) || _.isNil(state.createEditEndDate)) {
    return false;
  }

  if (moment(state.createEditEndDate).isBefore(moment(state.createEditStartDate))) {
    return false;
  }

  if (_.isNil(state.createEditSelectedJobType)) {
    return false;
  }

  if (_.isNil(state.createEditSelectedDatavan)) {
    return false;
  }

  // Check for any newly added wells (and not deleted) that do not have a selected well
  if (!_.isEmpty(state.createEditWells) && !_.isNil(_.find(state.createEditWells, (well) => { return well.toDelete === false && well.well.value === null; }))) {
    return false;
  }

  if (state.hasJobNameError === true || state.hasStartDateError === true || state.hasEndDateError === true || state.hasJobTypeError === true || state.hasDatavanError === true) {
    return false;
  }

  return true;

}

const evaluateHasChanges = (state) => {

  // Breaking it out here makes it useful for debugging
  let momentSelectedJobStartDate = moment(state.selectedJob.startDate);
  let momentCreateEditStartDate = moment(state.createEditStartDateDisplay);
  let momentSelectedJobEndtDate = moment(state.selectedJob.endDate);
  let momentCreateEditEndDate = moment(state.createEditEndDateDisplay);

  return (
    (!_.isEmpty(state.createEditJobName) && (state.selectedJob.jobName !== state.createEditJobName)) ||
    (!_.isNil(state.createEditSelectedCustomer) && (state.selectedJob.customerName !== state.createEditSelectedCustomer.label)) ||
    (!_.isNil(state.createEditSelectedJobType) && (state.selectedJob.jobType !== state.createEditSelectedJobType.label)) ||
    (!_.isNil(state.createEditSelectedContact) && (state.selectedJob.contactName !== state.createEditSelectedContact.label)) ||
    (!_.isNil(state.createEditSelectedDatavan) && (state.selectedJob.datavan !== state.createEditSelectedDatavan.label)) ||
    (!_.isNil(state.createEditStartDateDisplay) && (momentSelectedJobStartDate.unix() !== momentCreateEditStartDate.unix())) ||
    (!_.isNil(state.createEditEndDateDisplay) && (momentSelectedJobEndtDate.unix() !== momentCreateEditEndDate.unix())) ||
    (!_.isEmpty(state.createEditWells) && (!_.isEqual(state.selectedJob.wells, state.createEditWells)))
  )

}

export {
  processCustomersForAutocomplete,
  processJobs,
  validateCanSave,
  evaluateHasChanges,
  processWells,
  processDatavans,
  processJobTypes
}